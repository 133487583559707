import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { ResizableDelta, Position } from 'react-rnd';

import Icon from '@ant-design/icons';
import { RoadConstructionsService, RoadSign } from 'generated/api/api';
import MapEventObserver from 'store/rakes/MapEventObserver';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { CloseIcon } from 'UI/custom/icons/actionIcons/CloseIcon';

import { RoadSignContainer } from './components/RoadSign';
import { RoadSignGroupInfoProps } from './model/RoadSignGroupInfo.model';
import { RoadSignGroupInfoStyles } from './styles/RoadSignGroupInfo.styles';
import { MarkerSizeMode } from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import _ from 'underscore';
import { forageStore } from 'services/axiosInstances/axiosSecured';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { Layers } from 'types/enums/map/layers/Layers';
import { mapMarkersRoadSingGroupActions } from 'store/reducers/map/mapMarkersRoadSingGroup/mapMarkers.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';

const { Title, SignsBodyDecoration, Wrapper, RoadGroupContainer, RndContainer } = RoadSignGroupInfoStyles;

export const RoadSignGroupInfo = React.memo(
  ({ roadGroup, onClose, markerSizeMode, changeMarkerSizeMode }: RoadSignGroupInfoProps) => {
    const dispatch = useDispatch();
    const { selectingSing } = useSelector((state: StateModel) => state.mapMarkersRoadSingGroup.selectingSing);
    const { bearing } = useSelector((state: StateModel) => state.mapboxView.viewState);
    const [roadSings, setRoadSings] = useState<RoadSign[]>();
    const [mainSize, setMainSize] = useState<{ width: number; height: number }>(
      // @ts-ignore опять ошибка АПИ!
      roadGroup?.custom_size ?? { width: 100, height: (roadGroup.road_signs_list.length ?? 1) * 150 }
    );
    const orderedGroup = roadGroup?.road_signs_list;

    const isEmptyRoadSingState: boolean = _.isEmpty(selectingSing);
    const isNeedDangerBorderState: boolean =
      !isEmptyRoadSingState && !!selectingSing?.road_sign_group && selectingSing?.road_sign_group !== roadGroup.id;

    const handleUnlockMapEventsOnLeave = useCallback(() => {
      MapEventObserver.unlockMapBubbles();
    }, []);

    const notificationAlert = useCallback(
      (e, customMessage = 'Неизвестная ошибка обратитесь к администратору', title = null) => {
        let messages = [customMessage];
        if (e?.response && _.isObject(e.response)) {
          messages = createAlertMessage(e);
        }
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: title ? PromptType.success : PromptType.error,
            title: title ?? 'Ошибка',
            message: messages,
          })
        );
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
      },
      [dispatch]
    );

    useEffect(() => {
      const downloadRoadSingData = async () => {
        const roadSignsResponse = await Promise.allSettled(
          orderedGroup.map((id) => RoadConstructionsService.roadConstructionsRoadSignsList({ id }))
        );
        // @ts-ignore опять ошибка АПИ!
        const roadSigns: RoadSign[] = roadSignsResponse.map((el) => el.value.results).flat();
        setRoadSings(roadSigns);
      };
      if (orderedGroup.length) downloadRoadSingData();

      return () => {
        handleUnlockMapEventsOnLeave();
      };
    }, [handleUnlockMapEventsOnLeave, orderedGroup]);

    const handleLockMapEventsOnMouseEnter = useCallback(() => {
      MapEventObserver.lockMapBubbles();
    }, []);

    const handleCloseCard = useCallback(() => {
      onClose && onClose();
    }, [onClose]);

    const azimuthGroupRotate = useMemo(() => {
      if (roadSings && roadSings.length) {
        const azimuthSings =
          (roadSings.map((el) => el.azimuth).reduce((sum, current) => (sum ?? 0) + (current ?? 0)) ?? 0) /
          (roadSings?.length ?? 1);

        const result = roadSings
          .map((el) => el.azimuth)
          .reduce((prev, curr) => {
            return Math.abs((curr ?? 0) - azimuthSings) < Math.abs((prev ?? 0) - azimuthSings) ? curr : prev;
          });
        return result ?? 0;
      } else return 0;
    }, [roadSings]);

    const RoadGroupContainerStyle = useMemo(
      () => ({
        transform: `rotate(${azimuthGroupRotate - (bearing ?? 0)}deg)`,
        width: `${mainSize.width}px`,
        height: `${mainSize.height}px`,
      }),
      [azimuthGroupRotate, bearing, mainSize]
    );

    const signs: ReactElement[] | undefined = useMemo(
      () =>
        roadSings?.map((sign, index) => (
          <RoadSignContainer sign={sign} key={`${sign.id}_${index}`} azimuthGroupRotate={azimuthGroupRotate} />
        )),
      [azimuthGroupRotate, roadSings]
    );

    const handleSwitchMarkerMode = useCallback(() => {
      const newMarkerSizeMode = markerSizeMode === MarkerSizeMode.move ? MarkerSizeMode.drag : MarkerSizeMode.move;
      changeMarkerSizeMode(newMarkerSizeMode);
    }, [changeMarkerSizeMode, markerSizeMode]);

    const closeButton = useMemo(
      () => <Button onClick={handleCloseCard} icon={<CloseIcon />} type={ButtonType.unboundedPrimary} p={0} />,
      [handleCloseCard]
    );
    const handleSwitchMarkerModeButton = useMemo(
      () => (
        <Button
          onClick={handleSwitchMarkerMode}
          icon={<Icon type={markerSizeMode === MarkerSizeMode.move ? MarkerSizeMode.drag : 'fullscreen'} />}
          type={ButtonType.unboundedPrimary}
          p={0}
        />
      ),
      [handleSwitchMarkerMode, markerSizeMode]
    );

    const RndStyle = useMemo(
      () => ({
        width: `${mainSize.width}px`,
        height: `${mainSize.height - 20}px`,
      }),
      [mainSize]
    );

    const changeParentGroupSing = useCallback(async () => {
      if (isNeedDangerBorderState && selectingSing) {
        try {
          const importRoadSingGroupId = roadGroup.id;
          const { id, road_sign_group: exportRoadSingGroupId } = selectingSing;
          const newSing = { id, body: { road_sign_group: importRoadSingGroupId } };
          const response = await RoadConstructionsService.roadConstructionsRoadSignsPartialUpdate(newSing);
          if (response) {
            await forageStore.clear();
            dispatch(mapMarkersRoadSingGroupActions.removeMapMarkerRoadSingGroup());
            const promisImportRoadSingGroup = RoadConstructionsService.roadConstructionsRoadSignGroupsList({
              id: importRoadSingGroupId,
            });
            const promisExportRoadSingGroup = RoadConstructionsService.roadConstructionsRoadSignGroupsList({
              id: exportRoadSingGroupId,
            });
            // @ts-ignore опять ошибка АПИ!
            const { results: responseImportRoadSingGroups } = await promisImportRoadSingGroup;
            // @ts-ignore опять ошибка АПИ!
            const { results: responseExportRoadSingGroups } = await promisExportRoadSingGroup;
            dispatch(
              mapboxDataActions.replaceRelatedDataByKey(
                'road_sign_group',
                Layers.roadsSchema,
                responseImportRoadSingGroups[0]
              )
            );
            dispatch(
              mapboxDataActions.replaceRelatedDataByKey(
                'road_sign_group',
                Layers.roadsSchema,
                responseExportRoadSingGroups[0]
              )
            );
            notificationAlert(
              null,
              'Дорожный знак успешно перенесен. Закройте и и откройте обе группы для обновления данных',
              'Успешно'
            );
          }
        } catch (e) {
          notificationAlert(e);
        }
      }
    }, [dispatch, isNeedDangerBorderState, notificationAlert, roadGroup, selectingSing]);

    const titleButtons = useMemo(() => {
      return markerSizeMode === MarkerSizeMode.static ? (
        closeButton
      ) : (
        <>
          {handleSwitchMarkerModeButton}
          {closeButton}
        </>
      );
    }, [closeButton, handleSwitchMarkerModeButton, markerSizeMode]);
    const content = useMemo(() => {
      const style = {
        width: `${mainSize.width}px`,
        height: `${mainSize.height - 47}px`,
      };
      return (
        <Wrapper>
          <Title>{titleButtons}</Title>
          <SignsBodyDecoration
            style={style}
            className={isNeedDangerBorderState ? 'need_danger_border' : ''}
            onClick={changeParentGroupSing}
          >
            {signs}
          </SignsBodyDecoration>
        </Wrapper>
      );
    }, [changeParentGroupSing, isNeedDangerBorderState, signs, mainSize, titleButtons]);

    const isDisabledResize = markerSizeMode === MarkerSizeMode.move || markerSizeMode === MarkerSizeMode.static;
    const isDisabledMove = markerSizeMode === MarkerSizeMode.drag || markerSizeMode === MarkerSizeMode.static;

    const onResizeStop = useCallback(
      async (
        e: MouseEvent | TouchEvent,
        dir: any,
        elementRef: HTMLElement,
        delta: ResizableDelta,
        position: Position
      ) => {
        const { width, height } = delta;
        const custom_size = { width: mainSize.width + width, height: mainSize.height + height };
        setMainSize(custom_size);
        const { id } = roadGroup;
        const newMarkerObject = { id, body: { ...roadGroup, custom_size } };
        const response = await RoadConstructionsService.roadConstructionsRoadSignGroupsUpdate(newMarkerObject);
        await forageStore.clear();
        if (response) {
          dispatch(mapboxDataActions.replaceRelatedDataByKey('road_sign_group', Layers.roadsSchema, response));
          onClose && onClose(response);
        }
      },
      [dispatch, mainSize, onClose, roadGroup]
    );

    return (
      <RoadGroupContainer
        style={RoadGroupContainerStyle}
        onMouseEnter={handleLockMapEventsOnMouseEnter}
        onMouseLeave={handleUnlockMapEventsOnLeave}
      >
        <RndContainer
          minWidth={50}
          default={RndStyle}
          disableDragging={!isDisabledMove}
          enableResizing={isDisabledResize}
          enableUserSelectHack={true}
          onResizeStop={onResizeStop}
        >
          {content}
        </RndContainer>
      </RoadGroupContainer>
    );
  }
);
