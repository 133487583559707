import { PropsWithChildren } from 'react';

import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

import { IApplicationFormProps } from '../interfacesCargoTransport/interfacesCargoTransport';
import { createInputs } from './createInputs';

interface IprepareSingleGroupsProps {
  model: ModelResponse;
  data: any[];
  formProps: IApplicationFormProps;
}

export function prepareSinglePanels({ formProps, model, data }: PropsWithChildren<IprepareSingleGroupsProps>) {
  const missingGroups = Object.keys(model.scheme);
  return createInputs(model, missingGroups, data, formProps.form);
}
