import { Form } from 'antd';
import MaskedInput from 'antd-mask-input';
import React, { useMemo } from 'react';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import { Styled } from '../styled/BaseInput.styled';
import { useBaseInputPlaceholderControl } from '../hooks/useBaseInputPlaceholderControl';
import { checkConditionAndCallFn } from '../../../../services/common/checkConditionAndCallFn';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';

const { getBaseStylesForInput, BasePlaceholder, BaseErrorMessage } = Styled;

const StyledMaskedInput = getBaseStylesForInput(MaskedInput);

const CustomMaskedInput = (props) => {
  const { control, errors, setValue } = useFormContext();
  const { description, object, field, mask, validator } = props;
  const { required, message } = useBaseInputSettings(description);

  const { placeholderState, setPlaceholderState } = useBaseInputPlaceholderControl(field, object, setValue);

  const className = useMemo(() => {
    if (placeholderState) return 'opened';
    if (errors[field]) return 'class_errors';
    return '';
  }, [errors, field, placeholderState]);

  if (errors[field]?.message?.includes(description.label) && errors[field]) {
    errors[field].message = null;
  }
  return (
    <Form.Item key={'form_' + field} name={field} className={className}>
      <Controller
        name={field}
        as={StyledMaskedInput}
        mask={mask}
        key={'input_' + field}
        control={control}
        rules={{
          required: { value: required, message: message },
          validate: (value) => {
            if (value && validator) {
              return validator(value, mask);
            } else {
              return true;
            }
          },
        }}
        disabled={description.read_only}
        placeholder={description.label}
        onFocus={() => {
          setPlaceholderState(true);
        }}
        onBlur={(e) => checkConditionAndCallFn(!e[0].target.value, () => setPlaceholderState(false))}
        onSubmit={(e) => {
          return e;
        }}
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <BasePlaceholder className={className}>{description.label}</BasePlaceholder>
    </Form.Item>
  );
};

export default CustomMaskedInput;
