import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';

export class transportAnalysisDistrictIsetskiiy extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'transportAnalysisDistrictIsetskiiy',
      filterLabel: 'Исетский район',
    });
  }

  async uploadData() {
    return await getFromURL.getWithParams('/public_transport/checkpoints_you_can_reach/', { district_id: '16' });
  }
}
