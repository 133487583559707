import { NumericLayerSetting } from './prototype/types/NumericLayerSetting';
import { Settings } from 'types/enums/map/layers/Settings';

export class IconSizeScale extends NumericLayerSetting {
  constructor(currentValue = 1) {
    super({
      label: 'Размер иконок',
      keyForDeck: Settings.iconSizeScale,
      minimum: 0.1,
      currentValue: currentValue,
      maximum: 4,
      step: 0.1,
      isRanged: false,
      isPercent: true,
    });
  }
}
