import { ArcLayer, IconLayer } from '@deck.gl/layers';

import InstanceLayer from 'types/classes/InstanceLayer';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { work_shifts } from '../../iconMappers/work_shifts';
import { DSelector } from 'services/map/Dselector/DSelector';
import { Mark, StatusF30Enum, WorkShift, WorkShiftWithApplication } from 'generated/api/api';
import { AnyObject } from 'types/enums/general/general.model';
import { ArkPathI, BrigadeWorkShiftsLayerDataI } from './BrigadeApplications.model';
import MapEventObserver from 'store/rakes/MapEventObserver';
import { Layers } from 'types/enums/map/layers/Layers';
import { TextLayer } from 'deck.gl';

const getArkPath = (
  selectedObject: AnyObject,
  workShiftsApplications: Array<WorkShiftWithApplication>,
  workShiftTrack: Array<Mark>
) => {
  const workShiftPath = [];
  const statusColor = {
    NEW: [45, 156, 219],
    CONFIRMED: [47, 128, 237],
    IN_WORK: [242, 153, 74],
    DONE: [39, 174, 96],
    APPOINTED: [187, 107, 217],
    CLOSED: [146, 159, 181],
    CANCELED: [146, 159, 181],
  };

  if (selectedObject?.type === MapObjects.brigadeWorkShifts && !!workShiftsApplications) {
    const selectedObjectId = selectedObject?.selectedObject?.object?.id ?? selectedObject?.selectedObject?.id;
    const currentTrack = workShiftTrack?.find((el) => el.work_shift_id === selectedObjectId);
    const currentWorkShiftPoint = currentTrack?.point;

    for (let index in workShiftsApplications) {
      if (workShiftsApplications.hasOwnProperty(index)) {
        const currentIndex = parseInt(index);
        const currentStatus = workShiftsApplications[currentIndex]?.status ?? StatusF30Enum.NEW;
        const pointCoords = DSelector.getPositionFromPoint(workShiftsApplications[currentIndex]);
        const pointColor = statusColor[currentStatus];

        if (currentIndex === 0) {
          workShiftPath.push({
            sourceColor: [47, 128, 237],
            targetColor: pointColor,
            from: {
              coordinates: !!currentWorkShiftPoint
                ? [currentWorkShiftPoint?.longitude, currentWorkShiftPoint?.latitude]
                : pointCoords,
            },
            to: {
              coordinates: pointCoords,
            },
          });
        } else {
          const prevPointCoords = DSelector.getPositionFromPoint(workShiftsApplications[currentIndex - 1]);
          const prevStatus = workShiftsApplications[currentIndex - 1]?.status ?? StatusF30Enum.NEW;
          const prevPointColor = statusColor[prevStatus];

          workShiftPath.push({
            sourceColor: prevPointColor,
            targetColor: pointColor,
            from: {
              coordinates: prevPointCoords,
            },
            to: {
              coordinates: pointCoords,
            },
          });
        }
      }
    }
  }

  return workShiftPath;
};

class BrigadeWorkShiftsLayer extends InstanceLayer<BrigadeWorkShiftsLayerDataI, any> {
  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickInstance) {
        event.info.object = this.props.data?.[MapObjects.brigadeWorkShifts].find(
          (el: WorkShift) => el.id === event.info.object.work_shift_id
        );
        this.props.onClickInstance(event.info, MapObjects.brigadeWorkShifts, Layers.brigadeApplications);
      }
    }
    return event.info;
  }

  updateState() {
    const workShiftPath = getArkPath(
      this.props.selectedObject,
      this.props.data?.[MapObjects.brigadeWorkShiftsApplications],
      this.props.data?.[MapObjects.brigadeWorkShiftsTrack]
    );

    this.setState({ workShiftPath });
  }

  renderLayers() {
    if (!this.props.data) return [];
    return [
      new IconLayer<Mark>({
        id: 'brigade-work-shifts-layer',
        data: this.props.data?.[MapObjects.brigadeWorkShiftsTrack],
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/work_shifts.png`,
        iconMapping: work_shifts,
        opacity: this?.props?.opacity ?? 0,
        pickable: true,
        autoHighlight: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => (d?.point ? [d.point.longitude, d.point.latitude] : [0, 0]),
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: this.props.selectedObject,
        },
        getIcon: (d) => {
          return DSelector.getSelectableIcon({
            id: d?.work_shift_id,
            layerState: this.props,
            typeCheck: MapObjects.brigadeWorkShifts,
            selectedMapper: 'work_shift_active',
            deselectedMapper: 'work_shift',
          });
        },
      }),
      new ArcLayer<ArkPathI>({
        id: 'brigade-work-shift-path-layer',
        data: this.state.workShiftPath,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getSourcePosition: (d) => d.from.coordinates,
        getTargetPosition: (d) => d.to.coordinates,
        getWidth: () => 3,
        getHeight: 0.5,
        getSourceColor: (d) => d.sourceColor,
        getTargetColor: (d) => d.targetColor,
      }),
      new TextLayer<any>({
        id: 'brigade-work-shift-text-layer',
        data: this.props.data?.[MapObjects.brigadeWorkShiftsTrack],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d: any): any => {
          if (d) {
            return d.appointed_team_name;
          }
        },
        getSize: 9,
        getPixelOffset: [0, -18],
        getPosition: (d: any): any => {
          return d?.point ? [d.point.longitude, d.point.latitude] : [0, 0];
        },
      }),
    ];
  }
}

export default BrigadeWorkShiftsLayer;
