import React from 'react';
import styled from 'styled-components/macro';
import LayersContentSwitcherContainer from '../LayersContentSwitcher/LayersContentSwitcherContainer';
import { Button } from '../../../../../../../../../../UI/common/components/Button/Button';
import { baseStyles } from '../../../../../../../../../stylesRegistrator';
import { MenuIcon } from '../../../../../../../../../../UI/custom/icons/actionIcons/MenuIcon';

const { BaseMapPanelHeader, BaseMapPanelTopPart, BaseMapPanelPrimaryTitle } = baseStyles.modules.map;

const StyledPanelTopPart = styled(BaseMapPanelTopPart)`
  justify-content: center;
  & button {
    position: absolute;
    left: 10px;
  }
  & ${BaseMapPanelPrimaryTitle} {
    width: 70%;
    text-align: center;
  }
`;

const LayersMenuHeader = ({ toggleMainPanelCollapse }) => {
  return (
    <BaseMapPanelHeader>
      <StyledPanelTopPart>
        <Button onClick={toggleMainPanelCollapse} p={'none'} type={'unbounded'} icon={<MenuIcon />} />
        <BaseMapPanelPrimaryTitle>Меню карты</BaseMapPanelPrimaryTitle>
      </StyledPanelTopPart>
      <LayersContentSwitcherContainer />
    </BaseMapPanelHeader>
  );
};

export default LayersMenuHeader;
