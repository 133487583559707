import { NumericLayerSetting } from './prototype/types/NumericLayerSetting';

export class BorderLineWidthScale extends NumericLayerSetting {
  constructor() {
    super({
      label: 'Размер границ полигонов',
      keyForDeck: 'getLineWidth',
      type: 'Number',
      minimum: 0.1,
      currentValue: 0.5,
      maximum: 3,
      step: 0.1,
      isRanged: false,
      isPercent: true,
    });
  }
}
