import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RoadsService as RoadWorksService } from '../../../../../../generated/api/api';

export class ImprovementElements extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'improvement_elements',
      filterLabel: 'Элементы благоустройства',
      selectedObjectName: MapObjects.improvementElements,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadWorksService.roadsImprovementElementsList();
  }

  async deleteRecord(id) {
    return await RoadWorksService.roadsImprovementElementsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadWorksService.roadsImprovementElementsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL('roads/improvement_elements/');
  }
}
