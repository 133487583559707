import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { postToURLSingle } from '../../../api/postToURLSingle';
import { cropPath } from '../../../services/net/cropPath';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { appActions } from '../../reducers/global/app/app.actions';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import _ from 'underscore';
import { createAlertMessage } from '../app/createAlertMessage';
import { forageStore } from 'services/axiosInstances/axiosSecured';

export const DELETE_OBJECT_DATA = 'DELETE_OBJECT_DATA';

export const deleteObject = ({
  url,
  id,
  RelatedDataObject = null,
  redrawDispatch = null,
  successSideEffectsDispatch = [],
  finallySideEffectsDispatch = [],
  errorSideEffectsDispatch = [],
}) => ({
  type: DELETE_OBJECT_DATA,
  payload: {
    id,
    url,
    redrawDispatch,
    RelatedDataObject,
    finallySideEffectsDispatch,
    errorSideEffectsDispatch,
    successSideEffectsDispatch,
  },
});

function* deleteObjectSaga(action) {
  yield put({ type: 'START_DELETING_DATA' });
  yield put(appActions.startAppFetching());

  try {
    const { url, id, redrawDispatch } = action.payload;
    const response = yield call(() => postToURLSingle.deleteData(cropPath(url), id));
    if (response || response === '') {
      yield put(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: 'Объект был успешно удален',
        })
      );

      if (redrawDispatch) {
        yield put(redrawDispatch);
        yield put({
          type: 'REFRESH_SUCCESSFUL',
        });
      }
      yield forageStore.clear();
    }
  } catch (e) {
    if (e?.response && _.isObject(e.response)) {
      const message = createAlertMessage(e);
      yield put(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: message,
        })
      );
    }
  } finally {
    const { finallySideEffectsDispatch } = action.payload;

    for (let sideEffect of finallySideEffectsDispatch) {
      yield put(sideEffect());
    }

    yield put(appActions.endAppFetching());
    yield put({ type: 'END_DELETING_DATA' });
    yield delay(3000);
    yield put(notificationActions.clearGlobalAlertData());
  }
}

export function* watchDeleteObjectSaga() {
  yield takeEvery(DELETE_OBJECT_DATA, deleteObjectSaga);
}
