import styled from 'styled-components/macro';

const LocalItemWrap = styled.article`
  text-align: left;
  width: 100%;
  min-height: 60px;
  height: fit-content;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  background-color: ${(props) => props.theme.colors.defaultLight};
  padding: 12px 16px;

  :not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const LocalDescItemName = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  word-break: break-word;
  color: ${(props) => props.theme.colors.neutralDarker};
  margin: 0;
`;

const LocalDescItemContentWrap = styled.div`
  color: ${(props) => (props.color ? props.color : props.theme.colors.defaultDark)};
  font-weight: 500;
  word-break: break-word;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
`;

const LocalDescItemContent = styled.p`
  margin: 0;
`;

const LocalItemButtonWrap = styled.div`
  background: transparent !important;

  button {
    ${(props) => (props?.style?.backgroundColor ? `background:${props.style.backgroundColor};` : '')}
    ${(props) => (props?.style?.color ? `color:${props.style.color};` : '')}
    width: 100%;
    margin-top: 16px;
  }
`;

export const Styled = {
  LocalItemWrap,
  LocalDescItemName,
  LocalDescItemContentWrap,
  LocalDescItemContent,
  LocalItemButtonWrap,
};
