import React from 'react';

import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { AnyObject } from 'types/enums/general/general.model';
import { PatchedRoadMarkingPoint } from 'generated/api/api';

interface RoadMarkingsItemSelectionProps {
  handleCloseMapSelectedObjectInfo: () => void;
  selectedMapItem: AnyObject;
  data: PatchedRoadMarkingPoint;
}

const RoadMarkingsItemSelection = (
  {
    handleCloseMapSelectedObjectInfo,
    selectedMapItem,
    data,
  }: RoadMarkingsItemSelectionProps) => {

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord: any) => dataRecord.name === MapObjects.roadMarkings);
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadMarkingPoints}/${id}/`, '_blank');
    win?.focus();
  };

  const Descriptions: IDescriptions[] = [];

  Descriptions.push({
    key: 'Tип разметки',
    value: data.road_marking_type_name ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Примечание',
    value: data.comment ?? '-',
  });
  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer?.toString() ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Дорожная разметка'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default RoadMarkingsItemSelection;
