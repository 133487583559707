import { LayerCardPrototype } from 'registrators/map/layers/description/cards/prototype/LayerCardPrototype';

export class BrigadeApplicationsCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'Заявки и бригады',
      linkToPreview: 'SchemaRoad.svg',
      permissionToShowCard: 'layer_permissions.can_view_dutssd_layer',
    });
  }
}
