import { Dispatch } from 'react';

import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { CameraWidget, CountingIntervalEnum, CountingPeriodEnum } from 'generated/api/api';
import { WatcherFieldsFunction } from 'hooks/form/usePrepareFieldsHook/model/watchFieldsModel.model';

export const countingIntervalWatcher = (
  model: ModelResponse,
  data: CameraWidget,
  setModel: Dispatch<ModelResponse>
): WatcherFieldsFunction => {
  const choices = [...(model.scheme.counting_interval.choices ?? [])];
  let dataValue = data.counting_period;

  return (setValue, value: CountingPeriodEnum) => {
    const availableInterval: {
      [key: string]: string[];
    } = {
      [CountingPeriodEnum.TWENTY_FOUR_HOURS]: [
        CountingIntervalEnum.FIFTEEN_MINUTES,
        CountingIntervalEnum.THIRTY_MINUTES,
        CountingIntervalEnum.ONE_HOUR,
      ],
      [CountingPeriodEnum.WEEK]: [CountingIntervalEnum.TWENTY_FOUR_HOURS],
      [CountingPeriodEnum.MONTH]: [CountingIntervalEnum.WEEK],
      [CountingPeriodEnum.QUARTER]: [CountingIntervalEnum.WEEK, CountingIntervalEnum.MONTH],
      [CountingPeriodEnum.HALF_YEAR]: [CountingIntervalEnum.MONTH],
      [CountingPeriodEnum.YEAR]: [CountingIntervalEnum.MONTH],
    };

    const newChoices = [];

    for (const choice of choices) {
      const choiceValue = choice.value;

      if (availableInterval[value]?.includes(choiceValue)) {
        newChoices.push(choice);
      }
    }

    model.scheme.counting_interval.choices = newChoices;
    setModel(model);

    if (value && value !== dataValue) {
      setValue('counting_interval', null);
      dataValue = value;
    }
  };
};
