export const signsRoadMapperType7 = {
  7.1: { x: 0, y: 0, width: 88, height: 180 },
  '7.1-G': { x: 89, y: 0, width: 88, height: 180 },
  '7.1-R': { x: 178, y: 0, width: 88, height: 180 },
  7.2: { x: 267, y: 0, width: 88, height: 180 },
  '7.2-G': { x: 0, y: 181, width: 88, height: 180 },
  '7.2-R': { x: 89, y: 181, width: 88, height: 180 },
  7.3: { x: 178, y: 181, width: 88, height: 180 },
  '7.3-G': { x: 267, y: 181, width: 88, height: 180 },
  '7.3-R': { x: 356, y: 0, width: 88, height: 180 },
  7.4: { x: 356, y: 181, width: 88, height: 180 },
  '7.4-G': { x: 445, y: 0, width: 88, height: 180 },
  '7.4-R': { x: 445, y: 181, width: 88, height: 180 },
  7.5: { x: 0, y: 362, width: 88, height: 180 },
  '7.5-G': { x: 89, y: 362, width: 88, height: 180 },
  '7.5-R': { x: 178, y: 362, width: 88, height: 180 },
  7.6: { x: 267, y: 362, width: 88, height: 180 },
  '7.6-G': { x: 356, y: 362, width: 88, height: 180 },
  '7.6-R': { x: 445, y: 362, width: 88, height: 180 },
  7.7: { x: 534, y: 0, width: 88, height: 180 },
  '7.7-G': { x: 534, y: 181, width: 88, height: 180 },
  '7.7-R': { x: 534, y: 362, width: 88, height: 180 },
  7.8: { x: 623, y: 0, width: 88, height: 180 },
  '7.8-G': { x: 623, y: 181, width: 88, height: 180 },
  '7.8-R': { x: 623, y: 362, width: 88, height: 180 },
  7.9: { x: 0, y: 543, width: 88, height: 180 },
  '7.9-G': { x: 89, y: 543, width: 88, height: 180 },
  '7.9-R': { x: 178, y: 543, width: 88, height: 180 },
  '7.10': { x: 267, y: 543, width: 88, height: 180 },
  '7.10-G': { x: 356, y: 543, width: 88, height: 180 },
  '7.10-R': { x: 445, y: 543, width: 88, height: 180 },
  7.11: { x: 534, y: 543, width: 88, height: 180 },
  '7.11-G': { x: 623, y: 543, width: 88, height: 180 },
  '7.11-R': { x: 712, y: 0, width: 88, height: 180 },
  7.12: { x: 712, y: 181, width: 88, height: 180 },
  '7.12-G': { x: 712, y: 362, width: 88, height: 180 },
  '7.12-R': { x: 712, y: 543, width: 88, height: 180 },
  7.13: { x: 801, y: 0, width: 88, height: 180 },
  '7.13-G': { x: 801, y: 181, width: 88, height: 180 },
  '7.13-R': { x: 801, y: 362, width: 88, height: 180 },
  7.14: { x: 801, y: 543, width: 88, height: 180 },
  '7.14-G': { x: 0, y: 724, width: 88, height: 180 },
  '7.14-R': { x: 89, y: 724, width: 88, height: 180 },
  7.15: { x: 178, y: 724, width: 88, height: 180 },
  '7.15-G': { x: 267, y: 724, width: 88, height: 180 },
  '7.15-R': { x: 356, y: 724, width: 88, height: 180 },
  7.16: { x: 445, y: 724, width: 88, height: 180 },
  '7.16-G': { x: 534, y: 724, width: 88, height: 180 },
  '7.16-R': { x: 623, y: 724, width: 88, height: 180 },
  7.17: { x: 712, y: 724, width: 88, height: 180 },
  '7.17-G': { x: 801, y: 724, width: 88, height: 180 },
  '7.17-R': { x: 890, y: 0, width: 88, height: 180 },
  7.18: { x: 890, y: 181, width: 88, height: 180 },
  '7.18-G': { x: 890, y: 362, width: 88, height: 180 },
  '7.18-R': { x: 890, y: 543, width: 88, height: 180 },
  7.19: { x: 890, y: 724, width: 88, height: 180 },
  '7.19-G': { x: 979, y: 0, width: 88, height: 180 },
  '7.19-R': { x: 979, y: 181, width: 88, height: 180 },
  '7.20': { x: 979, y: 362, width: 88, height: 180 },
  '7.20-G': { x: 979, y: 543, width: 88, height: 180 },
  '7.20-R': { x: 979, y: 724, width: 88, height: 180 },
};
