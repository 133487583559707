import React from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const RailCrossingItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'rail_crossings');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/${DirectoryRouteModel.artificial_structures_rail_crossings}/${id}/`,
      '_blank'
    );
    win.focus();
  };

  //Словарь для типа перекрываемого препятствия
  const BARRIER_AND_TRAFFIC_LIGHT = 'BARRIER_AND_TRAFFIC_LIGHT',
    ONLY_TRAFFIC_LIGHT = 'ONLY_TRAFFIC_LIGHT',
    WITHOUT_OBJECTS = 'WITHOUT_OBJECTS';
  const typeMap = new Map([
    [BARRIER_AND_TRAFFIC_LIGHT, 'Переезд со шлагбаумом и светофором'],
    [ONLY_TRAFFIC_LIGHT, 'Переезд без шлагбаума со светофором'],
    [WITHOUT_OBJECTS, 'Переезд без шлагбаума и светофора'],
  ]);

  let Descriptions = [];

  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = data;

  Descriptions.push({
    key: 'Наименование дороги',
    value: properties.segment_name,
  });

  Descriptions.push({
    key: 'Тип перекрываемого препятствия',
    value: typeMap.get(properties.rail_crossing_type) ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об объекте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

RailCrossingItemSelection.propTypes = {};

export default RailCrossingItemSelection;
