import React, { FC, useMemo } from 'react';
import moment from 'moment';
import { CartesianGrid, Line, LineChart as LineChartR, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import _ from 'lodash';

import { Styled } from './style/LineChart.styled';
import { LineChartProps } from './model/LineChart.model';
import LineTooltip from './components/LineTooltip/LineTooltip';

const { LineChartWrap, LineChartLegend, LineChartLegendWrap, LineChartLegendItem } = Styled;

const LineChart: FC<LineChartProps> = ({ chartSettings, data, xKey }) => {
  const formatData = useMemo(() => {
    const allData = _.flatten(
      data.map((el) => {
        return el.data.map((dataEl: any) => {
          let xKeyData = dataEl[xKey];

          if (moment(xKeyData).isValid()) {
            xKeyData = moment(xKeyData).format('DD.MM.YYYY HH:mm');
          }

          return {
            [xKey]: xKeyData,
            [`result_${el.id}`]: dataEl.result,
          };
        });
      })
    );

    const grouped = _.groupBy(allData, xKey);

    const groupData: any = [];

    _.forEach(grouped, function (value: any) {
      const obj = {};
      for (let i = 0; i < value.length; i++) {
        _.defaults(obj, value[i]);
      }
      groupData.push(obj);
    });

    return groupData;
  }, [data, xKey]);

  const legend = useMemo(() => {
    return data.map((el) => {
      return (
        <LineChartLegendItem key={el.id} color={el.color}>
          {el.name}
        </LineChartLegendItem>
      );
    });
  }, [data]);

  const lines = useMemo(() => {
    return data.map((el) => {
      return (
        <Line
          key={el.id}
          type="monotone"
          strokeWidth={2}
          name={el.name}
          dot={false}
          dataKey={`result_${el.id}`}
          activeDot={{ r: 4, strokeWidth: 0 }}
          stroke={el.color}
        />
      );
    });
  }, [data]);

  return (
    <LineChartWrap>
      <ResponsiveContainer>
        <LineChartR data={formatData}>
          {chartSettings?.showGridLines && <CartesianGrid stroke={'#F1F3F6'} />}
          <Tooltip content={LineTooltip} />
          <YAxis tickLine={false} axisLine={false} fontSize={10} width={35} />
          <XAxis tickLine={false} axisLine={false} fontSize={10} height={10} dataKey={xKey} />
          {lines}
        </LineChartR>
      </ResponsiveContainer>
      <LineChartLegend>
        {chartSettings?.showCaptions && <LineChartLegendWrap>{legend}</LineChartLegendWrap>}
      </LineChartLegend>
    </LineChartWrap>
  );
};

export default LineChart;
