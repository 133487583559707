import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import _ from 'underscore';

import { Styled } from './AutocompleteForm.styled';
import { ApplicationFormProps } from './model/ApplicationForm.model';
import { preparePanelGroups } from './utils/preparePanelGroups';
import { prepareSinglePanels } from './utils/prepareSinglePanels';

const { ExtendedAutocompleteForm, LocalTitle, LocalPanelsWrapper } = Styled;

const AutocompleteForm = React.forwardRef(
  (
    { handleSubmitForm, hideTitle, additionalRenders, form, data, model }: PropsWithChildren<ApplicationFormProps>,
    ref: any
  ) => {
    const methods = useForm();

    const groups = model.groups;

    const panelsRender = useMemo(() => {
      return groups ? preparePanelGroups({ form, groups, data, model }) : prepareSinglePanels({ form, data, model });
    }, [data, form, groups, model]);

    const content = useMemo(() => {
      return (
        <>
          {!hideTitle && <LocalTitle>Основные</LocalTitle>}
          <LocalPanelsWrapper>{panelsRender}</LocalPanelsWrapper>
        </>
      );
    }, [hideTitle, panelsRender]);

    const onFinish = useCallback(() => {
      methods.triggerValidation().then((isDataValidation) => {
        const submitData = methods.getValues();
        if (isDataValidation && !_.isEmpty(submitData)) handleSubmitForm(submitData);
      });
    }, [handleSubmitForm, methods]);

    return (
      <FormContext {...methods}>
        <ExtendedAutocompleteForm ref={ref} onFinish={onFinish}>
          {content}
        </ExtendedAutocompleteForm>
        {additionalRenders}
      </FormContext>
    );
  }
);

export default AutocompleteForm;
