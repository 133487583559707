import styled from 'styled-components';
import { DefaultStyleMixins } from '@frontend-packages/its-ui-kit';
const { getSpacing } = DefaultStyleMixins.offset;

export const SButtonBlockContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  gap: ${getSpacing(1)};
  margin-top: ${getSpacing(2)};
`;
