export enum TabMenuEnum {
  role_name = 'Название роли',
  get_directory = 'Доступ к справочникам',
  get_layers = 'Доступ к картам',
  get_reports = 'Доступ к отчётам',
  permissions_groups = 'Название роли',
  main = 'Основные',
  filters_user = 'Фильтрация',
  filters_user_organizations = 'Организации',
  filters_user_districts = 'Районы',
  users = 'Пользователи',
}
