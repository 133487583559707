import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SET_OBJECT_FOR_STANDALONE_EDIT_MODE,
  SET_STANDALONE_EDIT_MODE_OBJECT,
} from '../../reducers/map/mapboxEditableStandaloneObject';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import { getRegisteredLayer } from '../../../registrators/map/layers/layersRegistrator';
import { ModifyMode } from '@nebula.gl/edit-modes';

export const INIT_SPECIALIZE_OBJECT_EDIT_MODE_SAGA = 'INIT_OVERPASSES_ADDING_SEGMENT_MODE_SAGA';

export const initializeSpecializeObjectEditMode = (
  selectedObject,
  typeOfObject,
  objectLayerName,
  initialCoordinate,
  groupType,
  urlData
) => {
  return {
    type: INIT_SPECIALIZE_OBJECT_EDIT_MODE_SAGA,
    payload: {
      selectedObject,
      typeOfObject,
      objectLayerName,
      initialCoordinate,
      groupType,
      urlData,
    },
  };
};

/**
 * Привязка сегмента к искусственному сооружению
 * @param action
 * Эта сага - огрызок от standaloneObjectEditableSaga.js, созданный, чтобы не засорять основную сагу. Она принимает данные о выбранном объекте
 * и передаёт их в нагрузке экшенов, чтобы записать в стэйт и, изменив режим карты на "редактирование", включить режим редактирования
 * без изменения геометрии - то есть можно вносить изменения только в карточку объекта. Так как вызывается не карточка
 * самого объекта, а специальная, дополнительная карточка привязки сегмента к ИС, то урл для неё передаётся отдельно в нагрузке экшена
 *
 * Так как в последствии, возможно, подобных специфичных саг станет больше, название саге дано более общее. Полный список того,
 * что принимает и что может отдавать сага смотреть в standaloneObjectEditableSaga.js и mapboxEditableStandaloneObject.js - сейчас многие вещи написаны хардкодом
 * или убраны, чтобы было взято дефолтное значение. Возможно добавление существенной вариативности.
 */

function* initSpecializingObjectEditableSaga(action) {
  try {
    const { selectedObject, typeOfObject, objectLayerName, urlData } = action.payload;
    // relatedData - основывясь на принятом имени слоя загружает все его составляющие.
    const relatedData = getRegisteredLayer(objectLayerName).getRelatedData();
    // relatedDataObject путём сравнения св-ва name каждой составляющей с принятым типом объекта оставляет одну - к которой объект принадлежит.
    const relatedDataObject = relatedData.find((el) => el.name.includes(typeOfObject));
    // model используя метод описанный при создании элементов, получает структуру/модель объекта данной составляющей.
    const model = yield call(() => relatedDataObject.uploadModelForAddingIntoOverpasses());
    // Так как в проекте, где это записывается, данные сегментов в их отображениях на карте сокращены до необход. минимума и не совпадают
    //  с теми, которые нужно отобразить при редактировании, то вводится дополнительная переменная currentObject, являющаяся
    //  полными данными объекта с типом = relatedDataObject и id = selectedObject.id.
    let currentObject = yield call(() => relatedDataObject.readRecord(selectedObject.id));
    // В карточке редактирования объекта сегмент задаётся тот, который выбран на карте и блокируется возможность редактирования поля.
    model.scheme.segment.label = currentObject.name;
    model.scheme.segment.read_only = true;
    // Принятые, созданные и полученные данные собираются в объект preparedData и вызываются экшены
    // в переводящие карту и объекты в состояние редактирования.
    // Большая часть данных захардкожена или убрана за ненадобностью. editMode - выбор режима редактирования карты в котором. Полный список режимов
    // интересно глянуть в node_modules/@nebula.gl/edit-modes/src/lib. ModifyMode - мод редактирования без редактирования геометрии объектов (ну, вроде бы)
    const preparedData = {
      urlData,
      selectedInstance: typeOfObject,
      drawData: {
        type: 'FeatureCollection',
        features: [],
      },
      model,
      geometryKey: null,
      editMode: ModifyMode,
      name: 'Привязка участка дороги к искусственному сооружению',
      isNew: false,
      instance: currentObject,
      layerName: objectLayerName,
    };
    //finally set mode to true
    yield put({
      type: SET_STANDALONE_EDIT_MODE_OBJECT,
      payload: {
        ...preparedData,
      },
    });
    yield put({
      type: SET_OBJECT_FOR_STANDALONE_EDIT_MODE,
      payload: {
        value: true,
      },
    });

    yield put({
      type: notificationActions.SET_NOTIFICATION_DATA,
      payload: {
        message: 'Введите данные',
        status: ActiveStatus.active,
      },
    });
  } catch (e) {
    console.error(e);
    yield put(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message: 'Что-то пошло не так',
      })
    );
  }
}

//ready
export function* watchSpecializingObjectEditableSaga() {
  yield takeEvery(INIT_SPECIALIZE_OBJECT_EDIT_MODE_SAGA, initSpecializingObjectEditableSaga);
}
