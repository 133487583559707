import styled from 'styled-components/macro';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { OpacityColor } = styleMixins.color;

const Placeholder = styled.span`
  position: absolute;
  font-size: ${(props) => props.theme.fontSettings.default};
  line-height: 140%;
  color: ${(props) => props.theme.colors.gray};
  font-weight: 500;
  left: 16px;
  pointer-events: none;
  transition: all 0.2s ease;
  top: 18px;
`;

const HelpPlaceholder = styled.span`
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.neutralDarker};
  margin-top: 4px;
`;

const MultipleLabel = styled.span`
  top: 28px;
  right: 16px;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.default};
  line-height: 140%;
  pointer-events: none;
`;

const StyledSelect = styled.div`
  width: 100%;
  position: relative;

  & .ant-select {
    width: 100%;
    height: 56px;
    border: 2px solid transparent;
    background-color: ${(props) => props.theme.colors.defaultLight};
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius} !important;
    display: block;

    &:hover {
      border: 2px solid ${(props) => props.theme.colors.darkblue};

      &.ant-select-allow-clear {
        & .ant-select-arrow {
          display: none;
        }
      }
    }
  }

  & .ant-select-selector {
    height: 100% !important;
    width: 100%;
    border: none !important;
    background: transparent !important;
    box-shadow: none;
  }

  & .ant-select-selector {
    height: 100%;
    padding-top: 3px !important;
    span {
      font-weight: 500;
      font-size: ${(props) => props.theme.fontSettings.default};
      line-height: 60px !important;
    }
  }

  & .ant-select-selection__placeholder {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSettings.default};
    color: ${(props) => props.theme.colors.neutralDark};
    top: 25px;
    margin: 0;
    height: auto;
    left: 2px;
  }

  & .ant-select-arrow {
    color: ${(props) => props.theme.colors.darkblue};
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    transform: translateY(-50%);
    right: 16px;
  }

  & .ant-select-selection-selected-value {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSettings.default};
    line-height: 140%;
    color: ${(props) => props.theme.colors.dark};
  }

  & .ant-select-search--inline {
    position: absolute;
    height: 19px !important;
    float: unset;
    margin-top: 0;
    top: 20px;
    font-size: ${(props) => props.theme.fontSettings.default};
    left: -1px;
  }

  & .ant-select-selection__clear {
    right: 16px;
  }

  & .ant-select-selection--multiple {
    padding-bottom: 0;
    margin-bottom: 0;

    & .ant-select-selection__clear {
      top: 50%;
    }

    & .ant-select-selection__choice {
      display: none;
    }
  }

  & .ant-select-dropdown {
    background: ${(props) => props.theme.colors.defaultLighter};
    box-shadow: 0 2px 8px ${(props) => OpacityColor(props.theme.colors.primary, 0.12)};
    backdrop-filter: ${(props) => props.theme.decoration.defaultBackgroundFilter};
    padding: 8px;
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  }

  & .ant-select-dropdown-menu-item {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSettings.defaultSmall};
    line-height: 140%;
    color: ${(props) => props.theme.colors.dark};
    padding: 9px 8px 9px 12px;
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};

    &:hover {
      backdrop-filter: ${(props) => props.theme.decoration.smallBackgroundFilter};
      background: ${(props) => OpacityColor(props.theme.colors.darkblue, 0.12)};
      color: ${(props) => props.theme.colors.darkblue};
    }
  }

  & .ant-select-dropdown-menu-item-selected {
    backdrop-filter: ${(props) => props.theme.decoration.smallBackgroundFilter};
    background: ${(props) => OpacityColor(props.theme.colors.darkblue, 0.12)};
    color: ${(props) => props.theme.colors.darkblue};
  }

  &.open {
    & ${Placeholder} {
      font-size: ${(props) => props.theme.fontSettings.defaultSmall};
      color: ${(props) => props.theme.colors.darkblue};
      transform: translateY(-11px);
    }

    & .ant-select {
      border: 2px solid ${(props) => props.theme.colors.darkblue};
      background-color: ${(props) => props.theme.colors.defaultLighter};
    }
  }

  &.selected {
    & ${Placeholder} {
      font-size: ${(props) => props.theme.fontSettings.defaultSmall};
      color: ${(props) => props.theme.colors.darkblue};
      transform: translateY(-11px);
    }

    &.open {
      & .ant-select-selector {
        svg {
          transform: none !important;
        }
        span {
          line-height: 60px;
        }
      }
    }
  }

  &.clear {
    &.selected {
      &:not(.disabled) .ant-select:hover {
        & .ant-select-arrow {
          display: none;
        }
      }
    }
  }

  &.disabled {
    & .ant-select {
      cursor: not-allowed;
      pointer-events: none;
    }

    & ${Placeholder} {
      color: ${(props) => props.theme.colors.neutralDark};
    }

    & .ant-select-arrow {
      color: ${(props) => props.theme.colors.neutralDark};
    }

    & .ant-select-selection-selected-value {
      color: ${(props) => props.theme.colors.neutralDark};
    }
  }

  &.error:not(.open) {
    & .ant-select {
      border: 2px solid ${(props) => props.theme.colors.danger};
    }

    & ${Placeholder}, & ${HelpPlaceholder}, & .ant-select-arrow {
      color: ${(props) => props.theme.colors.danger};
    }

    & .ant-select-selection-selected-value {
      color: ${(props) => props.theme.colors.gray};
    }
  }

  &.small {
    & .ant-select {
      height: 100%;
    }

    & .ant-select-selection__rendered {
      display: flex;
      align-items: center;
      padding-top: 0;
    }

    & .ant-select-search--inline {
      top: 3px;
    }

    & .ant-select-selection__placeholder {
      top: 9px;
    }

    & ${Placeholder} {
      top: 50%;
      transform: translateY(-50%);
    }

    & ${MultipleLabel} {
      top: 50%;
      transform: translateY(-50%);
    }

    &.open,
    &.selected {
      & ${Placeholder} {
        display: none;
      }
    }
  }
`;

export const Styled = {
  StyledSelect,
  Placeholder,
  HelpPlaceholder,
  MultipleLabel,
};
