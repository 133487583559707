import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { Styled } from './PrepareScrollableInfoCard.styled';
import { CloseIcon } from 'UI/custom/icons/actionIcons/CloseIcon';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { TextIcon } from 'UI/custom/icons/actionIcons/TextIcon';
import { ChatIcon } from 'UI/custom/icons/actionIcons/ChatIcon';
import ToggleButtonGroup from 'UI/common/components/ToggleButtonGroup/ToggleButtonGroup';
import BrigadeApplicationsChat from './BrigadeApplicationsSelection/BrigadeApplicationsChat/BrigadeApplicationsChat';
import BrigadeApplicationsInfo from './BrigadeApplicationsSelection/BrigadeApplicationsInfo/BrigadeApplicationsInfo';
import _ from 'underscore';
import { modeEnum, ToggleButtonI } from 'UI/common/components/ToggleButtonGroup/model/ToggleButtonGroup.model';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';
import { IApplicationImage } from './BrigadeApplicationsSelection/BrigadeApplicationsSelection.model';

const { LocalHeaderWrapper, ExtendedBtnClose, LocalHeaderTitle, HeaderTop, HeaderButtonWrap, FooterButtonWrap } =
  Styled;

export interface BrigadeApplicationsCardI {
  handleCloseCard: () => void;
  title?: string;
  openInNewTabBtnDesc?: string;
  listOfDescriptions?: Array<IDescriptions>;
  handleOpenItemInDirectory: () => void;
  fileUploaderComponent: JSX.Element;
  handleEditObject: () => void;
  images: Array<IApplicationImage>;
  application_id?: number;
}

export interface IWsMessage {
  message: string;
  message_type: string;
  datetime: string;
  user_data?: {
    first_name: string;
    is_dispatcher: boolean;
    last_name: string;
    middle_name: string;
    username: string;
  };
  media?: {
    image: string;
  };
}

const BrigadeApplicationsCard = ({
  handleCloseCard,
  title,
  openInNewTabBtnDesc,
  listOfDescriptions,
  handleOpenItemInDirectory,
  fileUploaderComponent,
  handleEditObject,
  images,
  application_id,
}: BrigadeApplicationsCardI) => {
  const [mode, setMode] = useState<modeEnum>(modeEnum.info);
  const [wsChannel, setWsChannel] = useState<WebSocket>();
  const [messages, setMessages] = useState<IWsMessage[]>([]);
  const [disabledButtons, setDisabledButtons] = useState<boolean>(true);
  const [countNewMessage, setCountNewMessage] = useState<number>(0);

  const wsChannelUrl = `${process.env.REACT_APP_CHAT_URL}/${application_id}/`;

  const token_access: string = localStorage.getItem('token_access')!;
  const countOldMessage: number = +(localStorage.getItem(wsChannelUrl) ?? 0);

  useEffect(() => {
    wsChannel?.addEventListener('open', () => setDisabledButtons(false));
    const messageHandler = (e: MessageEvent) => {
      const newMessages = JSON.parse(e.data);
      if (_.isArray(newMessages)) {
        setMessages((prevMessages) => [...prevMessages, ...newMessages.reverse()]);
      } else {
        setMessages([newMessages]);
      }
      setCountNewMessage(newMessages.length - countOldMessage ?? 0);
    };
    wsChannel?.addEventListener('message', messageHandler);

    return () => {
      wsChannel?.removeEventListener('message', messageHandler);
    };
  }, [countOldMessage, wsChannel]);

  useEffect(() => {
    let ws: WebSocket;
    const closeHandler = () => {
      console.log('CLOSE WS');
      setDisabledButtons(true);
      setTimeout(createChannel, 5000);
    };
    const createChannel = () => {
      ws?.removeEventListener('close', closeHandler);
      ws?.close();
      ws = new WebSocket(`${wsChannelUrl}${token_access}`);
      ws?.addEventListener('close', closeHandler);
      setWsChannel(ws);
    };
    createChannel();
    return () => {
      ws?.removeEventListener('close', closeHandler);
      ws?.close();
    };
  }, [token_access, wsChannelUrl]);

  const headerStyles = useMemo(() => ({ padding: '14px 16px 16px', position: 'relative' }), []);
  const contentStyles = useMemo(() => ({ padding: '16px 10px 16px 16px' }), []);
  const footerStyles = useMemo(() => ({ padding: '16px' }), []);

  const SWITCHER_BUTTONS_TEMPLATE: ToggleButtonI[] = useMemo(
    () => [
      {
        id: 1,
        icon: <TextIcon key={1} />,
        name: 'О заявке',
        switcher: modeEnum.info,
      },
      {
        id: 2,
        icon: <ChatIcon key={2} />,
        name: 'Чат',
        switcher: modeEnum.chat,
      },
    ],
    []
  );

  const switchTab = useCallback(
    (element: ToggleButtonI) => {
      const { switcher } = element;
      setCountNewMessage(0);
      localStorage.setItem(wsChannelUrl, `${messages.length}`);
      setMode(switcher);
    },
    [messages, wsChannelUrl]
  );

  const headerContent = useMemo(
    () => (
      <LocalHeaderWrapper>
        <HeaderTop>
          <LocalHeaderTitle>{title}</LocalHeaderTitle>
          <ExtendedBtnClose onClick={handleCloseCard} icon={<CloseIcon />} type={ButtonType.unboundedPrimary} p={0} />
        </HeaderTop>
        <HeaderButtonWrap>
          <ToggleButtonGroup
            buttonClickHandler={switchTab}
            activePanel={mode}
            displayedButtons={SWITCHER_BUTTONS_TEMPLATE}
            countNewMessage={countNewMessage}
          />
        </HeaderButtonWrap>
      </LocalHeaderWrapper>
    ),
    [SWITCHER_BUTTONS_TEMPLATE, handleCloseCard, countNewMessage, mode, switchTab, title]
  );

  const footerContent = useMemo(
    () => (
      <FooterButtonWrap>
        <Button onClick={handleEditObject} type={ButtonType.primaryLC}>
          Редактировать
        </Button>
        <Button onClick={handleOpenItemInDirectory} type={ButtonType.primaryReverse}>
          {openInNewTabBtnDesc}
        </Button>
      </FooterButtonWrap>
    ),
    [handleEditObject, handleOpenItemInDirectory, openInNewTabBtnDesc]
  );

  const infoContent = useMemo(
    () => (
      <BrigadeApplicationsInfo
        headerStyles={headerStyles}
        headerContent={headerContent}
        contentStyles={contentStyles}
        footerStyles={footerStyles}
        footerContent={footerContent}
        images={images}
        fileUploaderComponent={fileUploaderComponent}
        listOfDescriptions={listOfDescriptions}
      />
    ),
    [
      contentStyles,
      fileUploaderComponent,
      footerContent,
      footerStyles,
      headerContent,
      headerStyles,
      images,
      listOfDescriptions,
    ]
  );

  const chatContent = useMemo(
    () => (
      <BrigadeApplicationsChat
        headerStyles={headerStyles}
        headerContent={headerContent}
        contentStyles={contentStyles}
        footerStyles={footerStyles}
        wsChannel={wsChannel}
        messages={messages}
        setMessages={setMessages}
        disabledButtons={disabledButtons}
        wsChannelUrl={wsChannelUrl}
        application_id={application_id}
      />
    ),
    [
      application_id,
      contentStyles,
      disabledButtons,
      footerStyles,
      headerContent,
      headerStyles,
      messages,
      wsChannel,
      wsChannelUrl,
    ]
  );

  return mode === modeEnum.info ? infoContent : chatContent;
};

export default BrigadeApplicationsCard;
