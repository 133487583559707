import React, { PropsWithChildren, useCallback } from 'react';
import { FormContext, useForm } from 'react-hook-form';

import { IApplicationFormProps } from '../interfacesCargoTransport/interfacesCargoTransport';
import { getCustomizableModelAndData } from '../methods/getCustomizableModelAndData';
import { preparePanelGroups } from '../utils/preparePanelGroups';
import { prepareSinglePanels } from '../utils/prepareSinglePanels';
import { Styled } from './ApplicationForm.styled';
import _ from 'underscore';
import { useDispatch } from 'react-redux';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';

const { ExtendedApplicationteForm, LocalTitle, LocalPanelsWrapper } = Styled;

const ApplicationForm = React.forwardRef((props: PropsWithChildren<IApplicationFormProps>, ref: any) => {
  const dispatch = useDispatch();
  const { params, model, data, handleSubmitForm, additionalRenders, recommendedHarmCalc, responsiblePersonsData } =
    props;
  const methods = useForm();
  const values = methods.getValues();

  getCustomizableModelAndData(model, data, params, values, recommendedHarmCalc, responsiblePersonsData);

  if (!_.isEmpty(methods.errors)) {
    dispatch(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message: 'Заполните все обязательные поля!',
      })
    );
    setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
  } else if (_.isEmpty(methods.errors)) {
    dispatch(notificationActions.clearGlobalAlertData());
  }

  let panelsRender;
  if (model && model?.groups?.length) {
    const groups = model?.groups;
    panelsRender = preparePanelGroups({ formProps: props, groups, data, model });
  } else if (model) {
    panelsRender = prepareSinglePanels({ formProps: props, data, model });
  }

  const onFinish = useCallback(() => {
    methods.triggerValidation().then((isDataValidation) => isDataValidation && handleSubmitForm(methods.getValues()));
  }, [handleSubmitForm, methods]);

  return (
    <div>
      <FormContext {...methods}>
        <ExtendedApplicationteForm ref={ref} onFinish={onFinish}>
          <LocalTitle>Основные данные</LocalTitle>
          <LocalPanelsWrapper>{panelsRender}</LocalPanelsWrapper>
        </ExtendedApplicationteForm>
        {additionalRenders}
      </FormContext>
    </div>
  );
});

export default ApplicationForm;
