export enum EDirectoryFolders {
  administration = 'Администрация',
  roadGeoParams = 'Геометрические параметры участка дороги',
  transport = 'Общественный транспорт',
  general = 'Общие',
  roads = 'Дороги',
  states = 'Cостояния',
  artificial_structures = 'Искусственные сооружения',
  filters = 'Фильтры',
  roadAccidents = 'Дорожные происшествия',
  roadsConstruction = 'Строительство дорог',
  roadsQuality = 'Диагностика УДС',
  socialFacilities = 'Социальные объекты',
  ekbElectro = 'Электро ЕКБ',
  transportDetectors = 'Камеры/Детекторы',
  markup = 'Разметка',
  trafficManagementProjects = 'ПОДД',
}
