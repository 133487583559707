import { BooleanLayerSetting } from './prototype/types/BooleanLayerSetting';
import { Settings } from 'types/enums/map/layers/Settings';

export class SwitchCluster extends BooleanLayerSetting {
  constructor() {
    super({
      label: 'Группировка активна',
      keyForDeck: Settings.switchCluster,
      currentValue: true,
    });
  }
}
