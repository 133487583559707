import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const CityDistrictsSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  // TODO Раскоментировать код когда появиться справочник районов Тех-долг-FRONTEND #5794
  // const handleOpenObjectInNewTab = () => {
  //   const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'road_signs');
  //   const { id } = roadData.data;
  //   const win = window.open(`/app/directory/${DirectoryRouteModel.road_constructions_road_signs}/${id}/`, '_blank');
  //   win.focus();
  // };

  let Descriptions = [];

  Descriptions.push({
    key: 'Наименование',
    value: data?.name,
  });

  Descriptions.push({
    key: 'Периметр, km',
    value: data?.perimeter?.toFixed(3),
  });

  Descriptions.push({
    key: 'Площадь, km2',
    value: data?.area?.toFixed(3),
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о районе'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      // handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

CityDistrictsSelection.propTypes = {};

export default CityDistrictsSelection;
