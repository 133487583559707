import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RoadConstructionsService } from '../../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class SpeedBumpProject extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'speed_bump_project',
      filterLabel: 'Лежачий полицейский',
      selectedObjectName: MapObjects.projectLayerSpeedBump,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsSpeedBumpProjectList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsSpeedBumpProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsSpeedBumpProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.speedBumpProjection);
  }
}
