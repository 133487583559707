import styled from 'styled-components/macro';
import { Styled as styleMixins } from '../../../../../styles/styleMixins/styleMixins.styled';

const { AddMarginsProps, AddPaddingsProps } = styleMixins.offset;

const LocalDescription = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.fontSettings.default};
  color: ${(props) => props.theme.colors.primary};
  font-weight: normal;
  margin: 0;
`;

const LocalMainWrapper = styled.div`
  padding: ${(props) => props.theme.paddings.smallPadding};
  ${(props) => AddPaddingsProps(props)};
  ${(props) => AddMarginsProps(props)};
  background: ${(props) => props.theme.colors.defaultLighter};
  box-shadow: ${(props) => props.theme.decoration.darkBoxShadow};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  width: auto;
  height: 40px;
  display: flex;
`;

const LocalExtensionFunctional = styled.div`
  display: flex;
  margin: 0 0 0 30px;
`;

const LocalIcon = styled.div`
  padding: 2px 8px 0 0;
`;

export const Styled = {
  LocalMainWrapper,
  LocalIcon,
  LocalDescription,
  LocalExtensionFunctional,
};
