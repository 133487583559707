import { cropPath } from 'services/net/cropPath';
import { AdministrationRegistratorObjectProps } from './AdministrationRegistratorObjectProps';

import { registeredAdministrationUrls } from './administratonPageRegistrator';

/***
 *  function resolves prototype from registered urls or return default prototype
 * @param url - searched url to resolve prototype
 * @param field - searched field to resolve prototype
 * @param urlField - switch if look up for path from not url, by default = 'url'
 * @param onlyExact
 * @returns {null|React.Element}  (not found prototype) | (found prototype)
 */

export const resolver = (
  url: string,
  field?: keyof AdministrationRegistratorObjectProps,
  urlField: keyof AdministrationRegistratorObjectProps = 'url',
  onlyExact: boolean = false
) => {
  if (!url) throw new Error(`${url} is empty for field ${field}`);
  let result = registeredAdministrationUrls.filter((el) => url.includes(cropPath(el[urlField]?.toString())));
  const exact = result.filter((el) => el[urlField]?.toString().toLowerCase() === url);
  if (exact && exact.length) {
    result = exact;
  } else if (onlyExact) {
    return null;
  }

  if (field && result && result[0] && result[0][field]) {
    return result[0][field];
  } else if (field) {
    return null;
  }
  return null;
};
