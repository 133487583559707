import styled from 'styled-components/macro';
import { Slider } from 'antd';
import { Styled as styledMixins } from 'styles/styleMixins/styleMixins.styled';
import { AnyObject } from 'types/enums/general/general.model';

const { CutStringWithEllipsisOnTheEnd } = styledMixins.text;

const SliderWrapper = styled.div`
  padding: ${(props: AnyObject) => (props.p ? props.p : props.theme.paddings.smallPadding)};
  background: ${(props) => props.theme.colors.defaultLight};
  font-size: ${(props) => props.theme.fontSettings.default};
  width: ${(props: AnyObject) => (props.width ? props.width : 'auto')};
  //border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  border-radius: 8px;
  height: 60px;
  margin-bottom: 24px;
  width: 100%;
`;

const SliderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SliderLabel = styled.span`
  width: 75%;
  text-align: left;

  &:first-letter {
    text-transform: uppercase;
  }

  ${CutStringWithEllipsisOnTheEnd}
`;

const SliderInfoWindow = styled.div`
  background: ${(props) => props.theme.colors.transparent};
  border-radius: 8px;

  color: ${(props) => props.theme.colors.primary};
  width: 20%;
  position: absolute;
  right: 5px;
  height: 40px;
  top: 10px;

  & img {
    position: absolute;
    left: 32%;
    bottom: 0;
    width: 25px;
    top: 10%;
  }
`;

const ExtendedAntdSlider = styled(Slider)`
  margin: 3px;
  width: 70%;
  position: absolute;
  top: 45%;

  & .ant-slider-rail {
    background: ${(props) => props.theme.colors.neutralDark};
  }

  & .ant-slider-track {
    background: ${(props) => props.theme.colors.primary};
  }

  & .ant-slider-handle {
    background: ${(props) => props.theme.colors.primary};
    width: 6px;
    height: 16px;
    border: none;
    box-shadow: none;
    //border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
    border-radius: 8px;
  }

  &:hover .ant-slider-track,
  .ant-slider-handle {
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const Styled = {
  ExtendedAntdSlider,
  SliderWrapper,
  SliderLabel,
  SliderInner,
  SliderInfoWindow,
};
