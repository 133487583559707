import turfCenterOfMass from '@turf/center-of-mass';
import { calculateLinePathCenterCoords } from 'services/map/calculateLinePathCenterCoords';
import { getCoordsFromParsedGeometry } from 'services/map/getCoordsFromParsedGeometry';
import { getOpacityForItems } from 'services/map/getOpacityForItems';
import { MapMarker } from 'store/reducers/map/mapMarkers/mapMarkers.model';
// @ts-ignore
import wkt from 'wkt';

interface WithPolygon {
  id?: number;
  polygon?: string;
}

interface WithLinePath {
  id?: number;
  line_path?: string;
}

interface WithPoint {
  id?: number;
  point?: string;
}

interface WithLocation {
  id?: number;
  location?: string;
}

interface WithPickableIconPolygon {
  // Тип проверяемого выбранного объекта
  typeCheck: string;
  // ID проверяемого выбранного объекта
  id?: number;
  // стейт слоя, содержащий выбранный объект
  layerState: any;
  // Маппинг иконки выбранного объекта
  selectedMapper: string;
  // Маппинг иконки невыбранного объекта
  deselectedMapper: string;
}

interface WithSelectedOpacity {
  // ID проверяемого выбранного объекта
  id?: number;
  // Выбранный на карте объект
  selectedObject?: any;
  // Тип проверяемого выбранного объекта
  itemType: string;
  // Базовый ргб цвет
  basicColor: number[] | [number, number, number];
}

interface WithColorsChoise {
  // Тип проверяемого выбранного объекта
  typeCheck: string;
  // стейт слоя, содержащий выбранный объект
  layerState: any;
  // Цвет выбранного объекта
  selectedColor: any;
  // Цвет невыбранного объекта
  deselectedColor: any;
  // ID проверяемого выбранного объекта
  id?: number;
}
const indexPolygonOffset = 10000;

export class DSelector {
  /**
   * Получение полигона
   */
  static getDPolygon(data: WithPolygon) {
    if (data.polygon) {
      return wkt.parse(data.polygon)?.coordinates?.[0] ?? [];
    } else {
      return [];
    }
  }
  /**
   * Получение иконки с вариантом для выбранного объекта
   */
  static getSelectableIcon(args: WithPickableIconPolygon) {
    return args.layerState?.selectedObject?.type === args.typeCheck &&
      (args.id === args.layerState?.selectedObject?.selectedObject?.object?.id ||
        args.id === args.layerState?.selectedObject?.selectedObject?.id)
      ? args.selectedMapper
      : args.deselectedMapper;
  }

  /**
   * Получение точки центра полигона
   */
  static getCenterOfPolygon(data: WithPolygon) {
    if (data.polygon) {
      const parsed = wkt.parse(data.polygon);
      const center = turfCenterOfMass(parsed);
      return center.geometry.coordinates as [number, number];
    } else return [0, 0] as [number, number];
  }

  /**
   * Получение точки центра линии
   */
  static getCenterOfLinePath(data: WithLinePath) {
    if (data.line_path) {
      const result = calculateLinePathCenterCoords(data.line_path);
      if (result) return result;
      return [0, 0] as [number, number];
    } else return [0, 0] as [number, number];
  }

  /**
   * Получение геометрии линии
   */
  static getLinePath(data: WithLinePath) {
    if (data.line_path) {
      return getCoordsFromParsedGeometry(data.line_path) ?? [[0, 0]];
    }
    return [[0, 0]];
  }

  /**
   * Получение точки из лайнстринга. Ошибка бэка в структуре
   * @deprecated
   */
  static getPointFromLinePath(data: WithLinePath) {
    if (data.line_path) {
      return getCoordsFromParsedGeometry(data.line_path) as [number, number];
    } else return [0, 0] as [number, number];
  }

  /**
   * Получение геометрии точки
   */
  static getPositionFromPoint(data: WithPoint) {
    if (data.point) {
      return getCoordsFromParsedGeometry(data.point) as [number, number];
    } else return [0, 0] as [number, number];
  }

  /**
   * Получение геометрии точки из пропса location
   */
  static getPositionFromLocation(data: WithLocation) {
    if (data.location) {
      return getCoordsFromParsedGeometry(data.location) as [number, number];
    } else return [0, 0] as [number, number];
  }

  /**
   * Получения стандартного сдвига композитного слоя
   */
  static getDefaultPolygonOffset({ layerIndex }: any) {
    const defaultPolygonOffset = 10000;
    return [0, -layerIndex * defaultPolygonOffset] as [number, number];
  }

  /**
   * Просчет цвета с прозрачностью для с возможностью выбора объекта
   */
  static getColorWithOpacity(args: WithSelectedOpacity) {
    const opacity = getOpacityForItems(args.id, args.selectedObject, args.itemType);
    return [...args.basicColor, opacity] as any;
  }

  /**
   * Получение цвета линии или полигона с возможностью выбора объекта
   * @param args
   */
  static getColorWithSelected(args: WithColorsChoise) {
    const isObjectTypeCorrect = args.layerState?.selectedObject?.type?.includes(args.typeCheck);
    const isObjectAdditionalTypeCorrect =
      args.layerState?.selectedObjectType?.includes(args.typeCheck) ||
      args.layerState?.selectedObject.type?.includes(args.typeCheck);
    const isIdsEqual =
      args?.id === args?.layerState?.selectedObject?.id ||
      args?.id === args?.layerState?.selectedObject?.selectedObject?.object?.id;
    return (isObjectTypeCorrect || isObjectAdditionalTypeCorrect) && isIdsEqual
      ? args.selectedColor
      : args.deselectedColor;
  }

  /**
   * Получения точки начального положения маркера
   * @param marker
   * @see MapMarker
   */
  static getPointFromMarker(marker: MapMarker) {
    return marker.clickInfo?.coordinate;
  }

  static getNewPolygonOffset({ layerIndex }: { layerIndex: number }): [number, number] {
    return [0, -layerIndex * indexPolygonOffset];
  }

  /**
   * Получения точки конечного положения маркера
   * @param marker
   * @see MapMarker
   */
  static getPointFromMovedMarker(marker: MapMarker) {
    if (!marker?.markerPosition) {
      return DSelector.getPointFromMarker(marker);
    }
    return marker.markerPosition;
  }

  /**
   * Получения линии сдвига маркера
   * @param marker
   * @see MapMarker
   */
  static getLineToMarkerPosition(marker: MapMarker) {
    return [DSelector.getPointFromMovedMarker(marker), DSelector.getPointFromMarker(marker)];
  }

  /**
   * Получения строки POINT
   * @param points
   */
  static getPointString(points: [number, number]) {
    const [coordinatesX, coordinatesY] = points;
    return `SRID=4326;POINT (${coordinatesX} ${coordinatesY})`;
  }

  /**
   * Получения строки LINESTRING
   * @param linePoints
   */
  static getLineString(linePoints: [number, number][]) {
    let linePointsStr = '';
    if (linePoints?.length) {
      linePoints.forEach((point: [number, number], index: number) => {
        index === linePoints.length - 1
          ? (linePointsStr += `${point[0]} ${point[1]})`)
          : (linePointsStr += `${point[0]} ${point[1]}, `);
      });
    }
    return `SRID=4326;LINESTRING (${linePointsStr}`;
  }

  /**
   * Получения строки POLYGON
   * @param polygonPoints
   */
  static getPolygonString(polygonPoints: [number, number][]) {
    let polygonPointsStr = '';
    if (polygonPoints?.length) {
      polygonPoints.forEach((point: [number, number], index: number) => {
        index === polygonPoints.length - 1
          ? (polygonPointsStr += `${point[0]} ${point[1]}))`)
          : (polygonPointsStr += `${point[0]} ${point[1]}, `);
      });
    }
    return `SRID=4326;POLYGON ((${polygonPointsStr}`;
  }
}
