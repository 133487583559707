import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { SpecialPermitsService } from '../../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class DimensionsHeightOversizes extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'dimensions_height_oversizes',
      filterLabel: 'Превышения по высоте по маршруту',
      selectedObjectName: MapObjects.dimensionsHeightOversizes,
    });
  }

  async uploadData() {
    return await SpecialPermitsService.specialPermitsIndividualStatementsDimensionsHeightOversizesList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async readRecord(id) {
    return await SpecialPermitsService.specialPermitsIndividualStatementsDimensionsHeightOversizesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.permitsIndividualStatementHeightOversizes);
  }
}
