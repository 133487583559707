import React from 'react';
import { LayersGalleryHeader } from './Header/LayersGalleryHeader';
import { LayersGalleryContent } from './Content/LayersGalleryContent';
import { groupOfLayersItem } from './Types/LayersGalleryTypes';

interface ILayersGallery {
  sortedGroupOfLayersGalleryItemsWithoutSelected: groupOfLayersItem[];
}

const LayersGallery = ({ sortedGroupOfLayersGalleryItemsWithoutSelected }: ILayersGallery) => {
  return (
    <>
      <LayersGalleryHeader />
      <LayersGalleryContent
        sortedGroupOfLayersGalleryItemsWithoutSelected={sortedGroupOfLayersGalleryItemsWithoutSelected}
      />
    </>
  );
};

export default LayersGallery;
