import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { layerSettings } from '../description/settings';
import TramsSchemeItemSelection from '../../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/TramsSchemeItemSelection';
import { layerDataAccessors } from '../description/dataAccessors';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class TramsSchemaLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.tramsSchema,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.TramsDataAccessor(), //провалиться в датааксцессор
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.Lines_tram(),
        new relatedLayerData.ekbRelatedDataForLayers.Cables_tram(),
        new relatedLayerData.ekbRelatedDataForLayers.Supply_cables_tram(),
      ],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.TramsSchemaCard(),
      customSettings: [
        new layerSettings.OpacitySetting(),
        new layerSettings.IconSizeScale(),
        new layerSettings.ColorSetting(),
      ],
      layerSchema: new layerSchemas.ekbLayersSchema.TramsSchemaLayerSchema(),
      // templates: new layerTemplates.TramsSchemaLayerTemplates(), // вроде не используются на бэке
      selectedObjectPrototype: TramsSchemeItemSelection,
    });
  }
}
