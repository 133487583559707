import { DataAccessMethod } from '../../../../../../types/enums/routes/request/DataAccessMethod.model';
import { basePath } from '../../../layersRegistrator';

interface IMainDataHttpPrototypeConstructor {
  dataAccessMethod: DataAccessMethod;
  dataKeyForIds: string;
  urlData: string | null;
  additionalParameters?: any;
}

export class MainDataHttpPrototype {
  basePathTemplate: string = basePath;
  dataAccessMethod = DataAccessMethod.httpGetAll;
  dataKeyForIds: string;
  urlData: string | null = null;
  additionalParameters?: any;

  constructor({
    urlData,
    dataKeyForIds = 'id',
    dataAccessMethod = DataAccessMethod.httpGetAll,
    ...other
  }: IMainDataHttpPrototypeConstructor) {
    this.urlData = this.basePathTemplate + urlData;
    this.dataKeyForIds = dataKeyForIds;
    this.dataAccessMethod = dataAccessMethod;
    this.additionalParameters = other;
  }

  getAdditionalParameters() {
    return this.additionalParameters;
  }

  getDataAccessMethod() {
    return this.dataAccessMethod;
  }

  getDataKeyForIds() {
    return this.dataKeyForIds;
  }

  getUrlData() {
    return this.urlData;
  }
}
