import { groupTypes } from '../../contentGroups';
import { ObjectsPrototype } from './ObjectsPrototype';
import { initializeSingleObjectEditMode } from '../../../../../store/sagas/map/standaloneObjectEditableSaga';
import { DataConcerningIcon } from '../../../../../UI/custom/icons/contextMenuIcons/DataConcerningIcon';
import { DeleteIcon } from '../../../../../UI/custom/icons/actionIcons/DeleteIcon';
import { getRegisteredLayer } from '../../../layers/layersRegistrator';
import { mapboxDataActions } from '../../../../../store/reducers/map/mapboxData/mapboxData.actions';
const { ACTION_GROUP, EDIT_GROUP } = groupTypes;

/**
 * Используется в: создание объектов
 * Прототип контекстного меню для выбранного объекта
 */

export class CommonSelectedItemContextMenuPrototype extends ObjectsPrototype {
  layerName = null;
  selectedObject = null;

  constructor(selectedObject, name, layerName) {
    super(name);
    this.layerName = layerName;
    this.selectedObject = selectedObject;
  }

  /**
   * Действия которые появятся в контекстном меню по умолчанию для объектов - редактирование, открытие в справочнике, удаление
   */
  actionItems = [
    {
      path: 'Данные',
      group: EDIT_GROUP,
      comment: 'Данные',
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          this.name,
          this.layerName,
          this.initialCoordinate,
          EDIT_GROUP
        ),
      icon: <DataConcerningIcon />,
    },
    /*   {
      path: 'Открыть в справочнике',
      group: ACTION_GROUP,
      comment: 'Переход в справочник',
      icon: <FolderIcon />,
      dispatchedAction: {
        type: 'TEST_ACTION',
      },
    },*/

    // Это условия для знаков в кластере
    {
      path: 'Удалить',
      group: ACTION_GROUP,
      icon: <DeleteIcon />,
      comment: 'Удаление выбранного объекта',
      dispatchedAction: () => {
        let id;
        if (this.selectedObject?.properties) {
          id = this.selectedObject?.properties.id;
        } else {
          id = this.selectedObject.id;
        }
        const relatedData = getRegisteredLayer(this.layerName).getRelatedData();
        const RelatedDataObject = relatedData.find((el) => el.name.includes(this.name));
        RelatedDataObject.deleteRecord(id);
        return mapboxDataActions.deleteRelatedDataObjectByKey(RelatedDataObject.selectedObjectName, this.layerName, id);
      },
    },
  ];
}
