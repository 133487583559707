import { Layers } from 'types/enums/map/layers/Layers';
import { CURRENT_APP_URL, CURRENT_APP_WS } from 'types/constants/constants';
import { applicationLayers } from './index';
import { LayerPrototype } from './description/prototype/LayerPrototype';

export const basePath = CURRENT_APP_URL;
export const baseWSPath = CURRENT_APP_WS;

/**
 * используется в: создание слоёв
 * Регистратор слоёв. Задаёт схему для слоя.
 */

export const registeredLayersUrls = {
  [Layers.roadAccidents]: new applicationLayers.ekbLayers.RoadAccidentsLayer(),
  [Layers.brigadeApplications]: new applicationLayers.ekbLayers.BrigadeApplicationsLayer(),
  [Layers.camerasUdc]: new applicationLayers.ekbLayers.CamerasUdcLayer(),
  [Layers.roadsSchema]: new applicationLayers.ekbLayers.RoadSchemaLayer(),
  [Layers.transportOrders]: new applicationLayers.ekbLayers.TransportOrdersLayer(),
  [Layers.publicTransport]: new applicationLayers.ekbLayers.PublicTransportLayer(),
  [Layers.trolleybusTransport]: new applicationLayers.ekbLayers.TrolleybusLayer(),
  [Layers.tramsSchema]: new applicationLayers.ekbLayers.TramsSchemaLayer(),
  [Layers.substationsLayer]: new applicationLayers.ekbLayers.ElectricalSubstationsLayer(),
  [Layers.trafficLightObject]: new applicationLayers.ekbLayers.TrafficLightObjectLayer(),
  [Layers.camerasAndDetectors]: new applicationLayers.ekbLayers.CamerasAndDetectorsLayer(),
  [Layers.roadSpeedBumps]: new applicationLayers.ekbLayers.RoadSpeedBumpsLayer(),
  [Layers.illuminatedPedestrianCrossings]: new applicationLayers.ekbLayers.IlluminatedPedestrianCrossingsLayer(),
  [Layers.citydistricts]: new applicationLayers.ekbLayers.CityDistrictsSchemaLayer(),
  [Layers.trafficManagementProjects]: new applicationLayers.ekbLayers.TrafficManagementProjectsLayer(),
  [Layers.projectLayer]: new applicationLayers.ekbLayers.ProjectLayer(),
  [Layers.monitoringUds]: new applicationLayers.ekbLayers.MonitoringUDSLayer(),
};

export const getRegisteredLayer = (name: keyof typeof registeredLayersUrls): LayerPrototype => {
  return registeredLayersUrls[name];
};

export const getAllRegisteredLayers = () => {
  return registeredLayersUrls;
};
