import React from 'react';

import {
  IFilterData,
  LayerDataAccessorPrototype,
} from 'registrators/map/layers/description/dataAccessors/prototype/LayerDataAccessorPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { functionCellStyle } from './utils/dataAccessorCallbacks';

const BrigadeApplicationsSwitcher = React.lazy(
  () => import('../../customButtons/BrigadeApplicationsButton/BrigadeApplicationsSwitcher.lazy')
);

/**
 * Класс для грида слоя "Заявки и бригады". Все загрузки данных/можели происходят в свитчере.
 */
export class BrigadeApplicationsDataAccessor extends LayerDataAccessorPrototype {
  actionButtons = [BrigadeApplicationsSwitcher];

  constructor() {
    super({
      label: 'Заявки',
      selectedType: MapObjects.brigadeApplications,
    });

    BrigadeApplicationsDataAccessor.selectedType = MapObjects.brigadeApplications;
    BrigadeApplicationsDataAccessor.filterData = null;
  }

  changeSelectedTypeForGettingDataFromSwitcher() {
    if (!BrigadeApplicationsDataAccessor.selectedType) {
      BrigadeApplicationsDataAccessor.replaceSelectedType(MapObjects.brigadeApplications);
    }
  }

  static getSelectedType() {
    return BrigadeApplicationsDataAccessor.selectedType;
  }

  static replaceSelectedType(type: string | null) {
    BrigadeApplicationsDataAccessor.selectedType = type;
  }

  static replaceFilter(type: IFilterData | null) {
    BrigadeApplicationsDataAccessor.filterData = type;
  }

  getSelectedObjectType() {
    return BrigadeApplicationsDataAccessor.selectedType;
  }

  getAccessorCallbacks() {
    return {
      functionCellStyle,
    };
  }

  get filterData() {
    return BrigadeApplicationsDataAccessor.filterData;
  }
}
