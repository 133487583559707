import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, message, Modal } from 'antd';
import { Styled } from './ModalSearchAddress.styled';
import { ResponseSuggestAddress, RoadsService } from '../../../../../generated/api/api';
import { goToMapCoords } from '../../../../../store/reducers/map/actions/mapboxActions';
import { useDispatch } from 'react-redux';
// @ts-ignore
import * as wkt from 'wkt';
import { notificationActions } from '../../../../../store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../../../types/enums/UI/ActiveStatus.model';
import { PromptType } from '../../../../../types/enums/UI/PromptType.model';

const { Search } = Input;

const { HintsContainer, Hint } = Styled;

interface IProps {
  showQuickFilter: boolean;
  toggleShowQuickFilter: () => void;
}

interface ResponseGeocoder {
  point?: string;
  detail?: string;
}

const ModalSearchAdress = ({ showQuickFilter, toggleShowQuickFilter }: IProps) => {
  const [visible, setVisible] = useState(showQuickFilter);
  const [loader, setLoader] = useState(false);
  const [hints, setHints] = useState<ResponseSuggestAddress[]>([]);
  const [address, setAddress] = useState('');
  const [addressForHints, setAddressForHints] = useState('');
  const [visibleHints, setVisibleHints] = useState(false);
  const [clickedHint, setClickedHint] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setVisible(showQuickFilter);
  }, [showQuickFilter]);

  useEffect(() => {
    if (!clickedHint) {
      setLoader(true);
      setVisibleHints(true);
      const timeOutId = setTimeout(() => {
        if (addressForHints === '') {
          setLoader(false);
          setHints([]);
        } else {
          setAddress('');
          RoadsService.roadsSuggestAddressCreate({
            body: { address: addressForHints },
          }).then((res) => {
            setLoader(false);
            setHints(res);
          });
        }
      }, 2000);
      return () => clearTimeout(timeOutId);
    } else {
      return setClickedHint(false);
    }
    // TODO не могу добавить clickedHint в зависимость, иначе перерендер будет, а он не нужен, не знаю что с этим варнингом делать Тех-долг-FRONTEND #5558
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressForHints]);

  const handleCancel = () => {
    toggleShowQuickFilter();
    setVisibleHints(false);
  };

  const onSetAddress = useCallback((el: any) => {
    setClickedHint(true);
    setVisibleHints(false);
    const data = [el.data.block, el.data.city, el.data.street, el.data.house];
    const address = [];
    for (const el of data) if (el) address.push(el);
    setAddressForHints(address.join());
    setAddress(address.join());
  }, []);

  const handlePostAddress = async () => {
    try {
      if (address === '') {
        message.error('Выберите адрес из списка');
      } else {
        await RoadsService.roadsGeocoderCreate({
          body: {
            address: address,
          },
        }).then((res: ResponseGeocoder) => {
          if (res.detail) {
            dispatch(
              notificationActions.setGlobalAlertData({
                status: ActiveStatus.active,
                type: PromptType.error,
                title: 'Ошибка',
                message: res.detail,
              })
            );
            setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 5000);
          } else {
            const geometry = wkt.parse(res.point);
            const centerLatitude = geometry.coordinates[1];
            const centerLongitude = geometry.coordinates[0];
            toggleShowQuickFilter();
            dispatch(goToMapCoords(centerLatitude, centerLongitude));
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const hintsItems = useMemo(
    () => hints.map((el: any) => <Hint onClick={() => onSetAddress(el)}>{el.value}</Hint>),
    [hints, onSetAddress]
  );
  return (
    <>
      <Modal title="Поиск адреса" visible={visible} onCancel={handleCancel} footer={null} zIndex={5000} destroyOnClose>
        <Search
          style={{ borderRadius: '35px' }}
          onSearch={handlePostAddress}
          enterButton={'Перейти'}
          onChange={(e) => setAddressForHints(e.target.value)}
          placeholder="Введите адрес"
          loading={loader}
          value={addressForHints}
          onClick={() => setVisibleHints(true)}
        />
        {visibleHints ? <HintsContainer>{hintsItems}</HintsContainer> : null}
      </Modal>
    </>
  );
};

export default ModalSearchAdress;
