import { useState, useMemo, useCallback } from 'react';
import { Modal } from 'antd';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { iconSize } from '../../../interfacesApplications/templates';
import { Styled } from '../SemifinishedObjectForm.styled';
import { useDispatch } from 'react-redux';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { SemifinishedObjectsProps } from './model/SemifinishedObjects.model';
import { AnyObject } from 'types/enums/general/general.model';
import { ApplicationObject } from 'generated/api/api';
import { DownloadIcon } from 'UI/custom/icons/actionIcons/DownloadIcon';
import { ModalObjectContainer } from './ModalObjectContainer';

const { confirm } = Modal;

const { SemifinishedObjectsContainer, ApplicationObjectsContainer, ObjectTitle, DescriptionContainer } = Styled;

const DeleteStatementObject = (url: string, id: string, refreshData: () => void, dispatch: (param: any) => void) => {
  const performDeletion = () => {
    dispatch(deleteObject({ url, id }));
    refreshData();
  };
  confirm({
    title: 'Вы действительно желаете удалить объект?',
    icon: <QuestionCircleOutlined />,
    onOk() {
      performDeletion();
    },
  });
};

export const SemifinishedObjects = ({ data, refreshData, extraData }: SemifinishedObjectsProps) => {
  const dispatch = useDispatch();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [activeObject, setActiveObject] = useState<AnyObject>({});

  const clickOpenModalButton = useCallback((object: AnyObject) => {
    setActiveObject(object);
    setOpenModal(true);
  }, []);

  const SemifinishedObjectsList = useMemo(() => {
    return data?.map((object: ApplicationObject, index: number) => {
      const { id, object_class, object_name, additional_info } = object;
      const { url } = extraData?.find((data) => data.value === object_class) ?? {};
      const clianUrl = url?.replace('api/', '');

      return (
        clianUrl &&
        id && (
          <ApplicationObjectsContainer key={`SemifObjectContainerWithMapButton_${index}`}>
            <ObjectTitle>Описание объекта: {object_name}</ObjectTitle>
            <DescriptionContainer>{additional_info}</DescriptionContainer>
            <Button
              p={'none'}
              type={ButtonType.unbounded}
              icon={<DownloadIcon />}
              iconSize={iconSize}
              onClick={() => clickOpenModalButton({ ...object, url: clianUrl })}
              tooltip={'Открыть детализацию объекта'}
            />
            <Button
              p={'none'}
              type={ButtonType.unbounded}
              icon={<DeleteIcon />}
              iconSize={iconSize}
              onClick={() => DeleteStatementObject(clianUrl, `${id}`, refreshData, dispatch)}
            />
          </ApplicationObjectsContainer>
        )
      );
    });
  }, [clickOpenModalButton, data, dispatch, extraData, refreshData]);

  return (
    <SemifinishedObjectsContainer>
      {SemifinishedObjectsList}
      {isOpenModal && (
        <ModalObjectContainer
          isOpenModal={isOpenModal}
          setOpenModal={setOpenModal}
          object={activeObject}
          refreshData={refreshData}
        />
      )}
    </SemifinishedObjectsContainer>
  );
};
