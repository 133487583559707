import React from 'react';
import { Button } from '../../Button/Button';
import { MenuIcon } from '../../../../custom/icons/actionIcons/MenuIcon';
import { Styled } from './SidebarHeader.styled';
import _ from 'underscore';
import { theme } from '../../../../../styles/themes/rootTheme';

const { LocalMainWrapper, LocalLogoWrap } = Styled;

const SidebarHeader = ({ sidebarIsOpened, toggleSidebarPosition }) => {
  return (
    <LocalMainWrapper sidebarIsOpened={sidebarIsOpened} toggleSidebarPosition={toggleSidebarPosition}>
      <LocalLogoWrap className="sidebar-header__logo" sidebarIsOpened={sidebarIsOpened} onClick={toggleSidebarPosition}>
        {sidebarIsOpened ? <theme.logo.mini /> : <theme.logo.full />}
      </LocalLogoWrap>
      {!sidebarIsOpened && (
        <Button
          type={'unbounded'}
          p={0}
          key={_.uniqueId('btn')}
          style={{ display: 'block' }}
          icon={<MenuIcon />}
          iconColor={`${(props) => props.theme.colors.defaultDark}`}
          onClick={toggleSidebarPosition}
        />
      )}
    </LocalMainWrapper>
  );
};

export default SidebarHeader;
