import React from 'react';
import Icon from '@ant-design/icons';

const ArrowInDropDownSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z" fill="currentColor" />
  </svg>
);

export const ArrowToDown = (props) => <Icon component={ArrowInDropDownSVG} {...props} />;
