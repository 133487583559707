import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';
import { Select } from 'antd';

const LocalMainWrapper = styled.div`=
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  .active {
    background: ${(props) => props.theme.colors.primary};
  }
`;

const ExtendedButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectItem = styled(Select)`
  width: 250px;
`;

const ExtendedButton = styled(Button)`
  display: flex;
  justify-content: center;
  border-radius: 6px;
  align-items: center;
  margin-right: 8px;
  width: 100px;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  height: 100px;
  background-color: ${(props) => props.theme.colors.neutralDark};
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: white;
  }
  &:focus {
    background: ${(props) => props.theme.colors.primary};
    color: white;
  }
`;

const TitleInput = styled.h3`
  font-size: ${(props) => props.theme.fontSettings.defaultLarge};
`;

const ScrollableBoxAreaContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 98%;
  height: calc(100vh - 170px);
  margin: 80px 0 0 20px;
  background: white;
  border-radius: 15px;
`;

export const Styled = {
  ExtendedButtonWrapper,
  ExtendedButton,
  LocalMainWrapper,
  ScrollableBoxAreaContainer,
  ButtonGroup,
  SelectItem,
  TitleInput,
};
