import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import _ from 'underscore';
import { History } from 'history';

import { getNewModel } from '../../../../BaseModules/Map/MapDisplayMode/getNewModel';
import { CompendiumContainer } from './CompendiumWithButton.status';
import { IApplicationsContext, CargoTransportParams } from '../interfacesApplications/interfacesApplications';
import { ApplicationsContext } from '../../ApplicationsRouter';
import { ActionButtons } from '../ActionButtons/ActionButtons';
import { useAgGridControl } from '../../../../../../hooks/ag-grid/useAgGridControl';
import { HeaderScipBlock } from 'components/Application/Common/HeaderScipBlock/HeaderScipBlock';
import { BreadcrumbsControlled } from 'components/Application/Common/BreadcrumbsControlled/BreadcrumbsControlled';
import Compendium from 'UI/custom/Aggrid/Compendium/Compendium';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { CompendiumWithButtonProps } from './model/CompendiumWithButtonProps.model';
import { ContainerCallbacks } from 'UI/custom/Aggrid/Compendium/model/ContainerCallbacks.model';
import { emptyArr } from '../interfacesApplications/templates';
import { functionCellStyle } from 'registrators/map/layers/description/dataAccessors/ekb/BrigadeApplicationsDataAccessor/utils/dataAccessorCallbacks';
import { openFileInNewTab } from '../methods/openFileInNewTab';
/**
 * Компонент просмотра списка
 * @returns {JSX.Element}
 * @constructor
 */

export const CompendiumWithButton = React.memo((props: PropsWithChildren<CompendiumWithButtonProps>) => {
  const history = useHistory<History>();
  const location = useLocation();
  const { setPageMenuKey, setMainModel, setApplicationTasks } = useContext<IApplicationsContext>(ApplicationsContext);
  const { params } = useRouteMatch<CargoTransportParams>();
  const { directory, application } = params;

  const {
    data,
    model,
    showQuickFilter,
    gridCellSizes,
    agGridThemeName,
    namesCollectionForFiltering,
    buttons,
    selectIdAGGrid,
    isHeader,
    breadcrumbsLinks,
    pageMenuKey,
  } = props;

  const tableRenderingModel = useMemo(() => getNewModel(model, true), [model]);

  const {
    quickMenu,
    aggApi,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
    onFilterChanged,
    clearAgGridFilters,
  } = useAgGridControl();

  const handleProvideData = useCallback(
    (event) => {
      let urlLocation = '';
      const url: string | undefined = event?.data?.photo;
      if (pageMenuKey?.includes(ApplicationsDirectory.photos) && url) {
        openFileInNewTab(url);
        return;
      }
      if (pageMenuKey === ApplicationsDirectory.childrenTasks) {
        urlLocation = location.pathname.replace(`${application}`, event.data.id);
        setPageMenuKey(ApplicationsDirectory.main);
        setApplicationTasks((prevTasks: string[]) =>
          prevTasks.includes(`${application}`) ? prevTasks : [...prevTasks, `${application}`]
        );
      } else {
        let newPage = pageMenuKey?.replace('api', '').replace(/\//g, '');
        if (directory !== ApplicationsDirectory.tasks) {
          newPage = newPage?.replace('applications', '');
        }
        urlLocation = application
          ? `${location.pathname}${newPage}/${event.data.id}/`
          : `${location.pathname}${event.data.id}/`;
        if (model.name && !application) {
          setMainModel(model);
          setApplicationTasks(emptyArr);
        }
      }
      history.push(urlLocation);
    },
    [
      application,
      directory,
      history,
      location.pathname,
      model,
      pageMenuKey,
      setApplicationTasks,
      setMainModel,
      setPageMenuKey,
    ]
  );

  const handleSelectRow = useCallback(
    (event) => {
      selectIdAGGrid(event.data.id);
    },
    [selectIdAGGrid]
  );

  const clickBreadcrumbsControlled = useCallback(() => {
    setPageMenuKey(ApplicationsDirectory.main);
  }, [setPageMenuKey]);

  const containerCallbacks: ContainerCallbacks = useMemo(
    () => ({
      handleAGGInit,
      toggleShowFilterMenu,
      toggleShowRowsMenu,
      toggleShowQuickFilter,
      handleProvideData,
      handleSelectRow,
      onFilterChanged,
      clearAgGridFilters,
      functionCellStyle,
    }),
    [
      handleAGGInit,
      toggleShowFilterMenu,
      toggleShowRowsMenu,
      toggleShowQuickFilter,
      handleProvideData,
      handleSelectRow,
      onFilterChanged,
      clearAgGridFilters,
    ]
  );

  if (!_.isArray(data) && _.isEmpty(tableRenderingModel)) return null;

  return (
    <>
      {isHeader && <HeaderScipBlock />}
      {!!breadcrumbsLinks && (
        <BreadcrumbsControlled links={breadcrumbsLinks} clickBreadcrumbsControlled={clickBreadcrumbsControlled} />
      )}
      <ActionButtons containerCallbacks={containerCallbacks} quickMenu={quickMenu} buttons={buttons} />
      <CompendiumContainer>
        <Compendium
          aggApi={aggApi}
          data={data}
          model={tableRenderingModel}
          showQuickFilter={showQuickFilter}
          containerCallbacks={containerCallbacks}
          gridCellSizes={gridCellSizes}
          agGridThemeName={agGridThemeName}
          namesCollectionForFiltering={namesCollectionForFiltering}
        />
      </CompendiumContainer>
    </>
  );
});
