import React from 'react';
import styled from 'styled-components/macro';
import { Styled } from '../RoadAccident/RoadAccident.style';
import ReportSider from '../../parts/Sider/ReportSider';
import DataTSContainer from '../../parts/DataForFilters/DataTSContainer';
import TimePeriodicityData from '../../parts/PeriodicityData/TimePeriodicityData';
import ExtensionData from '../../parts/ExtensionData/ExtensionData';
import ScrollableBoxArea from '../../../../../../../../UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';

const StyledDrawer = styled.div``;
const { PanelLayout, LocalInnerWrapper, LocalTitle, LocalReportData, LocalDataTS, LocalExtensionData } = Styled;

const StatisticOfShipments = ({ sendData }) => {
  return (
    <StyledDrawer placement={'right'} closable={false} visible={false} getContainer={false} bodyStyle={{ padding: 0 }}>
      <PanelLayout>
        <ReportSider sendData={sendData} />
        <ScrollableBoxArea>
          <LocalTitle>Отчет "Статистика заявлений по перевозке груза"</LocalTitle>
          <LocalInnerWrapper>
            <LocalDataTS>
              <DataTSContainer />
            </LocalDataTS>
            <LocalReportData>
              <TimePeriodicityData />
            </LocalReportData>
            <LocalExtensionData>
              <ExtensionData />
            </LocalExtensionData>
          </LocalInnerWrapper>
        </ScrollableBoxArea>
      </PanelLayout>
    </StyledDrawer>
  );
};

export default StatisticOfShipments;
