import React, { useMemo } from 'react';
import { Layout } from 'antd';
import * as CSS from 'csstype';
import { Route } from 'react-router-dom';
import HeaderContainer from './Common/Header/Header';
import ErrorMessageContainer from '../ErrorMessage/ErrorMessageContainer';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import DirectoryRouter from './BaseModules/Directory/DirectoryRouter';
import { MapboxContainer } from './BaseModules/Map/MapboxContainer';
import { Styled } from './Application.styled';
import HomePageContainer from './BaseModules/HomePage/HomePage.container';
import ReportsRouter from './BaseModules/Reports/ReportsRouter/ReportsRouter';
import FileUploaderErrorReport from './ExtraModules/FileUploaderErrorReport/FileUploaderErrorReport';
import CargoTransportRouter from './ExtraModules/CargoTransport/CargoTransportRouter';
import { useLocation } from 'react-router';
import ApplicationsRouter from './ExtraModules/Applications/ApplicationsRouter';
import withMapModuleContext from './BaseModules/Map/moduleContext/withMapModuleContext';
import ApplicationUtilsContainer from './ApplicationUtils/ApplicationUtils.container';
import { SaiderContainer } from './SaiderContainer';
import { AdministrationDirectoryRouter } from './ExtraModules/CustomRolesDirectory/AdministrationDirectoryRouter';
import { ModeratorRoom } from '../ModeratorRoom/ModeratorRoom';
import { useSelector } from 'react-redux';
import { StateModel } from '../../store/reducers';
import { isEmpty } from 'lodash';

const { Header, Content } = Layout;

const { ApplicationContentInner } = Styled;

/**
 * Initial component for providing urls of directories root folders
 * @param isSidebarOpened - Redux статус открытия боковой панели
 * @param props
 * @param isFetching -
 * @param alertData
 * @param promptData
 * @param clearGlobalAlertData
 * @returns component for directory work.
 * @constructor none
 */

export const Application = React.memo(() => {
  const currentPath = useLocation();
  const isAppInMapPrintMode = currentPath.pathname.includes('map/print_mode');
  const isChangedMenuOnMap = currentPath.pathname.match('app/map');
  const userPermissions = useSelector((state: StateModel) => state.auth.permissions);
  const isUserHasRightForReports = !isEmpty(userPermissions.reports);
  const isUserHasRightForLayers = !isEmpty(userPermissions.layers);
  const isUserHasRightForDirectories = !isEmpty(userPermissions.directory);
  const isUserHaveRightForSpecialPermits =
    isUserHasRightForDirectories &&
    userPermissions.directory.some((permission) => permission.startsWith('special_permits'));
  const isUserHaveRightForRoadApplications =
    isUserHasRightForDirectories &&
    userPermissions.directory.some((permission) => permission.startsWith('applications'));
  const isUserHaveRightForAdministration =
    isUserHasRightForDirectories &&
    userPermissions.directory.some((permission) => permission.startsWith('auth') || permission.startsWith('system'));

  const layoutStyle = useMemo(() => ({ position: 'relative' as CSS.Property.Position }), []);
  const siderContainerContent = useMemo(
    () => !isAppInMapPrintMode && !isChangedMenuOnMap && <SaiderContainer />,
    [isAppInMapPrintMode, isChangedMenuOnMap]
  );
  const headerContainerContent = useMemo(
    () => !isAppInMapPrintMode && !isChangedMenuOnMap && <HeaderContainer />,
    [isAppInMapPrintMode, isChangedMenuOnMap]
  );

  return (
    <Layout style={layoutStyle}>
      <Layout>
        <ApplicationUtilsContainer />
        {siderContainerContent}
        <Content>
          <ApplicationContentInner>
            <Header className="header__without-map">{headerContainerContent}</Header>
            <ErrorMessageContainer />
            <BreadcrumbsItem to="/">Главная</BreadcrumbsItem>
            {isUserHasRightForDirectories && <DirectoryRouter />}
            <Route exact path="/app/" render={() => <HomePageContainer />} />
            {isUserHasRightForLayers && (
              <Route path={`/app/:mainPage(map)/:params?/:user?`} render={withMapModuleContext(MapboxContainer)} />
            )}
            {isUserHasRightForReports && <Route path={`/app/:mainPage(reports)`} render={() => <ReportsRouter />} />}
            {isUserHaveRightForSpecialPermits && (
              <Route path={`/app/:mainPage(special_permits)`} render={() => <CargoTransportRouter />} />
            )}
            {isUserHaveRightForRoadApplications && (
              <Route path={`/app/:mainPage(applications)`} render={() => <ApplicationsRouter />} />
            )}
            <Route path={`/app/:mainPage(moderation)`} render={() => <ModeratorRoom />} />
            {isUserHaveRightForAdministration && (
              <Route path={`/app/:mainPage(administration)`} render={() => <AdministrationDirectoryRouter />} />
            )}
            <Route
              path={`/app/:mainPage(roads_quality_upload_file_errors)/:id `}
              render={() => <FileUploaderErrorReport />}
            />
          </ApplicationContentInner>
        </Content>
      </Layout>
    </Layout>
  );
});
