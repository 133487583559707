import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { Application, ApplicationsService } from 'generated/api/api';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import PrepareModal from 'UI/common/components/PrepareModal/PrepareModal';

interface TeamOfMastersRepresentation {
  key: number;
  value: string;
}

const MasterButton = ({ selectedMapItem, data }: { selectedMapItem: any; data: Application }) => {
  const dispatch = useDispatch();

  const selectedObjectData = selectedMapItem?.data[0];
  const urlData = selectedObjectData?.urlData;

  const [teamOfMasters, setTeamOfMastersRepresentation] = useState<TeamOfMastersRepresentation[]>([]);

  useEffect(() => {
    (async () => {
      const { results } = await ApplicationsService.applicationsTeamsOfMastersList({ representation: 'true' });
      setTeamOfMastersRepresentation(results as unknown as TeamOfMastersRepresentation[]);
    })();
  }, []);

  const options = useMemo(
    () => teamOfMasters.map((el: TeamOfMastersRepresentation) => ({ value: el.value, label: el.value })),
    [teamOfMasters]
  );

  const selectorProps = useMemo(
    () => ({
      options: options,
      placeholder: 'Бригада',
      selected: data.responsible_master_name,
    }),
    [data.responsible_master_name, options]
  );

  const handleSuccess = useCallback(
    (responsible_master_name) => {
      if (responsible_master_name) {
        dispatch(submitObjectSagaErrorResponse({ url: urlData, data: { ...data, responsible_master_name } }));
      }
    },
    [data, dispatch, urlData]
  );

  return (
    <PrepareModal
      handleSuccess={handleSuccess}
      selectorProps={selectorProps}
      buttonText={'Добавить в чат'}
      buttonType={ButtonType.primaryReverse}
      title={'Добавить в чат'}
    />
  );
};

export default MasterButton;
