import React from 'react';

import { FormGeneratedField } from 'types/interfaces/forms/FormGeneratedField.model';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import SelectChartType from './inputs/SelectChartType/SelectChartType';
import PrepareInput from '../components/PrepareInput/PrepareInput';
import FieldChildInput from './inputs/FieldChildInput/FieldChildInput';
import { AnyObject } from 'types/enums/general/general.model';
import StringInput from 'UI/common/form/inputs/StringInput';
import ChoiceInput from 'UI/common/form/inputs/ChoiceInput';
import RelatedFieldInput from 'UI/common/form/inputs/RelatedFieldInput';
import BooleanInput from 'UI/common/form/inputs/BooleanInput';
import ColorPickerInput from 'UI/common/form/inputs/ColorPickerInput/ColorPickerInput';
import ReferenceFieldInput from 'UI/common/form/inputs/ReferenceFieldInput';
import { WatcherFields } from 'hooks/form/usePrepareFieldsHook/model/watchFieldsModel.model';

const widgetsProvideInput = (
  field: string,
  description: FormGeneratedField,
  object?: AnyObject,
  prepareInput?: boolean,
  watcher?: WatcherFields
) => {
  let input: JSX.Element | null = null;
  let prepare = prepareInput;

  switch (description.type) {
    case SchemaFieldType.integer: {
      if (field === 'id') return null;
      return null;
    }
    case SchemaFieldType.string: {
      const inputProps = {
        small: true,
        autoWidth: true,
      };

      input = <StringInput field={field} description={description} object={object} inputProps={inputProps} />;
      break;
    }
    case SchemaFieldType.choice: {
      if (field === 'chart_type') {
        input = <SelectChartType field={field} object={object} description={description} />;
        break;
      }

      const inputProps = {
        small: true,
      };

      input = (
        <ChoiceInput
          field={field}
          description={description}
          object={object}
          inputProps={inputProps}
          watcher={watcher}
        />
      );
      break;
    }
    case SchemaFieldType.hexColor: {
      input = <ColorPickerInput field={field} object={object} description={description} />;
      break;
    }
    case SchemaFieldType.relatedField:
    case SchemaFieldType.multiRelatedField: {
      const inputProps = {
        small: true,
      };

      input = <RelatedFieldInput description={description} object={object} field={field} inputProps={inputProps} />;
      break;
    }
    case SchemaFieldType.boolean: {
      prepare = false;
      input = <BooleanInput description={description} object={object} field={field} />;
      break;
    }
    case SchemaFieldType.field: {
      prepare = false;
      input = (
        <FieldChildInput description={description} field={field} object={object} provideInput={widgetsProvideInput} />
      );
      break;
    }
    case SchemaFieldType.relatedReferenceField:
    case SchemaFieldType.multiRelatedReferenceField: {
      const inputProps = {
        small: true,
      };

      input = <ReferenceFieldInput field={field} description={description} object={object} inputProps={inputProps} />;
    }
  }

  if (prepare && input) {
    return (
      <PrepareInput key={field} label={description.label}>
        {input}
      </PrepareInput>
    );
  }

  return input;
};

export default widgetsProvideInput;
