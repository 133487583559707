export const SELECT_OBJECTS_IN_DATA_ACCESSOR = 'SELECT_OBJECTS_IN_DATA_ACCESSOR';
export const FILTER_OBJECTS_IN_DATA_ACCESSOR = 'FILTER_OBJECTS_IN_DATA_ACCESSOR';

const initialState = {
  selected: [],
  filtered: [],
};

export const getSelectedRowsInDataAccessor = (state) => {
  return state.mapDataAccessorSelectedObjects.selected;
};

export const getFilteredDataInDataAccessor = (state) => {
  return state.mapDataAccessorSelectedObjects.filtered;
};

const mapDataAccessorSelectedObjects = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_OBJECTS_IN_DATA_ACCESSOR: {
      const { selectedData } = action.payload;
      return {
        ...state,
        selected: [...selectedData],
      };
    }
    case FILTER_OBJECTS_IN_DATA_ACCESSOR: {
      const { filteredData } = action.payload;
      return filteredData
        ? {
            ...state,
            filtered: [...filteredData],
          }
        : {
            ...state,
            filtered: [],
          };
    }
    default: {
      return state;
    }
  }
};

export default mapDataAccessorSelectedObjects;
