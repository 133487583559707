import React from 'react';
import DeckSettingsContent from './DeckSettingsContent';

const DeckSettingsContentContainer = () => {
  return <DeckSettingsContent />;
};

DeckSettingsContentContainer.propTypes = {};

export default DeckSettingsContentContainer;
