import styled from 'styled-components/macro';
import { ExtendedStyleSettings } from './model/ExtendedStyleSettings.model';

const LocalWrapper = styled.h3<{ extendedStyleSettings?: ExtendedStyleSettings }>`
  padding: ${(props) => props.theme.paddings.smallPadding};
  color: ${(props) => props?.extendedStyleSettings?.color ?? props.theme.colors.defaultDark};
  font-size: ${(props) => props.extendedStyleSettings?.fontSize ?? props.theme.fontSettings.defaultSmall};
  text-align: left;
  width: 100%;
  margin: 0;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    cursor: default;
  }
  & + * {
    margin-top: ${(props) => props?.extendedStyleSettings?.marginTop ?? props.theme.margins.defaultMargin};
  }
`;

export const Styled = { LocalWrapper };
