import { LayerDataAccessorPrototype } from 'registrators/map/layers/description/dataAccessors/prototype/LayerDataAccessorPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { DetectionsService, DetectionStatusEnum, ValidationStatusEnum } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class MonitoringUDSDataAccessor extends LayerDataAccessorPrototype {
  constructor() {
    super({
      label: 'Мониторинг УДС',
      selectedType: MapObjects.monitoringUds,
    });

    MonitoringUDSDataAccessor.selectedType = LayerDataAccessorPrototype.selectedType;
  }

  async getData() {
    return await DetectionsService.detectionsList({
      hasImage: true,
      statusIn: `${DetectionStatusEnum.REQUIRED_CLARIFICATION},${DetectionStatusEnum.CONFIRMED}`,
      validationStatus: ValidationStatusEnum.VALID_DETECTION,
    });
  }

  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.detections);
  }

  static getSelectedType() {
    return MonitoringUDSDataAccessor.selectedType;
  }

  static replaceSelectedType(type: any) {
    MonitoringUDSDataAccessor.selectedType = type;
  }

  getSelectedObjectType() {
    return MonitoringUDSDataAccessor.selectedType;
  }
}
