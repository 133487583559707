import { resolver } from './resolver';
import { groupsList, groupTypes } from './contentGroups';
import { RoadSchema } from './itemsCollections/layers/RoadSchema';
import { RoadSegmentDescription } from './itemsCollections/objects/roadsSchema/RoadSegmentDescription';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { TrafficLightDescription } from './itemsCollections/objects/roadsSchema/TrafficLightDescription';
import { RailCrossingDescription } from './itemsCollections/objects/roadsSchema/RailCrossingDescription';
import { PipesDescription } from './itemsCollections/objects/roadsSchema/PipesDescription';
import { LightingDescription } from './itemsCollections/objects/roadsSchema/LightingDescription';
import { RestAreasDescription } from './itemsCollections/objects/roadsSchema/RestAreasDescription';
import { WeatherStationsDescription } from './itemsCollections/objects/roadsSchema/WeatherStationsDescription';
import { PipelinesDescription } from './itemsCollections/objects/roadsSchema/PipelinesDescription';
import { TunnelsDescription } from './itemsCollections/objects/roadsSchema/TunnelsDescription';
import { FerryCrossingsDescription } from './itemsCollections/objects/roadsSchema/FerryCrossingsDescription';
import { TransportRoute } from './itemsCollections/layers/TransportRoute';
import { RoadSignDescription } from './itemsCollections/objects/roadsSchema/RoadSignDescription';
import { OverpassesDescription } from './itemsCollections/objects/roadsSchema/OverpassesDescription';
import { RoadTechnicalCharacteristicsDescription } from './itemsCollections/objects/RoadTechnicalCharacteristics/RoadTechnicalCharacteristicsDescription';
import { BridgesDescription } from './itemsCollections/objects/roadsSchema/BridgesDescription';
import { RoadMarkingPolygonDescription } from './itemsCollections/objects/roadsSchema/RoadMarkingPolygonDescription';
import { TramCablesDescription } from './itemsCollections/objects/tramsSchema/TramCablesDescription';
import { TramsSchema } from './itemsCollections/layers/TramsSchema';
import { TrolleybusSchema } from './itemsCollections/layers/TrolleybusSchema';
import { Cables } from './itemsCollections/objects/trolleybusSchema/Cables';
import { ElectricalSubstations } from './itemsCollections/layers/ElectricalSubstations';
import { ElectricalSubstationsDescription } from './itemsCollections/objects/ElectricalSubstationsDescription/ElectricalSubstationsDescription';
import { SupplyCablesShema } from './itemsCollections/objects/trolleybusSchema/SupplyCablesShema';
import { SupplyCablesShemaTram } from './itemsCollections/objects/tramsSchema/SupplyCablesShema';
import { RoadMarkingsDescription } from './itemsCollections/objects/roadsSchema/RoadMarkingsDescription';
import { BusStopsDescription } from './itemsCollections/objects/roadsSchema/BusStopsDescription';
import { RoadMarkingLinesDescription } from './itemsCollections/objects/roadsSchema/RoadMarkingLinesDescription';
import { SidewalkDescription } from './itemsCollections/objects/roadsSchema/SidewalkDescription';
import { TramRailsDescription } from './itemsCollections/objects/roadsSchema/TramRailsDescription';
import { EngineeringConstructionDescription } from './itemsCollections/objects/roadsSchema/EngineeringConstructionDescription';
import { RoadFencesDescription } from './itemsCollections/objects/roadsSchema/RoadFencesDescription';
import { SupportWalDescription } from './itemsCollections/objects/roadsSchema/SupportWalDescription';
import { SupportPillarsDescription } from './itemsCollections/objects/roadsSchema/SupportPillarsDescription';
import { RoadDividingDescription } from './itemsCollections/objects/roadsSchema/RoadDividingDescription';
import { RoadwaysDescription } from './itemsCollections/objects/roadsSchema/RoadwaysDescription';
import { SpeedBumpsDescription } from './itemsCollections/objects/roadsSchema/SpeedBumpsDescription';
import { CabinetsDescription } from './itemsCollections/objects/roadsSchema/CabinetsDescription';
import { CamerasAndDetectorsDescription } from './itemsCollections/objects/CamerasAndDetectorsSchema/CamerasAndDetectorsDescription';
import { CamerasAndDetectors } from './itemsCollections/layers/CamerasAndDetectors';
import { RoadPedestrianFencesDescription } from './itemsCollections/objects/roadsSchema/RoadPedestrianFencesDescription';
import { SignalPolesDescription } from './itemsCollections/objects/roadsSchema/SignalPolesDescription';
import { RoadImprovementElementsDescription } from './itemsCollections/objects/roadsSchema/RoadImprovementElementsDescription';
import { RoadTraySystem } from './itemsCollections/objects/roadsSchema/RoadTraySystem';
import { ContoursLawnDescription } from './itemsCollections/objects/roadsSchema/ContoursLawnDescription';
import { TownSquaresDescription } from './itemsCollections/objects/roadsSchema/TownSquaresDescription';
import { IlluminatedPedestrianCrossingsDescription } from './itemsCollections/objects/IlluminatedPedestrianCrossingsDescription/IlluminatedPedestrianCrossingsDescription';
import { IlluminatedPedestrianCrossings } from './itemsCollections/layers/IlluminatedPedestrianCrossings';
import { PublicTransport } from './itemsCollections/layers/PublicTransport';
import { RoadSpeedBumps } from './itemsCollections/layers/RoadSpeedBumps';
import { BrigadeApplications } from './itemsCollections/layers/BrigadeApplications';
import { BrigadeApplicationsDescription } from './itemsCollections/objects/BrigadeApplicationsDescription/BrigadeApplicationsDescription';
import { BusSheltersDescription } from './itemsCollections/objects/BusSheltersDescription/BusSheltersDescription';
import { CamerasUdc } from './itemsCollections/layers/CamerasUdc';

export const contextMenuContentGroupList = groupsList;
export const contextMenuContentGroupTypes = groupTypes;

export const resolveContextMenuContent = resolver;

/**
 * Используется в: создание объектов
 * Контекст меню - это меню которое появляется по клику на карте или объекте ПРАВОЙ кнопкой мыши.
 * Вначале подключаются слои у которых на карте должно быть можно вызвать контекстное меню (основная функция - создание объектов)
 * Ниже - подключаются объекты, клик по которым дожен вызвать меню (основные функции - редактироваие и удаление объектов)
 * Удаление объектов из регистратора блокирует вызов меню, не более, не забудьте удалить и сами файлы!
 */

export const registeredMenuContent = {
  display: {
    map: {
      [Layers.roadsSchema]: RoadSchema,
      [Layers.publicTransport]: PublicTransport,
      [Layers.transportOrders]: TransportRoute,
      [Layers.tramsSchema]: TramsSchema,
      [Layers.trolleybusTransport]: TrolleybusSchema,
      [Layers.substationsLayer]: ElectricalSubstations,
      [Layers.camerasAndDetectors]: CamerasAndDetectors,
      [Layers.roadSpeedBumps]: RoadSpeedBumps,
      [Layers.illuminatedPedestrianCrossings]: IlluminatedPedestrianCrossings,
      [Layers.brigadeApplications]: BrigadeApplications,
      [Layers.camerasUdc]: CamerasUdc,
    },
    [MapObjects.roadSegment]: RoadSegmentDescription,
    [MapObjects.contoursLawn]: ContoursLawnDescription,
    [MapObjects.townSquares]: TownSquaresDescription,
    [MapObjects.roadSigns]: RoadSignDescription,
    [MapObjects.bridges]: BridgesDescription,
    [MapObjects.roadMarkingPolygon]: RoadMarkingPolygonDescription,
    [MapObjects.trafficLight]: TrafficLightDescription,
    [MapObjects.speedBumps]: SpeedBumpsDescription,
    [MapObjects.improvementElements]: RoadImprovementElementsDescription,
    [MapObjects.overpasses]: OverpassesDescription,
    [MapObjects.railCrossings]: RailCrossingDescription,
    [MapObjects.pipes]: PipesDescription,
    [MapObjects.lighting]: LightingDescription,
    [MapObjects.restAreas]: RestAreasDescription,
    [MapObjects.weatherStations]: WeatherStationsDescription,
    [MapObjects.pipelines]: PipelinesDescription,
    [MapObjects.tunnels]: TunnelsDescription,
    [MapObjects.traySystem]: RoadTraySystem,
    [MapObjects.ferryCrossings]: FerryCrossingsDescription,
    [MapObjects.technicalSegments]: RoadTechnicalCharacteristicsDescription,
    [MapObjects.roadTechnicalCharacteristicsAxeLoads]: RoadTechnicalCharacteristicsDescription,
    [MapObjects.roadTechnicalCharacteristicsCoatingTypes]: RoadTechnicalCharacteristicsDescription,
    [MapObjects.roadTechnicalCharacteristicsLanesAmounts]: RoadTechnicalCharacteristicsDescription,
    [MapObjects.cablesTroll]: Cables,
    [MapObjects.supply_cables_troll]: SupplyCablesShema,
    [MapObjects.supply_cables_tram]: SupplyCablesShemaTram,
    [MapObjects.tramCables]: TramCablesDescription,
    [MapObjects.substations]: ElectricalSubstationsDescription,
    [MapObjects.roadMarkings]: RoadMarkingsDescription,
    [MapObjects.busStops]: BusStopsDescription,
    [MapObjects.sidewalk]: SidewalkDescription,
    [MapObjects.tramRails]: TramRailsDescription,
    [MapObjects.engineeringConstructions]: EngineeringConstructionDescription,
    [MapObjects.roadFences]: RoadFencesDescription,
    [MapObjects.pedestrianFences]: RoadPedestrianFencesDescription,
    [MapObjects.supportWall]: SupportWalDescription,
    [MapObjects.roadSigns]: RoadSignDescription,
    [MapObjects.trafficLight]: TrafficLightDescription,
    [MapObjects.lighting]: LightingDescription,
    [MapObjects.supportPillars]: SupportPillarsDescription,
    [MapObjects.cabinets]: CabinetsDescription,
    [MapObjects.busStops]: BusStopsDescription,
    [MapObjects.roadMarkingLine]: RoadMarkingLinesDescription,
    [MapObjects.dividingLines]: RoadDividingDescription,
    [MapObjects.roadWays]: RoadwaysDescription,
    [MapObjects.camerasAndDetectors]: CamerasAndDetectorsDescription,
    [MapObjects.signalPoles]: SignalPolesDescription,
    [MapObjects.illuminatedPedestrianCrossings]: IlluminatedPedestrianCrossingsDescription,
    [MapObjects.brigadeApplications]: BrigadeApplicationsDescription,
    [MapObjects.busShelters]: BusSheltersDescription,

    default: null,
  },
  standaloneEdit: {
    default: null,
  },
};
