import styled from 'styled-components';

export const Wrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  padding-left: 16px;
  padding-bottom: 20px;
  span {
    padding-right: 3px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Item = styled.span`
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  color: ${(props) => props.theme?.colors?.paragraph || 'rgba(0, 0, 0, 0.45)'};
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    color: ${(props) => props.theme?.colors?.defaultDarker || 'rgba(0, 0, 0, 0.85)'};
    .breadcrumb-separator {
      display: none;
    }
  }
`;

export const Separator = styled.span`
  margin: 0 8px;
  color: ${(props) => props.theme?.colors?.paragraph || 'rgba(0, 0, 0, 0.45)'};
`;
