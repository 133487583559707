import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';

import { getFromURL } from '../../../../../../api/getFromURL';
import { StatesService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class CityDistricts extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'city_districts',
      filterLabel: 'районы',
      selectedObjectName: MapObjects.citydistricts,
    });
  }

  async uploadData() {
    return await StatesService.statesCityDistrictsList();
  }

  async deleteRecord(id) {
    return await StatesService.statesCityDistrictsDestroy({ id });
  }

  async readRecord(id) {
    return await StatesService.statesCityDistrictsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.cityDistricts);
  }
}
