import React from 'react';
import styled from 'styled-components/macro';
import MapStyleSelectorContainer from './MapStyleSelector/MapStyleSelectorContainer';
import MapRasterStyleSelectorContainer from './MapRasterStyleSelector/MapRasterStyleSelectorContainer';

const StyledSettingsContent = styled.div`
  .ant-select-selector {
    width: 236px !important;
    height: 40px !important;
    margin-left: 16px;
    border: 2px solid ${(props) => props.theme.colors.primary};
    box-shadow: none;
    border-radius: 8px;
    background: ${(props) => props.theme.colors.defaultLight};
    color: ${(props) => props.theme.colors.paragraph};
    display: flex;
    align-items: center;
    &:hover {
      border: 2px solid ${(props) => props.theme.colors.primary} !important;
    }
    &:active {
      border: 2px solid ${(props) => props.theme.colors.primary} !important;
    }
  }
  .ant-select-arrow .ant-select-arrow-icon svg {
    color: ${(props) => props.theme.colors.primary};
    width: 9px;
    height: 6px;
  }

  p {
    text-align: left;
    color: ${(props) => props.theme.colors.neutralDarker};
    margin: 12px 0 6px 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
  }
`;

const DeckSettingsContent = () => {
  return (
    <StyledSettingsContent>
      <p>Картографическая подложка</p>
      <MapStyleSelectorContainer />
      <p>Растровая подложка</p>
      <MapRasterStyleSelectorContainer />
    </StyledSettingsContent>
  );
};

DeckSettingsContent.propTypes = {};

export default DeckSettingsContent;
