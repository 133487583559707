import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useSaveDoc } from 'hooks/reports/useSaveDoc';
import { useSavePdf } from 'hooks/reports/useSavePdf';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';
import { useTheme } from 'styled-components';
import { ApplicationTheme } from 'styles/themes/model/ApplicationTheme.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { UploadTMCFileButton } from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileButton';
import CreateButton from 'UI/custom/actionsButtons/common/CreateButton';
import PrintButton from 'UI/custom/actionsButtons/common/PrintButton';
import { RefreshButton } from 'UI/custom/actionsButtons/common/RefreshButton';
import TextIconButton from 'UI/custom/actionsButtons/common/TextIconButton';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { DownloadIcon } from 'UI/custom/icons/actionIcons/DownloadIcon';
import { SignatureIcon } from 'UI/custom/icons/actionIcons/SignatureIcon';

import { IData } from '../interfacesCargoTransport/interfacesCargoTransport';
import { iconSize } from '../interfacesCargoTransport/templates';
import { clickSignDocument } from '../methods/clickSignDocument';
import { downloadFile } from '../methods/downloadFile';
import { getBillingUnp } from '../methods/getBillingUnp';
import { GetRequest } from '../methods/GetRequest';
import { printDocument } from '../methods/printDocument';
import { showSignature } from '../methods/showSignature';
import { ChangeStatusButton } from './ChangeStatusButton/ChangeStatusButton';
import {
  pagesAddFilesButton,
  pagesDontNeedsCreateButton,
  pagesNeedsDeleteButton,
  pagesNeedsElectronicSignatureButton,
  pagesNeedsShowSignatureButton,
} from './menuLists';
import { basePath } from 'registrators/map/layers/layersRegistrator';

const { confirm } = Modal;

const DeleteStatementObject = (
  directory: string,
  pageMenuKey: IOPWDirectory,
  idSignDocument: number,
  refreshData: () => void,
  dispatch: (param: any) => void
) => {
  confirm({
    title: 'Вы действительно желаете удалить объект?',
    icon: <QuestionCircleOutlined />,
    onOk() {
      const url = `${basePath}/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/`;
      dispatch(deleteObject({ url, id: idSignDocument }));
      refreshData();
    },
  });
};

interface IButtonsProps {
  pageMenuKey: IOPWDirectory;
  moveToPageCreatingNewAccount: () => void;
  refreshData: () => void;
  idSignDocument: number;
  id: number | undefined;
  data: IData[];
  model?: ModelResponse;
  directory: IOPWDirectory;
}

export const Buttons = ({
  pageMenuKey,
  moveToPageCreatingNewAccount,
  refreshData,
  idSignDocument,
  id,
  data,
  model,
  directory,
}: PropsWithChildren<IButtonsProps>) => {
  const dispatch = useDispatch();
  const { saveDoc } = useSaveDoc();
  const { savePdf } = useSavePdf();
  // место установки конопок завист от выбраной вкладки меню
  const theme = useTheme() as ApplicationTheme;
  const clickDownloadFile = useCallback(() => {
    const url = data?.find((file: any) => file.id === idSignDocument)?.file;
    if (url) downloadFile(url);
  }, [data, idSignDocument]);

  const isVisibleShowSignaturePrintButtons = useMemo(
    () =>
      pagesNeedsShowSignatureButton.some((page) => page === pageMenuKey) &&
      directory !== IOPWDirectory.federal_statements,
    [directory, pageMenuKey]
  );

  const addFilesPage = useMemo(
    () => directory !== IOPWDirectory.federal_statements && pagesAddFilesButton.some((page) => page === pageMenuKey),
    [directory, pageMenuKey]
  );

  const downloadFilesPage = useMemo(
    () => pageMenuKey === IOPWDirectory.approval_files || pageMenuKey === IOPWDirectory.files,
    [pageMenuKey]
  );
  const isVisibleDeleteButtton = useMemo(
    () =>
      pagesNeedsDeleteButton.some((page) => page === pageMenuKey) &&
      !(pageMenuKey === IOPWDirectory.files && directory === IOPWDirectory.federal_statements),
    [directory, pageMenuKey]
  );

  const isVisibleElectronicSignatureButton = useMemo(
    () => pagesNeedsElectronicSignatureButton.some((page) => page === pageMenuKey),
    [pageMenuKey]
  );

  const approvalsPage = useMemo(() => pageMenuKey === IOPWDirectory.approvals, [pageMenuKey]);
  const adapterMessagesPage = useMemo(() => pageMenuKey === IOPWDirectory.adapter_messages, [pageMenuKey]);
  const isVisibleCreateButton = useMemo(
    () => pagesDontNeedsCreateButton.every((page) => page !== pageMenuKey),
    [pageMenuKey]
  );

  return (
    <>
      {addFilesPage && (
        <UploadTMCFileButton
          isDisabled={!id}
          tooltip={'Добавить файл'}
          pageMenuKey={pageMenuKey}
          id={id}
          refreshData={refreshData}
        />
      )}
      {downloadFilesPage && (
        <Button
          rounded
          hasShadow={true}
          type={ButtonType.danger}
          icon={<DownloadIcon />}
          iconColor={theme.colors.primary}
          iconSize={iconSize}
          onClick={clickDownloadFile}
          tooltip={'Скачать файл'}
          disabled={!idSignDocument}
        />
      )}
      {isVisibleCreateButton && <CreateButton moveToPageCreating={moveToPageCreatingNewAccount} tooltip={'Добавить'} />}
      {adapterMessagesPage && (
        <Button
          rounded
          hasShadow={true}
          type={ButtonType.dangerDelete}
          p={'0.7rem'}
          icon={<SignatureIcon />}
          iconSize={iconSize}
          tooltip={'Отправить сообщение'}
          disabled={!idSignDocument}
          onClick={() =>
            GetRequest(
              `/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/${idSignDocument}/send_message/`,
              dispatch,
              refreshData,
              'Сообщение отправлено'
            )
          }
        />
      )}
      {approvalsPage && (
        <>
          <Button
            rounded
            hasShadow={true}
            type={ButtonType.dangerDelete}
            p={'0.7rem'}
            icon={<DownloadIcon />}
            iconSize={iconSize}
            tooltip={'Выгрузить'}
            disabled={!idSignDocument}
            onClick={() => {
              printDocument(
                `${basePath}/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/${idSignDocument}/print`,
                savePdf,
                dispatch
              );
            }}
          />
          <ChangeStatusButton
            data={data}
            model={model}
            directory={directory}
            idStatement={id}
            idSignDocument={idSignDocument}
            isDisabled={!idSignDocument}
            refreshData={refreshData}
            pageMenuKey={pageMenuKey}
          />
        </>
      )}
      <RefreshButton refreshData={refreshData} tooltip={'Обновить'} />
      {isVisibleDeleteButtton && (
        <Button
          rounded
          hasShadow={true}
          type={ButtonType.dangerDelete}
          p={'0.7rem'}
          icon={<DeleteIcon />}
          iconSize={iconSize}
          tooltip={'Удалить объект'}
          disabled={!idSignDocument}
          onClick={() => DeleteStatementObject(directory, pageMenuKey, idSignDocument, refreshData, dispatch)}
        />
      )}
      {isVisibleShowSignaturePrintButtons && (
        <>
          <PrintButton
            printDocument={() => {
              printDocument(
                `${basePath}/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/${idSignDocument}/print/`,
                saveDoc,
                dispatch
              );
            }}
            isDisabled={!idSignDocument}
            tooltip="Распечатать заявление"
          />
          <Button
            rounded
            hasShadow={true}
            type={ButtonType.danger}
            icon={<DownloadIcon />}
            iconSize={iconSize}
            iconColor={theme.colors.primary}
            onClick={() => showSignature(idSignDocument, dispatch)}
            tooltip={'Скачать XML с электронной подписью'}
            disabled={!idSignDocument}
          />
          <Button
            rounded
            hasShadow={true}
            type={ButtonType.dangerDelete}
            icon={<SignatureIcon />}
            iconSize={iconSize}
            iconColor={theme.colors.dangerDelete}
            onClick={() =>
              clickSignDocument(
                `/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/${idSignDocument}/`,
                dispatch
              )
            }
            tooltip={'Осуществить электронную подпись'}
            disabled={!idSignDocument}
          />
          <TextIconButton
            clickButton={() =>
              getBillingUnp(idSignDocument, IOPWDirectory.send_billing_to_unp, pageMenuKey, dispatch, directory)
            }
            tooltip={'Отправка счета на проверку'}
            isDisabled={!idSignDocument}
            labelText={'Зарегистрировать'}
          />
          <TextIconButton
            clickButton={() =>
              getBillingUnp(
                idSignDocument,
                IOPWDirectory.check_billing_status_from_unp,
                pageMenuKey,
                dispatch,
                directory
              )
            }
            tooltip={'Проверка счета'}
            isDisabled={!idSignDocument}
            labelText={'Узнать статус'}
          />
          <TextIconButton
            clickButton={() =>
              getBillingUnp(
                idSignDocument,
                IOPWDirectory.check_billing_payment_from_unp,
                pageMenuKey,
                dispatch,
                directory
              )
            }
            tooltip={'Проверка оплаты'}
            isDisabled={!idSignDocument}
            labelText={'Проверить оплату'}
          />
        </>
      )}
      {isVisibleElectronicSignatureButton && (
        <Button
          rounded
          hasShadow={true}
          type={ButtonType.dangerDelete}
          p={'1rem'}
          icon={<SignatureIcon />}
          iconSize={iconSize}
          onClick={() =>
            clickSignDocument(
              `/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/${idSignDocument}/`,
              dispatch
            )
          }
          tooltip={'Осуществить электронную подпись'}
          disabled={!idSignDocument}
        />
      )}
    </>
  );
};
