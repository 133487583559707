import React, { ReactNode, useCallback, useMemo } from 'react';
import { Styled } from './style/Alert.styled';
import ScreenPortal from '../ScreenPortal/ScreenPortal';
import { usePortalContext } from '../../../../services/dom/usePortalContext';
import { AlertProps } from './model/Alert.model';
import { AlertOwnProps } from './model/AlertOwnProps.model';
import _ from 'underscore';

const { AntAlert, LocalMainWrapper } = Styled;

const Alert = ({ type, title, message, size, style: userStyles = {}, onClose, ...otherProps }: AlertOwnProps) => {
  const { enhancedCloseCallback } = usePortalContext(onClose);

  const antAlertCallback = useCallback(
    (mes: ReactNode, index?: number) => {
      return (
        <AntAlert
          key={`ant_alert_callback__${index}`}
          type={type}
          onClose={enhancedCloseCallback}
          closable
          showIcon
          message={title}
          description={mes}
          {...otherProps}
        />
      );
    },
    [enhancedCloseCallback, otherProps, title, type]
  );

  const antAlertsContainer = useMemo(() => {
    if (_.isArray(message)) {
      return message.map(antAlertCallback);
    } else {
      return antAlertCallback(message);
    }
  }, [antAlertCallback, message]);

  return (
    <LocalMainWrapper style={userStyles} {...otherProps}>
      {antAlertsContainer}
    </LocalMainWrapper>
  );
};

/** Стилизованный алерт*/
const Usage = ({ isOpen, portalSide, ...otherProps }: AlertProps) => {
  return (
    <ScreenPortal portalSide={portalSide} isOpen={isOpen} {...otherProps}>
      <Alert {...otherProps} />
    </ScreenPortal>
  );
};

export default Usage;
