import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { postToURLSingle } from '../../../api/postToURLSingle';
import { makeUrlWithoutIdAtTheEnd } from '../../../services/net/makeUrlWithoutIdAtTheEnd';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import _ from 'underscore';
import { createAlertMessage } from './createAlertMessage';
import { editableObjectDescription } from 'registrators/map/editableObjectsRegistrator/editableObjectsRegistrator';
import { DataUpdateMethod } from 'types/enums/routes/request/DataAccessMethod.model';
import { forageStore } from 'services/axiosInstances/axiosSecured';
import { AnyObject } from 'types/enums/general/general.model';

export interface SubmitObjectSagaErrorResponseProps {
  url: string;
  data: AnyObject;
  initial?: AnyObject;
  initClose?: (response: AnyObject) => void;
  setIdBlanks?: (response: any[]) => void;
  setAlertData?: (response: AnyObject) => void;
  setIsActiveMenu?: (response: boolean) => void;
  setReturnResponse?: (response: AnyObject) => void;
}

export const submitObjectSagaErrorResponse = ({
  url,
  data,
  initial,
  initClose,
  setIdBlanks,
  setAlertData,
  setIsActiveMenu,
  setReturnResponse,
}: SubmitObjectSagaErrorResponseProps) => ({
  type: SUBMIT_OBJECT_SAGA_ERROR_RESPONSE,
  payload: {
    url,
    data,
    initial,
    initClose,
    setIdBlanks,
    setAlertData,
    setIsActiveMenu,
    setReturnResponse,
  },
});
export const SUBMIT_OBJECT_SAGA_ERROR_RESPONSE = 'SUBMIT_OBJECT_SAGA_ERROR_RESPONSE';

function* postObjectSagaErrorResponse(action: { type: string; payload: SubmitObjectSagaErrorResponseProps }) {
  try {
    yield put({ type: 'START_POSTING_DATA' });
    const { url, data, initial, initClose, setIdBlanks, setAlertData, setIsActiveMenu, setReturnResponse } =
      action.payload;
    //this fn call can break something in the project
    const preparedUrl = makeUrlWithoutIdAtTheEnd(url);
    const editableObject = editableObjectDescription.find((el) => url.includes(el.urlData));

    let updateFunction;
    if (editableObject?.updateMethod === DataUpdateMethod.partialUpdate && data.id) {
      updateFunction = () => postToURLSingle.patchData(preparedUrl, data, initial);
    } else {
      updateFunction = () => postToURLSingle.postOrPutDataErrorResponse(preparedUrl, data, initial);
    }

    const response: AnyObject = yield call(updateFunction);
    if (response) {
      if (setAlertData) {
        setAlertData({
          isVisebleAlert: true,
          type: PromptType.success,
          title: 'Успешно',
          message: data.id ? 'Объект был успешно изменен' : 'Объект был успешно добавлен',
        });
      } else {
        yield put(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.success,
            title: 'Успешно',
            message: data.id ? 'Объект был успешно изменен' : 'Объект был успешно добавлен',
          })
        );
      }
      yield forageStore.clear();
      if (initClose) initClose(response);
      if (setIdBlanks) setIdBlanks([]);
      if (setIsActiveMenu) setIsActiveMenu(true);
      if (setReturnResponse) setReturnResponse(response);
    } else {
    }
  } catch (e) {
    if (_.isObject(e) && e?.response && _.isObject(e.response)) {
      const message = createAlertMessage(e);
      if (action?.payload?.setAlertData) {
        action.payload.setAlertData({
          isVisebleAlert: true,
          type: PromptType.error,
          title: 'Ошибка',
          message: message,
        });
      } else {
        yield put(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: message,
          })
        );
      }
    }
  } finally {
    yield delay(9000);
    if (action?.payload?.setAlertData) {
      action.payload.setAlertData({ isVisebleAlert: false });
    } else yield put(notificationActions.clearGlobalAlertData());
  }
}

export function* watchPostObjectSagaErrorResponse() {
  yield takeEvery(SUBMIT_OBJECT_SAGA_ERROR_RESPONSE, postObjectSagaErrorResponse);
}
