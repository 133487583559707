import React from 'react';
import Icon from '@ant-design/icons';

const TrafficLightIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0006 7.5C9.55862 7.5 9.1347 7.3244 8.82214 7.01184C8.50958 6.69928 8.33398 6.27536 8.33398 5.83333C8.33398 5.39131 8.50958 4.96738 8.82214 4.65482C9.1347 4.34226 9.55862 4.16667 10.0006 4.16667C10.9256 4.16667 11.6673 4.90833 11.6673 5.83333C11.6673 6.27536 11.4917 6.69928 11.1792 7.01184C10.8666 7.3244 10.4427 7.5 10.0006 7.5ZM10.0006 11.6667C9.55862 11.6667 9.1347 11.4911 8.82214 11.1785C8.50958 10.866 8.33398 10.442 8.33398 10C8.33398 9.55797 8.50958 9.13405 8.82214 8.82149C9.1347 8.50893 9.55862 8.33333 10.0006 8.33333C10.9256 8.33333 11.6673 9.075 11.6673 10C11.6673 10.442 11.4917 10.866 11.1792 11.1785C10.8666 11.4911 10.4427 11.6667 10.0006 11.6667ZM10.0006 15.8333C9.55862 15.8333 9.1347 15.6577 8.82214 15.3452C8.50958 15.0326 8.33398 14.6087 8.33398 14.1667C8.33398 13.7246 8.50958 13.3007 8.82214 12.9882C9.1347 12.6756 9.55862 12.5 10.0006 12.5C10.9256 12.5 11.6673 13.2417 11.6673 14.1667C11.6673 14.6087 11.4917 15.0326 11.1792 15.3452C10.8666 15.6577 10.4427 15.8333 10.0006 15.8333ZM16.6673 8.33333H14.1673V7.38333C15.6006 7.00833 16.6673 5.71667 16.6673 4.16667H14.1673V3.33333C14.1673 3.11232 14.0795 2.90036 13.9232 2.74408C13.767 2.5878 13.555 2.5 13.334 2.5H6.66732C6.4463 2.5 6.23434 2.5878 6.07806 2.74408C5.92178 2.90036 5.83398 3.11232 5.83398 3.33333V4.16667H3.33398C3.33398 5.71667 4.40065 7.00833 5.83398 7.38333V8.33333H3.33398C3.33398 9.88333 4.40065 11.175 5.83398 11.55V12.5H3.33398C3.33398 14.05 4.40065 15.3417 5.83398 15.7167V16.6667C5.83398 16.8877 5.92178 17.0996 6.07806 17.2559C6.23434 17.4122 6.4463 17.5 6.66732 17.5H13.334C13.555 17.5 13.767 17.4122 13.9232 17.2559C14.0795 17.0996 14.1673 16.8877 14.1673 16.6667V15.7167C15.6006 15.3417 16.6673 14.05 16.6673 12.5H14.1673V11.55C15.6006 11.175 16.6673 9.88333 16.6673 8.33333Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const TrafficLightIcon = (props) => <Icon component={TrafficLightIconSVG} {...props} />;
