import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import { EngineeringConstructionsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class EngineeringConstructions extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'engineering_constructions',
      filterLabel: 'Инженерное сооружение',
      selectedObjectName: MapObjects.engineeringConstructions,
      isHidden: true,
    });
  }

  async uploadData() {
    return await EngineeringConstructionsService.engineeringConstructionsList();
  }

  async deleteRecord(id) {
    return await EngineeringConstructionsService.engineeringConstructionsDestroy({ id });
  }

  async readRecord(id) {
    return await EngineeringConstructionsService.engineeringConstructionsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.engineeringConstructions);
  }
}
