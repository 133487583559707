import React, { useEffect, useState } from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { getFromURL } from '../../../api/getFromURL';
import _ from 'underscore';

const RoadSchemeItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const [currentProperties, setCurrentProperties] = useState({});
  const [roadsModel, setRoadsModel] = useState({});

  useEffect(() => {
    if (data.road) {
      (async () => {
        const responseData = await getFromURL.getById('roads/', data.road);
        setCurrentProperties(responseData);
        const responseModel = await getFromURL.getModelFromURL('roads/');
        setRoadsModel(responseModel?.scheme ?? {});
      })();
    }
  }, [data.road]);

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'segment');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadsSegments}/${id}/`, '_blank');
    win.focus();
  };

  const segmentsModel = selectedMapItem?.data?.[0]?.model?.scheme ?? {};

  const availableFields = [
    'name',
    'importance',
    'total_road_length',
    'owner_organization_name',
    'road_number',
    'road_code',
    'road_name',
    'start_segment',
    'end_segment',
    'segment_length',
    'exploitative_kilometer',
  ];

  const unionRoadsSegmentsModel = _.pick({ ...roadsModel, ...segmentsModel }, ...availableFields);
  const unionRoadsSegmentsData = { ...currentProperties, ...(data ?? {}) };

  // добавит в Descriptions все переданные бэком поля отмеченные read_only: false
  const descriptions = Object.keys(unionRoadsSegmentsModel).map((key) => {
    const description = unionRoadsSegmentsModel[key]?.choices
      ? unionRoadsSegmentsModel[key]?.choices?.find((el) => el.value === unionRoadsSegmentsData[key])?.display_name ??
        'Данные отсутствуют'
      : unionRoadsSegmentsData[key] ?? 'Данные отсутствуют';
    return {
      key: unionRoadsSegmentsModel[key]?.label,
      value: description,
    };
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные дорог и участков'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={descriptions ?? []}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

RoadSchemeItemSelection.propTypes = {};

export default RoadSchemeItemSelection;
