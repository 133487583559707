import React from 'react';
import Icon from '@ant-design/icons';

const ColumnIconSVG = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.66667 1.66675H13.3333C13.7754 1.66675 14.1993 1.84234 14.5118 2.1549C14.8244 2.46746 15 2.89139 15 3.33341V16.6667C15 17.1088 14.8244 17.5327 14.5118 17.8453C14.1993 18.1578 13.7754 18.3334 13.3333 18.3334H6.66667C6.22464 18.3334 5.80072 18.1578 5.48816 17.8453C5.17559 17.5327 5 17.1088 5 16.6667V3.33341C5 2.89139 5.17559 2.46746 5.48816 2.1549C5.80072 1.84234 6.22464 1.66675 6.66667 1.66675ZM6.66667 8.33342V11.6667H13.3333V8.33342H6.66667ZM6.66667 13.3334V16.6667H13.3333V13.3334H6.66667ZM6.66667 3.33341V6.66675H13.3333V3.33341H6.66667Z'
        fill='currentColor' />
    </svg>
  )
;

export const ColumnIcon = (props) => <Icon component={ColumnIconSVG} {...props} />;
