import React, { FC } from 'react';

import { Styled } from './style/PieTooltip.styled';

const { TooltipWrap, TooltipName, TooltipValue } = Styled;

const PieTooltip: FC<any> = ({ active, payload, ...props }) => {
  if (active && payload && payload[0]) {
    const item = payload[0];
    const color = item.payload.fill;

    return (
      <TooltipWrap color={color}>
        <TooltipName>{item.name}</TooltipName>
        <TooltipValue>{item.value}</TooltipValue>
      </TooltipWrap>
    );
  }

  return null;
};

export default PieTooltip;
