import React, { PropsWithChildren } from 'react';
import { Styled } from './style/TooltipItem.styled';
import { CustomTooltipItemProps } from './model/TooltipItem.model';

const TooltipItem = ({ children, fontColor, onClick }: PropsWithChildren<CustomTooltipItemProps>) => {
  const onClickHandler = (event: any) => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <Styled.LocalWrapper fontColor={fontColor} onClick={onClickHandler}>
      {children}
    </Styled.LocalWrapper>
  );
};

export default TooltipItem;
