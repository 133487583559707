import styled from 'styled-components/macro';

export const LocalMainWrapper = styled.div`
  padding: 30px;
`;

export const LocalInnerWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  background-color: ${(props) => props.theme.colors.defaultLight};
  flex-wrap: wrap;
`;
