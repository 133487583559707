import React, { useCallback, useMemo } from 'react';
import { PreparedDescItemsI } from './model/PreparedDescItems.model';
import { Styled } from './style/PreparedDescItems.styled';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';

const { LocalItemWrap, LocalDescItemName, LocalDescItemContentWrap, LocalDescItemContent, LocalItemButtonWrap } =
  Styled;

const PreparedDescItems = ({ listOfDescriptions }: PreparedDescItemsI) => {
  const localItemContent = useCallback((value, options) => {
    if (Array.isArray(value)) {
      return value.map((m: string) => (
        <LocalDescItemContent key={m} style={{ color: options?.styles?.color }}>
          {m}
        </LocalDescItemContent>
      ));
    }

    return <LocalDescItemContent style={{ color: options?.styles?.color }}>{value}</LocalDescItemContent>;
  }, []);

  const content = useMemo(
    () =>
      listOfDescriptions.map(({ key, value, options }: IDescriptions) => {
        return (
          <LocalItemWrap key={key} style={{ background: options?.styles?.backgroundColor }}>
            <LocalDescItemName style={{ color: options?.styles?.color }}>{key}</LocalDescItemName>
            <LocalDescItemContentWrap>
              {localItemContent(value, options)}
              {options?.buttons && (
                <LocalItemButtonWrap style={options?.styles}>{options?.buttons}</LocalItemButtonWrap>
              )}
            </LocalDescItemContentWrap>
          </LocalItemWrap>
        );
      }),
    [listOfDescriptions, localItemContent]
  );

  return <>{content}</>;
};

export default PreparedDescItems;
