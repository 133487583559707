import React from 'react';
import RoadAccidentSections from './RoadAccidentSections';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import useGlobal from '../../store';
import { getFromURL } from '../../../../../../../../api/getFromURL';
import { postToURLSingle } from '../../../../../../../../api/postToURLSingle';
import withContentPadding from '../../../../../../../../HOC/withContentPadding';
import { basePath } from '../../../../../../../../registrators/map/layers/layersRegistrator';
import _ from 'underscore';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { useSaveExel } from '../../../../../../../../hooks/reports/useSaveExel';

const RoadAccidentSectionsContainer = () => {
  const dispatch = useDispatch();
  const [globalState] = useGlobal();
  const { saveExel } = useSaveExel();

  const sendData = () => {
    async function postData() {
      const response = await getFromURL.getDataFromURL('/reports/road_accident_sections');
      const json = await response;

      const rewriteJson = { ...json };
      const { email, checkedItems, extensions, timeStart, timeEnd } = globalState;
      rewriteJson.email = email;
      rewriteJson.mime_type = extensions;
      rewriteJson.filters[4].value = [checkedItems];
      rewriteJson.filters[0].value = [timeStart, timeEnd];
      try {
        const postResult = await postToURLSingle.postOrPutDataErrorResponse(
          '/reports/road_accident_sections',
          rewriteJson
        );
        if (extensions === 'text/html') {
          const win = window.open(`about:blank`, '_blank');
          win.document.write(postResult);
          win.focus();
        }
        if (extensions === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          const result = await postResult;
          const resultId = result.result_id;
          const url = basePath + `/reports/result/` + resultId;
          saveExel(url);
        }
      } catch (e) {
        if (e?.response && _.isObject(e.response)) {
          const message = createAlertMessage(e);
          dispatch(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: 'Ошибка',
              message: message,
            })
          );
        }
      } finally {
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 9000);
      }
    }
    postData();
  };

  return (
    <div>
      <RoadAccidentSections sendData={sendData} />
    </div>
  );
};

const enhance = compose(withContentPadding, connect(null, null));
export default enhance(RoadAccidentSectionsContainer);
