import styled from 'styled-components/macro';
import { Button } from '../../../../UI/common/components/Button/Button';
import { NavLink } from 'react-router-dom';

const LocalMainWrapper = styled.div`
  position: absolute;
  top: 50%;
  height: 382px;
  transform: translateY(-55%);
  z-index: 50000;
`;

const LocalMainTitle = styled.h3`
  margin-top: 0;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  width: 100%;
  align-items: center;
  font-size: 24px;
  color: ${(props) => props.theme.colors.dark};
`;

const LocalMainInner = styled.div`
  padding: 32px 16px;
  width: 338px;
  background-color: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.largeBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.darkBoxShadow};

  & a {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.primary};
  }

  & .error-message {
    position: absolute;
    top: 59%;
    left: 7%;
    color: ${(props) => props.theme.colors.danger};
    text-align: left;
    background: none;
  }
`;

const LocalForm = styled.form`
  & + * {
    margin-top: 30px;
  }
`;

const LocalInputLogin = styled.div`
  position: relative;
  width: 306px;
  margin: 36px auto 16px;
  .ant-input-affix-wrapper {
    border: none;
  }
  input {
    padding: 15px 0 0 5px !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 50px ${(props) => props.theme.colors.defaultLighter} !important;
  }
`;

const LocalPasswordInput = styled.div`
  position: relative;
  width: 306px;
  margin: 16px auto 16px;
  .ant-input-affix-wrapper {
    border: none;
  }
  input {
    padding: 15px 0 0 5px !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 50px ${(props) => props.theme.colors.defaultLighter} !important;
  }
`;

const ErrorMessageItem = styled.p`
  color: ${(props) => props.theme.colors.gray};
  font-size: 13px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  margin-top: -12px;
`;

const ErrorMessageDanger = styled.p`
  color: ${(props) => props.theme.colors.danger};
  font-size: 13px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  margin-top: -12px;
`;

const LocalPlaceholderLogin = styled.span`
  &.inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-weight: 600;
    font-size: 13px;
    color: ${(props) => props.theme.colors.primary};
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }
`;

const LocalPlaceholderPassword = styled.span`
  &.inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-weight: 600;
    font-size: 13px;
    color: ${(props) => props.theme.colors.primary};
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }
`;

const ExtendedButton = styled(Button)`
  font-size: ${(props) => props.theme.fontSettings.default};
  font-family: 'Gilroy', sans-serif;
  margin: 16px auto 16px;
`;

const ExtendedNavLink = styled(NavLink)`
  display: block;
`;

const LocalPrimaryDescription = styled.small`
  display: block;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Gilroy', sans-serif;
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
  line-height: 19px;
  opacity: 0.8;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
`;

export const Styled = {
  LocalMainWrapper,
  LocalMainInner,
  LocalForm,
  LocalInputLogin,
  LocalPasswordInput,
  LocalMainTitle,
  LocalPlaceholderLogin,
  LocalPlaceholderPassword,
  LocalPrimaryDescription,
  ExtendedButton,
  ExtendedNavLink,
  ErrorMessageItem,
  ErrorMessageDanger,
};
