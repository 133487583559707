import _ from 'underscore';
import { createSelector } from 'reselect';

export const ADD_HISTORY_ITEM = 'ADD_HISTORY_ITEM';
export const RESET_AND_ADD_FIRST_ITEM = 'RESET_AND_ADD_FIRST_ITEM';
export const PUSH_INLINE_HISTORY = 'PUSH_INLINE_HISTORY';
export const CLEAN_INLINE_HISTORY = 'CLEAN_INLINE_HISTORY';
export const PROVIDE_DATA_TO_INLINE_HISTORY_BY_ID = 'PROVIDE_DATA_TO_INLINE_HISTORY_BY_ID';
export const RELOAD_INLINE_HISTORY = 'RELOAD_INLINE_HISTORY';
export const REVERT_HISTORY_TO_RECORD = 'REVERT_HISTORY_TO_RECORD';
export const SELECT_INSTANCE_IN_DIRECTORY_HISTORY = 'SELECT_INSTANCE_IN_DIRECTORY_HISTORY';

const initialState = [];

export const selectDirectoryHistory = (state) => {
  return state.directoryHistory;
};

export const getLastHistoryRecord = createSelector([selectDirectoryHistory], (directoryHistoryData) => {
  return _.last(directoryHistoryData);
});

export const getMainObject = createSelector([selectDirectoryHistory], (directoryHistoryData) => {
  return _.find(directoryHistoryData, (el) => el.type === 'mainObject');
});

export const getMainDirectory = createSelector([selectDirectoryHistory], (directoryHistoryData) => {
  return _.find(directoryHistoryData, (el) => el?.type === 'main');
});

export const getLastObjectInHistory = createSelector([selectDirectoryHistory], (directoryHistoryData) => {
  const index = _.findLastIndex(directoryHistoryData, (el) => el.type.includes('Object'));
  return directoryHistoryData[index];
});

export const getLastHistoryRecordTitle = createSelector([getLastHistoryRecord], (lastHistory) => !!lastHistory?.url);

export const getMainObjectId = createSelector([getMainObject], (mainObject) => mainObject.ids);

export const getLastSiderHistoryRecord = createSelector([selectDirectoryHistory], (directoryHistoryData) => {
  for (let it = directoryHistoryData.length - 1; it >= 0; it--) {
    if (directoryHistoryData[it].type === 'mainObject' || directoryHistory[it].type === 'inlineObject') {
      return directoryHistoryData[it];
    }
  }
});

const directoryHistory = (state = initialState, action) => {
  switch (action.type) {
    case ADD_HISTORY_ITEM:
      return [...state, action.payload];
    case RESET_AND_ADD_FIRST_ITEM:
      return [action.payload];
    case REVERT_HISTORY_TO_RECORD: {
      const { index, newData } = action.payload;
      const copy = _.first(state, index + 1);
      if (!_.isArray(newData)) {
        copy[copy.length - 1].data = { ...newData };
      } else if (_.isArray(newData)) {
        copy[copy.length - 1].data = [...newData];
      }
      return [...copy];
    }
    case PUSH_INLINE_HISTORY: {
      const lastInHistory = _.last(state);
      const data = action.payload;
      if (action.payload.type === 'main') {
        return [data];
      }
      if (lastInHistory && !_.isEmpty(lastInHistory)) {
        if (lastInHistory.type === action.payload.type) {
          const copy = _.initial(state);
          copy.push(data);
          return [...copy];
        } else {
          return [...state, data];
        }
      } else {
        return [data];
      }
    }
    case SELECT_INSTANCE_IN_DIRECTORY_HISTORY: {
      const { selected } = action.payload;
      const copyOfHistory = state;
      copyOfHistory[copyOfHistory.length - 1].selectedInstance = selected;
      return [...copyOfHistory];
    }
    case CLEAN_INLINE_HISTORY: {
      return initialState;
    }
    case RELOAD_INLINE_HISTORY: {
      const { id, data } = action.payload;
      const index = state.findIndex((el) => el.id === id);
      state[index].data = data;
      return [...state];
    }
    case PROVIDE_DATA_TO_INLINE_HISTORY_BY_ID: {
      const index = state.findIndex((el) => el.id === action.payload.id);
      state[index].data = action.payload.data;
      state[index].model = action.payload.model;
      return [...state];
    }
    default:
      return state;
  }
};

export default directoryHistory;
