import { RelatedDataPrototype } from 'registrators/map/layers/description/relatedData/prototype/RelatedDataPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { ApplicationsService } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { RequestPath } from 'types/enums/routes/request/RequestPath';

export class BrigadeTeamOfWorkers extends RelatedDataPrototype {
  constructor() {
    super({
      name: MapObjects.brigadeTeamsOfWorkers,
      filterLabel: 'Бригады',
      selectedObjectName: MapObjects.brigadeTeamsOfWorkers,
    });
  }

  async uploadData() {
    return await ApplicationsService.applicationsTeamsOfWorkersList();
  }

  async deleteRecord(id: number) {
    return await ApplicationsService.applicationsTeamsOfWorkersDestroy({ id });
  }

  async readRecord(id: number) {
    return await ApplicationsService.applicationsTeamsOfWorkersRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.brigadeTeamsOfWorkers);
  }
}
