export enum ApplicationsDirectory {
  create = 'create',
  applications = 'applications',
  main = 'main',
  teams_of_masters = 'teams_of_masters',
  expendable_materials = 'expendable_materials',
  materials = 'materials',
  types = 'types',
  teams_of_workers = 'teams_of_workers',
  photos = 'photos',
  tasks = 'tasks',
  task_objects = 'task_objects',
  responsible_persons = 'responsible_persons',
  childrenTasks = 'childrenTasks',
  semifinishedObjects = 'semifinished_objects',
  sources = 'sources',
}
