import React from 'react';
import Icon from '@ant-design/icons';

const HelpIconSVG = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.66675 14.9998H11.3334V13.3332H9.66675V14.9998ZM10.5001 1.6665C9.40573 1.6665 8.3221 1.88205 7.31105 2.30084C6.30001 2.71963 5.38135 3.33346 4.60752 4.10728C3.04472 5.67008 2.16675 7.7897 2.16675 9.99984C2.16675 12.21 3.04472 14.3296 4.60752 15.8924C5.38135 16.6662 6.30001 17.28 7.31105 17.6988C8.3221 18.1176 9.40573 18.3332 10.5001 18.3332C12.7102 18.3332 14.8298 17.4552 16.3926 15.8924C17.9554 14.3296 18.8334 12.21 18.8334 9.99984C18.8334 8.90549 18.6179 7.82186 18.1991 6.81081C17.7803 5.79976 17.1665 4.8811 16.3926 4.10728C15.6188 3.33346 14.7002 2.71963 13.6891 2.30084C12.6781 1.88205 11.5944 1.6665 10.5001 1.6665ZM10.5001 16.6665C6.82508 16.6665 3.83341 13.6748 3.83341 9.99984C3.83341 6.32484 6.82508 3.33317 10.5001 3.33317C14.1751 3.33317 17.1667 6.32484 17.1667 9.99984C17.1667 13.6748 14.1751 16.6665 10.5001 16.6665ZM10.5001 4.99984C9.61603 4.99984 8.76818 5.35103 8.14306 5.97615C7.51794 6.60127 7.16675 7.44912 7.16675 8.33317H8.83342C8.83342 7.89114 9.00901 7.46722 9.32157 7.15466C9.63413 6.8421 10.0581 6.6665 10.5001 6.6665C10.9421 6.6665 11.366 6.8421 11.6786 7.15466C11.9912 7.46722 12.1667 7.89114 12.1667 8.33317C12.1667 9.99984 9.66675 9.7915 9.66675 12.4998H11.3334C11.3334 10.6248 13.8334 10.4165 13.8334 8.33317C13.8334 7.44912 13.4822 6.60127 12.8571 5.97615C12.232 5.35103 11.3841 4.99984 10.5001 4.99984Z"
      fill="currentColor"
    />
  </svg>
);

export const HelpIcon = (props) => <Icon component={HelpIconSVG} {...props} />;
