import CameraMaskPrototype, { MaskObjectList, PolygonConfig } from './prototype/CameraMaskPrototype';
import { ObjectType } from './prototype/ObjectType';
import { DetectionMask, MaskTypeEnum } from 'generated/api/api';

class RoadMarksMask extends CameraMaskPrototype {
  protected static maskTypeName = 'Дорожная разметка';
  protected static maskType = MaskTypeEnum.RoadMarksChecker;
  protected static maskObjectList: Array<MaskObjectList> = [
    {
      type: ObjectType.roiPoints,
      name: 'Область разметки',
      help: 'Выделите область дорожной разметки',
    },
  ];
  protected static polygonConfig: PolygonConfig = {
    ...CameraMaskPrototype.polygonConfig,
    fill: 'rgba(224, 81, 175, 0.08)',
    stroke: '#E051AF',
  };

  constructor(cameraID: number, mask: DetectionMask | null = null) {
    super(cameraID, mask);
  }

  static getMaskTypeName(): string {
    return RoadMarksMask.maskTypeName;
  }

  static getMaskType(): string {
    return RoadMarksMask.maskType;
  }

  static getMaskObjectList(): Array<MaskObjectList> {
    return RoadMarksMask.maskObjectList;
  }

  get maskTypeName(): string {
    return RoadMarksMask.maskTypeName;
  }

  get maskType(): MaskTypeEnum {
    return RoadMarksMask.maskType;
  }

  get maskObjectList(): Array<MaskObjectList> {
    return RoadMarksMask.maskObjectList;
  }

  get polygonConfig(): PolygonConfig {
    return RoadMarksMask.polygonConfig;
  }
}

export default RoadMarksMask;
