import React, { useCallback } from 'react';
import MapSelectedObjectInfo from './MapSelectedObjectInfo';
import { connect } from 'react-redux';
import { clearMapSelectedObject } from '../../../../../../store/reducers/map/actions/mapSelectedObjectActions';
import { goToMapCoords } from '../../../../../../store/reducers/map/actions/mapboxActions';
import { toggleMapSelectedObjectInfoVisibility } from '../../../../../../store/reducers/map/actions/mapPanelsActions';
import { mapboxDataActions } from '../../../../../../store/reducers/map/mapboxData/mapboxData.actions';
import { getSelectedMapboxLayer } from '../../../../../../store/reducers/map/mapboxLayers';
import { Layers } from '../../../../../../types/enums/map/layers/Layers';

const MapSelectedObjectInfoContainer = (props) => {
  const {
    handlerClearMapSelectedObject,
    selectedMapItem,
    isMapSelectedObjectInfoVisible,
    handlerToggleMapSelectedObjectInfoVisibility,
    reloadMapboxLayerData,
    selectedLayer,
  } = props;

  const handleCloseMapSelectedObjectInfo = useCallback(() => {
    //в слое "Перевозка КТГ" при закрытии окна объекта выделение на карте должно оставаться
    if (selectedLayer?.name !== Layers.transportOrders) handlerClearMapSelectedObject();
    handlerToggleMapSelectedObjectInfoVisibility(false);
  }, [handlerClearMapSelectedObject, handlerToggleMapSelectedObjectInfoVisibility, selectedLayer]);
  return (
    <>
      {isMapSelectedObjectInfoVisible && (
        <MapSelectedObjectInfo
          handleCloseMapSelectedObjectInfo={handleCloseMapSelectedObjectInfo}
          selectedMapItem={selectedMapItem}
          isMapSelectedObjectInfoVisible={isMapSelectedObjectInfoVisible}
          reloadMapboxLayerData={reloadMapboxLayerData}
          selectedLayer={selectedLayer}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedMapItem: state.mapSelectedObject,
    selectedLayer: getSelectedMapboxLayer(state),
    isMapSelectedObjectInfoVisible: state.mapPanels?.mapSelectedObjectInfoVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlerClearMapSelectedObject: () => dispatch(clearMapSelectedObject()),
    handlerToggleMapSelectedObjectInfoVisibility: () => dispatch(toggleMapSelectedObjectInfoVisibility()),
    goToMapCoords: (latitude, longitude) => dispatch(goToMapCoords(latitude, longitude)),
    reloadMapboxLayerData: (urlData, layerName) =>
      dispatch(mapboxDataActions.reloadMapboxLayerData(urlData, layerName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapSelectedObjectInfoContainer);
