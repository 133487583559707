import React from 'react';
import Icon from '@ant-design/icons';

const EyeIconSVG = () => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 4.5C10.6632 4.5 11.2991 4.76339 11.7679 5.23223C12.2368 5.70107 12.5002 6.33696 12.5002 7C12.5002 7.66304 12.2368 8.29893 11.7679 8.76777C11.2991 9.23661 10.6632 9.5 10.0002 9.5C9.33712 9.5 8.70124 9.23661 8.23239 8.76777C7.76355 8.29893 7.50016 7.66304 7.50016 7C7.50016 6.33696 7.76355 5.70107 8.23239 5.23223C8.70124 4.76339 9.33712 4.5 10.0002 4.5ZM10.0002 0.75C14.1668 0.75 17.7252 3.34167 19.1668 7C17.7252 10.6583 14.1668 13.25 10.0002 13.25C5.8335 13.25 2.27516 10.6583 0.833496 7C2.27516 3.34167 5.8335 0.75 10.0002 0.75ZM2.65016 7C3.32371 8.37526 4.36958 9.53396 5.66889 10.3444C6.96821 11.1548 8.46883 11.5844 10.0002 11.5844C11.5315 11.5844 13.0321 11.1548 14.3314 10.3444C15.6307 9.53396 16.6766 8.37526 17.3502 7C16.6766 5.62474 15.6307 4.46604 14.3314 3.65562C13.0321 2.8452 11.5315 2.41557 10.0002 2.41557C8.46883 2.41557 6.96821 2.8452 5.66889 3.65562C4.36958 4.46604 3.32371 5.62474 2.65016 7Z"
      fill="#2F80ED"
    />
  </svg>
);

const EyeIconTransparentSVG = () => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 4.5C10.6632 4.5 11.2991 4.76339 11.7679 5.23223C12.2368 5.70107 12.5002 6.33696 12.5002 7C12.5002 7.66304 12.2368 8.29893 11.7679 8.76777C11.2991 9.23661 10.6632 9.5 10.0002 9.5C9.33712 9.5 8.70124 9.23661 8.23239 8.76777C7.76355 8.29893 7.50016 7.66304 7.50016 7C7.50016 6.33696 7.76355 5.70107 8.23239 5.23223C8.70124 4.76339 9.33712 4.5 10.0002 4.5ZM10.0002 0.75C14.1668 0.75 17.7252 3.34167 19.1668 7C17.7252 10.6583 14.1668 13.25 10.0002 13.25C5.8335 13.25 2.27516 10.6583 0.833496 7C2.27516 3.34167 5.8335 0.75 10.0002 0.75ZM2.65016 7C3.32371 8.37526 4.36958 9.53396 5.66889 10.3444C6.96821 11.1548 8.46883 11.5844 10.0002 11.5844C11.5315 11.5844 13.0321 11.1548 14.3314 10.3444C15.6307 9.53396 16.6766 8.37526 17.3502 7C16.6766 5.62474 15.6307 4.46604 14.3314 3.65562C13.0321 2.8452 11.5315 2.41557 10.0002 2.41557C8.46883 2.41557 6.96821 2.8452 5.66889 3.65562C4.36958 4.46604 3.32371 5.62474 2.65016 7Z"
      fill="#70adff"
    />
  </svg>
);

export const EyeIcon = (props) => <Icon component={EyeIconSVG} {...props} />;
export const EyeTransparentIcon = (props) => <Icon component={EyeIconTransparentSVG} {...props} />;
