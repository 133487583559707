import React, { ReactNode, useCallback } from 'react';
import TooltipItem from '../../UI/common/components/Tooltip/components/Item/TooltipItem';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import _ from 'underscore';
import styled from 'styled-components/macro';

const { confirm } = Modal;

const LocalMarginIcon = styled.div`
  margin-right: 17px;
`;

const LocalDescr = styled.div``;

export interface ContextItemProps {
  path: string;
  icon: ReactNode;
  group: string;
  dispatchedAction: () => void;
}

interface PrepareMenuContextItemProps {
  el: ContextItemProps;
  dispatchFn: (dispatch: any) => void;
  action: () => void;
}

export const PrepareMenuContextItem = ({ el, dispatchFn, action }: PrepareMenuContextItemProps) => {
  const descriptionEdit = el?.path;
  const icon = el?.icon || null;

  const executeAction = useCallback(() => {
    if (_.isFunction(action)) {
      const result = action();
      dispatchFn(result);
    } else {
      dispatchFn(action);
    }
  }, [action, dispatchFn]);

  const clickTooltip = useCallback(() => {
    if (descriptionEdit === 'Удалить') {
      confirm({
        title: 'Вы действительно желаете удалить объект?',
        icon: <QuestionCircleOutlined />,
        onOk() {
          executeAction();
        },
      });
    } else {
      executeAction();
    }
  }, [descriptionEdit, executeAction]);

  return (
    <TooltipItem onClick={clickTooltip}>
      <LocalMarginIcon>{icon}</LocalMarginIcon>
      <LocalDescr>{descriptionEdit}</LocalDescr>
    </TooltipItem>
  );
};
