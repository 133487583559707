import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { SpeedBumpsMaterialTypeEnum, SpeedBumpsTypeEnum } from '../../../types/enums/map/SelectedItemTypeEnum';

const SpeedBumpsItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'speed_bumps');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.speed_bumps}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];
  Descriptions.push({
    key: 'Год установки',
    value: data?.year ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Адрес',
    value: data?.address ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Материал',
    value: SpeedBumpsMaterialTypeEnum[data?.material] ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Тип',
    value: SpeedBumpsTypeEnum[data?.type_speed_bump] ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Количество промежуточных звеньев',
    value: data?.number_of_links ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Количество конечных звеньев',
    value: data?.number_of_ends ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о лежачем полицейском'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

SpeedBumpsItemSelection.propTypes = {};

export default SpeedBumpsItemSelection;
