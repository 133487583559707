import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

// Меню согласования маршрута Росавтодор
export const menuTabFederalStatement: IOPWDirectory[] = [
  IOPWDirectory.cancels,
  IOPWDirectory.additional_inspections,
  IOPWDirectory.files,
  IOPWDirectory.vehicles,
  IOPWDirectory.adapter_messages,
  IOPWDirectory.control_stations,
  IOPWDirectory.calc_of_harm,
  IOPWDirectory.history_change_statement,
  IOPWDirectory.billings,
];
// Меню заявлений ФЛ и ЮЛ
export const menuTabIPORGStatement: IOPWDirectory[] = [
  IOPWDirectory.files,
  IOPWDirectory.calc_of_harm,
  IOPWDirectory.billings,
  IOPWDirectory.approvals,
  IOPWDirectory.history_change_statement,
];

export const menuActs: IOPWDirectory[] = [IOPWDirectory.forms_account];
