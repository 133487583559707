import React, { Dispatch, useCallback } from 'react';
import DisplayedLayerItem from './DisplayedLayerItem';
import { connect } from 'react-redux';
import { removeLayer, selectMapboxLayer, toggleLayerVisibility } from 'store/reducers/map/actions/mapboxLayersActions';
import {
  closeAllAdditionalPanels,
  setLayerDataAccessor,
  toggleLayerDataAccessorVisibility,
  toggleLayerSettingsVisibility,
} from 'store/reducers/map/actions/mapPanelsActions';
import { closeSocketConnection } from 'store/reducers/map/socketsStatus';
import { getSelectedMapboxLayer } from 'store/reducers/map/mapboxLayers';
import { initGetRelatedDataForLayer } from 'store/sagas/map/getRelatedDataForLayerSaga';
import { getRegisteredLayer } from 'registrators/map/layers/layersRegistrator';
import { clearMapSelectedObject } from 'store/reducers/map/actions/mapSelectedObjectActions';
import { StateModel } from 'store/reducers';
import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';
import { Layers } from 'types/enums/map/layers/Layers';
import { AnyObject } from 'types/enums/general/general.model';
import { DataAccessMethod } from 'types/enums/routes/request/DataAccessMethod.model';
import { MainDataHttpPrototype } from 'registrators/map/layers/description/mainData/prototype/MainDataHttpPrototype';
import { MainDataSocketPrototype } from 'registrators/map/layers/description/mainData/prototype/MainDataSocketPrototype';

interface DisplayedLayerItemContainerProps {
  item: LayerPrototype;
  handleToggleLayerVisibility: (layer: LayerPrototype) => void;
  handleToggleLayerSettingsVisibility: (bool?: boolean) => void;
  dispatchRemoveLayer: (layer: Layers) => void;
  closeSocket: (type: string, layer: Layers, name: Layers) => void;
  handlerSelectMapboxLayer: (layer: LayerPrototype) => void;
  isSettingsPanelVisible?: boolean;
  handlerSetLayerDataAccessor: (layer: any) => void;
  handlerToggleLayerDataAccessorVisibility: (bool?: boolean) => void;
  isLayerDataAccessorVisible?: boolean;
  handleCloseAllAdditionalPanels: () => void;
  currentDataAccessor: any;
  selectedMapboxLayer?: LayerPrototype;
  handlerClearMapSelectedObject: () => void;
  selectedObject: AnyObject;
}

const DisplayedLayerItemContainer = ({
  item,
  handleToggleLayerVisibility,
  handleToggleLayerSettingsVisibility,
  dispatchRemoveLayer,
  closeSocket,
  handlerSelectMapboxLayer,
  isSettingsPanelVisible,
  handlerSetLayerDataAccessor,
  handlerToggleLayerDataAccessorVisibility,
  isLayerDataAccessorVisible,
  handleCloseAllAdditionalPanels,
  currentDataAccessor,
  selectedMapboxLayer,
  handlerClearMapSelectedObject,
  selectedObject,
}: DisplayedLayerItemContainerProps) => {
  const isCurrentDataAccessorForThisItem = currentDataAccessor?.selectedType === item?.dataAccessor?.selectedType;
  const isSelectedMapboxLayerForThisItem = selectedMapboxLayer?.getName() === item.name;

  const toggleLayerVisible = useCallback(() => handleToggleLayerVisibility(item), [handleToggleLayerVisibility, item]);

  const selectDataForLayer = useCallback(() => {
    if (isCurrentDataAccessorForThisItem) {
      handlerToggleLayerDataAccessorVisibility();
    } else {
      handlerSetLayerDataAccessor(item.getDataAccessor());
      handlerToggleLayerDataAccessorVisibility(true);
    }
  }, [handlerSetLayerDataAccessor, handlerToggleLayerDataAccessorVisibility, isCurrentDataAccessorForThisItem, item]);

  const handleRemoveLayer = useCallback(
    (layer: LayerPrototype) => {
      dispatchRemoveLayer(layer.name);
      handleCloseAllAdditionalPanels();
      const layerInstance = getRegisteredLayer(layer.name);
      const layerMainData = layerInstance?.getMainData();

      if (layerMainData) {
        layerMainData.forEach((currentMainData: MainDataHttpPrototype | MainDataSocketPrototype) => {
          const dataGetterType = currentMainData.dataAccessMethod;
          if (dataGetterType === DataAccessMethod.websocket && 'getRelatedDataKey' in currentMainData) {
            const relatedDataKey = currentMainData.getRelatedDataKey();
            // @ts-ignore несовпадение типов сокетов TODO: понять что вообще нужно в этих мэйн датах Тех-долг-FRONTEND #5790
            closeSocket('mapLayer', layer.name, relatedDataKey);
          }
        });
      }
    },
    [closeSocket, dispatchRemoveLayer, handleCloseAllAdditionalPanels]
  );

  const onItemWrapperClick = useCallback(() => {
    handlerSelectMapboxLayer(item);
  }, [handlerSelectMapboxLayer, item]);

  const selectMapboxLayerWithSideEffect = useCallback(
    (layer: LayerPrototype) => {
      if (isSelectedMapboxLayerForThisItem) {
        handleToggleLayerSettingsVisibility();
      } else {
        handlerSelectMapboxLayer(layer);
        handleToggleLayerSettingsVisibility(true);
      }
    },
    [handleToggleLayerSettingsVisibility, handlerSelectMapboxLayer, isSelectedMapboxLayerForThisItem]
  );

  return (
    <DisplayedLayerItem
      item={item}
      toggleLayerVisible={toggleLayerVisible}
      selectDataForLayer={selectDataForLayer}
      handleRemoveLayer={handleRemoveLayer}
      onItemWrapperClick={onItemWrapperClick}
      isSelected={isSelectedMapboxLayerForThisItem}
      selectMapboxLayerWithSideEffect={selectMapboxLayerWithSideEffect}
      isSettingsPanelVisible={isSettingsPanelVisible}
      isLayerDataAccessorVisible={isLayerDataAccessorVisible}
      isCurrentDataAccessorForThisItem={isCurrentDataAccessorForThisItem}
      handlerClearMapSelectedObject={handlerClearMapSelectedObject}
      selectedObject={selectedObject}
    />
  );
};

const mapStateToProps = (state: StateModel) => {
  return {
    enabledLayers: state.mapboxLayers,
    isSettingsPanelVisible: state.mapPanels?.layerSettingVisible,
    currentDataAccessor: state.mapPanels.dataAccessor,
    isLayerDataAccessorVisible: state.mapPanels?.dataAccessorVisible,
    selectedMapboxLayer: getSelectedMapboxLayer(state),
    selectedObject: state.mapSelectedObject,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    handlerClearMapSelectedObject: () => dispatch(clearMapSelectedObject()),
    handleToggleLayerVisibility: (layer: LayerPrototype) => dispatch(toggleLayerVisibility(layer)),
    handleToggleLayerSettingsVisibility: (bool?: boolean) => dispatch(toggleLayerSettingsVisibility(bool)),
    getRelatedDataForLayer: (layer: LayerPrototype) => dispatch(initGetRelatedDataForLayer(layer)),
    dispatchRemoveLayer: (layer: Layers) => dispatch(removeLayer(layer)),
    closeSocket: (type: string, layer: Layers, name: Layers) => dispatch(closeSocketConnection(type, layer, name)),
    handlerSelectMapboxLayer: (layer: LayerPrototype) => dispatch(selectMapboxLayer(layer)),
    handlerSetLayerDataAccessor: (layer: LayerPrototype) => dispatch(setLayerDataAccessor(layer)),
    handlerToggleLayerDataAccessorVisibility: (bool?: boolean) => dispatch(toggleLayerDataAccessorVisibility(bool)),
    handleCloseAllAdditionalPanels: () => dispatch(closeAllAdditionalPanels()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayedLayerItemContainer);
