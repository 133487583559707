export const mapboxDefaultMapStylesEnum = {
  collection: [
    { label: 'Дорожная карта V10', value: 'mapbox://styles/mapbox/streets-v10' },
    { label: 'Дорожная карта V11', value: 'mapbox://styles/mapbox/streets-v11' },
    { label: 'Карта общего назначения V10', value: 'mapbox://styles/mapbox/outdoors-v10' },
    { label: 'Светлая дорожная карта V9', value: 'mapbox://styles/mapbox/light-v9' },
    { label: 'Тёмная дорожная карта V9', value: 'mapbox://styles/mapbox/dark-v9' },
    { label: 'Тёмная дорожная карта V10', style: 'mapbox://styles/mapbox/dark-v10' },
    { label: 'Спутниковая карта V9', value: 'mapbox://styles/mapbox/satellite-v9' },
    {
      label: 'Спутниковая карта + дорожная карта V10',
      value: 'mapbox://styles/mapbox/satellite-streets-v10',
    },
    {
      label: 'Навигатор, день V4',
      value: 'mapbox://styles/mapbox/navigation-preview-day-v4',
    },
    {
      label: 'Навигатор, ночь V4',
      value: 'mapbox://styles/mapbox/navigation-preview-night-v4',
    },
    {
      label: 'Навигатор упрощённая, день V4',
      value: 'mapbox://styles/mapbox/navigation-guidance-day-v4',
    },
    {
      label: 'Навигатор упрощённая, ночь V4',
      value: 'mapbox://styles/mapbox/navigation-guidance-night-v4',
    },
  ],
  selectItem(enumerate, value) {
    const suitable = enumerate.find((el) => el.value === value);
    if (suitable) return suitable.label;
    return 'none';
  },
};
