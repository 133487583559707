import React, { useEffect, useMemo, useState } from 'react';
import { getFromURL } from '../../../api/getFromURL';
import CompendiumSelectedContainer from '../../../UI/custom/Aggrid/Compendium/CompendiumSelectedContainer';
// @ts-ignore
import styled from 'styled-components/macro';
import { Radio } from 'antd';

export enum PublicTransportBusStopSelectionGrids {
  count = 'count',
  schedule = 'schedule',
  passenger_traffic = 'passenger_traffic',
  stop_information = 'stop_information',
}

const LocalStyledContainer = styled.div`
  width: 100%;
`;

const BusStopButtonGroup = styled.div`
  * {
    border: none;
    text-transform: none !important;
    &:before,
    &:after {
      display: none !important;
    }
  }
  .ant-radio-button-wrapper {
    display: inline-block;
    width: auto;
    border-radius: 8px;
    margin-left: 6px;
    border: none;
    background: transparent;
    color: #6b8ae4;
    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 140%;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: #6b8ae4 !important;
    color: #fff !important;
    &:hover {
      color: #fff !important;
    }
  }
`;

const PublicTransportBusStopSelection = (props: any) => {
  const [routeData, setRouteData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [stopData, setStopData] = useState([]);
  const [activeGrid, setActiveGrid] = useState<PublicTransportBusStopSelectionGrids>(
    PublicTransportBusStopSelectionGrids.count
  );
  const { data } = props;
  const { checkpoint_id } = data?.object;
  const { name } = data?.object;

  const modelCount = useMemo(
    () => ({
      scheme: {
        route_number: {
          label: 'Номер маршрута',
          read_only: true,
          required: false,
          type: 'string',
        },
        route_name: {
          label: 'Название маршрута',
          read_only: true,
          required: false,
          type: 'string',
        },
        organization_name: {
          label: 'Перевозчик',
          read_only: true,
          required: false,
          type: 'string',
        },
        MO: {
          label: 'Понедельник',
          read_only: true,
          required: false,
          type: 'integer',
        },
        TU: {
          label: 'Вторник',
          read_only: true,
          required: false,
          type: 'integer',
        },
        WE: {
          label: 'Среда',
          read_only: true,
          required: false,
          type: 'integer',
        },
        TH: {
          label: 'Четверг',
          read_only: true,
          required: false,
          type: 'integer',
        },
        FR: {
          label: 'Пятница',
          read_only: true,
          required: false,
          type: 'integer',
        },
        SA: {
          label: 'Суббота',
          read_only: true,
          required: false,
          type: 'integer',
        },
        SU: {
          label: 'Воскресение',
          read_only: true,
          required: false,
          type: 'integer',
        },
      },
    }),
    []
  );

  const modelSchedule = useMemo(
    () => ({
      scheme: {
        route_number: {
          label: 'Номер маршрута',
          read_only: true,
          required: false,
          type: 'string',
        },
        route_name: {
          label: 'Название маршрута',
          read_only: true,
          required: false,
          type: 'string',
        },
        organization_name: {
          label: 'Перевозчик',
          read_only: true,
          required: false,
          type: 'string',
        },
        MO: {
          label: 'Понедельник',
          read_only: true,
          required: false,
          type: 'busScheduleArray',
        },
        TU: {
          label: 'Вторник',
          read_only: true,
          required: false,
          type: 'busScheduleArray',
        },
        WE: {
          label: 'Среда',
          read_only: true,
          required: false,
          type: 'busScheduleArray',
        },
        TH: {
          label: 'Четверг',
          read_only: true,
          required: false,
          type: 'busScheduleArray',
        },
        FR: {
          label: 'Пятница',
          read_only: true,
          required: false,
          type: 'busScheduleArray',
        },
        SA: {
          label: 'Суббота',
          read_only: true,
          required: false,
          type: 'busScheduleArray',
        },
        SU: {
          label: 'Воскресение',
          read_only: true,
          required: false,
          type: 'busScheduleArray',
        },
      },
    }),
    []
  );

  const modelStop = useMemo(
    () => ({
      scheme: {
        name: {
          label: 'Наименование остановки',
          read_only: true,
          required: false,
          type: 'string',
        },
        has_wifi: {
          label: 'Наличие wi-fi',
          read_only: true,
          required: false,
          type: 'boolean',
        },
        has_station_platform: {
          label: 'Наличие платформы',
          read_only: true,
          required: false,
          type: 'boolean',
        },
        has_pavilion: {
          label: 'Наличие павильона',
          read_only: true,
          required: false,
          type: 'boolean',
        },
        has_bin: {
          label: 'Наличие мусорной корзины',
          read_only: true,
          required: false,
          type: 'boolean',
        },
      },
    }),
    []
  );

  const modelPassenger = useMemo(
    () => ({
      scheme: {
        // TODO заполнить модель для AGGrid при наличии понимяния какие столбцы необходимы Тех-долг-FRONTEND #5792
      },
    }),
    []
  );

  const title = useMemo(() => {
    switch (activeGrid) {
      case PublicTransportBusStopSelectionGrids.count:
        return `Информация об остановке ${name ?? ''}`;
      case PublicTransportBusStopSelectionGrids.schedule:
        return `Расписание остановки ${name ?? ''}`;
      case PublicTransportBusStopSelectionGrids.passenger_traffic:
        return `Информация о пасажиропотоке ${name ?? ''}`;
      case PublicTransportBusStopSelectionGrids.stop_information:
        return `Информация об остановке ${name ?? ''}`;
      default:
        return 'Поле отсутствует';
    }
  }, [activeGrid, name]);

  useEffect(() => {
    setRouteData([]);
    setScheduleData([]);
    if (checkpoint_id) {
      getFromURL
        .getWithParams('/public_transport/count_trips_by_checkpoint/', {
          checkpoint_id,
        })
        .then(({ results }) => setRouteData(results));
      // TODO тут моковые данные Тех-долг-FRONTEND #5792
      getFromURL
        .getWithParams('/public_transport/schedules_times_by_checkpoint/', {
          checkpoint_id,
        })
        .then((data) => {
          setScheduleData(data?.results ?? []);
        });
      // TODO разблокировать когда появиться чекпоинт для пасажиропотока Тех-долг-FRONTEND #5792
      // getFromURL
      //   .getWithParams('/public_transport/schedules_times_by_checkpoint/', {
      //     checkpoint_id,
      //   })
      //   .then((data) => {
      //     setPassengerData(data?.results ?? []);
      //   });
      getFromURL
        .getWithParams('/public_transport/checkpoint/', {
          checkpoint_id,
        })
        .then((data) => {
          setStopData(data?.results ?? []);
        });
    }
  }, [checkpoint_id]);

  const buttonGroup = useMemo(
    () => (
      <BusStopButtonGroup>
        <Radio.Group value={activeGrid} buttonStyle="solid">
          <Radio.Button
            value={PublicTransportBusStopSelectionGrids.count}
            onClick={() => setActiveGrid(PublicTransportBusStopSelectionGrids.count)}
          >
            Количество
          </Radio.Button>
          <Radio.Button
            value={PublicTransportBusStopSelectionGrids.passenger_traffic}
            onClick={() => setActiveGrid(PublicTransportBusStopSelectionGrids.passenger_traffic)}
          >
            Пассажиропоток
          </Radio.Button>
          <Radio.Button
            value={PublicTransportBusStopSelectionGrids.schedule}
            onClick={() => setActiveGrid(PublicTransportBusStopSelectionGrids.schedule)}
          >
            Расписание
          </Radio.Button>
          <Radio.Button
            value={PublicTransportBusStopSelectionGrids.stop_information}
            onClick={() => setActiveGrid(PublicTransportBusStopSelectionGrids.stop_information)}
          >
            Информация по остановке
          </Radio.Button>
        </Radio.Group>
      </BusStopButtonGroup>
    ),
    [activeGrid]
  );

  return (
    <LocalStyledContainer>
      {activeGrid === PublicTransportBusStopSelectionGrids.count && (
        <CompendiumSelectedContainer
          data={routeData}
          model={modelCount}
          title={title}
          agGridThemeName={'ag-theme-custom-base ag-grid-theme-custom-map'}
          renderButtons={buttonGroup}
        />
      )}
      {activeGrid === PublicTransportBusStopSelectionGrids.passenger_traffic && (
        <CompendiumSelectedContainer
          data={[]}
          model={modelPassenger}
          title={title}
          agGridThemeName={'ag-theme-custom-base ag-grid-theme-custom-map'}
          renderButtons={buttonGroup}
        />
      )}
      {activeGrid === PublicTransportBusStopSelectionGrids.stop_information && (
        <CompendiumSelectedContainer
          data={stopData}
          model={modelStop}
          title={title}
          agGridThemeName={'ag-theme-custom-base ag-grid-theme-custom-map'}
          renderButtons={buttonGroup}
        />
      )}
      {activeGrid === PublicTransportBusStopSelectionGrids.schedule && (
        <CompendiumSelectedContainer
          data={scheduleData}
          model={modelSchedule}
          title={title}
          agGridThemeName={'ag-theme-custom-base ag-grid-theme-custom-map'}
          renderButtons={buttonGroup}
          cellSizes
        />
      )}
    </LocalStyledContainer>
  );
};

export default PublicTransportBusStopSelection;
