import { LayerGroupsPrototype } from './prototype/LayerGroupsPrototype';
import { LayerGroupLabel, LayerGroupName, LayerGroupLabelDecryption } from 'types/enums/map/layers/LayerGroupsEnums';

export class UdsLayerGroup extends LayerGroupsPrototype {
  constructor() {
    super({
      label: LayerGroupLabel.uds,
      name: LayerGroupName.uds,
      decryption: LayerGroupLabelDecryption.uds,
    });
  }
}
