import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import { serviceOptions, UsersService } from 'generated/api/api';
import securedAxiosInstance from 'services/axiosInstances/axiosSecured';
import { authenticationActions } from 'store/reducers/global/auth/auth.actions';
import { useHistory } from 'react-router';

serviceOptions.axios = securedAxiosInstance;

export const useProtect = () => {
  const { permissions, initialized } = useSelector((state: StateModel) => state.auth);
  const user = localStorage.getItem('user');
  const dispatch = useDispatch();
  const history = useHistory();

  const uploadPermissionForUser = useCallback(() => {
    UsersService.usersMeRetrieve().then((response) => {
      dispatch(authenticationActions.login(response));
    });
    UsersService.usersMePermissionsRetrieve().then((response) => {
      dispatch(authenticationActions.applyPermissions(response));
    });
  }, [dispatch]);

  useEffect(() => {
    if (user && !initialized) {
      uploadPermissionForUser();
    }
  }, [dispatch, initialized, permissions, uploadPermissionForUser, user]);

  if (!user) {
    history.replace('/login');
  }
};
