import React from 'react';
import RoadAccident from './RoadAccident';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

import { getFromURL } from '../../../../../../../../api/getFromURL';
import { postToURLSingle } from '../../../../../../../../api/postToURLSingle';
import withContentPadding from '../../../../../../../../HOC/withContentPadding';

import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { saveAs } from 'file-saver';

// TODO еще один мусорный файл?? Тех-долг-FRONTEND #5589
const RoadAccidentContainer = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports);

  const sendData = () => {
    async function postData() {
      const response = await getFromURL.getDataFromURL('/reports/road_accidents');
      const json = await response;
      const rewriteJson = { ...json };
      const { email, checkedItems, extension, timeStart, timeEnd } = reports;
      rewriteJson.email = email;
      rewriteJson.mime_type = extension;
      rewriteJson.periodicity = 'bla-bla-bla'; //это поле требуется бэку, на результат не влияет
      rewriteJson.filters.road_id.value = checkedItems;
      rewriteJson.filters.datetime.value = [timeStart, timeEnd];
      try {
        const postResult = await postToURLSingle.postDataReports('/reports/road_accidents', rewriteJson, extension);
        if (extension === 'text/html') {
          const win = window.open(`about:blank`, '_blank');
          win.document.write(postResult);
          win.focus();
        }
        if (extension === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          const result = await postToURLSingle.postDataReports('/reports/road_accidents', rewriteJson, extension, true);
          const blob = new Blob([result], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, 'report.xls');
        }
      } catch (e) {
        if (e?.response && _.isObject(e.response)) {
          const message = createAlertMessage(e);
          dispatch(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: 'Ошибка',
              message: message,
            })
          );
        }
      } finally {
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 9000);
      }
    }
    postData();
  };

  return (
    <div>
      <RoadAccident sendData={sendData} />
    </div>
  );
};

const enhance = compose(withContentPadding, connect(null, null));
export default enhance(RoadAccidentContainer);
