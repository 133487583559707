import moment from 'moment';
import { approvalsStatusChoises } from 'types/enums/directories/approvalsStatusChoises';
import { AnyObject } from 'types/enums/general/general.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import _ from 'underscore';

import { CargoTransportParams, ModelGroup } from '../interfacesCargoTransport/interfacesCargoTransport';
import {
  groupVehicleOwnerIp,
  groupVehicleOwnerOrg,
  hiddenField,
  modelSchemaNotReadOnlyKeys,
} from '../schemesGroups/statementsChageingGroups';

const FEDERAL_DISTRICT_NUMBER = 6;

export const getCustomizableModelAndData = (
  model: ModelResponse,
  data: any,
  params: CargoTransportParams,
  values: any,
  recommendedHarmCalc: number | null,
  responsiblePersonsData: any[]
) => {
  const { statement, scoreMode, directory, scoreId } = params;
  // Дописываем * во всех label где required === true (при необходимости можно уменьшить длину строки)
  const newData: AnyObject = data;
  if (model?.scheme) {
    Object.keys(model.scheme).forEach((key) => {
      if (model.scheme[key].required && !model.scheme[key].label.includes('*')) {
        model.scheme[key].label += '*';
      }
    });
  }

  // Модуль настройки информации об осях
  if (data && model?.scheme?.vehicle_parameters) {
    data.vehicle_parameters = scoreId;
    model.scheme.vehicle_parameters.read_only = true;
  }

  // Модуль прописывания заявления в формировании счетов, работы с заявками и расчете вреда
  if (data && model?.scheme?.statement && params.statement && params.statement !== 'create') {
    data.statement = +params.statement;
    model.scheme.statement.read_only = true;
  }

  // Настройка по владельцу ТС
  const calculationFieldStatemente = (firstGroup: ModelGroup, secondGroup: ModelGroup) => {
    model.groups?.splice(
      model.groups.findIndex((group: ModelGroup) => group.label === 'Данные о владельце ТС'),
      1,
      firstGroup
    );

    secondGroup.fields.forEach((field) => {
      if (!firstGroup.fields.some((fieldIp) => fieldIp === field)) model.scheme[field].type = SchemaFieldType.hidden;
    });
    firstGroup.fields.forEach((field) => {
      if (!secondGroup.fields.some((fieldOrg) => fieldOrg === field)) model.scheme[field].type = SchemaFieldType.string;
    });
  };

  // Модуль настройки заявлениий скрытие полей created_at, modified_at, uuid_statement
  if (
    model?.scheme &&
    model?.groups &&
    (directory === IOPWDirectory.individual_statements || directory === IOPWDirectory.entity_statements) &&
    !scoreMode
  ) {
    // TODO временно!!! Тех-долг-FRONTEND #5581

    if (model.groups[6].fields.length)
      model.groups[6].fields = [
        'weight_road_train',
        'weight_road_train_without_cargo',
        'is_heavy_vehicle',
        'vehicle_parameters',
      ];

    // Проверяем выбран ли правильный юсер для разблокировки полей
    const responsibleUser = values.responsible_user ?? data.responsible_user;
    const responsiblePerson = responsiblePersonsData.find((person) => person.id === responsibleUser);
    const selectRightPerson = responsiblePerson?.username === localStorage.getItem('user');
    // Настройка полей
    Object.keys(model.scheme).forEach((key) => {
      if (model.scheme[key].type === 'inn') model.scheme[key].type = SchemaFieldType.inn_statement;
      if (key === 'description_route' || key === 'special_driving_conditions')
        model.scheme[key].type = SchemaFieldType.string_long_field;
      if (hiddenField.some((field) => field === key)) model.scheme[key].type = SchemaFieldType.hidden;
      if ('uuid_statement' === key) model.scheme[key].type = SchemaFieldType.string;
      if (!selectRightPerson && key !== 'responsible_user') {
        if (model.scheme[key].read_only === false) {
          modelSchemaNotReadOnlyKeys.push(key);
          model.scheme[key].read_only = true;
        }
      } else if (modelSchemaNotReadOnlyKeys.some((el) => el === key)) {
        model.scheme[key].read_only = false;
      }
      if (key.includes('phone')) model.scheme[key].type = SchemaFieldType.phone;
    });
    // Меняем название типа для настройки окна осей ТС
    if (model.scheme.vehicle_parameters) model.scheme.vehicle_parameters.type = SchemaFieldType.vehicle_parameters;

    if (_.isObject(data)) {
      // Вид перевозки местная по умолчанию
      if (!data.transportation_type) {
        data.transportation_type = 'Local';
        values.transportation_type = 'Local';
      }
      // автоматически заполняем тип документа
      if (!data.type_document) {
        data.type_document = 'PASSPORT';
        values.type_document = 'PASSPORT';
      }
      // Настройка если владелец и заявитель одно лицо
      if (values.is_vehicle_owner) {
        if (directory === IOPWDirectory.individual_statements) {
          data.vehicle_owner_first_name = values.first_name;
          data.vehicle_owner_surname = values.sur_name;
          data.vehicle_owner_middle_name = values.middle_name;
          data.vehicle_owner_inn = values.inn;
          data.vehicle_owner_address = values.address;
          data.vehicle_owner_phone = values.phone;
          data.vehicle_owner_ogrnip = values.ogrnip;
          data.type_of_vehicle_owner = 'RF';
          values.type_of_vehicle_owner = 'RF';
        } else {
          data.vehicle_company_name = values.name;
          data.vehicle_owner_ogrnip = values.ogrn;
          data.vehicle_company_first_name = values.full_name;
          data.vehicle_company_phone = values.phone;
          data.vehicle_company_email = values.email;
          data.vehicle_owner_inn = values.inn;
          data.vehicle_company_address = values.address;
          data.type_of_vehicle_owner = 'UL';
          values.type_of_vehicle_owner = 'UL';
        }
        model.scheme.type_of_vehicle_owner.read_only = true;
        data.is_vehicle_owner = values.is_vehicle_owner;
      } else if (!values.is_vehicle_owner) {
        model.scheme.type_of_vehicle_owner.read_only = false;
        data.is_vehicle_owner = values.is_vehicle_owner;
      }

      // Настройка по владельцу ТС продолжение
      if (values.type_of_vehicle_owner === 'RF') {
        calculationFieldStatemente(groupVehicleOwnerIp, groupVehicleOwnerOrg);
      } else if (values.type_of_vehicle_owner === 'UL') {
        calculationFieldStatemente(groupVehicleOwnerOrg, groupVehicleOwnerIp);
      }
    }
  }

  if (scoreMode === IOPWDirectory.calc_of_harm && _.isArray(data) && values?.road) {
    const selectedRoad = data.find((road) => road?.id === values?.road);
    newData.length_road = selectedRoad?.length;
    if (!newData?.road_type) {
      newData.road_type = selectedRoad?.importance;
    }
    if (!newData.coef_federal_district) {
      // tslint:disable-next-line:no-magic-numbers
      newData.coef_federal_district = FEDERAL_DISTRICT_NUMBER;
    }
    if (!newData.coef_of_climatic_zones) {
      newData.coef_of_climatic_zones = '0.35';
    }
    if (!newData.formula_type) {
      newData.formula_type = 'FIRST';
    }
    if (!newData.normative_load) {
      newData.normative_load = 1; // Автоматически заполняем поле нагрузка на ось 10т
    }
  }

  // Настройка окна "создания счетов"
  if (scoreMode === IOPWDirectory.billings && model?.scheme) {
    if (model.scheme.statement_name?.type) {
      model.scheme.statement_name.type = SchemaFieldType.hidden;
    }

    // Настройка размера полей
    Object.keys(model.scheme).forEach((key) => {
      if (key === 'errors' || key === 'payment_type_name') {
        model.scheme[key].type = SchemaFieldType.string_long_field;
      }
      if (values.payment_method === 'SITE' && (key === 'unique_id' || key === 'is_paid' || key === 'amount_paid')) {
        model.scheme[key].read_only = true;
      } else if (
        values.payment_method !== 'SITE' &&
        (key === 'unique_id' || key === 'is_paid' || key === 'amount_paid')
      ) {
        model.scheme[key].read_only = false;
      }
    });

    if (Array.isArray(data)) {
      // Скрываем поле УИН в случае первичного заполнения счета
      if (!newData?.unique_id && model?.scheme?.unique_id?.type) {
        model.scheme.unique_id.type = SchemaFieldType.hidden;
      }

      if (!newData?.created_at && !values?.created_at) {
        newData.created_at = moment().format('YYYY-MM-DD');
      }

      // Определение значения статичных полей в зависимости от вида платежа
      if (values?.payment_type && data?.length && model?.scheme) {
        const paymentTypeData = data.find((type) => type.id === values.payment_type);

        if (paymentTypeData?.id) {
          newData.payment_type_recepient_name = paymentTypeData.recepient_name;
          newData.payment_type_name = paymentTypeData.name;
          newData.payment_type_kpp = paymentTypeData.kpp;
          newData.payment_type_oktmo = paymentTypeData.oktmo;
          newData.payment_type_bik = paymentTypeData.bank_code;
          newData.payment_type_account_number = paymentTypeData.account_number;
          newData.payment_type_budget_classification_code = paymentTypeData.budget_classification_code;
          newData.payment_type_correspondent_account = paymentTypeData.correspondent_account;
          newData.payment_type_okpo = paymentTypeData.okpo;
          newData.payment_type_inn = paymentTypeData.inn;
        }
      }

      // Автозаполнение "суммы платежа" и "оплаченной суммы" во вкладке "Формирование счетов"
      if (data?.length && model?.scheme) {
        const sum = data.reduce((summa, current) => (current?.fact_harm_calc ?? 0) + summa, 0).toFixed(2);
        newData.invoice_amount = sum;
        newData.amount_paid = sum;
      }
    }
  }

  // Настройка окна "работа с заявками на согласование" при первичном заполнении
  if (directory === IOPWDirectory.federal_statements && !_.isEmpty(model?.scheme)) {
    if (!scoreMode && model.scheme.resolution_comment?.type) {
      model.scheme.resolution_comment.type = SchemaFieldType.string_text_area_field;
    }
  }

  // Настройка окна "работа с заявками на согласование" при первичном заполнении
  if (scoreMode === IOPWDirectory.approvals && !_.isEmpty(model?.scheme)) {
    // Настройка полей string related field
    if (!scoreId) {
      // Настройка полей string related field
      Object.keys(model.scheme).forEach((key) => {
        if (
          key === 'performer_user' ||
          key === 'initiator_user' ||
          key === 'status' ||
          key === 'performer_comment' ||
          key === 'desc_route_performer' ||
          key === 'created_at' ||
          model.scheme[key].type === SchemaFieldType.string_text_area_field
        ) {
          model.scheme[key].type = SchemaFieldType.hidden;
        }
      });
      model.groups = [];
    } else {
      Object.keys(model.scheme).forEach((key) => {
        if (
          model.scheme[key].type === SchemaFieldType.string_related_field &&
          key !== 'statement_name' &&
          key !== 'performer_user_name' &&
          key !== 'initiator_user_name' &&
          key !== 'responsible_organization_name'
        ) {
          model.scheme[key].type = SchemaFieldType.string;
        }
      });
      // Переводим статус согласования
      if (data && data?.status) {
        data.status = approvalsStatusChoises.find((el) => el.value === data.status)?.display_name;
      }
    }
  }

  // Модуль настройки расчете вреда
  if (data && model?.scheme?.recommended_harm_calc && scoreMode === IOPWDirectory.calc_of_harm) {
    if (Number.isFinite(recommendedHarmCalc)) {
      data.recommended_harm_calc = recommendedHarmCalc;
      data.fact_harm_calc = recommendedHarmCalc;
    }
    model.scheme.recommended_harm_calc.read_only = true;
    model.scheme.road.type = SchemaFieldType.string_text_area_field;
    model.scheme.recommended_harm_calc.help_text = 'float_harm_calc';
    model.scheme.fact_harm_calc.help_text = 'float_harm_calc';

    // замена адреса в модели при расчете вреда
    if (!model.scheme.road?.url?.includes('?statement='))
      model.scheme.road.url = `${model.scheme.road.url}?statement=${statement}`;

    if (data.length) {
      data.isOwnerData = data.map((el: any) => (el?.is_owner ? el.id : null)).filter((el: any) => el);
    }
  }
};
