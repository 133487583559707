import { resolver } from './resolver';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import RoadSchemeItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadSchemeItemSelection';
import { DataAccessMethod } from '../../../types/enums/routes/request/DataAccessMethod.model';
import RailCrossingItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RailCrossingItemSelection';
import TrafficLightItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/TrafficLightItemSelection';
import PipesItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/PipesItemSelection';
import LightingItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/LightingItemSelection';
import RestAreasCrossingItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RestAreasCrossingItemSelection';
import WeatherStationsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/WeatherStationsItemSelection';
import PipeLinesItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/PipeLinesItemSelection';
import TunnelsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/TunnelsItemSelection';
import FerryCrossingsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/FerryCrossingsItemSelection';
import RoadAccidentsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadAccidentsItemSelection/RoadAccidentsItemSelection';
import RoadQualitySegmentSelection from '../../../layers/SelectedObjectsRepresentations/RoadQualitySegmentSelection/RoadQualitySegmentSelection';
import RoadSignItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadSignItemSelection';
import ViolationsWarrantyPeriodSelection from '../../../layers/SelectedObjectsRepresentations/RoadQualitySegmentSelection/ViolationsWarrantyPeriodSelection';
import RoadTechnicalCharacteristicsSelection from '../../../layers/SelectedObjectsRepresentations/RoadTechnicalCharacteristicsSelection/RoadTechnicalCharacteristicsSelection';
import PublicTransportBusStopSelection from '../../../layers/SelectedObjectsRepresentations/PublicTransportBusStopSelection/PublicTransportBusStopSelection';
import BridgesItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/BridgesItemSelection';
import OrderRoutePathSelection from '../../../layers/SelectedObjectsRepresentations/OrderRouteSelection/OrderRoutePathSelection';
import OverpassesSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/OverpassesSelection';
import AxleLoadOverloadsSelection from '../../../layers/SelectedObjectsRepresentations/OrderRouteSelection/AxleLoadOverloadsSelection';
import LineTrollItemSelection from '../../../layers/SelectedObjectsRepresentations/TrolleybusItemSelection/LineTrollItemSelection';
import SupplyCablesTrollItemsSelection from '../../../layers/SelectedObjectsRepresentations/TrolleybusItemSelection/SupplyCablesTrollItemsSelection';
import LineTramItemSelection from '../../../layers/SelectedObjectsRepresentations/TramItemSelection/LineTramItemSelection';
import SupplyLineTramItemSelection from '../../../layers/SelectedObjectsRepresentations/TramItemSelection/SupplyLineTramItemSelection';
import ElectricalSubstationsSelection from '../../../layers/SelectedObjectsRepresentations/ElectricalSubstationsItemSelection/ElectricalSubstationsSelection';
import RoadMarkingsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadMarkingsItemSelection';
import BusStopstemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/BusStopstemSelection';
import RoadWaysItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadWaysItemSelection';
import SupportWallItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/SupportWallItemSelection';
import RoadFencesItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadFencesItemSelection';
import EngineeringConstructionsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/EngineeringConstructionsItemSelection';
import SupportPillarsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/SupportPillarsItemSelection';
import SidewalkItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/SidewalkItemSelection';
import RoadsDividingLinesItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadsDividingLinesItemSelection';
import TramRailsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/TramRailsItemSelection';
import RoadMarkingPolygonItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadMarkingPolygonItemSelection';
import RoadMarkingLinesItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadMarkingLinesItemSelection';
import TrafficLightsObjectsSelection from '../../../layers/SelectedObjectsRepresentations/TrafficLightsObjectsSelection/TrafficLightsObjectsSelection';
import PublicTransportItemCard from '../../../layers/SelectedObjectsRepresentations/PublicTransportBusStopSelection/PublicTransportItemCard';
import TransportOrdersCompositeLayer from '../../../layers/CompositeLayers/ekb/TransportOrders/TransportOrdersCompositeLayer';
import SpeedBumpsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/SpeedBumpsItemSelection';
import CabinetsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/CabinetsItemSelection';
import DimensionsHeightOversizesSelection from '../../../layers/SelectedObjectsRepresentations/OrderRouteSelection/DimensionsHeightOversizesSelection';
import DimensionsWidthOversizesSelection from '../../../layers/SelectedObjectsRepresentations/OrderRouteSelection/DimensionsWidthOversizesSelection';
import CamerasAndDetectorsItemSelection from '../../../layers/SelectedObjectsRepresentations/CamerasAndDetectorsItemSelection/CamerasAndDetectorsItemSelection';
import SignalPolesItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/SignalPolesItemSelection';
import RoadTraySystemsItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadTraySystemsItemSelection';
import RoadPedBordersItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadPedBordersItemSelection';
import GeneralDimensionsHeightWidthOversizesSelection from '../../../layers/SelectedObjectsRepresentations/OrderRouteSelection/GeneralDimensionsHeightWidthOversizesSelection';
import TownSquaresItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/TownSquaresItemSelection';
import ContoursLawnItemSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/ContoursLawnItemSelection';
import IlluminatedPedestrianCrossingsItemSelection from '../../../layers/SelectedObjectsRepresentations/IlluminatedPedestrianCrossingsItemSelection/IlluminatedPedestrianCrossingsItemSelection';
import CityDistrictsSelection from '../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/CityDistrictsSelection';
import { TrafficManagementProjectsCard } from 'layers/SelectedObjectsRepresentations/TrafficManagementProjectsCard';
import BrigadeApplicationsSelection from 'layers/SelectedObjectsRepresentations/BrigadeApplicationsSelection/BrigadeApplicationsSelection';
import ProjectLayerItemSelection from '../../../layers/SelectedObjectsRepresentations/ProjectLayerItemSelection/ProjectLayerItemSelection';
import { RequestPath } from 'types/enums/routes/request/RequestPath';
import BusSheltersSelectionSelection from 'layers/SelectedObjectsRepresentations/BusSheltersSelection/BusSheltersSelectionSelection';
import BrigadeWorkShiftsSelection from '../../../layers/SelectedObjectsRepresentations/BrigadeWorkShiftsSelection/BrigadeWorkShiftsSelection';
import BrigadeTeamsOfWorkersSelection from '../../../layers/SelectedObjectsRepresentations/BrigadeTeamsOfWorkersSelection/BrigadeTeamsOfWorkersSelection';
import CamerasUdcSelection from '../../../layers/SelectedObjectsRepresentations/CamerasUdcSelection/CamerasUdcSelection';
import MonitoringUDSItemSelection from '../../../layers/SelectedObjectsRepresentations/MonitoringUDSSelection/MonitoringUDSItemSelection';

export const resolveSelectedObjectDescription = resolver;

/**
 * Используется в: создание объектов
 * Регистратор для объектов на карте, которые должно быть возможно выбрать.
 * ОБЯЗАТЕЛЬНО зарегистрировать объект, если нужно вызвать его карточку с карты или грида.
 * выбор идёт из mapSelectedObjectSaga, по ключу name выбирается массив modes шз которого будет взято отображение
 * name - имя объекта
 * label - название, которое отобразится в заголовке карточки, если включить функцию переключения между карточками, сейчас не используется
 * urlData - урл из которого возьмутся данные для карточки
 * accessMethod - метод обращения по урлу
 * idKey - ключевое свойство
 * representationPrototype - подключённая карточка - принимает, обрабатывает и выводит данные в карточку
 */

interface ParamsModel {
  name: string;
  value: string;
  path?: string;
}

interface ModesModel {
  name: MapObjects | string;
  label: string;
  urlData: string;
  accessMethod: DataAccessMethod;
  idKey?: string;
  representationPrototype?: (props: any) => JSX.Element;
  params?: ParamsModel[];
}

export interface SelectionMapObjectRegistratorModel {
  name: MapObjects;
  modes: ModesModel[];
  mapLayerPrototype?: any;
  permissionToShow?: any[];
  actions?: any[];
  hideOtherLayers?: any;
}

const generateRightsByKey = (permissionKey: string, application?: string) =>
  ['add', 'change', 'delete', 'view'].map((right) => {
    return `${application ? application + '.' : ''}${right}_${permissionKey}`;
  });

export const selectionMapObjectRegistrator: SelectionMapObjectRegistratorModel[] = [
  {
    name: MapObjects.roadMarkingPolygon,
    modes: [
      {
        name: 'road_marking_polygon',
        label: 'Дорожная разметка полигон',
        urlData: 'roads/road_marking_polygons/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadMarkingPolygonItemSelection,
      },
    ],
  },
  {
    name: MapObjects.roadMarkingLine,
    modes: [
      {
        name: 'road_marking_lines',
        label: 'Дорожная разметка',
        urlData: 'roads/road_marking_lines/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadMarkingLinesItemSelection,
      },
    ],
  },
  {
    name: MapObjects.tramCables,
    modes: [
      {
        name: 'cablesTram',
        label: 'Кабель',
        urlData: 'kns/cables_tram/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: LineTramItemSelection,
      },
    ],
  },
  {
    name: MapObjects.supplyTramCables,
    modes: [
      {
        name: 'Supply_cables_tram',
        label: 'Кабель',
        urlData: 'kns/supply_cables_tram/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: SupplyLineTramItemSelection,
      },
    ],
  },
  {
    name: MapObjects.publicTransportRoute,
    modes: [
      {
        name: 'publicTransportRoute',
        label: 'Маршрут',
        urlData: 'public_transport/route_segments/?route_scheme=',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: PublicTransportItemCard,
      },
    ],
  },
  {
    name: MapObjects.traySystem,
    modes: [
      {
        name: 'traySystem',
        label: 'Лотковые системы',
        urlData: 'roads/tray_systems/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadTraySystemsItemSelection,
      },
    ],
  },

  {
    name: MapObjects.cablesTroll,
    modes: [
      {
        name: 'cablesTroll',
        label: 'Кабель',
        urlData: 'kns/cables_troll/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: LineTrollItemSelection,
      },
    ],
  },
  {
    name: MapObjects.supply_cables_troll,
    modes: [
      {
        name: 'supply_cables_troll',
        label: 'Кабель',
        urlData: 'kns/supply_cables_troll/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: SupplyCablesTrollItemsSelection,
      },
    ],
  },
  {
    name: MapObjects.roadSegment,
    modes: [
      {
        name: 'segment',
        label: 'Дороги и участки',
        urlData: 'roads/segments/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadSchemeItemSelection,
      },
    ],
  },
  {
    name: MapObjects.roadSigns,
    modes: [
      {
        name: 'road_signs',
        label: 'Дорожный знак',
        urlData: RequestPath.roadConstructionsRoadSigns,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadSignItemSelection,
      },
    ],
  },
  {
    name: MapObjects.roadconSign,
    modes: [
      {
        name: 'roadcon_sign',
        label: 'Дорожный знак',
        accessMethod: DataAccessMethod.httpGet,
        urlData: 'roadcons/road_signs/',
        idKey: 'id',
        representationPrototype: RoadSignItemSelection,
      },
    ],
  },
  {
    name: MapObjects.trafficLight,
    modes: [
      {
        name: 'traffic_light',
        label: 'Светофор',
        urlData: 'road_constructions/traffic_lights/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: TrafficLightItemSelection,
      },
    ],
  },
  {
    name: MapObjects.speedBumps,
    modes: [
      {
        name: 'speed_bumps',
        label: 'Лежачий полицейский',
        urlData: 'road_constructions/speed_bumps/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: SpeedBumpsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.bridges,
    modes: [
      {
        name: 'bridges',
        label: 'Мост',
        urlData: 'artificial_structures/bridges/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: BridgesItemSelection,
      },
    ],
  },
  {
    name: MapObjects.overpasses,
    modes: [
      {
        name: 'overpasses',
        label: 'Искусственные сооружения',
        urlData: 'artificial_structures/overpasses/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: OverpassesSelection,
      },
    ],
  },
  {
    name: MapObjects.railCrossings,
    modes: [
      {
        name: 'rail_crossings',
        label: 'ЖД переезд',
        urlData: 'artificial_structures/rail_crossings/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RailCrossingItemSelection,
      },
    ],
  },
  {
    name: MapObjects.improvementElements,
    modes: [
      {
        name: 'improvement_elements',
        label: 'Элементы благоустройства',
        urlData: 'roads/improvement_elements/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RestAreasCrossingItemSelection,
      },
    ],
  },
  {
    name: MapObjects.pipes,
    modes: [
      {
        name: 'pipes',
        label: 'Трубы',
        urlData: RequestPath.tubes,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: PipesItemSelection,
      },
    ],
  },
  {
    name: MapObjects.lighting,
    modes: [
      {
        name: 'lighting',
        label: 'Освещение',
        urlData: 'road_constructions/lighting/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: LightingItemSelection,
      },
    ],
  },
  {
    name: MapObjects.restAreas,
    modes: [
      {
        name: 'rest_areas',
        label: 'Зоны отдыха',
        urlData: 'roads_construction/rest_areas/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RestAreasCrossingItemSelection,
      },
    ],
  },
  {
    name: MapObjects.roadWays,
    modes: [
      {
        name: 'road_ways',
        label: 'Проезжая часть',
        urlData: 'roads/roadways/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadWaysItemSelection,
      },
    ],
  },
  {
    name: MapObjects.sidewalk,
    modes: [
      {
        name: 'sidewalk',
        label: 'Тротуар',
        urlData: 'roads/sidewalks/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: SidewalkItemSelection,
      },
    ],
  },
  {
    name: MapObjects.dividingLines,
    modes: [
      {
        name: 'dividing_lines',
        label: 'Разделительная линия',
        urlData: 'roads/dividing_lines/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadsDividingLinesItemSelection,
      },
    ],
  },
  {
    name: MapObjects.tramRails,
    modes: [
      {
        name: 'tram_rails',
        label: 'Трамвайное полотно',
        urlData: 'public_transport/tramways/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: TramRailsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.engineeringConstructions,
    modes: [
      {
        name: 'engineering_constructions',
        label: 'Инженерное дорожное сооружение',
        urlData: 'engineering_constructions/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: EngineeringConstructionsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.supportPillars,
    modes: [
      {
        name: 'support_pillars',
        label: 'Опорный столб',
        urlData: 'kns/support_pillars/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: SupportPillarsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.contoursLawn,
    modes: [
      {
        name: 'contours_lawn',
        label: 'Контуры газонов',
        urlData: 'roads/contours_lawn/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: ContoursLawnItemSelection,
      },
    ],
  },

  {
    name: MapObjects.townSquares,
    modes: [
      {
        name: 'town_squares',
        label: 'Площади',
        urlData: 'roads/town_squares/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: TownSquaresItemSelection,
      },
    ],
  },

  {
    name: MapObjects.supportWall,
    modes: [
      {
        name: 'support_wall',
        label: 'Подпорная стена',
        urlData: 'road_constructions/support_walls/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: SupportWallItemSelection,
      },
    ],
  },
  {
    name: MapObjects.roadFences,
    modes: [
      {
        name: 'road_fences',
        label: 'Дорожное ограждение',
        urlData: 'roads/road_fences/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadFencesItemSelection,
      },
    ],
  },
  {
    name: MapObjects.pedestrianFences,
    modes: [
      {
        name: 'pedestrian_fences',
        label: 'Пешеходные ограждения',
        urlData: 'road_constructions/ped_borders/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadPedBordersItemSelection,
      },
    ],
  },
  {
    name: MapObjects.weatherStations,
    modes: [
      {
        name: 'weather_stations',
        label: 'Метеостанция',
        urlData: 'roads_construction/weather_stations/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: WeatherStationsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.pipelines,
    modes: [
      {
        name: 'pipelines',
        label: 'Трубопровод',
        urlData: 'artificial_structures/pipelines/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: PipeLinesItemSelection,
      },
    ],
  },
  {
    name: MapObjects.tunnels,
    modes: [
      {
        name: 'tunnels',
        label: 'Туннель',
        urlData: 'artificial_structures/tunnels/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: TunnelsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.ferryCrossings,
    modes: [
      {
        name: 'ferry_crossings',
        label: 'Паромная переправа',
        urlData: 'artificial_structures/ferry_crossings/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: FerryCrossingsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.qualitySegmentComplex,
    modes: [
      {
        name: MapObjects.qualitySegmentComplex,
        label: 'Комплексная оценка участка',
        urlData: 'roads_quality/road_complex_type_quality_repr/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadQualitySegmentSelection,
      },
    ],
  },
  {
    name: MapObjects.qualitySegmentStrength,
    modes: [
      {
        name: MapObjects.qualitySegmentStrength,
        label: 'Участок качества дороги',
        urlData: RequestPath.roadStrengthQualityShort,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadQualitySegmentSelection,
      },
    ],
  },
  {
    name: MapObjects.qualitySegmentRut,
    modes: [
      {
        name: MapObjects.qualitySegmentRut,
        label: 'Участок качества дороги',
        urlData: 'roads_quality/road_rut_quality_repr/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadQualitySegmentSelection,
      },
    ],
  },
  {
    name: MapObjects.qualitySegmentFlatness,
    modes: [
      {
        name: MapObjects.qualitySegmentFlatness,
        label: 'Участок качества дороги',
        urlData: 'roads_quality/road_flatness_quality_repr/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadQualitySegmentSelection,
      },
    ],
  },
  {
    name: MapObjects.qualitySegmentClutch,
    modes: [
      {
        name: MapObjects.qualitySegmentClutch,
        label: 'Участок качества дороги',
        urlData: 'roads_quality/road_clutch_quality_repr/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadQualitySegmentSelection,
      },
    ],
  },
  {
    name: MapObjects.SegmentAnalysisValues,
    modes: [
      {
        name: MapObjects.SegmentAnalysisValues,
        label: 'Участок качества дороги',
        urlData: 'roads_quality/segment_analysis_values/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadQualitySegmentSelection,
      },
    ],
  },
  {
    name: MapObjects.ViolationsWarrantyPeriod,
    modes: [
      {
        name: MapObjects.ViolationsWarrantyPeriod,
        label: 'Гарантийный срок',
        urlData: 'roads_quality/violations_warranty_period/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: ViolationsWarrantyPeriodSelection,
      },
    ],
  },
  {
    name: MapObjects.roadAccidents,
    modes: [
      {
        name: MapObjects.roadAccidents,
        label: 'ДТП',
        urlData: 'road_accidents/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadAccidentsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.substations,
    modes: [
      {
        name: MapObjects.substations,
        label: 'Подстанция',
        urlData: 'kns/substations/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: ElectricalSubstationsSelection,
      },
    ],
  },
  {
    name: MapObjects.roadAccidentsSections,
    modes: [
      {
        name: MapObjects.roadAccidentsSections,
        label: 'Аварийно опасный участок',
        urlData: 'layer_road_accidents/road_accidents_sections/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadAccidentsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.roadTechnicalCharacteristicsAxeLoads,
    modes: [
      {
        name: MapObjects.roadTechnicalCharacteristicsAxeLoads,
        label: 'Нагрузки на ось',
        urlData: 'roads/segments/axle_loads/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadTechnicalCharacteristicsSelection,
      },
    ],
  },
  {
    name: MapObjects.roadTechnicalCharacteristicsCoatingTypes,
    modes: [
      {
        name: MapObjects.roadTechnicalCharacteristicsCoatingTypes,
        label: 'Тип покрытия',
        urlData: 'roads/segments/coating_types/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadTechnicalCharacteristicsSelection,
      },
    ],
  },
  {
    name: MapObjects.roadTechnicalCharacteristicsLanesAmounts,
    modes: [
      {
        name: MapObjects.roadTechnicalCharacteristicsLanesAmounts,
        label: 'Количество полос',
        urlData: 'roads/segments/lanes_amounts/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadTechnicalCharacteristicsSelection,
      },
    ],
  },
  {
    name: MapObjects.individualStatements,
    modes: [
      {
        name: MapObjects.individualStatements,
        label: 'Маршрут заявки',
        urlData: '/special_permits/individual_statements/routes',
        accessMethod: DataAccessMethod.httpGetWithParams,
        params: [
          {
            name: 'statement',
            value: 'id',
          },
        ],
        representationPrototype: OrderRoutePathSelection,
      },
    ],
    mapLayerPrototype: TransportOrdersCompositeLayer,
  },
  {
    name: MapObjects.entityStatements,
    modes: [
      {
        name: MapObjects.entityStatements,
        label: 'Маршрут заявки',
        urlData: '/special_permits/entity_statements/routes',
        accessMethod: DataAccessMethod.httpGetWithParams,
        params: [
          {
            name: 'statement',
            value: 'id',
          },
        ],
        representationPrototype: OrderRoutePathSelection,
      },
    ],
    mapLayerPrototype: TransportOrdersCompositeLayer,
  },
  {
    name: MapObjects.axleLoadOverloads,
    modes: [
      {
        name: MapObjects.axleLoadOverloads,
        label: 'Перегрузка по маршруту',
        urlData: 'special_permits/individual_statements/axle_load_overloads/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: AxleLoadOverloadsSelection,
      },
    ],
  },
  {
    name: MapObjects.entityStatementsAxleLoadOverloads,
    modes: [
      {
        name: MapObjects.entityStatementsAxleLoadOverloads,
        label: 'Перегрузка по маршруту',
        urlData: 'special_permits/entity_statements/axle_load_overloads/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: AxleLoadOverloadsSelection,
      },
    ],
  },
  {
    name: MapObjects.dimensionsHeightOversizes,
    modes: [
      {
        name: MapObjects.dimensionsHeightOversizes,
        label: 'Превышения по высоте по маршруту',
        urlData: 'special_permits/individual_statements/dimensions_height_oversizes/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: DimensionsHeightOversizesSelection,
      },
    ],
  },
  {
    name: MapObjects.entityStatementsDimensionsHeightOversizes,
    modes: [
      {
        name: MapObjects.entityStatementsDimensionsHeightOversizes,
        label: 'Превышения по высоте по маршруту',
        urlData: 'special_permits/entity_statements/dimensions_height_oversizes/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: DimensionsHeightOversizesSelection,
      },
    ],
  },
  {
    name: MapObjects.dimensionsWidthOversizes,
    modes: [
      {
        name: MapObjects.dimensionsWidthOversizes,
        label: 'Превышения по ширине по маршруту',
        urlData: 'special_permits/individual_statements/dimensions_width_oversizes/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: DimensionsWidthOversizesSelection,
      },
    ],
  },
  {
    name: MapObjects.entityStatementsDimensionsWidthOversizes,
    modes: [
      {
        name: MapObjects.entityStatementsDimensionsWidthOversizes,
        label: 'Превышения по ширине по маршруту',
        urlData: 'special_permits/entity_statements/dimensions_width_oversizes/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: DimensionsWidthOversizesSelection,
      },
    ],
  },
  {
    name: MapObjects.generalDimensionsHeightWidthOversizes,
    modes: [
      {
        name: MapObjects.generalDimensionsHeightWidthOversizes,
        label: 'Глобальные превышения по высоте и ширине по маршруту',
        urlData: 'special_permits/individual_statements/general_dimensions_oversizes/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: GeneralDimensionsHeightWidthOversizesSelection,
      },
    ],
  },
  {
    name: MapObjects.entityStatementsGeneralDimensionsHeightWidthOversizes,
    modes: [
      {
        name: MapObjects.entityStatementsGeneralDimensionsHeightWidthOversizes,
        label: 'Глобальные превышения по высоте и ширине по маршруту',
        urlData: 'special_permits/entity_statements/general_dimensions_oversizes/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: GeneralDimensionsHeightWidthOversizesSelection,
      },
    ],
  },
  {
    name: MapObjects.publicTransportBusStop,
    modes: [
      {
        name: MapObjects.publicTransportBusStop,
        label: 'Остановка маршрутного транспорта',
        urlData: RequestPath.publicTransportBusStops,
        accessMethod: DataAccessMethod.none,
        idKey: 'id',
        representationPrototype: PublicTransportBusStopSelection,
      },
    ],
  },
  {
    name: MapObjects.roadMarkings,
    modes: [
      {
        name: MapObjects.roadMarkings,
        label: 'Дорожная разметка',
        urlData: 'roads/road_marking_points/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: RoadMarkingsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.busStops,
    modes: [
      {
        name: MapObjects.busStops,
        label: 'Остановки',
        urlData: RequestPath.publicTransportBusStops,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: BusStopstemSelection,
      },
    ],
  },
  {
    name: MapObjects.illuminatedPedestrianCrossings,
    modes: [
      {
        name: MapObjects.illuminatedPedestrianCrossings,
        label: 'Пешеходные переходы',
        urlData: RequestPath.illuminatedPedestrianCrossings,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: IlluminatedPedestrianCrossingsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.trafficLightObject,
    modes: [
      {
        name: MapObjects.trafficLightObject,
        label: 'Светофорные объекты',
        urlData: RequestPath.trafficLightObject,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: TrafficLightsObjectsSelection,
      },
    ],
  },
  {
    name: MapObjects.cabinets,
    modes: [
      {
        name: MapObjects.cabinets,
        label: 'Шкафы кабельных коммуникаций',
        urlData: RequestPath.cabinets,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: CabinetsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.signalPoles,
    modes: [
      {
        name: MapObjects.signalPoles,
        label: 'Сигнальные столбики',
        urlData: RequestPath.signalBars,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: SignalPolesItemSelection,
      },
    ],
  },

  /** Камеры и детекторы */
  {
    name: MapObjects.camerasAndDetectors,
    modes: [
      {
        name: MapObjects.camerasAndDetectors,
        label: 'Камеры и детекторы',
        urlData: RequestPath.transportDetectors,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: CamerasAndDetectorsItemSelection,
      },
    ],
  },
  {
    name: MapObjects.citydistricts,
    modes: [
      {
        name: 'citydistricts',
        label: 'Районы',
        urlData: RequestPath.cityDistricts,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: CityDistrictsSelection,
      },
    ],
  },
  {
    name: MapObjects.trafficManagementProjects,
    modes: [
      {
        name: 'trafficManagementProjects',
        label: 'ПОДД',
        urlData: RequestPath.roadWorksTrafficManagementProjects,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: TrafficManagementProjectsCard,
      },
    ],
  },
  // Проектный слой
  {
    name: MapObjects.projectLayerRoadMarkingLine,
    modes: [
      {
        name: 'designsLayer',
        label: 'Проектный',
        urlData: '/roads/road_marking_line_project',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
      },
    ],
  },
  {
    name: MapObjects.projectLayerProjectRoadSigns,
    modes: [
      {
        name: 'designsLayer',
        label: 'Проектный',
        urlData: 'road_constructions/road_signs_project/',
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: ProjectLayerItemSelection,
      },
    ],
  },
  /* Мониторинг УДС */
  {
    name: MapObjects.monitoringUds,
    modes: [
      {
        name: MapObjects.monitoringUds,
        label: 'Мониторинг',
        urlData: RequestPath.detections,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: MonitoringUDSItemSelection,
      },
    ],
  },
  /* Задачи бригад */
  {
    name: MapObjects.brigadeApplications,
    permissionToShow: generateRightsByKey('application'),
    modes: [
      {
        name: MapObjects.brigadeApplications,
        label: 'Заявки',
        urlData: RequestPath.brigadeApplications,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: BrigadeApplicationsSelection,
      },
    ],
  },
  {
    name: MapObjects.brigadeWorkShifts,
    modes: [
      {
        name: MapObjects.brigadeWorkShifts,
        label: 'Смены',
        urlData: RequestPath.brigadeWorkShifts,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: BrigadeWorkShiftsSelection,
      },
    ],
  },
  {
    name: MapObjects.brigadeTeamsOfWorkers,
    modes: [
      {
        name: MapObjects.brigadeTeamsOfWorkers,
        label: 'Бригады',
        urlData: RequestPath.brigadeTeamsOfWorkers,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: BrigadeTeamsOfWorkersSelection,
      },
    ],
  },

  {
    name: MapObjects.busShelters,
    modes: [
      {
        name: MapObjects.busShelters,
        label: 'Автобусные остановки',
        urlData: RequestPath.roadConstructionsBusShelters,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: BusSheltersSelectionSelection,
      },
    ],
  },

  // Камеры УДС
  {
    name: MapObjects.camerasUdc,
    modes: [
      {
        name: MapObjects.camerasUdc,
        label: 'Автобусные остановки',
        urlData: RequestPath.camerasUdc,
        accessMethod: DataAccessMethod.httpGet,
        idKey: 'id',
        representationPrototype: CamerasUdcSelection,
      },
    ],
  },
];

export const defaultObjectDescription: Partial<SelectionMapObjectRegistratorModel> = {
  permissionToShow: [],
  actions: [],
};
