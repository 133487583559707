import React from 'react';
import Icon from '@ant-design/icons';

const PenIconSVG = ({ fill }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 11.4999V13.9999H4.5L11.8733 6.62659L9.37333 4.12659L2 11.4999ZM13.8067 4.69325C13.8685 4.63158 13.9175 4.55832 13.951 4.47767C13.9844 4.39702 14.0016 4.31057 14.0016 4.22325C14.0016 4.13594 13.9844 4.04949 13.951 3.96884C13.9175 3.88819 13.8685 3.81493 13.8067 3.75325L12.2467 2.19325C12.185 2.13145 12.1117 2.08242 12.0311 2.04897C11.9504 2.01551 11.864 1.99829 11.7767 1.99829C11.6894 1.99829 11.6029 2.01551 11.5223 2.04897C11.4416 2.08242 11.3683 2.13145 11.3067 2.19325L10.0867 3.41325L12.5867 5.91325L13.8067 4.69325Z"
      fill={fill}
    />
  </svg>
);

export const PenIcon = (props) => <Icon component={PenIconSVG} {...props} />;
