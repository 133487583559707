import React, { FC, useMemo } from 'react';

import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { EyeOnIcon } from 'UI/custom/icons/actionIcons/EyeOnIcon';
import { EyeOffIcon } from 'UI/custom/icons/actionIcons/EyeOffIcon';
import { Styled } from './style/AddedMasks.styled';
import AddedMaskItem from './AddedMaskItem';
import { AddedMasksProps } from './model/AddedMasks.model';

const { AddedMasksHeaderContentWrap, AddedMasksHeaderButtonWrap, AddedMasksContentWrap, NotAddedMasks } = Styled;

const AddedMasks: FC<AddedMasksProps> = ({
  wrapperStyles,
  headerStyles,
  addedMasks,
  visibleAllMasks,
  onChangeVisibleMask,
  onEditMask,
  onDeleteMask,
  onChangeVisibleAllMasks,
}) => {
  const headerContent = useMemo(() => {
    const visibleIcon = visibleAllMasks ? <EyeOnIcon /> : <EyeOffIcon />;

    return (
      <AddedMasksHeaderContentWrap>
        <span>Добавленные объекты</span>
        <AddedMasksHeaderButtonWrap>
          <Button
            icon={visibleIcon}
            onClick={onChangeVisibleAllMasks}
            iconSize={20}
            p={0}
            iconColor={'#35446F'}
            type={ButtonType.unbounded}
          />
        </AddedMasksHeaderButtonWrap>
      </AddedMasksHeaderContentWrap>
    );
  }, [onChangeVisibleAllMasks, visibleAllMasks]);

  const masks = useMemo(
    () =>
      addedMasks.map((el) => {
        let name: string;

        if (el.name) {
          name = el.name;
        } else {
          const masksType = addedMasks.filter((filterEl) => filterEl.maskType === el.maskType && !filterEl.name);
          const index = masksType.findIndex((indexEl) => indexEl.id === el.id);
          name = `${el.maskTypeName} ${index + 1}`;
        }

        return (
          <AddedMaskItem
            key={el.id}
            name={name}
            isVisible={el.visible}
            onDeleteMask={() => onDeleteMask(el.id)}
            onEditMask={() => onEditMask(el)}
            onVisibleMask={() => onChangeVisibleMask(el.id)}
          />
        );
      }),
    [addedMasks, onDeleteMask, onEditMask, onChangeVisibleMask]
  );

  const content = useMemo(
    () => (
      <>
        {!addedMasks.length && <NotAddedMasks>Добавленных объектов нет</NotAddedMasks>}
        {!!addedMasks.length && <AddedMasksContentWrap>{masks}</AddedMasksContentWrap>}
      </>
    ),
    [addedMasks.length, masks]
  );

  return (
    <ScrollableCard headerContent={headerContent} wrapperStyles={wrapperStyles} headerStyles={headerStyles}>
      {content}
    </ScrollableCard>
  );
};

export default AddedMasks;
