import { Styled } from '../SemifinishedObjectForm.styled';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { RefreshIcon } from 'UI/custom/icons/actionIcons/RefreshIcon';
import { iconSize } from '../../../interfacesApplications/templates';
import { HeaderContainerProps } from './model/HeaderContainer.model';

const { LocalTitle, HeaderContainerWrapper, BaseSelectInput } = Styled;

export const HeaderContainer = ({
  refreshData,
  applicationName,
  optionsTypeObject,
  selectTypeObject,
}: HeaderContainerProps) => {
  return (
    <HeaderContainerWrapper>
      <LocalTitle>
        План по {`${refreshData ? 'заявке' : 'задаче'}`} {applicationName}
      </LocalTitle>
      {optionsTypeObject && (
        <BaseSelectInput onSelect={selectTypeObject} defaultValue={'Выберите тип объекта'}>
          {optionsTypeObject}
        </BaseSelectInput>
      )}
      {refreshData && (
        <Button
          p={'none'}
          type={ButtonType.unbounded}
          icon={<RefreshIcon />}
          iconSize={iconSize}
          onClick={refreshData}
        />
      )}
    </HeaderContainerWrapper>
  );
};
