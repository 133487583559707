import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';

import { Application, ApplicationsService } from 'generated/api/api';
import { OrderApplicationsButtonI } from './model/OrderApplications.model';
import PrepareModal from 'UI/common/components/PrepareModal/PrepareModal';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { Layers } from 'types/enums/map/layers/Layers';
import { useDispatch } from 'react-redux';

const getApplications = async (teamOfMasters: string): Promise<any> => {
  return await ApplicationsService.applicationsTeamsOfMastersGetApplicationsRetrieve({ teamOfMasters });
};

const AddApplicationsButton: FC<OrderApplicationsButtonI> = (
  {
    teamMastersId,
    appointedTeamId,
    applications,
    onUploadApplications,
  }) => {
  const dispatch = useDispatch();
  const [newApplications, setNewApplications] = useState<Array<Application>>([]);

  useEffect(() => {
    (async () => {
      if (teamMastersId.length) {
        const teamOfMasters = teamMastersId.join(',');
        const { results } = await getApplications(teamOfMasters);
        const applicationsNotBrigade = results.filter((el: Application) => !el.appointed_team);
        setNewApplications(applicationsNotBrigade);
      }
    })();
  }, [teamMastersId]);

  const options = useMemo(() => {
    return newApplications.map((el) => ({
      value: el.id,
      label: `Заявка №${el.id}`,
    }));
  }, [newApplications]);

  const selectorProps = useMemo(
    () => ({
      options: options,
      placeholder: 'Выберите заявку',
      required: true,
    }), [options]);

  const handleSuccess = useCallback(
    async (id) => {
      const applicationIds = [...applications.map((el) => el.id), id];
      const currentApplication = newApplications.find((el) => el.id === id)

      const updateOrderObj = {
        id,
        order: applicationIds.length,
      };

      try {
        await ApplicationsService.applicationsPartialUpdate({
          id,
          body: {
            appointed_team: appointedTeamId,
            status: currentApplication?.status,
          },
        });
        await ApplicationsService.applicationsSetOrdersCreate({ body: { data: [updateOrderObj] } });
        dispatch(mapboxDataActions.reloadMapboxLayerDataByRelatedName(MapObjects.brigadeApplications, Layers.brigadeApplications));
        onUploadApplications();
      } catch (e) {
        message.error('Что-то пошло не так');
      }
    }, [applications, appointedTeamId, dispatch, newApplications, onUploadApplications]);

  return (
    <PrepareModal
      handleSuccess={handleSuccess}
      selectorProps={selectorProps}
      buttonText={'Добавить'}
      buttonType={ButtonType.primary}
      title={'Добавить заявку бригаде'}
    />
  );
};

export default AddApplicationsButton;
