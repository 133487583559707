import styled from 'styled-components/macro';

// import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';
import PanelHeader from 'UI/common/components/Panel/components/Header/PanelHeader';
import PanelSection from 'UI/common/components/Panel/components/Section/PanelItemSection';
import Panel from 'UI/common/components/Panel/Panel';

// const { GetDefaultBeatifiedScrollbar } = styleMixins.other;

const ExtendedPanelWrapper = styled(Panel)<any>`
  height: 67vh;
  //height: 800px;
  display: flex;
  flex-direction: column;
  width: 272px;
  max-width: 100%;
`;

const ExtendedPanelHeader = styled(PanelHeader)<any>`
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  font-weight: bold;
`;

const ExtendedPanelContent = styled(PanelSection)<any>`
  //{(props)} => GetDefaultBeatifiedScrollbar(props)}
  //flex: 1 1 auto;
  //overflow-y: scroll;
`;

const ExtendedPanelFooter = styled(PanelHeader)<any>`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
`;

export const Styled = {
  ExtendedPanelWrapper,
  ExtendedPanelFooter,
  ExtendedPanelHeader,
  ExtendedPanelContent,
};
