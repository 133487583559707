import { RoadSchemaCard } from './RoadSchemaCard';
import { TramsSchemaCard } from './TramsSchemaCard';
import { TrolleybusCard } from './TrolleybusCard';
import { ElectricalSubstationCard } from './ElectricalSubstationCard';
import { TrafficLightsObjectsCard } from './TrafficLightsObjectsCard';
import { CamerasAndDetectorsCard } from './CamerasAndDetectorsCard';
import { SpeedBumpsCard } from './SpeedBumpsCard';
import { IlluminatedPedestrianCrossingsCard } from './IlluminatedPedestrianCrossingsCard';
import { CityDistrictsCard } from './CityDistrictsCard';
import { TrafficManagementProjectsCard } from './TrafficManagementProjectsCard';
import { BrigadeApplicationsCard } from './BrigadeApplicationsCard';
import { MonitoringUDSCard } from './MonitoringUDSCard';
import { ProjectCard } from './ProjectCard';
import { CamerasUdc } from './CamerasUdc';
import { PublicTransportCard } from './PublicTransportCard';
import { TransportOrdersLayerCard } from './TransportOrdersLayerCard';
import { RoadAccidentsLayerCard } from './RoadAccidentsLayerCard';

/**
 * Используется в: создание слоёв
 * Индексация карточек объектов - имеют текст с названием слоя и картинку
 */

export const ekbLayerCards = {
  RoadSchemaCard,
  TrolleybusCard,
  TramsSchemaCard,
  ElectricalSubstationCard,
  TrafficLightsObjectsCard,
  CamerasAndDetectorsCard,
  SpeedBumpsCard,
  IlluminatedPedestrianCrossingsCard,
  CityDistrictsCard,
  TrafficManagementProjectsCard,
  ProjectCard,
  BrigadeApplicationsCard,
  MonitoringUDSCard,
  CamerasUdc,
  PublicTransportCard,
  TransportOrdersLayerCard,
  RoadAccidentsLayerCard,
};
