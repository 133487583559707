import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { layerDataAccessors } from '../description/dataAccessors';
import { layerSettings } from '../description/settings';
import IlluminatedPedestrianCrossingsItemSelection from '../../../../layers/SelectedObjectsRepresentations/IlluminatedPedestrianCrossingsItemSelection/IlluminatedPedestrianCrossingsItemSelection';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class IlluminatedPedestrianCrossingsLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.illuminatedPedestrianCrossings,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.IlluminatedPedestrianCrossingsDataAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.IlluminatedPedestrianCrossings()],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.IlluminatedPedestrianCrossingsCard(),
      customSettings: [new layerSettings.OpacitySetting(), new layerSettings.IconSizeScale()],
      layerSchema: new layerSchemas.ekbLayersSchema.IlluminatedPedestrianCrossingsSchema(),
      selectedObjectPrototype: IlluminatedPedestrianCrossingsItemSelection,
    });
  }
}
