import React, { FC, useMemo } from 'react';

import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { Styled } from './style/AvailableMasks.styled';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Button } from 'UI/common/components/Button/Button';
import AvailableMaskItem from './AvailableMaskItem';
import { getAllRegisteredMask } from '../../utils/masks/maskRegistrator';
import { AvailableMasksProps } from './model/AvailableMasks.model';

const { AvailableMasksContentWrap, AvailableMasksFooter } = Styled;

const AvailableMasks: FC<AvailableMasksProps> = ({
  wrapperStyles,
  headerStyles,
  currentMask,
  onSaveSettings,
  onSetMask,
  onCancelMask,
  onSaveMask,
  onChangeDrawType,
  onCancelSettings,
  onChangeMaskName,
  cameraID,
}) => {
  const maskItems = useMemo(() => {
    const masks = getAllRegisteredMask();

    return masks.map((el) => {
      return (
        <AvailableMaskItem
          key={el.getMaskType()}
          mask={el}
          onChangeMaskName={onChangeMaskName}
          currentMask={currentMask}
          onSetMask={onSetMask}
          onCancelMask={onCancelMask}
          onSaveMask={onSaveMask}
          onChangeDrawType={onChangeDrawType}
          cameraID={cameraID}
        />
      );
    });
  }, [cameraID, onChangeMaskName, currentMask, onCancelMask, onChangeDrawType, onSaveMask, onSetMask]);

  const footerContent = useMemo(() => {
    return (
      <>
        <AvailableMasksFooter>
          <Button onClick={onSaveSettings} type={ButtonType.primary}>
            Сохранить изменения
          </Button>
          <Button onClick={onCancelSettings} type={ButtonType.dangerReverseTranslucent}>
            Отменить и закрыть
          </Button>
        </AvailableMasksFooter>
      </>
    );
  }, [onCancelSettings, onSaveSettings]);

  return (
    <ScrollableCard
      wrapperStyles={wrapperStyles}
      headerStyles={headerStyles}
      headerContent={'Добавьте объекты'}
      footerContent={footerContent}
    >
      <AvailableMasksContentWrap>{maskItems}</AvailableMasksContentWrap>
    </ScrollableCard>
  );
};

export default AvailableMasks;
