import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
/*import { ArtificialStructuresService } from '../../../../../../generated/api/api';*/
import { getFromURL } from '../../../../../../api/getFromURL';

export class Tunnels extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'tunnels',
      filterLabel: 'Туннели',
      selectedObjectName: MapObjects.tunnels,
    });
  }

  /*  async uploadData() {
    return await ArtificialStructuresService.artificialStructuresTunnelsList();
  }

  async deleteRecord(id) {
    return await ArtificialStructuresService.artificialStructuresTunnelsDelete({ id });
  }

  async readRecord(id) {
    return await ArtificialStructuresService.artificialStructuresTunnelsRead({ id });
  }*/

  async uploadModel() {
    return await getFromURL.getModelFromURL('/artificial_structures/tunnels/');
  }
}
