import React from 'react';
import { resolver } from './resolver';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { DataAccessMethod, DataUpdateMethod } from 'types/enums/routes/request/DataAccessMethod.model';
import { RequestPath } from 'types/enums/routes/request/RequestPath';
import { generateRightsByKey } from '../../../services/application/permissions/generateRightsByKey';

export const resolveEditableObjectDescription = resolver;

/**
 * Используется в: создание объектов
 * Описание для режима редактирования объектов
 * urlData - урл для загрузки данных в карточку редактирования объекта
 * parent - родительский элемент к которому будет привязана геометрия объекта (возможно не только она)
 */

const ProjectRouteButton = React.lazy(
  () => import('../layers/description/dataAccessors/customButtons/ProjectRouteButton')
);

const SegmentConnectionButton = React.lazy(
  () => import('../layers/description/dataAccessors/customButtons/SegmentConnectionButton')
);

interface ParentModel {
  urlData: string;
  key: string;
  childKey: string;
  creatable: boolean;
  snapChildGeometry: boolean;
}

export interface EditableObjectDescriptionModel {
  name: MapObjects;
  urlData: string;
  accessMethod: DataAccessMethod;
  label: string;
  parent?: ParentModel;
  topPanelButtons?: React.LazyExoticComponent<() => JSX.Element>[];
  cropModel?: string[];
  updateMethod?: DataUpdateMethod;
  permissionToShow?: any[];
  actions?: any[];
  hardcodeInstance?: {
    detection: string;
    geometry: string;
  };
  withoutGeometry?: boolean;
}

export const editableObjectDescription: EditableObjectDescriptionModel[] = [
  {
    name: MapObjects.roadSegment,
    urlData: 'roads/segments/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'участок дороги',
    parent: {
      urlData: 'roads/',
      key: 'road',
      childKey: 'segment',
      creatable: true,
      snapChildGeometry: false,
    },
    topPanelButtons: [ProjectRouteButton, SegmentConnectionButton],
  },
  {
    name: MapObjects.roadWays,
    urlData: 'roads/roadways/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Проезжая часть',
  },
  {
    name: MapObjects.sidewalk,
    urlData: 'roads/sidewalks/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Тротуар',
  },
  {
    name: MapObjects.dividingLines,
    urlData: 'roads/dividing_lines/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Разделительная линия',
  },
  {
    name: MapObjects.supportWall,
    urlData: 'road_constructions/support_walls/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Подпорная стена',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'support_walls',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.tramRails,
    urlData: 'public_transport/tramways/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Трамвайное полотно',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'tram_rails',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.roadMarkingLine,
    urlData: 'roads/road_marking_lines/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Дорожная разметка',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'road_marking_lines',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.supportPillars,
    urlData: 'kns/support_pillars/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Опорный столб',
  },
  {
    name: MapObjects.engineeringConstructions,
    urlData: 'engineering_constructions/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Инженерное дорожное сооружение',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'engineering_constructions',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.contoursLawn,
    urlData: 'roads/contours_lawn/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Контуры газонов',
  },
  {
    name: MapObjects.townSquares,
    urlData: 'roads/town_squares/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Площади',
  },
  {
    name: MapObjects.traySystem,
    urlData: 'roads/tray_systems/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'лотковые системы',
  },
  {
    name: MapObjects.roadFences,
    urlData: 'roads/road_fences/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Дорожное ограждение',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'road_fences',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.pedestrianFences,
    urlData: 'road_constructions/ped_borders/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Пешеходное ограждение',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'road_fences',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.roadSigns,
    urlData: 'road_constructions/road_signs/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Дорожный знак',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'road_signs',
    //   creatable: false,
    //   snapChildGeometry: false,
    // },
  },
  {
    name: MapObjects.bridges,
    urlData: 'artificial_structures/bridges/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Мост',
  },
  {
    name: MapObjects.trafficLight,
    urlData: 'road_constructions/traffic_lights/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Светофорные знаки',
  },
  {
    name: MapObjects.cabinets,
    urlData: 'roads/cabinets/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Шкафы кабельных коммуникаций',
  },
  {
    name: MapObjects.speedBumps,
    urlData: 'road_constructions/speed_bumps/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Лежачие полицейские',
    parent: {
      urlData: RequestPath.roadsSegments,
      key: 'segment',
      childKey: 'speed_bumps',
      creatable: false,
      snapChildGeometry: false,
    },
  },
  {
    name: MapObjects.improvementElements,
    urlData: 'roads/improvement_elements/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Элементы благоустройства',
  },
  {
    name: MapObjects.overpasses,
    urlData: 'artificial_structures/overpasses/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Искусственные сооружения',
  },
  {
    name: MapObjects.railCrossings,
    urlData: 'artificial_structures/rail_crossings/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'ЖД переезд',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'rail_crossings',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.pipes,
    urlData: 'engineering_constructions/tubes/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Трубы',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'pipes',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.signalPoles,
    urlData: 'roads/signal_bars/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Сигнальные столбики',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'signal_poles',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.lighting,
    urlData: 'road_constructions/lighting/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Освещение',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'lighting',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.restAreas,
    urlData: 'roads_construction/rest_areas/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Зоны отдыха',
  },
  {
    name: MapObjects.weatherStations,
    urlData: 'roads_construction/weather_stations/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Метеостанция',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'weather_stations',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.pipelines,
    urlData: 'artificial_structures/pipelines/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Трубопровод',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'pipelines',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.tunnels,
    urlData: 'artificial_structures/tunnels/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Туннель',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'tunnels',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.ferryCrossings,
    urlData: 'artificial_structures/ferry_crossings/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Паромная переправа',
  },
  {
    name: MapObjects.roadTechnicalCharacteristicsAxeLoads,
    urlData: 'roads/segments/axle_loads/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Нагрузки на ось',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'axle_loads',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.roadTechnicalCharacteristicsCoatingTypes,
    urlData: 'roads/segments/coating_types/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Тип покрытия',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'coating_types',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.roadTechnicalCharacteristicsLanesAmounts,
    urlData: 'roads/segments/lanes_amounts/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Количество полос',
    parent: {
      urlData: 'roads/segments/',
      key: 'segment',
      childKey: 'lanes_amounts',
      creatable: false,
      snapChildGeometry: true,
    },
  },
  {
    name: MapObjects.individualStatements,
    urlData: RequestPath.permitsIndividualStatementRoutes,
    accessMethod: DataAccessMethod.httpGet,
    label: 'Маршрут',
    //
    parent: {
      urlData: '/special_permits/individual_statements/',
      key: 'statement',
      childKey: 'id',
      creatable: false,
      snapChildGeometry: false,
    },
    topPanelButtons: [ProjectRouteButton],
  },
  {
    name: MapObjects.entityStatements,
    urlData: RequestPath.entityStatementRoutes,
    accessMethod: DataAccessMethod.httpGet,
    label: 'Маршрут',
    //
    parent: {
      urlData: '/special_permits/entity_statements/',
      key: 'statement',
      childKey: 'id',
      creatable: false,
      snapChildGeometry: false,
    },
    topPanelButtons: [ProjectRouteButton],
  },
  {
    name: MapObjects.tramCables,
    urlData: 'kns/cables_tram/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'участок кабеля',
    parent: {
      urlData: 'kns/lines_tram/',
      key: 'line',
      childKey: 'cables_tram',
      creatable: true,
      snapChildGeometry: false,
    },
  },
  {
    name: MapObjects.supply_cables_troll,
    urlData: 'kns/supply_cables_troll/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'участок питающего кабеля',
    parent: {
      urlData: 'kns/lines_troll/',
      key: 'line',
      childKey: 'supply_cables_troll',
      creatable: true,
      snapChildGeometry: false,
    },
  },
  {
    name: MapObjects.supplyTramCables,
    urlData: 'kns/supply_cables_tram/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'участок питающего кабеля',
    parent: {
      urlData: 'kns/lines_tram/',
      key: 'line',
      childKey: 'supply_cables_tram',
      creatable: true,
      snapChildGeometry: false,
    },
  },
  {
    name: MapObjects.linesTroll,
    urlData: 'kns/lines_troll',
    accessMethod: DataAccessMethod.httpGet,
    label: 'участок кабеля2',
    parent: {
      urlData: 'kns/supply_cables_troll',
      key: 'line',
      childKey: 'supply_cables_troll',
      creatable: true,
      snapChildGeometry: false,
    },
  },
  {
    name: MapObjects.cablesTroll,
    urlData: 'kns/cables_troll/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Создать участок линии',
    parent: {
      urlData: 'kns/lines_troll/',
      key: 'line',
      childKey: 'cables_troll',
      creatable: true,
      snapChildGeometry: false,
    },
  },
  {
    name: MapObjects.substations,
    urlData: 'kns/substations/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Подстанция',
  },
  {
    name: MapObjects.illuminatedPedestrianCrossings,
    urlData: 'road_constructions/pedestrian_crossings/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Пеш. переходы',
  },
  {
    name: MapObjects.roadMarkings,
    urlData: 'roads/road_marking_points/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Дорожная разметка',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'road_marking_points',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  {
    name: MapObjects.roadMarkingPolygon,
    urlData: 'roads/road_marking_polygons/',
    accessMethod: DataAccessMethod.httpGet,
    label: 'Дорожная разметка',
  },
  {
    name: MapObjects.busStops,
    urlData: RequestPath.publicTransportBusStops,
    accessMethod: DataAccessMethod.httpGet,
    label: 'Остановка',
    // parent: {
    //   urlData: 'roads/segments/',
    //   key: 'segment',
    //   childKey: 'bus_stops',
    //   creatable: false,
    //   snapChildGeometry: true,
    // },
  },
  /** Камеры и детекторы */
  {
    name: MapObjects.camerasAndDetectors,
    urlData: RequestPath.transportDetectors,
    accessMethod: DataAccessMethod.httpGet,
    label: 'Камеры и детекторы',
  },
  {
    name: MapObjects.trafficManagementProjects,
    urlData: RequestPath.roadWorksTrafficManagementProjects,
    accessMethod: DataAccessMethod.httpGet,
    label: 'ПОДД',
  },
  /** Задачи Бригад*/
  {
    name: MapObjects.brigadeApplications,
    urlData: RequestPath.brigadeApplications,
    accessMethod: DataAccessMethod.httpGet,
    updateMethod: DataUpdateMethod.partialUpdate,
    permissionToShow: generateRightsByKey('application'),
    label: 'Заявки',
  },
  /** Мониторинг УДС **/
  {
    name: MapObjects.monitoringUds,
    urlData: RequestPath.brigadeApplications,
    accessMethod: DataAccessMethod.httpGet,
    label: 'Создание заявки',
    permissionToShow: generateRightsByKey('application'),
    hardcodeInstance: {
      detection: 'id',
      geometry: 'point',
    },
    withoutGeometry: true,
  },
  {
    name: MapObjects.busShelters,
    urlData: RequestPath.roadConstructionsBusShelters,
    accessMethod: DataAccessMethod.httpGet,
    label: 'Автобусные остановки',
  },
  {
    name: MapObjects.camerasUdc,
    urlData: RequestPath.camerasUdc,
    accessMethod: DataAccessMethod.httpGet,
    label: 'Камеры УДС',
  },
];

export const defaultObjectDescription: Partial<EditableObjectDescriptionModel> = {
  permissionToShow: [],
  actions: [],
};
