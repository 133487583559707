import { LayerTemplatesPrototype } from '../prototype/LayerTemplatesPrototype';

export class RoadsSchemaLayerTemplates extends LayerTemplatesPrototype {
  constructor() {
    super({
      templatesURL: '/segments/scheme_templates/',
      itemsURL: '/segments/scheme_template_items/',
      templatesLayer: 'not implemented',
    });
  }
}
