import { useCallback, useState, useEffect } from 'react';
import { Styled } from './MainDataFederalStatementCustomView.styled';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { ModalMapContainer } from 'UI/common/form/inputs/ModalMapInput/ModalMapContainer';
import {
  RouteFederalStatement,
  SpecialPermitsService,
  FederalStatementSpecialConditionCommentTemplate,
} from 'generated/api/api';
import { useRouteMatch } from 'react-router';
import { CargoTransportParams } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { HTTPStatusCode } from 'types/enums/routes/request/HTTPStatusCode.model';
import { AnyObject } from 'types/enums/general/general.model';
import { Model } from 'types/interfaces/net/ModelResponse.model';
import { ModalApprovalContainer } from './ModalApprovalContainer';
import { TemplateSelectInput } from './TemplateSelectInput';

const { ApprovalStatmentContent, TextAreaContainer } = Styled;

interface ApprovalStatmentContainerProps {
  notificationAlert: (e: any) => void;
  mainData: AnyObject;
  scheme: Model;
}

export enum StatusesApprovalModal {
  approval = 'approval',
  cancel = 'cancel',
  requestMaterials = 'requestMaterials',
}

export const statusApprovalNames = {
  [StatusesApprovalModal.approval]: 'Согласовано',
  [StatusesApprovalModal.cancel]: 'Отказ',
  [StatusesApprovalModal.requestMaterials]: 'Запрос доп. материалов',
};

export const ApprovalStatmentContainer = ({ notificationAlert, mainData, scheme }: ApprovalStatmentContainerProps) => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { id } = params;
  const [isOpenMapModal, setOpenMapModal] = useState(false);
  const [statmentObjectPath, setStatmentObjectPath] = useState<RouteFederalStatement>();
  const [isOpenApprovalModal, setOpenApprovalModal] = useState<boolean | StatusesApprovalModal>(false);
  const [responseTemplates, setResponseTemplates] = useState<FederalStatementSpecialConditionCommentTemplate[]>();
  const [resolutionRouteDescription, setResolutionRouteDescription] = useState<string>(
    mainData.resolution_route_description
  );
  const [resolutionSpecialCondition, setResolutionSpecialCondition] = useState<string>();

  useEffect(() => {
    (async () => {
      const responseTemplates =
        await SpecialPermitsService.specialPermitsFederalStatementsSpecialConditionCommentTemplatesList();
      // @ts-ignore Ошибка в апи у БЕКа
      setResponseTemplates(responseTemplates?.results ?? responseTemplates);
    })();
  }, []);

  const openRequestMaterialsModal = useCallback(() => setOpenApprovalModal(StatusesApprovalModal.requestMaterials), []);
  const openApprovalModal = useCallback(() => setOpenApprovalModal(StatusesApprovalModal.approval), []);
  const openCancelModal = useCallback(() => setOpenApprovalModal(StatusesApprovalModal.cancel), []);
  const openMapModal = useCallback(async () => {
    const responseFSRoute = await SpecialPermitsService.specialPermitsFederalStatementsRoutesList({ statement: id });
    // @ts-ignore Ошибка в апи у БЕКа
    const objectPath = responseFSRoute?.results?.[0];
    if (objectPath?.path) {
      //@ts-ignore
      setStatmentObjectPath({ ...objectPath, [SchemaFieldType.linePath]: objectPath.path });
    } else {
      notificationAlert({
        response: { status: HTTPStatusCode.BAD_REQUEST, data: { detail: 'У заявления не обозначен маршрут' } },
      });
    }
    setOpenMapModal(true);
  }, [id, notificationAlert]);

  const changeComment = useCallback(
    (e) => setResolutionSpecialCondition(e.target.value),
    [setResolutionSpecialCondition]
  );
  const changeResolutionRoute = useCallback(
    (e) => setResolutionRouteDescription(e.target.value),
    [setResolutionRouteDescription]
  );

  return (
    <ApprovalStatmentContent>
      <div>
        <TextAreaContainer
          value={resolutionRouteDescription}
          onChange={changeResolutionRoute}
          rows={6}
          placeholder={'Согласованный маршрут'}
        />
        <TemplateSelectInput
          responseTemplates={responseTemplates}
          placeholder={'Шаблонный ответ'}
          setComment={setResolutionSpecialCondition}
        />
        <TextAreaContainer
          value={resolutionSpecialCondition}
          onChange={changeComment}
          rows={6}
          placeholder={'Особые условия движения'}
        />
      </div>
      <div>
        <Button onClick={openMapModal} type={ButtonType.primaryReverse} width={'100%'} children={'Показать маршрут'} />
        <Button
          onClick={openRequestMaterialsModal}
          type={ButtonType.primaryReverse}
          width={'100%'}
          children={'Запрос доп. материалов'}
        />
        <Button onClick={openApprovalModal} type={ButtonType.primary} width={'100%'} children={'Согласовано'} />
        <Button onClick={openCancelModal} type={ButtonType.danger} width={'100%'} children={'Отказано'} />
      </div>
      {isOpenMapModal && (
        <ModalMapContainer
          isOpenModal={isOpenMapModal}
          setOpenModal={setOpenMapModal}
          field={SchemaFieldType.linePath}
          object={statmentObjectPath}
        />
      )}
      {!!isOpenApprovalModal && (
        <ModalApprovalContainer
          isOpenApprovalModal={isOpenApprovalModal}
          setOpenApprovalModal={setOpenApprovalModal}
          object={mainData}
          scheme={scheme}
          resolutionRouteDescription={resolutionRouteDescription}
          resolutionSpecialCondition={resolutionSpecialCondition}
          notificationAlert={notificationAlert}
        />
      )}
    </ApprovalStatmentContent>
  );
};
