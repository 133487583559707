import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { KnsService } from '../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Supply_cables_tram extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'supply_cables_tram',
      filterLabel: 'Питающие кабели трамваев',
      selectedObjectName: MapObjects.supplyTramCables,
    });
  }

  async uploadData() {
    return await KnsService.knsSupplyCablesTramList();
  }

  async deleteRecord(id) {
    return await KnsService.knsSupplyCablesTramDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsSupplyCablesTramRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.supplyCablesTram);
  }
}
