import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';

export class transportAnalysisDistrictUvatskiiy extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'transportAnalysisDistrictUvatskiiy',
      filterLabel: 'Уватский район',
    });
  }

  async uploadData() {
    return await getFromURL.getWithParams('/public_transport/checkpoints_you_can_reach/', { district_id: '36' });
  }
}
