import { IconLayer, PathLayer, PolygonLayer } from '@deck.gl/layers';
import { roadObjectsIconMapping } from 'layers/iconMappers/roadObjectsIconMapping';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { DSelector } from 'services/map/Dselector/DSelector';
import { layerColorsMapper } from 'layers/colorMappers/layerColorsMapper';
import { AnyObject } from 'types/enums/general/general.model';

const sizeScale = 50;
const widthMinPixels = 5;
const widthScale = 1;
const defaultWidth = 1;

const standartFieldNames = [SchemaFieldType.point, SchemaFieldType.linePath, SchemaFieldType.polygon];

export const getMainLayer = (field: string, object: { [key: string]: any }, newPosition: string) => {
  const copyObject = { ...object };
  let fieldForSwitch = field;

  if ((!fieldForSwitch || standartFieldNames.every((key) => !copyObject[key])) && object) {
    Object.values(object).forEach((value) => {
      if (typeof value === 'string') {
        if (value.includes('POINT')) {
          fieldForSwitch = SchemaFieldType.point;
          copyObject.point = value;
        } else if (value.includes('LINESTRING')) {
          fieldForSwitch = SchemaFieldType.linePath;
          copyObject.line_path = value;
        } else if (value.includes('POLYGON')) {
          fieldForSwitch = SchemaFieldType.polygon;
          copyObject.polygon = value;
        }
      }
    });
  }

  switch (fieldForSwitch) {
    case SchemaFieldType.point:
      return new IconLayer<AnyObject>({
        id: 'directory_layer_icon',
        data: [copyObject, { point: newPosition }],
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects_icon.png`,
        iconMapping: roadObjectsIconMapping,
        sizeScale: sizeScale,
        autoHighlight: true,
        highlightColor: layerColorsMapper.highlightColor,
        getPosition: DSelector.getPositionFromPoint,
        getIcon: (d) => (d?.id ? 'white_box' : 'grey_box'),
      });
    case SchemaFieldType.lineString:
    case SchemaFieldType.linePath:
      return new PathLayer<AnyObject>({
        id: 'directory_layer_line_path',
        data: [copyObject, { line_path: newPosition }],
        getPath: DSelector.getLinePath,
        getColor: (d: any) => (d.id ? layerColorsMapper.green : layerColorsMapper.blue),
        highlightColor: layerColorsMapper.highlightColor,
        capRounded: true,
        widthMinPixels: widthMinPixels,
        widthScale: widthScale,
      });
    case SchemaFieldType.polygon:
      return new PolygonLayer<AnyObject>({
        id: 'directory_layer_polygon',
        data: [copyObject, { polygon: newPosition }],
        getPolygon: DSelector.getDPolygon,
        getFillColor: (d: any) => (d.id ? layerColorsMapper.grayRoadTranslucent : layerColorsMapper.blueTranslucent),
        getLineColor: (d: any) => (d.id ? layerColorsMapper.grayRoad : layerColorsMapper.blue),
        getLineWidth: defaultWidth,
        highlightColor: layerColorsMapper.highlightColor,
        stroked: true,
        filled: true,
        wireframe: true,
        lineJointRounded: true,
        lineWidthMinPixels: defaultWidth,
      });
    default:
      return null;
  }
};
