import securedAxiosInstance from 'services/axiosInstances/axiosSecured';
import { applyParamsFilter } from '../services/net/applyParamsFilter';
import { Filters } from './model/Filters.model';

export const getFromURL = {
  async getAll(url: string) {
    try {
      const response = await securedAxiosInstance.get(url);
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
  async getDataFromURL(url: string) {
    try {
      const response = await securedAxiosInstance.get(url);
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
  async getWithParams(url: string, params = {}) {
    try {
      const response = await securedAxiosInstance.get(url, {
        params: params,
      });
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },

  /**
   * ?representation=true возвращает объект только с 2 свойствами - key: {id}, value: {name}
   */

  async getRepresentationData(url: string) {
    try {
      const response = await securedAxiosInstance.get(url.replace('/api', '') + '?representation=true', {});
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
  async getStatementRepresentationData(url: string) {
    try {
      const response = await securedAxiosInstance.get(url.replace('/api', '') + '&representation=true', {});
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
  async getRepresentationDataForRoad(url: string) {
    try {
      const response = await securedAxiosInstance.get(url.replace('/api', '') + '?representation=true', {
        params: {
          page_size: Number.MAX_SAFE_INTEGER,
        },
      });
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
  async getById(url: string, id: string | number) {
    try {
      const response = await securedAxiosInstance.get(url + `${id}/`);
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
  async getDataFromURLWithFilters(url: string, filters: Filters) {
    try {
      const query = applyParamsFilter({ filters });
      if (query) {
        const response = await securedAxiosInstance.get(url + query);
        return response.data.results;
      } else return null;
    } catch (e) {
      throw e;
    }
  },
  async getModelFromURL(url: string) {
    try {
      const response = await securedAxiosInstance.options(url);
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
  async getDataByParent(url: string, parentKey: string, parentId: string | number) {
    try {
      let searchParam = `?${parentKey}__id=${parentId}`;
      const response = await securedAxiosInstance.get(url + searchParam);
      return response?.data ? response?.data : response;
    } catch (e) {
      throw e;
    }
  },
};
