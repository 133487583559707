import { RelatedDataPrototype } from 'registrators/map/layers/description/relatedData/prototype/RelatedDataPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { ApplicationsService, StatusF30Enum } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { RequestPath } from 'types/enums/routes/request/RequestPath';

export class BrigadeApplications extends RelatedDataPrototype {
  constructor() {
    super({
      name: MapObjects.brigadeApplications,
      filterLabel: 'Заявки',
      selectedObjectName: MapObjects.brigadeApplications,
    });
  }

  async uploadData() {
    return await ApplicationsService.applicationsList({
      statusIn: `${StatusF30Enum.NEW},${StatusF30Enum.CONFIRMED},${StatusF30Enum.APPOINTED},${StatusF30Enum.IN_WORK},${StatusF30Enum.DONE}`,
    });
  }

  async deleteRecord(id: number) {
    return await ApplicationsService.applicationsDestroy({ id });
  }

  async readRecord(id: number) {
    return await ApplicationsService.applicationsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.brigadeApplications);
  }
}
