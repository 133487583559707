import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import React from 'react';
import { PublicTransportService } from '../../../../../../generated/api/api';
const PublicSchemaSwitcher = React.lazy(() => import('../customButtons/PublicTransportSwitcher.lazy'));
let selectedType = 'transportRoutes';

export class PublicTransportDataAccessor extends LayerDataAccessorPrototype {
  static selectedType = LayerDataAccessorPrototype.selectedType;

  actionButtons = [PublicSchemaSwitcher];

  constructor() {
    super({
      label: 'Маршруты общественного транспорта',
      selectedType: MapObjects.publicTransportRoute,
    });
  }

  async getData() {
    return await PublicTransportService.publicTransportRouteSchemesList();
  }

  // по запросу по модели вернем нашу схему. Await нужен для согласованности then, можно и в промис обернуть, но лень)
  async getModel() {
    return await getFromURL.getModelFromURL('/public_transport/route_schemes');
  }
  static replaceSelectedType(type) {
    PublicSchemaSwitcher.selectedType = type;
  }

  static getSelectedType() {
    return PublicSchemaSwitcher.selectedType ? PublicSchemaSwitcher.selectedType : selectedType;
  }

  getSelectedObjectType() {
    return PublicSchemaSwitcher.selectedType ? PublicSchemaSwitcher.selectedType : selectedType;
  }
}
