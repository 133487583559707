import React from 'react';
import SecondaryGroup from './SecondaryGroup';
import { zoomIn, zoomOut } from '../../../../../../store/reducers/map/actions/mapboxActions';
import { connect } from 'react-redux';
import { setRulerMode, toggleLegend } from '../../../../../../store/reducers/map/actions/mapPanelsActions';
import { getSelectedMapboxLayer } from '../../../../../../store/reducers/map/mapboxLayers';

const SecondaryGroupContainer = ({
  handleZoomIn,
  handleSetRulerMode,
  handleZoomOut,
  viewState,
  handleToggleLegend,
  mapLegendVisible,
  selectedLayer,
  ...other
}) => {
  return (
    <SecondaryGroup
      handleZoomIn={handleZoomIn}
      handleZoomOut={handleZoomOut}
      viewState={viewState}
      toggleRuler={handleSetRulerMode}
      handleToggleLegend={handleToggleLegend}
      mapLegendVisible={mapLegendVisible}
      selectedLayer={selectedLayer}
      {...other}
    />
  );
};

SecondaryGroupContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    viewState: state.mapboxView.viewState,
    mapLegendVisible: state.mapPanels.mapLegendVisible,
    selectedLayer: getSelectedMapboxLayer(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleZoomIn: () => dispatch(zoomIn()),
    handleZoomOut: () => dispatch(zoomOut()),
    handleSetRulerMode: (mode) => dispatch(setRulerMode(mode)),
    handleToggleLegend: () => dispatch(toggleLegend()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryGroupContainer);
