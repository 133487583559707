import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const IlluminatedPedestrianCrossingsItemSelection = ({
  model,
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'illuminated_pedestrian_crossings');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.illuminated_pedestrian_crossings}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  const properties = data;
  const scheme = model.scheme;
  const typFood = scheme.power_supply_type.choices.find((f) => f.value === properties?.power_supply_type);

  Descriptions.push({
    key: 'Адрес',
    value: properties.address ? properties.address : '-',
  });

  Descriptions.push({
    key: 'Тип питания',
    value: typFood?.display_name,
  });

  return (
    <PrepareScrollableInfoCard
      title={'Пешеходные переходы'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default IlluminatedPedestrianCrossingsItemSelection;
