import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import SpeedBumpsCompositeLayers from '../../../../../../layers/CompositeLayers/ekb/SpeedBumpsCompositeLayers';

export class SpeedBumpsLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: SpeedBumpsCompositeLayers,
      id: 'SpeedBumpSchema',
    });
  }
}
