import React, { FC, useMemo } from 'react';
import { ResponsiveContainer, PieChart as PieChartR, Pie, Cell, Tooltip } from 'recharts';

import { Styled } from './style/PieChart.styled';
import PieTooltip from './components/PieTooltip/PieTooltip';
import { PieChartProps } from './model/PieChart.model';

const {
  PieChartWrap,
  PieChartLegend,
  PieChartLegendWrap,
  PieChartLegendItem,
  PieChartLegendItemName,
  PieChartLegendItemValue,
} = Styled;

const PieChart: FC<PieChartProps> = ({ chartSettings, data }) => {
  const formatData = useMemo(() => {
    return data.map((el) => ({
      ...el,
      data: el.data?.[0]?.result ?? 0,
    }));
  }, [data]);

  const legend = useMemo(() => {
    return formatData.map((el) => (
      <PieChartLegendItem key={el.id} color={el.color}>
        <PieChartLegendItemName>{el.name}</PieChartLegendItemName>
        <PieChartLegendItemValue>{el.data}</PieChartLegendItemValue>
      </PieChartLegendItem>
    ));
  }, [formatData]);

  return (
    <PieChartWrap>
      <ResponsiveContainer>
        <PieChartR>
          <Pie data={formatData} dataKey={'data'} innerRadius={90}>
            {formatData.map((el) => {
              return <Cell key={`cell-${el.id}`} fill={el.color} />;
            })}
          </Pie>
          <Tooltip content={PieTooltip} />
        </PieChartR>
      </ResponsiveContainer>
      {chartSettings?.showCaptions && (
        <PieChartLegendWrap>
          <PieChartLegend>{legend}</PieChartLegend>
        </PieChartLegendWrap>
      )}
    </PieChartWrap>
  );
};

export default PieChart;
