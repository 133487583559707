import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin: 5px 5px 20px;
  width: calc(100% / 3 - 40px);
  min-width: 314px;
  justify-content: flex-start;
  position: relative;
  display: ${(props) => (props.hidden ? 'hidden' : 'flex')};
  & .ant-form-item {
    width: 100%;
  }
  & .ant-input,
  &.ant-input-number {
    border-radius: 8px;
    color: ${(props) => props.theme.colors.paragraph} !important;
    &:focus,
    &:hover {
      border: 2px solid ${(props) => props.theme.colors.primaryTranslucent} !important;
    }
  }
  .sc-amjdv {
    color: ${(props) => props.theme.colors.neutralDarker} !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 140%;
  }
  span {
    > span {
      font-weight: 500;
      font-size: 15px;
      color: ${(props) => props.theme.colors.neutralDarker};
    }
  }
  span.opened {
    transform: translateY(-8px) !important;
    color: ${(props) => props.theme.colors.primary} !important;
    font-weight: 500;
    font-size: 15px;
  }
  span.class_errors {
    color: ${(props) => props.theme.colors.danger} !important;
    font-weight: 500;
    font-size: 15px;
  }

  div.class_errors {
    .ant-select-selection,
    .ant-input,
    .ant-input-number {
      border-radius: 8px;
      border: 2px solid ${(props) => props.theme.colors.danger} !important;
    }
  }
`;

const StyledWrapperTextArea = styled(StyledWrapper)`
  width: calc(100% - 40px);
`;

const StyledWrapperTellTextArea = styled(StyledWrapperTextArea)`
  height: 120px;
  textarea {
    height: 100%;
    white-space: normal;
  }
`;

const StyledVehicleParametersContainer = styled(StyledWrapper)`
  width: calc(100% - 40px);
  height: 600px;
  display: flex;
  flex-direction: column;
`;

export const Styled = {
  StyledWrapper,
  StyledWrapperTextArea,
  StyledVehicleParametersContainer,
  StyledWrapperTellTextArea,
};
