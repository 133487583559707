import React from 'react';
import styled from 'styled-components/macro';
import { Styled } from './RoadAccident.style';
import ReportSider from '../../parts/Sider/ReportSider';
import DataTSContainer from '../../parts/DataForFilters/DataTSContainer';
import ExtensionData from '../../parts/ExtensionData/ExtensionData';
import ScrollableBoxArea from '../../../../../../../../UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';
import TimePeriodicityData from '../../parts/PeriodicityData/TimePeriodicityData';

const StyledDrawer = styled.div``;
const { PanelLayout, SelectedReport, LocalInnerWrapper, LocalTitle, LocalReportData, LocalDataTS, LocalExtensionData } =
  Styled;

const RoadAccident = ({ sendData }) => {
  return (
    <StyledDrawer placement={'right'} closable={false} visible={false} getContainer={false} bodyStyle={{ padding: 0 }}>
      <SelectedReport>
        <span>Отчеты</span>
        <span>•</span>
        <span>Анализ ДТП</span>
      </SelectedReport>
      <PanelLayout>
        <ReportSider sendData={sendData} />
        <ScrollableBoxArea>
          <LocalTitle>Формирование отчетности о реализации мероприятий</LocalTitle>
          <LocalInnerWrapper>
            <LocalDataTS>
              <DataTSContainer />
            </LocalDataTS>
            <LocalReportData>
              <TimePeriodicityData />
            </LocalReportData>
            <LocalExtensionData>
              <ExtensionData />
            </LocalExtensionData>
          </LocalInnerWrapper>
        </ScrollableBoxArea>
      </PanelLayout>
    </StyledDrawer>
  );
};

export default RoadAccident;
