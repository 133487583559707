import { Select } from 'antd';
import { Form } from 'antd';
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getFromURL } from '../../../../api/getFromURL';
import { Styled } from '../styled/BaseInput.styled';
import { useBaseInputPlaceholderControl } from '../hooks/useBaseInputPlaceholderControl';
import { checkConditionAndCallFn } from '../../../../services/common/checkConditionAndCallFn';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';
import _ from 'underscore';

const { Option } = Select;
const { BaseErrorMessage, BaseSelectInput, BaseSelectPlaceholder, BaseSelectInputWrap } = Styled;

const StatementRelatedFieldInput = (props) => {
  const inputWrapRef = useRef(null);
  const { control, errors, setValue } = useFormContext();
  const { description, object, field, mode } = props;
  const [optionsToChoose, setOptionsToChoose] = useState([]);
  // firstOptionsToChoose состояние для хранения первых пунктов меню отображаемых в селекте
  const [firstOptionsToChoose, setFirstOptionsToChoose] = useState([]);
  // filteredOptionsToChoose состояние для хранения отфильтрованных пунктов меню по ключевому слову введенному пользователем в селект
  const [filteredOptionsToChoose, setFilteredOptionsToChoose] = useState([]);
  // countOptionsList коэффициент для умножения количества пунктов меню отображаемых в селекте
  // первое значение равно двум так как по умолчанию отображается 20 пунктов и при первой прокрутке количество будет умножено на 2.
  const [countOptionsList, setCountOptionsList] = useState(2);
  const { required, message } = useBaseInputSettings(description);

  const { placeholderState, setPlaceholderState } = useBaseInputPlaceholderControl(field, object, setValue);

  const selectOption = useMemo(() => optionsToChoose?.filter((el) => el?.props?.value === object?.[field]) ?? [], [
    field,
    object,
    optionsToChoose,
  ]);

  useEffect(() => {
    if (description?.url) {
      (async () => {
        const result = await getFromURL.getStatementRepresentationData(description.url.replace('/api', ''));
        if (Array.isArray(result?.results) && result?.results.length) {
          const options = result.results.map((el) => (
            <Option value={el.key}>{el.value ? el.value : 'fallback data'}</Option>
          ));
          setOptionsToChoose(options);
          setFirstOptionsToChoose(_.first(options, 20));
        } else setOptionsToChoose(null);
      })();
    }
  }, [description]);

  const className = useMemo(() => {
    if (placeholderState) return 'opened';
    if (errors[field]) return 'class_errors';
    return '';
  }, [errors, field, placeholderState]);

  if (errors[field]?.message?.includes(description.label) && errors[field]) {
    errors[field].message = null;
  }

  let templateString = useRef('');

  const handleSetTemplates = (value) => {
    templateString.current = value;
    // @ts-ignore
    const filterOption = optionsToChoose?.filter((el) => el.props.children.toLowerCase().includes(value.toLowerCase()));
    setFilteredOptionsToChoose(filterOption);
    // @ts-ignore
    setFirstOptionsToChoose(_.first(filterOption, 20));
  };

  const onScroll = useCallback(
    (event) => {
      const { target } = event;
      const { scrollTop, offsetHeight, scrollHeight } = target;
      if (scrollTop + offsetHeight === scrollHeight) {
        let newOption = optionsToChoose;
        if (filteredOptionsToChoose.length) {
          newOption = filteredOptionsToChoose;
        }
        setFirstOptionsToChoose(_.first(newOption, countOptionsList * 20));
        setCountOptionsList(countOptionsList + 1);
      }
    },
    [countOptionsList, filteredOptionsToChoose, optionsToChoose]
  );

  const finalOptions = useMemo(() => [...selectOption, ..._.without(firstOptionsToChoose, selectOption[0])], [
    firstOptionsToChoose,
    selectOption,
  ]);

  return (
    <Form.Item>
      <BaseSelectInputWrap ref={inputWrapRef} className={className}>
        <Controller
          dropdownClassName={'custom-dropdown'}
          allowClear={true}
          name={field}
          as={
            <BaseSelectInput onSearch={(value) => handleSetTemplates(value)} onPopupScroll={onScroll}>
              {finalOptions}
            </BaseSelectInput>
          }
          control={control}
          disabled={!!description.read_only}
          mode={mode || null}
          showSearch
          optionFilterProp="children"
          rules={{ required: { value: required, message: message } }}
          getPopupContainer={() => inputWrapRef.current}
          onFocus={() => {
            setPlaceholderState(true);
          }}
          onBlur={(e) => {
            checkConditionAndCallFn(!e[0], () => setPlaceholderState(false));
          }}
        />
        <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
        <BaseSelectPlaceholder className={className}>{description.label}</BaseSelectPlaceholder>
      </BaseSelectInputWrap>
    </Form.Item>
  );
};

export default StatementRelatedFieldInput;
