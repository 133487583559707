import { Layout } from 'antd';
import styled from 'styled-components/macro';

const { Sider } = Layout;

const SiderContainer = styled(Sider)`
  height: calc(100vh - 30px);
  margin: 15px;
  margin-right: 0px;
  z-index: 1000;
`;

export const Styled = { SiderContainer };
