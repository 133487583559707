import styled from 'styled-components/macro';

const PrepareInputStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const PrepareInputName = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  color: ${(props) => props.theme.colors.gray};
  line-height: 140%;
  margin-bottom: 16px;
`;

export const Styled = {
  PrepareInputStyled,
  PrepareInputName,
};
