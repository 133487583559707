import styled from 'styled-components/macro';

const LocalMainWrapper = styled.div<{ imgSrc: string }>`
  display: flex;
  position: relative;
  background-image: url(${(props) => props.imgSrc});
  top: calc(50% - 1080px / 2);
  background-size: cover;
  background-repeat: no-repeat;

  .greeting-clipPath {
    position: absolute;
    top: -100px;
    left: -40px;
    z-index: 0;
  }
`;

const LocalBackgroundLeftSide = styled.div<{ lftSrc: string }>`
  position: absolute;
  left: 0;
  height: 100vh;
  width: 29%;
  background-image: url(${(props) => props.lftSrc});
  background-size: cover;
  background-position: 100% 60%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  z-index: 0;
`;

const LocalLogoProject = styled.div`
  position: absolute;
  top: 5%;
`;

const LocalGreetingSpriteWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  text-align: left;
  z-index: 100000;
  bottom: 12%;
`;

const LocalGreetingSpriteInner = styled.div`
  width: 430px;
  text-align: left;
  z-index: 1;
`;

const LocalLogoITS = styled.div`
  position: absolute;
  bottom: 6%;
  left: 2%;
`;

const LocalBackgroundCenter = styled.div`
  position: relative;
  width: 338px;
  left: 11%;
  z-index: 0;
`;

const LocalBackgroundRightSide = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
`;

const LocalBackgroundIMG = styled.img`
  height: 100%;
  display: inline;
  pointer-events: none;
  objectfit: 'fill';
`;

export const Styled = {
  LocalMainWrapper,
  LocalBackgroundLeftSide,
  LocalLogoProject,
  LocalLogoITS,
  LocalBackgroundRightSide,
  LocalBackgroundIMG,
  LocalBackgroundCenter,
  LocalGreetingSpriteInner,
  LocalGreetingSpriteWrapper,
};
