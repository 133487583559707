import React, { Dispatch, FC } from 'react';
import { connect } from 'react-redux';

import { StateModel } from 'store/reducers';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';

import { ApplicationUtils } from './ApplicationUtils';
import { ApplicationUtilsContainerProps } from './model/ApplicationUtilsContainer.model';
import { PromptState } from 'store/reducers/global/notifications/notifications.model';

const ApplicationUtilsContainer = (props: ApplicationUtilsContainerProps) => {
  const { isFetching, alertData, clearGlobalAlertData, promptData } = props;

  return (
    <ApplicationUtils
      isFetching={isFetching}
      alertData={alertData}
      clearGlobalAlertData={clearGlobalAlertData}
      promptData={promptData}
    />
  );
};

const mapStateToProps = (state: StateModel) => ({
  alertData: state.notifications.alert,
  promptData: state.notifications.notification,
  isFetching: state.app.fetching,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setGlobalAlertData: (alertRecord: PromptState) => dispatch(notificationActions.setGlobalAlertData(alertRecord)),
  clearGlobalAlertData: () => dispatch(notificationActions.clearGlobalAlertData()),
});
/**
 * Контейнерная компонента служебных компонент (уведомления и неактивность пользователя)
 * @see ApplicationUtils
 */
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationUtilsContainer) as FC;
