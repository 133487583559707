import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import withContentPadding from 'HOC/withContentPadding';
import { arrayGroupBy } from 'services/data/arrayGroupBy';
import { CollapsableItem } from 'UI/common/components/CollapsableItem/CollapsableItem';
import SingleSubjectCard from 'UI/common/components/SingleSubjectCard/SingleSubjectCard';
import _ from 'underscore';

import { LocalInnerWrapper, LocalMainWrapper } from './AdministrationCards.styled';
import { registeredAdministrationUrls } from 'registrators/administrationPage/administratonPageRegistrator';
import { AdministrationRegistratorObjectProps } from 'registrators/administrationPage/AdministrationRegistratorObjectProps';
import { StateModel } from '../../../../../store/reducers';
import { useSelector } from 'react-redux';

const AdministrationCards = () => {
  let resultList;
  const userDirectoryRights = useSelector((state: StateModel) => state.auth.permissions.directory);
  const registeredUrlsWithPermission = registeredAdministrationUrls.filter((administrationItem) => {
    const readPermission = administrationItem.permissionType.find((permission) => permission.includes('view'));
    if (!readPermission) return false;
    return userDirectoryRights.includes(readPermission);
  });
  const uniqueFolderKeys = [..._.uniq(registeredUrlsWithPermission.map((item) => item.folderKey))];
  resultList = arrayGroupBy(
    registeredUrlsWithPermission,
    (rule: AdministrationRegistratorObjectProps) => rule.folderKey
  );
  const cardsGroupsForRender = [];
  for (const folderKey of uniqueFolderKeys) {
    const oneCardsGroup = [];
    const itemsCollection = resultList?.get(folderKey);
    oneCardsGroup.push(
      itemsCollection.map((el: AdministrationRegistratorObjectProps, index: number) => (
        <Link to={`/app/administration/${el.url}/`} key={index}>
          <SingleSubjectCard titleContent={el.label} titleDescr={el.description} url={el.urlData} to={el.url} />
        </Link>
      ))
    );
    cardsGroupsForRender.push({
      name: folderKey,
      cardsGroup: oneCardsGroup,
    });
  }

  return (
    <LocalMainWrapper>
      {cardsGroupsForRender.map((el, index) => {
        return (
          <CollapsableItem rotateDegree={90} titleContent={el.name} key={index}>
            <LocalInnerWrapper>{el.cardsGroup}</LocalInnerWrapper>
          </CollapsableItem>
        );
      })}
    </LocalMainWrapper>
  );
};

const enhance = compose(withContentPadding);

export default enhance(AdministrationCards);
