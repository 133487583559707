import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { Form, InputNumber } from 'antd';

import { postToURLSingle } from 'api/postToURLSingle';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import _ from 'underscore';

import { Styled } from './CreatBlankStatementButton.styled';
import { useDispatch } from 'react-redux';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';

const { StartUploadButton, LocalModal, FormContainer, ContainerLable } = Styled;

interface ICreatBlankModal {
  isOpenModal: boolean;
  setOpenModal: (status: boolean) => void;
  numberOpenApplicationForms: number;
  refreshData: () => void;
}

export const CreatBlankStatementModal = (props: PropsWithChildren<ICreatBlankModal>) => {
  const dispatch = useDispatch();
  const { isOpenModal, setOpenModal, numberOpenApplicationForms, refreshData } = props;
  const [startCountApplicationForms, setStartCountApplicationForms] = useState<number>(0);
  const [countApplicationForms, setCountApplicationForms] = useState<number>(0);

  useEffect(() => {
    setStartCountApplicationForms(numberOpenApplicationForms + 1);
  }, [numberOpenApplicationForms]);

  const postNewStatus = useCallback(async () => {
    if (startCountApplicationForms < numberOpenApplicationForms) {
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: `Введенное вами первоначальный номер бланков не может быть меньше ${numberOpenApplicationForms}`,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
      return;
    }
    try {
      await postToURLSingle.postOrPutDataErrorResponse(`special_permits/forms_account/generate/`, {
        from_number: startCountApplicationForms,
        to_number: startCountApplicationForms + countApplicationForms - 1,
      });
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: 'Новые бланки сгенерированы',
        })
      );
      setOpenModal(false);
    } catch (e) {
      if (_.isObject(e) && e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message,
          })
        );
      }
    } finally {
      setTimeout(() => {
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
        refreshData && refreshData();
      }, AlertDelay.main);
    }
  }, [
    countApplicationForms,
    dispatch,
    numberOpenApplicationForms,
    refreshData,
    setOpenModal,
    startCountApplicationForms,
  ]);

  const startChangeStatusButton = useMemo(
    () => (
      <StartUploadButton>
        <Button
          className={'shitTrader'}
          width={'100%'}
          type={ButtonType.primary}
          onClick={postNewStatus}
          disabled={!countApplicationForms}
        >
          <p>Добавить бланки</p>
        </Button>
      </StartUploadButton>
    ),
    [countApplicationForms, postNewStatus]
  );

  return (
    <>
      <LocalModal
        title={`Регистрация бланков строгой отчетности`}
        visible={isOpenModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        centered={true}
        footer={startChangeStatusButton}
      >
        <FormContainer>
          <Form.Item>
            <ContainerLable>Начальный номер зарегистрированных бланков срогой отчетности:</ContainerLable>
            <InputNumber
              value={startCountApplicationForms}
              onChange={(count) => setStartCountApplicationForms(count ?? 0)}
            />
          </Form.Item>
          <Form.Item>
            <ContainerLable>Количество регистрируемых бланков строгой отчетности:</ContainerLable>
            <InputNumber onChange={(count) => setCountApplicationForms(+count ?? 0)} />
          </Form.Item>
        </FormContainer>
      </LocalModal>
    </>
  );
};
