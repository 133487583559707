import React from 'react';
import { Menu } from 'antd';
import { Styled } from './ReportSider.styled';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../../../../UI/common/components/Button/Button';
import useGlobal from '../../store';

const { SiderWrapper, SiderInner, SiderContent, SiderFooter, SiderTitle } = Styled;

const ReportSider = ({ sendData }) => {
  const history = useHistory();
  const [, globalActions] = useGlobal();

  const replaceAndCleaningStore = () => {
    history.replace('/app/reports/');
    globalActions.actions.emptyStoreAction();
  };
  return (
    <SiderWrapper width={'216px'}>
      <SiderInner>
        <SiderContent>
          <Menu onClick={''}>
            <Menu.Item>
              <div>Конструктор</div>
            </Menu.Item>
          </Menu>
          <SiderTitle>Сохраненные шаблоны</SiderTitle>
        </SiderContent>
        <SiderFooter>
          <Button onClick={sendData} type={'primary'} width={'100%'}>
            Сформировать отчет
          </Button>
          <Button onClick={replaceAndCleaningStore} type={'danger'} width={'100%'}>
            Отменить и закрыть
          </Button>
        </SiderFooter>
      </SiderInner>
    </SiderWrapper>
  );
};

export default ReportSider;
