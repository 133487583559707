import React from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { LightingItemSelectionProps } from './RoadSchemeItemSelection.model';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const LightingItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: LightingItemSelectionProps) => {
  const handleOpenObjectInNewTab = () => {
    const { id } = data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadsConstructionLighting}/${id}/`, '_blank');
    win?.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об освещении'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

LightingItemSelection.propTypes = {};

export default LightingItemSelection;
