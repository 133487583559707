import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
/*import { ArtificialStructuresService } from '../../../../../../generated/api/api';*/
import { getFromURL } from '../../../../../../api/getFromURL';

export class Pipelines extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'pipelines',
      filterLabel: 'Трубопроводы',
      selectedObjectName: MapObjects.pipelines,
    });
  }

  /*
  async uploadData() {
    return await ArtificialStructuresService.artificialStructuresPipelinesList();
  }

  async deleteRecord(id) {
    return await ArtificialStructuresService.artificialStructuresPipelinesDelete({ id });
  }

  async readRecord(id) {
    return await ArtificialStructuresService.artificialStructuresPipelinesRead({ id });
  }
*/

  async uploadModel() {
    return await getFromURL.getModelFromURL('/artificial_structures/pipelines/');
  }
}
