import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapDisplayMode } from './MapDisplayMode';
import { setCurrentMapMode, revertToDefaultMapMode } from 'store/reducers/map/actions/mapModeActions';
import { MapMode } from 'types/enums/map/MapMode.model';
import { StateModel } from 'store/reducers';
import { WebMercatorViewport } from 'deck.gl';

export const MapDisplayModeContainer = ({ deckViewRef }: { deckViewRef: WebMercatorViewport | null }) => {
  const dispatch = useDispatch();
  const dataAccessPanelVisible = useSelector((state: StateModel) => state.mapPanels.dataAccessorVisible);
  const isSidebarOpened = useSelector((state: StateModel) => state.app.sidebarIsOpened);
  const currentMode = useSelector((state: StateModel) => state.mapMode.currentMode);
  const mapGlobalPrintMode = useSelector((state: StateModel) => state.mapPanels.GlobalPrintMode);

  const setCurrentMapModeHandler = useCallback(
    (modeType: MapMode) => dispatch(setCurrentMapMode(modeType)),
    [dispatch]
  );

  const revertToDefaultMapModeHandler = useCallback(() => dispatch(revertToDefaultMapMode()), [dispatch]);

  useEffect(() => {
    setCurrentMapModeHandler(MapMode.display);
    return function cleanup() {
      revertToDefaultMapModeHandler();
    };
  }, [revertToDefaultMapModeHandler, setCurrentMapModeHandler]);

  return (
    <MapDisplayMode
      dataAccessPanelVisible={dataAccessPanelVisible}
      currentMode={currentMode}
      isSidebarOpened={isSidebarOpened}
      mapGlobalPrintMode={mapGlobalPrintMode}
      deckViewRef={deckViewRef}
    />
  );
};
