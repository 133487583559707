import { NumericLayerSetting } from './prototype/types/NumericLayerSetting';

export class TransportAnalysisBrushLayer extends NumericLayerSetting {
  constructor() {
    super({
      label: 'Километр области показа',
      keyForDeck: 'brushRadius',
      type: 'Number',
      minimum: 0.1,
      currentValue: 0.5,
      maximum: 10,
      step: 0.1,
      isRanged: false,
      isPercent: true,
    });
  }
}
