import styled from 'styled-components/macro';
import { Styled as styleMixins } from '../../../../../styles/styleMixins/styleMixins.styled';

const { HideScrollbar } = styleMixins.other;
const { AddMarginsProps, AddPaddingsProps } = styleMixins.offset;

const BaseWrapper = styled.div`
  background: ${(props) => props.theme.colors.defaultLighter};
  ${HideScrollbar};
  ${(props) => AddMarginsProps(props)};
  ${(props) => AddPaddingsProps(props)};
  border-radius: ${(props) => props.theme.decoration.largeBorderRadiusOnlyTop};
  box-shadow: 0 2px 8px rgba(14, 51, 137, 0.1);
  max-width: 100%;
  z-index: 1;
  height: fit-content;
  transition: all 0.3s;
`;

const LocalWrapperPrimary = styled(BaseWrapper)`
  width: 272px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  backdrop-filter: ${(props) => props.theme.decoration.defaultBackgroundFilter};
`;

const LocalWrapperSecondary = styled(LocalWrapperPrimary)`
  max-width: 100%;
  z-index: 0;
`;

const LocalSectionWrap = styled.div`
  padding: ${(props) => props.theme.paddings.defaultPadding};
  color: ${(props) => props.theme.colors.darkBlue};
  font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  ${(props) => AddMarginsProps(props)};
  ${(props) => AddPaddingsProps(props)};
`;

const LocalHeaderWrap = styled(LocalSectionWrap)`
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
  ${(props) => AddMarginsProps(props)};
  ${(props) => AddPaddingsProps(props)};
`;

const LocalSpaceBetweenPartWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LocalItemWrap = styled.div`
  margin-top: 1rem;
`;

export const Styled = {
  LocalWrapperPrimary,
  LocalWrapperSecondary,
  LocalSectionWrap,
  LocalHeaderWrap,
  LocalItemWrap,
  LocalSpaceBetweenPartWrap,
};
