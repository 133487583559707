import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form';

import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import widgetsPrepareInputs from './util/widgetsPrepareInputs';
import { Styled } from './style/WidgetsEditPanel.styled';
import { CamerasService, CameraWidget } from 'generated/api/api';
import { AnyObject } from 'types/enums/general/general.model';
import { widgetsActions } from 'store/reducers/widgets/widgets.actions';
import usePrepareFieldsHook from 'hooks/form/usePrepareFieldsHook/usePrepareFieldsHook';

const { StyledForm, StyledFormWrap } = Styled;

// Реализация под один вид данных для виджета
const WidgetsEditPanel: FC<any> = ({ data, model }) => {
  const dispatch = useDispatch();
  const methods = useForm();
  const formRef = useRef<any>();

  const { watchFields, prepareModel, prepareData } = usePrepareFieldsHook(model, data);

  // Отрисовка инпутов по модели
  const content = useMemo(() => {
    if (prepareModel && prepareData) {
      return widgetsPrepareInputs(prepareModel, prepareData, watchFields);
    }

    return null;
  }, [prepareModel, prepareData, watchFields]);

  const onClick = useCallback(() => {}, []);

  const onFinish = useCallback(
    (data, event) => {
      event.preventDefault();
      methods.triggerValidation().then(async (isDataValidation) => {
        if (isDataValidation) {
          try {
            let widget: CameraWidget;

            if (data.id) {
              //@ts-ignore
              widget = await CamerasService.camerasWidgetUpdate({ id: data.id, body: data });
            } else {
              //@ts-ignore
              widget = await CamerasService.camerasWidgetCreate({ body: data });
            }
            dispatch(widgetsActions.setCurrentWidget(widget));
            dispatch(widgetsActions.changePanelVisible(false));
          } catch (e) {
            dispatch(
              notificationActions.setGlobalAlertData({
                status: ActiveStatus.active,
                type: PromptType.error,
                title: 'Ошибка',
                message: createAlertMessage(e as AnyObject),
              })
            );
          }
        }
      });
    },
    [dispatch, methods]
  );

  return (
    <FormContext {...methods}>
      <StyledForm ref={formRef} onSubmit={methods.handleSubmit(onFinish)}>
        {content && (
          <ScrollableCard
            headerContent={'Создание виджета'}
            footerContent={
              <Button type={ButtonType.primary} onClick={onClick} width={'100%'}>
                Создать виджет
              </Button>
            }
          >
            <StyledFormWrap>{content}</StyledFormWrap>
          </ScrollableCard>
        )}
      </StyledForm>
    </FormContext>
  );
};
export default WidgetsEditPanel;
