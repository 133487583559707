import React, { FC, useCallback, useMemo } from 'react';

import { Styled } from './style/AvailableMasks.styled';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { PlusIcon } from 'UI/custom/icons/actionIcons/PlusIcon';
import { Button } from 'UI/common/components/Button/Button';
import Input from 'UI/common/components/Input/Input';
import { ObjectType } from '../../utils/masks/prototype/ObjectType';
import { AvailableMaskItemProps } from './model/AvailableMasks.model';
import { MaskObjectList } from '../../utils/masks/prototype/CameraMaskPrototype';

const {
  AvailableMaskItemWrap,
  AvailableMaskItemButton,
  AvailableMaskItemName,
  AvailableMaskItemButtonWrap,
  CurrentAvailableMaskItem,
  CurrentAvailableMaskItemTitle,
  CurrentAvailableMaskItemButtons,
  CurrentAvailableMaskItemStepTitle,
  CurrentAvailableMaskItemStepsWrap,
  CurrentAvailableMaskItemStep,
  CurrentAvailableMaskItemCircle,
  CurrentAvailableMaskItemButton,
} = Styled;

const AvailableMaskItem: FC<AvailableMaskItemProps> = ({
  mask,
  onSetMask,
  onCancelMask,
  onSaveMask,
  currentMask,
  onChangeDrawType,
  onChangeMaskName,
  cameraID,
}) => {
  const selected = useMemo(() => currentMask && mask.getMaskType() === currentMask.maskType, [currentMask, mask]);

  const disabled = useMemo(() => currentMask && mask.getMaskType() !== currentMask.maskType, [currentMask, mask]);

  const typeName: string = useMemo(() => mask.getMaskTypeName(), [mask]);

  const maskObjectList: Array<MaskObjectList> = useMemo(() => mask.getMaskObjectList(), [mask]);

  const maskDrawSteps = useMemo(() => {
    if (selected && currentMask) {
      const currentDrawType = currentMask.currentDrawType;
      const drawMaskPoints = currentMask.drawMaskPoints;

      return maskObjectList.map((el: any) => {
        const select = el.type === currentDrawType;
        const buttonType = select ? ButtonType.primaryTranslucent : ButtonType.unbounded;
        let disabledDraw = true;

        switch (el.type) {
          case ObjectType.roiPoints: {
            disabledDraw = false;
            break;
          }
          case ObjectType.controlLinePoints:
          case ObjectType.measureLinePoints: {
            const polygon = drawMaskPoints?.[ObjectType.roiPoints];
            if (polygon) {
              disabledDraw = polygon.length < 4;
            }
            break;
          }
        }

        return (
          <CurrentAvailableMaskItemStep key={el.type}>
            <CurrentAvailableMaskItemCircle className={select ? 'select' : ''} />
            <CurrentAvailableMaskItemButton
              onClick={() => onChangeDrawType(el.type)}
              type={buttonType}
              disabled={disabledDraw}
            >
              {el.name}
            </CurrentAvailableMaskItemButton>
          </CurrentAvailableMaskItemStep>
        );
      });
    }

    return null;
  }, [currentMask, maskObjectList, onChangeDrawType, selected]);

  const onChangeName = useCallback(
    (value: string) => {
      onChangeMaskName(value);
    },
    [onChangeMaskName]
  );

  return (
    <AvailableMaskItemWrap>
      {!selected && (
        <AvailableMaskItemButtonWrap>
          <AvailableMaskItemButton
            disabled={disabled}
            onClick={() => onSetMask(new mask(cameraID, null))}
            type={ButtonType.primaryTranslucent}
            icon={<PlusIcon />}
          />
          <AvailableMaskItemName>{typeName}</AvailableMaskItemName>
        </AvailableMaskItemButtonWrap>
      )}
      {selected && (
        <>
          <CurrentAvailableMaskItem>
            <CurrentAvailableMaskItemTitle>{typeName}</CurrentAvailableMaskItemTitle>
            <Input
              small
              autoWidth
              placeholder={'Введите наименование'}
              value={currentMask?.tmpName}
              onChange={onChangeName}
            />
            <CurrentAvailableMaskItemStepTitle>Нарисуйте объекты</CurrentAvailableMaskItemStepTitle>
            <CurrentAvailableMaskItemStepsWrap>{maskDrawSteps}</CurrentAvailableMaskItemStepsWrap>
            <CurrentAvailableMaskItemButtons>
              <Button type={ButtonType.primary} onClick={onSaveMask} disabled={!currentMask.checkRules()}>
                Сохранить
              </Button>
              <Button type={ButtonType.dangerReverseTranslucent} onClick={onCancelMask}>
                Отменить
              </Button>
            </CurrentAvailableMaskItemButtons>
          </CurrentAvailableMaskItem>
        </>
      )}
    </AvailableMaskItemWrap>
  );
};

export default AvailableMaskItem;
