import styled from 'styled-components/macro';
import PanelHeader from '../../Panel/components/Header/PanelHeader';
import PanelSection from '../../Panel/components/Section/PanelItemSection';
import Panel from '../../Panel/Panel';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { GetDefaultBeatifiedScrollbar } = styleMixins.other;

const ExtendedPanelWrapper = styled(Panel)<any>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 272px;
  max-width: 100%;
`;

const ExtendedPanelHeader = styled(PanelHeader)<any>`
  position: relative;
  padding: 14px 16px 16px;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  font-weight: bold;
`;

const ExtendedPanelContent = styled(PanelSection)<any>`
  ${(props) => GetDefaultBeatifiedScrollbar(props)};
  padding: 16px 10px 16px 16px;
  flex: 1 1 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
`;

const ExtendedPanelFooter = styled(PanelHeader)<any>`
  padding: 16px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
`;

export const Styled = {
  ExtendedPanelWrapper,
  ExtendedPanelFooter,
  ExtendedPanelHeader,
  ExtendedPanelContent,
};
