import { all, fork, takeLatest, put, select } from 'redux-saga/effects';
import * as types from './types';
import { breadcrumbsSelector, siderSelector } from './selectors';

import DirectoryService from 'services/directory';
import DataHelper from 'UI/custom/data-helper';
import { BreadcrumbType } from 'UI/common/components/Breadcrumb/types';

function* getDirectoryRequest({ payload }: { payload: types.getDirectoryPayload }) {
  try {
    yield put({ type: 'APPLICATIONS.START_FETCHING' });
    const { url, params = {}, contentRenderType = 'ag_grid' } = payload;

    const normalizedUrl = DataHelper.normalizeUrl(url);
    const { model, data } = yield DirectoryService.getDirectoryData(normalizedUrl, params);

    const breadcrumbs: Array<BreadcrumbType> = yield select(breadcrumbsSelector);
    const rootBreadcrumb = DirectoryService.getRootBreadcrumbIfExist(url);

    let sider;
    if (rootBreadcrumb === undefined) {
      const siderData = DataHelper.getObjectForRenderSide(model.scheme);
      sider = {
        activeTab: 'main',
        elements: [
          {
            key: 'main',
            label: 'Основное',
            url,
          },
          ...siderData,
        ],
      };
    }

    if (rootBreadcrumb === undefined) {
      breadcrumbs.push({
        label: data?.name || data?.username || data.name,
        key: model.name,
        url,
        sider,
        params,
      });
    } else {
      breadcrumbs.push(rootBreadcrumb);
    }

    yield put({
      type: types.GET_DIRECTORY_SUCCESS,
      payload: {
        breadcrumbs,
        sider,
        model,
        data,
        contentRenderType,
        requestUrl: url,
      },
    });
  } catch (e) {
    yield put({
      type: types.GET_DIRECTORY_FAILURE,
    });
  } finally {
    yield put({ type: 'APPLICATIONS.END_FETCHING' });
  }
}

function* updateDirectoryRequest({ payload }: { payload: types.UpdateDirectoryPayload }) {
  try {
    yield put({ type: 'APPLICATIONS.START_FETCHING' });
    const { url = '', params = {}, activeTab = 'main', label = '' } = payload;

    const normalizedUrl = DataHelper.normalizeUrl(url);
    const { model, data } = yield DirectoryService.getDirectoryData(normalizedUrl, params);
    const contentRenderType = DirectoryService.getRenderType(data);

    const breadcrumbs: Array<BreadcrumbType> = yield select(breadcrumbsSelector);
    const currentSider: types.SiderInterface = yield select(siderSelector);

    const sider = {
      ...currentSider,
      activeTab,
    };

    if (activeTab === 'main') {
      breadcrumbs.pop();
    } else {
      breadcrumbs.push({
        label: label || data?.name || data?.username || data.name,
        key: model.name,
        url,
        params,
        sider,
      });
    }

    yield put({
      type: types.UPDATE_DIRECTORY_SUCCESS,
      payload: {
        breadcrumbs,
        sider,
        model,
        data,
        contentRenderType,
        requestUrl: url,
      },
    });
  } catch (e) {
    yield put({ type: types.UPDATE_DIRECTORY_FAILURE });
  } finally {
    yield put({ type: 'APPLICATIONS.END_FETCHING' });
  }
}

function* updateBreadcrumbsRequest({ payload }: { payload: types.UpdateBreadcrumbsPayload }) {
  try {
    yield put({ type: 'APPLICATIONS.START_FETCHING' });
    const { url, params = {}, sider, breadcrumbIndex = 0 } = payload;

    const normalizedUrl = DataHelper.normalizeUrl(url);
    const { model, data } = yield DirectoryService.getDirectoryData(normalizedUrl, params);

    const contentRenderType = DirectoryService.getRenderType(data);

    let breadcrumbs: Array<BreadcrumbType> = yield select(breadcrumbsSelector);
    breadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);

    yield put({
      type: types.UPDATE_BREADCRUMBS_SUCCESS,
      payload: {
        sider,
        breadcrumbs,
        model,
        data,
        contentRenderType,
        requestUrl: url,
      },
    });
  } catch (e) {
    yield put({
      type: types.UPDATE_BREADCRUMBS_FAILURE,
    });
  } finally {
    yield put({ type: 'APPLICATIONS.END_FETCHING' });
  }
}

function* watchGetMainDataRequest() {
  yield takeLatest<any>(types.GET_DIRECTORY_REQUEST, getDirectoryRequest);
}

function* watchChangeData() {
  yield takeLatest<any>(types.UPDATE_DIRECTORY_REQUEST, updateDirectoryRequest);
}

function* watchChangeBreadcrumbs() {
  yield takeLatest<any>(types.UPDATE_BREADCRUMBS_REQUEST, updateBreadcrumbsRequest);
}

export function* watchDirectoryGetMainDataRequest() {
  yield all([fork(watchGetMainDataRequest), fork(watchChangeData), fork(watchChangeBreadcrumbs)]);
}
