import { ContextItemProps, PrepareMenuContextItem } from './PrepareMenuContextItem';
import TooltipGroup from '../../UI/common/components/Tooltip/components/Group/TooltipGroup';

interface GroupsListModel {
  type: string;
  title: string;
}

interface ContextMenuGroupsFactoryProps {
  groupsList: GroupsListModel[];
  certainCollection: ContextItemProps[];
  dispatchFn: (dispatch: any) => void;
}

export function contextMenuGroupsFactory({ groupsList, certainCollection, dispatchFn }: ContextMenuGroupsFactoryProps) {
  return groupsList.map((group: GroupsListModel) => {
    const currentGroupItems = certainCollection.filter((el: ContextItemProps) => el.group === group.type);

    if (currentGroupItems.length) {
      const preparedGroupItems = currentGroupItems.map((el: ContextItemProps) => (
        <PrepareMenuContextItem el={el} dispatchFn={dispatchFn} action={el.dispatchedAction} />
      ));
      return <TooltipGroup title={group.title}>{preparedGroupItems}</TooltipGroup>;
    } else {
      return null;
    }
  });
}
