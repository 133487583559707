import { getFromURL } from 'api/getFromURL';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import _ from 'underscore';

export const GetRequest = async (
  url: string,
  dispatch: (param: any) => void,
  refreshData: () => void,
  responseMessage: string
) => {
  try {
    const response = await getFromURL.getAll(url);
    if (response) {
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: responseMessage,
        })
      );
    }
  } catch (e) {
    if (_.isObject(e) && e?.response && _.isObject(e.response)) {
      const message = createAlertMessage(e);
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message,
        })
      );
    }
  } finally {
    refreshData();
    setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
  }
};
