import React from 'react';
import Icon from '@ant-design/icons';

const SvgIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4834 16.3333L11.8084 13.4333C12.625 12.8333 13.1917 11.9 13.3084 10.8333H16.6667C16.5979 11.9339 16.2746 13.0035 15.7222 13.9579C15.1698 14.9123 14.4034 15.7254 13.4834 16.3333ZM10.8334 5.85833V2.5C14.4167 2.71667 17.2834 5.58333 17.5 9.16667H14.1417C14.0486 8.32121 13.6702 7.53274 13.0687 6.9313C12.4673 6.32986 11.6788 5.9514 10.8334 5.85833ZM5.83336 10.4167C5.83336 10.95 5.94169 11.4583 6.15002 11.9167L3.25002 13.5917C2.75405 12.607 2.4971 11.5192 2.50002 10.4167C2.50002 6.64167 5.45002 3.55833 9.16669 3.33333V6.69167C7.29169 6.9 5.83336 8.48333 5.83336 10.4167ZM9.58336 17.5C7.10836 17.5 4.93336 16.25 3.66669 14.3167L6.56669 12.6417C6.91365 13.1157 7.36781 13.501 7.89209 13.766C8.41637 14.0311 8.9959 14.1684 9.58336 14.1667C10.1167 14.1667 10.625 14.0583 11.0834 13.85L12.7584 16.75C11.8 17.2333 10.725 17.5 9.58336 17.5Z"
      fill="currentColor"
    />
  </svg>
);

const ChartArcIcon = (props) => <Icon component={SvgIcon} {...props} />;

export default ChartArcIcon;
