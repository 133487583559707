import React, { PropsWithChildren } from 'react';
import { Styled } from './style/PanelDefaultCard.styled';

const { LocalMainWrapper, LocalContentWrap } = Styled.main;
const { LocalActionAreaWrap } = Styled.actionArea;
const { LocalDescriptionAreaWrap } = Styled.descriptionArea;
const { LocalPresentationAreaWrap } = Styled.presentationArea;

const ActionArea = ({ children, ...otherProps }: PropsWithChildren<{}>) => {
  return <LocalActionAreaWrap {...otherProps}>{children}</LocalActionAreaWrap>;
};

const DescriptionArea = ({ children, ...otherProps }: PropsWithChildren<{}>) => {
  return <LocalDescriptionAreaWrap {...otherProps}>{children}</LocalDescriptionAreaWrap>;
};

const PresentationArea = ({ children, ...otherProps }: PropsWithChildren<{}>) => {
  return <LocalPresentationAreaWrap {...otherProps}>{children}</LocalPresentationAreaWrap>;
};

const Content = ({ children, ...otherProps }: PropsWithChildren<{}>) => {
  return <LocalContentWrap {...otherProps}>{children}</LocalContentWrap>;
};

const Main = ({ children, ...otherProps }: PropsWithChildren<{}>) => {
  return <LocalMainWrapper {...otherProps}>{children}</LocalMainWrapper>;
};

export const PanelDefaultCard = {
  Main,
  Content,
  PresentationArea,
  DescriptionArea,
  ActionArea,
};
