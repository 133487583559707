import {
  CLEAR_STANDALONE_EDIT_MODE_OBJECT,
  REPLACE_COORDINATES_DATA_IN_STANDALONE_EDIT_MODE,
  REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE,
  SET_EDITING_CARD_VALUE,
  TOGGLE_STANDALONE_EDIT_MODE,
} from '../mapboxEditableStandaloneObject';
import { CLEAR_MAP_CONTEXT_MENU } from '../mapPanels';
import { notificationActions } from '../../global/notifications/notifications.actions';

export const replaceDrawDataForStandaloneObject = (
  data,
  lineLength,
  distanceOfFirstPoint,
  distanceOfLastPoint,
  place,
  scheme
) => {
  return (dispatch, getState) => {
    const isSetRedrawLine = () => getState().mapboxEditableStandaloneObject.drawData.features;
    dispatch({
      type: REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE,
      payload: {
        data,
        lineLength,
        distanceOfFirstPoint,
        distanceOfLastPoint,
        place,
        scheme,
      },
    });
    if (isSetRedrawLine().length) {
      dispatch({
        type: notificationActions.CLEAR_NOTIFICATION_DATA,
      });
    }
  };
};

export const replaceDrawDataCoordinatesForStandaloneObject = (data, lineLength) => {
  return (dispatch, getState) => {
    const isSetRedrawLine = () => getState().mapboxEditableStandaloneObject.drawData.features;
    dispatch({
      type: REPLACE_COORDINATES_DATA_IN_STANDALONE_EDIT_MODE,
      payload: {
        data,
        lineLength,
      },
    });

    if (isSetRedrawLine().length) {
      dispatch({
        type: notificationActions.CLEAR_NOTIFICATION_DATA,
      });
    }
  };
};

export const setEditingCardValue = (editableObjectInstance) => ({
  type: SET_EDITING_CARD_VALUE,
  payload: { editableObjectInstance },
});

export const toggleStandaloneEditMode = () => ({
  type: TOGGLE_STANDALONE_EDIT_MODE,
});

export const clearStandaloneEditModeObject = () => (dispatch) =>
  dispatch({
    type: CLEAR_STANDALONE_EDIT_MODE_OBJECT,
  });

export const clearNotificationData = () => (dispatch) =>
  dispatch({
    type: notificationActions.CLEAR_NOTIFICATION_DATA,
  });

export const clearMapContextMenu = () => (dispatch, getState) => {
  const { mapPanels } = getState();
  !!mapPanels?.mapContextMenu &&
    dispatch({
      type: CLEAR_MAP_CONTEXT_MENU,
    });
};
