import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RoadsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class RoadMarkingPolygon extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_marking_polygon',
      filterLabel: 'Разметка (полигон)',
      selectedObjectName: MapObjects.roadMarkingPolygon,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsRoadMarkingPolygonsList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsRoadMarkingPolygonsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsRoadMarkingPolygonsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadMarkingPolygons);
  }
}
