import React from 'react';
import Slider from 'UI/common/components/Slider/Slider';
import { Checkbox } from 'UI/common/components/Checkbox/Checkbox';
import { theme } from 'styles/themes/rootTheme';
import { Styled } from '../../LayerSettings.styled';
import _ from 'underscore';
import { Button, Dropdown } from 'antd';
import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import ColorPickerContainer from './RoadColorPicker/ColorPickerContainer';
import { Layers } from 'types/enums/map/layers/Layers';
import {
  IsettingObj,
  LayerChangeControlsFieldProps,
  LayerSettingsItemFactoryProps,
} from './model/LayerChangeControlsField.model';

const { SettingsControlsItem } = Styled;

/**
 * Используется в: настройки слоя
 * Тут описаны компоненты, которые будут рендерится в меню "Настройки слоя". Они принимают параметры от классовых компонентов из
 * src/registrators/map/layers/description/settings/index.ts и позволяют менять их, задавая настройки слоя
 * @constructor
 */

const PickersFromMenu = ({ layerName }: { layerName: Layers }) => {
  return (
    <Dropdown overlay={<ColorPickerContainer layerName={layerName} />} trigger={['click']}>
      <Button className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <SettingOutlined />
        Выбор цвета типа дорог
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

const LayerSettingsItemFactory = ({ settingObj, layerName, changeSetting }: LayerSettingsItemFactoryProps) => {
  const { type, keyForDeck, currentValue, label } = settingObj;
  const onAfterChange = (val: any) => changeSetting(layerName, keyForDeck, val);
  const result = () => {
    switch (type) {
      case Number:
        const { minimum, maximum, step } = settingObj;
        return (
          <SettingsControlsItem key={_.uniqueId()}>
            <Slider
              key={keyForDeck}
              min={minimum}
              max={maximum}
              step={step}
              defaultValue={+currentValue}
              onAfterChange={onAfterChange}
              width={'100%'}
              label={label}
              showInfoPanel={true}
            />
          </SettingsControlsItem>
        );
      case Boolean:
        return (
          <SettingsControlsItem key={_.uniqueId()}>
            <Checkbox
              checkboxColor={theme.colors.primary}
              fontColor={theme.colors.paragraph}
              checked={!!currentValue}
              onChange={() => changeSetting(layerName, keyForDeck, !currentValue)}
            >
              {label}
            </Checkbox>
          </SettingsControlsItem>
        );
      case 'color':
        return (
          <SettingsControlsItem key={_.uniqueId()}>
            <PickersFromMenu layerName={layerName} />
          </SettingsControlsItem>
        );
      default:
        return null;
    }
  };
  return result();
};

const LayerChangeControlsField = ({ selectedLayer, changeLayerSetting }: LayerChangeControlsFieldProps) => {
  const settings = selectedLayer?.customSettings.map((settingObj: IsettingObj) => {
    return LayerSettingsItemFactory({ settingObj, layerName: selectedLayer?.name, changeSetting: changeLayerSetting });
  });
  return <>{settings}</>;
};

export default LayerChangeControlsField;
