import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadConstructionsService } from '../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class SpeedBump extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'speed_bumps',
      filterLabel: 'Лежачий полицейский',
      selectedObjectName: MapObjects.speedBumps,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsSpeedBumpsList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsSpeedBumpsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsSpeedBumpsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.speedBumps);
  }
}
