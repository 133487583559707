import React from 'react';
import Tooltip from '../../../../../UI/common/components/Tooltip/Tooltip';
import TooltipHeader from '../../../../../UI/common/components/Tooltip/components/Header/TooltipHeader';

const MapContextMenu = ({ mapContextMenuProperties, groupsRenderProp, closeMapContext, title = null }) => {
  const displayedTitle =
    mapContextMenuProperties?.selected?.name ||
    mapContextMenuProperties.clickedObjectName?.settingsEntity?.filterLabel ||
    mapContextMenuProperties.clickedObjectName ||
    title;
  return (
    <>
      {groupsRenderProp && (
        <Tooltip
          positionStyleOfTooltip={mapContextMenuProperties}
          tailSide={'left-center'}
          onCloseCallback={closeMapContext}
        >
          <TooltipHeader>{displayedTitle}</TooltipHeader>
          {groupsRenderProp}
        </Tooltip>
      )}
    </>
  );
};

export default MapContextMenu;
