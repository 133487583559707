import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import ProjectCompositeLayer from '../../../../../../layers/CompositeLayers/ekb/ProjectCompositeLayer';

export class ProjectLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: ProjectCompositeLayer,
      id: 'ProjectLayerSchema',
    });
  }
}
