import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { SpecialPermitsService } from '../../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class GeneralDimensionsHeightWidthOversizes extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'general_dimensions_height_width_oversizes',
      filterLabel: 'Предопределённые превышения по ширине по маршруту',
      selectedObjectName: MapObjects.generalDimensionsHeightWidthOversizes,
    });
  }

  async uploadData() {
    return await SpecialPermitsService.specialPermitsIndividualStatementsGeneralDimensionsOversizesList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async readRecord(id) {
    return await SpecialPermitsService.specialPermitsIndividualStatementsGeneralDimensionsOversizesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.permitsIndividualStatementDimensionsOversizes);
  }
}
