import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Modal } from 'antd';
import { History } from 'history';

import { useTheme } from 'styled-components';
import { ApplicationTheme } from 'styles/themes/model/ApplicationTheme.model';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { ApplicationsLabels } from 'types/enums/applications/ApplicationsLabels';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import CreateButton from 'UI/custom/actionsButtons/common/CreateButton';
import { RefreshButton } from 'UI/custom/actionsButtons/common/RefreshButton';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { IndividualOrdersListDirectory } from '.';
import {
  IApplicationsContext,
  IBreadcrumbsLinks,
  CargoTransportParams,
} from '../ApplicationsForm/interfacesApplications/interfacesApplications';
import { emptyArr, iconSize, initialModel } from '../ApplicationsForm/interfacesApplications/templates';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';
import { CompendiumWithButton } from '../ApplicationsForm/CompendiumContainer/CompendiumWithButton';
import { ApplicationsContext } from '../ApplicationsRouter';
import { DownloadIcon } from 'UI/custom/icons/actionIcons/DownloadIcon';
import { downloadFile } from '../ApplicationsForm/methods/downloadFile';
import { AnyObject } from 'types/enums/general/general.model';

const { confirm } = Modal;

const DeleteStatementObject = (
  directory: string,
  refreshData: () => void,
  dispatch: (param: any) => void,
  idStatement?: number
) => {
  const performDeletion = () => {
    const newDirectory = directory === ApplicationsDirectory.main ? '' : `${directory}/`;
    let url = `/${ApplicationsDirectory.applications}/${newDirectory}`;
    dispatch(deleteObject({ url, id: idStatement }));
    refreshData();
  };
  confirm({
    title: 'Вы действительно желаете удалить объект?',
    icon: <QuestionCircleOutlined />,
    onOk() {
      performDeletion();
    },
  });
};

const { getFromURL, useDispatch } = IndividualOrdersListDirectory;

/**
 * Компонент просмотра списка ДУТССД
 * @returns {JSX.Element}
 * @constructor
 */

export const IndividualOrderList = React.memo(() => {
  const history = useHistory<History>();
  const location = useLocation();
  const theme = useTheme() as ApplicationTheme;
  const dispatch = useDispatch();
  const { params } = useRouteMatch<CargoTransportParams>();
  const [data, setData] = useState<AnyObject[]>(emptyArr);
  const [model, setModel] = useState<ModelResponse>(initialModel);
  const [idStatement, setIdStatement] = useState<number | undefined>();
  const { setMainModel, setPageMenuKey, setApplicationTasks } = useContext<IApplicationsContext>(ApplicationsContext);
  const { directory } = params;

  const refreshData = useCallback(async () => {
    const newDirectory = directory === ApplicationsDirectory.main ? '' : `${directory}/`;
    let urlData = `/${ApplicationsDirectory.applications}/${newDirectory}`;
    let urlModel = urlData;
    const responseData = getFromURL.getAll(urlData);
    const responseModel = getFromURL.getModelFromURL(urlModel);
    const doneResponseData = await responseData;
    const doneResponseModel = await responseModel;
    setData(doneResponseData?.results);
    setModel(doneResponseModel);
    setPageMenuKey(ApplicationsDirectory.main);
  }, [directory, setPageMenuKey]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const moveToPageCreatingNewRequest = useCallback(() => {
    setMainModel(model);
    history.push(`${location.pathname}create/`);
    setApplicationTasks(emptyArr);
  }, [history, location.pathname, model, setApplicationTasks, setMainModel]);

  const clickDownloadFile = useCallback(() => {
    const url = data?.find((file: any) => file.id === idStatement)?.photo;
    if (url) downloadFile(url);
  }, [data, idStatement]);

  const downloadFilesPage = useMemo(() => directory === ApplicationsDirectory.photos, [directory]);

  const buttons = useMemo(
    () => (
      <>
        {!downloadFilesPage && <CreateButton moveToPageCreating={moveToPageCreatingNewRequest} tooltip={'Добавить'} />}
        <RefreshButton refreshData={refreshData} tooltip={'Обновить страницу'} />
        {downloadFilesPage && (
          <Button
            rounded
            hasShadow={true}
            type={ButtonType.danger}
            icon={<DownloadIcon />}
            iconColor={theme.colors.primary}
            iconSize={iconSize}
            onClick={clickDownloadFile}
            tooltip={'Скачать файл'}
            disabled={!idStatement}
          />
        )}
        <Button
          rounded
          hasShadow={true}
          type={ButtonType.dangerDelete}
          p={'0.7rem'}
          icon={<DeleteIcon />}
          iconSize={iconSize}
          tooltip={'Удалить объект'}
          disabled={!idStatement}
          onClick={() => DeleteStatementObject(directory, refreshData, dispatch, idStatement)}
        />
      </>
    ),
    [
      clickDownloadFile,
      directory,
      dispatch,
      downloadFilesPage,
      idStatement,
      moveToPageCreatingNewRequest,
      refreshData,
      theme.colors.primary,
    ]
  );

  const label = useMemo(() => ApplicationsLabels[directory], [directory]);

  const breadcrumbsLinks: IBreadcrumbsLinks[] = useMemo(
    () => [
      {
        url: `/app/${ApplicationsDirectory.applications}/`,
        label: ApplicationsLabels.applications,
      },
      {
        url: '',
        label,
      },
    ],
    [label]
  );

  return (
    <>
      <CompendiumWithButton
        data={data}
        model={model}
        breadcrumbsLinks={breadcrumbsLinks}
        showQuickFilter={false}
        agGridThemeName={'ag-theme-custom-base'}
        namesCollectionForFiltering={[]}
        buttons={buttons}
        isHeader={true}
        selectIdAGGrid={setIdStatement}
        refreshData={refreshData}
      />
    </>
  );
});
