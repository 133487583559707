import React from 'react';
import Icon from '@ant-design/icons';

const PathwaySVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7667 8.33329H15.8333V6.83329H12.8167L11.15 4.05829C10.9 3.64163 10.45 3.35829 9.93333 3.35829C9.78333 3.35829 9.65 3.38329 9.51667 3.42496L5 4.83329V9.16663H6.5V6.10829L8.25833 5.55829L5 18.3333H6.5L8.89167 11.575L10.8333 14.1666V18.3333H12.3333V12.9916L10.2583 9.20829L10.8667 6.81663L11.7667 8.33329ZM11.6667 3.16663C12.5 3.16663 13.1667 2.49996 13.1667 1.66663C13.1667 0.833293 12.5 0.166626 11.6667 0.166626C10.8333 0.166626 10.1667 0.833293 10.1667 1.66663C10.1667 2.49996 10.8333 3.16663 11.6667 3.16663Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const Pathway = (props) => <Icon component={PathwaySVG} {...props} />;
