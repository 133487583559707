import React from 'react';
import Mapbox from './Mapbox';
import { useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';

/**
 * Контейнерная компонента для Mapbox
 * @see Mapbox
 */
export const MapboxContainer = () => {
  const standaloneEditModeEnabled: boolean = useSelector(
    (state: StateModel) => state.mapboxEditableStandaloneObject?.enabled
  );

  return <Mapbox standaloneEditModeEnabled={standaloneEditModeEnabled} />;
};
