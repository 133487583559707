import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { KnsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Lines_tram extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'lines_tram',
      filterLabel: 'Линии травмаев',
    });
  }

  async uploadData() {
    return await KnsService.knsLinesTramList();
  }

  async deleteRecord(id) {
    return await KnsService.knsLinesTramDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsLinesTramRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.knsLinesTram);
  }
}
