import styled from 'styled-components/macro';

const StyledWrapper = styled.div`
  margin: 0 20px;
  width: calc(100% / 12 * 4 - 40px);
  min-width: 250px;
  justify-content: flex-start;
  position: relative;
  & .ant-form-item {
    width: 100%;
  }
  display: ${(props) => (props.hidden ? 'hidden' : 'flex')};
  & .ant-form-item {
    width: 100%;
  }
  & .ant-input,
  &.ant-input-number {
    border-radius: 8px;
    span {
      top: -15px;
    }
  }
  span.class_errors {
    color: ${(props) => props.theme.colors.danger} !important;
  }

  div.class_errors {
    .ant-select-selection,
    .ant-input,
    .ant-input-number {
      border-radius: 8px;
      border: 2px solid ${(props) => props.theme.colors.danger} !important;
    }
  }
`;

export const Styled = { StyledWrapper };
