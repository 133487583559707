import styled from 'styled-components/macro';

export const SelectSkeleton = styled.div`
  width: 100%;
  height: 56px;
  border: 2px solid transparent;
  background: linear-gradient(${(props) => props.theme.gradient.defaultLighter});

  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius} !important;
  background-size: 400% 100%;

  -webkit-animation: skeleton-loading 3s linear infinite;
  animation: skeleton-loading 3s linear infinite;

  @-webkit-keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;
