import { useMemo } from 'react';
import { useCallback, useRef } from 'react';
import { AnyObject } from 'types/enums/general/general.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { HeaderContainer } from '../SemifinishedObjectForm/Components/HeaderContainer';
import { Styled } from '../SemifinishedObjectForm/SemifinishedObjectForm.styled';
import { InlineDrawerContainer } from './Components/InlineDrawerContainer';
import { TaskDataObjects } from './Components/TaskDataObjects';
import { TaskObjectProps } from './TaskObject.model';

const { MainContainer } = Styled;

export const TaskObject = ({ field, descriptions, setCustomFieldsData, data, setData, extraData }: TaskObjectProps) => {
  const taskName = data.name;
  const taskObjectModel: ModelResponse = useMemo(
    () => ({
      description: '',
      name: '',
      scheme: descriptions,
    }),
    [descriptions]
  );

  const formRef = useRef<HTMLFormElement>(null);

  const initSubmitForm = useCallback(() => {
    formRef.current?.submit();
  }, []);

  const handleSubmitForm = useCallback(
    (submitData: AnyObject) => {
      setData((prevData) => {
        if (!Array.isArray(prevData)) {
          return { ...prevData, task_objects: [...prevData.task_objects, submitData] };
        } else return prevData;
      });
      if (setCustomFieldsData)
        setCustomFieldsData((prevValues: AnyObject) => ({
          ...prevValues,
          task_objects: [...data.task_objects, submitData],
        }));
    },
    [data, setCustomFieldsData, setData]
  );

  return (
    <MainContainer>
      <HeaderContainer applicationName={taskName} />
      <InlineDrawerContainer
        initSubmitForm={initSubmitForm}
        handleSubmitForm={handleSubmitForm}
        model={taskObjectModel}
        ref={formRef}
      />
      <TaskDataObjects
        taskObjectList={data.task_objects}
        setCustomFieldsData={setCustomFieldsData}
        setData={setData}
        model={taskObjectModel}
        extraData={extraData}
      />
    </MainContainer>
  );
};
