import React from 'react';
import LayersMenuHeader from './LayersMenuHeader';
import { connect } from 'react-redux';
import { toggleMainPanelCollapse } from '../../../../../../../../../../store/reducers/map/actions/mapPanelsActions';

const LayersMenuHeaderContainer = (props) => {
  return <LayersMenuHeader {...props} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMainPanelCollapse: () => dispatch(toggleMainPanelCollapse()),
  };
};

export default connect(null, mapDispatchToProps)(LayersMenuHeaderContainer);
