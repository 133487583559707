import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateModel } from '../../../../../store/reducers';
import { isEmpty } from 'lodash';
import { registeredReportsUrls } from '../../../../../registrators/reportsRegistrator/reportsRegistrator';
import ReportsCards from './ReportsFolder/ReportsCardsFolder/ReportsCards';

const ReportsRouter = () => {
  const userDirectoryRights = useSelector((state: StateModel) => state.auth.permissions.reports);
  const filteredByPermissionReports = registeredReportsUrls.filter((report) => {
    return userDirectoryRights.includes(report.permissionToShow);
  });

  const reportRoutes = useMemo(() => {
    return filteredByPermissionReports.map((report) => (
      <Route exact path={`/app/reports/${report.url}`} component={report.component} key={report.url} />
    ));
  }, [filteredByPermissionReports]);
  const isHasNoReportsRight = isEmpty(userDirectoryRights);

  return (
    <Switch>
      {!isHasNoReportsRight && <Route exact path={'/app/reports/'} component={ReportsCards} />}
      {reportRoutes}
    </Switch>
  );
};

export default ReportsRouter;
