import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
/*import { RoadsConstructionService } from '../../../../../../generated/api/api';*/
import { getFromURL } from '../../../../../../api/getFromURL';

export class RestAreas extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'rest_areas',
      filterLabel: 'Зоны отдыха',
      selectedObjectName: MapObjects.restAreas,
    });
  }
  /*
  async uploadData() {
    return await RoadsConstructionService.roadsConstructionRestAreasList();
  }

  async deleteRecord(id) {
    return await RoadsConstructionService.roadsConstructionRestAreasDelete({ id });
  }

  async readRecord(id) {
    return await RoadsConstructionService.roadsConstructionRestAreasRead({ id });
  }*/

  async uploadModel() {
    return await getFromURL.getModelFromURL('/roads_construction/rest_areas/');
  }
}
