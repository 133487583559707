import React from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const BridgesItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'bridges');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roads_construction_bridges}/${id}/`, '_blank');
    win.focus();
  };

  //Словарь для типа направления светофора
  const BOTH = 'BOTH',
    DIRECT = 'DIRECT',
    REVERSE = 'REVERSE',
    DIRECT_STROKE = 'DIRECT_STROKE',
    REVERSE_STROKE = 'REVERSE_STROKE';
  const typeMap = new Map([
    [BOTH, 'Оба'],
    [DIRECT, 'Прямо'],
    [REVERSE, 'Обратное'],
    [DIRECT_STROKE, 'Прямой ход (внутреннее кольцо)'],
    [REVERSE_STROKE, 'Обратный ход (внешнее кольцо)'],
  ]);

  let Descriptions = [];

  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = data;

  Descriptions.push({
    key: 'Наименование дороги',
    value: properties.segment_name,
  });

  Descriptions.push({
    key: 'Расположение',
    value: typeMap.get(properties.direction_of_movement) ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о мосте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

BridgesItemSelection.propTypes = {};

export default BridgesItemSelection;
