import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { AnyObject } from 'types/enums/general/general.model';
import { Model, ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { watchFieldsModel } from './util/watchFieldsModel';
import { appActions } from 'store/reducers/global/app/app.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { initialPrepareFields } from './util/initialPrepareFields';
import { WatcherI } from 'hooks/form/usePrepareFieldsHook/model/watchFieldsModel.model';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';

export default function usePrepareFieldsHook(model: ModelResponse, data: AnyObject) {
  const dispatch = useDispatch();

  const [prepareData, setPrepareData] = useState<AnyObject | null>(null);
  const [prepareModel, setPrepareModel] = useState<ModelResponse | null>(null);
  // Вотчеры для полей формы
  const [watchFields, setWatchFields] = useState<WatcherI>({});

  // Фильтрация полей из watcher, удаляются read_only=true
  const filterFields = useCallback((model: ModelResponse) => {
    const prepareModel = _.cloneDeep(model);
    const scheme = prepareModel.scheme;
    const newScheme: Model = {};

    for (const field in scheme) {
      if (scheme.hasOwnProperty(field)) {
        const description = scheme[field];

        if (description.type === SchemaFieldType.field) {
          const children = description?.child?.children;

          if (children) {
            const newScheme: Model = {};

            for (const field in children) {
              if (children.hasOwnProperty(field)) {
                const description = children[field];

                if (!description.read_only) {
                  newScheme[field] = description;
                }
              }
            }

            // @ts-ignore
            description.child.children = newScheme;
          }
        }

        if (!description.read_only) {
          newScheme[field] = description;
        }
      }
    }

    prepareModel.scheme = newScheme;
    setPrepareModel(prepareModel);
  }, []);

  // Первоначальное форматирование данных схемы
  useEffect(() => {
    (async () => {
      dispatch(appActions.startAppFetching());
      try {
        const { prepareModel, prepareData } = await initialPrepareFields({ model, data });

        const watchFields = watchFieldsModel(prepareModel, prepareData, filterFields);

        setPrepareData(prepareData);
        setPrepareModel(prepareModel);
        setWatchFields(watchFields);
      } catch (e) {
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: createAlertMessage(e as AnyObject),
          })
        );
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
      }
      dispatch(appActions.endAppFetching());
    })();
  }, [data, dispatch, filterFields, model]);

  return {
    watchFields,
    prepareData,
    prepareModel,
  };
}
