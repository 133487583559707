import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadConstructionsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Lighting extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'lighting',
      filterLabel: 'Освещение',
      selectedObjectName: MapObjects.lighting,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsLightingList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsLightingDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsLightingRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadsConstructionLighting);
  }
}
