import React from 'react';
import { Button, Flex, Typography } from '@frontend-packages/its-ui-kit';
import { SButtonBlockContainer } from './styles/ModeratedImagePanelFooter.styles';
import { useModeratorContext } from '../../context/ModeratorContext';
import { isUndefined } from 'lodash';

interface ModeratedImagePanelFooterProps {
  onApproveDetection: () => void;
  onDeclineDetection: () => void;
}

export const ModeratedImagePanelFooter = ({
  onApproveDetection,
  onDeclineDetection,
}: ModeratedImagePanelFooterProps) => {
  const { moderation } = useModeratorContext();
  const totalImages = moderation.detectionList.length;
  const currentImageIndex = moderation.detectionList.findIndex(
    (detections) => detections.external_id === moderation.activeModeration?.external_id
  );

  const isNotEmptyIndex = !!totalImages && !isUndefined(currentImageIndex);

  return (
    <SButtonBlockContainer>
      <div>
        {isNotEmptyIndex && (
          <Typography type="type1">
            {currentImageIndex + 1} из {totalImages}
          </Typography>
        )}
      </div>
      <Flex gapMultiplier={1}>
        <Button onClick={onApproveDetection} width="200px">
          Принять
        </Button>
        <Button onClick={onDeclineDetection} type="danger" width="200px" buttonStyle="translucent">
          Отклонить
        </Button>
      </Flex>
    </SButtonBlockContainer>
  );
};
