import React from 'react';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { ContoursLawnItemSelectionProps } from './RoadSchemeItemSelection.model';

const ContoursLawnItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: ContoursLawnItemSelectionProps) => {
  const handleOpenObjectInNewTab = () => {
    const { id } = data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.contoursLawn}/${id}/`, '_blank');
    win?.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Наименование',
    value: data?.name ?? 'Не указан',
  });

  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о контурах газона'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

ContoursLawnItemSelection.propTypes = {};

export default ContoursLawnItemSelection;
