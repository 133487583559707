import React from 'react';
import styled from 'styled-components/macro';
import DirectoryCards from './DirectoryCardsFolder/DirectoryCards';

const StyledDirectoryFolder = styled.div``;

const DirectoryFolder = (props) => {
  return (
    <StyledDirectoryFolder>
      <DirectoryCards />
    </StyledDirectoryFolder>
  );
};

export default DirectoryFolder;
