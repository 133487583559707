import styled from 'styled-components';
import { DefaultStyleMixins } from '@frontend-packages/its-ui-kit';
const { getSpacing } = DefaultStyleMixins.offset;

export const SToolsContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(1)};
`;
