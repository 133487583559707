import styled from 'styled-components/macro';
import { PieTooltipWrapProps } from '../model/PieTooltip.styled';

const TooltipWrap = styled.div<PieTooltipWrapProps>`
  padding: 12px 16px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(107, 138, 228, 0.12);
  border-radius: 8px;
  color: ${(props) => (props.color ? props.color : '#6A7B9B')};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
`;

const TooltipName = styled.span``;

const TooltipValue = styled.span``;

export const Styled = {
  TooltipWrap,
  TooltipName,
  TooltipValue,
};
