export const trafficLightType1 = {
  P1: { x: 0, y: 0, width: 248, height: 248 },
  'P1-active': { x: 0, y: 249, width: 248, height: 248 },
  'P1-toremove': { x: 249, y: 0, width: 248, height: 248 },
  'P1-toremove-active': { x: 249, y: 249, width: 248, height: 248 },
  P2: { x: 0, y: 0, width: 248, height: 248 },
  'P2-active': { x: 0, y: 249, width: 248, height: 248 },
  'P2-toremove': { x: 249, y: 0, width: 248, height: 248 },
  'P2-toremove-active': { x: 249, y: 249, width: 248, height: 248 },
  T3L: { x: 498, y: 0, width: 248, height: 248 },
  'T3L-active': { x: 498, y: 249, width: 248, height: 248 },
  'T3L-toremove': { x: 498, y: 498, width: 248, height: 248 },
  'T3L-toremove-active': { x: 0, y: 747, width: 248, height: 248 },
  T3P: { x: 498, y: 996, width: 248, height: 248 },
  'T3P-active': { x: 747, y: 996, width: 248, height: 248 },
  'T3P-toremove': { x: 996, y: 0, width: 248, height: 248 },
  'T3P-toremove-active': { x: 996, y: 249, width: 248, height: 248 },
  T1L: { x: 747, y: 0, width: 248, height: 248 },
  'T1L-active': { x: 747, y: 249, width: 248, height: 248 },
  'T1L-toremove': { x: 747, y: 498, width: 248, height: 248 },
  'T1L-toremove-active': { x: 747, y: 747, width: 248, height: 248 },
  T1PL: { x: 996, y: 996, width: 248, height: 248 },
  'T1PL-active': { x: 0, y: 1245, width: 248, height: 248 },
  'T1PL-toremove': { x: 249, y: 1245, width: 248, height: 248 },
  'T1PL-toremove-active': { x: 498, y: 1245, width: 248, height: 248 },
  T1P: { x: 1245, y: 0, width: 248, height: 248 },
  'T1P-active': { x: 1245, y: 249, width: 248, height: 248 },
  'T1P-toremove': { x: 1245, y: 498, width: 248, height: 248 },
  'T1P-toremove-active': { x: 1245, y: 747, width: 248, height: 248 },
  T1: { x: 0, y: 1494, width: 248, height: 248 },
  'T1-active': { x: 249, y: 1494, width: 248, height: 248 },
  'T1-toremove': { x: 498, y: 1494, width: 248, height: 248 },
  'T1-toremove-active': { x: 747, y: 1494, width: 248, height: 248 },
  T8: { x: 0, y: 1494, width: 248, height: 248 },
  'T8-active': { x: 249, y: 1494, width: 248, height: 248 },
  'T8-toremove': { x: 498, y: 1494, width: 248, height: 248 },
  'T8-toremove-active': { x: 747, y: 1494, width: 248, height: 248 },
  T3: { x: 0, y: 1494, width: 248, height: 248 },
  'T3-active': { x: 249, y: 1494, width: 248, height: 248 },
  'T3-toremove': { x: 498, y: 1494, width: 248, height: 248 },
  'T3-toremove-active': { x: 747, y: 1494, width: 248, height: 248 },
  T2L: { x: 1494, y: 0, width: 248, height: 248 },
  'T2L-active': { x: 1494, y: 249, width: 248, height: 248 },
  'T2L-toremove': { x: 1494, y: 996, width: 248, height: 248 },
  'T2L-toremove-active': { x: 1494, y: 1245, width: 248, height: 248 },
  T2R: { x: 1494, y: 498, width: 248, height: 248 },
  'T2R-active': { x: 1494, y: 747, width: 248, height: 248 },
  'T2R-toremove': { x: 1494, y: 1494, width: 248, height: 248 },
  'T2R-toremove-active': { x: 0, y: 1743, width: 248, height: 248 },
  T2S: { x: 1245, y: 1743, width: 248, height: 248 },
  'T2-active': { x: 1494, y: 1743, width: 248, height: 248 },
  'T2-toremove': { x: 1743, y: 498, width: 248, height: 248 },
  'T2-toremove-active': { x: 1743, y: 747, width: 248, height: 248 },
  T2SL: { x: 498, y: 1992, width: 248, height: 248 },
  'T2SL-active': { x: 1245, y: 1992, width: 248, height: 248 },
  'T2SL-toremove': { x: 1494, y: 1992, width: 248, height: 248 },
  'T2SL-toremove-active': { x: 1992, y: 249, width: 248, height: 248 },
  T2SR: { x: 747, y: 1992, width: 248, height: 248 },
  'T2SR-active': { x: 996, y: 1992, width: 248, height: 248 },
  'T2SR-toremove': { x: 1743, y: 1992, width: 248, height: 248 },
  'T2SR-toremove-active': { x: 1992, y: 0, width: 248, height: 248 },
  T5: { x: 1992, y: 1494, width: 248, height: 248 },
  'T5-active': { x: 1992, y: 1743, width: 248, height: 248 },
  'T5-toremove': { x: 1992, y: 1992, width: 248, height: 248 },
  'T5-toremove-active': { x: 0, y: 2241, width: 248, height: 248 },
  T6D: { x: 747, y: 2241, width: 248, height: 248 },
  'T6D-active': { x: 996, y: 2241, width: 248, height: 248 },
  'T6D-toremove': { x: 1245, y: 2241, width: 248, height: 248 },
  'T6D-toremove-active': { x: 1494, y: 2241, width: 248, height: 248 },
  T7: { x: 2241, y: 0, width: 248, height: 248 },
  'T7-active': { x: 2241, y: 249, width: 248, height: 248 },
  'T7-toremove': { x: 2241, y: 498, width: 248, height: 248 },
  'T7-toremove-active': { x: 2241, y: 747, width: 248, height: 248 },
};
