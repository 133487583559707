import styled from 'styled-components/macro';

const LocalMainWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: ${(props) => (!props.sidebarIsOpened ? '2.6rem' : 0)};
  padding-right: ${(props) => (!props.sidebarIsOpened ? '1.5rem' : 0)};
  padding-top: 1.6rem;
`;

const LocalLogoWrap = styled.div`
  width: ${(props) => (!props.sidebarIsOpened ? 'auto' : '100%')};
  display: flex;
  justify-content: center;
`;

export const Styled = { LocalMainWrapper, LocalLogoWrap };
