import React, { FC } from 'react';
import { Styled } from './style/GlobalLoader.styled';
import { GlobalLoaderProps } from './model/GlobalLoader.model';
import LogoLoaderAnimation from 'UI/custom/icons/preloaders/LogoLoaderAnimation';

const GlobalLoader: FC<GlobalLoaderProps> = ({ show }) => {
  if (show) {
    return (
      <Styled.LocalMainWrapper>
        <Styled.LocalMainInner>
          <LogoLoaderAnimation />
        </Styled.LocalMainInner>
      </Styled.LocalMainWrapper>
    );
  }
  return null;
};

export default GlobalLoader;
