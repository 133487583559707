import React, { PropsWithChildren, useMemo } from 'react';
import { Styled } from '../../SemifinishedObjectForm/SemifinishedObjectForm.styled';
import ApplicationForm from '../../../ApplicationForm/ApplicationForm';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { InlineDrawerContainerProps } from './model/InlineDrawerContainer.model';

const { SaveButtonsContainer } = Styled;

export const InlineDrawerContainer = React.forwardRef(
  ({ initSubmitForm, handleSubmitForm, model }: PropsWithChildren<InlineDrawerContainerProps>, ref: any) => {
    const estimation = useMemo(() => {
      return (
        <>
          <SaveButtonsContainer>
            <Button onClick={initSubmitForm} type={ButtonType.primary} width={'100%'}>
              Сохранить*
            </Button>
          </SaveButtonsContainer>
          <p>*Внимание для полноценного сохранения объектов задачи нажмите кнопку "Сохранить изменения"</p>
        </>
      );
    }, [initSubmitForm]);

    const form = useMemo(
      () => ({
        showTime: true,
        dateTime: {},
        setDateTime: () => {},
      }),
      []
    );

    const InlineDrawer = useMemo(
      () => (
        <ApplicationForm
          nonTitle={true}
          handleSubmitForm={handleSubmitForm}
          model={model}
          ref={ref}
          additionalRenders={estimation}
          form={form}
        />
      ),
      [estimation, form, handleSubmitForm, model, ref]
    );
    return InlineDrawer;
  }
);
