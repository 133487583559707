import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToggleButtonGroup from '../../../../../../../../../../../UI/common/components/ToggleButtonGroup/ToggleButtonGroup';
import { setSelectedLayerSettingsType } from '../../../../../../../../../../../store/reducers/map/actions/mapPanelsActions';
import { PaletteIcon } from '../../../../../../../../../../../UI/custom/icons/actionIcons/PaletteIcon';
import { FilterIcon } from '../../../../../../../../../../../UI/custom/icons/actionIcons/FilterIcon';

const SWITCHER_BUTTONS_TEMPLATE = [
  {
    id: 1,
    icon: <PaletteIcon />,
    name: 'Дизайн',
    switcher: 'changeControls',
  },
  {
    id: 2,
    icon: <FilterIcon />,
    name: 'Фильтры',
    switcher: 'filters',
  },
  /*  {
    id: 3,
    icon: <HistoryWatchIcon />,
    name: 'Шаблоны',
    switcher: 'templates',
    requiredLayerKeyValue: { editable: true },
  },*/
];

const SettingsTypeSwitcherContainer = ({ setPanelContext, layerSettingType }) => {
  const handleChangePanelContext = (value) => {
    setPanelContext(value);
  };

  const buttonClickHandler = (element) => {
    handleChangePanelContext(element.switcher);
  };

  const copyOfTemplate = [...SWITCHER_BUTTONS_TEMPLATE];

  return (
    <ToggleButtonGroup
      handleChangePanelContext={handleChangePanelContext}
      buttonClickHandler={buttonClickHandler}
      activePanel={layerSettingType}
      displayedButtons={copyOfTemplate}
    />
  );
};

SettingsTypeSwitcherContainer.propTypes = {
  layerSettingType: PropTypes.string,
  setPanelContext: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPanelContext: (value) => dispatch(setSelectedLayerSettingsType(value)),
  };
};

const mapStateToProps = (state) => {
  return {
    layerSettingType: state.mapPanels.layerSettingType,
    includedLayers: state.mapboxLayers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTypeSwitcherContainer);
