export const roadObjectsMapping = {
  closed_road: {
    x: 0,
    y: 193,
    width: 192,
    height: 192,
  },
  closed_road_masked: {
    x: 579,
    y: 193,
    width: 192,
    height: 192,
    masked: true,
  },
  work_on_road: {
    x: 0,
    y: 579,
    width: 192,
    height: 192,
  },
  work_on_road_masked: {
    x: 195,
    y: 1,
    width: 192,
    height: 192,
    masked: true,
  },
  security_checkpoint: {
    x: 0,
    y: 386,
    width: 192,
    height: 192,
  },
  security_checkpoint_masked: {
    x: 193,
    y: 193,
    width: 192,
    height: 192,
    masked: true,
  },
  cluster: {
    x: 386,
    y: 193,
    width: 192,
    height: 192,
  },
  cluster_masked: {
    x: 193,
    y: 0,
    width: 192,
    height: 192,
    masked: true,
  },
  dock_segment: {
    x: 386,
    y: 0,
    width: 192,
    height: 192,
  },
  road_overpass: {
    x: 0,
    y: 0,
    width: 192,
    height: 192,
  },
  control_room: {
    x: 193,
    y: 579,
    width: 192,
    height: 192,
  },
};
