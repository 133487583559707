import React from 'react';
import { useMemo } from 'react';
import { RgbaCellRendererProps } from './model/RgbaCellRenderer.model';
import { ContainerRgbaIcon } from './style/ContainerRgbaIcon.styles';

const RgbaCellRenderer = (props: RgbaCellRendererProps) => {
  const drawElement = useMemo(() => {
    const backgroundColor = { backgroundColor: props.value };
    return <ContainerRgbaIcon style={backgroundColor} />;
  }, [props.value]);
  return drawElement;
};

export default RgbaCellRenderer;
