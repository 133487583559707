import { IconLayer, PathLayer, TextLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../../types/enums/map/MapObjects.model';
import { getCoordsFromParsedGeometry } from '../../../../services/map/getCoordsFromParsedGeometry';
import { roadAccidentsIconMapping } from '../../../iconMappers/roadAccidentsIconMapping';
import MapEventObserver from '../../../../store/rakes/MapEventObserver';
import ITSLayer from '../../../../types/classes/ITSLayer';
import { AnyObject } from '../../../../types/enums/general/general.model';
import { IClusters, IClusterType, IItemLeaves, IItemType } from './RoadAccident.model';
import { RoadAccident } from '../../../../generated/api/api';

function getIconSize(size: number) {
  return Math.min(100, size / 10) / 100 + 1;
}

function prepareClusterData(state: any, zoom: number) {
  const allWorldBbox = [-180, -85, 180, 85];
  const allData = state.index.getClusters(allWorldBbox, zoom);
  const clustersData = allData
    .map((cluster: IClusters) => {
      if (cluster?.id && cluster?.type) {
        return {
          cluster,
          leaves: state.index.getLeaves(cluster.id, 0, 0),
        };
      }
      return null;
    })
    .filter((item: IItemType) => {
      return item !== null;
    });
  return { allData, clustersData };
}

class RoadAccidentsClustersPointsCompositeLayer extends ITSLayer<RoadAccident, any> {
  shouldUpdateState({ changeFlags }: AnyObject) {
    return changeFlags.propsChanged || changeFlags.viewportChanged;
  }

  updateState(props: any) {
    console.log('props', props);
    if (props.props.relatedData.road_accidents && props.props.relatedData.road_accidents.length) {
      const { selectedObject } = props;
      const rebuildIndex = props.changeFlags;
      if (rebuildIndex) {
        const index = new Supercluster({ radius: 30, maxZoom: 14 });
        index.load(
          props.props.relatedData.road_accidents.map((d: any) => {
            const selected = selectedObject?.data?.properties?.road_accidents === d.road_accidents;
            const coordinates = d.point ? getCoordsFromParsedGeometry(d.point) : [0, 0];
            const { ...rest } = d;
            return {
              geometry: { coordinates },
              properties: { ...rest, selected },
            };
          })
        );
        this.setState({ index });
      }
      const z: number = Math.floor(props.context.viewport.zoom);
      if (rebuildIndex) {
        const { allData, clustersData } = prepareClusterData(this.state, z);
        this.setState({
          data: allData,
          clustersData,
          z,
        });
      }
    }
  }

  getPickingInfo(event: any) {
    let selectedObjectType = null;
    const selectedObjectLayerName = Layers.roadAccidents;
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (event.sourceLayer.id.includes('cluster') && this.props.onClickHandler) {
        // Это не мусор, это мне понадобиться немного позже, чтобы прикрутить карточки данных к кластерам
        // const clusterId = event.info.object.id;
        // const vehiclesInCluster = this.state.index
        //   .getLeaves(clusterId, 0, 0)
        //   .map((item) => deconstructGeoJsonItem(item));
        // this.props.onClickHandler(
        //   vehiclesInCluster, MapObjectsEnum.roadAccidentsCluster, Layers.roadAccidents,
        // );
      } else if (event.sourceLayer.id.includes('road-accidents')) {
        selectedObjectType = MapObjects.roadAccidents;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    return [
      // Паслэйер аварийноопасные участки
      new PathLayer({
        data: this.props.relatedData?.road_accidents_sections,
        widthScale: 3,
        getWidth: (d) => (this.props?.selectedObject?.id === d.id ? 20 : 5),
        pickable: true,
        rounded: true,
        updateTriggers: {
          getColor: this.props.selectedObject,
          getWidth: this.props.selectedObject,
        },
        billboard: false,
        autoHighlight: true,
        widthMinPixels: 3,
        opacity: 1,
        // @ts-ignore
        getPath: (d) => (d.line_path ? getCoordsFromParsedGeometry(d.line_path) : [0, 0]),
        getColor: [235, 87, 87],
      }),
      // Иконка кластеров
      new IconLayer(this.props, {
        id: 'icon',
        pickable: true,
        visible: true,
        data: this.state.data,
        iconAtlas: `${process.env.PUBLIC_URL}/img/road_accidents_icon.png`,
        iconMapping: roadAccidentsIconMapping,
        // eslint-disable-next-line no-unsafe-optional-chaining
        sizeScale: 50,
        getPosition: (d: IItemLeaves) => {
          return d?.geometry?.coordinates;
        },
        getIcon: (d: IClusterType) => (d?.properties?.cluster ? 'cluster_red_white' : ''),
        getSize: (d: IClusterType) => getIconSize(d?.properties?.cluster ? d?.properties?.point_count : 1),
        getPolygonOffset: ({ layerIndex }: AnyObject) => [0, -layerIndex * 10000],
      }),
      // Текст внутри иконок кластеров
      new TextLayer(this.props, this.getSubLayerProps({ id: 'text-for-cluster-road-accidents' }), {
        pickable: true,
        data: this.state.data,
        getPosition: (d: IItemLeaves) => d.geometry.coordinates,
        getText: (d: IClusterType) => (d.properties.cluster ? `${d.properties.point_count}` : ' '),
        billboard: true,
        fontWeight: 'bold',
        getColor: [235, 87, 87],
        getOpacity: 1,
        // eslint-disable-next-line no-unsafe-optional-chaining
        sizeScale: this?.props?.sizeScale,
        getSize: 18,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        sizeMinPixels: 18,
        sizeMaxPixels: 18,
        getPolygonOffset: ({ layerIndex }: AnyObject) => [0, -layerIndex * 10000],
      }),
      // Отдельные иконки - вне кластеров
      new IconLayer(this.props, this.getSubLayerProps({ id: 'road-accidents-icon' }), {
        data: this.state.data,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_accidents_icon.png`,
        iconMapping: roadAccidentsIconMapping,
        updateTriggers: {
          getSize: this.props?.selectedObject,
        },
        // eslint-disable-next-line no-unsafe-optional-chaining
        getSize: () => (37 * this?.props?.sizeScale.currentValue) / 15 ?? 37,
        opacity: this?.props?.opacity ?? 0.1,
        autoHighlight: true,
        billboard: true,
        visible: true,
        widthMinPixels: 3,
        getPolygonOffset: ({ layerIndex }: AnyObject) => [0, -layerIndex * 10000],
        getPosition: (d: IClusterType) => d.geometry.coordinates,
        // eslint-disable-next-line no-nested-ternary
        getIcon: (d: IClusters) => {
          return this.props?.id === Layers.roadAccidents &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? `accident_color_${d.properties.road_accident_type}`
            : `accident_white_${d.properties.road_accident_type}`;
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
};

RoadAccidentsClustersPointsCompositeLayer.defaultProps = defaultProps;
RoadAccidentsClustersPointsCompositeLayer.layerName = 'RoadAccidents';

export default RoadAccidentsClustersPointsCompositeLayer;
