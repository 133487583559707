import { RelatedDataPrototype } from 'registrators/map/layers/description/relatedData/prototype/RelatedDataPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { ApplicationsService } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { RequestPath } from 'types/enums/routes/request/RequestPath';

export class BrigadeWorkShifts extends RelatedDataPrototype {
  constructor() {
    super({
      name: MapObjects.brigadeWorkShifts,
      filterLabel: 'Смены',
      selectedObjectName: MapObjects.brigadeWorkShifts,
    });
  }

  async uploadData() {
    return await ApplicationsService.applicationsWorkShiftsList({ inProgress: true });
  }

  async deleteRecord(id: number) {
    return await ApplicationsService.applicationsWorkShiftsDestroy({ id });
  }

  async readRecord(id: number) {
    return await ApplicationsService.applicationsWorkShiftsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.brigadeWorkShifts);
  }
}
