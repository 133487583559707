import React, { FC, useMemo } from 'react';
import {
  ChartData,
  ChartType,
  ChartWidgetData,
} from 'components/Application/BaseModules/HomePage/components/WidgetsGrid/components/ChartWidget/model/ChartWidget.model';
import ChartWidget from 'components/Application/BaseModules/HomePage/components/WidgetsGrid/components/ChartWidget/ChartWidget';
import { CameraWidget, CameraWidgetDataResults } from 'generated/api/api';

interface CameraChartWidgetProps {
  widgetDescription: CameraWidget;
  onChangeWidget: () => void;
  onDeleteWidget: () => void;
  data: Array<CameraWidgetDataResults>;
}

const CameraChartWidget: FC<CameraChartWidgetProps> = ({ widgetDescription, onChangeWidget, onDeleteWidget, data }) => {
  // Настройки графического виджета
  const chartSettings = useMemo(
    () => ({
      showCaptions: widgetDescription.show_captions,
      showGridLines: widgetDescription.show_grid_lines_and_divisions,
    }),
    [widgetDescription.show_captions, widgetDescription.show_grid_lines_and_divisions]
  );

  const formatData: Array<ChartWidgetData> = useMemo(() => {
    return widgetDescription.widget_data.map((el) => {
      //@ts-ignore
      const currentData: Array<ChartData> = data.find((dataEl) => el.id === dataEl.widget_data_id)?.data ?? [];

      return {
        id: el.id,
        name: el.data_name,
        color: el.data_color,
        data: currentData,
      };
    });
  }, [data, widgetDescription.widget_data]);

  return (
    <ChartWidget
      onChangeWidget={onChangeWidget}
      onDeleteWidget={onDeleteWidget}
      name={widgetDescription.name}
      type={widgetDescription.chart_type as ChartType}
      chartSettings={chartSettings}
      data={formatData}
      xKey={'datetime'}
    />
  );
};

export default CameraChartWidget;
