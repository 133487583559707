import { createInputs } from 'services/application/schemaManipulation/createInputs';
import React from 'react';
import { Styled } from '../AutocompleteForm.styled';
import { preparePanelGroupsProps } from './model/preparePanelGroups.model';

const { LocalPanelColumn, ExtendedAntdCollapseItem, ExtendedAntdCollapse } = Styled;

export function preparePanelGroups({ form, groups, model, data }: preparePanelGroupsProps) {
  const panelsResult = [];
  let missingGroups = Object.keys(model.scheme);
  for (let group of groups) {
    const inputGroups = createInputs(model, group.fields, form, data);
    panelsResult.push(
      <LocalPanelColumn key={`uniq_${group.label}`}>
        <ExtendedAntdCollapse bordered={false} expandIconPosition={'right'} defaultActiveKey={['1']}>
          <ExtendedAntdCollapseItem header={<span>{group.label}</span>} key={'1'}>
            {inputGroups}
          </ExtendedAntdCollapseItem>
        </ExtendedAntdCollapse>
      </LocalPanelColumn>
    );
    for (let field of group.fields) {
      missingGroups = missingGroups.filter((el) => el !== field);
    }
  }

  const inputGroups = createInputs(model, missingGroups, form, data);

  panelsResult.push(inputGroups);

  return panelsResult;
}
