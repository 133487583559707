import { FlyToInterpolator } from 'react-map-gl';

export const SET_MAPBOX_VIEW = 'SET_MAPBOX_VIEW';
export const RENDER_MAP = 'RENDER_MAP';
export const GOTO_MAP_POINT = 'GOTO_MAP_POINT';
export const SET_MAPBOX_STYLE = 'SET_MAPBOX_STYLE';
export const ZOOM_IN = 'ZOOM_IN';
export const ZOOM_OUT = 'ZOOM_OUT';
export const SET_MAP_TO_NORTH = 'SET_MAP_TO_NORTH';
export const SET_MAP_BEARING = 'SET_MAP_BEARING';
export const TOGGLE_3D = 'TOGGLE_3D';
export const SET_ZOOM_FOR_CURRENT_LOCATION = 'SET_ZOOM_FOR_CURRENT_LOCATION';

const initialState = {
  viewState: {
    // изначальные координаты
    latitude: 56.8519,
    longitude: 60.6122,
    zoom: 11.5,
    bearing: 0,
    pitch: 0,
  },
  style: 'mapbox://styles/vsjaq/ckwy78xku00uw15mtm766p9v6',
};

const mapboxView = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAPBOX_VIEW: {
      const { viewState } = action.payload;
      return {
        ...state,
        viewState: viewState,
      };
    }
    case SET_MAPBOX_STYLE: {
      const { style } = action.payload;
      return {
        ...state,
        style,
      };
    }
    case GOTO_MAP_POINT: {
      const { latitude, longitude } = action.payload;
      return {
        ...state,
        viewState: {
          ...state.viewState,
          latitude,
          longitude,
          transitionDuration: 1000,
          transitionInterpolator: new FlyToInterpolator(),
        },
      };
    }
    case ZOOM_IN: {
      if (state.viewState.zoom < 20) {
        return {
          ...state,
          viewState: {
            ...state.viewState,
            zoom: state.viewState.zoom + 1,
          },
        };
      } else return state;
    }
    case ZOOM_OUT: {
      if (state.viewState.zoom > 1) {
        return {
          ...state,
          viewState: {
            ...state.viewState,
            zoom: state.viewState.zoom - 1,
          },
        };
      } else return state;
    }
    case SET_ZOOM_FOR_CURRENT_LOCATION: {
      const { newZoomValue } = action.payload;
      if (newZoomValue) {
        return {
          ...state,
          viewState: {
            ...state.viewState,
            zoom: newZoomValue,
          },
        };
      }
      return state;
    }
    case SET_MAP_TO_NORTH: {
      return {
        ...state,
        viewState: {
          ...state.viewState,
          bearing: 0,
          transitionDuration: 500,
          transitionInterpolator: new FlyToInterpolator(),
        },
      };
    }
    case SET_MAP_BEARING: {
      return {
        ...state,
        viewState: {
          ...state.viewState,
          bearing: action.payload.value,
        },
      };
    }
    case TOGGLE_3D: {
      if (state.viewState.pitch < state.viewState.maxPitch / 2) {
        return {
          ...state,
          viewState: {
            ...state.viewState,
            pitch: state.viewState.maxPitch,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
          },
        };
      } else {
        return {
          ...state,
          viewState: {
            ...state.viewState,
            pitch: state.viewState.minPitch,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
          },
        };
      }
    }
    default:
      return state;
  }
};

export default mapboxView;
