import { defineDrawKeyOnModel } from './defineDrawMapOnModel';
import * as wkt from 'wkt';
import * as turf from 'turf';

/**
 *  Функция ищет точку центра и границы геометрий исходя из даты и модели
 * @param data
 * @param model
 * @returns {null|{centerLatitude: *, bounds: *, centerLongitude: *}}
 */
export const getWktGeometryBounds = (data, model) => {
  // Ищем какой ключ отвечает за геометрию в дате
  const keyOfGeometry = defineDrawKeyOnModel(model);
  // TODO add cases of object and proto Тех-долг-FRONTEND #5650
  if (!!data[keyOfGeometry]) {
    // получаем geojson
    const geometry = wkt.parse(data[keyOfGeometry]);
    // проверяем, что метод применяется к массиву и объект имеет тип "полигон" или "путь"
    if (keyOfGeometry === 'line_path' || keyOfGeometry === 'polygon' || keyOfGeometry === 'geometry') {
      // формируем массив точек в зависимости от геометрии
      const points =
        keyOfGeometry === 'line_path'
          ? geometry.coordinates.map((point) => turf.point(point))
          : geometry.coordinates[0].map((point) => turf.point(point));
      // находем их границы
      const bounds = turf.bbox({
        type: 'FeatureCollection',
        features: points,
      });
      // преобразуем их в замкнутый полигон
      const boundsPoly = turf.bboxPolygon(bounds);
      // ищем его центр
      const center = turf.center(boundsPoly);
      // если все прошло удачно
      if (center && boundsPoly && bounds) {
        return {
          centerLatitude: center.geometry.coordinates[1],
          centerLongitude: center.geometry.coordinates[0],
          bounds,
          boundsPoly,
        };
      }
    }
    // возвращаем центр уже для точки
    else if (keyOfGeometry === 'point' || keyOfGeometry === 'location' || keyOfGeometry === 'coordinates') {
      return {
        // у точки две координаты - долготу берём по одной, широту по другой
        centerLatitude: geometry.coordinates[1],
        centerLongitude: geometry.coordinates[0],
      };
    }
    return null;
  }
  return null;
};
