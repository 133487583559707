import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RoadsQualityService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class ViolationsWarrantyPeriod extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'violations_warranty_period',
      filterLabel: 'Гарантийные сроки',
      selectedObjectName: MapObjects.qualitySegmentComplex,
    });
  }

  async uploadData() {
    return await RoadsQualityService.roadsQualityViolationsWarrantyPeriodList();
  }

  async readRecord(id) {
    return await RoadsQualityService.roadsQualityViolationsWarrantyPeriodRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadWarrantyViolations);
  }
}
