import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { _MapContext as MapContext, ScaleControl, StaticMap } from 'react-map-gl';
import { resolveEditableObjectDescription } from 'registrators/map/editableObjectsRegistrator/editableObjectsRegistrator';
import { resolveSelectedObjectDescription } from 'registrators/map/mapSelectObjectRegistrator/mapSelectObjectRegistrator';

import { TileLayer } from '@deck.gl/geo-layers';
import { BitmapLayer } from '@deck.gl/layers';
import { DrawPolygonMode, FeatureCollection, ModifyMode } from '@nebula.gl/edit-modes';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import turfLineSlice from '@turf/line-slice';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { length, lineString } from '@turf/turf';
import DeckGL, { TextLayer, WebMercatorViewport } from 'deck.gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { defineDrawKeyOnModel } from 'services/map/defineDrawMapOnModel';
import { generateUTF8CharacterSet } from 'services/map/generateUTF8CharacterSet';
import { getCoordsFromParsedGeometry } from 'services/map/getCoordsFromParsedGeometry';
import _ from 'underscore';
// @ts-ignore
import wkt from 'wkt';

import { DeckProps, ObjectSettingsProperties } from './model/Deck.model';
import { AdditionalLayerSettings } from './model/AdditionalLayerSettings.model';
import { applyVisibilityFilters } from 'services/map/applyVisibilityFilters';
import MapEventObserver from 'store/rakes/MapEventObserver';
import MapMarkerListContainer from '../MapDisplayMode/MapMarkerList/MapMarkerListContainer';
import MyMapController from './DeckController';
import { MapModuleContext } from '../moduleContext/withMapModuleContext';
import { AnyObject } from 'types/enums/general/general.model';
import LinePathLengthMarker from '../MapStandaloneEditMode/components/LinePathLengthMarker/LinePathLengthMarker';
import { MarkerSizeMode } from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { Styled } from './style/Deck.style';
import { RelatedDataPrototype } from '../../../../../registrators/map/layers/description/relatedData/prototype/RelatedDataPrototype';

const { ScaleControlContainer } = Styled;
const indexPolygonOffset = -1000000;

// Ключ Default public token Mapbox
const MAPBOX_TOKEN =
  'pk.eyJ1Ijoic21hcnQtdHJhbnNwb3J0IiwiYSI6ImNrZHNydmZ4ZTFxcGoyd21xOWhuNWdhb3IifQ.fE9c8_eZMFNSYUY5sPpX_g'; // eslint-disable-line
// Индексы выбранных объектов геометрии
let selectedFeatureIndexes: any[] = [];

const glOptions = { preserveDrawingBuffer: true };

export const Deck = ({
  updateViewState,
  viewState,
  deckCallbacks,
  mapControls,
  mapDisplayModeData,
  mapDrawMode,
  mapLayers,
  mapStyles,
  mapMarkers,
  directoryLayers,
  recalculated,
  setDeckViewRef,
  onReplaceDrawDataForSelectedArea,
  isPrint,
  isPrintMode,
}: DeckProps) => {
  const { onItemClick, onMapClick } = deckCallbacks;

  const { mapboxTime, mapRulerMode } = mapControls;

  const { roadColorFromPicker, selectedObject, standaloneEditableObject } = mapDisplayModeData;

  const { /* drawData, */ onReplaceDrawDataForStandaloneObject, /* onReplaceGeometryData, */ mapGlobalPrintMode } =
    mapDrawMode;

  const { enabledLayers, layersData, segments, selectAreaData } = mapLayers;

  const { zoom } = viewState;

  const { isRasterTileEnabled, /* isStandaloneEditModeEnabled, */ rasterTileStyle, style } = mapStyles;

  /** При флаге слоя hideOtherLayers спрятать слои переменной layer */
  let hideOtherBySingleLayer = false;
  /** Фикс изменений библиотеки символов. */
  const characterSet = useMemo(() => generateUTF8CharacterSet(), []);
  const [isDeckReady, setIsDeckReady] = useState<boolean>(false);
  const mapsContext = useContext<any>(MapModuleContext);

  /** Генерация ссылок на ноды оверлееи карты */
  const deckRef = useRef<any | null>(null);
  const constViewportOfDeckRef: WebMercatorViewport[] = mapsContext.deckRef?.viewManager?._viewports;
  if ((mapGlobalPrintMode && setDeckViewRef) || (isPrintMode && !isPrint)) {
    /** предотвращаяет баги запуска режима печати до загрузки карты */
    if (!isDeckReady) {
      setDeckViewRef(null);
    } else {
      setDeckViewRef(constViewportOfDeckRef[0]);
    }
  }
  /** Генерация ссылок на ноды карту(подложка) */
  const mapRef = useRef<any>(null);

  /**
   * При прорисовке карты присваивает ссылки на оверлей и canvas карты в контекст MapModuleContext
   * @see MapModuleContext
   */
  const onMapLoad = useCallback(() => {
    const mapCanvasRef = mapRef.current.getMap();
    mapsContext.deckRef = deckRef.current.deck;
    if (deckRef.current.deck) setIsDeckReady(true);
    mapsContext.mapRef = mapCanvasRef;
    // mapCanvasRef.addLayer(Building3DLayer);
  }, [mapsContext]);

  if (
    selectedObject &&
    !_.isEmpty(selectedObject) &&
    resolveSelectedObjectDescription(selectedObject?.type, 'hideOtherLayers')
  ) {
    hideOtherBySingleLayer = true;
  }

  // Проверка на свойство hideOtherLayers у выбранного объекта и построение композитного слоя, если он имеется
  /** Переменная отвечающая за рендер дополнительных слоев выбранного объекта */
  const singleLayer = useMemo(() => {
    let result = null;
    if (selectedObject && !_.isEmpty(selectedObject)) {
      const ResolvedSingleLayerPrototype = resolveSelectedObjectDescription(selectedObject?.type, 'mapLayerPrototype');
      if (ResolvedSingleLayerPrototype) {
        result = new ResolvedSingleLayerPrototype({
          data: selectedObject.data,
          invoked: selectedObject.selectedObject,
        });
      }
    }
    return result;
  }, [selectedObject]);

  const isSmallObjectIconVisible = zoom > 16;
  const isBigObjectIconVisible = zoom > 13;

  const layersByOrder = enabledLayers.sort((layerA: any, layerB: any) => (layerB?.order ?? 0) - (layerA.order ?? 0));
  // Отрисовка включенных слоев, итератор должен быть классом и быть зарегистрирован
  /** Переменная отвечающая за рендер основной картографической информации включенных слоев */
  const layers = useMemo(() => {
    const result = [];
    for (let Layer of layersByOrder) {
      /**
       * @type {(null || Array<LayerSettingsPrototype>)}
       * @see LayerSettingsPrototype
       */
      //Если слой отмечен как hidden или присутсвует слой с параметром hideOtherLayers скрываем слой
      if (Layer.hidden || hideOtherBySingleLayer) continue;

      const customSettings = Layer?.getCustomSettings();

      //применение настроек слоя из прототипа слоя
      const settings: AnyObject = {};
      if (customSettings) {
        for (const it in customSettings) {
          const record = customSettings[it];
          const key = record.getDeckKey();
          settings[key] = record.getSettingValues()?.getCurrentValue();
        }
      }
      const appliedSettings: AdditionalLayerSettings = {};
      //Получение шаблонов композитных слоев схемы
      const template = [Layer.getLayerSchema()];
      for (let it of template) {
        //Подготовка к рендеру слоя из шаблона
        const Layer_proto = it.layerToRender;
        let layerProps = _.clone(it);
        //После получения типа слоя удаляем запись на его тип для деструктуризации остальных пропсов слоя
        //Если есть загруженные данные, то строим слои, иначе ждем их получения
        if (layersData) {
          //Данные для передачи в слой
          let preparedData = layersData[Layer.getName()]?.data;
          //если слой ссылается на исторические данные, то передаем в слой записи из timeline из редакса (Трекинги)
          if (layerProps?.additionalParameters?.useTimePlayerForLayer) {
            appliedSettings.getCurrentTimeStamp = mapboxTime.currentTimeStamp;
            appliedSettings.trailLength = mapboxTime.currentTimeStamp;
            appliedSettings.currentTime = mapboxTime.currentTime;
          }
          //применение фильтра по свойству isHidden
          const relatedData = applyVisibilityFilters(layersData[Layer.getName()]?.relatedData, Layer);
          //Создание нового экземпляра слоя со всеми параметрами и настройками

          const converter = (accum: { [key: string]: ObjectSettingsProperties }, object: RelatedDataPrototype) => {
            const properties: ObjectSettingsProperties = {};
            Object.keys(object).forEach((el: string) => (properties[el] = object[el as keyof typeof object]));
            return Object.assign(accum, {
              [object.name]: properties,
            });
          };
          const objectSettings = Layer.relatedData.reduce(converter, {});

          if (Layer_proto) {
            const displayed = new Layer_proto({
              ...layerProps,
              ...appliedSettings,
              data: preparedData,
              onClickHandler: onItemClick,
              relatedData,
              objectSettings,
              editModeActivated: false,
              selectedObject,
              masterLayer: Layer,
              roadColorFromPicker: roadColorFromPicker,
              onMarkerAdd: mapMarkers.onAddMarker,
              onRemoveMarker: mapMarkers.onRemoveMarker,
              activeMarkers: mapMarkers.mapMarkers?.[Layer.getName()] ?? [],
              handleChangeMarkerSizeMode: mapMarkers.handleChangeMarkerSizeMode,
              recalculated,
              isSmallObjectIconVisible,
              isBigObjectIconVisible,
              ...settings,
            });
            result.push(displayed);
          }
        }
      }
    }
    return result;
  }, [
    hideOtherBySingleLayer,
    layersByOrder,
    layersData,
    mapMarkers.handleChangeMarkerSizeMode,
    mapMarkers.mapMarkers,
    mapMarkers.onAddMarker,
    mapMarkers.onRemoveMarker,
    mapboxTime.currentTime,
    mapboxTime.currentTimeStamp,
    onItemClick,
    roadColorFromPicker,
    selectedObject,
    recalculated,
    isSmallObjectIconVisible,
    isBigObjectIconVisible,
  ]);

  const DISTANCE_NUMBER = 0.1;

  /** Переменная отвечающая за рендер слоев режима редактирования (по умолчанию EditableGeoJsonLayer) */
  const editable = useMemo(() => {
    let result = null;
    // Подготовка редактируемого режима
    if (standaloneEditableObject) {
      let snapGeometry: any = null;
      // Загрузка привязываемой геометрии слоя
      const parentStandaloneEditableObject = resolveEditableObjectDescription(
        standaloneEditableObject.selectedInstance,
        'parent'
      );
      if (
        // Legacy, можно получить из инстанса класса
        _.isObject(parentStandaloneEditableObject) &&
        'snapChildGeometry' in parentStandaloneEditableObject &&
        parentStandaloneEditableObject.snapChildGeometry
      ) {
        // Выбор режима редактирования в зависимотси от типа геометрии
        const parentGeometryField = defineDrawKeyOnModel(standaloneEditableObject.parentModel);
        if (parentGeometryField) {
          snapGeometry = wkt.parse(standaloneEditableObject.parentData[parentGeometryField]);
        }
      }
      // Исходная геометрия объекта
      const geo = standaloneEditableObject.drawData?.features?.[0]?.geometry;
      const hasGeo = !_.isEmpty(geo);
      let definedMode;
      // Если есть геометрия включаем нужный режим геометрии
      if (!hasGeo) {
        definedMode = standaloneEditableObject.editMode;
        standaloneEditableObject.drawData.features = [];
      } else {
        selectedFeatureIndexes = [0];
        definedMode = ModifyMode;
      }
      result = new EditableGeoJsonLayer({
        id: 'geojson-layer',
        // @ts-ignore
        mode: definedMode,
        data: standaloneEditableObject.drawData,
        selectedFeatureIndexes,
        pointRadiusScale: 10,
        getLineWidth: () => 5,
        getPolygonOffset: () => [0, indexPolygonOffset],
        onEdit: (data: any) => {
          let lineLength = 0;
          let distanceOfFirstPoint = null;
          let distanceOfLastPoint = null;
          let place = null;
          let oldCopy = { ...standaloneEditableObject.drawData };
          if (data.editType === 'movePosition') {
            const newGeometry = data.updatedData.features[data.updatedData.features.length - 1];
            // редактирование точечной геометрии с привязкой к родительской
            if (snapGeometry && newGeometry?.geometry?.type === 'Point') {
              const closest = nearestPointOnLine(snapGeometry, newGeometry);
              if (closest && closest.properties.location) {
                place = closest.properties.location.toFixed(3);
                oldCopy.features = [closest];
              }
            }
            // редактирование линейной геометрии с привязкой к родительской
            else if (snapGeometry && newGeometry.geometry.type === 'LineString') {
              const coords = newGeometry.geometry.coordinates;
              for (const index in coords) {
                const point = nearestPointOnLine(snapGeometry, coords[index]);
                newGeometry.geometry.coordinates[index] = point.geometry.coordinates;
              }
              const firstPoint = coords[0];
              const lastPoint = coords[coords.length - 1];
              distanceOfFirstPoint =
                nearestPointOnLine(snapGeometry, firstPoint, {
                  units: 'kilometers',
                }).properties?.location?.toFixed(3) ?? 0;
              distanceOfLastPoint =
                nearestPointOnLine(snapGeometry, lastPoint, {
                  units: 'kilometers',
                }).properties?.location?.toFixed(3) ?? 0;
              const newGeometryLine = lineString(newGeometry.geometry.coordinates, { name: 'newGeometryLine' });

              // @ts-ignore
              lineLength = length(newGeometryLine, { units: 'kilometers' }).toFixed(3);
              oldCopy.features = [newGeometry];
            }
            // редактирование свободной линейной геометрии
            else if (newGeometry.geometry.type === 'LineString') {
              const coords = newGeometry.geometry.coordinates;
              const turfLength = lineString(coords);
              // @ts-ignore
              lineLength = length(turfLength, { units: 'kilometers' }).toFixed(3);
              oldCopy.features = [newGeometry];
            }
            // общий случай редактирования
            else {
              oldCopy.features = [newGeometry];
            }
            // @ts-ignore
            onReplaceDrawDataForStandaloneObject(oldCopy, lineLength, distanceOfFirstPoint, distanceOfLastPoint, place);
            return;
          }
          if (data.editType === 'addFeature') {
            const newGeometry = data.updatedData.features[data.updatedData.features.length - 1];
            // создание точечной геометрии с привязкой к родительской
            if (snapGeometry && newGeometry.geometry.type === 'Point') {
              const closest = nearestPointOnLine(snapGeometry, newGeometry);
              oldCopy.features = [closest];
            }
            // Создание геометрии линии с родительским элементом (вдоль дороги/сегмента и т.п)
            else if (snapGeometry && newGeometry?.geometry?.type === 'LineString') {
              const coords = newGeometry.geometry.coordinates;
              const firstPoint = coords[0];
              const lastPoint = coords[coords.length - 1];
              const sliced = turfLineSlice(firstPoint, lastPoint, snapGeometry);
              // передаём координаты для вывода в карточку редактирования с точностью до тысячных
              distanceOfFirstPoint =
                nearestPointOnLine(snapGeometry, firstPoint, {
                  units: 'kilometers',
                }).properties?.location?.toFixed(3) ?? 0;
              distanceOfLastPoint =
                nearestPointOnLine(snapGeometry, lastPoint, {
                  units: 'kilometers',
                }).properties?.location?.toFixed(3) ?? 0;
              const newGeometryLine = lineString(newGeometry.geometry.coordinates, { name: 'newGeometryLine' });
              // @ts-ignore
              lineLength = length(newGeometryLine, { units: 'kilometers' }).toFixed(3);
              oldCopy.features = [sliced];
            }
            // создание свободной линейной геометрии
            else if (newGeometry.geometry.type === 'LineString') {
              if (standaloneEditableObject.layerName !== 'transportOrders') {
                // получаем массив с координатами конечной точки
                const lastPoint = [...newGeometry.geometry.coordinates].pop();
                // получаем массив ближайших точек на линиях с параметрами их удалённости от конечной точки нарисованной геометрии
                const segmentsGeometry = segments.map((el: any) => {
                  if (el.line_path) {
                    const geometry = getCoordsFromParsedGeometry(el.line_path);
                    if (geometry) {
                      const line = lineString(geometry);
                      if (line) {
                        // @ts-ignore
                        return nearestPointOnLine(line, lastPoint, { units: 'kilometers' });
                      }
                      return null;
                    }
                    return null;
                  } else return null;
                });
                let distance = 0.1;
                // записываем в дистанс меньшее значение расстояния от точки до линии
                segmentsGeometry.forEach((el: any) => {
                  if (el?.properties?.dist < distance) {
                    distance = el.properties.dist;
                  }
                });
                // находим ту точку, которая имеет меньшее расстояние - дистанс
                const nearestPoint = segmentsGeometry.find((el: any) => el?.properties?.dist === distance);
                // если расстояние меньше 100 метров, меняем последнюю точку геометрии на ту, которую нашли - таким образом
                // примагничиваем рисуемую дорогу к ближайшей уже существующей в радиусе 0,1 км
                if (distance < DISTANCE_NUMBER) {
                  newGeometry.geometry.coordinates.pop();
                  newGeometry.geometry.coordinates.push(nearestPoint.geometry.coordinates);
                }
              }
              const coords = newGeometry.geometry.coordinates;
              const turfLength = lineString(coords);
              // @ts-ignore
              lineLength = length(turfLength, { units: 'kilometers' }).toFixed(3);
              oldCopy.features = [newGeometry];

              //Тестовый вариант маркера для изменения длины линии
              if (coords.length === 2 && mapMarkers.onAddMarker) {
                mapMarkers?.onAddMarker(standaloneEditableObject.layerName, {
                  id: data.editContext.featureIndexes[0],
                  type: standaloneEditableObject.selectedInstance,
                  markerSizeMode: MarkerSizeMode.static,
                  markerPosition: coords[0],
                  component: <LinePathLengthMarker />,
                });
              }
            }
            // создание геометрии в общем случае
            else {
              oldCopy.features = [newGeometry];
            }
            onReplaceDrawDataForStandaloneObject(
              oldCopy,
              lineLength,
              // @ts-ignore
              distanceOfFirstPoint,
              distanceOfLastPoint,
              place
            );
            return;
          }

          if (data.editType === 'addPosition' || data.editType === 'removePosition') {
            const feature = data?.updatedData?.features?.[0];
            const type = feature?.geometry?.type;
            const coordinates = feature?.geometry?.coordinates;
            //Тестовый вариант маркера для изменения длины линии
            if (type === 'LineString') {
              if (coordinates?.length > 2 && mapMarkers.onClearMarkersForLayer) {
                mapMarkers.onClearMarkersForLayer(standaloneEditableObject.layerName);
              } else if (mapMarkers.onAddMarker) {
                mapMarkers.onAddMarker(standaloneEditableObject.layerName, {
                  id: data.editContext.featureIndexes[0],
                  type: standaloneEditableObject.selectedInstance,
                  markerSizeMode: MarkerSizeMode.static,
                  markerPosition: coordinates[0],
                  component: <LinePathLengthMarker />,
                });
              }
            }
          }

          if (data.editType !== 'addFeature') {
            oldCopy = { ...standaloneEditableObject.drawData };
            const newGeometry = data.updatedData.features[data.updatedData.features.length - 1];
            if (snapGeometry && newGeometry?.geometry?.type === 'Point') {
              const closest = nearestPointOnLine(snapGeometry, newGeometry);
              oldCopy.features = [closest];
            } else {
              oldCopy.features = [newGeometry];
            }
            onReplaceDrawDataForStandaloneObject(oldCopy);
          }
        },
      });
    }
    return result;
  }, [mapMarkers, onReplaceDrawDataForStandaloneObject, segments, standaloneEditableObject]);

  /** Переменная отвечающая за рендер слоев измерений */
  const rulers = useMemo(() => {
    let result = null;
    // Подключении слоя измерений
    if (mapRulerMode) {
      result = new EditableGeoJsonLayer({
        id: 'ruler-Layer',
        data: undefined,
        // @ts-ignore
        mode: mapRulerMode,
      });
    }
    return result;
  }, [mapRulerMode]);

  const polygonSelector = useMemo(() => {
    return mapGlobalPrintMode
      ? new EditableGeoJsonLayer({
          id: 'select-area-Layer',
          data: selectAreaData,
          // @ts-ignore
          mode: selectAreaData?.features?.length ? ModifyMode : DrawPolygonMode,
          selectedFeatureIndexes: [0],
          onEdit: ({ updatedData }: { updatedData: FeatureCollection }) => {
            if (updatedData.features.length) onReplaceDrawDataForSelectedArea(updatedData);
          },
        })
      : null;
  }, [mapGlobalPrintMode, onReplaceDrawDataForSelectedArea, selectAreaData]);

  /** Переменная отвечающая за рендер растровых подложек */
  const additionalTileLayer = useMemo(() => {
    let result = null;
    // Подключении слоя растровых подложек
    if (isRasterTileEnabled) {
      result = new TileLayer({
        data: rasterTileStyle,
        minZoom: 0,
        maxZoom: 19,
        tileSize: 256,
        renderSubLayers: (props) => {
          const {
            bbox: { west, south, east, north },
          } = props.tile;
          return new BitmapLayer(props, {
            data: undefined,
            image: props.data,
            bounds: [west, south, east, north],
          });
        },
      });
    }
    return result;
  }, [isRasterTileEnabled, rasterTileStyle]);
  // Фикс бага с character set
  const initialCharLayer = useMemo(() => new TextLayer({ characterSet }), [characterSet]);
  // Фикс бага с не отрисовывающейся (только на проде, после билда) подложкой
  // @ts-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
  mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
  // Переменная для определения нахождения курсора на карте или же на объекте карты (изменяется в функции onHover, ипользуется в getCursor)
  let isHovering: string;

  const deckLayers = useMemo(
    () => [
      initialCharLayer,
      additionalTileLayer,
      ...layers,
      singleLayer,
      editable,
      rulers,
      polygonSelector,
      ...directoryLayers,
    ],
    [additionalTileLayer, directoryLayers, editable, initialCharLayer, layers, rulers, polygonSelector, singleLayer]
  );

  const mapController = useMemo(() => {
    return { type: MyMapController };
  }, []);

  return (
    <>
      <DeckGL
        workerCount={16}
        // @ts-ignore
        controller={mapController}
        ref={deckRef}
        layers={deckLayers}
        onClick={onMapClick}
        glOptions={glOptions}
        // @ts-ignore
        onHover={({ devicePixel }) => {
          isHovering = !MapEventObserver.checkEventLock()
            ? !!(devicePixel?.[0] && devicePixel?.[1])
              ? 'pointer'
              : 'grab'
            : 'auto';
        }}
        viewState={viewState}
        onViewStateChange={updateViewState}
        getCursor={() => isHovering}
        ContextProvider={MapContext.Provider}
      >
        <StaticMap
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapStyle={style}
          ref={mapRef}
          onLoad={onMapLoad}
          reuseMaps
          preserveDrawingBuffer={true}
        />
        <ScaleControlContainer>
          <ScaleControl maxWidth={100} unit={'metric'} />
        </ScaleControlContainer>
        <MapMarkerListContainer />
      </DeckGL>
    </>
  );
};
