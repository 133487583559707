import { Collapse, Form } from 'antd';

import styled from 'styled-components';

const ExtendedApplicationteForm = styled(Form)`
  padding: 2.5rem;
`;

const LocalTitle = styled.h2`
  font-size: 1.25rem;
  text-align: left;
`;

const LocalPanelColumn = styled.div`
  margin: 5px 20px;
  width: calc(100% - 40px);
  min-width: 250px;
  & + .workInProgress {
    display: none;
  }
  .ant-collapse-arrow {
    margin: 0 0 0 15px !important;
    svg {
      fill: ${(props) => props.theme.colors.primary};
      transform: rotate(-90deg);
    }
  }
`;

const ExtendedAntdCollapse = styled(Collapse)`
  width: 100%;
  background: transparent;
  border: none;
`;

const ExtendedAntdCollapseItem = styled(Collapse.Panel)`
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  border: none !important;
  width: 100%;
  padding: 0 !important;
  & .ant-collapse-header {
    color: ${(props) => props.theme.colors.neutralDarker} !important;
    padding-left: 0 !important;
    padding-bottom: 2.1rem !important;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: fit-content;
    & i {
      margin-left: 3rem;
      position: static !important;
      transform: none !important;
    }
  }
  & .ant-collapse-content-box {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  & .ant-collapse-content {
    border: none !important;
  }
`;

const LocalPanelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 -20px;
  .ant-input-number {
    overflow: visible;
  }
  .ant-select-selection-selected-value,
  .Checkboxstyled__TextWrapper-co1qsc-1,
  .ant-select ~ span {
    color: ${(props) => props.theme.colors.neutralDarker};
    font-size: 1rem;
    font-weight: 500;
  }
`;

const ContainerForContainersWrappersLocalPanels = styled.div`
  display: flex;
`;

const ContainersWrappersLocalPanels = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
  padding-right: 80px;
`;

export const Styled = {
  ExtendedApplicationteForm,
  LocalTitle,
  LocalPanelsWrapper,
  LocalPanelColumn,
  ExtendedAntdCollapse,
  ExtendedAntdCollapseItem,
  ContainerForContainersWrappersLocalPanels,
  ContainersWrappersLocalPanels,
};
