import styled from 'styled-components/macro';
import { Button } from '../../../../UI/common/components/Button/Button';
import { NavLink } from 'react-router-dom';

const LocalMainWrapper = styled.div`
  position: absolute;
  top: 50%;
  height: 382px;
  transform: translateY(-50%);
  z-index: 50000;
  .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
  .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
    color: ${(props) => props.theme.colors.primary};
  }

  .inline-placeholder-login.close {
    color: ${(props) => props.theme.colors.paragraph};
  }
`;

const LocalInputWrapperPassword = styled.div`
  position: relative;
  width: 306px;
  margin: 32px auto 16px auto;
`;

const LocalInputWrapperPasswordRepeat = styled.div`
  position: relative;
  width: 306px;
  margin: 16px auto 16px auto;
`;

const LocalPrimaryDescription = styled.small`
  display: block;
  font-weight: 700;
  font-family: 'Gilroy', sans-serif;
  font-size: ${(props) => props.theme.fontSettings.default};
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
  line-height: 19px;
  opacity: 0.8;
  transition: all 0.3s;
  margin-top: 35px;
  &:hover {
    opacity: 1;
  }
`;

const LocalFormWrapper = styled.div`
  padding: 32px 16px 34.5px;
  width: 338px;
  background-color: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.darkBoxShadow};
  & p {
    position: absolute;
    top: 37%;
    left: 6%;
  }

  & a {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.primary};
  }

  & .error-message {
    position: absolute;
    top: 59%;
    left: 7%;
    color: ${(props) => props.theme.colors.danger};
    text-align: left;
    background: none;
  }

  & .ant-btn.ant-btn-link {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-top: 38px;
    color: ${(props) => props.theme.colors.primary};
  }

  .inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    color: #bfbfbf;
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }
  .inline-placeholder-login.open.focused {
    color: ${(props) => props.theme.colors.paragraph};
  }
`;

const LocalFormTitle = styled.h3`
  font-family: 'Gilroy', sans-serif;
  margin: 0;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  width: 100%;
  align-items: center;
  font-size: 24px;
  color: ${(props) => props.theme.colors.dark};
`;

const LocalFormSubmit = styled.form`
  & + * {
    margin-top: 30px;
  }
`;

const LocalSetDataError = styled.div`
  margin: 37% 20px;
  text-align: center;
  color: ${(props) => props.theme.colors.dangerDark};
`;

const LocalSubmitFormError = styled.div`
  text-align: center;
  font-size: medium;
  color: ${(props) => props.theme.colors.dangerDark};
`;

const ExtendedButton = styled(Button)`
  font-family: 'Gilroy', sans-serif;
  color: white;
  font-weight: 700;
  font-size: 16px;
`;

const ExtendedNavLink = styled(NavLink)`
  display: block;
`;

// Todo style and functional Тех-долг-FRONTEND #5627
export const Styled = {
  LocalMainWrapper,
  LocalFormWrapper,
  LocalFormTitle,
  LocalFormSubmit,
  LocalInputWrapperPassword,
  LocalInputWrapperPasswordRepeat,
  ExtendedButton,
  LocalSetDataError,
  LocalSubmitFormError,
  LocalPrimaryDescription,
  ExtendedNavLink,
};
