import { message } from 'antd';
import { Dispatch } from 'react';

import { ApplicationsService } from 'generated/api/api';
import { appActions } from 'store/reducers/global/app/app.actions';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';

const getApplicationsForWorkShift = async (id: number): Promise<any> => {
  return await ApplicationsService.applicationsWorkShiftsGetApplicationsRetrieve({ id });
};

export const setStateApplicationsForWorkShift = async (workShiftID: number, dispatch: Dispatch<any>) => {
  dispatch(appActions.startAppFetching());
  try {
    const { results } = await getApplicationsForWorkShift(workShiftID);
    dispatch(
      mapboxDataActions.addMapboxRelatedData(
        Layers.brigadeApplications,
        MapObjects.brigadeWorkShiftsApplications,
        results
      )
    );
  } catch (e) {
    message.error('Что-то пошло не так');
  }
  dispatch(appActions.endAppFetching());
};
