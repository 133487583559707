import { Form } from 'antd';
import { InputNumber } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import { Styled } from '../styled/BaseInput.styled';
import { useBaseInputPlaceholderControl } from '../hooks/useBaseInputPlaceholderControl';
import { checkConditionAndCallFn } from '../../../../services/common/checkConditionAndCallFn';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { getFromURL } from 'api/getFromURL';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import _ from 'underscore';
import Usage from 'UI/common/components/Alert/Alert';

const { getBaseStylesForInput, BasePlaceholder, BaseErrorMessage, ContainerInnButton } = Styled;

const StyledInputNumber = getBaseStylesForInput(InputNumber);

const CustomINNInputStatement = (props) => {
  const [valueInn, setValueInn] = useState(null);
  const [alertData, setAlertData] = useState({});
  const { control, errors, setValue } = useFormContext();
  const { description, object, field, validator } = props;
  const { required, message } = useBaseInputSettings(description);

  const { placeholderState, setPlaceholderState } = useBaseInputPlaceholderControl(field, object, setValue);

  const className = useMemo(() => {
    if (placeholderState) return 'opened';
    if (errors[field]) return 'class_errors';
    return '';
  }, [errors, field, placeholderState]);

  if (errors[field]?.message?.includes(description.label) && errors[field]) {
    errors[field].message = null;
  }

  const getINNVolition = useCallback(async () => {
    if (!valueInn) return;
    try {
      const response = await getFromURL.getAll(`/special_permits/individual_statements/check_inn/?inn=${valueInn}`);
      if (response) {
        setAlertData({
          isVisebleAlert: true,
          type: response === 'Информация не найдена' ? PromptType.error : PromptType.success,
          title: 'Проверка произведена!',
          message: response,
        });
      }
    } catch (e) {
      if (e?.response && _.isObject(e.response)) {
        const alertMessage = createAlertMessage(e);
        setAlertData({
          isVisebleAlert: true,
          type: PromptType.error,
          title: 'Ошибка',
          message: alertMessage,
        });
      }
    } finally {
      setTimeout(() => setAlertData({ isVisebleAlert: false }), 9000);
    }
  }, [valueInn]);

  return (
    <Form.Item key={'form_' + field} name={field} className={className}>
      <Controller
        name={field}
        as={StyledInputNumber}
        key={'input_' + field}
        control={control}
        rules={{
          required: { value: required, message: message },
          validate: (value) => {
            if (value && validator) {
              return validator(value);
            } else {
              return true;
            }
          },
        }}
        disabled={description.read_only}
        onFocus={() => {
          setPlaceholderState(true);
        }}
        onBlur={(e) => {
          setValueInn(e[0].target.value);
          checkConditionAndCallFn(!e[0].target.value, () => setPlaceholderState(false));
        }}
        onSubmit={(e) => {
          return e;
        }}
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <BasePlaceholder className={className}>{description.label}</BasePlaceholder>
      <ContainerInnButton
        hasShadow={true}
        type={ButtonType.primary}
        tooltip={'Проверить действительность ИНН'}
        onClick={(e) => {
          e.preventDefault();
          getINNVolition();
        }}
      >
        Проверить ИНН
      </ContainerInnButton>
      <Usage
        portalSide={'bottom-right'}
        mb={'2rem'}
        mr={'2rem'}
        isOpen={alertData.isVisebleAlert}
        type={alertData.type}
        title={alertData.title}
        message={alertData.message}
        size={{ width: '400px' }}
        onClose={() => setAlertData({ ...alertData, isVisebleAlert: false })}
      />
    </Form.Item>
  );
};

export default CustomINNInputStatement;
