import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';

export class transportAnalysisDistrictOmutinskiiy extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'transportAnalysisDistrictOmutinskiiy',
      filterLabel: 'Омутинский район',
    });
  }

  async uploadData() {
    return await getFromURL.getWithParams('/public_transport/checkpoints_you_can_reach/', { district_id: '11' });
  }
}
