import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Menu } from 'antd';

import { StateModel } from 'store/reducers';
import { clearSelection } from 'store/reducers/directory/actions/selectedObjectActions';
import { rolesDirectoryActions } from 'store/reducers/rolesDirectory/rolesDirectory.actions';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

import { DirectoriesEnum } from '../enums/DirectoriesEnum';
import { TabMenuEnum } from '../enums/TabMenuEnum';
import { PermissionsPanelSiderProps } from './PermissionsPanelSider.model';
import { Styled } from './PermissionsPanelSider.styled';
import { AnyObject } from 'types/enums/general/general.model';

const { SiderWrapper, SiderInner, SiderContent, SiderFooter, SiderTitle } = Styled;

const menuTabPermissionsGroups = [
  DirectoriesEnum.main,
  DirectoriesEnum.get_directory,
  DirectoriesEnum.get_layers,
  DirectoriesEnum.get_reports,
];

const menuTabUsers = [DirectoriesEnum.main, DirectoriesEnum.permissions_groups, DirectoriesEnum.filters_user];

const CreateTabMenu = (folder: DirectoriesEnum, menuLabel: TabMenuEnum, isDisabledMenuItem: boolean) => {
  return (
    <Menu.Item disabled={isDisabledMenuItem} key={folder}>
      {menuLabel}
    </Menu.Item>
  );
};

const createMenuItems = (folder: DirectoriesEnum, isDisabledMenuItem: boolean, dataId: boolean) => {
  const result: AnyObject[] = [];
  if (folder === 'permissions_groups') {
    menuTabPermissionsGroups.forEach((tab) => {
      result.push(
        CreateTabMenu(
          DirectoriesEnum[tab],
          TabMenuEnum[tab === DirectoriesEnum.main ? DirectoriesEnum.permissions_groups : tab],
          isDisabledMenuItem
        )
      );
    });
  } else if (folder === 'users') {
    menuTabUsers.forEach((tab) => {
      const newDisapled = tab === DirectoriesEnum.filters_user && !dataId;
      result.push(CreateTabMenu(DirectoriesEnum[tab], TabMenuEnum[tab], newDisapled || isDisabledMenuItem));
    });
  }
  return result;
};

const PermissionsPanelSider = (props: PermissionsPanelSiderProps) => {
  const dispatch = useDispatch();
  const rolesDirectory = useSelector((state: StateModel) => state.rolesDirectory);
  const { nameRoles } = rolesDirectory;
  const {
    params: { folder },
  } = useRouteMatch<{ folder: DirectoriesEnum }>();
  const { initClose, changeMenuTab, handleSubmitForm, pageMenuKey, isSuperuser, submitData } = props;

  const isDisabledMenuItem = useMemo(() => {
    if (isSuperuser) return isSuperuser;
    return folder === 'permissions_groups' ? !nameRoles?.length : !submitData;
  }, [folder, isSuperuser, nameRoles, submitData]);

  const MenuItems = useMemo(
    () => createMenuItems(folder, isDisabledMenuItem, !!submitData?.id),
    [folder, isDisabledMenuItem, submitData]
  );

  const onSelectMenuItem = useCallback(
    (event) => {
      changeMenuTab(event.key);
    },
    [changeMenuTab]
  );

  const closeDirectory = () => {
    initClose();
    dispatch(rolesDirectoryActions.emptyStoreWhenExitAction());
    dispatch(clearSelection());
  };

  return (
    <SiderWrapper width="16%">
      <SiderInner>
        <SiderContent>
          <SiderTitle>Данные</SiderTitle>
          <Menu selectedKeys={[pageMenuKey]} onClick={onSelectMenuItem}>
            {MenuItems}
          </Menu>
        </SiderContent>
        <SiderFooter>
          <Button onClick={handleSubmitForm} type={ButtonType.primary} width="100%">
            {isDisabledMenuItem && !isSuperuser ? 'Заполнить роли/фильтра' : 'Сохранить изменения'}
          </Button>
          <Button onClick={closeDirectory} type={ButtonType.danger} width="100%">
            Выйти
          </Button>
        </SiderFooter>
      </SiderInner>
    </SiderWrapper>
  );
};

export default PermissionsPanelSider;
