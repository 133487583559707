import styled from 'styled-components/macro';

const LocalMainWrapper = styled.div``;

const LocalText = styled.div`
  padding-bottom: 30px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.neutralDarker};
`;

export const Styled = {
  LocalMainWrapper,
  LocalText,
};
