import styled from 'styled-components/macro';

const HintsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const Hint = styled.div`
  padding: 5px 10px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.backgroundNeutral}
`;

export const Styled = {
  HintsContainer,
  Hint,
};
