export enum TypeOfRevertEnum {
  lastOfType = 'lastOfType',
  includes = 'includes',
}

export enum TypeDisplayedDirectoryEnum {
  main = 'main',
  mainObject = 'mainObject',
  inline = 'inline',
  inlineObject = 'inlineObject',
}
