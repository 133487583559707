export const getLayersWithSettings = (mapboxLayers: any) => {
  let layersWithSettingsSettings = {};

  for (let i = 0; i < mapboxLayers.length; i++) {
    if (!mapboxLayers[i].isHidden) {
      const layerName = mapboxLayers[i].name;
      const relatedDatesArray = mapboxLayers[i].relatedData
        ?.filter((el: { isHidden: any }) => !el.isHidden)
        .map((el: { name: any }) => el.name);
      let HashOfSettings = {};
      const settings = mapboxLayers[i].customSettings;
      for (let j = 0; j < settings.length; j++) {
        HashOfSettings = {
          ...HashOfSettings,
          [settings[j].constructor.name]: { currentValue: settings[j].currentValue },
        };
      }
      layersWithSettingsSettings = {
        ...layersWithSettingsSettings,
        [layerName]: {
          relatedData: relatedDatesArray,
          customSettings: HashOfSettings,
        },
      };
    }
  }

  return layersWithSettingsSettings;
};
