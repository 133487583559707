import React from 'react';
import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { KnsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

const ElectricalSubstationsSwitcher = React.lazy(() => import('../customButtons/ElectricalSubstationsSwitcher.lazy'));
let selectedType = 'substations';

export class ElectricalSubstationsDataAccessor extends LayerDataAccessorPrototype {
  // отвечает за объект который выбирается в дата ацессоре, так как он общий для экземпляров класса то сделаем его статичным, чтобы не создавать лишние экземпляры
  static selectedType = LayerDataAccessorPrototype.selectedType;

  actionButtons = [ElectricalSubstationsSwitcher];

  constructor() {
    super({
      label: 'Подстанции',
      selectedType: MapObjects.substations,
    });
  }
  //получение данных для грид таблицы
  async getData() {
    return await KnsService.knsSubstationsList();
  }

  // по запросу по модели вернем нашу схему. Await нужен для согласованности then, можно и в промис обернуть, но лень)
  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.knsSubstations);
  }

  static getSelectedType() {
    return selectedType;
  }

  // метод замены статичной переменной
  static replaceSelectedType(type) {
    ElectricalSubstationsSwitcher.selectedType = type;
  }

  //оверрайдим метод прототипа для получения статичной переменной иначе получим переменную прототипа
  getSelectedObjectType() {
    return ElectricalSubstationsSwitcher.selectedType ? ElectricalSubstationsSwitcher.selectedType : selectedType;
  }
}
