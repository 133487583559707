import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';

import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class TownSquares extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'townSquares',
      filterLabel: 'Площади',
      selectedObjectName: MapObjects.townSquares,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsTownSquaresList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsTownSquaresDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsTownSquaresRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.townSquares);
  }
}
