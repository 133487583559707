import React, { FC } from 'react';
import { Styled } from './style/PromptNotifications.styled';
import { Button } from '../Button/Button';
import ScreenPortal from '../ScreenPortal/ScreenPortal';
import { PromptNotificationProps } from './model/PromptNotifications.model';
import { ButtonType } from '../Button/model/Button.model';
import { PromptNotificationUsageProps } from './model/PromptNotificationUsage.model';
import { QuestionIcon } from 'UI/custom/icons/objectIcons/QuestionIcon';

const { LocalMainWrapper, LocalIcon, LocalDescription, LocalExtensionFunctional } = Styled;

/** Компонент отрисовки уведомления*/
const PromptNotification: FC<PromptNotificationProps> = ({ promptData, isCreate, ...otherProps }) => {
  if (promptData) {
    const { message } = promptData;
    return (
      <LocalMainWrapper {...otherProps}>
        <LocalIcon>
          <QuestionIcon />
        </LocalIcon>
        <LocalDescription>{message}</LocalDescription>
        {isCreate && (
          <LocalExtensionFunctional>
            <Button type={ButtonType.primaryReverse}>Создать</Button>
            <Button type={ButtonType.unboundedPrimary}>Использовать тот же</Button>
          </LocalExtensionFunctional>
        )}
      </LocalMainWrapper>
    );
  } else {
    return null;
  }
};
/** Компонент отрисовки уведомления в портале
 * @see PromptNotification*/
const Usage: FC<PromptNotificationUsageProps> = ({ isOpen, promptData, isCreate, portalSide, ...otherProps }) => {
  return (
    <ScreenPortal isOpen={isOpen} portalSide={portalSide} {...otherProps}>
      <PromptNotification isCreate={isCreate} promptData={promptData} {...otherProps} />
    </ScreenPortal>
  );
};

export default Usage;
