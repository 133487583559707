import React, { createContext, useMemo, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

import { IApplicationsContext, RouterProps } from './ApplicationsForm/interfacesApplications/interfacesApplications';
import { emptyArr, initialModel } from './ApplicationsForm/interfacesApplications/templates';
import ApplicationsFolder from './folder/ApplicationsFolder';
import IndividualOrdersContainer from './Pages/IndividualOrdersContainer';

const initialValues: IApplicationsContext = {
  pageMenuKey: ApplicationsDirectory.main,
  setPageMenuKey: (pageMenuKey: ApplicationsDirectory) => {},
  mainModel: initialModel,
  setMainModel: (mainModel: ModelResponse) => {},
  applicationTasks: emptyArr,
  setApplicationTasks: () => {},
};

export const ApplicationsContext = createContext<IApplicationsContext>(initialValues);

const ApplicationsRouter = React.memo(
  () => {
    const [pageMenuKey, setPageMenuKey] = useState<ApplicationsDirectory>(ApplicationsDirectory.main);
    const [mainModel, setMainModel] = useState<ModelResponse>(initialModel);
    const [applicationTasks, setApplicationTasks] = useState<string[]>(emptyArr);

    const providerValues = useMemo(
      () => ({
        pageMenuKey,
        setPageMenuKey,
        mainModel,
        setMainModel,
        applicationTasks,
        setApplicationTasks,
      }),
      [applicationTasks, mainModel, pageMenuKey]
    );

    return (
      <ApplicationsContext.Provider value={providerValues}>
        <Switch>
          <Route
            exact
            path={`/app/applications/:directory/:application?/:scoreMode?/:scoreId(\\d+)?`}
            component={() => <IndividualOrdersContainer />}
          />
          <Route exact path={'/app/applications/'} component={ApplicationsFolder} />
        </Switch>
      </ApplicationsContext.Provider>
    );
  },
  (prevProps: RouterProps, nextProps: RouterProps) => {
    return prevProps.location.pathname === nextProps.location.pathname;
  }
);

export default withRouter(ApplicationsRouter);
