import React from 'react';
import LayerMainPanel from './LayerMainPanel';
import { connect } from 'react-redux';

const LayerMainPanelContainer = (props) => {
  return <LayerMainPanel {...props} />;
};

LayerMainPanelContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    isGalleryVisible: state.mapPanels.layersGalleryVisible,
  };
};

export default connect(mapStateToProps, null)(LayerMainPanelContainer);
