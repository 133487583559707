import styled from 'styled-components/macro';
import { Styled as styledMixins } from '../../../../../styles/styleMixins/styleMixins.styled';
import { Alert } from 'antd';

const { AddSizeProps } = styledMixins.size;
const { AddMarginsProps, AddPaddingsProps } = styledMixins.offset;

const LocalMainWrapper = styled.article`
  ${(props) => AddSizeProps(props)};
  ${(props) => AddMarginsProps(props)};
  ${(props) => AddPaddingsProps(props)};
  & .MuiAlert-action {
    align-items: start;
  }
`;

const AntAlert = styled(Alert)`
  margin: 20px;
`;

export const Styled = { LocalMainWrapper, AntAlert };
