import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { CommonSelectedItemContextMenuPrototype } from '../../prototypes/CommonSelectedItemContextMenuPrototype';

export class ElectricalSubstationsDescription extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, MapObjects.substations, layerName);
    this.initialCoordinate = initialCoordinate;
  }
}
