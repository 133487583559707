import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';

import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { KnsService } from '../../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class Lines_troll extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'Lines_troll',
      filterLabel: 'Линии троллейбусов',
      selectedObjectName: MapObjects.linesTroll,
    });
  }

  async uploadData() {
    return await KnsService.knsLinesTrollList();
  }

  async deleteRecord(id) {
    return await KnsService.knsLinesTrollDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsLinesTrollRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.knsLinesTroll);
  }
}
