import {
  Action,
  ActionFunction0,
  ActionFunction1,
  ActionFunction2,
  ActionFunction3,
  createAction,
} from 'redux-actions';

import {
  MapChangeMarkerSizeModeAction,
  MapClearMarkersForLayer,
  MapMarker,
  MapMarkerAction,
  MapMoveMarkerAction,
  MarkerSizeMode,
} from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { Layers } from 'types/enums/map/layers/Layers';

class MapMarkersActions {
  /** action types */
  public readonly prefix: string = 'MAP_MARKERS';
  public readonly ADD_CARD_MARKER: string = `${this.prefix}.ADD_CARD_MARKER`;
  public readonly REMOVE_CARD_MARKER: string = `${this.prefix}.REMOVE_CARD_MARKER`;
  public readonly MOVE_MARKER: string = `${this.prefix}.MOVE_MARKER`;
  public readonly CHANGE_MARKER_SIZE_MODE: string = `${this.prefix}.CHANGE_MARKER_SIZE_MODE`;
  public readonly CLEAR_MARKERS_FOR_LAYER: string = `${this.prefix}.CLEAR_MARKERS_FOR_LAYER`;
  public readonly CLEAR_ALL_MARKERS: string = `${this.prefix}.CLEAR_ALL_MARKERS`;

  /** actions */
  public addMapMarker: ActionFunction2<Layers, Partial<MapMarker>, Action<MapMarkerAction>> = createAction(
    this.ADD_CARD_MARKER,
    (layer: Layers, marker: Partial<MapMarker>) => ({ layer, marker })
  );

  public removeMapMarker: ActionFunction2<Layers, Partial<MapMarker>, Action<MapMarkerAction>> = createAction(
    this.REMOVE_CARD_MARKER,
    (layer: Layers, marker: Partial<MapMarker>) => ({ layer, marker })
  );

  public moveMarker: ActionFunction3<Layers, Partial<MapMarker>, [number, number], Action<MapMoveMarkerAction>> =
    createAction(this.MOVE_MARKER, (layer: Layers, marker: Partial<MapMarker>, newPosition: [number, number]) => ({
      layer,
      marker,
      markerPosition: newPosition,
    }));

  public changeMarkerSizeMode: ActionFunction3<
    Layers,
    Partial<MapMarker>,
    MarkerSizeMode,
    Action<MapChangeMarkerSizeModeAction>
  > = createAction(
    this.CHANGE_MARKER_SIZE_MODE,
    (layer: Layers, marker: Partial<MapMarker>, markerSizeMode: MarkerSizeMode) => ({
      layer,
      marker,
      markerSizeMode,
    })
  );

  public clearMarkersForLayer: ActionFunction1<Layers, Action<MapClearMarkersForLayer>> = createAction(
    this.CLEAR_MARKERS_FOR_LAYER,
    (layer: Layers) => ({ layer })
  );

  public clearAllMarkers: ActionFunction0<any> = createAction(this.CLEAR_ALL_MARKERS);
}

export const mapMarkersActions = new MapMarkersActions();
