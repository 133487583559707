import { Input, Modal } from 'antd';

import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';

const { TextArea } = Input;

const LocalModal = styled(Modal)`
  & .ant-modal-header {
    box-shadow: 0 0 8px -5px ${(props) => props.theme.colors.paragraph};
  }
  & .ant-modal-close {
    right: -50px;
    top: -15px;
    color: ${(props) => props.theme.colors.defaultLighter};
  }
  & .ant-modal-content {
    width: 500px;
  }
  & .ant-upload.ant-upload-drag {
    background-color: ${(props) => props.theme.colors.defaultLighter};
  }
  & .ant-modal-title {
    text-align: center;
  }
`;

const StartUploadButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  & .shitTrader {
    & p {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
    height: 40px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.defaultLighter};
  }
`;

const LocalSpan = styled.span``;

const LocalDiv = styled.div`
  margin-left: 15px;
`;

const DropdownStyledContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  height: 60px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.defaultLight};
  margin-bottom: 16px;
  *:first-child {
    width: 95%;
  }
  .ant-dropdown-button {
    button {
      width: 0;
      &:after {
        display: none;
      }
    }
    .ant-dropdown-trigger {
      width: 100%;
    }
  }
  .ant-dropdown-button:last-child {
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 2px;
      top: 18px;
      background-color: ${(props) => props.theme.colors.primary};
    }
    &:before {
      right: 7px;
      transform: rotate(45deg);
    }
    &:after {
      right: 2px;
      transform: rotate(-45deg);
    }
  }
  * {
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: flex-end;
    color: ${(props) => props.theme.colors.neutralDarker};
    text-align: left;
    border: none;
    background-color: ${(props) => props.theme.colors.transparent} !important;
    box-shadow: none;
    &:hover,
    &:active,
    &:checked {
      background-color: ${(props) => props.theme.colors.transparent} !important;
    }
    svg {
      display: none;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  button {
    width: auto;
    border-radius: 8px;
    text-align: left;
    background-color: ${(props) => props.theme.colors.backgroundNeutral};
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.primary};
    margin: 8px 8px 8px 0;
  }
`;

const TextAreaContainer = styled(TextArea)`
  background-color: ${(props) => props.theme.colors.defaultLight};
  border-radius: 8px;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.colors.neutralDarker};
  text-align: left;
  border: none;
  padding: 14px;
  margin-bottom: 14px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const FieldsTitle = styled.p`
  margin: 0;
  padding: 4px 0;
  font-weight: bold;
  font-size: 14px;
  color: ${(props) => props.theme.colors.gray};
`;

const StatusButton = styled(Button)`
  width: 100px;
  height: 40px;
  border-radius: 20px;
  path {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Styled = {
  LocalDiv,
  LocalSpan,
  StartUploadButton,
  LocalModal,
  DropdownStyledContainer,
  ButtonContainer,
  TextAreaContainer,
  FieldsTitle,
  StatusButton,
};
