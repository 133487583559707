import React, { FC, useCallback } from 'react';
import { Image, Layer } from 'react-konva';
import { Stage } from 'konva/lib/Stage';
import { Shape } from 'konva/lib/Shape';
import { KonvaEventObject } from 'konva/lib/Node';

import MaskGroup from '../MaskGroup/MaskGroup';
import { DrawLayerProps } from './model/DrawLayer.model';

const DrawLayer: FC<DrawLayerProps> = ({ image, currentMask, onChangePosition }) => {
  // Функция для добавления точек в режиме редактирования
  const handleDrawing = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const mouseEvent = e.evt.which;
      const LEFT_MOUSE_CLICK = 1;

      if (mouseEvent === LEFT_MOUSE_CLICK) {
        const target: Shape | Stage = e.target;
        const newPoints = currentMask?.drawHandler(target);

        if (newPoints) onChangePosition(newPoints);
      }
    },
    [currentMask, onChangePosition]
  );

  return (
    <>
      {currentMask && (
        <Layer onClick={handleDrawing}>
          {/*Картинка с нулевой видимостью нужна для отслеживания событий слоя*/}
          <Image image={image} opacity={0} />
          <MaskGroup
            polygonPoints={currentMask.drawMaskPoints}
            polygonConfig={currentMask.polygonConfig}
            drawType={currentMask.currentDrawType}
            editable
            onChangePosition={onChangePosition}
            drawPoints={currentMask.drawPoints}
          />
        </Layer>
      )}
    </>
  );
};

export default DrawLayer;
