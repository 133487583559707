import { EditableGeoJsonLayer } from 'nebula.gl';
import { DrawLineStringMode, DrawPointMode, DrawPolygonMode } from '@nebula.gl/edit-modes';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { DSelector } from 'services/map/Dselector/DSelector';

const defineDrawModeOnModel = (field: string) => {
  switch (field) {
    case SchemaFieldType.lineString:
    case SchemaFieldType.linePath:
      return DrawLineStringMode;
    case SchemaFieldType.polygon:
      return DrawPolygonMode;
    case SchemaFieldType.point:
      return DrawPointMode;
    default:
      return null;
  }
};

export const getEditLayer = (field: string, setNewPosition: (value: string) => void, changePosition: boolean) => {
  let result = new EditableGeoJsonLayer({
    id: 'directory_geojson-layer',
    data: { type: 'FeatureCollection', features: [] },
    // @ts-ignore
    mode: defineDrawModeOnModel(field),
    selectedFeatureIndexes: [0],
    onEdit: (data: any) => {
      if (data.editType === 'addFeature') {
        let result = '';
        switch (field) {
          case SchemaFieldType.point:
            const points = data?.updatedData?.features?.[0]?.geometry?.coordinates;
            result = DSelector.getPointString(points);
            break;
          case SchemaFieldType.linePath:
          case SchemaFieldType.lineString:
            const linePoints = data?.updatedData?.features?.[0]?.geometry?.coordinates;
            result = DSelector.getLineString(linePoints);
            break;
          case SchemaFieldType.polygon:
            const polygonPoints = data?.updatedData?.features?.[0]?.geometry?.coordinates?.[0];
            result = DSelector.getPolygonString(polygonPoints);
            break;
          default:
            break;
        }
        setNewPosition(result);
      }
    },
  });
  return changePosition ? result : null;
};
