import { AnyObject } from 'types/enums/general/general.model';
import { Model } from 'types/interfaces/net/ModelResponse.model';
import { Styled } from './MainDataFederalStatementCustomView.styled';
import {
  FederalStatementVehicle,
  FederalStatementVehicleAxis,
  CalculationOfHarmFederalStatement,
} from 'generated/api/api';

const { MainStatmentDataContent, MainStatmentDataWrapper, TableContainer } = Styled;

interface MainStatmentDataContainerProps {
  mainData: AnyObject;
  scheme: Model;
  vehiclesData?: FederalStatementVehicle[];
  vehicleScheme: Model;
  vehiclesAxisData?: FederalStatementVehicleAxis[];
  vehicleAxisScheme: Model;
  calcOfHarmData?: CalculationOfHarmFederalStatement[];
  calcOfHarmScheme: Model;
}

const tableStyles = {
  widthNinety: { width: '90px' },
  widthOneHundredFifty: { width: '150px' },
  widthOneHundredSixtyFive: { width: '165px' },
  widthOneHundredTen: { width: '110px' },
  widthOneHundredFifteen: { width: '115px' },
  widthOneHundred: { width: '100px' },
  widthEightyTwo: { width: '82px' },
  widthSixtyTwo: { width: '62px' },
  widthOneHundredFourty: { width: '140px' },
  widthOneHundredThirtyEight: { width: '138px' },
  widthFourHundredFive: { width: '405px' },
  widthOneHundredThirty: { width: '130px' },
  widthOneHundredThirtyFive: { width: '135px' },
  widthThreeHundredOne: { width: '301px' },
  widthThreeHundredEleven: { width: '311px' },
  widthTwoHundredFortyOne: { width: '241px' },
  widthThirtySeven: { width: '37px' },
};

const getChoiceDisplayName = (scheme: Model, field: string, data: AnyObject) =>
  scheme[field].choices?.find((el) => el.value.toLocaleUpperCase() === data[field]?.toLocaleUpperCase())?.display_name;

export const MainStatmentDataContainer = ({
  mainData,
  scheme,
  vehiclesData,
  vehicleScheme,
  vehiclesAxisData,
  vehicleAxisScheme,
  calcOfHarmData,
  calcOfHarmScheme,
}: MainStatmentDataContainerProps) => {
  const sumCalcOfHarm = calcOfHarmData?.reduce((summa, current) => summa + (current.fact_harm_calc ?? 0), 0);

  return (
    <MainStatmentDataContent>
      <MainStatmentDataWrapper>
        <TableContainer>
          <tr>
            <td className="label_text" style={tableStyles.widthNinety}>
              {scheme.request_number?.label}
            </td>
            <td style={tableStyles.widthOneHundredFifty}>{mainData.request_number}</td>
          </tr>
          <tr>
            <td className="label_text">{scheme.authority_name?.label}</td>
            <td>{mainData.authority_name}</td>
          </tr>
        </TableContainer>
        <TableContainer className="absolute_position_tables reconciling_organization">
          <tr>
            {/* TODO Поправить поля согласующая организация Тех-долг-FRONTEND #5602*/}
            <td className="label_text" style={tableStyles.widthEightyTwo}>
              {scheme.handling_organization_name?.label}
            </td>
            <td style={tableStyles.widthOneHundredTen}>{mainData.handling_organization_name}</td>
          </tr>
        </TableContainer>
        <TableContainer className="absolute_position_tables road_train_data">
          <caption>Данные автопоезда</caption>
          <tr>
            <td className="label_text" style={tableStyles.widthOneHundredSixtyFive}>
              {scheme.dimensions_length?.label}
            </td>
            <td style={tableStyles.widthSixtyTwo}>{mainData.dimensions_length}</td>
          </tr>
          <tr>
            <td className="label_text">{scheme.dimensions_width?.label}</td>
            <td>{mainData.dimensions_width}</td>
          </tr>
          <tr>
            <td className="label_text">{scheme.dimensions_height?.label}</td>
            <td>{mainData.dimensions_height}</td>
          </tr>
          <tr>
            <td className="label_text">Масса автопоезда без груза/c грузом (т)</td>
            <td>
              {mainData.empty_total_weight}/{mainData.total_weight}
            </td>
          </tr>
          {/* TODO Поправить поля согласующая организация Тех-долг-FRONTEND #5602*/}
          <tr>
            <td className="label_text">{scheme.with_freight?.label}</td>
            <td>{mainData.with_freight ? 'Да' : 'Нет'}</td>
          </tr>
          {/* TODO Поправить поля согласующая организация Тех-долг-FRONTEND #5602*/}
          <tr>
            <td className="label_text">{scheme.with_freight?.label}</td>
            <td>{mainData.with_freight ? 'Да' : 'Нет'}</td>
          </tr>
          <tr>
            <td className="label_text">{scheme.turn_radius?.label}</td>
            <td>{mainData.turn_radius}</td>
          </tr>
          <tr>
            <td className="label_text">{scheme.estimated_speed?.label}</td>
            <td>{mainData.estimated_speed}</td>
          </tr>
          {/* TODO Поправить поля согласующая организация Тех-долг-FRONTEND #5602*/}
          <tr>
            <td className="label_text">{scheme.additional_freight_dimensions_length?.label}</td>
            <td>{mainData.additional_freight_dimensions_length}</td>
          </tr>
        </TableContainer>
        <TableContainer>
          <caption>Согласовываемый маршрут движения (участок маршурта)</caption>
          <tr>
            <td className="label_text" style={tableStyles.widthOneHundredFourty}>
              {scheme.resolution_route_description?.label}
            </td>
            <td style={tableStyles.widthThreeHundredOne}>{mainData.resolution_route_description}</td>
          </tr>
          <tr>
            <td className="label_text">{scheme.route_description?.label}</td>
            <td>{mainData.route_description}</td>
          </tr>
        </TableContainer>
        <TableContainer>
          <tr>
            <td className="label_text" style={tableStyles.widthOneHundredThirty}>
              {scheme.transporter_name?.label}
            </td>
            <td style={tableStyles.widthThreeHundredEleven}>{mainData.transporter_name}</td>
          </tr>
          <tr>
            <td className="label_text">{scheme.transporter_inn?.label}</td>
            <td>{mainData.transporter_inn}</td>
          </tr>
        </TableContainer>
        <TableContainer>
          <tr>
            <td className="label_text" style={tableStyles.widthOneHundred}>
              Срок перевозки
            </td>
            <td className="label_text" style={tableStyles.widthOneHundred}>
              {scheme.shipping_type?.label}
            </td>
            <td className="label_text" style={tableStyles.widthTwoHundredFortyOne}>
              {scheme.trip_count?.label}
            </td>
          </tr>
          <tr>
            <td>
              с {mainData.period_from} по {mainData.period_to}
            </td>
            <td>{getChoiceDisplayName(scheme, 'shipping_type', mainData)}</td>
            <td>{mainData.trip_count}</td>
          </tr>
        </TableContainer>
        <TableContainer>
          <caption>Информация о транспортных средствах</caption>
          <tr>
            <td className="label_text" style={tableStyles.widthThirtySeven}>
              №
            </td>
            <td className="label_text" style={tableStyles.widthOneHundred}>
              {vehicleScheme.vehicle_type?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleScheme.model?.label}
            </td>
            {/* TODO Поправить поля согласующая организация Тех-долг-FRONTEND #5602 */}
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleScheme.order?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleScheme.registration_number?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleScheme.axle_count?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleScheme.weight?.label}
            </td>
          </tr>
          {vehiclesData?.map((vehicle, index) => (
            <tr key={`vehiclesData_${vehicle.id}`}>
              <td>{index + 1}</td>
              <td>
                {getChoiceDisplayName(vehicleScheme, 'vehicle_type', vehicle) ??
                  (vehicle.vehicle_type ? 'Тягач' : 'Незаполнено')}
              </td>
              <td>{vehicle.model}</td>
              {/* TODO Поправить поля согласующая организация Тех-долг-FRONTEND #5602*/}
              <td>{vehicle.order}</td>
              <td>{vehicle.registration_number}</td>
              <td>{vehicle.axle_count}</td>
              <td>{vehicle.weight}</td>
            </tr>
          ))}
        </TableContainer>
        <TableContainer>
          <tr>
            <td className="label_text" style={tableStyles.widthThirtySeven}>
              {vehicleAxisScheme.number?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundred}>
              {vehicleScheme.model?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleAxisScheme.load?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleAxisScheme.distance?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleAxisScheme.is_eight_wheel?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleAxisScheme.is_air_axle?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredTen}>
              {vehicleAxisScheme.is_twin_wheel?.label}
            </td>
          </tr>
          {vehiclesAxisData?.map((axis) => (
            <tr key={`vehiclesAxisData_${axis.id}`}>
              <td>{axis.number}</td>
              <td>{vehiclesData?.find((el) => el.id === axis.vehicle_parameters)?.model}</td>
              <td>{axis.load}</td>
              <td>{axis.distance}</td>
              <td>{axis.is_eight_wheel ? 'Да' : 'Нет'}</td>
              <td>{axis.is_air_axle ? 'Да' : 'Нет'}</td>
              <td>{axis.is_twin_wheel ? 'Да' : 'Нет'}</td>
            </tr>
          ))}
        </TableContainer>
        <TableContainer>
          <caption>Характеристики груза</caption>
          <tr>
            <td className="label_text" style={tableStyles.widthOneHundredFifteen}>
              {scheme.freight_kind?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredFifteen}>
              {scheme.freight_name?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredFifteen}>
              {scheme.freight_dimensions_length?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredFifteen}>
              {scheme.freight_dimensions_width?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredFifteen}>
              {scheme.freight_dimensions_height?.label}
            </td>
            <td className="label_text" style={tableStyles.widthOneHundredFifteen}>
              {scheme.freight_weight?.label}
            </td>
          </tr>
          <tr>
            <td>{getChoiceDisplayName(scheme, 'freight_kind', mainData)}</td>
            <td>{mainData.freight_name ?? '-'}</td>
            <td>{mainData.freight_dimensions_length}</td>
            <td>{mainData.freight_dimensions_width}</td>
            <td>{mainData.freight_dimensions_height}</td>
            <td>{mainData.freight_weight}</td>
          </tr>
        </TableContainer>
        <div className={'relative_position_container'}>
          <TableContainer>
            <caption>Расчет</caption>
            <tr>
              <td className="label_text" style={tableStyles.widthOneHundredThirtyEight}>
                {scheme.resolution_route_length?.label}
              </td>
            </tr>
            <tr>
              <td>{mainData.resolution_route_length ?? '-'}</td>
            </tr>
          </TableContainer>
          <TableContainer className="absolute_position_tables calc_of_harm">
            <tr>
              {/* TODO Поправить поля согласующая организация Тех-долг-FRONTEND #5602*/}
              <td className="label_text" style={tableStyles.widthOneHundredThirtyFive}>
                {calcOfHarmScheme.normative_load?.label}
              </td>
              <td className="label_text" style={tableStyles.widthOneHundredThirtyFive}>
                {calcOfHarmScheme.length_road?.label}
              </td>
              <td className="label_text" style={tableStyles.widthOneHundredThirtyFive}>
                {calcOfHarmScheme.fact_harm_calc?.label}
              </td>
              <td className="label_text" style={tableStyles.widthOneHundredThirtyFive}>
                Итого (рубли)
              </td>
            </tr>
          </TableContainer>
          <TableContainer className="absolute_position_tables calc_of_harm_data">
            <tr>
              {/* TODO Поправить поля согласующая организация  Тех-долг-FRONTEND #5602*/}
              <td className={'nested_table_container'} style={tableStyles.widthFourHundredFive}>
                <TableContainer className={'nested_table'}>
                  {calcOfHarmData?.map((calcOfHarm) => (
                    <tr key={`calc_of_harm_data_${calcOfHarm.id}`}>
                      {/* TODO Поправить поля согласующая организация  Тех-долг-FRONTEND #5602*/}
                      <td style={tableStyles.widthOneHundredThirtyFive}>{calcOfHarm.normative_load}</td>
                      <td style={tableStyles.widthOneHundredThirtyFive}>{calcOfHarm.length_road}</td>
                      <td style={tableStyles.widthOneHundredThirtyFive}>{calcOfHarm.fact_harm_calc}</td>
                    </tr>
                  ))}
                </TableContainer>
              </td>
              <td style={tableStyles.widthOneHundredThirtyFive} className={'sum_calc_of_harm'}>
                {sumCalcOfHarm}
              </td>
            </tr>
          </TableContainer>
        </div>
      </MainStatmentDataWrapper>
    </MainStatmentDataContent>
  );
};
