export enum DataAccessMethod {
  websocket = 'ws',
  httpGet = 'http',
  httpGetById = 'http by id',
  httpGetWithParams = 'http get with params',
  httpPost = 'http post',
  httpGetAll = 'http all',
  httpGetWithFilters = 'http get with filters',
  delayedUpload = 'delayed upload',
  useReduxData = 'use Redux data',
  useLayerCallback = 'use Layer setData callback',
  custom = 'custom',
  none = 'none',
}

export enum DataUpdateMethod {
  partialUpdate = 'PATCH',
  fullUpdate = 'PUT',
}
