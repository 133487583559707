export const signsRoadMapperType8 = {
  '8.1.1': { x: 0, y: 672, width: 128, height: 134 },
  '8.1.1-G': { x: 129, y: 672, width: 128, height: 134 },
  '8.1.1-R': { x: 258, y: 672, width: 128, height: 134 },
  '8.1.2': { x: 0, y: 0, width: 128, height: 180 },
  '8.1.2-G': { x: 129, y: 0, width: 128, height: 180 },
  '8.1.2-R': { x: 0, y: 181, width: 128, height: 180 },
  '8.1.3': { x: 387, y: 672, width: 128, height: 134 },
  '8.1.3-G': { x: 516, y: 672, width: 128, height: 134 },
  '8.1.3-R': { x: 645, y: 672, width: 128, height: 134 },
  '8.1.4': { x: 774, y: 0, width: 128, height: 134 },
  '8.1.4-G': { x: 774, y: 135, width: 128, height: 134 },
  '8.1.4-R': { x: 774, y: 270, width: 128, height: 134 },
  '8.2.1': { x: 774, y: 405, width: 128, height: 134 },
  '8.2.1-G': { x: 774, y: 540, width: 128, height: 134 },
  '8.2.1-R': { x: 0, y: 807, width: 128, height: 134 },
  '8.2.2': { x: 1806, y: 540, width: 68, height: 180 },
  '8.2.2-G': { x: 1806, y: 721, width: 68, height: 180 },
  '8.2.2-R': { x: 1806, y: 902, width: 68, height: 180 },
  '8.2.3': { x: 1806, y: 1083, width: 68, height: 180 },
  '8.2.3-G': { x: 1806, y: 1264, width: 68, height: 180 },
  '8.2.3-R': { x: 1806, y: 1445, width: 68, height: 180 },
  '8.2.4': { x: 1806, y: 1626, width: 68, height: 180 },
  '8.2.4-G': { x: 1935, y: 0, width: 68, height: 180 },
  '8.2.4-R': { x: 1935, y: 181, width: 68, height: 180 },
  '8.2.5': { x: 129, y: 807, width: 128, height: 134 },
  '8.2.5-G': { x: 258, y: 807, width: 128, height: 134 },
  '8.2.5-R': { x: 387, y: 807, width: 128, height: 134 },
  '8.2.6': { x: 516, y: 807, width: 128, height: 134 },
  '8.2.6-G': { x: 645, y: 807, width: 128, height: 134 },
  '8.2.6-R': { x: 774, y: 807, width: 128, height: 134 },
  '8.3.1': { x: 903, y: 0, width: 128, height: 134 },
  '8.3.1-G': { x: 903, y: 135, width: 128, height: 134 },
  '8.3.1-R': { x: 903, y: 270, width: 128, height: 134 },
  '8.3.2': { x: 903, y: 405, width: 128, height: 134 },
  '8.3.2-G': { x: 903, y: 540, width: 128, height: 134 },
  '8.3.2-R': { x: 903, y: 675, width: 128, height: 134 },
  '8.3.3': { x: 0, y: 942, width: 128, height: 134 },
  '8.3.3-1': { x: 129, y: 942, width: 128, height: 134 },
  '8.3.3-G': { x: 258, y: 942, width: 128, height: 134 },
  '8.3.3-G-1': { x: 387, y: 942, width: 128, height: 134 },
  '8.3.3-R': { x: 516, y: 942, width: 128, height: 134 },
  '8.3.3-R-1': { x: 645, y: 942, width: 128, height: 134 },
  '8.4.1': { x: 774, y: 942, width: 128, height: 134 },
  '8.4.1-G': { x: 903, y: 942, width: 128, height: 134 },
  '8.4.1-R': { x: 1032, y: 0, width: 128, height: 134 },
  '8.4.2': { x: 1032, y: 135, width: 128, height: 134 },
  '8.4.2-G': { x: 1032, y: 270, width: 128, height: 134 },
  '8.4.2-R': { x: 1032, y: 405, width: 128, height: 134 },
  '8.4.3.2': { x: 1032, y: 540, width: 128, height: 134 },
  '8.4.3.2-G': { x: 1032, y: 675, width: 128, height: 134 },
  '8.4.3.2-R': { x: 1032, y: 810, width: 128, height: 134 },
  '8.4.3': { x: 0, y: 1077, width: 128, height: 134 },
  '8.4.3-G': { x: 129, y: 1077, width: 128, height: 134 },
  '8.4.3-R': { x: 258, y: 1077, width: 128, height: 134 },
  '8.4.4': { x: 387, y: 1077, width: 128, height: 134 },
  '8.4.4-G': { x: 516, y: 1077, width: 128, height: 134 },
  '8.4.4-R': { x: 645, y: 1077, width: 128, height: 134 },
  '8.4.5': { x: 774, y: 1077, width: 128, height: 134 },
  '8.4.5-G': { x: 903, y: 1077, width: 128, height: 134 },
  '8.4.5-R': { x: 1032, y: 1077, width: 128, height: 134 },
  '8.4.6': { x: 774, y: 675, width: 120, height: 126 },
  '8.4.6-G': { x: 1161, y: 0, width: 128, height: 134 },
  '8.4.6-R': { x: 1161, y: 135, width: 128, height: 134 },
  '8.4.7': { x: 903, y: 810, width: 120, height: 126 },
  '8.4.7-G': { x: 1161, y: 270, width: 128, height: 134 },
  '8.4.7-R': { x: 1161, y: 405, width: 128, height: 134 },
  '8.4.8': { x: 1161, y: 540, width: 128, height: 134 },
  '8.4.8-G': { x: 1161, y: 675, width: 128, height: 134 },
  '8.4.8-R': { x: 1161, y: 810, width: 128, height: 134 },
  '8.4.9': { x: 387, y: 0, width: 128, height: 154 },
  '8.4.9-G': { x: 387, y: 155, width: 128, height: 154 },
  '8.4.9-R': { x: 0, y: 362, width: 128, height: 154 },
  '8.4.10': { x: 129, y: 362, width: 128, height: 154 },
  '8.4.10-G': { x: 258, y: 362, width: 128, height: 154 },
  '8.4.10-R': { x: 387, y: 362, width: 128, height: 154 },
  '8.4.11': { x: 516, y: 0, width: 128, height: 154 },
  '8.4.11-G': { x: 516, y: 155, width: 128, height: 154 },
  '8.4.11-R': { x: 516, y: 310, width: 128, height: 154 },
  '8.4.12': { x: 0, y: 517, width: 128, height: 154 },
  '8.4.12-G': { x: 129, y: 517, width: 128, height: 154 },
  '8.4.12-R': { x: 258, y: 517, width: 128, height: 154 },
  '8.4.13': { x: 387, y: 517, width: 128, height: 154 },
  '8.4.13-G': { x: 516, y: 517, width: 128, height: 154 },
  '8.4.13-R': { x: 645, y: 0, width: 128, height: 154 },
  '8.4.14': { x: 645, y: 155, width: 128, height: 154 },
  '8.4.14-G': { x: 645, y: 310, width: 128, height: 154 },
  '8.4.14-R': { x: 645, y: 465, width: 128, height: 154 },
  '8.5.1': { x: 1161, y: 945, width: 128, height: 134 },
  '8.5.1-G': { x: 0, y: 1212, width: 128, height: 134 },
  '8.5.1-R': { x: 129, y: 1212, width: 128, height: 134 },
  '8.5.2': { x: 258, y: 1212, width: 128, height: 134 },
  '8.5.2-G': { x: 387, y: 1212, width: 128, height: 134 },
  '8.5.2-R': { x: 516, y: 1212, width: 128, height: 134 },
  '8.5.3': { x: 645, y: 1212, width: 128, height: 134 },
  '8.5.3-G': { x: 774, y: 1212, width: 128, height: 134 },
  '8.5.3-R': { x: 903, y: 1212, width: 128, height: 134 },
  '8.5.4': { x: 1032, y: 1212, width: 128, height: 134 },
  '8.5.4-G': { x: 1161, y: 1212, width: 128, height: 134 },
  '8.5.4-R': { x: 1290, y: 0, width: 128, height: 134 },
  '8.5.5': { x: 1290, y: 135, width: 128, height: 134 },
  '8.5.5-G': { x: 1290, y: 270, width: 128, height: 134 },
  '8.5.5-R': { x: 1290, y: 405, width: 128, height: 134 },
  '8.5.6': { x: 1290, y: 540, width: 128, height: 134 },
  '8.5.6-G': { x: 1290, y: 675, width: 128, height: 134 },
  '8.5.6-R': { x: 1290, y: 810, width: 128, height: 134 },
  '8.5.7': { x: 1290, y: 945, width: 128, height: 134 },
  '8.5.7-G': { x: 1290, y: 1080, width: 128, height: 134 },
  '8.5.7-R': { x: 0, y: 1347, width: 128, height: 134 },
  '8.6.1': { x: 129, y: 1347, width: 128, height: 134 },
  '8.6.1-G': { x: 258, y: 1347, width: 128, height: 134 },
  '8.6.1-R': { x: 387, y: 1347, width: 128, height: 134 },
  '8.6.2': { x: 516, y: 1347, width: 128, height: 134 },
  '8.6.2-G': { x: 645, y: 1347, width: 128, height: 134 },
  '8.6.2-R': { x: 774, y: 1347, width: 128, height: 134 },
  '8.6.3': { x: 903, y: 1347, width: 128, height: 134 },
  '8.6.3-G': { x: 1032, y: 1347, width: 128, height: 134 },
  '8.6.3-R': { x: 1161, y: 1347, width: 128, height: 134 },
  '8.6.4': { x: 1290, y: 1347, width: 128, height: 134 },
  '8.6.4-G': { x: 1419, y: 0, width: 128, height: 134 },
  '8.6.4-R': { x: 1419, y: 135, width: 128, height: 134 },
  '8.6.5': { x: 1419, y: 270, width: 128, height: 134 },
  '8.6.5-G': { x: 1419, y: 405, width: 128, height: 134 },
  '8.6.5-R': { x: 1419, y: 540, width: 128, height: 134 },
  '8.6.6': { x: 1419, y: 675, width: 128, height: 134 },
  '8.6.6-G': { x: 1419, y: 810, width: 128, height: 134 },
  '8.6.6-R': { x: 1419, y: 945, width: 128, height: 134 },
  '8.6.7': { x: 1419, y: 1080, width: 128, height: 134 },
  '8.6.7-G': { x: 1419, y: 1215, width: 128, height: 134 },
  '8.6.7-R': { x: 0, y: 1482, width: 128, height: 134 },
  '8.6.8': { x: 129, y: 1482, width: 128, height: 134 },
  '8.6.8-G': { x: 258, y: 1482, width: 128, height: 134 },
  '8.6.8-R': { x: 387, y: 1482, width: 128, height: 134 },
  '8.6.9': { x: 516, y: 1482, width: 128, height: 134 },
  '8.6.9-G': { x: 645, y: 1482, width: 128, height: 134 },
  '8.6.9-R': { x: 774, y: 1482, width: 128, height: 134 },
  8.7: { x: 903, y: 1482, width: 128, height: 134 },
  '8.7-G': { x: 1032, y: 1482, width: 128, height: 134 },
  '8.7-R': { x: 1161, y: 1482, width: 128, height: 134 },
  8.8: { x: 1290, y: 1482, width: 128, height: 134 },
  '8.8-G': { x: 1419, y: 1482, width: 128, height: 134 },
  '8.8-R': { x: 1548, y: 0, width: 128, height: 134 },
  '8.9.1': { x: 1548, y: 135, width: 128, height: 134 },
  '8.9.1-G': { x: 1548, y: 270, width: 128, height: 134 },
  '8.9.1-R': { x: 1548, y: 405, width: 128, height: 134 },
  8.9: { x: 1032, y: 945, width: 120, height: 126 },
  '8.9-1': { x: 1161, y: 1080, width: 120, height: 126 },
  '8.9-G': { x: 1290, y: 1215, width: 120, height: 126 },
  '8.9-G-1': { x: 1548, y: 540, width: 128, height: 134 },
  '8.9-R': { x: 1419, y: 1350, width: 120, height: 126 },
  '8.9-R-1': { x: 1548, y: 675, width: 128, height: 134 },
  '8.10': { x: 1548, y: 810, width: 128, height: 134 },
  '8.10-G': { x: 1548, y: 945, width: 128, height: 134 },
  '8.10-R': { x: 1548, y: 1080, width: 128, height: 134 },
  8.11: { x: 1548, y: 1215, width: 128, height: 134 },
  '8.11-G': { x: 1548, y: 1350, width: 128, height: 134 },
  '8.11-R': { x: 0, y: 1617, width: 128, height: 134 },
  8.12: { x: 129, y: 1617, width: 128, height: 134 },
  '8.12-G': { x: 258, y: 1617, width: 128, height: 134 },
  '8.12-R': { x: 387, y: 1617, width: 128, height: 134 },
  8.13: { x: 129, y: 181, width: 128, height: 180 },
  '8.13-G': { x: 258, y: 0, width: 128, height: 180 },
  '8.13-R': { x: 258, y: 181, width: 128, height: 180 },
  8.14: { x: 516, y: 1617, width: 128, height: 134 },
  '8.14-G': { x: 645, y: 1617, width: 128, height: 134 },
  '8.14-R': { x: 774, y: 1617, width: 128, height: 134 },
  8.15: { x: 903, y: 1617, width: 128, height: 134 },
  '8.15-G': { x: 1032, y: 1617, width: 128, height: 134 },
  '8.15-R': { x: 1161, y: 1617, width: 128, height: 134 },
  8.16: { x: 1290, y: 1617, width: 128, height: 134 },
  '8.16-G': { x: 1419, y: 1617, width: 128, height: 134 },
  '8.16-R': { x: 1548, y: 1617, width: 128, height: 134 },
  8.17: { x: 1677, y: 0, width: 128, height: 134 },
  '8.17-G': { x: 1677, y: 135, width: 128, height: 134 },
  '8.17-R': { x: 1677, y: 270, width: 128, height: 134 },
  8.18: { x: 1677, y: 405, width: 128, height: 134 },
  '8.18-G': { x: 1677, y: 540, width: 128, height: 134 },
  '8.18-R': { x: 1677, y: 675, width: 128, height: 134 },
  8.19: { x: 1677, y: 810, width: 128, height: 134 },
  '8.19-G': { x: 1677, y: 945, width: 128, height: 134 },
  '8.19-R': { x: 1677, y: 1080, width: 128, height: 134 },
  '8.20.1': { x: 1677, y: 1215, width: 128, height: 134 },
  '8.20.1-G': { x: 1677, y: 1350, width: 128, height: 134 },
  '8.20.1-R': { x: 1677, y: 1485, width: 128, height: 134 },
  '8.20.2': { x: 0, y: 1752, width: 128, height: 134 },
  '8.20.2-G': { x: 129, y: 1752, width: 128, height: 134 },
  '8.20.2-R': { x: 258, y: 1752, width: 128, height: 134 },
  '8.21.1': { x: 387, y: 1752, width: 128, height: 134 },
  '8.21.1-G': { x: 516, y: 1752, width: 128, height: 134 },
  '8.21.1-R': { x: 645, y: 1752, width: 128, height: 134 },
  '8.21.2': { x: 774, y: 1752, width: 128, height: 134 },
  '8.21.2-G': { x: 903, y: 1752, width: 128, height: 134 },
  '8.21.2-R': { x: 1032, y: 1752, width: 128, height: 134 },
  '8.21.3': { x: 1161, y: 1752, width: 128, height: 134 },
  '8.21.3-G': { x: 1290, y: 1752, width: 128, height: 134 },
  '8.21.3-R': { x: 1419, y: 1752, width: 128, height: 134 },
  '8.22.1': { x: 1875, y: 1626, width: 43, height: 180 },
  '8.22.1-G': { x: 1875, y: 1445, width: 43, height: 180 },
  '8.22.1-R': { x: 1875, y: 1264, width: 43, height: 180 },
  '8.22.2': { x: 1875, y: 1083, width: 43, height: 180 },
  '8.22.2-G': { x: 1875, y: 902, width: 43, height: 180 },
  '8.22.2-R': { x: 1875, y: 721, width: 43, height: 180 },
  '8.22.3': { x: 1875, y: 540, width: 43, height: 180 },
  '8.22.3-G': { x: 1935, y: 362, width: 43, height: 180 },
  '8.22.3-R': { x: 1935, y: 543, width: 43, height: 180 },
  8.23: { x: 1548, y: 1752, width: 128, height: 134 },
  '8.23-G': { x: 1677, y: 1752, width: 128, height: 134 },
  '8.23-R': { x: 1806, y: 0, width: 128, height: 134 },
  8.24: { x: 1806, y: 135, width: 128, height: 134 },
  '8.24-G': { x: 1806, y: 270, width: 128, height: 134 },
  '8.24-R': { x: 1806, y: 405, width: 128, height: 134 },
};
