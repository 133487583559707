export default function SNILSValidator(snils) {
  let result;
  let localSnils = snils.toString().replace(/[^0-9.]/g, '');
  if (/[^0-9]/.test(localSnils)) {
    result = 'СНИЛС должен содержать только цифры';
  } else if (localSnils.length !== 11) {
    result = 'СНИЛС должен содержать 11 цифр.';
  } else {
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(localSnils[i]) * (9 - i);
    }
    let checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit === parseInt(localSnils.slice(-2))) {
      result = true;
    } else {
      result = 'невыполненная контрольная сумма';
    }
  }
  return result;
}
