import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RoadConstructionsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class RoadSigns extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_signs',
      filterLabel: 'Дорожные знаки',
      selectedObjectName: MapObjects.roadSigns,
      isHidden: false,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsRoadSignsList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsRoadSignsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsRoadSignsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadConstructionsRoadSigns);
  }
}
