import { handleActions, Reducer } from 'redux-actions';
import { appActions } from './app.actions';
import { AppState } from './app.model';
import { MapReducer } from '../../reducers.model';
import _ from 'underscore';

const { START_FETCHING, END_FETCHING, SET_SIDEBAR_COLLAPSE, RECALCULATE } = appActions;

const initialState = {
  fetching: false,
  sidebarIsOpened: true,
  recalculated: 'none',
};

export const startFetching: Reducer<AppState, null> = (state) => {
  return {
    ...state,
    fetching: true,
  };
};

export const endFetching: Reducer<AppState, null> = (state) => {
  return {
    ...state,
    fetching: false,
  };
};

export const setSidebarCollapse: Reducer<AppState, boolean> = (state, action) => {
  return {
    ...state,
    sidebarIsOpened: action.payload ?? !state.sidebarIsOpened,
  };
};

//задание уникального айди в свойство recalculated
export const recalculate: Reducer<AppState, null> = (state) => {
  return {
    ...state,
    recalculated: _.uniqueId(),
  };
};

const mapReducer: MapReducer<AppState, any> = {
  [START_FETCHING]: startFetching,
  [END_FETCHING]: endFetching,
  [SET_SIDEBAR_COLLAPSE]: setSidebarCollapse,
  [RECALCULATE]: recalculate,
};

export const appReducer: Reducer<AppState, any> = handleActions(mapReducer, initialState);
