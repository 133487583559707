export default function INNValidator(inn, mask = '111111111111') {
  let result = null;
  const regexpNumbers = /[0-9.]/g;
  const regexpAlphabetRu = /[aA-zZ.]/g;
  const regexpAlphabetEn = /[аА-яЯ.]/g;
  const preparedRegexp = mask.replace(regexpNumbers, '').replace(regexpAlphabetEn, '').replace(regexpAlphabetRu, '');
  let preparedValue = inn;
  for (let it = 0; it < preparedRegexp.length; it++) {
    preparedValue = preparedValue.replace(preparedRegexp[it], '');
  }
  const prepared = preparedValue;
  let localInn = prepared.toString().replace(/[^0-9.]/g, '');
  if (/[^0-9]/.test(localInn)) {
    result = 'Индивидуальный номер налогоплательщика для ИП должен состоять только из цифр';
  } else if ([10, 12].indexOf(localInn.length) === -1) {
    result = 'Индивидуальный номер налогоплательщика для ИП должен состоять из 10 или 12 цифр.';
  } else {
    let checkDigit = function (localInnCurrent, coefficients) {
      let n = 0;
      for (let i in coefficients) {
        n += coefficients[i] * localInnCurrent[i];
      }
      return parseInt((n % 11) % 10);
    };
    switch (localInn.length) {
      case 10:
        let n10 = checkDigit(localInn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(localInn[9])) {
          result = true;
        }
        break;
      case 12:
        let n11 = checkDigit(localInn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        let n12 = checkDigit(localInn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n11 === parseInt(localInn[10]) && n12 === parseInt(localInn[11])) {
          result = true;
        }
        break;
      default:
        break;
    }
    if (!result) {
      result = 'невыполненная контрольная сумма';
    }
  }
  return result;
}
