import { Button } from '../../../../common/components/Button/Button';
import { DropUploadIcon } from '../../../icons/actionIcons/DropUploadIcon';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Styled } from './UploadTMCFileButton.styled';
import { UploadFileItemIcon } from '../../../icons/actionIcons/UploadFileItemIcon';
import { DeleteIcon } from '../../../icons/actionIcons/DeleteIcon';
import { message, Select, Upload, Input } from 'antd';
import { getYearsChoice } from '../../../../../services/data/getYearsChoice';
import _ from 'underscore';
import { basePath } from '../../../../../registrators/map/layers/layersRegistrator';
import { postToURLSingle } from '../../../../../api/postToURLSingle';
import { useDispatch, useSelector } from 'react-redux';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { UploadFile } from 'antd/lib/upload/interface';
import { AnyObject } from 'types/enums/general/general.model';
import { RequestPath } from '../../../../../types/enums/routes/request/RequestPath';

const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;

const { LocalSpan, StartUploadButton, LocalFileContent, LocalFileItem, LocalModal, LocalFileItemFullLine } = Styled;

interface IData extends UploadFile {
  url?: string;
  formData: FormData;
  additionalData: string;
  size: number;
}

interface IUploadModalProps {
  pageMenuKey: string;
  id?: string;
  isOpenModal: boolean;
  setOpenModal: (status: boolean) => void;
  refreshData?: (newUpdateURLResponse: AnyObject) => void;
  updateAsyncData?: () => void;
}

const ReportTypes = _.keys(getYearsChoice()).map((key) => {
  const value = getYearsChoice()[key];
  return <Option value={key}>{value}</Option>;
});

export const TMCUploadModal = ({
  pageMenuKey,
  id,
  isOpenModal,
  setOpenModal,
  refreshData,
  updateAsyncData,
}: PropsWithChildren<IUploadModalProps>) => {
  const dispatch = useDispatch();
  const directoryHistoryUrl = useSelector((state: AnyObject) => state?.directoryHistory[0]?.url);
  const [fileList, setFileList] = useState<IData[]>([]);
  const [uploadStatus, setUploadStatus] = useState<any[]>([]);
  const units = useMemo(() => ['байт', 'килобайт', 'мегабайт', 'гигабайт', 'TB', 'PB', 'EB', 'ZB', 'YB'], []);

  // Определение на каком справочнике добавляем фаил
  const pageDirectory = pageMenuKey ?? directoryHistoryUrl;

  const niceBytes = useCallback(
    (x) => {
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    },
    [units]
  );
  const uploadSettings = useMemo(() => {
    return {
      onRemove: (file: IData) => {
        const removedItem = fileList.filter((x: AnyObject) => x?.uid !== file?.uid);
        const newStatus = uploadStatus.filter((x: AnyObject) => file.uid !== x.uid);
        setUploadStatus(newStatus);
        setFileList(removedItem);
        return { fileList: removedItem };
      },
      beforeUpload: (file: IData) => {
        const MAX_FILE_SIZE = 51000000;
        if (file.size > MAX_FILE_SIZE) {
          message.error(`${file.name} вес файла не должен превышать ${niceBytes(MAX_FILE_SIZE)}`);
          return;
        }
        setUploadStatus((prevState) => [...prevState, { uid: file.uid, status: null }]);
        const duplicatedFiles = fileList.filter((x) => x.name === file.name);
        setFileList((prevState) => {
          return [...prevState.filter((x) => !duplicatedFiles.includes(x)), file];
        });
        return false;
      },
      name: 'file',
      action: basePath + '',
      headers: {},
      showUploadList: false,
      uploading: false,
      multiple: true,
      onChange(info: AnyObject) {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} отправлен на обработку`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} ошибка при загрузке`);
        }
      },
    };
  }, [fileList, niceBytes, uploadStatus]);

  const updateStatus = useCallback(
    (uid, status) => {
      const index = uploadStatus.findIndex((x) => x.uid === uid);
      const copy = [...uploadStatus];
      copy[index].status = status;
      setUploadStatus(copy);
    },
    [uploadStatus]
  );

  const generationData = useCallback(
    (file) => {
      const data: Partial<IData> = { url: undefined, formData: new FormData() };
      const parent_id: string = id ?? '';

      switch (pageDirectory) {
        case '/app/directory/road_complex_type_quality/':
          if (file?.additionalData) {
            data.formData?.append('year_of_quality', file.additionalData);
          }
          data.formData?.append('file', file);
          data.url = basePath + '/roads_quality/file_parser/';
          break;
        case '/app/directory/road_accidents/':
          data.formData?.append('source_file', file);
          data.url = basePath + '/road_accidents/upload_accidents/';
          break;
        case '/app/directory/roads_quality_administration_assignments/':
          data.formData?.append('attached_file', file);
          data.url = basePath + '/roads_quality/administration_assignments_files/';
          break;
        case `/api${RequestPath.brigadePhotos}`:
          data.formData?.append('photo', file);
          data.formData?.append('application', parent_id);
          data.url = pageDirectory.replace('api/', '');
          break;
        case 'files':
          if (file?.additionalData) {
            data.formData?.append('file_name', file.additionalData);
          }
          data.formData?.append('statement', parent_id);
          data.formData?.append('file', file);
          data.url = basePath + RequestPath.permitsIndividualStatementFiles;
          break;
        case RequestPath.brigadeApplications:
          if (file?.additionalData) {
            data.formData?.append('file_name', file.additionalData);
          }
          data.formData?.append('application', parent_id);
          data.formData?.append('photo', file);
          data.url = RequestPath.brigadePhotos;
          break;
        case 'road_works/traffic_management_projects_files/':
          if (file?.additionalData) {
            data.formData?.append('file_name', file.additionalData);
          }
          data.formData?.append('file', file);
          data.formData?.append('traffic_management_project', parent_id);
          data.url = basePath + RequestPath.trafficManagmentFiles;
          break;
        case 'road_works/traffic_organization_schema_files/':
          if (file?.additionalData) {
            data.formData?.append('file_name', file.additionalData);
          }
          data.formData?.append('file', file);
          data.formData?.append('traffic_organization_schema', parent_id);
          data.url = basePath + RequestPath.trafficOrganizationSchemaFiles;
          break;
        default:
          if (file?.additionalData) {
            data.formData?.append('file_name', file.additionalData);
          }
          data.formData?.append('road_cons_object', parent_id);
          data.formData?.append('file', file);
          data.url = basePath + `/${pageDirectory}`;
          break;
      }
      return data;
    },
    [id, pageDirectory]
  );

  const startUpload = useCallback(() => {
    if (!fileList?.length) {
      message.error(`Отсутствуют файлы`);
    }
    fileList.forEach((file) => {
      const data = generationData(file);
      updateStatus(file.uid, 'pending');
      (async () => {
        try {
          const response = await postToURLSingle.sendFile(data.url, data.formData);
          const newUpdateURLResponse = {
            ...response,
            photo: `${process.env.REACT_APP_HOST_URL}${response.photo}`,
          };
          const status = newUpdateURLResponse ? 'ok' : 'reject';
          updateStatus(file.uid, status);
          if (uploadStatus.every((file) => file.status === 'ok')) {
            setOpenModal(false);
            setFileList([]);
            if (refreshData) setTimeout(() => refreshData(newUpdateURLResponse), 500);
          }
        } catch (e) {
          if (_.isObject(e) && e?.newUpdateURLResponse && _.isObject(e.newUpdateURLResponse)) {
            const message = createAlertMessage(e);
            dispatch(
              notificationActions.setGlobalAlertData({
                status: ActiveStatus.active,
                type: PromptType.error,
                title: 'Ошибка',
                message: message,
              })
            );
          }
        } finally {
          setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 9000);
          if (updateAsyncData) {
            updateAsyncData();
          }
        }
      })();
    });
  }, [dispatch, fileList, generationData, refreshData, setOpenModal, updateAsyncData, updateStatus, uploadStatus]);

  const uploadItemsContent = useMemo(
    () =>
      fileList?.map((item) => {
        const status = uploadStatus.find((x) => x.uid === item.uid);
        const className = status?.status ? status.status : '';
        const LocalFileContainer = (
          <>
            <Button iconSize={{ width: '18px', height: '18px' }}>
              <UploadFileItemIcon style={{ color: '#6B8AE4' }} />
            </Button>
            <p>{item.name}</p>
            <Button
              iconSize={{ width: '20px', height: '20px' }}
              icon={<DeleteIcon style={{ color: '#EB5757' }} />}
              onClick={() => uploadSettings.onRemove(item)}
            />
          </>
        );
        switch (pageDirectory) {
          case '/app/directory/road_complex_type_quality/':
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LocalFileItem key={_.uniqueId()} className={className}>
                  {LocalFileContainer}
                </LocalFileItem>
                <LocalFileItem>
                  <Select onChange={(value: string) => (item.additionalData = value)} style={{ width: 200 }}>
                    {ReportTypes}
                  </Select>
                </LocalFileItem>
              </div>
            );
          case 'files':
          case 'roads/road_marking_line_files/':
          case 'roads/road_marking_polygon_files/':
          case 'roads/road_marking_point_files/':
          case 'road_constructions/traffic_light_files/':
          case 'road_constructions/road_sign_files/':
          case 'public_transport/bus_stop_files/':
          case 'road_works/traffic_management_projects/':
          case 'road_works/traffic_management_projects_files/':
          case 'road_works/traffic_organization_schema_files/':
            item.additionalData = item.name;
            return (
              <div>
                <LocalFileItemFullLine key={_.uniqueId()} className={className}>
                  {LocalFileContainer}
                </LocalFileItemFullLine>
                <LocalFileItemFullLine>
                  <TextArea
                    defaultValue={item.additionalData}
                    onChange={({ target: { value } }) => (item.additionalData = value)}
                    placeholder="Название файла"
                  />
                </LocalFileItemFullLine>
              </div>
            );
          default:
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <LocalFileItemFullLine key={_.uniqueId()} className={className}>
                  {LocalFileContainer}
                </LocalFileItemFullLine>
              </div>
            );
        }
      }),
    [fileList, pageDirectory, uploadSettings, uploadStatus]
  );

  const hideModal = useCallback(() => setOpenModal(false), [setOpenModal]);

  return (
    <LocalModal
      title="Загрузка файлов"
      visible={isOpenModal}
      onOk={hideModal}
      onCancel={hideModal}
      centered={true}
      footer={
        <StartUploadButton>
          <Button className={'shitTrader'} width={'100%'} onClick={startUpload}>
            <p>Загрузить в справочник</p>
          </Button>
        </StartUploadButton>
      }
    >
      {
        // @ts-ignore
        <Dragger {...uploadSettings}>
          <p className="ant-upload-drag-icon">
            <DropUploadIcon />
          </p>
          <p className="ant-upload-hint">Перетащите ваши файлы сюда</p>
          <p className="ant-upload-hint">
            Или <LocalSpan>Выберите</LocalSpan>
          </p>
        </Dragger>
      }
      <LocalFileContent>{uploadItemsContent}</LocalFileContent>
    </LocalModal>
  );
};
