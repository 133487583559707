import React from 'react';

import ButtonExtensions from './parts/Extension/ButtonExtensions';
import { Styled } from './ExtensionData.styled';
import { CollapsableItem } from '../../../../../../../../UI/common/components/CollapsableItem/CollapsableItem';
import { reportsActions } from '../../../../../../../../store/reducers/reports/reports.actions';
import { useDispatch } from 'react-redux';

const { LocalMainWrapper } = Styled;

const ExtensionData = () => {
  const dispatch = useDispatch();

  const handleSetExtension = (currentExtensions) => {
    dispatch(reportsActions.setExtension(currentExtensions));
  };

  return (
    <CollapsableItem titleContent={'В каком виде получить отчет?'}>
      <LocalMainWrapper>
        <ButtonExtensions handleSetExtension={handleSetExtension} />
      </LocalMainWrapper>
    </CollapsableItem>
  );
};

export default ExtensionData;
