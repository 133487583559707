import React, { PropsWithChildren } from 'react';

import { Modal } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

const { confirm } = Modal;

interface IShowConfirm {
  newSubmitData: any;
  scoreMode: IOPWDirectory;
  mainData: any;
  setMainData: (data: any) => void;
  setData: (data: any) => void;
  history: any;
  location: any;
}

export const showConfirm = ({
  newSubmitData,
  scoreMode,
  mainData,
  setMainData,
  setData,
  history,
  location,
}: PropsWithChildren<IShowConfirm>) => {
  if (mainData) return;
  confirm({
    title:
      scoreMode === IOPWDirectory.write_off_acts ? 'Желаете создать акт уничтожения?' : 'Желаете создать акт списания?',
    icon: <QuestionCircleOutlined />,
    onOk() {
      setMainData(newSubmitData);
      scoreMode === IOPWDirectory.write_off_acts
        ? history.push(location.pathname.replace(IOPWDirectory.write_off_acts, IOPWDirectory.destruction_acts))
        : history.push(location.pathname.replace(IOPWDirectory.destruction_acts, IOPWDirectory.write_off_acts));
      setData(newSubmitData);
    },
  });
};
