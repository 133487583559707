import { useCallback, useState } from 'react';
import { Detection, DetectionsService, ValidationStatusEnum } from '../../../../generated/api/api';

interface UseModeratorRequestsConfig {
  onNetworkError?: (error: any) => void;
}

export const useModeratorRequests = ({ onNetworkError }: UseModeratorRequestsConfig) => {
  const [isFetching, setIsFetching] = useState(false);

  const approveDetectionRequest = useCallback(
    async (detection: Detection) => {
      try {
        setIsFetching(true);
        return await DetectionsService.detectionsUpdate({
          id: detection.id,
          body: { ...detection, validation_status: ValidationStatusEnum.VALID_DETECTION },
        });
      } catch (e) {
        onNetworkError && onNetworkError(e);
      } finally {
        setIsFetching(false);
      }
      return null;
    },
    [onNetworkError]
  );

  const uploadDetectionListRequest = useCallback(async () => {
    try {
      setIsFetching(true);
      const data = await DetectionsService.detectionsList({ hasImage: true, validationStatus: 'AWAITING_VALIDATION' });
      return (data as any).results as Detection[];
    } catch (e) {
      onNetworkError && onNetworkError(e);
    } finally {
      setIsFetching(false);
    }
    return [] as Detection[];
  }, [onNetworkError]);

  const declineDetectionRequest = useCallback(
    async (detection: Detection) => {
      try {
        setIsFetching(true);
        return await DetectionsService.detectionsUpdate({
          id: detection.id,
          body: { ...detection, validation_status: ValidationStatusEnum.INVALID_DETECTION },
        });
      } catch (e) {
        onNetworkError && onNetworkError(e);
      } finally {
        setIsFetching(false);
      }
      return null;
    },
    [onNetworkError]
  );

  return { approveDetectionRequest, declineDetectionRequest, uploadDetectionListRequest, isFetching };
};
