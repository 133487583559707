import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';
import { Layers } from 'types/enums/map/layers/Layers';
import { relatedLayerData } from 'registrators/map/layers/description/relatedData';
import { layerSchemas } from 'registrators/map/layers/description/layerSchemaPresets';
import { layerDataAccessors } from 'registrators/map/layers/description/dataAccessors';
import { ekbLayerCards } from 'registrators/map/layers/description/cards/ekb';
import MonitoringUDSItemSelection from 'layers/SelectedObjectsRepresentations/MonitoringUDSSelection/MonitoringUDSItemSelection';
import { layerSettings } from '../description/settings';
import { projectLayerGroups } from '../description/layerGroups';

export class MonitoringUDSLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.monitoringUds,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.MonitoringUDSDataAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.MonitoringUDS()],
      layerGroup: new projectLayerGroups.MsdidiLayerGroup(),
      card: new ekbLayerCards.MonitoringUDSCard(),
      customSettings: [new layerSettings.IconSizeScale()],
      layerSchema: new layerSchemas.ekbLayersSchema.MonitoringUDSLayerSchema(),
      selectedObjectPrototype: MonitoringUDSItemSelection,
    });
  }
}
