export const PRESET_COLORS_FOR_ROAD_PICKER = [
  '#2F80ED',
  '#D0021B',
  '#F5A623',
  '#7ED321',
  '#9013FE',
  '#FFFFFF',
  '#01FFDC',
  '#FFEF00',
];
