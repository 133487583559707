import styled from 'styled-components';

const LocalModelTSWrapper = styled.div`
  width: 100%;
  padding-bottom: 30px;
  & .ant-select-selector {
    width: 100%;
    height: 60px;
    box-shadow: none;
    border-radius: 8px;
    background: ${(props) => props.theme.colors.defaultLight};
    color: ${(props) => props.theme.colors.paragraph};
    border: none;
    &:hover {
      border: 2px solid ${(props) => props.theme.colors.primary} !important;
    }
    &:focus {
      border: 2px solid ${(props) => props.theme.colors.primary} !important;
    }
  }
  & .ant-select-arrow .ant-select-arrow-icon svg {
    color: ${(props) => props.theme.colors.primary};
    width: 9px;
    height: 6px;
  }

  & .ant-select-selection-selected-value {
    position: absolute;
    top: 50%;
    color: #929fb5;
  }
`;

const LocalSelectedModelTS = styled.div``;

const LocalSelectedWrapperGRZ = styled.div`
  padding-top: 30px;
`;

export const Styled = {
  LocalModelTSWrapper,
  LocalSelectedWrapperGRZ,
  LocalSelectedModelTS,
};
