import { RoadConstructionsService } from 'generated/api/api';
import React, { useCallback } from 'react';
import { DSelector } from 'services/map/Dselector/DSelector';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { MarkerSizeMode } from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { MapMarkerProps } from './model/MapMarker.model';
import { MapMarkerStyles } from './styles/MapMarker.styles';
import { useDispatch } from 'react-redux';
import { forageStore } from 'services/axiosInstances/axiosSecured';
import { Layers } from 'types/enums/map/layers/Layers';

const MapMarker = ({ marker, onMarkerMove }: MapMarkerProps) => {
  const dispatch = useDispatch();
  const { markerPosition, clickInfo, markerSizeMode, type } = marker;
  const coordinates = markerPosition ?? clickInfo?.coordinate;
  const object = clickInfo?.object;
  const sizeMode = markerSizeMode;
  const [longitude, latitude] = coordinates;

  const onMarkerMoveEnd = useCallback(
    async ({ lngLat }) => {
      const custom_position = DSelector.getPointString(lngLat);
      const { id, ...anyFields } = object;
      const newMarkerObject = { id, body: { ...anyFields, custom_position } };
      const response = await RoadConstructionsService.roadConstructionsRoadSignGroupsUpdate(newMarkerObject);
      await forageStore.clear();
      if (response) {
        dispatch(mapboxDataActions.replaceRelatedDataByKey(type, Layers.roadsSchema, response));
      }
      onMarkerMove([lngLat[0], lngLat[1]]);
    },
    [dispatch, object, onMarkerMove, type]
  );

  const isSizeModeDrag = sizeMode === MarkerSizeMode.drag;

  return (
    <MapMarkerStyles.MapMarkerContainer
      longitude={longitude}
      latitude={latitude}
      draggable={isSizeModeDrag}
      captureDrag={isSizeModeDrag}
      onDragEnd={onMarkerMoveEnd}
      captureScroll
      captureClick
    >
      {marker.component}
    </MapMarkerStyles.MapMarkerContainer>
  );
};

export default MapMarker;
