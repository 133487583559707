import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { Styled } from './PrepareScrollableInfoCard.styled';
import { CloseIcon } from 'UI/custom/icons/actionIcons/CloseIcon';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import MonitoringUDSInfo from './MonitoringUDSSelection/MonitoringUDSInfo/MonitoringUDSInfo';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';

interface IDefectText {
  point: Array<number>;
  time: string;
  violationType: string;
}

interface MonitoringUDSInfoCardProps {
  defectName: string;
  handleCloseCard: () => void;
  title: string;
  listOfDescriptions: Array<IDescriptions>;
  handleEditObject: () => void;
  handleRejectDetection: (comment?: string) => void;
  image: string;
  bbox?: Array<number>;
  defectText: IDefectText;
}

const {
  LocalHeaderWrapper,
  ExtendedBtnClose,
  LocalHeaderTitle,
  HeaderTop,
  FooterButtonWrap,
  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalFooter,
  StyledModalTextArea,
  StyledModalConfirmedTitle,
  StyledModalConfirmedText,
  StyledModalButtons,
} = Styled;

const MonitoringUDSInfoCard: FC<MonitoringUDSInfoCardProps> = ({
  handleCloseCard,
  title,
  listOfDescriptions,
  handleEditObject,
  handleRejectDetection,
  image,
  defectName,
  bbox,
  defectText,
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [comment, setComment] = useState<string>();
  const [confirmedModal, setConfirmedModal] = useState<boolean>(false);

  const onChangeTextArea = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalVisible(false);
    setComment(undefined);
    setConfirmedModal(false);
  }, []);

  const rejectDetection = useCallback(() => {
    handleRejectDetection(comment);
    onCloseModal();
  }, [comment, handleRejectDetection, onCloseModal]);

  const headerContent = useMemo(
    () => (
      <LocalHeaderWrapper>
        <HeaderTop>
          <LocalHeaderTitle>{title}</LocalHeaderTitle>
          <ExtendedBtnClose onClick={handleCloseCard} icon={<CloseIcon />} type={ButtonType.unboundedPrimary} p={0} />
        </HeaderTop>
      </LocalHeaderWrapper>
    ),
    [handleCloseCard, title]
  );

  const footerContent = useMemo(
    () => (
      <FooterButtonWrap>
        <Button onClick={handleEditObject} type={ButtonType.primaryLC}>
          Принять
        </Button>
        <Button onClick={() => setModalVisible(true)} type={ButtonType.dangerReverseTranslucent}>
          Отклонить
        </Button>
      </FooterButtonWrap>
    ),
    [handleEditObject]
  );

  const rejectModal = useMemo(() => {
    const widthModal = !confirmedModal ? 338 : 272;

    return (
      <StyledModal
        visible={modalVisible}
        closable={!confirmedModal}
        zIndex={5001}
        footer={null}
        width={widthModal}
        onCancel={onCloseModal}
        centered
      >
        {!confirmedModal && (
          <>
            <StyledModalHeader>Отклонение дефекта</StyledModalHeader>
            <StyledModalBody>
              <StyledModalTextArea placeholder={'Комментарий'} value={comment} onChange={onChangeTextArea} />
            </StyledModalBody>
            <StyledModalFooter>
              <Button type={ButtonType.primary} width={'100%'} onClick={() => setConfirmedModal(true)}>
                Отклонить
              </Button>
            </StyledModalFooter>
          </>
        )}
        {confirmedModal && (
          <StyledModalBody>
            <StyledModalConfirmedTitle>Отклонение дефекта</StyledModalConfirmedTitle>
            <StyledModalConfirmedText>Вы действительно хотите отклонить дефект?</StyledModalConfirmedText>
            <StyledModalButtons>
              <Button type={ButtonType.dangerReverse} onClick={rejectDetection}>
                Отклонить
              </Button>
              <Button type={ButtonType.dangerReverseTranslucent} onClick={onCloseModal}>
                Отмена
              </Button>
            </StyledModalButtons>
          </StyledModalBody>
        )}
      </StyledModal>
    );
  }, [comment, confirmedModal, modalVisible, onChangeTextArea, onCloseModal, rejectDetection]);

  return useMemo(
    () => (
      <>
        {rejectModal}
        <MonitoringUDSInfo
          bbox={bbox}
          defectText={defectText}
          defectName={defectName}
          headerContent={headerContent}
          footerContent={footerContent}
          listOfDescriptions={listOfDescriptions}
          image={image}
        />
      </>
    ),
    [rejectModal, bbox, defectText, defectName, headerContent, footerContent, listOfDescriptions, image]
  );
};

export default MonitoringUDSInfoCard;
