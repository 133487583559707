import React, { useContext, useMemo } from 'react';
import LayersGallery from './LayersGallery';
import { useSelector } from 'react-redux';
import { LayersItemsContext } from '../../../../../LayersMenu';
import { StateModel } from 'store/reducers';
import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';
import { Styled } from './LayersGallery.styled';
import { layersItem } from './Types/LayersGalleryTypes';
import { groupingLayerGalleryItemsWithSort } from './Utils/GroupingLayerGalleryItems';

const { LayersGalleryScrollbar } = Styled;

const LayersGalleryContainer = () => {
  const SelectedLayers: LayerPrototype[] | [] = useSelector((state: StateModel) => state?.mapboxLayers);
  const userLayerPermissions = useSelector((state: StateModel) => state.auth.permissions.layers);

  const layersItems = useContext<layersItem[] | null>(LayersItemsContext);

  const listOfSelectedLayersNames = useMemo(
    () => SelectedLayers.map((item: { name: string }) => item.name),
    [SelectedLayers]
  );

  const sortedGroupOfLayersGalleryItemsWithoutSelected = useMemo(() => {
    const layersGalleryItemsWithoutSelected =
      layersItems?.reduce<layersItem[]>((acc, layer) => {
        const isItemLayerSelected = listOfSelectedLayersNames.includes(layer.name);
        const isUserHaveRightForCard = layer.card.checkUserPermissions(userLayerPermissions);
        if (!isItemLayerSelected && isUserHaveRightForCard) acc.push(layer);
        return acc;
      }, []) ?? [];
    return groupingLayerGalleryItemsWithSort(layersGalleryItemsWithoutSelected);
  }, [layersItems, listOfSelectedLayersNames, userLayerPermissions]);

  return (
    <LayersGalleryScrollbar>
      <LayersGallery sortedGroupOfLayersGalleryItemsWithoutSelected={sortedGroupOfLayersGalleryItemsWithoutSelected} />
    </LayersGalleryScrollbar>
  );
};

export default LayersGalleryContainer;
