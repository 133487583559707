import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { CamerasAndDetectorsTypeEnum } from '../../../types/enums/map/SelectedItemTypeEnum';

const CamerasAndDetectorsItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'cameras_and_detectors');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.transportDetectors}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Наименование',
    value: data.name ?? 'Не указан',
  });

  Descriptions.push({
    key: 'Тип',
    value: CamerasAndDetectorsTypeEnum[data.detector_type] ?? 'Не указан',
  });

  Descriptions.push({
    key: 'План по установке',
    value: data.installation_plan_name ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об объекте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

CamerasAndDetectorsItemSelection.propTypes = {};

export default CamerasAndDetectorsItemSelection;
