import { CellClassParams } from 'ag-grid-community';
import { theme } from 'styles/themes/rootTheme';
import { StatusF30Enum } from 'generated/api/api';

export const functionCellStyle = (params: CellClassParams) => {
  if (params.data && params.colDef?.field === 'status') {
    const status = params.data.status as StatusF30Enum;
    const color = theme.status?.[status];

    return { color };
  }

  return null;
};
