import React, { Component } from 'react';
import { Tag } from 'antd';

class BusScheduleArrayRenderer extends Component {
  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
  }

  invokeParentMethod() {}

  render() {
    const field = this.props.colDef.additionalData;
    const data = this.props.data[field];
    const tags =
      data?.map((element) => {
        const color = element.is_disabled_person ? 'green' : 'gold';
        return (
          <Tag color={color} style={{ margin: '0.1rem' }}>
            {element.time.split('.')[0]}
          </Tag>
        );
      }) ?? null;

    return <div style={{ display: 'flex', flexWrap: 'wrap', margin: '-0.1rem' }}>{tags}</div>;
  }
}

export default BusScheduleArrayRenderer;
