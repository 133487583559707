import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

import { IFormProps } from '../interfacesCargoTransport/interfacesCargoTransport';
import { provideInput } from './provideInput';

/**
 *
 * @param groupFromModel model object recieved from options response
 * @param fields list of fields to drawS
 * @param instance single instance of object with values or empty for creating instance
 * @param referenceToForm link to Antd.Form
 * @returns {[]} containing input fields in group or single input field with wrapper
 */
export const createInputs = (
  groupFromModel: ModelResponse,
  fields: string[],
  instance: any[],
  referenceToForm: IFormProps
) => {
  const group = groupFromModel?.scheme;
  const result = [];
  if (group) {
    for (const field of fields) {
      const description = group[field];
      const element = provideInput(instance, description, field, referenceToForm);
      result.push(element);
    }
  }

  return result;
};
