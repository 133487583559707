import * as axios from 'axios';
import { doCleanup } from '../application/auth/doCleanup';
import { serviceOptions } from '../../generated/api/api';
import { isExpired } from 'react-jwt';
import { authApi } from 'api/authApi';
import { cancelToken } from './axiosCancelToken';
import { setup } from 'axios-cache-adapter';
import localforage from 'localforage';
import { CURRENT_APP_URL } from 'types/constants/constants';
import { axiosCacheExcludePaths } from './axiosCacheExcludePaths';

interface IfailedRequestQueue {
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
}

export const forageStore: LocalForage = localforage.createInstance({
  driver: [localforage.INDEXEDDB],
  name: 'app-cache',
});

/**
 * определение шаблона для аксиоса, задание базового урла через .ЕНВ
 */
// Настроено обновление кеша через 5 секунд для предотвращения резких повторных запросов
// const millisecondInMinutes = 60000;
const millisecondInMinutes = 500;
const minutesValue = 10;
const cacheTime = minutesValue * millisecondInMinutes;

/*
 * Удалил создание ключа для кэша запроса, тк ключом был url, запросы кешировались и разные методы отдавали одни и те же
 * данные, по умолчанию ключ создается из req.url + serializeQuery(req.params) и это позволяет кешу различать методы
 */

const axiosTemplate = setup({
  baseURL: CURRENT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  cache: {
    maxAge: cacheTime,
    key: (req) => `${req.method} + ${req.url} + ${JSON.stringify(req.params)}`,
    readOnError: true,
    clearOnStale: true,
    exclude: {
      paths: axiosCacheExcludePaths,
      methods: ['post', 'patch', 'put', 'delete'],
      query: false,
    },
    store: forageStore,
  },
});

// переменная очереди промисов из запросов выдавших ошибку
let failedRequestQueue: IfailedRequestQueue[] = [];
// переменная проверки был ли уже выслан запрос за авт. токеном. по умолчанию фолс, но запрос может быть выслан
// из Application.js - проверки для этого - ниже
let isRefreshRequestSend = false;

axiosTemplate.interceptors.request.use(
  (config) => {
    if (!config.headers.common.Accept.includes(config.headers.common['Content-Type']))
      config.headers['Content-Type'] = 'application/json';
    // если в локал сторе есть авторизационный токен, добавит его в хэдэр запроса
    if (localStorage.getItem('token_access') !== 'undefined') {
      if (config?.url?.includes('password/reset/confirm/')) {
        return config;
      } else if (config?.url?.includes('token/refresh')) {
        isRefreshRequestSend = true;
        return config;
      }
      config.headers.Authorization = `Bearer ${localStorage.getItem('token_access')}`;
      config.cancelToken = cancelToken.getSource().token;
      return config;
    } else {
      doCleanup();
      sessionStorage.setItem('redirect_to', window.location.hash);
      window.location.hash = '/guest/login';
      return Promise.reject('unauthorised');
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const successHandler = (response: axios.AxiosResponse<any>) => {
  //TODO success messages Тех-долг-FRONTEND #5810
  return response;
};

export const failedRequestQueueOperations = (error: any, token: any = null) => {
  // выполняется только если в очереди есть промисы
  if (failedRequestQueue.length && localStorage.getItem('token_access') && localStorage.getItem('token_access')) {
    // вызывает для каждого промиса соответствующий метод
    failedRequestQueue.forEach((deferredRequest) => {
      if (error) {
        isRefreshRequestSend = false;
        deferredRequest.reject(error);
      } else {
        isRefreshRequestSend = false;
        deferredRequest.resolve(token);
      }
    });
    failedRequestQueue = [];
  }
};

const errorHandler = (error: any) => {
  const unauthorizedStatusCode = 401;
  if (axios.default.isCancel(error)) {
    return Promise.reject();
  }
  if (!localStorage.getItem('token_access') || !localStorage.getItem('token_refresh')) {
    return Promise.reject(error);
  }
  if (error?.response?.status === unauthorizedStatusCode) {
    const initialRequest = error.config;
    if (isRefreshRequestSend && !isExpired(localStorage.getItem('token_access')!)) {
      return new Promise((resolve, reject) => {
        failedRequestQueue.push({ resolve, reject });
      })
        .then(() => {
          initialRequest.Authorization = `Bearer ${localStorage.getItem('token_access')}`;
          return securedAxiosInstance(initialRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    } else if (isRefreshRequestSend && isExpired(localStorage.getItem('token_access')!)) {
      initialRequest.Authorization = `Bearer ${localStorage.getItem('token_access')}`;
      return securedAxiosInstance(initialRequest);
    }

    isRefreshRequestSend = true;

    return new Promise((resolve, reject) => {
      authApi
        .refreshToken()
        .then((response: any) => {
          isRefreshRequestSend = false;
          // после ответа переключаем переменную выполненности запроса и сэтаем новый авт. токен в локал стор
          localStorage.setItem('token_access', response.data.access);
          // в изначальный запрос добавляем новый авт. токен
          initialRequest.Authorization = `Bearer ${localStorage.getItem('token_access')}`;
          // резолвим изначальный запрос
          failedRequestQueueOperations(null, localStorage.getItem('token_access'));
          resolve(securedAxiosInstance(initialRequest));
        })
        .catch((e) => {
          isRefreshRequestSend = false;
          // в случае ошибки очищаем очередь запросов, приложение, и редиректимся на страницу логина
          failedRequestQueueOperations(e, null);
          doCleanup();
          sessionStorage.setItem('redirect_to', window.location.hash);
          window.location.hash = '/guest/login';
          reject(e);
        });
    });
  } else {
    // если ошибка не 401 выводим ошибку в консоль
    throw error;
  }
};

axiosTemplate.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const securedAxiosInstance = axiosTemplate;

export function initAxios() {
  serviceOptions.axios = securedAxiosInstance;
}

export default securedAxiosInstance;
