import { delay, put, all, takeEvery, retry } from 'redux-saga/effects';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { appActions } from '../../reducers/global/app/app.actions';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import { mapboxDataActions } from '../../reducers/map/mapboxData/mapboxData.actions';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';

const INIT_GET_RELATED_DATA_FOR_LAYER = 'INIT_GET_RELATED_DATA_FOR_LAYER';

const maxRetries = 3;
const delayBetweenRetries = 3000;

export const initGetRelatedDataForLayer = (Layer) => ({
  type: INIT_GET_RELATED_DATA_FOR_LAYER,
  payload: {
    Layer,
  },
});

/**
 * Получает модель (список ключей объектов приходящих с бэка) и "дату" - сами объекты в полном колличестве
 * @param dataSource
 */
function* getDataAndModelForDataSource(dataSource) {
  try {
    const model = yield retry(maxRetries, delayBetweenRetries, dataSource.uploadModel);
    const data = yield retry(maxRetries, delayBetweenRetries, dataSource.uploadData);
    return {
      model: model,
      resultData: data?.results ?? data,
      // имя задаётся в инстансах RelatedDataPrototype.js
      dataSourceName: dataSource.getName(),
    };
  } catch (error) {
    console.error('Ошибка, данные подслоя не загружены!', error);
  } // Добавил блок try/catch для предотвращения падения внутренней ошибки саги
}

function* getRelatedDataForLayerSaga(action) {
  // запускает лоадер
  yield put(appActions.startAppFetching());
  try {
    // Получает описаную relatedData слоя
    const { Layer } = action.payload;
    const layerLabel = Layer?.card?.label;
    // Получает схему объектов, отрисовываемых на этом слое
    const layerRelatedData = Layer.getRelatedData();
    if (layerRelatedData && Array.isArray(layerRelatedData)) {
      // Модель и дата присваиваются в dataSourcesCollection
      const dataSourcesCollection = layerRelatedData.map((dataSource) => getDataAndModelForDataSource(dataSource));
      const results = yield all(dataSourcesCollection);
      const relatedDatas = [];
      const errors = results
        ?.filter((el) => {
          if (!el.errorLabel) {
            relatedDatas.push(el);
            return false;
          }
          return true;
        })
        .map((el) => el.errorLabel);
      if (relatedDatas && Array.isArray(relatedDatas)) {
        const arrayOfRelatedData = [];
        // Задаёт ключи родительского объекта
        for (let item of relatedDatas) {
          arrayOfRelatedData.push({
            parentKey: Layer.getName(),
            childKey: item?.dataSourceName,
            data: item?.resultData,
          });
          arrayOfRelatedData.push({
            parentKey: Layer.getName(),
            childKey: item?.dataSourceName + '_model',
            data: item?.model?.scheme,
          });
        }
        //Формирование стэйта
        yield put(mapboxDataActions.addMapboxMultipleRelatedData(arrayOfRelatedData));
        if (errors.length && layerLabel) {
          yield put(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: `Ошибка`,
              message: `Недостаточно прав для доступа к слою ${layerLabel}. Обратитесь к администратору!`,
            })
          );
        }
      }
    } else {
      console.warn(`Layer ${Layer.getName()} doesn't have related data or related data is not an array`);
      yield put(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.warning,
          title: 'Warning',
          message: `Layer ${Layer.getName()} doesn't have related data or related data is not an array`,
        })
      );
    }
  } catch (e) {
    console.error(e);
    yield put(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message: e?.response?.data?.messages?.[0]?.message ?? e?.message ?? 'При загрузке данных слоя произошла ошибка',
      })
    );
  } finally {
    yield put(appActions.endAppFetching());
    yield delay(AlertDelay.saga);
    yield put(notificationActions.clearGlobalAlertData());
  }
}

export function* watchGetRelatedDataForLayerSaga() {
  yield takeEvery(INIT_GET_RELATED_DATA_FOR_LAYER, getRelatedDataForLayerSaga);
}
