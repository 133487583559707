import React, { FC, useMemo } from 'react';
import NumberWidget from 'components/Application/BaseModules/HomePage/components/WidgetsGrid/components/NumberWidget/NumberWidget';
import { CameraWidget, CameraWidgetDataResults } from 'generated/api/api';
import {
  NumberWidgetColumns,
  NumberWidgetData,
} from 'components/Application/BaseModules/HomePage/components/WidgetsGrid/components/NumberWidget/model/NumberWidget.model';

interface CameraNumberWidgetProps {
  widgetDescription: CameraWidget;
  onChangeWidget: () => void;
  onDeleteWidget: () => void;
  data: Array<CameraWidgetDataResults>;
}

export const CameraNumberWidget: FC<CameraNumberWidgetProps> = ({
  widgetDescription,
  onChangeWidget,
  onDeleteWidget,
  data,
}) => {
  const columns: Array<NumberWidgetColumns> = useMemo(
    () => [
      {
        title: 'Наименование',
        dataIndex: 'name',
      },
      {
        title: 'Данные',
        dataIndex: 'result',
      },
    ],
    []
  );

  const formatData: Array<NumberWidgetData> = useMemo(() => {
    return widgetDescription.widget_data.map((el) => {
      const currentData = data.find((dataEl) => el.id === dataEl.widget_data_id);

      return {
        name: el.data_name,
        result: currentData?.data?.[0]?.result ?? 0,
      };
    });
  }, [data, widgetDescription]);

  return (
    <NumberWidget
      name={widgetDescription.name}
      onChangeWidget={onChangeWidget}
      onDeleteWidget={onDeleteWidget}
      columns={columns}
      data={formatData}
    />
  );
};

export default CameraNumberWidget;
