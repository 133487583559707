export interface DirectoryElement {
  value: string;
  isChecked: boolean;
}

export interface ParentDirectory extends DirectoryElement {
  data: { [key: string]: number[] };
}

export interface RoleElement extends DirectoryElement {
  id?: number | number[];
}

export interface PermissionElement extends RoleElement {
  permission?: string;
}

export interface GetDirectoryModel {
  [key: string]: {
    id: number;
    model: string;
    name: string;
  }[];
}

export interface GetLayersModel {
  [key: string]: {
    [key: string]: number[];
  };
}

export interface DirectoryDataModel {
  get_directory?: GetDirectoryModel;
  get_layers?: GetLayersModel[];
  get_reports?: any[];
}

export interface DirectoryDataFilteredModel {
  [key: string]: RoleElement[];
}

export interface ChangeDirectoryDataFilteredsAction {
  data: RoleElement[];
  pageMenuKey: string;
}

export interface ReplaceFieldByKeyAction {
  data: ReplaceDataTypes;
  field: RolesDirectoryFields;
}

export interface ChangePartArrayDataByKeyAction {
  data: ChangeArrayDataTypes;
  field: RolesDirectoryArrayDataFields;
}

export type ChangeArrayDataTypes = DirectoryElement[] | RoleElement[] | ParentDirectory[] | PermissionElement[];

export type ReplaceDataTypes =
  | ChangeArrayDataTypes
  | number
  | number[]
  | string
  | DirectoryDataModel
  | DirectoryDataFilteredModel;

export enum DirectoryDataFilteredFields {
  directoryDataFiltered = 'directoryDataFiltered',
  clickedRight = 'clickedRight',
}

export enum RolesDirectoryArrayDataFields {
  checkingDirectory = 'checkingDirectory',
  checkedDirectory = 'checkedDirectory',
  checkingMap = 'checkingMap',
  checkedMap = 'checkedMap',
  checkingReports = 'checkingReports',
  checkedReports = 'checkedReports',
  checkedRoles = 'checkedRoles',
  checkedOrganizationsList = 'checkedOrganizationsList',
  checkedDistrictsList = 'checkedDistrictsList',
  rolesList = 'rolesList',
  organizationsList = 'organizationsList',
  districtsList = 'districtsList',
}

export enum RolesDirectoryFields {
  checkingDirectory = 'checkingDirectory',
  checkedDirectory = 'checkedDirectory',
  directoryData = 'directoryData',
  directoryDataFiltered = 'directoryDataFiltered',
  clickedRight = 'clickedRight',
  checkingReports = 'checkingReports',
  checkedReports = 'checkedReports',
  nameRoles = 'nameRoles',
  rolesList = 'rolesList',
  checkedRoles = 'checkedRoles',
  checkingMap = 'checkingMap',
  checkedMap = 'checkedMap',
  organizationsList = 'organizationsList',
  checkedOrganizationsList = 'checkedOrganizationsList',
  districtsList = 'districtsList',
  checkedDistrictsList = 'checkedDistrictsList',
  permissionsMap = 'permissionsMap',
  idForRightDirec = 'idForRightDirec',
  idForRightMap = 'idForRightMap',
  idForRightReports = 'idForRightReports',
  IDRoles = 'IDRoles',
}

export interface RoleState {
  checkingDirectory: DirectoryElement[];
  checkedDirectory: DirectoryElement[];
  directoryData: DirectoryDataModel | RoleElement[];
  directoryDataFiltered: DirectoryDataFilteredModel;
  clickedRight: DirectoryDataFilteredModel;
  checkingReports: RoleElement[];
  checkedReports: RoleElement[];
  nameRoles: string;
  rolesList: RoleElement[];
  checkedRoles: RoleElement[];
  checkingMap: ParentDirectory[];
  checkedMap: ParentDirectory[];
  organizationsList: RoleElement[];
  checkedOrganizationsList: RoleElement[];
  districtsList: RoleElement[];
  checkedDistrictsList: RoleElement[];
  permissionsMap: PermissionElement[];
  idForRightDirec: number[];
  idForRightMap: number[];
  idForRightReports: number[];
  IDRoles?: number;
}
