import { LayerCardPrototype } from '../prototype/LayerCardPrototype';

export class SpeedBumpsCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'ИНД',
      linkToPreview: 'police.svg',
      permissionToShowCard: 'layer_permissions.can_view_ind_layer',
    });
  }
}
