export const brigadeApplicationsMapping = {
  BUS_STOP_APPOINTED: { x: 0, y: 0, width: 128, height: 128 },
  BUS_STOP_APPOINTED_ACTIVE: { x: 0, y: 129, width: 128, height: 128 },
  BUS_STOP_CLOSED: { x: 129, y: 0, width: 128, height: 128 },
  BUS_STOP_CLOSED_ACTIVE: { x: 129, y: 129, width: 128, height: 128 },
  BUS_STOP_CANCELED: { x: 129, y: 0, width: 128, height: 128 },
  BUS_STOP_CANCELED_ACTIVE: { x: 129, y: 129, width: 128, height: 128 },
  BUS_STOP_CONFIRMED: { x: 0, y: 258, width: 128, height: 128 },
  BUS_STOP_CONFIRMED_ACTIVE: { x: 129, y: 258, width: 128, height: 128 },
  BUS_STOP_DONE: { x: 258, y: 0, width: 128, height: 128 },
  BUS_STOP_DONE_ACTIVE: { x: 258, y: 129, width: 128, height: 128 },
  BUS_STOP_IN_WORK: { x: 258, y: 258, width: 128, height: 128 },
  BUS_STOP_IN_WORK_ACTIVE: { x: 0, y: 387, width: 128, height: 128 },
  BUS_STOP_NEW: { x: 129, y: 387, width: 128, height: 128 },
  BUS_STOP_NEW_ACTIVE: { x: 258, y: 387, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_APPOINTED: { x: 0, y: 0, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_APPOINTED_ACTIVE: { x: 0, y: 129, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_CLOSED: { x: 129, y: 0, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_CLOSED_ACTIVE: { x: 129, y: 129, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_CANCELED: { x: 129, y: 0, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_CANCELED_ACTIVE: { x: 129, y: 129, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_CONFIRMED: { x: 0, y: 258, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_CONFIRMED_ACTIVE: { x: 129, y: 258, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_DONE: { x: 258, y: 0, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_DONE_ACTIVE: { x: 258, y: 129, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_IN_WORK: { x: 258, y: 258, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_IN_WORK_ACTIVE: { x: 0, y: 387, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_NEW: { x: 129, y: 387, width: 128, height: 128 },
  BUS_STOP_SCOREBOARD_NEW_ACTIVE: { x: 258, y: 387, width: 128, height: 128 },
  ROAD_MARKING_APPOINTED: { x: 387, y: 0, width: 128, height: 128 },
  ROAD_MARKING_APPOINTED_ACTIVE: { x: 387, y: 129, width: 128, height: 128 },
  ROAD_MARKING_CLOSED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_CLOSED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_CANCELED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_CANCELED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_CONFIRMED: { x: 0, y: 516, width: 128, height: 128 },
  ROAD_MARKING_CONFIRMED_ACTIVE: { x: 129, y: 516, width: 128, height: 128 },
  ROAD_MARKING_DONE: { x: 258, y: 516, width: 128, height: 128 },
  ROAD_MARKING_DONE_ACTIVE: { x: 387, y: 516, width: 128, height: 128 },
  ROAD_MARKING_IN_WORK_ACTIVE: { x: 516, y: 0, width: 128, height: 128 },
  ROAD_MARKING_IN_WORK: { x: 516, y: 129, width: 128, height: 128 },
  ROAD_MARKING_NEW: { x: 516, y: 258, width: 128, height: 128 },
  ROAD_MARKING_NEW_ACTIVE: { x: 516, y: 387, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_APPOINTED: { x: 387, y: 0, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_APPOINTED_ACTIVE: { x: 387, y: 129, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_CLOSED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_CLOSED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_CANCELED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_CANCELED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_CONFIRMED: { x: 0, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_CONFIRMED_ACTIVE: { x: 129, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_DONE: { x: 258, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_DONE_ACTIVE: { x: 387, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_IN_WORK_ACTIVE: { x: 516, y: 0, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_IN_WORK: { x: 516, y: 129, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_NEW: { x: 516, y: 258, width: 128, height: 128 },
  ROAD_MARKING_POLYGON_NEW_ACTIVE: { x: 516, y: 387, width: 128, height: 128 },
  ROAD_MARKING_LINE_APPOINTED: { x: 387, y: 0, width: 128, height: 128 },
  ROAD_MARKING_LINE_APPOINTED_ACTIVE: { x: 387, y: 129, width: 128, height: 128 },
  ROAD_MARKING_LINE_CLOSED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_LINE_CLOSED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_LINE_CANCELED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_LINE_CANCELED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_LINE_CONFIRMED: { x: 0, y: 516, width: 128, height: 128 },
  ROAD_MARKING_LINE_CONFIRMED_ACTIVE: { x: 129, y: 516, width: 128, height: 128 },
  ROAD_MARKING_LINE_DONE: { x: 258, y: 516, width: 128, height: 128 },
  ROAD_MARKING_LINE_DONE_ACTIVE: { x: 387, y: 516, width: 128, height: 128 },
  ROAD_MARKING_LINE_IN_WORK_ACTIVE: { x: 516, y: 0, width: 128, height: 128 },
  ROAD_MARKING_LINE_IN_WORK: { x: 516, y: 129, width: 128, height: 128 },
  ROAD_MARKING_LINE_NEW: { x: 516, y: 258, width: 128, height: 128 },
  ROAD_MARKING_LINE_NEW_ACTIVE: { x: 516, y: 387, width: 128, height: 128 },
  ROAD_MARKING_POINT_APPOINTED: { x: 387, y: 0, width: 128, height: 128 },
  ROAD_MARKING_POINT_APPOINTED_ACTIVE: { x: 387, y: 129, width: 128, height: 128 },
  ROAD_MARKING_POINT_CLOSED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_POINT_CLOSED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_POINT_CANCELED: { x: 387, y: 258, width: 128, height: 128 },
  ROAD_MARKING_POINT_CANCELED_ACTIVE: { x: 387, y: 387, width: 128, height: 128 },
  ROAD_MARKING_POINT_CONFIRMED: { x: 0, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POINT_CONFIRMED_ACTIVE: { x: 129, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POINT_DONE: { x: 258, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POINT_DONE_ACTIVE: { x: 387, y: 516, width: 128, height: 128 },
  ROAD_MARKING_POINT_IN_WORK_ACTIVE: { x: 516, y: 0, width: 128, height: 128 },
  ROAD_MARKING_POINT_IN_WORK: { x: 516, y: 129, width: 128, height: 128 },
  ROAD_MARKING_POINT_NEW: { x: 516, y: 258, width: 128, height: 128 },
  ROAD_MARKING_POINT_NEW_ACTIVE: { x: 516, y: 387, width: 128, height: 128 },
  ROAD_SIGN_APPOINTED: { x: 516, y: 516, width: 128, height: 128 },
  ROAD_SIGN_APPOINTED_ACTIVE: { x: 0, y: 645, width: 128, height: 128 },
  ROAD_SIGN_CLOSED: { x: 129, y: 645, width: 128, height: 128 },
  ROAD_SIGN_CLOSED_ACTIVE: { x: 258, y: 645, width: 128, height: 128 },
  ROAD_SIGN_CANCELED: { x: 129, y: 645, width: 128, height: 128 },
  ROAD_SIGN_CANCELED_ACTIVE: { x: 258, y: 645, width: 128, height: 128 },
  ROAD_SIGN_CONFIRMED: { x: 387, y: 645, width: 128, height: 128 },
  ROAD_SIGN_CONFIRMED_ACTIVE: { x: 516, y: 645, width: 128, height: 128 },
  ROAD_SIGN_DONE: { x: 645, y: 0, width: 128, height: 128 },
  ROAD_SIGN_DONE_ACTIVE: { x: 645, y: 129, width: 128, height: 128 },
  ROAD_SIGN_IN_WORK: { x: 645, y: 258, width: 128, height: 128 },
  ROAD_SIGN_IN_WORK_ACTIVE: { x: 645, y: 387, width: 128, height: 128 },
  ROAD_SIGN_NEW: { x: 645, y: 516, width: 128, height: 128 },
  ROAD_SIGN_NEW_ACTIVE: { x: 645, y: 645, width: 128, height: 128 },
  TL_CONTROLLER_APPOINTED: { x: 0, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_APPOINTED_ACTIVE: { x: 129, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_CLOSED: { x: 258, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_CLOSED_ACTIVE: { x: 387, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_CANCELED: { x: 258, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_CANCELED_ACTIVE: { x: 387, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_CONFIRMED: { x: 516, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_CONFIRMED_ACTIVE: { x: 645, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_DONE: { x: 774, y: 0, width: 128, height: 128 },
  TL_CONTROLLER_DONE_ACTIVE: { x: 774, y: 129, width: 128, height: 128 },
  TL_CONTROLLER_IN_WORK: { x: 774, y: 258, width: 128, height: 128 },
  TL_CONTROLLER_IN_WORK_ACTIVE: { x: 774, y: 387, width: 128, height: 128 },
  TL_CONTROLLER_NEW: { x: 774, y: 516, width: 128, height: 128 },
  TL_CONTROLLER_NEW_ACTIVE: { x: 774, y: 645, width: 128, height: 128 },
  TL_CONTROLLER_WELL_APPOINTED: { x: 0, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_APPOINTED_ACTIVE: { x: 129, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_CLOSED: { x: 258, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_CLOSED_ACTIVE: { x: 387, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_CANCELED: { x: 258, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_CANCELED_ACTIVE: { x: 387, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_CONFIRMED: { x: 516, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_CONFIRMED_ACTIVE: { x: 645, y: 774, width: 128, height: 128 },
  TL_CONTROLLER_WELL_DONE: { x: 774, y: 0, width: 128, height: 128 },
  TL_CONTROLLER_WELL_DONE_ACTIVE: { x: 774, y: 129, width: 128, height: 128 },
  TL_CONTROLLER_WELL_IN_WORK: { x: 774, y: 258, width: 128, height: 128 },
  TL_CONTROLLER_WELL_IN_WORK_ACTIVE: { x: 774, y: 387, width: 128, height: 128 },
  TL_CONTROLLER_WELL_NEW: { x: 774, y: 516, width: 128, height: 128 },
  TL_CONTROLLER_WELL_NEW_ACTIVE: { x: 774, y: 645, width: 128, height: 128 },
};
