import styled from 'styled-components';

const PrintModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 338px;
  border-radius: 16px 16px;
  background-color: ${(props) => props.theme.colors.defaultLighter};
  z-index: 10;
`;

const PrintModalHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px 6px 10px -2px rgba(34, 60, 80, 0.2);
  h1 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 0;
  }
`;

const PrintModalIcon = styled.div`
  position: absolute;
  top: 14px;
  right: 15px;
  cursor: pointer;
`;

const PrintModalBody = styled.div`
  padding: 16px;
  div {
    padding: 8px 16px;
    background: ${(props) => props.theme.colors.defaultLight};
    border-radius: 8px;
    p {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 140%;
      color: #6b8ae4;
    }
    input {
      width: 100%;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      color: #495a82;
      outline: none;
      border: none;
      background: none;
      &::placeholder {
        color: #495a82;
      }
    }
  }
`;

const PrintModalButton = styled.div`
  width: 306px;
  height: 40px;
  padding: 11px 32px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.defaultLight};
  background: #6b8ae4;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
`;

const PrintModalFooter = styled.div`
  width: 100%;
  padding: 16px;
`;

export { PrintModalWrapper, PrintModalHeader, PrintModalIcon, PrintModalBody, PrintModalButton, PrintModalFooter };
