import React, { useState, useMemo, useCallback, useRef, useEffect, FC } from 'react';
import { Select as SelectANTD } from 'antd';
import _ from 'underscore';

import { OptionProps, SelectProps } from './model/SelectProps.model';
import { Styled } from './style/Select.styled';
import { ArrowIcon } from 'UI/custom/icons/actionIcons/ArrowIcon';
import { CheckIcon } from 'UI/custom/icons/actionIcons/CheckIcon';

const { Option } = SelectANTD;

type modeTypes = 'multiple' | 'tags' | undefined;

const { StyledSelect, Placeholder, HelpPlaceholder, MultipleLabel } = Styled;

const Select: FC<SelectProps> = ({
  placeholder,
  value,
  searchPlaceholder,
  disabled,
  showSearch,
  helpPlaceholder,
  options,
  required,
  onChange,
  error,
  allowClear,
  small,
  mode,
}) => {
  const [focused, changeFocus] = useState(false);
  const [inputValue, changeValue] = useState<string | Array<string> | undefined>(value ?? undefined);
  const [isError, changeError] = useState(error);
  const [isSearch, changeSearch] = useState(false);
  // Коэффициент для умножения количества пунктов меню отображаемых в селекте
  const [countOptionsList, setCountOptionsList] = useState(2);
  const [filterOptions, setFilterOptions] = useState<Array<OptionProps>>([]);

  const selectWrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => changeError(error), [error]);
  useEffect(() => changeValue(value ?? undefined), [value]);

  const icon = useMemo(() => (inputValue && !isError ? <CheckIcon /> : <ArrowIcon />), [inputValue, isError]);

  const selectClassName = useMemo(() => {
    let className = [];

    if (disabled) {
      className.push('disabled');
    }

    if (focused) {
      className.push('open');
    }

    if ((Array.isArray(inputValue) && inputValue.length) || (!Array.isArray(inputValue) && !!inputValue)) {
      className.push('selected');
    }

    if (isError) {
      className.push('error');
    }

    if (allowClear) {
      className.push('clear');
    }

    if (small) {
      className.push('small');
    }

    return className.join(' ');
  }, [disabled, focused, inputValue, isError, allowClear, small]);

  const showSearchPlaceholder = useMemo(() => focused && showSearch, [focused, showSearch]);

  const searchPlaceholderText = useMemo(() => {
    return showSearchPlaceholder && <span>{searchPlaceholder || 'Поиск данных...'}</span>;
  }, [searchPlaceholder, showSearchPlaceholder]);

  const selectedItem = useMemo(() => options.filter((el) => el.value === inputValue), [options, inputValue]);

  const optionsWithFirst = useMemo(() => {
    const currentOptions = !!filterOptions.length ? filterOptions : options;

    return _.without(currentOptions, selectedItem[0]);
  }, [filterOptions, options, selectedItem]);

  const selectedOptions = useMemo(() => {
    return _.first(
      [...selectedItem, ...optionsWithFirst].map((el) => (
        <Option {...el} className={'styledOption'} key={el.value}>
          {el?.label}
        </Option>
      )),
      countOptionsList * 10
    );
  }, [countOptionsList, optionsWithFirst, selectedItem]);

  const multipleLabel = useMemo(() => {
    if (mode === 'multiple' && Array.isArray(inputValue) && inputValue.length > 0 && !isSearch) {
      return <MultipleLabel>Выбрано значений: {inputValue.length}</MultipleLabel>;
    }

    return null;
  }, [isSearch, mode, inputValue]);

  const onChangeHandler = useCallback(
    (value: string | Array<string>, option: any) => {
      changeValue(value);

      if (mode === 'default') {
        changeFocus(false);
      }

      if (onChange) {
        onChange(value, option);
      }
    },
    [mode, onChange]
  );

  const onBlurHandler = useCallback(() => {
    changeFocus(false);

    if (required && !inputValue) {
      changeError(true);
    } else if (!error) {
      changeError(false);
    }

    changeSearch(false);
    setCountOptionsList(2);
  }, [error, required, inputValue]);

  const onFocusHandler = useCallback(() => {
    changeFocus(true);
  }, []);

  const onScrollHandler = useCallback(
    (event) => {
      const { target } = event;
      const { scrollTop, offsetHeight, scrollHeight } = target;
      const availableScroll =
        scrollTop && offsetHeight && scrollHeight ? scrollTop + offsetHeight === scrollHeight : false;

      if (availableScroll) {
        setCountOptionsList(countOptionsList + 1);
      }
    },
    [countOptionsList]
  );

  const onSearchHandler = useCallback(
    (value: string) => {
      if (value) {
        const searchOptions = options.filter((el) => el.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()));

        setFilterOptions(searchOptions);
        changeSearch(true);
      } else {
        setFilterOptions([]);
        changeSearch(false);
      }
    },
    [options]
  );

  return (
    <StyledSelect ref={selectWrapRef} className={selectClassName}>
      <SelectANTD
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onPopupScroll={onScrollHandler}
        suffixIcon={icon}
        showSearch={showSearch}
        onSearch={onSearchHandler}
        placeholder={searchPlaceholderText}
        value={inputValue}
        optionFilterProp={'label'}
        allowClear={allowClear}
        // @ts-ignore
        getPopupContainer={() => selectWrapRef.current}
        mode={mode as modeTypes}
      >
        {selectedOptions}
      </SelectANTD>
      <Placeholder>{placeholder || 'Выберите данные'}</Placeholder>
      {helpPlaceholder && <HelpPlaceholder>{helpPlaceholder}</HelpPlaceholder>}
      {multipleLabel}
    </StyledSelect>
  );
};

export default Select;
