import React from 'react';
import { Link } from 'react-router-dom';

import { ObjectCellRendererProps } from './model/ObjectCellRenderer.model';

const ObjectCellRenderer = (props: ObjectCellRendererProps) => {
  const field = props.colDef?.additionalData?.url?.label;
  if (props.data && field && props.data[field + '_detail'].length) {
    const link = props.data[field + '_detail'].split('/api/')[1];
    return (
      <Link to={'/app/directory/' + link} style={{ height: 20, lineHeight: 0.5 }} className="btn btn-info">
        {props.data[field + '_name']}
      </Link>
    );
  } else return null;
};

export default ObjectCellRenderer;
