import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import BrigadeApplicationsCard from '../BrigadeApplicationsCard';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';
import StatusButton from './BrigadeApplicationsButtons/StatusButton';
import BrigadeButton from './BrigadeApplicationsButtons/BrigadeButton';
import { ApplicationPhoto, ApplicationsService, StatusF30Enum } from 'generated/api/api';
import FileUploader from 'UI/custom/components/FileUploader/FileUploader';
import MasterButton from './BrigadeApplicationsButtons/MasterButton';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { groupTypes } from 'registrators/map/itemContextMenuRegistrator/contentGroups';
import { initializeSingleObjectEditMode } from 'store/sagas/map/standaloneObjectEditableSaga';
import { Layers } from 'types/enums/map/layers/Layers';
import { BrigadeApplicationsSelectionProps, IApplicationImage } from './BrigadeApplicationsSelection.model';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { theme } from 'styles/themes/rootTheme';
import { RequestPath } from '../../../types/enums/routes/request/RequestPath';

const { OpacityColor } = styleMixins.color;

const BrigadeApplicationsSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: BrigadeApplicationsSelectionProps) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<Array<IApplicationImage>>([]);

  const uploadFiles = useCallback(async (): Promise<any> => {
    const { results } = await ApplicationsService.applicationsPhotosList({ application: `${data.id}` });
    const newFiles = results
      .filter((el: ApplicationPhoto) => el?.photo)
      .map((el: { id: number; photo: string }) => {
        let uriAr = el.photo.split('/');
        const name = uriAr[uriAr.length - 1];

        return { id: el.id, name, path: el.photo };
      });
    setFiles(newFiles);
  }, [data.id]);

  const application_id = data?.id;

  const handleOpenObjectInNewTab = useCallback(() => {
    const roadData = selectedMapItem.data.find((dataRecord: any) => dataRecord.name === MapObjects.brigadeApplications);
    const { id } = roadData.data;
    const win = window.open(
      `/app/${ApplicationsDirectory.applications}/${ApplicationsDirectory.main}/${id}/`,
      '_blank'
    );
    win?.focus();
  }, [selectedMapItem.data]);

  const handleEditObject = useCallback(() => {
    dispatch(
      initializeSingleObjectEditMode(
        data,
        MapObjects.brigadeApplications,
        Layers.brigadeApplications,
        null,
        groupTypes.EDIT_GROUP
      )
    );
  }, [data, dispatch]);

  useEffect(() => {
    uploadFiles();
  }, [uploadFiles]);

  /*КНОПКИ ДЛЯ КАРТОЧКИ ОБЪЕКТА*/
  const masterButton = useMemo(
    () => <MasterButton selectedMapItem={selectedMapItem} data={data} />,
    [data, selectedMapItem]
  );
  const statusButton = useMemo(
    () => <StatusButton selectedMapItem={selectedMapItem} data={data} />,
    [data, selectedMapItem]
  );
  const brigadeButton = useMemo(
    () => <BrigadeButton selectedMapItem={selectedMapItem} data={data} />,
    [data, selectedMapItem]
  );

  const Descriptions: IDescriptions[] = [];
  const scheme = selectedMapItem.data[0].model.scheme;
  const statusValue: string =
    scheme.status.choices.find((el: { value: string }) => el.value === data.status)?.display_name ?? '';
  const brigadeValue: string = data?.appointed_team_name ?? '';
  const masterValue: string = data?.responsible_master_name ?? '';
  const applicationTypeValue: string = data?.application_type_name ?? '';

  const status = data?.status ?? StatusF30Enum.NEW;
  const color = theme.status[status];
  const backgroundColor = OpacityColor(theme.status[status], 0.08);

  Descriptions.push({
    key: 'Статус',
    value: statusValue,
    options: {
      buttons: [statusButton],
      styles: {
        backgroundColor: backgroundColor,
        color: color,
      },
    },
  });

  Descriptions.push({
    key: 'Назначенная бригада',
    value: brigadeValue,
    options: {
      buttons: [brigadeButton],
    },
  });

  Descriptions.push({
    key: 'Тип заявки',
    value: applicationTypeValue,
  });
  //TODO Поменять когда бэк сделает родительские задачи Тех-долг-FRONTEND #5631
  Descriptions.push({
    key: 'Родительская задача',
    value: 'Ремонт светофоров',
  });

  Descriptions.push({
    key: 'Ответственный',
    value: masterValue,
    options: {
      buttons: [masterButton],
    },
  });

  const fileUploaderComponent = useMemo(
    () => (
      <FileUploader
        id={`${application_id}`}
        pageMenuKey={selectedMapItem.data[0].urlData}
        files={files}
        deletePath={RequestPath.brigadePhotos}
        updateAsyncData={uploadFiles}
      />
    ),
    [application_id, files, selectedMapItem.data, uploadFiles]
  );

  return (
    <BrigadeApplicationsCard
      title={`Заявка №${application_id}`}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
      fileUploaderComponent={fileUploaderComponent}
      handleEditObject={handleEditObject}
      images={files}
      application_id={application_id}
    />
  );
};

export default BrigadeApplicationsSelection;
