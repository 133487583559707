import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import IlluminatedPedestrianCrossingsCompositeLayer from '../../../../../../layers/CompositeLayers/ekb/IlluminatedPedestrianCrossingsCompositeLayer';

export class IlluminatedPedestrianCrossingsSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: IlluminatedPedestrianCrossingsCompositeLayer,
      id: 'IlluminatedPedestrianCrossingsSchema',
    });
  }
}
