import React, { useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import Compendium from './Compendium';
import { useAgGridControl } from '../../../../hooks/ag-grid/useAgGridControl';
import { initSetMapSelectedObjectSaga } from '../../../../store/sagas/map/mapSelectedObjectSaga';
import { Button } from '../../../common/components/Button/Button';
import { CloseIcon } from '../../icons/actionIcons/CloseIcon';
import { iconSizesKit } from '../../../../styles/utils/iconSizesKit';
import { clearMapSelectedObject } from '../../../../store/reducers/map/actions/mapSelectedObjectActions';
import WindowedDataTable from '../../../common/components/WindowedDataTable/WindowedDataTable';
import WindowedDataTableHeader from '../../../common/components/WindowedDataTable/Header/WindowedDataTableHeader';
import CompendiumColumnActionPanel from '../CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanel';
import { isArray } from 'underscore';

const CompendiumSelectedContainer = ({
  data,
  model,
  handleInitSetMapSelectedObjectSaga,
  mapSelectedObject,
  style,
  handleClearMapSelectedObject,
  title,
  styleWrapper,
  styleHeader,
  renderButtons = null,
  ...other
}) => {
  const {
    aggApi,
    quickMenu,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
    onFilterChanged,
    clearAgGridFilters,
  } = useAgGridControl();

  // TODO delete if unnecessary Тех-долг-FRONTEND #5556
  const handleSelectRow = useCallback(() => {}, []);

  const handleRefresh = useCallback(() => {}, []);

  const handleProvideData = (event) => {
    handleInitSetMapSelectedObjectSaga(event.data, other.mode.childNext, 'display');
  };

  let containerCallbacks = {
    handleAGGInit,
    handleRefresh,
    handleSelectRow,
    toggleShowFilterMenu,
    handleProvideData,
    toggleShowRowsMenu,
    toggleShowQuickFilter,
    onFilterChanged,
    clearAgGridFilters,
  };

  const panelLabel = useRef(mapSelectedObject?.data?.[0]?.label);

  return (
    <WindowedDataTable
      style={styleWrapper}
      headerContent={
        <WindowedDataTableHeader
          titleContent={title || panelLabel.current || 'Информация об объекте'}
          actionsContent={
            <>
              {renderButtons}
              <CompendiumColumnActionPanel
                quickMenuState={quickMenu}
                toggleShowFilterMenu={containerCallbacks.toggleShowFilterMenu}
                toggleShowQuickFilter={containerCallbacks.toggleShowQuickFilter}
                toggleShowRowsMenu={containerCallbacks.toggleShowRowsMenu}
                clearAgGridFilters={containerCallbacks.clearAgGridFilters}
                iconSizeType={'small'}
              />
              <Button
                type={'unboundedPrimary'}
                p={0}
                icon={<CloseIcon />}
                iconSize={iconSizesKit.huge}
                rounded
                onClick={() => {
                  handleClearMapSelectedObject();
                }}
              />
            </>
          }
          style={styleHeader}
        />
      }
    >
      <Compendium
        aggApi={aggApi}
        data={isArray(data) ? data : [data]} // Строка скорректирована для предотвращения ошибок в случае прихода не корректных данных
        model={model}
        showQuickFilter={quickMenu.search}
        containerCallbacks={containerCallbacks}
        withPagination={false}
        agGridThemeName={'ag-theme-custom-base ag-grid-theme-custom-map'}
      />
    </WindowedDataTable>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.app.language,
    mapSelectedObject: state.mapSelectedObject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleInitSetMapSelectedObjectSaga: (data, type, mapMode) =>
      dispatch(initSetMapSelectedObjectSaga(data, type, mapMode)),
    handleClearMapSelectedObject: () => dispatch(clearMapSelectedObject()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompendiumSelectedContainer);
