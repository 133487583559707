import styled from 'styled-components';
import { Input, Modal, Select } from 'antd';

const { TextArea } = Input;

function getBaseStylesForInput(inputType: any) {
  return styled(inputType)`
    color: ${(props) => props.theme.colors.defaultDarker};
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border: none;
    height: 4.2rem;
    border-radius: 4px;
    padding-top: 1.85rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::placeholder {
      color: transparent;
    }
    &:focus,
    &:hover {
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }
  `;
}

const BaseInput = getBaseStylesForInput(Input);

const MainContainer = styled.div`
  padding: 10px 20px;
`;

const MainDataContainer = styled.div`
  display: flex;
`;

const MainStatmentDataContent = styled.div`
  background-color: ${(props) => props.theme.colors.defaultLight};
  padding: 10px 0;
  width: calc(100% - 346px);
  height: calc(100vh - 230px);
  margin-right: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.defaultLighter};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutralDark};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.neutralDarker};
  }
`;

const MainStatmentDataWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.defaultLighter};
  border-radius: 8px;
  width: 720px;
  padding: 15px;
  position: relative;
  .relative_position_container {
    position: relative;
  }
`;

const ApprovalStatmentContent = styled.div`
  width: 306px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  button {
    margin-bottom: 16px;
    :last-child {
      margin: 0;
    }
  }
`;

const TableContainer = styled.table`
  background-color: ${(props) => props.theme.colors.defaultLighter};
  color: ${(props) => props.theme.colors.dark};
  font-size: 10px;
  margin-bottom: 10px;
  z-index: 2;
  &.relative_position_tables {
    position: relative;
  }
  &.absolute_position_tables {
    position: absolute;
  }
  td {
    padding: 0;
    border: 1px solid ${(props) => props.theme.colors.dark};
    &.nested_table_container {
      padding: -1px;
    }
  }
  .label_text {
    font-weight: bold;
  }
  caption {
    padding: 0;
    color: ${(props) => props.theme.colors.dark};
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 6px;
    caption-side: top;
  }
  &.reconciling_organization {
    top: 15px;
    left: 265px;
  }
  &.road_train_data {
    top: 15px;
    left: 475px;
  }
  &.calc_of_harm {
    top: 24px;
    left: 155px;
  }
  &.calc_of_harm_data {
    z-index: 1;
    box-shadow: 0px 0px 0px 15px ${(props) => props.theme.colors.defaultLighter};
    top: 42px;
    left: 155px;
  }
  &.nested_table {
    border: 2px solid ${(props) => props.theme.colors.defaultLighter};
    margin: 0;
    padding: 0;
  }
  .sum_calc_of_harm {
    padding-top: 15px;
  }
`;

const TextAreaContainer = styled(TextArea)`
  background-color: ${(props) => props.theme.colors.defaultLight};
  border-radius: 8px;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.colors.neutralDarker};
  text-align: left;
  border: none;
  padding: 14px;
  margin-bottom: 14px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BaseSelectInput = styled(Select)`
  .antd-options {
    top: 20px;
  }

  .ant-select-arrow {
    top: 30px !important;
  }

  .ant-select-selector {
    width: 306px !important;
    height: 60px !important;
    margin-bottom: 16px;
    background-color: ${(props) => props.theme.colors.defaultLight} !important;
    border: none !important;

    &-selected-value {
      padding-top: 10px;
    }
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    padding-top: 15px !important;
  }
`;

const ModalContainer = styled(Modal)`
  .ant-modal-body {
    padding: 16px;
  }
`;

const SaveChangeButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  & .shitTrader {
    width: 300px;
    & p {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
    height: 40px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.defaultLighter};
  }
`;

const BaseInputContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  p {
    opacity: 0.8;
    color: ${(props) => props.theme.colors.neutralDark};
    position: absolute;
    top: 10px;
    left: 10px;
  }
  input {
    padding-top: 25px;
    border: none;
    background-color: ${(props) => props.theme.colors.defaultLight};
    height: 60px;
  }
  span {
    width: 306px;
  }
  .ant-input-disabled {
    color: ${(props) => props.theme.colors.gray};
  }
  .ant-picker,
  .ant-picker-input {
    width: 100%;
    max-height: 56px;
    border: none;
    input {
      width: 90%;
    }
    span {
      width: 10%;
    }
  }
`;

const ApprovalNotificationContainer = styled.div`
  text-align: center;
  span {
    margin: 30px;
  }
  svg {
    height: 45px;
    width: 45px;
  }
  path {
    fill: ${(props) => props.theme.colors.darkblue};
  }
  p {
    font-weight: bold;
    font-size: 22px;
    color: ${(props) => props.theme.colors.dark};
  }
`;

export const Styled = {
  MainContainer,
  MainStatmentDataContent,
  MainStatmentDataWrapper,
  ApprovalStatmentContent,
  MainDataContainer,
  TableContainer,
  TextAreaContainer,
  BaseSelectInput,
  SaveChangeButton,
  ModalContainer,
  BaseInput,
  BaseInputContainer,
  ApprovalNotificationContainer,
};
