import React, { useCallback, useMemo, useState } from 'react';

import { FormInputProps } from 'UI/common/form/inputs/model/FormInputProps.model';

import { IAxes, IFormProps, IVehicleParameters } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { emptyAxes } from '../../interfacesCargoTransport/templates';
import { InputsMainParameters } from './InputsMainParameters';
import { MainAxelsContainer } from './MainAxelsContainer';
import { TabButtonsVehicle } from './TabButtonsVehicle';

interface IVehicleParametersFieldProps extends FormInputProps {
  formProps: IFormProps;
}

export const VehicleParametersField = ({ description, field, formProps }: IVehicleParametersFieldProps) => {
  const { mainVehicleParametersData, setMainVehicleParametersData } = formProps;
  const [activeVPIndex, setActiveVPIndex] = useState<number>(0);

  const activeVehicle: IVehicleParameters = useMemo(
    () => mainVehicleParametersData[activeVPIndex],
    [activeVPIndex, mainVehicleParametersData]
  );

  const changeFieldsActiveVehicle = useCallback(
    (key: string, value, obj: IVehicleParameters | IAxes) => {
      const stringFields = key !== 'registration_number' && key !== 'model';
      if (stringFields && !Number.isFinite(+value)) return;

      obj[key] = value;
      // Логика динамического добавления и удаления осей транспортного средства
      if (key === 'axle_count') {
        // Если значение не меняется то ретернем функцию
        if (+value === 0 || +value === obj.axes?.length) return;
        // Если значение если осей у ТС нет то добавляем пустые оси
        else if (!obj.axes?.length) obj.axes = Array(+value).fill(emptyAxes);
        // Если уменьшаем количество осей то отрубаем хвост у массива
        else if (+value < obj.axes?.length) obj.axes = obj.axes.slice(0, +value);
        // Если добавляем новые оси то добавляем новые пустые оси в конец массива
        else obj.axes = [...obj.axes, ...Array(+value - obj.axes.length).fill(emptyAxes)];
        // Перебираем все оси чтоб не было ссылок на emptyAxes и пронумеровываем оси
        obj?.axes?.forEach((el: any, index: number) => {
          if (obj.axes?.length) {
            obj.axes[index] = { ...obj.axes[index], number: index + 1 };
          }
        });
      }
      setMainVehicleParametersData([...mainVehicleParametersData]);
    },
    [setMainVehicleParametersData, mainVehicleParametersData]
  );

  const descriptionMainSchema = description?.child?.children;
  const descriptionAxelsSchema = description?.child?.children?.axes?.child?.children;

  return (
    <>
      <TabButtonsVehicle
        description={description}
        activeVPIndex={activeVPIndex}
        setActiveVPIndex={setActiveVPIndex}
        formProps={formProps}
      />
      {activeVehicle && (
        <>
          <InputsMainParameters
            description={descriptionMainSchema}
            object={activeVehicle}
            changeFields={changeFieldsActiveVehicle}
            field={field}
          />
          <MainAxelsContainer
            description={descriptionAxelsSchema}
            object={activeVehicle}
            changeFields={changeFieldsActiveVehicle}
            field={field}
          />
        </>
      )}
    </>
  );
};
