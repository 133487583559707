import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import { getFromURL } from 'api/getFromURL';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { initialModel } from '../../interfacesApplications/templates';
import _ from 'underscore';
import { useDispatch } from 'react-redux';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { Styled } from './SemifinishedObjectForm.styled';
import { HeaderContainer } from './Components/HeaderContainer';
import { InlineDrawerContainer } from './Components/InlineDrawerContainer';
import { AnyObject } from 'types/enums/general/general.model';
import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { SemifinishedObjectFormProps } from './model/SemifinishedObjectForm.model';
import { useRouteMatch } from 'react-router-dom';
import { CargoTransportParams } from '../../interfacesApplications/interfacesApplications';
import { SemifinishedObjects } from './Components/SemifinishedObjects';
import { ApplicationObject, ApplicationsService } from 'generated/api/api';

const { MainContainer, LoaderContainer } = Styled;

const { Option } = Select;

export const SemifinishedObjectForm = React.memo(({ extraData }: SemifinishedObjectFormProps) => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { application } = params;
  const dispatch = useDispatch();
  const [semifinishedObjectModel, setSemifinishedObjectModel] = useState<ModelResponse>(initialModel);
  const [loadingObjectModel, setLoadingObjectModel] = useState<boolean>(false);
  const [customFieldsData, setCustomFieldsData] = useState<AnyObject>({});
  const [selectedTypeObjectUrl, setSelectedTypeObjectUrl] = useState<string>();
  const [applicationObjectData, setApplicationObjectData] = useState<ApplicationObject[]>([]);

  const formRef = useRef<HTMLFormElement>(null);

  const notificationAlert = useCallback(
    (e, customMessage = 'Неизвестная ошибка обратитесь к администратору') => {
      let messages = [customMessage];
      if (e?.response && _.isObject(e.response)) {
        messages = createAlertMessage(e);
      }
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: messages,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
    },
    [dispatch]
  );

  const refreshData = useCallback(async () => {
    try {
      if (application && !Number.isNaN(+application)) {
        const results = await ApplicationsService.applicationsObjectsRetrieve({ id: +application });
        // @ts-ignore Ощибка в АПИ
        setApplicationObjectData(results);
      }
    } catch (e) {
      notificationAlert(e);
    }
  }, [application, notificationAlert]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const initSubmitForm = useCallback(() => {
    formRef.current?.submit();
  }, []);

  const handleSubmitForm = useCallback(
    (submitData: AnyObject) => {
      if (!_.isEmpty(customFieldsData) && selectedTypeObjectUrl) {
        dispatch(
          submitObjectSagaErrorResponse({
            url: selectedTypeObjectUrl,
            data: { ...submitData, ...customFieldsData },
            setReturnResponse: refreshData,
          })
        );
      } else {
        notificationAlert(null, 'Укажите месторасположение объекта');
      }
    },
    [customFieldsData, dispatch, notificationAlert, refreshData, selectedTypeObjectUrl]
  );

  const optionsTypeObject = useMemo(
    () =>
      extraData?.map((el) => (
        <Option key={`optionsTypeObject_${el.url}`} value={el?.value}>{`${el?.model_name}`}</Option>
      )),
    [extraData]
  );

  const selectTypeObject = useCallback(
    async (selectedValue) => {
      const selectedTypeObjectUrl = extraData?.find((el) => el.value === selectedValue)?.url?.replace('api/', '');
      if (selectedTypeObjectUrl) {
        setLoadingObjectModel(true);
        setSelectedTypeObjectUrl(selectedTypeObjectUrl);
        setCustomFieldsData({});
        const responseModel = await getFromURL.getModelFromURL(selectedTypeObjectUrl);
        setSemifinishedObjectModel(responseModel);
        setLoadingObjectModel(false);
      }
    },
    [extraData]
  );

  return (
    <MainContainer>
      <HeaderContainer
        refreshData={refreshData}
        applicationName={''}
        optionsTypeObject={optionsTypeObject}
        selectTypeObject={selectTypeObject}
      />
      {loadingObjectModel ? (
        <LoaderContainer>
          <Spin />
        </LoaderContainer>
      ) : semifinishedObjectModel.name ? (
        <InlineDrawerContainer
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          model={semifinishedObjectModel}
          ref={formRef}
          setCustomFieldsData={setCustomFieldsData}
        />
      ) : (
        <LoaderContainer>Для создания нового объекта заявки выберите тип объекта!</LoaderContainer>
      )}
      <SemifinishedObjects data={applicationObjectData} refreshData={refreshData} extraData={extraData} />
    </MainContainer>
  );
});
