import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { PublicTransportService } from '../../../../../../../generated/api/api';

export class TransportRoutes extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'transportRoutes',
      filterLabel: 'Маршруты общественного транспорта',
      selectedObjectName: MapObjects.publicTransportRoute,
    });
  }

  async uploadData() {
    // return await PublicTransportService.publicTransportRouteSchemesList();
    return await getFromURL.getDataFromURL('/public_transport/route_segments');
  }

  async uploadFullData() {
    return await PublicTransportService.publicTransportRouteSchemesList();
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL('/public_transport/route_schemes');
  }
}
