import { initialPrepareFieldsI } from '../model/initialPrepareFields.model';
import { Application, CameraWidget } from 'generated/api/api';
import { ModelNames } from 'types/enums/general/ModelNames.model';
import { prepareApplicationsList } from 'hooks/form/usePrepareFieldsHook/util/inititlaPrepareFunctions/prepareApplicationsList';
import { prepareCameraWidgetsList } from 'hooks/form/usePrepareFieldsHook/util/inititlaPrepareFunctions/prepareCameraWidgetsList';

export const initialPrepareFields = async ({ model, data }: initialPrepareFieldsI) => {
  switch (model.name) {
    case ModelNames.applicationList:
      return await prepareApplicationsList(model, data as Application);
    case ModelNames.cameraWidgetList:
      return prepareCameraWidgetsList(model, data as CameraWidget);
  }

  return {
    prepareModel: model,
    prepareData: data,
  };
};
