import styled from 'styled-components/macro';

const LocalMainWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  z-index: 9995;
  backdrop-filter: blur(2px);
`;

const LocalMainInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;

export const Styled = { LocalMainWrapper, LocalMainInner };
