import React from 'react';
import Icon from '@ant-design/icons';

const RoadSurfaceSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6666 3.33337L13.5749 5.24171L11.1749 7.64171L12.3583 8.82504L14.7583 6.42504L16.6666 8.33337V3.33337H11.6666ZM8.33325 3.33337H3.33325V8.33337L5.24159 6.42504L9.16658 10.3417V16.6667H10.8333V9.65837L6.42492 5.24171L8.33325 3.33337Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const RoadSurface = (props) => <Icon component={RoadSurfaceSVG} {...props} />;
