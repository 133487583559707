import { RegistratorPrototype } from '../../../../RegistratorPrototype';
import { DataAccessMethod } from '../../../../../types/enums/routes/request/DataAccessMethod.model';
import { Layers } from 'types/enums/map/layers/Layers';
import { LayerDataAccessorPrototype } from '../dataAccessors/prototype/LayerDataAccessorPrototype';
import { RelatedDataPrototype } from '../relatedData/prototype/RelatedDataPrototype';
import { LayerCardPrototype } from '../cards/prototype/LayerCardPrototype';
import { LayerSettingsPrototype } from '../settings/prototype/LayerSettingsPrototype';
import { LayerSchemaPrototype } from '../layerSchemaPresets/prototype/LayerSchemaPrototype';
import { FC } from 'react';
import { LayerTemplatesPrototype } from '../templates/prototype/LayerTemplatesPrototype';
import { LayerGroupsPrototype } from '../layerGroups/prototype/LayerGroupsPrototype';
import { MainDataHttpPrototype } from '../mainData/prototype/MainDataHttpPrototype';
import { MainDataSocketPrototype } from '../mainData/prototype/MainDataSocketPrototype';

interface RegistratorPrototypeConstructorArgs {
  name: Layers;
  mainData: null | MainDataHttpPrototype[] | MainDataSocketPrototype[];
  dataAccessor: LayerDataAccessorPrototype | null;
  relatedData: RelatedDataPrototype[];
  layerGroup: LayerGroupsPrototype;
  card: LayerCardPrototype;
  hidden?: boolean;
  customSettings: LayerSettingsPrototype[] | [];
  layerSchema: LayerSchemaPrototype;
  selectedObjectPrototype?: FC<any> | null;
  editable: boolean;
  templates?: LayerTemplatesPrototype | null;
  selectedObjectName?: string | null;
}

export class LayerPrototype extends RegistratorPrototype {
  readonly mainData: null | MainDataHttpPrototype[] | MainDataSocketPrototype[] = [
    new MainDataHttpPrototype({
      urlData: null,
      dataAccessMethod: DataAccessMethod.httpGet,
      dataKeyForIds: 'id',
    }),
  ];
  readonly dataAccessor;
  readonly relatedData;
  readonly card;
  readonly layerGroup;
  readonly customSettings;
  readonly layerSchema;
  readonly selectedObjectName;
  readonly selectedObjectPrototype;
  readonly editable: boolean;
  readonly templates;
  readonly hidden?: boolean;

  isHidden: boolean = false;
  isSelected: boolean = false;

  public constructor({
    name,
    mainData = [],
    dataAccessor = null,
    relatedData = [],
    layerGroup,
    card,
    customSettings = [],
    layerSchema,
    selectedObjectName = null,
    selectedObjectPrototype = null,
    editable = false,
    templates,
    hidden = false,
  }: RegistratorPrototypeConstructorArgs) {
    super(name);

    this.mainData = mainData;
    this.dataAccessor = dataAccessor;
    this.relatedData = relatedData;
    this.card = card;
    this.layerGroup = layerGroup;
    this.customSettings = customSettings;
    this.layerSchema = layerSchema;
    this.selectedObjectName = selectedObjectName;
    this.selectedObjectPrototype = selectedObjectPrototype;
    this.editable = editable;
    this.templates = templates;
    this.hidden = hidden;
  }

  public getIsEditableLayer() {
    return this.editable;
  }

  public getMainData() {
    return this.mainData;
  }

  public getDataAccessor() {
    return this.dataAccessor;
  }

  public getRelatedData() {
    return this.relatedData;
  }

  public getCard() {
    return this.card;
  }

  public getGroup() {
    return this.layerGroup;
  }

  public getCustomSettings() {
    return this.customSettings;
  }

  public getLayerSchema() {
    return this.layerSchema;
  }

  public getSelectedObjectName() {
    return this.selectedObjectName;
  }

  public getSelectedObjectPrototype() {
    return this.selectedObjectPrototype;
  }

  public getTemplates() {
    return this.templates;
  }

  public getHidden() {
    return this.hidden;
  }

  public getIsHidden() {
    return this.isHidden;
  }
}
