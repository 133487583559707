import React from 'react';
import { registeredUrls } from '../../../../../../registrators/directory/directoryRegistrator/directoryRegistrator';
import { compose } from 'redux';
import withContentPadding from '../../../../../../HOC/withContentPadding';
import { arrayGroupBy } from '../../../../../../services/data/arrayGroupBy';
import { Styled } from './DirectoryCards.styled';
import { VehicleIcon } from '../../../../../../UI/custom/icons/objectIcons/VehicleIcons';
import { Link } from 'react-router-dom';
import { StarIcon } from '../../../../../../UI/custom/icons/actionIcons/StarIcon';
import { OptionIcon } from '../../../../../../UI/custom/icons/actionIcons/OptionIcon';
import ScrollableBoxArea from '../../../../../../UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';
import { CollapsableItem } from '../../../../../../UI/common/components/CollapsableItem/CollapsableItem';
import _ from 'underscore';
import { StateModel } from '../../../../../../store/reducers';
import { useSelector } from 'react-redux';

const {
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardWrapper,
  LocalCardStar,
  LocalCardPoint,
  LocalMainWrapper,
  LocalCardDescr,
  LocalCardsWrapper,
  ExtendedCollapseItem,
} = Styled;
const DirectoryCards = () => {
  const userDirectoryPermissions = useSelector((state: StateModel) => state.auth.permissions.directory);
  const confirmedAccess = registeredUrls.filter((directory) => {
    const parsedViewDirectoryPermission =
      directory.folderPermissions.find((permission) => permission.includes('view_')) ?? '';
    return userDirectoryPermissions.includes(parsedViewDirectoryPermission);
  });
  let resultList;
  // @ts-ignore
  const uniqueFolderKeys = [...new Set(confirmedAccess.map((item) => item.folderKey))];

  // @ts-ignore
  resultList = arrayGroupBy(confirmedAccess, (rule) => rule.folderKey);

  const cardsGroupsForRender = [];
  for (let folderKey of uniqueFolderKeys) {
    const oneCardsGroup = [];
    const itemsCollection = resultList?.get(folderKey);
    oneCardsGroup.push(
      // @ts-ignore
      itemsCollection.map((el, index) => (
        <Link to={`/app/directory/${el.url}`} key={index}>
          <LocalCardMainWrapper>
            <LocalCardWrapper>
              <LocalCardHeader>
                {el.label}
                <LocalCardOption>
                  <LocalCardStar>
                    <StarIcon />
                  </LocalCardStar>
                  <LocalCardPoint>
                    <OptionIcon />
                  </LocalCardPoint>
                </LocalCardOption>
              </LocalCardHeader>
              <LocalCardDescr>{el.description}</LocalCardDescr>
            </LocalCardWrapper>
          </LocalCardMainWrapper>
        </Link>
      ))
    );
    cardsGroupsForRender.push({
      name: folderKey,
      cardsGroup: oneCardsGroup,
    });
  }

  return (
    <LocalMainWrapper>
      <ScrollableBoxArea>
        <LocalCardsWrapper>
          {cardsGroupsForRender.map((el) => {
            return (
              <ExtendedCollapseItem key={_.uniqueId()}>
                <CollapsableItem
                  rotateDegree={90}
                  // @ts-ignore
                  titleContent={
                    <>
                      {' '}
                      <VehicleIcon />
                      <div> {el.name}</div>{' '}
                    </>
                  }
                >
                  <div style={{ display: 'flex', backgroundColor: '#f1f3f6', flexWrap: 'wrap' }}>{el.cardsGroup}</div>
                </CollapsableItem>
              </ExtendedCollapseItem>
            );
          })}
        </LocalCardsWrapper>
      </ScrollableBoxArea>
    </LocalMainWrapper>
  );
};

const enhance = compose(withContentPadding);

export default enhance(DirectoryCards);
