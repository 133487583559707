import styled from 'styled-components/macro';
import { Input, Modal } from 'antd';

import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { OpacityColor } = styleMixins.color;
const { TextArea } = Input;

const ChatWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ChatMessageTitle = styled.span`
  font-weight: 700;
  margin-bottom: 2px;
`;

const ChatMessageText = styled.p`
  white-space: pre-wrap;
  margin-bottom: 5px;
`;

const ChatMessageStatus = styled.span`
  margin-top: 5px;
  opacity: 0.5;
`;

const ChatMessageDate = styled.span`
  margin-top: 2px;
  color: ${(props) => props.theme.colors.neutralDark};
`;

const ChatMessage = styled.div`
  max-width: calc(100% - 7px);
  width: max-content;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  height: max-content;
  font-weight: 500;

  &.error_message,
  &.system_message,
  &.yours,
  &.strange {
    border: 3px solid ${(props) => OpacityColor(props.theme.colors.danger, 0.5)};
    border-radius: 8px;
    margin-right: auto;
    color: ${(props) => props.theme.colors.defaultDark};

    ${ChatMessageTitle} {
      color: ${(props) => props.theme.colors.defaultDark};
    }
  }

  &.system_message {
    border: 3px solid ${(props) => OpacityColor(props.theme.colors.success, 0.5)};
  }

  &.strange {
    background: ${(props) => props.theme.colors.defaultLight};
    border-radius: 8px 8px 8px 0px;
    border: 0;

    ${ChatMessageTitle} {
      color: ${(props) => props.theme.colors.neutralDarker};
    }
  }

  &.yours {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 8px 8px 0px 8px;
    color: ${(props) => props.theme.colors.defaultLighter};
    border: 0;

    ${ChatMessageTitle} {
      color: ${(props) => OpacityColor(props.theme.colors.defaultLighter, 0.4)};
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const ChatMessagePhoto = styled.img`
  width: 100%;
  max-height: 76px;
  object-fit: cover;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  cursor: pointer;
`;

const TextAreaContainer = styled(TextArea)`
  resize: none;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  border: none;
  background: ${(props) => props.theme.colors.defaultLight};
  padding: 13px 15px;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  font-weight: bold;

  &.ant-input {
    min-height: 120px;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.neutralDarker};
  }
`;

const FooterChatButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 16px;
`;

const FooterButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  width: 100%;
`;

const ChatPhotoModal = styled(Modal)`
  & .ant-modal-body {
    padding: 0;
  }

  & .ant-modal-close {
    color: white;
  }
`;

export const Styled = {
  ChatWrap,
  ChatMessage,
  ChatMessageTitle,
  ChatMessageText,
  ChatMessageStatus,
  ChatMessageDate,
  ChatMessagePhoto,
  TextAreaContainer,
  FooterChatButtonWrap,
  FooterButtonWrap,
  ChatPhotoModal,
};
