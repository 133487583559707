import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  clearMapSelectedObject,
  onMapSelectedObjectUpdate,
  onMapSelectItem,
} from '../../reducers/map/actions/mapSelectedObjectActions';
import { toggleMapSelectedObjectInfoVisibility } from '../../reducers/map/actions/mapPanelsActions';
import { resolveSelectedObjectDescription } from '../../../registrators/map/mapSelectObjectRegistrator/mapSelectObjectRegistrator';
import { getFromURL } from '../../../api/getFromURL';
import { DataAccessMethod } from '../../../types/enums/routes/request/DataAccessMethod.model';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import { getWktGeometryBounds } from '../../../services/map/getWktGeometryBounds';
import { goToMapCoords } from '../../reducers/map/actions/mapboxActions';

const INIT_SET_MAP_SELECTED_OBJECT_SAGA = 'INIT_MAP_SELECTED_OBJECT_SAGA';
const INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA = 'INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA';

export const initSetMapSelectedObjectSaga = (data, type, mapMode, componentToRender) => ({
  type: INIT_SET_MAP_SELECTED_OBJECT_SAGA,
  payload: {
    data,
    type,
    mapMode,
    componentToRender,
  },
});

export const initUpdateMapSelectedObjectSaga = (data, type) => ({
  type: INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA,
  payload: {
    data,
    type,
  },
});

const localMakeURL = (currentItemMode = {}, itemEventData) => {
  const { urlData = '', idKey = '' } = currentItemMode;
  const hasItemEventDataProperties = !!itemEventData?.object?.properties;
  if (itemEventData.hasOwnProperty(idKey) && currentItemMode.label === 'Маршрут заявки') {
    return urlData + itemEventData.route_id;
  } else if (itemEventData.hasOwnProperty(idKey)) {
    return urlData + itemEventData[idKey];
  } else if (hasItemEventDataProperties) {
    return urlData + itemEventData.object.properties[idKey];
  }
  return urlData + itemEventData.object[idKey];
};

function* setMapSelectedObjectSaga(action) {
  //always need clear selected object
  yield put(clearMapSelectedObject());
  yield put(toggleMapSelectedObjectInfoVisibility(false));
  const { data, type, componentToRender } = action.payload;
  let resolvedSelectedModes = resolveSelectedObjectDescription(type, 'modes');
  for (let index in resolvedSelectedModes) {
    if (resolvedSelectedModes.hasOwnProperty(index)) {
      const mode = resolvedSelectedModes[index];
      switch (mode.accessMethod) {
        case DataAccessMethod.httpGet: {
          const responseData = yield call(() => getFromURL.getDataFromURL(localMakeURL(mode, data)));
          const model = yield call(() => getFromURL.getModelFromURL(localMakeURL(mode, data)));
          mode.data = responseData;
          mode.model = model;
          mode.invoked = data;
          mode.child = mode?.childNext;
          break;
        }
        case DataAccessMethod.httpGetWithParams: {
          let paramsObject = {};
          for (let param of mode.params) {
            if (param.value === 'value') {
              paramsObject[param.name] = data;
            } else {
              paramsObject[param.name] = data[param.value];
            }
          }
          const responseData = yield call(() => getFromURL.getWithParams(mode.urlData, paramsObject));
          const model = yield call(() => getFromURL.getModelFromURL(mode.urlData));
          mode.data = responseData.results;
          mode.model = model;
          mode.invoked = data;
          mode.child = mode?.childNext;
          break;
        }
        case DataAccessMethod.httpGetById: {
          const responseData = yield call(() => getFromURL.getById(mode.urlData, data[mode.idKey]));
          const model = yield call(() => getFromURL.getModelFromURL(mode.urlData));
          mode.data = responseData;
          mode.model = model;
          mode.invoked = data;
          mode.child = mode?.childNext;
          break;
        }
        case DataAccessMethod.httpGetWithFilters: {
          const requestFilters = [
            {
              field: 'id',
              operator: 'all',
              data: data.map((item) => item[mode.idKey]),
            },
          ];
          const responseData = yield call(() => getFromURL.getDataFromURLWithFilters(mode.urlData, requestFilters));
          const model = yield call(() => getFromURL.getModelFromURL(mode.urlData));
          mode.data = responseData;
          mode.model = model;
          mode.invoked = data;
          mode.child = mode?.childNext;
          break;
        }
        default:
          break;
      }
      // смотрим если у объекта есть геометрия и, если режим карты - просмотр, - прыгаем в центр границ геометрии
      if (mode?.data && mode?.model) {
        const zoomBounds = getWktGeometryBounds(mode.data, mode.model);
        const mapMode = yield select((state) => state.mapMode);
        //Информация о вызванном объекте находится в invoked, например выбран объект на карте в определеном слое или вызван в другом месте(гриде)
        if (zoomBounds && mapMode.currentMode === 'display' && !mode.invoked.layer) {
          const { centerLatitude, centerLongitude } = zoomBounds;
          yield put(goToMapCoords(centerLatitude, centerLongitude));
        }
      }
    }
  }
  yield put(onMapSelectItem(resolvedSelectedModes, type, data, componentToRender));
  yield put(toggleMapSelectedObjectInfoVisibility(true));
}

function* updateMapSelectedObjectSaga(action) {
  const stateData = yield select((state) => state.mapSelectedObject);
  const { data } = action.payload;
  if (data && stateData) {
    try {
      const preparedDataSelectedObject = {
        ...stateData?.data?.[0],
        data: data,
      };
      const preparedData = {
        ...stateData,
        data: [preparedDataSelectedObject],
        selectedObject: {
          ...data,
        },
      };
      yield put(onMapSelectedObjectUpdate(preparedData));
    } catch (e) {
      console.log(e, action.payload);
      yield put(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: 'Что-то пошло не так',
        })
      );
    }
  }
}

export function* watchMapSelectedObjectSaga() {
  yield takeEvery(INIT_SET_MAP_SELECTED_OBJECT_SAGA, setMapSelectedObjectSaga);
  yield takeEvery(INIT_UPDATE_MAP_SELECTED_OBJECT_SAGA, updateMapSelectedObjectSaga);
}
