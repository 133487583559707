import { NumericLayerSetting } from './prototype/types/NumericLayerSetting';

export class Radius extends NumericLayerSetting {
  constructor() {
    super({
      label: 'Радиус хекса',
      keyForDeck: 'radius',
      minimum: 100,
      currentValue: 300,
      maximum: 500,
      step: 50,
      isRanged: false,
      isPercent: false,
    });
  }
}
