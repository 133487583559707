import React from 'react';
import Icon from '@ant-design/icons';

const SignalPoleSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16667 2.49984L10 1.6665L10.8333 2.49984V16.6665C11.2754 16.6665 11.6993 16.8421 12.0118 17.1547C12.3244 17.4672 12.5 17.8911 12.5 18.3332H7.5C7.5 17.4165 8.25 16.6665 9.16667 16.6665V2.49984Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const SignalPole = (props) => <Icon component={SignalPoleSVG} {...props} />;
