import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { ekbLayerCards } from '../description/cards/ekb';
import { layerSettings } from '../description/settings';
import { layerDataAccessors } from '../description/dataAccessors';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import PublicTransportBusStopSelection from '../../../../layers/SelectedObjectsRepresentations/PublicTransportBusStopSelection/PublicTransportBusStopSelection';
import { projectLayerGroups } from '../description/layerGroups';

export class PublicTransportLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.publicTransport,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.PublicTransportDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.TransportRoutes(),
        new relatedLayerData.ekbRelatedDataForLayers.TransportStops(),
      ],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.PublicTransportCard(),
      customSettings: [new layerSettings.OpacitySetting(), new layerSettings.IconSizeScale()],
      layerSchema: new layerSchemas.ekbLayersSchema.PublicTransportLayerSchema(),
      selectedObjectPrototype: PublicTransportBusStopSelection,
    });
  }
}
