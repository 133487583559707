import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { SpecialPermitsService } from '../../../../../../../generated/api/api';

export class OrderRoute extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'order_route',
      filterLabel: 'маршрут заявки',
      selectedObjectName: MapObjects.individualStatements,
    });
  }

  async uploadData() {
    return await SpecialPermitsService.specialPermitsIndividualStatementsRoutesList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async readRecord(id) {
    return await SpecialPermitsService.specialPermitsIndividualStatementsRoutesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL('/special_permits/individual_statements/');
  }
}
