export enum DirectoryRouteModel {
  terminals = 'terminals',
  users = 'users',
  groups = 'groups',
  roads = 'roads',
  roads_road_categories = 'roads_road_categories',
  person_types = 'person_types',
  person = 'person',
  user_positions = 'user_positions',
  notifications = 'notifications',
  organizations = 'organizations',
  organizations_forms = 'organizations_forms',
  permissions = 'permissions',
  permissions_groups = 'permissions_groups',
  filters_organisation = 'filters_organisation',
  model_descriptions = 'model_descriptions',
  states = 'states',
  states_districts = 'states_districts',
  states_localities = 'states_localities',
  states_locality_types = 'states_locality_types',
  states_municipalities = 'states_municipalities',
  states_population = 'states_population',
  roads_clutch_quality = 'roads_clutch_quality',
  road_complex_type_quality = 'road_complex_type_quality',
  road_flatness_quality = 'road_flatness_quality',
  road_rut_quality = 'road_rut_quality',
  road_strength_quality = 'road_strength_quality',
  upload_files_history = 'upload_files_history',
  artificial_structures_segments_overpasses = 'artificial_structures_segments_overpasses',
  artificial_structures_segments_overpass_types = 'artificial_structures_segments_overpass_types',
  artificial_structures_overpasses = 'artificial_structures_overpasses',
  artificial_structures_bridges = 'artificial_structures_bridges',
  artificial_structures_ferry_crossings = 'artificial_structures_ferry_crossings',
  artificial_structures_pipelines = 'artificial_structures_pipelines',
  artificial_structures_pipes = 'artificial_structures_pipes',
  artificial_structures_rail_crossings = 'artificial_structures_rail_crossings',
  artificial_structures_tunnels = 'artificial_structures_tunnels',
  citizen_appears = 'citizen_appears',
  filters_user_organizations = 'filters_user_organizations',
  road_accidents = 'road_accidents',
  road_accidents_participant = 'road_accidents_participant',
  road_accidents_sections = 'road_accidents_sections',
  road_accidents_vehicle = 'road_accidents_vehicle',
  order_route = 'order_route',
  axle_load_overloads = 'axle_load_overloads',
  roads_segments = 'roads_segments',
  roads_segments_axle_loads = 'roads_segments_axle_loads',
  roads_segments_capacities = 'roads_segments_capacities',
  roads_segments_categories = 'roads_segments_categories',
  roads_segments_classes = 'roads_segments_classes',
  roads_segments_coating_types = 'roads_segments_coating_types',
  roads_segments_lane_types = 'roads_segments_lane_types',
  roads_segments_lane_widths = 'roads_segments_lane_widths',
  roads_segments_lanes_amounts = 'roads_segments_lanes_amounts',
  roads_segments_max_speeds = 'roads_segments_max_speeds',
  roads_segments_non_normative_states = 'roads_segments_non_normative_states',
  roads_segments_road_side_widths = 'roads_segments_road_side_widths',
  roads_segments_road_way_widths = 'roads_segments_road_way_widths',
  roads_segments_side_walk_widths = 'roads_segments_side_walk_widths',
  roads_segments_subgrade_widths = 'roads_segments_subgrade_widths',
  roads_snip_categories = 'roads_snip_categories',
  roads_construction_lighting = 'roads_construction_lighting',
  roads_construction_rest_areas = 'roads_construction_rest_areas',
  roads_construction_road_sign_groups = 'roads_construction_road_sign_groups',
  roads_construction_road_signs = 'roads_construction_road_signs',
  roads_construction_traffic_lights = 'roads_construction_traffic_lights',
  roads_construction_weather_stations = 'roads_construction_weather_stations',
  roads_quality_administration_assignments = 'roads_quality_administration_assignments',
  roads_quality_administration_assignments_files = 'roads_quality_administration_assignments_files',
  roads_quality_administration_assignments_road = 'roads_quality_administration_assignments_road',
  roads_quality_coefficients = 'roads_quality_coefficients',
  roads_quality_road_clutch_quality = 'roads_quality_road_clutch_quality',
  roads_quality_road_complex_type_quality = 'roads_quality_road_complex_type_quality',
  roads_quality_road_flatness_quality = 'roads_quality_road_flatness_quality',
  roads_quality_road_rut_quality = 'roads_quality_road_rut_quality',
  roads_quality_road_strength_quality = 'roads_quality_road_strength_quality',
  roads_quality_segment_analysis_values = 'roads_quality_segment_analysis_values',
  roads_quality_upload_files_history = 'roads_quality_upload_files_history',
  social_facilities = 'social_facilities',
  roads_quality_condition_of_pavement = 'roads_quality_condition_of_pavement',
  table1_initial_value_and_coeffic = 'table1_initial_value_and_coeffic',
  table2_initial_value_and_coeffic = 'table2_initial_value_and_coeffic',
  iopw_size_damage_cause_by_vehicle = 'iopw_size_damage_cause_by_vehicle',
  iopw_available_axial_loads_of_vehicles = 'iopw_available_axial_loads_of_vehicles',
  iopw_available_mass_vehicle = 'iopw_available_mass_vehicle',
  iopw_deflators = 'iopw_deflators',
  roads_segments_security_fence_widths = 'roads_segments_security_fence_widths',
  roads_segments_slopes_roadway = 'roads_segments_slopes_roadway',
  roads_segments_spans = 'roads_segments_spans',
  iopw_petition_status_comments = 'iopw_petition_status_comments',
  road_crosses = 'road_crosses',
  intensity_researches_cross_roads = 'intensity_researches_cross_roads',
  intensity_researches_crossed_streets = 'intensity_researches_crossed_streets',
  intensity_researches_researches = 'intensity_researches_researches',
  intensity_researches_cross_sections = 'intensity_researches_cross_sections',
  intensity_researches_cross_sections_hours = 'intensity_researches_cross_sections_hours',
  intensity_researches_intersections = 'intensity_researches_intersections',
  intensity_researches_intersections_hours = 'intensity_researches_intersections_hours',
  intensity_researches_crossing = 'intensity_researches_crossing',
  intensity_researches_crossing_hours = 'intensity_researches_crossing_hours',
  intensity_researches_archives = 'intensity_researches_archives',
  intensity_researches_errors = 'intensity_researches_errors',
  social_facilities_medical_objects = 'social_facilities_medical_objects',
  roadcons_public_transport_stops = 'roadcons_public_transport_stops',
  // route_schemes = 'route_schemes',
  artificial_structures_bridge_structure_supports = 'artificial_structures_bridge_structure_supports',
  artificial_structures_bridge_structures = 'artificial_structures_bridge_structures',
  artificial_structures_segments_bridge_structure = 'artificial_structures_segments_bridge_structure',
  artificial_structures_history_bridge_structures = 'artificial_structures_history_bridge_structures',
  artificial_structures_bridge_supports = 'artificial_structures_bridge_supports',
  roads_quality_contracts_list = 'roads_quality_contracts_list',
  roads_quality_contract_execution_processes_list = 'roads_quality_contract_execution_processes_list',
  gasStation = 'gas_stations',
  gasStationProject = 'project_gas_stations',
  technical_specifications_types_of_approvals = 'technical_specifications_types_of_approvals',
  technical_specifications_performers_statements = 'technical_specifications_performers_statements',
  technical_specifications_individual_statements = 'technical_specifications_individual_statements',
  technical_specifications_entity_statements = 'technical_specifications_entity_statements',
  roadsQualityContracts = 'roads_quality_contracts',
  roadsQualityContractsCards = 'roads_quality_contracts_cards',
  roadsQualityContractsDefectCategories = 'roads_quality_contracts_defect_categories',
  roadsQualityContractsDefects = 'roads_quality_contracts_defects',
  roadsQualityContractsMobileSurveyTrips = 'roads_quality_contracts_mobile_survey_trips',
  roadsQualityContractsRoadAssessments = 'roads_quality_contracts_road_assessments',
  roadsQualityContractsRoads = 'roads_quality_contracts_roads',
  roadsQualityContractsScore = 'roads_quality_contracts_score',
  roadsQualityContractsSegmentRoadAssessments = 'roads_quality_contracts_segment_road_assessments',
  roadsQualityContractsSurveyTrips = 'roads_quality_contracts_survey_trips',
  roadsQualityFixingOfDefects = 'roads_quality_fixing_of_defects',
  roadsQualityFixingOfDefectsImages = 'roads_quality_fixing_of_defects_images',
  roadsAssessment = 'roads_assessment',
  roadsAssessmentCards = 'roads_assessment_cards',
  traySystems = 'tray_systems',
  trafficLightObjects = 'traffic_light_objects',
  powerLinesSupport = 'power_lines_support',
  railRoad = 'rail_road',
  lightingSupports = 'lighting_supports',
  digitalTwinRailCrossings = 'digital_twin_rail_crossings',
  roadway = 'roadways',
  roadsDividingLines = 'roads_dividing_lines',
  sidewalks = 'sidewalks',
  roadsRoadCategories = 'roads_categories',
  personTypes = 'person_types',
  userPositions = 'user_positions',
  organizationsForms = 'organizations_forms',
  permissionsGroups = 'permissions_groups',
  filtersOrganisation = 'filters_organisation',
  cityDistricts = 'city_districts',
  statesDistricts = 'states_districts',
  statesLocalities = 'states_localities',
  statesLocalityTypes = 'states_locality_types',
  statesMunicipalities = 'states_municipalities',
  statesPopulation = 'states_population',
  roadsClutchQuality = 'roads_clutch_quality',
  roadComplexTypeQuality = 'road_complex_type_quality',
  roadFlatnessQuality = 'road_flatness_quality',
  roadRutQuality = 'road_rut_quality',
  roadStrengthQuality = 'road_strength_quality',
  uploadFilesHistory = 'upload_files_history',
  artificialStructuresOverpasses = 'artificial_structures_overpasses',
  artificialStructuresFerryCrossings = 'artificial_structures_ferry_crossings',
  artificialStructuresPipelines = 'artificial_structures_pipelines',
  artificialStructuresPipes = 'artificial_structures_pipes',
  artificialStructuresRailCrossings = 'artificial_structures_rail_crossings',
  artificialStructuresTunnels = 'artificial_structures_tunnels',
  citizenAppears = 'citizen_appears',
  filtersUserOrganizations = 'filters_user_organizations',
  roadAccidents = 'road_accidents',
  roadAccidentsParticipant = 'road_accidents_participant',
  roadAccidentsSections = 'road_accidents_sections',
  roadAccidentsVehicle = 'road_accidents_vehicle',
  orderRoute = 'order_route',
  axleLoadOverloads = 'axle_load_overloads',
  roadsSegments = 'roads_segments',
  roadsSegmentsCategories = 'roads_categories',
  roadsConstructionLighting = 'roads_construction_lighting',
  roadsConstructionRestAreas = 'roads_construction_rest_areas',
  roadsConstructionRoadSignGroups = 'roads_construction_road_sign_groups',
  roadConstructionsRoadSigns = 'road_constructions_road_signs',
  roadsConstructionTrafficLights = 'roads_construction_traffic_lights',
  roadsConstructionWeatherStations = 'roads_construction_weather_stations',
  socialFacilities = 'social_facilities',
  roadsSegmentsSupports = 'support_pillars',
  knsCablesTram = 'kns_cables_tram',
  knsLinesTram = 'kns_lines_tram',
  knsCablesTroll = 'kns_cables_troll',
  knsLinesTroll = 'kns_lines_troll',
  supplyCablesTram = 'supply_cables_tram',
  knsSubstations = 'kns_substations',
  roadMarkingPoints = 'road_marking_points',
  roadMarkingLines = 'road_marking_lines',
  roadMarkingPolygons = 'road_marking_polygons',
  publicTransportBusStops = 'public_transport_bus_stops',
  publicTransportTypesOfInformationBoards = 'public_transport_types_of_information_boards',
  trafficLightObject = 'traffic_light_object',
  roadsSegmentSurfaceTypes = 'roadsSegmentSurfaceTypes',
  tramway = 'tramway',
  engineeringConstructions = 'engineering_constructions',
  speedBumps = 'speed_bumps',
  roadFences = 'road_fences',
  supportWalls = 'support_walls',
  tubes = 'tubes',
  townSquares = 'town_squares',
  contoursLawn = 'contours_lawn',
  signalBars = 'signal_bars',
  cabinets = 'cabinets',
  improvementElements = 'improvement_elements',
  illuminatedPedestrianCrossings = 'illuminated_pedestrian_crossings',
  transportDetectors = 'transport_detectors',
  roadsMarkingParameters = 'roads_marking_parameters',
  roadWorksTrafficManagementProjects = 'road_works_traffic_management_projects',
  roadWorksTrafficOrganizationSchema = 'road_works_traffic_organization_schema',
  projectLayer = 'project_layer',
  brigadeApplications = 'brigade_applications',
  roadConstructionsBusShelters = 'road_constructions_bus_shelters',
  trafficManagementProjectsFiles = 'traffic_management_projects_files',
  roadsConstructionPedDorders = 'roads_construction_ped_dorders',
  camerasUdc = 'cameras_udc',
}
