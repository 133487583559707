/** Используется для переименованя полей в таблицах compendium и инпутах
 */

export const getNewData = (dataElement) => {
  if (!dataElement) return;

  let newElement = { ...dataElement };
  switch (dataElement.payment_status) {
    case 'NOT_PAID':
      newElement.payment_status = 'Не оплачен';
      break;
    case 'PARTIALLY_PAID':
      newElement.payment_status = 'Оплачен частично';
      break;
    case 'FULL_PAID':
      newElement.payment_status = 'Оплачен полностью';
      break;
    default:
      break;
  }
  return newElement;
};
