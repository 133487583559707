import React from 'react';

const Page404 = () => {
  return (
    <div>
      <p>Данные отсутсвуют</p>
    </div>
  );
};

Page404.propTypes = {};

export default Page404;
