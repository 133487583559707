import styled from 'styled-components/macro';
import { Button } from '../../../../UI/common/components/Button/Button';
import { NavLink } from 'react-router-dom';

const LocalMainWrapper = styled.div`
  position: absolute;
  top: 50%;
  height: 382px;
  transform: translateY(-52%);
  z-index: 50000;
  .inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1500;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    color: #bfbfbf;
    position: absolute;
    top: 16px;
    left: 31px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
  }

  & a {
    font-family: AvantGardeGothicC, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.primary};
  }
  // Todo refactoring style Тех-долг-FRONTEND #5615
`;

const LocalLoginFormTitleDescription = styled.div`
  font-family: 'Gilroy', sans-serif;
  width: 306px;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray};
  line-height: 22px;
  margin-bottom: 50px;
`;

const LocalLoginForm = styled.div`
  padding: 32px 16px 34.5px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.largeBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.darkBoxShadow};

  & a {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const LocalLoginFormTitle = styled.h3`
  font-family: 'Gilroy', sans-serif;
  margin-bottom: 32px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  width: 100%;
  align-items: center;
  font-size: 24px;
  color: ${(props) => props.theme.colors.dark};
`;

const LocalFormContentWrapper = styled.form``;

const LocalInputWrapper = styled.div`
  position: relative;
  width: 306px;
  margin: 0 auto 20px;
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  margin-bottom: 16px;
`;

const LocalPrimaryDescription = styled.small`
  display: block;
  font-weight: 700;
  font-family: 'Gilroy', sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
  line-height: 19px;
  opacity: 0.8;
  transition: all 0.3s;
  &:hover {
    opacity: 1;
  }
`;

const LocalMainWraper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
`;

const ExtendedNavLink = styled(NavLink)`
  display: block;
`;

const ExtendedButton = styled(Button)`
  font-weight: 700;
  font-family: 'Gilroy', sans-serif;
  font-size: ${(props) => props.theme.fontSettings.default};
  & + ${ExtendedNavLink} {
    margin-top: 30px;
  }
`;

export const Styled = {
  LocalMainWrapper,
  LocalLoginForm,
  LocalLoginFormTitle,
  LocalFormContentWrapper,
  LocalInputWrapper,
  LocalPrimaryDescription,
  LocalMainWraper,
  ExtendedButton,
  ExtendedNavLink,
  LocalLoginFormTitleDescription,
};
