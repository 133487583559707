import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { mainPages } from 'types/enums/app/mainPages/mainPages';
import UserSettings from './components/UserSettings/UserSettings';
import NamePage from './components/NamePage/NamePage';
import { Styled } from './style/Header.styled';
import WidgetSettings from './components/WidgetSettings/WidgetSettings';

const { LocalMainWrapper, LocalMainInner, LocalHeaderButton } = Styled;

const Header = () => {
  const { params } = useRouteMatch<{ mainPage?: mainPages }>();
  const { mainPage } = params;

  return (
    <LocalMainWrapper>
      <NamePage />
      <LocalMainInner>
        {!mainPage && (
          <LocalHeaderButton>
            <WidgetSettings />
          </LocalHeaderButton>
        )}
        <LocalHeaderButton>
          <UserSettings />
        </LocalHeaderButton>
      </LocalMainInner>
    </LocalMainWrapper>
  );
};

export default Header;
