import React from 'react';
import Icon from '@ant-design/icons';

const DataConcerningIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66602 2.5V7.5H4.12435L5.79102 12.5H4.99935V17.5H9.99935V13.675L14.5077 9.16667H18.3327V4.16667H13.3327V7.975L8.82435 12.5H7.54935L5.88268 7.5H6.66602V2.5H1.66602ZM3.33268 4.16667H4.99935V5.83333H3.33268V4.16667ZM14.9993 5.83333H16.666V7.5H14.9993V5.83333ZM17.6243 10.8333C17.4993 10.8333 17.391 10.875 17.2993 10.9667L16.4577 11.8167L18.1827 13.5417L19.0327 12.7C19.2077 12.525 19.2077 12.225 19.0327 12.05L17.9494 10.9667C17.9068 10.9239 17.8561 10.8901 17.8003 10.8672C17.7445 10.8443 17.6847 10.8328 17.6243 10.8333ZM15.9577 12.3L10.8327 17.425V19.1667H12.5743L17.691 14.0333L15.9577 12.3ZM6.66602 14.1667H8.33268V15.8333H6.66602V14.1667Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const DataConcerningIcon = (props) => <Icon component={DataConcerningIconSVG} {...props} />;
