import React from 'react';
import Icon from '@ant-design/icons';

const RoadSignsIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.89167 2.5L2.5 6.89167V13.1083L6.89167 17.5H13.1083C14.5833 16.0333 17.5 13.1083 17.5 13.1083V6.89167L13.1083 2.5H6.89167ZM7.58333 4.16667H12.4167L15.8333 7.58333V12.4167L12.4167 15.8333H7.58333L4.16667 12.4167V7.58333L7.58333 4.16667ZM9.16667 12.5H10.8333V14.1667H9.16667V12.5ZM9.16667 5.83333H10.8333V10.8333H9.16667V5.83333Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const RoadSignsIcon = (props) => <Icon component={RoadSignsIconSVG} {...props} />;
