import { ModelGroup } from '../interfacesCargoTransport/interfacesCargoTransport';

export const modelSchemaNotReadOnlyKeys: string[] = [];

export const hiddenField: Readonly<string[]> = [
  'worked_time',
  'registered_time',
  'is_route',
  'end_time',
  'planned_date_of_the_reg',
  'planned_date_of_the_end',
  'number_of_statement',
  'comment',
  'status',
  'type_of_document_for_vehicle',
  'number_of_document_for_vehicle',
  'date_of_document_for_vehicle',
  'created_at',
  'modified_at',
  'name_signer',
  'handling_organization_name',
  'responsible_user_name',
];

export const groupVehicleOwnerIp: Readonly<ModelGroup> = {
  label: 'Данные о владельце ТС',
  fields: [
    'is_vehicle_owner',
    'type_of_vehicle_owner',
    'vehicle_owner_first_name',
    'vehicle_owner_surname',
    'vehicle_owner_middle_name',
    'vehicle_owner_phone',
    'vehicle_owner_inn',
    'vehicle_owner_ogrnip',
    'vehicle_owner_address',
  ],
};

export const groupVehicleOwnerOrg: Readonly<ModelGroup> = {
  label: 'Данные о владельце ТС',
  fields: [
    'is_vehicle_owner',
    'type_of_vehicle_owner',
    'vehicle_company_name',
    'vehicle_owner_ogrnip',
    'vehicle_company_first_name',
    'vehicle_company_surname',
    'vehicle_company_middle_name',
    'vehicle_company_phone',
    'vehicle_owner_inn',
    'vehicle_company_email',
    'vehicle_company_address',
  ],
};

export const groupInitiator: Readonly<ModelGroup> = {
  label: 'Инициация запроса',
  fields: [
    'initiator_comment',
    'initiator_user',
    'initiator_user_work_phone',
    'initiator_user_email',
    'initiator_user_organization',
    'initiator_user_position',
    'desc_route_initiator',
    'responsible_organization',
  ],
};

export const groupPerformer: Readonly<ModelGroup> = {
  label: 'Согласующий запроса',
  fields: [
    'performer_comment',
    'performer_user',
    'performer_user_work_phone',
    'performer_user_email',
    'performer_user_organization',
    'performer_user_position',
    'desc_route_performer',
  ],
};
