import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import suspense from '../../../../HOC/suspense';
import { ChangePathNameComponent } from './ChangePathNameComponent';
import DirectoryFolder from './DirectoryFolder/DirectoryFolder';

const DirectoryItem = lazy(() => import('../DirectoryV2/views/Item'));

const DirectoryRouter = React.memo((props) => {
  return (
    <Switch>
      <Route exact={true} path="/app/directory/:folder" component={suspense(DirectoryItem)} />
      <Route
        exact
        path={'/app/directory/:folder/:id(\\d+)/'}
        component={({ match }) => <ChangePathNameComponent folder={match.params.folder} id={match.params.id} />}
      />
      <Route exact path={'/app/directory/'} component={DirectoryFolder} />
    </Switch>
  );
});

export default DirectoryRouter;
