import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { layerDataAccessors } from '../description/dataAccessors';
import { layerSettings } from '../description/settings';
import ElectricalSubstationsSelection from '../../../../layers/SelectedObjectsRepresentations/ElectricalSubstationsItemSelection/ElectricalSubstationsSelection';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class ElectricalSubstationsLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.substationsLayer,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.ElectricalSubstationsDataAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.Substations()],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.ElectricalSubstationCard(),
      customSettings: [
        new layerSettings.OpacitySetting(),
        new layerSettings.IconSizeScale(),
        new layerSettings.Radius(),
      ],
      layerSchema: new layerSchemas.ekbLayersSchema.ElectricalSubstationSchema(),
      selectedObjectPrototype: ElectricalSubstationsSelection,
    });
  }
}
