import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const SupplyCablesTrollItemsSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'supply_cables_troll');
    const { id } = roadData.data;

    const win = window.open(`/app/directory/${DirectoryRouteModel.knsCablesTroll}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Наименование',
    value: data?.name,
  });

  Descriptions.push({
    key: 'Тип',
    value: data?.type_cable ? data?.type_cable : '-',
  });

  Descriptions.push({
    key: 'Наименование линии КНС',
    value: data?.line_name ? data?.line_name : '-',
  });

  Descriptions.push({
    key: 'Наименование подстанции',
    value: data?.substation_name ? data?.substation_name : '-',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о питающем кабеле'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

SupplyCablesTrollItemsSelection.propTypes = {};

export default SupplyCablesTrollItemsSelection;
