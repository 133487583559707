import styled from 'styled-components/macro';

const CategoryWrapper = styled.section`
  padding-left: ${(props) => props.theme.paddings.sidebarElemPaddingLeft};
  padding-top: 1.6rem;
`;

export const Styled = {
  CategoryWrapper,
};
