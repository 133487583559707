import React from 'react';
import Icon from '@ant-design/icons';

const CheckIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50001 13.4749L4.02501 9.99987L2.84167 11.1749L7.50001 15.8332L17.5 5.8332L16.325 4.6582L7.50001 13.4749Z"
      fill="#27AE60"
    />
  </svg>
);

export const CheckIcon = (props) => <Icon component={CheckIconSVG} {...props} />;
