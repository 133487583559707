import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';
import { TokenObtainPair, PasswordResetConfirm, UsersService } from '../../../../generated/api/api';
import { Dispatch } from 'react';
import { notificationActions } from '../notifications/notifications.actions';
import { ActiveStatus } from '../../../../types/enums/UI/ActiveStatus.model';
import { PromptType } from '../../../../types/enums/UI/PromptType.model';
import { errorNetworkMessageCreator } from '../../../../services/application/alertMessageCreators/networkErrorMessage';
import { GetTokenAccessResponse } from './auth.model';
import { createAlertMessage } from '../../../sagas/app/createAlertMessage';

class AuthActions {
  /** action types */
  public readonly prefix: string = 'AUTH';
  public readonly LOGIN_USER: string = `${this.prefix}.LOGIN_USER`;
  public readonly LOGOUT_USER: string = `${this.prefix}.LOGOUT_USER`;
  public readonly APPLY_PERMISSIONS: string = `${this.prefix}.APPLY_PERMISSIONS`;

  /** actions */
  public login: ActionFunction1<any, Action<any>> = createAction(this.LOGIN_USER);
  public logout: ActionFunction0<Action<null>> = createAction(this.LOGOUT_USER);
  public applyPermissions: ActionFunction1<any, Action<any>> = createAction(this.APPLY_PERMISSIONS);

  /** effects */
  public userLogin = (user: TokenObtainPair) => (dispatch: Dispatch<any>) => {
    //TODO тут идет несовпадение типов ответа бэка, надо фиксить на бэке Тех-долг-FRONTEND #5649
    // @ts-ignore

    UsersService.usersTokenCreate({ body: user })
      // @ts-ignore
      .then((responseData: GetTokenAccessResponse) => {
        localStorage.setItem('token_access', responseData?.access);
        localStorage.setItem('token_refresh', responseData?.refresh);
        localStorage.setItem('user', user.username);
        UsersService.usersMePermissionsRetrieve().then((permissions) => {
          dispatch(this.login(user.username));
          dispatch(this.applyPermissions(responseData?.permissions));
          dispatch(authenticationActions.applyPermissions(permissions));
          if (sessionStorage.getItem('redirect_to')) {
            // Использование глобального перехвата window
            // @ts-ignore
            window.location = sessionStorage.getItem('redirect_to');
            sessionStorage.removeItem('redirect_to');
          }
        });
      })
      .catch((e) => {
        dispatch(
          notificationActions.setPromptData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: createAlertMessage(e),
          })
        );
      });
  };
  public userLogout = () => (dispatch: Dispatch<any>) => {
    localStorage.removeItem('token_access');
    localStorage.removeItem('token_refresh');
    localStorage.removeItem('user');
    dispatch(this.logout());
  };
  public userResetPassword = (resetCredentials: PasswordResetConfirm) => (dispatch: Dispatch<any>) => {
    UsersService.usersPasswordResetConfirmCreate({ body: resetCredentials })
      .then((responseData) => {
        //TODO тут идет несовпадение типов ответа бэка, надо фиксить на бэке Тех-долг-FRONTEND #5649
        // @ts-ignore
        localStorage.setItem('token', responseData?.jwt);
        dispatch(this.login(responseData));
      })
      .catch((e) => {
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: errorNetworkMessageCreator(e),
          })
        );
      });
  };
}

export const authenticationActions = new AuthActions();
