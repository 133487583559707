import React, { PropsWithChildren, ReactNode } from 'react';
import CompendiumColumnActionPanel from 'UI/custom/Aggrid/CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanel';
import { Styled } from './ActionButton.styled';
import WindowedDataTableHeader from 'UI/common/components/WindowedDataTable/Header/WindowedDataTableHeader';
import { ContainerCallbacks } from 'UI/custom/Aggrid/Compendium/model/ContainerCallbacks.model';
import { QuickMenuState } from 'UI/custom/Aggrid/Compendium/model/CompendiumColumnActionPanel.model';
import { useMemo } from 'react';

const { ContainerActionPanel, ContainerButton, ContainerMainButton } = Styled;
interface IActionButtonsProps {
  buttons: ReactNode;
  containerCallbacks: ContainerCallbacks;
  quickMenu: QuickMenuState;
}

export const ActionButtons = (props: PropsWithChildren<IActionButtonsProps>) => {
  const { containerCallbacks, quickMenu, buttons } = props;
  const style = useMemo(() => ({ margin: '16px' }), []);
  return (
    <WindowedDataTableHeader
      style={style}
      actionsContent={
        <>
          <ContainerButton>
            <ContainerMainButton>{buttons}</ContainerMainButton>
            <ContainerActionPanel>
              <CompendiumColumnActionPanel
                quickMenuState={quickMenu}
                toggleShowFilterMenu={containerCallbacks.toggleShowFilterMenu}
                toggleShowQuickFilter={containerCallbacks.toggleShowQuickFilter}
                toggleShowRowsMenu={containerCallbacks.toggleShowRowsMenu}
                clearAgGridFilters={containerCallbacks.clearAgGridFilters}
                iconSizeType={'small'}
              />
            </ContainerActionPanel>
          </ContainerButton>
        </>
      }
    />
  );
};
