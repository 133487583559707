import { IconLayer, PathLayer, TextLayer } from '@deck.gl/layers';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../../types/enums/map/MapObjects.model';
import { getCoordsFromParsedGeometry } from '../../../../services/map/getCoordsFromParsedGeometry';
import { roadAccidentsIconMapping } from '../../../iconMappers/roadAccidentsIconMapping';
import MapEventObserver from '../../../../store/rakes/MapEventObserver';
import { layerColorsMapper } from '../../../colorMappers/layerColorsMapper';
import ITSLayer from '../../../../types/classes/ITSLayer';
import { PatchedRoadAccident } from '../../../../generated/api/api';
import { AnyObject } from '../../../../types/enums/general/general.model';

class RoadAccidentsPointsCompositeLayer extends ITSLayer<any, any> {
  updateState() {
    if (this.props?.selectedObject?.selectedObject) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject,
      });
    }
  }

  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        const selectedObjectLayerName = Layers.roadAccidents;
        if (event.sourceLayer.id.includes('road-accidents-icon')) {
          selectedObjectType = {
            mapObjects: MapObjects.roadAccidents,
            settingsEntity: this.props.objectSettings.road_accident,
          };
        }
        if (event.sourceLayer.id.includes('road-accidents-sections')) {
          selectedObjectType = {
            mapObjects: MapObjects.roadAccidents,
            settingsEntity: this.props.objectSettings.road_accidents_sections,
          };
        }
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }
    return event.info;
  }

  renderLayers() {
    const widthSelected = 20;
    const widthNotSelected = 5;
    const polygonCoef = 10000;

    if (!this.props) return [];
    return [
      // Паслэйер аварийноопасные участки
      new PathLayer({
        data: this.props.relatedData?.road_accidents_sections,
        widthScale: 3,
        getWidth: (d: PatchedRoadAccident) => {
          return this.state?.selectedObject?.id === d.id ? widthSelected : widthNotSelected;
        },
        pickable: true,
        rounded: true,
        updateTriggers: {
          getColor: this.state.selectedObject,
          getWidth: this.state.selectedObject,
        },
        billboard: false,
        autoHighlight: true,
        widthMinPixels: 3,
        opacity: 1,
        // @ts-ignore
        getPath: (d: any) => (d.line_path ? getCoordsFromParsedGeometry(d.line_path) : [0, 0]),
        getColor: layerColorsMapper.red,
      }),
      //Иконки
      new IconLayer(this.props, this.getSubLayerProps({ id: 'road-accidents-icon' }), {
        data: this.props.relatedData.road_accidents,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_accidents_icon.png`,
        iconMapping: roadAccidentsIconMapping,
        visible: true,
        sizeScale: 0.5,
        getSize: (d: PatchedRoadAccident) => {
          const selectInGrid = this.state?.selectedObject?.id;
          const selectInMap = this.state?.selectedObject?.object?.id;
          const highlightObject = 50;
          const defaultSize = 50;
          return selectInGrid === d?.id || selectInMap === d?.id ? highlightObject : defaultSize;
        },
        opacity: 1,
        pickable: true,
        updateTriggers: {
          getIcon: this.state?.selectedObject,
          getSize: this.state?.selectedObject,
        },
        getPolygonOffset: ({ layerIndex }: AnyObject) => {
          return [0, -layerIndex * polygonCoef];
        },
        getPosition: (d: PatchedRoadAccident) => {
          return d.point ? getCoordsFromParsedGeometry(d.point) : [0, 0];
        },
        getIcon: (d: PatchedRoadAccident) => {
          return this.props?.id === Layers.roadAccidents &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? `accident_color_${d.road_accident_type}`
            : `accident_white_${d.road_accident_type}`;
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
};

RoadAccidentsPointsCompositeLayer.defaultProps = defaultProps;
RoadAccidentsPointsCompositeLayer.layerName = 'RoadAccidents';

export default RoadAccidentsPointsCompositeLayer;
