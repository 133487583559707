import { handleActions, Reducer } from 'redux-actions';

import { mapMarkersRoadSingGroupActions } from 'store/reducers/map/mapMarkersRoadSingGroup/mapMarkers.actions';
import {
  MapMarkersRoadSingGroupState,
  MapMarkerRoadSingGroupAction,
} from 'store/reducers/map/mapMarkersRoadSingGroup/mapMarkers.model';

import { MapReducer } from '../../reducers.model';

const { ADD_SING_GROUP, REMOVE_SING_GROUP } = mapMarkersRoadSingGroupActions;

const initialState = {
  selectingSing: {
    selectingSing: null,
  },
};

/** Отметка знака на перемещение в другую группу */
const addMarker: Reducer<MapMarkersRoadSingGroupState, MapMarkerRoadSingGroupAction> = (state, { payload }) => {
  const { selectingSing } = payload;
  return {
    selectingSing,
  };
};

/** Удаление маркера с карты */
const removeMarker: Reducer<MapMarkersRoadSingGroupState, MapMarkerRoadSingGroupAction> = () => {
  return initialState;
};

const mapMarkersMapReducer: MapReducer<MapMarkersRoadSingGroupState, any> = {
  [ADD_SING_GROUP]: addMarker,
  [REMOVE_SING_GROUP]: removeMarker,
};

export const mapMarkersRoadSingGroupReducer: Reducer<MapMarkersRoadSingGroupState, any> = handleActions(
  mapMarkersMapReducer,
  initialState
);
