import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { GetDefaultBeatifiedScrollbar } = styleMixins.other;

const EditableContainerWrap = styled.div`
  padding: 16px 0;
  display: grid;
  grid-template-rows: 1fr 42px;
  grid-gap: 12px;
  position: relative;
`;

const EditableContainerStage = styled.div`
  ${(props) => GetDefaultBeatifiedScrollbar(props)};
  overflow: auto;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
`;

const EditableContainerFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
  align-items: center;
  justify-content: end;
`;

const EditableContainerFooterTime = styled.span`
  font-size: 14px;
  line-height: 120%;
  color: #35446f;
  font-weight: bold;
`;

const EditableContainerRefreshCadr = styled(Button)`
  font-size: 13px;
  line-height: 140%;
  padding: 11px 18px;
`;

const EditableHelp = styled.div`
  position: absolute;
  top: 34px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
`;

export const Styled = {
  EditableContainerWrap,
  EditableContainerStage,
  EditableContainerFooter,
  EditableContainerFooterTime,
  EditableContainerRefreshCadr,
  EditableHelp,
};
