import React from 'react';
import globalHook from 'use-global-hook';
import * as actions from '../actions';

const initialState = {
  name: null,
  email: null,
  headers: null,
  owner: null,
  modelId: null,
  checkedItems: null,
  select: null,
  timeStart: null,
  filteredBelongGRZToCar: null,
  checkedItemsAnother: null,
};

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;
