import React from 'react';
import Icon from '@ant-design/icons';

const RoadStopSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.49992 5C1.57492 5 0.833252 5.74167 0.833252 6.66667V12.5H2.49992C2.49992 13.163 2.76331 13.7989 3.23215 14.2678C3.70099 14.7366 4.33688 15 4.99992 15C5.66296 15 6.29884 14.7366 6.76769 14.2678C7.23653 13.7989 7.49992 13.163 7.49992 12.5H12.4999C12.4999 13.163 12.7633 13.7989 13.2322 14.2678C13.701 14.7366 14.3369 15 14.9999 15C15.663 15 16.2988 14.7366 16.7677 14.2678C17.2365 13.7989 17.4999 13.163 17.4999 12.5H19.1666V6.66667C19.1666 5.74167 18.4249 5 17.4999 5H2.49992ZM2.08325 6.25H5.41658V8.33333H2.08325V6.25ZM6.66658 6.25H9.99992V8.33333H6.66658V6.25ZM11.2499 6.25H14.5833V8.33333H11.2499V6.25ZM15.8333 6.25H17.9166V10.8333L15.8333 9.16667V6.25ZM4.99992 11.25C5.33144 11.25 5.64938 11.3817 5.8838 11.6161C6.11822 11.8505 6.24992 12.1685 6.24992 12.5C6.24992 12.8315 6.11822 13.1495 5.8838 13.3839C5.64938 13.6183 5.33144 13.75 4.99992 13.75C4.6684 13.75 4.35046 13.6183 4.11603 13.3839C3.88161 13.1495 3.74992 12.8315 3.74992 12.5C3.74992 12.1685 3.88161 11.8505 4.11603 11.6161C4.35046 11.3817 4.6684 11.25 4.99992 11.25ZM14.9999 11.25C15.3314 11.25 15.6494 11.3817 15.8838 11.6161C16.1182 11.8505 16.2499 12.1685 16.2499 12.5C16.2499 12.8315 16.1182 13.1495 15.8838 13.3839C15.6494 13.6183 15.3314 13.75 14.9999 13.75C14.6684 13.75 14.3505 13.6183 14.116 13.3839C13.8816 13.1495 13.7499 12.8315 13.7499 12.5C13.7499 12.1685 13.8816 11.8505 14.116 11.6161C14.3505 11.3817 14.6684 11.25 14.9999 11.25Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const RoadStop = (props) => <Icon component={RoadStopSVG} {...props} />;
