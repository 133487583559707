import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'antd';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { FormInputProps } from './model/FormInputProps.model';
import { ThemeProps } from './model/ThemeProps.model';
import Select from 'UI/common/components/Select/Select';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';
import { getFromURL } from 'api/getFromURL';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { AnyObject } from 'types/enums/general/general.model';
import { OptionProps } from 'UI/common/components/Select/model/SelectProps.model';

interface ReferenceFieldInputProps extends FormInputProps, ThemeProps {}

const unique = (arr: AnyObject[], key: string) => {
  const keys = new Set();
  return arr.filter((el) => !keys.has(el[key]) && keys.add(el[key]));
};

const ReferenceFieldInput: FC<ReferenceFieldInputProps> = ({ field, object, description, inputProps }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Array<OptionProps>>([]);

  const { control, errors, watch, setValue } = useFormContext();
  const watchField = watch(description.reference);
  const { required, message } = useBaseInputSettings(description);
  const error = _.get(errors, field);

  const objectValue = useMemo(() => _.get(object, field), [field, object]);

  const loadOptions = useCallback(async () => {
    try {
      let value = watchField ?? object?.[description.reference];

      if (description.url && ((Array.isArray(value) && !!value.length) || (!Array.isArray(value) && !!value))) {
        if (Array.isArray(value)) {
          value = value.join(',');
        }

        const url = description.url.replace(`{${description.reference}}`, value);
        const result = await getFromURL.getStatementRepresentationData(url);
        if (result.results.length) result.results = unique(result.results, 'key');

        if (Array.isArray(result)) {
          const options: Array<OptionProps> = result.map((el: AnyObject) => {
            const keys = Object.keys(el);

            if (keys.includes('key') && keys.includes('value')) {
              return {
                value: el.key,
                label: el.value,
              };
            } else if (keys.includes('name')) {
              return {
                value: el.name,
                label: el.name,
              };
            } else {
              return {
                value: el[keys[0]],
                label: el[keys[0]],
              };
            }
          });

          setOptions(options);
        } else {
          const { results } = result;
          const options: Array<OptionProps> = results.map((el: { key: number; value: string }) => ({
            value: el.key,
            label: el.value,
          }));

          setOptions(options);
        }
      } else {
        setOptions([]);
        setValue(field, null);
      }
    } catch (e) {
      setOptions([]);
      setValue(field, null);

      const message = createAlertMessage(e as AnyObject);
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: message,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 5000);
    }
  }, [description.reference, description.url, dispatch, field, object, setValue, watchField]);

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  if (error && error.message?.includes(description.label)) {
    error.message = null;
  }

  const onChange = useCallback(([value, el]) => value ?? null, []);

  return (
    <Form.Item>
      <Controller
        name={field}
        control={control}
        defaultValue={objectValue}
        required={required}
        error={!!error}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        onChange={onChange}
        as={
          <Select
            {...inputProps}
            options={options}
            showSearch
            allowClear
            placeholder={description.label}
            mode={description?.type.includes('multi') ? 'multiple' : 'default'}
          />
        }
      />
    </Form.Item>
  );
};

export default ReferenceFieldInput;
