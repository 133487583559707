import { MapObjects } from 'types/enums/map/MapObjects.model';
import { DataAccessMethod } from '../../../../../../types/enums/routes/request/DataAccessMethod.model';
import { basePath, baseWSPath } from '../../../layersRegistrator';

/**
 * Используется в: создание объектов
 * Прототип объектов для отображения на слое
 * filterLabel определяет лэйбл чекбоксов в фильтре видимости объектов на слое
 * isHidden определяет будет ли объект изначально скрыт
 * urlData задаёт URL для первоначальной загрузки данных (не используется после перехода на swagger, можно удалить?)
 */

interface IRelatedDataPrototypeConstructor {
  name: string;
  filterLabel: string;
  isHidden?: boolean;
  urlData?: string;
  dataAccessMethod?: DataAccessMethod | null;
  layerFilterKey?: string;
  dataGetterComponent?: any | null;
  selectedObjectName: MapObjects | null;
}

export class RelatedDataPrototype {
  name: string;
  filterLabel: string;
  isHidden?: boolean;
  urlData?: string;
  dataAccessMethod?: DataAccessMethod | null;
  layerFilterKey?: string;
  dataGetterComponent?: any | null;
  selectedObjectName: MapObjects | null;
  basePathTemplate: string = basePath;
  baseWSPathTemplate: string = baseWSPath;
  uploadFullData?: () => Promise<any | null> = undefined;

  constructor({
    name,
    filterLabel = '',
    urlData,
    isHidden,
    dataAccessMethod = DataAccessMethod.httpGetAll,
    layerFilterKey = 'id',
    dataGetterComponent = null,
    selectedObjectName = null,
  }: IRelatedDataPrototypeConstructor) {
    this.name = name;
    this.filterLabel = filterLabel ? filterLabel : name;
    this.isHidden = isHidden;
    this.dataAccessMethod = dataAccessMethod;
    if (dataAccessMethod === DataAccessMethod.websocket) {
      this.urlData = this.baseWSPathTemplate + urlData;
    } else {
      this.urlData = this.basePathTemplate + urlData;
    }
    this.dataGetterComponent = dataGetterComponent;
    this.layerFilterKey = layerFilterKey;
    this.selectedObjectName = selectedObjectName;
  }

  getName() {
    return this.name;
  }

  getFilterLabel() {
    return this.filterLabel;
  }

  getUrlData() {
    return this.urlData;
  }

  getDataAccessMethod() {
    return this.dataAccessMethod;
  }

  getLayerFilterKey() {
    return this.layerFilterKey;
  }

  getSelectedObjectName() {
    return this.selectedObjectName;
  }

  getDataGetterComponent() {
    return this.dataGetterComponent;
  }

  async uploadData(): Promise<any> {
    return undefined;
  }

  async uploadModel(): Promise<any | null> {
    return null;
  }

  async readRecord(id: number): Promise<any | null> {
    return null;
  }

  //Переключалка видимости объекта
  toggleVisible(value: boolean) {
    return (this.isHidden = value ?? !this.isHidden);
  }

  getIsHidden() {
    return this.isHidden;
  }
}
