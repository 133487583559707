import { MapController } from '@deck.gl/core';

import MapEventObserver from 'store/rakes/MapEventObserver';

export default class MyMapController extends MapController {
  // @ts-ignore - ts не прав
  handleEvent(event: any, ...others: any) {
    if (event.type === 'wheel' && MapEventObserver.checkEventLock()) {
      event.stopImmediatePropagation();
    } else if (event.type === 'panstart' && MapEventObserver.checkEventLock()) {
      event.stopImmediatePropagation();
    } else if (event.type === 'doubletap' && MapEventObserver.checkEventLock()) {
      event.stopImmediatePropagation();
    } else {
      super.handleEvent(event);
    }
  }
}
