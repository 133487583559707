import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import { Modal } from 'antd';
import { AnyObject } from 'types/enums/general/general.model';
import { Button } from 'UI/common/components/Button/Button';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { useEffect } from 'react';
import { initialModel } from '../../../interfacesApplications/templates';
import { getFromURL } from 'api/getFromURL';
import ApplicationForm from '../../../ApplicationForm/ApplicationForm';
import { useDispatch } from 'react-redux';
import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { Styled } from '../SemifinishedObjectForm.styled';
import { ModalObjectContainerProps } from './model/ModalObjectContainer.model';

const { SaveChangeButton } = Styled;

export const ModalObjectContainer = (props: PropsWithChildren<ModalObjectContainerProps>) => {
  const dispatch = useDispatch();
  const [customFieldsData, setCustomFieldsData] = useState<AnyObject>({});
  const [model, setModel] = useState<ModelResponse>(initialModel);
  const [data, setData] = useState<AnyObject>({});

  const formRef = useRef<HTMLFormElement>(null);

  const { isOpenModal, setOpenModal, object, refreshData } = props;
  const { url, id } = object ?? {};

  useEffect(() => {
    const downloadObjectData = async () => {
      const promiseModel = getFromURL.getModelFromURL(url);
      const promiseData = getFromURL.getAll(`${url}${id}/`);
      const resultData = await promiseData;
      const resultModel = await promiseModel;
      setData(resultData);
      setModel(resultModel);
    };
    downloadObjectData();
  }, [id, object, url]);

  const hideModal = useCallback(() => setOpenModal(false), [setOpenModal]);

  const initSubmitForm = useCallback(() => {
    formRef.current?.submit();
  }, []);

  const handleSubmitForm = useCallback(
    (submitData: AnyObject) => {
      const { url } = object ?? {};
      if (url) {
        dispatch(
          submitObjectSagaErrorResponse({
            url,
            data: { ...data, ...submitData, ...customFieldsData },
            setReturnResponse: () => {
              refreshData();
              hideModal();
            },
          })
        );
      }
    },
    [customFieldsData, data, dispatch, hideModal, object, refreshData]
  );

  const form = useMemo(
    () => ({
      showTime: true,
      dateTime: {},
      setDateTime: () => {},
      setCustomFieldsData,
    }),
    []
  );

  const InlineDrawer = useMemo(
    () => (
      <ApplicationForm
        nonTitle={true}
        handleSubmitForm={handleSubmitForm}
        model={model}
        data={data}
        ref={formRef}
        form={form}
      />
    ),
    [data, form, handleSubmitForm, model]
  );

  return (
    <Modal
      title={`Детализация объекта ${object?.object_name}`}
      visible={isOpenModal}
      onOk={hideModal}
      onCancel={hideModal}
      centered={true}
      width={800}
      footer={
        <SaveChangeButton>
          <Button className={'shitTrader'} onClick={initSubmitForm}>
            Сохранить изменения
          </Button>
        </SaveChangeButton>
      }
    >
      {InlineDrawer}
    </Modal>
  );
};
