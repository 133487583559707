export const work_shifts = {
  work_shift: {
    x: 0,
    y: 0,
    width: 128,
    height: 128,
  },
  work_shift_active: {
    x: 129,
    y: 0,
    width: 128,
    height: 128,
  },
  work_shift_drop: {
    x: 0,
    y: 129,
    width: 128,
    height: 128,
  },
  work_shift_drop_active: {
    x: 129,
    y: 129,
    width: 128,
    height: 128,
  },
};
