import React from 'react';
import Icon from '@ant-design/icons';

const LockStartedPageIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99998 14.1667C9.55795 14.1667 9.13403 13.9911 8.82147 13.6786C8.50891 13.366 8.33331 12.9421 8.33331 12.5C8.33331 11.575 9.07498 10.8334 9.99998 10.8334C10.442 10.8334 10.8659 11.009 11.1785 11.3215C11.4911 11.6341 11.6666 12.058 11.6666 12.5C11.6666 12.9421 11.4911 13.366 11.1785 13.6786C10.8659 13.9911 10.442 14.1667 9.99998 14.1667ZM15 16.6667V8.33337H4.99998V16.6667H15ZM15 6.66671C15.442 6.66671 15.8659 6.8423 16.1785 7.15486C16.491 7.46742 16.6666 7.89135 16.6666 8.33337V16.6667C16.6666 17.1087 16.491 17.5327 16.1785 17.8452C15.8659 18.1578 15.442 18.3334 15 18.3334H4.99998C4.55795 18.3334 4.13403 18.1578 3.82147 17.8452C3.50891 17.5327 3.33331 17.1087 3.33331 16.6667V8.33337C3.33331 7.40837 4.07498 6.66671 4.99998 6.66671H5.83331V5.00004C5.83331 3.89497 6.2723 2.83516 7.0537 2.05376C7.8351 1.27236 8.89491 0.833374 9.99998 0.833374C10.5472 0.833374 11.089 0.941148 11.5945 1.15054C12.1 1.35994 12.5593 1.66685 12.9463 2.05376C13.3332 2.44067 13.6401 2.9 13.8495 3.40553C14.0589 3.91105 14.1666 4.45287 14.1666 5.00004V6.66671H15ZM9.99998 2.50004C9.33694 2.50004 8.70105 2.76343 8.23221 3.23227C7.76337 3.70111 7.49998 4.337 7.49998 5.00004V6.66671H12.5V5.00004C12.5 4.337 12.2366 3.70111 11.7677 3.23227C11.2989 2.76343 10.663 2.50004 9.99998 2.50004Z"
      fill="currentColor"
    />
  </svg>
);

export const PasswordIcon = (props) => <Icon component={() => LockStartedPageIconSVG(props.error)} {...props} />;
