import { CompositeLayer } from '@deck.gl/core';
import { ScatterplotLayer, TextLayer } from '@deck.gl/layers';
import wkt from 'wkt';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { DSelector } from 'services/map/Dselector/DSelector';
import { Settings } from '../../../types/enums/map/layers/Settings';

class ElectricalSubstationsCompositeLayers extends CompositeLayer {
  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.substationsLayer;
        if (event.sourceLayer.id.includes('cargo-position-point')) {
          selectedObjectType = MapObjects.substations;
        }
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }
  renderLayers() {
    const radius = 1 * this.props?.[Settings.radius] ?? 1;
    const sizeScale = 10 * this.props?.[Settings.sizeScale] ?? 1;

    if (!this.props.relatedData) return [];
    return [
      new ScatterplotLayer(this.props, this.getSubLayerProps({ id: 'cargo-position-point' }), {
        pickable: true,
        data: this.props.relatedData.substations,
        stroked: true,
        filled: true,
        radiusScale: 2,
        elevationScale: 20,
        radiusMinPixels: 20,
        radiusMaxPixels: 100,
        lineWidthMinPixels: 1,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d?.location) {
            const parsed = wkt.parse(d?.location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }
          } else {
            return [100, 100];
          }
        },
        getRadius: radius,
        opacity: 0.8,
        wireframe: true,
        getFillColor: [60, 140, 0, 100],
        getLineColor: [80, 80, 80, 100],
        updateTriggers: {
          getRadius: this.props.radius,
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'cargo-position-point-text' }), {
        pickable: true,
        data: this.props.relatedData.substations,
        getPosition: (d) => {
          if (d?.location) {
            const parsed = wkt.parse(d?.location);
            if (parsed) {
              return parsed.coordinates;
            } else {
              return [100, 100];
            }
          } else {
            return [100, 100];
          }
        },
        getText: (d) => d?.name.toUpperCase(),
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        backgroundColor: [255, 255, 255],
        billboard: true,
        getOpacity: 1,
        sizeScale: 1,
        getSize: sizeScale,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        sizeMinPixels: 0,
        sizeMaxPixels: 16,
        updateTriggers: {
          getSize: this.state.sizeScale,
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...ScatterplotLayer.defaultProps,
  // Label for each feature
  getLabel: { type: 'accessor', value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: 'accessor', value: 8 },
  // Label color for each feature
  getLabelColor: { type: 'accessor', value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: 'accessor', value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: 'pixels',
  // Label background color
  labelBackground: { type: 'color', value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: 'Monaco, monospace',
};

ElectricalSubstationsCompositeLayers.layerName = 'TrackingCargoPoint';
ElectricalSubstationsCompositeLayers.defaultProps = defaultProps;

export default ElectricalSubstationsCompositeLayers;
