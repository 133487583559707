import { UdsLayerGroup } from './UdsLayerGroup';
import { DefaultLayerGroup } from './DefaultLayerGroup';
import { DutssdLayerGroup } from './Dutssd';
import { MsdidiLayerGroup } from './Msdidi';

export const projectLayerGroups = {
  UdsLayerGroup,
  DefaultLayerGroup,
  DutssdLayerGroup,
  MsdidiLayerGroup,
};
