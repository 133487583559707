import { ObjectType } from './ObjectType';
import { DetectionMask, MaskTypeEnum } from 'generated/api/api';
import { Shape } from 'konva/lib/Shape';
import { Stage } from 'konva/lib/Stage';

export type TypePoints = number[][];

export type MaskPoints = {
  [key in ObjectType]?: TypePoints;
};

export interface MaskObjectList {
  type: ObjectType;
  name: string;
  help: string;
}

export interface PolygonConfig {
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  closed?: boolean;
}

interface MaskObject {
  id?: number;
  name: null | string;
  camera: number;
  mask_type: string;
  roi_points: TypePoints | null;
  control_line_points: TypePoints | null;
  measure_line_points: TypePoints | null;
}

abstract class CameraMaskPrototype {
  protected static maskTypeName: string;
  protected static maskType: MaskTypeEnum;
  protected static maskObjectList: Array<MaskObjectList>;
  protected static polygonConfig: PolygonConfig = {
    fill: '',
    stroke: '',
    strokeWidth: 4,
    closed: true,
  };

  id: number;
  name?: string;
  tmpName?: string;
  maskID?: number;
  cameraID: number;
  currentDrawType: ObjectType | null = null;
  _maskPoints: MaskPoints = {};
  _drawMaskPoints: MaskPoints = {};
  visible = true;

  protected constructor(cameraID: number, mask: DetectionMask | null = null) {
    this.id = mask?.id ?? Math.trunc(Math.random() * 100000);
    this.cameraID = cameraID;

    if (mask?.id) this.maskID = mask.id;
    if (mask?.name) this.name = mask.name;

    for (const object of this.maskObjectList) {
      //TODO Убрать после изменений бэка DetectionMask Тех-долг-FRONTEND #5559
      //@ts-ignore
      this._maskPoints[object.type] = mask?.[object.type] ?? [];
    }
  }

  static getMaskTypeName(): string {
    return CameraMaskPrototype.maskTypeName;
  }

  static getMaskType(): string {
    return CameraMaskPrototype.maskType;
  }

  static getMaskObjectList(): Array<MaskObjectList> {
    return CameraMaskPrototype.maskObjectList;
  }

  abstract get maskTypeName(): string;

  abstract get polygonConfig(): PolygonConfig;

  abstract get maskType(): MaskTypeEnum;

  get maskObjectList(): Array<MaskObjectList> {
    return CameraMaskPrototype.maskObjectList;
  }

  get help(): string | null {
    return this.maskObjectList.find((el) => el.type === this.currentDrawType)?.help ?? null;
  }

  get maskObject() {
    const object: MaskObject = {
      camera: this.cameraID,
      name: this.name ?? null,
      mask_type: this.maskType,
      roi_points: null,
      control_line_points: null,
      measure_line_points: null,
    };

    if (this.maskID) object.id = this.maskID;

    for (const geometry of this.maskObjectList) {
      const type = geometry.type;
      object[type] = this._maskPoints[type] ?? null;
    }

    return object;
  }

  get maskPoints(): MaskPoints {
    return this._maskPoints;
  }

  get drawMaskPoints(): MaskPoints {
    return this._drawMaskPoints;
  }

  get drawPoints(): TypePoints {
    if (this.currentDrawType) {
      return this._drawMaskPoints[this.currentDrawType] ?? [];
    }

    return [];
  }

  set drawMaskPoints(points: MaskPoints) {
    this._drawMaskPoints = points;
  }

  initialDraw(): void {
    this.currentDrawType = this.maskObjectList[0].type;
    this.tmpName = this.name;
    this._drawMaskPoints = { ...this._maskPoints };
    this.visible = false;
  }

  saveDraw(): void {
    this.name = this.tmpName;
    this._maskPoints = { ...this._drawMaskPoints };
    this.finishDraw();
  }

  finishDraw(): void {
    this.currentDrawType = null;
    this.tmpName = '';
    this._drawMaskPoints = {};
    this.visible = true;
  }

  // Проверка валидности всех геометрий масок
  checkRules(): boolean {
    const maskObjectList = this.maskObjectList;

    for (const object of maskObjectList) {
      const points = this._drawMaskPoints[object.type];
      if (points) {
        switch (object.type) {
          case ObjectType.roiPoints: {
            if (points?.length < 4) {
              return false;
            }
            break;
          }
          case ObjectType.controlLinePoints:
          case ObjectType.measureLinePoints: {
            if (points?.length !== 2) {
              return false;
            }
            break;
          }
        }
      }
    }

    if (!this.tmpName) {
      return false;
    }

    return true;
  }

  drawHandler(target: Shape | Stage): TypePoints {
    const stage = target.getStage();

    return this.defaultDrawHandler(stage);
  }

  defaultDrawHandler(stage: Stage | null): TypePoints {
    const point = stage?.getPointerPosition();
    let newPoints: TypePoints = [];

    if (this.currentDrawType) {
      const drawPoints = this._drawMaskPoints?.[this.currentDrawType] ?? [];
      newPoints = [...drawPoints];
    }

    if (point) {
      const newPoint = [point.x, point.y];

      newPoints = [...newPoints, newPoint];

      if (
        this.currentDrawType === ObjectType.controlLinePoints ||
        this.currentDrawType === ObjectType.measureLinePoints
      ) {
        if (newPoints.length > 2) newPoints.pop();
      }
    }

    return newPoints;
  }
}

export default CameraMaskPrototype;
