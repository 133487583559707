import React from 'react';
import { Detection } from '../../../../generated/api/api';
import { useModeratorContext } from '../../context/ModeratorContext';
import { Col, Row } from 'antd';
import { Typography } from '@frontend-packages/its-ui-kit';
import { SFileItemContainer } from './styles/ModeratedFileItem.styles';
import moment from 'moment-timezone';

interface ModeratedFileItemProps {
  detectionItem: Detection;
}

export const ModeratedFileItem = (props: ModeratedFileItemProps) => {
  const { detectionItem } = props;
  const { activateDetection, moderation } = useModeratorContext();
  const isActive =
    moderation.activeModeration?.id === detectionItem.id &&
    moderation.activeModeration?.external_id === detectionItem?.external_id;
  const formattedDate = moment(detectionItem.created_at).format('DD.MM.YYYY HH:mm:ss');
  return (
    <SFileItemContainer onClick={() => activateDetection(detectionItem)} isActive={isActive}>
      <Row>
        <Col span={8}>
          <Typography type="type2" className="text">
            {detectionItem.id}/{detectionItem.external_id}
          </Typography>
        </Col>
        <Col span={8}>
          <Typography type="type2" className="text">
            {detectionItem.violation_type_display_name}
          </Typography>
        </Col>
        <Col span={8}>
          <Typography type="type2" className="text">
            {formattedDate}
          </Typography>
        </Col>
      </Row>
    </SFileItemContainer>
  );
};
