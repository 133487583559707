import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'antd';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { getFromURL } from 'api/getFromURL';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { AnyObject } from 'types/enums/general/general.model';
import { OptionProps } from 'UI/common/components/Select/model/SelectProps.model';
import { replaceDrawDataForStandaloneObject } from 'store/reducers/map/actions/mapboxEditableStandaloneObjectActions';
import { FormInputProps } from 'UI/common/form/inputs/model/FormInputProps.model';
import { ThemeProps } from 'UI/common/form/inputs/model/ThemeProps.model';
import { useBaseInputSettings } from 'UI/common/form/hooks/useBaseInputSettings';
import Select from 'UI/common/components/Select/Select';
import { DSelector } from 'services/map/Dselector/DSelector';
import { IFormProps } from 'components/Application/ExtraModules/Applications/ApplicationsForm/interfacesApplications/interfacesApplications';

interface ObjectCharacteristicInputProps extends FormInputProps, ThemeProps {
  formProps?: IFormProps;
}

const ObjectCharacteristicInput: FC<ObjectCharacteristicInputProps> = ({
  field,
  object,
  description,
  inputProps,
  formProps,
}) => {
  const dispatch = useDispatch();
  const [choices, setChoices] = useState<Array<any>>([]);
  const [options, setOptions] = useState<Array<OptionProps>>([]);

  const { control, errors, watch, setValue } = useFormContext();
  const watchField = watch(description.reference);
  const { required, message } = useBaseInputSettings(description);
  const error = _.get(errors, field);

  const objectValue = useMemo(() => _.get(object, field), [field, object]);

  const loadOptions = useCallback(async () => {
    try {
      let value = watchField ?? object?.[description.reference];

      if (description.url && ((Array.isArray(value) && !!value.length) || (!Array.isArray(value) && !!value))) {
        if (Array.isArray(value)) {
          value = value.join(',');
        }

        const url = description.url.replace(`{${description.reference}}`, value);

        const result = await getFromURL.getStatementRepresentationData(url);

        if (Array.isArray(result)) {
          const options: Array<OptionProps> = result.map((el: AnyObject) => {
            const keys = Object.keys(el);

            if (keys.includes('key') && keys.includes('value')) {
              return {
                value: el.key,
                label: el.value,
              };
            } else if (keys.includes('name')) {
              return {
                value: el.name,
                label: el.name,
              };
            } else {
              return {
                value: el[keys[0]],
                label: el[keys[0]],
              };
            }
          });

          setChoices(result);
          setOptions(options);
        } else {
          const { results } = result;
          const options: Array<OptionProps> = results.map((el: { key: number; value: string }) => ({
            value: el.key,
            label: el.value,
          }));

          setChoices(results);
          setOptions(options);
        }
      } else {
        setOptions([]);
        setValue(field, null);
      }
    } catch (e) {
      setOptions([]);
      setValue(field, null);

      const message = createAlertMessage(e as AnyObject);
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: message,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 5000);
    }
  }, [description.reference, description.url, dispatch, field, object, setValue, watchField]);

  useEffect(() => {
    loadOptions();
  }, [loadOptions]);

  if (error && error.message?.includes(description.label)) {
    error.message = null;
  }

  const onChange = useCallback(
    ([value, el]) => {
      const currentChoice = choices.find((el) => {
        if (el.vale === value) return true;

        for (const key in el) {
          if (el[key] === value) return true;
        }

        return false;
      });

      if (currentChoice.point) {
        if (formProps?.setCustomFieldsData) {
          const point = DSelector.getPointString(currentChoice.point);
          formProps.setCustomFieldsData((prevValue: AnyObject) => ({ ...prevValue, point }));
        } else {
          dispatch(
            replaceDrawDataForStandaloneObject({
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: { coordinates: currentChoice.point, type: 'Point' },
                },
              ],
              type: 'FeatureCollection',
            })
          );
        }
      }

      return value ?? null;
    },
    [choices, dispatch, formProps]
  );

  return (
    <Form.Item>
      <Controller
        name={field}
        control={control}
        defaultValue={objectValue}
        required={required}
        error={!!error}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        onChange={onChange}
        as={
          <Select
            {...inputProps}
            options={options}
            showSearch
            allowClear
            placeholder={description.label}
            mode={description?.type.includes('multi') ? 'multiple' : 'default'}
          />
        }
      />
    </Form.Item>
  );
};

export default ObjectCharacteristicInput;
