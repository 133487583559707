interface LayerCardPrototypeConstructor {
  label: string;
  linkToPreview: string;
  dataSize?: string;
  group?: string | null;
  permissionToShowCard: string;
}

export class LayerCardPrototype {
  linkToPreviewPathTemplate = `${process.env.PUBLIC_URL}/img/layerImgs/`;

  label: string;
  linkToPreview: string;
  dataSize: string;
  group: string | null;
  permissionToShowCard: string;

  constructor({
    label,
    linkToPreview,
    dataSize = '5mb',
    group = null,
    permissionToShowCard,
  }: LayerCardPrototypeConstructor) {
    this.label = label;
    this.linkToPreview = this.linkToPreviewPathTemplate + linkToPreview;
    this.dataSize = dataSize;
    this.group = group;
    this.permissionToShowCard = permissionToShowCard;
  }

  getLabel() {
    return this.label;
  }

  getLinkToPreview() {
    return this.linkToPreview;
  }

  getGroup() {
    return this.group;
  }

  getDataSize() {
    return this.dataSize;
  }

  checkUserPermissions(listOfPermissions: string[]) {
    return listOfPermissions.includes(this.permissionToShowCard);
  }
}
