import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { mainPages, mainPagesLabel } from 'types/enums/app/mainPages/mainPages';
import { Styled } from './style/NamePage.styled';

const { LocalMainWrapper, LocalMainTitle } = Styled;

const NamePage = () => {
  const { params } = useRouteMatch<{ mainPage?: mainPages }>();
  const { mainPage } = params;

  const title = mainPage ? mainPagesLabel[mainPage] : mainPagesLabel.default;

  return (
    <LocalMainWrapper>
      <LocalMainTitle>{title}</LocalMainTitle>
    </LocalMainWrapper>
  );
};

export default NamePage;
