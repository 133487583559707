import wkt from 'wkt';
import turfLength from '@turf/length';
import turfPointAlongAtDistance from '@turf/along';
import { getCoords } from '@turf/turf';

export function calculateLinePathCenterCoords(linePath) {
  //linePath should be geojson object
  try {
    const parsed = wkt.parse(linePath);
    const length = turfLength(parsed);
    const center = turfPointAlongAtDistance(parsed, length / 2);
    return getCoords(center);
  } catch (e) {
    console.log(e, ' error in calculateLinePathCenterCoords(), maybe input data is not valid');
  }
}
