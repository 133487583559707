import { AnyObject } from 'types/enums/general/general.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import moment from 'moment-timezone';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import _ from 'lodash';

// Функция подготовки данных перед отправкой на БЕК
export const getPreparedData = (data: AnyObject, model: ModelResponse) => {
  const newData: AnyObject = _.cloneDeep(data);
  const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { scheme } = model;
  Object.keys(newData).forEach((dataKey: string) => {
    const schemaKey: string | undefined = Object.keys(scheme).find((schemeKey: string) => schemeKey === dataKey);
    const type: string | null = schemaKey ? scheme[schemaKey].type : null;
    if (dataKey.includes('inn') || dataKey.includes('snils') || dataKey.includes('phone') || dataKey.includes('kpp')) {
      newData[dataKey] = newData[dataKey]?.replace(/[^0-9.]/g, '');
    }
    switch (type) {
      case SchemaFieldType.datetime:
        newData[dataKey] = newData[dataKey] ? moment.tz(newData[dataKey], userTZ).format('YYYY-MM-DD HH:mm:ssZ') : null;
        break;
      case SchemaFieldType.date:
        newData[dataKey] = newData[dataKey] ? moment(newData[dataKey]).format('YYYY-MM-DD') : null;
        break;
      case SchemaFieldType.time:
        newData[dataKey] = newData[dataKey] ? moment(newData[dataKey]).format('HH:mm:ss') : null;
        break;
      default:
        break;
    }
  });
  return newData;
};
