import React, { useEffect, useState } from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import moment from 'moment';
import { getFromURL } from '../../../api/getFromURL';
import { RequestPath } from '../../../types/enums/routes/request/RequestPath';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const TrafficLightsObjectsSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentStates, setCurrentStates] = useState(null);
  useEffect(() => {
    if (data.id) {
      getFromURL.getDataFromURL(`${RequestPath.trafficLightObject}${data.id}/get_current_plan/`).then((res) => {
        setCurrentPlan(res);
      });
    }
  }, [data.id]);

  useEffect(() => {
    if (data.id) {
      getFromURL.getDataFromURL('/tl_controllers/states/get_current_states/').then((res) => {
        setCurrentStates(res);
      });
    }
  }, [data.id]);

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'traffic_light_object');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.trafficLightObject}/${id}/`, '_blank');
    win.focus();
  };
  const properties = currentPlan;
  const selectItemData = currentStates?.filter((el) => el.tl_controller === data.id);
  const time = moment(properties?.updated_at).format('DD.MM.YYYY / HH:mm:ss');

  let Descriptions = [];
  selectItemData &&
    Descriptions.push({
      key: 'Светофорный объект',
      value: data?.name,
    });

  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  properties?.updated_at &&
    Descriptions.push({
      key: 'Дата и время последнего получения сигнала',
      value: time,
    });
  properties?.updated_at &&
    Descriptions.push({
      key: 'Длительность фазы, сек',
      value: properties.duration,
    });
  properties?.updated_at &&
    Descriptions.push({
      key: 'Время смещения начала фазы, сек',
      value: properties.offset,
    });

  selectItemData &&
    Descriptions.push({
      key: 'Статус',
      value: selectItemData[0].status,
    });
  selectItemData &&
    Descriptions.push({
      key: 'Код',
      value: selectItemData[0].code,
    });
  selectItemData &&
    Descriptions.push({
      key: 'Текущая фаза',
      value: selectItemData[0].phase,
    });
  selectItemData &&
    Descriptions.push({
      key: 'Режим',
      value: selectItemData[0].mode,
    });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о светофорном объекте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default TrafficLightsObjectsSelection;
