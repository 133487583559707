import React from 'react';
import { Styled } from './GeneralDataModelTS.styled';
import PreparingDataContainer from './PreparingDataContainer';

const { LocalModelTSWrapper, LocalSelectedWrapperGRZ, LocalSelectedModelTS } = Styled;

//Todo переименовать компоненты, возможно есть лишняя вложенность Тех-долг-FRONTEND #5594
const GeneralDataModelTS = ({ updateDate, otherData }) => {
  return (
    <LocalModelTSWrapper>
      <LocalSelectedModelTS>
        <PreparingDataContainer updateDate={updateDate} otherData={otherData} />
      </LocalSelectedModelTS>
      <LocalSelectedWrapperGRZ />
    </LocalModelTSWrapper>
  );
};

export default GeneralDataModelTS;
