import { useEffect } from 'react';

export const useRenderCompendiumWithCertainColumns = (
  aggApi,
  columnsData,
  namesCollectionForFiltering,
  doRerenderIfDataChanged
) => {
  const deps = [aggApi, namesCollectionForFiltering];
  doRerenderIfDataChanged && deps.push(columnsData);
  useEffect(() => {
    if (aggApi && columnsData && namesCollectionForFiltering && namesCollectionForFiltering.length) {
      const colsKeys = columnsData.result.map((item) => item.field);
      aggApi.columnApi.setColumnsVisible(colsKeys, false);
      aggApi.columnApi.moveColumns([...namesCollectionForFiltering]);
      aggApi.columnApi.setColumnsVisible(namesCollectionForFiltering, true);
    }
  }, [aggApi, columnsData, namesCollectionForFiltering]);
};
