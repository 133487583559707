import React, { FC, useMemo } from 'react';
import PreparedDescItems from 'UI/custom/components/PreparedDescItems/PreparedDescItems';
import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { Styled } from './PrepareScrollableInfoCard.styled';
import { CloseIcon } from 'UI/custom/icons/actionIcons/CloseIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { IDescriptions } from '../../types/interfaces/map/selection/selection.model';

const { LocalHeaderWrapper, ExtendedBtnClose, LocalHeaderTitle, FooterButtonWrap } = Styled;

interface CamerasUdcCardProps {
  handleCloseCard: () => void;
  title: string;
  openInNewTabBtnDesc?: string;
  listOfDescriptions: Array<IDescriptions>;
  handleOpenItemInDirectory?: () => void;
  settingsCameraComponent: JSX.Element;
}

const CamerasUdcCard: FC<CamerasUdcCardProps> = ({
  handleCloseCard,
  title,
  openInNewTabBtnDesc,
  listOfDescriptions,
  handleOpenItemInDirectory,
  settingsCameraComponent,
}) => {
  const headerStyles = useMemo(() => ({ padding: '14px', position: 'relative' }), []);
  const contentStyles = useMemo(() => ({ padding: '16px 10px 16px 16px' }), []);
  const footerStyles = useMemo(() => ({ padding: '16px' }), []);

  const headerContent = useMemo(
    () => (
      <LocalHeaderWrapper>
        <LocalHeaderTitle>{title}</LocalHeaderTitle>
        <ExtendedBtnClose onClick={handleCloseCard} icon={<CloseIcon />} type={ButtonType.unboundedPrimary} p={0} />
      </LocalHeaderWrapper>
    ),
    [handleCloseCard, title]
  );

  const footerContent = useMemo(
    () => <FooterButtonWrap>{settingsCameraComponent}</FooterButtonWrap>,
    [settingsCameraComponent]
  );

  return (
    <ScrollableCard
      headerStyles={headerStyles}
      headerContent={headerContent}
      contentStyles={contentStyles}
      children={<>{listOfDescriptions && <PreparedDescItems listOfDescriptions={listOfDescriptions} />}</>}
      footerStyles={footerStyles}
      footerContent={footerContent}
    />
  );
};

export default CamerasUdcCard;
