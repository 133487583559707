export const camerasMapper = {
  work_empty: {
    x: 0,
    y: 66,
    width: 32,
    height: 32,
  },
  work_empty_active: {
    x: 33,
    y: 66,
    width: 32,
    height: 32,
  },
  work_filled: {
    x: 33,
    y: 0,
    width: 32,
    height: 32,
  },
  work_filled_active: {
    x: 33,
    y: 33,
    width: 32,
    height: 32,
  },
  not_working: {
    x: 0,
    y: 33,
    width: 32,
    height: 32,
  },
  not_working_active: {
    x: 0,
    y: 0,
    width: 32,
    height: 32,
  },
};
