import React, { PropsWithChildren } from 'react';
import { Styled } from '../../styles/Panel.styled';

const { LocalHeaderWrap } = Styled;

const PanelHeader = ({ children, ...other }: PropsWithChildren<{}>) => {
  return <LocalHeaderWrap {...other}>{children}</LocalHeaderWrap>;
};

export default PanelHeader;
