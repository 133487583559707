export const signsRoadMapperType1 = {
  1.1: { x: 0, y: 0, width: 128, height: 180 },
  '1.1-G': { x: 129, y: 0, width: 128, height: 180 },
  '1.1-R': { x: 0, y: 181, width: 128, height: 180 },
  1.2: { x: 129, y: 181, width: 128, height: 180 },
  '1.2-G': { x: 258, y: 0, width: 128, height: 180 },
  '1.2-R': { x: 258, y: 181, width: 128, height: 180 },
  '1.3.1': { x: 1548, y: 0, width: 128, height: 151 },
  '1.3.1-G': { x: 1548, y: 152, width: 128, height: 151 },
  '1.3.1-R': { x: 1548, y: 304, width: 128, height: 151 },
  '1.3.2': { x: 1032, y: 1448, width: 120, height: 180 },
  '1.3.2-G': { x: 1153, y: 1448, width: 120, height: 180 },
  '1.3.2-R': { x: 1274, y: 1448, width: 120, height: 180 },
  '1.4.1': { x: 1677, y: 0, width: 68, height: 180 },
  '1.4.1-G': { x: 1677, y: 181, width: 68, height: 180 },
  '1.4.1-R': { x: 1677, y: 362, width: 68, height: 180 },
  '1.4.2': { x: 1677, y: 543, width: 68, height: 180 },
  '1.4.2-G': { x: 1677, y: 724, width: 68, height: 180 },
  '1.4.2-R': { x: 1677, y: 905, width: 68, height: 180 },
  '1.4.3': { x: 1677, y: 1086, width: 68, height: 180 },
  '1.4.3-G': { x: 1677, y: 1267, width: 68, height: 180 },
  '1.4.3-R': { x: 1677, y: 1448, width: 68, height: 180 },
  '1.4.4': { x: 1746, y: 0, width: 68, height: 180 },
  '1.4.4-G': { x: 1746, y: 181, width: 68, height: 180 },
  '1.4.4-R': { x: 1746, y: 362, width: 68, height: 180 },
  '1.4.5': { x: 1746, y: 543, width: 68, height: 180 },
  '1.4.5-G': { x: 1746, y: 724, width: 68, height: 180 },
  '1.4.5-R': { x: 1746, y: 905, width: 68, height: 180 },
  '1.4.6': { x: 1746, y: 1086, width: 68, height: 180 },
  '1.4.6-G': { x: 1746, y: 1267, width: 68, height: 180 },
  '1.4.6-R': { x: 1746, y: 1448, width: 68, height: 180 },
  1.5: { x: 387, y: 0, width: 128, height: 180 },
  '1.5-G': { x: 387, y: 181, width: 128, height: 180 },
  '1.5-R': { x: 0, y: 362, width: 128, height: 180 },
  1.6: { x: 129, y: 362, width: 128, height: 180 },
  '1.6-G': { x: 258, y: 362, width: 128, height: 180 },
  '1.6-R': { x: 387, y: 362, width: 128, height: 180 },
  1.7: { x: 516, y: 0, width: 128, height: 180 },
  '1.7-G': { x: 516, y: 181, width: 128, height: 180 },
  '1.7-R': { x: 516, y: 362, width: 128, height: 180 },
  1.8: { x: 0, y: 543, width: 128, height: 180 },
  '1.8-G': { x: 129, y: 543, width: 128, height: 180 },
  '1.8-R': { x: 258, y: 543, width: 128, height: 180 },
  1.9: { x: 387, y: 543, width: 128, height: 180 },
  '1.9-G': { x: 516, y: 543, width: 128, height: 180 },
  '1.9-R': { x: 645, y: 0, width: 128, height: 180 },
  '1.10': { x: 1395, y: 1448, width: 120, height: 172 },
  '1.10-G': { x: 645, y: 181, width: 128, height: 180 },
  '1.10-R': { x: 645, y: 362, width: 128, height: 180 },
  '1.11.1': { x: 645, y: 543, width: 128, height: 180 },
  '1.11.1-G': { x: 774, y: 0, width: 128, height: 180 },
  '1.11.1-R': { x: 774, y: 181, width: 128, height: 180 },
  '1.11.2': { x: 774, y: 362, width: 128, height: 180 },
  '1.11.2-G': { x: 774, y: 543, width: 128, height: 180 },
  '1.11.2-R': { x: 0, y: 724, width: 128, height: 180 },
  '1.12.1': { x: 129, y: 724, width: 128, height: 180 },
  '1.12.1-G': { x: 258, y: 724, width: 128, height: 180 },
  '1.12.1-R': { x: 387, y: 724, width: 128, height: 180 },
  '1.12.2': { x: 516, y: 724, width: 128, height: 180 },
  '1.12.2-G': { x: 645, y: 724, width: 128, height: 180 },
  '1.12.2-R': { x: 774, y: 724, width: 128, height: 180 },
  1.13: { x: 903, y: 0, width: 128, height: 180 },
  '1.13-G': { x: 903, y: 181, width: 128, height: 180 },
  '1.13-R': { x: 903, y: 362, width: 128, height: 180 },
  1.14: { x: 903, y: 543, width: 128, height: 180 },
  '1.14-G': { x: 903, y: 724, width: 128, height: 180 },
  '1.14-R': { x: 0, y: 905, width: 128, height: 180 },
  1.15: { x: 129, y: 905, width: 128, height: 180 },
  '1.15-G': { x: 258, y: 905, width: 128, height: 180 },
  '1.15-R': { x: 387, y: 905, width: 128, height: 180 },
  1.16: { x: 516, y: 905, width: 128, height: 180 },
  '1.16-G': { x: 645, y: 905, width: 128, height: 180 },
  '1.16-R': { x: 774, y: 905, width: 128, height: 180 },
  1.17: { x: 903, y: 905, width: 128, height: 180 },
  '1.17-G': { x: 1032, y: 0, width: 128, height: 180 },
  '1.17-R': { x: 1032, y: 181, width: 128, height: 180 },
  1.18: { x: 1032, y: 362, width: 128, height: 180 },
  '1.18-G': { x: 1032, y: 543, width: 128, height: 180 },
  '1.18-R': { x: 1032, y: 724, width: 128, height: 180 },
  1.19: { x: 1032, y: 905, width: 128, height: 180 },
  '1.19-G': { x: 0, y: 1086, width: 128, height: 180 },
  '1.19-R': { x: 129, y: 1086, width: 128, height: 180 },
  '1.20.1': { x: 258, y: 1086, width: 128, height: 180 },
  '1.20.1-G': { x: 387, y: 1086, width: 128, height: 180 },
  '1.20.1-R': { x: 516, y: 1086, width: 128, height: 180 },
  '1.20.2': { x: 645, y: 1086, width: 128, height: 180 },
  '1.20.2-G': { x: 774, y: 1086, width: 128, height: 180 },
  '1.20.2-R': { x: 903, y: 1086, width: 128, height: 180 },
  '1.20.3': { x: 1032, y: 1086, width: 128, height: 180 },
  '1.20.3-G': { x: 1161, y: 0, width: 128, height: 180 },
  '1.20.3-R': { x: 1161, y: 181, width: 128, height: 180 },
  1.21: { x: 1161, y: 362, width: 128, height: 180 },
  '1.21-G': { x: 1161, y: 543, width: 128, height: 180 },
  '1.21-R': { x: 1161, y: 724, width: 128, height: 180 },
  1.22: { x: 1161, y: 905, width: 128, height: 180 },
  '1.22-G': { x: 1161, y: 1086, width: 128, height: 180 },
  '1.22-R': { x: 1290, y: 0, width: 128, height: 180 },
  1.23: { x: 1290, y: 181, width: 128, height: 180 },
  '1.23-G': { x: 1290, y: 362, width: 128, height: 180 },
  '1.23-R': { x: 1290, y: 543, width: 128, height: 180 },
  1.24: { x: 1290, y: 724, width: 128, height: 180 },
  '1.24-G': { x: 1290, y: 905, width: 128, height: 180 },
  '1.24-R': { x: 1290, y: 1086, width: 128, height: 180 },
  1.25: { x: 0, y: 1267, width: 128, height: 180 },
  '1.25-G': { x: 129, y: 1267, width: 128, height: 180 },
  '1.25-R': { x: 258, y: 1267, width: 128, height: 180 },
  1.26: { x: 387, y: 1267, width: 128, height: 180 },
  '1.26-G': { x: 516, y: 1267, width: 128, height: 180 },
  '1.26-R': { x: 645, y: 1267, width: 128, height: 180 },
  1.27: { x: 774, y: 1267, width: 128, height: 180 },
  '1.27-G': { x: 903, y: 1267, width: 128, height: 180 },
  '1.27-R': { x: 1032, y: 1267, width: 128, height: 180 },
  1.28: { x: 1161, y: 1267, width: 128, height: 180 },
  '1.28-G': { x: 1290, y: 1267, width: 128, height: 180 },
  '1.28-R': { x: 1419, y: 0, width: 128, height: 180 },
  1.29: { x: 1419, y: 181, width: 128, height: 180 },
  '1.29-G': { x: 1419, y: 362, width: 128, height: 180 },
  '1.29-R': { x: 1419, y: 543, width: 128, height: 180 },
  '1.30': { x: 1419, y: 724, width: 128, height: 180 },
  '1.30-G': { x: 1419, y: 905, width: 128, height: 180 },
  '1.30-R': { x: 1419, y: 1086, width: 128, height: 180 },
  1.31: { x: 1419, y: 1267, width: 128, height: 180 },
  '1.31-G': { x: 0, y: 1448, width: 128, height: 180 },
  '1.31-R': { x: 129, y: 1448, width: 128, height: 180 },
  1.32: { x: 258, y: 1448, width: 128, height: 180 },
  '1.32-G': { x: 387, y: 1448, width: 128, height: 180 },
  '1.32-R': { x: 516, y: 1448, width: 128, height: 180 },
  1.33: { x: 645, y: 1448, width: 128, height: 180 },
  '1.33-G': { x: 774, y: 1448, width: 128, height: 180 },
  '1.33-R': { x: 903, y: 1448, width: 128, height: 180 },
  '1.34.1.1': { x: 1548, y: 456, width: 128, height: 101 },
  '1.34.1.1-G': { x: 1548, y: 558, width: 128, height: 101 },
  '1.34.1.1-R': { x: 1548, y: 660, width: 128, height: 101 },
  '1.34.1.2': { x: 903, y: 1629, width: 71, height: 101 },
  '1.34.1.2-G': { x: 975, y: 1629, width: 71, height: 101 },
  '1.34.1.2-R': { x: 1047, y: 1629, width: 71, height: 101 },
  '1.34.1.3': { x: 1551, y: 1629, width: 41, height: 101 },
  '1.34.1.3-G': { x: 1593, y: 1629, width: 41, height: 101 },
  '1.34.1.3-R': { x: 1635, y: 1629, width: 41, height: 101 },
  '1.34.1': { x: 1548, y: 762, width: 128, height: 101 },
  '1.34.1-G': { x: 1548, y: 864, width: 128, height: 101 },
  '1.34.1-R': { x: 1548, y: 966, width: 128, height: 101 },
  '1.34.2.1': { x: 1548, y: 1068, width: 128, height: 101 },
  '1.34.2.1-G': { x: 1548, y: 1170, width: 128, height: 101 },
  '1.34.2.1-R': { x: 1548, y: 1272, width: 128, height: 101 },
  '1.34.2.2': { x: 1119, y: 1629, width: 71, height: 101 },
  '1.34.2.2-G': { x: 1191, y: 1629, width: 71, height: 101 },
  '1.34.2.2-R': { x: 1263, y: 1629, width: 71, height: 101 },
  '1.34.2.3': { x: 1677, y: 1629, width: 41, height: 101 },
  '1.34.2.3-G': { x: 1746, y: 1629, width: 41, height: 101 },
  '1.34.2.3-R': { x: 0, y: 1731, width: 41, height: 101 },
  '1.34.2': { x: 1548, y: 1374, width: 128, height: 101 },
  '1.34.2-G': { x: 1548, y: 1476, width: 128, height: 101 },
  '1.34.2-R': { x: 0, y: 1629, width: 128, height: 101 },
  '1.34.3.1': { x: 129, y: 1629, width: 128, height: 101 },
  '1.34.3.1-G': { x: 258, y: 1629, width: 128, height: 101 },
  '1.34.3.1-R': { x: 387, y: 1629, width: 128, height: 101 },
  '1.34.3.2': { x: 1335, y: 1629, width: 71, height: 101 },
  '1.34.3.2-G': { x: 1407, y: 1629, width: 71, height: 101 },
  '1.34.3.2-R': { x: 1479, y: 1629, width: 71, height: 101 },
  '1.34.3': { x: 516, y: 1629, width: 128, height: 101 },
  '1.34.3-G': { x: 645, y: 1629, width: 128, height: 101 },
  '1.34.3R': { x: 774, y: 1629, width: 128, height: 101 },
};
