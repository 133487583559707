import { Layers } from 'types/enums/map/layers/Layers';
import ITSLayer from 'types/classes/ITSLayer';
import { IconLayer, PolygonLayer, TextLayer } from '@deck.gl/layers';
import { Viewport } from '@deck.gl/core';
import { Camera } from 'generated/api/api';
import { DSelector } from 'services/map/Dselector/DSelector';
import sector from 'services/map/sector/sector';
import Supercluster, { ClusterFeature, PointFeature, AnyProps } from 'supercluster';
import * as turf from '@turf/turf';

import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { camerasMapper } from '../../iconMappers/camerasMapper';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { layerColorsMapper } from 'layers/colorMappers/layerColorsMapper';
import { Position } from '@deck.gl/core';
import { UpdateStateInfo } from '@deck.gl/core/lib/layer';
import { roadAccidentsIconMapping } from 'layers/iconMappers/roadAccidentsIconMapping';
import { BBox } from 'geojson';

const defaultSectorRadius = 0.2;
const ICON_CLUSTER_SIZE = 25;

interface ExtendedViewport extends Viewport {
  getBounds?: () => BBox;
}

class CamerasUdcCompositeLayer extends ITSLayer<any, any> {
  shouldUpdateState({ oldProps, props }: UpdateStateInfo<any>) {
    const isChangeProps = oldProps !== props;
    const isMissingDataZoom = !this.state.previousZoom;
    const isZoomedTooMuch = Math.abs(this.context.viewport.zoom - this.state.previousZoom) > 1;
    return isChangeProps || isMissingDataZoom || isZoomedTooMuch;
  }

  initializeState() {
    this.setState({
      clusterData: [],
    });
  }

  updateState({ props }: UpdateStateInfo<any>) {
    const data: Camera[] = props.relatedData[MapObjects.camerasUdc];
    if (data?.length) {
      const { switchCluster } = this.props;
      const clusters: (ClusterFeature<AnyProps> | PointFeature<AnyProps>)[] = [];
      let cameres: (ClusterFeature<AnyProps> | PointFeature<AnyProps>)[] = [];

      const prepareCheckpoints = data.map((camera: Camera) => {
        const coordinates = DSelector.getPositionFromPoint(camera);
        return turf.point(coordinates, camera) as PointFeature<Camera>;
      });

      if (switchCluster) {
        const index = new Supercluster({ radius: 120, maxZoom: 15 });
        index.load(prepareCheckpoints);
        const viewport: ExtendedViewport = this.context.viewport;

        const bbox = (viewport?.getBounds && viewport?.getBounds()) || [0, 0, 0, 0];

        const clusterData = index.getClusters(bbox, Math.floor(viewport.zoom));

        clusterData.forEach((el) => (el.properties.cluster ? clusters.push(el) : cameres.push(el)));
      } else {
        cameres = prepareCheckpoints;
      }

      this.setState({
        previousZoom: this.context.viewport.zoom,
        clusters,
        cameres,
      });
    }
  }

  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType: string;
        if (event.sourceLayer.id.includes('cameras-udc')) {
          selectedObjectType = MapObjects.camerasUdc;
          this.props.onClickHandler(event.info, selectedObjectType, Layers.camerasUdc);
        }
      }
    }
    return event.info;
  }

  renderLayers() {
    if (!this.state.clusters?.length && !this.state.cameres?.length) return [];

    const { sizeScale = 1, opacity = 1 } = this.props;

    return [
      new IconLayer<ClusterFeature<Camera>>({
        id: 'checkpoint-cluster-icon-layer',
        data: this.state.clusters,
        iconMapping: roadAccidentsIconMapping,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_accidents_icon.png`,
        getPosition: (el) => el.geometry.coordinates as [number, number],
        getIcon: () => 'cluster_blue_white',
        getSize: () => ICON_CLUSTER_SIZE,
        sizeScale,
        opacity,
      }),
      new TextLayer<ClusterFeature<Camera>>({
        id: 'checkpoint-cluster-text-layer',
        data: this.state.clusters,
        fontWeight: 'bold',
        getText: (el) => `${el.properties.point_count}`,
        getPosition: (el) => el.geometry.coordinates as [number, number],
        getColor: layerColorsMapper.blueTranslucentDark,
        getSize: 15 * sizeScale,
        opacity,
      }),
      new PolygonLayer<PointFeature<Camera>>({
        id: 'intersection-polygon',
        data: this.state.cameres,
        getPolygon: (d) => {
          const center = DSelector.getPositionFromPoint(d.properties);
          const azimuth = d.properties.azimuth ?? 0;
          const halfAngleOfView = (d.properties.angle_of_view ?? 0) / 2;
          const bearing1 = azimuth - halfAngleOfView;
          const bearing2 = azimuth + halfAngleOfView;

          const activSector = sector(center, defaultSectorRadius * sizeScale, bearing1, bearing2);

          return activSector.geometry.coordinates as Position[][];
        },
        getFillColor: layerColorsMapper.blueTranslucentDark,
        getLineColor: layerColorsMapper.blueTranslucentDark,
        getLineWidth: 1,
        stroked: true,
        filled: true,
        wireframe: true,
        lineJointRounded: true,
        lineWidthMinPixels: 1,
        opacity,
      }),
      new IconLayer<PointFeature<Camera>>({
        id: 'cameras-udc-layer',
        data: this.state.cameres,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/cameras.png`,
        iconMapping: camerasMapper,
        pickable: true,
        autoHighlight: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: ({ properties: d }) => DSelector.getPositionFromPoint(d),
        sizeScale,
        getSize: 25,
        opacity,
        getIcon: ({ properties: d }) => {
          let icon: string;

          if (d.is_working) {
            icon = d.has_detection_masks ? 'work_filled' : 'work_empty';
          } else {
            icon = 'not_working';
          }

          if (d.id === this.props?.selectedObject?.selectedObject?.object?.id) icon += '_active';

          return icon;
        },
      }),
    ];
  }
}

CamerasUdcCompositeLayer.layerName = Layers.camerasUdc;

export default CamerasUdcCompositeLayer;
