import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { History } from 'history';

import { getFromURL } from 'api/getFromURL';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import _ from 'underscore';
import { Styled } from './ObjectPanelApplications.styled';
import {
  IApplicationsContext,
  CargoTransportParams,
} from '../../../ApplicationsForm/interfacesApplications/interfacesApplications';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { ApplicationsContext } from '../../../ApplicationsRouter';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { ObjectPanelButtons } from '../../ObjectPanelButtons/ObjectPanelButtons';
import ApplicationForm from '../../../ApplicationsForm/ApplicationForm/ApplicationForm';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { SiderApplications } from './Sider/SiderApplications';
import ScrollableBoxArea from 'UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';
import { ApplicationsLabels } from 'types/enums/applications/ApplicationsLabels';
import { CompendiumWithButton } from '../../../ApplicationsForm/CompendiumContainer/CompendiumWithButton';
import { emptyArr, initialModel } from '../../../ApplicationsForm/interfacesApplications/templates';
import { getNewModel } from 'components/Application/BaseModules/Map/MapDisplayMode/getNewModel';
import { AnyObject } from 'types/enums/general/general.model';
import { TaskObjectFormsContainer } from '../../../ApplicationsForm/tasksObjectForms/TaskObjectFormsContainer';
import { getPreparedData } from 'services/net/getPreparedData';
import usePrepareFieldsHook from 'hooks/form/usePrepareFieldsHook/usePrepareFieldsHook';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

const gridCellSizes = {
  minWidth: 70,
  width: 220,
  maxWidth: 350,
};

const {
  PanelLayout,
  ScrollableBoxAreaContainer,
  StyledDrawer,
  LocalTitle,
  ApplicationPanelsContainer,
  ApplicationPanelWrapper,
} = Styled;

const ObjectPanelApplications = React.memo(() => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { directory, application, scoreId, scoreMode } = params;
  const dispatch = useDispatch();
  const history = useHistory<History>();
  const location = useLocation();
  const [data, setData] = useState<AnyObject[] | AnyObject>(emptyArr);
  const [model, setModel] = useState<ModelResponse>(initialModel);
  const [idSignDocument, setIdSignDocument] = useState<number>(0);
  const [dateTime, setDateTime] = useState<AnyObject>({});
  const { pageMenuKey, applicationTasks, setApplicationTasks, setPageMenuKey } =
    useContext<IApplicationsContext>(ApplicationsContext);
  const [customFieldsData, setCustomFieldsData] = useState<AnyObject>({});

  const initClose = useCallback(() => {
    let newLocation;
    if (directory === ApplicationsDirectory.tasks && applicationTasks.length && !scoreMode) {
      const newApplicationTasks = [...applicationTasks];
      const parentId = newApplicationTasks.pop();
      setApplicationTasks(newApplicationTasks);
      setPageMenuKey(ApplicationsDirectory.childrenTasks);
      newLocation = `/app/${ApplicationsDirectory.applications}/${ApplicationsDirectory.tasks}/${parentId}/`;
    } else {
      const urlApplication = scoreMode ? `${application}/` : '';
      newLocation = `/app/${ApplicationsDirectory.applications}/${directory}/${urlApplication}`;
      setPageMenuKey(ApplicationsDirectory.main);
    }
    history.push(newLocation);
  }, [application, applicationTasks, directory, history, scoreMode, setApplicationTasks, setPageMenuKey]);

  const idApplication = useMemo(() => {
    return application && !Number.isNaN(+application) && +application;
  }, [application]);

  const notificationAlert = useCallback(
    (e, customMessage = 'Неизвестная ошибка обратитесь к администратору') => {
      let messages = [customMessage];
      if (e?.response && _.isObject(e.response)) {
        messages = createAlertMessage(e);
      }
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: messages,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
    },
    [dispatch]
  );

  const urlData = useMemo(() => {
    if (scoreMode) {
      return scoreId ? `${pageMenuKey.replace('/api', '')}${scoreId}/` : null;
    }
    if (pageMenuKey !== ApplicationsDirectory.main) {
      // Логика для создания урла для подзадач
      if (pageMenuKey === ApplicationsDirectory.childrenTasks) {
        return `${RequestPath.brigadeTasks}?parent_task=${idApplication}`;
      }
      return `${pageMenuKey.replace('/api', '')}?application=${idApplication}`;
    }
    const newDirectory = directory === ApplicationsDirectory.main ? '' : `${directory}/`;
    return application === ApplicationsDirectory.create
      ? null
      : `/${ApplicationsDirectory.applications}/${newDirectory}${application}/`;
  }, [application, directory, idApplication, pageMenuKey, scoreId, scoreMode]);

  const currentUrlModel = useMemo(() => {
    if (pageMenuKey === ApplicationsDirectory.childrenTasks) {
      return `${RequestPath.brigadeTasks}`;
    }
    if (pageMenuKey !== ApplicationsDirectory.main) return `${pageMenuKey.replace('/api', '')}`;
    const newDirectory = directory === ApplicationsDirectory.main ? '' : `${directory}/`;
    return `/${ApplicationsDirectory.applications}/${newDirectory}`;
  }, [directory, pageMenuKey]);

  const refreshData = useCallback(async () => {
    try {
      const responseModel = getFromURL.getModelFromURL(currentUrlModel);
      if (urlData) {
        const responseData = getFromURL.getAll(urlData);
        const doneResponseData = await responseData;
        setData(doneResponseData?.results ?? doneResponseData);
      }
      // Автозаполнение родительской задачи при проваливании в подзадачи
      else if (applicationTasks.length && directory === ApplicationsDirectory.tasks) {
        const data = { parent_task: applicationTasks[applicationTasks.length - 1] };
        setData(data);
      }
      // Автозаполнение заявки при проваливании в инлайны
      else if (idApplication && scoreMode) {
        const data = { application: idApplication };
        setData(data);
      }
      const doneResponseModel: ModelResponse = await responseModel;
      setModel(doneResponseModel);
    } catch (e) {
      notificationAlert(e);
    }
  }, [currentUrlModel, urlData, applicationTasks, directory, idApplication, scoreMode, notificationAlert]);

  useEffect(() => {
    refreshData();
  }, [pageMenuKey, refreshData]);

  const formRef = useRef<HTMLFormElement>(null);

  const initSubmitForm = useCallback(() => {
    formRef.current?.submit();
  }, []);
  const setReturnResponse = useCallback(
    (response) => {
      if (response && response.id && !scoreId && !idApplication) {
        history.push(
          location.pathname.includes('create')
            ? `${location.pathname.replace('create', response.id)}`
            : `${location.pathname}${response.id}/`
        );
      }
    },
    [history, idApplication, location.pathname, scoreId]
  );

  const handleSubmitForm = useCallback(
    (submitData: AnyObject) => {
      const newSubmitData = getPreparedData({ ...data, ...submitData, ...dateTime, ...customFieldsData }, model);

      const url = currentUrlModel ?? `/${ApplicationsDirectory.applications}/`;
      const initial = data;

      // Логика для проверки наличия геометрии у объектов задач
      if (directory === ApplicationsDirectory.tasks && newSubmitData.task_objects?.length) {
        const emptyGeometry = newSubmitData.task_objects.some((task: AnyObject) => !task.geometry);
        if (emptyGeometry) {
          notificationAlert(null, 'Укажите месторасположения всех объектов');
          return;
        }
      }

      // При срабатывании условия будет возвращение на предидущую страницу
      if (directory === ApplicationsDirectory.materials) {
        dispatch(submitObjectSagaErrorResponse({ url, data: newSubmitData, initial, initClose }));
      } else {
        dispatch(submitObjectSagaErrorResponse({ url, data: newSubmitData, initial, setReturnResponse }));
      }
    },
    [
      dateTime,
      model,
      data,
      customFieldsData,
      currentUrlModel,
      directory,
      notificationAlert,
      dispatch,
      initClose,
      setReturnResponse,
    ]
  );

  const moveToPageCreatingNewAccount = useCallback(() => {
    if (pageMenuKey === ApplicationsDirectory.childrenTasks) {
      const newPathName = location.pathname.replace(`${idApplication}`, ApplicationsDirectory.create);
      setPageMenuKey(ApplicationsDirectory.main);
      setApplicationTasks((prevTasks: string[]) => [...prevTasks, `${idApplication}`]);
      history.push(`${newPathName}`);
    } else {
      let newPage = pageMenuKey?.replace('api', '').replace(/\//g, '');
      if (directory !== ApplicationsDirectory.tasks) {
        newPage = newPage?.replace('applications', '');
      }
      history.push(`${location.pathname}${newPage}/`);
    }
  }, [directory, history, idApplication, location.pathname, pageMenuKey, setApplicationTasks, setPageMenuKey]);

  const selectIdAGGrid = useCallback((selectId: number) => setIdSignDocument(selectId), []);

  const buttons = useMemo(
    () => (
      <ObjectPanelButtons
        pageMenuKey={pageMenuKey}
        idSignDocument={idSignDocument}
        moveToPageCreatingNewAccount={moveToPageCreatingNewAccount}
        refreshData={refreshData}
        id={application}
        data={data}
        model={model}
        directory={directory}
      />
    ),
    [pageMenuKey, idSignDocument, moveToPageCreatingNewAccount, refreshData, application, data, model, directory]
  );

  const { prepareData, prepareModel } = usePrepareFieldsHook(model, data);

  const applicationPanelWrapper = useMemo(() => {
    if (prepareModel) {
      const data = prepareData ?? {};
      const newModel = getNewModel(prepareModel, false, data);
      const extraData = model.extra_data;
      const showSemifinishedObjectForm =
        idApplication && pageMenuKey === ApplicationsDirectory.main && directory === ApplicationsDirectory.main;
      const result = [
        <ApplicationForm
          handleSubmitForm={handleSubmitForm}
          model={newModel}
          data={data}
          ref={formRef}
          form={{
            showTime: true,
            dateTime,
            setDateTime,
            setCustomFieldsData,
          }}
        />,
        showSemifinishedObjectForm && (
          <TaskObjectFormsContainer
            parentModel={model}
            setCustomFieldsData={setCustomFieldsData}
            data={data}
            setData={setData}
            extraData={extraData}
          />
        ),
      ];
      return result.map((el) => (el ? <ApplicationPanelWrapper>{el}</ApplicationPanelWrapper> : null));
    }
    return null;
  }, [dateTime, directory, handleSubmitForm, idApplication, model, pageMenuKey, prepareData, prepareModel]);

  const InlineDrawer = useMemo(
    () => <ApplicationPanelsContainer>{applicationPanelWrapper}</ApplicationPanelsContainer>,
    [applicationPanelWrapper]
  );

  const CompendiumContainer = useMemo(
    () =>
      _.isArray(data) && (
        <>
          <LocalTitle>{ApplicationsLabels[directory]}</LocalTitle>
          <CompendiumWithButton
            data={data}
            model={model}
            showQuickFilter={false}
            gridCellSizes={gridCellSizes}
            agGridThemeName={'ag-theme-custom-base'}
            namesCollectionForFiltering={emptyArr}
            buttons={buttons}
            selectIdAGGrid={selectIdAGGrid}
            pageMenuKey={pageMenuKey}
            refreshData={refreshData}
            isHeader={false}
            breadcrumbsLinks={emptyArr}
          />
        </>
      ),
    [data, directory, model, buttons, selectIdAGGrid, pageMenuKey, refreshData]
  );

  const inlinePage = pageMenuKey === ApplicationsDirectory.main || scoreMode;

  const areaBackground = inlinePage ? undefined : 'transparent';

  return (
    model && (
      <>
        <StyledDrawer>
          <PanelLayout>
            <SiderApplications
              params={params}
              initClose={initClose}
              initSubmitForm={initSubmitForm}
              ref={formRef}
              pageMenuKey={pageMenuKey}
              disabledMenu={!idApplication}
              setData={setData}
              setIdSignDocument={setIdSignDocument}
              data={data}
            />
            <ScrollableBoxArea areaBackground={areaBackground}>
              <ScrollableBoxAreaContainer>{inlinePage ? InlineDrawer : CompendiumContainer}</ScrollableBoxAreaContainer>
            </ScrollableBoxArea>
          </PanelLayout>
        </StyledDrawer>
      </>
    )
  );
});

export default ObjectPanelApplications;
