import moment from 'moment';
import { TimePicker, Form } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import { Styled } from '../styled/BaseInput.styled';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';

const StyledDatePicker = styled(TimePicker)`
  .ant-time-picker-input {
    width: 100%;
    height: 60px;
    background: #f2f2f2;
    border-radius: 4px;
    padding-left: 10px;
    padding-top: 25px;

    &::placeholder {
      color: transparent;
    }
  }
`;

const StyledDatePickerPlaceholder = styled.span`
  user-select: none;
  pointer-events: none;
  z-index: 1500;
  font-family: Open Sans, serif;
  font-size: 16px;
  color: #bfbfbf;
  position: absolute;
  top: -22px;
  left: 10px;
  transition: all 0.2s ease;

  &.opened {
    transform: translateY(-13px);
    font-size: 13px;
  }
`;

const { BaseErrorMessage } = Styled;

const TimeInput = (props) => {
  const { description, object, field } = props;
  const { control, errors, setValue } = useFormContext();
  const [placeholderState, setPlaceholderState] = useState(false);
  const [initialValue, setInitialValue] = useState(undefined);
  const { required, message } = useBaseInputSettings(description);

  function checkInputAndMakeAction(inputValue, actionFn) {
    if (!inputValue) {
      actionFn();
    }
  }

  if (object && object[field] && initialValue !== object[field]) {
    setInitialValue(object[field]);
  }

  useEffect(() => {
    if (initialValue) {
      setValue(field, moment(initialValue, 'HH:mm:ss'));
      setPlaceholderState(true);
    }
  }, [field, initialValue, setValue]);

  const className = useMemo(() => {
    if (placeholderState) return 'opened';
    if (errors[field]) return 'class_errors';
    return '';
  }, [errors, field, placeholderState]);

  if (errors[field]?.message?.includes(description.label) && errors[field]) {
    errors[field].message = null;
  }

  return (
    <Form.Item key={'form_' + field} className={className}>
      <Controller
        as={StyledDatePicker}
        name={field}
        control={control}
        key={'input_' + field}
        disabled={description.read_only}
        rules={{
          required: { value: required, message: message },
        }}
        onFocus={() => {
          setPlaceholderState(true);
        }}
        onBlur={(e) => checkInputAndMakeAction(e[0]?.target?.value, () => setPlaceholderState(false))}
        placeholder=""
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <StyledDatePickerPlaceholder className={className}>{description.label}</StyledDatePickerPlaceholder>
    </Form.Item>
  );
};

export default TimeInput;
