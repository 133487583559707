import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import ElectricalSubstationsCompositeLayers from '../../../../../../layers/CompositeLayers/ekb/ElectricalSubstationsCompositeLayers';

export class ElectricalSubstationSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: ElectricalSubstationsCompositeLayers,
      id: 'ElectricalSubstationSchema',
    });
  }
}
