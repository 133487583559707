import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { baseStyles } from 'components/stylesRegistrator';

/**
 * Возвращает чекбоксы для фильтрации объектов на слое
 * @param selectedLayer
 * @param recalculate
 * @returns {JSX.Element}
 * @constructor
 */

const { BaseMapPanelItem, CheckBoxItemsContainer, AllCheckBoxItem, HeaderCheckBox } = baseStyles.modules.map;

const LayerFilterTab = ({ selectedLayer, recalculate }) => {
  const [allCheckedObject, setAllCheckedObject] = useState(false);

  //Устанавливает видимость объекта на основе уникального айдишника заданного recalculate
  const handleChange = (e, RelatedData) => {
    RelatedData.toggleVisible(!e.target.checked);
    recalculate();
  };
  const handleChangeObject = React.useCallback(
    (RelatedData) => {
      RelatedData.forEach((obj) => {
        obj.toggleVisible(allCheckedObject);
        recalculate();
      });
      setAllCheckedObject(!allCheckedObject);
    },
    [allCheckedObject, recalculate]
  );

  // Получает RelatedData из выбранного слоя
  const layerRelatedData = selectedLayer.getRelatedData();
  // Итерирует массив и возвращает чекбоксы на каждый объект RelatedData, устанавливает чек свойство обратным от
  //isHidden и вешает обработчик события
  const filterCheckBox = layerRelatedData.map((RelatedData) => (
    <AllCheckBoxItem>
      <Checkbox checked={!RelatedData.getIsHidden()} onChange={(e) => handleChange(e, RelatedData)}>
        {RelatedData.getFilterLabel()}
      </Checkbox>
    </AllCheckBoxItem>
  ));

  return (
    <BaseMapPanelItem theme={{ paddings: { defaultPadding: '4px' } }}>
      <HeaderCheckBox>
        <Checkbox checked={allCheckedObject} onChange={() => handleChangeObject(layerRelatedData)}>
          Все объекты
        </Checkbox>
      </HeaderCheckBox>
      <CheckBoxItemsContainer>{filterCheckBox}</CheckBoxItemsContainer>
    </BaseMapPanelItem>
  );
};

LayerFilterTab.propTypes = {};

export default LayerFilterTab;
