export enum ButtonType {
  primary = 'primary',
  disable = 'disable',
  primaryTranslucent = 'primaryTranslucent',
  primaryLC = 'primaryLC',
  primaryReverse = 'primaryReverse',
  primaryReverseLB = 'primaryReverseLB',
  dangerDelete = 'dangerDelete',
  danger = 'danger',
  submit = 'submit',
  dangerReverse = 'dangerReverse',
  dangerReverseTranslucent = 'dangerReverseTranslucent',
  unbounded = 'unbounded',
  unboundedPrimary = 'unboundedPrimary',
  unboundedDanger = 'unboundedDanger',
}
