import { CLEAN_INLINE_HISTORY, SELECT_INSTANCE_IN_DIRECTORY_HISTORY } from '../directoryHistory';
import { PUSH_TO_HISTORY_SAGA, REVERT_HISTORY_SAGA } from '../../../sagas/directory/inlineHistorySaga';

const postToInlineHistory = (historyRecord) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_TO_HISTORY_SAGA,
      payload: historyRecord,
    });
  };
};

const revertToInlineHistoryRecordByType = (typeOfRevert, typeName) => {
  return (dispatch) => {
    dispatch({
      type: REVERT_HISTORY_SAGA,
      payload: {
        typeOfRevert,
        typeName,
      },
    });
  };
};

const clearInlineHistory = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAN_INLINE_HISTORY,
    });
  };
};

const selectRowForDirectoryHistory = (data) => ({
  type: SELECT_INSTANCE_IN_DIRECTORY_HISTORY,
  payload: {
    selected: data,
  },
});

export const directoryHistoryActions = {
  postToInlineHistory,
  revertToInlineHistoryRecordByType,
  clearInlineHistory,
  selectRowForDirectoryHistory,
};
