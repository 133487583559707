import { useCallback, useEffect, useState } from 'react';
import { getFromURL } from '../../api/getFromURL';
import { ReportRoutes } from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/enums/ReportRoutes';
import { RequestPath } from '../../types/enums/routes/request/RequestPath';

export const useReportsRegistersUrl = () => {
  // Хук для проверки по какому URL зашел и подставляет соотвественный endpoint для заполнения карточки категории
  const [itemRoads, setItemRoads] = useState([]);
  const [itemAnother, setItemAnother] = useState([]);

  const [filterItems, setFilterItems] = useState(null);
  const [filterItemsAnother, setFilterItemsAnother] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (itemRoads.length) {
      setIsLoading(false);
    }
  }, [itemRoads]);
  const model = useCallback(async (url) => {
    switch (url) {
      case ReportRoutes.statisticOfShipments: {
        const response = await getFromURL.getAll('/reports/statement');
        // TODO filters это объект, а не массив Тех-долг-FRONTEND #5630
        const responseModel = response?.filters?.map((el) => {
          return {
            value: el.label,
            id: el.id,
            isChecked: false,
          };
        });
        setItemRoads(responseModel?.slice(1));
        setFilterItems(responseModel?.slice(1));
        break;
      }
      case ReportRoutes.roadSignsByRoad: {
        const responseSigns = await getFromURL.getAll('/road_constructions/road_sign_types?representation=true');
        const responseRoad = await getFromURL.getAll(RequestPath.roads);

        const responseModel = responseRoad.results.map((el) => {
          return {
            value: el.name,
            id: el.id,
            isChecked: false,
          };
        });
        setItemRoads(responseModel);
        setFilterItems(responseModel);

        const modelAnother = responseSigns.results.map((el) => {
          return {
            value: el.value,
            id: el.value,
            isChecked: false,
          };
        });
        setItemAnother(modelAnother);
        setFilterItemsAnother(modelAnother);
        break;
      }

      case ReportRoutes.roadAccident: {
        const response = await getFromURL.getAll(RequestPath.roads);
        const responseModel = response?.results.map((el) => {
          return {
            value: el.name,
            id: el.id,
            isChecked: false,
          };
        });
        setItemRoads(responseModel);
        setFilterItems(responseModel);
        break;
      }
      case ReportRoutes.roadMarkingLineAreaAndVolumeReport: {
        const responseRoadMarking = await getFromURL.getAll('/roads/road_marking_line_types?representation=true');
        const modelMarking = responseRoadMarking.results.map((el) => {
          return {
            value: el.value,
            id: el.key,
            isChecked: false,
          };
        });
        setItemAnother(modelMarking);
        setFilterItemsAnother(modelMarking);

        const response = await getFromURL.getAll(RequestPath.roads);
        const responseModel = response?.results.map((el) => {
          return {
            value: el.name,
            id: el.id,
            isChecked: false,
          };
        });
        setItemRoads(responseModel);
        setFilterItems(responseModel);
        break;
      }
      case ReportRoutes.crossRoadPerDistrict: {
        const response = await getFromURL.getAll(RequestPath.cityDistricts);
        const responseModel = response.results.map((el, i) => {
          return {
            value: el.name,
            id: i + 1,
            isChecked: false,
          };
        });
        setItemRoads(responseModel);
        setFilterItems(responseModel);
        break;
      }
      default:
        return url;
    }
  }, []);
  return {
    model,
    itemRoads: itemRoads,
    setItemRoads: setItemRoads,
    itemAnother: itemAnother,
    setItemAnother: setItemAnother,
    filterItems: filterItems,
    setFilterItems: setFilterItems,
    filterItemsAnother: filterItemsAnother,
    setFilterItemsAnother: setFilterItemsAnother,
    isLoading: isLoading,
  };
};
