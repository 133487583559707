import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Layers } from 'types/enums/map/layers/Layers';
import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { initUpdateMapSelectedObjectSaga } from 'store/sagas/map/mapSelectedObjectSaga';
import { Application } from 'generated/api/api';
import PrepareModal from 'UI/common/components/PrepareModal/PrepareModal';
import { SchemaStatusI } from 'types/enums/general/general.model';
import { MapObjects } from 'types/enums/map/MapObjects.model';

const StatusButton = ({ selectedMapItem, data }: { selectedMapItem: any; data: Application }) => {
  const dispatch = useDispatch();

  const selectedObjectData = selectedMapItem?.data[0];
  const statuses = selectedObjectData?.model?.scheme?.status?.choices;
  const currentStatus = data.status;
  const urlData = selectedObjectData?.urlData;

  const selectedStatus = useMemo(
    () => statuses?.find((el: SchemaStatusI) => el.value === currentStatus),
    [currentStatus, statuses]
  );

  const availableStatuses = useMemo(
    () =>
      statuses
        ?.filter((el: SchemaStatusI) => data.on_change_allowed_statuses.includes(el.value))
        ?.map((el: SchemaStatusI) => ({
          value: el.value,
          label: el.display_name,
        })),
    [data.on_change_allowed_statuses, statuses]
  );

  const selectorProps = useMemo(
    () => ({
      options: availableStatuses,
      placeholder: 'Выберите статус',
      selected: selectedStatus?.display_name,
      required: true,
    }),
    [availableStatuses, selectedStatus?.display_name]
  );

  const handleInitClose = useCallback(
    (res) => {
      dispatch(mapboxDataActions.reloadMapboxLayerDataByRelatedName(MapObjects.brigadeApplications, Layers.brigadeApplications));
      dispatch(initUpdateMapSelectedObjectSaga(res));
    },
    [dispatch]
  );

  const handleSuccess = useCallback(
    (status) => {
      if (status) {
        const id = data.id;
        dispatch(submitObjectSagaErrorResponse({ url: urlData, data: { id, status }, initClose: handleInitClose }));
      }
    },
    [data.id, dispatch, handleInitClose, urlData]
  );

  return (
    <PrepareModal
      handleSuccess={handleSuccess}
      selectorProps={selectorProps}
      buttonText={'Изменить статус'}
      title={'Изменение статуса заявки'}
    />
  );
};

export default StatusButton;
