import React from 'react';
import { baseStyles } from 'components/stylesRegistrator';

const { BaseMapPanelTopPart, BaseMapPanelSecondaryTitle } = baseStyles.modules.map;

export const LayersGalleryHeader = () => {
  return (
    <BaseMapPanelTopPart>
      <BaseMapPanelSecondaryTitle>Какие слои отобразить?</BaseMapPanelSecondaryTitle>
    </BaseMapPanelTopPart>
  );
};
