import React, { useCallback, useEffect, useState } from 'react';
import { ColWrapper, SFileListContainer, SColumnTitleContainer } from './styles/ModerateFileList.styles';
import { Detection } from '../../../generated/api/api';
import { ModeratedFileItem } from './ModeratedFileItem/ModeratedFileItem';
import { useModeratorContext } from '../context/ModeratorContext';
import { Panel, Typography } from '@frontend-packages/its-ui-kit';
import { Col, List, Row } from 'antd';
import { ModeratedFileListHeader } from './ModeratedFileListHeader/ModeratedFileListHeader';

export const ModerateFileList = () => {
  const { moderation, uploadDetectionList, isFetching } = useModeratorContext();
  const [toggle, setToggle] = useState(false);
  const [newList, setNewList] = useState<Detection[]>([]);
  useEffect(() => {
    uploadDetectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerSortToDateCreate = useCallback(() => {
    setToggle((prev) => !prev);
    const newSortedArray = moderation.detectionList.sort((firstDate, lastDate) => {
      const firstDateInData = new Date(firstDate.created_at).getTime();
      const lastDateInData = new Date(lastDate.created_at).getTime();
      return firstDateInData - lastDateInData;
    });
    setNewList(newSortedArray);
  }, [moderation]);

  return (
    <SFileListContainer>
      <Panel headerContent={<ModeratedFileListHeader />}>
        <SColumnTitleContainer>
          <Row>
            <Col span={8}>
              <Typography type="type2">Идентификатор</Typography>
            </Col>
            <Col span={8}>
              <Typography type="type2">Тип Объекта</Typography>
            </Col>
            <ColWrapper onClick={handlerSortToDateCreate}>
              <Col>
                <Typography type="type2">Дата создания</Typography>
              </Col>
            </ColWrapper>
          </Row>
        </SColumnTitleContainer>
        {toggle && (
          <List
            loading={isFetching}
            dataSource={newList}
            renderItem={(detection: Detection) => (
              <ModeratedFileItem detectionItem={detection} key={detection.external_id} />
            )}
          />
        )}
        <List
          loading={isFetching}
          dataSource={moderation.detectionList}
          renderItem={(detection: Detection) => (
            <ModeratedFileItem detectionItem={detection} key={detection.external_id} />
          )}
        />
      </Panel>
    </SFileListContainer>
  );
};
