import styled from 'styled-components';

const ButtonWrapper = styled('div')`
  display: flex;
  flexdirection: row;
  margin: 10px;
  & button {
    margin-right: 20px;
  }
`;

export const Styled = {
  ButtonWrapper,
};
