import React, { useMemo } from 'react';
import { MapToolBarContainer } from '../MapToolBar/MapToolBarContainer';
import MapSelectedObjectInfoContainer from './MapSelectedObjectInfo/MapSelectedObjectInfoContainer';
import TimePlayerContainer from './TimePlayer/TimePlayerContainer';
import { baseStyles } from '../../../../stylesRegistrator';
import MapContextMenuContainer from '../ContextMenu/MapContextMenuContainer';
import LayerDataAccessorBuilder from './LayersDataAccessors/LayerDataAccessorBuilder';
import TopActionBarContainer from '../MapStandaloneEditMode/panels/TopActionBar/TopActionBarContainer';
import PrintMenu from '../../../../Print/PrintMenu/PrintMenu';
import { SaiderContainerInMap } from '../../../SaiderContainerOnMap';
import { useLocation } from 'react-router';
import HeaderContainer from '../../../Common/Header/Header';
import { Layout } from 'antd';
import { LayersBar } from './LayersBar/LayersBar';
import { MapMode } from 'types/enums/map/MapMode.model';
import { WebMercatorViewport } from 'deck.gl';

const { Header } = Layout;

const {
  BaseMapPanelLayoutArea,
  BaseMapMainModuleWrapper,
  BaseMapContentLayoutArea,
  BaseMapContentLayoutAreaInner,
  BaseMapContentLayoutAreaWrapper,
  BaseMapPanelLayoutAreaWrapper,
  BaseMapPanelLayoutAreaContent,
} = baseStyles.modules.map;

interface IMapDisplayMode {
  dataAccessPanelVisible: boolean;
  currentMode: MapMode;
  mapGlobalPrintMode: boolean;
  deckViewRef: WebMercatorViewport | null;
  isSidebarOpened: boolean;
}

export const MapDisplayMode = ({
  dataAccessPanelVisible,
  currentMode,
  mapGlobalPrintMode,
  deckViewRef,
  isSidebarOpened,
}: IMapDisplayMode) => {
  const location = useLocation();
  const isAppInMapPrintMode = location.pathname.includes('map/print_mode');
  const isChangedMenuOnMap = location.pathname.match('app/map');

  const saiderContainerContent = useMemo(() => isChangedMenuOnMap && <SaiderContainerInMap />, [isChangedMenuOnMap]);

  const headerContent = useMemo(
    () =>
      isSidebarOpened ? (
        <Header>{!isAppInMapPrintMode && <HeaderContainer />}</Header>
      ) : (
        <Header className="header__with-map">{!isAppInMapPrintMode && <HeaderContainer />}</Header>
      ),
    [isAppInMapPrintMode, isSidebarOpened]
  );

  const baseMapPanelLayoutContent = useMemo(() => {
    return mapGlobalPrintMode ? (
      <PrintMenu deckViewRef={deckViewRef} />
    ) : (
      <BaseMapPanelLayoutAreaWrapper>
        <BaseMapPanelLayoutAreaContent>
          {saiderContainerContent}
          <div>
            {headerContent}
            <BaseMapPanelLayoutArea>
              <LayersBar />
            </BaseMapPanelLayoutArea>
          </div>
        </BaseMapPanelLayoutAreaContent>
      </BaseMapPanelLayoutAreaWrapper>
    );
  }, [deckViewRef, headerContent, mapGlobalPrintMode, saiderContainerContent]);

  const baseMapContentLayoutContent = useMemo(
    () => dataAccessPanelVisible && <LayerDataAccessorBuilder />,
    [dataAccessPanelVisible]
  );

  return (
    <>
      <BaseMapMainModuleWrapper>
        {baseMapPanelLayoutContent}
        <BaseMapContentLayoutAreaWrapper>
          <BaseMapContentLayoutArea>
            <BaseMapContentLayoutAreaInner>
              {baseMapContentLayoutContent}
              <MapSelectedObjectInfoContainer />
              <TimePlayerContainer />
            </BaseMapContentLayoutAreaInner>
            <TopActionBarContainer />
            <MapToolBarContainer showTimePlayer={true} />
          </BaseMapContentLayoutArea>
        </BaseMapContentLayoutAreaWrapper>
      </BaseMapMainModuleWrapper>
      <MapContextMenuContainer mode={currentMode} />
    </>
  );
};
