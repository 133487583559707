import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateModel } from 'store/reducers';
import { rolesDirectoryActions } from 'store/reducers/rolesDirectory/rolesDirectory.actions';
import { RolesDirectoryFields } from 'store/reducers/rolesDirectory/rolesDirectory.model';

import { Styled } from './InputForm.styled';

const { LocalTitle, LocalMainWrapper, LocalInputWrapper, LocalMainInputWrapper, StyledInput } = Styled;

interface InputFormProps {
  nameSelectedRows?: string;
  idSelectedRows?: number;
}

const InputForm = ({ nameSelectedRows, idSelectedRows }: InputFormProps) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState<string | undefined>(nameSelectedRows);
  const { nameRoles } = useSelector((state: StateModel) => state.rolesDirectory);

  useEffect(() => {
    if (idSelectedRows && nameSelectedRows) {
      dispatch(rolesDirectoryActions.replaceFieldByKeyActions(nameSelectedRows, RolesDirectoryFields.nameRoles));
      dispatch(rolesDirectoryActions.replaceFieldByKeyActions(idSelectedRows, RolesDirectoryFields.IDRoles));
    }
  }, [dispatch, idSelectedRows, nameSelectedRows]);

  useEffect(() => {
    if (nameRoles?.length) setUserData(nameRoles);
  }, [nameRoles]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserData(event.target.value);
    dispatch(rolesDirectoryActions.replaceFieldByKeyActions(event.target.value, RolesDirectoryFields.nameRoles));
  };

  const changeClassName = useMemo(() => {
    return (
      <span
        className={`inline-placeholder-login
                        ${userData ? 'open' : 'close'}
                        `}
      >
        Название роли
      </span>
    );
  }, [userData]);

  return (
    <>
      <LocalMainWrapper>
        <LocalTitle>Название роли</LocalTitle>
        <LocalInputWrapper>
          <LocalMainInputWrapper>
            <StyledInput name="name" onChange={handleChange} value={userData} />
            {changeClassName}
          </LocalMainInputWrapper>
        </LocalInputWrapper>
      </LocalMainWrapper>
    </>
  );
};

export default InputForm;
