import React, { FC, useCallback, useMemo } from 'react';
import { Circle, Group, Line } from 'react-konva';
import _ from 'underscore';

import { ObjectType } from 'UI/custom/components/SettingsCameraUdc/utils/masks/prototype/ObjectType';
import Konva from 'konva';
import KonvaEventObject = Konva.KonvaEventObject;
import { MaskGroupProps } from './model/MaskGroup.model';

const MaskGroup: FC<MaskGroupProps> = ({
  polygonPoints,
  polygonConfig,
  visible = true,
  editable = false,
  drawType,
  onChangePosition,
  drawPoints,
  zIndex,
}) => {
  const handleMouseDownPoint = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const mouseEvent = e.evt.which;
      const RIGHT_MOUSE_CLICK = 3;

      if (mouseEvent === RIGHT_MOUSE_CLICK) {
        const target = e.target;
        const targetID = target.attrs.id;

        if (Array.isArray(drawPoints)) {
          const newPoints = [...drawPoints];
          newPoints.splice(targetID, 1);
          if (onChangePosition) onChangePosition(newPoints);
        }
      }
    },
    [drawPoints, onChangePosition]
  );

  const handleDragEndPoint = useCallback(
    (e: KonvaEventObject<DragEvent>) => {
      if (Array.isArray(drawPoints)) {
        const newPoints = [...drawPoints];
        const target = e.target;
        const targetAttrs = target.attrs;

        if (newPoints[targetAttrs?.id]) {
          newPoints[targetAttrs?.id] = [targetAttrs.x, targetAttrs.y];
          if (onChangePosition) onChangePosition(newPoints);
        }
      }
    },
    [drawPoints, onChangePosition]
  );

  const defaultLineConfig = useMemo(
    () => ({
      strokeWidth: 4,
      stroke: '#CA3F4B',
    }),
    []
  );

  const geometry = useMemo(() => {
    const geometry: Array<JSX.Element> = [];

    for (const lineType in polygonPoints) {
      if (polygonPoints.hasOwnProperty(lineType)) {
        const type = lineType as ObjectType;
        const lineConfig = type === ObjectType.roiPoints ? polygonConfig : defaultLineConfig;
        const points = polygonPoints[type];

        if (points) {
          if (editable && type === ObjectType.roiPoints && drawType !== ObjectType.roiPoints) {
            for (let i = 0; i < points.length; i++) {
              const nextKey = i + 1;
              let flattenPoints;

              if (points[nextKey]) {
                flattenPoints = [...points[i], ...points[nextKey]];
              } else {
                flattenPoints = [...points[i], ...points[0]];
              }

              geometry.push(
                <Line
                  key={`${ObjectType.roiPoints}_${i}`}
                  points={flattenPoints}
                  id={`${ObjectType.roiPoints}_${i}`}
                  name={type}
                  {...lineConfig}
                />
              );
            }
          }
          const flattenPoints = _.flatten(points);

          geometry.push(
            <Line key={type} listening={false} points={flattenPoints} visible={visible} name={type} {...lineConfig} />
          );
        }
      }
    }

    return geometry;
  }, [defaultLineConfig, drawType, editable, polygonConfig, polygonPoints, visible]);

  const points = useMemo(() => {
    if (editable && drawType) {
      const lineConfig = drawType === ObjectType.roiPoints ? polygonConfig : defaultLineConfig;
      return drawPoints?.map((el: any, key) => (
        <Circle
          key={key}
          id={`${key}`}
          x={el[0]}
          y={el[1]}
          draggable
          radius={4}
          strokeWidth={2}
          stroke={'#FFFFFF'}
          fill={lineConfig.stroke}
          onClick={handleMouseDownPoint}
          onDragEnd={handleDragEndPoint}
        />
      ));
    }

    return null;
  }, [editable, drawType, polygonConfig, defaultLineConfig, drawPoints, handleMouseDownPoint, handleDragEndPoint]);

  return (
    <Group zIndex={zIndex}>
      {geometry}
      {points}
    </Group>
  );
};

export default MaskGroup;
