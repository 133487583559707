import styled from 'styled-components/macro';

const StyledForm = styled.form`
  height: 100%;

  & .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledFormWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;

export const Styled = {
  StyledForm,
  StyledFormWrap,
};
