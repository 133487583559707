import React from 'react';
import Icon from '@ant-design/icons';

const PauseIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6667 15.8334H15V4.16675H11.6667V15.8334ZM5 15.8334H8.33333V4.16675H5V15.8334Z" fill="currentColor" />
  </svg>
);

export const PauseIcon = (props) => <Icon component={PauseIconSVG} {...props} />;
