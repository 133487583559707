import React, { PropsWithChildren } from 'react';
import { Button } from '../../../common/components/Button/Button';
import { ButtonType } from '../../../common/components/Button/model/Button.model';
import styled from 'styled-components/macro';

const ButtonContainer = styled.div`
  padding-left: 16px;
  p {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
    display: flex;
    align-items: center;
  }
  button {
    border-radius: 8px;
  }
`;

interface IUploadTMCFileButtonProps {
  clickButton: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  tooltip?: string;
  isDisabled: boolean;
  labelText: string;
  iconColor?: string;
  type?: ButtonType;
}

const TextIconButton = ({
  clickButton,
  tooltip,
  isDisabled,
  labelText,
  iconColor,
  type = ButtonType.danger,
}: PropsWithChildren<IUploadTMCFileButtonProps>) => {
  return (
    <ButtonContainer>
      <Button
        rounded
        hasShadow={true}
        type={type}
        onClick={clickButton}
        tooltip={tooltip}
        disabled={isDisabled}
        iconColor={iconColor}
      >
        <p>{labelText}</p>
      </Button>
    </ButtonContainer>
  );
};

export default TextIconButton;
