import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const RoadsDividingLinesItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'dividing_lines');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadsDividingLines}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Участок дороги',
    value: data?.segment_name ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о разделительной линии'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

RoadsDividingLinesItemSelection.propTypes = {};

export default RoadsDividingLinesItemSelection;
