import styled from 'styled-components/macro';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { OpacityColor } = styleMixins.color;

const HelpMessageWrap = styled.div`
  padding: 11px 16px;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 8px;
  align-items: center;
  box-shadow: 0px 2px 8px ${(props) => OpacityColor(props.theme.colors.darkBlueText, 0.12)};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  background-color: ${(props) => props.theme.colors.defaultLighter};
`;

const HelpMessageText = styled.span`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.darkBlueText};
`;

export const Styled = {
  HelpMessageWrap,
  HelpMessageText,
};
