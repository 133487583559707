import React, { useRef } from 'react';
import { Button } from '../../../common/components/Button/Button';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { PrintIcon } from '../../icons/actionIcons/PrintIcon';
import { ButtonType } from '../../../common/components/Button/model/Button.model';

const PrintButton = ({ printDocument, theme, tooltip, isDisabled }) => {
  const iconSize = useRef({ width: '20px', height: '20px' });

  return (
    <Button
      rounded
      hasShadow={true}
      type={ButtonType.danger}
      icon={<PrintIcon />}
      iconColor={theme.colors.primary}
      iconSize={iconSize.current}
      onClick={printDocument}
      tooltip={tooltip}
      disabled={isDisabled}
    />
  );
};

export default compose(withTheme)(PrintButton);
