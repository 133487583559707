export const TOGGLE_STANDALONE_EDIT_MODE = 'TOGGLE_EDIT_MODE_FOR_SINGLE_OBJECT';
export const SET_OBJECT_FOR_STANDALONE_EDIT_MODE = 'SET_EDIT_MODE_FOR_SINGLE_OBJECT';
export const SET_STANDALONE_EDIT_MODE_OBJECT = 'SET_STANDALONE_EDIT_MODE_OBJECT';
export const REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE = 'REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE';
export const REPLACE_COORDINATES_DATA_IN_STANDALONE_EDIT_MODE = 'REPLACE_COORDINATES_DATA_IN_STANDALONE_EDIT_MODE';
export const CLEAR_STANDALONE_EDIT_MODE_OBJECT = 'CLEAR_STANDALONE_EDIT_MODE_OBJECT';
export const SET_EDITING_CARD_VALUE = 'SET_EDITING_CARD_VALUE';

//// example structure of state
// {
//     enabled:false,
//     isNew: false,
//     instance: null,
//     layerName: full,
//     model: null,
//     name: null,
//     parentId: null,
//     parentKey: null,
//     drawData: null,
//     urlData: null,
//     selectedInstance: null,
//     parentObjectsDescription: [],
// };

const initialState = null;

const mapboxEditableStandaloneObject = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_STANDALONE_EDIT_MODE: {
      return {
        ...state,
        enabled: !state?.enabled,
      };
    }
    case REPLACE_COORDINATES_DATA_IN_STANDALONE_EDIT_MODE: {
      const { data, lineLength } = action.payload;
      return {
        ...state,
        drawData: {
          ...state?.drawData,
          features: [
            {
              geometry: { type: 'LineString', coordinates: data },
              properties: {},
              type: 'Feature',
            },
          ],
        },
        instance: {
          ...state?.instance,
          segment_length: lineLength,
          end_segment: Number.parseFloat(state?.instance?.start_segment) + Number.parseFloat(lineLength),
        },
      };
    }
    // Переделать, когда бэк даст нужным полям типы
    case REPLACE_DRAW_DATA_IN_STANDALONE_EDIT_MODE: {
      const { data, lineLength, distanceOfFirstPoint, distanceOfLastPoint, place, scheme } = action.payload;
      if (data?.features?.[0]?.geometry?.coordinates && data?.features?.[0]?.geometry?.type === 'LineString') {
        if (lineLength && !state?.instance?.start_segment && scheme?.segment_length) {
          return {
            ...state,
            instance: {
              ...state?.instance,
              path_distance: lineLength,
              location_km: lineLength,
              segment_length: lineLength,
              start_segment: distanceOfFirstPoint ? distanceOfFirstPoint : '0',
              end_segment: distanceOfLastPoint ? distanceOfLastPoint : lineLength,
            },
            drawData: data,
          };
        } else if (lineLength && !state?.instance?.start_segment && scheme?.length) {
          return {
            ...state,
            instance: {
              ...state?.instance,
              path_distance: lineLength,
              location_km: lineLength,
              length: lineLength,
              start: distanceOfFirstPoint ? distanceOfFirstPoint : '0',
              end: distanceOfLastPoint ? distanceOfLastPoint : lineLength,
            },
            drawData: data,
          };
        } else if (lineLength && state?.instance?.start_segment) {
          return {
            ...state,
            instance: {
              ...state?.instance,
              segment_length: lineLength,
              path_distance: lineLength,
              location_km: lineLength,
              end_segment: distanceOfLastPoint
                ? distanceOfLastPoint
                : Number.parseFloat(state?.instance?.start_segment) + Number.parseFloat(lineLength),
              start_segment: distanceOfFirstPoint ? distanceOfFirstPoint : 0,
            },
            drawData: data,
          };
        } else {
          return {
            ...state,
            drawData: data,
          };
        }
      } else if (data?.features?.[0]?.geometry?.coordinates && data?.features?.[0]?.geometry?.type === 'Point') {
        if (place) {
          return {
            ...state,
            instance: {
              ...state?.instance,
              place: place,
              location_km: place,
            },
            drawData: data,
          };
        } else {
          return {
            ...state,
            drawData: data,
          };
        }
      } else if (data?.[0]?.geometry?.coordinates && data?.[0]?.geometry?.type === 'LineString') {
        return {
          ...state,
          instance: {
            ...state?.instance,
            segment_length: lineLength,
            start_segment: distanceOfFirstPoint,
            end_segment: distanceOfLastPoint,
          },
          drawData: {
            ...state?.drawData,
            features: data,
          },
        };
      } else {
        return {
          ...state,
          drawData: data,
        };
      }
    }
    case SET_EDITING_CARD_VALUE: {
      const { end_segment, segment_length, start_segment } = action.payload.editableObjectInstance;
      return end_segment && segment_length && start_segment
        ? {
            ...state,
            instance: {
              ...state?.instance,
              segment_length: segment_length,
              end_segment: end_segment,
              start_segment: start_segment,
            },
          }
        : state;
    }
    case SET_OBJECT_FOR_STANDALONE_EDIT_MODE: {
      const { value } = action.payload;
      return {
        ...state,
        enabled: value,
      };
    }
    case SET_STANDALONE_EDIT_MODE_OBJECT: {
      const { payload } = action;
      if (payload.urlData === '/artificial_structures/segments_overpasses/') {
        return {
          ...state,
          ...payload,
          instance: {
            ...state?.instance,
            ...payload.instance,
            segment: payload.instance.id,
            segment_name: payload.name,
          },
        };
      } else {
        return {
          ...state,
          ...payload,
          instance: {
            ...state?.instance,
            ...payload.instance,
            place: payload.place,
            location_km: payload.place,
          },
        };
      }
    }
    case CLEAR_STANDALONE_EDIT_MODE_OBJECT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default mapboxEditableStandaloneObject;
