import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import { registeredReportsUrls } from '../../../../../../../registrators/reportsRegistrator/reportsRegistrator';
import { arrayGroupBy } from '../../../../../../../services/data/arrayGroupBy';
import SingleSubjectCard from '../../../../../../../UI/common/components/SingleSubjectCard/SingleSubjectCard';
import styled from 'styled-components/macro';
import { CollapsableItem } from '../../../../../../../UI/common/components/CollapsableItem/CollapsableItem';
import withContentPadding from '../../../../../../../HOC/withContentPadding';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import { StateModel } from '../../../../../../../store/reducers';

const LocalMainWrapper = styled.div`
  padding: 30px;
`;
const LocalInnerWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  background-color: ${(props) => props.theme.colors.defaultLight};
  flex-wrap: wrap;
`;

const ReportCards = () => {
  const userReportRights = useSelector((state: StateModel) => state.auth.permissions.reports);
  const confirmedAccess = registeredReportsUrls.filter((report) => userReportRights.includes(report.permissionToShow));
  let resultList;
  // @ts-ignore
  const uniqueFolderKeys = [...new Set(confirmedAccess.map((item) => item.folderKey))];
  // @ts-ignore
  resultList = arrayGroupBy(confirmedAccess, (rule) => rule.folderKey);
  const cardsGroupsForRender = [];
  for (let folderKey of uniqueFolderKeys) {
    const oneCardsGroup = [];
    const itemsCollection = resultList?.get(folderKey);
    oneCardsGroup.push(
      // @ts-ignore
      itemsCollection.map((el, index) => (
        <Link to={`/app/reports/${el.url}`} key={`${el.url}_${index}`}>
          <SingleSubjectCard titleContent={el.i18LabelPath} titleDescr={el.description} url={el.urlData} to={el.url} />
        </Link>
      ))
    );
    cardsGroupsForRender.push({
      name: folderKey,
      cardsGroup: oneCardsGroup,
    });
  }

  return (
    <LocalMainWrapper>
      {cardsGroupsForRender.map((el) => {
        return (
          <CollapsableItem key={_.uniqueId()} titleContent={el.name}>
            <LocalInnerWrapper>{el.cardsGroup}</LocalInnerWrapper>
          </CollapsableItem>
        );
      })}
    </LocalMainWrapper>
  );
};

const enhance = compose(withContentPadding);

export default enhance(ReportCards);
