import React from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const WeatherStationsItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'weather_stations');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/${DirectoryRouteModel.roads_construction_weather_stations}/${id}/`,
      '_blank'
    );
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = data;

  Descriptions.push({
    key: 'Наименование дороги',
    value: properties.segment_name,
  });

  Descriptions.push({
    key: 'Температура',
    value: properties.temperature ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об объекте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

WeatherStationsItemSelection.propTypes = {};

export default WeatherStationsItemSelection;
