import React from 'react';
import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { KnsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

const RoadSchemaSwitcher = React.lazy(() => import('../customButtons/TramSchemaSwitcher.lazy'));
let selectedType = 'Cables_tram';

export class TramsDataAccessor extends LayerDataAccessorPrototype {
  // отвечает за объект который выбирается в дата ацессоре, так как он общий для экземпляров класса то сделаем его статичным, чтобы не создавать лишние экземпляры
  static selectedType = LayerDataAccessorPrototype.selectedType;

  actionButtons = [];

  constructor() {
    super({
      label: 'Список участков',
      selectedType: MapObjects.tramCables,
    });
  }

  async getData() {
    return await KnsService.knsCablesTramList();
  }

  // по запросу по модели вернем нашу схему. Await нужен для согласованности then, можно и в промис обернуть, но лень)
  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.knsCablesTram);
  }

  // метод замены статичной переменной
  static replaceSelectedType(type) {
    RoadSchemaSwitcher.selectedType = type;
  }

  //оверрайдим метод прототипа для получения статичной переменной иначе получим переменную прототипа
  getSelectedObjectType() {
    return RoadSchemaSwitcher.selectedType ? RoadSchemaSwitcher.selectedType : selectedType;
  }
}
