// TODO duplicate file Тех-долг-FRONTEND #5587
import styled from 'styled-components/macro';
import { Layout } from 'antd';
import ScrollableBoxArea from '../../../../../../../../UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';

const PanelLayout = styled(Layout)`
  height: calc(100vh - 115px);
  padding: 0 20px 0 20px;
  & ${ScrollableBoxArea} {
    margin-left: 20px !important;
  }
`;

const SelectedReport = styled.div`
  padding: 8px 0 24px 24px;
  & span {
    font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
    margin-right: 8px;
  }
  & span:first-child {
    color: ${(props) => props.theme.colors.primary};
  }
  & span:nth-child(2),
  span:nth-child(3) {
    color: ${(props) => props.theme.colors.darkBlue};
  }
`;

const LocalInnerWrapper = styled.div`
  display: flex;
`;

const LocalTopWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const LocalTitle = styled.div`
  text-align: left;
  padding: 40px 40px 0;
  width: 100%;
  font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: ${(props) => props.theme.colors.darkBlue};
`;

const LocalReportData = styled.div`
  flex-direction: column;
  margin: 40px 40px 0 0;
`;
const LocalDataTS = styled.div`
  padding: 40px 0 40px 40px;
`;

const LocalExtensionData = styled.div`
  padding: 40px 40px 40px 0;
`;

const LocalBottomWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LocalSelectData = styled.div`
  padding: 40px;
`;

const LocalEmailData = styled.div`
  padding: 40px;
`;

export const Styled = {
  PanelLayout,
  SelectedReport,
  LocalInnerWrapper,
  LocalTopWrapper,
  LocalTitle,
  LocalReportData,
  LocalDataTS,
  LocalExtensionData,
  LocalBottomWrapper,
  LocalSelectData,
  LocalEmailData,
};
