import React from 'react';
import { Styled } from './style/HelpMessage.styled';
import { Button } from 'UI/common/components/Button/Button';
import { HelpIcon } from 'UI/custom/icons/actionIcons/HelpIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

const { HelpMessageWrap, HelpMessageText } = Styled;

const HelpMessage = ({ message }: any) => {
  return (
    <HelpMessageWrap>
      <Button icon={<HelpIcon />} p={0} type={ButtonType.unboundedPrimary} />
      <HelpMessageText>{message}</HelpMessageText>
    </HelpMessageWrap>
  );
};

export default HelpMessage;
