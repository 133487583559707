import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAgGridControl } from 'hooks/ag-grid/useAgGridControl';
import Compendium from 'UI/custom/Aggrid/Compendium/Compendium';
import { getFromURL } from 'api/getFromURL';
import { Button } from 'UI/common/components/Button/Button';
import { ArrowLongIcon } from 'UI/custom/icons/actionIcons/ArrowLongIcon';
import styled from 'styled-components/macro';
import CompendiumColumnActionPanel from 'UI/custom/Aggrid/CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanel';
import { sliceIdFromEndOfUrl } from 'services/net/sliceIdFromEndOfUrl';
import Panel from 'UI/common/components/Panel/Panel';

const StyledMainInner = styled(Panel)`
  padding: ${(props) => props.theme.paddings.defaultPadding};
  padding-top: 0;
  height: calc(100vh - 150px);
  width: 100%;
`;

const LocalInner = styled.div`
  height: fit-content !important;
  margin-top: 5.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.paddings.defaultPadding};
  margin-bottom: 20px;
`;

const baseReportErrorUrl = 'roads_quality/upload_files_history_errors/';

const errorReportRequest = async (id, setData, setModel) => {
  const responseModel = {};
  responseModel.scheme = {
    name: {
      label: 'Сообщение об ошибке',
      read_only: true,
      required: false,
      type: 'string',
    },
    position: {
      label: 'Позиция',
      read_only: true,
      required: false,
      type: 'string',
    },
    value: {
      label: 'Значение',
      read_only: true,
      required: false,
      type: 'string',
    },
  };
  const responseData = await getFromURL.getById(baseReportErrorUrl, id);
  setModel(responseModel);
  setData(responseData?.errors);
};

const FileUploaderErrorReport = () => {
  const {
    aggApi,
    quickMenu,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
    onFilterChanged,
    clearAgGridFilters,
  } = useAgGridControl();

  const [data, setData] = useState(null);
  const [model, setModel] = useState(null);

  const currentUrl = window.location.href;
  const reportId = sliceIdFromEndOfUrl(currentUrl);

  useEffect(() => {
    errorReportRequest(reportId, setData, setModel);
  }, [reportId]);

  // TODO DELETE GARBAGE FUNCTIONS Тех-долг-FRONTEND #5603
  const handleSelectRow = useCallback(() => {}, []);

  const handleRefresh = useCallback(() => {}, []);

  const handleProvideData = () => {};

  let containerCallbacks = {
    handleAGGInit,
    handleRefresh,
    handleSelectRow,
    toggleShowFilterMenu,
    handleProvideData,
    toggleShowRowsMenu,
    toggleShowQuickFilter,
    onFilterChanged,
    clearAgGridFilters,
  };

  const renderContent = !!(data && model);
  function onGoToHomeHandler() {
    window.location.replace('/app/directory/');
  }

  return (
    <>
      {renderContent && (
        <>
          <LocalInner>
            <Button
              width={'220px'}
              p={0.5}
              icon={<ArrowLongIcon />}
              iconSize={{ width: '18px', height: '18px' }}
              iconMargin={0.7}
              type={'primary'}
              onClick={onGoToHomeHandler}
            >
              Вернуться на главную
            </Button>
            <CompendiumColumnActionPanel
              quickMenuState={quickMenu}
              toggleShowFilterMenu={toggleShowFilterMenu}
              toggleShowQuickFilter={toggleShowQuickFilter}
              toggleShowRowsMenu={toggleShowRowsMenu}
              clearAgGridFilters={clearAgGridFilters}
              iconSizeType={'large'}
            />
          </LocalInner>
          <StyledMainInner>
            <Compendium
              aggApi={aggApi}
              data={data}
              model={model}
              showQuickFilter={quickMenu.search}
              containerCallbacks={containerCallbacks}
              withPagination={false}
              agGridThemeName={'ag-theme-custom-base'}
              gridCellSizes={{ minWidth: 100, width: 550, maxWidth: 1200 }}
            />
          </StyledMainInner>
        </>
      )}
    </>
  );
};

FileUploaderErrorReport.propTypes = {};

const mapStateToProps = (state) => {
  return {
    language: state.app.language,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploaderErrorReport);
