import styled from 'styled-components/macro';
import { Styled as styleMixins } from '../../../../../../styles/styleMixins/styleMixins.styled';

const { CutStringWithEllipsisOnTheEnd } = styleMixins.text;
const { AddPaddingsProps, AddMarginsProps } = styleMixins.offset;

const Title = styled.h5`
  color: ${(props) => (props.isSelected ? props.theme.colors.primary : '')};
  font-size: ${(props) => props.theme.fontSettings.default};
  line-height: 1rem;
  width: 100%;
  font-weight: normal;
  ${CutStringWithEllipsisOnTheEnd};
  // margins section
  ${(props) => AddMarginsProps(props)}
  //paddings section
  ${(props) => AddPaddingsProps(props)}
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Styled = { Title };
