import styled from 'styled-components/macro';
import { Styled as styledMixins } from '../../../../../styles/styleMixins/styleMixins.styled';

const { AddPaddingsProps, AddMarginsProps } = styledMixins.offset;

const MainWrapper = styled.div`
  box-shadow: ${(props) => (props.active ? props.theme.decoration.darkenBoxShadow : '')};
  background: ${(props) => props.theme.colors.defaultLight};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  // margins section
  ${(props) => AddMarginsProps(props)};

  //paddings section
  ${(props) => AddPaddingsProps(props)};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  transition: all 0.3s;
`;

export const Styled = { MainWrapper };
