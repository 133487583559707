export const signsRoadMapperType6 = {
  6.1: { x: 1917, y: 362, width: 88, height: 180 },
  '6.1-G': { x: 1917, y: 543, width: 88, height: 180 },
  '6.1-R': { x: 1917, y: 724, width: 88, height: 180 },
  6.2: { x: 498, y: 0, width: 128, height: 180 },
  '6.2-G': { x: 498, y: 181, width: 128, height: 180 },
  '6.2-R': { x: 498, y: 362, width: 128, height: 180 },
  '6.3.1': { x: 0, y: 543, width: 128, height: 180 },
  '6.3.1-G': { x: 129, y: 543, width: 128, height: 180 },
  '6.3.1-R': { x: 258, y: 543, width: 128, height: 180 },
  '6.3.2': { x: 387, y: 543, width: 128, height: 180 },
  '6.3.2-G': { x: 627, y: 0, width: 128, height: 180 },
  '6.3.2-R': { x: 627, y: 181, width: 128, height: 180 },
  '6.4.5d': { x: 627, y: 362, width: 128, height: 180 },
  '6.4.5d-1': { x: 627, y: 543, width: 128, height: 180 },
  '6.4.5d-G': { x: 756, y: 0, width: 128, height: 180 },
  '6.4.5d-G-1': { x: 756, y: 181, width: 128, height: 180 },
  '6.4.5d-R': { x: 756, y: 362, width: 128, height: 180 },
  '6.4.5d-R-1': { x: 756, y: 543, width: 128, height: 180 },
  '6.4.15d': { x: 0, y: 724, width: 128, height: 180 },
  '6.4.15d-G': { x: 129, y: 724, width: 128, height: 180 },
  '6.4.15d-R': { x: 258, y: 724, width: 128, height: 180 },
  '6.4.18d': { x: 387, y: 724, width: 128, height: 180 },
  '6.4.18d-G': { x: 516, y: 724, width: 128, height: 180 },
  '6.4.18d-R': { x: 645, y: 724, width: 128, height: 180 },
  6.4: { x: 885, y: 0, width: 128, height: 180 },
  '6.4-G': { x: 885, y: 181, width: 128, height: 180 },
  '6.4-R': { x: 885, y: 362, width: 128, height: 180 },
  6.5: { x: 885, y: 543, width: 128, height: 180 },
  '6.5-G': { x: 885, y: 724, width: 128, height: 180 },
  '6.5-R': { x: 0, y: 905, width: 128, height: 180 },
  '6.6.2': { x: 0, y: 0, width: 248, height: 180 },
  '6.6.2-1': { x: 0, y: 181, width: 248, height: 180 },
  '6.6.2-G': { x: 249, y: 0, width: 248, height: 180 },
  '6.6.2-G-1': { x: 249, y: 181, width: 248, height: 180 },
  '6.6.2-R': { x: 0, y: 362, width: 248, height: 180 },
  '6.6.2-R-1': { x: 249, y: 362, width: 248, height: 180 },
  6.6: { x: 129, y: 905, width: 128, height: 180 },
  '6.6-G': { x: 258, y: 905, width: 128, height: 180 },
  '6.6-R': { x: 387, y: 905, width: 128, height: 180 },
  6.7: { x: 516, y: 905, width: 128, height: 180 },
  '6.7-G': { x: 645, y: 905, width: 128, height: 180 },
  '6.7-R': { x: 774, y: 905, width: 128, height: 180 },
  '6.8.1': { x: 1014, y: 0, width: 128, height: 180 },
  '6.8.1-G': { x: 1014, y: 181, width: 128, height: 180 },
  '6.8.1-R': { x: 1014, y: 362, width: 128, height: 180 },
  '6.8.2': { x: 1014, y: 543, width: 128, height: 180 },
  '6.8.2-G': { x: 1014, y: 724, width: 128, height: 180 },
  '6.8.2-R': { x: 1014, y: 905, width: 128, height: 180 },
  '6.8.3': { x: 0, y: 1086, width: 128, height: 180 },
  '6.8.3-G': { x: 129, y: 1086, width: 128, height: 180 },
  '6.8.3-R': { x: 258, y: 1086, width: 128, height: 180 },
  '6.9.1.1': { x: 258, y: 1267, width: 128, height: 150 },
  '6.9.1.1-G': { x: 387, y: 1267, width: 128, height: 150 },
  '6.9.1.1-R': { x: 516, y: 1267, width: 128, height: 150 },
  '6.9.1.2': { x: 645, y: 1267, width: 128, height: 146 },
  '6.9.1.2-G': { x: 774, y: 1267, width: 128, height: 146 },
  '6.9.1.2-R': { x: 903, y: 1267, width: 128, height: 146 },
  '6.9.1.3': { x: 1272, y: 0, width: 128, height: 176 },
  '6.9.1.3-G': { x: 1272, y: 177, width: 128, height: 176 },
  '6.9.1.3-R': { x: 1272, y: 354, width: 128, height: 176 },
  '6.9.1.4': { x: 1401, y: 573, width: 128, height: 140 },
  '6.9.1.4-1': { x: 1032, y: 1267, width: 128, height: 143 },
  '6.9.1.4-G': { x: 1401, y: 714, width: 128, height: 140 },
  '6.9.1.4-G-1': { x: 1161, y: 1267, width: 128, height: 143 },
  '6.9.1.4-R': { x: 1401, y: 855, width: 128, height: 140 },
  '6.9.1.4-R-1': { x: 1401, y: 0, width: 128, height: 143 },
  '6.9.1.5': { x: 1272, y: 531, width: 128, height: 166 },
  '6.9.1.5-G': { x: 1272, y: 698, width: 128, height: 166 },
  '6.9.1.5-R': { x: 1272, y: 865, width: 128, height: 166 },
  '6.9.2.1': { x: 258, y: 1787, width: 128, height: 106 },
  '6.9.2.1-G': { x: 387, y: 1787, width: 128, height: 106 },
  '6.9.2.1-R': { x: 516, y: 1787, width: 128, height: 106 },
  '6.9.2.2': { x: 1161, y: 1422, width: 128, height: 130 },
  '6.9.2.2-G': { x: 1290, y: 1422, width: 128, height: 130 },
  '6.9.2.2-R': { x: 1530, y: 0, width: 128, height: 130 },
  '6.9.2.3': { x: 1401, y: 144, width: 128, height: 142 },
  '6.9.2.3-G': { x: 1401, y: 287, width: 128, height: 142 },
  '6.9.2.3-R': { x: 1401, y: 430, width: 128, height: 142 },
  '6.9.2': { x: 645, y: 1787, width: 128, height: 106 },
  '6.9.2-G': { x: 774, y: 1787, width: 128, height: 106 },
  '6.9.2-R': { x: 903, y: 1787, width: 128, height: 106 },
  '6.9.3': { x: 387, y: 1086, width: 128, height: 180 },
  '6.9.3-G': { x: 516, y: 1086, width: 128, height: 180 },
  '6.9.3-R': { x: 645, y: 1086, width: 128, height: 180 },
  '6.10.1.2': { x: 1788, y: 491, width: 128, height: 93 },
  '6.10.1.2-G': { x: 1788, y: 585, width: 128, height: 93 },
  '6.10.1.2-R': { x: 1788, y: 679, width: 128, height: 93 },
  '6.10.1.3': { x: 1290, y: 1787, width: 128, height: 99 },
  '6.10.1.3-G': { x: 1419, y: 1787, width: 128, height: 99 },
  '6.10.1.3-R': { x: 1548, y: 1787, width: 128, height: 99 },
  '6.10.1.5': { x: 1659, y: 575, width: 128, height: 113 },
  '6.10.1.5-G': { x: 1659, y: 689, width: 128, height: 113 },
  '6.10.1.5-R': { x: 1659, y: 803, width: 128, height: 113 },
  '6.10.1': { x: 1788, y: 200, width: 128, height: 96 },
  '6.10.1-G': { x: 1788, y: 297, width: 128, height: 96 },
  '6.10.1-R': { x: 1788, y: 394, width: 128, height: 96 },
  '6.10.2.2': { x: 1788, y: 773, width: 128, height: 93 },
  '6.10.2.2-G': { x: 1788, y: 867, width: 128, height: 93 },
  '6.10.2.2-R': { x: 1788, y: 961, width: 128, height: 93 },
  '6.10.2': { x: 1659, y: 1683, width: 128, height: 99 },
  '6.10.2-G': { x: 1788, y: 0, width: 128, height: 99 },
  '6.10.2-R': { x: 1788, y: 100, width: 128, height: 99 },
  '6.11.1': { x: 1659, y: 1247, width: 128, height: 108 },
  '6.11.1-G': { x: 1659, y: 1356, width: 128, height: 108 },
  '6.11.1-R': { x: 1659, y: 1465, width: 128, height: 108 },
  '6.11.2': { x: 1530, y: 488, width: 128, height: 114 },
  '6.11.2-G': { x: 1530, y: 603, width: 128, height: 114 },
  '6.11.2-R': { x: 1530, y: 718, width: 128, height: 114 },
  '6.11.3': { x: 1788, y: 1162, width: 120, height: 94 },
  '6.11.3-G': { x: 1032, y: 1787, width: 128, height: 102 },
  '6.11.3-R': { x: 1161, y: 1787, width: 128, height: 102 },
  6.11: { x: 1659, y: 1574, width: 128, height: 108 },
  '6.11-G': { x: 0, y: 1787, width: 128, height: 108 },
  '6.11-R': { x: 129, y: 1787, width: 128, height: 108 },
  '6.12.1': { x: 1530, y: 131, width: 128, height: 118 },
  '6.12.1-G': { x: 1530, y: 250, width: 128, height: 118 },
  '6.12.1-R': { x: 1530, y: 369, width: 128, height: 118 },
  '6.12.2': { x: 774, y: 1422, width: 128, height: 132 },
  '6.12.2-G': { x: 903, y: 1422, width: 128, height: 132 },
  '6.12.2-R': { x: 1032, y: 1422, width: 128, height: 132 },
  '6.12.3': { x: 1659, y: 917, width: 128, height: 109 },
  '6.12.3-G': { x: 1659, y: 1027, width: 128, height: 109 },
  '6.12.3-R': { x: 1659, y: 1137, width: 128, height: 109 },
  6.13: { x: 1272, y: 1032, width: 128, height: 154 },
  '6.13-G': { x: 0, y: 1267, width: 128, height: 154 },
  '6.13-R': { x: 129, y: 1267, width: 128, height: 154 },
  '6.14.1.1': { x: 774, y: 1086, width: 128, height: 180 },
  '6.14.1.1-G': { x: 903, y: 1086, width: 128, height: 180 },
  '6.14.1.1-R': { x: 1143, y: 0, width: 128, height: 180 },
  '6.14.1.2': { x: 1401, y: 996, width: 128, height: 134 },
  '6.14.1.2-G': { x: 1401, y: 1131, width: 128, height: 134 },
  '6.14.1.2-R': { x: 1401, y: 1266, width: 128, height: 134 },
  '6.14.1.3': { x: 0, y: 1422, width: 128, height: 134 },
  '6.14.1.3-G': { x: 129, y: 1422, width: 128, height: 134 },
  '6.14.1.3-R': { x: 258, y: 1422, width: 128, height: 134 },
  '6.14.1.4': { x: 387, y: 1422, width: 128, height: 134 },
  '6.14.1.4-G': { x: 516, y: 1422, width: 128, height: 134 },
  '6.14.1.4-R': { x: 645, y: 1422, width: 128, height: 134 },
  '6.14.2.1': { x: 1530, y: 833, width: 128, height: 114 },
  '6.14.2.1-G': { x: 1530, y: 948, width: 128, height: 114 },
  '6.14.2.1-R': { x: 1530, y: 1063, width: 128, height: 114 },
  '6.14.2.2': { x: 1530, y: 1178, width: 128, height: 114 },
  '6.14.2.2-G': { x: 1530, y: 1293, width: 128, height: 114 },
  '6.14.2.2-R': { x: 1530, y: 1408, width: 128, height: 114 },
  '6.14.2.3': { x: 0, y: 1557, width: 128, height: 114 },
  '6.14.2.3-G': { x: 129, y: 1557, width: 128, height: 114 },
  '6.14.2.3-R': { x: 258, y: 1557, width: 128, height: 114 },
  '6.15.1': { x: 387, y: 1557, width: 128, height: 114 },
  '6.15.1-G': { x: 516, y: 1557, width: 128, height: 114 },
  '6.15.1-R': { x: 645, y: 1557, width: 128, height: 114 },
  '6.15.2': { x: 774, y: 1557, width: 128, height: 114 },
  '6.15.2-G': { x: 903, y: 1557, width: 128, height: 114 },
  '6.15.2-R': { x: 1032, y: 1557, width: 128, height: 114 },
  '6.15.3': { x: 1788, y: 1055, width: 120, height: 106 },
  '6.15.3-G': { x: 1161, y: 1557, width: 128, height: 114 },
  '6.15.3-R': { x: 1290, y: 1557, width: 128, height: 114 },
  6.16: { x: 1419, y: 1557, width: 128, height: 114 },
  '6.16-G': { x: 0, y: 1672, width: 128, height: 114 },
  '6.16-R': { x: 129, y: 1672, width: 128, height: 114 },
  6.17: { x: 1032, y: 1086, width: 104, height: 180 },
  '6.17-G': { x: 903, y: 905, width: 104, height: 180 },
  '6.17-R': { x: 774, y: 724, width: 104, height: 180 },
  '6.18.1': { x: 258, y: 1672, width: 128, height: 114 },
  '6.18.1-G': { x: 387, y: 1672, width: 128, height: 114 },
  '6.18.1-R': { x: 516, y: 1672, width: 128, height: 114 },
  '6.18.2': { x: 645, y: 1672, width: 128, height: 114 },
  '6.18.2-G': { x: 774, y: 1672, width: 128, height: 114 },
  '6.18.2-R': { x: 903, y: 1672, width: 128, height: 114 },
  '6.18.3': { x: 1032, y: 1672, width: 128, height: 114 },
  '6.18.3-G': { x: 1161, y: 1672, width: 128, height: 114 },
  '6.18.3-R': { x: 1290, y: 1672, width: 128, height: 114 },
  '6.19.1': { x: 516, y: 543, width: 98, height: 180 },
  '6.19.1-G': { x: 1788, y: 1257, width: 98, height: 180 },
  '6.19.1-R': { x: 1788, y: 1438, width: 98, height: 180 },
  '6.19.2': { x: 1788, y: 1619, width: 98, height: 180 },
  '6.19.2-G': { x: 1917, y: 0, width: 98, height: 180 },
  '6.19.2-R': { x: 1917, y: 181, width: 98, height: 180 },
  '6.20.1': { x: 1143, y: 181, width: 128, height: 180 },
  '6.20.1-G': { x: 1143, y: 362, width: 128, height: 180 },
  '6.20.1-R': { x: 1143, y: 543, width: 128, height: 180 },
  '6.20.2': { x: 1143, y: 724, width: 128, height: 180 },
  '6.20.2-G': { x: 1143, y: 905, width: 128, height: 180 },
  '6.20.2-R': { x: 1143, y: 1086, width: 128, height: 180 },
  '6.21.1': { x: 1419, y: 1672, width: 128, height: 114 },
  '6.21.1-G': { x: 1659, y: 0, width: 128, height: 114 },
  '6.21.1-R': { x: 1659, y: 115, width: 128, height: 114 },
  '6.21.2': { x: 1659, y: 230, width: 128, height: 114 },
  '6.21.2-G': { x: 1659, y: 345, width: 128, height: 114 },
  '6.21.2-R': { x: 1659, y: 460, width: 128, height: 114 },
  6.72: { x: 1917, y: 905, width: 88, height: 180 },
  '6.72-G': { x: 1917, y: 1086, width: 88, height: 180 },
  '6.72-R': { x: 1917, y: 1267, width: 88, height: 180 },
};
