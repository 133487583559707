import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';
import { Layers } from 'types/enums/map/layers/Layers';
import { relatedLayerData } from 'registrators/map/layers/description/relatedData';
import { layerSchemas } from 'registrators/map/layers/description/layerSchemaPresets';
import { layerDataAccessors } from 'registrators/map/layers/description/dataAccessors';
import { ekbLayerCards } from 'registrators/map/layers/description/cards/ekb';
import BrigadeApplicationsSelection from 'layers/SelectedObjectsRepresentations/BrigadeApplicationsSelection/BrigadeApplicationsSelection';
import { mainDataForLayers } from '../description/mainData';
import { layerSettings } from '../description/settings';
import { projectLayerGroups } from '../description/layerGroups';

export class BrigadeApplicationsLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.brigadeApplications,
      editable: true,
      mainData: [
        new mainDataForLayers.ekbMainDataForLayers.BrigadeWorkShiftsMainData(),
        new mainDataForLayers.ekbMainDataForLayers.BrigadeMonitoringMainData(),
      ],
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.BrigadeApplicationsDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.BrigadeApplications(),
        new relatedLayerData.ekbRelatedDataForLayers.BrigadeWorkShifts(),
        new relatedLayerData.ekbRelatedDataForLayers.BrigadeTeamOfWorkers(),
      ],
      layerGroup: new projectLayerGroups.DutssdLayerGroup(),
      card: new ekbLayerCards.BrigadeApplicationsCard(),
      customSettings: [
        new layerSettings.ShowVehicles(),
        new layerSettings.IconSizeScale(),
        new layerSettings.OpacitySetting(),
      ],
      layerSchema: new layerSchemas.ekbLayersSchema.BrigadeApplicationsLayerSchema(),
      selectedObjectPrototype: BrigadeApplicationsSelection,
    });
  }
}
