import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputNumber } from 'antd';

import { FormInputProps } from 'UI/common/form/inputs/model/FormInputProps.model';
import _ from 'underscore';

import { Styled } from 'UI/common/form/styled/BaseInput.styled';

const { BasePlaceholder, BaseSelectInputWrap } = Styled;

interface IVPStringInputProps extends FormInputProps {
  step: number;
  changeFields: (key: string, value: number, object: any) => void;
}

export const VPNumberInput = ({ description, object, field, changeFields, step }: IVPStringInputProps) => {
  const { errors } = useFormContext();
  const [placeholderState, setPlaceholderState] = useState<boolean>(false);

  const className = useMemo(() => {
    if (placeholderState || object?.[field]) return 'opened';
    if (!_.isEmpty(errors) && description.required) return 'class_errors';
    return '';
  }, [description.required, errors, field, object, placeholderState]);

  return (
    <BaseSelectInputWrap className={className}>
      <InputNumber
        value={object?.[field]}
        onChange={(value) => value && changeFields(field, value, object)}
        step={step}
        max={description?.[field]?.max_value ?? Number.MAX_SAFE_INTEGER}
        min={description?.[field]?.min_value ?? 0}
        onFocus={() => setPlaceholderState(true)}
        onBlur={() => setPlaceholderState(false)}
        disabled={!object?.vehicle_type}
      />
      <BasePlaceholder className={`base_placeholder ${className}`}>{description?.label}</BasePlaceholder>
    </BaseSelectInputWrap>
  );
};
