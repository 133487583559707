import { DataAccessMethod } from 'types/enums/routes/request/DataAccessMethod.model';
import { baseWSPath } from '../../../layersRegistrator';

interface IMainDataSocketPrototypeConstructor {
  relatedDataKey: string;
  dataKeyForIds: string;
  urlData: string | null;
}

export class MainDataSocketPrototype {
  baseWSPathTemplate = baseWSPath;
  dataAccessMethod = DataAccessMethod.websocket;
  dataKeyForIds;
  urlData: string | null = null;
  relatedDataKey: string;

  constructor({ urlData, relatedDataKey, dataKeyForIds = 'id' }: IMainDataSocketPrototypeConstructor) {
    this.urlData = this.baseWSPathTemplate + urlData;
    this.dataKeyForIds = dataKeyForIds;
    this.relatedDataKey = relatedDataKey;
  }

  getDataAccessMethod() {
    return this.dataAccessMethod;
  }

  getDataKeyForIds() {
    return this.dataKeyForIds;
  }

  getUrlData() {
    return this.urlData;
  }

  getRelatedDataKey() {
    return this.relatedDataKey;
  }
}
