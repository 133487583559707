import React from 'react';
import { Button } from '../../../../../../UI/common/components/Button/Button';
/*import { ExpandArrowIcon } from '../../../../../../UI/custom/icons/actionIcons/ExpandArrowsIcon';*/
import { PrintIcon } from '../../../../../../UI/custom/icons/actionIcons/PrintIcon';
/*import { DotsIcon } from '../../../../../../UI/custom/icons/actionIcons/DotsIcon';*/
import { EarthOffIcon } from '../../../../../../UI/custom/icons/actionIcons/EarthOffIcon';
import { EarthOnIcon } from '../../../../../../UI/custom/icons/actionIcons/EarthOnIcon';
import _ from 'underscore';
import PrintWithAllSettingButton from './PrintWithAllSettingButton/PrintWithAllSettingButton';
import { iconSizesKit } from '../../../../../../styles/utils/iconSizesKit';

const PrimaryGroup = ({ /*handlePrint,*/ iconSize, toggleRasterTileEnable, rasterTileEnabled }) => {
  return (
    <>
      {/*  <Button
        rounded
        type={'primaryReverse'}
        disabled
        icon={<ExpandArrowIcon />}
        hasShadow
        iconSize={iconSize}
        key={_.uniqueId()}
        tooltip={'Скрыть'}
      />*/}
      {/* <Button
        rounded
        type={'primaryReverse'}
        onClick={handlePrint}
        icon={<PrintIcon />}
        hasShadow
        iconSize={iconSize}
        key={_.uniqueId()}
        tooltip={'Печать'}
      />*/}
      <PrintWithAllSettingButton
        rounded
        type={'primaryReverse'}
        icon={<PrintIcon />}
        hasShadow
        iconSize={iconSizesKit.default}
      />
      {rasterTileEnabled && (
        <Button
          rounded
          type={'primaryReverse'}
          onClick={toggleRasterTileEnable}
          icon={<EarthOffIcon />}
          hasShadow
          iconSize={iconSize}
          key={_.uniqueId()}
          tooltip={'Растровая Подложка'}
        />
      )}
      {!rasterTileEnabled && (
        <Button
          rounded
          type={'primaryReverse'}
          onClick={toggleRasterTileEnable}
          icon={<EarthOnIcon />}
          hasShadow
          iconSize={iconSize}
          key={_.uniqueId()}
          tooltip={'Растровая Подложка'}
        />
      )}
      {/*      <Button
        rounded
        type={'primaryReverse'}
        iconSize={iconSize}
        hasShadow
        disabled
        icon={<DotsIcon />}
        key={_.uniqueId()}
      />*/}
    </>
  );
};

export default PrimaryGroup;
