import { RelatedDataPrototype } from 'registrators/map/layers/description/relatedData/prototype/RelatedDataPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { DetectionsService, DetectionStatusEnum, ValidationStatusEnum } from 'generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class MonitoringUDS extends RelatedDataPrototype {
  constructor() {
    super({
      name: MapObjects.monitoringUds,
      filterLabel: 'МониторингУДС',
      selectedObjectName: MapObjects.monitoringUds,
      urlData: RequestPath.detections,
    });
  }

  async uploadData() {
    return await DetectionsService.detectionsList({
      hasImage: true,
      statusIn: `${DetectionStatusEnum.REQUIRED_CLARIFICATION},${DetectionStatusEnum.CONFIRMED}`,
      validationStatus: ValidationStatusEnum.VALID_DETECTION,
    });
  }

  async readRecord(id: number) {
    return await DetectionsService.detectionsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.detections);
  }
}
