import { UploadIcon } from '../../../icons/actionIcons/UploadIcon';
import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { Button } from '../../../../common/components/Button/Button';
import { useTheme } from 'styled-components';
import { Styled } from './UploadTMCFileButton.styled';
import { TMCUploadModal } from './UploadTMCFileModal';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { ApplicationTheme } from '../../../../../styles/themes/model/ApplicationTheme.model';

const { LocalDiv } = Styled;

interface IUploadTMCFileButtonProps {
  tooltip: string;
  pageMenuKey: string;
  id?: string | number;
  isDisabled: boolean;
  refreshData: () => void;
  CRUDHandlers?: {
    directoryData: {
      pageMenuKey: string;
      idSelectedParent: number;
    };
    refresh: () => void;
  };
}

export const UploadTMCFileButton = ({
  tooltip,
  pageMenuKey,
  id,
  isDisabled,
  refreshData,
  CRUDHandlers,
}: PropsWithChildren<IUploadTMCFileButtonProps>) => {
  const iconSize = useRef({ width: '20px', height: '20px' });
  const [isOpenModal, setOpenModal] = useState(false);
  const { directoryData, refresh } = CRUDHandlers ?? {};

  const theme = useTheme() as ApplicationTheme;

  const clickUploadTMCFileButton = useCallback(() => setOpenModal(true), []);

  return (
    <LocalDiv>
      <Button
        onClick={clickUploadTMCFileButton}
        icon={<UploadIcon />}
        rounded
        hasShadow
        type={ButtonType.danger}
        iconColor={theme.colors.primary}
        iconSize={iconSize.current}
        tooltip={tooltip}
        disabled={isDisabled}
      />
      <TMCUploadModal
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        pageMenuKey={pageMenuKey ?? directoryData?.pageMenuKey}
        id={`${id ?? directoryData?.idSelectedParent}`}
        refreshData={refreshData ?? refresh}
      />
    </LocalDiv>
  );
};
