import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from './ErrorMessage';
import { notificationActions } from '../../store/reducers/global/notifications/notifications.actions';

const ErrorMessageContainer = () => {
  const errMessage = useSelector((state) => state.app.message);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(notificationActions.clearGlobalAlertData());
  };

  const containerCallbacks = {
    handleClose,
  };

  return <>{errMessage && <ErrorMessage message={errMessage} containerCallbacks={containerCallbacks} />}</>;
};

export default ErrorMessageContainer;
