export class ObjectsPrototype {
  selectedObjectType = null;
  name = null;
  constructor(name) {
    this.name = name;
  }

  actionItems = [];

  getName() {
    return this.name;
  }
  getActionItem(index) {
    if (index < this.actionItems.length) {
      return this.actionItems[index];
    }
    return null;
  }

  getActionItemsList() {
    return this.actionItems;
  }

  getTitle() {
    return this.name;
  }
}
