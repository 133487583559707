import { MapObjects } from 'types/enums/map/MapObjects.model';
import { CommonSelectedItemContextMenuPrototype } from '../../prototypes/CommonSelectedItemContextMenuPrototype';
import { MapContextMenuItem } from 'types/enums/map/contextMenu/MapContextMenuItem.model';
import React from 'react';
import { groupTypes } from '../../../contentGroups';
import { initializeSingleObjectEditMode } from 'store/sagas/map/standaloneObjectEditableSaga';
import { RoadSignsIcon } from 'UI/custom/icons/contextMenuIcons/RoadSignsIcon';
import { TrafficLightIcon } from 'UI/custom/icons/contextMenuIcons/TrafficLightIcon';
import { PipesIcon } from 'UI/custom/icons/contextMenuIcons/PipesIcon';
import { LightingIcon } from 'UI/custom/icons/contextMenuIcons/LightingIcon';
import { RoadSurface } from 'UI/custom/icons/actionIcons/RoadSurface';
import { Pathway } from 'UI/custom/icons/actionIcons/Pathway';
import { DividingLine } from 'UI/custom/icons/actionIcons/DividingLine';
import { TramwayFabric } from 'UI/custom/icons/actionIcons/TramwayFabric';
import { EngineeringStructure } from 'UI/custom/icons/actionIcons/EngineeringStructure';
import { Fence } from 'UI/custom/icons/actionIcons/Fence';
import { RetainingWalls } from 'UI/custom/icons/actionIcons/RetainingWalls';
import { RoadMarkup } from 'UI/custom/icons/actionIcons/RoadMarkup';
import { SignalPole } from 'UI/custom/icons/actionIcons/SignalPole';
import { TraySystemIcon } from 'UI/custom/icons/contextMenuIcons/TraySystemIcon';

const { ADD_GROUP } = groupTypes;

export class RoadSegmentDescription extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, MapObjects.roadSegment, layerName);
    this.initialCoordinate = initialCoordinate;
  }

  /**
   * Используется в: создание объектов
   * Дополнительные действия контекстного меню - создание объектов НА СЕГМЕНТАХ НА СЛОЕ "СХЕМА ДОРОГ"
   */
  actionItems = [
    ...this.actionItems,
    {
      name: MapContextMenuItem.trafficLight,
      path: 'Светофор',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.trafficLight,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <TrafficLightIcon />,
    },
    {
      name: MapContextMenuItem.roadSigns,
      path: 'Дорожный знак',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.roadSigns,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <RoadSignsIcon />,
    },
    {
      name: MapContextMenuItem.roadWays,
      path: 'Проезжая часть',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.roadWays,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <RoadSurface />,
    },
    {
      name: MapContextMenuItem.engineeringConstructions,
      path: 'Инженерное сооружение',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.engineeringConstructions,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <EngineeringStructure />,
    },
    {
      name: MapContextMenuItem.sidewalk,
      path: 'Тротуар',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.sidewalk,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <Pathway />,
    },
    {
      name: MapContextMenuItem.dividingLines,
      path: 'Разделительная линия',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.dividingLines,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <DividingLine />,
    },
    {
      name: MapContextMenuItem.tramRails,
      path: 'Трамвайное полотно',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.tramRails,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <TramwayFabric />,
    },
    {
      name: MapContextMenuItem.roadFences,
      path: 'Дорожное ограждение',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.roadFences,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <Fence />,
    },
    {
      name: MapContextMenuItem.pedestrianFences,
      path: 'Пешеходное ограждение',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.pedestrianFences,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <Fence />,
    },
    {
      name: MapContextMenuItem.supportWall,
      path: 'Подпорная стена',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.supportWall,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <RetainingWalls />,
    },

    {
      name: MapContextMenuItem.roadSigns,
      path: 'Лотковые системы',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.traySystem,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <TraySystemIcon />,
    },

    // {
    //   name: MapContextMenuItem.overpasses,
    //   path: 'Искусственные сооружения',
    //   group: ADD_GROUP,
    //   dispatchedAction: () =>
    //     initializeSingleObjectEditMode(
    //       this.selectedObject,
    //       MapObjects.overpasses,
    //       this.layerName,
    //       this.initialCoordinate,
    //       ADD_GROUP
    //     ),
    //   icon: <OverpassesIcon />,
    // },
    // {
    //   name: MapContextMenuItem.railCrossings,
    //   path: 'ЖД переезд',
    //   group: ADD_GROUP,
    //   dispatchedAction: () =>
    //     initializeSingleObjectEditMode(
    //       this.selectedObject,
    //       MapObjects.railCrossings,
    //       this.layerName,
    //       this.initialCoordinate,
    //       ADD_GROUP
    //     ),
    //   icon: <RailCrossing />,
    // },
    {
      name: MapContextMenuItem.pipes,
      path: 'Труба',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.pipes,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <PipesIcon />,
    },
    {
      name: MapContextMenuItem.lighting,
      path: 'Освещение',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.lighting,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <LightingIcon />,
    },
    // Временно скрытые объекты. в дальнейшем может пригодиться
    // {
    //   name: MapContextMenuItem.restAreas,
    //   path: 'Зона отдыха',
    //   group: ADD_GROUP,
    //   dispatchedAction: () =>
    //     initializeSingleObjectEditMode(
    //       this.selectedObject,
    //       MapObjects.restAreas,
    //       this.layerName,
    //       this.initialCoordinate,
    //       ADD_GROUP
    //     ),
    //   icon: <RestAreasIcon />,
    // },
    // {
    //   name: MapContextMenuItem.weatherStations,
    //   path: 'Метеостанция',
    //   group: ADD_GROUP,
    //   dispatchedAction: () =>
    //     initializeSingleObjectEditMode(
    //       this.selectedObject,
    //       MapObjects.weatherStations,
    //       this.layerName,
    //       this.initialCoordinate,
    //       ADD_GROUP
    //     ),
    //   icon: <WeatherStationsIcon />,
    // },
    // Временно скрытый объект. в дальнейшем может пригодиться
    // {
    //   name: MapContextMenuItem.ferryCrossings,
    //   path: 'Паромная переправа',
    //   group: ADD_GROUP,
    //   dispatchedAction: () =>
    //     initializeSingleObjectEditMode(
    //       this.selectedObject,
    //       MapObjects.ferryCrossings,
    //       this.layerName,
    //       this.initialCoordinate,
    //       ADD_GROUP
    //     ),
    //   icon: <FerryCrossingsIcon />,
    // },
    {
      name: MapContextMenuItem.roadMarkings,
      path: 'Разметка (точка)',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.roadMarkings,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <RoadMarkup />,
    },
    {
      name: MapContextMenuItem.roadMarkingsLines,
      path: 'Разметка (линия)',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.roadMarkingLine,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <RoadMarkup />,
    },
    {
      name: MapContextMenuItem.roadMarkingsPolygons,
      path: 'Разметка (полигон)',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.roadMarkingPolygon,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <RoadMarkup />,
    },
    {
      name: MapContextMenuItem.signalPoles,
      path: 'Сигнальный столбик',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.signalPoles,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <SignalPole />,
    },
    {
      name: MapContextMenuItem.busShelters,
      path: 'Автобусная остановка',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.busShelters,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <SignalPole />,
    },
  ];
}
