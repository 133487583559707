import React, { FC } from 'react';
import { Styled } from './style/LineTooltip.styled';

const { TooltipWrap, TooltipHeader, TooltipLabel, TooltipLabelName, TooltipLabelValue } = Styled;

const LineTooltip: FC<any> = ({ active, payload, label, ...props }) => {
  if (active && payload && !!payload.length) {
    const labels = payload.map((el: any) => {
      return (
        <TooltipLabel key={el.dataKey} color={el.color}>
          <TooltipLabelName>{el.name}</TooltipLabelName>
          <TooltipLabelValue>{el.value}</TooltipLabelValue>
        </TooltipLabel>
      );
    });

    return (
      <TooltipWrap>
        <TooltipHeader>{label}</TooltipHeader>
        {labels}
      </TooltipWrap>
    );
  }

  return null;
};

export default LineTooltip;
