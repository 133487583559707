export const roadAccidentsIconMapping = {
  accident_color_TYPE_1: {
    x: 66,
    y: 165,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_2: {
    x: 66,
    y: 66,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_3: {
    x: 99,
    y: 99,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_4: {
    x: 33,
    y: 99,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_5: {
    x: 66,
    y: 99,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_6: {
    x: 66,
    y: 198,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_7: {
    x: 214,
    y: 0,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_8: {
    x: 99,
    y: 198,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_9: {
    x: 0,
    y: 132,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_10: {
    x: 66,
    y: 33,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_11: {
    x: 33,
    y: 0,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_12: {
    x: 214,
    y: 165,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_13: {
    x: 99,
    y: 0,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_14: {
    x: 132,
    y: 33,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_15: {
    x: 132,
    y: 99,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_16: {
    x: 0,
    y: 99,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_17: {
    x: 99,
    y: 165,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_18: {
    x: 0,
    y: 198,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_19: {
    x: 214,
    y: 33,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_20: {
    x: 132,
    y: 0,
    width: 32,
    height: 32,
  },
  accident_color_TYPE_21: {
    x: 33,
    y: 33,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_1: {
    x: 165,
    y: 198,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_2: {
    x: 99,
    y: 66,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_3: {
    x: 132,
    y: 66,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_4: {
    x: 33,
    y: 132,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_5: {
    x: 214,
    y: 132,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_6: {
    x: 214,
    y: 66,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_7: {
    x: 33,
    y: 66,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_8: {
    x: 214,
    y: 99,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_9: {
    x: 132,
    y: 198,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_10: {
    x: 132,
    y: 165,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_11: {
    x: 132,
    y: 132,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_12: {
    x: 0,
    y: 165,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_13: {
    x: 66,
    y: 132,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_14: {
    x: 99,
    y: 132,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_15: {
    x: 99,
    y: 33,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_16: {
    x: 0,
    y: 33,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_17: {
    x: 33,
    y: 165,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_18: {
    x: 0,
    y: 0,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_19: {
    x: 66,
    y: 0,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_20: {
    x: 0,
    y: 66,
    width: 32,
    height: 32,
  },
  accident_white_TYPE_21: {
    x: 33,
    y: 198,
    width: 32,
    height: 32,
  },
  cluster_blue_color: {
    x: 165,
    y: 0,
    width: 48,
    height: 48,
  },
  cluster_blue_white: {
    x: 165,
    y: 98,
    width: 48,
    height: 48,
  },
  cluster_red_color: {
    x: 165,
    y: 49,
    width: 48,
    height: 48,
  },
  cluster_red_white: {
    x: 165,
    y: 147,
    width: 48,
    height: 48,
  },
};
