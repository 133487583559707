import React from 'react';
import { Checkbox } from 'antd';

/**
 * Поле с чекбоксами, меняющими вид отображения на слое
 * @param selectedLayer - выбранный слой
 * @param changeDisplayOfMapItem - функция запускающая перерисовку слоя
 * @returns {JSX.Element}
 * @constructor
 */

const LayerChangeDisplayField = ({ selectedLayer, changeDisplayOfMapItem }) => {
  // Функция принимает выбранный слой и композитный слой, с которым нужно отобразить данные
  const changeDisplayOfMapItemFunction = (onChangeDisplayOfMapItem, nextCompositeLayer, selectedLayerName) => {
    changeDisplayOfMapItem(nextCompositeLayer, selectedLayerName);
  };

  // Получает массив с объектами, в которых layer - композитный слой, layer - его название
  const listOfPossibleLayerToRender = selectedLayer.layerSchema.getPossibleLayersToRender();
  // Мапит чекбоксы, вешает обработчик события и задаёт чекед так, чтобы был true тот, чей композитный слой совпадает с layer из массива
  const changeDisplayCheckBox = listOfPossibleLayerToRender.map((possibleLayersToRender) => (
    <Checkbox
      onChange={() => {
        changeDisplayOfMapItemFunction(
          changeDisplayOfMapItem,
          possibleLayersToRender.compositeLayer,
          selectedLayer.name
        );
      }}
      checked={possibleLayersToRender.compositeLayer === selectedLayer.layerSchema.layerToRender}
    >
      {possibleLayersToRender.label}
    </Checkbox>
  ));

  return (
    <div>
      <p>Изменение отображения объектов:</p>
      {changeDisplayCheckBox}
    </div>
  );
};

LayerChangeDisplayField.propTypes = {};

export default LayerChangeDisplayField;
