import React from 'react';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { RoadSignItemSelectionProps } from './RoadSchemeItemSelection.model';

const RoadSignItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: RoadSignItemSelectionProps) => {
  const handleOpenObjectInNewTab = () => {
    const { id } = data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadConstructionsRoadSigns}/${id}/`, '_blank');
    win?.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Тип',
    value: data.road_sign_type_name,
  });

  Descriptions.push({
    key: 'Примечание',
    value: data.comment ? data.comment : '-',
  });

  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о дорожном знаке'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

RoadSignItemSelection.propTypes = {};

export default RoadSignItemSelection;
