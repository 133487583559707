import React from 'react';
import {
  mapboxTimeActions,
  resetInitialTime,
  togglePlayerRunningStatus,
} from '../../../../../../store/reducers/map/actions/mapboxTimeActions';
import { connect } from 'react-redux';
import TimePlayer from './TimePlayer';

const TimePlayerContainer = ({
  mapboxTime,
  changeCurrentTimestamp,
  timePlayerVisible,
  startPlayerHandler,
  stopPlayerHandler,
  className = '',
  handlerResetInitialTime,
  handlerTogglePlayerRunningStatus,
  ...otherProps
}) => {
  const handleSliderDateSelect = (newValue) => {
    changeCurrentTimestamp(newValue);
  };

  const handleSliderDateAfterChange = () => {};

  const handleClosePlayer = () => {
    handlerResetInitialTime();
    handlerTogglePlayerRunningStatus(false);
  };

  return (
    <>
      {timePlayerVisible && (
        <TimePlayer
          className={`${className}`}
          {...otherProps}
          mapboxTime={mapboxTime}
          handleClosePlayer={handleClosePlayer}
          handleSliderDateSelect={handleSliderDateSelect}
          handleSliderDateAfterChange={handleSliderDateAfterChange}
          startPlayerHandler={startPlayerHandler}
          stopPlayerHandler={stopPlayerHandler}
        />
      )}
    </>
  );
};

TimePlayerContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    timePlayerVisible: state.mapPanels.timePlayerVisible,
    mapboxTime: state.mapboxTime,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentTimestamp: (currentTimestamp) =>
      dispatch(mapboxTimeActions.setCurrentTimeOnTimePlayer(currentTimestamp)),
    startPlayerHandler: () => dispatch(mapboxTimeActions.startPLayerSaga()),
    stopPlayerHandler: () => dispatch(mapboxTimeActions.stopPlayerSaga()),
    handlerResetInitialTime: () => dispatch(resetInitialTime()),
    handlerTogglePlayerRunningStatus: (bool) => dispatch(togglePlayerRunningStatus(bool)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimePlayerContainer);
