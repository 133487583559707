import { groupTypes } from '../../contentGroups';
import { LayersPrototype } from '../prototypes/LayersPrototype';
import React from 'react';
import { MapContextMenuItem } from '../../../../../types/enums/map/contextMenu/MapContextMenuItem.model';
import { MeasureDistanceMode } from '@nebula.gl/edit-modes';
import { setRulerMode } from '../../../../../store/reducers/map/actions/mapPanelsActions';
import { Layers } from '../../../../../types/enums/map/layers/Layers';
import { RulerIcon } from '../../../../../UI/custom/icons/objectIcons/RulerIcon';

const { ACTION_GROUP } = groupTypes;

export class TransportRoute extends LayersPrototype {
  constructor() {
    super(Layers.transportOrders);
  }

  actionItems = [
    {
      name: MapContextMenuItem.rulerLength,
      path: 'Линейка',
      group: ACTION_GROUP,
      dispatchedAction: () => setRulerMode(MeasureDistanceMode),
      icon: <RulerIcon />,
    },
  ];
}
