import { layerCards } from './cards';
import { layerDataAccessors } from './dataAccessors';
import { layerSchemas } from './layerSchemaPresets';
import { mainDataForLayers } from './mainData';
import { relatedLayerData } from './relatedData';
import { layerSettings } from './settings';
import { layerTemplates } from './templates';

export const layerBuilder = {
  layerCards,
  layerDataAccessors,
  layerSchemas,
  mainDataForLayers,
  relatedLayerData,
  layerSettings,
  layerTemplates,
};
