export enum SchemaFieldType {
  // common primitives
  boolean = 'boolean',
  choice = 'choice',
  multichoice = 'multichoice',
  datetime = 'datetime',
  date = 'date',
  time = 'time',
  integer = 'integer',
  float = 'float',
  rgbaColor = 'color in rgba',
  hexColor = 'color in hex',
  string = 'string',
  url = 'url',
  year = 'year',
  href = 'href',
  // common compound
  field = 'field',
  relatedField = 'related field',
  multiRelatedField = 'multi related field',
  stringRelatedField = 'string related field',
  creatableRelatedField = 'creatable related field',
  nestedObject = 'nested object',
  inline = 'inline',
  object = 'object',
  // common geo
  lineString = 'line string',
  linePath = 'line_path',
  point = 'point',
  length = 'length',
  polygon = 'polygon',
  json = 'json',
  // common validated
  email = 'email',
  phone = 'phone',
  inn = 'inn',
  kpp = 'kpp',
  ogrn = 'ogrn',
  okpo = 'okpo',
  okved = 'okved',
  snils = 'snils',
  // bkd-project
  transportRegistrationNumber = 'registration number',
  busScheduleArray = 'busScheduleArray',
  status = 'status',
  fileUpload = 'file upload',
  stringLongField = 'stringLongField',
  stringTextAreaField = 'stringTextAreaField',
  vehicle_parameters = 'vehicle_parameters',
  innStatement = 'innStatement',
  statementRelatedField = 'statement related field',
  initial = 'initial',
  hidden = 'hidden',
  geometry = 'geometry',
  list = 'list',
  file_upload = 'file upload',
  string_long_field = 'string_long_field',
  string_related_field = 'string_related_field',
  string_text_area_field = 'string_text_area_field',
  inn_statement = 'inn_statement',
  statement_related_field = 'statement related field',
  relatedReferenceField = 'related reference field',
  multiRelatedReferenceField = 'multi related reference field',
  imageUpload = 'image upload',
}
