import styled from 'styled-components/macro';

const LocalHeaderMainWrapper = styled.div``;

const LocalMainWrapper = styled.div``;

const LocalHeaderWrapper = styled.div`
  padding: ${(props) => props.theme.paddings.defaultPadding};
`;

const LocalInnerWrapper = styled.div``;

const LocalCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.paddings.defaultPadding};
  font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.darkBlue};
  & path {
    color: ${(props) => props.theme.colors.primary};
    width: 15px;
    height: 15px;
  }
  & span {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const LocalSelectWrapper = styled.div`
  & :focus {
    background: ${(props) => props.theme.colors.defaultLighter};
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  }
  padding: ${(props) => props.theme.paddings.defaultPadding};
  & button svg {
    width: 13px;
    height: 18px;
  }
`;

const LocalWrapperDivider = styled.div`
  width: 256px;
  padding-left: 16px;
  & .ant-divider-horizontal {
    margin: 0;
    background-color: ${(props) => props.theme.colors.neutralDark};
  }
`;

const LocalScrollableWrapper = styled.div`
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

const LocalChildrenWrapperForLoading = styled.div`
  position: absolute;
  top: 40%;
  left: 30%;
`;
export const Styled = {
  LocalHeaderMainWrapper,
  LocalHeaderWrapper,
  LocalMainWrapper,
  LocalInnerWrapper,
  LocalWrapperDivider,
  LocalCheckboxWrapper,
  LocalSelectWrapper,
  LocalScrollableWrapper,
  LocalChildrenWrapperForLoading,
};
