import React, { FC, useCallback, useMemo, useState } from 'react';
import PreparedDescItems from 'UI/custom/components/PreparedDescItems/PreparedDescItems';
import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { Styled } from './style/BrigadeApplicationsInfo.styled';
import { BrigadeApplicationsInfoProps } from './model/BrigadeApplicationsInfo.model';
import { ArrowToDown } from 'UI/custom/icons/actionIcons/ArrowToDown';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

const {
  LocalItemImageWrap,
  LocalItemWrapImage,
  LocalItemImage,
  LocalItemImageModal,
  ArrowIcon,
  PrevImageButton,
  NextImageButton,
} = Styled;

const BrigadeApplicationsInfo: FC<BrigadeApplicationsInfoProps> = ({
  headerStyles,
  headerContent,
  contentStyles,
  images,
  footerStyles,
  footerContent,
  fileUploaderComponent,
  listOfDescriptions,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
    setCurrentIndex(0);
  }, []);

  const onPrevImage = useCallback(() => {
    const newIndex = currentIndex - 1;
    if (images[newIndex]) setCurrentIndex(newIndex);
  }, [currentIndex, images]);

  const onNextImage = useCallback(() => {
    const newIndex = currentIndex + 1;
    if (images[newIndex]) setCurrentIndex(newIndex);
  }, [currentIndex, images]);

  const isShowPrevImageButton = useMemo(() => !!images[currentIndex - 1], [currentIndex, images]);
  const isShowNextImageButton = useMemo(() => !!images[currentIndex + 1], [currentIndex, images]);

  const imageContent = useMemo(
    () => (
      <>
        {!!images.length && (
          <LocalItemImageWrap>
            <LocalItemWrapImage>
              <ArrowIcon />
              <LocalItemImage src={images[0].path} onClick={showModal} />
              <LocalItemImageModal width={'max-content'} footer={null} visible={isModalVisible} onCancel={handleCancel}>
                <img src={images[currentIndex].path} alt="" />
                {isShowPrevImageButton && (
                  <PrevImageButton icon={<ArrowToDown />} type={ButtonType.primary} onClick={onPrevImage} />
                )}
                {isShowNextImageButton && (
                  <NextImageButton icon={<ArrowToDown />} type={ButtonType.primary} onClick={onNextImage} />
                )}
              </LocalItemImageModal>
            </LocalItemWrapImage>
          </LocalItemImageWrap>
        )}
      </>
    ),
    [
      currentIndex,
      handleCancel,
      images,
      isModalVisible,
      isShowNextImageButton,
      isShowPrevImageButton,
      onNextImage,
      onPrevImage,
      showModal,
    ]
  );

  return (
    <ScrollableCard
      headerStyles={headerStyles}
      headerContent={headerContent}
      contentStyles={contentStyles}
      children={
        <>
          {imageContent}
          {fileUploaderComponent}
          {listOfDescriptions && <PreparedDescItems listOfDescriptions={listOfDescriptions} />}
        </>
      }
      footerStyles={footerStyles}
      footerContent={footerContent}
    />
  );
};

export default BrigadeApplicationsInfo;
