import React, { FC, useEffect, useMemo, useState } from 'react';
import { Image, Layer, Stage } from 'react-konva';

import { Styled } from './style/EditableContainer.styled';
import { RefreshIcon } from 'UI/custom/icons/actionIcons/RefreshIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import HelpMessage from './components/HelpMessage/HelpMessage';
import { EditableContainerProps } from './model/EditableContainer.model';
import MaskGroup from './components/MaskGroup/MaskGroup';
import DrawLayer from './components/DrawLayer/DrawLayer';

const {
  EditableContainerWrap,
  EditableContainerStage,
  EditableContainerFooter,
  EditableContainerFooterTime,
  EditableContainerRefreshCadr,
  EditableHelp,
} = Styled;

const EditableContainer: FC<EditableContainerProps> = (
  {
    currentMask,
    addedMasks,
    onChangePosition,
    contentRef,
  }) => {
  const [height, setHeight] = useState(0);
  const [image, setImage] = useState<HTMLImageElement>(new window.Image());
  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  // Хук для загрузки картинки, в последующем вынесется в отдельную функцию
  useEffect(() => {
    const image = new window.Image();
    image.src = `${process.env.PUBLIC_URL}/img/cameraTestImage.png`;
    image.addEventListener('load', function() {
      setImage(image);
      setSizes({
        width: image.width,
        height: image.height,
      });
    });
  }, []);

  // Хук для корректного отображения высоты области редактирования
  useEffect(() => {
    const height = contentRef.current?.clientHeight ?? 0;
    setHeight(height);
  }, [contentRef]);

  // Слой с добавленными масками
  const addedMasksLayer = useMemo(() => {
    return (
      <Layer id={'added_masks'}>
        <Image image={image} zIndex={0} />
        {addedMasks.map((mask, key) => (
          <MaskGroup
            key={mask.id}
            zIndex={key + 1}
            visible={mask.visible}
            polygonPoints={mask.maskPoints}
            polygonConfig={mask.polygonConfig}
          />
        ))}
      </Layer>
    );
  }, [addedMasks, image]);

  // Подсказка для редактируемой геометрии
  const helpMessage = currentMask?.help ?? null;
  const help = useMemo(() => {
    if (helpMessage) {
      return (
        <EditableHelp>
          <HelpMessage message={helpMessage} />
        </EditableHelp>
      );
    }

    return null;
  }, [helpMessage]);

  return (
    <EditableContainerWrap style={{ height: `${height}px` }}>
      {help}
      <EditableContainerStage>
        <Stage width={sizes.width} height={sizes.height}>
          {addedMasksLayer}
          <DrawLayer
            image={image}
            currentMask={currentMask}
            onChangePosition={onChangePosition}
          />
        </Stage>
      </EditableContainerStage>
      <EditableContainerFooter>
        <EditableContainerFooterTime>Кадр от 22.05.2021, 14:00</EditableContainerFooterTime>
        <EditableContainerRefreshCadr iconMargin={1.1} iconSize={20} type={ButtonType.primary} icon={<RefreshIcon />}>
          Обновить кадр
        </EditableContainerRefreshCadr>
      </EditableContainerFooter>
    </EditableContainerWrap>
  );
};

export default EditableContainer;
