import { ChangeEvent } from 'react';

import {
  GetDirectoryModel,
  GetLayersModel,
  PermissionElement,
  RoleElement,
} from 'store/reducers/rolesDirectory/rolesDirectory.model';
import _ from 'underscore';

export function handleCheckedItem(
  selects: RoleElement[],
  event: ChangeEvent<HTMLInputElement>,
  setState: (selects: RoleElement[]) => void
) {
  selects.forEach((el) => {
    if (event.target.id && !Array.isArray(el.id)) {
      if (el.id === +event.target.id) el.isChecked = event.target.checked;
    } else {
      if (el.value === event.target.value || event.target.value.includes('all')) el.isChecked = event.target.checked;
    }
  });
  return setState([...selects]);
}

export function getIDForReports(checkedReports: RoleElement[]) {
  const filterElement = checkedReports.filter((el) => el.isChecked);
  return filterElement.map((el) => el.id);
}

export function getRightForSelectedItem(
  permissionsMap: PermissionElement[],
  checkedDirectory: RoleElement[],
  checkedDirectories: RoleElement[]
) {
  if (permissionsMap?.length && checkedDirectory?.length) {
    const selectedItem = checkedDirectories?.filter((el) => el.isChecked);
    const currentRight = [];
    for (const right of selectedItem) {
      const getRight = permissionsMap?.filter((el) => el.value === right.value);
      currentRight.push(getRight);
    }
    const convertStructure = _.flatten(currentRight);
    const value = convertStructure.map((el) => {
      return {
        value: `${el.value} (${el.permission})`,
        isChecked: false,
        id: el.id,
      };
    });
    return value;
  }
  return;
}

export function dataForCheckedItemDirectory(checkedDirectories: RoleElement[], directoryData: GetDirectoryModel) {
  const result = [];
  const getFilterValue = checkedDirectories.filter((el) => el.isChecked);
  const getValue = getFilterValue.map((el) => el.value);
  for (const data in directoryData) {
    const value = getValue.filter((el) => el === data);
    if (data === value[0]) {
      const dataValue = directoryData[data];
      const convertData = dataValue.map((el: { name: string; id: number }) => {
        return {
          value: el.name,
          isChecked: false,
          id: el.id,
        };
      });
      result.push(convertData);
    }
  }
  return result;
}
export function dataForCheckedItemMap(directoryData: GetLayersModel[]) {
  const result = [];
  for (const getObject of directoryData) {
    const keysForGetObject = Object.keys(getObject);
    const value = Object.values(getObject);
    const dataValues = value[0];
    const keysValues = Object.keys(dataValues);
    const keysLength = keysValues.length;
    if (keysLength <= 1) {
      const item = keysForGetObject.map((el) => {
        return {
          value: `${el}`,
          isChecked: false,
          id: dataValues[keysValues[0]],
          permission: keysValues[0],
        };
      });
      result.push(item);
    } else if (keysLength >= 1) {
      for (let i = 0; i < keysLength; i++) {
        const item = keysForGetObject.map((el) => {
          return {
            value: `${el}`,
            isChecked: false,
            id: dataValues[keysValues[i]],
            permission: keysValues[i],
          };
        });
        result.push(item);
      }
    }
  }
  return result;
}
