import React, { useCallback } from 'react';
import { Styled } from './TrafficIntensity.style';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Button } from 'UI/common/components/Button/Button';
import { DatePicker } from 'antd';
import { TrafficIntensityProps } from './TrafficIntenisty.model';

const {
  LocalMainWrapper,
  ExtendedButton,
  ScrollableBoxAreaContainer,
  ExtendedButtonWrapper,
  ButtonGroup,
  TitleInput,
  SelectItem,
} = Styled;

export const TrafficIntensity = ({
  handleSelectCamera,
  handleSelectMask,
  setCamera,
  setMask,
  setTypeMask,
  camerasData,
  typeMasks,
  masksData,
  selectDate,
  setFormatReport,
  sendData,
  formatReport,
}: TrafficIntensityProps) => {
  const onChangeMask = useCallback(
    (value: unknown) => {
      setMask(value as Array<string>);
    },
    [setMask]
  );
  const onChangeTypeMask = useCallback(
    (value: unknown) => {
      setTypeMask(value as Array<string>);
    },
    [setTypeMask]
  );

  const onChangeCamera = useCallback(
    (value: unknown) => {
      setCamera(value as Array<number>);
    },
    [setCamera]
  );

  return (
    <ScrollableBoxAreaContainer>
      <LocalMainWrapper>
        <TitleInput>Выберите камеру</TitleInput>
        <SelectItem
          mode="multiple"
          showSearch
          optionFilterProp="children"
          placeholder="Камера"
          onFocus={handleSelectCamera}
          onChange={onChangeCamera}
        >
          {camerasData}
        </SelectItem>
      </LocalMainWrapper>
      <LocalMainWrapper>
        <TitleInput>Выберите тип</TitleInput>
        <SelectItem mode="multiple" optionFilterProp="children" placeholder="Тип маски" onChange={onChangeTypeMask}>
          {typeMasks}
        </SelectItem>
      </LocalMainWrapper>
      <LocalMainWrapper>
        <TitleInput>Выберите маску</TitleInput>
        <SelectItem
          mode="multiple"
          onFocus={handleSelectMask}
          optionFilterProp="children"
          placeholder="Маски"
          onChange={onChangeMask}
        >
          {masksData}
        </SelectItem>
      </LocalMainWrapper>
      <LocalMainWrapper>
        <TitleInput>Выберите дату</TitleInput>
        <DatePicker placeholder={'Дату'} onChange={selectDate} />
      </LocalMainWrapper>
      <LocalMainWrapper>
        <h3>В каком виде получить отчёт</h3>
        <ExtendedButtonWrapper>
          <ButtonGroup>
            <ExtendedButton className={formatReport === 'html' ? 'active' : ''} onClick={() => setFormatReport('html')}>
              <h3>HTML</h3>
            </ExtendedButton>
            <ExtendedButton className={formatReport === 'xls' ? 'active' : ''} onClick={() => setFormatReport('xls')}>
              <h3>Excel</h3>
            </ExtendedButton>
          </ButtonGroup>
          <LocalMainWrapper>
            <Button onClick={sendData} children={'Выгрузить отчёт'} type={ButtonType.primary} />
          </LocalMainWrapper>
        </ExtendedButtonWrapper>
      </LocalMainWrapper>
    </ScrollableBoxAreaContainer>
  );
};
