import React, { PropsWithChildren, useRef, useState } from 'react';

import { useTheme } from 'styled-components';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { ChangeStatusIcon } from 'UI/custom/icons/actionIcons/ChangeStatusIcon';
import { IButton } from 'components/Application/ExtraModules/Applications/ApplicationsForm/interfacesApplications/interfacesApplications';

import { Styled } from './ChangeStatusButton.styled';
import { ChangeStatusModal } from './ChangeStatusModal';
import { ApplicationTheme } from '../../../../../../../styles/themes/model/ApplicationTheme.model';

const { LocalDiv, StatusButton } = Styled;

interface IChangeStatusButtonProps extends IButton {
  data: any[];
  model?: ModelResponse;
  directory: IOPWDirectory;
  idStatement?: number;
  idSignDocument?: number;
  isDisabled: boolean;
  refreshData: () => void;
  tooltip?: string;
  pageMenuKey?: IOPWDirectory;
}

export const ChangeStatusButton = (props: PropsWithChildren<IChangeStatusButtonProps>) => {
  const iconSize = useRef({ width: '20px', height: '20px' });
  const [isOpenModal, setOpenModal] = useState(false);
  const { isDisabled, tooltip } = props;

  const theme = useTheme() as ApplicationTheme;

  return (
    <LocalDiv>
      <StatusButton
        onClick={() => setOpenModal(true)}
        icon={<ChangeStatusIcon />}
        rounded
        hasShadow
        disabled={isDisabled}
        type={'danger'}
        iconColor={theme.colors.primary}
        iconSize={iconSize.current}
        tooltip={tooltip}
      />
      <ChangeStatusModal {...props} isOpenModal={isOpenModal} setOpenModal={setOpenModal} />
    </LocalDiv>
  );
};
