import React from 'react';
import DisplayedLayers from './DisplayedLayers';
import { connect } from 'react-redux';
import { changeLayerOrder } from '../../../../../../../../../../../store/reducers/map/actions/mapboxLayersActions';

const DisplayedLayersContainer = ({ enabledLayers, handleChangeLayerOrder, ...others }) => {
  return <DisplayedLayers {...others} changeLayerOrder={handleChangeLayerOrder} enabledLayers={enabledLayers} />;
};

const mapStateToProps = (state) => {
  return {
    enabledLayers: state.mapboxLayers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChangeLayerOrder: (order) => dispatch(changeLayerOrder(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayedLayersContainer);
