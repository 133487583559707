import React from 'react';
import TemplateItemsGroup from './parts/group/TemplateItemsGroup';
import { Divider } from 'antd';

const LayerTemplatesTab = () => {
  return (
    <>
      <TemplateItemsGroup title={'Активные шаблоны'} />
      <Divider />
      <TemplateItemsGroup title={'Галерея шаблонов'} enableSearch />
    </>
  );
};

LayerTemplatesTab.propTypes = {};

export default LayerTemplatesTab;
