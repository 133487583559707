import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RoadConstructionsService } from '../../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class ProjectRoadSigns extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_signs_project',
      filterLabel: 'Дорожные знаки',
      selectedObjectName: MapObjects.projectLayerProjectRoadSigns,
      isHidden: false,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsRoadSignsProjectList({ pageSize: Number.MAX_SAFE_INTEGER });
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsRoadSignsProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsRoadSignsProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadSignProjection);
  }
}
