import styled from 'styled-components/macro';
import { Styled as styleMixins } from '../../../../../styles/styleMixins/styleMixins.styled';

const { AddMarginsProps, AddPaddingsProps } = styleMixins.offset;
const { OpacityColor } = styleMixins.color;

const Item = styled.button`
  color: ${(props) => props.theme.colors.defaultDarker};
  //paddings section
  ${(props) => AddPaddingsProps(props)};
  padding: ${(props) => (props.p !== undefined ? `${props.p}rem` : '0.7rem')};
  // margins section
  ${(props) => AddMarginsProps(props)};
  //
  width: ${(props) => (props.width ? props.width : 'auto')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${(props) => (props.isBold ? 'normal' : 'bold')};;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  border-radius: ${(props) => (props.rounded ? '50%' : props.theme.decoration.defaultBorderRadius)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.fontSettings.defaultSmall)};
  box-shadow: ${(props) => (props.hasShadow ? props.theme.decoration.defaultBoxShadow : 'none')};

  display: flex;
  align-items: center;
  justify-content: center;
  
  border: none;
  transition: all 0.3s;
  text-decoration: none;
  outline: none;
  & svg {
    width: ${(props) => (props.iconSize ? props.iconSize?.width ?? `${props.iconSize}px` : '24px')};
    height: ${(props) => (props.iconSize ? props.iconSize?.height ?? `${props.iconSize}px` : '24px')};
    margin-right: ${(props) => (!props.loading ? `${props.iconMargin}rem` : '')};
    color: ${(props) => props.iconColor}
    }
  }
`;

const PrimaryItem = styled(Item)`
  font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
  font-weight: bold;
  font-size: 13px;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.defaultLighter};
  &:hover {
    background: ${(props) => props.theme.colors.primaryDark};
    text-decoration: ${(props) => props.href && 'underline'};
  }
`;

const PrimaryItemDisable = styled(Item)`
  background-color: ${(props) => props.theme.colors.lightBlueOpacity};
  color: ${(props) => props.theme.colors.neutralLight};
`;

const PrimaryTranslucentItem = styled(Item)`
  background-color: ${(props) => OpacityColor(props.theme.colors.darkblue, 0.08)};
  color: ${(props) => props.theme.colors.darkblue};
`;

const PrimaryLCItem = styled(PrimaryItem)`
  color: ${(props) => props.theme.colors.defaultLighter};
`;

const PrimaryReverseItem = styled(Item)`
  background: ${(props) => props.theme.colors.defaultLightTranslucent};
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
    text-decoration: ${(props) => props.href && 'underline'};
  }
`;

const PrimaryReverseLBItem = styled(PrimaryReverseItem)`
  background: ${(props) => props.theme.colors.defaultLighter};
`;

const DangerItem = styled(Item)`
  font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  background: ${(props) => props.theme.colors.defaultLighter};
  color: ${(props) => props.theme.colors.danger};
  &:hover {
    text-decoration: ${(props) => props.href && 'underline'};
    color: ${(props) => props.theme.colors.dangerDark};
    background: ${(props) => props.theme.colors.primary};
    path {
      fill: ${(props) => props.theme.colors.defaultLighter};
    }
  }
`;

const DangerItemDelete = styled(Item)`
  background: ${(props) => props.theme.colors.defaultLighter};
  color: ${(props) => props.theme.colors.danger};
  &:hover {
    text-decoration: ${(props) => props.href && 'underline'};
    color: ${(props) => props.theme.colors.dangerDark};
    background: ${(props) => props.theme.colors.danger};
    path {
      fill: ${(props) => props.theme.colors.defaultLighter};
    }
  }
`;

const DangerReverseItem = styled(Item)`
  background: ${(props) => props.theme.colors.danger};
  color: ${(props) => props.theme.colors.defaultLighter};
  &:hover {
    text-decoration: ${(props) => props.href && 'underline'};
    background: ${(props) => props.theme.colors.dangerDark};
    color: ${(props) => props.theme.colors.defaultLight};
  }
`;

const DangerReverseTranslucent = styled(Item)`
  background: ${(props) => OpacityColor(props.theme.colors.danger, 0.08)};
  color: ${(props) => props.theme.colors.danger};
`;

const UnboundedItem = styled(Item)`
  background: transparent;
  color: ${(props) => props.theme.colors.dark};
  &:hover {
    text-decoration: ${(props) => props.href && 'underline'};
  }
`;

const UnboundedPrimaryItem = styled(UnboundedItem)`
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;
const UnboundedDangerItem = styled(UnboundedItem)`
  color: ${(props) => props.theme.colors.danger};
  &:hover {
    color: #a13d3d;
  }
`;

const TooltipContainer = styled.div`
  display: flex;
`;

export const Styled = {
  Item,
  PrimaryItem,
  PrimaryTranslucentItem,
  DangerItem,
  DangerItemDelete,
  DangerReverseItem,
  UnboundedItem,
  UnboundedPrimaryItem,
  PrimaryReverseItem,
  PrimaryLCItem,
  PrimaryReverseLBItem,
  UnboundedDangerItem,
  TooltipContainer,
  DangerReverseTranslucent,
  PrimaryItemDisable,
};
