import { Modal } from 'antd';

import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';

const LocalModal = styled(Modal)`
  & .ant-modal-header {
    box-shadow: 0 0 8px -5px ${(props) => props.theme.colors.paragraph};
  }
  & .ant-modal-close {
    right: -50px;
    top: -15px;
    color: ${(props) => props.theme.colors.defaultLighter};
  }
  & .ant-modal-content {
    width: 300px;
  }
  & .ant-upload.ant-upload-drag {
    background-color: ${(props) => props.theme.colors.defaultLighter};
  }
  & .ant-modal-title {
    text-align: center;
  }
`;

const StartUploadButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  & .shitTrader {
    & p {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
    height: 40px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.defaultLighter};
  }
`;

const FormContainer = styled.div`
  .ant-form-item-children {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-input-number {
    width: 250px;
    height: 56px;
    border-radius: 8px;
    color: ${(props) => props.theme.colors.paragraph} !important;
    display: flex;
    align-items: center;
    border: none;
    background-color: ${(props) => props.theme.colors.backgroundNeutral};
    &:focus,
    &:hover {
      border: 2px solid ${(props) => props.theme.colors.primaryTranslucent} !important;
    }
  }
  .ant-input-number-input {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.paragraph};
  }
`;

const ContainerLable = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.neutralDarker};
`;

const LocalDiv = styled.div`
  margin-left: 15px;
`;

const CreatBlankButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  path {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const CheckboxWrapper = styled.label`
  align-self: start;
  padding-top: 10px;
  path {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Styled = {
  LocalDiv,
  StartUploadButton,
  LocalModal,
  CreatBlankButton,
  FormContainer,
  ContainerLable,
  CheckboxWrapper,
};
