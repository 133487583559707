import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import ITSLayer from 'types/classes/ITSLayer';
import { IconLayer } from '@deck.gl/layers';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { monitoringUdsMapping } from '../../iconMappers/monitoringUdsMapping';
import { DSelector } from '../../../services/map/Dselector/DSelector';

class MonitoringUDSCompositeLayer extends ITSLayer<any, any> {
  updateState({ oldProps, props, context, changeFlags }: any) {
    this.setState({
      selectedObject: this.props.currentSelectedObject ?? null,
    });
  }

  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        this.props.onClickHandler(event.info, MapObjects.monitoringUds, Layers.monitoringUds);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    const data = this.props.relatedData.monitoring_uds;
    return [
      new IconLayer({
        id: 'monitoring-uds-layer',
        data: data,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/monitoringUds.png`,
        iconMapping: monitoringUdsMapping,
        opacity: this?.props?.opacity ?? 0,
        pickable: true,
        autoHighlight: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        // @ts-ignore
        getPosition: DSelector.getPositionFromPoint,
        updateTriggers: {
          getIcon: this.props.selectedObject,
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        getIcon: (d: any) => {
          const name = d.detection_type;
          return DSelector.getSelectableIcon({
            id: d.id,
            layerState: this.props,
            typeCheck: MapObjects.monitoringUds,
            selectedMapper: `${name}_ACTIVE`,
            deselectedMapper: name,
          });
        },
      }),
    ];
  }
}

MonitoringUDSCompositeLayer.layerName = Layers.monitoringUds;

export default MonitoringUDSCompositeLayer;
