import TrafficManagementProjectsLayer from 'layers/CompositeLayers/ekb/TrafficManagementProjectsLayer';
import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';

export class TrafficManagementProjectsLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: TrafficManagementProjectsLayer,
      id: 'TrafficManagementProjectsSchema',
    });
  }
}
