import styled from 'styled-components';

const WidgetsGridWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const WidgetEditPanelWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 16px);
`;

export const Styled = {
  WidgetsGridWrap,
  WidgetEditPanelWrap,
};
