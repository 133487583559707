import { getFromURL } from 'api/getFromURL';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import _ from 'underscore';

export const getINNVolition = async (
  data: any[] | null,
  directory: string,
  dispatch: (param: any) => void,
  idStatement?: number
) => {
  const innStatement = data?.find((statement) => statement.id === idStatement)?.inn;
  try {
    const response = await getFromURL.getAll(
      `/${IOPWDirectory.special_permits}/${directory}/check_inn/?inn=${innStatement}`
    );
    if (response === 'Информация не найдена') throw new Error(response);
    else if (response) {
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: response,
        })
      );
    }
  } catch (e) {
    let message = ['Неизвестная ошибка обратитесь к администратору'];
    if (_.isObject(e) && e?.response && _.isObject(e.response)) message = createAlertMessage(e);
    else if (_.isObject(e) && e?.message) message = e.message;
    dispatch(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message,
      })
    );
  } finally {
    setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
  }
};
