import { NumericLayerSetting } from './prototype/types/NumericLayerSetting';

export class ElevationScale extends NumericLayerSetting {
  constructor() {
    super({
      label: 'Высота хекса',
      keyForDeck: 'elevationScale',
      minimum: 1,
      currentValue: 30,
      maximum: 100,
      step: 1,
      isRanged: false,
      isPercent: false,
    });
  }
}
