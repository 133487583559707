import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, PathLayer, TextLayer } from '@deck.gl/layers';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import { roadObjectsMapping } from '../../iconMappers/roadObjectsMapping';
import { PathStyleExtension } from '@deck.gl/extensions';
import wkt from 'wkt';
import { signsRoadMapperType1 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType1';
import { signsRoadMapperType2 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType2';
import { signsRoadMapperType3 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType3';
import { signsRoadMapperType4 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType4';
import { signsRoadMapperType5 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType5';
import { signsRoadMapperType6 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType6';
import { signsRoadMapperType7 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType7';
import { signsRoadMapperType8 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType8';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import PolygonLayer from '@deck.gl/layers/dist/es5/polygon-layer/polygon-layer';
import { objectAllIconMapper } from '../../iconMappers/objectAllIconMapper/objectAllIconMapper';
import { objectAllIconMapperProject } from '../../iconMappers/objectAllIconMapper/objectAllIconMapperProject';
import { MapSignsSubtype } from '../../../types/enums/map/MapSignsSubtype.model';
import { setIconsAdditionalParams } from '../../../types/utils/setIconsAdditionalParams';
import { calculateLinePathCenterCoords } from '../../../services/map/calculateLinePathCenterCoords';
import { roadMarkingIconMapping } from '../../iconMappers/roadMarkingIconMapping/roadMarkingIconMapping';
import { DSelector } from '../../../services/map/Dselector/DSelector';
import { trafficLightTypeProject } from '../../iconMappers/trafficLight/trafficLightTypeProject';

const SIGNS_MAPPER_SET = [
  signsRoadMapperType1,
  signsRoadMapperType2,
  signsRoadMapperType3,
  signsRoadMapperType4,
  signsRoadMapperType5,
  signsRoadMapperType6,
  signsRoadMapperType7,
  signsRoadMapperType8,
];

class ProjectCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    this.setState({
      relatedData: this.props.relatedData,
    });

    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof ProjectCompositeLayer) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }
  }

  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.projectLayer;

        if (event.sourceLayer.id.includes('signs')) selectedObjectType = MapObjects.projectLayerProjectRoadSigns;
        if (event.sourceLayer.id.includes('traffic-light')) selectedObjectType = MapObjects.trafficLight;
        if (event.sourceLayer.id.includes('road-marking-polygon')) selectedObjectType = MapObjects.roadMarkingPolygon;
        if (event.sourceLayer.id.includes('road-marking-symbol')) selectedObjectType = MapObjects.roadMarkings;
        if (event.sourceLayer.id.includes('sidewalk')) selectedObjectType = MapObjects.sidewalk;
        if (event.sourceLayer.id.includes('road-ways')) selectedObjectType = MapObjects.roadWays;
        if (event.sourceLayer.id.includes('speed-bumps')) selectedObjectType = MapObjects.speedBumps;
        if (event.sourceLayer.id.includes('signal-poles')) selectedObjectType = MapObjects.projectLayerSignalPoles;
        if (event.sourceLayer.id.includes('street-lamp')) selectedObjectType = MapObjects.projectLayerStreetLamp;
        if (event.sourceLayer.id.includes('pedestrian-border')) selectedObjectType = MapObjects.projectLayerPedBorder;

        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    const typesOfRoadMarkingPolygon = {
      '1.14.1': [250, 250, 250, 100],
      '1.14.1y': [252, 250, 252, 100],
      '1.16.1': [204, 204, 204, 100],
      '1.16.2': [158, 158, 158, 100],
      '1.16.3': [130, 130, 130, 100],
      '1.17.1': [92, 92, 92, 100],
      1.25: [71, 71, 71, 100],
    };

    let isRoadMarkingVisible;
    if (this.context?.viewport?.zoom > 13.815833253734663) {
      isRoadMarkingVisible = true;
    } else {
      isRoadMarkingVisible = false;
    }
    if (!this.props.relatedData) return [];

    let roadMarkingLineLayer = isRoadMarkingVisible
      ? [
          new PathLayer(this.props, this.getSubLayerProps({ id: 'road-marking-lines' }), {
            data: this.props.relatedData.road_marking_line_project,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            widthScale: 3,
            widthMaxPixels: 3,
            billboard: false,
            extensions: [new PathStyleExtension({ dash: true })],
            getDashArray: (d) => {
              switch (d.road_marking_type_name) {
                case '1.5': {
                  return [20, 50];
                }
                case '1.6': {
                  return [30, 10];
                }
                case '1.7': {
                  return [10, 10];
                }
                case '1.8': {
                  return [20, 50];
                }
                case '1.9': {
                  return [30, 10];
                }
                case '1.10': {
                  return [10, 10];
                }
                default:
                  return [0, 0];
              }
            },
            getPolygonOffset: DSelector.getNewPolygonOffset,
            dashJustified: true,
            getPath: (d) => {
              if (d?.line_path) {
                const geometry = wkt.parse(d.line_path);
                return geometry.coordinates;
              }
              return [0, 0];
            },
            getColor: (d) => {
              switch (d.road_marking_type_name) {
                case '1.3': {
                  return [255, 0, 0];
                }
                case '1.9': {
                  return [0, 0, 255];
                }
                case '1.11': {
                  return [255, 0, 255];
                }
                default:
                  return [255, 255, 255];
              }
            },
            getWidth: (d) => {
              if (d.road_marking_type_name === '1.12') {
                return [10];
              } else {
                return [0.1];
              }
            },
          }),
          new PathLayer(this.props, this.getSubLayerProps({ id: 'road-marking-lines-light' }), {
            data: this.props.relatedData.road_marking_line_project,
            pickable: true,
            getWidth: 2,
            widthScale: 2,
            widthMinPixels: 2,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            getPath: (d) => {
              if (d?.line_path) {
                const geometry = wkt.parse(d.line_path);
                return geometry.coordinates;
              }
              return [0, 0];
            },
            getColor: (d) => [0, 255, 48, 58],
          }),
        ]
      : null;

    const signsLayers = [];
    for (let it = 1; it < 9; it++) {
      let icon;
      icon = new IconLayer(this.props, this.getSubLayerProps({ id: `signs-icon-type-${it}` }), {
        data: this.props.relatedData.road_signs_project,
        iconAtlas: `${process.env.PUBLIC_URL}/img/signsRoadAllTypes/signsRoadType${it}.png`,
        iconMapping: SIGNS_MAPPER_SET[it - 1],
        autoHighlight: true,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        billboard: false,
        updateTriggers: {
          getIcon: this.state.selectedObject,
          getSize: this.context?.viewport?.zoom,
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 2.5,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        getAngle: (d) => {
          return d.azimuth ? 180 - d.azimuth : 0;
        },
        getIcon: (d) => {
          const { road_sign_type_name } = d;
          const borderType = '-G';
          let roadSignName;
          if (road_sign_type_name?.includes('5.15')) {
            if (d.subtype === MapSignsSubtype.right) roadSignName = road_sign_type_name + '.3';
            if (d.subtype === MapSignsSubtype.left) roadSignName = road_sign_type_name + '.4';
            if (d.subtype === MapSignsSubtype.rightAndStraight) roadSignName = road_sign_type_name + '.2';
            if (d.subtype === MapSignsSubtype.leftAndStraight) roadSignName = road_sign_type_name + '.1';
          }
          roadSignName = `${roadSignName ?? road_sign_type_name}${borderType}`;
          return [roadSignName];
        },
      });

      signsLayers.push(icon);
    }

    let roadMarkingPolygonLayer = isRoadMarkingVisible
      ? [
          new PolygonLayer(this.props, this.getSubLayerProps({ id: 'road-marking-polygon-project' }), {
            data: this.props.relatedData.road_marking_polygon_project,
            stroked: true,
            filled: true,
            pickable: true,
            autoHighlight: true,
            highlightColor: [60, 179, 113],
            getLineColor: [0, 255, 0],
            wireframe: true,
            lineWidthMinPixels: 3,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            /*getLineColor: (d) => {
            if (d?.road_marking_type) return typesOfRoadMarkingPolygon[d.road_marking_type];
          },*/
            getFillColor: (d) => {
              if (d?.road_marking_type) return typesOfRoadMarkingPolygon[d.road_marking_type];
              return [39, 174, 96, 100];
            }, //[39, 174, 96, 100]

            getPolygon: (d) => {
              if (d.polygon) {
                const parsed = wkt.parse(d.polygon);
                return parsed
                  ? parsed.coordinates[0]
                  : [
                      [0, 0],
                      [0.1, 0.1],
                      [0.11, 0.11],
                    ];
              } else {
                return [
                  [0, 0],
                  [0.1, 0.1],
                  [0.11, 0.11],
                ];
              }
            },
            updateTriggers: {
              getFillColor: this.state.selectedLayer,
              getPolygon: this.state.geozonesTypes,
            },
            getLineWidth: 1,
            lineJointRounded: true,
          }),
        ]
      : null;

    let signalPolesLayer = isRoadMarkingVisible
      ? [
          new IconLayer(this.props, this.getSubLayerProps({ id: 'signal-poles' }), {
            data: this.props.relatedData.signal_bars_project,
            iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
            iconMapping: objectAllIconMapper,
            opacity: this?.props?.opacity ?? 0.1,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            fp64: true,
            billboard: false,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            getPosition: (d) => {
              if (d.line_path) {
                return calculateLinePathCenterCoords(d.line_path);
              } else return [0, 0];
            },
            sizeScale: this?.props?.sizeScale ?? 1,
            getSize: () => 2.5,
            updateTriggers: {
              getIcon: [this.state.selectedObject, this.state.data?.instance],
            },
            getAngle: (d) => {
              return d.azimuth ? 180 - d.azimuth : 0;
            },
            getIcon: (d) => {
              if (this.state.data?.instance?.id === d.id) {
                return null;
              }
              const additionalParams = setIconsAdditionalParams(
                this.props?.selectedObject?.active,
                this.props?.selectedObject?.disable,
                this.props?.selectedObject?.danger
              );
              return `recreationArea${additionalParams}`;
            },
          }),
        ]
      : null;

    let pedestrianBorderLayer = isRoadMarkingVisible
      ? [
          new PathLayer(this.props, this.getSubLayerProps({ id: 'pedestrian_fences-path' }), {
            data: this.props.relatedData.ped_border_project,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            widthScale: 2,
            widthMaxPixels: 5,
            rounded: true,
            billboard: false,
            getPath: (d) => {
              if (d.line_path) {
                return getCoordsFromParsedGeometry(d.line_path);
              }
              return [0, 0];
            },
            updateTriggers: {
              getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
              getWidth: this.state.selectedObject,
            },
            getColor: [77, 175, 124, 255],
            getWidth: (d) => {
              return this.props?.selectedObject?.type === 'road_fences' &&
                d.id === this.props?.selectedObject?.selectedObject?.object?.id
                ? 2
                : 1;
            },
          }),
          new IconLayer(this.props, this.getSubLayerProps({ id: 'pedestrian-border' }), {
            data: this.props.relatedData.ped_border_project,
            iconAtlas: `${process.env.PUBLIC_URL}/img/allObjectsProject.png`,
            iconMapping: objectAllIconMapperProject,
            opacity: this?.props?.opacity ?? 0.1,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            fp64: true,
            billboard: false,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            getPosition: (d) => {
              if (d.line_path) {
                return calculateLinePathCenterCoords(d.line_path);
              } else return [0, 0];
            },
            sizeScale: this?.props?.sizeScale ?? 1,
            getSize: () => 2.5,
            updateTriggers: {
              getIcon: [this.state.selectedObject, this.state.data?.instance],
            },
            getAngle: (d) => {
              return d.azimuth ? 180 - d.azimuth : 0;
            },
            getIcon: (d) => {
              if (this.state.data?.instance?.id === d.id) {
                return null;
              }
              const additionalParams = setIconsAdditionalParams(
                this.props?.selectedObject?.active,
                this.props?.selectedObject?.disable,
                this.props?.selectedObject?.danger
              );
              return `pedestrianFences${additionalParams}`;
            },
          }),
        ]
      : null;

    let streetLampLayer = isRoadMarkingVisible
      ? [
          new IconLayer(this.props, this.getSubLayerProps({ id: 'street-lamp' }), {
            data: this.props.relatedData.street_lamp_project,
            iconAtlas: `${process.env.PUBLIC_URL}/img/allObjectsProject.png`,
            iconMapping: objectAllIconMapperProject,
            opacity: this?.props?.opacity ?? 0.1,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            fp64: true,
            billboard: false,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            getPosition: (d) => {
              if (d.point) {
                return getCoordsFromParsedGeometry(d.point);
              } else return [0, 0];
            },
            sizeScale: this?.props?.sizeScale ?? 1,
            getSize: () => 2.5,
            updateTriggers: {
              getIcon: [this.state.selectedObject, this.state.data?.instance],
            },
            getAngle: (d) => {
              return d.azimuth ? 180 - d.azimuth : 0;
            },
            getIcon: (d) => {
              if (this.state.data?.instance?.id === d.id) {
                return null;
              }
              const additionalParams = setIconsAdditionalParams(
                this.props?.selectedObject?.active,
                this.props?.selectedObject?.disable,
                this.props?.selectedObject?.danger
              );
              return `streetLamp${additionalParams}`;
            },
          }),
        ]
      : null;

    let roadMarkingSymbolLayer = isRoadMarkingVisible
      ? [
          new IconLayer(this.props, this.getSubLayerProps({ id: 'road-marking-icon' }), {
            data: this.props.relatedData.road_marking_symbol_project,
            iconAtlas: `${process.env.PUBLIC_URL}/img/textures/razmetka.png`,
            iconMapping: roadMarkingIconMapping,
            opacity: this?.props?.opacity ?? 0.1,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            fp64: true,
            billboard: false,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            getPosition: (d) => {
              if (d.point) {
                return getCoordsFromParsedGeometry(d.point);
              } else return [0, 0];
            },
            sizeScale: this?.props?.sizeScale ?? 1,
            getSize: () => 2.5,
            updateTriggers: {
              getIcon: [this.state.selectedObject, this.state.data?.instance],
            },
            getAngle: (d) => {
              return d.azimuth ? 180 - d.azimuth : 0;
            },
            getIcon: (d) => {
              if (
                this.props?.selectedObject?.type === 'road_markings' &&
                d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ) {
                if (this.state.data?.instance?.id === d.id) {
                  return null;
                } else if (d?.road_marking_type) {
                  return d.road_marking_type + '-active';
                }
              } else {
                if (this.state.data?.instance?.id === d.id) {
                  return null;
                } else if (d?.road_marking_type) {
                  return d.road_marking_type;
                }
              }
            },
          }),
        ]
      : null;

    return [
      new IconLayer(this.props, this.getSubLayerProps({ id: 'speed-bump-icon' }), {
        data: this.props.relatedData.speed_bump_project,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjectsProject.png`,
        iconMapping: objectAllIconMapperProject,
        opacity: this?.props?.opacity ?? 0.5,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        billboard: false,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 2.5,
        getAngle: (d) => {
          return d.azimuth ? 90 - d.azimuth : 0;
        },
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
          getSize: this.props?.selectedObject,
        },
        getIcon: (d) => {
          if (this.state.data?.instance?.id === d.id) {
            return null;
          }
          const additionalParams = setIconsAdditionalParams(
            this.props?.selectedObject?.active,
            this.props?.selectedObject?.disable,
            this.props?.selectedObject?.danger
          );
          return `speedBump${additionalParams}`;
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'traffic-light-icon' }), {
        data: this.props.relatedData?.traffic_lights_project,
        iconAtlas: `${process.env.PUBLIC_URL}/img/trafficLight/trafficLightProject.png`,
        iconMapping: trafficLightTypeProject,
        opacity: this?.props?.opacity ?? 0.5,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        billboard: false,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 2.5,
        getAngle: (d) => {
          return d.azimuth ? 90 - d.azimuth : 0;
        },
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
          getSize: this.props?.selectedObject,
        },
        getIcon: (d) => {
          let counter = 0;
          if (this.state.data?.instance?.id === d.id) {
            return null;
          }
          for (let key in trafficLightTypeProject) {
            if (key === d.shape) counter++;
          }
          if (counter === 1) {
            return DSelector.getSelectableIcon({
              typeCheck: MapObjects.trafficLight,
              id: d.id,
              layerState: this.props,
              selectedMapper: `${d.shape}`,
              deselectedMapper: `${d.shape}_disable`,
            });
          } else {
            return 'traffic';
          }
        },
      }),

      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'sidewalk-polygon' }), {
        data: this.props.relatedData.sidewalk_project,
        stroked: true,
        filled: true,
        pickable: true,
        wireframe: true,
        autoHighlight: true,
        highlightColor: [60, 179, 113],
        getLineColor: [0, 255, 0],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'sidewalk' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [175, 185, 197, 255]
            : [193, 203, 216, 255];
        },
        getPolygon: (d) => {
          if (d.polygon) {
            const parsed = wkt.parse(d.polygon);
            return parsed
              ? parsed.coordinates[0]
              : [
                  [0, 0],
                  [0.1, 0.1],
                  [0.11, 0.11],
                ];
          } else {
            return [
              [0, 0],
              [0.1, 0.1],
              [0.11, 0.11],
            ];
          }
        },
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        lineJointRounded: true,
      }),
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'road-ways-polygon' }), {
        data: this.props.relatedData.roadway_project,
        stroked: true,
        filled: true,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        wireframe: true,
        getLineColor: [146, 159, 181],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'road_ways' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [133, 143, 163, 255]
            : [146, 159, 181, 255];
        },
        getPolygon: (d) => {
          if (d.polygon) {
            const parsed = wkt.parse(d.polygon);
            return parsed
              ? parsed.coordinates[0]
              : [
                  [0, 0],
                  [0.1, 0.1],
                  [0.11, 0.11],
                ];
          } else {
            return [
              [0, 0],
              [0.1, 0.1],
              [0.11, 0.11],
            ];
          }
        },
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        lineJointRounded: true,
      }),

      new IconLayer(this.props, this.getSubLayerProps({ id: 'cluster' }), {
        data: this.state.dataCluster,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects.png`,
        iconMapping: roadObjectsMapping,
        sizeScale: 1 * this?.props?.sizeScale ?? 1,
        opacity: this?.props?.Claster === false ? 1 : 0,
        pickable: true,
        autoHighlight: true,
        fp64: true,
        billboard: false,
        getColor: [0, 128, 0],
        updateTriggers: {
          getSize: this.context?.viewport?.zoom,
        },
        getSize: 30,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => d.geometry.coordinates,
        getIcon: (d) => {
          if (d.properties.cluster) {
            return 'cluster';
          } else return ' ';
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'number-of-items-in-cluster-layer' }), {
        pickable: true,
        data: this.state.dataCluster,
        getPosition: (d) => {
          return d.geometry.coordinates;
        },
        getText: (d) => {
          if (d.properties.cluster) {
            return '' + d.properties.point_count;
          } else {
            return ' ';
          }
        },
        opacity: this?.props?.Claster === false ? 1 : 0,
        billboard: true,
        getColor: [47, 128, 237],
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: 20,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        sizeMinPixels: 20,
        sizeMaxPixels: 20,
        getPolygonOffset: DSelector.getNewPolygonOffset,
      }),
      roadMarkingLineLayer,
      roadMarkingPolygonLayer,
      roadMarkingSymbolLayer,
      signalPolesLayer,
      streetLampLayer,
      pedestrianBorderLayer,
      ...signsLayers,
    ];
  }
}

ProjectCompositeLayer.layerName = Layers.projectLayer;

export default ProjectCompositeLayer;
