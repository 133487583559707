import React from 'react';
import Icon from '@ant-design/icons';

const ArrowExpandIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.33333 17.5V15.8333H5.34167L9.09167 12.0833L7.91667 10.9083L4.16667 14.6583V11.6667H2.5V17.5H8.33333ZM12.0833 9.09167L15.8333 5.34167V8.33333H17.5V2.5H11.6667V4.16667H14.6583L10.9083 7.91667L12.0833 9.09167Z"
      fill="currentColor"
    />
  </svg>
);

export const ArrowExpandIcon = (props) => <Icon component={ArrowExpandIconSVG} {...props} />;
