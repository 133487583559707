import { CompositeLayer } from '@deck.gl/core';
import { CompositeLayerProps } from '@deck.gl/core/lib/composite-layer';

export interface ITSLayerSettings {
  itsSettings: any;
}

export default class ITSLayer<D, P extends Partial<CompositeLayerProps<D>> & ITSLayerSettings> extends CompositeLayer<
  D,
  P
> {}
