import React from 'react';
import { compose } from 'redux';

import HomePage from './HomePage';
import withContentPadding from 'HOC/withContentPadding';

const HomePageContainer = () => {
  return <HomePage />;
};

const enhance = compose(withContentPadding);

export default enhance(HomePageContainer);
