import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { KnsService } from '../../../../../../../generated/api/api';
import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';

export class Cables_troll extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'Cables_troll',
      filterLabel: 'Кабели троллейбусов',
      selectedObjectName: MapObjects.cablesTroll,
    });
  }

  async uploadData() {
    return await KnsService.knsCablesTrollList();
  }

  async deleteRecord(id) {
    return await KnsService.knsCablesTrollDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsCablesTrollRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL('/kns/cables_troll');
  }
}
