import { Layout, Modal, Select } from 'antd';

import styled from 'styled-components';
import { Button } from 'UI/common/components/Button/Button';

const { Option } = Select;

const PanelLayout = styled(Layout)`
  height: calc(100vh - 150px);
  padding: 0 20px 20px 40px;
`;

const SelectContainer = styled(Select)`
  width: calc(100% - 80px);
  margin: 0 40px;
`;

const OptionContainer = styled(Option)``;

const LocalTitle = styled.h2`
  display: flex;
  padding: 35px 0 30px 15px;
  font-weight: bold;
  font-size: 17px;
  line-height: 140%;
  p {
    padding-left: 5px;
    color: ${(props) => props.theme.colors.green};
  }
`;

const ScrollableBoxAreaContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledDrawer = styled.div`
  padding: 0;
`;

const ContainerEstimationButton = styled(Button)`
  margin-right: 5rem;
  float: right;
`;

const ModalContainer = styled(Modal)``;

export const Styled = {
  PanelLayout,
  LocalTitle,
  ScrollableBoxAreaContainer,
  StyledDrawer,
  ContainerEstimationButton,
  SelectContainer,
  OptionContainer,
  ModalContainer,
};
