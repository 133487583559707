import React, { FC } from 'react';
import WidgetWrap from '../WidgetWrap/WidgetWrap';
import { Styled } from './style/NumberWidget.styled';
import { NumberWidgetProps } from 'components/Application/BaseModules/HomePage/components/WidgetsGrid/components/NumberWidget/model/NumberWidget.model';

const { StyledTable } = Styled;

const NumberWidget: FC<NumberWidgetProps> = ({
  name = 'Числовой виджет',
  onChangeWidget,
  onDeleteWidget,
  columns,
  data,
}) => {
  return (
    <WidgetWrap name={name} bodyPadding={8} onChangeWidget={onChangeWidget} onDeleteWidget={onDeleteWidget}>
      <StyledTable columns={columns} dataSource={data} scroll={{ y: '100%' }} pagination={false} />
    </WidgetWrap>
  );
};

export default NumberWidget;
