import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

// место установки конопок завист от выбраной вкладки меню

export const pagesNeedsElectronicSignatureButton = [
  IOPWDirectory.statement_billings,
  IOPWDirectory.approval_federal_statement_resolutions,
  IOPWDirectory.approval_federal_statement_additional_inspection,
];

export const pagesNeedsShowSignatureButton = [IOPWDirectory.billings];

export const pagesNeedsDeleteButton = [
  IOPWDirectory.vehicle_parameters,
  IOPWDirectory.files,
  IOPWDirectory.calc_of_harm,
  IOPWDirectory.vehicle_axes,
  IOPWDirectory.billings,
  IOPWDirectory.approvals,
  IOPWDirectory.control_stations,
  IOPWDirectory.excess_percents,
  IOPWDirectory.non_working_days,
  IOPWDirectory.global_organizations,
  IOPWDirectory.signers,
  IOPWDirectory.responsible_persons,
];

export const pagesDontNeedsCreateButton = [
  IOPWDirectory.files,
  IOPWDirectory.vehicles,
  IOPWDirectory.adapter_messages,
  IOPWDirectory.approval_files,
  IOPWDirectory.forms_account,
  IOPWDirectory.vehicle_axis,
  IOPWDirectory.cancels,
  IOPWDirectory.history_change_statement,
];

export const pagesAddFilesButton = [IOPWDirectory.approval_files, IOPWDirectory.files];
