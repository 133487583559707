import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';

/**
 * Фильтрация по свойству isHidden (спрятаны) в RelatedData и пропускающий дальше только false результаты
 * @param uploadedData
 * @param Layer
 * @returns {*}
 */
export const applyVisibilityFilters = (uploadedData: any, Layer: LayerPrototype) => {
  const result = { ...uploadedData };
  Layer.getRelatedData()
    .filter((RelatedData) => RelatedData.isHidden)
    .forEach((RelatedData) => {
      const name = RelatedData.getName();
      result[name] = [];
    });
  return result;
};
