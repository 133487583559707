import React from 'react';
import { Detection } from '../../../generated/api/api';
import { DataInfo } from '@frontend-packages/its-ui-kit';
import { SToolsContainer } from './styles/ModerateTools.styles';

interface ModerateToolsProps {
  detectionItem: Detection | null | undefined;
}

const detectionTypeLabelMap = {
  PIT: 'Яма',
  ROAD_SURFACE: 'Дорожное покрытие',
  ROAD_MARKING: 'Дорожная разметка',
  ROAD_SIGN: 'Дорожный знак',
  SPEED_BUMP: 'Искусственная дорожная неровность сборно-разборной конструкции',
  TRAFFIC_LIGHTS: 'Светофор',
};

const detectionStatusLabelMap = {
  REQUIRED_CLARIFICATION: 'Требует уточнения',
  NEW: 'Новая',
  APPOINTED: 'Назначено',
  CONFIRMED: 'Подтверждено',
  FALSE_DETECTION: 'Ложное срабатывание',
  NOT_RELEVANT: 'Неактуально',
  APPLICATION_CREATED: 'Создана заявка',
  FIXED: 'Исправлено',
  IN_WORK: 'В работе',
  DONE: 'Выполнена',
  CLOSED: 'Закрыто',
  CANCELED: 'Отменена',
};

export const ModerateTools = ({ detectionItem }: ModerateToolsProps) => {
  const detectionDisplayValue = detectionItem?.detection_type
    ? detectionTypeLabelMap[detectionItem.detection_type]
    : 'Неизвестно';
  const statusDisplayValue = detectionItem?.status ? detectionStatusLabelMap[detectionItem.status] : 'Неизвестно';
  return (
    <SToolsContainer>
      <DataInfo title="ID" content={detectionItem?.id} />
      <DataInfo title="Внешний идентификатор распознавания" content={detectionItem?.external_id} />
      <DataInfo title="Тип объекта распознавания" content={detectionDisplayValue} />
      <DataInfo title="Комментарий" content={detectionItem?.comment} />
      <DataInfo title="Дата создания распознавания" content={detectionItem?.created_at} />
      <DataInfo title="Ближайший адрес" content={detectionItem?.nearest_address} />
      <DataInfo title="Статус" content={statusDisplayValue} />
      <DataInfo title="Тип нарушения" content={detectionItem?.violation_type_display_name} />
      <DataInfo title="Местоположение" content={detectionItem?.point} />
    </SToolsContainer>
  );
};
