import styled from 'styled-components/macro';

const HomePageStyled = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px 16px;
`;

export const Styled = {
  HomePageStyled,
};
