import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import _ from 'underscore';
import { withTheme } from 'styled-components/macro';

import { getFromURL } from 'api/getFromURL';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';
import { FormInputProps } from './model/FormInputProps.model';
import { ThemeProps } from './model/ThemeProps.model';
import Select from '../../components/Select/Select';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { OptionProps } from 'UI/common/components/Select/model/SelectProps.model';
import { SelectSkeleton } from 'UI/common/components/SkeletonInput/SkeletonInput.styled';

interface RelatedFieldInputProps extends FormInputProps, ThemeProps {}

const RelatedFieldInput = (props: RelatedFieldInputProps) => {
  const dispatch = useDispatch();
  const { control, errors } = useFormContext();
  const { description, object, field, inputProps } = props;
  const [options, setOptions] = useState<Array<OptionProps>>([]);
  const [isFetching, setFetching] = useState(false);
  const { required, message } = useBaseInputSettings(description);
  const error = _.get(errors, field);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const objectValue = useMemo(() => (object ? _.get(object, field) : null), [field, object]);

  useLayoutEffect(() => {
    (async () => {
      if (description.url) {
        try {
          setFetching(true);
          const { results } = await getFromURL.getRepresentationData(description.url!.replace('/api', ''));

          const options = results.map((el: { key: number; value: string }) => ({
            value: el.key,
            label: el.value,
          }));

          setOptions(options);
        } catch (e) {
          if (_.isObject(e) && e?.response && _.isObject(e.response)) {
            const message = createAlertMessage(e);
            dispatch(
              notificationActions.setGlobalAlertData({
                status: ActiveStatus.active,
                type: PromptType.error,
                title: 'Ошибка',
                message: message,
              })
            );
            setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 5000);
          }
        } finally {
          setFetching(false);
        }
      }
    })();
  }, [description, dispatch, field]);

  if (error && error.message?.includes(description.label)) {
    error.message = null;
  }

  const onChange = useCallback(([value]) => value ?? null, []);

  return (
    <Form.Item>
      <Controller
        name={field}
        control={control}
        defaultValue={objectValue}
        required={required}
        error={!!error}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        onChange={onChange}
        as={(renderProps) => (
          <>
            {isFetching && <SelectSkeleton />}
            {!isFetching && (
              <Select
                {...inputProps}
                {...renderProps}
                ref={inputRef}
                options={options}
                showSearch
                allowClear
                placeholder={description.label}
                mode={description?.type.includes('multi') ? 'multiple' : 'default'}
              />
            )}
          </>
        )}
      />
    </Form.Item>
  );
};

export default withTheme(RelatedFieldInput);
