import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { RoadAccidentsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';
import React from 'react';

const RoadAccidentsSwitcher = React.lazy(() => import('../customButtons/RoadAccidentsSwitcher.lazy'));

export class RoadAccidentsDataAccessor extends LayerDataAccessorPrototype {
  static selectedType = LayerDataAccessorPrototype.selectedType;

  actionButtons = [RoadAccidentsSwitcher];

  constructor() {
    super({
      label: 'Список ДТП',
      selectedType: MapObjects.roadAccidents,
    });
  }

  async getData() {
    return await RoadAccidentsService.roadAccidentsList();
  }

  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.road_accidents);
  }

  static replaceSelectedType(type: any) {
    RoadAccidentsDataAccessor.selectedType = type;
  }

  static getSelectedType() {
    return RoadAccidentsDataAccessor.selectedType;
  }
  getSelectedObjectType() {
    return RoadAccidentsDataAccessor.selectedType;
  }
}
