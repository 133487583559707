import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { TlControllersService } from '../../../../../../generated/api/api';

export class TrafficLightsObjectsSchemaWork extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'traffic_light_object_schema_work',
      filterLabel: 'Светофорные объекты',
    });
  }

  async uploadData() {
    return await TlControllersService.tlControllersStatesGetCurrentStatesRetrieve();
  }

  async readRecord(id) {
    return await TlControllersService.tlControllersStatesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL('/tl_controllers/states/get_current_states/');
  }
}
