import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { ekbLayerCards } from '../description/cards/ekb';
import { layerDataAccessors } from '../description/dataAccessors';
import { layerSchemas } from '../description/layerSchemaPresets';
import { relatedLayerData } from '../description/relatedData';
import OrderRoutePathSelection from '../../../../layers/SelectedObjectsRepresentations/OrderRouteSelection/OrderRoutePathSelection';
import { projectLayerGroups } from '../description/layerGroups';
import { layerSettings } from '../description/settings';

export class TransportOrdersLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.transportOrders,
      editable: false,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.TransportOrdersDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.OrderRoute(),
        new relatedLayerData.ekbRelatedDataForLayers.AxleLoadOverloads(),
        new relatedLayerData.ekbRelatedDataForLayers.DimensionsHeightOversizes(),
        new relatedLayerData.ekbRelatedDataForLayers.DimensionsWidthOversizes(),
        new relatedLayerData.ekbRelatedDataForLayers.GeneralDimensionsHeightWidthOversizes(),
      ],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.TransportOrdersLayerCard(),
      customSettings: [new layerSettings.IconSizeScale(), new layerSettings.LineWidthSetting()],
      layerSchema: new layerSchemas.ekbLayersSchema.TransportOrdersLayerSchema(),
      selectedObjectPrototype: OrderRoutePathSelection,
    });
  }
}
