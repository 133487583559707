import React from 'react';
import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { RoadWorksService } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

const AddTrafficManagementProjectsGeometryButton = React.lazy(
  () => import('../customButtons/TrafficManagementProjectsButtons/AddTrafficManagementProjectsGeometryButton.lazy')
);
let selectedType = 'traffic_management_projects';

export class TrafficManagementProjectsDataAccessor extends LayerDataAccessorPrototype {
  // отвечает за объект который выбирается в дата ацессоре, так как он общий для экземпляров класса то сделаем его статичным, чтобы не создавать лишние экземпляры
  static selectedType = LayerDataAccessorPrototype.selectedType;

  actionButtons = [AddTrafficManagementProjectsGeometryButton];

  constructor() {
    super({
      label: 'ПОДД',
      selectedType: MapObjects.trafficManagementProjects,
    });
  }

  async getData() {
    return await RoadWorksService.roadWorksTrafficManagementProjectsList();
  }

  // по запросу по модели вернем нашу схему. Await нужен для согласованности then, можно и в промис обернуть, но лень)
  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadWorksTrafficManagementProjects);
  }

  // метод замены статичной переменной
  static replaceSelectedType() {}

  static getSelectedType() {
    return selectedType;
  }

  //оверрайдим метод прототипа для получения статичной переменной иначе получим переменную прототипа
  getSelectedObjectType() {
    return selectedType;
  }
}
