import styled from 'styled-components/macro';
import { ArrowExpandIcon } from 'UI/custom/icons/actionIcons/ArrowExpandIcon';
import { Modal } from 'antd';
import { Button } from 'UI/common/components/Button/Button';

const LocalItemImageWrap = styled.div`
  display: flex;
  padding: 8px;
  background: ${(props) => props.theme.colors.defaultLight};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  max-height: 92px;
  margin-bottom: 8px;
  width: 100%;
  position: relative;
`;

const ArrowIcon = styled(ArrowExpandIcon)`
  top: 20px;
  right: 20px;
  position: absolute;
  color: white;
  pointer-events: none;
`;

const LocalItemWrapImage = styled.div`
  overflow: hidden;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  cursor: pointer;
`;

const LocalItemImage = styled.img`
  width: 100%;
`;

const LocalItemImageModal = styled(Modal)`
  max-width: calc(100vw - 200px);

  & .ant-modal-body {
    padding: 0;
    position: relative;
  }

  & .ant-modal-close {
    color: white;
  }
`;

const PrevImageButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  left: 5px;
  opacity: 30%;

  &:hover {
    opacity: 100%;
  }
`;

const NextImageButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  right: 5px;
  opacity: 30%;

  &:hover {
    opacity: 100%;
  }
`;

export const Styled = {
  LocalItemImageWrap,
  LocalItemWrapImage,
  LocalItemImage,
  LocalItemImageModal,
  ArrowIcon,
  PrevImageButton,
  NextImageButton,
};
