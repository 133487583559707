import React, { FC } from 'react';

import { Styled } from './style/WidgetWrap.styled';
import { Button } from 'UI/common/components/Button/Button';
import { SettingsIcon } from 'UI/custom/icons/actionIcons/SettingsIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { WidgetWrapProps } from './model/WidgetWrap.model';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';

const { WidgetWrapStyled, WidgetWrapHeader, WidgetWrapHeaderName, WidgetWrapBody, WidgetWrapButtonWrap, DragIcon } =
  Styled;

const WidgetWrap: FC<WidgetWrapProps> = ({
  name,
  bodyPadding,
  onChangeWidget,
  onDeleteWidget,
  staticWidget = false,
  children,
}) => {
  return (
    <WidgetWrapStyled>
      <WidgetWrapHeader>
        <WidgetWrapHeaderName>{name}</WidgetWrapHeaderName>
        <WidgetWrapButtonWrap>
          {onChangeWidget && (
            <Button
              icon={<SettingsIcon />}
              type={ButtonType.unbounded}
              p={0}
              iconSize={20}
              iconColor={'#6A7B9B'}
              onClick={onChangeWidget}
            />
          )}
          {onDeleteWidget && (
            <Button
              icon={<DeleteIcon />}
              type={ButtonType.unbounded}
              p={0}
              iconSize={20}
              iconColor={'#EB5757'}
              onClick={onDeleteWidget}
            />
          )}
        </WidgetWrapButtonWrap>
        {!staticWidget && <DragIcon />}
      </WidgetWrapHeader>
      <WidgetWrapBody padding={bodyPadding}>{children}</WidgetWrapBody>
    </WidgetWrapStyled>
  );
};

export default WidgetWrap;
