import React from 'react';
import { connect } from 'react-redux';
import { TopActionBar } from './TopActionBar';

const TopActionBarContainer = (props) => {
  const { editableObject } = props;
  return <TopActionBar editableObject={editableObject} />;
};

const mapStateToProps = (state) => ({
  editableObject: state.mapboxEditableStandaloneObject,
});

export default connect(mapStateToProps, null)(TopActionBarContainer);
