import React from 'react';
import { Input } from 'antd';
import { Styled } from './style/SearchPanel.styled';
import { SearchPanelProps } from './model/SearchPanel.model';

const { Search } = Input;

const { ExtendedStyledInput } = Styled;

const SearchPanel = ({ color, input, onChange }: SearchPanelProps) => {
  return (
    <ExtendedStyledInput color={color}>
      <>
        <Search placeholder="Поиск данных..." value={input} onChange={onChange} />
      </>
    </ExtendedStyledInput>
  );
};

export default SearchPanel;
