import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RoadsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class SignalPoles extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'signal_poles',
      filterLabel: 'Сигнальные столбики',
      selectedObjectName: MapObjects.signalPoles,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsSignalBarsList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsSignalBarsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsSignalBarsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.signalBars);
  }
}
