import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { Divider } from 'antd';

import PanelScrollableCard from '../ScrollableCard/ScrollableCard';
import { Styled } from './RepresentationCheckedItem.styled';
import SearchPanel from 'UI/common/components/SearchPanel/SearchPanel';

const {
  LocalMainWrapper,
  LocalHeaderMainWrapper,
  LocalCheckboxWrapper,
  LocalWrapperDivider,
  LocalHeaderWrapper,
  LocalInnerWrapper,
} = Styled;

interface RepresentationCheckedItemProps {
  items: any[];
  width: number;
  height: number;
  updateInput: (currentInput: ChangeEvent<HTMLInputElement>) => void;
  input: string;
  handleAllChecked: (currentInput: ChangeEvent<HTMLInputElement>) => void;
  nameAllCheckbox: string;
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
}

const RepresentationCheckedItem = ({
  items,
  height,
  width,
  handleAllChecked,
  updateInput,
  input,
  nameAllCheckbox,
  isChecked,
  setIsChecked,
}: RepresentationCheckedItemProps) => {
  return (
    <LocalMainWrapper>
      <LocalInnerWrapper>
        <PanelScrollableCard
          headerStyles={{ boxShadow: `none`, padding: `0px` }}
          footerStyles={{ padding: 0 }}
          contentStyles={{
            marginRight: `4px`,
            padding: `1.1rem`,
            lineHeight: 2.5,
            position: `relative`,
          }}
          wrapperStyles={{
            width,
            height,
          }}
          headerContent={
            <LocalHeaderMainWrapper style={{ width: `100%` }}>
              <LocalHeaderWrapper>
                <SearchPanel color="white" onChange={updateInput} input={input} />
                <LocalCheckboxWrapper>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingTop: '2px' }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          handleAllChecked(event);
                          if (setIsChecked) setIsChecked(!isChecked);
                        }}
                        value={nameAllCheckbox}
                        checked={isChecked}
                      />
                    </div>
                    <div style={{ paddingLeft: '20px' }}>
                      <label>Выбрать всё</label>
                    </div>
                  </div>
                </LocalCheckboxWrapper>
              </LocalHeaderWrapper>
              <LocalWrapperDivider>
                <Divider />
              </LocalWrapperDivider>
            </LocalHeaderMainWrapper>
          }
          children={items}
        />
      </LocalInnerWrapper>
    </LocalMainWrapper>
  );
};

export default RepresentationCheckedItem;
