import React from 'react';
import LayerFilterTab from './LayerFilterTab';
import { getSelectedMapboxLayer } from '../../../../../../../../../../../../store/reducers/map/mapboxLayers';
import { connect } from 'react-redux';
import { appActions } from '../../../../../../../../../../../../store/reducers/global/app/app.actions';

/**
 * Получает пропсы из стэйта, диспатч и прокидывает это в компоненту
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const LayerFilterTabContainer = (props) => {
  return <LayerFilterTab {...props} />;
};

LayerFilterTabContainer.propTypes = {};

const mapStateToProps = (state) => ({
  selectedLayer: getSelectedMapboxLayer(state),
  recalculated: state.app.recalculated,
});

const mapDispatchToProps = (dispatch) => ({
  recalculate: () => dispatch(appActions.recalculate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerFilterTabContainer);
