import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { WidgetMenuIcon } from 'UI/custom/icons/actionIcons/WidgetMenuIcon';
import { StateModel } from 'store/reducers';
import { widgetsActions } from 'store/reducers/widgets/widgets.actions';
import { isEmpty } from 'lodash';

const WidgetSettings: FC = () => {
  const dispatch = useDispatch();
  const isOpenPanel = useSelector((state: StateModel) => state.widgets.widgetsEditPanelVisible);
  const userWidgetsRights = useSelector(() => (state: StateModel) => state.auth.permissions.widgets);
  const isUserHasWidgetRights = !isEmpty(userWidgetsRights);

  const toggleWidgetsPanel = useCallback(() => {
    dispatch(widgetsActions.togglePanelVisible());
  }, [dispatch]);

  const buttonType = useMemo(() => (isOpenPanel ? ButtonType.primaryTranslucent : ButtonType.unbounded), [isOpenPanel]);

  if (!isUserHasWidgetRights) return null;

  return <Button type={buttonType} icon={<WidgetMenuIcon />} onClick={toggleWidgetsPanel} />;
};

export default WidgetSettings;
