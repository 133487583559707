import { Input } from 'antd';

import styled from 'styled-components';

const LocalTitle = styled.h2`
  text-align: left;
  font-size: ${(props) => props.theme.fontSettings.sectionTitle};
`;

const LocalMainWrapper = styled.div`
  padding: 40px;
  width: 310px;
`;

const LocalMainInputWrapper = styled.div`
  width: 314px;
  .inline-placeholder-login {
    user-select: none;
    pointer-events: none;
    z-index: 1;
    font-size: 16px;
    color: ${(props) => props.theme.colors.neutralDark};
    position: absolute;
    top: 50px;
    left: 10px;
    transition: all 0.2s;
  }

  .inline-placeholder-login.close.focused {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    font-size: 13px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
  }

  .inline-placeholder-login.close {
    color: ${(props) => props.theme.colors.neutralDarker};
  }

  .inline-placeholder-login.open {
    position: absolute;
    transform: translate3d(0, -6px, 0);
    color: ${(props) => props.theme.colors.primary};
    font-size: 13px;
    font-weight: bold;
  }
`;

const LocalInputWrapper = styled.div`
  padding-top: 30px;
  position: relative;
  margin: 0 auto 20px;
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  & .ant-input {
    background: ${(props) => props.theme.colors.defaultLight};
    height: 60px;
    border-radius: 8px;
    padding-top: 30px;
  }
`;

const StyledInput = styled(Input)`
  .ant-input {
    width: 315px;
    height: 60px;
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border-radius: 4px;
    padding-left: 10px;
    padding-top: 25px;
  }

  .ant-input:focus {
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border: 2px solid ${(props) => props.theme.colors.neutralDark};
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
  }

  .ant-input::placeholder {
    color: transparent;
  }

  .ant-input:hover {
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border: 2px solid ${(props) => props.theme.colors.neutralDark};
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
  }
`;

export const Styled = {
  LocalTitle,
  LocalMainWrapper,
  LocalMainInputWrapper,
  LocalInputWrapper,
  StyledInput,
};
