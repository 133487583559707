import React from 'react';
import { Styled } from './LayerSettings.styled';
import SettingsTypeSwitcherContainer from './SettingsTypeSwitcher/SettingsTypeSwitcherContainer';
import LayerSettingsContentContainer from './LayerSettingsContent/LayerSettingsContentContainer';
import { baseStyles } from 'components/stylesRegistrator';
import { Button } from 'UI/common/components/Button/Button';
import { MenuIcon } from 'UI/custom/icons/actionIcons/MenuIcon';
import { RefreshIcon } from 'UI/custom/icons/actionIcons/RefreshIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

const {
  BaseMapLayerPanelWrapperSecondary,
  BaseMapPanelTopPart,
  BaseMapPanelHeader,
  BaseMapPanelPrimaryTitle,
  BaseMapPanelItem,
} = baseStyles.modules.map;

const { SettingsControlsWrapper } = Styled;

interface ILayerSettings {
  toggleLayerSettingsVisibility: () => void;
  item: any;
  revertToDefaultLayerSettings: (keyOfLayer: string) => void;
}

const iconSize = { width: '20px', height: '20px' };

export const LayerSettings = ({
  toggleLayerSettingsVisibility,
  item,
  revertToDefaultLayerSettings,
}: ILayerSettings) => {
  return (
    <>
      <BaseMapLayerPanelWrapperSecondary>
        <BaseMapPanelHeader>
          <BaseMapPanelTopPart>
            <Button onClick={toggleLayerSettingsVisibility} p={'0'} type={ButtonType.unbounded} icon={<MenuIcon />} />
            <BaseMapPanelPrimaryTitle>Настройки слоя</BaseMapPanelPrimaryTitle>
            <Button
              p={'none'}
              type={ButtonType.unbounded}
              icon={<RefreshIcon />}
              iconSize={iconSize}
              onClick={() => revertToDefaultLayerSettings(item.name)}
            />
          </BaseMapPanelTopPart>
          <SettingsTypeSwitcherContainer />
        </BaseMapPanelHeader>
        <BaseMapPanelItem>
          <SettingsControlsWrapper>
            <LayerSettingsContentContainer />
          </SettingsControlsWrapper>
        </BaseMapPanelItem>
      </BaseMapLayerPanelWrapperSecondary>
    </>
  );
};
