import { Shape } from 'konva/lib/Shape';
import { Stage } from 'konva/lib/Stage';

import CameraMaskPrototype, {
  MaskObjectList,
  MaskPoints,
  PolygonConfig,
  TypePoints,
} from './prototype/CameraMaskPrototype';
import { ObjectType } from './prototype/ObjectType';
import { DetectionMask, MaskTypeEnum } from 'generated/api/api';
import _ from 'lodash';

class RoadSignsMask extends CameraMaskPrototype {
  protected static maskTypeName = 'Дорожный знак';
  protected static maskType = MaskTypeEnum.RoadSignsChecker;
  protected static maskObjectList: Array<MaskObjectList> = [
    {
      type: ObjectType.roiPoints,
      name: 'Область знака',
      help: 'Выделите сначала левый верхний, а потом правый нижний угол знака. Минимальный размер области выделения дорожного знака - 40х40px',
    },
  ];
  protected static polygonConfig: PolygonConfig = {
    ...CameraMaskPrototype.polygonConfig,
    fill: 'rgba(45, 156, 219, 0.08)',
    stroke: '#56CCF2',
  };

  constructor(cameraID: number, mask: DetectionMask | null = null) {
    super(cameraID, mask);
  }

  static getMaskTypeName(): string {
    return RoadSignsMask.maskTypeName;
  }

  static getMaskType(): string {
    return RoadSignsMask.maskType;
  }

  static getMaskObjectList(): Array<MaskObjectList> {
    return RoadSignsMask.maskObjectList;
  }

  get maskTypeName(): string {
    return RoadSignsMask.maskTypeName;
  }

  get maskType(): MaskTypeEnum {
    return RoadSignsMask.maskType;
  }

  get maskObjectList(): Array<MaskObjectList> {
    return RoadSignsMask.maskObjectList;
  }

  get polygonConfig(): PolygonConfig {
    return RoadSignsMask.polygonConfig;
  }

  get maskPoints(): MaskPoints {
    const maskPoints: MaskPoints = _.cloneDeep(this._maskPoints);

    const roiPoints = this._maskPoints.roi_points;

    if (roiPoints?.length === 2) {
      const [x1, y1, x2, y2] = [roiPoints[0][0], roiPoints[0][1], roiPoints[1][0], roiPoints[1][1]];
      maskPoints.roi_points = [roiPoints[0], [x2, y1], roiPoints[1], [x1, y2]];
    }

    return maskPoints;
  }

  get drawMaskPoints(): MaskPoints {
    const maskPoints: MaskPoints = { ...this._drawMaskPoints };

    const roiPoints = maskPoints.roi_points;

    if (roiPoints?.length === 2) {
      const [x1, y1, x2, y2] = [roiPoints[0][0], roiPoints[0][1], roiPoints[1][0], roiPoints[1][1]];
      maskPoints.roi_points = [roiPoints[0], [x2, y1], roiPoints[1], [x1, y2]];
    }

    return maskPoints;
  }

  set drawMaskPoints(points: MaskPoints) {
    const roiPoints = points.roi_points;
    if (roiPoints && roiPoints?.length > 2) {
      this._drawMaskPoints = {
        ...points,
        roi_points: [roiPoints[0], roiPoints[2]],
      };
    } else {
      this._drawMaskPoints = points;
    }
  }

  checkRules(): boolean {
    const roiPoints = this._drawMaskPoints.roi_points;

    if (roiPoints?.length === 2) {
      const [x1, y1, x2, y2, x3, y3] = [
        roiPoints[0][0],
        roiPoints[0][1],
        roiPoints[1][0],
        roiPoints[1][1],
        roiPoints[1][0],
        roiPoints[0][1],
      ];

      const lengthFirstSegment = Math.sqrt((x3 - x1) ** 2 + (y3 - y1) ** 2);
      const lengthSecondSegment = Math.sqrt((x2 - x3) ** 2 + (y2 - y3) ** 2);

      return x1 < x2 && y1 < y2 && lengthFirstSegment > 40 && lengthSecondSegment > 40;
    }

    return false;
  }

  drawHandler(target: Shape | Stage): TypePoints {
    const stage = target.getStage();

    const point = stage?.getPointerPosition();
    let newPoints: TypePoints = [];

    if (this.currentDrawType) {
      const drawPoints = this._drawMaskPoints?.[this.currentDrawType] ?? [];
      newPoints = [...drawPoints];
    }

    if (point) {
      const newPoint = [point.x, point.y];

      newPoints = [...newPoints, newPoint];

      if (newPoints.length > 2) {
        newPoints.pop();
      }
    }

    return newPoints;
  }
}

export default RoadSignsMask;
