export const SET_MAP_SELECTED_OBJECT = 'SET_MAP_SELECTED_OBJECT';
export const CLEAR_MAP_SELECTED_OBJECT = 'CLEAR_MAP_SELECTED_OBJECT';
export const UPDATE_SELECTED_OBJECT = 'UPDATE_SELECTED_OBJECT';

const initialState = {};

//temporally example of state structure {data: {geometry: {...}, properties: {...}}}

const mapSelectedObject = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MAP_SELECTED_OBJECT: {
      return initialState;
    }
    case SET_MAP_SELECTED_OBJECT: {
      const { data, type, selectedObject, componentToRender } = action.payload;
      return {
        ...state,
        data,
        type,
        selectedObject,
        componentToRender,
      };
    }
    case UPDATE_SELECTED_OBJECT: {
      const enteredData = action.payload.data;
      if (!state.data || !enteredData) return state;
      try {
        return {
          ...state,
          ...enteredData,
        };
      } catch (e) {
        console.log(action.payload);
      }
      break;
    }
    default:
      return state;
  }
};

export default mapSelectedObject;
