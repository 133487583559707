import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { RoadTechnicalCharacteristics } from '../../../types/enums/map/layers/RoadTechnicalCharacteristics.model';

const RoadTechnicalCharacteristicsSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'road');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/api/${selectedMapItem.data[0].urlData.replace('_repr', '')}/${id}/`,
      '_blank'
    );
    win.focus();
  };
  let Descriptions = [];

  const properties = data;
  properties.segment_name &&
    Descriptions.push({
      key: 'Участок',
      value: properties?.segment_name,
    });

  properties.start_segment &&
    Descriptions.push({
      key: 'Начало участка',
      value: `${properties?.start_segment?.toFixed(2)} км`,
    });

  properties.end_segment &&
    Descriptions.push({
      key: 'Конец участка',
      value: `${properties?.end_segment?.toFixed(2)} км`,
    });

  properties.segment_length &&
    Descriptions.push({
      key: 'Протяженность',
      value: `${properties?.segment_length?.toFixed(2)} км`,
    });

  properties.axle_load &&
    Descriptions.push({
      key: 'Нагрузка на ось',
      value: RoadTechnicalCharacteristics[properties.axle_load],
    });

  properties.coating_type &&
    Descriptions.push({
      key: 'Вид покрытия',
      value: RoadTechnicalCharacteristics[properties.coating_type],
    });

  properties.lanes_amount &&
    Descriptions.push({
      key: 'Количество полос',
      value: properties.lanes_amount,
    });

  return (
    <PrepareScrollableInfoCard
      title={'Диагностика УДС'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default RoadTechnicalCharacteristicsSelection;
