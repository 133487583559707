import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';

export class transportAnalysisDistrictTobolskiiy extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'transportAnalysisDistrictTobolskiiy',
      filterLabel: 'Тобольский район',
    });
  }

  async uploadData() {
    return await getFromURL.getWithParams('/public_transport/checkpoints_you_can_reach/', { district_id: '20' });
  }
}
