import { Dispatch } from 'react';

import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { WatcherFieldsFunction } from 'hooks/form/usePrepareFieldsHook/model/watchFieldsModel.model';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { CameraWidget, WidgetTypeEnum } from 'generated/api/api';

export const widgetTypeWatcher =
  (model: ModelResponse, data: CameraWidget, setModel: Dispatch<ModelResponse>): WatcherFieldsFunction =>
  (setValue, value: WidgetTypeEnum) => {
    const currentType = value ?? data.widget_type;

    const hideFields = [
      'chart_type',
      'data_color',
      'show_captions',
      'show_grid_lines_and_divisions',
      'show_numerical_value',
    ];
    let requiredFields: Array<string> = ['chart_type', 'data_color'];

    const scheme = model.scheme;

    for (const field in scheme) {
      if (scheme.hasOwnProperty(field)) {
        const description = scheme[field];

        if (description.type === SchemaFieldType.field) {
          const children = description?.child?.children;

          if (children) {
            for (const field in children) {
              if (children.hasOwnProperty(field)) {
                const description = children[field];

                if (hideFields.includes(field)) {
                  description.read_only = currentType === WidgetTypeEnum.NUMERIC;
                }

                if (requiredFields.includes(field)) {
                  description.required = currentType === WidgetTypeEnum.GRAPHIC;
                }
              }
            }
          }
        }

        if (hideFields.includes(field)) {
          description.read_only = currentType === WidgetTypeEnum.NUMERIC;
        }

        if (requiredFields.includes(field)) {
          description.required = currentType === WidgetTypeEnum.GRAPHIC;
        }
      }
    }

    setModel(model);
  };
