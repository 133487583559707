import styled from 'styled-components/macro';
import { LegendItemProps } from '../model/PieChart.model';

const PieChartWrap = styled.div`
  height: 100%;
  display: flex;
`;

const PieChartLegendWrap = styled.div`
  height: 100%;
  width: 250px;
  margin-left: 16px;
  overflow-y: auto;
  flex-shrink: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PieChartLegend = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`;

const PieChartLegendItem = styled.div<LegendItemProps>`
  padding-left: 68px;
  position: relative;
  color: ${(props) => (props.color ? props.color : '#6A7B9B')};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;

  &::before {
    content: '';
    position: absolute;
    background: ${(props) => (props.color ? props.color : '#6A7B9B')};
    width: 60px;
    height: 4px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const PieChartLegendItemName = styled.span`
  text-decoration: underline;
`;

const PieChartLegendItemValue = styled.span``;

export const Styled = {
  PieChartWrap,
  PieChartLegend,
  PieChartLegendWrap,
  PieChartLegendItem,
  PieChartLegendItemName,
  PieChartLegendItemValue,
};
