export const trafficLightTypeProject = {
  P1: { x: 0, y: 0, width: 248, height: 248 },
  P2: { x: 0, y: 0, width: 248, height: 248 },
  P1_disable: { x: 0, y: 249, width: 248, height: 248 },
  P2_disable: { x: 0, y: 249, width: 248, height: 248 },
  T1l: { x: 249, y: 0, width: 248, height: 248 },
  T1l_disable: { x: 249, y: 249, width: 248, height: 248 },
  T3l: { x: 0, y: 498, width: 248, height: 248 },
  T3l_disable: { x: 249, y: 498, width: 248, height: 248 },
  T1p: { x: 498, y: 0, width: 248, height: 248 },
  T1p_disable: { x: 498, y: 249, width: 248, height: 248 },
  T1pl: { x: 498, y: 498, width: 248, height: 248 },
  T1pl_disable: { x: 0, y: 747, width: 248, height: 248 },
  T3p: { x: 249, y: 747, width: 248, height: 248 },
  T3p_disable: { x: 498, y: 747, width: 248, height: 248 },
  T1: { x: 747, y: 0, width: 248, height: 248 },
  T8: { x: 747, y: 0, width: 248, height: 248 },
  T3: { x: 747, y: 0, width: 248, height: 248 },
  T1_disable: { x: 747, y: 249, width: 248, height: 248 },
  T8_disable: { x: 747, y: 249, width: 248, height: 248 },
  T3_disable: { x: 747, y: 249, width: 248, height: 248 },
  // Ниже знаки которые есть в дизайне, но которых не приходит с бэка, возможно когда-то пригодятся
  // T2L:{'x': 747,'y': 498,'width': 248,'height': 248},
  // T2L1:{'x': 747,'y': 747,'width': 248,'height': 248},
  // T2L_disable:{'x': 0,'y': 996,'width': 248,'height': 248},
  // T2L1_disable:{'x': 249,'y': 996,'width': 248,'height': 248},
  // T2P:{'x': 498,'y': 996,'width': 248,'height': 248},
  // T2P1:{'x': 747,'y': 996,'width': 248,'height': 248},
  // T2P_disable:{'x': 996,'y': 0,'width': 248,'height': 248},
  // T2P1_disable:{'x': 996,'y': 249,'width': 248,'height': 248},
  // T2PP:{'x': 996,'y': 498,'width': 248,'height': 248},
  // T2PP1:{'x': 996,'y': 747,'width': 248,'height': 248},
  // T2PP_disable:{'x': 996,'y': 996,'width': 248,'height': 248},
  // T2PP1_disable:{'x': 0,'y': 1245,'width': 248,'height': 248},
  T5: { x: 249, y: 1245, width: 248, height: 248 },
  T5_disable: { x: 498, y: 1245, width: 248, height: 248 },
  T6d: { x: 747, y: 1245, width: 248, height: 248 },
  T6dT10: { x: 747, y: 1245, width: 248, height: 248 },
  T6d_disable: { x: 996, y: 1245, width: 248, height: 248 },
  T6dT10_disable: { x: 996, y: 1245, width: 248, height: 248 },
  T7: { x: 1245, y: 0, width: 248, height: 248 },
  T7_disable: { x: 1245, y: 249, width: 248, height: 248 },
  traffic: { x: 1245, y: 498, width: 128, height: 128 },
  traffic_disable: { x: 1245, y: 627, width: 128, height: 128 },
};
