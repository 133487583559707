import styled from 'styled-components';

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ContainerMainButton = styled.div`
  display: flex;
  & > button {
    margin-left: 22px;
    height: 40px;
  }
  button:first-child {
    margin-left: 0;
  }
`;

const ContainerActionPanel = styled.div`
  padding-top: 5px;
  button:first-child {
    margin-left: 60px;
  }
  div:before,
  div:after {
    display: none;
  }
`;

export const Styled = { ContainerActionPanel, ContainerButton, ContainerMainButton };
