import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { relatedLayerData } from '../description/relatedData';
import { ekbLayerCards } from '../description/cards/ekb';
import { layerSchemas } from '../description/layerSchemaPresets';
import RoadAccidentsItemSelection from '../../../../layers/SelectedObjectsRepresentations/RoadAccidentsItemSelection/RoadAccidentsItemSelection';
import { projectLayerGroups } from '../description/layerGroups';
import { layerDataAccessors } from '../description/dataAccessors';
import { layerBuilder } from '../description';

export class RoadAccidentsLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.roadAccidents,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.RoadAccidentsDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.RoadAccidentsItem(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadAccidentsSections(),
      ],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.RoadAccidentsLayerCard(),
      customSettings: [
        new layerBuilder.layerSettings.OpacitySetting(),
        new layerBuilder.layerSettings.IconSizeScale(),
        new layerBuilder.layerSettings.Radius(),
        new layerBuilder.layerSettings.ElevationScale(),
      ],
      layerSchema: new layerSchemas.ekbLayersSchema.RoadAccidentsLayerSchema(),
      selectedObjectPrototype: RoadAccidentsItemSelection,
    });
  }
}
