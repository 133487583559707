import moment from 'moment';
import { DatePicker, Form } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import { Styled } from '../styled/BaseInput.styled';
import _ from 'underscore';
import 'moment/locale/ru';

const StyledDatePicker = styled(DatePicker)`
  &.ant-calendar-picker {
    width: 100%;
  }
  &.ant-picker,
  .ant-picker-input {
    width: 100%;
    height: 56px;
    border: none;
    background: ${(props) => props.theme.colors.backgroundNeutral};
    input {
      width: 90%;
    }
    span {
      width: 10%;
    }
  }

  .ant-input {
    height: 60px;
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border-radius: 4px;
    padding-top: 25px;
    border: none;

    &::placeholder {
      color: transparent;
    }
  }
`;

const { BasePlaceholder, BaseErrorMessage } = Styled;

const DateTimeInput = (props) => {
  const { errors, setValue, control } = useFormContext();
  const [placeholderState, setPlaceholderState] = useState(false);
  const { description, object, field, formProps } = props;
  const { showTime } = formProps || {};
  const [dateTime, setDateTime] = useState(null);

  // Хук для первоначальной инициализаци данных (если данные присутствуют у объекта), в последующем мы меняем данные через onChange
  useEffect(() => {
    if (object?.[field]) {
      const dateValue = moment(object[field]);
      setValue(field, dateValue);
      setDateTime(dateValue);
      setPlaceholderState(true);
    }
  }, [field, object, setValue]);

  const onChange = useCallback(
    (dateString) => {
      setValue(field, dateString);
      setDateTime(dateString);
      setPlaceholderState(true);
    },
    [field, setValue]
  );

  const disabledDate = useCallback(
    (date) => {
      if (field === 'datetime_start') {
        return Date.parse(date) > Date.parse(object[field]);
      } else if (field === 'datetime_end') {
        return Date.parse(date) < Date.parse(object[field]);
      }
    },
    [field, object]
  );

  const className = useMemo(() => {
    if (placeholderState) return 'opened';
    if (!_.isEmpty(errors) && description.required) return 'class_errors';
    return '';
  }, [description.required, errors, placeholderState]);

  if (errors[field]?.message?.includes(description.label) && errors[field]) {
    errors[field].message = null;
  }

  if (!object) return null;

  return (
    <Form.Item key={'form_' + field} className={className}>
      <Controller
        name={field}
        key={'input_' + field}
        as={(propsData) => <StyledDatePicker {...propsData} value={dateTime} onChange={onChange} />}
        showTime={
          showTime &&
          description.type === 'datetime' && {
            defaultValue: moment('06:00', 'HH:mm'),
            format: 'HH:mm',
          }
        }
        disabled={description.read_only}
        disabledDate={disabledDate}
        control={control}
        placeholder=""
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <BasePlaceholder className={className}>{description.label}</BasePlaceholder>
    </Form.Item>
  );
};

export default DateTimeInput;
