import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { ekbLayerCards } from '../description/cards/ekb';
import { layerSettings } from '../description/settings';
import RoadSchemeItemSelection from '../../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/RoadSchemeItemSelection';
import { layerDataAccessors } from '../description/dataAccessors';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { layerTemplates } from '../description/templates';
import { projectLayerGroups } from '../description/layerGroups';

export class RoadSchemaLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.roadsSchema,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.RoadsDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.DigitalTwinRoadSignsGroup(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadSigns(),
        new relatedLayerData.ekbRelatedDataForLayers.Segments(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadMarkings(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadMarkingLine(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadMarkingPolygon(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadsDividingLines(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadWays(),
        new relatedLayerData.ekbRelatedDataForLayers.TramRails(),
        new relatedLayerData.ekbRelatedDataForLayers.Sidewalk(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadFences(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadPedestrianFences(),
        new relatedLayerData.ekbRelatedDataForLayers.TrafficLight(),
        new relatedLayerData.ekbRelatedDataForLayers.EngineeringConstructions(),
        new relatedLayerData.ekbRelatedDataForLayers.Pipes(),
        new relatedLayerData.ekbRelatedDataForLayers.SignalPoles(),
        new relatedLayerData.ekbRelatedDataForLayers.SupportPillars(),
        new relatedLayerData.ekbRelatedDataForLayers.Lighting(),
        new relatedLayerData.ekbRelatedDataForLayers.SupportWall(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadTraySystem(),
        new relatedLayerData.ekbRelatedDataForLayers.Cabinets(),
        new relatedLayerData.ekbRelatedDataForLayers.ImprovementElements(),
        new relatedLayerData.ekbRelatedDataForLayers.ContoursLawn(),
        new relatedLayerData.ekbRelatedDataForLayers.TownSquares(),
        new relatedLayerData.ekbRelatedDataForLayers.BusShelters(),
      ],
      layerGroup: new projectLayerGroups.MsdidiLayerGroup(),
      card: new ekbLayerCards.RoadSchemaCard(),
      customSettings: [
        new layerSettings.OpacitySetting(),
        new layerSettings.IconSizeScale(),
        new layerSettings.BorderLineWidthScale(),
        new layerSettings.ColorSetting(),
        new layerSettings.ObjectNameLayers(),
      ],
      layerSchema: new layerSchemas.ekbLayersSchema.RoadSchemaLayerSchema(),
      templates: new layerTemplates.RoadsSchemaLayerTemplates(),
      selectedObjectPrototype: RoadSchemeItemSelection,
    });
  }
}
