import * as types from './types';

const initialState: types.DirectoryState = {
  changeHistoryInProcess: false,
  breadcrumbs: [
    {
      label: 'Справочники',
      key: 'directory',
      url: '',
      params: null,
      sider: undefined,
    },
  ],
  sider: undefined,
  model: {},
  data: {},
  contentRenderType: 'ag_grid',
  requestUrl: '',
};

export default function directoryV2Reducers(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case types.GET_DIRECTORY_REQUEST:
      return {
        ...state,
        changeHistoryInProcess: true,
      };
    case types.GET_DIRECTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        changeHistoryInProcess: false,
      };
    case types.GET_DIRECTORY_FAILURE:
      return {
        ...state,
        changeHistoryInProcess: false,
      };

    case types.UPDATE_DIRECTORY_REQUEST:
      return {
        ...state,
        changeHistoryInProcess: true,
      };
    case types.UPDATE_DIRECTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        changeHistoryInProcess: false,
      };
    case types.UPDATE_DIRECTORY_FAILURE:
      return {
        ...state,
        changeHistoryInProcess: false,
      };

    case types.UPDATE_BREADCRUMBS_REQUEST:
      return {
        ...state,
        changeHistoryInProcess: true,
      };
    case types.UPDATE_BREADCRUMBS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        changeHistoryInProcess: false,
      };
    case types.UPDATE_BREADCRUMBS_FAILURE:
      return {
        ...state,
        changeHistoryInProcess: false,
      };

    case types.RESET_DIRECTORY_HISTORY:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
