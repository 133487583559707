import { RoadsDataAccessor } from './RoadsDataAccessor';
import { TrolleybusTransportDataAccessor } from './TrolleybusTransportDataAccessor';
import { TramsDataAccessor } from './TramsDataAccessor';
import { ElectricalSubstationsDataAccessor } from './ElectricalSubstationsDataAccessor';
import { TrafficLightObjectsDataAccessor } from './TrafficLightObjectsDataAccessor';
import { CamerasAndDetectorsDataAccessor } from './CamerasAndDetectorsDataAccessor';
import { RoadSpeedBumpsDataAccessor } from './RoadSpeedBumpsDataAccessor';
import { IlluminatedPedestrianCrossingsDataAccessor } from './IlluminatedPedestrianCrossingsDataAccessor';
import { CityDistrictsAccessor } from './CityDistrictsAccessor';
import { TrafficManagementProjectsDataAccessor } from './TrafficManagementProjectsDataAccessor';
import { ProjectLayerDataAccessor } from './ProjectLayerDataAccessor';
import { BrigadeApplicationsDataAccessor } from './BrigadeApplicationsDataAccessor/BrigadeApplicationsDataAccessor';
import { MonitoringUDSDataAccessor } from './MonitoringUDSDataAccessor/MonitoringUDSDataAccessor';
import { TransportOrdersDataAccessor } from './TransportOrdersDataAccessor';
import { PublicTransportDataAccessor } from './PublicTransportDataAccessor';
import { CamerasUdcDataAccessor } from './CamerasUdcDataAccessor';
import { RoadAccidentsDataAccessor } from './RoadAccidentsDataAccessor';

export const ekbLayerDataAccessors = {
  RoadsDataAccessor,
  TramsDataAccessor,
  TrolleybusTransportDataAccessor,
  ElectricalSubstationsDataAccessor,
  TrafficLightObjectsDataAccessor,
  CamerasAndDetectorsDataAccessor,
  RoadSpeedBumpsDataAccessor,
  IlluminatedPedestrianCrossingsDataAccessor,
  CityDistrictsAccessor,
  TrafficManagementProjectsDataAccessor,
  ProjectLayerDataAccessor,
  BrigadeApplicationsDataAccessor,
  MonitoringUDSDataAccessor,
  TransportOrdersDataAccessor,
  PublicTransportDataAccessor,
  CamerasUdcDataAccessor,
  RoadAccidentsDataAccessor,
};
