import React, { useCallback } from 'react';
import DisplayedLayerItemContainer from './Item/DisplayedLayerItemContainer';
import styled from 'styled-components/macro';
import { baseStyles } from '../../../../../../../../../../stylesRegistrator';
import DraggableOneColumnGrid from '../../../../../../../../../../../UI/common/components/DraggableOneColumnGrid/DraggableOneColumnGrid';
import LayersGalleryContainer from './LayersGallery/LayersGalleryContainer';
import { Divider } from 'antd';

const { BaseMapPanelSecondaryTitle, BaseMapPanelItem } = baseStyles.modules.map;

const DisplayedLayers = ({ enabledLayers, changeLayerOrder }) => {
  const handleDragChange = useCallback(
    (layout) => {
      const order = layout.map((el) => el.y);
      changeLayerOrder(order);
    },
    [changeLayerOrder]
  );

  const Layers = enabledLayers.map((el, index) => (
    <div data-grid={{ x: 0, y: index, w: 2, h: 1, isBounded: true }} key={`${el?.name ?? index}`}>
      <DisplayedLayerItemContainer key={`item-${index}`} item={el} />
    </div>
  ));

  return (
    <StyledPanelItem>
      <BaseMapPanelSecondaryTitle>Активные слои</BaseMapPanelSecondaryTitle>
      {!Layers.length && <BlockHeader />}
      <DraggableOneColumnGrid onLayoutChange={handleDragChange} ownItems={Layers} />
      <Divider />
      <LayersGalleryContainer />
    </StyledPanelItem>
  );
};

export default DisplayedLayers;

const StyledPanelItem = styled(BaseMapPanelItem)`
  text-align: left;
`;

const BlockHeader = styled(BaseMapPanelItem)`
  height: 40px;
  content: '';
`;
