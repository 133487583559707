import styled from 'styled-components/macro';

const AddedMasksHeaderContentWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 8px;
  width: 100%;
`;

const AddedMasksHeaderButtonWrap = styled.div``;

const AddedMasksContentWrap = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const NotAddedMasks = styled.span`
  padding-top: 16px;
  display: block;
  text-align: center;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  color: ${(props) => props.theme.colors.gray};
  line-height: 140%;
`;

const AddedMaskItemName = styled.span`
  color: ${(props) => props.theme.colors.gray};
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  font-weight: bold;
  line-height: 140%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AddedMaskItemButtonsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 20px);
  grid-gap: 4px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100px);
`;

const AddedMaskItemWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-gap: 10px;
  background: #f1f3f6;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  padding: 11px 12px;
  position: relative;

  &:hover {
    grid-template-columns: 1fr 68px;

    & ${AddedMaskItemName} {
      width: 134px;
    }

    & ${AddedMaskItemButtonsWrap} {
      opacity: 1;
      pointer-events: unset;
      transform: translateY(0);
    }
  }
`;

export const Styled = {
  AddedMasksHeaderContentWrap,
  AddedMasksHeaderButtonWrap,
  AddedMasksContentWrap,
  NotAddedMasks,
  AddedMaskItemWrap,
  AddedMaskItemName,
  AddedMaskItemButtonsWrap,
};
