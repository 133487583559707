import styled from 'styled-components/macro';
import { Styled as styledMixins } from '../../../../styles/styleMixins/styleMixins.styled';
import { Layout } from 'antd';
const { OpacityColorInCertainProperty } = styledMixins.color;
const { Sider } = Layout;

const BaseMapMainModuleWrapper = styled.main`
  padding: 0 15px 0 15px;
  display: flex;
  grid-template-columns: auto auto;
  overflow: hidden;
`;

const BaseMapContentLayoutArea = styled.section`
  display: flex;
`;

const BaseMapContentLayoutAreaWrapper = styled.div`
  display: grid;
  padding-right: 40px;
  width: 100%;
`;

const BaseMapContentLayoutAreaInner = styled.div`
  padding-right: ${(props) => props.theme.paddings.defaultPadding};
  padding-left: ${(props) => props.theme.paddings.defaultPadding};
  max-height: 98%;
  height: 100%;
  min-height: 98%;
  margin: 15px 0;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: end;
  & > * {
    display: grid;
    grid-area: 1/1/1/6;
    z-index: 1;
  }
`;

const BaseMapPanelLayoutArea = styled.section`
  position: relative;
  width: auto;
  z-index: 1;
  padding-top: 80px;
  & > *:not(:last-child) {
    margin-bottom: ${(props) => props.theme.margins.defaultMargin};
  }
`;

const BaseMapPanelLayoutAreaInner = styled.div`
  height: 89vh;
  bottom: 0;
  top: 0;
  display: flex;
  margin: 0 -3px;
  & > * {
    margin: 0 3px;
  }
`;

const BaseMapPanelLayoutAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BaseMapPanel = styled.div`
  ${OpacityColorInCertainProperty('defaultLighter', 'background', 1)};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
  //backdrop-filter: blur(14px);
  max-width: 100%;
  z-index: 1;
  height: fit-content;
  transition: all 0.3s;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const BaseMapLayerPanelWrapperPrimary = styled(BaseMapPanel)`
  width: 100%;
  height: 100%;
  backdrop-filter: ${(props) => props.theme.decoration.defaultBackgroundFilter};
`;

const BaseMapLayerPanelWrapperSecondary = styled(BaseMapLayerPanelWrapperPrimary)`
  max-width: 100%;
  //backdrop-filter: blur(8px);
  z-index: 0;
`;

const BaseMapPanelItem = styled.div`
  padding: ${(props) => props.theme.paddings.defaultPadding};
`;

const BaseMapPanelTopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BaseMapPanelPrimaryTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSettings.default};
  color: ${(props) => props.theme.colors.defaultDark};
  margin: 0;
`;

const BaseMapPanelSecondaryTitle = styled(BaseMapPanelPrimaryTitle)`
  color: ${(props) => props.theme.colors.neutralDarker};
`;

const BaseMapPanelHeader = styled(BaseMapPanelItem)`
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
`;

const BaseMapPanelSection = styled.div`
  margin-top: 1rem;
`;

const TitleGroupLayer = styled.h3`
  color: ${(props) => props.theme.colors.gray};
  font-size: 13px;
`;

const SiderContainer = styled(Sider)`
  height: 90vh;
  z-index: 5000;
  width: 70px;
`;

const TooltipContainer = styled.div`
  .ant-tooltip-inner {
    width: max-content;
    height: auto;
    padding: 5px 15px;

    position: absolute;
    top: -25px;
    font-size: 12px;
    z-index: 1;
  }
`;

const Test = styled.div`
  display: flex;
`;

const BaseMapPanelLayoutAreaContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

const CheckBoxItemsContainer = styled.div`
  overflow-x: auto;
  height: calc(100vh - 300px);
  &::-webkit-scrollbar {
    width: 0;
  }
`;

const AllCheckBoxItem = styled.div`
  margin: 0 0 16px 3px;
  display: block;
`;

const HeaderCheckBox = styled.div`
  margin: 0 0 15px 3px;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #c1cbd8;
`;

export const Styled = {
  TooltipContainer,
  BaseMapMainModuleWrapper,
  BaseMapContentLayoutArea,
  BaseMapContentLayoutAreaWrapper,
  BaseMapPanelLayoutArea,
  BaseMapPanelLayoutAreaInner,
  BaseMapContentLayoutAreaInner,
  BaseMapPanel,
  BaseMapLayerPanelWrapperPrimary,
  BaseMapLayerPanelWrapperSecondary,
  BaseMapPanelItem,
  BaseMapPanelHeader,
  BaseMapPanelTopPart,
  BaseMapPanelPrimaryTitle,
  BaseMapPanelSecondaryTitle,
  BaseMapPanelSection,
  TitleGroupLayer,
  SiderContainer,
  Test,
  BaseMapPanelLayoutAreaWrapper,
  BaseMapPanelLayoutAreaContent,
  CheckBoxItemsContainer,
  AllCheckBoxItem,
  HeaderCheckBox,
};
