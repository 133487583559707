import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

import { ILinksCT } from '../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import IndividualOrdersContainer from '../Pages/IndividualOrdersContainer';
import { generateRightsByKey } from '../../../../../services/application/permissions/generateRightsByKey';

export const links: ILinksCT[] = [
  {
    label: IOPWTabMenuModel.individual_statements,
    description: 'Сведения о заявлениях',
    url: IOPWDirectory.individual_statements,
    component: IndividualOrdersContainer,
    folder: 'Заявления',
    permissionType: generateRightsByKey('individualstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.entity_statements,
    description: 'Сведения о заявлениях',
    url: IOPWDirectory.entity_statements,
    component: IndividualOrdersContainer,
    folder: 'Заявления',
    permissionType: generateRightsByKey('entitystatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.federal_statements,
    description: 'Сведения о заявлениях',
    url: IOPWDirectory.federal_statements,
    component: IndividualOrdersContainer,
    folder: 'Заявления',
    permissionType: generateRightsByKey('federalstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.forms_account,
    description: 'Бланки строгой отчетности',
    url: IOPWDirectory.forms_account,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('formaccountstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.write_off_acts,
    description: 'Акты о списании',
    url: IOPWDirectory.write_off_acts,
    component: IndividualOrdersContainer,
    folder: 'Акты',
    permissionType: generateRightsByKey('writeoffact', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.destruction_acts,
    description: 'Акты о уничтожении',
    url: IOPWDirectory.destruction_acts,
    component: IndividualOrdersContainer,
    folder: 'Акты',
    permissionType: generateRightsByKey('destructionact', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.coef_for_harm,
    description: 'Коэффициент вреда',
    url: IOPWDirectory.coef_for_harm,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('coefforcalcharm', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.permissible_axis_loads,
    description: 'Допустимые осевые нагрузки',
    url: IOPWDirectory.permissible_axis_loads,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('permissibleaxisloadstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.vehicle_axle_loads,
    description: 'Нагрузки на ось',
    url: IOPWDirectory.vehicle_axle_loads,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('vehicleaxleloadstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.location_axis,
    description: 'Автомобильная ось',
    url: IOPWDirectory.location_axis,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('locationaxisstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.permissible_weight,
    description: 'Информация допустимого веса',
    url: IOPWDirectory.permissible_weight,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('permissibleweightstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.excess_percents,
    description: 'Информация превышении веса',
    url: IOPWDirectory.excess_percents,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('excesspercent', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.ip_billings,
    description: 'Информация о счетах физических лиц и ИП',
    url: IOPWDirectory.ip_billings,
    component: IndividualOrdersContainer,
    folder: 'Счета',
    permissionType: generateRightsByKey('billingindividualstatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.org_billings,
    description: 'Информация о счетах юридических лиц',
    url: IOPWDirectory.org_billings,
    component: IndividualOrdersContainer,
    folder: 'Счета',
    permissionType: generateRightsByKey('billingentitystatement', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.payment_types,
    description: 'Данные об услугах',
    url: IOPWDirectory.payment_types,
    component: IndividualOrdersContainer,
    folder: 'Счета',
    permissionType: generateRightsByKey('paymenttype', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.non_working_days,
    description: 'Данные о нерабочих днях',
    url: IOPWDirectory.non_working_days,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('nonworkingdays', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.global_organizations,
    description: 'Организации региональных и федеральных дорог',
    url: IOPWDirectory.global_organizations,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('globalorganization', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.signers,
    description: 'Подписанты',
    url: IOPWDirectory.signers,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('signer', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.responsible_persons,
    description: 'Информация об ответственных лицах',
    url: IOPWDirectory.responsible_persons,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('responsibleperson', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.additional_inspection_comment_templates,
    description: 'Информация о шаблонах',
    url: IOPWDirectory.additional_inspection_comment_templates,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('federalstatementadditionalinspectioncommenttemplate', 'special_permits'),
  },
  {
    label: IOPWTabMenuModel.approval_comment_templates,
    description: 'Информация о шаблонах',
    url: IOPWDirectory.approval_comment_templates,
    permissionType: generateRightsByKey('federalstatementapprovedcommenttemplate', 'special_permits'),
    component: IndividualOrdersContainer,
    folder: 'Справочники',
  },
  {
    label: IOPWTabMenuModel.decline_comment_templates,
    description: 'Информация о шаблонах',
    url: IOPWDirectory.decline_comment_templates,
    component: IndividualOrdersContainer,
    permissionType: generateRightsByKey('federalstatementdeclinedcommenttemplate', 'special_permits'),
    folder: 'Справочники',
  },
  {
    label: IOPWTabMenuModel.special_condition_comment_templates,
    description: 'Информация о шаблонах',
    url: IOPWDirectory.special_condition_comment_templates,
    component: IndividualOrdersContainer,
    folder: 'Справочники',
    permissionType: generateRightsByKey('federalstatementspecialconditioncommenttemplate', 'special_permits'),
  },
];
