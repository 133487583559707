import { Roads } from './Roads';
import { Lines_troll } from './publickTrolleubysLayer/Lines_troll';
import { Cables_troll } from './publickTrolleubysLayer/Cables_troll';
import { Lines_tram } from './Lines_tram';
import { Cables_tram } from './Cables_tram';
import { Supply_cables_tram } from './Supply_cables_tram';
import { Supply_Cables_Troll } from './publickTrolleubysLayer/Supply_Cables_Troll';
import { Substations } from './Substations';
import { RoadMarkings } from './RoadMarkings';
import { TrafficLightsObjects } from './TrafficLightsObjects';
import { TrafficLightsObjectsSchemaWork } from './TrafficLightsObjectsSchemaWork';
import { CamerasAndDetectors } from './camerasAndDetectorsLayer/CamerasAndDetectors';
import { IlluminatedPedestrianCrossings } from './IlluminatedPedestrianCrossings';
import { CityDistricts } from './CityDistricts';
import { TrafficManagementProjects } from './TrafficManagementProjects';
import { BrigadeApplications } from './BrigadeApplications';
import { MonitoringUDS } from './MonitoringUDS';
import { BrigadeWorkShifts } from './BrigadeWorkShifts';
import { ProjectMarkingLine } from './ProjectLayer/ProjectMarkingLine';
import { ProjectRoadSigns } from './ProjectLayer/ProjectRoadSigns';
import { PedBorderProject } from './ProjectLayer/PedBorder';
import { SpeedBumpProject } from './ProjectLayer/SpeedBump';
import { StreetLampProject } from './ProjectLayer/StreetLamp';
import { TrafficLightsProject } from './ProjectLayer/TrafficLightsProject';
import { SignalPolesProject } from './ProjectLayer/SignalPolesProject';
import { RoadMarkingPolygonProject } from './ProjectLayer/RoadMarkingPolygon';
import { RoadMarkingSymbolProject } from './ProjectLayer/RoadMarkingSymbol';
import { RoadwayProject } from './ProjectLayer/Roadway';
import { SidewalkProject } from './ProjectLayer/Sidewalk';
import { BrigadeTeamOfWorkers } from './BrigadeTeamOfWorkers';
import { CamerasUds } from './CamerasUds';
import { OrderRoute } from './orderRoute/OrderRoute';
import { AxleLoadOverloads } from './orderRoute/AxleLoadOverloads';
import { DimensionsHeightOversizes } from './orderRoute/DimensionsHeightOversizes';
import { DimensionsWidthOversizes } from './orderRoute/DimensionsWidthOversizes';
import { GeneralDimensionsHeightWidthOversizes } from './orderRoute/GeneralDimensionsHeightWidthOversizes';
import { TransportStops } from './publicTransport/TransportStops';
import { TransportRoutes } from './publicTransport/TransportRoutes';
import { DigitalTwinRoadSignsGroup } from './DigitalTwinRoadSignsGroup';
import { RoadSigns } from './RoadSigns';
import { Segments } from './Segments';
import { RoadMarkingLine } from './RoadMarkingLine';
import { RoadMarkingPolygon } from './RoadMarkingPolygon';
import { RoadsDividingLines } from './RoadsDividingLines';
import { RoadWays } from './RoadWays';
import { TramRails } from './TramRails';
import { Sidewalk } from './Sidewalk';
import { RoadFences } from './RoadFences';
import { RoadPedestrianFences } from './RoadPedestrianFences';
import { TrafficLight } from './TrafficLight';
import { EngineeringConstructions } from './EngineeringConstructions';
import { Pipes } from './Pipes';
import { SignalPoles } from './SignalPoles';
import { SupportPillars } from './SupportPillars';
import { Lighting } from './Lighting';
import { SupportWall } from './SupportWall';
import { RoadTraySystem } from './RoadTraySystem';
import { Cabinets } from './Cabinets';
import { ImprovementElements } from './ImprovementElements';
import { ContoursLawn } from './ContoursLawn';
import { TownSquares } from './TownSquares';
import { BusShelters } from './BusShelters';
import { Overpasses } from './Overpasses';
import { FerryCrossings } from './FerryCrossings';
import { Pipelines } from './Pipelines';
import { QualitySegments } from './QualitySegments';
import { ViolationsWarrantyPeriod } from './ViolationsWarrantyPeriod';
import { RailCrossings } from './RailCrossing';
import { RestAreas } from './RestAreas';
import { SpeedBump } from './SpeedBump';
import { Tunnels } from './Tunnels';
import { WeatherStations } from './WeatherStations';
import { Bridges } from './Bridges';
import { RoadTechnicalCharacteristicAxleLoads } from './relatedDataRoadTechnicalCharacteristics/RoadTechnicalCharacteristicAxleLoads';
import { TechnicalSegments } from './relatedDataRoadTechnicalCharacteristics/TechnicalSegments';
import { RoadAccidentsItem } from './relatedDataRoadAccidentsLayer/RoadAccidentsItem';
import { RoadAccidentsSections } from './relatedDataRoadAccidentsLayer/RoadAccidentsSections';

import { transportAnalysisDistrictCentralnyiy } from './publicTransportAnalysis/transportAnalysisDistrictCentralnyiy';
import { transportAnalysisDistrictKalininskiiy } from './publicTransportAnalysis/transportAnalysisDistrictKalininskiiy';
import { transportAnalysisDistrictVostochnyiy } from './publicTransportAnalysis/transportAnalysisDistrictVostochnyiy';
import { transportAnalysisDistrictLeninskiyi } from './publicTransportAnalysis/transportAnalysisDistrictLeninskiyi';
import { transportAnalysisRegionTyumeskiiy } from './publicTransportAnalysis/transportAnalysisRegionTyumeskiiy';
import { transportAnalysisYalutorovsk } from './publicTransportAnalysis/transportAnalysisYalutorovsk';
import { transportAnalysisDistrictArmizonskiiy } from './publicTransportAnalysis/transportAnalysisDistrictArmizonskiiy';
import { transportAnalysisDistrictUporovskiiy } from './publicTransportAnalysis/transportAnalysisDistrictUporovskiiy';
import { transportAnalysisTyumen } from './publicTransportAnalysis/transportAnalysisTyumen';
import { transportAnalysisDistrictAbatskiiy } from './publicTransportAnalysis/transportAnalysisDistrictAbatskiiy';
import { transportAnalysisDistrictOmutinskiiy } from './publicTransportAnalysis/transportAnalysisDistrictOmutinskiiy';
import { transportAnalysisDistrictKazanskiiy } from './publicTransportAnalysis/transportAnalysisDistrictKazanskiiy';
import { transportAnalysisDistrictYarkovskiiy } from './publicTransportAnalysis/transportAnalysisDistrictYarkovskiiy';
import { transportAnalysisDistrictBerdujiiy } from './publicTransportAnalysis/transportAnalysisDistrictBerdujiiy';
import { transportAnalysisTobolsk } from './publicTransportAnalysis/transportAnalysisTobolsk';
import { transportAnalysisDistrictIsetskiiy } from './publicTransportAnalysis/transportAnalysisDistrictIsetskiiy';
import { transportAnalysisDistrictYalutorovskiiy } from './publicTransportAnalysis/transportAnalysisDistrictYalutorovskiiy';
import { transportAnalysisDistrictNijnetavdinskiiy } from './publicTransportAnalysis/transportAnalysisDistrictNijnetavdinskiiy';
import { transportAnalysisDistrictIshimskiiy } from './publicTransportAnalysis/transportAnalysisDistrictIshimskiiy';
import { transportAnalysisDistrictTobolskiiy } from './publicTransportAnalysis/transportAnalysisDistrictTobolskiiy';
import { transportAnalysisDistrictGolyshmanovskiiy } from './publicTransportAnalysis/transportAnalysisDistrictGolyshmanovskiiy';
import { transportAnalysisDistrictVikulovskiiy } from './publicTransportAnalysis/transportAnalysisDistrictVikulovskiiy';
import { transportAnalysisDistrictVagaiyskiiy } from './publicTransportAnalysis/transportAnalysisDistrictVagaiyskiiy';
import { transportAnalysisDistrictSladkovskiiy } from './publicTransportAnalysis/transportAnalysisDistrictSladkovskiiy';
import { transportAnalysisRegionZavodoukovskiiy } from './publicTransportAnalysis/transportAnalysisRegionZavodoukovskiiy';
import { transportAnalysisDistrictAromashevskiiy } from './publicTransportAnalysis/transportAnalysisDistrictAromashevskiiy';
import { transportAnalysisIshim } from './publicTransportAnalysis/transportAnalysisIshim';
import { transportAnalysisDistrictSorokinskiiy } from './publicTransportAnalysis/transportAnalysisDistrictSorokinskiiy';
import { transportAnalysisDistrictYurginskiiy } from './publicTransportAnalysis/transportAnalysisDistrictYurginskiiy';
import { transportAnalysisDistrictTyumenskiiy } from './publicTransportAnalysis/transportAnalysisDistrictTyumenskiiy';
import { transportAnalysisDistrictUvatskiiy } from './publicTransportAnalysis/transportAnalysisDistrictUvatskiiy';
import { transportAnalysisDistrictZavodoukovskiiy } from './publicTransportAnalysis/transportAnalysisDistrictZavodoukovskiiy';
import { transportAnalysisZavodoukovsk } from './publicTransportAnalysis/transportAnalysisZavodoukovsk';

/**
 * Используется в: создание объектов
 * Индексация объектов доступных для отображения на слоях
 * Имеют методы для доступа к данным - основные: загрузка данных, загрузка модели, загрузка данных объекта по айди и удаление
 * и множество дополнительных свойств - смотрет в прототипе объектов
 */

export const ekbRelatedDataForLayers = {
  // Объекты для слоя Цифровой двойник
  Roads,
  Lines_troll,
  Cables_troll,
  Lines_tram,
  Cables_tram,
  Supply_cables_tram,
  Supply_Cables_Troll,
  Substations,
  RoadMarkings,
  TrafficLightsObjects,
  TrafficLightsObjectsSchemaWork,
  CamerasAndDetectors,
  IlluminatedPedestrianCrossings,
  CityDistricts,
  TrafficManagementProjects,
  DigitalTwinRoadSignsGroup,
  RoadSigns,
  Segments,
  RoadMarkingLine,
  RoadMarkingPolygon,
  RoadsDividingLines,
  RoadWays,
  TramRails,
  Sidewalk,
  RoadFences,
  RoadPedestrianFences,
  TrafficLight,
  EngineeringConstructions,
  Pipes,
  SignalPoles,
  SupportPillars,
  Lighting,
  SupportWall,
  RoadTraySystem,
  Cabinets,
  ImprovementElements,
  ContoursLawn,
  TownSquares,
  BusShelters,
  Overpasses,
  FerryCrossings,
  Pipelines,
  RailCrossings,
  RestAreas,
  SpeedBump,
  Tunnels,
  WeatherStations,
  Bridges,

  // Сегменты и иконки для слоя УДС
  QualitySegments,
  ViolationsWarrantyPeriod,

  //Заявки бригад
  BrigadeApplications,
  BrigadeWorkShifts,
  BrigadeTeamOfWorkers,

  // project layer
  ProjectMarkingLine,
  ProjectRoadSigns,
  PedBorderProject,
  SpeedBumpProject,
  StreetLampProject,
  TrafficLightsProject,
  SignalPolesProject,
  RoadMarkingPolygonProject,
  RoadMarkingSymbolProject,
  RoadwayProject,
  SidewalkProject,

  // Камеры УДС
  CamerasUds,

  // Мониторинг УДС
  MonitoringUDS,
  // Сегменты для слоя КТГ
  OrderRoute,
  AxleLoadOverloads,
  DimensionsHeightOversizes,
  DimensionsWidthOversizes,
  GeneralDimensionsHeightWidthOversizes,

  // Объекты слоя общественного транспорта
  TransportStops,
  TransportRoutes,

  // Характеристики для слоя характеристики дорог
  RoadTechnicalCharacteristicAxleLoads,
  TechnicalSegments,

  // Объекты слоя ДТП
  RoadAccidentsItem,
  RoadAccidentsSections,

  // Разные районы для слоя анализа маршрутов общественного транспорта
  transportAnalysisDistrictCentralnyiy,
  transportAnalysisDistrictKalininskiiy,
  transportAnalysisDistrictVostochnyiy,
  transportAnalysisDistrictLeninskiyi,
  transportAnalysisRegionTyumeskiiy,
  transportAnalysisYalutorovsk,
  transportAnalysisDistrictArmizonskiiy,
  transportAnalysisDistrictUporovskiiy,
  transportAnalysisTyumen,
  transportAnalysisDistrictAbatskiiy,
  transportAnalysisDistrictOmutinskiiy,
  transportAnalysisDistrictKazanskiiy,
  transportAnalysisDistrictYarkovskiiy,
  transportAnalysisDistrictBerdujiiy,
  transportAnalysisTobolsk,
  transportAnalysisDistrictIsetskiiy,
  transportAnalysisDistrictYalutorovskiiy,
  transportAnalysisDistrictNijnetavdinskiiy,
  transportAnalysisDistrictIshimskiiy,
  transportAnalysisDistrictTobolskiiy,
  transportAnalysisDistrictGolyshmanovskiiy,
  transportAnalysisDistrictVikulovskiiy,
  transportAnalysisDistrictVagaiyskiiy,
  transportAnalysisDistrictSladkovskiiy,
  transportAnalysisRegionZavodoukovskiiy,
  transportAnalysisDistrictAromashevskiiy,
  transportAnalysisIshim,
  transportAnalysisDistrictSorokinskiiy,
  transportAnalysisDistrictYurginskiiy,
  transportAnalysisDistrictTyumenskiiy,
  transportAnalysisDistrictUvatskiiy,
  transportAnalysisDistrictZavodoukovskiiy,
  transportAnalysisZavodoukovsk,
};
