import React from 'react';
import styled from 'styled-components/macro';

const ContentPaddingWrapper = styled.section`
  //this is should be header size
  padding-top: 80px;
  height: 100%;
`;

const withContentPadding = (WrappedComponent) => {
  const hocComponent = ({ ...props }) => (
    <ContentPaddingWrapper>
      <WrappedComponent {...props} />
    </ContentPaddingWrapper>
  );

  hocComponent.propTypes = {};

  return hocComponent;
};

export default withContentPadding;
