import { IconLayer, LineLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers';
import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { RoadSignGroupInfoContainer } from './RoadSignGroupInfo';
import { roadObjectsIconMapping } from 'layers/iconMappers/roadObjectsIconMapping';
import MapEventObserver from '../../store/rakes/MapEventObserver';
import { RoadSignGroup } from 'generated/api/api';
import InstanceLayer from 'types/classes/InstanceLayer';
import { RoadSignGroupLayerModel } from './RoadSignGroupLayer.model';
import { UpdateStateInfo } from '@deck.gl/core/lib/layer';
import { AnyObject } from 'types/enums/general/general.model';
import { DSelector } from 'services/map/Dselector/DSelector';
import { MapMarker, MarkerSizeMode } from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { layerColorsMapper } from 'layers/colorMappers/layerColorsMapper';
import _ from 'lodash';

const defaultMaxZoom = 20;
const defaultMinZoom = 15;

class RoadSignGroupLayer extends InstanceLayer<RoadSignGroup, RoadSignGroupLayerModel> {
  shouldUpdateState({ changeFlags, context }: UpdateStateInfo<any>) {
    const zoomChanged = changeFlags.viewportChanged && Math.abs(this.state.zoom - context.viewport.zoom) > 3;
    return changeFlags.propsChanged || zoomChanged;
  }

  updateState({ context }: { context: AnyObject }) {
    this.setState({
      selectedObject: this.props.currentSelectedObject ?? null,
      zoom: context.viewport.zoom,
      roadSignGroups: this.props.roadSignGroups,
    });
  }

  getPickingInfo(event: AnyObject) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onMarkerAdd) {
        const { object } = event.info;
        const customPositionString = object?.custom_position ?? object?.point;
        const customPosition = DSelector.getPositionFromPoint({ point: customPositionString });
        this.props.onMarkerAdd(Layers.roadsSchema, {
          type: MapObjects.roadSignGroup,
          component: (
            <RoadSignGroupInfoContainer
              roadGroup={object}
              onClose={(response?: RoadSignGroup) => {
                this.props.onMarkerClose(Layers.roadsSchema, { type: MapObjects.roadSignGroup, clickInfo: event.info });
                if (response) {
                  const newObject = this.state.roadSignGroups.find((group: any) => group.id === response.id);
                  this.getPickingInfo({
                    ...event,
                    info: { ...event.info, object: { ...newObject, custom_size: response.custom_size } },
                  });
                }
              }}
              changeMarkerSizeMode={(sizeMode) =>
                this.props.handleChangeMarkerSizeMode(
                  Layers.roadsSchema,
                  { type: MapObjects.roadSignGroup, clickInfo: event.info },
                  sizeMode
                )
              }
              layer={Layers.roadsSchema}
            />
          ),
          clickInfo: event.info,
          markerSizeMode: MarkerSizeMode.drag,
          markerPosition: customPosition,
          id: event.info.object.id,
        });
      }
    }
    return event.info;
  }

  renderLayers() {
    if (this.state?.zoom < defaultMinZoom) return [];

    const { maxZoomVisibleMarkers = defaultMaxZoom, minZoomVisibleMarkers = defaultMinZoom } = this.props;
    const data = this.props.roadSignGroups ?? [];
    const filteredWithMarkers = data.filter(
      (el) => !this.props.activeMarkers?.some((activeMarker) => activeMarker.clickInfo.object.id === el.id)
    );
    const isMarkerVisible = !!data?.length && _.inRange(this.state.zoom, minZoomVisibleMarkers, maxZoomVisibleMarkers);

    const commonDataProps = {
      data: filteredWithMarkers,
      opacity: this.props.opacity ?? 1,
    };
    const activeMarkers = this.props.activeMarkers?.filter((marker) => {
      return marker.type === MapObjects.roadSignGroup;
    });

    return [
      new IconLayer<RoadSignGroup>({
        id: 'road_sign_group_background',
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects_icon.png`,
        iconMapping: roadObjectsIconMapping,
        billboard: false,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 2.5,
        pickable: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: DSelector.getPositionFromPoint,
        getIcon: () => 'white_box',
        ...commonDataProps,
      }),
      new TextLayer<RoadSignGroup>({
        id: 'road_sign_group_amount',
        getPosition: DSelector.getPositionFromPoint,
        getText: (d) => d.road_signs_list?.length?.toString(),
        billboard: true,
        getColor: layerColorsMapper.blue,
        sizeScale: this?.props?.sizeScale,
        sizeUnits: 'meters',
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        getPolygonOffset: DSelector.getNewPolygonOffset,
        sizeMinPixels: 20,
        sizeMaxPixels: 20,
        updateTriggers: {
          visible: this.context?.viewport?.zoom,
          sizeScale: this.state?.zoom,
        },
        pickable: false,
        ...commonDataProps,
      }),
      new ScatterplotLayer<MapMarker>({
        id: 'road_sign_group_markers',
        data: activeMarkers,
        getPosition: DSelector.getPointFromMarker,
        pickable: false,
        stroked: false,
        filled: true,
        visible: isMarkerVisible,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getRadius: 1,
        radiusUnits: 'meters',
        opacity: 1,
        getFillColor: layerColorsMapper.red,
        updateTriggers: {
          visible: this.context?.viewport?.zoom,
        },
      }),
      new LineLayer<MapMarker>({
        id: 'road_sign_group_markers_path',
        data: activeMarkers,
        pickable: false,
        autoHighlight: false,
        widthScale: 1,
        widthMinPixels: 2,
        widthMaxPixels: 5,
        visible: isMarkerVisible,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getSourcePosition: DSelector.getPointFromMarker,
        getTargetPosition: DSelector.getPointFromMovedMarker,
        getWidth: 0.2,
        widthUnits: 'meters',
        getColor: layerColorsMapper.black,
        updateTriggers: {
          visible: this.context?.viewport?.zoom,
        },
      }),
    ];
  }
}

export default RoadSignGroupLayer;
