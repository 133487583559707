/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ApplicationsService {
  /**
   * Заявки
   */
  static applicationsList(
    params: {
      /** Адрес выполнения */
      address?: string;
      /** Фильтр по полям модели "Тип заявки" */
      applicationType?: string;
      /** Фильтр по полям модели "Бригада" */
      appointedTeam?: string;
      /** Фильтр по полям модели "Пользователь" */
      author?: string;
      /** Дата и время поступления */
      createdAt?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Район города" */
      district?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
      /** Фильтр по нескольким статусам */
      statusIn?: string;
      /** Фильтр по полям модели "Задача" */
      task?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        application_type: params['applicationType'],
        appointed_team: params['appointedTeam'],
        author: params['author'],
        created_at: params['createdAt'],
        deleted: params['deleted'],
        district: params['district'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
        status__in: params['statusIn'],
        task: params['task'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsCreate(
    params: {
      /** requestBody */
      body?: Application;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Application> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsRetrieve(
    params: {
      /** A unique integer value identifying this Заявка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Application> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsUpdate(
    params: {
      /** A unique integer value identifying this Заявка. */
      id: number;
      /** requestBody */
      body?: Application;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Application> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsPartialUpdate(
    params: {
      /** A unique integer value identifying this Заявка. */
      id: number;
      /** requestBody */
      body?: PatchedApplication;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Application> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsDestroy(
    params: {
      /** A unique integer value identifying this Заявка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsObjectsRetrieve(
    params: {
      /** A unique integer value identifying this Заявка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationObject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/{id}/objects/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты
   */
  static applicationsChatRoomsList(
    params: {
      /** Фильтр по полям модели "Заявка" */
      application?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Пользователь" */
      users?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoomList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        application: params['application'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        users: params['users'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты
   */
  static applicationsChatRoomsCreate(
    params: {
      /** requestBody */
      body?: ApplicationChatRoom;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты
   */
  static applicationsChatRoomsRetrieve(
    params: {
      /** A unique integer value identifying this Чат. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты
   */
  static applicationsChatRoomsAuthorizationCreate(
    params: {
      /** requestBody */
      body?: ApplicationChatRoomAuthorizationInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoomAuthorizationOutput> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms/authorization/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты
   */
  static applicationsChatRoomsSendSystemMessageChatCreate(
    params: {
      /** requestBody */
      body?: ApplicationChatRoomSystemMessageInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms/send_system_message_chat/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты/Пользователи
   */
  static applicationsChatRoomsUsersList(
    params: {
      /** Фильтр по полям модели "Чат" */
      chatRoom?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Пользователь" */
      user?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoomUserList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms_users/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        chat_room: params['chatRoom'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        user: params['user'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты/Пользователи
   */
  static applicationsChatRoomsUsersCreate(
    params: {
      /** requestBody */
      body?: ApplicationChatRoomUser;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoomUser> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms_users/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты/Пользователи
   */
  static applicationsChatRoomsUsersRetrieve(
    params: {
      /** A unique integer value identifying this Чат/Пользователь. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoomUser> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms_users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты/Пользователи
   */
  static applicationsChatRoomsUsersUpdate(
    params: {
      /** A unique integer value identifying this Чат/Пользователь. */
      id: number;
      /** requestBody */
      body?: ApplicationChatRoomUser;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoomUser> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms_users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты/Пользователи
   */
  static applicationsChatRoomsUsersPartialUpdate(
    params: {
      /** A unique integer value identifying this Чат/Пользователь. */
      id: number;
      /** requestBody */
      body?: PatchedApplicationChatRoomUser;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationChatRoomUser> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms_users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Чаты/Пользователи
   */
  static applicationsChatRoomsUsersDestroy(
    params: {
      /** A unique integer value identifying this Чат/Пользователь. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/chat_rooms_users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комментарии к заявке
   */
  static applicationsCommentsList(
    params: {
      /** Фильтр по полям модели "Заявка" */
      application?: string;
      /** Фильтр по полям модели "Пользователь" */
      author?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationCommentList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/comments/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        application: params['application'],
        author: params['author'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комментарии к заявке
   */
  static applicationsCommentsCreate(
    params: {
      /** requestBody */
      body?: ApplicationComment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationComment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/comments/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комментарии к заявке
   */
  static applicationsCommentsRetrieve(
    params: {
      /** A unique integer value identifying this Комментарий к заявке. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationComment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комментарии к заявке
   */
  static applicationsCommentsUpdate(
    params: {
      /** A unique integer value identifying this Комментарий к заявке. */
      id: number;
      /** requestBody */
      body?: ApplicationComment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationComment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комментарии к заявке
   */
  static applicationsCommentsPartialUpdate(
    params: {
      /** A unique integer value identifying this Комментарий к заявке. */
      id: number;
      /** requestBody */
      body?: PatchedApplicationComment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationComment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комментарии к заявке
   */
  static applicationsCommentsDestroy(
    params: {
      /** A unique integer value identifying this Комментарий к заявке. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Затраченные материалы
   */
  static applicationsExpendableMaterialsList(
    params: {
      /** Затраченное количество */
      amount?: number;
      /** Фильтр по полям модели "Заявка" */
      application?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Фильтр по полям модели "Материал" */
      material?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpendableMaterialList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/expendable_materials/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        amount: params['amount'],
        application: params['application'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        material: params['material'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Затраченные материалы
   */
  static applicationsExpendableMaterialsCreate(
    params: {
      /** requestBody */
      body?: ExpendableMaterial;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpendableMaterial> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/expendable_materials/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Затраченные материалы
   */
  static applicationsExpendableMaterialsRetrieve(
    params: {
      /** A unique integer value identifying this Затраченный материал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpendableMaterial> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/expendable_materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Затраченные материалы
   */
  static applicationsExpendableMaterialsUpdate(
    params: {
      /** A unique integer value identifying this Затраченный материал. */
      id: number;
      /** requestBody */
      body?: ExpendableMaterial;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpendableMaterial> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/expendable_materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Затраченные материалы
   */
  static applicationsExpendableMaterialsPartialUpdate(
    params: {
      /** A unique integer value identifying this Затраченный материал. */
      id: number;
      /** requestBody */
      body?: PatchedExpendableMaterial;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExpendableMaterial> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/expendable_materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Затраченные материалы
   */
  static applicationsExpendableMaterialsDestroy(
    params: {
      /** A unique integer value identifying this Затраченный материал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/expendable_materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsGetObjectCharacteristicDataList(
    params: {
      /** Адрес выполнения */
      address?: string;
      /** Фильтр по полям модели "Тип заявки" */
      applicationType?: string;
      /** Фильтр по полям модели "Бригада" */
      appointedTeam?: string;
      /** Фильтр по полям модели "Пользователь" */
      author?: string;
      /** Дата и время поступления */
      createdAt?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Район города" */
      district?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Объект */
      objectType?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
      /** Фильтр по полям модели "Задача" */
      task?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationObjectCharacteristicData[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/get_object_characteristic_data/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        application_type: params['applicationType'],
        appointed_team: params['appointedTeam'],
        author: params['author'],
        created_at: params['createdAt'],
        deleted: params['deleted'],
        district: params['district'],
        history: params['history'],
        id: params['id'],
        object_type: params['objectType'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
        task: params['task'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Отметки
   */
  static applicationsMarksList(
    params: {
      /**  */
      datetime?: string;
      /**  */
      id?: number;
      /**  */
      inProgress?: boolean;
      /**  */
      lastLocations?: boolean;
      /**  */
      teamOfMasters?: number;
      /**  */
      workShiftId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Mark[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/marks/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        datetime: params['datetime'],
        id: params['id'],
        in_progress: params['inProgress'],
        last_locations: params['lastLocations'],
        team_of_masters: params['teamOfMasters'],
        work_shift_id: params['workShiftId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Отметки
   */
  static applicationsMarksRetrieve(
    params: {
      /** A unique integer value identifying this Отметка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Mark> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/marks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Материалы
   */
  static applicationsMaterialsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Единица измерения */
      measurementUnit?: string;
      /** Название материала */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MaterialList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/materials/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        measurement_unit: params['measurementUnit'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Материалы
   */
  static applicationsMaterialsCreate(
    params: {
      /** requestBody */
      body?: Material;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Material> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/materials/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Материалы
   */
  static applicationsMaterialsRetrieve(
    params: {
      /** A unique integer value identifying this Материал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Material> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Материалы
   */
  static applicationsMaterialsUpdate(
    params: {
      /** A unique integer value identifying this Материал. */
      id: number;
      /** requestBody */
      body?: Material;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Material> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Материалы
   */
  static applicationsMaterialsPartialUpdate(
    params: {
      /** A unique integer value identifying this Материал. */
      id: number;
      /** requestBody */
      body?: PatchedMaterial;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Material> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Материалы
   */
  static applicationsMaterialsDestroy(
    params: {
      /** A unique integer value identifying this Материал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/materials/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фотографии к заявкам
   */
  static applicationsPhotosList(
    params: {
      /** Фильтр по полям модели "Заявка" */
      application?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPhotoList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/photos/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        application: params['application'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фотографии к заявкам
   */
  static applicationsPhotosCreate(
    params: {
      /** requestBody */
      body?: ApplicationPhotoCreate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPhotoCreate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/photos/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фотографии к заявкам
   */
  static applicationsPhotosRetrieve(
    params: {
      /** A unique integer value identifying this Фотография к заявке. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPhoto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/photos/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фотографии к заявкам
   */
  static applicationsPhotosUpdate(
    params: {
      /** A unique integer value identifying this Фотография к заявке. */
      id: number;
      /** requestBody */
      body?: ApplicationPhoto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPhoto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/photos/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фотографии к заявкам
   */
  static applicationsPhotosPartialUpdate(
    params: {
      /** A unique integer value identifying this Фотография к заявке. */
      id: number;
      /** requestBody */
      body?: PatchedApplicationPhoto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationPhoto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/photos/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фотографии к заявкам
   */
  static applicationsPhotosDestroy(
    params: {
      /** A unique integer value identifying this Фотография к заявке. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/photos/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsSetOrdersCreate(
    params: {
      /** requestBody */
      body?: ApplicationOrderList;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/set_orders/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки
   */
  static applicationsSetStatusesCreate(
    params: {
      /** requestBody */
      body?: ApplicationStatusList[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/set_statuses/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Источники заявки
   */
  static applicationsSourcesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Источник заявки */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationSourceList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/sources/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Источники заявки
   */
  static applicationsSourcesCreate(
    params: {
      /** requestBody */
      body?: ApplicationSource;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationSource> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/sources/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Источники заявки
   */
  static applicationsSourcesRetrieve(
    params: {
      /** A unique integer value identifying this Источник заявки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationSource> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/sources/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Источники заявки
   */
  static applicationsSourcesUpdate(
    params: {
      /** A unique integer value identifying this Источник заявки. */
      id: number;
      /** requestBody */
      body?: ApplicationSource;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationSource> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/sources/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Источники заявки
   */
  static applicationsSourcesPartialUpdate(
    params: {
      /** A unique integer value identifying this Источник заявки. */
      id: number;
      /** requestBody */
      body?: PatchedApplicationSource;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationSource> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/sources/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Источники заявки
   */
  static applicationsSourcesDestroy(
    params: {
      /** A unique integer value identifying this Источник заявки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/sources/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты задач
   */
  static applicationsTaskObjectsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Задача" */
      task?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TaskObjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/task_objects/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        task: params['task'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты задач
   */
  static applicationsTaskObjectsCreate(
    params: {
      /** requestBody */
      body?: TaskObject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TaskObject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/task_objects/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты задач
   */
  static applicationsTaskObjectsRetrieve(
    params: {
      /** A unique integer value identifying this Объект задачи. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TaskObject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/task_objects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты задач
   */
  static applicationsTaskObjectsUpdate(
    params: {
      /** A unique integer value identifying this Объект задачи. */
      id: number;
      /** requestBody */
      body?: TaskObject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TaskObject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/task_objects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты задач
   */
  static applicationsTaskObjectsPartialUpdate(
    params: {
      /** A unique integer value identifying this Объект задачи. */
      id: number;
      /** requestBody */
      body?: PatchedTaskObject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TaskObject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/task_objects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты задач
   */
  static applicationsTaskObjectsDestroy(
    params: {
      /** A unique integer value identifying this Объект задачи. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/task_objects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Задачи
   */
  static applicationsTasksList(
    params: {
      /** Фильтр по полям модели "Заявка" */
      applications?: string;
      /** Фильтр по полям модели "Пользователь" */
      author?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Задача" */
      parentTask?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Ответственное лицо" */
      responsiblePerson?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TaskList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/tasks/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        applications: params['applications'],
        author: params['author'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        parent_task: params['parentTask'],
        representation: params['representation'],
        responsible_person: params['responsiblePerson'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Задачи
   */
  static applicationsTasksCreate(
    params: {
      /** requestBody */
      body?: Task;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Task> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/tasks/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Задачи
   */
  static applicationsTasksRetrieve(
    params: {
      /** A unique integer value identifying this Задача. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Task> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Задачи
   */
  static applicationsTasksUpdate(
    params: {
      /** A unique integer value identifying this Задача. */
      id: number;
      /** requestBody */
      body?: Task;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Task> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Задачи
   */
  static applicationsTasksPartialUpdate(
    params: {
      /** A unique integer value identifying this Задача. */
      id: number;
      /** requestBody */
      body?: PatchedTask;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Task> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Задачи
   */
  static applicationsTasksDestroy(
    params: {
      /** A unique integer value identifying this Задача. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы мастеров
   */
  static applicationsTeamsOfMastersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Пользователь" */
      primaryMaster?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Пользователь" */
      secondaryMasters?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfMastersList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_masters/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        primary_master: params['primaryMaster'],
        representation: params['representation'],
        secondary_masters: params['secondaryMasters'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы мастеров
   */
  static applicationsTeamsOfMastersCreate(
    params: {
      /** requestBody */
      body?: TeamOfMasters;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfMasters> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_masters/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы мастеров
   */
  static applicationsTeamsOfMastersRetrieve(
    params: {
      /** A unique integer value identifying this Тип мастера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfMasters> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_masters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы мастеров
   */
  static applicationsTeamsOfMastersUpdate(
    params: {
      /** A unique integer value identifying this Тип мастера. */
      id: number;
      /** requestBody */
      body?: TeamOfMasters;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfMasters> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_masters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы мастеров
   */
  static applicationsTeamsOfMastersPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип мастера. */
      id: number;
      /** requestBody */
      body?: PatchedTeamOfMasters;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfMasters> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_masters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы мастеров
   */
  static applicationsTeamsOfMastersDestroy(
    params: {
      /** A unique integer value identifying this Тип мастера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_masters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы мастеров
   */
  static applicationsTeamsOfMastersGetApplicationsRetrieve(
    params: {
      /** Фильтр типам мастеров */
      teamOfMasters?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Application> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_masters/get_applications/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { team_of_masters: params['teamOfMasters'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бригады
   */
  static applicationsTeamsOfWorkersList(
    params: {
      /**  */
      applicationType?: number;
      /**  */
      id?: number;
      /**  */
      name?: string;
      /**  */
      teamOfMasters?: any | null[];
      /**  */
      teamlead?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfWorkersList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_workers/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        application_type: params['applicationType'],
        id: params['id'],
        name: params['name'],
        team_of_masters: params['teamOfMasters'],
        teamlead: params['teamlead'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бригады
   */
  static applicationsTeamsOfWorkersCreate(
    params: {
      /** requestBody */
      body?: TeamOfWorkers;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfWorkers> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_workers/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бригады
   */
  static applicationsTeamsOfWorkersRetrieve(
    params: {
      /** A unique integer value identifying this Бригада. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfWorkers> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_workers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бригады
   */
  static applicationsTeamsOfWorkersUpdate(
    params: {
      /** A unique integer value identifying this Бригада. */
      id: number;
      /** requestBody */
      body?: TeamOfWorkers;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfWorkers> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_workers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бригады
   */
  static applicationsTeamsOfWorkersPartialUpdate(
    params: {
      /** A unique integer value identifying this Бригада. */
      id: number;
      /** requestBody */
      body?: PatchedTeamOfWorkers;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TeamOfWorkers> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_workers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бригады
   */
  static applicationsTeamsOfWorkersDestroy(
    params: {
      /** A unique integer value identifying this Бригада. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/teams_of_workers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы заявки
   */
  static applicationsTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Тип заявки */
      name?: string;
      /** Тип объекта */
      objectType?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Тип мастера" */
      teamOfMasters?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        object_type: params['objectType'],
        ordering: params['ordering'],
        representation: params['representation'],
        team_of_masters: params['teamOfMasters'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы заявки
   */
  static applicationsTypesCreate(
    params: {
      /** requestBody */
      body?: ApplicationType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы заявки
   */
  static applicationsTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип заявки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы заявки
   */
  static applicationsTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип заявки. */
      id: number;
      /** requestBody */
      body?: ApplicationType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы заявки
   */
  static applicationsTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип заявки. */
      id: number;
      /** requestBody */
      body?: PatchedApplicationType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы заявки
   */
  static applicationsTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип заявки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Смены
   */
  static applicationsWorkShiftsList(
    params: {
      /** Фильтр по полям модели "Заявка" */
      application?: string;
      /** Фильтр по полям модели "Бригада" */
      appointedTeam?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Фильтр по рабочим сменам, которые еще не завершились */
      inProgress?: boolean;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkShiftList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/work_shifts/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        application: params['application'],
        appointed_team: params['appointedTeam'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        in_progress: params['inProgress'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Смены
   */
  static applicationsWorkShiftsCreate(
    params: {
      /** requestBody */
      body?: WorkShift;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkShift> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/work_shifts/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Смены
   */
  static applicationsWorkShiftsRetrieve(
    params: {
      /** A unique integer value identifying this Смена. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkShift> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/work_shifts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Смены
   */
  static applicationsWorkShiftsUpdate(
    params: {
      /** A unique integer value identifying this Смена. */
      id: number;
      /** requestBody */
      body?: WorkShift;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkShift> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/work_shifts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Смены
   */
  static applicationsWorkShiftsPartialUpdate(
    params: {
      /** A unique integer value identifying this Смена. */
      id: number;
      /** requestBody */
      body?: PatchedWorkShift;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkShift> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/work_shifts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Смены
   */
  static applicationsWorkShiftsDestroy(
    params: {
      /** A unique integer value identifying this Смена. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/work_shifts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Смены
   */
  static applicationsWorkShiftsGetApplicationsRetrieve(
    params: {
      /** A unique integer value identifying this Смена. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkShiftWithApplication> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/applications/work_shifts/{id}/get_applications/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CamerasService {
  /**
   * Камеры
   */
  static camerasList(
    params: {
      /** Адрес */
      address?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasCreate(
    params: {
      /** requestBody */
      body?: Camera;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Camera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasRetrieve(
    params: {
      /** A unique integer value identifying this Камера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Camera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasUpdate(
    params: {
      /** A unique integer value identifying this Камера. */
      id: number;
      /** requestBody */
      body?: Camera;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Camera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasPartialUpdate(
    params: {
      /** A unique integer value identifying this Камера. */
      id: number;
      /** requestBody */
      body?: PatchedCamera;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Camera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasDestroy(
    params: {
      /** A unique integer value identifying this Камера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasLastFrameRetrieve(
    params: {
      /** A unique integer value identifying this Камера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraLastFrame> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/{id}/last_frame/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasMasksRetrieve2(
    params: {
      /** A unique integer value identifying this Камера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionMasksGrouped> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/{id}/masks/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маски распознавания
   */
  static camerasMasksList(
    params: {
      /** Фильтр по полям модели "Камера" */
      camera?: string;
      /** Фильтр по нескольким камерам */
      cameraIn?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Тип маски */
      maskType?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionMaskList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/masks/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        camera: params['camera'],
        camera__in: params['cameraIn'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        mask_type: params['maskType'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маски распознавания
   */
  static camerasMasksCreate(
    params: {
      /** requestBody */
      body?: DetectionMask;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionMask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/masks/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маски распознавания
   */
  static camerasMasksRetrieve1(
    params: {
      /** A unique integer value identifying this Маска распознавания. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionMask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/masks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маски распознавания
   */
  static camerasMasksUpdate(
    params: {
      /** A unique integer value identifying this Маска распознавания. */
      id: number;
      /** requestBody */
      body?: DetectionMask;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionMask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/masks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маски распознавания
   */
  static camerasMasksPartialUpdate(
    params: {
      /** A unique integer value identifying this Маска распознавания. */
      id: number;
      /** requestBody */
      body?: PatchedDetectionMask;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionMask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/masks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маски распознавания
   */
  static camerasMasksDestroy(
    params: {
      /** A unique integer value identifying this Маска распознавания. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/masks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры
   */
  static camerasMasksBatchCreateCreate(
    params: {
      /** Фильтр по полям модели "Камера" */
      camera?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Тип маски */
      maskType?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** requestBody */
      body?: DetectionMaskList;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionMask[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/masks/batch_create/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        camera: params['camera'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        mask_type: params['maskType'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счетчики объектов
   */
  static camerasObjectCountersList(
    params: {
      /**  */
      cameraId?: number;
      /**  */
      detectionDatetimeAfter?: string;
      /**  */
      detectionDatetimeBefore?: string;
      /**  */
      maskId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ObjectCounterList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/object_counters/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        camera_id: params['cameraId'],
        detection_datetime_after: params['detectionDatetimeAfter'],
        detection_datetime_before: params['detectionDatetimeBefore'],
        mask_id: params['maskId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счетчики объектов
   */
  static camerasObjectCountersRetrieve(
    params: {
      /** A unique integer value identifying this Счетчик объектов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ObjectCounter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/object_counters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счетчики объектов
   */
  static camerasObjectCountersDestroy(
    params: {
      /** A unique integer value identifying this Счетчик объектов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/object_counters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы дорожной разметки
   */
  static camerasRoadMarksCheckersList(
    params: {
      /**  */
      cameraId?: number;
      /**  */
      detectionDatetimeAfter?: string;
      /**  */
      detectionDatetimeBefore?: string;
      /**  */
      maskId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarksCheckerList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/road_marks_checkers/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        camera_id: params['cameraId'],
        detection_datetime_after: params['detectionDatetimeAfter'],
        detection_datetime_before: params['detectionDatetimeBefore'],
        mask_id: params['maskId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы дорожной разметки
   */
  static camerasRoadMarksCheckersRetrieve(
    params: {
      /** A unique integer value identifying this Детектор дорожной разметки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarksChecker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/road_marks_checkers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы дорожной разметки
   */
  static camerasRoadMarksCheckersDestroy(
    params: {
      /** A unique integer value identifying this Детектор дорожной разметки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/road_marks_checkers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы дорожных знаков
   */
  static camerasRoadSignsCheckersList(
    params: {
      /**  */
      cameraId?: number;
      /**  */
      detectionDatetimeAfter?: string;
      /**  */
      detectionDatetimeBefore?: string;
      /**  */
      maskId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignsCheckerList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/road_signs_checkers/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        camera_id: params['cameraId'],
        detection_datetime_after: params['detectionDatetimeAfter'],
        detection_datetime_before: params['detectionDatetimeBefore'],
        mask_id: params['maskId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы дорожных знаков
   */
  static camerasRoadSignsCheckersRetrieve(
    params: {
      /** A unique integer value identifying this Детектор дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignsChecker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/road_signs_checkers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы дорожных знаков
   */
  static camerasRoadSignsCheckersDestroy(
    params: {
      /** A unique integer value identifying this Детектор дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/road_signs_checkers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счетчики скорости
   */
  static camerasSpeedCountersList(
    params: {
      /**  */
      cameraId?: number;
      /**  */
      detectionDatetimeAfter?: string;
      /**  */
      detectionDatetimeBefore?: string;
      /**  */
      maskId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedCounterList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/speed_counters/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        camera_id: params['cameraId'],
        detection_datetime_after: params['detectionDatetimeAfter'],
        detection_datetime_before: params['detectionDatetimeBefore'],
        mask_id: params['maskId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счетчики скорости
   */
  static camerasSpeedCountersRetrieve(
    params: {
      /** A unique integer value identifying this Счетчик скорости. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedCounter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/speed_counters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счетчики скорости
   */
  static camerasSpeedCountersDestroy(
    params: {
      /** A unique integer value identifying this Счетчик скорости. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/speed_counters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы заснеженности
   */
  static camerasWhitetrackDetectorsList(
    params: {
      /**  */
      cameraId?: number;
      /**  */
      detectionDatetimeAfter?: string;
      /**  */
      detectionDatetimeBefore?: string;
      /**  */
      maskId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WhiteTrackDetectorList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/whitetrack_detectors/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        camera_id: params['cameraId'],
        detection_datetime_after: params['detectionDatetimeAfter'],
        detection_datetime_before: params['detectionDatetimeBefore'],
        mask_id: params['maskId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы заснеженности
   */
  static camerasWhitetrackDetectorsRetrieve(
    params: {
      /** A unique integer value identifying this Детектор заснеженности. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WhiteTrackDetector> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/whitetrack_detectors/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы заснеженности
   */
  static camerasWhitetrackDetectorsDestroy(
    params: {
      /** A unique integer value identifying this Детектор заснеженности. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/whitetrack_detectors/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Виджеты камер
   */
  static camerasWidgetList(options: IRequestOptions = {}): Promise<CameraWidgetList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/widget/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Виджеты камер
   */
  static camerasWidgetCreate(
    params: {
      /** requestBody */
      body?: CameraWidget;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraWidget> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/widget/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Виджеты камер
   */
  static camerasWidgetRetrieve(
    params: {
      /** A unique integer value identifying this Виджет камер. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraWidget> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/widget/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Виджеты камер
   */
  static camerasWidgetUpdate(
    params: {
      /** A unique integer value identifying this Виджет камер. */
      id: number;
      /** requestBody */
      body?: CameraWidget;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraWidget> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/widget/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Виджеты камер
   */
  static camerasWidgetDestroy(
    params: {
      /** A unique integer value identifying this Виджет камер. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/widget/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Данные виджетов камер
   */
  static camerasWidgetDataCreate(
    params: {
      /** requestBody */
      body?: CameraWidgetCalculatedData;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraWidgetCalculatedData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/widget_data/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Данные виджета камер
   */
  static camerasWidgetDataGetResultCreate(
    params: {
      /** A unique integer value identifying this Данные виджета камер. */
      id: number;
      /** requestBody */
      body?: CameraWidgetDataByDatetimeInput;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraWidgetCalculatedData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cameras/widget_data/{id}/get_result/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CitizenAppearsService {
  /**
   * Обращения граждан
   */
  static citizenAppearsList(
    params: {
      /** Адрес */
      address?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitizenAppealList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/citizen_appears/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обращения граждан
   */
  static citizenAppearsCreate(
    params: {
      /** requestBody */
      body?: CitizenAppeal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitizenAppeal> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/citizen_appears/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обращения граждан
   */
  static citizenAppearsRetrieve(
    params: {
      /** A unique integer value identifying this Обращение граждан. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitizenAppeal> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/citizen_appears/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обращения граждан
   */
  static citizenAppearsUpdate(
    params: {
      /** A unique integer value identifying this Обращение граждан. */
      id: number;
      /** requestBody */
      body?: CitizenAppeal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitizenAppeal> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/citizen_appears/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обращения граждан
   */
  static citizenAppearsPartialUpdate(
    params: {
      /** A unique integer value identifying this Обращение граждан. */
      id: number;
      /** requestBody */
      body?: PatchedCitizenAppeal;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CitizenAppeal> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/citizen_appears/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обращения граждан
   */
  static citizenAppearsDestroy(
    params: {
      /** A unique integer value identifying this Обращение граждан. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/citizen_appears/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DetectionsService {
  /**
   * Распознавания
   */
  static detectionsList(
    params: {
      /** Дата создания распознавания */
      createdAt?: string;
      /** Фильтр минимального времени создания */
      createdAtGte?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Тип объекта распознавания */
      detectionType?: string;
      /**  */
      hasImage?: boolean;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
      /** Фильтр по нескольким статусам */
      statusIn?: string;
      /** Статус подтверждения распознавания */
      validationStatus?: string;
      /** Тип нарушения */
      violationType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        created_at: params['createdAt'],
        created_at__gte: params['createdAtGte'],
        deleted: params['deleted'],
        detection_type: params['detectionType'],
        has_image: params['hasImage'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
        status__in: params['statusIn'],
        validation_status: params['validationStatus'],
        violation_type: params['violationType'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Распознавания
   */
  static detectionsCreate(
    params: {
      /** requestBody */
      body?: Detection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Detection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Распознавания
   */
  static detectionsRetrieve(
    params: {
      /** A unique integer value identifying this Распознавание. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Detection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Распознавания
   */
  static detectionsUpdate(
    params: {
      /** A unique integer value identifying this Распознавание. */
      id: number;
      /** requestBody */
      body?: Detection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Detection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Распознавания
   */
  static detectionsPartialUpdate(
    params: {
      /** A unique integer value identifying this Распознавание. */
      id: number;
      /** requestBody */
      body?: PatchedDetection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Detection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Распознавания
   */
  static detectionsDestroy(
    params: {
      /** A unique integer value identifying this Распознавание. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Распознавания
   */
  static detectionsRealObjectsList(
    params: {
      /** Дата создания распознавания */
      createdAt?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Тип объекта распознавания */
      detectionType?: string;
      /**  */
      hasImage?: boolean;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
      /** Статус подтверждения распознавания */
      validationStatus?: string;
      /** Тип нарушения */
      violationType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionRealObject[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/{id}/real_objects/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        created_at: params['createdAt'],
        deleted: params['deleted'],
        detection_type: params['detectionType'],
        has_image: params['hasImage'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
        validation_status: params['validationStatus'],
        violation_type: params['violationType'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Распознавания
   */
  static detectionsActualList(
    params: {
      /** Дата создания распознавания */
      createdAt?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Тип объекта распознавания */
      detectionType?: string;
      /**  */
      hasImage?: boolean;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
      /** Статус подтверждения распознавания */
      validationStatus?: string;
      /** Тип нарушения */
      violationType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionShort[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/actual/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        created_at: params['createdAt'],
        deleted: params['deleted'],
        detection_type: params['detectionType'],
        has_image: params['hasImage'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
        validation_status: params['validationStatus'],
        violation_type: params['violationType'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проверочные задания на распознавание
   */
  static detectionsTasksList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionTaskList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/tasks/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проверочные задания на распознавание
   */
  static detectionsTasksCreate(
    params: {
      /** requestBody */
      body?: DetectionTask;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionTask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/tasks/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проверочные задания на распознавание
   */
  static detectionsTasksRetrieve(
    params: {
      /** A unique integer value identifying this Проверочное задание на распознавание. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionTask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проверочные задания на распознавание
   */
  static detectionsTasksUpdate(
    params: {
      /** A unique integer value identifying this Проверочное задание на распознавание. */
      id: number;
      /** requestBody */
      body?: DetectionTask;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionTask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проверочные задания на распознавание
   */
  static detectionsTasksPartialUpdate(
    params: {
      /** A unique integer value identifying this Проверочное задание на распознавание. */
      id: number;
      /** requestBody */
      body?: PatchedDetectionTask;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionTask> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проверочные задания на распознавание
   */
  static detectionsTasksDestroy(
    params: {
      /** A unique integer value identifying this Проверочное задание на распознавание. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/detections/tasks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class EngineeringConstructionsService {
  /**
   * Инженерные дорожные сооружения
   */
  static engineeringConstructionsList(
    params: {
      /** Тип */
      constructionType?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EngineeringConstructionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        construction_type: params['constructionType'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Инженерные дорожные сооружения
   */
  static engineeringConstructionsCreate(
    params: {
      /** requestBody */
      body?: EngineeringConstruction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EngineeringConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Инженерные дорожные сооружения
   */
  static engineeringConstructionsRetrieve(
    params: {
      /** A unique integer value identifying this Инженерное дорожное сооружение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EngineeringConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Инженерные дорожные сооружения
   */
  static engineeringConstructionsUpdate(
    params: {
      /** A unique integer value identifying this Инженерное дорожное сооружение. */
      id: number;
      /** requestBody */
      body?: EngineeringConstruction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EngineeringConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Инженерные дорожные сооружения
   */
  static engineeringConstructionsPartialUpdate(
    params: {
      /** A unique integer value identifying this Инженерное дорожное сооружение. */
      id: number;
      /** requestBody */
      body?: PatchedEngineeringConstruction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EngineeringConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Инженерные дорожные сооружения
   */
  static engineeringConstructionsDestroy(
    params: {
      /** A unique integer value identifying this Инженерное дорожное сооружение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы
   */
  static engineeringConstructionsTubesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
      /** Тип */
      tubeType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TubeConstructionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/tubes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
        tube_type: params['tubeType'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы
   */
  static engineeringConstructionsTubesCreate(
    params: {
      /** requestBody */
      body?: TubeConstruction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TubeConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/tubes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы
   */
  static engineeringConstructionsTubesRetrieve(
    params: {
      /** A unique integer value identifying this Труба. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TubeConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/tubes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы
   */
  static engineeringConstructionsTubesUpdate(
    params: {
      /** A unique integer value identifying this Труба. */
      id: number;
      /** requestBody */
      body?: TubeConstruction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TubeConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/tubes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы
   */
  static engineeringConstructionsTubesPartialUpdate(
    params: {
      /** A unique integer value identifying this Труба. */
      id: number;
      /** requestBody */
      body?: PatchedTubeConstruction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TubeConstruction> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/tubes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы
   */
  static engineeringConstructionsTubesDestroy(
    params: {
      /** A unique integer value identifying this Труба. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/tubes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static engineeringConstructionsUploadEngineeringConstructionCreate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/engineering_constructions/upload_engineering_construction';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FiltersService {
  /**
   * Фильтры районов для пользователей
   */
  static filtersUserDistrictsList(
    params: {
      /** Фильтр по полям модели "Муниципальный район" */
      district?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Пользователь" */
      user?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDistricts[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_districts/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        district: params['district'],
        id: params['id'],
        ordering: params['ordering'],
        user: params['user'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры районов для пользователей
   */
  static filtersUserDistrictsCreate(
    params: {
      /** requestBody */
      body?: UserDistricts;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDistricts> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_districts/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры районов для пользователей
   */
  static filtersUserDistrictsRetrieve(
    params: {
      /** A unique integer value identifying this Фильтр районов для пользователей. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDistricts> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры районов для пользователей
   */
  static filtersUserDistrictsUpdate(
    params: {
      /** A unique integer value identifying this Фильтр районов для пользователей. */
      id: number;
      /** requestBody */
      body?: UserDistricts;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDistricts> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры районов для пользователей
   */
  static filtersUserDistrictsPartialUpdate(
    params: {
      /** A unique integer value identifying this Фильтр районов для пользователей. */
      id: number;
      /** requestBody */
      body?: PatchedUserDistricts;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDistricts> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры районов для пользователей
   */
  static filtersUserDistrictsDestroy(
    params: {
      /** A unique integer value identifying this Фильтр районов для пользователей. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры организаций для пользователей
   */
  static filtersUserOrganizationsList(
    params: {
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Организация" */
      organization?: string;
      /** Фильтр по полям модели "Пользователь" */
      user?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserOrganizations[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_organizations/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        id: params['id'],
        ordering: params['ordering'],
        organization: params['organization'],
        user: params['user'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры организаций для пользователей
   */
  static filtersUserOrganizationsCreate(
    params: {
      /** requestBody */
      body?: UserOrganizations;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserOrganizations> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_organizations/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры организаций для пользователей
   */
  static filtersUserOrganizationsRetrieve(
    params: {
      /** A unique integer value identifying this Фильтр организаций для пользователей. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserOrganizations> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры организаций для пользователей
   */
  static filtersUserOrganizationsUpdate(
    params: {
      /** A unique integer value identifying this Фильтр организаций для пользователей. */
      id: number;
      /** requestBody */
      body?: UserOrganizations;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserOrganizations> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры организаций для пользователей
   */
  static filtersUserOrganizationsPartialUpdate(
    params: {
      /** A unique integer value identifying this Фильтр организаций для пользователей. */
      id: number;
      /** requestBody */
      body?: PatchedUserOrganizations;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserOrganizations> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фильтры организаций для пользователей
   */
  static filtersUserOrganizationsDestroy(
    params: {
      /** A unique integer value identifying this Фильтр организаций для пользователей. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/filters/user_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class KnsService {
  /**
   * Кабели КНС трамваев
   */
  static knsCablesTramList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTramList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_tram/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС трамваев
   */
  static knsCablesTramCreate(
    params: {
      /** requestBody */
      body?: CableTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_tram/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС трамваев
   */
  static knsCablesTramRetrieve(
    params: {
      /** A unique integer value identifying this Кабель КНС трамваев. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС трамваев
   */
  static knsCablesTramUpdate(
    params: {
      /** A unique integer value identifying this Кабель КНС трамваев. */
      id: number;
      /** requestBody */
      body?: CableTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС трамваев
   */
  static knsCablesTramPartialUpdate(
    params: {
      /** A unique integer value identifying this Кабель КНС трамваев. */
      id: number;
      /** requestBody */
      body?: PatchedCableTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС трамваев
   */
  static knsCablesTramDestroy(
    params: {
      /** A unique integer value identifying this Кабель КНС трамваев. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС Троллейбусов
   */
  static knsCablesTrollList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTrolleybusList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_troll/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС Троллейбусов
   */
  static knsCablesTrollCreate(
    params: {
      /** requestBody */
      body?: CableTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_troll/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС Троллейбусов
   */
  static knsCablesTrollRetrieve(
    params: {
      /** A unique integer value identifying this Кабель КНС Троллейбусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС Троллейбусов
   */
  static knsCablesTrollUpdate(
    params: {
      /** A unique integer value identifying this Кабель КНС Троллейбусов. */
      id: number;
      /** requestBody */
      body?: CableTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС Троллейбусов
   */
  static knsCablesTrollPartialUpdate(
    params: {
      /** A unique integer value identifying this Кабель КНС Троллейбусов. */
      id: number;
      /** requestBody */
      body?: PatchedCableTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Кабели КНС Троллейбусов
   */
  static knsCablesTrollDestroy(
    params: {
      /** A unique integer value identifying this Кабель КНС Троллейбусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС трамваев
   */
  static knsLinesTramList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTramList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_tram/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС трамваев
   */
  static knsLinesTramCreate(
    params: {
      /** requestBody */
      body?: LineTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_tram/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС трамваев
   */
  static knsLinesTramRetrieve(
    params: {
      /** A unique integer value identifying this Линия КНС трамваев. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС трамваев
   */
  static knsLinesTramUpdate(
    params: {
      /** A unique integer value identifying this Линия КНС трамваев. */
      id: number;
      /** requestBody */
      body?: LineTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС трамваев
   */
  static knsLinesTramPartialUpdate(
    params: {
      /** A unique integer value identifying this Линия КНС трамваев. */
      id: number;
      /** requestBody */
      body?: PatchedLineTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС трамваев
   */
  static knsLinesTramDestroy(
    params: {
      /** A unique integer value identifying this Линия КНС трамваев. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС троллейбусов
   */
  static knsLinesTrollList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTrolleybusList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_troll/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС троллейбусов
   */
  static knsLinesTrollCreate(
    params: {
      /** requestBody */
      body?: LineTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_troll/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС троллейбусов
   */
  static knsLinesTrollRetrieve(
    params: {
      /** A unique integer value identifying this Линия КНС троллейбусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС троллейбусов
   */
  static knsLinesTrollUpdate(
    params: {
      /** A unique integer value identifying this Линия КНС троллейбусов. */
      id: number;
      /** requestBody */
      body?: LineTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС троллейбусов
   */
  static knsLinesTrollPartialUpdate(
    params: {
      /** A unique integer value identifying this Линия КНС троллейбусов. */
      id: number;
      /** requestBody */
      body?: PatchedLineTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LineTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линии КНС троллейбусов
   */
  static knsLinesTrollDestroy(
    params: {
      /** A unique integer value identifying this Линия КНС троллейбусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/lines_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подстанции
   */
  static knsSubstationsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubstationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/substations/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подстанции
   */
  static knsSubstationsCreate(
    params: {
      /** requestBody */
      body?: Substation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Substation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/substations/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подстанции
   */
  static knsSubstationsRetrieve(
    params: {
      /** A unique integer value identifying this Подстанция. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Substation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/substations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подстанции
   */
  static knsSubstationsUpdate(
    params: {
      /** A unique integer value identifying this Подстанция. */
      id: number;
      /** requestBody */
      body?: Substation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Substation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/substations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подстанции
   */
  static knsSubstationsPartialUpdate(
    params: {
      /** A unique integer value identifying this Подстанция. */
      id: number;
      /** requestBody */
      body?: PatchedSubstation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Substation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/substations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подстанции
   */
  static knsSubstationsDestroy(
    params: {
      /** A unique integer value identifying this Подстанция. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/substations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели трамваев
   */
  static knsSupplyCablesTramList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTramList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_tram/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели трамваев
   */
  static knsSupplyCablesTramCreate(
    params: {
      /** requestBody */
      body?: SupplyCableTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_tram/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели трамваев
   */
  static knsSupplyCablesTramRetrieve(
    params: {
      /** A unique integer value identifying this Питающий кабель трамваев. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели трамваев
   */
  static knsSupplyCablesTramUpdate(
    params: {
      /** A unique integer value identifying this Питающий кабель трамваев. */
      id: number;
      /** requestBody */
      body?: SupplyCableTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели трамваев
   */
  static knsSupplyCablesTramPartialUpdate(
    params: {
      /** A unique integer value identifying this Питающий кабель трамваев. */
      id: number;
      /** requestBody */
      body?: PatchedSupplyCableTram;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTram> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели трамваев
   */
  static knsSupplyCablesTramDestroy(
    params: {
      /** A unique integer value identifying this Питающий кабель трамваев. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_tram/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели троллейбусов
   */
  static knsSupplyCablesTrollList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTrolleybusList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_troll/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели троллейбусов
   */
  static knsSupplyCablesTrollCreate(
    params: {
      /** requestBody */
      body?: SupplyCableTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_troll/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели троллейбусов
   */
  static knsSupplyCablesTrollRetrieve(
    params: {
      /** A unique integer value identifying this Питающий кабель троллейбусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели троллейбусов
   */
  static knsSupplyCablesTrollUpdate(
    params: {
      /** A unique integer value identifying this Питающий кабель троллейбусов. */
      id: number;
      /** requestBody */
      body?: SupplyCableTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели троллейбусов
   */
  static knsSupplyCablesTrollPartialUpdate(
    params: {
      /** A unique integer value identifying this Питающий кабель троллейбусов. */
      id: number;
      /** requestBody */
      body?: PatchedSupplyCableTrolleybus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupplyCableTrolleybus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Питающие кабели троллейбусов
   */
  static knsSupplyCablesTrollDestroy(
    params: {
      /** A unique integer value identifying this Питающий кабель троллейбусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/supply_cables_troll/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опорные столбы
   */
  static knsSupportPillarsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportPillarList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/support_pillars/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опорные столбы
   */
  static knsSupportPillarsCreate(
    params: {
      /** requestBody */
      body?: SupportPillar;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportPillar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/support_pillars/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опорные столбы
   */
  static knsSupportPillarsRetrieve(
    params: {
      /** A unique integer value identifying this Опорный столб. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportPillar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/support_pillars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опорные столбы
   */
  static knsSupportPillarsUpdate(
    params: {
      /** A unique integer value identifying this Опорный столб. */
      id: number;
      /** requestBody */
      body?: SupportPillar;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportPillar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/support_pillars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опорные столбы
   */
  static knsSupportPillarsPartialUpdate(
    params: {
      /** A unique integer value identifying this Опорный столб. */
      id: number;
      /** requestBody */
      body?: PatchedSupportPillar;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportPillar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/support_pillars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опорные столбы
   */
  static knsSupportPillarsDestroy(
    params: {
      /** A unique integer value identifying this Опорный столб. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/support_pillars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы кабеля
   */
  static knsTypePowerCablesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypePowerCableList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/type_power_cables/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы кабеля
   */
  static knsTypePowerCablesCreate(
    params: {
      /** requestBody */
      body?: TypePowerCable;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypePowerCable> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/type_power_cables/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы кабеля
   */
  static knsTypePowerCablesRetrieve(
    params: {
      /** A unique integer value identifying this Тип кабеля. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypePowerCable> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/type_power_cables/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы кабеля
   */
  static knsTypePowerCablesUpdate(
    params: {
      /** A unique integer value identifying this Тип кабеля. */
      id: number;
      /** requestBody */
      body?: TypePowerCable;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypePowerCable> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/type_power_cables/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы кабеля
   */
  static knsTypePowerCablesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип кабеля. */
      id: number;
      /** requestBody */
      body?: PatchedTypePowerCable;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypePowerCable> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/type_power_cables/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы кабеля
   */
  static knsTypePowerCablesDestroy(
    params: {
      /** A unique integer value identifying this Тип кабеля. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/type_power_cables/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Идеальные вагоны
   */
  static knsWaggonsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WaggonList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/waggons/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Идеальные вагоны
   */
  static knsWaggonsCreate(
    params: {
      /** requestBody */
      body?: Waggon;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Waggon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/waggons/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Идеальные вагоны
   */
  static knsWaggonsRetrieve(
    params: {
      /** A unique integer value identifying this Идеальный вагон. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Waggon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/waggons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Идеальные вагоны
   */
  static knsWaggonsUpdate(
    params: {
      /** A unique integer value identifying this Идеальный вагон. */
      id: number;
      /** requestBody */
      body?: Waggon;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Waggon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/waggons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Идеальные вагоны
   */
  static knsWaggonsPartialUpdate(
    params: {
      /** A unique integer value identifying this Идеальный вагон. */
      id: number;
      /** requestBody */
      body?: PatchedWaggon;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Waggon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/waggons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Идеальные вагоны
   */
  static knsWaggonsDestroy(
    params: {
      /** A unique integer value identifying this Идеальный вагон. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/kns/waggons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MonitoringService {
  /**
   * Транспортные средства
   */
  static monitoringVehiclesList(
    params: {
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Государственный регистрационный номер */
      registrationNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GridVehicle[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/monitoring/vehicles/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ordering: params['ordering'], registration_number: params['registrationNumber'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Транспортные средства
   */
  static monitoringVehiclesRetrieve(
    params: {
      /** A unique integer value identifying this Транспортное средство. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GridVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/monitoring/vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrganizationsService {
  /**
   * Организации
   */
  static organizationsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Форма организации" */
      organizationForm?: string;
      /** Телефон */
      phone?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        organization_form: params['organizationForm'],
        phone: params['phone'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Организации
   */
  static organizationsCreate(
    params: {
      /** requestBody */
      body?: Organization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Organization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Организации
   */
  static organizationsRetrieve(
    params: {
      /** A unique integer value identifying this Организация. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Organization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Организации
   */
  static organizationsUpdate(
    params: {
      /** A unique integer value identifying this Организация. */
      id: number;
      /** requestBody */
      body?: Organization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Organization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Организации
   */
  static organizationsPartialUpdate(
    params: {
      /** A unique integer value identifying this Организация. */
      id: number;
      /** requestBody */
      body?: PatchedOrganization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Organization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Организации
   */
  static organizationsDestroy(
    params: {
      /** A unique integer value identifying this Организация. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Формы организаций
   */
  static organizationsFormsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Название */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationFormList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/forms/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Формы организаций
   */
  static organizationsFormsCreate(
    params: {
      /** requestBody */
      body?: OrganizationForm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationForm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/forms/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Формы организаций
   */
  static organizationsFormsRetrieve(
    params: {
      /** A unique integer value identifying this Форма организации. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationForm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/forms/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Формы организаций
   */
  static organizationsFormsUpdate(
    params: {
      /** A unique integer value identifying this Форма организации. */
      id: number;
      /** requestBody */
      body?: OrganizationForm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationForm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/forms/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Формы организаций
   */
  static organizationsFormsPartialUpdate(
    params: {
      /** A unique integer value identifying this Форма организации. */
      id: number;
      /** requestBody */
      body?: PatchedOrganizationForm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationForm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/forms/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Формы организаций
   */
  static organizationsFormsDestroy(
    params: {
      /** A unique integer value identifying this Форма организации. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/forms/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PermissionsService {
  /**
   * права
   */
  static permissionsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "группа" */
      group?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Имя */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        group: params['group'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * права
   */
  static permissionsRetrieve(
    params: {
      /** A unique integer value identifying this право. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Permission> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * права
   */
  static permissionsGetDirectoryRetrieve(options: IRequestOptions = {}): Promise<Permission> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/get_directory/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * права
   */
  static permissionsGetLayersRetrieve(options: IRequestOptions = {}): Promise<Permission> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/get_layers/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * права
   */
  static permissionsGetReportsRetrieve(options: IRequestOptions = {}): Promise<Permission> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/get_reports/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * права
   */
  static permissionsGetWidgetsRetrieve(options: IRequestOptions = {}): Promise<Permission> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/get_widgets/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * группы
   */
  static permissionsGroupsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Имя */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "право" */
      permissions?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Пользователь" */
      user?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GroupList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/groups/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        permissions: params['permissions'],
        representation: params['representation'],
        user: params['user'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * группы
   */
  static permissionsGroupsCreate(
    params: {
      /** requestBody */
      body?: Group;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Group> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/groups/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * группы
   */
  static permissionsGroupsRetrieve(
    params: {
      /** A unique integer value identifying this группа. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Group> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * группы
   */
  static permissionsGroupsUpdate(
    params: {
      /** A unique integer value identifying this группа. */
      id: number;
      /** requestBody */
      body?: Group;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Group> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * группы
   */
  static permissionsGroupsPartialUpdate(
    params: {
      /** A unique integer value identifying this группа. */
      id: number;
      /** requestBody */
      body?: PatchedGroup;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Group> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * группы
   */
  static permissionsGroupsDestroy(
    params: {
      /** A unique integer value identifying this группа. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/permissions/groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PublicTransportService {
  /**
   * Файлы
   */
  static publicTransportBusStopFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Остановка общественного транспорта" */
      roadConsObject?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStopFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stop_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_cons_object: params['roadConsObject'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static publicTransportBusStopFilesCreate(
    params: {
      /** requestBody */
      body?: BusStopFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStopFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stop_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static publicTransportBusStopFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStopFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stop_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static publicTransportBusStopFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: BusStopFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStopFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stop_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static publicTransportBusStopFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: PatchedBusStopFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStopFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stop_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static publicTransportBusStopFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stop_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановки общественного транспорта
   */
  static publicTransportBusStopsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Название остановки */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStopList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stops/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановки общественного транспорта
   */
  static publicTransportBusStopsCreate(
    params: {
      /** requestBody */
      body?: BusStop;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stops/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановки общественного транспорта
   */
  static publicTransportBusStopsRetrieve(
    params: {
      /** A unique integer value identifying this Остановка общественного транспорта. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stops/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановки общественного транспорта
   */
  static publicTransportBusStopsUpdate(
    params: {
      /** A unique integer value identifying this Остановка общественного транспорта. */
      id: number;
      /** requestBody */
      body?: BusStop;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stops/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановки общественного транспорта
   */
  static publicTransportBusStopsPartialUpdate(
    params: {
      /** A unique integer value identifying this Остановка общественного транспорта. */
      id: number;
      /** requestBody */
      body?: PatchedBusStop;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stops/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановки общественного транспорта
   */
  static publicTransportBusStopsDestroy(
    params: {
      /** A unique integer value identifying this Остановка общественного транспорта. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/bus_stops/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы маршрута
   */
  static publicTransportRouteSchemesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по идентификатору остановки */
      stopId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteSchemeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_schemes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        stop_id: params['stopId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы маршрута
   */
  static publicTransportRouteSchemesCreate(
    params: {
      /** requestBody */
      body?: RouteScheme;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteScheme> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_schemes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы маршрута
   */
  static publicTransportRouteSchemesRetrieve(
    params: {
      /** A unique integer value identifying this Схема маршрута. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteScheme> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_schemes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы маршрута
   */
  static publicTransportRouteSchemesUpdate(
    params: {
      /** A unique integer value identifying this Схема маршрута. */
      id: number;
      /** requestBody */
      body?: RouteScheme;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteScheme> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_schemes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы маршрута
   */
  static publicTransportRouteSchemesPartialUpdate(
    params: {
      /** A unique integer value identifying this Схема маршрута. */
      id: number;
      /** requestBody */
      body?: PatchedRouteScheme;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteScheme> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_schemes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы маршрута
   */
  static publicTransportRouteSchemesDestroy(
    params: {
      /** A unique integer value identifying this Схема маршрута. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_schemes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сегменты маршрута
   */
  static publicTransportRouteSegmentsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Схема маршрута" */
      routeScheme?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteSegmentList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_segments/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        route_scheme: params['routeScheme'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сегменты маршрута
   */
  static publicTransportRouteSegmentsCreate(
    params: {
      /** requestBody */
      body?: RouteSegment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteSegment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_segments/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сегменты маршрута
   */
  static publicTransportRouteSegmentsRetrieve(
    params: {
      /** A unique integer value identifying this Сегмент маршрута. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteSegment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сегменты маршрута
   */
  static publicTransportRouteSegmentsCreateOrUpdateCreate(
    params: {
      /** requestBody */
      body?: RouteSegment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteSegment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/route_segments/create_or_update/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static publicTransportRoutesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Название маршрута */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/routes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static publicTransportRoutesCreate(
    params: {
      /** requestBody */
      body?: Route;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Route> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/routes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static publicTransportRoutesRetrieve(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Route> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static publicTransportRoutesUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: Route;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Route> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static publicTransportRoutesPartialUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: PatchedRoute;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Route> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static publicTransportRoutesDestroy(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Статусы остановок
   */
  static publicTransportStatusesBusStopList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusBusStopList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/statuses_bus_stop/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Статусы остановок
   */
  static publicTransportStatusesBusStopCreate(
    params: {
      /** requestBody */
      body?: StatusBusStop;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusBusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/statuses_bus_stop/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Статусы остановок
   */
  static publicTransportStatusesBusStopRetrieve(
    params: {
      /** A unique integer value identifying this Статус остановки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusBusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/statuses_bus_stop/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Статусы остановок
   */
  static publicTransportStatusesBusStopUpdate(
    params: {
      /** A unique integer value identifying this Статус остановки. */
      id: number;
      /** requestBody */
      body?: StatusBusStop;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusBusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/statuses_bus_stop/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Статусы остановок
   */
  static publicTransportStatusesBusStopPartialUpdate(
    params: {
      /** A unique integer value identifying this Статус остановки. */
      id: number;
      /** requestBody */
      body?: PatchedStatusBusStop;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusBusStop> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/statuses_bus_stop/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Статусы остановок
   */
  static publicTransportStatusesBusStopDestroy(
    params: {
      /** A unique integer value identifying this Статус остановки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/statuses_bus_stop/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трамвайное полотно
   */
  static publicTransportTramwaysList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Материал покрытия */
      material?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TramWayList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/tramways/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        material: params['material'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трамвайное полотно
   */
  static publicTransportTramwaysCreate(
    params: {
      /** requestBody */
      body?: TramWay;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TramWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/tramways/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трамвайное полотно
   */
  static publicTransportTramwaysRetrieve(
    params: {
      /** A unique integer value identifying this Трамвайное полотно. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TramWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/tramways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трамвайное полотно
   */
  static publicTransportTramwaysUpdate(
    params: {
      /** A unique integer value identifying this Трамвайное полотно. */
      id: number;
      /** requestBody */
      body?: TramWay;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TramWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/tramways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трамвайное полотно
   */
  static publicTransportTramwaysPartialUpdate(
    params: {
      /** A unique integer value identifying this Трамвайное полотно. */
      id: number;
      /** requestBody */
      body?: PatchedTramWay;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TramWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/tramways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трамвайное полотно
   */
  static publicTransportTramwaysDestroy(
    params: {
      /** A unique integer value identifying this Трамвайное полотно. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/tramways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы информационных табло
   */
  static publicTransportTypesOfInformationBoardsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypeOfInformationBoardList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/types_of_information_boards/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы информационных табло
   */
  static publicTransportTypesOfInformationBoardsCreate(
    params: {
      /** requestBody */
      body?: TypeOfInformationBoard;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypeOfInformationBoard> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/types_of_information_boards/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы информационных табло
   */
  static publicTransportTypesOfInformationBoardsRetrieve(
    params: {
      /** A unique integer value identifying this Тип информационных табло. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypeOfInformationBoard> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/types_of_information_boards/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы информационных табло
   */
  static publicTransportTypesOfInformationBoardsUpdate(
    params: {
      /** A unique integer value identifying this Тип информационных табло. */
      id: number;
      /** requestBody */
      body?: TypeOfInformationBoard;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypeOfInformationBoard> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/types_of_information_boards/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы информационных табло
   */
  static publicTransportTypesOfInformationBoardsPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип информационных табло. */
      id: number;
      /** requestBody */
      body?: PatchedTypeOfInformationBoard;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TypeOfInformationBoard> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/types_of_information_boards/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы информационных табло
   */
  static publicTransportTypesOfInformationBoardsDestroy(
    params: {
      /** A unique integer value identifying this Тип информационных табло. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/public_transport/types_of_information_boards/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ReportsService {
  /**
   *
   */
  static reportsCrossRoadPerDistrictRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/cross_road_per_district';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsCrossRoadPerDistrictCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/cross_road_per_district';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsCrossRoadPerDistrictUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/cross_road_per_district';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadAccidentSectionsRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_accident_sections';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadAccidentSectionsCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_accident_sections';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadAccidentSectionsUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_accident_sections';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadAccidentsRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_accidents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadAccidentsCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_accidents';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadAccidentsUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_accidents';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadMarkingLineAreaAndVolumeReportRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_marking_line_area_and_volume_report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadMarkingLineAreaAndVolumeReportCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_marking_line_area_and_volume_report';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadMarkingLineAreaAndVolumeReportUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_marking_line_area_and_volume_report';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadSignsByRoadRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_signs_by_road';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadSignsByRoadCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_signs_by_road';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadSignsByRoadUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_signs_by_road';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadStatsRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_stats';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadStatsCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_stats';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsRoadStatsUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/road_stats';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsStatementRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/statement';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsStatementCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/statement';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsStatementUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/statement';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsTrafficIntensityRetrieve(options: IRequestOptions = {}): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/traffic_intensity';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsTrafficIntensityCreate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/traffic_intensity';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reportsTrafficIntensityUpdate(
    params: {
      /** requestBody */
      body?: ReportConfig;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportConfig> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reports/traffic_intensity';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoadAccidentsService {
  /**
   * ДТП
   */
  static roadAccidentsList(
    params: {
      /** Адрес */
      address?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Вид происшествия */
      roadAccidentType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_accident_type: params['roadAccidentType'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ДТП
   */
  static roadAccidentsCreate(
    params: {
      /** requestBody */
      body?: RoadAccident;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccident> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ДТП
   */
  static roadAccidentsRetrieve(
    params: {
      /** A unique integer value identifying this ДТП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccident> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ДТП
   */
  static roadAccidentsUpdate(
    params: {
      /** A unique integer value identifying this ДТП. */
      id: number;
      /** requestBody */
      body?: RoadAccident;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccident> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ДТП
   */
  static roadAccidentsPartialUpdate(
    params: {
      /** A unique integer value identifying this ДТП. */
      id: number;
      /** requestBody */
      body?: PatchedRoadAccident;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccident> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ДТП
   */
  static roadAccidentsDestroy(
    params: {
      /** A unique integer value identifying this ДТП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участники ДТП
   */
  static roadAccidentsParticipantList(
    params: {
      /** Категория участника */
      category?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "ДТП" */
      roadAccident?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentParticipantList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/participant/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        category: params['category'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_accident: params['roadAccident'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участники ДТП
   */
  static roadAccidentsParticipantCreate(
    params: {
      /** requestBody */
      body?: RoadAccidentParticipant;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentParticipant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/participant/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участники ДТП
   */
  static roadAccidentsParticipantRetrieve(
    params: {
      /** A unique integer value identifying this Участник ДТП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentParticipant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/participant/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участники ДТП
   */
  static roadAccidentsParticipantUpdate(
    params: {
      /** A unique integer value identifying this Участник ДТП. */
      id: number;
      /** requestBody */
      body?: RoadAccidentParticipant;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentParticipant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/participant/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участники ДТП
   */
  static roadAccidentsParticipantPartialUpdate(
    params: {
      /** A unique integer value identifying this Участник ДТП. */
      id: number;
      /** requestBody */
      body?: PatchedRoadAccidentParticipant;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentParticipant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/participant/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участники ДТП
   */
  static roadAccidentsParticipantDestroy(
    params: {
      /** A unique integer value identifying this Участник ДТП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/participant/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аварийно-опасные участки дорог
   */
  static roadAccidentsSectionsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentSectionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/sections/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аварийно-опасные участки дорог
   */
  static roadAccidentsSectionsCreate(
    params: {
      /** requestBody */
      body?: RoadAccidentSection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentSection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/sections/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аварийно-опасные участки дорог
   */
  static roadAccidentsSectionsRetrieve(
    params: {
      /** A unique integer value identifying this Аварийно-опасный участок дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentSection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/sections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аварийно-опасные участки дорог
   */
  static roadAccidentsSectionsUpdate(
    params: {
      /** A unique integer value identifying this Аварийно-опасный участок дороги. */
      id: number;
      /** requestBody */
      body?: RoadAccidentSection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentSection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/sections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аварийно-опасные участки дорог
   */
  static roadAccidentsSectionsPartialUpdate(
    params: {
      /** A unique integer value identifying this Аварийно-опасный участок дороги. */
      id: number;
      /** requestBody */
      body?: PatchedRoadAccidentSection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentSection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/sections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аварийно-опасные участки дорог
   */
  static roadAccidentsSectionsDestroy(
    params: {
      /** A unique integer value identifying this Аварийно-опасный участок дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/sections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аварийно-опасные участки дорог
   */
  static roadAccidentsSectionsGenerateSectionsCreate(
    params: {
      /** requestBody */
      body?: RoadAccidentSection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentSection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/sections/generate_sections/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ДТП
   */
  static roadAccidentsUpdateAccidentsSegmentCreate(
    params: {
      /** requestBody */
      body?: RoadAccident;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccident> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/update_accidents_segment/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ДТП
   */
  static roadAccidentsUploadAccidentsCreate(
    params: {
      /** requestBody */
      body?: RoadAccident;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccident> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/upload_accidents/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС участвовавшие в ДТП
   */
  static roadAccidentsVehicleList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentVehicleList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/vehicle/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС участвовавшие в ДТП
   */
  static roadAccidentsVehicleCreate(
    params: {
      /** requestBody */
      body?: RoadAccidentVehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/vehicle/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС участвовавшие в ДТП
   */
  static roadAccidentsVehicleRetrieve(
    params: {
      /** A unique integer value identifying this ТС участвовавшее в ДТП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/vehicle/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС участвовавшие в ДТП
   */
  static roadAccidentsVehicleUpdate(
    params: {
      /** A unique integer value identifying this ТС участвовавшее в ДТП. */
      id: number;
      /** requestBody */
      body?: RoadAccidentVehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/vehicle/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС участвовавшие в ДТП
   */
  static roadAccidentsVehiclePartialUpdate(
    params: {
      /** A unique integer value identifying this ТС участвовавшее в ДТП. */
      id: number;
      /** requestBody */
      body?: PatchedRoadAccidentVehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAccidentVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/vehicle/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС участвовавшие в ДТП
   */
  static roadAccidentsVehicleDestroy(
    params: {
      /** A unique integer value identifying this ТС участвовавшее в ДТП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_accidents/vehicle/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoadConstructionsService {
  /**
   * Остановочные навесы
   */
  static roadConstructionsBusSheltersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusShelterList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/bus_shelters/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановочные навесы
   */
  static roadConstructionsBusSheltersCreate(
    params: {
      /** requestBody */
      body?: BusShelter;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusShelter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/bus_shelters/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановочные навесы
   */
  static roadConstructionsBusSheltersRetrieve(
    params: {
      /** A unique integer value identifying this Остановочный навес. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusShelter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/bus_shelters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановочные навесы
   */
  static roadConstructionsBusSheltersUpdate(
    params: {
      /** A unique integer value identifying this Остановочный навес. */
      id: number;
      /** requestBody */
      body?: BusShelter;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusShelter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/bus_shelters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановочные навесы
   */
  static roadConstructionsBusSheltersPartialUpdate(
    params: {
      /** A unique integer value identifying this Остановочный навес. */
      id: number;
      /** requestBody */
      body?: PatchedBusShelter;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BusShelter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/bus_shelters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Остановочные навесы
   */
  static roadConstructionsBusSheltersDestroy(
    params: {
      /** A unique integer value identifying this Остановочный навес. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/bus_shelters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры освещения
   */
  static roadConstructionsLightingList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LightingList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/lighting/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры освещения
   */
  static roadConstructionsLightingCreate(
    params: {
      /** requestBody */
      body?: Lighting;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Lighting> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/lighting/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры освещения
   */
  static roadConstructionsLightingRetrieve(
    params: {
      /** A unique integer value identifying this Опора освещения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Lighting> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/lighting/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры освещения
   */
  static roadConstructionsLightingUpdate(
    params: {
      /** A unique integer value identifying this Опора освещения. */
      id: number;
      /** requestBody */
      body?: Lighting;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Lighting> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/lighting/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры освещения
   */
  static roadConstructionsLightingPartialUpdate(
    params: {
      /** A unique integer value identifying this Опора освещения. */
      id: number;
      /** requestBody */
      body?: PatchedLighting;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Lighting> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/lighting/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры освещения
   */
  static roadConstructionsLightingDestroy(
    params: {
      /** A unique integer value identifying this Опора освещения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/lighting/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые пешеходные ограждения
   */
  static roadConstructionsPedBorderProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorderProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_border_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые пешеходные ограждения
   */
  static roadConstructionsPedBorderProjectCreate(
    params: {
      /** requestBody */
      body?: PedBorderProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorderProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_border_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые пешеходные ограждения
   */
  static roadConstructionsPedBorderProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемое пешеходное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorderProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_border_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые пешеходные ограждения
   */
  static roadConstructionsPedBorderProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемое пешеходное ограждение. */
      id: number;
      /** requestBody */
      body?: PedBorderProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorderProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_border_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые пешеходные ограждения
   */
  static roadConstructionsPedBorderProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемое пешеходное ограждение. */
      id: number;
      /** requestBody */
      body?: PatchedPedBorderProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorderProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_border_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые пешеходные ограждения
   */
  static roadConstructionsPedBorderProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемое пешеходное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_border_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные ограждения
   */
  static roadConstructionsPedBordersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorderList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_borders/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные ограждения
   */
  static roadConstructionsPedBordersCreate(
    params: {
      /** requestBody */
      body?: PedBorder;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_borders/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные ограждения
   */
  static roadConstructionsPedBordersRetrieve(
    params: {
      /** A unique integer value identifying this Пешеходное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_borders/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные ограждения
   */
  static roadConstructionsPedBordersUpdate(
    params: {
      /** A unique integer value identifying this Пешеходное ограждение. */
      id: number;
      /** requestBody */
      body?: PedBorder;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_borders/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные ограждения
   */
  static roadConstructionsPedBordersPartialUpdate(
    params: {
      /** A unique integer value identifying this Пешеходное ограждение. */
      id: number;
      /** requestBody */
      body?: PatchedPedBorder;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedBorder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_borders/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные ограждения
   */
  static roadConstructionsPedBordersDestroy(
    params: {
      /** A unique integer value identifying this Пешеходное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/ped_borders/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные переходы
   */
  static roadConstructionsPedestrianCrossingsList(
    params: {
      /** Адрес */
      address?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Тип питания */
      powerSupplyType?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedestrianCrossingList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/pedestrian_crossings/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        power_supply_type: params['powerSupplyType'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные переходы
   */
  static roadConstructionsPedestrianCrossingsCreate(
    params: {
      /** requestBody */
      body?: PedestrianCrossing;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedestrianCrossing> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/pedestrian_crossings/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные переходы
   */
  static roadConstructionsPedestrianCrossingsRetrieve(
    params: {
      /** A unique integer value identifying this Пешеходный переход. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedestrianCrossing> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/pedestrian_crossings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные переходы
   */
  static roadConstructionsPedestrianCrossingsUpdate(
    params: {
      /** A unique integer value identifying this Пешеходный переход. */
      id: number;
      /** requestBody */
      body?: PedestrianCrossing;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedestrianCrossing> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/pedestrian_crossings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные переходы
   */
  static roadConstructionsPedestrianCrossingsPartialUpdate(
    params: {
      /** A unique integer value identifying this Пешеходный переход. */
      id: number;
      /** requestBody */
      body?: PatchedPedestrianCrossing;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PedestrianCrossing> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/pedestrian_crossings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пешеходные переходы
   */
  static roadConstructionsPedestrianCrossingsDestroy(
    params: {
      /** A unique integer value identifying this Пешеходный переход. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/pedestrian_crossings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsRoadSignFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорожный знак" */
      roadConsObject?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_cons_object: params['roadConsObject'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsRoadSignFilesCreate(
    params: {
      /** requestBody */
      body?: RoadSignFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsRoadSignFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsRoadSignFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: RoadSignFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsRoadSignFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: PatchedRoadSignFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsRoadSignFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Группы дорожных знаков
   */
  static roadConstructionsRoadSignGroupsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Координата X нижней правой точки */
      xMax?: number;
      /** Координата X верхней левой точки */
      xMin?: number;
      /** Координата Y нижней правой точки */
      yMax?: number;
      /** Координата Y верхней левой точки */
      yMin?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignGroupList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_groups/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        x_max: params['xMax'],
        x_min: params['xMin'],
        y_max: params['yMax'],
        y_min: params['yMin'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Группы дорожных знаков
   */
  static roadConstructionsRoadSignGroupsCreate(
    params: {
      /** requestBody */
      body?: RoadSignGroup;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignGroup> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_groups/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Группы дорожных знаков
   */
  static roadConstructionsRoadSignGroupsRetrieve(
    params: {
      /** A unique integer value identifying this Группа дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignGroup> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Группы дорожных знаков
   */
  static roadConstructionsRoadSignGroupsUpdate(
    params: {
      /** A unique integer value identifying this Группа дорожных знаков. */
      id: number;
      /** requestBody */
      body?: RoadSignGroup;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignGroup> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Группы дорожных знаков
   */
  static roadConstructionsRoadSignGroupsPartialUpdate(
    params: {
      /** A unique integer value identifying this Группа дорожных знаков. */
      id: number;
      /** requestBody */
      body?: PatchedRoadSignGroup;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignGroup> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Группы дорожных знаков
   */
  static roadConstructionsRoadSignGroupsDestroy(
    params: {
      /** A unique integer value identifying this Группа дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_groups/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтипы дорожных знаков
   */
  static roadConstructionsRoadSignSubtypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Тип дорожных знаков" */
      roadSignType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignSubTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_subtypes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_sign_type: params['roadSignType'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтипы дорожных знаков
   */
  static roadConstructionsRoadSignSubtypesCreate(
    params: {
      /** requestBody */
      body?: RoadSignSubType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignSubType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_subtypes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтипы дорожных знаков
   */
  static roadConstructionsRoadSignSubtypesRetrieve(
    params: {
      /** A unique integer value identifying this Подтип дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignSubType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_subtypes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтипы дорожных знаков
   */
  static roadConstructionsRoadSignSubtypesUpdate(
    params: {
      /** A unique integer value identifying this Подтип дорожных знаков. */
      id: number;
      /** requestBody */
      body?: RoadSignSubType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignSubType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_subtypes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтипы дорожных знаков
   */
  static roadConstructionsRoadSignSubtypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Подтип дорожных знаков. */
      id: number;
      /** requestBody */
      body?: PatchedRoadSignSubType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignSubType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_subtypes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подтипы дорожных знаков
   */
  static roadConstructionsRoadSignSubtypesDestroy(
    params: {
      /** A unique integer value identifying this Подтип дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_subtypes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожных знаков
   */
  static roadConstructionsRoadSignTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Тип */
      groupType?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Номер */
      number?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Приоритет */
      priority?: number;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        group_type: params['groupType'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        number: params['number'],
        ordering: params['ordering'],
        priority: params['priority'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожных знаков
   */
  static roadConstructionsRoadSignTypesCreate(
    params: {
      /** requestBody */
      body?: RoadSignType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожных знаков
   */
  static roadConstructionsRoadSignTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожных знаков
   */
  static roadConstructionsRoadSignTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип дорожных знаков. */
      id: number;
      /** requestBody */
      body?: RoadSignType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожных знаков
   */
  static roadConstructionsRoadSignTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип дорожных знаков. */
      id: number;
      /** requestBody */
      body?: PatchedRoadSignType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожных знаков
   */
  static roadConstructionsRoadSignTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип дорожных знаков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_sign_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные знаки
   */
  static roadConstructionsRoadSignsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Группа дорожных знаков" */
      roadSignGroup?: string;
      /** Фильтр по полям модели "Подтип дорожных знаков" */
      roadSignSubtype?: string;
      /** Фильтр по полям модели "Тип дорожных знаков" */
      roadSignType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_sign_group: params['roadSignGroup'],
        road_sign_subtype: params['roadSignSubtype'],
        road_sign_type: params['roadSignType'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные знаки
   */
  static roadConstructionsRoadSignsCreate(
    params: {
      /** requestBody */
      body?: RoadSign;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSign> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные знаки
   */
  static roadConstructionsRoadSignsRetrieve(
    params: {
      /** A unique integer value identifying this Дорожный знак. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSign> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные знаки
   */
  static roadConstructionsRoadSignsUpdate(
    params: {
      /** A unique integer value identifying this Дорожный знак. */
      id: number;
      /** requestBody */
      body?: RoadSign;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSign> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные знаки
   */
  static roadConstructionsRoadSignsPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорожный знак. */
      id: number;
      /** requestBody */
      body?: PatchedRoadSign;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSign> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные знаки
   */
  static roadConstructionsRoadSignsDestroy(
    params: {
      /** A unique integer value identifying this Дорожный знак. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные знаки
   */
  static roadConstructionsRoadSignsProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Группа дорожных знаков" */
      roadSignGroup?: string;
      /** Фильтр по полям модели "Подтип дорожных знаков" */
      roadSignSubtype?: string;
      /** Фильтр по полям модели "Тип дорожных знаков" */
      roadSignType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_sign_group: params['roadSignGroup'],
        road_sign_subtype: params['roadSignSubtype'],
        road_sign_type: params['roadSignType'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные знаки
   */
  static roadConstructionsRoadSignsProjectCreate(
    params: {
      /** requestBody */
      body?: RoadSignProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные знаки
   */
  static roadConstructionsRoadSignsProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемый дорожный знак. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные знаки
   */
  static roadConstructionsRoadSignsProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый дорожный знак. */
      id: number;
      /** requestBody */
      body?: RoadSignProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные знаки
   */
  static roadConstructionsRoadSignsProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый дорожный знак. */
      id: number;
      /** requestBody */
      body?: PatchedRoadSignProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSignProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные знаки
   */
  static roadConstructionsRoadSignsProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемый дорожный знак. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/road_signs_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые искуственные неровности
   */
  static roadConstructionsSpeedBumpProjectList(
    params: {
      /** Адрес */
      address?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBumpProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bump_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые искуственные неровности
   */
  static roadConstructionsSpeedBumpProjectCreate(
    params: {
      /** requestBody */
      body?: SpeedBumpProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBumpProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bump_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые искуственные неровности
   */
  static roadConstructionsSpeedBumpProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемая искуственная неровность. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBumpProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bump_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые искуственные неровности
   */
  static roadConstructionsSpeedBumpProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая искуственная неровность. */
      id: number;
      /** requestBody */
      body?: SpeedBumpProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBumpProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bump_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые искуственные неровности
   */
  static roadConstructionsSpeedBumpProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая искуственная неровность. */
      id: number;
      /** requestBody */
      body?: PatchedSpeedBumpProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBumpProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bump_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые искуственные неровности
   */
  static roadConstructionsSpeedBumpProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемая искуственная неровность. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bump_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Искуственные неровности
   */
  static roadConstructionsSpeedBumpsList(
    params: {
      /** Адрес */
      address?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBumpList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bumps/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        address: params['address'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Искуственные неровности
   */
  static roadConstructionsSpeedBumpsCreate(
    params: {
      /** requestBody */
      body?: SpeedBump;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBump> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bumps/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Искуственные неровности
   */
  static roadConstructionsSpeedBumpsRetrieve(
    params: {
      /** A unique integer value identifying this Искуственная неровность. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBump> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bumps/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Искуственные неровности
   */
  static roadConstructionsSpeedBumpsUpdate(
    params: {
      /** A unique integer value identifying this Искуственная неровность. */
      id: number;
      /** requestBody */
      body?: SpeedBump;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBump> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bumps/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Искуственные неровности
   */
  static roadConstructionsSpeedBumpsPartialUpdate(
    params: {
      /** A unique integer value identifying this Искуственная неровность. */
      id: number;
      /** requestBody */
      body?: PatchedSpeedBump;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SpeedBump> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bumps/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Искуственные неровности
   */
  static roadConstructionsSpeedBumpsDestroy(
    params: {
      /** A unique integer value identifying this Искуственная неровность. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/speed_bumps/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые опоры освещения
   */
  static roadConstructionsStreetLampProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LightingProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/street_lamp_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые опоры освещения
   */
  static roadConstructionsStreetLampProjectCreate(
    params: {
      /** requestBody */
      body?: LightingProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LightingProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/street_lamp_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые опоры освещения
   */
  static roadConstructionsStreetLampProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемая опора освещения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LightingProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/street_lamp_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые опоры освещения
   */
  static roadConstructionsStreetLampProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая опора освещения. */
      id: number;
      /** requestBody */
      body?: LightingProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LightingProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/street_lamp_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые опоры освещения
   */
  static roadConstructionsStreetLampProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая опора освещения. */
      id: number;
      /** requestBody */
      body?: PatchedLightingProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LightingProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/street_lamp_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые опоры освещения
   */
  static roadConstructionsStreetLampProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемая опора освещения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/street_lamp_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подпорные стены
   */
  static roadConstructionsSupportWallsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Материал */
      material?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportWallList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/support_walls/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        material: params['material'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подпорные стены
   */
  static roadConstructionsSupportWallsCreate(
    params: {
      /** requestBody */
      body?: SupportWall;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportWall> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/support_walls/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подпорные стены
   */
  static roadConstructionsSupportWallsRetrieve(
    params: {
      /** A unique integer value identifying this Подпорная стена. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportWall> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/support_walls/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подпорные стены
   */
  static roadConstructionsSupportWallsUpdate(
    params: {
      /** A unique integer value identifying this Подпорная стена. */
      id: number;
      /** requestBody */
      body?: SupportWall;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportWall> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/support_walls/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подпорные стены
   */
  static roadConstructionsSupportWallsPartialUpdate(
    params: {
      /** A unique integer value identifying this Подпорная стена. */
      id: number;
      /** requestBody */
      body?: PatchedSupportWall;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SupportWall> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/support_walls/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подпорные стены
   */
  static roadConstructionsSupportWallsDestroy(
    params: {
      /** A unique integer value identifying this Подпорная стена. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/support_walls/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsTrafficLightFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Светофор" */
      roadConsObject?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_light_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_cons_object: params['roadConsObject'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsTrafficLightFilesCreate(
    params: {
      /** requestBody */
      body?: TrafficLightsFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_light_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsTrafficLightFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_light_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsTrafficLightFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: TrafficLightsFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_light_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsTrafficLightFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: PatchedTrafficLightsFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_light_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadConstructionsTrafficLightFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_light_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофоры
   */
  static roadConstructionsTrafficLightsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофоры
   */
  static roadConstructionsTrafficLightsCreate(
    params: {
      /** requestBody */
      body?: TrafficLights;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLights> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофоры
   */
  static roadConstructionsTrafficLightsRetrieve(
    params: {
      /** A unique integer value identifying this Светофор. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLights> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофоры
   */
  static roadConstructionsTrafficLightsUpdate(
    params: {
      /** A unique integer value identifying this Светофор. */
      id: number;
      /** requestBody */
      body?: TrafficLights;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLights> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофоры
   */
  static roadConstructionsTrafficLightsPartialUpdate(
    params: {
      /** A unique integer value identifying this Светофор. */
      id: number;
      /** requestBody */
      body?: PatchedTrafficLights;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLights> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофоры
   */
  static roadConstructionsTrafficLightsDestroy(
    params: {
      /** A unique integer value identifying this Светофор. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые светофоры
   */
  static roadConstructionsTrafficLightsProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые светофоры
   */
  static roadConstructionsTrafficLightsProjectCreate(
    params: {
      /** requestBody */
      body?: TrafficLightsProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые светофоры
   */
  static roadConstructionsTrafficLightsProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемый светофор. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые светофоры
   */
  static roadConstructionsTrafficLightsProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый светофор. */
      id: number;
      /** requestBody */
      body?: TrafficLightsProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые светофоры
   */
  static roadConstructionsTrafficLightsProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый светофор. */
      id: number;
      /** requestBody */
      body?: PatchedTrafficLightsProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficLightsProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые светофоры
   */
  static roadConstructionsTrafficLightsProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемый светофор. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_constructions/traffic_lights_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoadWorksService {
  /**
   * Дорожные работы
   */
  static roadWorksList(options: IRequestOptions = {}): Promise<RoadWorkList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные работы
   */
  static roadWorksCreate(
    params: {
      /** requestBody */
      body?: RoadWork;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWork> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные работы
   */
  static roadWorksRetrieve(
    params: {
      /** A unique integer value identifying this Дорожные работы. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWork> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные работы
   */
  static roadWorksUpdate(
    params: {
      /** A unique integer value identifying this Дорожные работы. */
      id: number;
      /** requestBody */
      body?: RoadWork;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWork> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные работы
   */
  static roadWorksPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорожные работы. */
      id: number;
      /** requestBody */
      body?: PatchedRoadWork;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWork> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожные работы
   */
  static roadWorksDestroy(
    params: {
      /** A unique integer value identifying this Дорожные работы. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проекты организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорога" */
      road?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        road: params['road'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проекты организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsCreate(
    params: {
      /** requestBody */
      body?: TrafficManagementProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проекты организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsRetrieve(
    params: {
      /** A unique integer value identifying this ('Проект организации дорожного движения',). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проекты организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsUpdate(
    params: {
      /** A unique integer value identifying this ('Проект организации дорожного движения',). */
      id: number;
      /** requestBody */
      body?: TrafficManagementProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проекты организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsPartialUpdate(
    params: {
      /** A unique integer value identifying this ('Проект организации дорожного движения',). */
      id: number;
      /** requestBody */
      body?: PatchedTrafficManagementProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проекты организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsDestroy(
    params: {
      /** A unique integer value identifying this ('Проект организации дорожного движения',). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы проектов организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Имя файла */
      fileName?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "('Проект организации дорожного движения',)" */
      trafficManagementProject?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProjectFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        file_name: params['fileName'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
        traffic_management_project: params['trafficManagementProject'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы проектов организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsFilesCreate(
    params: {
      /** requestBody */
      body?: TrafficManagementProjectFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProjectFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы проектов организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsFilesRetrieve(
    params: {
      /** A unique integer value identifying this ('Файл проекта организации дорожного движения',). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProjectFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы проектов организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsFilesUpdate(
    params: {
      /** A unique integer value identifying this ('Файл проекта организации дорожного движения',). */
      id: number;
      /** requestBody */
      body?: TrafficManagementProjectFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProjectFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы проектов организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this ('Файл проекта организации дорожного движения',). */
      id: number;
      /** requestBody */
      body?: PatchedTrafficManagementProjectFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficManagementProjectFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы проектов организации дорожного движения
   */
  static roadWorksTrafficManagementProjectsFilesDestroy(
    params: {
      /** A unique integer value identifying this ('Файл проекта организации дорожного движения',). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_management_projects_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Наименование объекта проектирования */
      projectingObjectName?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Улица */
      streetName?: string;
      /** Дата загрузки */
      uploadDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchemaList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        projecting_object_name: params['projectingObjectName'],
        representation: params['representation'],
        street_name: params['streetName'],
        upload_date: params['uploadDate'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaCreate(
    params: {
      /** requestBody */
      body?: TrafficOrganizationSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaRetrieve(
    params: {
      /** A unique integer value identifying this Схема организации движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaUpdate(
    params: {
      /** A unique integer value identifying this Схема организации движения. */
      id: number;
      /** requestBody */
      body?: TrafficOrganizationSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaPartialUpdate(
    params: {
      /** A unique integer value identifying this Схема организации движения. */
      id: number;
      /** requestBody */
      body?: PatchedTrafficOrganizationSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaDestroy(
    params: {
      /** A unique integer value identifying this Схема организации движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Имя файла */
      fileName?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Схема организации движения" */
      trafficOrganizationSchema?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchemaFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        file_name: params['fileName'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
        traffic_organization_schema: params['trafficOrganizationSchema'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaFilesCreate(
    params: {
      /** requestBody */
      body?: TrafficOrganizationSchemaFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchemaFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл схемы организации движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchemaFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл схемы организации движения. */
      id: number;
      /** requestBody */
      body?: TrafficOrganizationSchemaFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchemaFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл схемы организации движения. */
      id: number;
      /** requestBody */
      body?: PatchedTrafficOrganizationSchemaFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TrafficOrganizationSchemaFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы схемы организации движения
   */
  static roadWorksTrafficOrganizationSchemaFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл схемы организации движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/traffic_organization_schema_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Характер работ
   */
  static roadWorksWorkCategoriesList(options: IRequestOptions = {}): Promise<WorkCategoryList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/work_categories/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Характер работ
   */
  static roadWorksWorkCategoriesCreate(
    params: {
      /** requestBody */
      body?: WorkCategory;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/work_categories/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Характер работ
   */
  static roadWorksWorkCategoriesRetrieve(
    params: {
      /** A unique integer value identifying this Характер работ. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/work_categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Характер работ
   */
  static roadWorksWorkCategoriesUpdate(
    params: {
      /** A unique integer value identifying this Характер работ. */
      id: number;
      /** requestBody */
      body?: WorkCategory;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/work_categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Характер работ
   */
  static roadWorksWorkCategoriesPartialUpdate(
    params: {
      /** A unique integer value identifying this Характер работ. */
      id: number;
      /** requestBody */
      body?: PatchedWorkCategory;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/work_categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Характер работ
   */
  static roadWorksWorkCategoriesDestroy(
    params: {
      /** A unique integer value identifying this Характер работ. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/road_works/work_categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoadsService {
  /**
   * Дороги
   */
  static roadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Классификация */
      importance?: string;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        importance: params['importance'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги
   */
  static roadsCreate(
    params: {
      /** requestBody */
      body?: Road;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Road> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги
   */
  static roadsRetrieve(
    params: {
      /** A unique integer value identifying this Дорога. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Road> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги
   */
  static roadsUpdate(
    params: {
      /** A unique integer value identifying this Дорога. */
      id: number;
      /** requestBody */
      body?: Road;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Road> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги
   */
  static roadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорога. */
      id: number;
      /** requestBody */
      body?: PatchedRoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Road> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги
   */
  static roadsDestroy(
    params: {
      /** A unique integer value identifying this Дорога. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рекламные конструкции
   */
  static roadsAdvertshieldList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdvertShieldList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/advertshield/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рекламные конструкции
   */
  static roadsAdvertshieldCreate(
    params: {
      /** requestBody */
      body?: AdvertShield;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdvertShield> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/advertshield/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рекламные конструкции
   */
  static roadsAdvertshieldRetrieve(
    params: {
      /** A unique integer value identifying this Рекламная конструкция. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdvertShield> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/advertshield/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рекламные конструкции
   */
  static roadsAdvertshieldUpdate(
    params: {
      /** A unique integer value identifying this Рекламная конструкция. */
      id: number;
      /** requestBody */
      body?: AdvertShield;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdvertShield> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/advertshield/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рекламные конструкции
   */
  static roadsAdvertshieldPartialUpdate(
    params: {
      /** A unique integer value identifying this Рекламная конструкция. */
      id: number;
      /** requestBody */
      body?: PatchedAdvertShield;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdvertShield> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/advertshield/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рекламные конструкции
   */
  static roadsAdvertshieldDestroy(
    params: {
      /** A unique integer value identifying this Рекламная конструкция. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/advertshield/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Радиусы кривых в плане
   */
  static roadsAxisRadiusList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisRadiusList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_radius/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Радиусы кривых в плане
   */
  static roadsAxisRadiusCreate(
    params: {
      /** requestBody */
      body?: AxisRadius;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisRadius> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_radius/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Радиусы кривых в плане
   */
  static roadsAxisRadiusRetrieve(
    params: {
      /** A unique integer value identifying this Радиус кривых в плане. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisRadius> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_radius/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Радиусы кривых в плане
   */
  static roadsAxisRadiusUpdate(
    params: {
      /** A unique integer value identifying this Радиус кривых в плане. */
      id: number;
      /** requestBody */
      body?: AxisRadius;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisRadius> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_radius/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Радиусы кривых в плане
   */
  static roadsAxisRadiusPartialUpdate(
    params: {
      /** A unique integer value identifying this Радиус кривых в плане. */
      id: number;
      /** requestBody */
      body?: PatchedAxisRadius;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisRadius> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_radius/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Радиусы кривых в плане
   */
  static roadsAxisRadiusDestroy(
    params: {
      /** A unique integer value identifying this Радиус кривых в плане. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_radius/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольные уклоны
   */
  static roadsAxisSlopeList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisSlopeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_slope/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольные уклоны
   */
  static roadsAxisSlopeCreate(
    params: {
      /** requestBody */
      body?: AxisSlope;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisSlope> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_slope/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольные уклоны
   */
  static roadsAxisSlopeRetrieve(
    params: {
      /** A unique integer value identifying this Продольный уклон. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisSlope> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_slope/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольные уклоны
   */
  static roadsAxisSlopeUpdate(
    params: {
      /** A unique integer value identifying this Продольный уклон. */
      id: number;
      /** requestBody */
      body?: AxisSlope;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisSlope> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_slope/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольные уклоны
   */
  static roadsAxisSlopePartialUpdate(
    params: {
      /** A unique integer value identifying this Продольный уклон. */
      id: number;
      /** requestBody */
      body?: PatchedAxisSlope;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisSlope> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_slope/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольные уклоны
   */
  static roadsAxisSlopeDestroy(
    params: {
      /** A unique integer value identifying this Продольный уклон. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_slope/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расстояния видимости
   */
  static roadsAxisVisibilityList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Направление */
      direction?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisVisibilityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_visibility/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        direction: params['direction'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расстояния видимости
   */
  static roadsAxisVisibilityCreate(
    params: {
      /** requestBody */
      body?: AxisVisibility;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisVisibility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_visibility/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расстояния видимости
   */
  static roadsAxisVisibilityRetrieve(
    params: {
      /** A unique integer value identifying this Расстояние видимости. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisVisibility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_visibility/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расстояния видимости
   */
  static roadsAxisVisibilityUpdate(
    params: {
      /** A unique integer value identifying this Расстояние видимости. */
      id: number;
      /** requestBody */
      body?: AxisVisibility;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisVisibility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_visibility/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расстояния видимости
   */
  static roadsAxisVisibilityPartialUpdate(
    params: {
      /** A unique integer value identifying this Расстояние видимости. */
      id: number;
      /** requestBody */
      body?: PatchedAxisVisibility;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AxisVisibility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_visibility/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расстояния видимости
   */
  static roadsAxisVisibilityDestroy(
    params: {
      /** A unique integer value identifying this Расстояние видимости. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/axis_visibility/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шкафы для кабельных коммуникаций
   */
  static roadsCabinetsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CabinetForCableCommunicationsList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cabinets/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шкафы для кабельных коммуникаций
   */
  static roadsCabinetsCreate(
    params: {
      /** requestBody */
      body?: CabinetForCableCommunications;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CabinetForCableCommunications> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cabinets/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шкафы для кабельных коммуникаций
   */
  static roadsCabinetsRetrieve(
    params: {
      /** A unique integer value identifying this Шкаф для кабельных коммуникаций. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CabinetForCableCommunications> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cabinets/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шкафы для кабельных коммуникаций
   */
  static roadsCabinetsUpdate(
    params: {
      /** A unique integer value identifying this Шкаф для кабельных коммуникаций. */
      id: number;
      /** requestBody */
      body?: CabinetForCableCommunications;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CabinetForCableCommunications> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cabinets/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шкафы для кабельных коммуникаций
   */
  static roadsCabinetsPartialUpdate(
    params: {
      /** A unique integer value identifying this Шкаф для кабельных коммуникаций. */
      id: number;
      /** requestBody */
      body?: PatchedCabinetForCableCommunications;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CabinetForCableCommunications> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cabinets/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шкафы для кабельных коммуникаций
   */
  static roadsCabinetsDestroy(
    params: {
      /** A unique integer value identifying this Шкаф для кабельных коммуникаций. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cabinets/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Категории дорог
   */
  static roadsCategoriesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Категория */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadCategoryList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/categories/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Категории дорог
   */
  static roadsCategoriesCreate(
    params: {
      /** requestBody */
      body?: RoadCategory;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/categories/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Категории дорог
   */
  static roadsCategoriesRetrieve(
    params: {
      /** A unique integer value identifying this Категория дорог. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Категории дорог
   */
  static roadsCategoriesUpdate(
    params: {
      /** A unique integer value identifying this Категория дорог. */
      id: number;
      /** requestBody */
      body?: RoadCategory;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Категории дорог
   */
  static roadsCategoriesPartialUpdate(
    params: {
      /** A unique integer value identifying this Категория дорог. */
      id: number;
      /** requestBody */
      body?: PatchedRoadCategory;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadCategory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Категории дорог
   */
  static roadsCategoriesDestroy(
    params: {
      /** A unique integer value identifying this Категория дорог. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/categories/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры воздушной линии связи
   */
  static roadsCommunicationPoleList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationPoleList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/communication_pole/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры воздушной линии связи
   */
  static roadsCommunicationPoleCreate(
    params: {
      /** requestBody */
      body?: CommunicationPole;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/communication_pole/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры воздушной линии связи
   */
  static roadsCommunicationPoleRetrieve(
    params: {
      /** A unique integer value identifying this Опора воздушной линии связи. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/communication_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры воздушной линии связи
   */
  static roadsCommunicationPoleUpdate(
    params: {
      /** A unique integer value identifying this Опора воздушной линии связи. */
      id: number;
      /** requestBody */
      body?: CommunicationPole;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/communication_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры воздушной линии связи
   */
  static roadsCommunicationPolePartialUpdate(
    params: {
      /** A unique integer value identifying this Опора воздушной линии связи. */
      id: number;
      /** requestBody */
      body?: PatchedCommunicationPole;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CommunicationPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/communication_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры воздушной линии связи
   */
  static roadsCommunicationPoleDestroy(
    params: {
      /** A unique integer value identifying this Опора воздушной линии связи. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/communication_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Контуры газонов
   */
  static roadsContoursLawnList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContourLawnList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/contours_lawn/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Контуры газонов
   */
  static roadsContoursLawnCreate(
    params: {
      /** requestBody */
      body?: ContourLawn;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContourLawn> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/contours_lawn/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Контуры газонов
   */
  static roadsContoursLawnRetrieve(
    params: {
      /** A unique integer value identifying this Контур газонов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContourLawn> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/contours_lawn/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Контуры газонов
   */
  static roadsContoursLawnUpdate(
    params: {
      /** A unique integer value identifying this Контур газонов. */
      id: number;
      /** requestBody */
      body?: ContourLawn;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContourLawn> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/contours_lawn/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Контуры газонов
   */
  static roadsContoursLawnPartialUpdate(
    params: {
      /** A unique integer value identifying this Контур газонов. */
      id: number;
      /** requestBody */
      body?: PatchedContourLawn;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContourLawn> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/contours_lawn/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Контуры газонов
   */
  static roadsContoursLawnDestroy(
    params: {
      /** A unique integer value identifying this Контур газонов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/contours_lawn/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Перекрестки
   */
  static roadsCrossRoadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CrossRoadList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cross_roads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Перекрестки
   */
  static roadsCrossRoadsCreate(
    params: {
      /** requestBody */
      body?: CrossRoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CrossRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cross_roads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Перекрестки
   */
  static roadsCrossRoadsRetrieve(
    params: {
      /** A unique integer value identifying this Перекресток. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CrossRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cross_roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Перекрестки
   */
  static roadsCrossRoadsUpdate(
    params: {
      /** A unique integer value identifying this Перекресток. */
      id: number;
      /** requestBody */
      body?: CrossRoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CrossRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cross_roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Перекрестки
   */
  static roadsCrossRoadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Перекресток. */
      id: number;
      /** requestBody */
      body?: PatchedCrossRoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CrossRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cross_roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Перекрестки
   */
  static roadsCrossRoadsDestroy(
    params: {
      /** A unique integer value identifying this Перекресток. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/cross_roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бордюрные камни
   */
  static roadsCurbstoneList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Тип участка */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CurbstoneList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/curbstone/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        type: params['type'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бордюрные камни
   */
  static roadsCurbstoneCreate(
    params: {
      /** requestBody */
      body?: Curbstone;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Curbstone> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/curbstone/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бордюрные камни
   */
  static roadsCurbstoneRetrieve(
    params: {
      /** A unique integer value identifying this Бордюрный камень. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Curbstone> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/curbstone/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бордюрные камни
   */
  static roadsCurbstoneUpdate(
    params: {
      /** A unique integer value identifying this Бордюрный камень. */
      id: number;
      /** requestBody */
      body?: Curbstone;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Curbstone> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/curbstone/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бордюрные камни
   */
  static roadsCurbstonePartialUpdate(
    params: {
      /** A unique integer value identifying this Бордюрный камень. */
      id: number;
      /** requestBody */
      body?: PatchedCurbstone;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Curbstone> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/curbstone/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бордюрные камни
   */
  static roadsCurbstoneDestroy(
    params: {
      /** A unique integer value identifying this Бордюрный камень. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/curbstone/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы разделительных линии
   */
  static roadsDividingLineTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLineTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_line_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы разделительных линии
   */
  static roadsDividingLineTypesCreate(
    params: {
      /** requestBody */
      body?: DividingLineType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_line_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы разделительных линии
   */
  static roadsDividingLineTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип разделительной линии. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы разделительных линии
   */
  static roadsDividingLineTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип разделительной линии. */
      id: number;
      /** requestBody */
      body?: DividingLineType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы разделительных линии
   */
  static roadsDividingLineTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип разделительной линии. */
      id: number;
      /** requestBody */
      body?: PatchedDividingLineType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы разделительных линии
   */
  static roadsDividingLineTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип разделительной линии. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Разделительные полосы
   */
  static roadsDividingLinesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLineList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_lines/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Разделительные полосы
   */
  static roadsDividingLinesCreate(
    params: {
      /** requestBody */
      body?: DividingLine;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_lines/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Разделительные полосы
   */
  static roadsDividingLinesRetrieve(
    params: {
      /** A unique integer value identifying this Разделительная полоса. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Разделительные полосы
   */
  static roadsDividingLinesUpdate(
    params: {
      /** A unique integer value identifying this Разделительная полоса. */
      id: number;
      /** requestBody */
      body?: DividingLine;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Разделительные полосы
   */
  static roadsDividingLinesPartialUpdate(
    params: {
      /** A unique integer value identifying this Разделительная полоса. */
      id: number;
      /** requestBody */
      body?: PatchedDividingLine;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DividingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Разделительные полосы
   */
  static roadsDividingLinesDestroy(
    params: {
      /** A unique integer value identifying this Разделительная полоса. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/dividing_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Откосы земляного полотна
   */
  static roadsEarthbedList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EarthBedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/earthbed/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Откосы земляного полотна
   */
  static roadsEarthbedCreate(
    params: {
      /** requestBody */
      body?: EarthBed;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EarthBed> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/earthbed/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Откосы земляного полотна
   */
  static roadsEarthbedRetrieve(
    params: {
      /** A unique integer value identifying this Откос земляного полотна. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EarthBed> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/earthbed/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Откосы земляного полотна
   */
  static roadsEarthbedUpdate(
    params: {
      /** A unique integer value identifying this Откос земляного полотна. */
      id: number;
      /** requestBody */
      body?: EarthBed;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EarthBed> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/earthbed/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Откосы земляного полотна
   */
  static roadsEarthbedPartialUpdate(
    params: {
      /** A unique integer value identifying this Откос земляного полотна. */
      id: number;
      /** requestBody */
      body?: PatchedEarthBed;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EarthBed> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/earthbed/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Откосы земляного полотна
   */
  static roadsEarthbedDestroy(
    params: {
      /** A unique integer value identifying this Откос земляного полотна. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/earthbed/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры ЛЭП
   */
  static roadsElectricPoleList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectricPoleList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/electric_pole/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры ЛЭП
   */
  static roadsElectricPoleCreate(
    params: {
      /** requestBody */
      body?: ElectricPole;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectricPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/electric_pole/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры ЛЭП
   */
  static roadsElectricPoleRetrieve(
    params: {
      /** A unique integer value identifying this Опора ЛЭП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectricPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/electric_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры ЛЭП
   */
  static roadsElectricPoleUpdate(
    params: {
      /** A unique integer value identifying this Опора ЛЭП. */
      id: number;
      /** requestBody */
      body?: ElectricPole;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectricPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/electric_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры ЛЭП
   */
  static roadsElectricPolePartialUpdate(
    params: {
      /** A unique integer value identifying this Опора ЛЭП. */
      id: number;
      /** requestBody */
      body?: PatchedElectricPole;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ElectricPole> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/electric_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Опоры ЛЭП
   */
  static roadsElectricPoleDestroy(
    params: {
      /** A unique integer value identifying this Опора ЛЭП. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/electric_pole/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Геокодер - адрес преобразовывает в координаты
   */
  static roadsGeocoderCreate(
    params: {
      /** requestBody */
      body?: RequestGeocoder;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseGeocoder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/geocoder/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsImprovementElementsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImprovementElementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/improvement_elements/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsImprovementElementsCreate(
    params: {
      /** requestBody */
      body?: ImprovementElement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImprovementElement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/improvement_elements/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsImprovementElementsRetrieve(
    params: {
      /** A unique integer value identifying this Элемент благоустройства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImprovementElement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/improvement_elements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsImprovementElementsUpdate(
    params: {
      /** A unique integer value identifying this Элемент благоустройства. */
      id: number;
      /** requestBody */
      body?: ImprovementElement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImprovementElement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/improvement_elements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsImprovementElementsPartialUpdate(
    params: {
      /** A unique integer value identifying this Элемент благоустройства. */
      id: number;
      /** requestBody */
      body?: PatchedImprovementElement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImprovementElement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/improvement_elements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsImprovementElementsDestroy(
    params: {
      /** A unique integer value identifying this Элемент благоустройства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/improvement_elements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы надземных газопроводов и теплосетей
   */
  static roadsOvergroundPipesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Тип */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OvergroundPipesList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/overground_pipes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        type: params['type'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы надземных газопроводов и теплосетей
   */
  static roadsOvergroundPipesCreate(
    params: {
      /** requestBody */
      body?: OvergroundPipes;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OvergroundPipes> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/overground_pipes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы надземных газопроводов и теплосетей
   */
  static roadsOvergroundPipesRetrieve(
    params: {
      /** A unique integer value identifying this Трубы надземных газопроводов и теплосетей. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OvergroundPipes> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/overground_pipes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы надземных газопроводов и теплосетей
   */
  static roadsOvergroundPipesUpdate(
    params: {
      /** A unique integer value identifying this Трубы надземных газопроводов и теплосетей. */
      id: number;
      /** requestBody */
      body?: OvergroundPipes;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OvergroundPipes> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/overground_pipes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы надземных газопроводов и теплосетей
   */
  static roadsOvergroundPipesPartialUpdate(
    params: {
      /** A unique integer value identifying this Трубы надземных газопроводов и теплосетей. */
      id: number;
      /** requestBody */
      body?: PatchedOvergroundPipes;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OvergroundPipes> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/overground_pipes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Трубы надземных газопроводов и теплосетей
   */
  static roadsOvergroundPipesDestroy(
    params: {
      /** A unique integer value identifying this Трубы надземных газопроводов и теплосетей. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/overground_pipes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы ограничения парковки
   */
  static roadsParkingPostsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Тип участка */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParkingPostList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/parking_posts/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        type: params['type'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы ограничения парковки
   */
  static roadsParkingPostsCreate(
    params: {
      /** requestBody */
      body?: ParkingPost;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParkingPost> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/parking_posts/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы ограничения парковки
   */
  static roadsParkingPostsRetrieve(
    params: {
      /** A unique integer value identifying this Элемент ограничения парковки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParkingPost> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/parking_posts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы ограничения парковки
   */
  static roadsParkingPostsUpdate(
    params: {
      /** A unique integer value identifying this Элемент ограничения парковки. */
      id: number;
      /** requestBody */
      body?: ParkingPost;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParkingPost> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/parking_posts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы ограничения парковки
   */
  static roadsParkingPostsPartialUpdate(
    params: {
      /** A unique integer value identifying this Элемент ограничения парковки. */
      id: number;
      /** requestBody */
      body?: PatchedParkingPost;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParkingPost> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/parking_posts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы ограничения парковки
   */
  static roadsParkingPostsDestroy(
    params: {
      /** A unique integer value identifying this Элемент ограничения парковки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/parking_posts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Железнодорожные и трамвайные пути
   */
  static roadsRailwayList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Типы железнодорожных путей */
      railwayType?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RailwayList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/railway/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        railway_type: params['railwayType'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Железнодорожные и трамвайные пути
   */
  static roadsRailwayCreate(
    params: {
      /** requestBody */
      body?: Railway;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Railway> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/railway/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Железнодорожные и трамвайные пути
   */
  static roadsRailwayRetrieve(
    params: {
      /** A unique integer value identifying this Железнодорожные и трамвайные пути. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Railway> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/railway/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Железнодорожные и трамвайные пути
   */
  static roadsRailwayUpdate(
    params: {
      /** A unique integer value identifying this Железнодорожные и трамвайные пути. */
      id: number;
      /** requestBody */
      body?: Railway;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Railway> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/railway/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Железнодорожные и трамвайные пути
   */
  static roadsRailwayPartialUpdate(
    params: {
      /** A unique integer value identifying this Железнодорожные и трамвайные пути. */
      id: number;
      /** requestBody */
      body?: PatchedRailway;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Railway> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/railway/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Железнодорожные и трамвайные пути
   */
  static roadsRailwayDestroy(
    params: {
      /** A unique integer value identifying this Железнодорожные и трамвайные пути. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/railway/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Осевые линии
   */
  static roadsRoadAxisesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Наименование участка */
      partName?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAxisList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_axises/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        part_name: params['partName'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Осевые линии
   */
  static roadsRoadAxisesCreate(
    params: {
      /** requestBody */
      body?: RoadAxis;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAxis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_axises/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Осевые линии
   */
  static roadsRoadAxisesRetrieve(
    params: {
      /** A unique integer value identifying this Осевая линия. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAxis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_axises/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Осевые линии
   */
  static roadsRoadAxisesUpdate(
    params: {
      /** A unique integer value identifying this Осевая линия. */
      id: number;
      /** requestBody */
      body?: RoadAxis;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAxis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_axises/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Осевые линии
   */
  static roadsRoadAxisesPartialUpdate(
    params: {
      /** A unique integer value identifying this Осевая линия. */
      id: number;
      /** requestBody */
      body?: PatchedRoadAxis;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadAxis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_axises/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Осевые линии
   */
  static roadsRoadAxisesDestroy(
    params: {
      /** A unique integer value identifying this Осевая линия. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_axises/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожное ограждение
   */
  static roadsRoadFencesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Тип */
      roadFenceType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFenceList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_fence_type: params['roadFenceType'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожное ограждение
   */
  static roadsRoadFencesCreate(
    params: {
      /** requestBody */
      body?: RoadFence;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFence> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожное ограждение
   */
  static roadsRoadFencesRetrieve(
    params: {
      /** A unique integer value identifying this Дорожное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFence> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожное ограждение
   */
  static roadsRoadFencesUpdate(
    params: {
      /** A unique integer value identifying this Дорожное ограждение. */
      id: number;
      /** requestBody */
      body?: RoadFence;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFence> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожное ограждение
   */
  static roadsRoadFencesPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорожное ограждение. */
      id: number;
      /** requestBody */
      body?: PatchedRoadFence;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFence> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожное ограждение
   */
  static roadsRoadFencesDestroy(
    params: {
      /** A unique integer value identifying this Дорожное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные ограждения
   */
  static roadsRoadFencesProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Тип */
      roadFenceType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFenceProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_fence_type: params['roadFenceType'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные ограждения
   */
  static roadsRoadFencesProjectCreate(
    params: {
      /** requestBody */
      body?: RoadFenceProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFenceProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные ограждения
   */
  static roadsRoadFencesProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемое дорожное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFenceProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные ограждения
   */
  static roadsRoadFencesProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемое дорожное ограждение. */
      id: number;
      /** requestBody */
      body?: RoadFenceProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFenceProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные ограждения
   */
  static roadsRoadFencesProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемое дорожное ограждение. */
      id: number;
      /** requestBody */
      body?: PatchedRoadFenceProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFenceProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые дорожные ограждения
   */
  static roadsRoadFencesProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемое дорожное ограждение. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_fences_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingLineFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Линейная дорожная разметка" */
      roadConsObject?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_cons_object: params['roadConsObject'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingLineFilesCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingLineFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingLineFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingLineFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: RoadMarkingLineFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingLineFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingLineFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingLineFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые линейные дорожные разметки
   */
  static roadsRoadMarkingLineProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Тип линейной дорожной разметки" */
      roadMarkingType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_marking_type: params['roadMarkingType'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые линейные дорожные разметки
   */
  static roadsRoadMarkingLineProjectCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingLineProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые линейные дорожные разметки
   */
  static roadsRoadMarkingLineProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемая линейная дорожная разметка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые линейные дорожные разметки
   */
  static roadsRoadMarkingLineProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая линейная дорожная разметка. */
      id: number;
      /** requestBody */
      body?: RoadMarkingLineProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые линейные дорожные разметки
   */
  static roadsRoadMarkingLineProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая линейная дорожная разметка. */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingLineProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые линейные дорожные разметки
   */
  static roadsRoadMarkingLineProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемая линейная дорожная разметка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы линейной дорожной разметки
   */
  static roadsRoadMarkingLineTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы линейной дорожной разметки
   */
  static roadsRoadMarkingLineTypesCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingLineType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы линейной дорожной разметки
   */
  static roadsRoadMarkingLineTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип линейной дорожной разметки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы линейной дорожной разметки
   */
  static roadsRoadMarkingLineTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип линейной дорожной разметки. */
      id: number;
      /** requestBody */
      body?: RoadMarkingLineType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы линейной дорожной разметки
   */
  static roadsRoadMarkingLineTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип линейной дорожной разметки. */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingLineType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы линейной дорожной разметки
   */
  static roadsRoadMarkingLineTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип линейной дорожной разметки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_line_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линейные дорожные разметки
   */
  static roadsRoadMarkingLinesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Тип линейной дорожной разметки" */
      roadMarkingType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLineList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_lines/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_marking_type: params['roadMarkingType'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линейные дорожные разметки
   */
  static roadsRoadMarkingLinesCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingLine;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_lines/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линейные дорожные разметки
   */
  static roadsRoadMarkingLinesRetrieve(
    params: {
      /** A unique integer value identifying this Линейная дорожная разметка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линейные дорожные разметки
   */
  static roadsRoadMarkingLinesUpdate(
    params: {
      /** A unique integer value identifying this Линейная дорожная разметка. */
      id: number;
      /** requestBody */
      body?: RoadMarkingLine;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линейные дорожные разметки
   */
  static roadsRoadMarkingLinesPartialUpdate(
    params: {
      /** A unique integer value identifying this Линейная дорожная разметка. */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingLine;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingLine> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Линейные дорожные разметки
   */
  static roadsRoadMarkingLinesDestroy(
    params: {
      /** A unique integer value identifying this Линейная дорожная разметка. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_lines/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPointFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорожная разметка (Точечная)" */
      roadConsObject?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_point_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_cons_object: params['roadConsObject'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPointFilesCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingPointFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_point_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPointFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_point_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPointFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: RoadMarkingPointFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_point_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPointFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingPointFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_point_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPointFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_point_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Точечная)
   */
  static roadsRoadMarkingPointsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Тип по ГОСТ Р 51256-2018 */
      roadMarkingType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_points/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_marking_type: params['roadMarkingType'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Точечная)
   */
  static roadsRoadMarkingPointsCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingPoint;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPoint> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_points/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Точечная)
   */
  static roadsRoadMarkingPointsRetrieve(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Точечная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPoint> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_points/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Точечная)
   */
  static roadsRoadMarkingPointsUpdate(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Точечная). */
      id: number;
      /** requestBody */
      body?: RoadMarkingPoint;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPoint> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_points/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Точечная)
   */
  static roadsRoadMarkingPointsPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Точечная). */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingPoint;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPoint> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_points/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Точечная)
   */
  static roadsRoadMarkingPointsDestroy(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Точечная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_points/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPolygonFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорожная разметка (Площадная)" */
      roadConsObject?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_cons_object: params['roadConsObject'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPolygonFilesCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingPolygonFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPolygonFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPolygonFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: RoadMarkingPolygonFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPolygonFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingPolygonFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы
   */
  static roadsRoadMarkingPolygonFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектирумая дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Тип по ГОСТ Р 51256-2018 */
      roadMarkingType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_marking_type: params['roadMarkingType'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектирумая дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonProjectCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingPolygonProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектирумая дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектирумая дорожная разметка (Площадная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектирумая дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектирумая дорожная разметка (Площадная). */
      id: number;
      /** requestBody */
      body?: RoadMarkingPolygonProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектирумая дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектирумая дорожная разметка (Площадная). */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingPolygonProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектирумая дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектирумая дорожная разметка (Площадная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygon_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Тип по ГОСТ Р 51256-2018 */
      roadMarkingType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygonList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygons/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_marking_type: params['roadMarkingType'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonsCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingPolygon;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygons/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonsRetrieve(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Площадная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonsUpdate(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Площадная). */
      id: number;
      /** requestBody */
      body?: RoadMarkingPolygon;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonsPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Площадная). */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingPolygon;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPolygon> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дорожная разметка (Площадная)
   */
  static roadsRoadMarkingPolygonsDestroy(
    params: {
      /** A unique integer value identifying this Дорожная разметка (Площадная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_polygons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемая дорожная разметка (Точечная)
   */
  static roadsRoadMarkingSymbolProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Тип по ГОСТ Р 51256-2018 */
      roadMarkingType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_symbol_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road_marking_type: params['roadMarkingType'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемая дорожная разметка (Точечная)
   */
  static roadsRoadMarkingSymbolProjectCreate(
    params: {
      /** requestBody */
      body?: RoadMarkingPointProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_symbol_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемая дорожная разметка (Точечная)
   */
  static roadsRoadMarkingSymbolProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемая дорожная разметка (Точечная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_symbol_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемая дорожная разметка (Точечная)
   */
  static roadsRoadMarkingSymbolProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая дорожная разметка (Точечная). */
      id: number;
      /** requestBody */
      body?: RoadMarkingPointProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_symbol_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемая дорожная разметка (Точечная)
   */
  static roadsRoadMarkingSymbolProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая дорожная разметка (Точечная). */
      id: number;
      /** requestBody */
      body?: PatchedRoadMarkingPointProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadMarkingPointProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_symbol_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемая дорожная разметка (Точечная)
   */
  static roadsRoadMarkingSymbolProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемая дорожная разметка (Точечная). */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/road_marking_symbol_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обочины
   */
  static roadsRoadshoulderList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadShoulderList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadshoulder/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обочины
   */
  static roadsRoadshoulderCreate(
    params: {
      /** requestBody */
      body?: RoadShoulder;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadShoulder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadshoulder/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обочины
   */
  static roadsRoadshoulderRetrieve(
    params: {
      /** A unique integer value identifying this Обочина. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadShoulder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadshoulder/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обочины
   */
  static roadsRoadshoulderUpdate(
    params: {
      /** A unique integer value identifying this Обочина. */
      id: number;
      /** requestBody */
      body?: RoadShoulder;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadShoulder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadshoulder/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обочины
   */
  static roadsRoadshoulderPartialUpdate(
    params: {
      /** A unique integer value identifying this Обочина. */
      id: number;
      /** requestBody */
      body?: PatchedRoadShoulder;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadShoulder> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadshoulder/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обочины
   */
  static roadsRoadshoulderDestroy(
    params: {
      /** A unique integer value identifying this Обочина. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadshoulder/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые проезжие части
   */
  static roadsRoadwayProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWayProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadway_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые проезжие части
   */
  static roadsRoadwayProjectCreate(
    params: {
      /** requestBody */
      body?: RoadWayProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWayProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadway_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые проезжие части
   */
  static roadsRoadwayProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемая проезжая часть. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWayProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadway_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые проезжие части
   */
  static roadsRoadwayProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая проезжая часть. */
      id: number;
      /** requestBody */
      body?: RoadWayProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWayProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadway_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые проезжие части
   */
  static roadsRoadwayProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемая проезжая часть. */
      id: number;
      /** requestBody */
      body?: PatchedRoadWayProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWayProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadway_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые проезжие части
   */
  static roadsRoadwayProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемая проезжая часть. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadway_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проезжие части
   */
  static roadsRoadwaysList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Материал покрытия */
      material?: string;
      /** Количество полос */
      numLanes?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Тип */
      roadwayType?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWayList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadways/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        material: params['material'],
        num_lanes: params['numLanes'],
        ordering: params['ordering'],
        representation: params['representation'],
        roadway_type: params['roadwayType'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проезжие части
   */
  static roadsRoadwaysCreate(
    params: {
      /** requestBody */
      body?: RoadWay;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadways/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проезжие части
   */
  static roadsRoadwaysRetrieve(
    params: {
      /** A unique integer value identifying this Проезжая часть. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проезжие части
   */
  static roadsRoadwaysUpdate(
    params: {
      /** A unique integer value identifying this Проезжая часть. */
      id: number;
      /** requestBody */
      body?: RoadWay;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проезжие части
   */
  static roadsRoadwaysPartialUpdate(
    params: {
      /** A unique integer value identifying this Проезжая часть. */
      id: number;
      /** requestBody */
      body?: PatchedRoadWay;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadWay> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проезжие части
   */
  static roadsRoadwaysDestroy(
    params: {
      /** A unique integer value identifying this Проезжая часть. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/roadways/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузки на ось
   */
  static roadsSegmentAxleLoadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentAxleLoadList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_axle_loads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузки на ось
   */
  static roadsSegmentAxleLoadsCreate(
    params: {
      /** requestBody */
      body?: SegmentAxleLoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentAxleLoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_axle_loads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузки на ось
   */
  static roadsSegmentAxleLoadsRetrieve(
    params: {
      /** A unique integer value identifying this Нагрузка на ось. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentAxleLoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузки на ось
   */
  static roadsSegmentAxleLoadsUpdate(
    params: {
      /** A unique integer value identifying this Нагрузка на ось. */
      id: number;
      /** requestBody */
      body?: SegmentAxleLoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentAxleLoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузки на ось
   */
  static roadsSegmentAxleLoadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Нагрузка на ось. */
      id: number;
      /** requestBody */
      body?: PatchedSegmentAxleLoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentAxleLoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузки на ось
   */
  static roadsSegmentAxleLoadsDestroy(
    params: {
      /** A unique integer value identifying this Нагрузка на ось. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Направление движения
   */
  static roadsSegmentDirectionsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentDirectionOfMovementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_directions/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Направление движения
   */
  static roadsSegmentDirectionsCreate(
    params: {
      /** requestBody */
      body?: SegmentDirectionOfMovement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentDirectionOfMovement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_directions/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Направление движения
   */
  static roadsSegmentDirectionsRetrieve(
    params: {
      /** A unique integer value identifying this Направление движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentDirectionOfMovement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_directions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Направление движения
   */
  static roadsSegmentDirectionsUpdate(
    params: {
      /** A unique integer value identifying this Направление движения. */
      id: number;
      /** requestBody */
      body?: SegmentDirectionOfMovement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentDirectionOfMovement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_directions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Направление движения
   */
  static roadsSegmentDirectionsPartialUpdate(
    params: {
      /** A unique integer value identifying this Направление движения. */
      id: number;
      /** requestBody */
      body?: PatchedSegmentDirectionOfMovement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentDirectionOfMovement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_directions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Направление движения
   */
  static roadsSegmentDirectionsDestroy(
    params: {
      /** A unique integer value identifying this Направление движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_directions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожного покрытия
   */
  static roadsSegmentSurfaceTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSurfaceTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_surface_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожного покрытия
   */
  static roadsSegmentSurfaceTypesCreate(
    params: {
      /** requestBody */
      body?: SegmentSurfaceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSurfaceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_surface_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожного покрытия
   */
  static roadsSegmentSurfaceTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип дорожного покрытия. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSurfaceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_surface_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожного покрытия
   */
  static roadsSegmentSurfaceTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип дорожного покрытия. */
      id: number;
      /** requestBody */
      body?: SegmentSurfaceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSurfaceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_surface_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожного покрытия
   */
  static roadsSegmentSurfaceTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип дорожного покрытия. */
      id: number;
      /** requestBody */
      body?: PatchedSegmentSurfaceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSurfaceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_surface_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы дорожного покрытия
   */
  static roadsSegmentSurfaceTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип дорожного покрытия. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_surface_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ширина дороги
   */
  static roadsSegmentWidthList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentWidthList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_width/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ширина дороги
   */
  static roadsSegmentWidthCreate(
    params: {
      /** requestBody */
      body?: SegmentWidth;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentWidth> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_width/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ширина дороги
   */
  static roadsSegmentWidthRetrieve(
    params: {
      /** A unique integer value identifying this Ширина дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentWidth> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_width/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ширина дороги
   */
  static roadsSegmentWidthUpdate(
    params: {
      /** A unique integer value identifying this Ширина дороги. */
      id: number;
      /** requestBody */
      body?: SegmentWidth;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentWidth> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_width/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ширина дороги
   */
  static roadsSegmentWidthPartialUpdate(
    params: {
      /** A unique integer value identifying this Ширина дороги. */
      id: number;
      /** requestBody */
      body?: PatchedSegmentWidth;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentWidth> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_width/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ширина дороги
   */
  static roadsSegmentWidthDestroy(
    params: {
      /** A unique integer value identifying this Ширина дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segment_width/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки
   */
  static roadsSegmentsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорога" */
      road?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segments/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        road: params['road'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки
   */
  static roadsSegmentsCreate(
    params: {
      /** requestBody */
      body?: Segment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Segment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segments/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки
   */
  static roadsSegmentsRetrieve(
    params: {
      /** A unique integer value identifying this Участок. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Segment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки
   */
  static roadsSegmentsUpdate(
    params: {
      /** A unique integer value identifying this Участок. */
      id: number;
      /** requestBody */
      body?: Segment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Segment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки
   */
  static roadsSegmentsPartialUpdate(
    params: {
      /** A unique integer value identifying this Участок. */
      id: number;
      /** requestBody */
      body?: PatchedSegment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Segment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки
   */
  static roadsSegmentsDestroy(
    params: {
      /** A unique integer value identifying this Участок. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые тротуары
   */
  static roadsSidewalkProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Материал покрытия */
      material?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalkProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalk_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        material: params['material'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые тротуары
   */
  static roadsSidewalkProjectCreate(
    params: {
      /** requestBody */
      body?: SideWalkProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalkProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalk_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые тротуары
   */
  static roadsSidewalkProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемый тротуар. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalkProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalk_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые тротуары
   */
  static roadsSidewalkProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый тротуар. */
      id: number;
      /** requestBody */
      body?: SideWalkProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalkProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalk_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые тротуары
   */
  static roadsSidewalkProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый тротуар. */
      id: number;
      /** requestBody */
      body?: PatchedSideWalkProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalkProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalk_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые тротуары
   */
  static roadsSidewalkProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемый тротуар. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalk_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Тротуары
   */
  static roadsSidewalksList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Материал покрытия */
      material?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalkList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalks/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        material: params['material'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Тротуары
   */
  static roadsSidewalksCreate(
    params: {
      /** requestBody */
      body?: SideWalk;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalk> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalks/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Тротуары
   */
  static roadsSidewalksRetrieve(
    params: {
      /** A unique integer value identifying this Тротуар. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalk> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Тротуары
   */
  static roadsSidewalksUpdate(
    params: {
      /** A unique integer value identifying this Тротуар. */
      id: number;
      /** requestBody */
      body?: SideWalk;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalk> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Тротуары
   */
  static roadsSidewalksPartialUpdate(
    params: {
      /** A unique integer value identifying this Тротуар. */
      id: number;
      /** requestBody */
      body?: PatchedSideWalk;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SideWalk> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Тротуары
   */
  static roadsSidewalksDestroy(
    params: {
      /** A unique integer value identifying this Тротуар. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/sidewalks/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сигнальные столбики
   */
  static roadsSignalBarsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBarList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сигнальные столбики
   */
  static roadsSignalBarsCreate(
    params: {
      /** requestBody */
      body?: SegmentSignalBar;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сигнальные столбики
   */
  static roadsSignalBarsRetrieve(
    params: {
      /** A unique integer value identifying this Сигнальный столбик. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сигнальные столбики
   */
  static roadsSignalBarsUpdate(
    params: {
      /** A unique integer value identifying this Сигнальный столбик. */
      id: number;
      /** requestBody */
      body?: SegmentSignalBar;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сигнальные столбики
   */
  static roadsSignalBarsPartialUpdate(
    params: {
      /** A unique integer value identifying this Сигнальный столбик. */
      id: number;
      /** requestBody */
      body?: PatchedSegmentSignalBar;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBar> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Сигнальные столбики
   */
  static roadsSignalBarsDestroy(
    params: {
      /** A unique integer value identifying this Сигнальный столбик. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые сигнальные столбики
   */
  static roadsSignalBarsProjectList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBarProjectList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars_project/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые сигнальные столбики
   */
  static roadsSignalBarsProjectCreate(
    params: {
      /** requestBody */
      body?: SegmentSignalBarProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBarProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars_project/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые сигнальные столбики
   */
  static roadsSignalBarsProjectRetrieve(
    params: {
      /** A unique integer value identifying this Проектируемый сигнальный столбик. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBarProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые сигнальные столбики
   */
  static roadsSignalBarsProjectUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый сигнальный столбик. */
      id: number;
      /** requestBody */
      body?: SegmentSignalBarProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBarProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые сигнальные столбики
   */
  static roadsSignalBarsProjectPartialUpdate(
    params: {
      /** A unique integer value identifying this Проектируемый сигнальный столбик. */
      id: number;
      /** requestBody */
      body?: PatchedSegmentSignalBarProject;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentSignalBarProject> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Проектируемые сигнальные столбики
   */
  static roadsSignalBarsProjectDestroy(
    params: {
      /** A unique integer value identifying this Проектируемый сигнальный столбик. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/signal_bars_project/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsStreetFurnitureList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Тип */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StreetFurnitureList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/street_furniture/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        type: params['type'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsStreetFurnitureCreate(
    params: {
      /** requestBody */
      body?: StreetFurniture;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StreetFurniture> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/street_furniture/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsStreetFurnitureRetrieve(
    params: {
      /** A unique integer value identifying this Элементы благоустройства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StreetFurniture> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/street_furniture/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsStreetFurnitureUpdate(
    params: {
      /** A unique integer value identifying this Элементы благоустройства. */
      id: number;
      /** requestBody */
      body?: StreetFurniture;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StreetFurniture> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/street_furniture/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsStreetFurniturePartialUpdate(
    params: {
      /** A unique integer value identifying this Элементы благоустройства. */
      id: number;
      /** requestBody */
      body?: PatchedStreetFurniture;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StreetFurniture> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/street_furniture/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Элементы благоустройства
   */
  static roadsStreetFurnitureDestroy(
    params: {
      /** A unique integer value identifying this Элементы благоустройства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/street_furniture/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подсказка для вводимых адресов
   */
  static roadsSuggestAddressCreate(
    params: {
      /** requestBody */
      body?: RequestSuggestAddress;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseSuggestAddress[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/suggest_address/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Площади
   */
  static roadsTownSquaresList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownSquareList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/town_squares/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Площади
   */
  static roadsTownSquaresCreate(
    params: {
      /** requestBody */
      body?: TownSquare;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownSquare> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/town_squares/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Площади
   */
  static roadsTownSquaresRetrieve(
    params: {
      /** A unique integer value identifying this Площадь. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownSquare> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/town_squares/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Площади
   */
  static roadsTownSquaresUpdate(
    params: {
      /** A unique integer value identifying this Площадь. */
      id: number;
      /** requestBody */
      body?: TownSquare;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownSquare> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/town_squares/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Площади
   */
  static roadsTownSquaresPartialUpdate(
    params: {
      /** A unique integer value identifying this Площадь. */
      id: number;
      /** requestBody */
      body?: PatchedTownSquare;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TownSquare> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/town_squares/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Площади
   */
  static roadsTownSquaresDestroy(
    params: {
      /** A unique integer value identifying this Площадь. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/town_squares/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Лотковая система
   */
  static roadsTraySystemsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentTraySystemList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/tray_systems/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Лотковая система
   */
  static roadsTraySystemsCreate(
    params: {
      /** requestBody */
      body?: SegmentTraySystem;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentTraySystem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/tray_systems/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Лотковая система
   */
  static roadsTraySystemsRetrieve(
    params: {
      /** A unique integer value identifying this Лотковая система. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentTraySystem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/tray_systems/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Лотковая система
   */
  static roadsTraySystemsUpdate(
    params: {
      /** A unique integer value identifying this Лотковая система. */
      id: number;
      /** requestBody */
      body?: SegmentTraySystem;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentTraySystem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/tray_systems/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Лотковая система
   */
  static roadsTraySystemsPartialUpdate(
    params: {
      /** A unique integer value identifying this Лотковая система. */
      id: number;
      /** requestBody */
      body?: PatchedSegmentTraySystem;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentTraySystem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/tray_systems/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Лотковая система
   */
  static roadsTraySystemsDestroy(
    params: {
      /** A unique integer value identifying this Лотковая система. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/tray_systems/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры фото- и видеофиксации нарушений ПДД
   */
  static roadsViolationCameraList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolationCameraList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/violation_camera/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры фото- и видеофиксации нарушений ПДД
   */
  static roadsViolationCameraCreate(
    params: {
      /** requestBody */
      body?: ViolationCamera;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolationCamera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/violation_camera/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры фото- и видеофиксации нарушений ПДД
   */
  static roadsViolationCameraRetrieve(
    params: {
      /** A unique integer value identifying this Камера фото- и видеофиксации нарушений ПДД. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolationCamera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/violation_camera/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры фото- и видеофиксации нарушений ПДД
   */
  static roadsViolationCameraUpdate(
    params: {
      /** A unique integer value identifying this Камера фото- и видеофиксации нарушений ПДД. */
      id: number;
      /** requestBody */
      body?: ViolationCamera;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolationCamera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/violation_camera/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры фото- и видеофиксации нарушений ПДД
   */
  static roadsViolationCameraPartialUpdate(
    params: {
      /** A unique integer value identifying this Камера фото- и видеофиксации нарушений ПДД. */
      id: number;
      /** requestBody */
      body?: PatchedViolationCamera;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolationCamera> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/violation_camera/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Камеры фото- и видеофиксации нарушений ПДД
   */
  static roadsViolationCameraDestroy(
    params: {
      /** A unique integer value identifying this Камера фото- и видеофиксации нарушений ПДД. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/violation_camera/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты в зоне треугольников видимости перекрестков
   */
  static roadsVisibilityBlockerList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Участок" */
      segment?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VisibilityBlockerList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/visibility_blocker/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        segment: params['segment'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты в зоне треугольников видимости перекрестков
   */
  static roadsVisibilityBlockerCreate(
    params: {
      /** requestBody */
      body?: VisibilityBlocker;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VisibilityBlocker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/visibility_blocker/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты в зоне треугольников видимости перекрестков
   */
  static roadsVisibilityBlockerRetrieve(
    params: {
      /** A unique integer value identifying this Объект в зоне треугольников видимости перекрестков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VisibilityBlocker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/visibility_blocker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты в зоне треугольников видимости перекрестков
   */
  static roadsVisibilityBlockerUpdate(
    params: {
      /** A unique integer value identifying this Объект в зоне треугольников видимости перекрестков. */
      id: number;
      /** requestBody */
      body?: VisibilityBlocker;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VisibilityBlocker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/visibility_blocker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты в зоне треугольников видимости перекрестков
   */
  static roadsVisibilityBlockerPartialUpdate(
    params: {
      /** A unique integer value identifying this Объект в зоне треугольников видимости перекрестков. */
      id: number;
      /** requestBody */
      body?: PatchedVisibilityBlocker;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VisibilityBlocker> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/visibility_blocker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Объекты в зоне треугольников видимости перекрестков
   */
  static roadsVisibilityBlockerDestroy(
    params: {
      /** A unique integer value identifying this Объект в зоне треугольников видимости перекрестков. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads/visibility_blocker/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoadsQualityService {
  /**
   * Поручения Губернатора
   */
  static roadsQualityAdministrationAssignmentsList(
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора
   */
  static roadsQualityAdministrationAssignmentsCreate(
    params: {
      /** requestBody */
      body?: AdministrationAssignments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignments> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора
   */
  static roadsQualityAdministrationAssignmentsRetrieve(
    params: {
      /** A unique integer value identifying this Поручение Губернатора. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignments> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора
   */
  static roadsQualityAdministrationAssignmentsUpdate(
    params: {
      /** A unique integer value identifying this Поручение Губернатора. */
      id: number;
      /** requestBody */
      body?: AdministrationAssignments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignments> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора
   */
  static roadsQualityAdministrationAssignmentsPartialUpdate(
    params: {
      /** A unique integer value identifying this Поручение Губернатора. */
      id: number;
      /** requestBody */
      body?: PatchedAdministrationAssignments;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignments> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора
   */
  static roadsQualityAdministrationAssignmentsDestroy(
    params: {
      /** A unique integer value identifying this Поручение Губернатора. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прикрепленные файлы к поручению
   */
  static roadsQualityAdministrationAssignmentsFilesList(
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsFilesList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прикрепленные файлы к поручению
   */
  static roadsQualityAdministrationAssignmentsFilesCreate(
    params: {
      /** requestBody */
      body?: AdministrationAssignmentsFiles;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsFiles> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прикрепленные файлы к поручению
   */
  static roadsQualityAdministrationAssignmentsFilesRetrieve(
    params: {
      /** A unique integer value identifying this Прикрепленный файл к поручению. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsFiles> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прикрепленные файлы к поручению
   */
  static roadsQualityAdministrationAssignmentsFilesUpdate(
    params: {
      /** A unique integer value identifying this Прикрепленный файл к поручению. */
      id: number;
      /** requestBody */
      body?: AdministrationAssignmentsFiles;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsFiles> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прикрепленные файлы к поручению
   */
  static roadsQualityAdministrationAssignmentsFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Прикрепленный файл к поручению. */
      id: number;
      /** requestBody */
      body?: PatchedAdministrationAssignmentsFiles;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsFiles> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прикрепленные файлы к поручению
   */
  static roadsQualityAdministrationAssignmentsFilesDestroy(
    params: {
      /** A unique integer value identifying this Прикрепленный файл к поручению. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора/Дороги
   */
  static roadsQualityAdministrationAssignmentsRoadList(
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsRoadList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_road/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора/Дороги
   */
  static roadsQualityAdministrationAssignmentsRoadCreate(
    params: {
      /** requestBody */
      body?: AdministrationAssignmentsRoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_road/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора/Дороги
   */
  static roadsQualityAdministrationAssignmentsRoadRetrieve(
    params: {
      /** A unique integer value identifying this Поручение Губернатора/Дорога. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_road/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора/Дороги
   */
  static roadsQualityAdministrationAssignmentsRoadUpdate(
    params: {
      /** A unique integer value identifying this Поручение Губернатора/Дорога. */
      id: number;
      /** requestBody */
      body?: AdministrationAssignmentsRoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_road/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора/Дороги
   */
  static roadsQualityAdministrationAssignmentsRoadPartialUpdate(
    params: {
      /** A unique integer value identifying this Поручение Губернатора/Дорога. */
      id: number;
      /** requestBody */
      body?: PatchedAdministrationAssignmentsRoad;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdministrationAssignmentsRoad> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_road/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поручения Губернатора/Дороги
   */
  static roadsQualityAdministrationAssignmentsRoadDestroy(
    params: {
      /** A unique integer value identifying this Поручение Губернатора/Дорога. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/administration_assignments_road/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static roadsQualityAnalysisCriticalSegmentsCreate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/analysis_critical_segments/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты значимости параметров
   */
  static roadsQualityCoefficientsList(options: IRequestOptions = {}): Promise<RoadQualityCoefficientsList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/coefficients/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты значимости параметров
   */
  static roadsQualityCoefficientsCreate(
    params: {
      /** requestBody */
      body?: RoadQualityCoefficients;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadQualityCoefficients> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/coefficients/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты значимости параметров
   */
  static roadsQualityCoefficientsRetrieve(
    params: {
      /** A unique integer value identifying this Коэффициенты значимости параметров. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadQualityCoefficients> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/coefficients/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты значимости параметров
   */
  static roadsQualityCoefficientsUpdate(
    params: {
      /** A unique integer value identifying this Коэффициенты значимости параметров. */
      id: number;
      /** requestBody */
      body?: RoadQualityCoefficients;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadQualityCoefficients> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/coefficients/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты значимости параметров
   */
  static roadsQualityCoefficientsPartialUpdate(
    params: {
      /** A unique integer value identifying this Коэффициенты значимости параметров. */
      id: number;
      /** requestBody */
      body?: PatchedRoadQualityCoefficients;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadQualityCoefficients> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/coefficients/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты значимости параметров
   */
  static roadsQualityCoefficientsDestroy(
    params: {
      /** A unique integer value identifying this Коэффициенты значимости параметров. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/coefficients/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния дорожной одежды и покрытия
   */
  static roadsQualityConditionOfPavementList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConditionOfPavementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/condition_of_pavement/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния дорожной одежды и покрытия
   */
  static roadsQualityConditionOfPavementCreate(
    params: {
      /** requestBody */
      body?: ConditionOfPavement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConditionOfPavement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/condition_of_pavement/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния дорожной одежды и покрытия
   */
  static roadsQualityConditionOfPavementRetrieve(
    params: {
      /** A unique integer value identifying this Состояние дорожной одежды и покрытия. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConditionOfPavement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/condition_of_pavement/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния дорожной одежды и покрытия
   */
  static roadsQualityConditionOfPavementUpdate(
    params: {
      /** A unique integer value identifying this Состояние дорожной одежды и покрытия. */
      id: number;
      /** requestBody */
      body?: ConditionOfPavement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConditionOfPavement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/condition_of_pavement/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния дорожной одежды и покрытия
   */
  static roadsQualityConditionOfPavementPartialUpdate(
    params: {
      /** A unique integer value identifying this Состояние дорожной одежды и покрытия. */
      id: number;
      /** requestBody */
      body?: PatchedConditionOfPavement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConditionOfPavement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/condition_of_pavement/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния дорожной одежды и покрытия
   */
  static roadsQualityConditionOfPavementDestroy(
    params: {
      /** A unique integer value identifying this Состояние дорожной одежды и покрытия. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/condition_of_pavement/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static roadsQualityFileParserCreate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/file_parser/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициент сцепления дороги
   */
  static roadsQualityRoadClutchQualityList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      yearOfQuality?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadClutchQualityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_clutch_quality/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year_of_quality: params['yearOfQuality'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициент сцепления дороги
   */
  static roadsQualityRoadClutchQualityRetrieve(
    params: {
      /** A unique integer value identifying this Коэффициент сцепления дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadClutchQuality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_clutch_quality/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициент сцепления дороги по полосам
   */
  static roadsQualityRoadClutchQualityByLaneList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadClutchQualityByLaneList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_clutch_quality_by_lane/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициент сцепления дороги по полосам
   */
  static roadsQualityRoadClutchQualityByLaneRetrieve(
    params: {
      /** A unique integer value identifying this Коэффициент сцепления дороги по полосам. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadClutchQualityByLane> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_clutch_quality_by_lane/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициент сцепления дороги
   */
  static roadsQualityRoadClutchQualityReprList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadClutchQualityRepresentationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_clutch_quality_repr/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year: params['year'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициент сцепления дороги
   */
  static roadsQualityRoadClutchQualityReprRetrieve(
    params: {
      /** A unique integer value identifying this Коэффициент сцепления дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadClutchQualityRepresentation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_clutch_quality_repr/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комплексный показатель КПд дороги
   */
  static roadsQualityRoadComplexTypeQualityList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      yearOfQuality?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadComplexTypeQualityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_complex_type_quality/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year_of_quality: params['yearOfQuality'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комплексный показатель КПд дороги
   */
  static roadsQualityRoadComplexTypeQualityRetrieve(
    params: {
      /** A unique integer value identifying this Комплексный показатель КПд дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadComplexTypeQuality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_complex_type_quality/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комплексный показатель КПд дороги
   */
  static roadsQualityRoadComplexTypeQualityReprList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadComplexTypeQualityRepresentationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_complex_type_quality_repr/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year: params['year'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Комплексный показатель КПд дороги
   */
  static roadsQualityRoadComplexTypeQualityReprRetrieve(
    params: {
      /** A unique integer value identifying this Комплексный показатель КПд дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadComplexTypeQualityRepresentation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_complex_type_quality_repr/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ровность покрытия дороги
   */
  static roadsQualityRoadFlatnessQualityList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      yearOfQuality?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFlatnessQualityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_flatness_quality/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year_of_quality: params['yearOfQuality'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ровность покрытия дороги
   */
  static roadsQualityRoadFlatnessQualityRetrieve(
    params: {
      /** A unique integer value identifying this Ровность покрытия дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFlatnessQuality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_flatness_quality/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольная ровность покрытия дороги по полосам
   */
  static roadsQualityRoadFlatnessQualityByLaneList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFlatnessQualityByLaneList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_flatness_quality_by_lane/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Продольная ровность покрытия дороги по полосам
   */
  static roadsQualityRoadFlatnessQualityByLaneRetrieve(
    params: {
      /** A unique integer value identifying this Продольная ровность покрытия дороги по полосам. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFlatnessQualityByLane> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_flatness_quality_by_lane/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ровность покрытия дороги
   */
  static roadsQualityRoadFlatnessQualityReprList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFlatnessQualityRepresentationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_flatness_quality_repr/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year: params['year'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ровность покрытия дороги
   */
  static roadsQualityRoadFlatnessQualityReprRetrieve(
    params: {
      /** A unique integer value identifying this Ровность покрытия дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadFlatnessQualityRepresentation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_flatness_quality_repr/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глубина колеи дороги
   */
  static roadsQualityRoadRutQualityList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      yearOfQuality?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadRutQualityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_rut_quality/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year_of_quality: params['yearOfQuality'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глубина колеи дороги
   */
  static roadsQualityRoadRutQualityRetrieve(
    params: {
      /** A unique integer value identifying this Глубина колеи дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadRutQuality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_rut_quality/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поперечная ровность покрытия (колейность) дороги по полосам
   */
  static roadsQualityRoadRutQualityByLaneList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadRutQualityByLaneList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_rut_quality_by_lane/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Поперечная ровность покрытия (колейность) дороги по полосам
   */
  static roadsQualityRoadRutQualityByLaneRetrieve(
    params: {
      /** A unique integer value identifying this Поперечная ровность покрытия (колейность) дороги по полосам. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadRutQualityByLane> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_rut_quality_by_lane/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глубина колеи дороги
   */
  static roadsQualityRoadRutQualityReprList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadRutQualityRepresentationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_rut_quality_repr/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year: params['year'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глубина колеи дороги
   */
  static roadsQualityRoadRutQualityReprRetrieve(
    params: {
      /** A unique integer value identifying this Глубина колеи дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadRutQualityRepresentation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_rut_quality_repr/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прочность дороги
   */
  static roadsQualityRoadStrengthQualityList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      yearOfQuality?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadStrengthQualityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_strength_quality/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year_of_quality: params['yearOfQuality'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прочность дороги
   */
  static roadsQualityRoadStrengthQualityRetrieve(
    params: {
      /** A unique integer value identifying this Прочность дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadStrengthQuality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_strength_quality/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прочность дороги
   */
  static roadsQualityRoadStrengthQualityReprList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Год диагностики */
      year?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadStrengthQualityRepresentationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_strength_quality_repr/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
        year: params['year'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Прочность дороги
   */
  static roadsQualityRoadStrengthQualityReprRetrieve(
    params: {
      /** A unique integer value identifying this Прочность дороги. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadStrengthQualityRepresentation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_strength_quality_repr/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояние покрытия и модуля упругости
   */
  static roadsQualityRoadSurfaceConditionQualityList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSurfaceConditionQualityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_surface_condition_quality/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояние покрытия и модуля упругости
   */
  static roadsQualityRoadSurfaceConditionQualityRetrieve(
    params: {
      /** A unique integer value identifying this Состояние покрытия и модуля упругости. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadSurfaceConditionQuality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/road_surface_condition_quality/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аналитические данные сегмента
   */
  static roadsQualitySegmentAnalysisValuesList(options: IRequestOptions = {}): Promise<SegmentAnalysisValuesList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/segment_analysis_values/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Аналитические данные сегмента
   */
  static roadsQualitySegmentAnalysisValuesRetrieve(
    params: {
      /** A unique integer value identifying this Аналитические данные сегмента. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SegmentAnalysisValues> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/segment_analysis_values/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * История загрузки файлов для парсинга
   */
  static roadsQualityUploadFilesHistoryList(options: IRequestOptions = {}): Promise<RoadsQualityUploadFileHistoryList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/upload_files_history/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * История загрузки файлов для парсинга
   */
  static roadsQualityUploadFilesHistoryRetrieve(
    params: {
      /** A unique integer value identifying this История загрузки файлов для парсинга. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadsQualityUploadFileHistory> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/upload_files_history/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * История загрузки файлов для парсинга
   */
  static roadsQualityUploadFilesHistoryErrorsList(
    options: IRequestOptions = {}
  ): Promise<RoadsQualityUploadFileHistoryErrorsList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/upload_files_history_errors/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * История загрузки файлов для парсинга
   */
  static roadsQualityUploadFilesHistoryErrorsRetrieve(
    params: {
      /** A unique integer value identifying this История загрузки файлов для парсинга. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoadsQualityUploadFileHistoryErrors> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/upload_files_history_errors/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нарушения гарантийных сроков
   */
  static roadsQualityViolationsWarrantyPeriodList(options: IRequestOptions = {}): Promise<ViolationWarrantyPeriodList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/violations_warranty_period/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нарушения гарантийных сроков
   */
  static roadsQualityViolationsWarrantyPeriodRetrieve(
    params: {
      /** A unique integer value identifying this Нарушение гарантийного срока. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolationWarrantyPeriod> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/violations_warranty_period/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нарушения гарантийных сроков
   */
  static roadsQualityViolationsWarrantyPeriodGenerateViolationsCreate(
    params: {
      /** requestBody */
      body?: ViolationWarrantyPeriod;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ViolationWarrantyPeriod> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/roads_quality/violations_warranty_period/generate_violations/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SchemaService {
  /**
   *
   */
  static schemaRetrieve(
    params: {
      /**  */
      format?: string;
      /**  */
      lang?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/schema/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { format: params['format'], lang: params['lang'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SocialFacilitiesService {
  /**
   * Социальные объекты
   */
  static socialFacilitiesList(options: IRequestOptions = {}): Promise<SocialFacilityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/social_facilities/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Социальные объекты
   */
  static socialFacilitiesCreate(
    params: {
      /** requestBody */
      body?: SocialFacility;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SocialFacility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/social_facilities/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Социальные объекты
   */
  static socialFacilitiesRetrieve(
    params: {
      /** A unique integer value identifying this Социальный объект. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SocialFacility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/social_facilities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Социальные объекты
   */
  static socialFacilitiesUpdate(
    params: {
      /** A unique integer value identifying this Социальный объект. */
      id: number;
      /** requestBody */
      body?: SocialFacility;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SocialFacility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/social_facilities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Социальные объекты
   */
  static socialFacilitiesPartialUpdate(
    params: {
      /** A unique integer value identifying this Социальный объект. */
      id: number;
      /** requestBody */
      body?: PatchedSocialFacility;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SocialFacility> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/social_facilities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Социальные объекты
   */
  static socialFacilitiesDestroy(
    params: {
      /** A unique integer value identifying this Социальный объект. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/social_facilities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SpecialPermitsService {
  /**
   * Коэффициенты для расчета размера вреда в зависимости от федеральных округов
   */
  static specialPermitsCoefForHarmList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoefForCalcHarmList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/coef_for_harm/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты для расчета размера вреда в зависимости от федеральных округов
   */
  static specialPermitsCoefForHarmCreate(
    params: {
      /** requestBody */
      body?: CoefForCalcHarm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoefForCalcHarm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/coef_for_harm/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты для расчета размера вреда в зависимости от федеральных округов
   */
  static specialPermitsCoefForHarmRetrieve(
    params: {
      /** A unique integer value identifying this Коэффициенты для расчета размера вреда в зависимости от федеральных округов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoefForCalcHarm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/coef_for_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты для расчета размера вреда в зависимости от федеральных округов
   */
  static specialPermitsCoefForHarmUpdate(
    params: {
      /** A unique integer value identifying this Коэффициенты для расчета размера вреда в зависимости от федеральных округов. */
      id: number;
      /** requestBody */
      body?: CoefForCalcHarm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoefForCalcHarm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/coef_for_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты для расчета размера вреда в зависимости от федеральных округов
   */
  static specialPermitsCoefForHarmPartialUpdate(
    params: {
      /** A unique integer value identifying this Коэффициенты для расчета размера вреда в зависимости от федеральных округов. */
      id: number;
      /** requestBody */
      body?: PatchedCoefForCalcHarm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoefForCalcHarm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/coef_for_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Коэффициенты для расчета размера вреда в зависимости от федеральных округов
   */
  static specialPermitsCoefForHarmDestroy(
    params: {
      /** A unique integer value identifying this Коэффициенты для расчета размера вреда в зависимости от федеральных округов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/coef_for_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акт об уничтожении
   */
  static specialPermitsDestructionActsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DestructionActList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/destruction_acts/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акт об уничтожении
   */
  static specialPermitsDestructionActsCreate(
    params: {
      /** requestBody */
      body?: DestructionAct;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DestructionAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/destruction_acts/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акт об уничтожении
   */
  static specialPermitsDestructionActsRetrieve(
    params: {
      /** A unique integer value identifying this Акт об уничтожении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DestructionAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/destruction_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акт об уничтожении
   */
  static specialPermitsDestructionActsUpdate(
    params: {
      /** A unique integer value identifying this Акт об уничтожении. */
      id: number;
      /** requestBody */
      body?: DestructionAct;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DestructionAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/destruction_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акт об уничтожении
   */
  static specialPermitsDestructionActsPartialUpdate(
    params: {
      /** A unique integer value identifying this Акт об уничтожении. */
      id: number;
      /** requestBody */
      body?: PatchedDestructionAct;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DestructionAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/destruction_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акт об уничтожении
   */
  static specialPermitsDestructionActsDestroy(
    params: {
      /** A unique integer value identifying this Акт об уничтожении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/destruction_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акт об уничтожении
   */
  static specialPermitsDestructionActsPrintRetrieve(
    params: {
      /** A unique integer value identifying this Акт об уничтожении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DestructionAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/destruction_acts/{id}/print/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsCreate(
    params: {
      /** requestBody */
      body?: EntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsRetrieve(
    params: {
      /** A unique integer value identifying this Заявка Юр.лица. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsUpdate(
    params: {
      /** A unique integer value identifying this Заявка Юр.лица. */
      id: number;
      /** requestBody */
      body?: EntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsPartialUpdate(
    params: {
      /** A unique integer value identifying this Заявка Юр.лица. */
      id: number;
      /** requestBody */
      body?: PatchedEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsDestroy(
    params: {
      /** A unique integer value identifying this Заявка Юр.лица. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsChangeStatusCreate(
    params: {
      /** A unique integer value identifying this Заявка Юр.лица. */
      id: number;
      /** requestBody */
      body?: EntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/{id}/change_status/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsPrintRetrieve(
    params: {
      /** A unique integer value identifying this Заявка Юр.лица. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/{id}/print/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения нагрузок на ось
   */
  static specialPermitsEntityStatementsAxleLoadOverloadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementAxleLoadOverloadList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/axle_load_overloads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения нагрузок на ось
   */
  static specialPermitsEntityStatementsAxleLoadOverloadsRetrieve(
    params: {
      /** A unique integer value identifying this Превышение нагрузки на ось. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementAxleLoadOverload> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/axle_load_overloads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsEntityStatementsBillingsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingEntityStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/billings/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsEntityStatementsBillingsCreate(
    params: {
      /** requestBody */
      body?: BillingEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/billings/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsEntityStatementsBillingsRetrieve(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsEntityStatementsBillingsUpdate(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
      /** requestBody */
      body?: BillingEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsEntityStatementsBillingsPartialUpdate(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
      /** requestBody */
      body?: PatchedBillingEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsEntityStatementsBillingsDestroy(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsEntityStatementsBillingsPrintRetrieve(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/billings/{id}/print/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsEntityStatementsCalcOfHarmList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmEntityStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/calc_of_harm/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsEntityStatementsCalcOfHarmCreate(
    params: {
      /** requestBody */
      body?: CalculationOfHarmEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/calc_of_harm/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsEntityStatementsCalcOfHarmRetrieve(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsEntityStatementsCalcOfHarmUpdate(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
      /** requestBody */
      body?: CalculationOfHarmEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsEntityStatementsCalcOfHarmPartialUpdate(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
      /** requestBody */
      body?: PatchedCalculationOfHarmEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsEntityStatementsCalcOfHarmDestroy(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsEntityStatementsCalcOfHarmCalcCreate(
    params: {
      /** requestBody */
      body?: CalculationOfHarmEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/calc_of_harm/calc/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки Юр.лица
   */
  static specialPermitsEntityStatementsCheckInnRetrieve(options: IRequestOptions = {}): Promise<EntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/check_inn/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по высоте
   */
  static specialPermitsEntityStatementsDimensionsHeightOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementDimensionsHeightOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/dimensions_height_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по высоте
   */
  static specialPermitsEntityStatementsDimensionsHeightOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по высоте. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementDimensionsHeightOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/dimensions_height_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине на участке
   */
  static specialPermitsEntityStatementsDimensionsSegmentWidthOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementDimensionsSegmentWidthOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/dimensions_segment_width_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине на участке
   */
  static specialPermitsEntityStatementsDimensionsSegmentWidthOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по ширине на участке. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementDimensionsSegmentWidthOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/dimensions_segment_width_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине
   */
  static specialPermitsEntityStatementsDimensionsWidthOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementDimensionsWidthOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/dimensions_width_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине
   */
  static specialPermitsEntityStatementsDimensionsWidthOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по ширине. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementDimensionsWidthOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/dimensions_width_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsEntityStatementsFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileEntityStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsEntityStatementsFilesCreate(
    params: {
      /** requestBody */
      body?: FileEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsEntityStatementsFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsEntityStatementsFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
      /** requestBody */
      body?: FileEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsEntityStatementsFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
      /** requestBody */
      body?: PatchedFileEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsEntityStatementsFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов для заявлений
   */
  static specialPermitsEntityStatementsGeneralDimensionsOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Инженерное дорожное сооружение" */
      engineeringConstruction?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementGeneralDimensionsOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/general_dimensions_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        engineering_construction: params['engineeringConstruction'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов для заявлений
   */
  static specialPermitsEntityStatementsGeneralDimensionsOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов для заявления. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityStatementGeneralDimensionsOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/general_dimensions_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsEntityStatementsRoadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорога" */
      road?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityRoadsStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/roads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road: params['road'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsEntityStatementsRoadsCreate(
    params: {
      /** requestBody */
      body?: EntityRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/roads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsEntityStatementsRoadsRetrieve(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsEntityStatementsRoadsUpdate(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
      /** requestBody */
      body?: EntityRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsEntityStatementsRoadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
      /** requestBody */
      body?: PatchedEntityRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsEntityStatementsRoadsDestroy(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsEntityStatementsRoutesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteEntityStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/routes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsEntityStatementsRoutesCreate(
    params: {
      /** requestBody */
      body?: RouteEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/routes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsEntityStatementsRoutesRetrieve(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsEntityStatementsRoutesUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: RouteEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsEntityStatementsRoutesPartialUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: PatchedRouteEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsEntityStatementsRoutesDestroy(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleAxesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Параметры транспорта в заявке для юр.лиц" */
      vehicleParameters?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisEntityStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_axes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        vehicle_parameters: params['vehicleParameters'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleAxesCreate(
    params: {
      /** requestBody */
      body?: VehicleAxisEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_axes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleAxesRetrieve(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для юр.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleAxesUpdate(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для юр.лиц. */
      id: number;
      /** requestBody */
      body?: VehicleAxisEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleAxesPartialUpdate(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для юр.лиц. */
      id: number;
      /** requestBody */
      body?: PatchedVehicleAxisEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleAxesDestroy(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для юр.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleParametersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка Юр.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersEntityStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_parameters/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleParametersCreate(
    params: {
      /** requestBody */
      body?: VehicleParametersEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_parameters/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleParametersRetrieve(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для юр.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleParametersUpdate(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для юр.лиц. */
      id: number;
      /** requestBody */
      body?: VehicleParametersEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleParametersPartialUpdate(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для юр.лиц. */
      id: number;
      /** requestBody */
      body?: PatchedVehicleParametersEntityStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersEntityStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для юр.лиц
   */
  static specialPermitsEntityStatementsVehicleParametersDestroy(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для юр.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/entity_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рассчитанные размеры вреда под определенный процент превышения нагрузки
   */
  static specialPermitsExcessPercentsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExcessPercentList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/excess_percents/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рассчитанные размеры вреда под определенный процент превышения нагрузки
   */
  static specialPermitsExcessPercentsCreate(
    params: {
      /** requestBody */
      body?: ExcessPercent;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExcessPercent> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/excess_percents/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рассчитанные размеры вреда под определенный процент превышения нагрузки
   */
  static specialPermitsExcessPercentsRetrieve(
    params: {
      /** A unique integer value identifying this Рассчитанный размер вреда под определенный процент превышения нагрузки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExcessPercent> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/excess_percents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рассчитанные размеры вреда под определенный процент превышения нагрузки
   */
  static specialPermitsExcessPercentsUpdate(
    params: {
      /** A unique integer value identifying this Рассчитанный размер вреда под определенный процент превышения нагрузки. */
      id: number;
      /** requestBody */
      body?: ExcessPercent;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExcessPercent> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/excess_percents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рассчитанные размеры вреда под определенный процент превышения нагрузки
   */
  static specialPermitsExcessPercentsPartialUpdate(
    params: {
      /** A unique integer value identifying this Рассчитанный размер вреда под определенный процент превышения нагрузки. */
      id: number;
      /** requestBody */
      body?: PatchedExcessPercent;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExcessPercent> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/excess_percents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Рассчитанные размеры вреда под определенный процент превышения нагрузки
   */
  static specialPermitsExcessPercentsDestroy(
    params: {
      /** A unique integer value identifying this Рассчитанный размер вреда под определенный процент превышения нагрузки. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/excess_percents/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsList(
    params: {
      /** Идентификатор заявки */
      approvalId?: string;
      /** Уполномоченный орган */
      authorityName?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        approval_id: params['approvalId'],
        authority_name: params['authorityName'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsCreate(
    params: {
      /** requestBody */
      body?: FederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsRetrieve(
    params: {
      /** A unique integer value identifying this Согласование заявления от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsUpdate(
    params: {
      /** A unique integer value identifying this Согласование заявления от ФКУ "Росдормониторинг". */
      id: number;
      /** requestBody */
      body?: FederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsPartialUpdate(
    params: {
      /** A unique integer value identifying this Согласование заявления от ФКУ "Росдормониторинг". */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsDestroy(
    params: {
      /** A unique integer value identifying this Согласование заявления от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsGetXmlRetrieve(
    params: {
      /** A unique integer value identifying this Согласование заявления от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/{id}/get_xml/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsPrintRetrieve(
    params: {
      /** A unique integer value identifying this Согласование заявления от ФКУ "Росдормониторинг". */
      id: number;
      /** Тип печатной формы */
      typeForm: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/{id}/print/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type_form: params['typeForm'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Согласование заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsSignXmlCreate(
    params: {
      /** A unique integer value identifying this Согласование заявления от ФКУ "Росдормониторинг". */
      id: number;
      /** requestBody */
      body?: FederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/{id}/sign_xml/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Информация для запросов сервиса СМЭВ3
   */
  static specialPermitsFederalStatementsAdapterMessagesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Ответ отправлен в сервис СМЭВ3 */
      isResolutionSend?: boolean;
      /** Тип сообщения */
      messageType?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdapterMessageList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/adapter_messages/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        is_resolution_send: params['isResolutionSend'],
        message_type: params['messageType'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Информация для запросов сервиса СМЭВ3
   */
  static specialPermitsFederalStatementsAdapterMessagesRetrieve(
    params: {
      /** A unique integer value identifying this Информация для запросов сервиса СМЭВ3. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdapterMessage> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/adapter_messages/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для запроса дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionCommentTemplatesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspectionCommentTemplateList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspection_comment_templates/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для запроса дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionCommentTemplatesCreate(
    params: {
      /** requestBody */
      body?: FederalStatementAdditionalInspectionCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspectionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspection_comment_templates/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для запроса дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionCommentTemplatesRetrieve(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для запроса дополнительных материалов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspectionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspection_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для запроса дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionCommentTemplatesUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для запроса дополнительных материалов. */
      id: number;
      /** requestBody */
      body?: FederalStatementAdditionalInspectionCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspectionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspection_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для запроса дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionCommentTemplatesPartialUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для запроса дополнительных материалов. */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatementAdditionalInspectionCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspectionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspection_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для запроса дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionCommentTemplatesDestroy(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для запроса дополнительных материалов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspection_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionsList(
    params: {
      /** Фильтр по полям модели "Пользователь" */
      createdUser?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Данные получены */
      isDataReceive?: boolean;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspectionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspections/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        created_user: params['createdUser'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        is_data_receive: params['isDataReceive'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionsCreate(
    params: {
      /** requestBody */
      body?: FederalStatementAdditionalInspection;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspections/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionsRetrieve(
    params: {
      /** A unique integer value identifying this Запрос дополнительных материалов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspections/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы дополнительных материалов
   */
  static specialPermitsFederalStatementsAdditionalInspectionsSendRequestRetrieve(
    params: {
      /** A unique integer value identifying this Запрос дополнительных материалов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAdditionalInspection> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/additional_inspections/{id}/send_request/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для согласования заявления
   */
  static specialPermitsFederalStatementsApprovalCommentTemplatesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementApprovedCommentTemplateList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/approval_comment_templates/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для согласования заявления
   */
  static specialPermitsFederalStatementsApprovalCommentTemplatesCreate(
    params: {
      /** requestBody */
      body?: FederalStatementApprovedCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementApprovedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/approval_comment_templates/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для согласования заявления
   */
  static specialPermitsFederalStatementsApprovalCommentTemplatesRetrieve(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для согласования заявления. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementApprovedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/approval_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для согласования заявления
   */
  static specialPermitsFederalStatementsApprovalCommentTemplatesUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для согласования заявления. */
      id: number;
      /** requestBody */
      body?: FederalStatementApprovedCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementApprovedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/approval_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для согласования заявления
   */
  static specialPermitsFederalStatementsApprovalCommentTemplatesPartialUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для согласования заявления. */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatementApprovedCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementApprovedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/approval_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для согласования заявления
   */
  static specialPermitsFederalStatementsApprovalCommentTemplatesDestroy(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для согласования заявления. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/approval_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения нагрузок на ось
   */
  static specialPermitsFederalStatementsAxleLoadOverloadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAxleLoadOverloadList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/axle_load_overloads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения нагрузок на ось
   */
  static specialPermitsFederalStatementsAxleLoadOverloadsRetrieve(
    params: {
      /** A unique integer value identifying this Превышение нагрузки на ось. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementAxleLoadOverload> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/axle_load_overloads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsFederalStatementsCalcOfHarmList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmFederalStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/calc_of_harm/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsFederalStatementsCalcOfHarmCreate(
    params: {
      /** requestBody */
      body?: CalculationOfHarmFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/calc_of_harm/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsFederalStatementsCalcOfHarmRetrieve(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsFederalStatementsCalcOfHarmUpdate(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
      /** requestBody */
      body?: CalculationOfHarmFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsFederalStatementsCalcOfHarmPartialUpdate(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
      /** requestBody */
      body?: PatchedCalculationOfHarmFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsFederalStatementsCalcOfHarmDestroy(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsFederalStatementsCalcOfHarmCalcCreate(
    params: {
      /** requestBody */
      body?: CalculationOfHarmFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/calc_of_harm/calc/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы на отмену согласований заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsCancelsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
      /** Статус отзыва */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementCancelList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/cancels/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы на отмену согласований заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsCancelsCreate(
    params: {
      /** requestBody */
      body?: FederalStatementCancel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementCancel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/cancels/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы на отмену согласований заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsCancelsRetrieve(
    params: {
      /** A unique integer value identifying this Запрос на отмену согласования заявления от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementCancel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/cancels/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы на отмену согласований заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsCancelsUpdate(
    params: {
      /** A unique integer value identifying this Запрос на отмену согласования заявления от ФКУ "Росдормониторинг". */
      id: number;
      /** requestBody */
      body?: FederalStatementCancel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementCancel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/cancels/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы на отмену согласований заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsCancelsPartialUpdate(
    params: {
      /** A unique integer value identifying this Запрос на отмену согласования заявления от ФКУ "Росдормониторинг". */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatementCancel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementCancel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/cancels/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Запросы на отмену согласований заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsCancelsDestroy(
    params: {
      /** A unique integer value identifying this Запрос на отмену согласования заявления от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/cancels/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пункты весогабаритного контроля по маршруту
   */
  static specialPermitsFederalStatementsControlStationsList(
    options: IRequestOptions = {}
  ): Promise<FederalStatementControlStationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/control_stations/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пункты весогабаритного контроля по маршруту
   */
  static specialPermitsFederalStatementsControlStationsCreate(
    params: {
      /** requestBody */
      body?: FederalStatementControlStation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementControlStation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/control_stations/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пункты весогабаритного контроля по маршруту
   */
  static specialPermitsFederalStatementsControlStationsRetrieve(
    params: {
      /** A unique integer value identifying this Пункт весогабаритного контроля по маршруту. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementControlStation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/control_stations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пункты весогабаритного контроля по маршруту
   */
  static specialPermitsFederalStatementsControlStationsUpdate(
    params: {
      /** A unique integer value identifying this Пункт весогабаритного контроля по маршруту. */
      id: number;
      /** requestBody */
      body?: FederalStatementControlStation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementControlStation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/control_stations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пункты весогабаритного контроля по маршруту
   */
  static specialPermitsFederalStatementsControlStationsPartialUpdate(
    params: {
      /** A unique integer value identifying this Пункт весогабаритного контроля по маршруту. */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatementControlStation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementControlStation> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/control_stations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пункты весогабаритного контроля по маршруту
   */
  static specialPermitsFederalStatementsControlStationsDestroy(
    params: {
      /** A unique integer value identifying this Пункт весогабаритного контроля по маршруту. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/control_stations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для отказа в согласовании заявления
   */
  static specialPermitsFederalStatementsDeclineCommentTemplatesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDeclinedCommentTemplateList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/decline_comment_templates/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для отказа в согласовании заявления
   */
  static specialPermitsFederalStatementsDeclineCommentTemplatesCreate(
    params: {
      /** requestBody */
      body?: FederalStatementDeclinedCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDeclinedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/decline_comment_templates/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для отказа в согласовании заявления
   */
  static specialPermitsFederalStatementsDeclineCommentTemplatesRetrieve(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для отказа в согласовании заявления. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDeclinedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/decline_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для отказа в согласовании заявления
   */
  static specialPermitsFederalStatementsDeclineCommentTemplatesUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для отказа в согласовании заявления. */
      id: number;
      /** requestBody */
      body?: FederalStatementDeclinedCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDeclinedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/decline_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для отказа в согласовании заявления
   */
  static specialPermitsFederalStatementsDeclineCommentTemplatesPartialUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для отказа в согласовании заявления. */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatementDeclinedCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDeclinedCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/decline_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для отказа в согласовании заявления
   */
  static specialPermitsFederalStatementsDeclineCommentTemplatesDestroy(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для отказа в согласовании заявления. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/decline_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по высоте
   */
  static specialPermitsFederalStatementsDimensionsHeightOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDimensionsHeightOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/dimensions_height_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по высоте
   */
  static specialPermitsFederalStatementsDimensionsHeightOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по высоте. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDimensionsHeightOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/dimensions_height_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине на участке
   */
  static specialPermitsFederalStatementsDimensionsSegmentWidthOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDimensionsSegmentWidthOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/dimensions_segment_width_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине на участке
   */
  static specialPermitsFederalStatementsDimensionsSegmentWidthOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по ширине на участке. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDimensionsSegmentWidthOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/dimensions_segment_width_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине
   */
  static specialPermitsFederalStatementsDimensionsWidthOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDimensionsWidthOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/dimensions_width_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине
   */
  static specialPermitsFederalStatementsDimensionsWidthOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по ширине. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementDimensionsWidthOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/dimensions_width_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для согласования заявления от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementFileList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для согласования заявления от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл для согласования заявления от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов для заявлений
   */
  static specialPermitsFederalStatementsGeneralDimensionsOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Инженерное дорожное сооружение" */
      engineeringConstruction?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementGeneralDimensionsOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/general_dimensions_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        engineering_construction: params['engineeringConstruction'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов для заявлений
   */
  static specialPermitsFederalStatementsGeneralDimensionsOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов для заявления. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementGeneralDimensionsOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/general_dimensions_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static specialPermitsFederalStatementsGetApprovalRouteStatusCreate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/get_approval_route_status/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static specialPermitsFederalStatementsGetCancelApprovalRouteStatusCreate(
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/get_cancel_approval_route_status/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsFederalStatementsRoadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорога" */
      road?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalRoadsStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/roads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road: params['road'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsFederalStatementsRoadsCreate(
    params: {
      /** requestBody */
      body?: FederalRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/roads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsFederalStatementsRoadsRetrieve(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsFederalStatementsRoadsUpdate(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
      /** requestBody */
      body?: FederalRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsFederalStatementsRoadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
      /** requestBody */
      body?: PatchedFederalRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsFederalStatementsRoadsDestroy(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки маршрутов заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsRouteSegmentsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование участка маршрута */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementRouteSegmentList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/route_segments/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки маршрутов заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsRouteSegmentsCreate(
    params: {
      /** requestBody */
      body?: FederalStatementRouteSegment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementRouteSegment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/route_segments/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки маршрутов заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsRouteSegmentsRetrieve(
    params: {
      /** A unique integer value identifying this Участок маршрута заявлений от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementRouteSegment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/route_segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки маршрутов заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsRouteSegmentsUpdate(
    params: {
      /** A unique integer value identifying this Участок маршрута заявлений от ФКУ "Росдормониторинг". */
      id: number;
      /** requestBody */
      body?: FederalStatementRouteSegment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementRouteSegment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/route_segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки маршрутов заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsRouteSegmentsPartialUpdate(
    params: {
      /** A unique integer value identifying this Участок маршрута заявлений от ФКУ "Росдормониторинг". */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatementRouteSegment;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementRouteSegment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/route_segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Участки маршрутов заявлений от ФКУ "Росдормониторинг"
   */
  static specialPermitsFederalStatementsRouteSegmentsDestroy(
    params: {
      /** A unique integer value identifying this Участок маршрута заявлений от ФКУ "Росдормониторинг". */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/route_segments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsFederalStatementsRoutesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteFederalStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/routes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsFederalStatementsRoutesCreate(
    params: {
      /** requestBody */
      body?: RouteFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/routes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsFederalStatementsRoutesRetrieve(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsFederalStatementsRoutesUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: RouteFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsFederalStatementsRoutesPartialUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: PatchedRouteFederalStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteFederalStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsFederalStatementsRoutesDestroy(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для особых условий движения
   */
  static specialPermitsFederalStatementsSpecialConditionCommentTemplatesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementSpecialConditionCommentTemplateList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/special_condition_comment_templates/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для особых условий движения
   */
  static specialPermitsFederalStatementsSpecialConditionCommentTemplatesCreate(
    params: {
      /** requestBody */
      body?: FederalStatementSpecialConditionCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementSpecialConditionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/special_condition_comment_templates/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для особых условий движения
   */
  static specialPermitsFederalStatementsSpecialConditionCommentTemplatesRetrieve(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для особых условий движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementSpecialConditionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/special_condition_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для особых условий движения
   */
  static specialPermitsFederalStatementsSpecialConditionCommentTemplatesUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для особых условий движения. */
      id: number;
      /** requestBody */
      body?: FederalStatementSpecialConditionCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementSpecialConditionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/special_condition_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для особых условий движения
   */
  static specialPermitsFederalStatementsSpecialConditionCommentTemplatesPartialUpdate(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для особых условий движения. */
      id: number;
      /** requestBody */
      body?: PatchedFederalStatementSpecialConditionCommentTemplate;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementSpecialConditionCommentTemplate> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/special_condition_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны ответов для особых условий движения
   */
  static specialPermitsFederalStatementsSpecialConditionCommentTemplatesDestroy(
    params: {
      /** A unique integer value identifying this Шаблонный ответ для особых условий движения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/special_condition_comment_templates/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static specialPermitsFederalStatementsUploadApprovalRouteCreate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/upload_approval_route/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static specialPermitsFederalStatementsUploadCancelApprovalRouteCreate(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/upload_cancel_approval_route/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Информация об осях ТС
   */
  static specialPermitsFederalStatementsVehicleAxisList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "ТС в составе автопоезда" */
      vehicleParameters?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementVehicleAxisList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/vehicle_axis/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        vehicle_parameters: params['vehicleParameters'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Информация об осях ТС
   */
  static specialPermitsFederalStatementsVehicleAxisRetrieve(
    params: {
      /** A unique integer value identifying this Информация об осях ТС. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementVehicleAxis> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/vehicle_axis/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС в составе автопоезда
   */
  static specialPermitsFederalStatementsVehiclesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Согласование заявления от ФКУ "Росдормониторинг"" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementVehicleList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/vehicles/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * ТС в составе автопоезда
   */
  static specialPermitsFederalStatementsVehiclesRetrieve(
    params: {
      /** A unique integer value identifying this ТС в составе автопоезда. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FederalStatementVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/federal_statements/vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Акт об уничтожении" */
      destructionAct?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Акт о списании" */
      writeOffAct?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormAccountStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        destruction_act: params['destructionAct'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        write_off_act: params['writeOffAct'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountCreate(
    params: {
      /** requestBody */
      body?: FormAccountStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormAccountStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountRetrieve(
    params: {
      /** A unique integer value identifying this Бланк строгой отчетности. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormAccountStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountUpdate(
    params: {
      /** A unique integer value identifying this Бланк строгой отчетности. */
      id: number;
      /** requestBody */
      body?: FormAccountStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormAccountStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountPartialUpdate(
    params: {
      /** A unique integer value identifying this Бланк строгой отчетности. */
      id: number;
      /** requestBody */
      body?: PatchedFormAccountStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormAccountStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountDestroy(
    params: {
      /** A unique integer value identifying this Бланк строгой отчетности. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountGenerateCreate(
    params: {
      /** requestBody */
      body?: FormAccountStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormAccountStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/generate/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Бланки строгой отчетности
   */
  static specialPermitsFormsAccountGetFreeNumberRetrieve(options: IRequestOptions = {}): Promise<FormAccountStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/forms_account/get_free_number/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глобальные организации
   */
  static specialPermitsGlobalOrganizationsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Организация" */
      organization?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GlobalOrganizationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/global_organizations/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        organization: params['organization'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глобальные организации
   */
  static specialPermitsGlobalOrganizationsCreate(
    params: {
      /** requestBody */
      body?: GlobalOrganization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GlobalOrganization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/global_organizations/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глобальные организации
   */
  static specialPermitsGlobalOrganizationsRetrieve(
    params: {
      /** A unique integer value identifying this Глобальная организация. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GlobalOrganization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/global_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глобальные организации
   */
  static specialPermitsGlobalOrganizationsUpdate(
    params: {
      /** A unique integer value identifying this Глобальная организация. */
      id: number;
      /** requestBody */
      body?: GlobalOrganization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GlobalOrganization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/global_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глобальные организации
   */
  static specialPermitsGlobalOrganizationsPartialUpdate(
    params: {
      /** A unique integer value identifying this Глобальная организация. */
      id: number;
      /** requestBody */
      body?: PatchedGlobalOrganization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GlobalOrganization> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/global_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Глобальные организации
   */
  static specialPermitsGlobalOrganizationsDestroy(
    params: {
      /** A unique integer value identifying this Глобальная организация. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/global_organizations/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsCreate(
    params: {
      /** requestBody */
      body?: IndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsRetrieve(
    params: {
      /** A unique integer value identifying this Заявка ИП/Физ.лица. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsUpdate(
    params: {
      /** A unique integer value identifying this Заявка ИП/Физ.лица. */
      id: number;
      /** requestBody */
      body?: IndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsPartialUpdate(
    params: {
      /** A unique integer value identifying this Заявка ИП/Физ.лица. */
      id: number;
      /** requestBody */
      body?: PatchedIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsDestroy(
    params: {
      /** A unique integer value identifying this Заявка ИП/Физ.лица. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsChangeStatusCreate(
    params: {
      /** A unique integer value identifying this Заявка ИП/Физ.лица. */
      id: number;
      /** requestBody */
      body?: IndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/{id}/change_status/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsPrintRetrieve(
    params: {
      /** A unique integer value identifying this Заявка ИП/Физ.лица. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/{id}/print/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения нагрузок на ось
   */
  static specialPermitsIndividualStatementsAxleLoadOverloadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementAxleLoadOverloadList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/axle_load_overloads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения нагрузок на ось
   */
  static specialPermitsIndividualStatementsAxleLoadOverloadsRetrieve(
    params: {
      /** A unique integer value identifying this Превышение нагрузки на ось. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementAxleLoadOverload> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/axle_load_overloads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsIndividualStatementsBillingsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingIndividualStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/billings/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsIndividualStatementsBillingsCreate(
    params: {
      /** requestBody */
      body?: BillingIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/billings/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsIndividualStatementsBillingsRetrieve(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsIndividualStatementsBillingsUpdate(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
      /** requestBody */
      body?: BillingIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsIndividualStatementsBillingsPartialUpdate(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
      /** requestBody */
      body?: PatchedBillingIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsIndividualStatementsBillingsDestroy(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/billings/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Счета
   */
  static specialPermitsIndividualStatementsBillingsPrintRetrieve(
    params: {
      /** A unique integer value identifying this Счет. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BillingIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/billings/{id}/print/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsIndividualStatementsCalcOfHarmList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmIndividualStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/calc_of_harm/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsIndividualStatementsCalcOfHarmCreate(
    params: {
      /** requestBody */
      body?: CalculationOfHarmIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/calc_of_harm/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsIndividualStatementsCalcOfHarmRetrieve(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsIndividualStatementsCalcOfHarmUpdate(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
      /** requestBody */
      body?: CalculationOfHarmIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsIndividualStatementsCalcOfHarmPartialUpdate(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
      /** requestBody */
      body?: PatchedCalculationOfHarmIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsIndividualStatementsCalcOfHarmDestroy(
    params: {
      /** A unique integer value identifying this Расчет вреда. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/calc_of_harm/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расчет вреда
   */
  static specialPermitsIndividualStatementsCalcOfHarmCalcCreate(
    params: {
      /** requestBody */
      body?: CalculationOfHarmIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CalculationOfHarmIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/calc_of_harm/calc/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Заявки ИП/Физ.лица
   */
  static specialPermitsIndividualStatementsCheckInnRetrieve(
    options: IRequestOptions = {}
  ): Promise<IndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/check_inn/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по высоте
   */
  static specialPermitsIndividualStatementsDimensionsHeightOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementDimensionsHeightOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/dimensions_height_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по высоте
   */
  static specialPermitsIndividualStatementsDimensionsHeightOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по высоте. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementDimensionsHeightOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/dimensions_height_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине на участке
   */
  static specialPermitsIndividualStatementsDimensionsSegmentWidthOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementSegmentDimensionsWidthOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/dimensions_segment_width_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине на участке
   */
  static specialPermitsIndividualStatementsDimensionsSegmentWidthOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по ширине на участке. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementSegmentDimensionsWidthOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/dimensions_segment_width_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине
   */
  static specialPermitsIndividualStatementsDimensionsWidthOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementDimensionsWidthOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/dimensions_width_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов по ширине
   */
  static specialPermitsIndividualStatementsDimensionsWidthOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов по ширине. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementDimensionsWidthOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/dimensions_width_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsIndividualStatementsFilesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileIndividualStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/files/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsIndividualStatementsFilesCreate(
    params: {
      /** requestBody */
      body?: FileIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/files/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsIndividualStatementsFilesRetrieve(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsIndividualStatementsFilesUpdate(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
      /** requestBody */
      body?: FileIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsIndividualStatementsFilesPartialUpdate(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
      /** requestBody */
      body?: PatchedFileIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Файлы для заявлений
   */
  static specialPermitsIndividualStatementsFilesDestroy(
    params: {
      /** A unique integer value identifying this Файл для заявлений. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/files/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов для заявлений
   */
  static specialPermitsIndividualStatementsGeneralDimensionsOversizesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Инженерное дорожное сооружение" */
      engineeringConstruction?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementGeneralDimensionsOversizeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/general_dimensions_oversizes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        engineering_construction: params['engineeringConstruction'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Превышения габаритов для заявлений
   */
  static specialPermitsIndividualStatementsGeneralDimensionsOversizesRetrieve(
    params: {
      /** A unique integer value identifying this Превышение габаритов для заявления. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualStatementGeneralDimensionsOversize> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/general_dimensions_oversizes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsIndividualStatementsRoadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Дорога" */
      road?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualRoadsStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/roads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        road: params['road'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsIndividualStatementsRoadsCreate(
    params: {
      /** requestBody */
      body?: IndividualRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/roads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsIndividualStatementsRoadsRetrieve(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsIndividualStatementsRoadsUpdate(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
      /** requestBody */
      body?: IndividualRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsIndividualStatementsRoadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
      /** requestBody */
      body?: PatchedIndividualRoadsStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IndividualRoadsStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дороги в заявлении
   */
  static specialPermitsIndividualStatementsRoadsDestroy(
    params: {
      /** A unique integer value identifying this Дорога в заявлении. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/roads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsIndividualStatementsRoutesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteIndividualStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/routes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsIndividualStatementsRoutesCreate(
    params: {
      /** requestBody */
      body?: RouteIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/routes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsIndividualStatementsRoutesRetrieve(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsIndividualStatementsRoutesUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: RouteIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsIndividualStatementsRoutesPartialUpdate(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
      /** requestBody */
      body?: PatchedRouteIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RouteIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Маршруты
   */
  static specialPermitsIndividualStatementsRoutesDestroy(
    params: {
      /** A unique integer value identifying this Маршрут. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/routes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleAxesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Параметры транспорта в заявке для физ.лиц" */
      vehicleParameters?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisIndividualStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_axes/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        vehicle_parameters: params['vehicleParameters'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleAxesCreate(
    params: {
      /** requestBody */
      body?: VehicleAxisIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_axes/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleAxesRetrieve(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для физ.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleAxesUpdate(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для физ.лиц. */
      id: number;
      /** requestBody */
      body?: VehicleAxisIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleAxesPartialUpdate(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для физ.лиц. */
      id: number;
      /** requestBody */
      body?: PatchedVehicleAxisIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxisIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Оси ТС в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleAxesDestroy(
    params: {
      /** A unique integer value identifying this Ось ТС в заявке для физ.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_axes/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleParametersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Заявка ИП/Физ.лица" */
      statement?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersIndividualStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_parameters/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        statement: params['statement'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleParametersCreate(
    params: {
      /** requestBody */
      body?: VehicleParametersIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_parameters/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleParametersRetrieve(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для физ.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleParametersUpdate(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для физ.лиц. */
      id: number;
      /** requestBody */
      body?: VehicleParametersIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleParametersPartialUpdate(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для физ.лиц. */
      id: number;
      /** requestBody */
      body?: PatchedVehicleParametersIndividualStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleParametersIndividualStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Параметры транспорта в заявке для физ.лиц
   */
  static specialPermitsIndividualStatementsVehicleParametersDestroy(
    params: {
      /** A unique integer value identifying this Параметры транспорта в заявке для физ.лиц. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/individual_statements/vehicle_parameters/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расположение осей ТС
   */
  static specialPermitsLocationAxisList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationAxisStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/location_axis/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расположение осей ТС
   */
  static specialPermitsLocationAxisCreate(
    params: {
      /** requestBody */
      body?: LocationAxisStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationAxisStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/location_axis/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расположение осей ТС
   */
  static specialPermitsLocationAxisRetrieve(
    params: {
      /** A unique integer value identifying this Расположение осей ТС. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationAxisStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/location_axis/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расположение осей ТС
   */
  static specialPermitsLocationAxisUpdate(
    params: {
      /** A unique integer value identifying this Расположение осей ТС. */
      id: number;
      /** requestBody */
      body?: LocationAxisStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationAxisStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/location_axis/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расположение осей ТС
   */
  static specialPermitsLocationAxisPartialUpdate(
    params: {
      /** A unique integer value identifying this Расположение осей ТС. */
      id: number;
      /** requestBody */
      body?: PatchedLocationAxisStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationAxisStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/location_axis/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Расположение осей ТС
   */
  static specialPermitsLocationAxisDestroy(
    params: {
      /** A unique integer value identifying this Расположение осей ТС. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/location_axis/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нерабочие дни
   */
  static specialPermitsNonWorkingDaysList(
    params: {
      /** Дата нерабочего дня */
      date?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonWorkingDaysList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/non_working_days/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        date: params['date'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нерабочие дни
   */
  static specialPermitsNonWorkingDaysCreate(
    params: {
      /** requestBody */
      body?: NonWorkingDays;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonWorkingDays> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/non_working_days/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нерабочие дни
   */
  static specialPermitsNonWorkingDaysRetrieve(
    params: {
      /** A unique integer value identifying this Нерабочий день. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonWorkingDays> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/non_working_days/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нерабочие дни
   */
  static specialPermitsNonWorkingDaysUpdate(
    params: {
      /** A unique integer value identifying this Нерабочий день. */
      id: number;
      /** requestBody */
      body?: NonWorkingDays;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonWorkingDays> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/non_working_days/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нерабочие дни
   */
  static specialPermitsNonWorkingDaysPartialUpdate(
    params: {
      /** A unique integer value identifying this Нерабочий день. */
      id: number;
      /** requestBody */
      body?: PatchedNonWorkingDays;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NonWorkingDays> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/non_working_days/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нерабочие дни
   */
  static specialPermitsNonWorkingDaysDestroy(
    params: {
      /** A unique integer value identifying this Нерабочий день. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/non_working_days/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Вид оплаты
   */
  static specialPermitsPaymentTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование платежа */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/payment_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Вид оплаты
   */
  static specialPermitsPaymentTypesCreate(
    params: {
      /** requestBody */
      body?: PaymentType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/payment_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Вид оплаты
   */
  static specialPermitsPaymentTypesRetrieve(
    params: {
      /** A unique integer value identifying this Вид оплаты. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/payment_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Вид оплаты
   */
  static specialPermitsPaymentTypesUpdate(
    params: {
      /** A unique integer value identifying this Вид оплаты. */
      id: number;
      /** requestBody */
      body?: PaymentType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/payment_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Вид оплаты
   */
  static specialPermitsPaymentTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Вид оплаты. */
      id: number;
      /** requestBody */
      body?: PatchedPaymentType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/payment_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Вид оплаты
   */
  static specialPermitsPaymentTypesDestroy(
    params: {
      /** A unique integer value identifying this Вид оплаты. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/payment_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые осевые нагрузки ТС
   */
  static specialPermitsPermissibleAxisLoadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleAxisLoadStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_axis_loads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые осевые нагрузки ТС
   */
  static specialPermitsPermissibleAxisLoadsCreate(
    params: {
      /** requestBody */
      body?: PermissibleAxisLoadStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleAxisLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_axis_loads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые осевые нагрузки ТС
   */
  static specialPermitsPermissibleAxisLoadsRetrieve(
    params: {
      /** A unique integer value identifying this Допустимые осевые нагрузки ТС. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleAxisLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_axis_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые осевые нагрузки ТС
   */
  static specialPermitsPermissibleAxisLoadsUpdate(
    params: {
      /** A unique integer value identifying this Допустимые осевые нагрузки ТС. */
      id: number;
      /** requestBody */
      body?: PermissibleAxisLoadStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleAxisLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_axis_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые осевые нагрузки ТС
   */
  static specialPermitsPermissibleAxisLoadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Допустимые осевые нагрузки ТС. */
      id: number;
      /** requestBody */
      body?: PatchedPermissibleAxisLoadStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleAxisLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_axis_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые осевые нагрузки ТС
   */
  static specialPermitsPermissibleAxisLoadsDestroy(
    params: {
      /** A unique integer value identifying this Допустимые осевые нагрузки ТС. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_axis_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые массы транспортного средства
   */
  static specialPermitsPermissibleWeightList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleWeightStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_weight/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые массы транспортного средства
   */
  static specialPermitsPermissibleWeightCreate(
    params: {
      /** requestBody */
      body?: PermissibleWeightStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleWeightStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_weight/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые массы транспортного средства
   */
  static specialPermitsPermissibleWeightRetrieve(
    params: {
      /** A unique integer value identifying this Допустимая масса транспортного средства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleWeightStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_weight/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые массы транспортного средства
   */
  static specialPermitsPermissibleWeightUpdate(
    params: {
      /** A unique integer value identifying this Допустимая масса транспортного средства. */
      id: number;
      /** requestBody */
      body?: PermissibleWeightStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleWeightStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_weight/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые массы транспортного средства
   */
  static specialPermitsPermissibleWeightPartialUpdate(
    params: {
      /** A unique integer value identifying this Допустимая масса транспортного средства. */
      id: number;
      /** requestBody */
      body?: PatchedPermissibleWeightStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PermissibleWeightStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_weight/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Допустимые массы транспортного средства
   */
  static specialPermitsPermissibleWeightDestroy(
    params: {
      /** A unique integer value identifying this Допустимая масса транспортного средства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/permissible_weight/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подписанты
   */
  static specialPermitsSignersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Пользователь" */
      user?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SignerList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/signers/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        user: params['user'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подписанты
   */
  static specialPermitsSignersCreate(
    params: {
      /** requestBody */
      body?: Signer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Signer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/signers/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подписанты
   */
  static specialPermitsSignersRetrieve(
    params: {
      /** A unique integer value identifying this Подписант. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Signer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/signers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подписанты
   */
  static specialPermitsSignersUpdate(
    params: {
      /** A unique integer value identifying this Подписант. */
      id: number;
      /** requestBody */
      body?: Signer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Signer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/signers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подписанты
   */
  static specialPermitsSignersPartialUpdate(
    params: {
      /** A unique integer value identifying this Подписант. */
      id: number;
      /** requestBody */
      body?: PatchedSigner;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Signer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/signers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Подписанты
   */
  static specialPermitsSignersDestroy(
    params: {
      /** A unique integer value identifying this Подписант. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/signers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны комментариев для статусов
   */
  static specialPermitsStatusCommentsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusCommentStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/status_comments/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны комментариев для статусов
   */
  static specialPermitsStatusCommentsCreate(
    params: {
      /** requestBody */
      body?: StatusCommentStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusCommentStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/status_comments/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны комментариев для статусов
   */
  static specialPermitsStatusCommentsRetrieve(
    params: {
      /** A unique integer value identifying this Шаблон комментариев для статусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusCommentStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/status_comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны комментариев для статусов
   */
  static specialPermitsStatusCommentsUpdate(
    params: {
      /** A unique integer value identifying this Шаблон комментариев для статусов. */
      id: number;
      /** requestBody */
      body?: StatusCommentStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusCommentStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/status_comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны комментариев для статусов
   */
  static specialPermitsStatusCommentsPartialUpdate(
    params: {
      /** A unique integer value identifying this Шаблон комментариев для статусов. */
      id: number;
      /** requestBody */
      body?: PatchedStatusCommentStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StatusCommentStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/status_comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны комментариев для статусов
   */
  static specialPermitsStatusCommentsDestroy(
    params: {
      /** A unique integer value identifying this Шаблон комментариев для статусов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/status_comments/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Шаблоны комментариев для статусов
   */
  static specialPermitsStatusCommentsGetJsonRetrieve(options: IRequestOptions = {}): Promise<StatusCommentStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/status_comments/get_json/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузка на ось транспортного средства
   */
  static specialPermitsVehicleAxleLoadsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxleLoadStatementList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/vehicle_axle_loads/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузка на ось транспортного средства
   */
  static specialPermitsVehicleAxleLoadsCreate(
    params: {
      /** requestBody */
      body?: VehicleAxleLoadStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxleLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/vehicle_axle_loads/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузка на ось транспортного средства
   */
  static specialPermitsVehicleAxleLoadsRetrieve(
    params: {
      /** A unique integer value identifying this Нагрузка на ось транспортного средства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxleLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/vehicle_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузка на ось транспортного средства
   */
  static specialPermitsVehicleAxleLoadsUpdate(
    params: {
      /** A unique integer value identifying this Нагрузка на ось транспортного средства. */
      id: number;
      /** requestBody */
      body?: VehicleAxleLoadStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxleLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/vehicle_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузка на ось транспортного средства
   */
  static specialPermitsVehicleAxleLoadsPartialUpdate(
    params: {
      /** A unique integer value identifying this Нагрузка на ось транспортного средства. */
      id: number;
      /** requestBody */
      body?: PatchedVehicleAxleLoadStatement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleAxleLoadStatement> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/vehicle_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Нагрузка на ось транспортного средства
   */
  static specialPermitsVehicleAxleLoadsDestroy(
    params: {
      /** A unique integer value identifying this Нагрузка на ось транспортного средства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/vehicle_axle_loads/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акты о списании
   */
  static specialPermitsWriteOffActsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WriteOffActList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/write_off_acts/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акты о списании
   */
  static specialPermitsWriteOffActsCreate(
    params: {
      /** requestBody */
      body?: WriteOffAct;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WriteOffAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/write_off_acts/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акты о списании
   */
  static specialPermitsWriteOffActsRetrieve(
    params: {
      /** A unique integer value identifying this Акт о списании. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WriteOffAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/write_off_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акты о списании
   */
  static specialPermitsWriteOffActsUpdate(
    params: {
      /** A unique integer value identifying this Акт о списании. */
      id: number;
      /** requestBody */
      body?: WriteOffAct;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WriteOffAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/write_off_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акты о списании
   */
  static specialPermitsWriteOffActsPartialUpdate(
    params: {
      /** A unique integer value identifying this Акт о списании. */
      id: number;
      /** requestBody */
      body?: PatchedWriteOffAct;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WriteOffAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/write_off_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акты о списании
   */
  static specialPermitsWriteOffActsDestroy(
    params: {
      /** A unique integer value identifying this Акт о списании. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/write_off_acts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Акты о списании
   */
  static specialPermitsWriteOffActsPrintRetrieve(
    params: {
      /** A unique integer value identifying this Акт о списании. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WriteOffAct> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/special_permits/write_off_acts/{id}/print/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatesService {
  /**
   * Субъекты РФ
   */
  static statesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StateList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Субъекты РФ
   */
  static statesCreate(
    params: {
      /** requestBody */
      body?: State;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<State> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Субъекты РФ
   */
  static statesRetrieve(
    params: {
      /** A unique integer value identifying this Субъект РФ. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<State> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Субъекты РФ
   */
  static statesUpdate(
    params: {
      /** A unique integer value identifying this Субъект РФ. */
      id: number;
      /** requestBody */
      body?: State;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<State> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Субъекты РФ
   */
  static statesPartialUpdate(
    params: {
      /** A unique integer value identifying this Субъект РФ. */
      id: number;
      /** requestBody */
      body?: PatchedState;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<State> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Субъекты РФ
   */
  static statesDestroy(
    params: {
      /** A unique integer value identifying this Субъект РФ. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Районы города
   */
  static statesCityDistrictsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Название района */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDistrictList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/city_districts/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Районы города
   */
  static statesCityDistrictsCreate(
    params: {
      /** requestBody */
      body?: CityDistrict;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDistrict> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/city_districts/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Районы города
   */
  static statesCityDistrictsRetrieve(
    params: {
      /** A unique integer value identifying this Район города. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDistrict> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/city_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Районы города
   */
  static statesCityDistrictsUpdate(
    params: {
      /** A unique integer value identifying this Район города. */
      id: number;
      /** requestBody */
      body?: CityDistrict;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDistrict> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/city_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Районы города
   */
  static statesCityDistrictsPartialUpdate(
    params: {
      /** A unique integer value identifying this Район города. */
      id: number;
      /** requestBody */
      body?: PatchedCityDistrict;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CityDistrict> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/city_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Районы города
   */
  static statesCityDistrictsDestroy(
    params: {
      /** A unique integer value identifying this Район города. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/city_districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные районы
   */
  static statesDistrictsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Субъект РФ" */
      state?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DistrictList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/districts/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        state: params['state'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные районы
   */
  static statesDistrictsCreate(
    params: {
      /** requestBody */
      body?: District;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<District> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/districts/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные районы
   */
  static statesDistrictsRetrieve(
    params: {
      /** A unique integer value identifying this Муниципальный район. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<District> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные районы
   */
  static statesDistrictsUpdate(
    params: {
      /** A unique integer value identifying this Муниципальный район. */
      id: number;
      /** requestBody */
      body?: District;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<District> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные районы
   */
  static statesDistrictsPartialUpdate(
    params: {
      /** A unique integer value identifying this Муниципальный район. */
      id: number;
      /** requestBody */
      body?: PatchedDistrict;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<District> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные районы
   */
  static statesDistrictsDestroy(
    params: {
      /** A unique integer value identifying this Муниципальный район. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/districts/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Населенные пункты
   */
  static statesLocalitiesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Субъект РФ" */
      state?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocalityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/localities/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        state: params['state'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Населенные пункты
   */
  static statesLocalitiesCreate(
    params: {
      /** requestBody */
      body?: Locality;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Locality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/localities/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Населенные пункты
   */
  static statesLocalitiesRetrieve(
    params: {
      /** A unique integer value identifying this Населенный пункт. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Locality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/localities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Населенные пункты
   */
  static statesLocalitiesUpdate(
    params: {
      /** A unique integer value identifying this Населенный пункт. */
      id: number;
      /** requestBody */
      body?: Locality;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Locality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/localities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Населенные пункты
   */
  static statesLocalitiesPartialUpdate(
    params: {
      /** A unique integer value identifying this Населенный пункт. */
      id: number;
      /** requestBody */
      body?: PatchedLocality;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Locality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/localities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Населенные пункты
   */
  static statesLocalitiesDestroy(
    params: {
      /** A unique integer value identifying this Населенный пункт. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/localities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы населенных пунктов
   */
  static statesLocalityTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocalityTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/locality_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы населенных пунктов
   */
  static statesLocalityTypesCreate(
    params: {
      /** requestBody */
      body?: LocalityType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocalityType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/locality_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы населенных пунктов
   */
  static statesLocalityTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип населенного пункта. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocalityType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/locality_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы населенных пунктов
   */
  static statesLocalityTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип населенного пункта. */
      id: number;
      /** requestBody */
      body?: LocalityType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocalityType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/locality_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы населенных пунктов
   */
  static statesLocalityTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип населенного пункта. */
      id: number;
      /** requestBody */
      body?: PatchedLocalityType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocalityType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/locality_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы населенных пунктов
   */
  static statesLocalityTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип населенного пункта. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/locality_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные образования
   */
  static statesMunicipalitiesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Фильтр по полям модели "Муниципальный район" */
      municipalDistrict?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MunicipalityList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/municipalities/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        municipal_district: params['municipalDistrict'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные образования
   */
  static statesMunicipalitiesCreate(
    params: {
      /** requestBody */
      body?: Municipality;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Municipality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/municipalities/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные образования
   */
  static statesMunicipalitiesRetrieve(
    params: {
      /** A unique integer value identifying this Муниципальное образование. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Municipality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/municipalities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные образования
   */
  static statesMunicipalitiesUpdate(
    params: {
      /** A unique integer value identifying this Муниципальное образование. */
      id: number;
      /** requestBody */
      body?: Municipality;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Municipality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/municipalities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные образования
   */
  static statesMunicipalitiesPartialUpdate(
    params: {
      /** A unique integer value identifying this Муниципальное образование. */
      id: number;
      /** requestBody */
      body?: PatchedMunicipality;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Municipality> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/municipalities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Муниципальные образования
   */
  static statesMunicipalitiesDestroy(
    params: {
      /** A unique integer value identifying this Муниципальное образование. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/municipalities/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Численность населения
   */
  static statesPopulationList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Фильтр по полям модели "Населенный пункт" */
      locality?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PopulationList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/population/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        locality: params['locality'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Численность населения
   */
  static statesPopulationCreate(
    params: {
      /** requestBody */
      body?: Population;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Population> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/population/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Численность населения
   */
  static statesPopulationRetrieve(
    params: {
      /** A unique integer value identifying this Численность населения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Population> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/population/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Численность населения
   */
  static statesPopulationUpdate(
    params: {
      /** A unique integer value identifying this Численность населения. */
      id: number;
      /** requestBody */
      body?: Population;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Population> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/population/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Численность населения
   */
  static statesPopulationPartialUpdate(
    params: {
      /** A unique integer value identifying this Численность населения. */
      id: number;
      /** requestBody */
      body?: PatchedPopulation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Population> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/population/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Численность населения
   */
  static statesPopulationDestroy(
    params: {
      /** A unique integer value identifying this Численность населения. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/states/population/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SystemService {
  /**
   * Константы
   */
  static systemConstantsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Название константы */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Значение */
      value?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ConstantList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/system/constants/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
        value: params['value'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Константы
   */
  static systemConstantsCreate(
    params: {
      /** requestBody */
      body?: Constant;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Constant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/system/constants/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Константы
   */
  static systemConstantsRetrieve(
    params: {
      /** A unique integer value identifying this Константа. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Constant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/system/constants/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Константы
   */
  static systemConstantsUpdate(
    params: {
      /** A unique integer value identifying this Константа. */
      id: number;
      /** requestBody */
      body?: Constant;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Constant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/system/constants/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Константы
   */
  static systemConstantsPartialUpdate(
    params: {
      /** A unique integer value identifying this Константа. */
      id: number;
      /** requestBody */
      body?: PatchedConstant;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Constant> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/system/constants/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Константы
   */
  static systemConstantsDestroy(
    params: {
      /** A unique integer value identifying this Константа. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/system/constants/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TerminalsService {
  /**
   * Навигационные терминалы
   */
  static terminalsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Модель прибора" */
      deviceFullname?: string;
      /** Фильтр по полям модели "Тип навигационного терминала" */
      deviceType?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Номер трансляции */
      number?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Организация" */
      organization?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        device_fullname: params['deviceFullname'],
        device_type: params['deviceType'],
        history: params['history'],
        id: params['id'],
        number: params['number'],
        ordering: params['ordering'],
        organization: params['organization'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы
   */
  static terminalsCreate(
    params: {
      /** requestBody */
      body?: Device;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы
   */
  static terminalsRetrieve(
    params: {
      /** A unique integer value identifying this Навигационный терминал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы
   */
  static terminalsUpdate(
    params: {
      /** A unique integer value identifying this Навигационный терминал. */
      id: number;
      /** requestBody */
      body?: Device;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы
   */
  static terminalsPartialUpdate(
    params: {
      /** A unique integer value identifying this Навигационный терминал. */
      id: number;
      /** requestBody */
      body?: PatchedDevice;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Device> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы
   */
  static terminalsDestroy(
    params: {
      /** A unique integer value identifying this Навигационный терминал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Модели приборов
   */
  static terminalsDeviceFullnamesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Модель прибора */
      deviceFullname?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceFullnameList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_fullnames/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        device_fullname: params['deviceFullname'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Модели приборов
   */
  static terminalsDeviceFullnamesCreate(
    params: {
      /** requestBody */
      body?: DeviceFullname;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceFullname> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_fullnames/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Модели приборов
   */
  static terminalsDeviceFullnamesRetrieve(
    params: {
      /** A unique integer value identifying this Модель прибора. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceFullname> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_fullnames/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Модели приборов
   */
  static terminalsDeviceFullnamesUpdate(
    params: {
      /** A unique integer value identifying this Модель прибора. */
      id: number;
      /** requestBody */
      body?: DeviceFullname;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceFullname> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_fullnames/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Модели приборов
   */
  static terminalsDeviceFullnamesPartialUpdate(
    params: {
      /** A unique integer value identifying this Модель прибора. */
      id: number;
      /** requestBody */
      body?: PatchedDeviceFullname;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceFullname> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_fullnames/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Модели приборов
   */
  static terminalsDeviceFullnamesDestroy(
    params: {
      /** A unique integer value identifying this Модель прибора. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_fullnames/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы навигационных терминалов
   */
  static terminalsDeviceTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Версия протокола */
      library?: string;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        library: params['library'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы навигационных терминалов
   */
  static terminalsDeviceTypesCreate(
    params: {
      /** requestBody */
      body?: DeviceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы навигационных терминалов
   */
  static terminalsDeviceTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип навигационного терминала. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы навигационных терминалов
   */
  static terminalsDeviceTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип навигационного терминала. */
      id: number;
      /** requestBody */
      body?: DeviceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы навигационных терминалов
   */
  static terminalsDeviceTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип навигационного терминала. */
      id: number;
      /** requestBody */
      body?: PatchedDeviceType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы навигационных терминалов
   */
  static terminalsDeviceTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип навигационного терминала. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/terminals/device_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TlControllersService {
  /**
   * Светофорные контроллеры
   */
  static tlControllersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофорные контроллеры
   */
  static tlControllersRetrieve(
    params: {
      /** A unique integer value identifying this Светофорный контроллер. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlController> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Светофорные контроллеры
   */
  static tlControllersGetCurrentPlanRetrieve(
    params: {
      /** A unique integer value identifying this Светофорный контроллер. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlController> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/{id}/get_current_plan/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фазы циклов светофорных контроллеров
   */
  static tlControllersCyclePhasesList(
    params: {
      /** Фильтр по полям модели "Цикл светофорного контроллера" */
      cycle?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Номер */
      number?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerCyclePhaseList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/cycle_phases/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        cycle: params['cycle'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        number: params['number'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Фазы циклов светофорных контроллеров
   */
  static tlControllersCyclePhasesRetrieve(
    params: {
      /** A unique integer value identifying this Фаза цикла светофорного контроллера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerCyclePhase> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/cycle_phases/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Циклы светофорных контроллеров
   */
  static tlControllersCyclesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Светофорный контроллер" */
      tlController?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerCycleList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/cycles/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        tl_controller: params['tlController'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Циклы светофорных контроллеров
   */
  static tlControllersCyclesRetrieve(
    params: {
      /** A unique integer value identifying this Цикл светофорного контроллера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerCycle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/cycles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дневные планы светофорных контроллеров
   */
  static tlControllersDayPlansList(
    params: {
      /** Фильтр по полям модели "Цикл светофорного контроллера" */
      cycle?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Время начала цикла */
      startTime?: string;
      /** Фильтр по полям модели "Недельный план светофорного контроллера" */
      weekPlan?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerDayPlanList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/day_plans/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        cycle: params['cycle'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        start_time: params['startTime'],
        week_plan: params['weekPlan'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Дневные планы светофорных контроллеров
   */
  static tlControllersDayPlansRetrieve(
    params: {
      /** A unique integer value identifying this Дневной план светофорного контроллера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerDayPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/day_plans/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния светофорных контроллеров
   */
  static tlControllersStatesList(
    params: {
      /** Время обновления */
      createdAt?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Статус работы */
      mode?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Статус подключения */
      status?: string;
      /** Фильтр по полям модели "Светофорный контроллер" */
      tlController?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerStateList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/states/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        created_at: params['createdAt'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        mode: params['mode'],
        ordering: params['ordering'],
        representation: params['representation'],
        status: params['status'],
        tl_controller: params['tlController'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния светофорных контроллеров
   */
  static tlControllersStatesRetrieve(
    params: {
      /** A unique integer value identifying this Состояние светофорного контроллера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerState> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/states/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Состояния светофорных контроллеров
   */
  static tlControllersStatesGetCurrentStatesRetrieve(options: IRequestOptions = {}): Promise<TlControllerState> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/states/get_current_states/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Недельные планы светофорных контроллеров
   */
  static tlControllersWeekPlansList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Светофорный контроллер" */
      tlController?: string;
      /** День недели */
      weekDay?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerWeekPlanList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/week_plans/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        tl_controller: params['tlController'],
        week_day: params['weekDay'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Недельные планы светофорных контроллеров
   */
  static tlControllersWeekPlansRetrieve(
    params: {
      /** A unique integer value identifying this Недельный план светофорного контроллера. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TlControllerWeekPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/tl_controllers/week_plans/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TransportDetectorsService {
  /**
   * Детекторы транспорта
   */
  static transportDetectorsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Тип */
      detectorType?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Фильтр по полям модели "План установки камер и детекторов" */
      installationPlan?: string;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransportDetectorList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        detector_type: params['detectorType'],
        history: params['history'],
        id: params['id'],
        installation_plan: params['installationPlan'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы транспорта
   */
  static transportDetectorsCreate(
    params: {
      /** requestBody */
      body?: TransportDetector;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransportDetector> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы транспорта
   */
  static transportDetectorsRetrieve(
    params: {
      /** A unique integer value identifying this Детектор транспорта. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransportDetector> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы транспорта
   */
  static transportDetectorsUpdate(
    params: {
      /** A unique integer value identifying this Детектор транспорта. */
      id: number;
      /** requestBody */
      body?: TransportDetector;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransportDetector> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы транспорта
   */
  static transportDetectorsPartialUpdate(
    params: {
      /** A unique integer value identifying this Детектор транспорта. */
      id: number;
      /** requestBody */
      body?: PatchedTransportDetector;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TransportDetector> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Детекторы транспорта
   */
  static transportDetectorsDestroy(
    params: {
      /** A unique integer value identifying this Детектор транспорта. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Планы установки камер и детекторов
   */
  static transportDetectorsInstallationPlansList(
    params: {
      /** Цвет */
      color?: string;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Год установки */
      installationYear?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstallationPlanList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/installation_plans/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        color: params['color'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        installation_year: params['installationYear'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Планы установки камер и детекторов
   */
  static transportDetectorsInstallationPlansCreate(
    params: {
      /** requestBody */
      body?: InstallationPlan;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstallationPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/installation_plans/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Планы установки камер и детекторов
   */
  static transportDetectorsInstallationPlansRetrieve(
    params: {
      /** A unique integer value identifying this План установки камер и детекторов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstallationPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/installation_plans/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Планы установки камер и детекторов
   */
  static transportDetectorsInstallationPlansUpdate(
    params: {
      /** A unique integer value identifying this План установки камер и детекторов. */
      id: number;
      /** requestBody */
      body?: InstallationPlan;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstallationPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/installation_plans/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Планы установки камер и детекторов
   */
  static transportDetectorsInstallationPlansPartialUpdate(
    params: {
      /** A unique integer value identifying this План установки камер и детекторов. */
      id: number;
      /** requestBody */
      body?: PatchedInstallationPlan;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstallationPlan> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/installation_plans/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Планы установки камер и детекторов
   */
  static transportDetectorsInstallationPlansDestroy(
    params: {
      /** A unique integer value identifying this План установки камер и детекторов. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/transport_detectors/installation_plans/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserSettingsService {
  /**
   * Настройки пользователей
   */
  static userSettingsRetrieve(
    params: {
      /** Ключ настройки */
      path?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserSetting> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_settings/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { path: params['path'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Настройки пользователей
   */
  static userSettingsCreate(
    params: {
      /** requestBody */
      body?: UserSetting;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_settings/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Настройки пользователей
   */
  static userSettingsUpdate(
    params: {
      /** Ключ настройки */
      path?: string;
      /** requestBody */
      body?: UserSetting;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_settings/';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { path: params['path'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Настройки пользователей
   */
  static userSettingsDestroy(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_settings/';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   * Пользователи
   */
  static usersList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Email */
      email?: string;
      /** Имя */
      firstName?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Активен */
      isActive?: boolean;
      /** Фамилия */
      lastName?: string;
      /** Отчество */
      middleName?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Организация" */
      organization?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Логин */
      username?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        email: params['email'],
        first_name: params['firstName'],
        history: params['history'],
        id: params['id'],
        is_active: params['isActive'],
        last_name: params['lastName'],
        middle_name: params['middleName'],
        ordering: params['ordering'],
        organization: params['organization'],
        representation: params['representation'],
        username: params['username'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пользователи
   */
  static usersCreate(
    params: {
      /** requestBody */
      body?: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пользователи
   */
  static usersRetrieve(
    params: {
      /** A unique integer value identifying this Пользователь. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пользователи
   */
  static usersUpdate(
    params: {
      /** A unique integer value identifying this Пользователь. */
      id: number;
      /** requestBody */
      body?: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пользователи
   */
  static usersPartialUpdate(
    params: {
      /** A unique integer value identifying this Пользователь. */
      id: number;
      /** requestBody */
      body?: PatchedUser;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пользователи
   */
  static usersDestroy(
    params: {
      /** A unique integer value identifying this Пользователь. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersCheckUsernameCreate(
    params: {
      /** requestBody */
      body?: GenerateUsernames;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GenerateUsernames> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/check_username/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пользователи
   */
  static usersMeRetrieve(options: IRequestOptions = {}): Promise<User> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/me/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Пользователи
   */
  static usersMePermissionsRetrieve(options: IRequestOptions = {}): Promise<UserPermissions> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/me/permissions/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersPasswordResetCreate(
    params: {
      /** requestBody */
      body?: PasswordReset;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PasswordReset> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/password/reset/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersPasswordResetConfirmCreate(
    params: {
      /** requestBody */
      body?: PasswordResetConfirm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PasswordResetConfirm> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/password/reset/confirm/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы персонала
   */
  static usersPersonTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/person_types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы персонала
   */
  static usersPersonTypesCreate(
    params: {
      /** requestBody */
      body?: PersonType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/person_types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы персонала
   */
  static usersPersonTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип персонала. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/person_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы персонала
   */
  static usersPersonTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип персонала. */
      id: number;
      /** requestBody */
      body?: PersonType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/person_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы персонала
   */
  static usersPersonTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип персонала. */
      id: number;
      /** requestBody */
      body?: PatchedPersonType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/person_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы персонала
   */
  static usersPersonTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип персонала. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/person_types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Персонал
   */
  static usersPersonsList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Организация" */
      organization?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/persons/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        organization: params['organization'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Персонал
   */
  static usersPersonsCreate(
    params: {
      /** requestBody */
      body?: Person;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Person> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/persons/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Персонал
   */
  static usersPersonsRetrieve(
    params: {
      /** A unique integer value identifying this Персонал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Person> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Персонал
   */
  static usersPersonsUpdate(
    params: {
      /** A unique integer value identifying this Персонал. */
      id: number;
      /** requestBody */
      body?: Person;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Person> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Персонал
   */
  static usersPersonsPartialUpdate(
    params: {
      /** A unique integer value identifying this Персонал. */
      id: number;
      /** requestBody */
      body?: PatchedPerson;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Person> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Персонал
   */
  static usersPersonsDestroy(
    params: {
      /** A unique integer value identifying this Персонал. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Должности
   */
  static usersPositionsList(options: IRequestOptions = {}): Promise<PositionList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/positions/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Должности
   */
  static usersPositionsCreate(
    params: {
      /** requestBody */
      body?: Position;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Position> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/positions/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Должности
   */
  static usersPositionsRetrieve(
    params: {
      /** A unique integer value identifying this Должность. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Position> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/positions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Должности
   */
  static usersPositionsUpdate(
    params: {
      /** A unique integer value identifying this Должность. */
      id: number;
      /** requestBody */
      body?: Position;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Position> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/positions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Должности
   */
  static usersPositionsPartialUpdate(
    params: {
      /** A unique integer value identifying this Должность. */
      id: number;
      /** requestBody */
      body?: PatchedPosition;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Position> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/positions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Должности
   */
  static usersPositionsDestroy(
    params: {
      /** A unique integer value identifying this Должность. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/positions/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ответственные лица
   */
  static usersResponsiblePersonsList(
    params: {
      /** Заявки юридических лиц */
      canEntityStatement?: boolean;
      /** Согласования заявлений от ФКУ "Росдормониторинг" */
      canFederalStatement?: boolean;
      /** Заявки ИП/Физических лиц */
      canIndividualStatement?: boolean;
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Пользователь" */
      user?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponsiblePersonList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/responsible_persons/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        can_entity_statement: params['canEntityStatement'],
        can_federal_statement: params['canFederalStatement'],
        can_individual_statement: params['canIndividualStatement'],
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        representation: params['representation'],
        user: params['user'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ответственные лица
   */
  static usersResponsiblePersonsCreate(
    params: {
      /** requestBody */
      body?: ResponsiblePerson;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponsiblePerson> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/responsible_persons/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ответственные лица
   */
  static usersResponsiblePersonsRetrieve(
    params: {
      /** A unique integer value identifying this Ответственное лицо. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponsiblePerson> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/responsible_persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ответственные лица
   */
  static usersResponsiblePersonsUpdate(
    params: {
      /** A unique integer value identifying this Ответственное лицо. */
      id: number;
      /** requestBody */
      body?: ResponsiblePerson;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponsiblePerson> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/responsible_persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ответственные лица
   */
  static usersResponsiblePersonsPartialUpdate(
    params: {
      /** A unique integer value identifying this Ответственное лицо. */
      id: number;
      /** requestBody */
      body?: PatchedResponsiblePerson;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponsiblePerson> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/responsible_persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Ответственные лица
   */
  static usersResponsiblePersonsDestroy(
    params: {
      /** A unique integer value identifying this Ответственное лицо. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/responsible_persons/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersTokenCreate(
    params: {
      /** requestBody */
      body?: TokenObtainPair;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TokenObtainPair> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/token/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersTokenRefreshCreate(
    params: {
      /** requestBody */
      body?: TokenRefresh;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TokenRefresh> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/token/refresh/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VehiclesService {
  /**
   * Транспортные средства
   */
  static vehiclesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Государственный регистрационный номер */
      registrationNumber?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Тип транспортного средства" */
      vehicleType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        registration_number: params['registrationNumber'],
        representation: params['representation'],
        vehicle_type: params['vehicleType'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Транспортные средства
   */
  static vehiclesCreate(
    params: {
      /** requestBody */
      body?: Vehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Транспортные средства
   */
  static vehiclesRetrieve(
    params: {
      /** A unique integer value identifying this Транспортное средство. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Транспортные средства
   */
  static vehiclesUpdate(
    params: {
      /** A unique integer value identifying this Транспортное средство. */
      id: number;
      /** requestBody */
      body?: Vehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Транспортные средства
   */
  static vehiclesPartialUpdate(
    params: {
      /** A unique integer value identifying this Транспортное средство. */
      id: number;
      /** requestBody */
      body?: PatchedVehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Vehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Транспортные средства
   */
  static vehiclesDestroy(
    params: {
      /** A unique integer value identifying this Транспортное средство. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы / Транспортные средства
   */
  static vehiclesDevicesVehiclesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Фильтр по полям модели "Навигационный терминал" */
      device?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Фильтр по полям модели "Организация" */
      organization?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
      /** Фильтр по полям модели "Транспортное средство" */
      vehicle?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceVehicleList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/devices_vehicles/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        device: params['device'],
        history: params['history'],
        id: params['id'],
        ordering: params['ordering'],
        organization: params['organization'],
        representation: params['representation'],
        vehicle: params['vehicle'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы / Транспортные средства
   */
  static vehiclesDevicesVehiclesCreate(
    params: {
      /** requestBody */
      body?: DeviceVehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/devices_vehicles/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы / Транспортные средства
   */
  static vehiclesDevicesVehiclesRetrieve(
    params: {
      /** A unique integer value identifying this Навигационные терминалы / Транспортное средство. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/devices_vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы / Транспортные средства
   */
  static vehiclesDevicesVehiclesUpdate(
    params: {
      /** A unique integer value identifying this Навигационные терминалы / Транспортное средство. */
      id: number;
      /** requestBody */
      body?: DeviceVehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/devices_vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы / Транспортные средства
   */
  static vehiclesDevicesVehiclesPartialUpdate(
    params: {
      /** A unique integer value identifying this Навигационные терминалы / Транспортное средство. */
      id: number;
      /** requestBody */
      body?: PatchedDeviceVehicle;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeviceVehicle> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/devices_vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Навигационные терминалы / Транспортные средства
   */
  static vehiclesDevicesVehiclesDestroy(
    params: {
      /** A unique integer value identifying this Навигационные терминалы / Транспортное средство. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/devices_vehicles/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы транспортных средств
   */
  static vehiclesTypesList(
    params: {
      /** Возвращает все удаленные объекты */
      deleted?: string;
      /** Возвращает историю изменения объектов */
      history?: string;
      /** Идентификатор */
      id?: number;
      /** Наименование */
      name?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** Возвращает объекты в упрощенном виде */
      representation?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleTypeList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/types/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        deleted: params['deleted'],
        history: params['history'],
        id: params['id'],
        name: params['name'],
        ordering: params['ordering'],
        representation: params['representation'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы транспортных средств
   */
  static vehiclesTypesCreate(
    params: {
      /** requestBody */
      body?: VehicleType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/types/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы транспортных средств
   */
  static vehiclesTypesRetrieve(
    params: {
      /** A unique integer value identifying this Тип транспортного средства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы транспортных средств
   */
  static vehiclesTypesUpdate(
    params: {
      /** A unique integer value identifying this Тип транспортного средства. */
      id: number;
      /** requestBody */
      body?: VehicleType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы транспортных средств
   */
  static vehiclesTypesPartialUpdate(
    params: {
      /** A unique integer value identifying this Тип транспортного средства. */
      id: number;
      /** requestBody */
      body?: PatchedVehicleType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VehicleType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Типы транспортных средств
   */
  static vehiclesTypesDestroy(
    params: {
      /** A unique integer value identifying this Тип транспортного средства. */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/vehicles/types/{id}/';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InternalService {
  /**
   *
   */
  static internalApiApplicationsList(options: IRequestOptions = {}): Promise<ApplicationForActiveEkaterinburgOutput[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/internal/api/applications/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static internalApiApplicationsBatchCreateCreate(
    params: {
      /** requestBody */
      body?: ApplicationForActiveEkaterinburgList;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationForActiveEkaterinburgList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/internal/api/applications/batch_create/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static internalApiCamerasList(options: IRequestOptions = {}): Promise<CameraNeuralService[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/internal/api/cameras/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static internalApiCamerasMasksRetrieve(
    params: {
      /**  */
      cameraId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CameraDetectionMasksNeuralService> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/internal/api/cameras/{camera_id}/masks/';
      url = url.replace('{camera_id}', params['cameraId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Создать список распознаваний
   */
  static internalApiDetectionsBulkCreateCreate(
    params: {
      /** requestBody */
      body?: Detection[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Detection[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/internal/api/detections/bulk_create/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Обновить статус проверочных заданий на распознавание
   */
  static internalApiDetectionsTasksStatusUpdateCreate(
    params: {
      /** requestBody */
      body?: DetectionTaskStatus[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetectionTask[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/internal/api/detections/tasks/status_update/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Получить email пользователя
   */
  static internalApiPtolemyUserEmailRetrieve(
    params: {
      /** Логин пользователя */
      username: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserEmail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/internal/api/ptolemy/user_email/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { username: params['username'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AdministrationAssignments {
  /**  */
  id: number;

  /**  */
  number: string;

  /**  */
  date: Date;

  /**  */
  description: string;

  /**  */
  status: CombinedStatusTypes;
}

export interface AdministrationAssignmentsFiles {
  /**  */
  id: number;

  /**  */
  administration_assignment: number;

  /**  */
  attached_file: string;

  /**  */
  file_name: string;
}

export interface AdministrationAssignmentsFilesList {
  /**  */
  count: number;

  /**  */
  results: AdministrationAssignmentsFiles[];
}

export interface AdministrationAssignmentsList {
  /**  */
  count: number;

  /**  */
  results: AdministrationAssignments[];
}

export interface AdministrationAssignmentsRoad {
  /**  */
  id: number;

  /**  */
  deleted: Date;

  /**  */
  line_path: string;

  /**  */
  start_km: number;

  /**  */
  end_km: number;

  /**  */
  administration_assignment: number;

  /**  */
  road: number;
}

export interface AdministrationAssignmentsRoadList {
  /**  */
  count: number;

  /**  */
  results: AdministrationAssignmentsRoad[];
}

export interface AdvertShield {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface AdvertShieldList {
  /**  */
  count: number;

  /**  */
  results: AdvertShield[];
}

export interface Application {
  /**  */
  id: number;

  /**  */
  application_source?: number;

  /**  */
  application_source_name: string;

  /**  */
  object_type?: CombinedObjectTypeTypes;

  /**  */
  application_type: number;

  /**  */
  object_characteristic?: string;

  /**  */
  application_name: string;

  /**  */
  description: string;

  /**  */
  address?: string;

  /**  */
  district?: number;

  /**  */
  task?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  author: number;

  /**  */
  created_at?: Date;

  /**  */
  author_full_name: string;

  /**  */
  application_type_name: string;

  /**  */
  work_type: string;

  /**  */
  finish_of_work_planned_date: string;

  /**  */
  appointed_team?: number;

  /**  */
  appointed_team_datetime: Date;

  /**  */
  appointed_team_name: string;

  /**  */
  responsible_master_name: string;

  /**  */
  point: string;

  /**  */
  closed_status_time: Date;

  /**  */
  application_comment: ApplicationCommentForNesting[];

  /**  */
  order: number;

  /**  */
  on_change_allowed_statuses: string[];

  /**  */
  detection?: number;

  /**  */
  has_photos: boolean;
}

export interface ApplicationChatRoom {
  /**  */
  id: number;

  /**  */
  application: number;

  /**  */
  application_name: string;

  /**  */
  message_history?: object;

  /**  */
  status?: CombinedStatusTypes;
}

export interface ApplicationChatRoomAuthorizationInput {
  /**  */
  user_token: string;

  /**  */
  chat_id: number;
}

export interface ApplicationChatRoomAuthorizationOutput {
  /**  */
  username: string;

  /**  */
  first_name: string;

  /**  */
  middle_name: string;

  /**  */
  last_name: string;

  /**  */
  is_dispatcher: boolean;
}

export interface ApplicationChatRoomList {
  /**  */
  count: number;

  /**  */
  results: ApplicationChatRoom[];
}

export interface ApplicationChatRoomSystemMessageInput {
  /**  */
  chats_ids: number[];

  /**  */
  message: string;
}

export interface ApplicationChatRoomUser {
  /**  */
  id: number;

  /**  */
  chat_room: number;

  /**  */
  chat_room_name: string;

  /**  */
  user: number;

  /**  */
  user_name: string;
}

export interface ApplicationChatRoomUserList {
  /**  */
  count: number;

  /**  */
  results: ApplicationChatRoomUser[];
}

export interface ApplicationComment {
  /**  */
  id: number;

  /**  */
  application: number;

  /**  */
  application_name: string;

  /**  */
  author: number;

  /**  */
  author_full_name: string;

  /**  */
  comment?: string;
}

export interface ApplicationCommentForNesting {
  /**  */
  id: number;

  /**  */
  author: number;

  /**  */
  author_full_name: string;

  /**  */
  comment?: string;
}

export interface ApplicationCommentList {
  /**  */
  count: number;

  /**  */
  results: ApplicationComment[];
}

export interface ApplicationForActiveEkaterinburgInput {
  /**  */
  ae_application_id: string;

  /**  */
  address: string;

  /**  */
  description: string;

  /**  */
  point: string;

  /**  */
  photos: string[];
}

export interface ApplicationForActiveEkaterinburgList {
  /**  */
  data: ApplicationForActiveEkaterinburgInput[];
}

export interface ApplicationForActiveEkaterinburgOutput {
  /**  */
  id: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  last_comment: string;

  /**  */
  photos: ApplicationPhotoForActiveEkaterinburg[];
}

export interface ApplicationList {
  /**  */
  count: number;

  /**  */
  results: Application[];
}

export interface ApplicationObject {
  /**  */
  id: number;

  /**  */
  object_class: string;

  /**  */
  object_name: string;

  /**  */
  additional_info: string;
}

export interface ApplicationObjectCharacteristicData {
  /**  */
  name: string;

  /**  */
  point?: any | null[];
}

export interface ApplicationOrder {
  /**  */
  id: number;

  /**  */
  order: number;
}

export interface ApplicationOrderList {
  /**  */
  data: ApplicationOrder[];
}

export interface ApplicationPhoto {
  /**  */
  id: number;

  /**  */
  photo: string;

  /**  */
  application: number;

  /**  */
  application_name: string;
}

export interface ApplicationPhotoCreate {
  /**  */
  id: number;

  /**  */
  photo: string;

  /**  */
  application: number;

  /**  */
  application_name: string;
}

export interface ApplicationPhotoForActiveEkaterinburg {
  /**  */
  photo: string;
}

export interface ApplicationPhotoList {
  /**  */
  count: number;

  /**  */
  results: ApplicationPhoto[];
}

export interface ApplicationSource {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface ApplicationSourceList {
  /**  */
  count: number;

  /**  */
  results: ApplicationSource[];
}

export interface ApplicationStatus {
  /**  */
  id: number;

  /**  */
  status: StatusF30Enum;
}

export interface ApplicationStatusList {
  /**  */
  data: ApplicationStatus[];
}

export interface ApplicationType {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  days_limit: number;

  /**  */
  team_of_masters: number;

  /**  */
  team_of_masters_name: string;

  /**  */
  work_type: CombinedWorkTypeTypes;

  /**  */
  object_type: CombinedObjectTypeTypes;
}

export interface ApplicationTypeList {
  /**  */
  count: number;

  /**  */
  results: ApplicationType[];
}

export interface AxisRadius {
  /**  */
  id: number;

  /**  */
  radius?: number;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface AxisRadiusList {
  /**  */
  count: number;

  /**  */
  results: AxisRadius[];
}

export interface AxisSlope {
  /**  */
  id: number;

  /**  */
  slope?: number;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface AxisSlopeList {
  /**  */
  count: number;

  /**  */
  results: AxisSlope[];
}

export interface AxisVisibility {
  /**  */
  id: number;

  /**  */
  visibility?: number;

  /**  */
  direction: CombinedDirectionTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface AxisVisibilityList {
  /**  */
  count: number;

  /**  */
  results: AxisVisibility[];
}

export interface BillingEntityStatement {
  /**  */
  id: number;

  /**  */
  payment_method: CombinedPaymentMethodTypes;

  /**  */
  unique_id: string;

  /**  */
  invoice_amount: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  payment_type: number;

  /**  */
  payment_type_name: string;

  /**  */
  payment_type_recepient_name: string;

  /**  */
  payment_type_kpp: string;

  /**  */
  payment_type_inn: string;

  /**  */
  payment_type_oktmo: string;

  /**  */
  payment_type_okpo: number;

  /**  */
  payment_type_account_number: number;

  /**  */
  payment_type_budget_classification_code: number;

  /**  */
  payment_type_correspondent_account: string;

  /**  */
  payment_type_bik: number;

  /**  */
  amount_paid?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  is_paid?: boolean;

  /**  */
  created_at?: Date;

  /**  */
  errors: string;
}

export interface BillingEntityStatementList {
  /**  */
  count: number;

  /**  */
  results: BillingEntityStatement[];
}

export interface BillingIndividualStatement {
  /**  */
  id: number;

  /**  */
  payment_method: CombinedPaymentMethodTypes;

  /**  */
  unique_id: string;

  /**  */
  invoice_amount: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  payment_type: number;

  /**  */
  payment_type_name: string;

  /**  */
  payment_type_recepient_name: string;

  /**  */
  payment_type_kpp: string;

  /**  */
  payment_type_inn: string;

  /**  */
  payment_type_oktmo: string;

  /**  */
  payment_type_okpo: number;

  /**  */
  payment_type_account_number: number;

  /**  */
  payment_type_budget_classification_code: number;

  /**  */
  payment_type_correspondent_account: string;

  /**  */
  payment_type_bik: number;

  /**  */
  amount_paid?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  is_paid?: boolean;

  /**  */
  created_at?: Date;

  /**  */
  errors: string;
}

export interface BillingIndividualStatementList {
  /**  */
  count: number;

  /**  */
  results: BillingIndividualStatement[];
}

export interface BusShelter {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  point: string;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface BusShelterList {
  /**  */
  count: number;

  /**  */
  results: BusShelter[];
}

export interface BusStop {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  direction?: CombinedDirectionTypes;

  /**  */
  point: string;

  /**  */
  type_bus?: CombinedTypeBusTypes;

  /**  */
  year_of_creation?: number;

  /**  */
  count_canopy?: number;

  /**  */
  type_canopy?: CombinedTypeCanopyTypes;

  /**  */
  district?: number;

  /**  */
  district_name: string;

  /**  */
  is_checked?: boolean;

  /**  */
  route_list?: string;

  /**  */
  balance_holder?: string;

  /**  */
  status?: number;

  /**  */
  display?: boolean;

  /**  */
  information_board?: number;

  /**  */
  is_check_pocket?: boolean;

  /**  */
  infrastructure_for_invalids?: string;

  /**  */
  is_bus?: boolean;

  /**  */
  is_troll?: boolean;

  /**  */
  is_tram?: boolean;

  /**  */
  with_platform?: boolean;

  /**  */
  with_parking?: boolean;

  /**  */
  with_pavilion?: boolean;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  code?: number;

  /**  */
  okato?: number;

  /**  */
  address?: string;

  /**  */
  installation_date?: Date;

  /**  */
  dismantling_date?: Date;

  /**  */
  comment?: string;

  /**  */
  adverting_object?: boolean;

  /**  */
  trading_object?: boolean;

  /**  */
  has_route: boolean;
}

export interface BusStopFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file: string;

  /**  */
  road_cons_object: number;

  /**  */
  date_of_download: Date;

  /**  */
  user_name: string;
}

export interface BusStopFileList {
  /**  */
  count: number;

  /**  */
  results: BusStopFile[];
}

export interface BusStopList {
  /**  */
  count: number;

  /**  */
  results: BusStop[];
}

export interface CabinetForCableCommunications {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  point: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface CabinetForCableCommunicationsList {
  /**  */
  count: number;

  /**  */
  results: CabinetForCableCommunications[];
}

export interface CableTram {
  /**  */
  id: number;

  /**  */
  line: number;

  /**  */
  model: string;

  /**  */
  length: number;

  /**  */
  line_path: string;

  /**  */
  start: number;

  /**  */
  end: number;

  /**  */
  section_cable?: number;

  /**  */
  line_name: string;

  /**  */
  hanging_height?: number;
}

export interface CableTramList {
  /**  */
  count: number;

  /**  */
  results: CableTram[];
}

export interface CableTrolleybus {
  /**  */
  id: number;

  /**  */
  line: number;

  /**  */
  line_name: string;

  /**  */
  model: string;

  /**  */
  length: number;

  /**  */
  line_path: string;

  /**  */
  start: number;

  /**  */
  end: number;

  /**  */
  section_cable?: number;

  /**  */
  line_length: number;

  /**  */
  line_of_failure_name: string;

  /**  */
  line_of_disable_name: string;

  /**  */
  reserve_substation_name: string;

  /**  */
  substation_name: string;

  /**  */
  throughput?: number;

  /**  */
  hanging_height?: number;
}

export interface CableTrolleybusList {
  /**  */
  count: number;

  /**  */
  results: CableTrolleybus[];
}

export interface CalculationOfHarmEntityStatement {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  statement: number;

  /**  */
  length_road: number;

  /**  */
  road_type: CombinedRoadTypeTypes;

  /**  */
  coef_federal_district: number;

  /**  */
  coef_of_climatic_zones: CombinedCoefOfClimaticZonesTypes;

  /**  */
  formula_type: CombinedFormulaTypeTypes;

  /**  */
  normative_load: number;

  /**  */
  recommended_harm_calc?: number;

  /**  */
  fact_harm_calc?: number;

  /**  */
  index_deflator: number;
}

export interface CalculationOfHarmEntityStatementList {
  /**  */
  count: number;

  /**  */
  results: CalculationOfHarmEntityStatement[];
}

export interface CalculationOfHarmFederalStatement {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  statement: number;

  /**  */
  length_road: number;

  /**  */
  road_type: CombinedRoadTypeTypes;

  /**  */
  coef_federal_district: number;

  /**  */
  coef_of_climatic_zones: CombinedCoefOfClimaticZonesTypes;

  /**  */
  formula_type: CombinedFormulaTypeTypes;

  /**  */
  normative_load: number;

  /**  */
  recommended_harm_calc?: number;

  /**  */
  fact_harm_calc?: number;

  /**  */
  index_deflator: number;
}

export interface CalculationOfHarmFederalStatementList {
  /**  */
  count: number;

  /**  */
  results: CalculationOfHarmFederalStatement[];
}

export interface CalculationOfHarmIndividualStatement {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  statement: number;

  /**  */
  length_road: number;

  /**  */
  road_type: CombinedRoadTypeTypes;

  /**  */
  coef_federal_district: number;

  /**  */
  coef_of_climatic_zones: CombinedCoefOfClimaticZonesTypes;

  /**  */
  formula_type: CombinedFormulaTypeTypes;

  /**  */
  normative_load: number;

  /**  */
  recommended_harm_calc?: number;

  /**  */
  fact_harm_calc?: number;

  /**  */
  index_deflator: number;
}

export interface CalculationOfHarmIndividualStatementList {
  /**  */
  count: number;

  /**  */
  results: CalculationOfHarmIndividualStatement[];
}

export interface Camera {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  address?: string;

  /**  */
  point: string;

  /**  */
  url: string;

  /**  */
  stream_url?: string;

  /**  */
  has_detection_masks: boolean;

  /**  */
  is_working: boolean;

  /**  */
  azimuth?: number;

  /**  */
  angle_of_view?: number;
}

export interface CameraDetectionMasksNeuralService {
  /**  */
  cam_id: string;

  /**  */
  masks: DetectonMaskNeuralService[];
}

export interface CameraLastFrame {
  /**  */
  image: string;
}

export interface CameraList {
  /**  */
  count: number;

  /**  */
  results: Camera[];
}

export interface CameraNeuralService {
  /**  */
  external_id: string;

  /**  */
  url: string;
}

export interface CameraWidget {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  widget_type: CombinedWidgetTypeTypes;

  /**  */
  chart_type?: CombinedChartTypeTypes;

  /**  */
  data_source: CombinedDataSourceTypes;

  /**  */
  cameras: number[];

  /**  */
  widget_data: CameraWidgetData[];

  /**  */
  counting_method: CombinedCountingMethodTypes;

  /**  */
  counting_interval: CombinedCountingIntervalTypes;

  /**  */
  counting_period: CombinedCountingPeriodTypes;

  /**  */
  update_interval: CombinedUpdateIntervalTypes;

  /**  */
  show_captions?: boolean;

  /**  */
  show_grid_lines_and_divisions?: boolean;
}

export interface CameraWidgetCalculatedData {
  /**  */
  widget_id: number;

  /**  */
  widget_data: CameraWidgetDataResults[];
}

export interface CameraWidgetData {
  /**  */
  id: number;

  /**  */
  data_type: CombinedDataTypeTypes;

  /**  */
  data_name: string;

  /**  */
  masks: number[];

  /**  */
  data_color?: string;
}

export interface CameraWidgetDataByDatetimeInput {
  /**  */
  end_datetime: Date;
}

export interface CameraWidgetDataResultByDatetime {
  /**  */
  datetime: Date;

  /**  */
  result: number;
}

export interface CameraWidgetDataResults {
  /**  */
  widget_data_id: number;

  /**  */
  data: CameraWidgetDataResultByDatetime[];
}

export interface CameraWidgetList {
  /**  */
  count: number;

  /**  */
  results: CameraWidget[];
}

export interface CitizenAppeal {
  /**  */
  id: number;

  /**  */
  description: string;

  /**  */
  address: string;

  /**  */
  coords: string;
}

export interface CitizenAppealList {
  /**  */
  count: number;

  /**  */
  results: CitizenAppeal[];
}

export interface CityDistrict {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  polygon: string;

  /**  */
  area: number;

  /**  */
  perimeter: number;

  /**  */
  okato?: number;
}

export interface CityDistrictList {
  /**  */
  count: number;

  /**  */
  results: CityDistrict[];
}

export interface CoefForCalcHarm {
  /**  */
  id: number;

  /**  */
  federal_district: CombinedFederalDistrictTypes;

  /**  */
  coef_of_climatic_zones: number;

  /**  */
  coef_of_repairs: number;

  /**  */
  coef_of_influence_mass: number;
}

export interface CoefForCalcHarmList {
  /**  */
  count: number;

  /**  */
  results: CoefForCalcHarm[];
}

export interface CommunicationPole {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface CommunicationPoleList {
  /**  */
  count: number;

  /**  */
  results: CommunicationPole[];
}

export interface ConditionOfPavement {
  /**  */
  id: number;

  /**  */
  segment: number;

  /**  */
  segment_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length?: number;

  /**  */
  condition: string;

  /**  */
  diagnosis_date?: Date;

  /**  */
  contractor: number;

  /**  */
  contractor_name: string;
}

export interface ConditionOfPavementList {
  /**  */
  count: number;

  /**  */
  results: ConditionOfPavement[];
}

export interface Constant {
  /**  */
  id: number;

  /**  */
  name: CombinedNameTypes;

  /**  */
  value: number;
}

export interface ConstantList {
  /**  */
  count: number;

  /**  */
  results: Constant[];
}

export interface ContourLawn {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  polygon: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface ContourLawnList {
  /**  */
  count: number;

  /**  */
  results: ContourLawn[];
}

export interface CrossRoad {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  regulated_type?: CombinedRegulatedTypeTypes;

  /**  */
  point: string;

  /**  */
  tl_controller?: number;

  /**  */
  tl_controller_name: string;

  /**  */
  first_segment: number;

  /**  */
  first_segment_name: string;

  /**  */
  first_road_name: string;

  /**  */
  second_segment: number;

  /**  */
  second_segment_name: string;

  /**  */
  second_road_name: string;
}

export interface CrossRoadList {
  /**  */
  count: number;

  /**  */
  results: CrossRoad[];
}

export interface Curbstone {
  /**  */
  id: number;

  /**  */
  type?: CombinedTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface CurbstoneList {
  /**  */
  count: number;

  /**  */
  results: Curbstone[];
}

export interface DataSuggestAddress {
  /**  */
  city: string;

  /**  */
  street?: string;

  /**  */
  house?: string;

  /**  */
  block?: string;
}

export interface DestructionAct {
  /**  */
  id: number;

  /**  */
  number: number;

  /**  */
  reason: string;

  /**  */
  date_of_act: Date;

  /**  */
  responsible_person: string;

  /**  */
  commission_persons: string;

  /**  */
  date_of_order: Date;

  /**  */
  number_of_order: string;

  /**  */
  from_date: Date;

  /**  */
  to_date: Date;

  /**  */
  created_at: Date;

  /**  */
  forms?: number[];
}

export interface DestructionActList {
  /**  */
  count: number;

  /**  */
  results: DestructionAct[];
}

export interface Detection {
  /**  */
  id: number;

  /**  */
  detection_type: CombinedDetectionTypeTypes;

  /**  */
  violation_type: string;

  /**  */
  violation_type_display_name: string;

  /**  */
  point: string;

  /**  */
  nearest_address?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  image_src?: string;

  /**  */
  external_id: number;

  /**  */
  created_at: Date;

  /**  */
  comment?: string;

  /**  */
  validation_status?: CombinedValidationStatusTypes;

  /**  */
  bbox?: number[];

  /**  */
  road_kilometer: string;

  /**  */
  hsc_type?: CombinedHscTypeTypes;
}

export interface DetectionList {
  /**  */
  count: number;

  /**  */
  results: Detection[];
}

export interface DetectionMask {
  /**  */
  id?: number;

  /**  */
  name: string;

  /**  */
  mask_type: CombinedMaskTypeTypes;

  /**  */
  roi_points: number[];

  /**  */
  control_line_points?: number[];

  /**  */
  measure_line_points?: number[];

  /**  */
  camera: number;
}

export interface DetectionMaskList {
  /**  */
  masks: DetectionMask[];
}

export interface DetectionMasksGrouped {
  /**  */
  mask_type: DetectionMaskList;
}

export interface DetectionRealObject {
  /**  */
  id: number;

  /**  */
  type: string;

  /**  */
  point: string;
}

export interface DetectionShort {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  image_src?: string;

  /**  */
  bbox?: number[];
}

export interface DetectionTask {
  /**  */
  id: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  created_at: Date;

  /**  */
  author: number;

  /**  */
  author_name: string;

  /**  */
  application: number;
}

export interface DetectionTaskList {
  /**  */
  count: number;

  /**  */
  results: DetectionTask[];
}

export interface DetectionTaskStatus {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;
}

export interface DetectonMasSettingskNeuralService {
  /**  */
  mask_id: string;

  /**  */
  roi_points: string[];

  /**  */
  control_line_points?: string[];

  /**  */
  measure_line_points?: string[];

  /**  */
  object_type_list?: string[];
}

export interface DetectonMaskNeuralService {
  /**  */
  mask_name: MaskNameEnum;

  /**  */
  mask_settings: DetectonMasSettingskNeuralService;
}

export interface Device {
  /**  */
  id: number;

  /**  */
  number: string;

  /**  */
  rnic_number?: string;

  /**  */
  device_type?: number;

  /**  */
  device_type_name: string;

  /**  */
  organization?: number;

  /**  */
  organization_name: string;

  /**  */
  device_fullname?: number;

  /**  */
  device_fullname_name: string;

  /**  */
  alarm_button_address?: number;

  /**  */
  ip_addr?: string;

  /**  */
  imei?: string;

  /**  */
  server_password?: string;

  /**  */
  disabled?: boolean;

  /**  */
  relay_address?: string;

  /**  */
  firmware_version?: string;

  /**  */
  voice_call_support?: boolean;

  /**  */
  timezone?: CombinedTimezoneTypes;

  /**  */
  manufacture_date: Date;

  /**  */
  banned?: boolean;

  /**  */
  comment?: string;

  /**  */
  in_params?: object;

  /**  */
  event_params?: object;

  /**  */
  extra?: object;
}

export interface DeviceFullname {
  /**  */
  id: number;

  /**  */
  device_fullname: string;
}

export interface DeviceFullnameList {
  /**  */
  count: number;

  /**  */
  results: DeviceFullname[];
}

export interface DeviceList {
  /**  */
  count: number;

  /**  */
  results: Device[];
}

export interface DeviceType {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  library: CombinedLibraryTypes;

  /**  */
  firm?: string;

  /**  */
  n_analog_in?: number;

  /**  */
  n_digital_in?: number;

  /**  */
  n_counter_in?: number;

  /**  */
  alarm_button?: boolean;
}

export interface DeviceTypeList {
  /**  */
  count: number;

  /**  */
  results: DeviceType[];
}

export interface DeviceVehicle {
  /**  */
  id: number;

  /**  */
  vehicle: number;

  /**  */
  vehicle_name: string;

  /**  */
  device: number;

  /**  */
  device_name: string;

  /**  */
  organization?: number;

  /**  */
  organization_name: string;

  /**  */
  start_date?: Date;

  /**  */
  end_date?: Date;
}

export interface DeviceVehicleList {
  /**  */
  count: number;

  /**  */
  results: DeviceVehicle[];
}

export interface District {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  district_code?: number;

  /**  */
  state: number;

  /**  */
  state_name: string;
}

export interface DistrictList {
  /**  */
  count: number;

  /**  */
  results: District[];
}

export interface DividingLine {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  dividing_line_type?: number;

  /**  */
  dividing_line_type_name: string;

  /**  */
  roadway?: number;

  /**  */
  start?: number;

  /**  */
  end?: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface DividingLineList {
  /**  */
  count: number;

  /**  */
  results: DividingLine[];
}

export interface DividingLineType {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  width?: number;

  /**  */
  height?: number;
}

export interface DividingLineTypeList {
  /**  */
  count: number;

  /**  */
  results: DividingLineType[];
}

export interface DynamicFilter {
  /**  */
  city_district_ids?: CombinedCityDistrictIdsTypes;
}

export interface DynamicFilterItem {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  label: string;

  /**  */
  operator: string;

  /**  */
  show_values: boolean;

  /**  */
  widget: string;

  /**  */
  widget_data: string;

  /**  */
  widget_initial: object;

  /**  */
  is_free: boolean;

  /**  */
  required: boolean;

  /**  */
  advanced: boolean;

  /**  */
  value: number[];
}

export interface EarthBed {
  /**  */
  id: number;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface EarthBedList {
  /**  */
  count: number;

  /**  */
  results: EarthBed[];
}

export interface ElectricPole {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface ElectricPoleList {
  /**  */
  count: number;

  /**  */
  results: ElectricPole[];
}

export interface EngineeringConstruction {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  construction_type: CombinedConstructionTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  width?: number;

  /**  */
  height?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  location?: string;

  /**  */
  year_of_construction?: number;

  /**  */
  space_length?: number;

  /**  */
  design_load?: string;

  /**  */
  restriction_on_the_mass_of_the_car?: number;

  /**  */
  carriageway_dimension?: number;

  /**  */
  building_material?: CombinedBuildingMaterialTypes;

  /**  */
  type_of_coverage?: CombinedTypeOfCoverageTypes;

  /**  */
  commissioned?: boolean;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface EngineeringConstructionList {
  /**  */
  count: number;

  /**  */
  results: EngineeringConstruction[];
}

export interface EntityRoadsStatement {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  length: number;

  /**  */
  importance: string;

  /**  */
  road: number;

  /**  */
  road_name: string;
}

export interface EntityRoadsStatementList {
  /**  */
  count: number;

  /**  */
  results: EntityRoadsStatement[];
}

export interface EntityStatement {
  /**  */
  id: number;

  /**  */
  uuid_statement: string;

  /**  */
  number_of_statement?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  comment?: string;

  /**  */
  bank_name?: string;

  /**  */
  phone: string;

  /**  */
  email?: string;

  /**  */
  bank_code?: number;

  /**  */
  inn?: string;

  /**  */
  payment_account?: string;

  /**  */
  correspondent_account?: string;

  /**  */
  vehicle_owner_first_name?: string;

  /**  */
  vehicle_owner_surname?: string;

  /**  */
  vehicle_owner_middle_name?: string;

  /**  */
  vehicle_owner_inn?: string;

  /**  */
  vehicle_owner_address?: string;

  /**  */
  vehicle_owner_phone?: string;

  /**  */
  vehicle_owner_ogrnip?: string;

  /**  */
  start_date: Date;

  /**  */
  end_date: Date;

  /**  */
  number_of_trips: number;

  /**  */
  is_devided: boolean;

  /**  */
  name_cargo: string;

  /**  */
  width_cargo: number;

  /**  */
  height_cargo: number;

  /**  */
  length_cargo: number;

  /**  */
  overhang_length_cargo?: number;

  /**  */
  weight_cargo: number;

  /**  */
  width_road_train: number;

  /**  */
  height_road_train: number;

  /**  */
  length_road_train: number;

  /**  */
  min_turning_radius: number;

  /**  */
  need_escort?: boolean;

  /**  */
  type_escort?: string;

  /**  */
  weight_road_train: number;

  /**  */
  created_at: Date;

  /**  */
  modified_at: Date;

  /**  */
  is_route: boolean;

  /**  */
  is_vehicle_owner?: boolean;

  /**  */
  type_of_document_for_vehicle?: CombinedTypeOfDocumentForVehicleTypes;

  /**  */
  number_of_document_for_vehicle?: number;

  /**  */
  date_of_document_for_vehicle?: Date;

  /**  */
  vehicle_company_full_name?: string;

  /**  */
  vehicle_company_phone?: string;

  /**  */
  vehicle_company_email?: string;

  /**  */
  vehicle_company_address?: string;

  /**  */
  vehicle_company_name?: string;

  /**  */
  description_route: string;

  /**  */
  is_backward: boolean;

  /**  */
  min_speed: number;

  /**  */
  transportation_type?: CombinedTransportationTypeTypes;

  /**  */
  weight_road_train_without_cargo?: number;

  /**  */
  kpp?: string;

  /**  */
  okpo?: string;

  /**  */
  type_of_vehicle_owner?: CombinedTypeOfVehicleOwnerTypes;

  /**  */
  registered_time?: Date;

  /**  */
  end_time?: Date;

  /**  */
  payment_guarant_first_name?: string;

  /**  */
  payment_guarant_last_name?: string;

  /**  */
  payment_guarant_middle_name?: string;

  /**  */
  payment_guarant_position?: string;

  /**  */
  special_driving_conditions?: string;

  /**  */
  signer?: number;

  /**  */
  name_signer: string;

  /**  */
  is_heavy_vehicle?: boolean;

  /**  */
  position_signer: string;

  /**  */
  handling_organization_name: string;

  /**  */
  handling_organization?: number;

  /**  */
  responsible_user?: number;

  /**  */
  responsible_user_name: string;

  /**  */
  vehicle_parameters: VehicleParametersEntityStatement[];

  /**  */
  name: string;

  /**  */
  address?: string;

  /**  */
  ogrn: string;

  /**  */
  full_name?: string;

  /**  */
  comment_representative?: string;
}

export interface EntityStatementAxleLoadOverload {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  overload: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface EntityStatementAxleLoadOverloadList {
  /**  */
  count: number;

  /**  */
  results: EntityStatementAxleLoadOverload[];
}

export interface EntityStatementDimensionsHeightOversize {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  height_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface EntityStatementDimensionsHeightOversizeList {
  /**  */
  count: number;

  /**  */
  results: EntityStatementDimensionsHeightOversize[];
}

export interface EntityStatementDimensionsSegmentWidthOversize {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  width_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface EntityStatementDimensionsSegmentWidthOversizeList {
  /**  */
  count: number;

  /**  */
  results: EntityStatementDimensionsSegmentWidthOversize[];
}

export interface EntityStatementDimensionsWidthOversize {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  width_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface EntityStatementDimensionsWidthOversizeList {
  /**  */
  count: number;

  /**  */
  results: EntityStatementDimensionsWidthOversize[];
}

export interface EntityStatementGeneralDimensionsOversize {
  /**  */
  id: number;

  /**  */
  width_oversize: number;

  /**  */
  height_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  engineering_construction: number;

  /**  */
  engineering_construction_name: string;

  /**  */
  line_path: string;
}

export interface EntityStatementGeneralDimensionsOversizeList {
  /**  */
  count: number;

  /**  */
  results: EntityStatementGeneralDimensionsOversize[];
}

export interface EntityStatementList {
  /**  */
  count: number;

  /**  */
  results: EntityStatement[];
}

export interface ExcessPercent {
  /**  */
  id: number;

  /**  */
  normative_load: number;

  /**  */
  percent: number;

  /**  */
  value: number;
}

export interface ExcessPercentList {
  /**  */
  count: number;

  /**  */
  results: ExcessPercent[];
}

export interface ExpendableMaterial {
  /**  */
  id: number;

  /**  */
  application: number;

  /**  */
  application_name: string;

  /**  */
  material: number;

  /**  */
  material_name: string;

  /**  */
  material_measurement_unit: CombinedMaterialMeasurementUnitTypes;

  /**  */
  amount?: number;
}

export interface ExpendableMaterialList {
  /**  */
  count: number;

  /**  */
  results: ExpendableMaterial[];
}

export interface FederalRoadsStatement {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  length: number;

  /**  */
  importance: string;

  /**  */
  road: number;

  /**  */
  road_name: string;
}

export interface FederalRoadsStatementList {
  /**  */
  count: number;

  /**  */
  results: FederalRoadsStatement[];
}

export interface FederalStatement {
  /**  */
  status?: CombinedStatusTypes;

  /**  */
  resolution_route_description: string;

  /**  */
  resolution_special_condition?: string;

  /**  */
  resolution_payment_for_damages?: number;

  /**  */
  resolution_route_length?: number;

  /**  */
  resolution_comment?: string;

  /**  */
  id: number;

  /**  */
  packet_id: string;

  /**  */
  approval_id: string;

  /**  */
  authority_id: string;

  /**  */
  authority_name: string;

  /**  */
  authority_inn: string;

  /**  */
  authority_kpp: string;

  /**  */
  authority_ogrn: string;

  /**  */
  authority_address: string;

  /**  */
  authority_email: string;

  /**  */
  authority_phone: string;

  /**  */
  authority_fax: string;

  /**  */
  created_by_name: string;

  /**  */
  created_by_job_title: string;

  /**  */
  created_by_phone: string;

  /**  */
  created_by_email: string;

  /**  */
  request_number: string;

  /**  */
  request_date: Date;

  /**  */
  route_description: string;

  /**  */
  transporter_type: CombinedTransporterTypeTypes;

  /**  */
  transporter_name: string;

  /**  */
  transporter_inn: string;

  /**  */
  transporter_ogrn: string;

  /**  */
  transporter_country_code: string;

  /**  */
  transporter_post_code: string;

  /**  */
  transporter_address: string;

  /**  */
  transporter_email: string;

  /**  */
  transporter_phone: string;

  /**  */
  transporter_fax: string;

  /**  */
  transporter_author_name: string;

  /**  */
  transporter_author_job_title: string;

  /**  */
  transporter_bank_info: string;

  /**  */
  period_from: Date;

  /**  */
  period_to: Date;

  /**  */
  shipping_type: CombinedShippingTypeTypes;

  /**  */
  empty_total_weight: number;

  /**  */
  total_weight: number;

  /**  */
  dimensions_length: number;

  /**  */
  dimensions_width: number;

  /**  */
  dimensions_height: number;

  /**  */
  turn_radius: number;

  /**  */
  estimated_speed: number;

  /**  */
  trip_count: number;

  /**  */
  with_freight: boolean;

  /**  */
  freight_name: string;

  /**  */
  freight_dimensions_length: number;

  /**  */
  freight_dimensions_width: number;

  /**  */
  freight_dimensions_height: number;

  /**  */
  additional_freight_dimensions_length: number;

  /**  */
  additional_freight_dimensions_width: number;

  /**  */
  additional_freight_dimensions_height: number;

  /**  */
  freight_weight: number;

  /**  */
  freight_kind: CombinedFreightKindTypes;

  /**  */
  escort_info: string;

  /**  */
  is_constant_route: boolean;

  /**  */
  resolution_date: Date;

  /**  */
  resolution_created_user: number;

  /**  */
  resolution_created_user_name: string;

  /**  */
  is_route: boolean;

  /**  */
  resolution_number: string;

  /**  */
  simple_procedure: boolean;

  /**  */
  handling_organization_name: string;

  /**  */
  handling_organization: number;
}

export interface FederalStatementAdapterMessage {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  message_type: CombinedMessageTypeTypes;

  /**  */
  is_resolution_xml_data_sign: boolean;

  /**  */
  is_resolution_send: boolean;

  /**  */
  created_at: Date;

  /**  */
  modified_at: Date;
}

export interface FederalStatementAdapterMessageList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementAdapterMessage[];
}

export interface FederalStatementAdditionalInspection {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  comment: string;

  /**  */
  created_user_name: string;

  /**  */
  created_user: number;

  /**  */
  resolution_comment: string;

  /**  */
  resolution_created_by_name: string;

  /**  */
  resolution_created_by_job_title: string;

  /**  */
  resolution_created_by_phone: string;

  /**  */
  resolution_created_by_email: string;

  /**  */
  is_data_receive: boolean;
}

export interface FederalStatementAdditionalInspectionCommentTemplate {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  comment: string;
}

export interface FederalStatementAdditionalInspectionCommentTemplateList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementAdditionalInspectionCommentTemplate[];
}

export interface FederalStatementAdditionalInspectionList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementAdditionalInspection[];
}

export interface FederalStatementApprovedCommentTemplate {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  comment: string;
}

export interface FederalStatementApprovedCommentTemplateList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementApprovedCommentTemplate[];
}

export interface FederalStatementAxleLoadOverload {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  overload: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface FederalStatementAxleLoadOverloadList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementAxleLoadOverload[];
}

export interface FederalStatementCancel {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  created_by_name: string;

  /**  */
  created_by_job_title: string;

  /**  */
  created_by_phone: string;

  /**  */
  created_by_email: string;

  /**  */
  comment: string;

  /**  */
  resolution_created_user: number;

  /**  */
  resolution_created_user_name: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  resolution_comment?: string;
}

export interface FederalStatementCancelList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementCancel[];
}

export interface FederalStatementControlStation {
  /**  */
  id: number;

  /**  */
  code: string;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  direction_code: string;
}

export interface FederalStatementControlStationList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementControlStation[];
}

export interface FederalStatementDeclinedCommentTemplate {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  comment: string;
}

export interface FederalStatementDeclinedCommentTemplateList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementDeclinedCommentTemplate[];
}

export interface FederalStatementDimensionsHeightOversize {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  height_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface FederalStatementDimensionsHeightOversizeList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementDimensionsHeightOversize[];
}

export interface FederalStatementDimensionsSegmentWidthOversize {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  width_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface FederalStatementDimensionsSegmentWidthOversizeList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementDimensionsSegmentWidthOversize[];
}

export interface FederalStatementDimensionsWidthOversize {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  width_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface FederalStatementDimensionsWidthOversizeList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementDimensionsWidthOversize[];
}

export interface FederalStatementFile {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  file: string;

  /**  */
  statement_name: string;

  /**  */
  file_name: string;
}

export interface FederalStatementFileList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementFile[];
}

export interface FederalStatementGeneralDimensionsOversize {
  /**  */
  id: number;

  /**  */
  width_oversize: number;

  /**  */
  height_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  engineering_construction: number;

  /**  */
  engineering_construction_name: string;

  /**  */
  line_path: string;
}

export interface FederalStatementGeneralDimensionsOversizeList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementGeneralDimensionsOversize[];
}

export interface FederalStatementList {
  /**  */
  count: number;

  /**  */
  results: FederalStatement[];
}

export interface FederalStatementRouteSegment {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  line_path?: string;
}

export interface FederalStatementRouteSegmentList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementRouteSegment[];
}

export interface FederalStatementSpecialConditionCommentTemplate {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  comment: string;
}

export interface FederalStatementSpecialConditionCommentTemplateList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementSpecialConditionCommentTemplate[];
}

export interface FederalStatementVehicle {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  model: string;

  /**  */
  weight: number;

  /**  */
  registration_number: string;

  /**  */
  axle_count: number;

  /**  */
  vehicle_type: CombinedVehicleTypeTypes;

  /**  */
  order?: number;

  /**  */
  axes: FederalStatementVehicleAxis[];
}

export interface FederalStatementVehicleAxis {
  /**  */
  is_twin_wheel?: boolean;

  /**  */
  is_eight_wheel?: boolean;

  /**  */
  is_air_axle?: boolean;

  /**  */
  id: number;

  /**  */
  number: number;

  /**  */
  load: number;

  /**  */
  distance: number;

  /**  */
  vehicle_parameters?: number;

  /**  */
  slope?: number;
}

export interface FederalStatementVehicleAxisList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementVehicleAxis[];
}

export interface FederalStatementVehicleList {
  /**  */
  count: number;

  /**  */
  results: FederalStatementVehicle[];
}

export interface Field {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  label: string;

  /**  */
  show: boolean;
}

export interface FileEntityStatement {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  file_name: string;

  /**  */
  file: string;
}

export interface FileEntityStatementList {
  /**  */
  count: number;

  /**  */
  results: FileEntityStatement[];
}

export interface FileIndividualStatement {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  file_name: string;

  /**  */
  file: string;
}

export interface FileIndividualStatementList {
  /**  */
  count: number;

  /**  */
  results: FileIndividualStatement[];
}

export interface FormAccountStatement {
  /**  */
  id: number;

  /**  */
  number: number;

  /**  */
  individual_statement?: number;

  /**  */
  entity_statement?: number;

  /**  */
  date?: Date;

  /**  */
  write_off_act?: number;

  /**  */
  destruction_act?: number;
}

export interface FormAccountStatementList {
  /**  */
  count: number;

  /**  */
  results: FormAccountStatement[];
}

export interface GenerateUsernames {
  /**  */
  username: string;
}

export interface GlobalOrganization {
  /**  */
  id: number;

  /**  */
  organization: number;

  /**  */
  organization_name: string;
}

export interface GlobalOrganizationList {
  /**  */
  count: number;

  /**  */
  results: GlobalOrganization[];
}

export interface GridVehicle {
  /**  */
  id: number;

  /**  */
  registration_number: string;

  /**  */
  vehicle_type_name: string;

  /**  */
  comment?: string;

  /**  */
  point?: object;

  /**  */
  azimuth?: number;

  /**  */
  speed?: number;

  /**  */
  address?: string;

  /**  */
  datetime_received?: Date;
}

export interface Group {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  permissions?: number[];

  /**  */
  count_layer_permissions: number;

  /**  */
  count_widget_permissions: number;

  /**  */
  count_reports_permissions: number;

  /**  */
  count_directory_permissions: number;
}

export interface GroupByChoices {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  label: string;
}

export interface GroupList {
  /**  */
  count: number;

  /**  */
  results: Group[];
}

export interface ImprovementElement {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  point: string;

  /**  */
  element_type: CombinedElementTypeTypes;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface ImprovementElementList {
  /**  */
  count: number;

  /**  */
  results: ImprovementElement[];
}

export interface IndividualRoadsStatement {
  /**  */
  id: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  length: number;

  /**  */
  importance: string;

  /**  */
  road: number;

  /**  */
  road_name: string;
}

export interface IndividualRoadsStatementList {
  /**  */
  count: number;

  /**  */
  results: IndividualRoadsStatement[];
}

export interface IndividualStatement {
  /**  */
  id: number;

  /**  */
  uuid_statement: string;

  /**  */
  number_of_statement?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  comment?: string;

  /**  */
  bank_name?: string;

  /**  */
  phone: string;

  /**  */
  email?: string;

  /**  */
  bank_code?: number;

  /**  */
  inn?: string;

  /**  */
  payment_account?: string;

  /**  */
  correspondent_account?: string;

  /**  */
  vehicle_owner_first_name?: string;

  /**  */
  vehicle_owner_surname?: string;

  /**  */
  vehicle_owner_middle_name?: string;

  /**  */
  vehicle_owner_inn?: string;

  /**  */
  vehicle_owner_address?: string;

  /**  */
  vehicle_owner_phone?: string;

  /**  */
  vehicle_owner_ogrnip?: string;

  /**  */
  start_date: Date;

  /**  */
  end_date: Date;

  /**  */
  number_of_trips: number;

  /**  */
  is_devided: boolean;

  /**  */
  name_cargo: string;

  /**  */
  width_cargo: number;

  /**  */
  height_cargo: number;

  /**  */
  length_cargo: number;

  /**  */
  overhang_length_cargo?: number;

  /**  */
  weight_cargo: number;

  /**  */
  width_road_train: number;

  /**  */
  height_road_train: number;

  /**  */
  length_road_train: number;

  /**  */
  min_turning_radius: number;

  /**  */
  need_escort?: boolean;

  /**  */
  type_escort?: string;

  /**  */
  weight_road_train: number;

  /**  */
  created_at: Date;

  /**  */
  modified_at: Date;

  /**  */
  is_route: boolean;

  /**  */
  is_vehicle_owner?: boolean;

  /**  */
  type_of_document_for_vehicle?: CombinedTypeOfDocumentForVehicleTypes;

  /**  */
  number_of_document_for_vehicle?: number;

  /**  */
  date_of_document_for_vehicle?: Date;

  /**  */
  vehicle_company_full_name?: string;

  /**  */
  vehicle_company_phone?: string;

  /**  */
  vehicle_company_email?: string;

  /**  */
  vehicle_company_address?: string;

  /**  */
  vehicle_company_name?: string;

  /**  */
  description_route: string;

  /**  */
  is_backward: boolean;

  /**  */
  min_speed: number;

  /**  */
  transportation_type?: CombinedTransportationTypeTypes;

  /**  */
  weight_road_train_without_cargo?: number;

  /**  */
  kpp?: string;

  /**  */
  okpo?: string;

  /**  */
  type_of_vehicle_owner?: CombinedTypeOfVehicleOwnerTypes;

  /**  */
  registered_time?: Date;

  /**  */
  end_time?: Date;

  /**  */
  payment_guarant_first_name?: string;

  /**  */
  payment_guarant_last_name?: string;

  /**  */
  payment_guarant_middle_name?: string;

  /**  */
  payment_guarant_position?: string;

  /**  */
  special_driving_conditions?: string;

  /**  */
  signer?: number;

  /**  */
  name_signer: string;

  /**  */
  is_heavy_vehicle?: boolean;

  /**  */
  position_signer: string;

  /**  */
  handling_organization_name: string;

  /**  */
  handling_organization?: number;

  /**  */
  responsible_user?: number;

  /**  */
  responsible_user_name: string;

  /**  */
  vehicle_parameters: VehicleParametersIndividualStatement[];

  /**  */
  first_name: string;

  /**  */
  sur_name: string;

  /**  */
  middle_name: string;

  /**  */
  address: string;

  /**  */
  ogrnip?: string;

  /**  */
  type_document?: CombinedTypeDocumentTypes;

  /**  */
  document_series?: string;

  /**  */
  document_number?: string;

  /**  */
  issuing_authority?: string;

  /**  */
  date_of_issue?: Date;

  /**  */
  registration_address?: string;
}

export interface IndividualStatementAxleLoadOverload {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  overload: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface IndividualStatementAxleLoadOverloadList {
  /**  */
  count: number;

  /**  */
  results: IndividualStatementAxleLoadOverload[];
}

export interface IndividualStatementDimensionsHeightOversize {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  height_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface IndividualStatementDimensionsHeightOversizeList {
  /**  */
  count: number;

  /**  */
  results: IndividualStatementDimensionsHeightOversize[];
}

export interface IndividualStatementDimensionsWidthOversize {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  width_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface IndividualStatementDimensionsWidthOversizeList {
  /**  */
  count: number;

  /**  */
  results: IndividualStatementDimensionsWidthOversize[];
}

export interface IndividualStatementGeneralDimensionsOversize {
  /**  */
  id: number;

  /**  */
  width_oversize: number;

  /**  */
  height_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;

  /**  */
  engineering_construction: number;

  /**  */
  engineering_construction_name: string;

  /**  */
  line_path: string;
}

export interface IndividualStatementGeneralDimensionsOversizeList {
  /**  */
  count: number;

  /**  */
  results: IndividualStatementGeneralDimensionsOversize[];
}

export interface IndividualStatementList {
  /**  */
  count: number;

  /**  */
  results: IndividualStatement[];
}

export interface IndividualStatementSegmentDimensionsWidthOversize {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  width_oversize: number;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface IndividualStatementSegmentDimensionsWidthOversizeList {
  /**  */
  count: number;

  /**  */
  results: IndividualStatementSegmentDimensionsWidthOversize[];
}

export interface InstallationPlan {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  installation_year?: number;

  /**  */
  color: string;
}

export interface InstallationPlanList {
  /**  */
  count: number;

  /**  */
  results: InstallationPlan[];
}

export interface Lighting {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  point: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface LightingList {
  /**  */
  count: number;

  /**  */
  results: Lighting[];
}

export interface LightingProject {
  /**  */
  id: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  point: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface LightingProjectList {
  /**  */
  count: number;

  /**  */
  results: LightingProject[];
}

export interface LineTram {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  length?: number;

  /**  */
  substation?: number;

  /**  */
  reserve_substation?: number;

  /**  */
  count?: number;

  /**  */
  voltage_drop?: number;

  /**  */
  PS_restrictions?: number;

  /**  */
  is_MZ?: boolean;

  /**  */
  tvz?: string;

  /**  */
  comment?: string;

  /**  */
  disable?: string;

  /**  */
  line_of_failure?: number;

  /**  */
  line_of_disable?: number;

  /**  */
  support_pillar?: number;

  /**  */
  current_sc: string;
}

export interface LineTramList {
  /**  */
  count: number;

  /**  */
  results: LineTram[];
}

export interface LineTrolleybus {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  length?: number;

  /**  */
  substation?: number;

  /**  */
  reserve_substation?: number;

  /**  */
  count?: number;

  /**  */
  voltage_drop?: number;

  /**  */
  PS_restrictions?: number;

  /**  */
  is_MZ?: boolean;

  /**  */
  tvz?: string;

  /**  */
  comment?: string;

  /**  */
  disable?: string;

  /**  */
  line_of_failure?: number;

  /**  */
  line_of_disable?: number;

  /**  */
  support_pillar?: number;

  /**  */
  current_sc: string;
}

export interface LineTrolleybusList {
  /**  */
  count: number;

  /**  */
  results: LineTrolleybus[];
}

export interface Locality {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  state: number;

  /**  */
  state_name: string;

  /**  */
  district?: number;

  /**  */
  district_name: string;

  /**  */
  municipality?: number;

  /**  */
  municipality_name: string;

  /**  */
  population_locality: number[];

  /**  */
  timezone: CombinedTimezoneTypes;

  /**  */
  phone_code?: string;

  /**  */
  postal_code?: number;

  /**  */
  OKATO_code?: number;

  /**  */
  OKTMO_code?: number;

  /**  */
  locality_type: number;

  /**  */
  locality_type_name: string;

  /**  */
  location?: string;

  /**  */
  center_coordinate?: string;
}

export interface LocalityList {
  /**  */
  count: number;

  /**  */
  results: Locality[];
}

export interface LocalityType {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface LocalityTypeList {
  /**  */
  count: number;

  /**  */
  results: LocalityType[];
}

export interface LocationAxisStatement {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface LocationAxisStatementList {
  /**  */
  count: number;

  /**  */
  results: LocationAxisStatement[];
}

export interface Mark {
  /**  */
  id: number;

  /**  */
  datetime: Date;

  /**  */
  point: MarkPoint;

  /**  */
  work_shift_id: number;
}

export interface MarkPoint {
  /**  */
  longitude: number;

  /**  */
  latitude: number;
}

export interface Material {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  measurement_unit: CombinedMeasurementUnitTypes;

  /**  */
  object_type?: ObjectTypeD6fEnum[];
}

export interface MaterialList {
  /**  */
  count: number;

  /**  */
  results: Material[];
}

export interface Municipality {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  OKTMO_code?: number;

  /**  */
  municipal_district: number;

  /**  */
  municipal_district_name: string;

  /**  */
  municipality_type: CombinedMunicipalityTypeTypes;
}

export interface MunicipalityList {
  /**  */
  count: number;

  /**  */
  results: Municipality[];
}

export interface NonWorkingDays {
  /**  */
  id: number;

  /**  */
  date: Date;
}

export interface NonWorkingDaysList {
  /**  */
  count: number;

  /**  */
  results: NonWorkingDays[];
}

export interface ObjectCounter {
  /**  */
  id: number;

  /**  */
  camera_id: string;

  /**  */
  mask_id: string;

  /**  */
  objects_count: number;
}

export interface ObjectCounterList {
  /**  */
  count: number;

  /**  */
  results: ObjectCounter[];
}

export interface OrderBy {
  /**  */
  field_name: string;

  /**  */
  direction?: OrderByDirectionEnum;

  /**  */
  subtotal?: boolean;
}

export interface Organization {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  short_name: string;

  /**  */
  phone?: string;

  /**  */
  mobile_phone?: string;

  /**  */
  email?: string;

  /**  */
  website?: string;

  /**  */
  inn: string;

  /**  */
  kpp?: string;

  /**  */
  ogrn?: string;

  /**  */
  okpo?: string;

  /**  */
  okved?: string;

  /**  */
  legal_address?: string;

  /**  */
  actual_address: string;

  /**  */
  mailing_address?: string;

  /**  */
  location?: string;

  /**  */
  navigation_operator?: number;

  /**  */
  organization_form: number;
}

export interface OrganizationForm {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  full_name: string;
}

export interface OrganizationFormList {
  /**  */
  count: number;

  /**  */
  results: OrganizationForm[];
}

export interface OrganizationList {
  /**  */
  count: number;

  /**  */
  results: Organization[];
}

export interface OvergroundPipes {
  /**  */
  id: number;

  /**  */
  type?: CombinedTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface OvergroundPipesList {
  /**  */
  count: number;

  /**  */
  results: OvergroundPipes[];
}

export interface ParkingPost {
  /**  */
  id: number;

  /**  */
  type: CombinedTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  segment_name: string;
}

export interface ParkingPostList {
  /**  */
  count: number;

  /**  */
  results: ParkingPost[];
}

export interface PasswordReset {
  /**  */
  email: string;
}

export interface PasswordResetConfirm {
  /**  */
  new_password1: string;

  /**  */
  new_password2: string;

  /**  */
  uid: string;

  /**  */
  token: string;
}

export interface PatchedAdministrationAssignments {
  /**  */
  id?: number;

  /**  */
  number?: string;

  /**  */
  date?: Date;

  /**  */
  description?: string;

  /**  */
  status?: CombinedStatusTypes;
}

export interface PatchedAdministrationAssignmentsFiles {
  /**  */
  id?: number;

  /**  */
  administration_assignment?: number;

  /**  */
  attached_file?: string;

  /**  */
  file_name?: string;
}

export interface PatchedAdministrationAssignmentsRoad {
  /**  */
  id?: number;

  /**  */
  deleted?: Date;

  /**  */
  line_path?: string;

  /**  */
  start_km?: number;

  /**  */
  end_km?: number;

  /**  */
  administration_assignment?: number;

  /**  */
  road?: number;
}

export interface PatchedAdvertShield {
  /**  */
  id?: number;

  /**  */
  point?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedApplication {
  /**  */
  id?: number;

  /**  */
  application_source?: number;

  /**  */
  application_source_name?: string;

  /**  */
  object_type?: CombinedObjectTypeTypes;

  /**  */
  application_type?: number;

  /**  */
  object_characteristic?: string;

  /**  */
  application_name?: string;

  /**  */
  description?: string;

  /**  */
  address?: string;

  /**  */
  district?: number;

  /**  */
  task?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  author?: number;

  /**  */
  created_at?: Date;

  /**  */
  author_full_name?: string;

  /**  */
  application_type_name?: string;

  /**  */
  work_type?: string;

  /**  */
  finish_of_work_planned_date?: string;

  /**  */
  appointed_team?: number;

  /**  */
  appointed_team_datetime?: Date;

  /**  */
  appointed_team_name?: string;

  /**  */
  responsible_master_name?: string;

  /**  */
  point?: string;

  /**  */
  closed_status_time?: Date;

  /**  */
  application_comment?: ApplicationCommentForNesting[];

  /**  */
  order?: number;

  /**  */
  on_change_allowed_statuses?: string[];

  /**  */
  detection?: number;

  /**  */
  has_photos?: boolean;
}

export interface PatchedApplicationChatRoomUser {
  /**  */
  id?: number;

  /**  */
  chat_room?: number;

  /**  */
  chat_room_name?: string;

  /**  */
  user?: number;

  /**  */
  user_name?: string;
}

export interface PatchedApplicationComment {
  /**  */
  id?: number;

  /**  */
  application?: number;

  /**  */
  application_name?: string;

  /**  */
  author?: number;

  /**  */
  author_full_name?: string;

  /**  */
  comment?: string;
}

export interface PatchedApplicationPhoto {
  /**  */
  id?: number;

  /**  */
  photo?: string;

  /**  */
  application?: number;

  /**  */
  application_name?: string;
}

export interface PatchedApplicationSource {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedApplicationType {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  days_limit?: number;

  /**  */
  team_of_masters?: number;

  /**  */
  team_of_masters_name?: string;

  /**  */
  work_type?: CombinedWorkTypeTypes;

  /**  */
  object_type?: CombinedObjectTypeTypes;
}

export interface PatchedAxisRadius {
  /**  */
  id?: number;

  /**  */
  radius?: number;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedAxisSlope {
  /**  */
  id?: number;

  /**  */
  slope?: number;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedAxisVisibility {
  /**  */
  id?: number;

  /**  */
  visibility?: number;

  /**  */
  direction?: CombinedDirectionTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedBillingEntityStatement {
  /**  */
  id?: number;

  /**  */
  payment_method?: CombinedPaymentMethodTypes;

  /**  */
  unique_id?: string;

  /**  */
  invoice_amount?: number;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;

  /**  */
  payment_type?: number;

  /**  */
  payment_type_name?: string;

  /**  */
  payment_type_recepient_name?: string;

  /**  */
  payment_type_kpp?: string;

  /**  */
  payment_type_inn?: string;

  /**  */
  payment_type_oktmo?: string;

  /**  */
  payment_type_okpo?: number;

  /**  */
  payment_type_account_number?: number;

  /**  */
  payment_type_budget_classification_code?: number;

  /**  */
  payment_type_correspondent_account?: string;

  /**  */
  payment_type_bik?: number;

  /**  */
  amount_paid?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  is_paid?: boolean;

  /**  */
  created_at?: Date;

  /**  */
  errors?: string;
}

export interface PatchedBillingIndividualStatement {
  /**  */
  id?: number;

  /**  */
  payment_method?: CombinedPaymentMethodTypes;

  /**  */
  unique_id?: string;

  /**  */
  invoice_amount?: number;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;

  /**  */
  payment_type?: number;

  /**  */
  payment_type_name?: string;

  /**  */
  payment_type_recepient_name?: string;

  /**  */
  payment_type_kpp?: string;

  /**  */
  payment_type_inn?: string;

  /**  */
  payment_type_oktmo?: string;

  /**  */
  payment_type_okpo?: number;

  /**  */
  payment_type_account_number?: number;

  /**  */
  payment_type_budget_classification_code?: number;

  /**  */
  payment_type_correspondent_account?: string;

  /**  */
  payment_type_bik?: number;

  /**  */
  amount_paid?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  is_paid?: boolean;

  /**  */
  created_at?: Date;

  /**  */
  errors?: string;
}

export interface PatchedBusShelter {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  point?: string;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedBusStop {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  direction?: CombinedDirectionTypes;

  /**  */
  point?: string;

  /**  */
  type_bus?: CombinedTypeBusTypes;

  /**  */
  year_of_creation?: number;

  /**  */
  count_canopy?: number;

  /**  */
  type_canopy?: CombinedTypeCanopyTypes;

  /**  */
  district?: number;

  /**  */
  district_name?: string;

  /**  */
  is_checked?: boolean;

  /**  */
  route_list?: string;

  /**  */
  balance_holder?: string;

  /**  */
  status?: number;

  /**  */
  display?: boolean;

  /**  */
  information_board?: number;

  /**  */
  is_check_pocket?: boolean;

  /**  */
  infrastructure_for_invalids?: string;

  /**  */
  is_bus?: boolean;

  /**  */
  is_troll?: boolean;

  /**  */
  is_tram?: boolean;

  /**  */
  with_platform?: boolean;

  /**  */
  with_parking?: boolean;

  /**  */
  with_pavilion?: boolean;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  code?: number;

  /**  */
  okato?: number;

  /**  */
  address?: string;

  /**  */
  installation_date?: Date;

  /**  */
  dismantling_date?: Date;

  /**  */
  comment?: string;

  /**  */
  adverting_object?: boolean;

  /**  */
  trading_object?: boolean;

  /**  */
  has_route?: boolean;
}

export interface PatchedBusStopFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;

  /**  */
  road_cons_object?: number;

  /**  */
  date_of_download?: Date;

  /**  */
  user_name?: string;
}

export interface PatchedCabinetForCableCommunications {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  point?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedCableTram {
  /**  */
  id?: number;

  /**  */
  line?: number;

  /**  */
  model?: string;

  /**  */
  length?: number;

  /**  */
  line_path?: string;

  /**  */
  start?: number;

  /**  */
  end?: number;

  /**  */
  section_cable?: number;

  /**  */
  line_name?: string;

  /**  */
  hanging_height?: number;
}

export interface PatchedCableTrolleybus {
  /**  */
  id?: number;

  /**  */
  line?: number;

  /**  */
  line_name?: string;

  /**  */
  model?: string;

  /**  */
  length?: number;

  /**  */
  line_path?: string;

  /**  */
  start?: number;

  /**  */
  end?: number;

  /**  */
  section_cable?: number;

  /**  */
  line_length?: number;

  /**  */
  line_of_failure_name?: string;

  /**  */
  line_of_disable_name?: string;

  /**  */
  reserve_substation_name?: string;

  /**  */
  substation_name?: string;

  /**  */
  throughput?: number;

  /**  */
  hanging_height?: number;
}

export interface PatchedCalculationOfHarmEntityStatement {
  /**  */
  id?: number;

  /**  */
  road?: number;

  /**  */
  statement?: number;

  /**  */
  length_road?: number;

  /**  */
  road_type?: CombinedRoadTypeTypes;

  /**  */
  coef_federal_district?: number;

  /**  */
  coef_of_climatic_zones?: CombinedCoefOfClimaticZonesTypes;

  /**  */
  formula_type?: CombinedFormulaTypeTypes;

  /**  */
  normative_load?: number;

  /**  */
  recommended_harm_calc?: number;

  /**  */
  fact_harm_calc?: number;

  /**  */
  index_deflator?: number;
}

export interface PatchedCalculationOfHarmFederalStatement {
  /**  */
  id?: number;

  /**  */
  road?: number;

  /**  */
  statement?: number;

  /**  */
  length_road?: number;

  /**  */
  road_type?: CombinedRoadTypeTypes;

  /**  */
  coef_federal_district?: number;

  /**  */
  coef_of_climatic_zones?: CombinedCoefOfClimaticZonesTypes;

  /**  */
  formula_type?: CombinedFormulaTypeTypes;

  /**  */
  normative_load?: number;

  /**  */
  recommended_harm_calc?: number;

  /**  */
  fact_harm_calc?: number;

  /**  */
  index_deflator?: number;
}

export interface PatchedCalculationOfHarmIndividualStatement {
  /**  */
  id?: number;

  /**  */
  road?: number;

  /**  */
  statement?: number;

  /**  */
  length_road?: number;

  /**  */
  road_type?: CombinedRoadTypeTypes;

  /**  */
  coef_federal_district?: number;

  /**  */
  coef_of_climatic_zones?: CombinedCoefOfClimaticZonesTypes;

  /**  */
  formula_type?: CombinedFormulaTypeTypes;

  /**  */
  normative_load?: number;

  /**  */
  recommended_harm_calc?: number;

  /**  */
  fact_harm_calc?: number;

  /**  */
  index_deflator?: number;
}

export interface PatchedCamera {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  point?: string;

  /**  */
  url?: string;

  /**  */
  stream_url?: string;

  /**  */
  has_detection_masks?: boolean;

  /**  */
  is_working?: boolean;

  /**  */
  azimuth?: number;

  /**  */
  angle_of_view?: number;
}

export interface PatchedCitizenAppeal {
  /**  */
  id?: number;

  /**  */
  description?: string;

  /**  */
  address?: string;

  /**  */
  coords?: string;
}

export interface PatchedCityDistrict {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  polygon?: string;

  /**  */
  area?: number;

  /**  */
  perimeter?: number;

  /**  */
  okato?: number;
}

export interface PatchedCoefForCalcHarm {
  /**  */
  id?: number;

  /**  */
  federal_district?: CombinedFederalDistrictTypes;

  /**  */
  coef_of_climatic_zones?: number;

  /**  */
  coef_of_repairs?: number;

  /**  */
  coef_of_influence_mass?: number;
}

export interface PatchedCommunicationPole {
  /**  */
  id?: number;

  /**  */
  point?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedConditionOfPavement {
  /**  */
  id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  start_segment?: number;

  /**  */
  end_segment?: number;

  /**  */
  segment_length?: number;

  /**  */
  condition?: string;

  /**  */
  diagnosis_date?: Date;

  /**  */
  contractor?: number;

  /**  */
  contractor_name?: string;
}

export interface PatchedConstant {
  /**  */
  id?: number;

  /**  */
  name?: CombinedNameTypes;

  /**  */
  value?: number;
}

export interface PatchedContourLawn {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  polygon?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedCrossRoad {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  regulated_type?: CombinedRegulatedTypeTypes;

  /**  */
  point?: string;

  /**  */
  tl_controller?: number;

  /**  */
  tl_controller_name?: string;

  /**  */
  first_segment?: number;

  /**  */
  first_segment_name?: string;

  /**  */
  first_road_name?: string;

  /**  */
  second_segment?: number;

  /**  */
  second_segment_name?: string;

  /**  */
  second_road_name?: string;
}

export interface PatchedCurbstone {
  /**  */
  id?: number;

  /**  */
  type?: CombinedTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedDestructionAct {
  /**  */
  id?: number;

  /**  */
  number?: number;

  /**  */
  reason?: string;

  /**  */
  date_of_act?: Date;

  /**  */
  responsible_person?: string;

  /**  */
  commission_persons?: string;

  /**  */
  date_of_order?: Date;

  /**  */
  number_of_order?: string;

  /**  */
  from_date?: Date;

  /**  */
  to_date?: Date;

  /**  */
  created_at?: Date;

  /**  */
  forms?: number[];
}

export interface PatchedDetection {
  /**  */
  id?: number;

  /**  */
  detection_type?: CombinedDetectionTypeTypes;

  /**  */
  violation_type?: string;

  /**  */
  violation_type_display_name?: string;

  /**  */
  point?: string;

  /**  */
  nearest_address?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  image_src?: string;

  /**  */
  external_id?: number;

  /**  */
  created_at?: Date;

  /**  */
  comment?: string;

  /**  */
  validation_status?: CombinedValidationStatusTypes;

  /**  */
  bbox?: number[];

  /**  */
  road_kilometer?: string;

  /**  */
  hsc_type?: CombinedHscTypeTypes;
}

export interface PatchedDetectionMask {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  mask_type?: CombinedMaskTypeTypes;

  /**  */
  roi_points?: number[];

  /**  */
  control_line_points?: number[];

  /**  */
  measure_line_points?: number[];

  /**  */
  camera?: number;
}

export interface PatchedDetectionTask {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  created_at?: Date;

  /**  */
  author?: number;

  /**  */
  author_name?: string;

  /**  */
  application?: number;
}

export interface PatchedDevice {
  /**  */
  id?: number;

  /**  */
  number?: string;

  /**  */
  rnic_number?: string;

  /**  */
  device_type?: number;

  /**  */
  device_type_name?: string;

  /**  */
  organization?: number;

  /**  */
  organization_name?: string;

  /**  */
  device_fullname?: number;

  /**  */
  device_fullname_name?: string;

  /**  */
  alarm_button_address?: number;

  /**  */
  ip_addr?: string;

  /**  */
  imei?: string;

  /**  */
  server_password?: string;

  /**  */
  disabled?: boolean;

  /**  */
  relay_address?: string;

  /**  */
  firmware_version?: string;

  /**  */
  voice_call_support?: boolean;

  /**  */
  timezone?: CombinedTimezoneTypes;

  /**  */
  manufacture_date?: Date;

  /**  */
  banned?: boolean;

  /**  */
  comment?: string;

  /**  */
  in_params?: object;

  /**  */
  event_params?: object;

  /**  */
  extra?: object;
}

export interface PatchedDeviceFullname {
  /**  */
  id?: number;

  /**  */
  device_fullname?: string;
}

export interface PatchedDeviceType {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  library?: CombinedLibraryTypes;

  /**  */
  firm?: string;

  /**  */
  n_analog_in?: number;

  /**  */
  n_digital_in?: number;

  /**  */
  n_counter_in?: number;

  /**  */
  alarm_button?: boolean;
}

export interface PatchedDeviceVehicle {
  /**  */
  id?: number;

  /**  */
  vehicle?: number;

  /**  */
  vehicle_name?: string;

  /**  */
  device?: number;

  /**  */
  device_name?: string;

  /**  */
  organization?: number;

  /**  */
  organization_name?: string;

  /**  */
  start_date?: Date;

  /**  */
  end_date?: Date;
}

export interface PatchedDistrict {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  district_code?: number;

  /**  */
  state?: number;

  /**  */
  state_name?: string;
}

export interface PatchedDividingLine {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  dividing_line_type?: number;

  /**  */
  dividing_line_type_name?: string;

  /**  */
  roadway?: number;

  /**  */
  start?: number;

  /**  */
  end?: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedDividingLineType {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  width?: number;

  /**  */
  height?: number;
}

export interface PatchedEarthBed {
  /**  */
  id?: number;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedElectricPole {
  /**  */
  id?: number;

  /**  */
  point?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedEngineeringConstruction {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  construction_type?: CombinedConstructionTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  width?: number;

  /**  */
  height?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  location?: string;

  /**  */
  year_of_construction?: number;

  /**  */
  space_length?: number;

  /**  */
  design_load?: string;

  /**  */
  restriction_on_the_mass_of_the_car?: number;

  /**  */
  carriageway_dimension?: number;

  /**  */
  building_material?: CombinedBuildingMaterialTypes;

  /**  */
  type_of_coverage?: CombinedTypeOfCoverageTypes;

  /**  */
  commissioned?: boolean;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedEntityRoadsStatement {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;

  /**  */
  length?: number;

  /**  */
  importance?: string;

  /**  */
  road?: number;

  /**  */
  road_name?: string;
}

export interface PatchedEntityStatement {
  /**  */
  id?: number;

  /**  */
  uuid_statement?: string;

  /**  */
  number_of_statement?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  comment?: string;

  /**  */
  bank_name?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  bank_code?: number;

  /**  */
  inn?: string;

  /**  */
  payment_account?: string;

  /**  */
  correspondent_account?: string;

  /**  */
  vehicle_owner_first_name?: string;

  /**  */
  vehicle_owner_surname?: string;

  /**  */
  vehicle_owner_middle_name?: string;

  /**  */
  vehicle_owner_inn?: string;

  /**  */
  vehicle_owner_address?: string;

  /**  */
  vehicle_owner_phone?: string;

  /**  */
  vehicle_owner_ogrnip?: string;

  /**  */
  start_date?: Date;

  /**  */
  end_date?: Date;

  /**  */
  number_of_trips?: number;

  /**  */
  is_devided?: boolean;

  /**  */
  name_cargo?: string;

  /**  */
  width_cargo?: number;

  /**  */
  height_cargo?: number;

  /**  */
  length_cargo?: number;

  /**  */
  overhang_length_cargo?: number;

  /**  */
  weight_cargo?: number;

  /**  */
  width_road_train?: number;

  /**  */
  height_road_train?: number;

  /**  */
  length_road_train?: number;

  /**  */
  min_turning_radius?: number;

  /**  */
  need_escort?: boolean;

  /**  */
  type_escort?: string;

  /**  */
  weight_road_train?: number;

  /**  */
  created_at?: Date;

  /**  */
  modified_at?: Date;

  /**  */
  is_route?: boolean;

  /**  */
  is_vehicle_owner?: boolean;

  /**  */
  type_of_document_for_vehicle?: CombinedTypeOfDocumentForVehicleTypes;

  /**  */
  number_of_document_for_vehicle?: number;

  /**  */
  date_of_document_for_vehicle?: Date;

  /**  */
  vehicle_company_full_name?: string;

  /**  */
  vehicle_company_phone?: string;

  /**  */
  vehicle_company_email?: string;

  /**  */
  vehicle_company_address?: string;

  /**  */
  vehicle_company_name?: string;

  /**  */
  description_route?: string;

  /**  */
  is_backward?: boolean;

  /**  */
  min_speed?: number;

  /**  */
  transportation_type?: CombinedTransportationTypeTypes;

  /**  */
  weight_road_train_without_cargo?: number;

  /**  */
  kpp?: string;

  /**  */
  okpo?: string;

  /**  */
  type_of_vehicle_owner?: CombinedTypeOfVehicleOwnerTypes;

  /**  */
  registered_time?: Date;

  /**  */
  end_time?: Date;

  /**  */
  payment_guarant_first_name?: string;

  /**  */
  payment_guarant_last_name?: string;

  /**  */
  payment_guarant_middle_name?: string;

  /**  */
  payment_guarant_position?: string;

  /**  */
  special_driving_conditions?: string;

  /**  */
  signer?: number;

  /**  */
  name_signer?: string;

  /**  */
  is_heavy_vehicle?: boolean;

  /**  */
  position_signer?: string;

  /**  */
  handling_organization_name?: string;

  /**  */
  handling_organization?: number;

  /**  */
  responsible_user?: number;

  /**  */
  responsible_user_name?: string;

  /**  */
  vehicle_parameters?: VehicleParametersEntityStatement[];

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  ogrn?: string;

  /**  */
  full_name?: string;

  /**  */
  comment_representative?: string;
}

export interface PatchedExcessPercent {
  /**  */
  id?: number;

  /**  */
  normative_load?: number;

  /**  */
  percent?: number;

  /**  */
  value?: number;
}

export interface PatchedExpendableMaterial {
  /**  */
  id?: number;

  /**  */
  application?: number;

  /**  */
  application_name?: string;

  /**  */
  material?: number;

  /**  */
  material_name?: string;

  /**  */
  material_measurement_unit?: CombinedMaterialMeasurementUnitTypes;

  /**  */
  amount?: number;
}

export interface PatchedFederalRoadsStatement {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;

  /**  */
  length?: number;

  /**  */
  importance?: string;

  /**  */
  road?: number;

  /**  */
  road_name?: string;
}

export interface PatchedFederalStatement {
  /**  */
  status?: CombinedStatusTypes;

  /**  */
  resolution_route_description?: string;

  /**  */
  resolution_special_condition?: string;

  /**  */
  resolution_payment_for_damages?: number;

  /**  */
  resolution_route_length?: number;

  /**  */
  resolution_comment?: string;

  /**  */
  id?: number;

  /**  */
  packet_id?: string;

  /**  */
  approval_id?: string;

  /**  */
  authority_id?: string;

  /**  */
  authority_name?: string;

  /**  */
  authority_inn?: string;

  /**  */
  authority_kpp?: string;

  /**  */
  authority_ogrn?: string;

  /**  */
  authority_address?: string;

  /**  */
  authority_email?: string;

  /**  */
  authority_phone?: string;

  /**  */
  authority_fax?: string;

  /**  */
  created_by_name?: string;

  /**  */
  created_by_job_title?: string;

  /**  */
  created_by_phone?: string;

  /**  */
  created_by_email?: string;

  /**  */
  request_number?: string;

  /**  */
  request_date?: Date;

  /**  */
  route_description?: string;

  /**  */
  transporter_type?: CombinedTransporterTypeTypes;

  /**  */
  transporter_name?: string;

  /**  */
  transporter_inn?: string;

  /**  */
  transporter_ogrn?: string;

  /**  */
  transporter_country_code?: string;

  /**  */
  transporter_post_code?: string;

  /**  */
  transporter_address?: string;

  /**  */
  transporter_email?: string;

  /**  */
  transporter_phone?: string;

  /**  */
  transporter_fax?: string;

  /**  */
  transporter_author_name?: string;

  /**  */
  transporter_author_job_title?: string;

  /**  */
  transporter_bank_info?: string;

  /**  */
  period_from?: Date;

  /**  */
  period_to?: Date;

  /**  */
  shipping_type?: CombinedShippingTypeTypes;

  /**  */
  empty_total_weight?: number;

  /**  */
  total_weight?: number;

  /**  */
  dimensions_length?: number;

  /**  */
  dimensions_width?: number;

  /**  */
  dimensions_height?: number;

  /**  */
  turn_radius?: number;

  /**  */
  estimated_speed?: number;

  /**  */
  trip_count?: number;

  /**  */
  with_freight?: boolean;

  /**  */
  freight_name?: string;

  /**  */
  freight_dimensions_length?: number;

  /**  */
  freight_dimensions_width?: number;

  /**  */
  freight_dimensions_height?: number;

  /**  */
  additional_freight_dimensions_length?: number;

  /**  */
  additional_freight_dimensions_width?: number;

  /**  */
  additional_freight_dimensions_height?: number;

  /**  */
  freight_weight?: number;

  /**  */
  freight_kind?: CombinedFreightKindTypes;

  /**  */
  escort_info?: string;

  /**  */
  is_constant_route?: boolean;

  /**  */
  resolution_date?: Date;

  /**  */
  resolution_created_user?: number;

  /**  */
  resolution_created_user_name?: string;

  /**  */
  is_route?: boolean;

  /**  */
  resolution_number?: string;

  /**  */
  simple_procedure?: boolean;

  /**  */
  handling_organization_name?: string;

  /**  */
  handling_organization?: number;
}

export interface PatchedFederalStatementAdditionalInspectionCommentTemplate {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  comment?: string;
}

export interface PatchedFederalStatementApprovedCommentTemplate {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  comment?: string;
}

export interface PatchedFederalStatementCancel {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;

  /**  */
  created_by_name?: string;

  /**  */
  created_by_job_title?: string;

  /**  */
  created_by_phone?: string;

  /**  */
  created_by_email?: string;

  /**  */
  comment?: string;

  /**  */
  resolution_created_user?: number;

  /**  */
  resolution_created_user_name?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  resolution_comment?: string;
}

export interface PatchedFederalStatementControlStation {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;

  /**  */
  direction_code?: string;
}

export interface PatchedFederalStatementDeclinedCommentTemplate {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  comment?: string;
}

export interface PatchedFederalStatementRouteSegment {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  line_path?: string;
}

export interface PatchedFederalStatementSpecialConditionCommentTemplate {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  comment?: string;
}

export interface PatchedFileEntityStatement {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;
}

export interface PatchedFileIndividualStatement {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;
}

export interface PatchedFormAccountStatement {
  /**  */
  id?: number;

  /**  */
  number?: number;

  /**  */
  individual_statement?: number;

  /**  */
  entity_statement?: number;

  /**  */
  date?: Date;

  /**  */
  write_off_act?: number;

  /**  */
  destruction_act?: number;
}

export interface PatchedGlobalOrganization {
  /**  */
  id?: number;

  /**  */
  organization?: number;

  /**  */
  organization_name?: string;
}

export interface PatchedGroup {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  permissions?: number[];

  /**  */
  count_layer_permissions?: number;

  /**  */
  count_widget_permissions?: number;

  /**  */
  count_reports_permissions?: number;

  /**  */
  count_directory_permissions?: number;
}

export interface PatchedImprovementElement {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  point?: string;

  /**  */
  element_type?: CombinedElementTypeTypes;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedIndividualRoadsStatement {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;

  /**  */
  length?: number;

  /**  */
  importance?: string;

  /**  */
  road?: number;

  /**  */
  road_name?: string;
}

export interface PatchedIndividualStatement {
  /**  */
  id?: number;

  /**  */
  uuid_statement?: string;

  /**  */
  number_of_statement?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  comment?: string;

  /**  */
  bank_name?: string;

  /**  */
  phone?: string;

  /**  */
  email?: string;

  /**  */
  bank_code?: number;

  /**  */
  inn?: string;

  /**  */
  payment_account?: string;

  /**  */
  correspondent_account?: string;

  /**  */
  vehicle_owner_first_name?: string;

  /**  */
  vehicle_owner_surname?: string;

  /**  */
  vehicle_owner_middle_name?: string;

  /**  */
  vehicle_owner_inn?: string;

  /**  */
  vehicle_owner_address?: string;

  /**  */
  vehicle_owner_phone?: string;

  /**  */
  vehicle_owner_ogrnip?: string;

  /**  */
  start_date?: Date;

  /**  */
  end_date?: Date;

  /**  */
  number_of_trips?: number;

  /**  */
  is_devided?: boolean;

  /**  */
  name_cargo?: string;

  /**  */
  width_cargo?: number;

  /**  */
  height_cargo?: number;

  /**  */
  length_cargo?: number;

  /**  */
  overhang_length_cargo?: number;

  /**  */
  weight_cargo?: number;

  /**  */
  width_road_train?: number;

  /**  */
  height_road_train?: number;

  /**  */
  length_road_train?: number;

  /**  */
  min_turning_radius?: number;

  /**  */
  need_escort?: boolean;

  /**  */
  type_escort?: string;

  /**  */
  weight_road_train?: number;

  /**  */
  created_at?: Date;

  /**  */
  modified_at?: Date;

  /**  */
  is_route?: boolean;

  /**  */
  is_vehicle_owner?: boolean;

  /**  */
  type_of_document_for_vehicle?: CombinedTypeOfDocumentForVehicleTypes;

  /**  */
  number_of_document_for_vehicle?: number;

  /**  */
  date_of_document_for_vehicle?: Date;

  /**  */
  vehicle_company_full_name?: string;

  /**  */
  vehicle_company_phone?: string;

  /**  */
  vehicle_company_email?: string;

  /**  */
  vehicle_company_address?: string;

  /**  */
  vehicle_company_name?: string;

  /**  */
  description_route?: string;

  /**  */
  is_backward?: boolean;

  /**  */
  min_speed?: number;

  /**  */
  transportation_type?: CombinedTransportationTypeTypes;

  /**  */
  weight_road_train_without_cargo?: number;

  /**  */
  kpp?: string;

  /**  */
  okpo?: string;

  /**  */
  type_of_vehicle_owner?: CombinedTypeOfVehicleOwnerTypes;

  /**  */
  registered_time?: Date;

  /**  */
  end_time?: Date;

  /**  */
  payment_guarant_first_name?: string;

  /**  */
  payment_guarant_last_name?: string;

  /**  */
  payment_guarant_middle_name?: string;

  /**  */
  payment_guarant_position?: string;

  /**  */
  special_driving_conditions?: string;

  /**  */
  signer?: number;

  /**  */
  name_signer?: string;

  /**  */
  is_heavy_vehicle?: boolean;

  /**  */
  position_signer?: string;

  /**  */
  handling_organization_name?: string;

  /**  */
  handling_organization?: number;

  /**  */
  responsible_user?: number;

  /**  */
  responsible_user_name?: string;

  /**  */
  vehicle_parameters?: VehicleParametersIndividualStatement[];

  /**  */
  first_name?: string;

  /**  */
  sur_name?: string;

  /**  */
  middle_name?: string;

  /**  */
  address?: string;

  /**  */
  ogrnip?: string;

  /**  */
  type_document?: CombinedTypeDocumentTypes;

  /**  */
  document_series?: string;

  /**  */
  document_number?: string;

  /**  */
  issuing_authority?: string;

  /**  */
  date_of_issue?: Date;

  /**  */
  registration_address?: string;
}

export interface PatchedInstallationPlan {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  installation_year?: number;

  /**  */
  color?: string;
}

export interface PatchedLighting {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  point?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedLightingProject {
  /**  */
  id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  point?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedLineTram {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  length?: number;

  /**  */
  substation?: number;

  /**  */
  reserve_substation?: number;

  /**  */
  count?: number;

  /**  */
  voltage_drop?: number;

  /**  */
  PS_restrictions?: number;

  /**  */
  is_MZ?: boolean;

  /**  */
  tvz?: string;

  /**  */
  comment?: string;

  /**  */
  disable?: string;

  /**  */
  line_of_failure?: number;

  /**  */
  line_of_disable?: number;

  /**  */
  support_pillar?: number;

  /**  */
  current_sc?: string;
}

export interface PatchedLineTrolleybus {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  length?: number;

  /**  */
  substation?: number;

  /**  */
  reserve_substation?: number;

  /**  */
  count?: number;

  /**  */
  voltage_drop?: number;

  /**  */
  PS_restrictions?: number;

  /**  */
  is_MZ?: boolean;

  /**  */
  tvz?: string;

  /**  */
  comment?: string;

  /**  */
  disable?: string;

  /**  */
  line_of_failure?: number;

  /**  */
  line_of_disable?: number;

  /**  */
  support_pillar?: number;

  /**  */
  current_sc?: string;
}

export interface PatchedLocality {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  state?: number;

  /**  */
  state_name?: string;

  /**  */
  district?: number;

  /**  */
  district_name?: string;

  /**  */
  municipality?: number;

  /**  */
  municipality_name?: string;

  /**  */
  population_locality?: number[];

  /**  */
  timezone?: CombinedTimezoneTypes;

  /**  */
  phone_code?: string;

  /**  */
  postal_code?: number;

  /**  */
  OKATO_code?: number;

  /**  */
  OKTMO_code?: number;

  /**  */
  locality_type?: number;

  /**  */
  locality_type_name?: string;

  /**  */
  location?: string;

  /**  */
  center_coordinate?: string;
}

export interface PatchedLocalityType {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedLocationAxisStatement {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedMaterial {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  measurement_unit?: CombinedMeasurementUnitTypes;

  /**  */
  object_type?: ObjectTypeD6fEnum[];
}

export interface PatchedMunicipality {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  OKTMO_code?: number;

  /**  */
  municipal_district?: number;

  /**  */
  municipal_district_name?: string;

  /**  */
  municipality_type?: CombinedMunicipalityTypeTypes;
}

export interface PatchedNonWorkingDays {
  /**  */
  id?: number;

  /**  */
  date?: Date;
}

export interface PatchedOrganization {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  short_name?: string;

  /**  */
  phone?: string;

  /**  */
  mobile_phone?: string;

  /**  */
  email?: string;

  /**  */
  website?: string;

  /**  */
  inn?: string;

  /**  */
  kpp?: string;

  /**  */
  ogrn?: string;

  /**  */
  okpo?: string;

  /**  */
  okved?: string;

  /**  */
  legal_address?: string;

  /**  */
  actual_address?: string;

  /**  */
  mailing_address?: string;

  /**  */
  location?: string;

  /**  */
  navigation_operator?: number;

  /**  */
  organization_form?: number;
}

export interface PatchedOrganizationForm {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  full_name?: string;
}

export interface PatchedOvergroundPipes {
  /**  */
  id?: number;

  /**  */
  type?: CombinedTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedParkingPost {
  /**  */
  id?: number;

  /**  */
  type?: CombinedTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  segment_name?: string;
}

export interface PatchedPaymentType {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  name_bank?: string;

  /**  */
  recepient_name?: string;

  /**  */
  kpp?: string;

  /**  */
  okpo?: string;

  /**  */
  inn?: string;

  /**  */
  oktmo?: number;

  /**  */
  account_number?: number;

  /**  */
  bank_code?: number;

  /**  */
  budget_classification_code?: number;

  /**  */
  purpose_of_payment?: string;

  /**  */
  correspondent_account?: string;
}

export interface PatchedPedBorder {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  line_path?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  height?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedPedBorderProject {
  /**  */
  id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  line_path?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedPedestrianCrossing {
  /**  */
  id?: number;

  /**  */
  address?: string;

  /**  */
  coordinates?: string;

  /**  */
  power_supply_type?: CombinedPowerSupplyTypeTypes;

  /**  */
  adjustable?: boolean;

  /**  */
  lat?: string;

  /**  */
  lon?: string;
}

export interface PatchedPermissibleAxisLoadStatement {
  /**  */
  id?: number;

  /**  */
  location_axis?: CombinedLocationAxisTypes;

  /**  */
  min_distance?: number;

  /**  */
  max_distance?: number;

  /**  */
  permissible_load_first?: number;

  /**  */
  permissible_load_first_extend?: number;

  /**  */
  permissible_load_second?: number;

  /**  */
  permissible_load_second_extend?: number;

  /**  */
  permissible_load_third?: number;

  /**  */
  permissible_load_third_extend?: number;
}

export interface PatchedPermissibleWeightStatement {
  /**  */
  id?: number;

  /**  */
  number_of_axles?: number;

  /**  */
  is_single?: boolean;

  /**  */
  weigth?: number;
}

export interface PatchedPerson {
  /**  */
  id?: number;

  /**  */
  user?: number;

  /**  */
  user_name?: string;

  /**  */
  organization?: number;

  /**  */
  organization_name?: string;

  /**  */
  person_type?: number;

  /**  */
  person_type_name?: string;

  /**  */
  first_name?: string;

  /**  */
  last_name?: string;

  /**  */
  middle_name?: string;

  /**  */
  date_of_birth?: Date;

  /**  */
  phone?: string;

  /**  */
  extra?: object;
}

export interface PatchedPersonType {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedPopulation {
  /**  */
  id?: number;

  /**  */
  year?: number;

  /**  */
  population?: number;

  /**  */
  locality?: number;

  /**  */
  locality_name?: string;
}

export interface PatchedPosition {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;
}

export interface PatchedRailway {
  /**  */
  id?: number;

  /**  */
  railway_type?: CombinedRailwayTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  length?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedResponsiblePerson {
  /**  */
  id?: number;

  /**  */
  user?: number;

  /**  */
  user_name?: string;

  /**  */
  username?: string;

  /**  */
  can_individual_statement?: boolean;

  /**  */
  can_entity_statement?: boolean;

  /**  */
  can_federal_statement?: boolean;
}

export interface PatchedRoad {
  /**  */
  id?: number;

  /**  */
  road_number?: string;

  /**  */
  road_code?: string;

  /**  */
  name?: string;

  /**  */
  importance?: CombinedImportanceTypes;

  /**  */
  commissioning_datetime?: Date;

  /**  */
  start_road?: number;

  /**  */
  end_road?: number;

  /**  */
  total_road_length?: number;

  /**  */
  is_checked?: boolean;

  /**  */
  road_category?: CombinedRoadCategoryTypes;

  /**  */
  category?: number;

  /**  */
  category_name?: string;

  /**  */
  owner_organization?: number;

  /**  */
  district?: number;

  /**  */
  locality?: number;

  /**  */
  comment?: string;
}

export interface PatchedRoadAccident {
  /**  */
  id?: number;

  /**  */
  address?: string;

  /**  */
  date_time?: Date;

  /**  */
  lighting?: string;

  /**  */
  lost_amount?: number;

  /**  */
  members_amount?: number;

  /**  */
  motion_factors?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  road_importance?: CombinedRoadImportanceTypes;

  /**  */
  roads_disadvantages?: string;

  /**  */
  roadway_status?: string;

  /**  */
  road_accident_type?: CombinedRoadAccidentTypeTypes;

  /**  */
  vehicles_amount?: number;

  /**  */
  weather_condition?: string;

  /**  */
  wounded_amount?: number;

  /**  */
  place_origin_road?: number;

  /**  */
  point?: string;
}

export interface PatchedRoadAccidentParticipant {
  /**  */
  id?: number;

  /**  */
  road_accident?: number;

  /**  */
  road_accident_name?: string;

  /**  */
  vehicle?: number;

  /**  */
  vehicle_number?: string;

  /**  */
  category?: string;

  /**  */
  additional_traffic_violations?: string;

  /**  */
  degree_of_intoxication?: string;

  /**  */
  driving_experience?: number;

  /**  */
  gender?: CombinedGenderTypes;

  /**  */
  immediate_traffic_violations?: string;

  /**  */
  leave_accident_place?: string;

  /**  */
  is_seat_belt_used?: boolean;

  /**  */
  severity_of_consequences?: string;

  /**  */
  type_of_child_restraint?: string;
}

export interface PatchedRoadAccidentSection {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  description?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  line_path?: string;

  /**  */
  buffer?: string;

  /**  */
  start_road?: number;

  /**  */
  end_road?: number;

  /**  */
  recognition_date_time?: Date;

  /**  */
  measures_eliminate?: string;

  /**  */
  start_address?: string;

  /**  */
  end_address?: string;

  /**  */
  elimination_date_time?: Date;
}

export interface PatchedRoadAccidentVehicle {
  /**  */
  id?: number;

  /**  */
  number?: number;

  /**  */
  color?: string;

  /**  */
  damaged_places?: string;

  /**  */
  vehicle_model?: string;

  /**  */
  technical_issues?: string;

  /**  */
  vehicle_type?: string;

  /**  */
  type_of_drive?: string;

  /**  */
  type_of_ownership?: string;

  /**  */
  year_of_issue?: number;
}

export interface PatchedRoadAxis {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  part_name?: string;

  /**  */
  length?: number;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedRoadCategory {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedRoadFence {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  road_fence_type?: CombinedRoadFenceTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  installation_date?: Date;

  /**  */
  dismantling_date?: Date;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedRoadFenceProject {
  /**  */
  id?: number;

  /**  */
  road_fence_type?: CombinedRoadFenceTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedRoadMarkingLine {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  road_marking_type?: number;

  /**  */
  road_marking_type_name?: string;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedRoadMarkingLineFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;

  /**  */
  road_cons_object?: number;

  /**  */
  date_of_download?: Date;

  /**  */
  user_name?: string;
}

export interface PatchedRoadMarkingLineProject {
  /**  */
  id?: number;

  /**  */
  road_marking_type?: number;

  /**  */
  road_marking_type_name?: string;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedRoadMarkingLineType {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  width?: number;

  /**  */
  height?: number;
}

export interface PatchedRoadMarkingPoint {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  road_marking_type_name?: string;

  /**  */
  point?: string;

  /**  */
  azimuth?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedRoadMarkingPointFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;

  /**  */
  road_cons_object?: number;

  /**  */
  date_of_download?: Date;

  /**  */
  user_name?: string;
}

export interface PatchedRoadMarkingPointProject {
  /**  */
  id?: number;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  point?: string;

  /**  */
  azimuth?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedRoadMarkingPolygon {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  road_marking_type_name?: string;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  area?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedRoadMarkingPolygonFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;

  /**  */
  road_cons_object?: number;

  /**  */
  date_of_download?: Date;

  /**  */
  user_name?: string;
}

export interface PatchedRoadMarkingPolygonProject {
  /**  */
  id?: number;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedRoadQualityCoefficients {
  /**  */
  id?: number;

  /**  */
  date_time_of_last_analysis?: Date;

  /**  */
  deleted?: Date;

  /**  */
  coef_school_bus_traffic?: number;

  /**  */
  coef_public_traffic?: number;

  /**  */
  coef_social_sign_roads?: number;

  /**  */
  coef_alternative_detour?: number;

  /**  */
  coef_citizen_appeals?: number;

  /**  */
  date_to_analysis?: Date;
}

export interface PatchedRoadShoulder {
  /**  */
  id?: number;

  /**  */
  width?: number;

  /**  */
  length?: number;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedRoadSign {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  road_sign_type?: number;

  /**  */
  road_sign_type_name?: string;

  /**  */
  road_sign_image?: string;

  /**  */
  road_sign_subtype?: number;

  /**  */
  road_sign_subtype_name?: string;

  /**  */
  road_sign_image_to_remove?: string;

  /**  */
  road_sign_priority?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  point?: string;

  /**  */
  azimuth?: number;

  /**  */
  installation_date?: Date;

  /**  */
  dismantling_date?: Date;

  /**  */
  road_sign_support_type?: CombinedRoadSignSupportTypeTypes;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  subtype?: string;

  /**  */
  to_remove?: boolean;

  /**  */
  road_sign_group?: number;

  /**  */
  road_sign_group_name?: string;

  /**  */
  application?: number;
}

export interface PatchedRoadSignFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;

  /**  */
  road_cons_object?: number;

  /**  */
  date_of_download?: Date;

  /**  */
  user_name?: string;
}

export interface PatchedRoadSignGroup {
  /**  */
  id?: number;

  /**  */
  point?: string;

  /**  */
  custom_position?: string;

  /**  */
  custom_size?: object;

  /**  */
  azimuth?: number;

  /**  */
  road_signs_list?: number[];
}

export interface PatchedRoadSignProject {
  /**  */
  id?: number;

  /**  */
  road_sign_type?: number;

  /**  */
  road_sign_type_name?: string;

  /**  */
  road_sign_image?: string;

  /**  */
  road_sign_priority?: number;

  /**  */
  road_sign_subtype_name?: string;

  /**  */
  road_sign_subtype?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  point?: string;

  /**  */
  azimuth?: number;

  /**  */
  installation_date?: Date;

  /**  */
  road_sign_support_type?: CombinedRoadSignSupportTypeTypes;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  subtype?: string;

  /**  */
  road_sign_group?: number;

  /**  */
  road_sign_group_name?: string;

  /**  */
  application?: number;
}

export interface PatchedRoadSignSubType {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  road_sign_type?: number;

  /**  */
  image?: string;
}

export interface PatchedRoadSignType {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  number?: string;

  /**  */
  priority?: number;

  /**  */
  group_type?: CombinedGroupTypeTypes;

  /**  */
  image?: string;

  /**  */
  image_border_green?: string;

  /**  */
  image_border_red?: string;
}

export interface PatchedRoadWay {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  roadway_type?: CombinedRoadwayTypeTypes;

  /**  */
  material?: CombinedMaterialTypes;

  /**  */
  polygon?: string;

  /**  */
  num_lanes?: number;

  /**  */
  width?: number;

  /**  */
  length?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  street_name?: string;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedRoadWayProject {
  /**  */
  id?: number;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  application?: number;
}

export interface PatchedRoadWork {
  /**  */
  id?: number;

  /**  */
  object_code?: string;

  /**  */
  full_name?: string;

  /**  */
  object_is_finished?: boolean;

  /**  */
  object_registration_date?: Date;

  /**  */
  object_end_warranty_date?: Date;

  /**  */
  start_km?: number;

  /**  */
  end_km?: number;

  /**  */
  geometry?: string;

  /**  */
  road?: string;

  /**  */
  road_type?: string;

  /**  */
  start_reason?: string;

  /**  */
  curator?: string;

  /**  */
  period_start?: Date;

  /**  */
  period_end?: Date;

  /**  */
  project_cost?: number;

  /**  */
  work_category?: number;

  /**  */
  work_category_name?: string;

  /**  */
  dangerous_segment?: number;

  /**  */
  dangerous_segment_name?: string;

  /**  */
  customer?: number;

  /**  */
  customer_name?: string;

  /**  */
  gen_contractor?: number;

  /**  */
  gen_contractor_name?: string;
}

export interface PatchedRoute {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  number?: string;

  /**  */
  district?: number;

  /**  */
  route_type?: CombinedRouteTypeTypes;

  /**  */
  district_name?: string;

  /**  */
  vehicle_type?: CombinedVehicleTypeTypes;

  /**  */
  route_type_name?: string;
}

export interface PatchedRouteEntityStatement {
  /**  */
  id?: number;

  /**  */
  name_route?: string;

  /**  */
  start_route?: string;

  /**  */
  end_route?: string;

  /**  */
  path?: string;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;
}

export interface PatchedRouteFederalStatement {
  /**  */
  id?: number;

  /**  */
  name_route?: string;

  /**  */
  start_route?: string;

  /**  */
  end_route?: string;

  /**  */
  path?: string;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;
}

export interface PatchedRouteIndividualStatement {
  /**  */
  id?: number;

  /**  */
  name_route?: string;

  /**  */
  start_route?: string;

  /**  */
  end_route?: string;

  /**  */
  path?: string;

  /**  */
  statement?: number;

  /**  */
  statement_name?: string;
}

export interface PatchedRouteScheme {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  route?: number;

  /**  */
  length?: number;

  /**  */
  route_name?: string;
}

export interface PatchedSegment {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  road?: number;

  /**  */
  road_name?: string;

  /**  */
  start_segment?: number;

  /**  */
  end_segment?: number;

  /**  */
  segment_length?: number;

  /**  */
  area_m2?: number;

  /**  */
  line_path?: string;

  /**  */
  direction?: CombinedDirectionTypes;

  /**  */
  segment_type?: CombinedSegmentTypeTypes;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedSegmentAxleLoad {
  /**  */
  id?: number;

  /**  */
  start_segment?: number;

  /**  */
  end_segment?: number;

  /**  */
  segment_length?: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  direction?: CombinedDirectionTypes;

  /**  */
  axle_load?: CombinedAxleLoadTypes;
}

export interface PatchedSegmentDirectionOfMovement {
  /**  */
  id?: number;

  /**  */
  start_segment?: number;

  /**  */
  end_segment?: number;

  /**  */
  segment_length?: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  direction_of_movement?: CombinedDirectionOfMovementTypes;
}

export interface PatchedSegmentSignalBar {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  signal_bar_type?: CombinedSignalBarTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedSegmentSignalBarProject {
  /**  */
  id?: number;

  /**  */
  signal_bar_type?: CombinedSignalBarTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  application?: number;
}

export interface PatchedSegmentSurfaceType {
  /**  */
  id?: number;

  /**  */
  start_segment?: number;

  /**  */
  end_segment?: number;

  /**  */
  segment_length?: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  segment_type?: CombinedSegmentTypeTypes;
}

export interface PatchedSegmentTraySystem {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedSegmentWidth {
  /**  */
  id?: number;

  /**  */
  start_segment?: number;

  /**  */
  end_segment?: number;

  /**  */
  segment_length?: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  width?: number;
}

export interface PatchedSideWalk {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  material?: CombinedMaterialTypes;

  /**  */
  sidewalk_type?: CombinedSidewalkTypeTypes;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  area?: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedSideWalkProject {
  /**  */
  id?: number;

  /**  */
  material?: CombinedMaterialTypes;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  area?: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedSigner {
  /**  */
  id?: number;

  /**  */
  user?: number;

  /**  */
  user_name?: string;

  /**  */
  type_of_signer?: CombinedTypeOfSignerTypes;
}

export interface PatchedSocialFacility {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  facility_type?: CombinedFacilityTypeTypes;

  /**  */
  geodata?: string;
}

export interface PatchedSpeedBump {
  /**  */
  id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  line_path?: string;

  /**  */
  year?: number;

  /**  */
  address?: string;

  /**  */
  material?: CombinedMaterialTypes;

  /**  */
  type_speed_bump?: CombinedTypeSpeedBumpTypes;

  /**  */
  on_pedestrian_crossing?: boolean;

  /**  */
  number_of_links?: number;

  /**  */
  number_of_ends?: number;

  /**  */
  to_remove?: boolean;
}

export interface PatchedSpeedBumpProject {
  /**  */
  id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  type_speed_bump?: CombinedTypeSpeedBumpTypes;

  /**  */
  line_path?: string;

  /**  */
  year?: number;

  /**  */
  address?: string;

  /**  */
  material?: CombinedMaterialTypes;

  /**  */
  on_pedestrian_crossing?: boolean;

  /**  */
  number_of_links?: number;

  /**  */
  number_of_ends?: number;

  /**  */
  application?: number;
}

export interface PatchedState {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  subject_code?: number;

  /**  */
  federal_district?: CombinedFederalDistrictTypes;
}

export interface PatchedStatusBusStop {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedStatusCommentStatement {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  comment_name?: string;

  /**  */
  comment?: string;
}

export interface PatchedStreetFurniture {
  /**  */
  id?: number;

  /**  */
  type?: CombinedTypeTypes;

  /**  */
  point?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedSubstation {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  location?: string;

  /**  */
  current_sc?: number;

  /**  */
  rated_load?: number;

  /**  */
  current_load?: number;

  /**  */
  current_power?: number;

  /**  */
  rated_power?: number;

  /**  */
  current_voltage?: number;

  /**  */
  rated_voltage?: number;
}

export interface PatchedSupplyCableTram {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  type_cable?: number;

  /**  */
  line?: number;

  /**  */
  substation?: number;

  /**  */
  line_name?: string;

  /**  */
  line_path?: string;

  /**  */
  substation_name?: string;

  /**  */
  type_name?: string;

  /**  */
  hanging_height?: number;
}

export interface PatchedSupplyCableTrolleybus {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  type_cable?: number;

  /**  */
  line?: number;

  /**  */
  substation?: number;

  /**  */
  line_name?: string;

  /**  */
  line_path?: string;

  /**  */
  substation_name?: string;

  /**  */
  type_name?: string;

  /**  */
  hanging_height?: number;
}

export interface PatchedSupportPillar {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  point?: string;

  /**  */
  road_cons_id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedSupportWall {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  line_path?: string;

  /**  */
  material?: CombinedMaterialTypes;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedTask {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  author?: number;

  /**  */
  author_name?: string;

  /**  */
  created_at?: Date;

  /**  */
  closed_status_time?: Date;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  responsible_person?: number;

  /**  */
  responsible_person_name?: string;

  /**  */
  parent_task?: number;

  /**  */
  work_type?: CombinedWorkTypeTypes;

  /**  */
  task_objects?: TaskObjectInTask[];
}

export interface PatchedTaskObject {
  /**  */
  id?: number;

  /**  */
  object_type?: CombinedObjectTypeTypes;

  /**  */
  geometry?: string;

  /**  */
  address?: string;

  /**  */
  task?: number;

  /**  */
  task_name?: string;
}

export interface PatchedTeamOfMasters {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  primary_master?: number;

  /**  */
  primary_master_full_name?: string;
}

export interface PatchedTeamOfWorkers {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  teamlead?: number;

  /**  */
  teamlead_full_name?: string;

  /**  */
  team_of_masters?: number[];

  /**  */
  team_of_masters_names?: string[];

  /**  */
  is_working?: boolean;
}

export interface PatchedTownSquare {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  polygon?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedTrafficLights {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  point?: string;

  /**  */
  azimuth?: number;

  /**  */
  shape?: CombinedShapeTypes;

  /**  */
  shape_display?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedTrafficLightsFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;

  /**  */
  road_cons_object?: number;

  /**  */
  date_of_download?: Date;

  /**  */
  user_name?: string;
}

export interface PatchedTrafficLightsProject {
  /**  */
  id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  point?: string;

  /**  */
  azimuth?: number;

  /**  */
  shape?: CombinedShapeTypes;

  /**  */
  shape_display?: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PatchedTrafficManagementProject {
  /**  */
  id?: number;

  /**  */
  road?: number;

  /**  */
  name?: string;

  /**  */
  polygon?: string;

  /**  */
  created_by_full_name?: string;

  /**  */
  created_at?: Date;

  /**  */
  updated_at?: Date;
}

export interface PatchedTrafficManagementProjectFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file_url?: string;

  /**  */
  traffic_management_project?: number;

  /**  */
  traffic_management_project_name?: string;
}

export interface PatchedTrafficOrganizationSchema {
  /**  */
  id?: number;

  /**  */
  street_name?: string;

  /**  */
  projecting_object_name?: string;

  /**  */
  comment?: string;

  /**  */
  upload_date?: Date;
}

export interface PatchedTrafficOrganizationSchemaFile {
  /**  */
  id?: number;

  /**  */
  file_name?: string;

  /**  */
  file?: string;

  /**  */
  traffic_organization_schema?: number;

  /**  */
  traffic_organization_schema_name?: string;
}

export interface PatchedTramWay {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  material?: CombinedMaterialTypes;

  /**  */
  polygon?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  area?: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedTransportDetector {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  point?: string;

  /**  */
  detector_type?: CombinedDetectorTypeTypes;

  /**  */
  installation_plan?: number;

  /**  */
  installation_plan_name?: string;

  /**  */
  installation_plan_year?: number;

  /**  */
  installation_plan_color?: string;
}

export interface PatchedTubeConstruction {
  /**  */
  id?: number;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  tube_type?: CombinedTubeTypeTypes;

  /**  */
  line_path?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PatchedTypeOfInformationBoard {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedTypePowerCable {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  color?: string;

  /**  */
  section_cable?: number;
}

export interface PatchedUser {
  /**  */
  id?: number;

  /**  */
  username?: string;

  /**  */
  first_name?: string;

  /**  */
  last_name?: string;

  /**  */
  middle_name?: string;

  /**  */
  email?: string;

  /**  */
  work_phone?: string;

  /**  */
  mobile_phone?: string;

  /**  */
  organization?: number;

  /**  */
  organization_name?: string;

  /**  */
  position?: number;

  /**  */
  position_name?: string;

  /**  */
  date_joined?: Date;

  /**  */
  date_leaved?: Date;

  /**  */
  is_active?: boolean;

  /**  */
  report_failed_login_attempts?: boolean;

  /**  */
  deactivate_at_expiration?: boolean;

  /** Группы, к которым принадлежит данный пользователь. Пользователь получит все права, указанные в каждой из его\/её групп. */
  groups?: number[];
}

export interface PatchedUserDistricts {
  /**  */
  id?: number;

  /**  */
  user?: number;

  /**  */
  user_full_name?: string;

  /**  */
  district?: number;

  /**  */
  district_name?: string;
}

export interface PatchedUserOrganizations {
  /**  */
  id?: number;

  /**  */
  user?: number;

  /**  */
  user_full_name?: string;

  /**  */
  organization?: number;

  /**  */
  organization_name?: string;
}

export interface PatchedVehicle {
  /**  */
  id?: number;

  /**  */
  registration_number?: string;

  /**  */
  vehicle_type?: number;

  /**  */
  vehicle_type_name?: string;

  /**  */
  comment?: string;

  /**  */
  devices_vehicles?: number[];
}

export interface PatchedVehicleAxisEntityStatement {
  /**  */
  id?: number;

  /**  */
  number?: number;

  /**  */
  load?: number;

  /**  */
  distance?: number;

  /**  */
  vehicle_parameters?: number;

  /**  */
  slope?: number;
}

export interface PatchedVehicleAxisIndividualStatement {
  /**  */
  id?: number;

  /**  */
  number?: number;

  /**  */
  load?: number;

  /**  */
  distance?: number;

  /**  */
  vehicle_parameters?: number;

  /**  */
  slope?: number;
}

export interface PatchedVehicleAxleLoadStatement {
  /**  */
  id?: number;

  /**  */
  normative_load?: CombinedNormativeLoadTypes;

  /**  */
  coef_of_climatic_zones?: number;

  /**  */
  coef_of_repairs?: number;

  /**  */
  coef_of_influence_mass?: number;

  /**  */
  original_value_of_harm?: number;

  /**  */
  coef_a?: number;

  /**  */
  coef_b?: number;
}

export interface PatchedVehicleParametersEntityStatement {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  model?: string;

  /**  */
  weight?: number;

  /**  */
  registration_number?: string;

  /**  */
  axle_count?: number;

  /**  */
  vehicle_type?: CombinedVehicleTypeTypes;

  /**  */
  order?: number;

  /**  */
  axes?: VehicleAxisEntityStatement[];
}

export interface PatchedVehicleParametersIndividualStatement {
  /**  */
  id?: number;

  /**  */
  statement?: number;

  /**  */
  model?: string;

  /**  */
  weight?: number;

  /**  */
  registration_number?: string;

  /**  */
  axle_count?: number;

  /**  */
  vehicle_type?: CombinedVehicleTypeTypes;

  /**  */
  order?: number;

  /**  */
  axes?: VehicleAxisIndividualStatement[];
}

export interface PatchedVehicleType {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedViolationCamera {
  /**  */
  id?: number;

  /**  */
  azimuth?: number;

  /**  */
  point?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedVisibilityBlocker {
  /**  */
  id?: number;

  /**  */
  point?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name?: string;
}

export interface PatchedWaggon {
  /**  */
  id?: number;

  /**  */
  power?: number;

  /**  */
  count?: number;

  /**  */
  voltage?: number;

  /**  */
  on_board_voltage?: number;
}

export interface PatchedWorkCategory {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface PatchedWorkShift {
  /**  */
  id?: number;

  /**  */
  application?: number[];

  /**  */
  appointed_team?: number;

  /**  */
  appointed_team_name?: string;

  /**  */
  appointed_team_teamlead_fullname?: string;

  /**  */
  team_of_masters?: string[];

  /**  */
  start_at?: Date;

  /**  */
  end_at?: Date;

  /**  */
  in_progress?: boolean;
}

export interface PatchedWriteOffAct {
  /**  */
  id?: number;

  /**  */
  number?: number;

  /**  */
  reason?: string;

  /**  */
  date_of_act?: Date;

  /**  */
  responsible_person?: string;

  /**  */
  commission_persons?: string;

  /**  */
  date_of_order?: Date;

  /**  */
  number_of_order?: string;

  /**  */
  from_date?: Date;

  /**  */
  to_date?: Date;

  /**  */
  created_at?: Date;

  /**  */
  forms?: number[];
}

export interface PaymentType {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  name_bank: string;

  /**  */
  recepient_name: string;

  /**  */
  kpp: string;

  /**  */
  okpo: string;

  /**  */
  inn: string;

  /**  */
  oktmo: number;

  /**  */
  account_number: number;

  /**  */
  bank_code: number;

  /**  */
  budget_classification_code: number;

  /**  */
  purpose_of_payment: string;

  /**  */
  correspondent_account: string;
}

export interface PaymentTypeList {
  /**  */
  count: number;

  /**  */
  results: PaymentType[];
}

export interface PedBorder {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  line_path: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  height?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface PedBorderList {
  /**  */
  count: number;

  /**  */
  results: PedBorder[];
}

export interface PedBorderProject {
  /**  */
  id: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  line_path: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface PedBorderProjectList {
  /**  */
  count: number;

  /**  */
  results: PedBorderProject[];
}

export interface PedestrianCrossing {
  /**  */
  id: number;

  /**  */
  address?: string;

  /**  */
  coordinates: string;

  /**  */
  power_supply_type: CombinedPowerSupplyTypeTypes;

  /**  */
  adjustable?: boolean;

  /**  */
  lat: string;

  /**  */
  lon: string;
}

export interface PedestrianCrossingList {
  /**  */
  count: number;

  /**  */
  results: PedestrianCrossing[];
}

export interface Periodicities {
  /**  */
  id: string;

  /**  */
  name: string;
}

export interface PermissibleAxisLoadStatement {
  /**  */
  id: number;

  /**  */
  location_axis: CombinedLocationAxisTypes;

  /**  */
  min_distance: number;

  /**  */
  max_distance: number;

  /**  */
  permissible_load_first: number;

  /**  */
  permissible_load_first_extend: number;

  /**  */
  permissible_load_second: number;

  /**  */
  permissible_load_second_extend: number;

  /**  */
  permissible_load_third: number;

  /**  */
  permissible_load_third_extend: number;
}

export interface PermissibleAxisLoadStatementList {
  /**  */
  count: number;

  /**  */
  results: PermissibleAxisLoadStatement[];
}

export interface PermissibleWeightStatement {
  /**  */
  id: number;

  /**  */
  number_of_axles: number;

  /**  */
  is_single?: boolean;

  /**  */
  weigth: number;
}

export interface PermissibleWeightStatementList {
  /**  */
  count: number;

  /**  */
  results: PermissibleWeightStatement[];
}

export interface Permission {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  app_label: string;

  /**  */
  codename: string;
}

export interface PermissionList {
  /**  */
  count: number;

  /**  */
  results: Permission[];
}

export interface Person {
  /**  */
  id: number;

  /**  */
  user?: number;

  /**  */
  user_name: string;

  /**  */
  organization: number;

  /**  */
  organization_name: string;

  /**  */
  person_type: number;

  /**  */
  person_type_name: string;

  /**  */
  first_name: string;

  /**  */
  last_name: string;

  /**  */
  middle_name?: string;

  /**  */
  date_of_birth?: Date;

  /**  */
  phone?: string;

  /**  */
  extra?: object;
}

export interface PersonList {
  /**  */
  count: number;

  /**  */
  results: Person[];
}

export interface PersonType {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface PersonTypeList {
  /**  */
  count: number;

  /**  */
  results: PersonType[];
}

export interface Placeholder {
  /**  */
  name: string;

  /**  */
  label: string;

  /**  */
  value: string;
}

export interface Population {
  /**  */
  id: number;

  /**  */
  year: number;

  /**  */
  population: number;

  /**  */
  locality: number;

  /**  */
  locality_name: string;
}

export interface PopulationList {
  /**  */
  count: number;

  /**  */
  results: Population[];
}

export interface Position {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description?: string;
}

export interface PositionList {
  /**  */
  count: number;

  /**  */
  results: Position[];
}

export interface Railway {
  /**  */
  id: number;

  /**  */
  railway_type: CombinedRailwayTypeTypes;

  /**  */
  line_path: string;

  /**  */
  length?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface RailwayList {
  /**  */
  count: number;

  /**  */
  results: Railway[];
}

export interface ReportConfig {
  /**  */
  name: string;

  /**  */
  title: string;

  /**  */
  fields: Field[];

  /**  */
  filters: CombinedFiltersTypes;

  /**  */
  placeholders: Placeholder[];

  /**  */
  having: any | null[];

  /**  */
  group_by_choices: GroupByChoices[];

  /**  */
  group_by: any | null[];

  /**  */
  order_by: OrderBy[];

  /**  */
  show_total: boolean;

  /**  */
  show_sql: boolean;

  /**  */
  enumerate_rows: boolean;

  /**  */
  subtotals: any | null[];

  /**  */
  template_id?: number;

  /**  */
  template_name?: string;

  /**  */
  periodicity: string;

  /**  */
  public: boolean;

  /**  */
  operators: any | null[];

  /**  */
  periodicities: Periodicities[];
}

export interface RequestGeocoder {
  /**  */
  address: string;
}

export interface RequestSuggestAddress {
  /**  */
  address: string;
}

export interface ResponseGeocoder {
  /**  */
  point: string;
}

export interface ResponseSuggestAddress {
  /**  */
  value: string;

  /**  */
  unrestricted_value: string;

  /**  */
  data: CombinedDataTypes;
}

export interface ResponsiblePerson {
  /**  */
  id: number;

  /**  */
  user: number;

  /**  */
  user_name: string;

  /**  */
  username: string;

  /**  */
  can_individual_statement?: boolean;

  /**  */
  can_entity_statement?: boolean;

  /**  */
  can_federal_statement?: boolean;
}

export interface ResponsiblePersonList {
  /**  */
  count: number;

  /**  */
  results: ResponsiblePerson[];
}

export interface Road {
  /**  */
  id: number;

  /**  */
  road_number?: string;

  /**  */
  road_code?: string;

  /**  */
  name: string;

  /**  */
  importance?: CombinedImportanceTypes;

  /**  */
  commissioning_datetime?: Date;

  /**  */
  start_road?: number;

  /**  */
  end_road?: number;

  /**  */
  total_road_length?: number;

  /**  */
  is_checked?: boolean;

  /**  */
  road_category?: CombinedRoadCategoryTypes;

  /**  */
  category?: number;

  /**  */
  category_name: string;

  /**  */
  owner_organization?: number;

  /**  */
  district?: number;

  /**  */
  locality?: number;

  /**  */
  comment?: string;
}

export interface RoadAccident {
  /**  */
  id: number;

  /**  */
  address: string;

  /**  */
  date_time: Date;

  /**  */
  lighting?: string;

  /**  */
  lost_amount?: number;

  /**  */
  members_amount?: number;

  /**  */
  motion_factors?: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  road_importance?: CombinedRoadImportanceTypes;

  /**  */
  roads_disadvantages?: string;

  /**  */
  roadway_status?: string;

  /**  */
  road_accident_type: CombinedRoadAccidentTypeTypes;

  /**  */
  vehicles_amount: number;

  /**  */
  weather_condition?: string;

  /**  */
  wounded_amount?: number;

  /**  */
  place_origin_road?: number;

  /**  */
  point: string;
}

export interface RoadAccidentList {
  /**  */
  count: number;

  /**  */
  results: RoadAccident[];
}

export interface RoadAccidentParticipant {
  /**  */
  id: number;

  /**  */
  road_accident: number;

  /**  */
  road_accident_name: string;

  /**  */
  vehicle?: number;

  /**  */
  vehicle_number: string;

  /**  */
  category: string;

  /**  */
  additional_traffic_violations?: string;

  /**  */
  degree_of_intoxication?: string;

  /**  */
  driving_experience?: number;

  /**  */
  gender?: CombinedGenderTypes;

  /**  */
  immediate_traffic_violations?: string;

  /**  */
  leave_accident_place?: string;

  /**  */
  is_seat_belt_used?: boolean;

  /**  */
  severity_of_consequences: string;

  /**  */
  type_of_child_restraint?: string;
}

export interface RoadAccidentParticipantList {
  /**  */
  count: number;

  /**  */
  results: RoadAccidentParticipant[];
}

export interface RoadAccidentSection {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  segment: number;

  /**  */
  segment_name: string;

  /**  */
  description?: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  line_path: string;

  /**  */
  buffer?: string;

  /**  */
  start_road: number;

  /**  */
  end_road: number;

  /**  */
  recognition_date_time?: Date;

  /**  */
  measures_eliminate?: string;

  /**  */
  start_address?: string;

  /**  */
  end_address?: string;

  /**  */
  elimination_date_time?: Date;
}

export interface RoadAccidentSectionList {
  /**  */
  count: number;

  /**  */
  results: RoadAccidentSection[];
}

export interface RoadAccidentVehicle {
  /**  */
  id: number;

  /**  */
  number: number;

  /**  */
  color?: string;

  /**  */
  damaged_places?: string;

  /**  */
  vehicle_model: string;

  /**  */
  technical_issues?: string;

  /**  */
  vehicle_type: string;

  /**  */
  type_of_drive?: string;

  /**  */
  type_of_ownership?: string;

  /**  */
  year_of_issue?: number;
}

export interface RoadAccidentVehicleList {
  /**  */
  count: number;

  /**  */
  results: RoadAccidentVehicle[];
}

export interface RoadAxis {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  part_name?: string;

  /**  */
  length?: number;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface RoadAxisList {
  /**  */
  count: number;

  /**  */
  results: RoadAxis[];
}

export interface RoadCategory {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface RoadCategoryList {
  /**  */
  count: number;

  /**  */
  results: RoadCategory[];
}

export interface RoadClutchQuality {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;

  /**  */
  year_of_certification: number;

  /**  */
  year_of_quality: number;
}

export interface RoadClutchQualityByLane {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: string;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;
}

export interface RoadClutchQualityByLaneList {
  /**  */
  count: number;

  /**  */
  results: RoadClutchQualityByLane[];
}

export interface RoadClutchQualityList {
  /**  */
  count: number;

  /**  */
  results: RoadClutchQuality[];
}

export interface RoadClutchQualityRepresentation {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  importance: CombinedImportanceTypes;

  /**  */
  district: string;

  /**  */
  line_path: string;

  /**  */
  year: number;

  /**  */
  color: CombinedColorTypes;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  average_value: number;
}

export interface RoadClutchQualityRepresentationList {
  /**  */
  count: number;

  /**  */
  results: RoadClutchQualityRepresentation[];
}

export interface RoadComplexTypeQuality {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;

  /**  */
  year_of_certification: number;

  /**  */
  year_of_quality: number;
}

export interface RoadComplexTypeQualityList {
  /**  */
  count: number;

  /**  */
  results: RoadComplexTypeQuality[];
}

export interface RoadComplexTypeQualityRepresentation {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  importance: CombinedImportanceTypes;

  /**  */
  district: string;

  /**  */
  line_path: string;

  /**  */
  year: number;

  /**  */
  color: CombinedColorTypes;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  average_value: number;
}

export interface RoadComplexTypeQualityRepresentationList {
  /**  */
  count: number;

  /**  */
  results: RoadComplexTypeQualityRepresentation[];
}

export interface RoadFence {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  road_fence_type: CombinedRoadFenceTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  installation_date?: Date;

  /**  */
  dismantling_date?: Date;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface RoadFenceList {
  /**  */
  count: number;

  /**  */
  results: RoadFence[];
}

export interface RoadFenceProject {
  /**  */
  id: number;

  /**  */
  road_fence_type: CombinedRoadFenceTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface RoadFenceProjectList {
  /**  */
  count: number;

  /**  */
  results: RoadFenceProject[];
}

export interface RoadFlatnessQuality {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;

  /**  */
  year_of_certification: number;

  /**  */
  year_of_quality: number;
}

export interface RoadFlatnessQualityByLane {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: string;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;
}

export interface RoadFlatnessQualityByLaneList {
  /**  */
  count: number;

  /**  */
  results: RoadFlatnessQualityByLane[];
}

export interface RoadFlatnessQualityList {
  /**  */
  count: number;

  /**  */
  results: RoadFlatnessQuality[];
}

export interface RoadFlatnessQualityRepresentation {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  importance: CombinedImportanceTypes;

  /**  */
  district: string;

  /**  */
  line_path: string;

  /**  */
  year: number;

  /**  */
  color: CombinedColorTypes;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  average_value: number;
}

export interface RoadFlatnessQualityRepresentationList {
  /**  */
  count: number;

  /**  */
  results: RoadFlatnessQualityRepresentation[];
}

export interface RoadList {
  /**  */
  count: number;

  /**  */
  results: Road[];
}

export interface RoadMarkingLine {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  road_marking_type?: number;

  /**  */
  road_marking_type_name: string;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface RoadMarkingLineFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file: string;

  /**  */
  road_cons_object: number;

  /**  */
  date_of_download: Date;

  /**  */
  user_name: string;
}

export interface RoadMarkingLineFileList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingLineFile[];
}

export interface RoadMarkingLineList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingLine[];
}

export interface RoadMarkingLineProject {
  /**  */
  id: number;

  /**  */
  road_marking_type?: number;

  /**  */
  road_marking_type_name: string;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface RoadMarkingLineProjectList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingLineProject[];
}

export interface RoadMarkingLineType {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  width?: number;

  /**  */
  height?: number;
}

export interface RoadMarkingLineTypeList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingLineType[];
}

export interface RoadMarkingPoint {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  road_marking_type_name: string;

  /**  */
  point: string;

  /**  */
  azimuth?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface RoadMarkingPointFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file: string;

  /**  */
  road_cons_object: number;

  /**  */
  date_of_download: Date;

  /**  */
  user_name: string;
}

export interface RoadMarkingPointFileList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingPointFile[];
}

export interface RoadMarkingPointList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingPoint[];
}

export interface RoadMarkingPointProject {
  /**  */
  id: number;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  point: string;

  /**  */
  azimuth?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface RoadMarkingPointProjectList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingPointProject[];
}

export interface RoadMarkingPolygon {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  road_marking_type_name: string;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  area?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface RoadMarkingPolygonFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file: string;

  /**  */
  road_cons_object: number;

  /**  */
  date_of_download: Date;

  /**  */
  user_name: string;
}

export interface RoadMarkingPolygonFileList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingPolygonFile[];
}

export interface RoadMarkingPolygonList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingPolygon[];
}

export interface RoadMarkingPolygonProject {
  /**  */
  id: number;

  /**  */
  road_marking_type?: CombinedRoadMarkingTypeTypes;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface RoadMarkingPolygonProjectList {
  /**  */
  count: number;

  /**  */
  results: RoadMarkingPolygonProject[];
}

export interface RoadMarksChecker {
  /**  */
  id: number;

  /**  */
  camera_id: string;

  /**  */
  mask_id: string;

  /**  */
  image_path?: string;

  /**  */
  polygon: number[];

  /**  */
  marks_status?: boolean;
}

export interface RoadMarksCheckerList {
  /**  */
  count: number;

  /**  */
  results: RoadMarksChecker[];
}

export interface RoadQualityCoefficients {
  /**  */
  id: number;

  /**  */
  date_time_of_last_analysis: Date;

  /**  */
  deleted: Date;

  /**  */
  coef_school_bus_traffic: number;

  /**  */
  coef_public_traffic: number;

  /**  */
  coef_social_sign_roads: number;

  /**  */
  coef_alternative_detour: number;

  /**  */
  coef_citizen_appeals: number;

  /**  */
  date_to_analysis: Date;
}

export interface RoadQualityCoefficientsList {
  /**  */
  count: number;

  /**  */
  results: RoadQualityCoefficients[];
}

export interface RoadRutQuality {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;

  /**  */
  year_of_certification: number;

  /**  */
  year_of_quality: number;
}

export interface RoadRutQualityByLane {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: string;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;
}

export interface RoadRutQualityByLaneList {
  /**  */
  count: number;

  /**  */
  results: RoadRutQualityByLane[];
}

export interface RoadRutQualityList {
  /**  */
  count: number;

  /**  */
  results: RoadRutQuality[];
}

export interface RoadRutQualityRepresentation {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  importance: CombinedImportanceTypes;

  /**  */
  district: string;

  /**  */
  line_path: string;

  /**  */
  year: number;

  /**  */
  color: CombinedColorTypes;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  average_value: number;
}

export interface RoadRutQualityRepresentationList {
  /**  */
  count: number;

  /**  */
  results: RoadRutQualityRepresentation[];
}

export interface RoadShoulder {
  /**  */
  id: number;

  /**  */
  width?: number;

  /**  */
  length?: number;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface RoadShoulderList {
  /**  */
  count: number;

  /**  */
  results: RoadShoulder[];
}

export interface RoadSign {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  road_sign_type?: number;

  /**  */
  road_sign_type_name: string;

  /**  */
  road_sign_image: string;

  /**  */
  road_sign_subtype?: number;

  /**  */
  road_sign_subtype_name: string;

  /**  */
  road_sign_image_to_remove: string;

  /**  */
  road_sign_priority: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  point: string;

  /**  */
  azimuth?: number;

  /**  */
  installation_date?: Date;

  /**  */
  dismantling_date?: Date;

  /**  */
  road_sign_support_type?: CombinedRoadSignSupportTypeTypes;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  subtype?: string;

  /**  */
  to_remove?: boolean;

  /**  */
  road_sign_group?: number;

  /**  */
  road_sign_group_name: string;

  /**  */
  application?: number;
}

export interface RoadSignFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file: string;

  /**  */
  road_cons_object: number;

  /**  */
  date_of_download: Date;

  /**  */
  user_name: string;
}

export interface RoadSignFileList {
  /**  */
  count: number;

  /**  */
  results: RoadSignFile[];
}

export interface RoadSignGroup {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  custom_position?: string;

  /**  */
  custom_size?: object;

  /**  */
  azimuth?: number;

  /**  */
  road_signs_list: number[];
}

export interface RoadSignGroupList {
  /**  */
  count: number;

  /**  */
  results: RoadSignGroup[];
}

export interface RoadSignList {
  /**  */
  count: number;

  /**  */
  results: RoadSign[];
}

export interface RoadSignProject {
  /**  */
  id: number;

  /**  */
  road_sign_type?: number;

  /**  */
  road_sign_type_name: string;

  /**  */
  road_sign_image: string;

  /**  */
  road_sign_priority: number;

  /**  */
  road_sign_subtype_name: string;

  /**  */
  road_sign_subtype?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  point: string;

  /**  */
  azimuth?: number;

  /**  */
  installation_date?: Date;

  /**  */
  road_sign_support_type?: CombinedRoadSignSupportTypeTypes;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  subtype?: string;

  /**  */
  road_sign_group?: number;

  /**  */
  road_sign_group_name: string;

  /**  */
  application?: number;
}

export interface RoadSignProjectList {
  /**  */
  count: number;

  /**  */
  results: RoadSignProject[];
}

export interface RoadSignSubType {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  road_sign_type: number;

  /**  */
  image: string;
}

export interface RoadSignSubTypeList {
  /**  */
  count: number;

  /**  */
  results: RoadSignSubType[];
}

export interface RoadSignType {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  number: string;

  /**  */
  priority: number;

  /**  */
  group_type: CombinedGroupTypeTypes;

  /**  */
  image?: string;

  /**  */
  image_border_green?: string;

  /**  */
  image_border_red?: string;
}

export interface RoadSignTypeList {
  /**  */
  count: number;

  /**  */
  results: RoadSignType[];
}

export interface RoadSignsChecker {
  /**  */
  id: number;

  /**  */
  camera_id: string;

  /**  */
  mask_id: string;

  /**  */
  image_path?: string;

  /**  */
  bbox: number[];

  /**  */
  sign_status?: boolean;
}

export interface RoadSignsCheckerList {
  /**  */
  count: number;

  /**  */
  results: RoadSignsChecker[];
}

export interface RoadStrengthQuality {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;

  /**  */
  year_of_certification: number;

  /**  */
  year_of_quality: number;
}

export interface RoadStrengthQualityList {
  /**  */
  count: number;

  /**  */
  results: RoadStrengthQuality[];
}

export interface RoadStrengthQualityRepresentation {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  importance: CombinedImportanceTypes;

  /**  */
  district: string;

  /**  */
  line_path: string;

  /**  */
  year: number;

  /**  */
  color: CombinedColorTypes;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  average_value: number;
}

export interface RoadStrengthQualityRepresentationList {
  /**  */
  count: number;

  /**  */
  results: RoadStrengthQualityRepresentation[];
}

export interface RoadSurfaceConditionQuality {
  /**  */
  id: number;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: string;

  /**  */
  normative_value: number;

  /**  */
  less_allowable_value: number;

  /**  */
  value: number;

  /**  */
  import_datetime: Date;

  /**  */
  updated_date: Date;
}

export interface RoadSurfaceConditionQualityList {
  /**  */
  count: number;

  /**  */
  results: RoadSurfaceConditionQuality[];
}

export interface RoadWay {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  roadway_type: CombinedRoadwayTypeTypes;

  /**  */
  material: CombinedMaterialTypes;

  /**  */
  polygon: string;

  /**  */
  num_lanes?: number;

  /**  */
  width?: number;

  /**  */
  length?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  street_name?: string;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface RoadWayList {
  /**  */
  count: number;

  /**  */
  results: RoadWay[];
}

export interface RoadWayProject {
  /**  */
  id: number;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  application?: number;
}

export interface RoadWayProjectList {
  /**  */
  count: number;

  /**  */
  results: RoadWayProject[];
}

export interface RoadWork {
  /**  */
  id: number;

  /**  */
  object_code: string;

  /**  */
  full_name: string;

  /**  */
  object_is_finished?: boolean;

  /**  */
  object_registration_date?: Date;

  /**  */
  object_end_warranty_date?: Date;

  /**  */
  start_km?: number;

  /**  */
  end_km?: number;

  /**  */
  geometry?: string;

  /**  */
  road: string;

  /**  */
  road_type?: string;

  /**  */
  start_reason?: string;

  /**  */
  curator?: string;

  /**  */
  period_start?: Date;

  /**  */
  period_end?: Date;

  /**  */
  project_cost: number;

  /**  */
  work_category?: number;

  /**  */
  work_category_name: string;

  /**  */
  dangerous_segment?: number;

  /**  */
  dangerous_segment_name: string;

  /**  */
  customer?: number;

  /**  */
  customer_name: string;

  /**  */
  gen_contractor?: number;

  /**  */
  gen_contractor_name: string;
}

export interface RoadWorkList {
  /**  */
  count: number;

  /**  */
  results: RoadWork[];
}

export interface RoadsQualityUploadFileHistory {
  /**  */
  id: number;

  /**  */
  original_file_name: string;

  /**  */
  unique_file_name: string;

  /**  */
  uploaded_datetime: Date;

  /**  */
  parsing_done: boolean;

  /**  */
  update_done: boolean;

  /**  */
  has_errors: boolean;

  /**  */
  errors_href: string;
}

export interface RoadsQualityUploadFileHistoryErrors {
  /**  */
  errors: object;
}

export interface RoadsQualityUploadFileHistoryErrorsList {
  /**  */
  count: number;

  /**  */
  results: RoadsQualityUploadFileHistoryErrors[];
}

export interface RoadsQualityUploadFileHistoryList {
  /**  */
  count: number;

  /**  */
  results: RoadsQualityUploadFileHistory[];
}

export interface Route {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  number?: string;

  /**  */
  district?: number;

  /**  */
  route_type: CombinedRouteTypeTypes;

  /**  */
  district_name: string;

  /**  */
  vehicle_type: CombinedVehicleTypeTypes;

  /**  */
  route_type_name: string;
}

export interface RouteEntityStatement {
  /**  */
  id: number;

  /**  */
  name_route: string;

  /**  */
  start_route: string;

  /**  */
  end_route: string;

  /**  */
  path: string;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface RouteEntityStatementList {
  /**  */
  count: number;

  /**  */
  results: RouteEntityStatement[];
}

export interface RouteFederalStatement {
  /**  */
  id: number;

  /**  */
  name_route: string;

  /**  */
  start_route: string;

  /**  */
  end_route: string;

  /**  */
  path: string;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface RouteFederalStatementList {
  /**  */
  count: number;

  /**  */
  results: RouteFederalStatement[];
}

export interface RouteIndividualStatement {
  /**  */
  id: number;

  /**  */
  name_route: string;

  /**  */
  start_route: string;

  /**  */
  end_route: string;

  /**  */
  path: string;

  /**  */
  statement: number;

  /**  */
  statement_name: string;
}

export interface RouteIndividualStatementList {
  /**  */
  count: number;

  /**  */
  results: RouteIndividualStatement[];
}

export interface RouteList {
  /**  */
  count: number;

  /**  */
  results: Route[];
}

export interface RouteScheme {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  route: number;

  /**  */
  length?: number;

  /**  */
  route_name: string;
}

export interface RouteSchemeList {
  /**  */
  count: number;

  /**  */
  results: RouteScheme[];
}

export interface RouteSegment {
  /**  */
  id: number;

  /**  */
  is_start?: boolean;

  /**  */
  is_end?: boolean;

  /**  */
  stop: number;

  /**  */
  order: number;

  /**  */
  is_backward?: boolean;

  /**  */
  line_path: string;

  /**  */
  length: number;

  /**  */
  route_scheme: number;

  /**  */
  stop_name: string;

  /**  */
  route_scheme_name: string;

  /**  */
  stop_point: string;
}

export interface RouteSegmentList {
  /**  */
  count: number;

  /**  */
  results: RouteSegment[];
}

export interface Segment {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  name: string;

  /**  */
  road: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  area_m2?: number;

  /**  */
  line_path?: string;

  /**  */
  direction?: CombinedDirectionTypes;

  /**  */
  segment_type?: CombinedSegmentTypeTypes;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface SegmentAnalysisValues {
  /**  */
  id: number;

  /**  */
  road_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  average_value: number;

  /**  */
  sum_p: number;

  /**  */
  availability_alternative_detour_p: number;

  /**  */
  school_buses_intensity_p: number;

  /**  */
  public_transport_intensity_p: number;

  /**  */
  social_facilities_p: number;

  /**  */
  citizen_appeals_p: number;

  /**  */
  assignment: number;

  /**  */
  district: string;

  /**  */
  importance: CombinedImportanceTypes;

  /**  */
  is_analysis: boolean;

  /**  */
  color: string;

  /**  */
  line_path: string;
}

export interface SegmentAnalysisValuesList {
  /**  */
  count: number;

  /**  */
  results: SegmentAnalysisValues[];
}

export interface SegmentAxleLoad {
  /**  */
  id: number;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path: string;

  /**  */
  segment: number;

  /**  */
  segment_name: string;

  /**  */
  direction?: CombinedDirectionTypes;

  /**  */
  axle_load: CombinedAxleLoadTypes;
}

export interface SegmentAxleLoadList {
  /**  */
  count: number;

  /**  */
  results: SegmentAxleLoad[];
}

export interface SegmentDirectionOfMovement {
  /**  */
  id: number;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path: string;

  /**  */
  segment: number;

  /**  */
  segment_name: string;

  /**  */
  direction_of_movement?: CombinedDirectionOfMovementTypes;
}

export interface SegmentDirectionOfMovementList {
  /**  */
  count: number;

  /**  */
  results: SegmentDirectionOfMovement[];
}

export interface SegmentList {
  /**  */
  count: number;

  /**  */
  results: Segment[];
}

export interface SegmentSignalBar {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  signal_bar_type?: CombinedSignalBarTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface SegmentSignalBarList {
  /**  */
  count: number;

  /**  */
  results: SegmentSignalBar[];
}

export interface SegmentSignalBarProject {
  /**  */
  id: number;

  /**  */
  signal_bar_type?: CombinedSignalBarTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  application?: number;
}

export interface SegmentSignalBarProjectList {
  /**  */
  count: number;

  /**  */
  results: SegmentSignalBarProject[];
}

export interface SegmentSurfaceType {
  /**  */
  id: number;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path: string;

  /**  */
  segment: number;

  /**  */
  segment_name: string;

  /**  */
  segment_type: CombinedSegmentTypeTypes;
}

export interface SegmentSurfaceTypeList {
  /**  */
  count: number;

  /**  */
  results: SegmentSurfaceType[];
}

export interface SegmentTraySystem {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface SegmentTraySystemList {
  /**  */
  count: number;

  /**  */
  results: SegmentTraySystem[];
}

export interface SegmentWidth {
  /**  */
  id: number;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  segment_length: number;

  /**  */
  area_m2?: number;

  /**  */
  sub_line_path: string;

  /**  */
  segment: number;

  /**  */
  segment_name: string;

  /**  */
  width: number;
}

export interface SegmentWidthList {
  /**  */
  count: number;

  /**  */
  results: SegmentWidth[];
}

export interface SideWalk {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  material: CombinedMaterialTypes;

  /**  */
  sidewalk_type?: CombinedSidewalkTypeTypes;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  area: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface SideWalkList {
  /**  */
  count: number;

  /**  */
  results: SideWalk[];
}

export interface SideWalkProject {
  /**  */
  id: number;

  /**  */
  material: CombinedMaterialTypes;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  area: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface SideWalkProjectList {
  /**  */
  count: number;

  /**  */
  results: SideWalkProject[];
}

export interface Signer {
  /**  */
  id: number;

  /**  */
  user: number;

  /**  */
  user_name: string;

  /**  */
  type_of_signer: CombinedTypeOfSignerTypes;
}

export interface SignerList {
  /**  */
  count: number;

  /**  */
  results: Signer[];
}

export interface SocialFacility {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  address: string;

  /**  */
  facility_type: CombinedFacilityTypeTypes;

  /**  */
  geodata: string;
}

export interface SocialFacilityList {
  /**  */
  count: number;

  /**  */
  results: SocialFacility[];
}

export interface SpeedBump {
  /**  */
  id: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  line_path: string;

  /**  */
  year?: number;

  /**  */
  address?: string;

  /**  */
  material: CombinedMaterialTypes;

  /**  */
  type_speed_bump: CombinedTypeSpeedBumpTypes;

  /**  */
  on_pedestrian_crossing?: boolean;

  /**  */
  number_of_links?: number;

  /**  */
  number_of_ends?: number;

  /**  */
  to_remove?: boolean;
}

export interface SpeedBumpList {
  /**  */
  count: number;

  /**  */
  results: SpeedBump[];
}

export interface SpeedBumpProject {
  /**  */
  id: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  type_speed_bump: CombinedTypeSpeedBumpTypes;

  /**  */
  line_path: string;

  /**  */
  year?: number;

  /**  */
  address?: string;

  /**  */
  material: CombinedMaterialTypes;

  /**  */
  on_pedestrian_crossing?: boolean;

  /**  */
  number_of_links?: number;

  /**  */
  number_of_ends?: number;

  /**  */
  application?: number;
}

export interface SpeedBumpProjectList {
  /**  */
  count: number;

  /**  */
  results: SpeedBumpProject[];
}

export interface SpeedCounter {
  /**  */
  id: number;

  /**  */
  camera_id: string;

  /**  */
  mask_id: string;

  /**  */
  objects_speed: number;
}

export interface SpeedCounterList {
  /**  */
  count: number;

  /**  */
  results: SpeedCounter[];
}

export interface State {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  subject_code?: number;

  /**  */
  federal_district: CombinedFederalDistrictTypes;
}

export interface StateList {
  /**  */
  count: number;

  /**  */
  results: State[];
}

export interface StatusBusStop {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface StatusBusStopList {
  /**  */
  count: number;

  /**  */
  results: StatusBusStop[];
}

export interface StatusCommentStatement {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  comment_name: string;

  /**  */
  comment: string;
}

export interface StatusCommentStatementList {
  /**  */
  count: number;

  /**  */
  results: StatusCommentStatement[];
}

export interface StreetFurniture {
  /**  */
  id: number;

  /**  */
  type: CombinedTypeTypes;

  /**  */
  point: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface StreetFurnitureList {
  /**  */
  count: number;

  /**  */
  results: StreetFurniture[];
}

export interface Substation {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  location: string;

  /**  */
  current_sc?: number;

  /**  */
  rated_load?: number;

  /**  */
  current_load?: number;

  /**  */
  current_power?: number;

  /**  */
  rated_power?: number;

  /**  */
  current_voltage?: number;

  /**  */
  rated_voltage?: number;
}

export interface SubstationList {
  /**  */
  count: number;

  /**  */
  results: Substation[];
}

export interface SupplyCableTram {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  type_cable?: number;

  /**  */
  line?: number;

  /**  */
  substation?: number;

  /**  */
  line_name: string;

  /**  */
  line_path: string;

  /**  */
  substation_name: string;

  /**  */
  type_name: string;

  /**  */
  hanging_height?: number;
}

export interface SupplyCableTramList {
  /**  */
  count: number;

  /**  */
  results: SupplyCableTram[];
}

export interface SupplyCableTrolleybus {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  type_cable?: number;

  /**  */
  line?: number;

  /**  */
  substation?: number;

  /**  */
  line_name: string;

  /**  */
  line_path: string;

  /**  */
  substation_name: string;

  /**  */
  type_name: string;

  /**  */
  hanging_height?: number;
}

export interface SupplyCableTrolleybusList {
  /**  */
  count: number;

  /**  */
  results: SupplyCableTrolleybus[];
}

export interface SupportPillar {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  point: string;

  /**  */
  road_cons_id?: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface SupportPillarList {
  /**  */
  count: number;

  /**  */
  results: SupportPillar[];
}

export interface SupportWall {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  line_path: string;

  /**  */
  material: CombinedMaterialTypes;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  length?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface SupportWallList {
  /**  */
  count: number;

  /**  */
  results: SupportWall[];
}

export interface Task {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  description: string;

  /**  */
  author: number;

  /**  */
  author_name: string;

  /**  */
  created_at?: Date;

  /**  */
  closed_status_time?: Date;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  responsible_person?: number;

  /**  */
  responsible_person_name: string;

  /**  */
  parent_task?: number;

  /**  */
  work_type: CombinedWorkTypeTypes;

  /**  */
  task_objects?: TaskObjectInTask[];
}

export interface TaskList {
  /**  */
  count: number;

  /**  */
  results: Task[];
}

export interface TaskObject {
  /**  */
  id: number;

  /**  */
  object_type: CombinedObjectTypeTypes;

  /**  */
  geometry: string;

  /**  */
  address?: string;

  /**  */
  task?: number;

  /**  */
  task_name: string;
}

export interface TaskObjectInTask {
  /**  */
  id: number;

  /**  */
  object_type: CombinedObjectTypeTypes;

  /**  */
  geometry: string;

  /**  */
  address?: string;

  /**  */
  task_name: string;
}

export interface TaskObjectList {
  /**  */
  count: number;

  /**  */
  results: TaskObject[];
}

export interface TeamOfMasters {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  primary_master: number;

  /**  */
  primary_master_full_name: string;
}

export interface TeamOfMastersList {
  /**  */
  count: number;

  /**  */
  results: TeamOfMasters[];
}

export interface TeamOfWorkers {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  teamlead: number;

  /**  */
  teamlead_full_name: string;

  /**  */
  team_of_masters: number[];

  /**  */
  team_of_masters_names: string[];

  /**  */
  is_working: boolean;
}

export interface TeamOfWorkersList {
  /**  */
  count: number;

  /**  */
  results: TeamOfWorkers[];
}

export interface TlController {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  point: string;
}

export interface TlControllerCycle {
  /**  */
  id: number;

  /**  */
  tl_controller: number;

  /**  */
  tl_controller_name: string;

  /**  */
  name: string;

  /**  */
  offset: number;

  /**  */
  duration: number;

  /**  */
  updated_at: Date;
}

export interface TlControllerCycleList {
  /**  */
  count: number;

  /**  */
  results: TlControllerCycle[];
}

export interface TlControllerCyclePhase {
  /**  */
  id: number;

  /**  */
  cycle: number;

  /**  */
  cycle_name: string;

  /**  */
  number: number;

  /**  */
  offset: number;

  /**  */
  duration: number;
}

export interface TlControllerCyclePhaseList {
  /**  */
  count: number;

  /**  */
  results: TlControllerCyclePhase[];
}

export interface TlControllerDayPlan {
  /**  */
  id: number;

  /**  */
  week_plan: number;

  /**  */
  week_plan_name: string;

  /**  */
  cycle: number;

  /**  */
  cycle_name: string;

  /**  */
  start_time: string;
}

export interface TlControllerDayPlanList {
  /**  */
  count: number;

  /**  */
  results: TlControllerDayPlan[];
}

export interface TlControllerList {
  /**  */
  count: number;

  /**  */
  results: TlController[];
}

export interface TlControllerState {
  /**  */
  id: number;

  /**  */
  tl_controller: number;

  /**  */
  tl_controller_name: string;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  mode: string;

  /**  */
  code: string;

  /**  */
  phase: number;

  /**  */
  interim: boolean;

  /**  */
  created_at: Date;
}

export interface TlControllerStateList {
  /**  */
  count: number;

  /**  */
  results: TlControllerState[];
}

export interface TlControllerWeekPlan {
  /**  */
  id: number;

  /**  */
  tl_controller: number;

  /**  */
  tl_controller_name: string;

  /**  */
  week_day: CombinedWeekDayTypes;

  /**  */
  updated_at: Date;
}

export interface TlControllerWeekPlanList {
  /**  */
  count: number;

  /**  */
  results: TlControllerWeekPlan[];
}

export interface TokenObtainPair {
  /**  */
  username: string;

  /**  */
  password: string;

  /**  */
  access: string;

  /**  */
  refresh: string;
}

export interface TokenRefresh {
  /**  */
  access: string;

  /**  */
  refresh: string;
}

export interface TownSquare {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  name?: string;

  /**  */
  polygon: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface TownSquareList {
  /**  */
  count: number;

  /**  */
  results: TownSquare[];
}

export interface TrafficLights {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  point: string;

  /**  */
  azimuth?: number;

  /**  */
  shape?: CombinedShapeTypes;

  /**  */
  shape_display: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface TrafficLightsFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file: string;

  /**  */
  road_cons_object: number;

  /**  */
  date_of_download: Date;

  /**  */
  user_name: string;
}

export interface TrafficLightsFileList {
  /**  */
  count: number;

  /**  */
  results: TrafficLightsFile[];
}

export interface TrafficLightsList {
  /**  */
  count: number;

  /**  */
  results: TrafficLights[];
}

export interface TrafficLightsProject {
  /**  */
  id: number;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  point: string;

  /**  */
  azimuth?: number;

  /**  */
  shape?: CombinedShapeTypes;

  /**  */
  shape_display: string;

  /**  */
  installation_date?: Date;

  /**  */
  comment?: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  application?: number;
}

export interface TrafficLightsProjectList {
  /**  */
  count: number;

  /**  */
  results: TrafficLightsProject[];
}

export interface TrafficManagementProject {
  /**  */
  id: number;

  /**  */
  road?: number;

  /**  */
  name: string;

  /**  */
  polygon?: string;

  /**  */
  created_by_full_name: string;

  /**  */
  created_at: Date;

  /**  */
  updated_at: Date;
}

export interface TrafficManagementProjectFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file_url: string;

  /**  */
  traffic_management_project: number;

  /**  */
  traffic_management_project_name: string;
}

export interface TrafficManagementProjectFileList {
  /**  */
  count: number;

  /**  */
  results: TrafficManagementProjectFile[];
}

export interface TrafficManagementProjectList {
  /**  */
  count: number;

  /**  */
  results: TrafficManagementProject[];
}

export interface TrafficOrganizationSchema {
  /**  */
  id: number;

  /**  */
  street_name?: string;

  /**  */
  projecting_object_name: string;

  /**  */
  comment?: string;

  /**  */
  upload_date: Date;
}

export interface TrafficOrganizationSchemaFile {
  /**  */
  id: number;

  /**  */
  file_name: string;

  /**  */
  file: string;

  /**  */
  traffic_organization_schema: number;

  /**  */
  traffic_organization_schema_name: string;
}

export interface TrafficOrganizationSchemaFileList {
  /**  */
  count: number;

  /**  */
  results: TrafficOrganizationSchemaFile[];
}

export interface TrafficOrganizationSchemaList {
  /**  */
  count: number;

  /**  */
  results: TrafficOrganizationSchema[];
}

export interface TramWay {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  material: CombinedMaterialTypes;

  /**  */
  polygon: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  area: number;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface TramWayList {
  /**  */
  count: number;

  /**  */
  results: TramWay[];
}

export interface TransportDetector {
  /**  */
  id: number;

  /**  */
  name?: string;

  /**  */
  point: string;

  /**  */
  detector_type: CombinedDetectorTypeTypes;

  /**  */
  installation_plan: number;

  /**  */
  installation_plan_name: string;

  /**  */
  installation_plan_year: number;

  /**  */
  installation_plan_color: string;
}

export interface TransportDetectorList {
  /**  */
  count: number;

  /**  */
  results: TransportDetector[];
}

export interface TubeConstruction {
  /**  */
  id: number;

  /**  */
  status: CombinedStatusTypes;

  /**  */
  tube_type?: CombinedTubeTypeTypes;

  /**  */
  line_path: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;

  /**  */
  exploitative_kilometer?: number;

  /**  */
  to_remove?: boolean;

  /**  */
  application?: number;
}

export interface TubeConstructionList {
  /**  */
  count: number;

  /**  */
  results: TubeConstruction[];
}

export interface TypeOfInformationBoard {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface TypeOfInformationBoardList {
  /**  */
  count: number;

  /**  */
  results: TypeOfInformationBoard[];
}

export interface TypePowerCable {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  color: string;

  /**  */
  section_cable?: number;
}

export interface TypePowerCableList {
  /**  */
  count: number;

  /**  */
  results: TypePowerCable[];
}

export interface User {
  /**  */
  id: number;

  /**  */
  username: string;

  /**  */
  first_name: string;

  /**  */
  last_name: string;

  /**  */
  middle_name?: string;

  /**  */
  email: string;

  /**  */
  work_phone?: string;

  /**  */
  mobile_phone?: string;

  /**  */
  organization?: number;

  /**  */
  organization_name: string;

  /**  */
  position?: number;

  /**  */
  position_name: string;

  /**  */
  date_joined: Date;

  /**  */
  date_leaved?: Date;

  /**  */
  is_active?: boolean;

  /**  */
  report_failed_login_attempts?: boolean;

  /**  */
  deactivate_at_expiration?: boolean;

  /** Группы, к которым принадлежит данный пользователь. Пользователь получит все права, указанные в каждой из его\/её групп. */
  groups?: number[];
}

export interface UserDistricts {
  /**  */
  id: number;

  /**  */
  user: number;

  /**  */
  user_full_name: string;

  /**  */
  district: number;

  /**  */
  district_name: string;
}

export interface UserEmail {
  /**  */
  username: string;

  /**  */
  email: string;
}

export interface UserList {
  /**  */
  count: number;

  /**  */
  results: User[];
}

export interface UserOrganizations {
  /**  */
  id: number;

  /**  */
  user: number;

  /**  */
  user_full_name: string;

  /**  */
  organization: number;

  /**  */
  organization_name: string;
}

export interface UserPermissions {
  /**  */
  layers: string[];

  /**  */
  reports: string[];

  /**  */
  widgets: string[];

  /**  */
  directory: string[];
}

export interface UserSetting {
  /**  */
  data: object;
}

export interface Vehicle {
  /**  */
  id: number;

  /**  */
  registration_number: string;

  /**  */
  vehicle_type?: number;

  /**  */
  vehicle_type_name: string;

  /**  */
  comment?: string;

  /**  */
  devices_vehicles: number[];
}

export interface VehicleAxisEntityStatement {
  /**  */
  id: number;

  /**  */
  number: number;

  /**  */
  load: number;

  /**  */
  distance: number;

  /**  */
  vehicle_parameters?: number;

  /**  */
  slope?: number;
}

export interface VehicleAxisEntityStatementList {
  /**  */
  count: number;

  /**  */
  results: VehicleAxisEntityStatement[];
}

export interface VehicleAxisIndividualStatement {
  /**  */
  id: number;

  /**  */
  number: number;

  /**  */
  load: number;

  /**  */
  distance: number;

  /**  */
  vehicle_parameters?: number;

  /**  */
  slope?: number;
}

export interface VehicleAxisIndividualStatementList {
  /**  */
  count: number;

  /**  */
  results: VehicleAxisIndividualStatement[];
}

export interface VehicleAxleLoadStatement {
  /**  */
  id: number;

  /**  */
  normative_load: CombinedNormativeLoadTypes;

  /**  */
  coef_of_climatic_zones: number;

  /**  */
  coef_of_repairs: number;

  /**  */
  coef_of_influence_mass: number;

  /**  */
  original_value_of_harm: number;

  /**  */
  coef_a: number;

  /**  */
  coef_b: number;
}

export interface VehicleAxleLoadStatementList {
  /**  */
  count: number;

  /**  */
  results: VehicleAxleLoadStatement[];
}

export interface VehicleList {
  /**  */
  count: number;

  /**  */
  results: Vehicle[];
}

export interface VehicleParametersEntityStatement {
  /**  */
  id: number;

  /**  */
  statement?: number;

  /**  */
  model: string;

  /**  */
  weight: number;

  /**  */
  registration_number: string;

  /**  */
  axle_count: number;

  /**  */
  vehicle_type: CombinedVehicleTypeTypes;

  /**  */
  order?: number;

  /**  */
  axes: VehicleAxisEntityStatement[];
}

export interface VehicleParametersEntityStatementList {
  /**  */
  count: number;

  /**  */
  results: VehicleParametersEntityStatement[];
}

export interface VehicleParametersIndividualStatement {
  /**  */
  id: number;

  /**  */
  statement?: number;

  /**  */
  model: string;

  /**  */
  weight: number;

  /**  */
  registration_number: string;

  /**  */
  axle_count: number;

  /**  */
  vehicle_type: CombinedVehicleTypeTypes;

  /**  */
  order?: number;

  /**  */
  axes: VehicleAxisIndividualStatement[];
}

export interface VehicleParametersIndividualStatementList {
  /**  */
  count: number;

  /**  */
  results: VehicleParametersIndividualStatement[];
}

export interface VehicleType {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface VehicleTypeList {
  /**  */
  count: number;

  /**  */
  results: VehicleType[];
}

export interface ViolationCamera {
  /**  */
  id: number;

  /**  */
  azimuth?: number;

  /**  */
  point: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface ViolationCameraList {
  /**  */
  count: number;

  /**  */
  results: ViolationCamera[];
}

export interface ViolationWarrantyPeriod {
  /**  */
  id: number;

  /**  */
  road_complex_type_quality_representation_name: string;

  /**  */
  start_segment: number;

  /**  */
  end_segment: number;

  /**  */
  road_work_name: string;

  /**  */
  road_work_gen_contractor: string;

  /**  */
  road_work_curator: string;

  /**  */
  year: number;

  /**  */
  object_end_warranty_date: Date;

  /**  */
  date_time_detection: Date;

  /**  */
  point: string;
}

export interface ViolationWarrantyPeriodList {
  /**  */
  count: number;

  /**  */
  results: ViolationWarrantyPeriod[];
}

export interface VisibilityBlocker {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  segment?: number;

  /**  */
  segment_name: string;
}

export interface VisibilityBlockerList {
  /**  */
  count: number;

  /**  */
  results: VisibilityBlocker[];
}

export interface Waggon {
  /**  */
  id: number;

  /**  */
  power: number;

  /**  */
  count: number;

  /**  */
  voltage: number;

  /**  */
  on_board_voltage: number;
}

export interface WaggonList {
  /**  */
  count: number;

  /**  */
  results: Waggon[];
}

export interface WhiteTrackDetector {
  /**  */
  id: number;

  /**  */
  camera_id: string;

  /**  */
  mask_id: string;

  /**  */
  white_percent: number;

  /**  */
  track_status: boolean;
}

export interface WhiteTrackDetectorList {
  /**  */
  count: number;

  /**  */
  results: WhiteTrackDetector[];
}

export interface WorkCategory {
  /**  */
  id: number;

  /**  */
  name: string;
}

export interface WorkCategoryList {
  /**  */
  count: number;

  /**  */
  results: WorkCategory[];
}

export interface WorkShift {
  /**  */
  id: number;

  /**  */
  application?: number[];

  /**  */
  appointed_team: number;

  /**  */
  appointed_team_name: string;

  /**  */
  appointed_team_teamlead_fullname: string;

  /**  */
  team_of_masters: string[];

  /**  */
  start_at: Date;

  /**  */
  end_at?: Date;

  /**  */
  in_progress: boolean;
}

export interface WorkShiftList {
  /**  */
  count: number;

  /**  */
  results: WorkShift[];
}

export interface WorkShiftWithApplication {
  /**  */
  id: number;

  /**  */
  point: string;

  /**  */
  status?: CombinedStatusTypes;

  /**  */
  finish_of_work_planned_date: string;
}

export interface WriteOffAct {
  /**  */
  id: number;

  /**  */
  number: number;

  /**  */
  reason: string;

  /**  */
  date_of_act: Date;

  /**  */
  responsible_person: string;

  /**  */
  commission_persons: string;

  /**  */
  date_of_order: Date;

  /**  */
  number_of_order: string;

  /**  */
  from_date: Date;

  /**  */
  to_date: Date;

  /**  */
  created_at: Date;

  /**  */
  forms?: number[];
}

export interface WriteOffActList {
  /**  */
  count: number;

  /**  */
  results: WriteOffAct[];
}
export type CombinedStatusTypes = StatusF30Enum;
export enum AdministrationAssignmentsStatusEnum {
  'IN_WORK' = 'IN_WORK',
  'DONE' = 'DONE',
  'CANCELED' = 'CANCELED',
}
export type CombinedObjectTypeTypes = ObjectTypeB19Enum;
export enum ApplicationChatRoomStatusEnum {
  'OPEN' = 'OPEN',
  'ARCHIVED' = 'ARCHIVED',
}
export type CombinedWorkTypeTypes = TaskWorkTypeEnum;
export enum ApplicationTypeWorkTypeEnum {
  'EXPLOITATION' = 'EXPLOITATION',
  'INSTALLATION' = 'INSTALLATION',
  'APPLYING' = 'APPLYING',
  'REMOVAL' = 'REMOVAL',
  'DEMARCATION' = 'DEMARCATION',
  'UNKNOWN' = 'UNKNOWN',
}
export type CombinedDirectionTypes = Direction591Enum;
export type AxisVisibilityDirectionEnum = 1 | -1;

export enum AxleLoadEnum {
  'TEN' = 'TEN',
  'ELEVEN_AND_HALF' = 'ELEVEN_AND_HALF',
  'SIX' = 'SIX',
}
export type CombinedPaymentMethodTypes = PaymentMethodEnum;
export enum BuildingMaterialEnum {
  'STONE' = 'STONE',
  'REINFORCED_CONCRETE' = 'REINFORCED_CONCRETE',
  'METAL' = 'METAL',
  'IRON_CONCRETE' = 'IRON_CONCRETE',
}
export type CombinedTypeBusTypes = TypeBusEnum;
export type CombinedTypeCanopyTypes = TypeCanopyEnum;
export enum BusStopDirectionEnum {
  'EVEN' = 'EVEN',
  'ODD' = 'ODD',
}
export type CombinedRoadTypeTypes = RoadImportanceEnum;
export type CombinedCoefOfClimaticZonesTypes = CoefOfClimaticZonesEnum;
export type CombinedFormulaTypeTypes = FormulaTypeEnum;
export type CombinedWidgetTypeTypes = WidgetTypeEnum;
export type CombinedChartTypeTypes = ChartTypeEnum;
export type CombinedDataSourceTypes = DataSourceEnum;
export type CombinedCountingMethodTypes = CountingMethodEnum;
export type CombinedCountingIntervalTypes = CountingIntervalEnum;
export type CombinedCountingPeriodTypes = CountingPeriodEnum;
export type CombinedUpdateIntervalTypes = UpdateIntervalEnum;
export type CombinedDataTypeTypes = DataTypeEnum;
export enum ChartTypeEnum {
  'PIE' = 'PIE',
  'BAR' = 'BAR',
  'LINE_GRAPH' = 'LINE_GRAPH',
  'FILLED_LINE_GRAPH' = 'FILLED_LINE_GRAPH',
}
export type CombinedFederalDistrictTypes = FederalDistrictEnum;
export enum CoefOfClimaticZonesEnum {
  'KEY_1' = '1',
  'KEY_0.35' = '0.35',
}

export enum ColorEnum {
  '#EB5757' = '#EB5757',
  '#F2994A' = '#F2994A',
  '#27AE60' = '#27AE60',
}
export type CombinedNameTypes = NameEnum;
export enum ConstructionTypeEnum {
  'BRIDGE' = 'BRIDGE',
  'BRIDGE_STRUCTURE' = 'BRIDGE_STRUCTURE',
  'SMALL_BRIDGE' = 'SMALL_BRIDGE',
  'OVERPASS' = 'OVERPASS',
  'VIADUCT' = 'VIADUCT',
  'FLYOVER' = 'FLYOVER',
  'TUNNEL' = 'TUNNEL',
  'CITY_TUNNEL' = 'CITY_TUNNEL',
  'PEDESTRIAN_TUNNEL' = 'PEDESTRIAN_TUNNEL',
  'OVERHEAD_PEDESTRIAN_CROSSING' = 'OVERHEAD_PEDESTRIAN_CROSSING',
  'OVERHEAD_COMMUNICATIONS' = 'OVERHEAD_COMMUNICATIONS',
  'UNDERGROUND_PEDESTRIAN_CROSSING' = 'UNDERGROUND_PEDESTRIAN_CROSSING',
  'U_SHAPED_SUPPORT_FOR_TSODD' = 'U_SHAPED_SUPPORT_FOR_TSODD',
  'CROSSWALK' = 'CROSSWALK',
}

export enum CountingIntervalEnum {
  'FIFTEEN_MINUTES' = 'FIFTEEN_MINUTES',
  'THIRTY_MINUTES' = 'THIRTY_MINUTES',
  'ONE_HOUR' = 'ONE_HOUR',
  'TWELVE_HOURS' = 'TWELVE_HOURS',
  'TWENTY_FOUR_HOURS' = 'TWENTY_FOUR_HOURS',
  'WEEK' = 'WEEK',
  'MONTH' = 'MONTH',
}

export enum CountingMethodEnum {
  'MEAN' = 'MEAN',
  'SUM' = 'SUM',
}

export enum CountingPeriodEnum {
  'TWENTY_FOUR_HOURS' = 'TWENTY_FOUR_HOURS',
  'WEEK' = 'WEEK',
  'MONTH' = 'MONTH',
  'QUARTER' = 'QUARTER',
  'HALF_YEAR' = 'HALF_YEAR',
  'YEAR' = 'YEAR',
}
export type CombinedRegulatedTypeTypes = RegulatedTypeEnum;
export type CombinedTypeTypes = StreetFurnitureTypeEnum;
export enum CurbstoneTypeEnum {
  'NORMAL' = 'NORMAL',
  'LOWERING_SECTION' = 'LOWERING_SECTION',
  'LIFTING_SECTION' = 'LIFTING_SECTION',
}

export enum DataSourceEnum {
  'CAMERAS' = 'CAMERAS',
}

export enum DataTypeEnum {
  'VehicleIntensity' = 'VehicleIntensity',
  'AverageVehicleSpeed' = 'AverageVehicleSpeed',
  'PedestrianIntensity' = 'PedestrianIntensity',
}
export type CombinedDetectionTypeTypes = DetectionTypeEnum;
export type CombinedValidationStatusTypes = ValidationStatusEnum;
export type CombinedHscTypeTypes = HscTypeEnum;
export type CombinedMaskTypeTypes = MaskTypeEnum;
export enum DetectionStatusEnum {
  'REQUIRED_CLARIFICATION' = 'REQUIRED_CLARIFICATION',
  'CONFIRMED' = 'CONFIRMED',
  'FALSE_DETECTION' = 'FALSE_DETECTION',
  'NOT_RELEVANT' = 'NOT_RELEVANT',
  'APPLICATION_CREATED' = 'APPLICATION_CREATED',
  'FIXED' = 'FIXED',
}

export enum DetectionTypeEnum {
  'PIT' = 'PIT',
  'ROAD_SURFACE' = 'ROAD_SURFACE',
  'ROAD_MARKING' = 'ROAD_MARKING',
  'ROAD_SIGN' = 'ROAD_SIGN',
  'SPEED_BUMP' = 'SPEED_BUMP',
  'TRAFFIC_LIGHTS' = 'TRAFFIC_LIGHTS',
}

export enum DetectorTypeEnum {
  'DETECTOR' = 'DETECTOR',
  'CAMERA' = 'CAMERA',
}
export type CombinedTimezoneTypes = TimezoneEnum;
export type CombinedLibraryTypes = LibraryEnum;
export enum Direction591Enum {
  'FORWARD' = 'FORWARD',
  'BACKWARD' = 'BACKWARD',
  'ALL' = 'ALL',
}

export enum DirectionOfMovementEnum {
  'FORWARD' = 'FORWARD',
  'BACKWARD' = 'BACKWARD',
  'ALL' = 'ALL',
}
export type CombinedCityDistrictIdsTypes = DynamicFilterItem;
export enum ElementTypeEnum {
  'SIDE_STONE' = 'SIDE_STONE',
  'BENCHES' = 'BENCHES',
  'BIN' = 'BIN',
  'INFORMATION_BOARDS' = 'INFORMATION_BOARDS',
  'BARRIER' = 'BARRIER',
  'FOUNTAIN' = 'FOUNTAIN',
}
export type CombinedConstructionTypeTypes = ConstructionTypeEnum;
export type CombinedBuildingMaterialTypes = BuildingMaterialEnum;
export type CombinedTypeOfCoverageTypes = TypeOfCoverageEnum;
export type CombinedTypeOfDocumentForVehicleTypes = TypeOfDocumentForVehicleEnum;
export type CombinedTransportationTypeTypes = TransportationTypeEnum;
export type CombinedTypeOfVehicleOwnerTypes = TypeOfVehicleOwnerEnum;
export type CombinedMaterialMeasurementUnitTypes = MaterialMeasurementUnitEnum;
export enum FacilityTypeEnum {
  'SCHOOL' = 'SCHOOL',
  'KINDERGARTEN' = 'KINDERGARTEN',
  'HOSPITAL' = 'HOSPITAL',
}

export enum FederalDistrictEnum {
  'CENTRAL' = 'CENTRAL',
  'NORTHWESTERN' = 'NORTHWESTERN',
  'SOUTHERN' = 'SOUTHERN',
  'NORTH_CAUCASIAN' = 'NORTH_CAUCASIAN',
  'VOLGA' = 'VOLGA',
  'URAL' = 'URAL',
  'SIBERIAN' = 'SIBERIAN',
  'FAR_EASTERN' = 'FAR_EASTERN',
}
export type CombinedTransporterTypeTypes = TransporterTypeEnum;
export type CombinedShippingTypeTypes = ShippingTypeEnum;
export type CombinedFreightKindTypes = FreightKindEnum;
export type CombinedMessageTypeTypes = MessageTypeEnum;
export enum FederalStatementCancelStatusEnum {
  'Accepted' = 'Accepted',
  'Rejected' = 'Rejected',
}

export enum FederalStatementStatusEnum {
  'Approved' = 'Approved',
  'Declined' = 'Declined',
  'InProgress' = 'InProgress',
  'Cancelled' = 'Cancelled',
}
export type CombinedVehicleTypeTypes = VehicleType7daEnum;
export enum FormulaTypeEnum {
  'FIRST' = 'FIRST',
  'SECOND' = 'SECOND',
}

export enum FreightKindEnum {
  'Partible' = 'Partible',
  'Impartible' = 'Impartible',
}

export enum GenderEnum {
  'MALE' = 'MALE',
  'FEMALE' = 'FEMALE',
  'NOT_DETERMINED' = 'NOT_DETERMINED',
}

export enum GroupTypeEnum {
  'WARNING' = 'WARNING',
  'PRIORITY' = 'PRIORITY',
  'PROHIBITORY' = 'PROHIBITORY',
  'MANDATORY' = 'MANDATORY',
  'SERVICE' = 'SERVICE',
  'SPECIAL_REGULATION' = 'SPECIAL_REGULATION',
  'INFORMATION' = 'INFORMATION',
  'ADDITIONAL_INFORMATION' = 'ADDITIONAL_INFORMATION',
}

export enum HscTypeEnum {
  'MUNICIPAL' = 'MUNICIPAL',
  'FEDERAL' = 'FEDERAL',
}
export type CombinedElementTypeTypes = ElementTypeEnum;
export type CombinedTypeDocumentTypes = TypeDocumentEnum;
export enum LibraryEnum {
  'lib285' = 'lib285',
  'libasc6' = 'libasc6',
  'libautographgsm' = 'libautographgsm',
  'libazimuthgsm5' = 'libazimuthgsm5',
  'libbncomplex' = 'libbncomplex',
  'libcguard' = 'libcguard',
  'libfort11x' = 'libfort11x',
  'libfort300' = 'libfort300',
  'libgalileo' = 'libgalileo',
  'libgranit' = 'libgranit',
  'libgranit3' = 'libgranit3',
  'libionulc' = 'libionulc',
  'libnavis' = 'libnavis',
  'libscout' = 'libscout',
  'libshtrih' = 'libshtrih',
  'libtachograph_shtrih' = 'libtachograph_shtrih',
  'libwialon' = 'libwialon',
  'libwialon_ips' = 'libwialon_ips',
}

export enum LocationAxisEnum {
  'FIRST' = 'FIRST',
  'SECOND' = 'SECOND',
  'THIRD' = 'THIRD',
  'FOURTH' = 'FOURTH',
  'FIFTH' = 'FIFTH',
}

export enum MaskNameEnum {
  'WhiteTrack_detector' = 'WhiteTrack_detector',
  'ObjectCounter' = 'ObjectCounter',
  'SpeedCounter' = 'SpeedCounter',
  'RoadSignsChecker' = 'RoadSignsChecker',
  'RoadMarksChecker' = 'RoadMarksChecker',
}

export enum MaskTypeEnum {
  'WhiteTrack_detector' = 'WhiteTrack_detector',
  'ObjectCounterCar' = 'ObjectCounterCar',
  'ObjectCounterPedestrians' = 'ObjectCounterPedestrians',
  'SpeedCounter' = 'SpeedCounter',
  'RoadSignsChecker' = 'RoadSignsChecker',
  'RoadMarksChecker' = 'RoadMarksChecker',
}
export type CombinedMeasurementUnitTypes = MeasurementUnitEnum;
export enum Material0b5Enum {
  'ASPHALT' = 'ASPHALT',
  'RUBBER' = 'RUBBER',
  'COMPOSITE' = 'COMPOSITE',
  'POLYMER_SAND' = 'POLYMER_SAND',
}

export enum Material0d9Enum {
  'ASPHALT_CONCRETE' = 'ASPHALT_CONCRETE',
  'CEMENT_CONCRETE' = 'CEMENT_CONCRETE',
  'GRAVEL' = 'GRAVEL',
  'CRUSHED_STONE' = 'CRUSHED_STONE',
  'PAVING_STONE' = 'PAVING_STONE',
  'PRIMING' = 'PRIMING',
}

export enum Material0fcEnum {
  'ASPHALT_CONCRETE' = 'ASPHALT_CONCRETE',
  'CEMENT_CONCRETE' = 'CEMENT_CONCRETE',
  'NO' = 'NO',
  'CRUSHED_STONE' = 'CRUSHED_STONE',
  'PRIMING' = 'PRIMING',
  'WOOD' = 'WOOD',
  'REINFORCED_CONCRETE_SLABS' = 'REINFORCED_CONCRETE_SLABS',
  'PAVING_SLABS' = 'PAVING_SLABS',
}

export enum MaterialMeasurementUnitEnum {
  'METER' = 'METER',
  'TONNA' = 'TONNA',
  'KILOGRAM' = 'KILOGRAM',
  'GRAM' = 'GRAM',
  'LITR' = 'LITR',
  'CUBE_METER' = 'CUBE_METER',
  'UNIT' = 'UNIT',
}

export enum MeasurementUnitEnum {
  'METER' = 'METER',
  'TONNA' = 'TONNA',
  'KILOGRAM' = 'KILOGRAM',
  'GRAM' = 'GRAM',
  'LITR' = 'LITR',
  'CUBE_METER' = 'CUBE_METER',
  'UNIT' = 'UNIT',
}

export enum MessageTypeEnum {
  'APPROVAL_STATEMENT' = 'APPROVAL_STATEMENT',
  'CANCEL_APPROVAL_STATEMENT' = 'CANCEL_APPROVAL_STATEMENT',
  'ADDITIONAL_INSPECTION_STATEMENT' = 'ADDITIONAL_INSPECTION_STATEMENT',
}
export type CombinedMunicipalityTypeTypes = MunicipalityTypeEnum;
export enum MunicipalityTypeEnum {
  'RURAL_SETTLEMENT' = 'RURAL_SETTLEMENT',
  'URBAN_SETTLEMENT' = 'URBAN_SETTLEMENT',
  'MUNICIPAL_AREA' = 'MUNICIPAL_AREA',
  'URBAN_DISTRICT' = 'URBAN_DISTRICT',
  'FEDERAL_SIGNIFICANCE' = 'FEDERAL_SIGNIFICANCE',
  'INTRACITY_DIVISION_DIST' = 'INTRACITY_DIVISION_DIST',
  'INTRACITY_DISTRICT' = 'INTRACITY_DISTRICT',
  'MUNICIPAL_DISTRICT' = 'MUNICIPAL_DISTRICT',
}

export enum NameEnum {
  'DETECTION_BUFFER_SIZE_METERS' = 'DETECTION_BUFFER_SIZE_METERS',
}

export enum NormativeLoadEnum {
  'KEY_6' = '6',
  'KEY_10' = '10',
  'KEY_11.5' = '11.5',
}

export enum ObjectTypeB19Enum {
  'roadmarkingpoint' = 'roadmarkingpoint',
  'roadmarkingline' = 'roadmarkingline',
  'roadmarkingpolygon' = 'roadmarkingpolygon',
  'dividingline' = 'dividingline',
  'roadway' = 'roadway',
  'sidewalk' = 'sidewalk',
  'roadfence' = 'roadfence',
  'segmentsignalbar' = 'segmentsignalbar',
  'segmenttraysystem' = 'segmenttraysystem',
  'cabinetforcablecommunications' = 'cabinetforcablecommunications',
  'improvementelement' = 'improvementelement',
  'contourlawn' = 'contourlawn',
  'townsquare' = 'townsquare',
  'roadsign' = 'roadsign',
  'pedborder' = 'pedborder',
  'trafficlights' = 'trafficlights',
  'lighting' = 'lighting',
  'supportwall' = 'supportwall',
  'engineeringconstruction' = 'engineeringconstruction',
  'tubeconstruction' = 'tubeconstruction',
  'supportpillar' = 'supportpillar',
  'tramway' = 'tramway',
  'UNKNOWN' = 'UNKNOWN',
}

export enum ObjectTypeD6fEnum {
  'ROAD_SIGN' = 'ROAD_SIGN',
  'ROAD_MARKING_POINT' = 'ROAD_MARKING_POINT',
  'ROAD_MARKING_LINE' = 'ROAD_MARKING_LINE',
  'ROAD_MARKING_POLYGON' = 'ROAD_MARKING_POLYGON',
  'TL_CONTROLLER' = 'TL_CONTROLLER',
  'TL_CONTROLLER_WELL' = 'TL_CONTROLLER_WELL',
  'BUS_STOP' = 'BUS_STOP',
  'BUS_STOP_SCOREBOARD' = 'BUS_STOP_SCOREBOARD',
}

export enum OrderByDirectionEnum {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export enum OvergroundPipesTypeEnum {
  'GAS_PIPELINE' = 'GAS_PIPELINE',
  'HEAT_SUPPLY' = 'HEAT_SUPPLY',
  'OTHER' = 'OTHER',
}

export enum ParkingPostTypeEnum {
  'PARKING_POLE' = 'PARKING_POLE',
  'HEMISPHERE' = 'HEMISPHERE',
  'BARRIER' = 'BARRIER',
}
export type CombinedPowerSupplyTypeTypes = PowerSupplyTypeEnum;
export type CombinedLocationAxisTypes = LocationAxisEnum;
export type CombinedRailwayTypeTypes = RailwayTypeEnum;
export type CombinedImportanceTypes = RoadImportanceEnum;
export type CombinedRoadCategoryTypes = RoadCategoryEnum;
export type CombinedRoadImportanceTypes = RoadImportanceEnum;
export type CombinedRoadAccidentTypeTypes = RoadAccidentTypeEnum;
export type CombinedGenderTypes = GenderEnum;
export type CombinedRoadFenceTypeTypes = RoadFenceTypeEnum;
export type CombinedRoadMarkingTypeTypes = RoadMarkingType419Enum;
export type CombinedRoadSignSupportTypeTypes = RoadSignSupportTypeEnum;
export type CombinedGroupTypeTypes = GroupTypeEnum;
export type CombinedRoadwayTypeTypes = RoadwayTypeEnum;
export type CombinedMaterialTypes = TramWayMaterialEnum;
export type CombinedRouteTypeTypes = RouteTypeEnum;
export type CombinedSegmentTypeTypes = SegmentType0d9Enum;
export type CombinedAxleLoadTypes = AxleLoadEnum;
export type CombinedDirectionOfMovementTypes = DirectionOfMovementEnum;
export type CombinedSignalBarTypeTypes = SignalBarTypeEnum;
export type CombinedSidewalkTypeTypes = SidewalkTypeEnum;
export type CombinedTypeOfSignerTypes = TypeOfSignerEnum;
export type CombinedFacilityTypeTypes = FacilityTypeEnum;
export type CombinedTypeSpeedBumpTypes = TypeSpeedBumpEnum;
export type CombinedShapeTypes = ShapeEnum;
export type CombinedDetectorTypeTypes = DetectorTypeEnum;
export type CombinedTubeTypeTypes = TubeTypeEnum;
export type CombinedNormativeLoadTypes = NormativeLoadEnum;
export enum PaymentMethodEnum {
  'RECEIPT' = 'RECEIPT',
  'SITE' = 'SITE',
}

export enum PowerSupplyTypeEnum {
  'POWER_GRID' = 'POWER_GRID',
  'SOLAR_BATTERY' = 'SOLAR_BATTERY',
  'NO_POWER' = 'NO_POWER',
}

export enum RailwayTypeEnum {
  'TRAM' = 'TRAM',
  'RAILWAY' = 'RAILWAY',
}

export enum RegulatedTypeEnum {
  'REGULATED' = 'REGULATED',
  'UNREGULATED' = 'UNREGULATED',
}
export type CombinedFiltersTypes = DynamicFilter;
export type CombinedDataTypes = DataSuggestAddress;
export enum RoadAccidentSectionStatusEnum {
  'NEW' = 'NEW',
  'APPROVED' = 'APPROVED',
  'CHECKING' = 'CHECKING',
  'LIQUIDATED' = 'LIQUIDATED',
  'NOT_LIQUIDATED' = 'NOT_LIQUIDATED',
}

export enum RoadAccidentTypeEnum {
  'TYPE_01' = 'TYPE_01',
  'TYPE_02' = 'TYPE_02',
  'TYPE_03' = 'TYPE_03',
  'TYPE_04' = 'TYPE_04',
  'TYPE_05' = 'TYPE_05',
  'TYPE_06' = 'TYPE_06',
  'TYPE_07' = 'TYPE_07',
  'TYPE_08' = 'TYPE_08',
  'TYPE_09' = 'TYPE_09',
  'TYPE_10' = 'TYPE_10',
  'TYPE_11' = 'TYPE_11',
  'TYPE_12' = 'TYPE_12',
  'TYPE_13' = 'TYPE_13',
  'TYPE_14' = 'TYPE_14',
  'TYPE_15' = 'TYPE_15',
  'TYPE_16' = 'TYPE_16',
  'TYPE_17' = 'TYPE_17',
  'TYPE_18' = 'TYPE_18',
  'TYPE_19' = 'TYPE_19',
  'TYPE_20' = 'TYPE_20',
  'TYPE_21' = 'TYPE_21',
}

export enum RoadCategoryEnum {
  'I' = 'I',
  'II' = 'II',
  'III' = 'III',
  'IV' = 'IV',
  'V' = 'V',
  'A' = 'A',
  'B' = 'B',
  'C' = 'C',
  'D' = 'D',
  'G1' = 'G1',
  'G2' = 'G2',
  'G3' = 'G3',
  'E1' = 'E1',
  'E2' = 'E2',
}
export type CombinedColorTypes = ColorEnum;
export enum RoadFenceTypeEnum {
  'BARRIER' = 'BARRIER',
  'PARAPET' = 'PARAPET',
  'CABLE' = 'CABLE',
}

export enum RoadImportanceEnum {
  'MUNICIPAL' = 'MUNICIPAL',
  'REGIONAL' = 'REGIONAL',
  'FEDERAL' = 'FEDERAL',
}

export enum RoadMarkingType1a9Enum {
  'G_1_18' = 'G_1_18',
  'G_1_18__reversal' = 'G_1_18__reversal',
  'G_1_18__to_the_left' = 'G_1_18__to_the_left',
  'G_1_18__left' = 'G_1_18__left',
  'G_1_18__right' = 'G_1_18__right',
  'G_1_18__left_and_left' = 'G_1_18__left_and_left',
  'G_1_18__left_and_slightly_left' = 'G_1_18__left_and_slightly_left',
  'G_1_18__slightly_right_and_slightly_left' = 'G_1_18__slightly_right_and_slightly_left',
  'G_1_18__right_and_slightly_right' = 'G_1_18__right_and_slightly_right',
  'G_1_18__right_and_slightly_left' = 'G_1_18__right_and_slightly_left',
  'G_1_18__right_and_left' = 'G_1_18__right_and_left',
  'G_1_18__to_the_right' = 'G_1_18__to_the_right',
  'G_1_18__forward' = 'G_1_18__forward',
  'G_1_18__forward_and_left' = 'G_1_18__forward_and_left',
  'G_1_18__forward_and_slightly_left' = 'G_1_18__forward_and_slightly_left',
  'G_1_18__forward_and_slightly_right' = 'G_1_18__forward_and_slightly_right',
  'G_1_18__forward_and_right' = 'G_1_18__forward_and_right',
  'G_1_18__forward_and_turn' = 'G_1_18__forward_and_turn',
  'G_1_18__forward_right_and_left' = 'G_1_18__forward_right_and_left',
  'G_1_18__forward_with_right_bend' = 'G_1_18__forward_with_right_bend',
  'G_1_18__forward_with_left_bend' = 'G_1_18__forward_with_left_bend',
  'G_1_18__forward_with_left_bend_and_left' = 'G_1_18__forward_with_left_bend_and_left',
  'G_1_18__forward_with_left_bend_and_right' = 'G_1_18__forward_with_left_bend_and_right',
  'G_1_18__forward_with_right_bend_and_left' = 'G_1_18__forward_with_right_bend_and_left',
  'G_1_18__forward_with_right_bend_and_right' = 'G_1_18__forward_with_right_bend_and_right',
  'G_1_18__forward_and_slightly_right_and_right_and_left' = 'G_1_18__forward_and_slightly_right_and_right_and_left',
  'G_1_19__to_the_left' = 'G_1_19__to_the_left',
  'G_1_19__to_the_right' = 'G_1_19__to_the_right',
  'G_1_20' = 'G_1_20',
  'G_1_21' = 'G_1_21',
  'G_1_23_1' = 'G_1_23_1',
  'G_1_23_2' = 'G_1_23_2',
  'G_1_23_3' = 'G_1_23_3',
  'G_1_24_1__1_22' = 'G_1_24_1__1_22',
  'G_1_24_1__1_23' = 'G_1_24_1__1_23',
  'G_1_24_2__20' = 'G_1_24_2__20',
  'G_1_24_2__40' = 'G_1_24_2__40',
  'G_1_24_2__60' = 'G_1_24_2__60',
  'G_1_24_3' = 'G_1_24_3',
  'G_1_24_4' = 'G_1_24_4',
  'G_1_24_5' = 'G_1_24_5',
  'G_2_1_1' = 'G_2_1_1',
  'G_2_1_2' = 'G_2_1_2',
  'G_2_1_3' = 'G_2_1_3',
  'G_2_2' = 'G_2_2',
  'G_2_3' = 'G_2_3',
  'G_2_4' = 'G_2_4',
}

export enum RoadMarkingType419Enum {
  'G_1_25' = 'G_1_25',
  'G_1_14_1' = 'G_1_14_1',
  'G_1_16_2' = 'G_1_16_2',
  'G_1_16_3' = 'G_1_16_3',
  'G_1_17_1' = 'G_1_17_1',
  'G_1_16_1' = 'G_1_16_1',
  'G_1_14_1y' = 'G_1_14_1y',
  'G1_14_2' = 'G1_14_2',
  'G1_14_3' = 'G1_14_3',
  'G1_15' = 'G1_15',
  'G1_17_2' = 'G1_17_2',
  'G1_26' = 'G1_26',
}

export enum RoadSignSupportTypeEnum {
  'METAL_STAND' = 'METAL_STAND',
  'TRAFFIC_LIGHT_COLUMN' = 'TRAFFIC_LIGHT_COLUMN',
  'LIGHTING_SUPPORT' = 'LIGHTING_SUPPORT',
  'CATENARY_SUPPORT' = 'CATENARY_SUPPORT',
  'OUTBOARD_CONSOLE' = 'OUTBOARD_CONSOLE',
  'FACADE_OF_BUILDING' = 'FACADE_OF_BUILDING',
  'G_SUPPORT' = 'G_SUPPORT',
  'P_SUPPORT' = 'P_SUPPORT',
  'T_SUPPORT' = 'T_SUPPORT',
  'TRIP_WIRE' = 'TRIP_WIRE',
  'ARTIFICIAL_CONSTRUCTION' = 'ARTIFICIAL_CONSTRUCTION',
  'POWER_LINES_SUPPORT' = 'POWER_LINES_SUPPORT',
  'STOPPING_PAVILION' = 'STOPPING_PAVILION',
}

export enum RoadwayTypeEnum {
  'MAIN_ROADWAY' = 'MAIN_ROADWAY',
  'CROSSROADS' = 'CROSSROADS',
  'EXIT_TO_THE_ADJACENT_TERRITORY' = 'EXIT_TO_THE_ADJACENT_TERRITORY',
  'STOPPING_PLATFORM' = 'STOPPING_PLATFORM',
  'AREA_ADJACENT_TO_THE_ROADWAY' = 'AREA_ADJACENT_TO_THE_ROADWAY',
  'TRANSITION_LANE' = 'TRANSITION_LANE',
  'SLOP_AND_TURNAROUND_PLATFORM_FOR_PUBLIC_TRANSPORT' = 'SLOP_AND_TURNAROUND_PLATFORM_FOR_PUBLIC_TRANSPORT',
  'LOCAL_TRAVEL' = 'LOCAL_TRAVEL',
}

export enum RouteTypeEnum {
  'CITY' = 'CITY',
  'INTERCITY' = 'INTERCITY',
}

export enum RouteVehicleTypeEnum {
  'TRAM' = 'TRAM',
  'TROLL' = 'TROLL',
  'BUS' = 'BUS',
}

export enum SegmentDirectionEnum {
  'FORWARD' = 'FORWARD',
  'BACKWARD' = 'BACKWARD',
}

export enum SegmentSegmentTypeEnum {
  'MAIN_ROADWAY' = 'MAIN_ROADWAY',
  'LOCAL_TRAVEL' = 'LOCAL_TRAVEL',
  'RIGHT_HAND_EXIT' = 'RIGHT_HAND_EXIT',
  'LEFT_HAND_EXIT' = 'LEFT_HAND_EXIT',
  'CROSSWALK' = 'CROSSWALK',
}

export enum SegmentType0d9Enum {
  'ASPHALT_CONCRETE' = 'ASPHALT_CONCRETE',
  'CEMENT_CONCRETE' = 'CEMENT_CONCRETE',
  'GRAVEL' = 'GRAVEL',
  'CRUSHED_STONE' = 'CRUSHED_STONE',
  'PAVING_STONE' = 'PAVING_STONE',
  'PRIMING' = 'PRIMING',
}

export enum ShapeEnum {
  'P1' = 'P1',
  'P2' = 'P2',
  'T1' = 'T1',
  'T10' = 'T10',
  'T1g' = 'T1g',
  'T1l' = 'T1l',
  'T1p' = 'T1p',
  'T1pl' = 'T1pl',
  'T2L' = 'T2L',
  'T2R' = 'T2R',
  'T2RL' = 'T2RL',
  'T2S' = 'T2S',
  'T2SL' = 'T2SL',
  'T2SR' = 'T2SR',
  'T3' = 'T3',
  'T3l' = 'T3l',
  'T3p' = 'T3p',
  'T4' = 'T4',
  'T4zh' = 'T4zh',
  'T5' = 'T5',
  'T6' = 'T6',
  'T6d' = 'T6d',
  'T6dT10' = 'T6dT10',
  'T7' = 'T7',
  'T8' = 'T8',
  'T9' = 'T9',
  'ISp' = 'ISp',
  'ISl' = 'ISl',
}

export enum ShippingTypeEnum {
  'International' = 'International',
  'Interregional' = 'Interregional',
  'Local' = 'Local',
}

export enum SidewalkTypeEnum {
  'SIDEWALK' = 'SIDEWALK',
  'FOOTPATH' = 'FOOTPATH',
  'STAIRS' = 'STAIRS',
  'RAMP' = 'RAMP',
  'LANDING_SITE_AT_BUS_STOP' = 'LANDING_SITE_AT_BUS_STOP',
  'PedestrianBridge' = 'PedestrianBridge',
  'TECHNICAL_SIDEWALK' = 'TECHNICAL_SIDEWALK',
  'BICYCLE_PATH' = 'BICYCLE_PATH',
  'TRAFFIC_ISLAND' = 'TRAFFIC_ISLAND',
}

export enum SignalBarTypeEnum {
  'C1' = 'C1',
  'C2' = 'C2',
  'C3' = 'C3',
}

export enum Status05bEnum {
  'NEW' = 'NEW',
  'ON_CHECK' = 'ON_CHECK',
  'OBJECT_FOUND' = 'OBJECT_FOUND',
  'OBJECT_NOT_FOUND' = 'OBJECT_NOT_FOUND',
}

export enum Status092Enum {
  'UNKNOWN' = 'UNKNOWN',
}

export enum Status70dEnum {
  'NEW' = 'NEW',
  'FOR_REVISION' = 'FOR_REVISION',
  'REGISTERED' = 'REGISTERED',
  'IN_WORK' = 'IN_WORK',
  'DELAYED' = 'DELAYED',
  'AWAITING_PAYMENT' = 'AWAITING_PAYMENT',
  'AGREED' = 'AGREED',
}

export enum Status981Enum {
  'EXISTS' = 'EXISTS',
  'REMOVED' = 'REMOVED',
}

export enum StatusF30Enum {
  'NEW' = 'NEW',
  'CONFIRMED' = 'CONFIRMED',
  'APPOINTED' = 'APPOINTED',
  'IN_WORK' = 'IN_WORK',
  'DONE' = 'DONE',
  'CLOSED' = 'CLOSED',
  'CANCELED' = 'CANCELED',
}

export enum StreetFurnitureTypeEnum {
  'TRASHCAN' = 'TRASHCAN',
  'BENCH' = 'BENCH',
  'MONUMENT' = 'MONUMENT',
  'STELE' = 'STELE',
  'INFORMATION_BOARD' = 'INFORMATION_BOARD',
  'FLOWERPOT' = 'FLOWERPOT',
  'FOUNTAIN' = 'FOUNTAIN',
  'PAYPHONE' = 'PAYPHONE',
  'DECORATIVE_LANTERN' = 'DECORATIVE_LANTERN',
}

export enum SupportWallMaterialEnum {
  'CONCRETE_BLOCKS' = 'CONCRETE_BLOCKS',
  'WOOD' = 'WOOD',
  'GRAVEL' = 'GRAVEL',
  'BRICKWORK' = 'BRICKWORK',
  'METAL' = 'METAL',
  'MONOLITHIC_REINFORCED_CONCRETE' = 'MONOLITHIC_REINFORCED_CONCRETE',
}

export enum TaskStatusEnum {
  'NEW' = 'NEW',
  'DRAFT' = 'DRAFT',
  'RESPONSIBLE_APPOINTED' = 'RESPONSIBLE_APPOINTED',
  'IN_WORK' = 'IN_WORK',
  'DONE' = 'DONE',
  'CLOSED' = 'CLOSED',
}

export enum TaskWorkTypeEnum {
  'INSTALLATION' = 'INSTALLATION',
  'REMOVAL' = 'REMOVAL',
}

export enum TimezoneEnum {
  'UTC+2(MSK-1)' = 'UTC+2(MSK-1)',
  'UTC+3(MSK)' = 'UTC+3(MSK)',
  'UTC+4(MSK+1)' = 'UTC+4(MSK+1)',
  'UTC+5(MSK+2)' = 'UTC+5(MSK+2)',
  'UTC+6(MSK+3)' = 'UTC+6(MSK+3)',
  'UTC+7(MSK+4)' = 'UTC+7(MSK+4)',
  'UTC+8(MSK+5)' = 'UTC+8(MSK+5)',
  'UTC+9(MSK+6)' = 'UTC+9(MSK+6)',
  'UTC+10(MSK+7)' = 'UTC+10(MSK+7)',
  'UTC+11(MSK+8)' = 'UTC+11(MSK+8)',
  'UTC+12(MSK+9)' = 'UTC+12(MSK+9)',
}

export enum TlControllerStateStatusEnum {
  'CONNECT' = 'CONNECT',
  'NO_CONNECTION' = 'NO_CONNECTION',
  'UNKNOWN' = 'UNKNOWN',
}
export type CombinedWeekDayTypes = WeekDayEnum;
export enum TramWayMaterialEnum {
  'ASPHALT_CONCRETE' = 'ASPHALT_CONCRETE',
  'CEMENT_CONCRETE' = 'CEMENT_CONCRETE',
  'GRAVEL' = 'GRAVEL',
  'PRIMING' = 'PRIMING',
  'METAL' = 'METAL',
  'RUBBER_PLASTIC' = 'RUBBER_PLASTIC',
  'LAWN' = 'LAWN',
  'PAVING_WITH_TILES' = 'PAVING_WITH_TILES',
}

export enum TransportationTypeEnum {
  'INTERNATIONAL' = 'INTERNATIONAL',
  'INTERREGIONAL' = 'INTERREGIONAL',
  'LOCAL' = 'LOCAL',
}

export enum TransporterTypeEnum {
  'UL' = 'UL',
  'RF' = 'RF',
  'IP' = 'IP',
}

export enum TubeTypeEnum {
  'OVERHEAD_GAS_PIPELINE' = 'OVERHEAD_GAS_PIPELINE',
  'HEATING_NETWORKS' = 'HEATING_NETWORKS',
}

export enum TypeBusEnum {
  'INTERMEDIATE_CITY' = 'INTERMEDIATE_CITY',
  'INTERMEDIATE_SUBURBAN' = 'INTERMEDIATE_SUBURBAN',
  'ULTIMATE_CITY' = 'ULTIMATE_CITY',
  'ULTIMATE_SUBURBAN' = 'ULTIMATE_SUBURBAN',
}

export enum TypeCanopyEnum {
  'NARROW' = 'NARROW',
  'STANDART' = 'STANDART',
  'CLOSED' = 'CLOSED',
  'WARM' = 'WARM',
}

export enum TypeDocumentEnum {
  'PASSPORT' = 'PASSPORT',
  'INTERNATIONAL_PASSPORT' = 'INTERNATIONAL_PASSPORT',
}

export enum TypeOfCoverageEnum {
  'ASPHALT_CONCRETE' = 'ASPHALT_CONCRETE',
}

export enum TypeOfDocumentForVehicleEnum {
  'CONTRACT' = 'CONTRACT',
  'POWER_OF_ATTORNEY' = 'POWER_OF_ATTORNEY',
}

export enum TypeOfSignerEnum {
  'SIGNER' = 'SIGNER',
  'ALTERNATE_SIGNER' = 'ALTERNATE_SIGNER',
}

export enum TypeOfVehicleOwnerEnum {
  'ENTITY' = 'ENTITY',
  'INDIVIDUAL' = 'INDIVIDUAL',
}

export enum TypeSpeedBumpEnum {
  'PREFABRICATED_STRUCTURE' = 'PREFABRICATED_STRUCTURE',
  'MONOLITH' = 'MONOLITH',
  'MARKUP' = 'MARKUP',
}

export enum UpdateIntervalEnum {
  'FIVE_MINUTES' = 'FIVE_MINUTES',
  'FIFTEEN_MINUTES' = 'FIFTEEN_MINUTES',
  'THIRTY_MINUTES' = 'THIRTY_MINUTES',
  'ONE_HOUR' = 'ONE_HOUR',
  'WEEK' = 'WEEK',
  'MONTH' = 'MONTH',
}

export enum ValidationStatusEnum {
  'AWAITING_VALIDATION' = 'AWAITING_VALIDATION',
  'VALID_DETECTION' = 'VALID_DETECTION',
  'INVALID_DETECTION' = 'INVALID_DETECTION',
}

export enum VehicleType7daEnum {
  'TRACTOR' = 'TRACTOR',
  'TRAILER' = 'TRAILER',
  'SEMITRAILER' = 'SEMITRAILER',
}

export enum WeekDayEnum {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

export enum WidgetTypeEnum {
  'NUMERIC' = 'NUMERIC',
  'GRAPHIC' = 'GRAPHIC',
}
