import { LayerDataAccessorPrototype } from 'registrators/map/layers/description/dataAccessors/prototype/LayerDataAccessorPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { CamerasService } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class CamerasUdcDataAccessor extends LayerDataAccessorPrototype {
  constructor() {
    super({
      label: 'Камеры УДС',
      selectedType: MapObjects.camerasUdc,
    });

    CamerasUdcDataAccessor.selectedType = LayerDataAccessorPrototype.selectedType;
  }

  async getData() {
    return await CamerasService.camerasList();
  }

  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.camerasUdc);
  }

  static getSelectedType() {
    return CamerasUdcDataAccessor.selectedType;
  }

  static replaceSelectedType(type: any) {
    CamerasUdcDataAccessor.selectedType = type;
  }

  getSelectedObjectType() {
    return CamerasUdcDataAccessor.selectedType;
  }
}
