import { useDispatch } from 'react-redux';
import { basePath } from 'registrators/map/layers/layersRegistrator';

import { getFromURL } from 'api/getFromURL';
import { useSaveExel } from 'hooks/reports/useSaveExel';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';
import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import Usage from 'UI/common/components/Alert/Alert';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { UploadTMCFileButton } from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileButton';
import TextIconButton from 'UI/custom/actionsButtons/common/TextIconButton';
import { CheckINNIcon } from 'UI/custom/icons/actionIcons/CheckINNIcon';
import { CopyIcon } from 'UI/custom/icons/actionIcons/CopyIcon';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { PrintIcon } from 'UI/custom/icons/actionIcons/PrintIcon';
import { SignatureIcon } from 'UI/custom/icons/actionIcons/SignatureIcon';

export const IndividualOrdersListDirectory = {
  IOPWDirectory,
  getFromURL,
  basePath,
  Button,
  ButtonType,
  CopyIcon,
  CheckINNIcon,
  PrintIcon,
  useSaveExel,
  Usage,
  SignatureIcon,
  UploadTMCFileButton,
  DeleteIcon,
  useDispatch,
  deleteObject,
  IOPWTabMenuModel,
  TextIconButton,
};
