import React from 'react';
import Icon from '@ant-design/icons';

const SvgIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3334 9.81667L16.8667 3.70833L18.3084 4.54167L13.9501 12.0833L8.52508 8.95833L4.55008 15.8333H18.3334V17.5H1.66675V2.5H3.33341V14.6167L7.91675 6.66667L13.3334 9.81667Z"
      fill="currentColor"
    />
  </svg>
);

const ChartLineIcon = (props) => <Icon component={SvgIcon} {...props} />;

export default ChartLineIcon;
