import React, { createContext, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

import {
  IAccess,
  CargoTransportContextModel,
  IData,
  IIdBlanks,
} from './CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import CargoTransportFolder from './folder/CargoTransportFolder';
import IndividualOrdersContainer from './Pages/IndividualOrdersContainer';

const initialValues = {
  statement: null,
  setStatement: (data: IData) => {},
  count: null,
  setCount: (count: string) => {},
  idBlanks: [],
  setIdBlanks: (idBlanks: IIdBlanks[]) => {},
  mainData: null,
  setMainData: (mainData: IIdBlanks[] | null) => {},
  access: {
    add: false,
    change: false,
    delete: false,
    view: false,
  },
  setAccess: (access: IAccess) => {},
  pageMenuKey: IOPWDirectory.main,
  setPageMenuKey: (pageMenuKey: IOPWDirectory) => {},
};

export const CargoTransportContext = createContext<CargoTransportContextModel>(initialValues);

//TODO Провайдер должен быть изолирован по SOLID
const CargoTransportRouter = React.memo((props) => {
  const [pageMenuKey, setPageMenuKey] = useState<IOPWDirectory>(IOPWDirectory.main);
  const [mainData, setMainData] = useState<any[] | null>(null);
  const [statement, setStatement] = useState<IData | null>(null);
  const [count, setCount] = useState<string | null>(null);
  const [idBlanks, setIdBlanks] = useState<IIdBlanks[]>([]);
  const [access, setAccess] = useState<IAccess>({
    add: false,
    change: false,
    delete: false,
    view: false,
  });

  //TODO Провайдер должен быть изолирован по SOLID
  return (
    <CargoTransportContext.Provider
      value={{
        statement,
        setStatement,
        count,
        setCount,
        idBlanks,
        setIdBlanks,
        mainData,
        setMainData,
        access,
        setAccess,
        pageMenuKey,
        setPageMenuKey,
      }}
    >
      <Switch>
        <Route exact path={`/app/special_permits/:directory/:id(\\d+)?`} component={IndividualOrdersContainer} />
        <Route exact path={`/app/special_permits/:directory/:mode?`} component={IndividualOrdersContainer} />
        <Route
          exact
          path={`/app/special_permits/:directory/:statement/:scoreMode/:scoreId(\\d+)?`}
          component={IndividualOrdersContainer}
        />
        <Route exact path={'/app/special_permits/'} component={CargoTransportFolder} />
      </Switch>
    </CargoTransportContext.Provider>
  );
});

export default withRouter(CargoTransportRouter);
