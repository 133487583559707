import { handleActions, Reducer } from 'redux-actions';
import { agGridFiltersActions } from './agGridFilters.actions';
import { MapReducer } from '../../reducers.model';
import { AnyObject } from 'types/enums/general/general.model';

const { ADD_FILTER, CLEAR_FILTER } = agGridFiltersActions;

const initialState: AnyObject = {};

export const addFilter: Reducer<AnyObject, AnyObject> = (state, action) => {
  const { newFilter } = action.payload;
  return { ...state, ...newFilter };
};

export const clearFilter: Reducer<AnyObject, string> = (state, action) => {
  const { payload } = action;
  const { [payload]: removedField, ...elseFields } = state;
  return { ...elseFields };
};

const mapReducer: MapReducer<AnyObject> = {
  [ADD_FILTER]: addFilter,
  [CLEAR_FILTER]: clearFilter,
};

export const agGridFiltersReducer: Reducer<AnyObject, any> = handleActions(mapReducer, initialState);
