import styled from 'styled-components/macro';
import { Input, Select } from 'antd';
import { Styled as styledMixins } from '../../../../styles/styleMixins/styleMixins.styled';
import { Button } from 'UI/common/components/Button/Button';

const { TextArea } = Input;

const { CutStringWithEllipsisOnTheEnd } = styledMixins.text;

function getBaseStylesForInput(inputType: any) {
  return styled(inputType)`
    color: ${(props) => props.theme.colors.defaultDarker};
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border: none;
    height: 4.2rem;
    border-radius: 4px;
    padding-top: 1.85rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::placeholder {
      color: transparent;
    }
    &:focus,
    &:hover {
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }
  `;
}

const BaseInput = getBaseStylesForInput(Input);

const BaseTextArea = getBaseStylesForInput(TextArea);

const BasePlaceholder = styled('span')`
  color: ${(props) => props.theme.colors.paragraph};
  text-align: left;
  user-select: none;
  pointer-events: none;
  z-index: 100;
  font-size: 1.15rem;
  position: absolute;
  top: -24px;
  left: 10px;
  transition: all 0.2s ease;
  width: 90%;
  ${CutStringWithEllipsisOnTheEnd}
  &.opened {
    transform: translateY(2px) !important;
    font-size: 0.85rem;
  }
`;

const BaseErrorMessage = styled('p')`
  color: ${(props) => props.theme.colors.danger};
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  height: auto;
`;

const BaseSelectInput = styled(Select)`
  height: 60px;
  background: ${(props) => props.theme.colors.backgroundNeutral};
  border-radius: 4px;
  border: none;

  .antd-options {
    top: 20px;
  }

  .ant-select-selector {
    height: 60px;
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border-radius: 4px;
    border: none;

    &-selected-value {
      padding-top: 10px;
    }
    &__rendered {
      height: 60px;
      padding-top: 1rem;
    }
    &__placeholder {
      color: transparent;
    }
  }

  .ant-select-search {
  }

  .ant-input.ant-select-search__field {
    height: 60px;
    background: ${(props) => props.theme.colors.backgroundNeutral};
    border-radius: 4px;

    &::placeholder {
      color: transparent;
    }
  }
`;

const BaseSelectPlaceholder = styled(BasePlaceholder)`
  top: 18px;
  line-height: 1.6rem;
  width: 80%;
  text-align: left;
  ${CutStringWithEllipsisOnTheEnd}
`;

const BaseSelectInputWrap = styled.div`
  position: relative;
  width: 100%;
  .custom-dropdown {
    top: 60px !important;
  }
  ul {
    padding-top: 15px;
  }
  .ant-picker {
    width: 100%;
    height: 100%;
    max-height: 56px;
    border: none;
    background-color: ${(props) => props.theme.colors.backgroundNeutral};
    &:focus,
    &:active,
    &:hover {
      border: 2px solid ${(props) => props.theme.colors.primaryTranslucent} !important;
    }
    input {
      padding-top: 15px;
    }
  }
`;

const ContainerInnButton = styled(Button)`
  margin-top: 10px;
  margin-left: 5%;
  width: 90%;
  height: 40px;
`;

const TextIconButtonContainer = styled.div`
  div:first-child {
    padding: 0;
  }
  button {
    width: 100%;
    height: 56px;
  }
`;

export const Styled = {
  BaseInput,
  BasePlaceholder,
  getBaseStylesForInput,
  BaseErrorMessage,
  BaseSelectInput,
  BaseSelectPlaceholder,
  BaseSelectInputWrap,
  ContainerInnButton,
  BaseTextArea,
  TextIconButtonContainer,
};
