import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Input } from 'antd';

import { FormInputProps } from 'UI/common/form/inputs/model/FormInputProps.model';
import _ from 'underscore';
import { Styled } from 'UI/common/form/styled/BaseInput.styled';

const { BasePlaceholder, BaseSelectInputWrap } = Styled;

interface VPStringInputProps extends FormInputProps {
  changeFields: (key: string, value: string, object: any) => void;
}

export const VPStringInput = ({ description, object, field, changeFields }: VPStringInputProps) => {
  const { errors } = useFormContext();
  const [placeholderState, setPlaceholderState] = useState<boolean>(false);

  const className = useMemo(() => {
    if (placeholderState || object?.[field]) return 'opened';
    if (!_.isEmpty(errors) && description.required) return 'class_errors';
    return '';
  }, [description.required, errors, field, object, placeholderState]);

  return (
    <BaseSelectInputWrap className={className}>
      <Input
        value={object?.[field]}
        onChange={(e) => changeFields(field, e.target.value, object)}
        onFocus={() => setPlaceholderState(true)}
        onBlur={() => setPlaceholderState(false)}
        disabled={!object?.vehicle_type}
      />
      <BasePlaceholder className={`base_placeholder ${className}`}>{description?.label}</BasePlaceholder>
    </BaseSelectInputWrap>
  );
};
