import { saveAs } from 'file-saver';

export const useSavePdf = () => {
  // Хук для того сохранение Pdf файлов
  const savePdf = async (url) => {
    const response = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.token_access,
      },
    });
    if (!response.ok) throw new Error('Error');
    const blob = await response.blob();
    await saveAs(blob, 'statement.pdf');
  };

  return { savePdf };
};
