import React from 'react';
import Icon from '@ant-design/icons';

const RestAreasSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.94935 12.1917C2.44935 12.0084 2.96602 11.9167 3.51602 11.9167C4.63268 11.9167 5.60768 12.2667 6.44102 12.9667L3.82435 15.5834C2.95622 14.6054 2.31584 13.447 1.94935 12.1917V12.1917ZM12.966 8.16673C14.6077 9.39173 16.3827 9.69173 18.2827 9.06673C18.3077 9.24173 18.3327 9.5584 18.3327 10.0001C18.3327 10.8584 18.1244 11.8167 17.7327 12.8751C17.341 13.9251 16.8577 14.7751 16.291 15.4167L11.016 10.1584L12.966 8.16673ZM7.30768 13.8667C8.19102 15.1417 8.37435 16.5334 7.84935 18.0501C6.66602 17.7084 5.60768 17.1751 4.72435 16.4417L7.30768 13.8667ZM10.1577 11.0167L15.416 16.2917C13.6077 17.8751 11.4827 18.5417 9.06602 18.2834C9.24102 17.7334 9.33268 17.1334 9.33268 16.4834C9.33268 15.9667 9.21602 15.3584 8.98268 14.6667C8.74935 13.9751 8.47435 13.4084 8.16602 12.9667L10.1577 11.0167V11.0167ZM7.34102 12.0834C6.5816 11.4043 5.65072 10.946 4.64935 10.7584C3.63268 10.5667 2.65768 10.6251 1.71602 10.9417C1.69102 10.7584 1.66602 10.4417 1.66602 10.0001C1.66602 9.14173 1.87435 8.1834 2.26602 7.12506C2.65768 6.07506 3.14102 5.22506 3.70768 4.5834L9.25768 10.1584L7.34102 12.0834V12.0834ZM12.966 6.44173C11.8493 5.06673 11.591 3.56673 12.191 1.95006C12.7077 2.0834 13.2993 2.3334 13.966 2.71673C14.6243 3.09173 15.166 3.46673 15.5827 3.82506L12.966 6.44173ZM18.0493 7.81673C17.5493 7.99173 17.0327 8.0834 16.4827 8.0834C15.5744 8.0834 14.6993 7.81673 13.866 7.26673L16.441 4.72506C17.1743 5.64173 17.7077 6.66673 18.0493 7.81673ZM10.1577 9.2584L4.58268 3.7084C6.39102 2.12506 8.51602 1.4584 10.941 1.71673C10.7577 2.26673 10.666 2.86673 10.666 3.51673C10.666 4.11673 10.7993 4.79173 11.0743 5.55006C11.3493 6.30006 11.666 6.90006 12.0827 7.34173L10.1577 9.2584V9.2584Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const RestAreasIcon = (props) => <Icon component={RestAreasSVG} {...props} />;
