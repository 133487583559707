import { PropsWithChildren } from 'react';

import { cropPath } from 'services/net/cropPath';
import { TypeDisplayedDirectoryEnum } from 'types/enums/roles/inlineHistory.model';

import { resolveUrl } from 'registrators/administrationPage/administratonPageRegistrator';
import { PathWatcherProps } from './PathWatcher.model';

const PathWatcher = (props: PropsWithChildren<PathWatcherProps>) => {
  const { location, inlineHistory, pushInlineHistory } = props;
  const clearUrl = location.pathname.replace('/app/administration/', '');
  const urlParts = clearUrl.split('/');
  let flagProcessing = false;

  if (urlParts[0]) {
    const resolved = resolveUrl(urlParts[0], 'urlData')?.toString();
    if (
      (inlineHistory && inlineHistory[0] && inlineHistory[0].url !== `/app/administration/${urlParts[0]}/`) ||
      !inlineHistory.length
    ) {
      pushInlineHistory({
        type: TypeDisplayedDirectoryEnum.main,
        ids: null,
        urlData: resolved ?? '',
        url: `/app/administration/${urlParts[0]}/`,
        field: null,
      });
      flagProcessing = true;
    }
  }
  if (urlParts[1] && !flagProcessing) {
    const id = Number.parseInt(urlParts[1]);
    const resolved = cropPath(resolveUrl(urlParts[0], 'urlData')?.toString());
    if ((inlineHistory && inlineHistory[0] && !inlineHistory[1]) || inlineHistory[1]?.urlData !== resolved + `${id}/`) {
      pushInlineHistory({
        type: TypeDisplayedDirectoryEnum.mainObject,
        ids: id,
        urlData: resolved + `${id}/`,
        url: `/app/administration/${urlParts[0]}/${id}/`,
        field: null,
      });
    }
  }
  return null;
};

export default PathWatcher;
