/**
 * Простенькая функция возвращающая полную или частичную непрозрачность в зависимости входных данных
 * @param id - айди отрисовываемого объекта
 * @param selectedObject - данные выбранного объекта
 * @param itemType - тип отрисовываемого объекта
 * @returns {number}
 */

export const getOpacityForItems = (id, selectedObject, itemType) => {
  let opacity;
  // определяет есть ли выбранный объект и равен ли его тип, типу отрисовываемого и, если нет, возвращает полную непрозрачность
  selectedObject?.selectedObject &&
  (selectedObject?.type === itemType || selectedObject?.type === `${itemType}s`) &&
  id !== selectedObject?.selectedObject?.object?.id &&
  id !== selectedObject?.selectedObject?.id
    ? // проверяет равенство типов выбранного и отрисовываемого объектов и их айдишников - если да, то вернёт полную непрозрачность,
      // если нет, не полную - для не выбранных объектов - создавая эффект выделения на выбранном, по заветам Дениса-дизайнера. (opacity = 250)
      (opacity = 90)
    : (opacity = 250);
  return opacity;
};
