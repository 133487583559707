import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import { TlControllersService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class TrafficLightsObjects extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'traffic_light_object',
      filterLabel: 'Светофорные объекты',
      selectedObjectName: MapObjects.trafficLightObject,
    });
  }

  async uploadData() {
    return await TlControllersService.tlControllersList();
  }

  async readRecord(id) {
    return await TlControllersService.tlControllersRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.trafficLightObject);
  }
}
