import { take, cancel, cancelled, delay, call, fork, put, select } from 'redux-saga/effects';
import { mapboxTimeSelectors } from 'store/reducers/map/mapboxTime/mapboxTime';
import { mapboxTimeActions } from '../../reducers/map/actions/mapboxTimeActions';

export const START_TIME_PLAYER_SAGA = 'START_TIME_PLAYER_SAGA';
export const STOP_TIME_PLAYER_SAGA = 'STOP_TIME_PLAYER_SAGA';

const { stopPlayerSaga, setCurrentTimeOnTimePlayer, togglePlayerRunningStatus } = mapboxTimeActions;
const { getMapboxTimeCurrentAndEndTimestamps } = mapboxTimeSelectors;

function* endLoopPlayerSaga() {
  yield put(togglePlayerRunningStatus(false));
  yield put({ type: 'TIME_PLAYER_SAGA_ENDED' });
  yield put(stopPlayerSaga());
  //check current and end timestamps for toggle player ended status
  // const mapboxTimeData = yield select(getMapboxTimeCurrentAndEndTimestamps)
  // if(mapboxTimeData.currentTimeStamp === mapboxTimeData.endTimeStamp) yield put(togglePlayerEndedStatus(true))
}

function* loopPLayerSaga() {
  try {
    let mapboxTimeData = yield select(getMapboxTimeCurrentAndEndTimestamps);
    let { currentTimeStamp, endTimeStamp } = mapboxTimeData;
    while (currentTimeStamp < endTimeStamp) {
      const newMapboxTimeData = yield select(getMapboxTimeCurrentAndEndTimestamps);
      yield put(setCurrentTimeOnTimePlayer(newMapboxTimeData.currentTimeStamp + 20));
      currentTimeStamp = newMapboxTimeData.currentTimeStamp;
      yield delay(10);
    }
    //if while condition is false
    yield call(endLoopPlayerSaga);
  } finally {
    if (yield cancelled()) {
      yield call(endLoopPlayerSaga);
    }
  }
}

function* timePlayerSaga() {
  yield put({ type: 'TIME_PLAYER_SAGA_INIT' });
  yield put(togglePlayerRunningStatus(true));
  yield call(loopPLayerSaga);
}

export function* watchTimePlayerSaga() {
  while (yield take(START_TIME_PLAYER_SAGA)) {
    // yield put(togglePlayerEndedStatus())
    const timePlayerTask = yield fork(timePlayerSaga);
    yield take(STOP_TIME_PLAYER_SAGA);
    yield cancel(timePlayerTask);
  }
}
