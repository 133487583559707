import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { SpecialPermitsService } from '../../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class AxleLoadOverloads extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'axle_load_overloads',
      filterLabel: 'Перегрузки по маршруту',
      selectedObjectName: MapObjects.axleLoadOverloads,
    });
  }

  async uploadData() {
    return await SpecialPermitsService.specialPermitsIndividualStatementsAxleLoadOverloadsList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async readRecord(id) {
    return await SpecialPermitsService.specialPermitsIndividualStatementsAxleLoadOverloadsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.permitsIndividualStatementAxleOverloads);
  }
}
