import { Form } from 'antd';
import React, { FC, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { ChromePicker, ColorResult } from 'react-color';
import { Controller, useFormContext } from 'react-hook-form';

import { AnyObject } from 'types/enums/general/general.model';
import { ColorPickerInputProps } from './model/ColorPickerInput.model';

const ColorPickerInput: FC<ColorPickerInputProps> = ({ description, object, field, formProps }) => {
  const { control, errors } = useFormContext();
  const required = description.required;
  const message = `${description.label} неверно заполнено`;
  const error = _.get(errors, field);

  const objectValue = useMemo(() => _.get(object, field) ?? '', [field, object]);

  if (error && error.message?.includes(description.label)) {
    error.message = null;
  }

  const onChangeComplete = useCallback(
    (value: ColorResult) => {
      const { hex } = value;
      if (formProps && formProps.setCustomFieldsData) {
        formProps.setCustomFieldsData((prevValue: AnyObject) => ({ ...prevValue, [field]: `${hex}` }));
      }
    },
    [field, formProps]
  );

  const onChange = useCallback(([value]) => value.hex, []);

  if (description.read_only) return null;

  return (
    <Form.Item>
      <Controller
        name={field}
        defaultValue={objectValue}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        control={control}
        onChange={onChange}
        as={(props) => <ChromePicker {...props} color={props.value ?? ''} onChangeComplete={onChangeComplete} />}
      />
    </Form.Item>
  );
};

export default ColorPickerInput;
