import styled from 'styled-components';
import { Select } from 'antd';

const MainContainer = styled.div`
  width: 760px;
  height: calc(100vh - 215px);
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px ${(props) => props.theme.colors.neutralDarker};
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  form {
    padding: 0;
    width: auto;
  }
`;

const HeaderContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutralDarker};
`;

const SaveButtonsContainer = styled(HeaderContainerWrapper)`
  padding-bottom: 10px;
  button {
    width: 314px;
  }
`;

const LocalTitle = styled.h2`
  font-size: 1.25rem;
  text-align: left;
`;

const SemifinishedObjectsContainer = styled(HeaderContainerWrapper)`
  flex-wrap: wrap;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SemifObjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 314px;
  height: 150px;
  margin: 5px;
  background-color: ${(props) => props.theme.colors.backgroundNeutral};
  border-radius: 16px;
  position: relative;
  button {
    position: absolute;
    top: 15px;
    right: 50px;
  }
  button:last-child {
    top: 15px;
    right: 10px;
  }
`;

const SemifObjectContainerWithMapButton = styled.div`
  width: 314px;
  .emptyGeometry {
    border: 1px solid ${(props) => props.theme.colors.danger};
  }
`;

const ObjectTitle = styled.p`
  font-weight: bold;
  font-size: 15px;
  color: ${(props) => props.theme.colors.primary};
  padding: 5px 50px 0 10px;
`;

const DescriptionContainer = styled.div`
  padding: 0 10px;
  overflow-y: auto;
  height: 80px;
`;

const ApplicationObjectsContainer = styled(SemifObjectContainer)`
  width: 100%;
  height: 55px;
  p {
    margin: 0;
  }
`;

const LoaderContainer = styled.div`
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutralDarker};
  text-align: center;
  padding-top: 10px;
`;

export const BaseSelectInput = styled(Select)`
  width: 250px;
  margin-top: -5px;
  .ant-select-selection-selected-value {
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const SaveChangeButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  & .shitTrader {
    width: 300px;
    & p {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
    height: 40px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.defaultLighter};
  }
`;

export const Styled = {
  LocalTitle,
  MainContainer,
  SaveButtonsContainer,
  HeaderContainerWrapper,
  SemifinishedObjectsContainer,
  SemifObjectContainer,
  SemifObjectContainerWithMapButton,
  ApplicationObjectsContainer,
  ObjectTitle,
  DescriptionContainer,
  BaseSelectInput,
  LoaderContainer,
  SaveChangeButton,
};
