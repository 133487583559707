import React, { PropsWithChildren, useMemo } from 'react';
import { Styled } from '../SemifinishedObjectForm.styled';
import ApplicationForm from '../../../ApplicationForm/ApplicationForm';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { InlineDrawerContainerProps } from './model/InlineDrawerContainer.model';
import { useRouteMatch } from 'react-router-dom';
import { CargoTransportParams } from '../../../interfacesApplications/interfacesApplications';

const { SaveButtonsContainer } = Styled;

export const InlineDrawerContainer = React.forwardRef(
  (
    { initSubmitForm, handleSubmitForm, model, setCustomFieldsData }: PropsWithChildren<InlineDrawerContainerProps>,
    ref: any
  ) => {
    const { params } = useRouteMatch<CargoTransportParams>();
    const { application } = params;

    const data = useMemo(() => {
      if (application && !Number.isNaN(+application)) {
        return { application: +application };
      }
      return {};
    }, [application]);

    const estimation = useMemo(() => {
      return (
        <SaveButtonsContainer>
          <Button onClick={initSubmitForm} type={ButtonType.primary} width={'100%'}>
            Сохранить
          </Button>
          <Button onClick={() => {}} type={ButtonType.primaryReverse} width={'100%'}>
            Из задачи
          </Button>
        </SaveButtonsContainer>
      );
    }, [initSubmitForm]);

    const form = useMemo(
      () => ({
        showTime: true,
        dateTime: {},
        setDateTime: () => {},
        setCustomFieldsData,
      }),
      [setCustomFieldsData]
    );

    const InlineDrawer = useMemo(
      () => (
        <ApplicationForm
          nonTitle={true}
          handleSubmitForm={handleSubmitForm}
          model={model}
          ref={ref}
          additionalRenders={estimation}
          form={form}
          data={data}
        />
      ),
      [data, estimation, form, handleSubmitForm, model, ref]
    );
    return InlineDrawer;
  }
);
