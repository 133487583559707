import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

function oneColumnGridItemsFactory(newItems) {
  return newItems.map((el, index) => {
    return (
      <div data-grid={{ x: 0, y: el?.order ?? index, w: 2, h: 1, isBounded: true }} key={`${el?.name ?? index}`}>
        {el}
      </div>
    );
  });
}

const DraggableOneColumnGrid = ({ children, onLayoutChange, ownItems }) => {
  // если генерация не имеет зависимостей от стейта, то можно использовать oneColumnGridItemsFactory
  const gridItems = children ? oneColumnGridItemsFactory(children) : null;
  return ownItems?.length ? (
    <ResponsiveGridLayout
      cols={{ lg: 2, md: 2, sm: 2, xs: 2, xxs: 2 }}
      className="layout"
      isResizable={false}
      rowHeight={40}
      isDroppable={true}
      onLayoutChange={onLayoutChange}
    >
      {ownItems ? ownItems : gridItems}
    </ResponsiveGridLayout>
  ) : null;
};

export default DraggableOneColumnGrid;
