export enum LayerGroupName {
  uds = 'uds',
  msdidi = 'msdidi',
  dutssd = 'dutssd',
  others = 'others',
}

export enum LayerGroupLabel {
  uds = 'УДС',
  msdidi = 'МСДИДИ',
  dutssd = 'ДУТССД',
  others = 'Другие',
}

export enum LayerGroupLabelDecryption {
  uds = 'Управление состоянием дорог',
  msdidi = 'Мониторинг состояния дорог и дорожной инфраструктуры',
  dutssd = 'Диспетчерское управление транспортом служб содержания дорог',
  others = 'Другие',
}

export const LayerGroupOrder = [
  LayerGroupLabel.uds,
  LayerGroupLabel.msdidi,
  LayerGroupLabel.dutssd,
  LayerGroupLabel.others,
];
