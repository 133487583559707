import React from 'react';
import Icon from '@ant-design/icons';

const TramwayFabricSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8334 14.1166V7.08329C15.8334 4.75829 13.6584 4.24996 10.8334 4.16663L11.4584 2.91663H14.1667V1.66663H5.83341V2.91663H9.79175L9.16675 4.16663C6.55008 4.25829 4.16675 4.77496 4.16675 7.08329V14.1166C4.16675 15.325 5.15841 16.3333 6.32508 16.5916L5.00008 17.9166V18.3333H6.85841L8.52508 16.6666H11.6667L13.3334 18.3333H15.0001V17.9166L13.7501 16.6666H13.6834C15.0917 16.6666 15.8334 15.525 15.8334 14.1166ZM10.0001 15.4166C9.66856 15.4166 9.35062 15.2849 9.1162 15.0505C8.88178 14.8161 8.75008 14.4981 8.75008 14.1666C8.75008 13.8351 8.88178 13.5172 9.1162 13.2827C9.35062 13.0483 9.66856 12.9166 10.0001 12.9166C10.3316 12.9166 10.6495 13.0483 10.884 13.2827C11.1184 13.5172 11.2501 13.8351 11.2501 14.1666C11.2501 14.4981 11.1184 14.8161 10.884 15.0505C10.6495 15.2849 10.3316 15.4166 10.0001 15.4166ZM14.1667 11.6666H5.83341V7.49996H14.1667V11.6666Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const TramwayFabric = (props) => <Icon component={TramwayFabricSVG} {...props} />;
