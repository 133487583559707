import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { EngineeringConstructionsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Pipes extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'pipes',
      filterLabel: 'Трубы',
      selectedObjectName: MapObjects.pipes,
      isHidden: true,
    });
  }

  async uploadData() {
    return await EngineeringConstructionsService.engineeringConstructionsTubesList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async deleteRecord(id) {
    return await EngineeringConstructionsService.engineeringConstructionsTubesDestroy({ id });
  }

  async readRecord(id) {
    return await EngineeringConstructionsService.engineeringConstructionsTubesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.tubes);
  }
}
