import React from 'react';
import styled from 'styled-components/macro';

const StyledLocalMainWrapper = styled.div`
  #arrow {
    animation: arrow 1s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes arrow {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LogoLoaderAnimation = ({ color = 'white' }) => {
  return (
    <StyledLocalMainWrapper>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="MENU MINI 1">
          <g id="arrow">
            <path
              id="Vector_2"
              d="M27.1369 6.8998C24.9525 5.6998 22.5698 5 19.8894 5C11.6508 5 5 11.6998 5 20C5 28.3002 11.6508 35 19.89 35C28.1291 35 34.7799 28.3002 34.7799 20C34.7799 17.3 34.085 14.8002 32.8945 12.7002L31.3056 13.6002C32.3978 15.5998 32.9934 17.8002 32.9934 20.3C32.9934 27.8 26.938 33.8998 19.4927 33.8998C12.0481 33.8998 5.99275 27.8 5.99275 20.3C5.99275 12.8 12.0481 6.7002 19.4927 6.7002C21.9749 6.7002 24.2582 7.4 26.2431 8.5002L25.2503 10.4L30.5118 10.3002L27.7319 5.8002L27.1369 6.8998Z"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </StyledLocalMainWrapper>
  );
};

export default LogoLoaderAnimation;
