import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RoadConstructionsService } from '../../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class TrafficLightsProject extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'traffic_lights_project',
      filterLabel: 'Светофоры',
      selectedObjectName: MapObjects.projectLayerTrafficLights,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsTrafficLightsProjectList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsTrafficLightsProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsTrafficLightsProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadTrafficLightsProjection);
  }
}
