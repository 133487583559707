var store = undefined;
//TODO this is dangerous solution Тех-долг-FRONTEND #5633
const storeProvider = {
  init(configureStore) {
    store = configureStore;
  },
  getStore() {
    return store;
  },
};

export default storeProvider;
