import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Styled } from './PrepareScrollableInfoCard.styled.ts';
import ScrollableCard from '../../UI/common/components/ScrollableCard/ScrollableCard';
import { CloseIcon } from '../../UI/custom/icons/actionIcons/CloseIcon';
import { Button } from '../../UI/common/components/Button/Button';

const {
  LocalDescItemName,
  LocalDescItemContent,
  LocalHeaderWrapper,
  ExtendedBtnClose,
  LocalHeaderTitle,
  LocalItem,
  LocalItemWrap,
} = Styled;

export function createDescriptionItem(key, value) {
  return (
    <LocalItemWrap>
      <LocalDescItemName>{key}</LocalDescItemName>
      <LocalDescItemContent>
        {typeof value === 'object' ? (
          value?.map((m) => <LocalDescItemContent>{m}</LocalDescItemContent>)
        ) : (
          <LocalDescItemContent>{value}</LocalDescItemContent>
        )}
      </LocalDescItemContent>
    </LocalItemWrap>
  );
}
const PrepareScrollableInfoCard = ({
  handleCloseCard,
  title,
  openInNewTabBtnDesc,
  listOfDescriptions,
  handleOpenItemInDirectory,
}) => {
  let preparedDescItems = null;
  if (listOfDescriptions) {
    preparedDescItems = listOfDescriptions.map((descItem) => {
      const { key, value } = descItem;
      return createDescriptionItem(key, value);
    });
  }

  const headerStyles = useMemo(() => ({ padding: `11px 6px 8px 16px`, position: 'relative' }), []);
  const contentStyles = useMemo(() => ({ padding: '16px 10px 16px 16px' }), []);
  const footerStyles = useMemo(() => ({ padding: `10px 48px 9px 16px` }), []);

  return (
    <ScrollableCard
      headerStyles={headerStyles}
      headerContent={
        <LocalHeaderWrapper>
          <LocalHeaderTitle>{title}</LocalHeaderTitle>
          <ExtendedBtnClose onClick={handleCloseCard} icon={<CloseIcon />} type={'unboundedPrimary'} p={0} />
        </LocalHeaderWrapper>
      }
      contentStyles={contentStyles}
      children={<LocalItem>{preparedDescItems}</LocalItem>}
      footerStyles={footerStyles}
      footerContent={
        <div>
          <Button onClick={handleOpenItemInDirectory} type={'unboundedPrimary'} p={0}>
            {openInNewTabBtnDesc}
          </Button>
        </div>
      }
    />
  );
};

export default PrepareScrollableInfoCard;

PrepareScrollableInfoCard.propTypes = {
  handleCloseCard: PropTypes.func.isRequired,
  handleOpenItemInDirectory: PropTypes.func.isRequired,
  listOfDescriptions: PropTypes.arrayOf(PropTypes.object),
  openInNewTabBtnDesc: PropTypes.string,
  title: PropTypes.string,
};

PrepareScrollableInfoCard.defaultProps = {
  openInNewTabBtnDesc: 'Open in new tab',
  title: 'Detail information',
  listOfDescriptions: [],
};
