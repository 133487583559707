import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { KnsService } from '../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Cables_tram extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'Cables_tram',
      filterLabel: 'Кабели трамваев',
      selectedObjectName: MapObjects.tramCables,
    });
  }

  async uploadData() {
    return await KnsService.knsCablesTramList();
  }
  async deleteRecord(id) {
    return await KnsService.knsCablesTramDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsCablesTramRetrieve({ id });
  }
  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.knsCablesTram);
  }
}
