import styled from 'styled-components/macro';

const LocalWrapper = styled.div`
  width: 100%;
`;

const LocalTitle = styled.h4`
  color: ${(props) => props.titleColor ?? props.theme.colors.neutralDarker};
  font-weight: ${(props) => (props.isTitleBold ? 'bold' : 'normal')};
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  padding: ${(props) => props.theme.paddings.smallPadding};
  margin: 0;
  text-align: left;
  &:hover {
    cursor: default;
  }

  &:first-letter {
    text-transform: uppercase;
  }
`;

const LocalItem = styled.div`
  max-height: ${(props) => props.isLargeTooltip ?? '250px'};
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Styled = {
  LocalWrapper,
  LocalTitle,
  LocalItem,
};
