import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateModel } from 'store/reducers';
import { appActions } from 'store/reducers/global/app/app.actions';
import { ThemeProvider } from 'styled-components';

import SidebarCategory from './SidebarCategory/SidebarCategory';
import { Styled } from './SiderbarContainer.styled';
import SidebarHeader from './SidebarHeader/SidebarHeader';

const SiderbarContainer = () => {
  const dispatch = useDispatch();
  const expandedTheme = (theme: any) => ({
    ...theme,
    sidebarElemPaddingLeft: '15%',
  });
  const styledClassName = useMemo(() => `sidebar-wrapper`, []);
  const isSidebarOpened = useSelector((state: StateModel) => state.app.sidebarIsOpened);

  const toggleSidebarPosition = useCallback(() => {
    dispatch(appActions.setSidebarCollapse());
  }, [dispatch]);
  return (
    <ThemeProvider theme={expandedTheme}>
      <Styled.Wrapper as="aside" className={styledClassName}>
        <SidebarHeader sidebarIsOpened={isSidebarOpened} toggleSidebarPosition={toggleSidebarPosition} />
        <SidebarCategory sidebarIsOpened={isSidebarOpened} />
      </Styled.Wrapper>
    </ThemeProvider>
  );
};

export default SiderbarContainer;
