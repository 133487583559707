import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CamerasService, CameraWidgetCalculatedData, CameraWidgetDataResults, WidgetTypeEnum } from 'generated/api/api';
import { widgetsActions } from 'store/reducers/widgets/widgets.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AnyObject } from 'types/enums/general/general.model';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { ConnectWidgetProps } from './model/CameraWidget.model';
import CameraNumberWidget from 'components/Application/BaseModules/HomePage/components/WidgetsGrid/components/CameraWidget/components/CameraNumberWidget/CameraNumberWidget';
import CameraChartWidget from 'components/Application/BaseModules/HomePage/components/WidgetsGrid/components/CameraWidget/components/CameraChartWidget/CameraChartWidget';
import { CURRENT_APP_WS } from 'types/constants/constants';

const CameraWidget: FC<ConnectWidgetProps> = ({ widgetDescription }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<Array<CameraWidgetDataResults>>([]);

  // Хук для подключения сокетов виджета
  useEffect(() => {
    const token_access: string = localStorage.getItem('token_access')!;
    const url = `${CURRENT_APP_WS}/camera-widget-results/?token=${token_access}`;
    const ws = new WebSocket(url);

    // Отправка ид виджета, при открытии сокета
    ws.onopen = function () {
      ws.send(
        JSON.stringify({
          widget_id: widgetDescription.id,
        })
      );
    };

    // Запись данных сокета в стейт компоненты
    ws.onmessage = function (e) {
      //TODO убрать двойной парсинг после рефакторинга с бэка Тех-долг-FRONTEND #5559
      const socketData: CameraWidgetCalculatedData = JSON.parse(JSON.parse(e.data));
      setData(socketData.widget_data);
    };

    // Отписываемся от сокета при закрытии компоненты
    return () => {
      setData([]);
      ws.close();
    };
  }, [widgetDescription]);

  const onChangeWidget = useCallback(() => {
    dispatch(widgetsActions.changePanelVisible(false));
    setTimeout(() => {
      dispatch(widgetsActions.changeEditWidget(widgetDescription.id));
      dispatch(widgetsActions.changePanelVisible(true));
    });
  }, [dispatch, widgetDescription.id]);

  const onDeleteWidget = useCallback(() => {
    try {
      CamerasService.camerasWidgetDestroy({ id: widgetDescription.id });
    } catch (e) {
      const message = createAlertMessage(e as AnyObject);
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: message,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 5000);
    }
  }, [dispatch, widgetDescription.id]);

  return (
    <>
      {widgetDescription.widget_type === WidgetTypeEnum.NUMERIC && (
        <CameraNumberWidget
          widgetDescription={widgetDescription}
          onChangeWidget={onChangeWidget}
          onDeleteWidget={onDeleteWidget}
          data={data}
        />
      )}
      {widgetDescription.widget_type === WidgetTypeEnum.GRAPHIC && (
        <CameraChartWidget
          widgetDescription={widgetDescription}
          onChangeWidget={onChangeWidget}
          onDeleteWidget={onDeleteWidget}
          data={data}
        />
      )}
    </>
  );
};

export default CameraWidget;
