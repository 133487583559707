import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

import { BreadcrumbsControlled } from '../../../../../Common/BreadcrumbsControlled/BreadcrumbsControlled';
import { HeaderScipBlock } from '../../../../../Common/HeaderScipBlock/HeaderScipBlock';
import { CargoTransportParams } from '../../../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import { CargoTransportContext } from '../../../CargoTransportRouter';
import ObjectPanel from '../../../CargoTransportForm/ObjectPanel';

/**
 * @returns {JSX.Element}
 * @constructor
 */

const responseTemplatUrls = [
  IOPWDirectory.additional_inspection_comment_templates,
  IOPWDirectory.approval_comment_templates,
  IOPWDirectory.decline_comment_templates,
  IOPWDirectory.special_condition_comment_templates,
];

export const IndividualOrder = React.memo(() => {
  const history = useHistory();
  const { params } = useRouteMatch<CargoTransportParams>();

  const { directory, id } = params;

  const { statement, setPageMenuKey } = useContext(CargoTransportContext);

  const isOpenResponseTemplatesDirectory = responseTemplatUrls.some((url) => url === directory);

  // Костомная логика для справочников шаблонов
  const routeLocation = isOpenResponseTemplatesDirectory
    ? `${IOPWDirectory.special_permits}/${IOPWDirectory.federal_statements}`
    : IOPWDirectory.special_permits;

  // Костомная логика для справочников отв. лица
  const preDir = directory === IOPWDirectory.responsible_persons ? 'users' : routeLocation;
  const urlModel = id ? `/${preDir}/${directory}/${id}/` : `/${preDir}/${directory}/`;

  const labelDirectory = useMemo(() => {
    return IOPWTabMenuModel[directory];
  }, [directory]);

  const labelStatement = useMemo(() => {
    let nameCrumbs = `Создание заявления`;
    if (statement?.number_of_statement) {
      nameCrumbs = `Заявление ${statement.number_of_statement}`;
    } else if (statement?.approval_id) {
      nameCrumbs = `Идентификатор заявки ${statement.approval_id}`;
    } else if (labelDirectory === 'Ответственные лица') {
      nameCrumbs = 'Создание нового ответственного лица';
    }
    return id ? 'Создание заявления' : nameCrumbs;
  }, [id, statement, labelDirectory]);

  const initClose = useCallback(() => {
    history.push(`/app/${IOPWDirectory.special_permits}/${directory}/`);
  }, [directory, history]);

  const breadcrumbsLinks = useMemo(
    () => [
      {
        url: `/app/${preDir}/`,
        label: 'Перевозка грузов',
      },
      {
        url: `/app/${preDir}/${directory}/`,
        label: labelDirectory,
      },
      {
        url: '',
        label: labelStatement,
      },
    ],
    [directory, labelDirectory, labelStatement, preDir]
  );

  const clickBreadcrumbsControlled = useCallback(() => setPageMenuKey(IOPWDirectory.main), [setPageMenuKey]);

  return (
    <>
      <HeaderScipBlock />
      {breadcrumbsLinks && (
        <BreadcrumbsControlled links={breadcrumbsLinks} clickBreadcrumbsControlled={clickBreadcrumbsControlled} />
      )}
      <ObjectPanel urlModel={urlModel} initClose={initClose} />
    </>
  );
});
