import { LayerSchemaPrototype } from 'registrators/map/layers/description/layerSchemaPresets/prototype/LayerSchemaPrototype';
import MonitoringUDSCompositeLayer from 'layers/CompositeLayers/ekb/MonitoringUDSCompositeLayer';

export class MonitoringUDSLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: MonitoringUDSCompositeLayer,
      id: 'MonitoringUdsSchema',
    });
  }
}
