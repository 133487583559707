import { CURRENT_APP_URL } from 'types/constants/constants';
import { Layers } from 'types/enums/map/layers/Layers';

export class RegistratorPrototype {
  name: Layers;

  static readonly basePath = CURRENT_APP_URL;
  static readonly baseWSPath = `wss://api.demo-ng.oits.su`;

  constructor(name: Layers) {
    this.name = name;
  }

  getName() {
    return this.name;
  }
}
