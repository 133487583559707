import { useDispatch } from 'react-redux';
import { basePath } from 'registrators/map/layers/layersRegistrator';

import { getFromURL } from 'api/getFromURL';
import { useSaveExel } from 'hooks/reports/useSaveExel';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';
import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import Usage from 'UI/common/components/Alert/Alert';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { UploadTMCFileButton } from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileButton';
import TextIconButton from 'UI/custom/actionsButtons/common/TextIconButton';
import { CheckINNIcon } from 'UI/custom/icons/actionIcons/CheckINNIcon';
import { CopyIcon } from 'UI/custom/icons/actionIcons/CopyIcon';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { PrintIcon } from 'UI/custom/icons/actionIcons/PrintIcon';
import { SignatureIcon } from 'UI/custom/icons/actionIcons/SignatureIcon';
import CreateButton from 'UI/custom/actionsButtons/common/CreateButton';
import { RefreshButton } from 'UI/custom/actionsButtons/common/RefreshButton';

import { ChangeStatusButton } from '../CargoTransportForm/ActionButtons/ChangeStatusButton/ChangeStatusButton';
import { CreatBlankStatementButton } from '../CargoTransportForm/ActionButtons/CreatBlankStatementButton/CreatBlankStatementButton';
import { pagesNeedsDeleteButton } from '../CargoTransportForm/ActionButtons/menuLists';
import { PrintButtonStatementModal } from '../CargoTransportForm/ActionButtons/PrintButtonModal/PrintButtonStatementModal';
import { PrintFederalStatementModal } from '../CargoTransportForm/ActionButtons/PrintFederalStatement/PrintFederalStatementModal';
import { iconSize } from '../CargoTransportForm/interfacesCargoTransport/templates';
import { clickSignDocument } from '../CargoTransportForm/methods/clickSignDocument';
import { getINNVolition } from '../CargoTransportForm/methods/getINNVolition';
import { GetRequest } from '../CargoTransportForm/methods/GetRequest';
import { printDocument } from '../CargoTransportForm/methods/printDocument';
import { CompendiumWithButton } from './Pages/CompendiumWithButton';

export const IndividualOrdersListDirectory = {
  IOPWDirectory,
  getFromURL,
  basePath,
  CreateButton,
  RefreshButton,
  CreatBlankStatementButton,
  PrintButtonStatementModal,
  Button,
  ButtonType,
  CopyIcon,
  CheckINNIcon,
  PrintIcon,
  useSaveExel,
  Usage,
  printDocument,
  GetRequest,
  getINNVolition,
  clickSignDocument,
  SignatureIcon,
  UploadTMCFileButton,
  iconSize,
  DeleteIcon,
  useDispatch,
  deleteObject,
  IOPWTabMenuModel,
  pagesNeedsDeleteButton,
  TextIconButton,
  ChangeStatusButton,
  CompendiumWithButton,
  PrintFederalStatementModal,
};
