import securedAxiosInstance from 'services/axiosInstances/axiosSecured';
import { AnyObject } from 'types/enums/general/general.model';
import { removeNullFields } from '../services/data/removeNullFields';

export const postToURLSingle = {
  async sendFile(url?: string, file?: any) {
    if (url) {
      const response = await securedAxiosInstance.post(url, file, {
        headers: {
          'Accept-Language': localStorage.getItem('locale'),
          'Content-Type': 'multipart/form-data;',
        },
      });
      return response?.data ? response?.data : response;
    }
  },
  async postOrPutDataErrorResponse(url: string, data?: AnyObject, initial?: AnyObject) {
    const isNecessaryPatchRequest = ['road_signs'].some((detailUrl) => url.includes(detailUrl));
    if ((data && data?.id) || (initial && initial?.id)) {
      const id = data?.id || initial?.id;
      const path = url + id + '/';
      const response = isNecessaryPatchRequest
        ? await securedAxiosInstance.patch(path, { ...removeNullFields(data) })
        : await securedAxiosInstance.put(path, { ...removeNullFields(data) });
      return response?.data ?? response;
    } else {
      const response = await securedAxiosInstance.post(url, { ...removeNullFields(data) }, {});
      return response?.data ?? response;
    }
  },
  async postDataReports(url: string, data: AnyObject, extensions: string, blob = false) {
    // метод для новых отчетов, тип файла указан в хедерс.
    // Если требуется сохранить как файл - blob должен быть true
    const response = await securedAxiosInstance.post(url, data, {
      headers: {
        'Accept-Language': localStorage.getItem('locale'),
        Accept: extensions,
      },
      responseType: blob ? 'blob' : undefined,
    });
    return response?.data ?? response;
  },
  async patchData(url: string, data: AnyObject, initial?: AnyObject) {
    try {
      const id = data.id || initial?.id;
      const path = url + id + '/';
      const response = await securedAxiosInstance.patch(path, data);
      return response?.data ?? response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  async deleteData(url: string, id: string) {
    if (id) {
      const response = await securedAxiosInstance.delete(url + id + '/', {});
      return response.data;
    } else {
      return null;
    }
  },
};
