import React from 'react';
import ObjectPanelSider from './ObjectPanelSider';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLastHistoryRecord } from '../../../../../store/reducers/directory/directoryHistory';

const ObjectPanelSiderContainer = React.forwardRef((props, ref) => {
  const {
    breadcrumbText,
    setSelectedOption,
    schema,
    initClose,
    goToInline,
    initSubmitForm,
    lastInlineFieldName,
    directoryHistoryLastItemData,
  } = props;
  const location = useLocation();

  return (
    <ObjectPanelSider
      ref={ref}
      breadcrumbText={breadcrumbText}
      breadcrumbsUrl={location.pathname}
      setSelectedOption={setSelectedOption}
      initClose={initClose}
      goToInline={goToInline}
      initSubmitForm={initSubmitForm}
      schema={schema}
      lastInlineFieldName={lastInlineFieldName}
      directoryHistoryLastItemData={directoryHistoryLastItemData}
    />
  );
});

function mapStateToProps(state) {
  return {
    directoryHistoryLastItemData: getLastHistoryRecord(state),
  };
}

export default connect(mapStateToProps, null)(ObjectPanelSiderContainer);
