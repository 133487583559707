import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Roads extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'roads',
      filterLabel: 'Дороги',
    });
  }

  async uploadData() {
    // return await RoadsService.roadsList({pageSize:Number.MAX_SAFE_INTEGER})
    return await RoadsService.roadsList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roads);
  }
}
