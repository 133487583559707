import styled from 'styled-components/macro';
import { CloseIcon } from 'UI/custom/icons/actionIcons/CloseIcon';

const StyledInputContainer = styled.div``;

const StyledInputLabel = styled.span`
  margin-top: 4px;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.gray};
`;

const StyledInputEditablePlaceholder = styled.span`
  color: ${(props) => props.theme.colors.gray};
  font-family: 'Gilroy', sans-serif;
  font-size: ${(props) => props.theme.fontSettings.default};
  font-weight: 600;
  line-height: 140%;
  position: absolute;
  left: 16px;
  top: 17px;
  z-index: 1;
  text-align: left;
  user-select: none;
  pointer-events: none;
  transition: all 0.2s ease;
`;

const StyledInputClearButton = styled(CloseIcon)`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 16px;
  color: ${(props) => props.theme.colors.darkblue};
`;

const StyledInputWrap = styled.div<{ placeholderLength?: boolean | undefined | '' }>`
  overflow: hidden;
  position: relative;
  height: 56px;
  width: 100%;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  background: ${(props) => props.theme.colors.defaultLight};
  border: 2px solid transparent;
  display: flex;

  & .ant-input {
    width: 100%;
    height: 100%;
    background: transparent;
    font-family: 'Gilroy', sans-serif;
    border: none;
    color: ${(props) => props.theme.colors.darkBlueText};
    padding: 26px 16px 6px;
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSettings.default};
    line-height: 140%;
    box-shadow: none;

    &:hover {
      border: none !important;
    }

    &::placeholder {
      color: transparent;
    }
  }

  &.small {
    height: 40px;

    & .ant-input {
      padding: 11px 14px;

      &::placeholder {
        color: ${(props) => props.theme.colors.gray};
      }
    }

    & ${StyledInputClearButton} {
      margin-right: 11px;
    }
  }

  &.focus {
    border-color: ${(props) => props.theme.colors.darkblue};

    & .ant-input::placeholder {
      color: ${(props) => props.theme.colors.neutralDark};
    }
  }

  &.placeholder_top {
    & ${StyledInputEditablePlaceholder} {
      font-size: ${({ placeholderLength, ...props }) =>
        placeholderLength ? props.theme.fontSettings.small : props.theme.fontSettings.defaultSmall};
      color: ${(props) => props.theme.colors.darkblue};
      transform: ${({ placeholderLength }) => (placeholderLength ? 'translateY(-15px);' : 'translateY(-10px);')};
    }
  }

  &.icon {
    & ${StyledInputEditablePlaceholder} {
      left: 52px;
    }

    & .ant-input-prefix {
      left: 16px;
    }

    & .ant-input {
      padding-left: 52px;
    }
  }

  &.error {
    border-color: ${(props) => props.theme.colors.danger};

    ${StyledInputEditablePlaceholder} {
      color: ${(props) => props.theme.colors.danger};
    }

    ${StyledInputClearButton} {
      color: ${(props) => props.theme.colors.danger};
    }

    & + ${StyledInputLabel} {
      color: ${(props) => props.theme.colors.danger};
    }

    & .ant-input-prefix {
      color: ${(props) => props.theme.colors.danger};
    }
  }

  &.clear {
    & .ant-input {
      padding-right: 8px;
    }
  }

  &.disabled {
    background: ${(props) => props.theme.colors.defaultLight};
    border-color: transparent;

    ${StyledInputEditablePlaceholder} {
      color: ${(props) => props.theme.colors.neutralDark};
    }
  }

  &.auto {
    width: 100%;
    max-width: 100%;
  }
`;

export const Styled = {
  StyledInputContainer,
  StyledInputLabel,
  StyledInputWrap,
  StyledInputEditablePlaceholder,
  StyledInputClearButton,
};
