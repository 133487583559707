import { SizeMixinProps } from 'styles/styleMixins/model/SizeMixin.model';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

export const emptyArr = [];
export const iconSize = { width: '20px', height: '20px' };
export const customAlertSize = { width: '400px' } as SizeMixinProps;

export const initialModel: ModelResponse = {
  description: '',
  name: '',
  scheme: {
    initialScheme: {
      label: '',
      read_only: false,
      required: false,
      type: SchemaFieldType.initial,
    },
  },
};

export const statusChoice = [
  { value: 'NEW', display_name: 'Новая заявка' },
  { value: 'REGISTERED', display_name: 'Зарегистрирована' },
  { value: 'REGISTRATION_DENIED', display_name: 'Отказано в регистрации' },
  { value: 'IN_WORK', display_name: 'В работе' },
  { value: 'AWAITING_AGREED', display_name: 'Ожидает согласования' },
  { value: 'AWAITING_AGREED_GBDD', display_name: 'Ожидает согласования ГБДД' },
  { value: 'REFUSED_TO_ISSUE', display_name: 'Отказано в выдаче' },
  { value: 'AWAITING_PAYMENT', display_name: 'Ожидает оплаты' },
  { value: 'READY_TO_ISSUE', display_name: 'Готово к выдаче' },
  { value: 'ISSUED_BY', display_name: 'Выдано' },
  { value: 'CANCELED', display_name: 'Отменено' },
  { value: 'PAYMENT_COMPLETE', display_name: 'Выполнена оплата' },
  { value: 'PAID_UP', display_name: 'Оплачено' },
  { value: 'CHECK_APPROVAL', display_name: 'Проверить согласование' },
  { value: 'AGREED', display_name: 'Согласовано' },
];
