import { LayerCardPrototype } from 'registrators/map/layers/description/cards/prototype/LayerCardPrototype';

export class MonitoringUDSCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'Мониторинг УДС',
      linkToPreview: 'MonitoringUDS.svg',
      permissionToShowCard: 'layer_permissions.can_view_monitoring_uds_layer',
    });
  }
}
