import { baseStyles } from 'components/stylesRegistrator';
import styled from 'styled-components/macro';
import { Button } from '../../Button/Button';

interface IProps {
  isSidebarOpened: boolean;
  active?: boolean;
}

const { CutStringWithEllipsisOnTheEnd } = baseStyles.mixins.text;

const Content = styled.span<IProps>`
  display: ${(props) => (props.isSidebarOpened ? 'none' : 'block')};
  ${CutStringWithEllipsisOnTheEnd};
  color: ${(props) => props.theme.colors.defaultDark};
`;

const Icon = styled.i<IProps>`
  color: ${(props) => props.theme.colors.neutralDarker};
  display: flex;
  align-items: center;
  padding-right: ${(props) => (props.isSidebarOpened ? '0' : '1rem')};
`;

const Item = styled(Button)<IProps>`
  justify-content: ${(props) => (props.isSidebarOpened ? 'center' : 'flex-start')};
  outline: none;
  display: flex;
  z-index: 1;
  font-size: 1rem;
  position: relative;
  background: transparent;
  cursor: pointer;
  width: 100%;
  transition: all 0.3s;

  &:hover ${Icon}, &:hover ${Content} {
    color: ${(props) => props.theme.colors.primaryDark};
  }

  &.active ${Icon}, &.active ${Content} {
    color: ${(props) => props.theme.colors.primaryDark};
  }

  &:after {
    background: ${(props) => props.theme.colors.defaultLight};
    opacity: ${(props) => (props.active ? 1 : 0)};
    width: ${(props) => (props.isSidebarOpened ? '40px' : '100%')};
    height: 40px;
    content: '';
    display: block;
    position: absolute;
    top: 5%;
    left: -40%;
    bottom: 0;
    z-index: -1;
    border-radius: 8px;
    transition: all 0.2s;
  }
`;

export const Styled = { Item, Content, Icon };
