import styled from 'styled-components/macro';
import { Rnd } from 'react-rnd';

const Title = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SignsBodyDecoration = styled.div`
  padding: 2px;
  border: 1px solid ${(props) => props.theme.colors.defaultDarker};
  background-color: ${(props) => props.theme.colors.neutralDarker};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RndContainer = styled(Rnd)`
  display: block;
  transform: translate(0, 0);
`;

const RoadGroupContainer = styled.div`
  position: absolute;
  top: -60px;
  left: -60px;
  .need_danger_border:hover {
    border: 1px solid ${(props) => props.theme.colors.danger};
  }
`;

export const RoadSignGroupInfoStyles = {
  Title,
  SignsBodyDecoration,
  Wrapper,
  RoadGroupContainer,
  RndContainer,
};
