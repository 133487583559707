import React from 'react';

import { groupTypes } from '../../contentGroups';
import { LayersPrototype } from '../prototypes/LayersPrototype';
import { MapContextMenuItem } from 'types/enums/map/contextMenu/MapContextMenuItem.model';
import { initializeSingleObjectEditMode } from 'store/sagas/map/standaloneObjectEditableSaga';
import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { PlusSegmentIcon } from 'UI/custom/icons/actionIcons/PlusSegmentIcon';

const { ADD_GROUP } = groupTypes;

export class CamerasUdc extends LayersPrototype {
  constructor() {
    super(Layers.camerasUdc);
  }

  actionItems = [
    {
      name: MapContextMenuItem.camerasUdc,
      path: 'Создать камеру',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.camerasUdc, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
  ];
}
