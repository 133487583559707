import {
  GOTO_MAP_POINT,
  SET_MAP_BEARING,
  SET_MAP_TO_NORTH,
  SET_MAPBOX_STYLE,
  SET_MAPBOX_VIEW,
  SET_ZOOM_FOR_CURRENT_LOCATION,
  TOGGLE_3D,
  ZOOM_IN,
  ZOOM_OUT,
} from '../mapboxView';

export const onMapStateChanged = ({ viewState }) => {
  return {
    type: SET_MAPBOX_VIEW,
    payload: {
      viewState,
    },
  };
};

export const changeMapStateZoomForCurrentLocation = (newZoomValue) => ({
  type: SET_ZOOM_FOR_CURRENT_LOCATION,
  payload: {
    newZoomValue,
  },
});

export const onMapStyleChanged = (newStyle) => {
  return {
    type: SET_MAPBOX_STYLE,
    payload: {
      style: newStyle,
    },
  };
};

export const goToMapCoords = (latitude, longitude) => {
  return {
    type: GOTO_MAP_POINT,
    payload: {
      latitude,
      longitude,
    },
  };
};

export const zoomIn = () => {
  return {
    type: ZOOM_IN,
  };
};

export const toggle3D = () => {
  return {
    type: TOGGLE_3D,
  };
};

export const setMapToNorth = () => {
  return {
    type: SET_MAP_TO_NORTH,
  };
};

export const setMapBearing = (value) => {
  return {
    type: SET_MAP_BEARING,
    payload: {
      value,
    },
  };
};

export const zoomOut = () => {
  return {
    type: ZOOM_OUT,
  };
};
