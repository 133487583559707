import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
/*import { RoadsConstructionService } from '../../../../../../generated/api/api';*/
import { getFromURL } from '../../../../../../api/getFromURL';

export class WeatherStations extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'weather_stations',
      filterLabel: 'Погодные станции',
      selectedObjectName: MapObjects.weatherStations,
    });
  }

  /*
  async uploadData() {
    return await RoadsConstructionService.roadsConstructionWeatherStationsList();
  }

  async deleteRecord(id) {
    return await RoadsConstructionService.roadsConstructionWeatherStationsDelete({ id });
  }

  async readRecord(id, body) {
    return await RoadsConstructionService.roadsConstructionWeatherStationsRead({ id });
  }
*/

  async uploadModel() {
    return await getFromURL.getModelFromURL('/roads_construction/weather_stations/');
  }
}
