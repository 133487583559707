import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { layerSettings } from '../description/settings';
import { layerDataAccessors } from '../description/dataAccessors';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class RoadSpeedBumpsLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.roadSpeedBumps,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.RoadSpeedBumpsDataAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.SpeedBump()],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.SpeedBumpsCard(),
      customSettings: [new layerSettings.OpacitySetting(), new layerSettings.IconSizeScale()],
      layerSchema: new layerSchemas.ekbLayersSchema.SpeedBumpsLayerSchema(),
    });
  }
}
