import styled from 'styled-components/macro';
import * as CSS from 'csstype';

interface AreaContentStyleProps {
  areaBackground?: CSS.Property.Color;
}

const AreaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const AreaContent = styled.div<AreaContentStyleProps>`
  background: ${(props) => props.areaBackground ?? props.theme.colors.defaultLighter};
  flex: 1 1 auto;
  border-radius: 6px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Styled = { AreaWrapper, AreaContent };
