import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RoadConstructionsService } from '../../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class StreetLampProject extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'street_lamp_project',
      filterLabel: 'Уличное освещение',
      selectedObjectName: MapObjects.projectLayerStreetLamp,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsStreetLampProjectList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsStreetLampProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsStreetLampProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.streetLamps);
  }
}
