import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import { KnsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class SupportPillars extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'support_pillars',
      filterLabel: 'Опорные столбы',
      selectedObjectName: MapObjects.supportPillars,
      isHidden: true,
    });
  }

  async uploadData() {
    return await KnsService.knsSupportPillarsList();
  }

  async deleteRecord(id) {
    return await KnsService.knsSupportPillarsDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsSupportPillarsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadsSegmentsSupports);
  }
}
