import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { layerDataAccessors } from '../description/dataAccessors';
import { layerSettings } from '../description/settings';
import TrafficLightsObjectsSelection from '../../../../layers/SelectedObjectsRepresentations/TrafficLightsObjectsSelection/TrafficLightsObjectsSelection';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class TrafficManagementProjectsLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.trafficManagementProjects,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.TrafficManagementProjectsDataAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.TrafficManagementProjects()],
      layerGroup: new projectLayerGroups.UdsLayerGroup(),
      card: new ekbLayerCards.TrafficManagementProjectsCard(),
      customSettings: [new layerSettings.OpacitySetting(), new layerSettings.IconSizeScale()],
      layerSchema: new layerSchemas.ekbLayersSchema.TrafficManagementProjectsLayerSchema(),
      selectedObjectPrototype: TrafficLightsObjectsSelection,
    });
  }
}
