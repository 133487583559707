import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentMapMode, revertToDefaultMapMode } from 'store/reducers/map/actions/mapModeActions';

import { MapMode } from 'types/enums/map/MapMode.model';

import MapStandaloneEditMode from './MapStandaloneEditMode';

export const MapStandaloneEditModeContainer = () => {
  const dispatch = useDispatch();

  const setCurrentMapModeHandle = useCallback((modeType: MapMode) => dispatch(setCurrentMapMode(modeType)), [dispatch]);

  const revertToDefaultMapModeHandle = useCallback(() => dispatch(revertToDefaultMapMode()), [dispatch]);

  useEffect(() => {
    setCurrentMapModeHandle(MapMode.standaloneEdit);
    return function cleanup() {
      revertToDefaultMapModeHandle();
    };
  }, [revertToDefaultMapModeHandle, setCurrentMapModeHandle]);

  return <MapStandaloneEditMode />;
};
