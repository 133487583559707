import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

import { BreadcrumbsControlled } from '../../../../../Common/BreadcrumbsControlled/BreadcrumbsControlled';
import { HeaderScipBlock } from '../../../../../Common/HeaderScipBlock/HeaderScipBlock';
import {
  CargoTransportContextModel,
  CargoTransportParams,
} from '../../../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import { CargoTransportContext } from '../../../CargoTransportRouter';
import ObjectPanel from '../../../CargoTransportForm/ObjectPanel';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const FormsAccount = React.memo(() => {
  const history = useHistory();
  const { params } = useRouteMatch<CargoTransportParams>();
  const { setPageMenuKey } = useContext<CargoTransportContextModel>(CargoTransportContext);
  const { directory, scoreMode } = params;

  const labelDirectory = useMemo(() => {
    return IOPWTabMenuModel[directory];
  }, [directory]);

  const labelCount = useMemo(() => (scoreMode ? IOPWTabMenuModel[scoreMode] : 'Empty'), [scoreMode]);

  let newDirectory = `${directory}/`;
  if (directory === IOPWDirectory.forms_account) {
    newDirectory = '';
  }

  const urlModel = `/${IOPWDirectory.special_permits}/${newDirectory}${scoreMode}/`;

  const breadcrumbsLinks = useMemo(
    () => [
      {
        url: `/app/${IOPWDirectory.special_permits}/`,
        label: 'Перевозка грузов',
      },
      {
        url: `/app/${IOPWDirectory.special_permits}/${directory}/`,
        label: labelDirectory,
      },
      {
        url: '',
        label: labelCount,
      },
    ],
    [directory, labelCount, labelDirectory]
  );

  const initClose = useCallback(() => {
    history.push(`/app/${IOPWDirectory.special_permits}/${directory}/`);
  }, [directory, history]);

  const clickBreadcrumbsControlled = useCallback(() => setPageMenuKey(IOPWDirectory.main), [setPageMenuKey]);

  return (
    <>
      <HeaderScipBlock />
      {breadcrumbsLinks && (
        <BreadcrumbsControlled links={breadcrumbsLinks} clickBreadcrumbsControlled={clickBreadcrumbsControlled} />
      )}
      <ObjectPanel urlModel={urlModel} initClose={initClose} />
    </>
  );
});
