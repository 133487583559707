import React from 'react';

import { baseStyles } from 'components/stylesRegistrator';

import StandaloneEditModeFormPanelContainer from './panels/StandaloneEditModeFormPanel/StandaloneEditModeFormPanelContainer';
import TopActionBarContainer from './panels/TopActionBar/TopActionBarContainer';
import { MapToolBarContainer } from '../MapToolBar/MapToolBarContainer';
const { BaseMapMainModuleWrapper, BaseMapContentLayoutArea, BaseMapContentLayoutAreaInner } = baseStyles.modules.map;

const MapStandaloneEditMode = () => {
  return (
    <>
      <BaseMapMainModuleWrapper>
        <BaseMapContentLayoutArea>
          <BaseMapContentLayoutAreaInner>
            <StandaloneEditModeFormPanelContainer />
          </BaseMapContentLayoutAreaInner>
          <TopActionBarContainer />
          <MapToolBarContainer showTimePlayer={false} />
        </BaseMapContentLayoutArea>
      </BaseMapMainModuleWrapper>
    </>
  );
};

export default MapStandaloneEditMode;
