import { Moment } from 'moment';

export interface CamerasList {
  mask: any;
  address: string;
  has_detection_masks: boolean;
  id: number;
  is_working: boolean;
  name: string;
  point: string;
  url: string;
}

export enum TypesMasks {
  ObjectCounterPedestrians = 'Счетчик пешеходов',
  ObjectCounterCar = 'Счетчик машин',
}

export interface TrafficIntensityProps {
  handleSelectCamera: () => void;
  handleSelectMask: () => void;
  setCamera: (value: Array<number>) => void;
  setTypeMask: (value: Array<string>) => void;
  setMask: (value: Array<string>) => void;
  camerasData: CamerasList[];
  masksData: Array<JSX.Element>;
  typeMasks: Array<JSX.Element>;
  selectDate: (date: Moment | null, dateString: string) => void;
  setFormatReport: (formatReport: string) => void;
  sendData: () => void;
  formatReport: string;
}
