import React from 'react';
import styled from 'styled-components';
import { Styled } from './RoadAccidentSections.style';
import ReportSider from '../../parts/Sider/ReportSider';
import TimePeriodicityData from '../../parts/PeriodicityData/TimePeriodicityData';

import ExtensionData from '../../parts/ExtensionData/ExtensionData';
import ScrollableBoxArea from '../../../../../../../../UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';

const StyledDrawer = styled.div``;
const { PanelLayout, LocalInnerWrapper, LocalTitle, LocalReportData, LocalDataTS, LocalExtensionData } = Styled;

const RoadAccidentSections = ({ sendData }) => {
  return (
    <StyledDrawer placement={'right'} closable={false} visible={false} getContainer={false} bodyStyle={{ padding: 0 }}>
      <PanelLayout>
        <ReportSider sendData={sendData} />
        <ScrollableBoxArea>
          <LocalTitle>Отчет "Аварийно-опасные участки дорог"</LocalTitle>
          <LocalInnerWrapper>
            <LocalDataTS>{/*<DataTSContainer />*/}</LocalDataTS>
            <LocalReportData>
              <TimePeriodicityData />
              {/*<EmailDataContainer />*/}
              {/*<SelectGeneralData />*/}
            </LocalReportData>
            <LocalExtensionData>
              <ExtensionData />
            </LocalExtensionData>
          </LocalInnerWrapper>
        </ScrollableBoxArea>
      </PanelLayout>
    </StyledDrawer>
  );
};

export default RoadAccidentSections;
