import React, { FC } from 'react';

import { ChartWidgetProps } from './model/ChartWidget.model';
import WidgetWrap from '../WidgetWrap/WidgetWrap';
import LineChart from './components/LineChart/LineChart';
import PieChart from './components/PieChart/PieChart';

const ChartWidget: FC<ChartWidgetProps> = ({
  name = 'Графический виджет',
  type,
  onChangeWidget,
  chartSettings,
  onDeleteWidget,
  data,
  xKey,
}) => {
  return (
    <WidgetWrap name={name} onChangeWidget={onChangeWidget} onDeleteWidget={onDeleteWidget}>
      {type === 'PIE' && <PieChart chartSettings={chartSettings} data={data} />}
      {type === 'LINE_GRAPH' && xKey && <LineChart chartSettings={chartSettings} data={data} xKey={xKey} />}
      {type === 'BAR'}
      {type === 'FILLED_LINE_GRAPH'}
    </WidgetWrap>
  );
};

export default ChartWidget;
