import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { AnyObject } from 'types/enums/general/general.model';
import { appActions } from 'store/reducers/global/app/app.actions';
import { CamerasService } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import WidgetsEditPanel from 'components/Application/BaseModules/HomePage/components/WidgetsEditPanel/WidgetsEditPanel';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export const WidgetEditPanelContainer: FC = () => {
  const dispatch = useDispatch();

  const [model, setModel] = useState<ModelResponse | null>(null);
  const [data, setData] = useState<AnyObject>({});

  const editWidget = useSelector((state: StateModel) => state.widgets.editWidget);

  // Загрузка модели
  useEffect(() => {
    (async () => {
      dispatch(appActions.startAppFetching());
      try {
        if (editWidget) {
          const data = await CamerasService.camerasWidgetRetrieve({ id: editWidget });
          setData(data);
        }
        const model = await getFromURL.getModelFromURL(RequestPath.camerasUdcWidgets);
        setModel(model);
      } catch (e) {
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: createAlertMessage(e as AnyObject),
          })
        );
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
      }
      dispatch(appActions.endAppFetching());
    })();
  }, [dispatch, editWidget]);

  return <>{model && <WidgetsEditPanel model={model} data={data} />}</>;
};

export default WidgetEditPanelContainer;
