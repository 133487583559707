import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PasswordRemember from './Auth/PasswordRemember/PasswordRemember';
import Login from './Auth/Login/Login';
import { compose } from 'redux';
import { Redirect, useRouteMatch, withRouter } from 'react-router-dom';
import PasswordReset from './Auth/PasswordReset/PasswordReset';
import { Styled } from './GuestPage.styled';
import WelcomePage from './WelcomePage/WelcomePage';
import { theme } from '../../styles/themes/rootTheme';
import { StateModel } from 'store/reducers';
import { routeParams } from './GuestPage.model';

const {
  LocalMainWrapper,
  LocalBackgroundLeftSide,
  LocalLogoProject,
  LocalGreetingSpriteInner,
  LocalBackgroundRightSide,
  LocalBackgroundCenter,
  LocalGreetingSpriteWrapper,
} = Styled;
const GuestPage = () => {
  const user = useSelector((state: StateModel) => state.auth.user);

  const alertStatus = useSelector((state: StateModel) => {
    return {
      alertData: state?.notifications?.alert,
      promptData: state?.notifications?.notification,
    };
  });

  const dispatch = useDispatch();
  const sessionUser = localStorage.user;
  const matchHomePage: routeParams | null = useRouteMatch('/guest');
  const matchLoginPage: routeParams | null = useRouteMatch('/guest/login');
  const matchLoginRemember: routeParams | null = useRouteMatch('/guest/login/remember');
  const matchPasswordRecovery: routeParams | null = useRouteMatch('/guest/password_recovery/:uid/:token');

  const checkUser = () => {
    if (sessionUser && !user) {
      dispatch({
        type: 'LOGIN_USER',
        payload: sessionUser,
      });
    }
  };
  checkUser();

  const redirect = useMemo(
    () => matchHomePage && matchHomePage.isExact && <Redirect to={'/guest/login'} />,
    [matchHomePage]
  );

  const login = useMemo(
    () => matchLoginPage && matchLoginPage.isExact && <Login alertMsg={alertStatus.promptData} />,
    [alertStatus.promptData, matchLoginPage]
  );

  const passwordRemember = useMemo(
    () => matchLoginRemember && matchLoginRemember.isExact && <PasswordRemember />,
    [matchLoginRemember]
  );

  const passwordReset = useMemo(
    () =>
      matchPasswordRecovery &&
      matchPasswordRecovery.isExact && (
        <PasswordReset uid={matchPasswordRecovery?.params?.uid} token={matchPasswordRecovery?.params?.token} />
      ),
    [matchPasswordRecovery]
  );

  return (
    <LocalMainWrapper imgSrc={theme.auth.bgImgSrcLink}>
      <LocalBackgroundLeftSide lftSrc={theme.auth.shapeLeftSrcLink}>
        <LocalLogoProject>
          <theme.logo.auth />
        </LocalLogoProject>
      </LocalBackgroundLeftSide>
      <LocalBackgroundCenter>
        {redirect}
        {login}
        {passwordRemember}
        {passwordReset}
        <LocalGreetingSpriteWrapper>
          <LocalGreetingSpriteInner>
            <WelcomePage />
          </LocalGreetingSpriteInner>
        </LocalGreetingSpriteWrapper>
      </LocalBackgroundCenter>
      <LocalBackgroundRightSide />
    </LocalMainWrapper>
  );
};

export default compose(withRouter)(GuestPage);
