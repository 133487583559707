import React from 'react';
import Icon from '@ant-design/icons';

const TunnelIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3132 12.0832C15.1721 12.0832 15.05 12.0316 14.9469 11.9285C14.8439 11.8255 14.7923 11.7034 14.7923 11.5623V7.9165C14.7923 6.97249 14.559 6.10173 14.0924 5.3042C13.6259 4.50667 12.9938 3.87462 12.1963 3.40804C11.3988 2.94146 10.528 2.70817 9.58398 2.70817C8.63997 2.70817 7.76921 2.94146 6.97168 3.40804C6.17415 3.87462 5.5421 4.50667 5.07552 5.3042C4.60894 6.10173 4.37565 6.97249 4.37565 7.9165V11.5623C4.37565 11.7034 4.32411 11.8255 4.22103 11.9285C4.11795 12.0316 3.99588 12.0832 3.85482 12.0832C3.71376 12.0832 3.59169 12.0316 3.48861 11.9285C3.38553 11.8255 3.33398 11.7034 3.33398 11.5623V7.9165C3.33398 6.78803 3.61339 5.74365 4.1722 4.78337C4.73101 3.82308 5.49056 3.06353 6.45085 2.50472C7.41113 1.94591 8.45551 1.6665 9.58398 1.6665C10.7125 1.6665 11.7568 1.94591 12.7171 2.50472C13.6774 3.06353 14.437 3.82308 14.9958 4.78337C15.5546 5.74365 15.834 6.78803 15.834 7.9165V11.5623C15.834 11.7034 15.7824 11.8255 15.6794 11.9285C15.5763 12.0316 15.4542 12.0832 15.3132 12.0832ZM5.93815 12.0832C5.79709 12.0832 5.67502 12.0316 5.57194 11.9285C5.46886 11.8255 5.41732 11.7034 5.41732 11.5623V7.9165C5.41732 6.76633 5.82422 5.78434 6.63802 4.97054C7.45182 4.15674 8.43381 3.74984 9.58398 3.74984C10.7342 3.74984 11.7161 4.15674 12.5299 4.97054C13.3437 5.78434 13.7507 6.76633 13.7507 7.9165V11.5623C13.7507 11.7034 13.6991 11.8255 13.596 11.9285C13.4929 12.0316 13.3709 12.0832 13.2298 12.0832H12.709L14.2715 18.3332H4.89648L6.45898 12.0832H5.93815ZM10.2676 14.1665H8.90039L8.78646 15.2082H10.3815L10.2676 14.1665ZM10.6257 17.2915L10.4954 16.2498H8.67253L8.54232 17.2915H10.6257ZM9.01432 13.1248H10.1536L10.1048 12.0832H9.06315L9.01432 13.1248Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const TunnelIcon = (props) => <Icon component={TunnelIconSVG} {...props} />;
