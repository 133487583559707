import * as axios from 'axios';

export const axiosSmev = axios.create({
  baseURL: 'http://localhost:23494/api/signSmev3Sp',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    timeout: Number.MAX_SAFE_INTEGER,
  },
});
