import React, { PropsWithChildren } from 'react';
import { Styled } from './style/ButtonGroup.styled';
import { ButtonGroupProps } from './model/ButtonGroup.model';

const { ButtonGroupWrapper } = Styled;

/** Компонент отрисовки группы элементов по горизонтали или вертикали */
const ButtonGroup = ({ children, vertical }: PropsWithChildren<ButtonGroupProps>) => {
  return <ButtonGroupWrapper vertical={vertical}>{children}</ButtonGroupWrapper>;
};

export default ButtonGroup;
