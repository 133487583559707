export const monitoringUdsMapping = {
  ROAD_MARKING: { x: 0, y: 0, width: 128, height: 128 },
  ROAD_MARKING_ACTIVE: { x: 0, y: 129, width: 128, height: 128 },
  ROAD_SIGN: { x: 129, y: 0, width: 128, height: 128 },
  ROAD_SIGN_ACTIVE: { x: 129, y: 129, width: 128, height: 128 },
  ROAD_SURFACE_ACTIVE: { x: 0, y: 258, width: 128, height: 128 },
  ROAD_SURFACE: { x: 129, y: 258, width: 128, height: 128 },
  SPEED_BUMP: { x: 258, y: 0, width: 128, height: 128 },
  TRAFFIC_LIGHTS: { x: 258, y: 129, width: 128, height: 128 },
  TRAFFIC_LIGHTS_ACTIVE: { x: 258, y: 258, width: 128, height: 128 },
  SPEED_BUMP_ACTIVE: { x: 0, y: 387, width: 128, height: 128 },
};
