import { LayerCardPrototype } from '../prototype/LayerCardPrototype';

export class TrolleybusCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'СКС троллейбусов',
      linkToPreview: 'Passajirskiy.svg',
      permissionToShowCard: 'layer_permissions.can_view_scs_trolleybuses_layer',
    });
  }
}
