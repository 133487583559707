import React from 'react';
import Icon from '@ant-design/icons';

const ChatIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33317 3.33341H16.6665V13.3334H4.30817L3.33317 14.3084V3.33341ZM3.33317 1.66675C2.4165 1.66675 1.67484 2.41675 1.67484 3.33341L1.6665 18.3334L4.99984 15.0001H16.6665C17.5832 15.0001 18.3332 14.2501 18.3332 13.3334V3.33341C18.3332 2.41675 17.5832 1.66675 16.6665 1.66675H3.33317ZM4.99984 10.0001H11.6665V11.6667H4.99984V10.0001ZM4.99984 7.50008H14.9998V9.16675H4.99984V7.50008ZM4.99984 5.00008H14.9998V6.66675H4.99984V5.00008Z"
      fill="currentColor"
    />
  </svg>
);

export const ChatIcon = (props) => <Icon component={ChatIconSVG} {...props} />;
