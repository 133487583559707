import { Dispatch, SetStateAction, useCallback } from 'react';
import { Select } from 'antd';
import {
  FederalStatementSpecialConditionCommentTemplate,
  FederalStatementApprovedCommentTemplate,
  FederalStatementDeclinedCommentTemplate,
  FederalStatementAdditionalInspectionCommentTemplate,
} from 'generated/api/api';
import { Styled } from './MainDataFederalStatementCustomView.styled';

const { Option } = Select;

const { BaseSelectInput } = Styled;

interface TemplateSelectInputProps {
  responseTemplates?:
    | FederalStatementSpecialConditionCommentTemplate[]
    | FederalStatementApprovedCommentTemplate[]
    | FederalStatementDeclinedCommentTemplate[]
    | FederalStatementAdditionalInspectionCommentTemplate[];
  placeholder: string;
  setComment: Dispatch<SetStateAction<string | undefined>>;
}

export const TemplateSelectInput = ({ responseTemplates, placeholder, setComment }: TemplateSelectInputProps) => {
  const templatOptions = responseTemplates?.map((el) => (
    <Option key={`responseTemplates_${el?.id}`} value={el?.comment}>
      {el?.name}
    </Option>
  ));

  const changeTemplate = useCallback(
    (value) => {
      setComment(value);
    },
    [setComment]
  );

  return (
    <BaseSelectInput onChange={changeTemplate} placeholder={placeholder}>
      {templatOptions}
    </BaseSelectInput>
  );
};
