import React from 'react';
import { useMemo } from 'react';

import { CheckIcon } from 'UI/custom/icons/actionIcons/CheckIcon';
import { CrossIcon } from 'UI/custom/icons/actionIcons/CrossIcon';

import { BooleanCellRendererProps } from './model/BooleanCellRenderer.model';
import { ContainerBooleanIcon } from './style/ContainerBooleanIcon.styles';

const BooleanCellRenderer = (props: BooleanCellRendererProps) => {
  const drawElement = useMemo(() => {
    return <ContainerBooleanIcon>{props.value ? <CheckIcon /> : <CrossIcon />}</ContainerBooleanIcon>;
  }, [props.value]);
  return <>{drawElement}</>;
};

export default BooleanCellRenderer;
