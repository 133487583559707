import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { CommonSelectedItemContextMenuPrototype } from '../../prototypes/CommonSelectedItemContextMenuPrototype';
import { MapContextMenuItem } from '../../../../../../types/enums/map/contextMenu/MapContextMenuItem.model';
import React from 'react';
import { groupTypes } from '../../../contentGroups';
import { initializeSingleObjectEditMode } from '../../../../../../store/sagas/map/standaloneObjectEditableSaga';
import { PipelinesIcon } from '../../../../../../UI/custom/icons/contextMenuIcons/PipelinesIcon';
import { TunnelIcon } from '../../../../../../UI/custom/icons/contextMenuIcons/TunnelIcon';
import { FerryCrossingsIcon } from '../../../../../../UI/custom/icons/contextMenuIcons/FerryCrossingsIcon';
const { ADD_GROUP } = groupTypes;

export class RoadTechnicalCharacteristicsDescription extends CommonSelectedItemContextMenuPrototype {
  constructor(selectedObject, layerName, initialCoordinate, selectedLayerTab) {
    super(selectedObject, MapObjects.roadSegment, layerName, selectedLayerTab);

    this.actionItems =
      selectedLayerTab === 'axle_loads'
        ? [
            {
              name: MapContextMenuItem.roadTechnicalCharacteristicsAxeLoads,
              path: 'Нагрузки на ось',
              group: ADD_GROUP,
              dispatchedAction: () =>
                initializeSingleObjectEditMode(
                  selectedObject,
                  MapObjects.roadTechnicalCharacteristicsAxeLoads,
                  layerName,
                  initialCoordinate,
                  ADD_GROUP
                ),
              icon: <PipelinesIcon />,
            },
          ]
        : selectedLayerTab === 'coating_types'
        ? [
            {
              name: MapContextMenuItem.roadTechnicalCharacteristicsCoatingTypes,
              path: 'Тип покрытия',
              group: ADD_GROUP,
              dispatchedAction: () =>
                initializeSingleObjectEditMode(
                  selectedObject,
                  MapObjects.roadTechnicalCharacteristicsCoatingTypes,
                  layerName,
                  initialCoordinate,
                  ADD_GROUP
                ),
              icon: <TunnelIcon />,
            },
          ]
        : selectedLayerTab === 'lanes_amounts'
        ? [
            {
              name: MapContextMenuItem.roadTechnicalCharacteristicsLanesAmounts,
              path: 'Количество полос',
              group: ADD_GROUP,
              dispatchedAction: () =>
                initializeSingleObjectEditMode(
                  selectedObject,
                  MapObjects.roadTechnicalCharacteristicsLanesAmounts,
                  layerName,
                  initialCoordinate,
                  ADD_GROUP
                ),
              icon: <FerryCrossingsIcon />,
            },
          ]
        : [];
  }
}
