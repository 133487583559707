import React, { useCallback } from 'react';
import { LayersMenu } from './LayersMenu';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMainPanelCollapse } from 'store/reducers/map/actions/mapPanelsActions';
import { StateModel } from 'store/reducers';

export const LayersMenuContainer = () => {
  const dispatch = useDispatch();

  const showLayersSettings: boolean = useSelector((state: StateModel) => state.mapPanels.layerSettingVisible);
  const mainPanelCollapseStatus: boolean = useSelector((state: StateModel) => state.mapPanels.mainPanelCollapsed);

  const handleToggleMainPanelCollapse = useCallback(() => dispatch(toggleMainPanelCollapse()), [dispatch]);

  return (
    <LayersMenu
      showLayersSettings={showLayersSettings}
      mainPanelCollapseStatus={mainPanelCollapseStatus}
      toggleMainPanelCollapse={handleToggleMainPanelCollapse}
    />
  );
};
