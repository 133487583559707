import { IconSizeScale } from './IconSizeScale';
import { OpacitySetting } from './OpacitySetting';
import { ProjectionOnMapSetting } from './ProjectionOnMapSetting';
import { Radius } from './Radius';
import { ElevationScale } from './ElevationScale';
import { LineWidthSetting } from './LineWidthSetting';
import { ColorSetting } from './ColorSetting';
import { TransportAnalysisBrushLayer } from './TransportAnalysisBrushLayer';
import { BorderLineWidthScale } from './BorderLineWidthScale';
import { ObjectNameLayers } from './ObjectNameLayers';
import { ShowVehicles } from './ShowVehicles';
import { SwitchCluster } from './SwitchCluster';

export const layerSettings = {
  IconSizeScale,
  OpacitySetting,
  Radius,
  ElevationScale,
  LineWidthSetting,
  TransportAnalysisBrushLayer,
  ColorSetting,
  ProjectionOnMapSetting,
  BorderLineWidthScale,
  ObjectNameLayers,
  ShowVehicles,
  SwitchCluster,
};
