import { Styled } from '../WindowedDataTable.styled';
import React from 'react';
import PropTypes from 'prop-types';

const { ExtendedHeaderWrap, LocalHeaderGroup, LocalHeaderTitle } = Styled.header;

const WindowedDataTableHeader = ({
  style: userStyles = {},
  titleContent = null,
  children = null,
  actionsContent,
  ...otherProps
}) => {
  return (
    <ExtendedHeaderWrap {...otherProps} style={userStyles}>
      <LocalHeaderTitle>{titleContent}</LocalHeaderTitle>
      {children}
      <LocalHeaderGroup>{actionsContent}</LocalHeaderGroup>
    </ExtendedHeaderWrap>
  );
};
export default WindowedDataTableHeader;

WindowedDataTableHeader.propTypes = {
  actionsContent: PropTypes.node,
  children: PropTypes.any,
  titleContent: PropTypes.node,
};
