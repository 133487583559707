import React, { useMemo } from 'react';
import CamerasUdcCard from '../CamerasUdcCard';
import SettingsCameraUdc from 'UI/custom/components/SettingsCameraUdc/SettingsCameraUdc';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';
import { theme } from 'styles/themes/rootTheme';
import { AnyObject } from 'types/enums/general/general.model';
import { Camera } from 'generated/api/api';

const { OpacityColor } = styleMixins.color;

interface CamerasUdcSelectionProps {
  handleCloseMapSelectedObjectInfo: () => void;
  selectedMapItem: AnyObject;
  data: Camera;
}

const CamerasUdcSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }: CamerasUdcSelectionProps) => {
  const title = useMemo(() => data?.address ?? 'Не указанно', [data?.address]);

  const settingsCameraComponent = useMemo(
    () => <SettingsCameraUdc cameraID={data.id} title={title} />,
    [data.id, title]
  );

  const Descriptions: IDescriptions[] = [];

  Descriptions.push({
    key: 'Местоположение',
    value: title,
  });

  Descriptions.push({
    key: 'Статус камеры',
    value: data?.is_working ? 'Работает' : 'Не работает',
    options: {
      styles: {
        backgroundColor: data?.is_working
          ? OpacityColor(theme.colors.success, 0.08)
          : OpacityColor(theme.colors.danger, 0.08),
        color: data?.is_working ? theme.colors.success : theme.colors.danger,
      },
    },
  });

  Descriptions.push({
    key: 'Объекты',
    value: data?.has_detection_masks ? 'Есть' : 'Нет',
    options: {
      styles: {
        backgroundColor: data?.has_detection_masks
          ? OpacityColor(theme.colors.success, 0.08)
          : OpacityColor(theme.colors.darkblue, 0.08),
        color: data?.has_detection_masks ? theme.colors.success : theme.colors.darkblue,
      },
    },
  });

  return (
    <CamerasUdcCard
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      listOfDescriptions={Descriptions}
      settingsCameraComponent={settingsCameraComponent}
      title={title}
    />
  );
};

export default CamerasUdcSelection;
