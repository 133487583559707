export const REGISTER_SOCKET_STATUS = 'REGISTER_SOCKET_STATUS';
export const CHECK_SOCKET_STATUS = 'CHECK_SOCKET_STATUS';
export const REFRESH_SOCKET_STATUS = 'REFRESH_SOCKET_STATUS';
export const CLOSE_SOCKET_STATUS = 'CLOSE_SOCKET_STATUS';
export const CLEAN_UP_SOCKETS_STATUSES = 'CLEAN_UP_SOCKETS_STATUSES';

const initialState = {};

export const closeSocketConnection = (initiatorType, initiatorLayerName, initiatorName) => ({
  type: CLOSE_SOCKET_STATUS,
  payload: {
    initiatorType,
    initiatorLayerName,
    initiatorName,
  },
});

const socketsStatus = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SOCKET_STATUS: {
      const { initiatorType, initiatorLayerName, initiatorName, socketInstance } = action.payload;
      const checkIfAlreadyCreated = state?.[initiatorType]?.[initiatorLayerName]?.[initiatorName];
      if (checkIfAlreadyCreated) checkIfAlreadyCreated.close();
      return {
        ...state,
        [initiatorType]: {
          ...state?.[initiatorType],
          [initiatorLayerName]: {
            ...state?.[initiatorType]?.[initiatorLayerName],
            [initiatorName]: socketInstance,
          },
        },
      };
    }
    case CHECK_SOCKET_STATUS: {
      const { initiatorType, initiatorLayerName, initiatorName } = action.payload;
      const checkIfAlreadyCreated = state?.[initiatorType]?.[initiatorLayerName]?.[initiatorName];
      if (checkIfAlreadyCreated) {
        checkIfAlreadyCreated.close();
        const stateCopy = { ...state };
        delete stateCopy[initiatorType][initiatorLayerName][initiatorName];
        return { ...stateCopy };
      }
      return state;
    }
    case CLOSE_SOCKET_STATUS: {
      const { initiatorType, initiatorLayerName, initiatorName } = action.payload;
      const socketInstance = state[initiatorType]?.[initiatorLayerName]?.[initiatorName];
      socketInstance.close();
      const stateCopy = { ...state };
      delete stateCopy[initiatorType]?.[initiatorLayerName]?.[initiatorName];
      return { ...stateCopy };
    }
    case CLEAN_UP_SOCKETS_STATUSES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default socketsStatus;
