import React from 'react';
import PreparedDescItems from 'UI/custom/components/PreparedDescItems/PreparedDescItems';
import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { AnyObject } from '../../../../types/enums/general/general.model';
import { DefectModal } from './components/DefectModal/DefectModal';

const MonitoringUDSInfo = ({
  defectName,
  headerContent,
  image,
  footerContent,
  listOfDescriptions,
  bbox,
  defectText,
}: AnyObject) => {
  return (
    <ScrollableCard headerContent={headerContent} footerContent={footerContent}>
      <>
        {image && <DefectModal image={image} defectName={defectName} bbox={bbox} defectText={defectText} />}
        {listOfDescriptions && <PreparedDescItems listOfDescriptions={listOfDescriptions} />}
      </>
    </ScrollableCard>
  );
};

export default MonitoringUDSInfo;
