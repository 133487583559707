import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import Compendium from './Compendium';
import CompendiumColumnActionPanel from '../CompendiumButtonBars/Common/CompendiumColumnActionPanel/CompendiumColumnActionPanel';
import styled from 'styled-components/macro';
import { useAgGridControl } from '../../../../hooks/ag-grid/useAgGridControl';
import { directoryHistoryActions } from '../../../../store/reducers/directory/actions/directoryHistoryActions';
import { selectObject } from '../../../../store/reducers/directory/actions/selectedObjectActions';

const { selectRowForDirectoryHistory } = directoryHistoryActions;

export const StyledCompendiumContainerMainWrapper = styled.div`
  position: relative;
  height: calc(100% - 45px);
  .ag-root-wrapper-body,
  .ag-layout-normal {
    min-height: calc(100vh - 350px);
    height: 100%;
  }
`;

export const StyledActionPanelWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 0;
`;

export const SpinContainer = styled(Spin)`
  padding: 10% 50%;
`;

const CompendiumContainer = (props) => {
  const {
    proceedData,
    selectRowForCommon,
    handleSelectRowForDirectoryHistory,
    additionalCallbacks,
    withPagination,
    agGridThemeName,
  } = props;

  const {
    aggApi,
    quickMenu,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
    onExternalFilterChanged,
    onFilterChanged,
    clearAgGridFilters,
  } = useAgGridControl();

  useEffect(() => {}, [aggApi, quickMenu]);

  const handleSelectRow = useCallback(
    (aggridRow) => {
      selectRowForCommon(aggridRow.data);
      handleSelectRowForDirectoryHistory(aggridRow.data);
    },
    [selectRowForCommon, handleSelectRowForDirectoryHistory]
  );

  const handleRefresh = useCallback(() => {}, []);

  const handleProvideData = (event) => {
    proceedData(event);
  };

  let containerCallbacks = {
    handleAGGInit,
    handleRefresh,
    handleSelectRow,
    toggleShowFilterMenu,
    handleProvideData,
    toggleShowRowsMenu,
    onExternalFilterChanged,
    toggleShowQuickFilter,
    onFilterChanged,
    clearAgGridFilters,
  };

  if (additionalCallbacks) containerCallbacks.additionalCallbacks = additionalCallbacks;

  return (
    <StyledCompendiumContainerMainWrapper>
      <>
        <StyledActionPanelWrapper>
          <CompendiumColumnActionPanel
            quickMenuState={quickMenu}
            toggleShowFilterMenu={toggleShowFilterMenu}
            toggleShowQuickFilter={toggleShowQuickFilter}
            toggleShowRowsMenu={toggleShowRowsMenu}
            onExternalFilterChanged={onExternalFilterChanged}
            clearAgGridFilters={clearAgGridFilters}
          />
        </StyledActionPanelWrapper>

        <Compendium
          aggApi={aggApi}
          data={props.data}
          model={props.model}
          showQuickFilter={quickMenu.search}
          toggleShowQuickFilter={toggleShowQuickFilter}
          containerCallbacks={containerCallbacks}
          withPagination={withPagination}
          agGridThemeName={agGridThemeName}
        />
      </>
    </StyledCompendiumContainerMainWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.app.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectRowForCommon: (data) => dispatch(selectObject(data)),
  handleSelectRowForDirectoryHistory: (selectedData) => dispatch(selectRowForDirectoryHistory(selectedData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompendiumContainer);
