import CompendiumContainer from '../../../UI/custom/Aggrid/Compendium/CompendiumContainer';
import ObjectPanelContainer from '../../../UI/custom/objectPanels/Common/ObjectPanelContainer';
import CreateButton from '../../../UI/custom/actionsButtons/common/CreateButton';
import { RefreshButton } from '../../../UI/custom/actionsButtons/common/RefreshButton';
import DeleteButton from '../../../UI/custom/actionsButtons/common/DeleteButton';
import { resolver } from './resolver';
import { EDirectoryFolders } from './folders/EDirectoryFolders';
import { RequestPath } from '../../../types/enums/routes/request/RequestPath';
import { DirectoryRegistratorObjectModel } from './directoryRegistrator.model';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import { UploadTMCFileButton } from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileButton';
import { generateRightsByKey } from '../../../services/application/permissions/generateRightsByKey';
import { AdministrationRouteModel } from '../../../types/enums/routes/administration/AdministrationRoute.model';

export const resolveUrl = resolver;

const defaultFields = {
  generalRenderer: CompendiumContainer,
  objectPanel: ObjectPanelContainer,
  gridActionButtons: [CreateButton, RefreshButton, DeleteButton],
};
/***
 *register urls for application and custom renderers
 * register null if render is unnecessary
 */
export const registeredUrls: DirectoryRegistratorObjectModel[] = [
  {
    url: DirectoryRouteModel.supplyCablesTram,
    urlData: RequestPath.supplyCablesTram,
    folderKey: EDirectoryFolders.ekbElectro,
    label: 'Питающие кабели трамваев',
    description: 'Список питающих кабелей трамваев',
    folderPermissions: generateRightsByKey('supplycabletram', 'kns'),
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.users,
    urlData: RequestPath.users,
    folderKey: EDirectoryFolders.administration,
    label: 'Пользователи',
    folderPermissions: generateRightsByKey('user', 'users'),
    description: 'Список активных пользователей',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.knsCablesTram,
    urlData: RequestPath.knsCablesTram,
    folderKey: EDirectoryFolders.ekbElectro,
    label: 'Кабели трамваев',
    folderPermissions: generateRightsByKey('cabletram', 'kns'),
    description: 'Список кабелей трамваев',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.knsLinesTram,
    urlData: RequestPath.knsLinesTram,
    folderKey: EDirectoryFolders.ekbElectro,
    label: 'Линии трамваев',
    folderPermissions: generateRightsByKey('linetram', 'kns'),
    description: 'Список линий трамваев',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.knsCablesTroll,
    urlData: RequestPath.knsCablesTroll,
    folderKey: EDirectoryFolders.ekbElectro,
    label: 'Кабели троллейбусов',
    folderPermissions: generateRightsByKey('cabletrolleybus', 'kns'),
    description: 'Список кабелей троллейбусов',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.knsLinesTroll,
    urlData: RequestPath.knsLinesTroll,
    folderKey: EDirectoryFolders.ekbElectro,
    label: 'Линии троллейбусов',
    folderPermissions: generateRightsByKey('linetrolleybus', 'kns'),
    description: 'Список линий троллейбусов',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.knsSubstations,
    urlData: RequestPath.knsSubstations,
    folderKey: EDirectoryFolders.ekbElectro,
    label: 'Подстанции',
    folderPermissions: generateRightsByKey('substation', 'kns'),
    description: 'Сведения о подстанциях',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.publicTransportBusStops,
    urlData: RequestPath.publicTransportBusStops,
    folderKey: EDirectoryFolders.transport,
    label: 'Остановки',
    folderPermissions: generateRightsByKey('busstop', 'public_transport'),
    description: 'Сведения о остановках',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.publicTransportTypesOfInformationBoards,
    urlData: RequestPath.publicTransportTypesOfInformationBoards,
    folderKey: EDirectoryFolders.transport,
    label: 'Информационные табло',
    folderPermissions: generateRightsByKey('historicaltypeofinformationboard', 'public_transport'),
    description: 'Сводная информация',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.illuminatedPedestrianCrossings,
    urlData: RequestPath.illuminatedPedestrianCrossings,
    folderKey: EDirectoryFolders.ekbElectro,
    label: 'Пешеходные переходы',
    folderPermissions: generateRightsByKey('pedestriancrossing', 'roads_construction'),
    description: 'Сведения о пешеходных переходах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsMarkingParameters,
    urlData: RequestPath.roadsMarkingParameters,
    folderKey: EDirectoryFolders.markup,
    label: 'Параметры дорожной разметки (Линия)',
    folderPermissions: generateRightsByKey('roadmarkinglinetype', 'roads'),
    description: 'Сведения о дорожной разметки (Линия)',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.transportDetectors,
    urlData: RequestPath.transportDetectors,
    folderKey: EDirectoryFolders.transportDetectors,
    label: 'Камеры/Детекторы',
    folderPermissions: generateRightsByKey('transportdetector', 'transport_detectors'),
    description: 'Сведения о камерах/детекторах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.camerasUdc,
    urlData: RequestPath.camerasUdc,
    folderKey: EDirectoryFolders.transportDetectors,
    label: 'Камеры удс',
    folderPermissions: generateRightsByKey('camera', 'cameras'),
    description: 'Сведения о камерах удс',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.personTypes,
    urlData: RequestPath.personTypes,
    folderKey: EDirectoryFolders.administration,
    label: 'Типы пользователей',
    folderPermissions: generateRightsByKey('persontype', 'users'),
    description: 'Сведения о типах пользователей организации',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.person,
    urlData: RequestPath.person,
    folderKey: EDirectoryFolders.administration,
    label: 'Личные данные',
    folderPermissions: generateRightsByKey('person', 'users'),
    description: 'Личные сведения о пользователях организации',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.userPositions,
    urlData: RequestPath.userPositions,
    folderKey: EDirectoryFolders.administration,
    label: 'Позиции пользователей',
    folderPermissions: generateRightsByKey('position', 'users'),
    description: 'Сведения о позициях пользователей',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.organizations,
    urlData: RequestPath.organizations,
    folderKey: EDirectoryFolders.general,
    label: 'Организации',
    folderPermissions: generateRightsByKey('organization', 'organizations'),
    description: 'Сведения о доступных организациях',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.organizationsForms,
    urlData: RequestPath.organizationsForms,
    folderKey: EDirectoryFolders.general,
    label: 'Создать организацию',
    folderPermissions: generateRightsByKey('organizationform', 'organizations'),
    description: 'Сведения о формах организации',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.permissionsGroups,
    urlData: RequestPath.permissionsGroups,
    folderKey: EDirectoryFolders.administration,
    label: 'Роли',
    folderPermissions: generateRightsByKey('group', 'auth'),
    description: 'Сведения о группах прав',
    ...defaultFields,
  },

  {
    url: DirectoryRouteModel.filtersOrganisation,
    urlData: RequestPath.filtersUserOrganizations,
    folderKey: EDirectoryFolders.general,
    label: 'Фильтры данных',
    folderPermissions: generateRightsByKey('userorganizations', 'filters'),
    description: 'Сведения о фильтрах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.sidewalks,
    urlData: RequestPath.sidewalks,
    folderKey: EDirectoryFolders.roadGeoParams,
    label: 'Тротуар',
    folderPermissions: generateRightsByKey('sidewalk', 'roads'),
    description: 'Сведения о тротуарах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadway,
    urlData: RequestPath.roadway,
    folderKey: EDirectoryFolders.roadGeoParams,
    label: 'Проезжая часть',
    folderPermissions: generateRightsByKey('roadway', 'roads'),
    description: 'Сведения о проезжей части',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsDividingLines,
    urlData: RequestPath.roadsDividingLines,
    folderKey: EDirectoryFolders.roadGeoParams,
    label: 'Разделительные полосы',
    folderPermissions: generateRightsByKey('dividingline', 'roads'),
    description: 'Сведения о разделительных полосах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.states,
    urlData: RequestPath.states,
    folderKey: EDirectoryFolders.states,
    label: 'Области',
    folderPermissions: generateRightsByKey('state', 'states'),
    description: 'Сведения об области',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.cityDistricts,
    urlData: RequestPath.cityDistricts,
    folderKey: EDirectoryFolders.states,
    label: 'Деление на административные районы',
    folderPermissions: generateRightsByKey('citydistrict', 'states'),
    description: 'Сведения о делении на административные районы',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.statesDistricts,
    urlData: RequestPath.statesDistricts,
    folderKey: EDirectoryFolders.states,
    label: 'Районы области',
    folderPermissions: generateRightsByKey('statedistricts', 'states'),
    description: 'Сведения о районах области',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.statesLocalities,
    urlData: RequestPath.statesLocalities,
    folderKey: EDirectoryFolders.states,
    label: 'Районные населённые пункты',
    folderPermissions: generateRightsByKey('locality', 'states'),
    description: 'Сведения о районных населённых пунктах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.statesLocalityTypes,
    urlData: RequestPath.statesLocalityTypes,
    folderKey: EDirectoryFolders.states,
    label: 'Типы районных местностей',
    folderPermissions: generateRightsByKey('localitytype', 'states'),
    description: 'Сведения о типах районных местностей',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.statesMunicipalities,
    urlData: RequestPath.statesMunicipalities,
    folderKey: EDirectoryFolders.states,
    label: 'Районные муниципалитеты',
    folderPermissions: generateRightsByKey('municipality', 'states'),
    description: 'Сведения о районных муниципалитетах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.statesPopulation,
    urlData: RequestPath.statesPopulation,
    folderKey: EDirectoryFolders.states,
    label: 'Население районов',
    folderPermissions: generateRightsByKey('population', 'states'),
    description: 'Сведения о населении районов',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roads,
    urlData: RequestPath.roads,
    folderKey: EDirectoryFolders.roads,
    label: 'Дороги',
    folderPermissions: generateRightsByKey('road', 'roads'),
    description: 'Сведения о дорогах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsClutchQuality,
    urlData: RequestPath.roadsClutchQuality,
    folderKey: EDirectoryFolders.roadsQuality,
    label: 'Коэффициент сцепления',
    folderPermissions: generateRightsByKey('roadclutchquality', 'roads_quality'),
    description: 'Сведения о коэффициенте сцепления на дорогах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadComplexTypeQuality,
    urlData: RequestPath.roadComplexTypeQuality,
    folderKey: EDirectoryFolders.roadsQuality,
    label: 'Комплексный показатель',
    folderPermissions: generateRightsByKey('roadcomplextypequality', 'roads_quality'),
    description: 'Сведения о комплексном показателе',
    generalRenderer: CompendiumContainer,
    objectPanel: ObjectPanelContainer,
    gridActionButtons: [CreateButton, RefreshButton, DeleteButton, UploadTMCFileButton],
  },
  {
    url: DirectoryRouteModel.uploadFilesHistory,
    urlData: RequestPath.uploadFilesHistory,
    folderKey: EDirectoryFolders.roadsQuality,
    label: 'История загрузки файлов',
    folderPermissions: generateRightsByKey('roadsqualityuploadfilehistory', 'roads_quality'),
    description: 'Сведения об истории загрузки файлов',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadFlatnessQuality,
    urlData: RequestPath.roadFlatnessQuality,
    folderKey: EDirectoryFolders.roadsQuality,
    label: 'Коэффициент ровности дорог',
    folderPermissions: generateRightsByKey('roadflatnessquality', 'roads_quality'),
    description: 'Сведения о коэффициенте ровности дорог',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadRutQuality,
    urlData: RequestPath.roadRutQuality,
    folderKey: EDirectoryFolders.roadsQuality,
    label: 'Колейность',
    folderPermissions: generateRightsByKey('roadrutquality', 'roads_quality'),
    description: 'Сведения о дорожной колее',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadStrengthQuality,
    urlData: RequestPath.roadStrengthQuality,
    folderKey: EDirectoryFolders.roadsQuality,
    label: 'Коэффициент прочности',
    folderPermissions: generateRightsByKey('roadstrengthquality', 'roads_quality'),
    description: 'Cведения о коэффициенте прочности',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.trafficLightObject,
    urlData: RequestPath.trafficLightObject,
    folderKey: EDirectoryFolders.roadsConstruction,
    label: 'Светофорные объекты',
    folderPermissions: generateRightsByKey('tlcontroller', 'tl_controllers'),
    description: 'Cведения о светофорных объектах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsSegmentsSupports,
    urlData: RequestPath.roadsSegmentsSupports,
    folderKey: EDirectoryFolders.artificial_structures,
    label: 'Опорные столбы',
    folderPermissions: generateRightsByKey('historicalsupportpillar', 'kns'),
    description: 'Сведения об опорах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadAccidents,
    urlData: RequestPath.roadAccidents,
    folderKey: EDirectoryFolders.roadAccidents,
    label: 'Дорожно-транспортные происшествия',
    folderPermissions: generateRightsByKey('roadaccident', 'road_accidents'),
    description: 'Сведения о дорожно-транспортных происшествиях',
    generalRenderer: CompendiumContainer,
    objectPanel: ObjectPanelContainer,
    gridActionButtons: [CreateButton, RefreshButton, DeleteButton, UploadTMCFileButton],
  },
  {
    url: DirectoryRouteModel.roadAccidentsParticipant,
    urlData: RequestPath.roadAccidentsParticipant,
    folderKey: EDirectoryFolders.roadAccidents,
    label: 'Участник',
    folderPermissions: generateRightsByKey('roadaccidentparticipant', 'road_accidents'),
    description: 'Сведения об участнике ДТП',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadAccidentsVehicle,
    urlData: RequestPath.roadAccidentsVehicle,
    folderKey: EDirectoryFolders.roadAccidents,
    label: 'ДТП автомобиль',
    folderPermissions: generateRightsByKey('roadaccidentvehicle', 'road_accidents'),
    description: 'Сведения об автомобиле участнике ДТП',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsSegments,
    urlData: RequestPath.roadsSegments,
    folderKey: EDirectoryFolders.roads,
    label: 'Участки дорог',
    folderPermissions: generateRightsByKey('segment', 'roads'),
    description: 'Сведения об участках дорог',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsSegmentSurfaceTypes,
    urlData: RequestPath.roadsSegmentSurfaceTypes,
    folderKey: EDirectoryFolders.roads,
    label: 'Дорожное покрытие (типы)',
    folderPermissions: generateRightsByKey('segmentsurfacetype', 'roads'),
    description: 'Сведения о типах покрытий',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.sidewalks,
    urlData: RequestPath.sidewalks,
    folderKey: EDirectoryFolders.roads,
    label: 'Тротуар',
    folderPermissions: generateRightsByKey('sidewalk', 'roads'),
    description: 'Сведения о тротуарах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsDividingLines,
    urlData: RequestPath.roadsDividingLines,
    folderKey: EDirectoryFolders.roads,
    label: 'Разделительные линии',
    folderPermissions: generateRightsByKey('dividingline', 'roads'),
    description: 'Сведения о разделительных линиях',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.tramway,
    urlData: RequestPath.tramway,
    folderKey: EDirectoryFolders.roads,
    label: 'Трамвайное полотно',
    folderPermissions: generateRightsByKey('historicaltramway', 'public_transport'),
    description: 'Сведения о трамвайных полотнах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.engineeringConstructions,
    urlData: RequestPath.engineeringConstructions,
    folderKey: EDirectoryFolders.roads,
    label: 'Инженерные сооружения',
    folderPermissions: generateRightsByKey('engineeringconstruction', 'engineering_constructions'),
    description: 'Сведения об инженерных сооружениях',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.speedBumps,
    urlData: RequestPath.speedBumps,
    folderKey: EDirectoryFolders.roads,
    label: 'Лежачие полицейские',
    folderPermissions: generateRightsByKey('speedbump', 'roads_construction'),
    description: 'Сведения о лежачих полицейских',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadFences,
    urlData: RequestPath.roadFences,
    folderKey: EDirectoryFolders.roads,
    label: 'Дорожные ограждения',
    folderPermissions: generateRightsByKey('roadfence', 'roads'),
    description: 'Сведения о дорожных ограждениях',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadConstructionsRoadSigns,
    urlData: RequestPath.roadConstructionsRoadSigns,
    folderKey: EDirectoryFolders.roads,
    label: 'Дорожные знаки',
    folderPermissions: generateRightsByKey('historicalroadsign', 'roads_construction'),
    description: 'Сведения о дорожных знаках',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.supportWalls,
    urlData: RequestPath.supportWalls,
    folderKey: EDirectoryFolders.roads,
    label: 'Подпорные стены',
    folderPermissions: generateRightsByKey('historicalsupportwall', 'roads_construction'),
    description: 'Сведения о подпорных стенах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.tubes,
    urlData: RequestPath.tubes,
    folderKey: EDirectoryFolders.roads,
    label: 'Трубы',
    folderPermissions: generateRightsByKey('historicaltubeconstruction', 'engineering_constructions'),
    description: 'Сведения о трубах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsConstructionLighting,
    urlData: RequestPath.roadsConstructionLighting,
    folderKey: EDirectoryFolders.roads,
    label: 'Освещение',
    folderPermissions: generateRightsByKey('historicallighting', 'roads_construction'),
    description: 'Сведения об освещении',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadMarkingPoints,
    urlData: RequestPath.roadMarkingPoints,
    folderKey: EDirectoryFolders.roads,
    label: 'Разметка (точка)',
    folderPermissions: generateRightsByKey('roadmarkingpoint', 'roads'),
    description: 'Сведения о разметке (точки)',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadMarkingLines,
    urlData: RequestPath.roadMarkingLines,
    folderKey: EDirectoryFolders.roads,
    label: 'Разметка (линия)',
    folderPermissions: generateRightsByKey('roadmarkingline', 'roads'),
    description: 'Сведения о разметке (линии)',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadMarkingPolygons,
    urlData: RequestPath.roadMarkingPolygons,
    folderKey: EDirectoryFolders.roads,
    label: 'Разметка (полигон)',
    folderPermissions: generateRightsByKey('roadmarkingpolygon', 'roads'),
    description: 'Сведения о разметке (полигоны)',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.traySystems,
    urlData: RequestPath.traySystems,
    folderKey: EDirectoryFolders.roads,
    label: 'Лотковые системы',
    folderPermissions: generateRightsByKey('segmenttraysystem', 'roads'),
    description: 'Сведения о лотковых системах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.townSquares,
    urlData: RequestPath.townSquares,
    folderKey: EDirectoryFolders.roads,
    label: 'Площади',
    folderPermissions: generateRightsByKey('townsquare', 'roads'),
    description: 'Сведения о площадях',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.contoursLawn,
    urlData: RequestPath.contoursLawn,
    folderKey: EDirectoryFolders.roads,
    label: 'Газоны',
    folderPermissions: generateRightsByKey('contourlawn', 'roads'),
    description: 'Сведения о газонах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.signalBars,
    urlData: RequestPath.signalBars,
    folderKey: EDirectoryFolders.roads,
    label: 'Сигнальные столбики',
    folderPermissions: generateRightsByKey('segmentsignalbar', 'roads'),
    description: 'Сведения о сигнальных столбиках',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.cabinets,
    urlData: RequestPath.cabinets,
    folderKey: EDirectoryFolders.roads,
    label: 'Шкафы кабельных коммуникаций',
    folderPermissions: generateRightsByKey('cabinetforcablecommunications', 'roads'),
    description: 'Сведения о шкафах кабельных коммуникаций',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.improvementElements,
    urlData: RequestPath.improvementElements,
    folderKey: EDirectoryFolders.roads,
    label: 'Элементы благоустройства',
    folderPermissions: generateRightsByKey('improvementelement', 'roads'),
    description: 'Сведения об элементах благоустройства',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsConstructionRoadSignGroups,
    urlData: RequestPath.roadsConstructionRoadSignGroups,
    folderKey: EDirectoryFolders.roads,
    label: 'Типы дорожных знаков',
    folderPermissions: generateRightsByKey('roadsigntype', 'roads_construction'),
    description: 'Сведения о типах дорожных знаков',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsConstructionTrafficLights,
    urlData: RequestPath.roadsConstructionTrafficLights,
    folderKey: EDirectoryFolders.roadsConstruction,
    label: 'Светофорные знаки',
    folderPermissions: generateRightsByKey('historicaltrafficlights', 'roads_construction'),
    description: 'Сведения о светофорных знаках',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.socialFacilities,
    urlData: RequestPath.socialFacilities,
    folderKey: EDirectoryFolders.socialFacilities,
    label: 'Социальные объекты',
    folderPermissions: generateRightsByKey('socialfacility', 'social_facilities'),
    description: 'Сведения о социальных объектах',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadWorksTrafficManagementProjects,
    urlData: RequestPath.roadWorksTrafficManagementProjects,
    folderKey: EDirectoryFolders.trafficManagementProjects,
    label: 'ПОДД',
    folderPermissions: generateRightsByKey('historicaltrafficmanagementproject', 'road_works'),
    description: 'Сведения о ПОДД',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadConstructionsBusShelters,
    urlData: RequestPath.roadConstructionsBusShelters,
    folderKey: EDirectoryFolders.socialFacilities,
    label: 'Автобусные остановки',
    folderPermissions: generateRightsByKey('busshelter', 'roads_construction'),
    description: 'Сведения об автобусных остановках',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadWorksTrafficOrganizationSchema,
    urlData: RequestPath.roadWorksTrafficOrganizationSchema,
    folderKey: EDirectoryFolders.trafficManagementProjects,
    label: 'Схема организации движения',
    folderPermissions: generateRightsByKey('trafficorganizationschema', 'road_works'),
    description: 'Сведения об организации движения',
    ...defaultFields,
  },
  {
    url: DirectoryRouteModel.roadsConstructionPedDorders,
    urlData: RequestPath.roadsConstructionPedBorders,
    folderKey: EDirectoryFolders.roads,
    label: 'Пешеходное ограждение',
    folderPermissions: generateRightsByKey('historicalpedborder', 'roads_construction'),
    description: 'Сведения о пешеходных ограждениях',
    ...defaultFields,
  },
  {
    url: AdministrationRouteModel.constants,
    urlData: RequestPath.systemConstants,
    label: 'Переменные',
    description: 'Переменные системы',
    folderKey: EDirectoryFolders.administration,
    folderPermissions: generateRightsByKey('constant', 'system'),
    ...defaultFields,
  },
];
