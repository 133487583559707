import { Dispatch, useEffect } from 'react';
import { gotoElementByTag } from '../../../../../services/dom/gotoElementByTag';
import { setEditableLayerTooltip } from '../../../../../store/reducers/map/mapboxEditableLayersHistory';
import { AnyAction } from 'redux';

export function useOutsideAlert(ref: { current?: any }, dispatch: Dispatch<AnyAction>, onCloseCallback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      const parent = gotoElementByTag(event.target, 'div');
      if (event.rightButton) {
        return;
      }
      if (!('rightButton' in event) && event?.button === 2) {
        return;
      }
      if (parent.classList.contains('ant-popover-buttons')) {
        return;
      }
      if (ref?.current && !ref.current.contains(event.target)) {
        if (!onCloseCallback) {
          dispatch(setEditableLayerTooltip(null));
        } else onCloseCallback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch, onCloseCallback, ref]);
}
