import React, { useEffect, useState, useMemo } from 'react';
import { Styled } from './SliderInput.styled';
import { SliderInputProps } from './model/SliderInput.model';
import { useCallback } from 'react';
import { AnyObject } from 'types/enums/general/general.model';

const { ExtendedAntdSlider, SliderWrapper, SliderLabel, SliderInner, SliderInfoWindow } = Styled;

const SliderInput = ({ min, max, description, object, formProps }: SliderInputProps) => {
  const [valueSlider, setValueSlider] = useState<number>(0);
  const { setCustomFieldsData } = formProps;

  useEffect(() => {
    if (object) {
      setValueSlider(object.azimuth ?? 0);
    }
  }, [object]);

  const changeAngle = (value: number) => {
    setValueSlider(value);
  };

  const onAfterChange = useCallback(
    (value: number) => {
      if (setCustomFieldsData) {
        setCustomFieldsData((prevValue: AnyObject) => ({ ...prevValue, azimuth: `${value}` }));
      }
    },
    [setCustomFieldsData]
  );

  const onChange = useCallback(
    (val) => {
      changeAngle(val);
      onAfterChange(val);
    },
    [onAfterChange]
  );

  const style = useMemo(() => ({ transform: `rotate(${180 + valueSlider}deg)` }), [valueSlider]);

  return (
    <SliderWrapper>
      <SliderInner>
        <SliderLabel>{`${description.label} ${valueSlider}`}&#176;</SliderLabel>
      </SliderInner>
      <SliderInfoWindow style={style}>
        <img alt="Фото дорожного знака" src={process.env.PUBLIC_URL + '/img/signs/3.28.png'} />
      </SliderInfoWindow>
      <ExtendedAntdSlider min={min} max={max} onChange={onChange} range={false} />
    </SliderWrapper>
  );
};

export default SliderInput;
