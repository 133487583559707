import React, { FC, useMemo } from 'react';

import { Styled } from './style/AddedMasks.styled';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { PencilIcon } from 'UI/custom/icons/actionIcons/PencilIcon';
import { EyeOnIcon } from 'UI/custom/icons/actionIcons/EyeOnIcon';
import { EyeOffIcon } from 'UI/custom/icons/actionIcons/EyeOffIcon';
import { AddedMaskItemProps } from './model/AddedMasks.model';

const { AddedMaskItemWrap, AddedMaskItemName, AddedMaskItemButtonsWrap } = Styled;

const AddedMaskItem: FC<AddedMaskItemProps> = ({ name, isVisible, onDeleteMask, onEditMask, onVisibleMask }) => {
  const hideButtonIcon = useMemo(() => (isVisible ? <EyeOnIcon /> : <EyeOffIcon />), [isVisible]);

  return (
    <AddedMaskItemWrap>
      <AddedMaskItemName>{name}</AddedMaskItemName>
      <AddedMaskItemButtonsWrap>
        <Button onClick={onDeleteMask} icon={<DeleteIcon />} iconSize={20} p={0} type={ButtonType.unbounded} />
        <Button
          onClick={onEditMask}
          icon={<PencilIcon />}
          iconSize={20}
          p={0}
          iconColor={'#35446F'}
          type={ButtonType.unbounded}
        />
        <Button
          onClick={onVisibleMask}
          icon={hideButtonIcon}
          iconSize={20}
          p={0}
          iconColor={'#35446F'}
          type={ButtonType.unbounded}
        />
      </AddedMaskItemButtonsWrap>
    </AddedMaskItemWrap>
  );
};

export default AddedMaskItem;
