export interface ToggleButtonI {
  id: number;
  icon?: JSX.Element;
  name: string;
  switcher: modeEnum;
  ignore?: boolean;
}

export interface ToggleButtonGroupProps {
  buttonClickHandler: (element: ToggleButtonI) => void;
  displayedButtons: Array<ToggleButtonI>;
  activePanel: string;
  countNewMessage: number;
}

export enum modeEnum {
  info = 'info',
  chat = 'chat',
}
