import React, { useMemo, useCallback, useState } from 'react';
import { Styled } from './style/Slider.styled';
import { CustomSliderProps } from './model/Slider.model';

const { ExtendedAntdSlider, SliderWrapper, SliderLabel, SliderInner, SliderInfoWindow } = Styled;

const Slider = (props: CustomSliderProps) => {
  const {
    label,
    infoPostfix,
    width,
    showInfoPanel,
    p,
    defaultValue,
    onAfterChange,
    onChange: onChangeProps,
    ...other
  } = props;

  const [sliderValue, setSliderValue] = useState<number>(defaultValue ?? 0);

  const infoWindowContent = useMemo(
    () => `${sliderValue || defaultValue || 0}${infoPostfix ?? ''}`,
    [defaultValue, infoPostfix, sliderValue]
  );

  const onChange = useCallback(
    (val) => {
      setSliderValue(val);
      onAfterChange(val);
    },
    [onAfterChange]
  );

  return (
    <SliderWrapper p={p} width={width}>
      <SliderInner>
        <SliderLabel>{label}</SliderLabel>
        {showInfoPanel && <SliderInfoWindow>{infoWindowContent}</SliderInfoWindow>}
      </SliderInner>
      {/*Некорректный тип value в ExtendedAntdSlider*/}
      {/*@ts-ignore*/}
      <ExtendedAntdSlider {...other} value={sliderValue} onChange={onChange} range={false} />
    </SliderWrapper>
  );
};

export default Slider;
