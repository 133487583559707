import { call, put, takeEvery } from 'redux-saga/effects';
import _ from 'underscore';

import { initGetRelatedDataForLayer } from './getRelatedDataForLayerSaga';
import { DataAccessMethod } from 'types/enums/routes/request/DataAccessMethod.model';
import { addLayer } from 'store/reducers/map/actions/mapboxLayersActions';
import { getFromURL } from 'api/getFromURL';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { Layers } from 'types/enums/map/layers/Layers';

export const INIT_ADD_LAYER_TO_MAP_SAGA = 'INIT_ADD_LAYER_TO_MAP_SAGA';

// имена слоев с открытым гридом при загрузке
const layerNamesWithOpenGrid = [Layers.transportOrders, Layers.trafficManagementProjects, Layers.brigadeApplications];

const consoleErrorOfCustomMainDataGetter = (Layer, dataAccessMethod) => {
  console.group('DEV: Main data access error ');
  console.error(
    `Layer -> ${Layer.constructor.name} ->have ${dataAccessMethod} data upload method in ${
      Layer.getMainData()?.constructor.name
    } main data block but doesn't overrides getCustomMainDataDownload() method`
  );
  console.error(Layer);
  console.error(Layer.getMainData());
  console.groupEnd();
};

/**
 * Получает MainData для слоя который вызывается
 * @param Layer
 */
function* getMainDataForLayer(Layer) {
  const MainDataLayer = Layer.getMainData();
  const { httpGet, custom, httpPost, httpGetAll, websocket } = DataAccessMethod;
  let data = [];
  // В основном сейчас mainData задана null в слое и это всё не выполняется
  if (Array.isArray(MainDataLayer)) {
    for (const MainDataClass of MainDataLayer) {
      const dataAccessMethod = MainDataClass.getDataAccessMethod();
      switch (dataAccessMethod) {
        // У нас есть вебсокеты? Удалить?
        case custom:
        case httpPost:
        case websocket: {
          if (MainDataClass.getCustomMainDataDownload && _.isFunction(MainDataClass.getCustomMainDataDownload)) {
            if (MainDataClass?.getIsReturnsAC()) {
              const subscribeIds = MainDataClass?.getSubscribeIds
                ? yield call(() => MainDataClass?.getSubscribeIds())
                : [];
              const action = MainDataClass.getCustomMainDataDownload(subscribeIds);
              yield put(action);
            } else {
              data = MainDataClass.getCustomMainDataDownload();
              yield put(mapboxDataActions.addMapboxData(Layer.getName(), data));
            }
          } else {
            consoleErrorOfCustomMainDataGetter(Layer, dataAccessMethod);
          }
          break;
        }
        // Основной заданный дефолтом в mainData метод при создании объектов
        case httpGet: {
          const urlData = MainDataClass.getUrlData();
          data = yield call(() => getFromURL.getDataFromURL(urlData));
          yield put(mapboxDataActions.addMapboxData(Layer.getName(), data));
          break;
        }
        case httpGetAll: {
          const urlData = MainDataClass.getUrlData();
          data = yield call(() => getFromURL.getAll(urlData));
          yield put(mapboxDataActions.addMapboxData(Layer.getName(), data));
          break;
        }
        default:
          break;
      }
    }
  }
}

/**
 * Инициирует добавление слоя на карту, принимает данные из src/registrators/map/layers/bkd/ИмяВыбранногоСлоя.js
 * @param Layer
 * @returns {{payload: {Layer: *}, type: string}}
 */
export const initAddLayerToMapSaga = (Layer) => ({
  type: INIT_ADD_LAYER_TO_MAP_SAGA,
  payload: {
    Layer,
  },
});

function* addLayerToMap(action) {
  // Этот пут ничего не делает, вроде бы. Удалить?
  yield put({ type: 'RECEIVED_PUSH_TO_MAP_HISTORY_ATTEMPT' });
  const { Layer } = action.payload;
  // Получает мэйн дату. Не знаю для чего, возможно для случаев, когда выбран не слой на карте, сейчас, в основном, возвращает null
  yield call(() => getMainDataForLayer(Layer));
  // Отправляет в стэйт основные данные - модель и полный список объектов слоя
  yield put(initGetRelatedDataForLayer(Layer));
  // Отрисовывает слой на карте
  yield put(addLayer(Layer));
  // Если приоткрытии слоя необходимо чтоб был открыт грид , то открываем грид
  if (layerNamesWithOpenGrid.includes(Layer.name))
    yield put({ type: 'TOGGLE_LAYER_DATA_VISIBLE', payload: { newStatus: true } });
}

//ready
export function* watchInitializeAddLayerToMapSaga() {
  yield takeEvery(INIT_ADD_LAYER_TO_MAP_SAGA, addLayerToMap);
}
