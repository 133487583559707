import styled from 'styled-components/macro';
import PanelSlimCardContent from '../../../../../../../../../../../../UI/common/components/PanelSlimCard/components/Content/PanelSlimCardContent';
import PanelSlimCardActionGroup from '../../../../../../../../../../../../UI/common/components/PanelSlimCard/components/ActionGroup/PanelSlimCardActionGroup';
import PanelSlimCard from '../../../../../../../../../../../../UI/common/components/PanelSlimCard/PanelSlimCard';

const CustomisedPanelCardContent = styled(PanelSlimCardContent)`
  & button {
    margin-right: 5px;
  }
`;

const LocalIconWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 1.8rem;
  top: 50%;
  transform: rotate(90deg) translateX(-25%);
  transition: all 0.3s;
`;

const ExtendedPanelCard = styled(PanelSlimCard)`
  position: relative;

  :before {
    content: '';
    display: block;
    position: absolute;
    height: 30px;
    width: 5px;
    background-color: ${(props) => props.theme.colors.primary};
    left: -15px;
    top: 50%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 0.3s;
    transform: translateY(-50%);
    opacity: ${(props) => props.isSelected || 0};
  }
`;

const CustomisedPanelCardActionGroup = styled(PanelSlimCardActionGroup)`
  overflow: hidden;
  position: relative;
  & .moved-action-item {
    opacity: ${(props) => (props.active ? 1 : 0)};
    transform: ${(props) => (props.active ? '' : 'translateX(30px)')};
  }
  & ${LocalIconWrap} {
    opacity: ${(props) => (props.active ? 0 : 0.85)};
  }
`;

export const Styled = {
  CustomisedPanelCardContent,
  CustomisedPanelCardActionGroup,
  LocalIconWrap,
  ExtendedPanelCard,
};
