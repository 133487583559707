import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

export const inlineResolver = (model: ModelResponse) => {
  let inlines = [];
  let main = [];
  for (let [key, value] of Object.entries(model?.scheme)) {
    if (value.type === 'inline') inlines.push({ [key]: value });
    else main.push({ [key]: value });
  }
  return { main, inlines };
};
