import React from 'react';
import { Styled } from './DataTSContainer.styled';
import GeneralDataModelTS from './RepresentationData/GeneralDataModelTS';
import { CollapsableItem } from '../../../../../../../../UI/common/components/CollapsableItem/CollapsableItem';

const { LocalMainWrapper, LocalModelTSWrapper } = Styled;

const DataTSContainer = ({ updateDate, otherData, titleContent, defaultOpened }) => {
  return (
    <CollapsableItem
      titleContent={titleContent ? titleContent : 'Выберите нужные категории'}
      defaultOpened={typeof defaultOpened == 'boolean' ? defaultOpened : true}
    >
      <LocalMainWrapper>
        <LocalModelTSWrapper>
          <GeneralDataModelTS updateDate={updateDate} otherData={otherData} />
        </LocalModelTSWrapper>
      </LocalMainWrapper>
    </CollapsableItem>
  );
};

export default DataTSContainer;
