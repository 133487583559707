import { AnyObject } from 'types/enums/general/general.model';

export const getYearsChoice = () => {
  let yearsChoice: AnyObject = {};
  let date = new Date();
  let year = date.getFullYear();
  for (let i = 1990; i <= year; i++) {
    yearsChoice[i] = i;
  }
  return yearsChoice;
};
