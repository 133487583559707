import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { message } from 'antd';

import { modeEnum, OrderApplicationsI } from './model/OrderApplications.model';
import { Application, ApplicationOrder, ApplicationsService } from 'generated/api/api';
import { Styled } from './style/OrderApplications.style';
import { theme } from 'styles/themes/rootTheme';
import { iconSizesKit } from 'styles/utils/iconSizesKit';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import DraggableOneColumnGrid from 'UI/common/components/DraggableOneColumnGrid/DraggableOneColumnGrid';
import { OptionIcon } from 'UI/custom/icons/actionIcons/OptionIcon';
import { appActions } from 'store/reducers/global/app/app.actions';
import { getApplicationsForTeamsOfWorkers } from './utils/setStateApplicationsForWorkShift';
import AddApplicationsButton from './AddApplicationsButton';

const {
  OrderApplicationsForm,
  OrderApplicationsTitle,
  OrderApplicationsWrap,
  OrderApplicationsItemDate,
  OrderApplicationsItemCircle,
  OrderApplicationsItemName,
  OrderApplicationsButtonWrap,
  OrderItem,
  OrderItemIcon,
} = Styled;

const OrderApplications: FC<OrderApplicationsI> = ({ appointedTeamId, teamMastersId }) => {
  const dispatch = useDispatch();

  const [mode, setMode] = useState<modeEnum>(modeEnum.view);
  const [order, setOrder] = useState<Array<ApplicationOrder>>([]);
  const [applications, setApplications] = useState<Array<Application>>([]);

  const uploadApplications = useCallback(async () => {
    const teamOfWorkersApplications = await getApplicationsForTeamsOfWorkers(appointedTeamId, dispatch);
    setApplications(teamOfWorkersApplications);
  }, [appointedTeamId, dispatch]);

  useEffect(() => {
    uploadApplications();
  }, [appointedTeamId, uploadApplications]);

  const changeOrder = useCallback((order) => {
    const newOrder: Array<ApplicationOrder> = [];

    for (const index in order) {
      const item = order[index];
      const itemId = parseInt(item.i);
      const newIndex = parseInt(item.y) + 1;

      newOrder.push({
        id: itemId,
        order: newIndex,
      });
    }

    setOrder(newOrder);
  }, []);

  const setNewOrder = useCallback(async () => {
    dispatch(appActions.startAppFetching());
    try {
      await ApplicationsService.applicationsSetOrdersCreate({ body: { data: order } });
      uploadApplications();
      setMode(modeEnum.view);
    } catch (e) {
      message.error('Что-то пошло не так');
    }
    dispatch(appActions.endAppFetching());
  }, [dispatch, order, uploadApplications]);

  const viewContent = useMemo(() => {
    const content = applications.map((item) => {
      const time = moment(item.finish_of_work_planned_date).format('DD.MM HH:mm');
      const color = item.status ? theme.status[item.status] : '';

      return (
        <>
          <OrderApplicationsItemDate key={`${item.id}_date`}>{time}</OrderApplicationsItemDate>
          <OrderApplicationsItemCircle key={`${item.id}_circle`} color={color} />
          <OrderApplicationsItemName key={`${item.id}_name`}>Заявка №{item.id}</OrderApplicationsItemName>
        </>
      );
    });

    return <OrderApplicationsWrap>{content}</OrderApplicationsWrap>;
  }, [applications]);

  const editContent = useMemo(() => {
    const data = { x: 0, w: 2, h: 1, isBounded: true };
    return applications.map((item, index) => {
      return (
        <OrderItem data-grid={{ ...data, y: index }} key={`${item.id}`}>
          <OrderItemIcon
            p={0}
            className={'order__item-icon'}
            type={ButtonType.unbounded}
            iconSize={iconSizesKit.big}
            icon={<OptionIcon />}
          />
          Завка №{item.id}
        </OrderItem>
      );
    });
  }, [applications]);

  const viewButtons = useMemo(() => {
    return (
      <>
        <AddApplicationsButton
          appointedTeamId={appointedTeamId}
          teamMastersId={teamMastersId}
          applications={applications}
          onUploadApplications={uploadApplications}
        />
        <Button type={ButtonType.primaryReverse} onClick={() => setMode(modeEnum.edit)}>
          Изменить приоритет
        </Button>
      </>
    );
  }, [applications, appointedTeamId, teamMastersId, uploadApplications]);

  const editButtons = useMemo(() => {
    return (
      <>
        <Button type={ButtonType.primary} onClick={setNewOrder}>
          Сохранить приоритет
        </Button>
        <Button type={ButtonType.dangerReverseTranslucent} onClick={() => setMode(modeEnum.view)}>
          Отменить
        </Button>
      </>
    );
  }, [setNewOrder]);

  return (
    <OrderApplicationsForm>
      <OrderApplicationsTitle>Заявки бригады</OrderApplicationsTitle>
      {mode === modeEnum.view && viewContent}
      {/*@ts-ignore*/}
      {mode === modeEnum.edit && <DraggableOneColumnGrid onLayoutChange={changeOrder} ownItems={editContent} />}
      <OrderApplicationsButtonWrap>
        {mode === modeEnum.view && viewButtons}
        {mode === modeEnum.edit && editButtons}
      </OrderApplicationsButtonWrap>
    </OrderApplicationsForm>
  );
};

export default OrderApplications;
