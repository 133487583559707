import { LayerSettingsPrototype } from '../LayerSettingsPrototype';

export class ColorLayerSetting extends LayerSettingsPrototype {
  type = 'color';
  currentValue;

  constructor({ currentValue = [0, 0, 0], label, keyForDeck }) {
    super({ label, keyForDeck });

    this.currentValue = currentValue;
  }

  getSettingType() {
    return this.type;
  }

  getInitialValue() {
    return this.currentValue;
  }

  getCurrentValue() {
    return this.currentValue;
  }
}
