import { message } from 'antd';
import { Dispatch } from 'react';

import { ApplicationsService } from 'generated/api/api';
import { appActions } from 'store/reducers/global/app/app.actions';

const getResApplicationsForTeamsOfWorkers = async (id: number): Promise<any> => {
  const appointedTeam = id.toString();
  return await ApplicationsService.applicationsList({
    appointedTeam,
    ordering: 'order',
  });
};

export const getApplicationsForTeamsOfWorkers = async (teamOfWorkersID: number, dispatch: Dispatch<any>) => {
  let applications = [];
  dispatch(appActions.startAppFetching());
  try {
    const { results } = await getResApplicationsForTeamsOfWorkers(teamOfWorkersID);
    applications = results;
  } catch (e) {
    message.error('Что-то пошло не так');
  }
  dispatch(appActions.endAppFetching());

  return applications;
};
