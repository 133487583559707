import React, { createContext } from 'react';
import { iconSizesKit } from 'styles/utils/iconSizesKit';
import { MapContextProps } from 'react-map-gl/src/components/map-context';
import { AnyObject } from 'types/enums/general/general.model';
import { IMapbox } from '../Mapbox';
import { IMapContainerProps } from 'UI/common/form/inputs/ModalMapInput/MapContainer';

export const MapModuleContext = createContext<valueOfMapModuleContext | null>(null);

interface IWithMapModuleContextForMapContainerProps {
  (WrappedComponent: IMapContainerProps): JSX.Element;
}

interface IWithMapModuleContextForMapbox {
  (WrappedComponent: IMapbox): JSX.Element;
}

export type valueOfMapModuleContext = {
  iconSizesKit: { [key: string]: { width: string; height: string } };
  mapRef: null | MapContextProps;
  deckRef: null | AnyObject;
};

const withMapModuleContext = (
  WrappedComponent: IWithMapModuleContextForMapContainerProps | IWithMapModuleContextForMapbox
) => {
  return ({ ...props }) => {
    const value = {
      iconSizesKit: iconSizesKit,
      mapRef: null,
      deckRef: null,
    };
    return (
      <MapModuleContext.Provider value={value}>
        {/* @ts-ignore ошибка с типизацией TODO: исправить Тех-долг-FRONTEND #5586*/}
        <WrappedComponent {...props} />
      </MapModuleContext.Provider>
    );
  };
};
export default withMapModuleContext;
