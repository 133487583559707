import { BooleanLayerSetting } from './prototype/types/BooleanLayerSetting';

export class ObjectNameLayers extends BooleanLayerSetting {
  constructor() {
    super({
      label: 'Показать текст',
      keyForDeck: 'billboards',
    });
  }
}
