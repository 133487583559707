import CameraMaskPrototype, { MaskObjectList, PolygonConfig, TypePoints } from './prototype/CameraMaskPrototype';
import { ObjectType } from './prototype/ObjectType';
import { DetectionMask, MaskTypeEnum } from 'generated/api/api';
import _ from 'underscore';
import { Shape } from 'konva/lib/Shape';
import { Stage } from 'konva/lib/Stage';
import { Line } from 'konva/lib/shapes/Line';
import { formatPoints } from '../functions';

class ObjectCounterCarMask extends CameraMaskPrototype {
  protected static maskTypeName = 'Счетчик автомобилей';
  protected static maskType = MaskTypeEnum.ObjectCounterCar;
  protected static maskObjectList: Array<MaskObjectList> = [
    {
      type: ObjectType.roiPoints,
      name: 'Область детекции ТС',
      help: 'Выделите на полосе движения область детекции транспортного средства',
    },
    {
      type: ObjectType.controlLinePoints,
      name: 'Линия подсчета',
      help: 'Выделите одну из линий области детекции',
    },
  ];
  protected static polygonConfig: PolygonConfig = {
    ...CameraMaskPrototype.polygonConfig,
    fill: 'rgba(47, 128, 237, 0.08)',
    stroke: '#2F80ED',
  };

  constructor(cameraID: number, mask: DetectionMask | null = null) {
    super(cameraID, mask);
  }

  static getMaskTypeName(): string {
    return ObjectCounterCarMask.maskTypeName;
  }

  static getMaskType(): string {
    return ObjectCounterCarMask.maskType;
  }

  static getMaskObjectList(): Array<MaskObjectList> {
    return ObjectCounterCarMask.maskObjectList;
  }

  get maskTypeName(): string {
    return ObjectCounterCarMask.maskTypeName;
  }

  get maskType(): MaskTypeEnum {
    return ObjectCounterCarMask.maskType;
  }

  get maskObjectList(): Array<MaskObjectList> {
    return ObjectCounterCarMask.maskObjectList;
  }

  get polygonConfig(): PolygonConfig {
    return ObjectCounterCarMask.polygonConfig;
  }

  checkRules(): boolean {
    const superRules = super.checkRules();
    const roiPoints = this._drawMaskPoints[ObjectType.roiPoints];
    const controlLinePoints = this._drawMaskPoints[ObjectType.controlLinePoints];
    let available = false;

    if (controlLinePoints?.length === 2 && !!roiPoints?.length && roiPoints?.length >= 4) {
      for (let i = 0; i < roiPoints.length; i++) {
        const nextKey = i + 1;

        if (!roiPoints[nextKey]) {
          if (_.isEqual(roiPoints[i], controlLinePoints[0]) && _.isEqual(roiPoints[0], controlLinePoints[1])) {
            available = true;
            break;
          }
        } else if (
          _.isEqual(roiPoints[i], controlLinePoints[0]) &&
          _.isEqual(roiPoints[nextKey], controlLinePoints[1])
        ) {
          available = true;
          break;
        }
      }
    }

    return superRules && available;
  }

  get drawPoints(): TypePoints {
    if (this.currentDrawType && this.currentDrawType !== ObjectType.controlLinePoints) {
      return this._drawMaskPoints[this.currentDrawType] ?? [];
    }

    return [];
  }

  drawHandler(target: Shape | Stage): TypePoints {
    const stage = target.getStage();

    switch (this.currentDrawType) {
      case ObjectType.controlLinePoints: {
        return this.drawControlLine(target);
      }
      default: {
        return this.defaultDrawHandler(stage);
      }
    }
  }

  drawControlLine(target: Shape | Stage): TypePoints {
    const id = target.id();
    const stage = target.getStage();
    let newPoints: TypePoints = [];

    if (this.currentDrawType) {
      const drawPoints = this._drawMaskPoints?.[this.currentDrawType] ?? [];
      newPoints = [...drawPoints];
    }

    if (id.includes(ObjectType.roiPoints)) {
      const line: Line | undefined = stage?.findOne(`#${id}`);
      const points = line?.points();

      if (points) {
        newPoints = formatPoints(points);
      }
    }

    return newPoints;
  }
}

export default ObjectCounterCarMask;
