import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { roadObjectsIconMapping } from '../../iconMappers/roadObjectsIconMapping';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { DSelector } from 'services/map/Dselector/DSelector';

class TrafficLightObjectsCompositeLayers extends CompositeLayer {
  updateState({ changeFlags, props }) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof TrafficLightObjectsCompositeLayers) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }

    this.setState({
      trafficLightObjectStatus: this?.props?.relatedData?.traffic_light_object_schema_work,
    });
  }
  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.trafficLightObject;
        if (event.sourceLayer.id.includes('traffic-light-icon')) {
          selectedObjectType = MapObjects.trafficLightObject;
        }
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }
  renderLayers() {
    if (!this.props.relatedData) return [];

    return [
      new IconLayer(this.props, this.getSubLayerProps({ id: 'traffic-light-icon' }), {
        data: this.props.relatedData.traffic_light_object,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects_icon.png`,
        iconMapping: roadObjectsIconMapping,
        opacity: this?.props?.opacity ?? 0.5,
        autoHighlight: true,
        pickable: true,
        fp64: true,
        billboard: false,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        updateTriggers: {
          getIcon: this.state.selectedObject,
          getSize: this.context?.viewport?.zoom,
        },
        getIcon: (d) => {
          const { id } = d;
          const currentTrafficLight = this.state.trafficLightObjectStatus.filter((el) => el.tl_controller === id);
          const connect = currentTrafficLight[0].status === 'CONNECT';
          const notConnect = currentTrafficLight[0].status === 'NO_CONNECTION';
          const modeDefects = currentTrafficLight[0].mode === 'Неполадки';
          const modeNotErrors = currentTrafficLight[0].mode === 'Нет ошибок';
          const modeUnknown = currentTrafficLight[0].mode === 'Неизвестно';
          if (connect && modeNotErrors) {
            return this.props?.selectedObject?.type === 'traffic_light_object' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? 'traffic_light_color'
              : 'traffic_light_white';
          } else if (notConnect && modeUnknown) {
            return this.props?.selectedObject?.type === 'traffic_light_object' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? 'traffic_light_wb_color'
              : 'traffic_light_wb_white';
          } else if (connect && modeDefects) {
            return this.props?.selectedObject?.type === 'traffic_light_object' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? 'alarm_color'
              : 'alarm_white';
          }
        },
      }),
    ];
  }
}

const defaultProps = {
  // Label for each feature
  getLabel: { type: 'accessor', value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: 'accessor', value: 8 },
  // Label color for each feature
  getLabelColor: { type: 'accessor', value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: 'accessor', value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: 'pixels',
  // Label background color
  labelBackground: { type: 'color', value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: 'Monaco, monospace',
};

TrafficLightObjectsCompositeLayers.defaultProps = defaultProps;

export default TrafficLightObjectsCompositeLayers;
