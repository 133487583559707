import React from 'react';
import Icon from '@ant-design/icons';

const WidgetMenuIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3333 13.333H16.6666V16.6663H13.3333V13.333ZM8.33325 13.333H11.6666V16.6663H8.33325V13.333ZM3.33325 13.333H6.66658V16.6663H3.33325V13.333ZM13.3333 8.33301H16.6666V11.6663H13.3333V8.33301ZM8.33325 8.33301H11.6666V11.6663H8.33325V8.33301ZM3.33325 8.33301H6.66658V11.6663H3.33325V8.33301ZM13.3333 3.33301H16.6666V6.66634H13.3333V3.33301ZM8.33325 3.33301H11.6666V6.66634H8.33325V3.33301ZM3.33325 3.33301H6.66658V6.66634H3.33325V3.33301Z"
      fill="currentColor"
    />
  </svg>
);

export const WidgetMenuIcon = (props) => <Icon component={WidgetMenuIconSVG} {...props} />;
