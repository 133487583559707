export const vehiclesMonitoring = {
  Автобус: {
    x: 0,
    y: 0,
    width: 38,
    height: 32,
  },
  Автобус_selected: {
    x: 39,
    y: 66,
    width: 38,
    height: 32,
  },
  Грузовой: {
    x: 78,
    y: 0,
    width: 38,
    height: 32,
  },
  Грузовой_selected: {
    x: 78,
    y: 33,
    width: 38,
    height: 32,
  },
  Легковой: {
    x: 0,
    y: 33,
    width: 38,
    height: 32,
  },
  Легковой_selected: {
    x: 0,
    y: 66,
    width: 38,
    height: 32,
  },
  'Спец. техника': {
    x: 39,
    y: 0,
    width: 38,
    height: 32,
  },
  'Спец. техника_selected': {
    x: 39,
    y: 33,
    width: 38,
    height: 32,
  },
};
