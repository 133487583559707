export const SET_MAIN_MAP_PANEL_CONTEXT = 'SET_MAIN_MAP_PANEL_CONTEXT';
export const SET_LAYER_SETTINGS_TO_EDIT = 'SET_LAYER_SETTINGS_TO_EDIT';
export const SET_LAYER_SETTINGS_TYPE = 'SET_LAYER_SETTINGS_TYPE';
export const SET_LAYER_DATA_ACCESSOR = 'SET_LAYER_DATA_ACCESSOR';
export const SET_SHOW_CREATE_MODAL = 'SET_SHOW_CREATE_MODAL';
export const CLOSE_ALL_ADDITIONAL_PANELS = 'CLOSE_ALL_ADDITIONAL_PANELS';
export const TOGGLE_LAYER_DATA_ACCESSOR_VISIBLE = 'TOGGLE_LAYER_DATA_VISIBLE';
export const TOGGLE_MAIN_PANEL_COLLAPSE = 'SET_MAIN_PANEL_COLLAPSED';
export const TOGGLE_TIME_PLAYER_VISIBLE = 'TOGGLE_TIME_PLAYER_VISIBLE';
export const TOGGLE_LAYER_SETTINGS_VISIBILITY = 'TOGGLE_LAYER_SETTINGS_VISIBILITY';
export const TOGGLE_LAYERS_GALLERY_VISIBILITY = 'TOGGLE_LAYERS_GALLERY_VISIBILITY';
export const TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE = 'TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE';
export const TOGGLE_MAP_LEGEND_INFO_VISIBLE = 'TOGGLE_MAP_LEGEND_INFO_VISIBLE';
export const TOGGLE_VISIBLE_UNVISIBLE_FILTER = 'VISIBLE_UNVISIBLE_FILTER';
export const SET_RULER_MODE = 'SET_RULER_MODE';
export const SET_MAP_CONTEXT_MENU = 'SET_MAP_CONTEXT_MENU';
export const CHANGE_RASTER_STYLE = 'CHANGE_RASTER_STYLE';
export const TOGGLE_RASTER_ENABLED = 'TOGGLE_RASTER_ENABLED';
export const CLEAR_MAP_CONTEXT_MENU = 'CLEAR_MAP_CONTEXT_MENU';
export const TOGGLE_TOOLTIP = 'TOGGLE_TOOLTIP';
export const SET_COORD_FOR_PATH = 'SET_COORD_FOR_PATH';
export const SET_ID_AND_COORD = 'SET_ID_AND_COORD';
export const DELETE_PATH = 'DELETE_PATH';
export const SET_SELECTED_AREA_MODE = 'SET_SELECTED_AREA_MODE';
export const SET_SELECTED_AREA = 'SET_SELECTED_AREA';
export const RESET_TIMER = 'RESET_TIMER';
export const TOGGLE_WIDGETS_EDIT_PANEL = 'TOGGLE_WIDGETS_EDIT_PANEL';

function localReducerToggleTemplate(action, state, fieldName) {
  const { newStatus } = action.payload;
  if (typeof newStatus === 'boolean') {
    return {
      ...state,
      [fieldName]: newStatus,
    };
  }
  return {
    ...state,
    [fieldName]: !state[fieldName],
  };
}

const initialState = {
  layersGalleryVisible: true,
  selectedMainContext: 'layers',

  layerSettingToEdit: null,
  layerSettingType: 'changeControls',
  dataAccessor: null,

  layerSettingVisible: false,
  dataAccessorVisible: false,
  timePlayerVisible: false,
  mapLegendVisible: false,
  mapSelectedObjectInfoVisible: false,

  mainPanelCollapsed: false,
  showCreateModal: false,
  visibleUnvisibleFilter: false,

  GlobalPrintMode: false,
  selectAreaData: {
    type: 'FeatureCollection',
    features: [
      /* insert features here */
    ],
  },
  rulerMode: null,
  mapContextMenu: null, //{y: number, x: number, visible: bool}
  pathLineForTooltip: [],

  rasterStyle: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  rasterTileEnabled: false,

  widgetsEditPanel: false,
};

//GOTO mapPanelsActions.js
const mapPanels = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_CREATE_MODAL: {
      const { value } = action.payload;
      return {
        ...state,
        showCreateModal: value,
      };
    }
    case CLEAR_MAP_CONTEXT_MENU: {
      return {
        ...state,
        mapContextMenu: null,
      };
    }
    case CLOSE_ALL_ADDITIONAL_PANELS: {
      return {
        ...state,
        layerSettingVisible: false,
        dataAccessorVisible: false,
        timePlayerVisible: false,
        mapLegendVisible: false,
      };
    }
    case CHANGE_RASTER_STYLE: {
      const { tileUrl } = action.payload;
      return {
        ...state,
        rasterStyle: tileUrl,
      };
    }
    case RESET_TIMER: {
      return {
        ...state,
        timePlayerVisible: false,
      };
    }
    case TOGGLE_RASTER_ENABLED: {
      return {
        ...state,
        rasterTileEnabled: !state.rasterTileEnabled,
      };
    }
    case SET_MAIN_MAP_PANEL_CONTEXT: {
      return {
        ...state,
        selectedMainContext: action.payload,
        layerSettingVisible: false,
        layersGalleryVisible: false,
      };
    }
    case SET_RULER_MODE: {
      const { mode } = action.payload;
      if (state.rulerMode === mode) {
        return {
          ...state,
          rulerMode: null,
        };
      } else {
        return {
          ...state,
          rulerMode: mode,
        };
      }
    }
    case SET_SELECTED_AREA_MODE: {
      const { mode } = action.payload;
      if (mode) {
        return {
          ...state,
          GlobalPrintMode: true,
        };
      } else {
        return {
          ...state,
          GlobalPrintMode: false,
          selectAreaData: initialState.selectAreaData,
        };
      }
    }
    case SET_SELECTED_AREA: {
      const { data } = action.payload;
      return {
        ...state,
        selectAreaData: data,
      };
    }

    case SET_MAP_CONTEXT_MENU: {
      if (!action.payload) {
        return {
          ...state,
          mapContextMenu: null,
        };
      } else {
        const { x, y, visible, coordinate, clickedObjectName, selected, objectLayerName } = action.payload;
        return {
          ...state,
          mapContextMenu: {
            x,
            y,
            visible,
            coordinate,
            clickedObjectName,
            selected,
            objectLayerName,
          },
        };
      }
    }

    case SET_COORD_FOR_PATH: {
      const { dataCollection, id, coordEndTooltip, coordStartTooltip } = action.payload;
      const { geometry } = dataCollection;
      geometry.coordinates.push(coordStartTooltip, coordEndTooltip);

      if (state.pathLineForTooltip.length === 0) {
        return {
          ...state,
          pathLineForTooltip: [{ geometry, id }],
        };
      }

      for (let item of state.pathLineForTooltip) {
        if (item.id === id) {
          item.geometry.coordinates = [coordStartTooltip, coordEndTooltip];
          return {
            ...state,
            pathLineForTooltip: [...state.pathLineForTooltip],
          };
        }
      }
      for (let item of state.pathLineForTooltip) {
        if (item.id !== id) {
          return {
            ...state,
            pathLineForTooltip: [...state.pathLineForTooltip, { geometry, id }],
          };
        }
      }
      break;
    }
    case DELETE_PATH: {
      return Object.assign({}, state, {
        pathLineForTooltip: state.pathLineForTooltip.filter((item) => item.id !== action.payload),
      });
    }
    case SET_LAYER_SETTINGS_TYPE: {
      return {
        ...state,
        layerSettingType: action.payload,
      };
    }
    case SET_LAYER_DATA_ACCESSOR: {
      return {
        ...state,
        dataAccessor: action.payload,
      };
    }
    case SET_LAYER_SETTINGS_TO_EDIT: {
      return {
        ...state,
        layerSettingToEdit: action.payload,
      };
    }
    case TOGGLE_LAYERS_GALLERY_VISIBILITY: {
      return localReducerToggleTemplate(action, state, 'layersGalleryVisible');
    }
    case TOGGLE_TIME_PLAYER_VISIBLE: {
      return localReducerToggleTemplate(action, state, 'timePlayerVisible');
    }
    case TOGGLE_MAP_LEGEND_INFO_VISIBLE: {
      return localReducerToggleTemplate(action, state, 'mapLegendVisible');
    }
    case TOGGLE_LAYER_SETTINGS_VISIBILITY: {
      return localReducerToggleTemplate(action, state, 'layerSettingVisible');
    }
    case TOGGLE_MAIN_PANEL_COLLAPSE: {
      return localReducerToggleTemplate(action, state, 'mainPanelCollapsed');
    }
    case TOGGLE_LAYER_DATA_ACCESSOR_VISIBLE: {
      return localReducerToggleTemplate(action, state, 'dataAccessorVisible');
    }
    case TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE: {
      return localReducerToggleTemplate(action, state, 'mapSelectedObjectInfoVisible');
    }
    case TOGGLE_VISIBLE_UNVISIBLE_FILTER: {
      return localReducerToggleTemplate(action, state, 'visibleUnvisibleFilter');
    }
    case TOGGLE_WIDGETS_EDIT_PANEL: {
      return localReducerToggleTemplate(action, state, 'widgetsEditPanel');
    }
    default:
      return state;
  }
};

export default mapPanels;
