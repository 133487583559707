import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { directoryHistoryActions } from '../../../../store/reducers/directory/actions/directoryHistoryActions';
import { getLastHistoryRecord } from '../../../../store/reducers/directory/directoryHistory';
import { Button } from '../../../common/components/Button/Button';
import { PlusIcon } from '../../icons/actionIcons/PlusIcon';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { ButtonType } from '../../../common/components/Button/model/Button.model';

const { postToInlineHistory } = directoryHistoryActions;

const CreateButton = (props) => {
  const { CRUDHandlers, moveToPageCreating, tooltip, theme } = props;
  const iconSize = useRef({ width: '20px', height: '20px' });

  return (
    <Button
      onClick={() => (CRUDHandlers ? CRUDHandlers.createObject() : moveToPageCreating())}
      icon={<PlusIcon />}
      rounded
      hasShadow
      type={ButtonType.primary}
      iconColor={theme.colors.defaultLighter}
      iconSize={iconSize.current}
      tooltip={tooltip}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushInlineHistory: (historyRecord) => dispatch(postToInlineHistory(historyRecord)),
  };
};

const mapStateToProps = (state) => {
  return {
    lastInlineHistory: getLastHistoryRecord(state),
  };
};

export default compose(withTheme, connect(mapStateToProps, mapDispatchToProps))(CreateButton);
