import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';
import { baseStyles } from 'components/stylesRegistrator';

const { BaseMapPanelPrimaryTitle } = baseStyles.modules.map;

const CardText = styled(BaseMapPanelPrimaryTitle)``;

const CardButton = styled(Button)`
  padding: 4px;
  border-radius: 3px;
  background: ${(props) => props.theme.colors.neutralDarker};
  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const Styled = {
  CardButton,
  CardText,
};
