import React from 'react';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import _ from 'underscore';
import { RoadWaysItemSelectionProps } from './RoadSchemeItemSelection.model';
import { AnyObject } from 'types/enums/general/general.model';

const RoadWaysItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: RoadWaysItemSelectionProps) => {
  const handleOpenObjectInNewTab = () => {
    const { id } = data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadway}/${id}/`, '_blank');
    win?.focus();
  };

  const segmentsModel: AnyObject = selectedMapItem?.data?.[0]?.model?.scheme ?? {};
  const availableFields = [
    'roadway_type',
    'material',
    'num_lanes',
    'width',
    'length',
    'street_name',
    'comment',
    'exploitative_kilometer',
  ];

  const unionRoadsSegmentsModel = _.pick(segmentsModel, ...availableFields);

  // добавит в Descriptions все переданные бэком поля отмеченные read_only: false
  const descriptions = Object.keys(unionRoadsSegmentsModel).map((key) => {
    const dataClone: AnyObject = { ...data };
    const description = unionRoadsSegmentsModel[key]?.choices
      ? unionRoadsSegmentsModel[key]?.choices?.find((el: AnyObject) => el.value === dataClone[key])?.display_name ??
        'Данные отсутствуют'
      : dataClone[key] ?? 'Данные отсутствуют';
    return {
      key: unionRoadsSegmentsModel[key]?.label,
      value: description,
    };
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о проезжей части'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

RoadWaysItemSelection.propTypes = {};

export default RoadWaysItemSelection;
