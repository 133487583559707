import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { getFromURL } from 'api/getFromURL';
import { postToURLSingle } from 'api/postToURLSingle';
import moment from 'moment-timezone';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import _ from 'underscore';

import { CargoTransportContext } from '../CargoTransportRouter';
import { Buttons } from './ActionButtons/Buttons';
import ApplicationForm from './ApplicationForm/ApplicationForm';
import {
  CargoTransportContextModel,
  IIdBlanks,
  CargoTransportParams,
  IVehicleParameters,
} from './interfacesCargoTransport/interfacesCargoTransport';
import { emptyVehicleParameter } from './interfacesCargoTransport/templates';
import { showConfirm } from './methods/showConfirm';
import { Styled } from './ObjectPanel.styled';
import ObjectSider from './Sider/ObjectPanelSider';
import { TabPageContainer } from './TabPageContainer';
import ScrollableBoxArea from 'UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';
import { AnyObject } from 'types/enums/general/general.model';
import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { MainDataFederalStatementCustomView } from './utils/mainDataFederalStatementCustomView/MainDataFederalStatementCustomView';

const {
  PanelLayout,
  ScrollableBoxAreaContainer,
  StyledDrawer,
  SelectContainer,
  OptionContainer,
  ContainerEstimationButton,
} = Styled;

interface IObjectPanelProps {
  urlModel: string;
  initClose: () => void;
}

const ObjectPanel = React.memo((props: PropsWithChildren<IObjectPanelProps>) => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { directory, id, statement, mode, scoreId, scoreMode } = params;
  const dispatch = useDispatch();
  const history = useHistory<any>();
  const location = useLocation();
  const { urlModel, initClose } = props;
  const [data, setData] = useState<any[]>([]);
  const [responsiblePersonsData, setResponsiblePersonsData] = useState<AnyObject[]>([]);
  const [mainVehicleParametersData, setMainVehicleParametersData] = useState<IVehicleParameters[]>([
    { ...emptyVehicleParameter },
  ]);
  const [model, setModel] = useState<ModelResponse>();
  const [url, setUrl] = useState<string>(urlModel);
  const [idSignDocument, setIdSignDocument] = useState<number>(0);
  const [dateTime, setDateTime] = useState<AnyObject | null>({});
  const [eventData, setEventData] = useState<AnyObject | null>({});
  const [isActiveMenu, setIsActiveMenu] = useState<boolean>(false);
  const [recommendedHarmCalc, setRecommendedHarmCalc] = useState<number | null>(null);
  const { setCount, idBlanks, setIdBlanks, mainData, setMainData, pageMenuKey, setPageMenuKey } =
    useContext<CargoTransportContextModel>(CargoTransportContext);
  const [isActiveCustomFederalStatementView, setIsActiveCustomFederalStatementView] = useState<boolean>(
    pageMenuKey === IOPWDirectory.main
  );

  const { id: orderId = null, uin = null } = eventData ?? {};

  const notificationAlert = useCallback(
    (e) => {
      let messages = ['Неизвестная ошибка обратитесь к администратору'];
      if (e?.response && _.isObject(e.response)) {
        messages = createAlertMessage(e);
      }
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: messages,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
    },
    [dispatch]
  );

  const refreshData = useCallback(async () => {
    try {
      dispatch({ type: 'APPLICATIONS.START_FETCHING' });
      let urlData: string | null = url;
      let currentUrlModel: string = url;
      if (mode || pageMenuKey === IOPWDirectory.main || scoreMode === pageMenuKey) {
        if (mainData && !_.isEmpty(mainData) && IOPWDirectory.destruction_acts) {
          setData(mainData);
          urlData = null;
        } else if (scoreMode === IOPWDirectory.billings && !scoreId) {
          urlData = null;
          const responsePaymentTypes = getFromURL.getAll(
            `/${IOPWDirectory.special_permits}/${IOPWDirectory.payment_types}/`
          );
          const responseCalcHarm = getFromURL.getAll(
            `/${IOPWDirectory.special_permits}/${directory}/calc_of_harm/?statement=${id ?? statement}`
          );
          const donePaymentTypes = await responsePaymentTypes;
          const doneCalcHarm = await responseCalcHarm;
          setData([...donePaymentTypes?.results, ...doneCalcHarm?.results]);
        } else if (scoreMode === IOPWDirectory.calc_of_harm && !scoreId) {
          urlData = `/${IOPWDirectory.special_permits}/${directory}/${DirectoryRouteModel.roads}/?statement=${statement}`;
        } else if (directory === IOPWDirectory.individual_statements || directory === IOPWDirectory.entity_statements) {
          const responsiblePersons = await getFromURL.getAll(
            `/${DirectoryRouteModel.users}/${IOPWDirectory.responsible_persons}/`
          );
          setResponsiblePersonsData(responsiblePersons.results);
          if (mode) {
            urlData = null;
          }
        }
      } else if (pageMenuKey === IOPWDirectory.forms_account) {
        urlData = `/${IOPWDirectory.special_permits}/${pageMenuKey}/?${directory.substring(
          0,
          directory.length - 1
        )}=${id}`;
      } else if (pageMenuKey === IOPWDirectory.history_change_statement) {
        urlData = `/${IOPWDirectory.special_permits}/${directory}/?history=true&id=${id}`;
        currentUrlModel = `/${IOPWDirectory.special_permits}/${directory}/?history=true&id=${id}`;
      } else {
        let idStatement = id ?? statement;
        let filterselector = 'statement';
        if (scoreId && scoreMode === IOPWDirectory.vehicle_parameters) {
          idStatement = scoreId;
          filterselector = scoreMode;
        } else if (scoreId && scoreMode === IOPWDirectory.vehicles) {
          idStatement = scoreId;
          filterselector = IOPWDirectory.vehicle_parameters;
        }
        urlData = `/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/?${filterselector}=${idStatement}`;
        currentUrlModel = `/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/`;
      }
      const responseModel = getFromURL.getModelFromURL(currentUrlModel);
      if (urlData) {
        const responseData = getFromURL.getAll(urlData);
        const doneResponseData = await responseData;
        if (doneResponseData?.vehicle_parameters?.length) {
          setMainVehicleParametersData(doneResponseData?.vehicle_parameters);
        }
        setData(doneResponseData?.results ?? doneResponseData);
      }
      setModel(await responseModel);
      setIsActiveMenu(!!id || !!scoreId);
    } catch (e) {
      notificationAlert(e);
    } finally {
      dispatch({ type: 'APPLICATIONS.END_FETCHING' });
    }
  }, [dispatch, url, mode, pageMenuKey, scoreMode, id, scoreId, mainData, directory, statement, notificationAlert]);

  useEffect(() => {
    refreshData();
  }, [pageMenuKey, mode, id, url, refreshData]);

  useEffect(() => {
    if (pageMenuKey && orderId) {
      setCount(uin);
      history.push(`${location.pathname}${pageMenuKey}/${orderId}/`);
    }
  }, [history, location.pathname, orderId, pageMenuKey, setCount, uin]);

  const formRef = useRef<any>(null);

  const initSubmitForm = useCallback(() => {
    formRef.current.submit();
  }, []);
  const setReturnResponse = useCallback(
    (response) => {
      if (response && response.id && !id && !scoreId) {
        history.push(
          location.pathname.includes('create')
            ? `${location.pathname.replace('create', response.id)}`
            : `${location.pathname}${response.id}/`
        );
      } else {
        if (response.vehicle_parameters?.length) {
          setMainVehicleParametersData(response.vehicle_parameters);
        }
        setData(response);
      }
    },
    [history, id, location.pathname, scoreId]
  );

  const handleSubmitForm = useCallback(
    (submitData: AnyObject) => {
      if (submitData && model) {
        const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const { scheme } = model;
        Object.keys(submitData).forEach((dateKey: string) => {
          const key = Object.keys(scheme).find((schemeKey) => schemeKey === dateKey)!;
          const type = scheme[key]?.type;
          if (type === 'datetime') {
            submitData[dateKey] = moment.tz(submitData[dateKey], userTZ).format('YYYY-MM-DD HH:mm:ssZ');
          } else if (type === 'date') {
            submitData[dateKey] = moment(submitData[dateKey]).format('YYYY-MM-DD');
          }
        });
      }

      Object.keys(submitData).forEach((key) => {
        if (
          key.includes('inn') ||
          key.includes('snils') ||
          key.includes('phone') ||
          key.includes('kpp') ||
          key.includes('ogrn')
        ) {
          submitData[key] = submitData[key]?.replace(/[^0-9.]/g, '');
        }
      });
      const newSubmitData = { ...submitData, ...dateTime };

      // Добавление параметров ТС у физиков и юриков
      if (
        (directory === IOPWDirectory.individual_statements || directory === IOPWDirectory.entity_statements) &&
        !scoreMode
      ) {
        newSubmitData.vehicle_parameters = mainVehicleParametersData;
      }

      // Добавление бланков в отправляемые данные
      if (
        (scoreMode === IOPWDirectory.destruction_acts || scoreMode === IOPWDirectory.write_off_acts) &&
        idBlanks?.length
      ) {
        newSubmitData.forms = idBlanks.map((blank: IIdBlanks) => blank.id);
      }

      // Редактирование готовых данных
      if ((id && !scoreMode) || (scoreMode && scoreId)) {
        newSubmitData.id = scoreId ?? id;
      }

      switch (scoreMode) {
        case IOPWDirectory.forms_account:
          dispatch(
            submitObjectSagaErrorResponse({
              url: `/${IOPWDirectory.special_permits}/forms_account/`,
              data: newSubmitData,
              setReturnResponse: (res) => setIsActiveMenu(!!res),
            })
          );
          break;
        case IOPWDirectory.destruction_acts:
        case IOPWDirectory.write_off_acts:
          if (idBlanks?.length) {
            dispatch(submitObjectSagaErrorResponse({ url, data: newSubmitData, initial: data, setReturnResponse }));
            showConfirm({ newSubmitData, scoreMode, mainData, setMainData, setData, history, location });
          } else {
            initClose();
          }
          break;
        case IOPWDirectory.approvals:
          dispatch(submitObjectSagaErrorResponse({ url, data: newSubmitData, initial: data, setReturnResponse }));
          break;
        default:
          if (formRef.current?.estimation) {
            (async () => {
              try {
                const response = await postToURLSingle.postOrPutDataErrorResponse(
                  `/${IOPWDirectory.special_permits}/${directory}/${IOPWDirectory.calc_of_harm}/calc/`,
                  newSubmitData
                );
                setRecommendedHarmCalc(response);
              } catch (e) {
                notificationAlert(e);
              }
            })();
          }
          dispatch(submitObjectSagaErrorResponse({ url, data: newSubmitData, initial: data, setReturnResponse }));
          if (idBlanks?.length) setIdBlanks([]);
          break;
      }
    },
    [
      dateTime,
      directory,
      scoreMode,
      idBlanks,
      id,
      scoreId,
      model,
      mainVehicleParametersData,
      notificationAlert,
      dispatch,
      url,
      data,
      setReturnResponse,
      mainData,
      setMainData,
      history,
      location,
      initClose,
      setIdBlanks,
    ]
  );

  const moveToPageCreatingNewAccount = useCallback(() => {
    if (pageMenuKey) setCount(IOPWTabMenuModel[pageMenuKey]);
    history.push(`${location.pathname}${pageMenuKey}/`);
  }, [history, location.pathname, pageMenuKey, setCount]);

  const selectIdAGGrid = (selectId: number) => setIdSignDocument(selectId);

  const buttons = useMemo(() => {
    let idStatement;
    if (id) idStatement = +id;
    if (statement) idStatement = +statement;
    return (
      <>
        <Buttons
          pageMenuKey={pageMenuKey}
          idSignDocument={idSignDocument}
          moveToPageCreatingNewAccount={moveToPageCreatingNewAccount}
          refreshData={refreshData}
          id={idStatement}
          data={data}
          model={model}
          directory={directory}
        />
      </>
    );
  }, [pageMenuKey, idSignDocument, moveToPageCreatingNewAccount, refreshData, id, statement, data, model, directory]);

  const estimation = useMemo(() => {
    if (scoreMode === IOPWDirectory.calc_of_harm && !scoreId) {
      return (
        <ContainerEstimationButton
          onClick={() => {
            formRef.current.estimation = true;
            initSubmitForm();
          }}
          type={'primary'}
          tooltip={'Произвести предварительный расчет вреда'}
        >
          Предварительный расчет
        </ContainerEstimationButton>
      );
    } else if (
      (scoreMode === IOPWDirectory.write_off_acts || scoreMode === IOPWDirectory.destruction_acts) &&
      idBlanks?.length
    ) {
      const children = [<OptionContainer key={null} />];
      const visibleChildren = Object.keys(idBlanks).map((key: any) => `Бланк №${idBlanks[key].number}`);
      return (
        <SelectContainer mode="multiple" defaultValue={visibleChildren} disabled={true}>
          {children}
        </SelectContainer>
      );
    } else return null;
  }, [idBlanks, initSubmitForm, scoreId, scoreMode]);

  const ScrollableBoxAreaContent = useMemo(() => {
    if (!model) return null;

    if (isActiveCustomFederalStatementView && directory === IOPWDirectory.federal_statements && !scoreMode) {
      return (
        <MainDataFederalStatementCustomView
          model={model}
          data={data}
          notificationAlert={notificationAlert}
          urlModel={urlModel}
        />
      );
    }

    const menuSecondNesting =
      (scoreMode && pageMenuKey !== IOPWDirectory.vehicle_axes && pageMenuKey !== IOPWDirectory.vehicle_axis) ||
      pageMenuKey === IOPWDirectory.main;
    return menuSecondNesting ? (
      <ApplicationForm
        params={params}
        initClose={initClose}
        handleSubmitForm={handleSubmitForm}
        model={model}
        data={data}
        ref={formRef}
        additionalRenders={estimation}
        recommendedHarmCalc={recommendedHarmCalc}
        idBlanks={idBlanks}
        responsiblePersonsData={responsiblePersonsData}
        form={{
          showTime: true,
          dateTime,
          setDateTime,
          formRef,
          mainVehicleParametersData,
          setMainVehicleParametersData,
        }}
      />
    ) : (
      <TabPageContainer
        pageMenuKey={pageMenuKey}
        setEventData={setEventData}
        data={data}
        model={model}
        buttons={buttons}
        selectIdAGGrid={selectIdAGGrid}
        refreshData={refreshData}
      />
    );
  }, [
    model,
    isActiveCustomFederalStatementView,
    directory,
    scoreMode,
    pageMenuKey,
    params,
    initClose,
    handleSubmitForm,
    data,
    estimation,
    recommendedHarmCalc,
    idBlanks,
    responsiblePersonsData,
    dateTime,
    mainVehicleParametersData,
    buttons,
    refreshData,
    notificationAlert,
    urlModel,
  ]);

  const areaBackground = useMemo(() => {
    if (
      pageMenuKey !== IOPWDirectory.main &&
      !isActiveCustomFederalStatementView &&
      (!scoreMode || pageMenuKey === IOPWDirectory.vehicle_axes || pageMenuKey === IOPWDirectory.vehicle_axis)
    ) {
      return 'transparent';
    }
    return;
  }, [isActiveCustomFederalStatementView, pageMenuKey, scoreMode]);

  return (
    <StyledDrawer>
      <PanelLayout>
        <ObjectSider
          params={params}
          initClose={initClose}
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          ref={formRef}
          pageMenuKey={pageMenuKey}
          isActiveMenu={isActiveMenu}
          setData={setData}
          setIdSignDocument={setIdSignDocument}
          setUrl={setUrl}
          setPageMenuKey={setPageMenuKey}
          urlModel={urlModel}
          data={data}
          setIsActiveCustomFederalStatementView={setIsActiveCustomFederalStatementView}
          isActiveCustomFederalStatementView={isActiveCustomFederalStatementView}
        />
        <ScrollableBoxArea areaBackground={areaBackground}>
          <ScrollableBoxAreaContainer>{ScrollableBoxAreaContent}</ScrollableBoxAreaContainer>
        </ScrollableBoxArea>
      </PanelLayout>
    </StyledDrawer>
  );
});

export default ObjectPanel;
