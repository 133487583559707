import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const ProjectLayerItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'road_signs');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.projectLayer}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Тип',
    value: data.road_sign_type_name,
  });
  Descriptions.push({
    key: 'Примечание',
    value: data.comment ? data.comment : '-',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о дорожном знаке'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default ProjectLayerItemSelection;
