import React from 'react';

import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { FormGeneratedField } from 'types/interfaces/forms/FormGeneratedField.model';

import ModalMapInput from 'UI/common/form/inputs/ModalMapInput/ModalMapInput';
import DateTimeInput from 'UI/common/form/inputs/DateTimeInput';
import TimeInput from 'UI/common/form/inputs/TimeInput';
import CustomMaskedInput from 'UI/common/form/inputs/CustomMaskedInput';
import SNILSValidator from 'UI/common/form/validators/SNILSValidator';
import PhoneValidator from 'UI/common/form/validators/PhoneValidator';
import StringInput from 'UI/common/form/inputs/StringInput';
import BooleanInput from 'UI/common/form/inputs/BooleanInput';
import INNValidator from 'UI/common/form/validators/INNValidator';
import KPPValidator from 'UI/common/form/validators/KPPValidator';
import OGRNValidator from 'UI/common/form/validators/OGRNValidator';
import ChoiceInput from 'UI/common/form/inputs/ChoiceInput';
import RelatedFieldInput from 'UI/common/form/inputs/RelatedFieldInput';
import CreatableRelatedFieldInput from 'UI/common/form/inputs/CreatableRelatedFieldInput';
import StatementRelatedFieldInput from 'UI/common/form/inputs/StatementRelatedFieldInput';
import CustomINNInputStatement from 'UI/common/form/inputs/CustomINNInputStatement';
import { AnyObject } from 'types/enums/general/general.model';
import { IFormProps } from '../interfacesCargoTransport/interfacesCargoTransport';
import { Styled } from './provideInput.styled';
import IntegerInput from 'UI/common/form/inputs/IntegerInput';
import { VehicleParametersField } from './inputsVehicaleParameters/VehicleParametersField';

const { StyledWrapper, StyledWrapperTextArea, StyledWrapperTellTextArea, StyledVehicleParametersContainer } = Styled;

/**
 *
 * @param object single instance of object. For initial values
 * @param description description of field. Main ones are .type and .help_text on which fields are created
 * @param field key of field
 * @param formProps link to Antd.Form
 * @returns {React.Component} must provide props description={description} object={object} field={field} formProps={formProps}
 */

export const provideInput = (
  object: AnyObject,
  description: FormGeneratedField,
  field: string,
  formProps: IFormProps
) => {
  switch (description?.type) {
    case SchemaFieldType.datetime:
    case SchemaFieldType.date: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <DateTimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.time: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <TimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.integer: {
      if (field === 'id') return null;
      if (description.help_text === 'hidden') {
        return (
          <StyledWrapper key={`input-${field}_container`}>
            <IntegerInput
              key={`input-${field}`}
              description={description}
              step={1}
              object={object}
              field={field}
              className={'hidden'}
            />
          </StyledWrapper>
        );
      } else {
        return (
          <StyledWrapper key={`input-${field}_container`}>
            <IntegerInput key={`input-${field}`} description={description} step={1} object={object} field={field} />
          </StyledWrapper>
        );
      }
    }
    case SchemaFieldType.float: {
      if (description.help_text === 'hidden') {
        return (
          <StyledWrapper key={`input-${field}_container`}>
            <IntegerInput
              key={`input-${field}`}
              description={description}
              step={0.1}
              object={object}
              field={field}
              className={'hidden'}
            />
          </StyledWrapper>
        );
      } else {
        return (
          <StyledWrapper key={`input-${field}_container`}>
            <IntegerInput key={`input-${field}`} description={description} step={1} object={object} field={field} />
          </StyledWrapper>
        );
      }
    }
    case SchemaFieldType.snils: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomMaskedInput
            mask="000-000-000 00"
            validator={SNILSValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.phone: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomMaskedInput
            mask="+0 (000) 000-00-00"
            validator={PhoneValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.string_long_field: {
      return (
        <StyledWrapperTextArea>
          <StringInput key={`input-${field}`} description={description} object={object} field={field} />
        </StyledWrapperTextArea>
      );
    }
    case SchemaFieldType.string_text_area_field: {
      return (
        <StyledWrapperTellTextArea>
          <StringInput key={`input-${field}`} description={description} object={object} field={field} />
        </StyledWrapperTellTextArea>
      );
    }
    case SchemaFieldType.string:
    case SchemaFieldType.url:
    case SchemaFieldType.email:
    case SchemaFieldType.transportRegistrationNumber: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <StringInput key={`input-${field}`} description={description} object={object} field={field} />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.year:
    case SchemaFieldType.boolean: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <BooleanInput key={`input-${field}`} description={description} object={object} field={field} />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.inn: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomMaskedInput
            mask="000000000000"
            validator={INNValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.inn_statement: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomINNInputStatement
            mask="111111111111"
            validator={INNValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.kpp: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomMaskedInput
            mask="000000000"
            validator={KPPValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.ogrn: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomMaskedInput
            mask="000000000000000"
            validator={OGRNValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.okpo: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomMaskedInput
            mask="00000000"
            validator={() => true}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.okved: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CustomMaskedInput
            mask="000000"
            validator={() => true}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.field: {
      if (field.includes('_detail')) return null;
      if (field.includes('_name')) return null;
      return null;
    }
    case SchemaFieldType.choice: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <ChoiceInput
            key={`input-${field}`}
            description={description}
            choices={description.choices}
            object={object}
            field={field}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.stringRelatedField:
    case SchemaFieldType.relatedField: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <RelatedFieldInput key={`input-${field}`} description={description} object={object} field={field} />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.statement_related_field: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <StatementRelatedFieldInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.creatableRelatedField: {
      return (
        <StyledWrapper key={`input-${field}_container`}>
          <CreatableRelatedFieldInput key={`input-${field}`} description={description} object={object} field={field} />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.vehicle_parameters: {
      return (
        <StyledVehicleParametersContainer key={`input-${field}_container`}>
          <VehicleParametersField description={description} object={object} field={field} formProps={formProps} />
        </StyledVehicleParametersContainer>
      );
    }
    case SchemaFieldType.inline: {
      return null;
    }
    case SchemaFieldType.polygon:
    case SchemaFieldType.lineString:
    case SchemaFieldType.point: {
      return (
        <StyledWrapper>
          <ModalMapInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    default: {
      return null;
    }
  }
};
