import React, { useCallback, useMemo } from 'react';

import { Dropdown, Menu } from 'antd';

import { ChoicesModel, FormGeneratedField } from 'types/interfaces/forms/FormGeneratedField.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import TextIconButton from 'UI/custom/actionsButtons/common/TextIconButton';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { PlusIcon } from 'UI/custom/icons/actionIcons/PlusIcon';
import _ from 'underscore';

import { IVehicleParameters } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { emptyVehicleParameter } from '../../interfacesCargoTransport/templates';
import { Styled } from './VehicleParametersField.styled';

const { VehicleButtonContainer, TabContainer, CreatePVButtonContainer, DropdownStyledContainer, HeaderDropdownMenu } =
  Styled;

const iconSizeButton = { width: '20px', height: '20px' };

interface VehicleParametersFieldProps {
  description: FormGeneratedField;
  formProps: {
    mainVehicleParametersData: IVehicleParameters[];
    setMainVehicleParametersData: (data: IVehicleParameters[]) => void;
  };
  activeVPIndex: number;
  setActiveVPIndex: (props: number) => void;
}

export const TabButtonsVehicle = ({
  description,
  formProps,
  activeVPIndex,
  setActiveVPIndex,
}: VehicleParametersFieldProps) => {
  const { mainVehicleParametersData, setMainVehicleParametersData } = formProps;

  const chooseVehicleType = useCallback(
    (key, index) => {
      const newVPData = [...mainVehicleParametersData];
      newVPData[index].vehicle_type = key;
      setMainVehicleParametersData(newVPData);
    },
    [mainVehicleParametersData, setMainVehicleParametersData]
  );

  const vehicleTypeChoices = useMemo(() => description.child?.children?.vehicle_type?.choices, [description]);

  const typeVehicleMenu = useCallback(
    (index) => {
      return (
        <Menu onClick={(e) => chooseVehicleType(e.key, index)}>
          <HeaderDropdownMenu>Тип транспорта</HeaderDropdownMenu>
          {vehicleTypeChoices?.map((choice: ChoicesModel) => (
            <Menu.Item key={choice.value}>{choice.display_name}</Menu.Item>
          ))}
        </Menu>
      );
    },
    [chooseVehicleType, vehicleTypeChoices]
  );

  const getDisplayName = useCallback(
    (el) => vehicleTypeChoices.find((choice: ChoicesModel) => choice.value === el.vehicle_type)?.display_name,
    [vehicleTypeChoices]
  );

  const clickSelectVehicalButton = useCallback(
    (index: number, e: any) => {
      e.preventDefault();
      setActiveVPIndex(index);
    },
    [setActiveVPIndex]
  );

  const clickDeleteButton = useCallback(
    (index: number, e: any) => {
      e.preventDefault();
      if (activeVPIndex === index) setActiveVPIndex(0);
      setMainVehicleParametersData(_.without(mainVehicleParametersData, mainVehicleParametersData[index]));
    },
    [activeVPIndex, mainVehicleParametersData, setActiveVPIndex, setMainVehicleParametersData]
  );

  const clickCreateNewVehicalButton = useCallback(
    (e: any) => {
      e.preventDefault();
      setMainVehicleParametersData([...mainVehicleParametersData, { ...emptyVehicleParameter }]);
    },
    [mainVehicleParametersData, setMainVehicleParametersData]
  );

  const vehicleButtons = useMemo(
    () =>
      mainVehicleParametersData.map((el, index) => (
        <VehicleButtonContainer
          key={`mainVehicleParametersData_${el.id}`}
          className={index === activeVPIndex ? 'is_activ_button_container' : ''}
        >
          <TextIconButton
            clickButton={clickSelectVehicalButton.bind(this, index)}
            tooltip={getDisplayName(el) ?? null}
            isDisabled={false}
            labelText={getDisplayName(el) ?? 'Выберите тип ТС'}
          />
          <Button iconSize={iconSizeButton} icon={<DeleteIcon />} onClick={clickDeleteButton.bind(this, index)} />
          <DropdownStyledContainer>
            <Dropdown.Button overlay={typeVehicleMenu.bind(this, index)} trigger={['click']} />
          </DropdownStyledContainer>
        </VehicleButtonContainer>
      )),
    [
      activeVPIndex,
      clickDeleteButton,
      clickSelectVehicalButton,
      getDisplayName,
      mainVehicleParametersData,
      typeVehicleMenu,
    ]
  );

  return (
    <>
      <TabContainer>
        {vehicleButtons}
        <CreatePVButtonContainer>
          <TextIconButton
            clickButton={clickCreateNewVehicalButton.bind(this)}
            tooltip={'Добавить ТС'}
            isDisabled={false}
            labelText={'Добавить ТС'}
            type={ButtonType.primary}
          />
          <PlusIcon />
        </CreatePVButtonContainer>
      </TabContainer>
    </>
  );
};
