import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';

import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Segments extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'segments',
      filterLabel: 'Участки дороги',
      selectedObjectName: MapObjects.roadSegment,
    });
  }

  async uploadData() {
    return await RoadsService.roadsSegmentsList();
  }

  async uploadFullData() {
    return await RoadsService.roadsSegmentsList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsSegmentsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsSegmentsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadsSegments);
  }

  async uploadModelForAddingIntoOverpasses() {
    return await getFromURL.getModelFromURL('/artificial_structures/segments_overpasses/');
  }
}
