import React, { useCallback } from 'react';
import { LayerSettings } from './LayerSettings';
import { useDispatch, useSelector } from 'react-redux';
import { revertToDefaultLayerSettings } from 'store/reducers/map/actions/mapboxLayersActions';
import { toggleLayerSettingsVisibility } from 'store/reducers/map/actions/mapPanelsActions';
import { getSelectedMapboxLayer } from 'store/reducers/map/mapboxLayers';
import { StateModel } from 'store/reducers';

export const LayerSettingsContainer = () => {
  const dispatch = useDispatch();
  const toggleLayerSettingsVisibilityHandle = useCallback(() => dispatch(toggleLayerSettingsVisibility()), [dispatch]);
  const revertToDefaultLayerSettingsHandle = useCallback(
    (keyOfLayer: string) => dispatch(revertToDefaultLayerSettings(keyOfLayer)),
    [dispatch]
  );

  const selectedLayer = useSelector((state: StateModel) => getSelectedMapboxLayer(state));

  return (
    <>
      <LayerSettings
        item={selectedLayer[0]}
        toggleLayerSettingsVisibility={toggleLayerSettingsVisibilityHandle}
        revertToDefaultLayerSettings={revertToDefaultLayerSettingsHandle}
      />
    </>
  );
};
