import React from 'react';
import { Styled } from './WelcomePage.styled';
import { withTheme } from 'styled-components';

const { LocalMainWrapper, LocalMainTitle, LocalMainDescription } = Styled;

const WelcomePage = () => {
  return (
    <LocalMainWrapper>
      <LocalMainTitle>+7 (3452) 217-199</LocalMainTitle>
      <LocalMainDescription>Техническая поддержка. Добавочный номер "1".</LocalMainDescription>
    </LocalMainWrapper>
  );
};

export default withTheme(WelcomePage);
