import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Styled } from '../../../BaseModules/Directory/DirectoryFolder/DirectoryCardsFolder/DirectoryCards.styled';
import { links } from './LinksApplications';

import _ from 'underscore';
import { arrayGroupBy } from 'services/data/arrayGroupBy';
import { StarIcon } from 'UI/custom/icons/actionIcons/StarIcon';
import { OptionIcon } from 'UI/custom/icons/actionIcons/OptionIcon';
import { HeaderScipBlock } from 'components/Application/Common/HeaderScipBlock/HeaderScipBlock';
import ScrollableBoxArea from 'UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';
import { CollapsableItem } from 'UI/common/components/CollapsableItem/CollapsableItem';
import { ILinksCT } from '../ApplicationsForm/interfacesApplications/interfacesApplications';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { useSelector } from 'react-redux';
import { StateModel } from '../../../../../store/reducers';

const {
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardWrapper,
  LocalCardStar,
  LocalCardPoint,
  LocalMainWrapper,
  LocalCardDescr,
  LocalCardsWrapper,
  ExtendedCollapseItem,
  CardsGroupComtainer,
} = Styled;

const ApplicationsFolder = () => {
  const userDirectoryPermissions = useSelector((state: StateModel) => state.auth.permissions.directory);
  const linksWithPermission = links.filter((link) => {
    const viewPermission = link.permissions.find((permission) => permission.includes('view'));
    if (!viewPermission) return false;
    return userDirectoryPermissions.includes(viewPermission);
  });
  /** в зависимости от прав пользователя отфильтрует доступные справочники */
  const uniqueFolders = useMemo(() => _.uniq(linksWithPermission.map((item) => item.folder)), [linksWithPermission]);
  const resultList = arrayGroupBy(linksWithPermission, (rule: ILinksCT) => rule.folder);
  const cardsGroupsForRender = [];
  for (const folder of uniqueFolders) {
    const oneCardsGroup = [];
    const itemsCollection = resultList.get(folder);
    oneCardsGroup.push(
      itemsCollection.map((el: ILinksCT, index: number) => (
        <Link to={`/app/${ApplicationsDirectory.applications}/${el.url}/`} key={index}>
          <LocalCardMainWrapper>
            <LocalCardWrapper onClick={() => {}}>
              <LocalCardHeader>
                {el.label}
                <LocalCardOption>
                  <LocalCardStar>
                    <StarIcon />
                  </LocalCardStar>
                  <LocalCardPoint>
                    <OptionIcon />
                  </LocalCardPoint>
                </LocalCardOption>
              </LocalCardHeader>
              <LocalCardDescr>{el.description}</LocalCardDescr>
            </LocalCardWrapper>
          </LocalCardMainWrapper>
        </Link>
      ))
    );
    cardsGroupsForRender.push({
      name: folder,
      cardsGroup: oneCardsGroup,
    });
  }

  return (
    <>
      <HeaderScipBlock />
      <LocalMainWrapper>
        <ScrollableBoxArea>
          <LocalCardsWrapper>
            {cardsGroupsForRender.map((el, index) => {
              return (
                <ExtendedCollapseItem key={index}>
                  <CollapsableItem rotateDegree={90} titleContent={el.name}>
                    <CardsGroupComtainer>{el.cardsGroup}</CardsGroupComtainer>
                  </CollapsableItem>
                </ExtendedCollapseItem>
              );
            })}
          </LocalCardsWrapper>
        </ScrollableBoxArea>
      </LocalMainWrapper>
    </>
  );
};
export default ApplicationsFolder;
