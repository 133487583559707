import React, { PropsWithChildren } from 'react';

import { Styled } from './ScrollableBoxArea.styled';
import * as CSS from 'csstype';

interface ScrollableBoxAreaProps {
  areaBackground?: CSS.Property.Color;
}

const { AreaWrapper, AreaContent } = Styled;

const ScrollableBoxArea = ({ children, areaBackground }: PropsWithChildren<ScrollableBoxAreaProps>) => {
  return (
    <AreaWrapper>
      <AreaContent areaBackground={areaBackground}>{children}</AreaContent>
    </AreaWrapper>
  );
};

export default ScrollableBoxArea;
