import React from 'react';
import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { getFromURL } from 'api/getFromURL';
import { RoadConstructionsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

const switcherButton = React.lazy(() => import('../customButtons/ProjectLayerSwitcher.lazy'));
let selectedType = 'marking_lines';

export class ProjectLayerDataAccessor extends LayerDataAccessorPrototype {
  // отвечает за объект который выбирается в дата ацессоре, так как он общий для экземпляров класса то сделаем его статичным, чтобы не создавать лишние экземпляры
  static selectedType = LayerDataAccessorPrototype.selectedType;
  actionButtons = [switcherButton];

  constructor() {
    super({
      selectedType: MapObjects.projectLayerProjectRoadSigns,
      label: 'sing',
    });
  }

  async getData() {
    return await RoadConstructionsService.roadConstructionsRoadSignsProjectList();
  }

  // по запросу по модели вернем нашу схему. Await нужен для согласованности then, можно и в промис обернуть, но лень)
  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadSignProjection);
  }

  // метод замены статичной переменной
  static replaceSelectedType(type) {
    switcherButton.selectedType = type;
  }

  static getSelectedType() {
    return selectedType;
  }

  //оверрайдим метод прототипа для получения статичной переменной иначе получим переменную прототипа
  getSelectedObjectType() {
    return switcherButton.selectedType ? switcherButton.selectedType : selectedType;
  }
}
