import { postToURLSingle } from 'api/postToURLSingle';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import _ from 'underscore';

export const getBillingUnp = async (
  id: number,
  billing: string,
  pageMenuKey: IOPWDirectory | null,
  dispatch: (param: any) => void,
  directory: string
) => {
  try {
    const response = await postToURLSingle.postOrPutDataErrorResponse(
      `/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/${id}/${billing}/`
    );
    if (response) {
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: 'Проверка произведена!',
        })
      );
    } else {
    }
  } catch (e) {
    if (_.isObject(e) && e?.response && _.isObject(e.response)) {
      const message = createAlertMessage(e);
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message,
        })
      );
    }
  } finally {
    setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
  }
};
