import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const BusStopstemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'bus_stops');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.publicTransportBusStops}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  const properties = data;

  Descriptions.push({
    key: 'Название',
    value: properties.name,
  });

  Descriptions.push({
    key: 'Тип остановки',
    value: [
      properties.is_bus && 'Автобусная остановка',
      properties.is_troll && 'Троллейбусная остановка',
      properties.is_tram && 'Трамвайная остановка',
    ],
  });

  Descriptions.push({
    key: 'Статус',
    value: !properties.status && '-',
  });

  Descriptions.push({
    key: 'Район',
    value: !properties.status && '-',
  });

  Descriptions.push({
    key: 'Заездной карман',
    value: !properties.status && '-',
  });
  Descriptions.push({
    key: 'Примечание',
    value: properties.comment ? properties.comment : '-',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об остановке'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

BusStopstemSelection.propTypes = {};

export default BusStopstemSelection;
