import styled from 'styled-components';

const LocalMainWrapper = styled.div`
  margin: 30px 0;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  background: ${(props) => props.theme.colors.defaultLight};
  width: 314px;
  height: 60px;
  display: flex;
  flex-direction: row;
`;

const LocalInputWrapper = styled.div`
  padding-left: 14px;
  width: 150px;
  height: 60px;
`;

const LocalIcon = styled.i`
  color: ${(props) => props.theme.colors.primary};
  padding-top: 14px;
  transform: rotate(270deg);
`;

// const ExtendedTimeStart = styled(DateTimePicker)`
//   & .MuiInputLabel-animated {
//     color: ${(props) => props.theme.colors.primary};
//     padding-top: 8px;
//   }
//   & .MuiInputBase-input {
//     padding-top: 20px;
//     color: ${(props) => props.theme.colors.neutralDarker};
//   }
//
//   & .MuiInput-underline:before {
//     border-bottom: none;
//   }
//   & .MuiInput-underline:after {
//     border-bottom: none;
//   }
//   & .MuiPickersToolbar-toolbar {
//     background: ${(props) => props.theme.colors.primaryDarker};
//   }
//
//   & .MuiInput-formControl:hover {
//     border-bottom: none;
//   }
// `;

// const ExtendedTimeEnd = styled(DateTimePicker)`
//   & .MuiInputLabel-animated {
//     color: ${(props) => props.theme.colors.primary};
//     padding-top: 8px;
//   }
//   & .MuiInputBase-input {
//     padding-top: 20px;
//     color: ${(props) => props.theme.colors.neutralDarker};
//   }
//
//   & .MuiInput-underline:before {
//     border-bottom: none;
//   }
//   & .MuiInput-underline:after {
//     border-bottom: none;
//   }
//   & .MuiPickersToolbar-toolbar {
//     background: ${(props) => props.theme.colors.primaryDarker};
//   }
//   width: 111px;
// `;

export const Styled = {
  LocalMainWrapper,
  LocalInputWrapper,
  LocalIcon,
  // ExtendedTimeStart,
  // ExtendedTimeEnd,
};
