import React from 'react';
import Icon from '@ant-design/icons';

const GridLineIconSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 3V7.59L7.59 15H3V21H9V16.42L16.42 9H21V3H15ZM17 5H19V7H17V5ZM5 17H7V19H5" fill="#2F80ED" />
  </svg>
);

export const GridLineIcon = (props) => <Icon component={GridLineIconSVG} {...props} />;
