import styled from 'styled-components/macro';

const LocalMainWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

const LocalMainTitle = styled.h1`
  font-size: 16px;
  line-height: 140%;
  margin: 0;
  color: #1a2138;
  font-weight: bold;
`;

export const Styled = {
  LocalMainWrapper,
  LocalMainTitle,
};
