import styled from 'styled-components/macro';
import { LocalMainWrapperProps } from './model/LocalMainWrapperProps';
import * as CSS from 'csstype';
import { TailSideType } from '../model/TailSideType';

const handleResolveTailStylesByType = (type: TailSideType, tailColor: CSS.Property.Color) => {
  const rightBordersTemplate = `
                margin-top: -8px;
                border-left: 8px solid ${tailColor};
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
        `;
  const leftBordersTemplate = `
            margin-top: -8px;
            border-right: 8px solid ${tailColor};
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;`;
  const bottomBordersTemplate = `
            border-top: 8px solid ${tailColor};
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
            margin-left: -8px;
        `;
  const topBordersTemplate = `
            margin-left: -8px;
            border-bottom: 8px solid ${tailColor};
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        `;
  switch (type) {
    case 'right-top':
      return `top: 25%;
                left: 100%;
                ${rightBordersTemplate}`;
    case 'right-center':
      return `top: 50%;
                left: 100%;
      ${rightBordersTemplate}`;
    case 'right-bottom': {
      return `top: 75%;
                left: 100%;
                ${rightBordersTemplate}`;
    }
    case 'bottom-right': {
      return `top: 100%;
                left: 75%;
                ${bottomBordersTemplate}`;
    }
    case 'bottom-center': {
      return `top: 100%;
                left: 50%;
                ${bottomBordersTemplate}`;
    }
    case 'bottom-left': {
      return `top: 100%;
                left: 25%;
                ${bottomBordersTemplate}`;
    }
    case 'left-bottom': {
      return `top: 25%;
                right: 100%;
                ${leftBordersTemplate}`;
    }
    case 'left-center': {
      return `top: 50%;
                right: 100%;
                ${leftBordersTemplate}`;
    }
    case 'left-top': {
      return `top: 75%;
                right: 100%;
                ${leftBordersTemplate}`;
    }
    case 'top-left': {
      return `bottom: 100%;
                left: 25%;
                ${topBordersTemplate}`;
    }
    case 'top-center': {
      return `bottom: 100%;
                left: 50%;
                ${topBordersTemplate}`;
    }
    case 'top-right': {
      return `bottom: 100%;
                left: 75%;
                ${topBordersTemplate}`;
    }
    default: {
      return '';
    }
  }
};

export const LocalMainWrapper = styled.div<LocalMainWrapperProps>`
  //position
  top: ${(props) => props.positionStyleOfTooltip.y}px;
  left: ${(props) => props.positionStyleOfTooltip.x}px;
  position: absolute;
  //
  background: ${(props) => props.theme.colors.defaultLighter};
  padding-top: ${(props) => props.theme.paddings.defaultPadding};
  padding-bottom: ${(props) => props.theme.paddings.defaultPadding};
  padding-left: ${(props) => props.theme.paddings.smallPadding};
  padding-right: ${(props) => props.theme.paddings.smallPadding};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  color: ${(props) => props.theme.colors.paragraph};
  box-shadow: ${(props) => props.theme.decoration.defaultBoxShadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: ${(props) => props.tooltipWidth ?? '18.5rem'};
  height: ${(props) => props.tooltipHeight ?? 'fit-content'};
  opacity: ${(props) => props.tooltipOpacity ?? '100%'};
  z-index: 1000;
  transform: translateY(-50%);
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;

    ${(props) => handleResolveTailStylesByType(props.tailSide || 'bottom-center', props.theme.colors.defaultLighter)}
  }
`;

export const Styled = { LocalMainWrapper };
