import React from 'react';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { TrafficLightItemSelectionProps } from './RoadSchemeItemSelection.model';

const TrafficLightItemSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: TrafficLightItemSelectionProps) => {
  const handleOpenObjectInNewTab = () => {
    const { id } = data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadsConstructionTrafficLights}/${id}/`, '_blank');
    win?.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Тип по ГОСТ Р 52282-2004',
    value: data.shape_display,
  });

  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о светофоре'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default TrafficLightItemSelection;
