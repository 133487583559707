import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';

import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { KnsService } from '../../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class Supply_Cables_Troll extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'Supply_Cables_Troll',
      filterLabel: 'Питающие кабели трамваев',
      selectedObjectName: MapObjects.supply_cables_troll,
    });
  }

  async uploadData() {
    return await KnsService.knsSupplyCablesTrollList();
  }

  async deleteRecord(id) {
    return await KnsService.knsSupplyCablesTrollDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsSupplyCablesTrollRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.knsCableTroll);
  }
}
