export const trafficLightType2 = {
  not_type: {
    x: 0,
    y: 0,
    width: 128,
    height: 128,
  },
  not_type_select: {
    x: 0,
    y: 258,
    width: 128,
    height: 128,
  },
  remove: {
    x: 129,
    y: 0,
    width: 128,
    height: 128,
  },
  remove_select: {
    x: 258,
    y: 0,
    width: 128,
    height: 128,
  },
  create: {
    x: 0,
    y: 129,
    width: 128,
    height: 128,
  },
  create_select: {
    x: 129,
    y: 258,
    width: 128,
    height: 128,
  },
  old: {
    x: 129,
    y: 129,
    width: 128,
    height: 128,
  },
  old_select: {
    x: 258,
    y: 129,
    width: 128,
    height: 128,
  },
};
