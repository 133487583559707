import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';

const SettingsControlsItem = styled.div``;

const SettingsButtonClose = styled(Button)`
  font-family: HeliosC, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #495e5f;
  & i {
    padding-right: 17px;
  }
`;

const SettingsButtonDelete = styled(Button)`
  padding-top: 22px;
  font-family: HeliosC, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #eb5757;
  & i {
    padding-right: 17px;
  }
`;

const SettingsControlsWrapper = styled.div`
  & ${SettingsControlsItem}:not(:first-of-type) {
    margin-top: 8px;
  }
`;

export const Styled = {
  SettingsButtonClose,
  SettingsControlsWrapper,
  SettingsControlsItem,
  SettingsButtonDelete,
};
