import React from 'react';
import Icon from '@ant-design/icons';

const MapMinusIconSVG = () => (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5 15V16.6667H19.1667V15H12.5ZM10.9667 16.9667L7.5 15.75L3.05 17.475C3 17.5 2.95833 17.5 2.91667 17.5C2.68333 17.5 2.5 17.3167 2.5 17.0833V4.48333C2.5 4.29167 2.63333 4.14167 2.8 4.08333L7.5 2.5L12.5 4.25L16.95 2.5H17.0833C17.3167 2.5 17.5 2.68333 17.5 2.91667V11.125C16.6551 10.8203 15.7432 10.7517 14.8623 10.9264C13.9813 11.1011 13.1646 11.5126 12.5 12.1167V5.91667L7.5 4.16667V14.0833L10.8667 15.2667C10.8333 15.45 10.8333 15.6417 10.8333 15.8333C10.8333 16.225 10.8833 16.6 10.9667 16.9667Z'
        fill='currentColor' />
    </svg>
  )
;

export const MapMinusIcon = (props) => <Icon component={MapMinusIconSVG} {...props} />;
