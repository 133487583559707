import { handleActions, Reducer } from 'redux-actions';

import { MapReducer } from 'store/reducers/reducers.model';
import { widgetsActions } from './widgets.actions';
import { CameraWidget } from 'generated/api/api';
import { WidgetsStateI } from 'store/reducers/widgets/widgets.model';

const { TOGGLE_PANEL_VISIBLE, CHANGE_PANEL_VISIBLE, CHANGE_EDIT_WIDGET, SET_WIDGETS, SET_CURRENT_WIDGET } =
  widgetsActions;

const initialState = {
  widgetsEditPanelVisible: false,
  editWidget: null,
  widgets: [],
};

const togglePanelVisible: Reducer<WidgetsStateI, {}> = (state) => {
  return {
    ...state,
    widgetsEditPanelVisible: !state.widgetsEditPanelVisible,
    editWidget: state.widgetsEditPanelVisible ? null : state.editWidget,
  };
};

const changePanelVisible: Reducer<WidgetsStateI, { newStatus: boolean }> = (state, action) => {
  const { newStatus } = action.payload;

  return {
    ...state,
    widgetsEditPanelVisible: newStatus,
  };
};

const changeEditWidget: Reducer<WidgetsStateI, { id: number | null }> = (state, action) => {
  const { id } = action.payload;

  return {
    ...state,
    editWidget: id,
  };
};

const setWidgets: Reducer<WidgetsStateI, { widgets: Array<CameraWidget> }> = (state, action) => {
  const { widgets } = action.payload;

  return {
    ...state,
    widgets,
  };
};

const setCurrentWidget: Reducer<WidgetsStateI, { widget: CameraWidget }> = (state, action) => {
  const { widget } = action.payload;
  const widgets = [...state.widgets];
  const index = widgets.findIndex((el) => el.id === widget.id);

  if (index !== -1) {
    widgets[index] = widget;
  } else {
    widgets.push(widget);
  }

  return {
    ...state,
    widgets,
  };
};

const mapReducer: MapReducer<WidgetsStateI, any> = {
  [TOGGLE_PANEL_VISIBLE]: togglePanelVisible,
  [CHANGE_PANEL_VISIBLE]: changePanelVisible,
  [CHANGE_EDIT_WIDGET]: changeEditWidget,
  [SET_WIDGETS]: setWidgets,
  [SET_CURRENT_WIDGET]: setCurrentWidget,
};

export const widgetsReducer: Reducer<any, any> = handleActions(mapReducer, initialState);
