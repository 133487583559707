import styled from 'styled-components/macro';

const TooltipWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(107, 138, 228, 0.12);
  border-radius: 16px;
  padding: 8px;
  max-width: 270px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  width: 270px;
`;

const TooltipHeader = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  color: #6a7b9b;
`;

const TooltipLabel = styled.div<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : '#1A2138')};
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  padding: 12px 16px;
  background: #f1f3f6;
`;

const TooltipLabelName = styled.span`
  text-overflow: ellipsis;
`;

const TooltipLabelValue = styled.span`
  color: #1a2138;
`;

export const Styled = {
  TooltipWrap,
  TooltipHeader,
  TooltipLabel,
  TooltipLabelName,
  TooltipLabelValue,
};
