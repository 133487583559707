import { call, put, takeEvery } from 'redux-saga/effects';
import {
  SET_OBJECT_FOR_STANDALONE_EDIT_MODE,
  SET_STANDALONE_EDIT_MODE_OBJECT,
} from '../../reducers/map/mapboxEditableStandaloneObject';
import {
  editableObjectDescription,
  resolveEditableObjectDescription,
} from '../../../registrators/map/editableObjectsRegistrator/editableObjectsRegistrator';
import { getFromURL } from '../../../api/getFromURL';
import {
  defineDrawGeometryTypeOnModel,
  defineDrawKeyOnModel,
  defineDrawModeOnModel,
} from '../../../services/map/defineDrawMapOnModel';
import wkt from 'wkt';
import { point } from '@turf/helpers';
import _ from 'underscore';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import { getRegisteredLayer } from '../../../registrators/map/layers/layersRegistrator';
import { lineString, nearestPointOnLine } from '@turf/turf';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { createAlertMessage } from '../app/createAlertMessage';
import { mapMarkersActions } from '../../reducers/map/mapMarkers/mapMarkers.actions';
import LinePathLengthMarker from '../../../components/Application/BaseModules/Map/MapStandaloneEditMode/components/LinePathLengthMarker/LinePathLengthMarker';
import React from 'react';
import { MarkerSizeMode } from '../../reducers/map/mapMarkers/mapMarkers.model';

export const INIT_SINGLE_EDIT_MAP_MODE_SAGA = 'INIT_SINGLE_EDIT_MAP_MODE_SAGA';

export const initializeSingleObjectEditMode = (
  selectedObject,
  typeOfObject,
  objectLayerName,
  initialCoordinate,
  groupType
) => {
  return {
    type: INIT_SINGLE_EDIT_MAP_MODE_SAGA,
    payload: {
      selectedObject,
      typeOfObject,
      objectLayerName,
      initialCoordinate,
      groupType,
    },
  };
};

/**
 * Инициализация изменения геогрфических данных 1 объекта или 1 группы объектов
 * @param action
 * Сага ниже принимает в себя параметы, которые, преимущественно, берутся из файлов как RoadSegmentDescription.js и в момент клика
 * - это смотерть в DeckContainer.js, функция onClickItemHandler.
 */

function* initSingleObjectEditableSaga(action) {
  try {
    const { selectedObject, typeOfObject, objectLayerName, initialCoordinate, groupType } = action.payload;
    const resolvedRegisteredEditableObject = editableObjectDescription.find((el) => el.name === typeOfObject);
    // relatedData - основывясь на принятом имени слоя она загружает все его составляющие.
    const relatedData = getRegisteredLayer(objectLayerName).getRelatedData();
    // relatedDataObject путём сравнения св-ва name каждой составляющей с принятым типом объекта оставляет одну
    // - к которой объект принадлежит.
    let relatedDataObject = null;
    let model = {};

    try {
      if (
        relatedData &&
        relatedData.length &&
        typeOfObject !== MapObjects.individualStatements &&
        typeOfObject !== MapObjects.entityStatements
      ) {
        relatedDataObject = relatedData.find((el) => el.name.includes(typeOfObject));
      }

      // model используя метод описанный при создании элементов, получает структуру/модель объекта данной составляющей.
      if (relatedDataObject && typeOfObject !== MapObjects.monitoringUds) {
        model = yield call(() => relatedDataObject?.uploadModel());
      } else {
        model = yield call(() => getFromURL.getModelFromURL(resolvedRegisteredEditableObject.urlData));
      }
    } catch (e) {
      if (e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        yield put(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: message,
          })
        );
      }
    }

    // Так как в проекте, где это записывается, данные объектов во время клика по их отображениях на карте могут не совпадать
    //  с теми, которые в последствии нужно отобразить, то вводится дополнительная переменная currentObject, являющаяся
    //  полными данными объекта с типом = relatedDataObject и id = selectedObject.id.
    let currentObject;
    // isNew - идентификатор того, создаётся ли новый объект или редактируется уже существующий. Устанавливается на основе
    // принятого св-ва groupType.
    let isNew;
    // В случае, если объект создаётся, менять currentObject не нужно, если же редактируется, то она вызывает метод
    // составляющей и становится равна полноценному объекту с id того же значения, что объект на котором кликнули - selectedObject.
    if (groupType === 'add') {
      isNew = true;
      currentObject = selectedObject;
    } else if (groupType === 'generated') {
      isNew = false;
      currentObject = selectedObject;
    } else if (groupType === 'edit') {
      isNew = false;

      // Это условия для знаков в кластере
      if (selectedObject.id) {
        currentObject = yield call(() => relatedDataObject?.readRecord(selectedObject.id));
      } else {
        currentObject = yield call(() => relatedDataObject?.readRecord(selectedObject.properties.id));
      }
    }
    // Полуаются свойства объекта и родительская сущность, описываемая при описании создания элементов в editableObjectsRegistrator.js.
    const urlData = resolveEditableObjectDescription(typeOfObject, 'urlData');
    const name = resolveEditableObjectDescription(typeOfObject, 'label');
    const parentInstance = resolveEditableObjectDescription(typeOfObject, 'parent');
    // используется когда нужно явно передать поля в инстанс
    const hardcodeInstance = resolveEditableObjectDescription(typeOfObject, 'hardcodeInstance');
    const layerName = objectLayerName;
    const parentKey = parentInstance?.key || null;
    let parentId = null;
    const geometryKey = defineDrawKeyOnModel(model);
    // После исключения уникальных свойств идёт сравнение свойств текущего объекта - currentObject и модели объекта - model,
    // результат которого записывается в keysAreSame как булево значение их равенства.
    let objectKeys = currentObject ? Object.keys(currentObject) : [];
    objectKeys = _.without(objectKeys, geometryKey);
    let schemaKeys = Object.keys(model?.scheme).filter((key) => model?.scheme[key].type !== 'inline');
    schemaKeys = _.without(schemaKeys, geometryKey);
    const keysAreSame = _.isEqual(objectKeys, schemaKeys);
    // На основе данных из model выбирается тип создаваемой или редактируемой геометрии объекта.
    let drawData;
    if (isNew) {
      if (initialCoordinate) {
        const typeOfGeometry = defineDrawGeometryTypeOnModel(model);
        // eslint-disable-next-line default-case
        switch (typeOfGeometry) {
          case 'polygon': {
            drawData = [];
            break;
          }
          case 'linestring': {
            drawData = [];
            break;
          }
          case 'point': {
            drawData = point(initialCoordinate).geometry;
            break;
          }
          default:
            break;
        }
      } else {
        drawData = null;
      }
    } else {
      if (selectedObject?.[geometryKey]) {
        drawData = wkt.parse(selectedObject?.[geometryKey]);
      } else {
        drawData = null;
      }
    }
    // Операции над родительским объектом производятся, если клик произошёл не на карте и если такой объект описан в editableObjectsRegistrator.js.
    const drawDataInTemplate = {
      type: 'FeatureCollection',
      features: [
        {
          geometry: { ...drawData },
          properties: {},
          type: 'Feature',
        },
      ],
    };
    // Если запускается процесс редактирования, то parentId получает id родителя currentObject если keysAreSame равно true и id
    // selectedObject, если false.
    const editMode = defineDrawModeOnModel(model);
    if (keysAreSame) {
      if (currentObject?.[parentKey]) {
        parentId = currentObject?.[parentKey];
      }
    } else {
      parentId = currentObject ? selectedObject?.id : null;
    }
    // instance приравнивается currentObject, если запускается процесс редактирования.
    let instance = {};
    if (isNew && !hardcodeInstance) {
      instance = {};
    } else if (hardcodeInstance) {
      for (let key in hardcodeInstance) {
        instance[key] = currentObject[hardcodeInstance[key]];
      }
    } else {
      instance = currentObject;
    }
    // parentModel получает структуру/модель родительского объекта selectedObject.
    const parentModel = parentInstance ? yield call(() => getFromURL.getModelFromURL(parentInstance.urlData)) : null;
    // parentData - данные родительского элемента.
    let parentData =
      parentInstance && parentId
        ? yield call(() => getFromURL.getDataFromURL(parentInstance.urlData + `${parentId}/`))
        : null;
    // Блокируется для редактирования поле, в котором данные берутся из выбранного эдемента
    // Например, если выбран сегмент, то в поле "сегмент" в карточке будет вписан именно он и нельзя будет это изменить
    if (parentInstance?.creatable) {
      model.scheme[parentKey].type = 'creatable related field';
    } else if (
      parentInstance &&
      !parentInstance.creatable &&
      typeOfObject !== MapObjects.individualStatements &&
      typeOfObject !== MapObjects.entityStatements
    ) {
      instance[parentKey] = parentData?.id;
      model.scheme[parentKey].read_only = true;
    }
    // Place - переменная расчёта расстояния от начала линейного родительского объекта до точки геометрии. Для остальных
    // типов геометрии расчёты записаны в deck.js и по-хорошему это бы тоже туда же убрать как-то
    let place;
    if (drawData?.type === 'Point' && parentInstance) {
      const parentGeometryLine = lineString(getCoordsFromParsedGeometry(parentData?.line_path), {
        name: 'newGeometryLine',
      });
      place = nearestPointOnLine(parentGeometryLine, drawData?.coordinates, {
        units: 'kilometers',
      }).properties.location.toFixed(3);
    }
    // Принятые, созданные и полученные данные собираются в объект preparedData и вызываются экшены
    // в переводящие карту и объекты в состояние редактирования.
    const preparedData = {
      urlData,
      selectedInstance: typeOfObject,
      drawData: drawDataInTemplate,
      parentObjectsDescription: parentModel,
      model,
      geometryKey,
      editMode,
      name,
      isNew,
      instance,
      parentKey,
      parentId,
      layerName,
      parentData,
      parentModel,
      place,
    };
    //finally set mode to true
    yield put({
      type: SET_STANDALONE_EDIT_MODE_OBJECT,
      payload: {
        ...preparedData,
      },
    });

    if (drawData?.type === 'LineString') {
      const coordinates = drawData?.coordinates;

      if (coordinates?.length === 2) {
        yield put({
          type: mapMarkersActions.ADD_CARD_MARKER,
          payload: {
            layer: layerName,
            marker: {
              type: typeOfObject,
              markerSizeMode: MarkerSizeMode.static,
              markerPosition: coordinates[0],
              component: <LinePathLengthMarker />,
            },
          },
        });
      }
    }

    yield put({
      type: SET_OBJECT_FOR_STANDALONE_EDIT_MODE,
      payload: {
        value: true,
      },
    });

    yield put({
      type: notificationActions.SET_NOTIFICATION_DATA,
      payload: {
        message: 'Нарисуйте геометрию',
        status: ActiveStatus.active,
      },
    });
  } catch (e) {
    console.error(e);
    yield put(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message: 'Что-то пошло не так',
      })
    );
  }
}

//ready
export function* watchSingleObjectEditableSaga() {
  yield takeEvery(INIT_SINGLE_EDIT_MAP_MODE_SAGA, initSingleObjectEditableSaga);
}
