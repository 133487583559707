import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Menu } from 'antd';

import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

import { IObjectPanelSiderProps, CargoTransportParams } from '../interfacesCargoTransport/interfacesCargoTransport';
import { changeMenuTab } from '../methods/changeMenuTab';
import { menuActs, menuTabFederalStatement, menuTabIPORGStatement } from './MenuLists';
import { Styled } from './ObjectPanelSider.styled';
import { TransportOrdersLayer } from 'registrators/map/layers/ekb/TransportOrdersLayer';
import { AnyObject } from 'types/enums/general/general.model';

const { SiderWrapper, SiderInner, SiderContent, SiderFooter, SiderTitle } = Styled;

/**
 * Компонент создания меню в сайтбаре
 * Поскольку пунктов меню нет в scheme меню создается вручную через переменные menuTab...
 */

interface ICreateMenuItemsProps {
  params: CargoTransportParams;
  isActiveMenu: boolean;
}

const CreateTabMenu = (menu: IOPWDirectory[], isActiveMenu: boolean, directory: IOPWDirectory) =>
  menu.map((tab) => {
    const label =
      directory === IOPWDirectory.federal_statements && tab === IOPWDirectory.files
        ? IOPWTabMenuModel.federal_files
        : IOPWTabMenuModel[tab];
    return (
      <Menu.Item key={tab} disabled={!isActiveMenu}>
        {label}
      </Menu.Item>
    );
  });

const createMenuItems = ({ params, isActiveMenu }: PropsWithChildren<ICreateMenuItemsProps>) => {
  const { directory, scoreMode } = params;
  let result: AnyObject[] = [<Menu.Item key={IOPWDirectory.main}>{IOPWTabMenuModel.main}</Menu.Item>];
  if (!scoreMode && (directory === IOPWDirectory.write_off_acts || directory === IOPWDirectory.destruction_acts)) {
    result = [...result, ...CreateTabMenu(menuActs, isActiveMenu, directory)];
  } else if (directory === IOPWDirectory.individual_statements || directory === IOPWDirectory.entity_statements) {
    if (scoreMode === IOPWDirectory.vehicle_parameters) {
      result = [...result, ...CreateTabMenu([IOPWDirectory.vehicle_axes], isActiveMenu, directory)];
    } else if (!scoreMode) {
      result = [...result, ...CreateTabMenu(menuTabIPORGStatement, isActiveMenu, directory)];
    }
  } else if (directory === IOPWDirectory.federal_statements) {
    if (scoreMode === IOPWDirectory.vehicles) {
      result = [...result, ...CreateTabMenu([IOPWDirectory.vehicle_axis], isActiveMenu, directory)];
    } else if (!scoreMode) {
      result = [...result, ...CreateTabMenu(menuTabFederalStatement, isActiveMenu, directory)];
    }
  }
  return result;
};

const ObjectPanelSider = React.forwardRef((props: PropsWithChildren<IObjectPanelSiderProps>, ref: any) => {
  const dispatch = useDispatch();
  const history = useHistory<any[]>();
  const { params } = useRouteMatch<CargoTransportParams>();
  const { scoreMode, directory, scoreId } = params;
  const {
    initClose,
    pageMenuKey,
    isActiveMenu,
    setData,
    setIdSignDocument,
    setUrl,
    setPageMenuKey,
    urlModel,
    initSubmitForm,
    data,
    setIsActiveCustomFederalStatementView,
    isActiveCustomFederalStatementView,
  } = props;

  const MenuItems = useMemo(() => createMenuItems({ params, isActiveMenu }), [isActiveMenu, params]);

  const onSelectMenuItem = useCallback(
    (event) => {
      setIsActiveCustomFederalStatementView(false);
      changeMenuTab(
        event.key,
        pageMenuKey,
        setData,
        setIdSignDocument,
        directory,
        setUrl,
        setPageMenuKey,
        scoreMode,
        urlModel
      );
    },
    [
      directory,
      pageMenuKey,
      scoreMode,
      setData,
      setIdSignDocument,
      setIsActiveCustomFederalStatementView,
      setPageMenuKey,
      setUrl,
      urlModel,
    ]
  );

  let isEditableSaveChange = IOPWDirectory.main === pageMenuKey && directory !== IOPWDirectory.federal_statements; // Прячем кнопку в зависимости от возможности редактирования

  const isHideSaveChangeButton =
    directory === IOPWDirectory.write_off_acts ||
    directory === IOPWDirectory.destruction_acts ||
    scoreMode === IOPWDirectory.vehicles ||
    scoreMode === IOPWDirectory.adapter_messages;
  if (isHideSaveChangeButton) {
    isEditableSaveChange = false;
  }

  const isCreateRoute = useMemo(
    () =>
      (directory === IOPWDirectory.individual_statements || directory === IOPWDirectory.entity_statements) &&
      !scoreMode,
    [directory, scoreMode]
  );

  const saveChangeButton = useMemo(() => {
    const labelButton =
      (scoreMode === IOPWDirectory.approvals && !scoreId) || (scoreMode === IOPWDirectory.approvals && !data?.status);
    return (
      <Button
        onClick={() => {
          ref.current.estimation = false;
          initSubmitForm(ref);
        }}
        type={ButtonType.primary}
        width={'100%'}
        tooltip={'Сохранить изменения'}
      >
        {labelButton ? 'Подписать' : 'Сохранить изменения'}
      </Button>
    );
  }, [data, initSubmitForm, ref, scoreId, scoreMode]);

  const onClickButtonHandler = useCallback(() => {
    history.push('/app/map');
    dispatch({ type: 'INIT_ADD_LAYER_TO_MAP_SAGA', payload: { Layer: new TransportOrdersLayer() } });
  }, [dispatch, history]);

  const selectedKeys = useMemo(() => {
    const result = MenuItems.find((item: AnyObject) => item.key === pageMenuKey)?.key;
    if (!result) setPageMenuKey(IOPWDirectory.main);
    if (isActiveCustomFederalStatementView) return [''];
    return [result ?? IOPWDirectory.main];
  }, [MenuItems, isActiveCustomFederalStatementView, pageMenuKey, setPageMenuKey]);

  const selectCustomViewFederalStatementButton = useMemo(() => {
    return (
      directory === IOPWDirectory.federal_statements &&
      !scoreMode &&
      !scoreId && (
        <Button
          className={isActiveCustomFederalStatementView ? 'active' : ''}
          width={'100%'}
          tooltip={'Включить общий вид заявки'}
          onClick={() => setIsActiveCustomFederalStatementView(true)}
        >
          <span>Общий вид заявки</span>
        </Button>
      )
    );
  }, [directory, isActiveCustomFederalStatementView, scoreId, scoreMode, setIsActiveCustomFederalStatementView]);

  return (
    <SiderWrapper width={'16%'}>
      <SiderInner>
        <SiderContent>
          <SiderTitle>Данные</SiderTitle>
          {selectCustomViewFederalStatementButton}
          <Menu onClick={onSelectMenuItem} selectedKeys={selectedKeys}>
            {MenuItems}
          </Menu>
        </SiderContent>
        <SiderFooter>
          {isCreateRoute && (
            <Button
              disabled={!isActiveMenu}
              type={ButtonType.primary}
              width={'100%'}
              tooltip={'Создать маршрут "Перевозка КТГ"'}
              onClick={onClickButtonHandler}
            >
              Создать маршрут
            </Button>
          )}
          {isEditableSaveChange && saveChangeButton}
          <Button onClick={initClose} type={ButtonType.danger} width={'100%'} tooltip={'Выйти'}>
            Выйти
          </Button>
        </SiderFooter>
      </SiderInner>
    </SiderWrapper>
  );
});

export default ObjectPanelSider;
