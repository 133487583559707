import React from 'react';
import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';

const CreateRouteForTransportOrderButton = React.lazy(() =>
  import('../customButtons/TransportOrder/CreateRouteForTransportOrderButton.lazy')
);
const EditRouteForTransportOrderButton = React.lazy(() =>
  import('../customButtons/TransportOrder/EditRouteForTransportOrderButton.lazy')
);

const TransportOrderSwitcher = React.lazy(() => import('../customButtons/TransportOrder/TransportOrderSwitcher.lazy'));

export class TransportOrdersDataAccessor extends LayerDataAccessorPrototype {
  // отвечает за объект который выбирается в дата ацессоре, так как он общий для экземпляров класса то сделаем его статичным, чтобы не создавать лишние экземпляры
  static selectedType = LayerDataAccessorPrototype.selectedType;

  actionButtons = [TransportOrderSwitcher, CreateRouteForTransportOrderButton, EditRouteForTransportOrderButton];

  constructor() {
    super({
      label: 'Заявки на перевозку',
      selectedType: MapObjects.individualStatements,
      hideRefreshButton: true,
    });
  }

  changeSelectedTypeForGettingDataFromSwitcher() {
    if (!TransportOrdersDataAccessor.selectedType) {
      TransportOrdersDataAccessor.replaceSelectedType(MapObjects.individualStatements);
    }
  }

  // метод замены статичной переменной
  static replaceSelectedType(type) {
    TransportOrdersDataAccessor.selectedType = type;
  }

  static getSelectedType() {
    return TransportOrdersDataAccessor.selectedType;
  }

  getSelectedObjectType() {
    return TransportOrdersDataAccessor.selectedType;
  }
}
