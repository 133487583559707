import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Dropdown, Menu } from 'antd';

import { getFromURL } from 'api/getFromURL';
import { postToURLSingle } from 'api/postToURLSingle';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { approvalsStatusChoises } from 'types/enums/directories/approvalsStatusChoises';
import { IOPWDirectory, RequiredSmevStatement } from 'types/enums/routes/directory/IOPWDirectory.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import _ from 'underscore';

import { IButton } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { Styled } from './ChangeStatusButton.styled';
import { useDispatch } from 'react-redux';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { AnyObject } from 'types/enums/general/general.model';

interface IChangeStatusModalProps extends IButton {
  data: any[];
  model?: ModelResponse;
  directory: IOPWDirectory;
  idStatement?: number;
  idSignDocument?: number;
  isDisabled: boolean;
  refreshData: () => void;
  tooltip?: string;
  pageMenuKey?: IOPWDirectory;
  isOpenModal: boolean;
  setOpenModal: (data: boolean) => void;
}

const RequiredSmevStatementStatusList: RequiredSmevStatement[] = [
  RequiredSmevStatement.REGISTRATION_DENIED,
  RequiredSmevStatement.REFUSED_TO_ISSUE,
  RequiredSmevStatement.ISSUED_BY,
];
const requiredSmevStatusApprovalsList: RequiredSmevStatement[] = [
  RequiredSmevStatement.AGREED,
  RequiredSmevStatement.DENIED,
  RequiredSmevStatement.SEND_FOR_APPROVAL,
];

const {
  StartUploadButton,
  LocalModal,
  DropdownStyledContainer,
  ButtonContainer,
  LocalSpan,
  TextAreaContainer,
  FieldsTitle,
} = Styled;

export const ChangeStatusModal = (props: IChangeStatusModalProps) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<string | undefined>();
  const [statusMessages, setStatusMessages] = useState<AnyObject[]>([]);
  const [message, setMessage] = useState<string | undefined>();
  const [petitionStatusComments, setPetitionStatusComments] = useState<AnyObject>({});
  const [route, setRoute] = useState<string | undefined>();
  const [specialDrivingConditions, setSpecialDrivingConditions] = useState<string | undefined>();
  const { data, directory, idStatement, isOpenModal, model, setOpenModal, refreshData, pageMenuKey, idSignDocument } =
    props;

  useEffect(() => {
    (async () => {
      if (pageMenuKey !== 'approvals') {
        const response = await getFromURL.getAll(`/${IOPWDirectory.special_permits}/status_comments/get_json/`);
        setPetitionStatusComments(response);
      }
    })();
  }, [pageMenuKey]);

  const selectedDocument = useMemo(() => {
    setStatus(undefined);
    setMessage(undefined);
    setStatusMessages([]);
    const newSelectedDocument = data?.find((st) => (idSignDocument ? st.id === idSignDocument : st.id === idStatement));
    if (pageMenuKey === 'approvals') {
      setSpecialDrivingConditions(newSelectedDocument?.special_driving_conditions);
      setRoute(newSelectedDocument?.desc_route_initiator);
    }
    return newSelectedDocument;
  }, [data, idSignDocument, idStatement, pageMenuKey]);

  const getCommentTemplatesStatus = useCallback(
    (newStatus) => {
      setStatus(newStatus);
      if (petitionStatusComments) {
        Object.keys(petitionStatusComments).forEach((key) => {
          if (key === newStatus) {
            setStatusMessages(petitionStatusComments[key]);
          }
        });
      }
    },
    [petitionStatusComments]
  );

  const statusesMenu = useMemo(() => {
    const choices = pageMenuKey === 'approvals' ? approvalsStatusChoises : model?.scheme?.status?.choices;
    return (
      <Menu onClick={(e) => getCommentTemplatesStatus(e.key)}>
        {choices?.map((choice) => (
          <Menu.Item key={choice.value}>{choice.display_name}</Menu.Item>
        ))}
      </Menu>
    );
  }, [getCommentTemplatesStatus, model, pageMenuKey]);

  const commentOptions = useMemo(() => {
    return (
      <ButtonContainer>
        {statusMessages?.map((statusMessage, index) => (
          <Button
            onClick={() => setMessage(statusMessage.comment)}
            rounded
            hasShadow
            type={ButtonType.unbounded}
            key={index}
          >
            {statusMessage.comment_name}
          </Button>
        ))}
      </ButtonContainer>
    );
  }, [statusMessages]);

  const postNewStatus = useCallback(async () => {
    try {
      if (pageMenuKey) {
        if (requiredSmevStatusApprovalsList.some((el) => el === status) && idStatement) {
          // Осуществление электронной подписи
          const dataSign = await postToURLSingle.postOrPutDataErrorResponse(
            `special_permits/${directory}/${pageMenuKey}/data_to_sign/`,
            {
              statement_id: idStatement,
              status,
            }
          );
          // TODO доработать Тех-долг-FRONTEND #5598
          // const xmlResponce = await smevApiChangeStatus.getSignature(dataSign?.data);
          await postToURLSingle.postOrPutDataErrorResponse(
            `special_permits/${directory}/${pageMenuKey}/${selectedDocument?.id}/performer_sign/`,
            {
              data: dataSign?.data,
            }
          );
        }
        await postToURLSingle.postOrPutDataErrorResponse(
          `special_permits/${directory}/${pageMenuKey}/${selectedDocument?.id}/change_status/`,
          {
            new_status: status,
            performer_comment: message,
            desc_route_performer: route,
            special_driving_conditions: specialDrivingConditions,
          }
        );
      } else {
        if (RequiredSmevStatementStatusList.some((el) => el === status)) {
          // Осуществление электронной подписи
          const dataSign = await postToURLSingle.postOrPutDataErrorResponse(
            `special_permits/${directory}/${idSignDocument ? idSignDocument : idStatement}/data_to_sign/`
          );
          // TODO доработать Тех-долг-FRONTEND #5598
          // const xmlResponce = await smevApiChangeStatus.getSignature(dataSign?.data);
          await postToURLSingle.postOrPutDataErrorResponse(
            `special_permits/${directory}/${idSignDocument ? idSignDocument : idStatement}/sign/`,
            {
              data: dataSign?.data,
            }
          );
        }
        await postToURLSingle.postOrPutDataErrorResponse(
          `special_permits/${directory}/${selectedDocument?.id}/change_status/`,
          {
            new_status: status,
            comment: message,
          }
        );
      }
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: 'Статус успешно изменен!',
        })
      );
      refreshData();
      setOpenModal(false);
    } catch (e) {
      if (_.isObject(e) && e?.response && _.isObject(e.response)) {
        const alertMessage = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: alertMessage,
          })
        );
      }
    } finally {
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
    }
  }, [
    pageMenuKey,
    dispatch,
    refreshData,
    setOpenModal,
    idStatement,
    directory,
    selectedDocument?.id,
    status,
    message,
    route,
    specialDrivingConditions,
    idSignDocument,
  ]);

  const startChangeStatusButton = useMemo(() => {
    let labelButton = 'Изменить статус';
    const changeLabel =
      (pageMenuKey && requiredSmevStatusApprovalsList.some((el) => el === status)) ||
      (!pageMenuKey && RequiredSmevStatementStatusList.some((el) => el === status));
    if (changeLabel) labelButton = 'Подписать';
    return (
      <StartUploadButton>
        <Button className={'shitTrader'} width={'100%'} onClick={postNewStatus} disabled={!message}>
          <p>{labelButton}</p>
        </Button>
      </StartUploadButton>
    );
  }, [message, pageMenuKey, postNewStatus, status]);

  const statusDisplayName = useMemo(() => {
    const choices = pageMenuKey === 'approvals' ? approvalsStatusChoises : model?.scheme?.status?.choices;
    return choices?.find((el) => el.value === (status ?? selectedDocument?.status))?.display_name ?? 'Выберите статус';
  }, [model, selectedDocument, status, pageMenuKey]);

  return (
    <>
      <LocalModal
        title={`Смена статуса заявления ${selectedDocument?.number_of_statement ?? ''}`}
        visible={isOpenModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        centered={true}
        footer={startChangeStatusButton}
      >
        <FieldsTitle>Статус</FieldsTitle>
        <DropdownStyledContainer>
          <Dropdown.Button overlay={statusesMenu} trigger={['click']}>
            <LocalSpan>{statusDisplayName}</LocalSpan>
          </Dropdown.Button>
        </DropdownStyledContainer>
        <FieldsTitle>Коментарии</FieldsTitle>
        <TextAreaContainer
          value={message}
          disabled={!status}
          onChange={({ target: { value } }) => setMessage(value)}
          placeholder={pageMenuKey === 'approvals' ? 'Коментарии' : 'Почему меняем статус?*'}
        />
        {pageMenuKey === 'approvals' && (
          <>
            <FieldsTitle>Маршрут</FieldsTitle>
            <TextAreaContainer value={route} disabled={!status} onChange={({ target: { value } }) => setRoute(value)} />
            <FieldsTitle>Особые условия движения</FieldsTitle>
            <TextAreaContainer
              value={specialDrivingConditions}
              disabled={!status}
              onChange={({ target: { value } }) => setSpecialDrivingConditions(value)}
            />
          </>
        )}
        {commentOptions}
      </LocalModal>
    </>
  );
};
