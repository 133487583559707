export class LayerTemplatesPrototype {
  templatesURL;
  templateLayer;
  itemsURL;

  constructor({ templatesURL, itemsURL, templatesLayer }) {
    this.templatesURL = templatesURL;
    this.templatesLayer = templatesLayer;
    this.itemsURL = itemsURL;
  }

  getTemplateURL() {
    return this.templatesURL;
  }

  getTemplateLayer() {
    return this.templatesLayer;
  }

  getItemsURL() {
    return this.itemsURL;
  }
}
