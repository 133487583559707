import React from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import moment from 'moment';
import { SelectedItemTypeEnum } from '../../../types/enums/map/SelectedItemTypeEnum';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const RoadAccidentsItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'road_accidents');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.road_accidents}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];
  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = data;
  properties.road_accident_type &&
    Descriptions.push({
      key: 'Вид происшествия',
      value: SelectedItemTypeEnum[properties.road_accident_type] ?? 'Не указан',
    });

  properties.date_time &&
    Descriptions.push({
      key: 'Дата и время совершения',
      value:
        moment(`${properties.date_time}`, 'YYYY-MM-DD hh:mm:ss').format('Do MMM YYYY[г. в ] hh:mm a') ?? 'Не указана',
    });

  return (
    <PrepareScrollableInfoCard
      title={'Сведения о ДТП'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default RoadAccidentsItemSelection;
