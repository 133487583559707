import React from 'react';

import { FormInputProps } from 'UI/common/form/inputs/model/FormInputProps.model';

import { IAxes, IVehicleParameters } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { Styled } from './VehicleParametersField.styled';
import { VPNumberInput } from './VPNumberInput';
import { VPStringInput } from './VPStringInput';

const { MainVPContainer, StyledWrapper } = Styled;

interface InputsMainParametersProps extends FormInputProps {
  changeFields: (key: string, value: string | number, obj: IVehicleParameters | IAxes) => void;
}

export const InputsMainParameters = ({ description, object, changeFields }: InputsMainParametersProps) => {
  return (
    <MainVPContainer>
      <StyledWrapper>
        <VPStringInput
          key={`input-model`}
          description={description.model}
          object={object}
          field={'model'}
          changeFields={changeFields}
        />
      </StyledWrapper>
      <StyledWrapper>
        <VPStringInput
          key={`input-registration_number`}
          description={description.registration_number}
          object={object}
          field={'registration_number'}
          changeFields={changeFields}
        />
      </StyledWrapper>
      <StyledWrapper>
        <VPNumberInput
          key={`input-axle_count`}
          description={description.axle_count}
          object={object}
          field={'axle_count'}
          changeFields={changeFields}
          step={1}
        />
      </StyledWrapper>
      <StyledWrapper>
        <VPNumberInput
          key={`input-weight`}
          description={description.weight}
          object={object}
          field={'weight'}
          changeFields={changeFields}
          step={0.1}
        />
      </StyledWrapper>
    </MainVPContainer>
  );
};
