class AdministrationHelper {
  static resolveUrl = (pathname: string, urls: Array<any>): string => {
    const needRemovePaths = ['app', 'directory'];
    if (!pathname) return '';
    const filteredPathnameArray = pathname
      .split('/')
      .filter(Boolean)
      .filter((i: string) => !needRemovePaths.find((removePath: string) => removePath === i));
    const item = urls.find((i) => `${filteredPathnameArray[1]}` === i.url);
    return item?.urlData ?? filteredPathnameArray;
  };
}

export default AdministrationHelper;
