import _ from 'lodash';

import { CameraWidget, WidgetTypeEnum } from 'generated/api/api';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

export const prepareCameraWidgetsList = (model: ModelResponse, data: CameraWidget) => {
  const prepareData: CameraWidget = _.cloneDeep(data);

  if (!prepareData.widget_type) {
    prepareData.widget_type = WidgetTypeEnum.NUMERIC;
  }

  return {
    prepareData,
    prepareModel: model,
  };
};
