import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { basePath } from 'registrators/map/layers/layersRegistrator';

import { Select } from 'antd';

import { useSaveExel } from 'hooks/reports/useSaveExel';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import _ from 'underscore';

import { Styled } from './PrintFederalStatement.styled';

const { Option } = Select;
const { StartUploadButton, LocalModal, FormContainer, BaseSelectInput } = Styled;

interface IPrintButtonModal {
  isOpenModal: boolean;
  setOpenModal: (status: boolean) => void;
  idStatement?: number | null;
}

export const PrintButtonFederalStatement = (props: PropsWithChildren<IPrintButtonModal>) => {
  const dispatch = useDispatch();
  const { saveExel } = useSaveExel();
  const { isOpenModal, setOpenModal, idStatement } = props;
  const [exportOption, setExportOption] = useState<number | unknown>();

  const exportStatement = useCallback(async () => {
    try {
      saveExel(
        `${basePath}/${IOPWDirectory.special_permits}/${IOPWDirectory.federal_statements}/${idStatement}/print/?type_form=FORM${exportOption}`
      );
      setOpenModal(false);
    } catch (e) {
      if (_.isObject(e) && e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message,
          })
        );
      }
    } finally {
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
    }
  }, [saveExel, idStatement, exportOption, setOpenModal, dispatch]);

  const exportStatementButton = useMemo(
    () => (
      <StartUploadButton>
        <Button className={'shitTrader'} width={'100%'} type={ButtonType.primary} onClick={exportStatement}>
          <p>Экспортировать заявление</p>
        </Button>
      </StartUploadButton>
    ),
    [exportStatement]
  );

  // tslint:disable-next-line:no-magic-numbers
  const options = useMemo(() => [1, 2, 3], []);

  const optionMenu = useMemo(
    () =>
      options.map((el: number, index: number) => (
        <Option value={el} key={`base_select_option_${el}_${index}`}>
          {`Вариант ${el}`}
        </Option>
      )),
    [options]
  );

  const collapseInput = useMemo(
    () => (
      <FormContainer>
        <BaseSelectInput onChange={(value) => setExportOption(value)}>{optionMenu}</BaseSelectInput>
        <p className={exportOption ? 'fullField' : 'emptyField'}>Тип экспорта</p>
      </FormContainer>
    ),
    [exportOption, optionMenu]
  );

  return (
    <LocalModal
      title={`Выберите тип экспорта`}
      visible={isOpenModal}
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      centered={true}
      footer={exportOption ? exportStatementButton : null}
    >
      {collapseInput}
    </LocalModal>
  );
};
