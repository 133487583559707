import React from 'react';
import { Styled } from './LayerCard.styled';
import { PlusIcon } from 'UI/custom/icons/actionIcons/PlusIcon';
import { withTheme } from 'styled-components';
import { PanelDefaultCard } from 'UI/common/components/PanelDefaultCard/PanelDefaultCard';
import Img from 'react-image';
import { LayerCardPrototype } from 'registrators/map/layers/description/cards/prototype/LayerCardPrototype';
import { ApplicationTheme } from 'styles/themes/model/ApplicationTheme.model';

const { CardButton, CardText } = Styled;

interface ILayerCard {
  addLayerWithSideEffects: () => void;
  card: LayerCardPrototype;
  theme: ApplicationTheme;
}

const LayerCard = ({ addLayerWithSideEffects, card, theme }: ILayerCard) => {
  const iconSize = { width: '14px', height: '14px' };
  return (
    <PanelDefaultCard.Main>
      <PanelDefaultCard.Content>
        <PanelDefaultCard.PresentationArea>
          <Img src={card?.getLinkToPreview()} />
        </PanelDefaultCard.PresentationArea>
        <PanelDefaultCard.DescriptionArea>
          <CardText>{card?.getLabel()}</CardText>
        </PanelDefaultCard.DescriptionArea>
        <PanelDefaultCard.ActionArea>
          <CardButton
            iconColor={theme.colors.defaultLighter}
            padding={'none'}
            iconSize={iconSize}
            icon={<PlusIcon />}
            onClick={addLayerWithSideEffects}
          />
        </PanelDefaultCard.ActionArea>
      </PanelDefaultCard.Content>
    </PanelDefaultCard.Main>
  );
};

export default withTheme(LayerCard);
