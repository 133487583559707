import { BooleanLayerSetting } from './prototype/types/BooleanLayerSetting';
import { Settings } from 'types/enums/map/layers/Settings';

export class ShowVehicles extends BooleanLayerSetting {
  constructor() {
    super({
      label: 'Отображать ТС',
      keyForDeck: Settings.showVehicles,
      currentValue: false,
    });
  }
}
