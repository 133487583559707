/* tslint:disable:no-magic-numbers no-commented-out-code jsdoc-format */
/** Маппер для цветов для линий и полигонов на слоях из композитных слоёв
 * 4 элемент массива - прозрачность. Обычно идёт от 0 до 1, но у Дека от 0 до 255, так что не факт, что можно
 * применить где либо кроме слоёв Дека
 * */

interface IRGBAColor {
  [key: string]: [number, number, number, number?];
}

export const layerColorsMapper: IRGBAColor = {
  highlightColor: [0, 0, 0, 40], // затемнение при наведении курсора - указывается в одноимённом параметре слоя

  azure: [86, 204, 242], // 56ccf2 - лазурный
  azureDark: [86, 204, 242], // 47afd1 - лазурный тёмный

  blue: [47, 128, 237], // 2f80ed - синий
  blueDark: [42, 116, 212], // 2a74d4 - синий тёмный
  blueLight: [45, 156, 219], // 2d9cdb - светло-синий тёмный
  blueLightDark: [38, 136, 191], // 2688bf - светло-синий тёмный
  blueTranslucent: [47, 128, 237, 100], // 2f80ed - синий полупрозрачный
  blueTransparent: [47, 128, 237, 40], // 	2f80ed - синий прозрачный
  blueTranslucentDark: [44, 120, 219, 150], // 2c78db - синий полупрозрачный темнее

  grayRoad: [158, 158, 158, 150], // 9e9e9e - серый для дорог и сегментов
  grayRoadTranslucent: [158, 158, 158, 150], // 9e9e9e96 - серый полупрозрачный для дорог без типа
  graySidewalk: [193, 203, 216], // #c1cbd8 - серый для тротуаров
  grayAsphalt: [146, 159, 181], // #929fbs - серый для асфальта (проезжая часть)

  lightCarminePink: [230, 103, 97], // #e66761 - светлый карминово-розовый
  lightCarminePinkDark: [207, 93, 87], // #cf5d57 - светлый карминово-розовый темнее
  pinkTram: [175, 95, 117], // #af5f75 - розовый для трамвайных путей

  lightCherryCraiola: [221, 68, 146], // #dd4492 - светло-вишневый крайола
  lightCherryCraiolaDark: [209, 63, 138], // #d13f8a - светлый карминово-розовый темнее

  yellowTranslucent: [204, 204, 33, 100], // #cccc21 - желтый полупрозрачный

  mediumOrchidColor: [186, 85, 211], // #ba55d3 - умеренный цвет орхидеи
  mediumOrchidColorDark: [170, 91, 199], // #aa5bc7 - умеренный цвет орхидеи темнее

  grayLight: [200, 200, 200], // c8c8c8 - светло серый
  gray: [150, 150, 150], // 969696 - серый
  grayDark: [100, 100, 100], // 646464 - тёмно серый

  green: [39, 174, 96], // 27ae60 - зелёный для мостов
  greenTranslucent: [39, 174, 96, 100], // 27ae6064 - зелёный полупрозрачный для заливки полигонов мостов
  red: [212, 13, 13],
  black: [0, 0, 0],
};
