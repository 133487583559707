import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Deck } from './Deck';
import {
  MapControlsProps,
  MapDisplayModeProps,
  MapDrawModeProps,
  MapLayersProps,
  MapMarkersProps,
  MapMouseCallbacksProps,
  MapStylesProps,
} from './model/Deck.model';
import { DeckContainerProps } from './model/DeckContainer.model';
import { GeoJSON } from 'geojson';
import MapEventObserver from 'store/rakes/MapEventObserver';
import { StateModel } from 'store/reducers';
import { mapMarkersActions } from 'store/reducers/map/mapMarkers/mapMarkers.actions';
import { MapMarker, MarkerSizeMode } from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { initSetMapSelectedObjectSaga } from 'store/sagas/map/mapSelectedObjectSaga';
import { Layers } from 'types/enums/map/layers/Layers';
import {
  getLastItemInEditableLayersHistory,
  replaceGeometryData,
} from 'store/reducers/map/mapboxEditableLayersHistory';
import { setMapContextMenu, setSelectArea } from 'store/reducers/map/actions/mapPanelsActions';
import { replaceDrawDataForStandaloneObject } from 'store/reducers/map/actions/mapboxEditableStandaloneObjectActions';
import { onMapStateChanged } from 'store/reducers/map/actions/mapboxActions';
import { useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { initAddLayerToMapSaga } from 'store/sagas/map/addLayerToMapSaga';
import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';
import { getRegisteredLayer } from 'registrators/map/layers/layersRegistrator';
import { FeatureCollection } from '@nebula.gl/edit-modes';
import { getFromURL } from '../../../../../api/getFromURL';

export const DeckContainer = (props: DeckContainerProps) => {
  const { directoryLayers = [], setDeckViewRef, deckViewRef } = props;

  const dispatch = useDispatch();

  /** Dispatches */
  const addNewLayerHandler = useCallback((Layer: LayerPrototype) => dispatch(initAddLayerToMapSaga(Layer)), [dispatch]);
  const changeMarkerSizeModeHandler = useCallback(
    (layer: Layers, marker: Partial<MapMarker>, sizeMode: MarkerSizeMode) => {
      dispatch(mapMarkersActions.changeMarkerSizeMode(layer, marker, sizeMode));
    },
    [dispatch]
  );
  const initSetMapSelectedObjectSagaHandler = useCallback(
    (data, type, mapMode, componentToRender) =>
      dispatch(initSetMapSelectedObjectSaga(data, type, mapMode, componentToRender)),
    [dispatch]
  );
  const onAddMarkerHandler = useCallback(
    (layer: Layers, marker: Partial<MapMarker>) => dispatch(mapMarkersActions.addMapMarker(layer, marker)),
    [dispatch]
  );
  const onClearMarkersForLayerHandler = useCallback(
    (layer: Layers) => dispatch(mapMarkersActions.clearMarkersForLayer(layer)),
    [dispatch]
  );
  const onMapStateChangedHandler = useCallback(
    (newState) => {
      dispatch(onMapStateChanged(newState));
    },
    [dispatch]
  );
  const onRemoveMarkerHandler = useCallback(
    (layer: Layers, marker: Partial<MapMarker>) => dispatch(mapMarkersActions.removeMapMarker(layer, marker)),
    [dispatch]
  );
  const onReplaceDrawDataForSelectedArea = useCallback(
    (updatedData: FeatureCollection) => dispatch(setSelectArea(updatedData)),
    [dispatch]
  );
  const replaceDrawDataForStandaloneObjectHandler = useCallback(
    (data, lineLength, distanceOfFirstPoint, distanceOfLastPoint, place) =>
      dispatch(replaceDrawDataForStandaloneObject(data, lineLength, distanceOfFirstPoint, distanceOfLastPoint, place)),
    [dispatch]
  );
  const replaceGeometryDataHandler = useCallback((data: GeoJSON) => dispatch(replaceGeometryData(data)), [dispatch]);
  const setMapContextMenuHandler = useCallback(
    ({ x, y, visible, clickedObjectName, selected, coordinate, objectLayerName }) =>
      dispatch(
        setMapContextMenu({
          x,
          y,
          visible,
          clickedObjectName,
          selected,
          coordinate,
          objectLayerName,
        })
      ),
    [dispatch]
  );

  /** States */
  const {
    currentMode,
    drawData,
    enabledLayers,
    layersData,
    mapboxTime,
    mapGlobalPrintMode,
    mapMarkersState,
    mapRulerMode,
    rasterTileEnabled,
    rasterTileStyle,
    recalculated,
    roadColorFromPicker,
    segments,
    selectAreaData,
    selectedObject,
    standaloneEditModeEnabled,
    standaloneEditableObject,
    style,
    viewState,
  } = useSelector((state: StateModel) => {
    let currentSegments;
    if (state?.mapboxData?.roadsSchema) currentSegments = state?.mapboxData?.roadsSchema?.relatedData?.segments;
    if (state?.mapboxData?.tramsSchema) currentSegments = state?.mapboxData?.tramsSchema?.relatedData?.Cables_tram;
    if (state?.mapboxData?.roadSpeedBumps)
      currentSegments = state?.mapboxData?.roadSpeedBumps?.relatedData?.speed_bumps;
    if (state?.mapboxData?.trolleybusTransport)
      currentSegments = state?.mapboxData?.trolleybusTransport?.relatedData?.Cables_troll;
    return {
      currentMode: state.mapMode.currentMode,
      drawData: getLastItemInEditableLayersHistory(state)?.drawData,
      enabledLayers: state.mapboxLayers,
      layersData: state.mapboxData,
      mapboxTime: state.mapboxTime,
      mapGlobalPrintMode: state.mapPanels.GlobalPrintMode,
      mapMarkersState: state.mapMarkers,
      mapRulerMode: state.mapPanels.rulerMode,
      rasterTileEnabled: state.mapPanels.rasterTileEnabled,
      rasterTileStyle: state.mapPanels.rasterStyle,
      recalculated: state.app.recalculated,
      roadColorFromPicker: state.roadColorPickerReducer,
      segments: currentSegments,
      selectAreaData: state.mapPanels.selectAreaData,
      selectedObject: state.mapSelectedObject,
      standaloneEditModeEnabled: state.mapboxEditableStandaloneObject?.enabled,
      standaloneEditableObject: state.mapboxEditableStandaloneObject,
      style: state.mapboxView.style,
      viewState: state.mapboxView.viewState,
    };
  }, shallowEqual);

  const [isStoppedEvent, setIsStoppedEvent] = useState<boolean>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);
  const [isPrintMode, setIsPrintMode] = useState(false);

  // @ts-ignore
  let { params, user } = useParams();
  const query = useQuery();
  if (params === 'print_mode' && !isPrintMode) setIsPrintMode(true);

  const setPrintObject = useCallback(async () => {
    const username = localStorage.getItem('user');
    const zoom = 16.265288008466598;
    // @ts-ignore
    let longitude = parseFloat(query.get('lon'));
    // @ts-ignore
    let latitude = parseFloat(query.get('lat'));

    const backObj = await getFromURL.getDataFromURL(`http://its-api.gor.ekb.lan/print/print_params/${username}`);

    if (!longitude) {
      longitude = 60.6122;
    }
    if (!latitude) {
      latitude = 56.8519;
    }

    const printObject = {
      layersWithAllSettings: backObj.printRequestParams.layersWithAllSettings,
      center: [longitude, latitude],
      zoom,
      bearing: backObj.bearing,
      pitch: backObj.pitch,
      paper_format: 'A4',
      orientation: 'album',
      cartographic_scale: '1000',
      paper_count: '1',
      user: user,
      email: 'aaa@AAAA.aaa',
    };

    if (printObject.layersWithAllSettings) {
      for (let printObjectKey in printObject.layersWithAllSettings) {
        // @ts-ignore
        const LayerInstance = getRegisteredLayer(printObjectKey);
        const copyOfLayerInstance: any = { ...LayerInstance };
        copyOfLayerInstance.relatedData = LayerInstance.relatedData.filter(
          (
            el // @ts-ignore
          ) => printObject.layersWithAllSettings[LayerInstance.name].relatedData.includes(el.name)
        );
        // @ts-ignore
        LayerInstance.customSettings.forEach((el) => {
          // @ts-ignore
          const selector = printObject.layersWithAllSettings[printObjectKey].customSettings?.[el.constructor.name];
          if (selector) el.currentValue = selector.currentValue;
        });
        addNewLayerHandler(LayerInstance);
      }
    }

    const currentObject = backObj.printRequestParams.answer2[`${longitude},${latitude}`];

    // @ts-ignore
    const newViewState = deckViewRef?.fitBounds(
      [
        [currentObject.minLng, currentObject.minLat],
        [currentObject.maxLng, currentObject.maxLat],
      ],
      {
        padding: 0,
      }
    );
    onMapStateChangedHandler({
      viewState: newViewState,
    });
  }, [addNewLayerHandler, deckViewRef, onMapStateChangedHandler, query, user]);

  useEffect(() => {
    if (isPrintMode && !isPrint && deckViewRef) {
      setPrintObject();
      setIsPrint(true);
    }
  }, [deckViewRef, isPrint, isPrintMode, setPrintObject]);

  const onMapClickHandler = useCallback(
    (layerEvent: any, mouseEvent: any) => {
      if (MapEventObserver.checkEventLock()) return;
      if (!isStoppedEvent) {
        if (mouseEvent.rightButton) {
          const { x, y } = mouseEvent.center;
          const activeLayer = enabledLayers.filter((el: any) => el.isSelected);
          setMapContextMenuHandler({
            // TODO magical
            // tslint:disable-next-line:no-magic-numbers
            x: x - 230,
            y,
            visible: true,
            clickedObjectName: 'map',
            selected: null,
            coordinate: null,
            objectLayerName: activeLayer?.[0]?.name,
          });
          setIsStoppedEvent(false);
        }
      }
      setIsStoppedEvent(false);
    },
    [enabledLayers, isStoppedEvent, setMapContextMenuHandler]
  );

  const onClickItemHandler = useCallback(
    (event: any, currentLayerObjectType: any, currentLayerObjectName: any, componentToRender: any = null) => {
      if (MapEventObserver.checkEventLock()) return;
      // @ts-ignore
      const isLeftMouseClick = window.event.button === 0;
      // @ts-ignore
      const isRightMouseClick = window.event.button === 2;
      if (isLeftMouseClick) {
        initSetMapSelectedObjectSagaHandler(
          event,
          currentLayerObjectType.mapObjects || currentLayerObjectType,
          currentMode,
          componentToRender
        );
      } else if (isRightMouseClick) {
        // @ts-ignore
        const { layerX, layerY } = window.event;
        setMapContextMenuHandler({
          // TODO magical
          // tslint:disable-next-line:no-magic-numbers
          x: layerX + 20,
          y: layerY,
          visible: true,
          clickedObjectName: currentLayerObjectType,
          coordinate: event.coordinate,
          selected: event.object,
          objectLayerName: currentLayerObjectName,
        });
        setIsStoppedEvent(true);
      }
    },
    [currentMode, initSetMapSelectedObjectSagaHandler, setMapContextMenuHandler]
  );

  // Комбинирование пропсов
  const mapStyles: MapStylesProps = useMemo(
    () => ({
      style,
      isRasterTileEnabled: rasterTileEnabled,
      rasterTileStyle,
      isStandaloneEditModeEnabled: standaloneEditModeEnabled,
    }),
    [rasterTileEnabled, rasterTileStyle, standaloneEditModeEnabled, style]
  );
  const mapDisplayModeData: MapDisplayModeProps = useMemo(
    () => ({
      selectedObject,
      standaloneEditableObject,
      roadColorFromPicker,
    }),
    [roadColorFromPicker, selectedObject, standaloneEditableObject]
  );
  const mapDrawMode: MapDrawModeProps = useMemo(
    () => ({
      onReplaceGeometryData: replaceGeometryDataHandler,
      onReplaceDrawDataForStandaloneObject: replaceDrawDataForStandaloneObjectHandler,
      drawData,
      mapGlobalPrintMode,
    }),
    [drawData, mapGlobalPrintMode, replaceDrawDataForStandaloneObjectHandler, replaceGeometryDataHandler]
  );
  const mapLayers: MapLayersProps = useMemo(
    () => ({
      selectAreaData,
      layersData,
      enabledLayers,
      segments,
    }),
    [enabledLayers, layersData, segments, selectAreaData]
  );
  const deckCallbacks: MapMouseCallbacksProps = useMemo(
    () => ({
      onItemClick: onClickItemHandler,
      onMapClick: onMapClickHandler,
    }),
    [onClickItemHandler, onMapClickHandler]
  );
  const mapControls: MapControlsProps = useMemo(
    () => ({
      mapboxTime,
      mapRulerMode,
    }),
    [mapRulerMode, mapboxTime]
  );

  const mapMarkers: Partial<MapMarkersProps> = useMemo(
    () => ({
      mapMarkers: mapMarkersState,
      onAddMarker: onAddMarkerHandler,
      onRemoveMarker: onRemoveMarkerHandler,
      onClearMarkersForLayer: onClearMarkersForLayerHandler,
      handleChangeMarkerSizeMode: changeMarkerSizeModeHandler,
    }),
    [
      changeMarkerSizeModeHandler,
      mapMarkersState,
      onAddMarkerHandler,
      onClearMarkersForLayerHandler,
      onRemoveMarkerHandler,
    ]
  );

  return (
    <Deck
      deckCallbacks={deckCallbacks}
      directoryLayers={directoryLayers}
      mapDisplayModeData={mapDisplayModeData}
      mapDrawMode={mapDrawMode}
      mapLayers={mapLayers}
      mapMarkers={mapMarkers}
      mapStyles={mapStyles}
      mapControls={mapControls}
      onReplaceDrawDataForSelectedArea={onReplaceDrawDataForSelectedArea}
      recalculated={recalculated}
      setDeckViewRef={setDeckViewRef}
      viewState={viewState}
      isPrint={isPrint}
      isPrintMode={isPrintMode}
      updateViewState={onMapStateChangedHandler}
    />
  );
};
