import { MapReducer } from '../reducers.model';
import { handleActions, Reducer } from 'redux-actions';
import { reportsActions } from './reports.actions';
import { ReportsStateI } from './reports.model';

const { SET_EXTENSION, SET_CHECKED_ITEM, SET_CHECKED_ITEM_ANOTHER, SET_TIME_START, SET_TIME_END } = reportsActions;

const initialState = {
  name: null,
  email: null,
  headers: null,
  owner: null,
  checkedItems: null,
  select: null,
  extension: null,
  timeStart: null,
  timeEnd: null,
  checkedItemsAnother: null,
};

const setExtensionReducer: Reducer<ReportsStateI, { extension: string | null }> = (state, action) => {
  const { extension } = action.payload;
  return {
    ...state,
    extension,
  };
};

const setCheckedItem: Reducer<ReportsStateI, { checkedItems: Array<any> | null }> = (state, action) => {
  const { checkedItems } = action.payload;
  return {
    ...state,
    checkedItems,
  };
};

const setCheckedItemAnother: Reducer<ReportsStateI, { checkedItemsAnother: Array<any> | null }> = (state, action) => {
  const { checkedItemsAnother } = action.payload;
  return {
    ...state,
    checkedItemsAnother,
  };
};

const setTimeStart: Reducer<ReportsStateI, { timeStart: string | null }> = (state, action) => {
  const { timeStart } = action.payload;
  return {
    ...state,
    timeStart,
  };
};

const setTimeEnd: Reducer<ReportsStateI, { timeEnd: string | null }> = (state, action) => {
  const { timeEnd } = action.payload;
  return {
    ...state,
    timeEnd,
  };
};

const mapReducer: MapReducer<ReportsStateI, any> = {
  [SET_EXTENSION]: setExtensionReducer,
  [SET_CHECKED_ITEM]: setCheckedItem,
  [SET_CHECKED_ITEM_ANOTHER]: setCheckedItemAnother,
  [SET_TIME_START]: setTimeStart,
  [SET_TIME_END]: setTimeEnd,
};

export const reportsReducer: Reducer<any, any> = handleActions(mapReducer, initialState);
