import { IconLayer, PathLayer } from '@deck.gl/layers';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { publicTransportIconMapper } from '../../iconMappers/publicTransportIconMapper';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { PublicTransportDataAccessor } from 'registrators/map/layers/description/dataAccessors/ekb/PublicTransportDataAccessor';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { BusStop, PublicTransportService, RouteSegment } from '../../../generated/api/api';
import ITSLayer from '../../../types/classes/ITSLayer';
import { UpdateStateInfo } from '@deck.gl/core/lib/layer';
import { layerColorsMapper } from '../../colorMappers/layerColorsMapper';
import { AnyObject } from '../../../types/enums/general/general.model';
import { DSelector } from 'services/map/Dselector/DSelector';

interface resultsItem {
  id: number;
  length: number;
  name: string;
  route: number;
  route_name: string;
  route_segments: any[];
}

const emptyPath = 'SRID=4326;LINESTRING EMPTY';

class PublicTransportCompositeLayer extends ITSLayer<any, any> {
  updateState({ changeFlags, props }: UpdateStateInfo<any>) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof PublicTransportCompositeLayer) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }
    this.setState({
      data: props,
    });
  }

  async selectBusStop(object: AnyObject) {
    const { results } = await PublicTransportService.publicTransportRouteSchemesList({ stopId: object.id });
    // @ts-ignore
    const line_path = results.map((i: resultsItem) => i.route_segments).flat();
    this.setState({
      routes: line_path,
    });
  }

  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      this.setState({ selected: event.info?.object });
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.publicTransport;
        if (event.sourceLayer.id.includes('bus-stop')) {
          selectedObjectType = MapObjects.busStops;
          this.selectBusStop(event.info.object);
        }
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    let dataPathSelect = {};
    if (this.props?.selectedObject?.data) {
      if (this.props?.selectedObject?.data[0].data?.results) {
        dataPathSelect = this.props?.selectedObject?.data[0].data?.results;
      }
    }
    const object = PublicTransportDataAccessor.getSelectedType();
    return [
      new PathLayer<RouteSegment>({
        id: 'routes',
        data: this.state.routes,
        widthScale: 3,
        widthMinPixels: 2,
        widthMaxPixels: 12,
        capRounded: true,
        billboard: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPath: (d) =>
          d.line_path === emptyPath ? [[0, 0]] : (getCoordsFromParsedGeometry(d.line_path) as [number, number][]),
        opacity: Object.keys(dataPathSelect)?.length !== 0 ? 0.1 : 5,
        updateTriggers: {
          getColor: [this.state.selectedObject, object],
          getWidth: this.state.selectedObject,
          getPath: this.state.selectedObject,
        },
        getColor: () => {
          if (object === 'bus_stops') {
            return [0, 0, 0, 0];
          } else {
            return layerColorsMapper.blueTransparent;
          }
        },
        getWidth: 5,
      }),
      new IconLayer<BusStop>({
        id: 'bus-stop',
        data: this.props.relatedData.bus_stops,
        pickable: true,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/busStops.png`,
        iconMapping: publicTransportIconMapper,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point) as [number, number];
          }
          return [0, 0];
        },
        getIcon: (d) => {
          const selectedObjectId =
            this.props?.selectedObject?.selectedObject?.object?.id || this.props?.selectedObject?.selectedObject?.id;
          const statusChecked = d.id === selectedObjectId ? 'isSelectedBlue' : 'nonSelectedBlue';
          const statusNotChecked = d.id === selectedObjectId ? 'busStopSelectedGray' : 'nonBusStopGray';

          if (d.is_checked) {
            return statusChecked;
          } else {
            return statusNotChecked;
          }
        },
        updateTriggers: {
          getIcon: [this.state.selectedObject, object],
        },
        opacity: Object.keys(dataPathSelect)?.length !== 0 ? 0.1 : 5,
      }),
    ];
  }
}

PublicTransportCompositeLayer.layerName = Layers.publicTransport;

export default PublicTransportCompositeLayer;
