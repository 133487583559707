import React, { useEffect, useState } from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { getFromURL } from '../../../api/getFromURL';
import { SelectedItemTypeEnum } from '../../../types/enums/map/SelectedItemTypeEnum';

const OverpassesSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  let [segments, setSegments] = useState('');

  useEffect(() => {
    let overpassesSegments = '';
    if (data.segments) {
      data.segments.map(() =>
        getFromURL
          .getDataFromURL(`artificial_structures/segments_overpasses/?segment__in=${data.segments}`)
          .then((res) => {
            if (overpassesSegments === '') {
              res.results.map((el) => setSegments((overpassesSegments += `${el.segment_name}, `)));
            }
          })
      );
    }
  }, [data.segments]);

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'overpasses');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.artificial_structures_overpasses}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];
  const properties = data;

  properties.name &&
    Descriptions.push({
      key: 'Наименование',
      value: properties.name,
    });

  Descriptions.push({
    key: 'Сегменты ИС',
    value: segments,
  });

  properties.overpass_type &&
    Descriptions.push({
      key: 'Вид сооружения',
      value: SelectedItemTypeEnum[properties.overpass_type] ?? 'Не указан',
    });

  properties.barrier_name &&
    Descriptions.push({
      key: 'Наименование перекрываемого препятствия',
      value: properties.barrier_name,
    });

  properties.barrier_type &&
    Descriptions.push({
      key: 'Тип перекрываемого препятствия',
      value: SelectedItemTypeEnum[properties.barrier_type] ?? 'Не указан',
    });

  properties.extended_code &&
    Descriptions.push({
      key: 'Расширенный код',
      value: properties.extended_code,
    });

  properties.location_km &&
    Descriptions.push({
      key: 'Местоположение, км+',
      value: properties.location_km,
    });

  properties.building_length &&
    Descriptions.push({
      key: 'Длина сооружения, м',
      value: properties.building_length,
    });

  properties.height_dimension &&
    Descriptions.push({
      key: 'Габарит по высоте, м',
      value: properties.height_dimension,
    });

  properties.width_dimension &&
    Descriptions.push({
      key: 'Габрит по ширине, м',
      value: properties.width_dimension,
    });

  properties.total_width &&
    Descriptions.push({
      key: 'Полная ширина, м',
      value: properties.total_width,
    });

  properties.project_organization_name &&
    Descriptions.push({
      key: 'Проектная организация',
      value: properties.project_organization_name,
    });

  properties.building_organization_name &&
    Descriptions.push({
      key: 'Строительная организация',
      value: properties.building_organization_name,
    });

  properties.operating_organization_name &&
    Descriptions.push({
      key: 'Эксплуатирующая организация',
      value: properties.operating_organization_name,
    });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об объекте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

OverpassesSelection.propTypes = {};

export default OverpassesSelection;
