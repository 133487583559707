import { groupOfLayersItem, layersItem } from '../Types/LayersGalleryTypes';
import _ from 'lodash';
import { LayerGroupOrder } from '../../../../../../../../../../../../../types/enums/map/layers/LayerGroupsEnums';
import { sortOneArrayByAnother } from '../../../../../../../../../../../../../services/data/sortOneArrayByAnother';

export const groupingLayerGalleryItemsWithSort = (arrayOfLayerGalleryItems: layersItem[]) => {
  const groupedLayerGalleryItems = arrayOfLayerGalleryItems.reduce(
    (accumulatorValue: groupOfLayersItem[], currentElement: layersItem) => {
      const indexOfCurrentElement = accumulatorValue.findIndex(
        (el: { label: string }) => el.label === currentElement.group.getLabel
      );
      if (indexOfCurrentElement !== -1) {
        const deepCopyOfAccum: groupOfLayersItem[] = _.cloneDeep(accumulatorValue);
        deepCopyOfAccum[indexOfCurrentElement].layersGalleryItems.push(currentElement);
        return deepCopyOfAccum;
      }
      return [
        ...accumulatorValue,
        {
          label: currentElement.group.getLabel,
          decryption: currentElement.group.getDecryption,
          layersGalleryItems: [currentElement],
        },
      ];
    },
    []
  );

  return sortOneArrayByAnother(groupedLayerGalleryItems, LayerGroupOrder, 'label');
};
