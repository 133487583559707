import { Form, InputNumber } from 'antd';
import React, { useMemo } from 'react';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import { Styled } from '../styled/BaseInput.styled';
import { useBaseInputPlaceholderControl } from '../hooks/useBaseInputPlaceholderControl';
import { checkConditionAndCallFn } from '../../../../services/common/checkConditionAndCallFn';
import { useBaseIntegerInputSettings } from '../hooks/useBaseIntegerInputSettings';
import { useDispatch, useSelector } from 'react-redux';
import { setEditingCardValue } from '../../../../store/reducers/map/actions/mapboxEditableStandaloneObjectActions';

const { getBaseStylesForInput, BasePlaceholder, BaseErrorMessage } = Styled;

let StyledInputNumber = getBaseStylesForInput(InputNumber);
const StyledPlaceholder = styled(BasePlaceholder)`
  &.opened {
    transform: translateY(-22px);
  }
`;

const IntegerInput = (props) => {
  const { control, errors, setValue } = useFormContext();
  const { description, object, field, className, step } = props;
  const { minValue, required, message, maxValue } = useBaseIntegerInputSettings(description);
  const { placeholderState, setPlaceholderState } = useBaseInputPlaceholderControl(field, object, setValue);
  const editableObjectInstance = useSelector((state) => state?.mapboxEditableStandaloneObject?.instance);
  const mapMode = useSelector((state) => state?.mapMode?.currentMode);
  const typeOfGeometryEditableStandaloneObject = useSelector(
    (state) => state?.mapboxEditableStandaloneObject?.geometryKey
  );
  const dispatch = useDispatch();

  const isError = errors[field];

  const classNameError = useMemo(() => {
    if (isError) return 'class_errors';
    if (placeholderState) return 'opened';
    return '';
  }, [placeholderState, isError]);

  if (errors[field]?.message?.includes(description.label) && errors[field]) {
    errors[field].message = null;
  }

  return (
    <Form.Item key={'form_' + field} name={field} className={`${className} ${classNameError}`}>
      <Controller
        className={classNameError}
        as={StyledInputNumber}
        name={field}
        step={step}
        key={'input_' + field}
        disabled={description.read_only}
        control={control}
        rules={{
          required: { value: required, message: message },
          min: minValue,
          max: maxValue,
        }}
        onFocus={() => {
          setPlaceholderState(true);
        }}
        onBlur={(e) => {
          if (
            mapMode === 'standaloneEdit' &&
            (typeOfGeometryEditableStandaloneObject === 'sub_line_path' || 'line_path')
          ) {
            if (e?.[0]?.target?.value) {
              editableObjectInstance[e?.[0]?.target?.name] = e?.[0]?.target?.value;
              if (e?.[0]?.target?.name === 'start_segment' && editableObjectInstance?.segment_length) {
                editableObjectInstance.end_segment = `${(
                  Number.parseFloat(editableObjectInstance.start_segment) +
                  Number.parseFloat(editableObjectInstance.segment_length)
                ).toFixed(3)}`;
                dispatch(setEditingCardValue(editableObjectInstance));
              }
              if (e?.[0]?.target?.name === 'end_segment' && editableObjectInstance?.start_segment) {
                editableObjectInstance.segment_length = `${(
                  Number.parseFloat(editableObjectInstance.end_segment) +
                  Number.parseFloat(editableObjectInstance.start_segment)
                ).toFixed(3)}`;
                dispatch(setEditingCardValue(editableObjectInstance));
              }
              if (e?.[0]?.target?.name === 'segment_length' && editableObjectInstance?.start_segment) {
                editableObjectInstance.end_segment = `${(
                  Number.parseFloat(editableObjectInstance.segment_length) +
                  Number.parseFloat(editableObjectInstance.start_segment)
                ).toFixed(3)}`;
                dispatch(setEditingCardValue(editableObjectInstance));
              }
            }
          }
          checkConditionAndCallFn(!e[0].target.value, () => {
            setPlaceholderState(false);
          });
        }}
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
      <StyledPlaceholder className={classNameError}>{description.label}</StyledPlaceholder>
    </Form.Item>
  );
};

export default IntegerInput;
