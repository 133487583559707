const ROAD_SECTION_COLOR_FROM_PICKER = 'ROAD_SECTION_COLOR_FROM_PICKER';
const TRAM_CABLE_COLOR_FROM_PICKER = 'TRAM_CABLE_COLOR_FROM_PICKER';
const TROLL_CABLE_COLOR_FROM_PICKER = 'TROLL_CABLE_COLOR_FROM_PICKER';
const SUPPLY_CABLE__COLOR_FROM_PICKER = 'SUPPLY_CABLE__COLOR_FROM_PICKER';

export function roadSectionColorFromPickerAC(color: Array<number>) {
  return {
    type: ROAD_SECTION_COLOR_FROM_PICKER,
    payload: color,
  };
}

export function tramCableColorFromPickerAC(color: Array<number>) {
  return {
    type: TRAM_CABLE_COLOR_FROM_PICKER,
    payload: color,
  };
}

export function trollCableColorFromPickerAC(color: Array<number>) {
  return {
    type: TROLL_CABLE_COLOR_FROM_PICKER,
    payload: color,
  };
}

export function supplyCableColorFromPickerAC(color: Array<number>) {
  return {
    type: SUPPLY_CABLE__COLOR_FROM_PICKER,
    payload: color,
  };
}

const initialState = {
  roadSectionColor: [146, 159, 181, 255],
  tramCableColor: [124, 119, 185, 255],
  supplyCable: [20, 255, 247],
  trollCableColor: [240, 128, 128, 255],
};

export function roadColorPickerReducer(state = initialState, action: any) {
  switch (action.type) {
    case ROAD_SECTION_COLOR_FROM_PICKER:
      return {
        ...state,
        roadSectionColor: action.payload,
      };
    case TRAM_CABLE_COLOR_FROM_PICKER:
      return {
        ...state,
        tramCableColor: action.payload,
      };
    case SUPPLY_CABLE__COLOR_FROM_PICKER:
      return {
        ...state,
        supplyCable: action.payload,
      };
    case TROLL_CABLE_COLOR_FROM_PICKER:
      return {
        ...state,
        trollCableColor: action.payload,
      };
    default:
      return state;
  }
}
