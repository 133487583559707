/**
 * Возвращает utf8 символы кодов 1 - 1200
 * @returns {Array<char>}
 */
export const generateUTF8CharacterSet = () => {
  const result = [];
  for (let it = 1; it < 1200; it++) {
    result.push(String.fromCharCode(it));
  }
  return result;
};
