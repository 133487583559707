export const roadObjectsIconMapping = {
  overhead_communications_white: { x: 0, y: 0, width: 128, height: 128 },
  overhead_communications_color: { x: 0, y: 129, width: 128, height: 128 },
  road_blocking_white: { x: 129, y: 0, width: 128, height: 128 },
  road_blocking_color: { x: 129, y: 129, width: 128, height: 128 },
  road_fences_white: { x: 0, y: 258, width: 128, height: 128 },
  road_fences_color: { x: 129, y: 258, width: 128, height: 128 },
  speed_bump_white: { x: 258, y: 0, width: 128, height: 128 },
  crosswalk_white: { x: 258, y: 129, width: 128, height: 128 },
  overhead_passage_white: { x: 258, y: 258, width: 128, height: 128 },
  underground_crossing_white: { x: 0, y: 387, width: 128, height: 128 },
  underground_crossing_color: { x: 129, y: 387, width: 128, height: 128 },
  overhead_passage_color: { x: 258, y: 387, width: 128, height: 128 },
  crosswalk_color: { x: 387, y: 0, width: 128, height: 128 },
  speed_bump_color: { x: 387, y: 129, width: 128, height: 128 },
  support_pillars_white: { x: 387, y: 258, width: 128, height: 128 },
  support_pillars_color: { x: 387, y: 387, width: 128, height: 128 },
  rest_area_white: { x: 0, y: 516, width: 128, height: 128 },
  rest_area_color: { x: 129, y: 516, width: 128, height: 128 },
  substations_white: { x: 258, y: 516, width: 128, height: 128 },
  substations_color: { x: 387, y: 516, width: 128, height: 128 },
  road_work_white: { x: 516, y: 0, width: 128, height: 128 },
  road_work_color: { x: 516, y: 129, width: 128, height: 128 },
  alarm_color: { x: 516, y: 258, width: 128, height: 128 },
  alarm_white: { x: 516, y: 387, width: 128, height: 128 },
  bridge_white: { x: 516, y: 516, width: 128, height: 128 },
  bridge_color: { x: 0, y: 645, width: 128, height: 128 },
  lighting_white: { x: 129, y: 645, width: 128, height: 128 },
  lighting_color: { x: 258, y: 645, width: 128, height: 128 },
  tray_systems_white: { x: 387, y: 645, width: 128, height: 128 },
  tray_systems_color: { x: 516, y: 645, width: 128, height: 128 },
  overpass_white: { x: 645, y: 0, width: 128, height: 128 },
  tunnel_white: { x: 645, y: 129, width: 128, height: 128 },
  tunnel_color: { x: 645, y: 258, width: 128, height: 128 },
  rail_crossing_white: { x: 645, y: 387, width: 128, height: 128 },
  rail_crossing_color: { x: 645, y: 516, width: 128, height: 128 },
  dock_white: { x: 645, y: 645, width: 128, height: 128 },
  ferry_crossing_white: { x: 0, y: 774, width: 128, height: 128 },
  pipe_white: { x: 129, y: 774, width: 128, height: 128 },
  pipe_color: { x: 258, y: 774, width: 128, height: 128 },
  pipeline_white: { x: 387, y: 774, width: 128, height: 128 },
  weather_station_white: { x: 516, y: 774, width: 128, height: 128 },
  weather_station_color: { x: 645, y: 774, width: 128, height: 128 },
  pipeline_color: { x: 774, y: 0, width: 128, height: 128 },
  ferry_crossing_color: { x: 774, y: 129, width: 128, height: 128 },
  white_box: { x: 774, y: 258, width: 128, height: 128 },
  overpass_color: { x: 774, y: 387, width: 128, height: 128 },
  dock_color: { x: 774, y: 516, width: 128, height: 128 },
  grey_box: { x: 774, y: 645, width: 128, height: 128 },
  signal_poles_white: { x: 774, y: 774, width: 128, height: 128 },
  signal_poles_color: { x: 0, y: 903, width: 128, height: 128 },
  traffic_light_wb_white: { x: 129, y: 903, width: 128, height: 128 }, //черно-белый
  traffic_light_wb_color: { x: 258, y: 903, width: 128, height: 128 },
  support_wall_white: { x: 387, y: 903, width: 128, height: 128 },
  support_wall_color: { x: 516, y: 903, width: 128, height: 128 },
  traffic_light_white: { x: 903, y: 0, width: 128, height: 128 },
  traffic_light_color: { x: 903, y: 129, width: 128, height: 128 },
  contours_lawn_white: { x: 0, y: 1032, width: 128, height: 128 },
  contours_lawn_color: { x: 129, y: 1032, width: 128, height: 128 },
  town_squares_white: { x: 258, y: 1032, width: 128, height: 128 },
  town_squares_color: { x: 387, y: 1032, width: 128, height: 128 },
};
