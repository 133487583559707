import styled from 'styled-components';
import { LegendItemProps } from '../model/LineChart.model';

const LineChartWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LineChartLegend = styled.div`
  margin-top: 16px;
  padding-bottom: 5px;
  margin-bottom: -5px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const LineChartLegendWrap = styled.div`
  width: max-content;
  display: flex;
`;

const LineChartLegendItem = styled.div<LegendItemProps>`
  padding-top: 12px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  position: relative;
  min-width: 60px;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : '#6A7B9B')};

  &::before {
    content: '';
    position: absolute;
    height: 4px;
    width: 60px;
    top: 0;
    left: 0;
    background-color: ${(props) => (props.color ? props.color : '#6A7B9B')};
    border-radius: 8px;
  }

  &:not(:first-of-type) {
    margin-left: 16px;
  }
`;

export const Styled = {
  LineChartWrap,
  LineChartLegendWrap,
  LineChartLegend,
  LineChartLegendItem,
};
