import React, { createContext, useMemo } from 'react';
import LayerMainPanelContainer from './LayersPanels/LayerMainPanel/LayerMainPanelContainer';
import { baseStyles } from 'components/stylesRegistrator';
import { Button } from 'UI/common/components/Button/Button';
import { BurgerIcon } from 'UI/custom/icons/actionIcons/BurgerMenuIcon';
import { LayerSettingsContainer } from './LayersPanels/LayersAdditionalPanels/LayerSettings/LayerSettingsContainer';
import { getAllRegisteredLayers } from 'registrators/map/layers/layersRegistrator';
import { layersItem } from './LayersPanels/LayerMainPanel/LayersMenuContent/DisplayedLayers/LayersGallery/Types/LayersGalleryTypes';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { iconSizesKit } from 'styles/utils/iconSizesKit';
import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';

export const LayersItemsContext = createContext<layersItem[] | null>(null);

const { BaseMapPanelLayoutAreaInner } = baseStyles.modules.map;

interface ILayersMenu {
  showLayersSettings: boolean;
  toggleMainPanelCollapse: () => void;
  mainPanelCollapseStatus: boolean;
}

export const LayersMenu = ({ showLayersSettings, toggleMainPanelCollapse, mainPanelCollapseStatus }: ILayersMenu) => {
  const layersItems: layersItem[] = useMemo(() => {
    const allLayersLink = getAllRegisteredLayers();
    const arrayOfLayersItems: layersItem[] = [];
    for (let key in allLayersLink) {
      const Layer: LayerPrototype = allLayersLink[key as keyof typeof allLayersLink];
      arrayOfLayersItems.push({
        name: Layer.getName(),
        customSettings: Layer.getCustomSettings(),
        card: Layer.getCard(),
        group: Layer.getGroup(),
        isSelected: false,
      });
    }
    return arrayOfLayersItems;
  }, []);

  const layersMenuContent = useMemo(() => {
    if (mainPanelCollapseStatus) {
      return (
        <Button
          onClick={toggleMainPanelCollapse}
          rounded
          type={ButtonType.primaryReverse}
          icon={<BurgerIcon />}
          hasShadow
          iconSize={iconSizesKit.default}
        />
      );
    } else {
      return (
        <LayersItemsContext.Provider value={layersItems}>
          <BaseMapPanelLayoutAreaInner>
            <LayerMainPanelContainer />
            {showLayersSettings && <LayerSettingsContainer />}
          </BaseMapPanelLayoutAreaInner>
        </LayersItemsContext.Provider>
      );
    }
  }, [layersItems, mainPanelCollapseStatus, showLayersSettings, toggleMainPanelCollapse]);

  return layersMenuContent;
};
