import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { getFromURL } from 'api/getFromURL';
import {
  FiltersService,
  OrganizationsService,
  PermissionsService,
  StatesService,
  UserDistricts,
  UserOrganizations,
} from 'generated/api/api';
import { StateModel } from 'store/reducers';
import { getMainDirectory } from 'store/reducers/directory/directoryHistory';
import { getLastSelectedObject } from 'store/reducers/directory/selectedObject';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { rolesDirectoryActions } from 'store/reducers/rolesDirectory/rolesDirectory.actions';
import {
  DirectoryElement,
  ParentDirectory,
  PermissionElement,
  RoleElement,
  RolesDirectoryArrayDataFields,
  RolesDirectoryFields,
  RoleState,
} from 'store/reducers/rolesDirectory/rolesDirectory.model';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { initReloadLastInlineHistoryDataSaga } from 'store/sagas/directory/inlineHistorySaga';
import styled from 'styled-components';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { TypeDisplayedDirectoryEnum, TypeOfRevertEnum } from 'types/enums/roles/inlineHistory.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import AutocompleteForm from 'UI/custom/objectPanels/Common/AutocompleteForm/AutocompleteForm';
import _ from 'underscore';

import InputForm from '../ApplicationForm/InputForm';
import { DirectoriesEnum } from '../enums/DirectoriesEnum';
import PermissionsPanelSider from '../PanelSider/PermissionsPanelSider';
import { TabPageContainer } from '../Templates/TabPageContainer';
import { Styled } from './PermissionsPanel.styled';
import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { AnyObject } from 'types/enums/general/general.model';
import ScrollableBoxArea from 'UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';
import { getPreparedData } from 'services/net/getPreparedData';
import { RequestPath } from '../../../../../types/enums/routes/request/RequestPath';

const StyledDrawer = styled.div`
  padding: 0;
`;

const { PanelLayout } = Styled;

interface DirectoryModel {
  [key: string]: {
    id: number;
    model: string;
    name: string;
  }[];
}

interface LayersModel {
  [key: string]: {
    [key: string]: number[];
  };
}

interface ReportsModel {
  id: number;
  name: string;
}

const createNewList = <
  T extends {
    value?: string;
    name?: string;
    key?: number;
    id: number;
  }
>(
  list: T[] = []
) =>
  list.map((el) => ({
    value: el.value ?? el.name ?? '',
    isChecked: false,
    id: el.key ?? el.id,
  }));

const usersFilters = <T extends { id: number }>(fullList: T[], userList: number[]) =>
  fullList?.filter((el: { id: number }) => userList.some((filter: number) => filter === el.id));

const otherFilters = <T extends { id: number }>(fullList: T[], userList: number[]) =>
  fullList?.filter((el: { id: number }) => userList.every((filter: number) => filter !== el.id));

const getIdList = (roles: RoleElement[]) =>
  _.uniq(
    roles
      ?.map((checked) => checked.id ?? 0)
      .flat()
      .filter((el) => el)
  );

export interface PermissionsPanelProps {
  revertToInlineHistoryRecordByType: (type: TypeOfRevertEnum, typeName: TypeDisplayedDirectoryEnum) => void;
  model: ModelResponse;
  data?: AnyObject[] | AnyObject;
  urlData: string;
}

const PermissionsPanel = (props: PermissionsPanelProps) => {
  const mainDirectoryHistory: AnyObject = useSelector((state: StateModel) => getMainDirectory(state));
  const rolesDirectory: RoleState = useSelector((state: StateModel) => state.rolesDirectory);
  const dispatch = useDispatch();
  const { revertToInlineHistoryRecordByType, model, data, urlData } = props;
  const {
    params: { folder },
  } = useRouteMatch<{ folder: DirectoriesEnum }>();
  const { checkedRoles, checkedOrganizationsList, checkedDistrictsList } = rolesDirectory;
  const [pageMenuKey, setPageMenuKey] = useState<DirectoriesEnum>(DirectoriesEnum.main);
  const [submitData, setSubmitData] = useState<AnyObject | AnyObject[]>(data ?? {});
  const [dateTime, setDateTime] = useState<AnyObject>({});
  const [url, setUrl] = useState<string>();

  const notificationAlert = useCallback(
    (e: AnyObject | null = {}, message?: string | null, clearAlert?: boolean) => {
      if (clearAlert) {
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
        return;
      }
      let messages = ['Неизвестная ошибка обратитесь к администратору'];
      if (e?.response && _.isObject(e.response)) {
        messages = createAlertMessage(e);
      }
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: message ? PromptType.success : PromptType.error,
          title: message ? 'Успешно' : 'Ошибка',
          message: message ?? messages,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      if (pageMenuKey === DirectoriesEnum.main && folder === DirectoriesEnum.permissions_groups && !_.isArray(data)) {
        const promiseDirectoryPermissions = PermissionsService.permissionsGetDirectoryRetrieve();
        const promiseLayersPermissions = PermissionsService.permissionsGetLayersRetrieve();
        const promiseReportsPermissions = PermissionsService.permissionsGetReportsRetrieve();
        const promiseWidgetsPermissions = PermissionsService.permissionsGetWidgetsRetrieve();
        // @ts-ignore Ошибка в апи у БЕКа
        const directoryPermissions: DirectoryModel = await promiseDirectoryPermissions;
        // @ts-ignore Ошибка в апи у БЕКа
        const layersPermissions: LayersModel[] = await promiseLayersPermissions;
        // @ts-ignore Ошибка в апи у БЕКа
        const reportsPermissions: ReportsModel[] = await promiseReportsPermissions;
        // @ts-ignore Ошибка в апи у БЕКа
        const widgetsPermissions: any[] = await promiseWidgetsPermissions;
        console.log(widgetsPermissions);
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(
            {
              get_directory: directoryPermissions,
              get_layers: layersPermissions,
              get_reports: reportsPermissions,
            },
            RolesDirectoryFields.directoryData
          )
        );
        const rolePermissions: number[] = data?.permissions ?? [];

        // Автозаполнение прав по справочникам
        const checkingDirectory: DirectoryElement[] = [];
        const checkedDirectory: DirectoryElement[] = [];
        const checkingDirectoryPermissions: RoleElement[] = [];
        const checkedDirectoryPermissions: RoleElement[] = [];
        Object.keys(directoryPermissions).forEach((directory) => {
          if (
            directoryPermissions[directory]?.every((permission) =>
              rolePermissions.some((s) => {
                if (s === permission.id) {
                  checkedDirectoryPermissions.push({
                    value: permission.name,
                    isChecked: true,
                    id: permission.id,
                  });
                  return true;
                }
                return false;
              })
            )
          ) {
            checkedDirectory.push({
              value: directory,
              isChecked: true,
            });
            checkingDirectoryPermissions.push(
              ...createNewList(
                directoryPermissions[directory].filter((permission) =>
                  rolePermissions.every((s) => s !== permission.id)
                )
              )
            );
          } else {
            checkingDirectory.push({
              value: directory,
              isChecked: false,
            });
          }
        });
        dispatch(
          rolesDirectoryActions.changePartArrayDataByKeyActions(
            checkedDirectory,
            RolesDirectoryArrayDataFields.checkedDirectory
          )
        );
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(checkingDirectory, RolesDirectoryFields.checkingDirectory)
        );
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(
            getIdList(checkedDirectoryPermissions),
            RolesDirectoryFields.idForRightDirec
          )
        );

        // Автозаполнение прав по картам
        const checkedMap: ParentDirectory[] = [];
        const checkingMap: ParentDirectory[] = [];
        const checkingMapPermissions: RoleElement[] = [];
        const checkedMapPermissions: RoleElement[] = [];
        const permissionsMap: PermissionElement[] = [];
        layersPermissions.forEach((layers) => {
          Object.keys(layers).forEach((value) => {
            let checkedObject = null;
            Object.keys(layers?.[value]).forEach((valuePermission) => {
              permissionsMap.push({
                value,
                permission: valuePermission,
                isChecked: false,
                id: layers[value][valuePermission],
              });
              if (
                layers?.[value]?.[valuePermission]?.every((id) =>
                  rolePermissions.some((permission) => permission === id)
                )
              ) {
                checkedObject = layers;
                checkedMapPermissions.push({
                  value: `${value} (${valuePermission})`,
                  isChecked: true,
                  id: layers[value][valuePermission],
                });
              }
            });
            if (checkedObject) {
              checkedMap.push({
                value,
                isChecked: true,
                data: layers[value],
              });
            } else {
              checkingMap.push({
                value,
                isChecked: false,
                data: layers[value],
              });
            }
          });
        });
        permissionsMap.forEach((perm) => {
          if (
            checkedMapPermissions.some((checkedMapPermission) => checkedMapPermission.value.includes(perm.value)) &&
            checkedMapPermissions.every((checkedPermission) => checkedPermission.id !== perm.id)
          ) {
            checkingMapPermissions.push({
              value: `${perm.value} (${perm.permission})`,
              isChecked: false,
              id: perm.id,
            });
          }
        });
        dispatch(rolesDirectoryActions.replaceFieldByKeyActions(permissionsMap, RolesDirectoryFields.permissionsMap));
        dispatch(rolesDirectoryActions.replaceFieldByKeyActions(checkedMap, RolesDirectoryFields.checkedMap));
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(_.flatten(checkingMap), RolesDirectoryFields.checkingMap)
        );
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(
            getIdList(checkedMapPermissions),
            RolesDirectoryFields.idForRightMap
          )
        );

        // Автозаполнение прав по отчетам
        const item = createNewList(reportsPermissions);
        const checkingReports: RoleElement[] = [];
        const checkedReports: RoleElement[] = [];
        item.forEach((report) => {
          if (rolePermissions.some((el) => el === report.id)) checkedReports.push({ ...report, isChecked: true });
          else checkingReports.push(report);
        });
        dispatch(rolesDirectoryActions.replaceFieldByKeyActions(checkedReports, RolesDirectoryFields.checkedReports));
        dispatch(rolesDirectoryActions.replaceFieldByKeyActions(checkingReports, RolesDirectoryFields.checkingReports));
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(
            getIdList(checkedReports),
            RolesDirectoryFields.idForRightReports
          )
        );

        // Автозаполнение 3 колонки
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(
            {
              get_directory: checkingDirectoryPermissions,
              get_layers: checkingMapPermissions,
            },
            RolesDirectoryFields.directoryDataFiltered
          )
        );
        // Автозаполнение 4 колонки
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(
            {
              get_directory: checkedDirectoryPermissions,
              get_layers: checkedMapPermissions,
            },
            RolesDirectoryFields.clickedRight
          )
        );
      } else if (folder === DirectoriesEnum.users) {
        if (pageMenuKey === DirectoriesEnum.filters_user) {
          const promiseAllOrganizations = OrganizationsService.organizationsList({ representation: `${true}` });
          const promiseAllDistricts = StatesService.statesDistrictsList({ representation: `${true}` });
          let resolveUserOrganizations: UserOrganizations[] = [];
          let resolveUserDistricts: UserDistricts[] = [];
          let resolveAllOrganizations: AnyObject = {};
          let resolveAllDistricts: AnyObject = {};
          if (!_.isArray(data) && data?.id) {
            // TODO в апи не проработаны передаваемые параметры по этим урлам (задача БЕКу на переделку) Тех-долг-FRONTEND #5622
            const userOrganizations = FiltersService.filtersUserOrganizationsList({ user: `${data?.id}` });
            const userDistricts = FiltersService.filtersUserDistrictsList({ user: `${data?.id}` });
            try {
              resolveUserOrganizations = await userOrganizations;
              resolveUserDistricts = await userDistricts;
            } catch (e) {
              if (_.isObject(e)) notificationAlert(e);
            } finally {
              notificationAlert(null, null, true);
            }
          }
          try {
            resolveAllOrganizations = await promiseAllOrganizations;
            resolveAllDistricts = await promiseAllDistricts;
          } catch (e) {
            if (_.isObject(e)) notificationAlert(e);
          } finally {
            notificationAlert(null, null, true);
          }

          const organizationList = createNewList(resolveAllOrganizations?.results);
          const districtsList = createNewList(resolveAllDistricts?.results);

          const mockData = [{ filters: [] }];
          // TODO надо доработать с БЕКом Тех-долг-FRONTEND #5622
          // @ts-ignore
          const filtersOrg = resolveUserOrganizations?.[0]?.filters ?? mockData;
          // TODO надо доработать с БЕКом Тех-долг-FRONTEND #5622
          // @ts-ignore
          const filtersDist = resolveUserDistricts?.[0]?.filters ?? mockData;

          dispatch(
            rolesDirectoryActions.replaceFieldByKeyActions(
              otherFilters(organizationList, filtersOrg),
              RolesDirectoryFields.organizationsList
            )
          );
          dispatch(
            rolesDirectoryActions.replaceFieldByKeyActions(
              otherFilters(districtsList, filtersDist),
              RolesDirectoryFields.districtsList
            )
          );
          dispatch(
            rolesDirectoryActions.replaceFieldByKeyActions(
              usersFilters(organizationList, filtersOrg),
              RolesDirectoryFields.checkedOrganizationsList
            )
          );
          dispatch(
            rolesDirectoryActions.replaceFieldByKeyActions(
              usersFilters(districtsList, filtersDist),
              RolesDirectoryFields.checkedDistrictsList
            )
          );
          dispatch(
            rolesDirectoryActions.replaceFieldByKeyActions(
              [...organizationList, ...districtsList],
              RolesDirectoryFields.directoryData
            )
          );
        } else if (pageMenuKey === DirectoriesEnum.permissions_groups && url) {
          const responseData = await getFromURL.getDataFromURL(url);
          dispatch(rolesDirectoryActions.replaceFieldByKeyActions(responseData, RolesDirectoryFields.directoryData));
          const item = createNewList(responseData.results);
          if (!_.isArray(data) && data?.groups) {
            dispatch(
              rolesDirectoryActions.changePartArrayDataByKeyActions(
                usersFilters(item, data.groups),
                RolesDirectoryArrayDataFields.checkedRoles
              )
            );
            dispatch(
              rolesDirectoryActions.replaceFieldByKeyActions(
                otherFilters(item, data.groups),
                RolesDirectoryFields.rolesList
              )
            );
          } else {
            dispatch(rolesDirectoryActions.replaceFieldByKeyActions(item, RolesDirectoryFields.rolesList));
          }
        }
      }
    })();
  }, [data, folder, pageMenuKey, url, notificationAlert, dispatch]);

  const initClose = useCallback(() => {
    revertToInlineHistoryRecordByType(TypeOfRevertEnum.lastOfType, TypeDisplayedDirectoryEnum.main);
    dispatch(rolesDirectoryActions.emptyStoreWhenExitAction());
  }, [dispatch, revertToInlineHistoryRecordByType]);

  const selectedRows = useSelector((state) => getLastSelectedObject(state));
  const nameSelectedRows: string | undefined = selectedRows?.name;
  const idSelectedRows: number | undefined = selectedRows?.id;

  const formRef = useRef<HTMLFormElement>(null);

  const initSubmitForm = useCallback(() => {
    formRef.current?.submit();
  }, []);

  const changeMenuTab = useCallback(
    (key) => {
      if (folder !== 'permissions_groups' && key === 'permissions_groups') initSubmitForm();
      const newUrl =
        folder === 'permissions_groups' ? folder.replace('_groups', `/${key}/`) : `${key.replace('_', '/')}/`;
      setUrl(newUrl);
      setPageMenuKey(key);
    },
    [folder, initSubmitForm]
  );

  const setReturnResponse = useCallback((response) => {
    if (response) {
      setSubmitData(response);
    }
  }, []);

  const saveInStateSubmitForm = useCallback(
    (submitDataParams) => {
      if (!_.isArray(data) && !data?.is_superuser) {
        changeMenuTab('permissions_groups');
      }
      setSubmitData(submitDataParams);
    },
    [changeMenuTab, data]
  );

  const handleSubmitForm = useCallback(() => {
    if (folder !== 'permissions_groups' && !_.isArray(submitData) && !_.isArray(data)) {
      if (_.isEmpty(submitData) || pageMenuKey === 'main') {
        initSubmitForm();
        return;
      }

      Object.keys(submitData).forEach((key) => {
        if (key.includes('inn') || key.includes('snils') || key.includes('phone') || key.includes('kpp')) {
          submitData[key] = submitData[key]?.replace(/[^0-9.]/g, '');
        }
      });
      if (folder === 'users' && checkedRoles?.length) submitData.groups = checkedRoles.map((el) => el.id);
      else if (folder === 'users') submitData.groups = data?.groups;

      const finalData = getPreparedData({ ...data, ...submitData, ...dateTime }, model);

      dispatch(
        submitObjectSagaErrorResponse({ url: urlData, data: finalData, initial: submitData, setReturnResponse })
      );
      if (checkedOrganizationsList.length && finalData?.id) {
        dispatch(
          submitObjectSagaErrorResponse({
            url: '/filters/user_organizations/',
            data: {
              user: finalData.id,
              filters: checkedOrganizationsList.map((el) => el.id),
            },
          })
        );
      }
      if (checkedDistrictsList.length && finalData?.id) {
        dispatch(
          submitObjectSagaErrorResponse({
            url: RequestPath.filterUserDistricts,
            data: {
              user: finalData.id,
              filters: checkedDistrictsList.map((el) => el.id),
            },
          })
        );
      }
    } else {
      const { nameRoles, idForRightDirec = [], idForRightMap, IDRoles, idForRightReports } = rolesDirectory;
      let groupRight: number[] = [];
      if (idForRightDirec || idForRightMap || idForRightReports) {
        const flattenIDMaps = _.flatten(idForRightMap);
        const flattenIDReports = _.flatten(idForRightReports);
        groupRight = [...idForRightDirec, ...flattenIDMaps, ...flattenIDReports];
      }
      const uniqId = _.uniq(groupRight);
      const currentSubmitData = {
        name: nameRoles,
        permissions: uniqId,
        id: IDRoles,
      };

      const redrawDispatch = () => {
        dispatch(initReloadLastInlineHistoryDataSaga(mainDirectoryHistory));
      };

      dispatch(
        submitObjectSagaErrorResponse({
          url: DirectoriesEnum.permissions_groups.replace('_', '/'),
          data: currentSubmitData,
          initClose: redrawDispatch,
        })
      );
      dispatch(rolesDirectoryActions.emptyStoreWhenExitAction());
      initClose();
    }
  }, [
    folder,
    submitData,
    data,
    checkedRoles,
    dateTime,
    model,
    dispatch,
    urlData,
    setReturnResponse,
    checkedOrganizationsList,
    checkedDistrictsList,
    pageMenuKey,
    initSubmitForm,
    rolesDirectory,
    initClose,
    mainDirectoryHistory,
  ]);

  const InlineDrawer = useMemo(() => {
    if (folder === DirectoriesEnum.permissions_groups) {
      return <InputForm idSelectedRows={idSelectedRows} nameSelectedRows={nameSelectedRows} />;
    } else {
      return (
        <AutocompleteForm
          model={model}
          data={submitData}
          handleSubmitForm={saveInStateSubmitForm}
          ref={formRef}
          form={{
            showTime: true,
            dateTime,
            setDateTime,
            formRef,
          }}
        />
      );
    }
  }, [folder, idSelectedRows, nameSelectedRows, model, submitData, saveInStateSubmitForm, dateTime]);

  const isSuperUser: boolean = useMemo(() => !_.isArray(data) && data?.is_superuser, [data]);

  return (
    <StyledDrawer>
      <PanelLayout>
        <PermissionsPanelSider
          initClose={initClose}
          handleSubmitForm={handleSubmitForm}
          changeMenuTab={changeMenuTab}
          pageMenuKey={pageMenuKey}
          isSuperuser={isSuperUser}
          submitData={submitData}
        />
        <ScrollableBoxArea>
          <TabPageContainer pageMenuKey={pageMenuKey} InlineDrawer={InlineDrawer} folder={folder} />
        </ScrollableBoxArea>
      </PanelLayout>
    </StyledDrawer>
  );
};

export default PermissionsPanel;
