import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdministrationBuilder from './AdministratorBuilder';
import AdministrationCards from './parts/AdministrationCards';

export const AdministrationDirectoryRouter = () => {
  return (
    <Switch>
      <Route exact path={'/app/administration/:folder/'} component={() => <AdministrationBuilder />} />
      <Route exact path={'/app/administration/'} component={AdministrationCards} />
    </Switch>
  );
};
