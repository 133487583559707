import { CompositeLayer } from '@deck.gl/core';
import { PolygonLayer } from '@deck.gl/layers';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import wkt from 'wkt';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { DSelector } from 'services/map/Dselector/DSelector';

class TrafficManagementProjectsLayer extends CompositeLayer {
  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      this.setState({ selected: event.info?.object });
      if (this.props.onClickHandler) {
        this.props.onClickHandler(event.info, MapObjects.trafficManagementProjects, Layers.trafficManagementProjects);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];

    return [
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'trafficManagementProjects' }), {
        data: this.props.relatedData.traffic_management_projects,
        stroked: true,
        filled: true,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        wireframe: true,
        lineWidthMinPixels: 3,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getLineColor: [47, 128, 237],
        getFillColor: (d) => {
          const opacity =
            d?.id === this.state?.selectedObject?.id || d?.id === this.state?.selectedObject?.object?.id ? 200 : 100;
          return [47, 128, 237, opacity];
        },
        getPolygon: (d) => {
          if (d.polygon) {
            const parsed = wkt.parse(d.polygon);
            return parsed.coordinates[0];
          } else {
            return [
              [0, 0],
              [0.1, 0.1],
              [0.11, 0.11],
            ];
          }
        },
        updateTriggers: {
          getFillColor: this.state.selectedLayer,
        },
        getLineWidth: 1,
        lineJointRounded: true,
      }),
    ];
  }
}

TrafficManagementProjectsLayer.layerName = Layers.trafficManagementProjects;

export default TrafficManagementProjectsLayer;
