import styled from 'styled-components/macro';

const ScaleControlContainer = styled.div`
  position: absolute;
  top: 85%;
  right: 100px;
`;

export const Styled = {
  ScaleControlContainer,
};
