import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';

const BasePanelSection = styled.div`
  margin-top: 1rem;
`;

const StyledButton = styled(Button)`
  position: relative;
  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
    text-decoration: ${(props) => props.href && 'underline'};
  }
  &.inactive {
    background: ${(props) => props.theme.colors.defaultLightTranslucent};
    color: ${(props) => props.theme.colors.primary};
    height: 40px;
    width: 100%;
    align-items: center;
    font-size: 0;
  }

  &.active {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.defaultLighter};
    height: 40px;
    width: 100%;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`;

const StyledWrapper = styled(BasePanelSection)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: 8px;
  width: 240px;
  height: 40px;
`;

const CountNewMessage = styled.div`
  text-align: center;
  width: 27px;
  height: 14px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors.danger};
  position: absolute;
  top: 7px;
  left: 60px;
  color: ${(props) => props.theme.colors.defaultLighter};
  font-size: 10px;
  font-weight: lighter;
  display: block !important;
`;

export const Styled = {
  BasePanelSection,
  StyledButton,
  StyledWrapper,
  CountNewMessage,
};
