import React, { FC, useCallback, useMemo, useRef } from 'react';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Button } from 'UI/common/components/Button/Button';
import { Styled } from './style/SettingsCamerasUdc.styled';
import AvailableMasks from './components/AvailableMasks/AvailableMasks';
import AddedMasks from './components/AddedMasks/AddedMasks';
import EditableContainer from './components/EditableContainer/EditableContainer';
import { getRegisteredMaskForType } from './utils/masks/maskRegistrator';
import { useDispatch } from 'react-redux';
import { appActions } from 'store/reducers/global/app/app.actions';
import { CamerasService } from 'generated/api/api';
import { message } from 'antd';
import { SettingsCameraUdcProps } from './model/SettingsCameraUdc.model';
import useCamerasState from './store/useCamerasState';

const { SettingsModalWrap, SettingsModalContent } = Styled;

const SettingsCameraUdc: FC<SettingsCameraUdcProps> = ({ cameraID, title }) => {
  const globalDispatch = useDispatch();

  const { state, actions } = useCamerasState();

  const { addedMasks, visibleAllMasks, currentMask, deletedMasks, modalVisible } = state;

  // Экшены стейта
  const {
    openModal,
    closeModal,
    setCurrentMask,
    cancelCurrentMask,
    saveCurrentMask,
    changeVisibleMask,
    changeVisibleAllMasks,
    deleteMask,
    changeDrawType,
    changePosition,
    setAddedMasks,
    changeMaskName,
  } = actions;

  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleShowModal = useCallback(async () => {
    globalDispatch(appActions.startAppFetching());
    try {
      const addedMasks = [];

      //@ts-ignore
      const { results } = await CamerasService.camerasMasksRetrieve2({ id: cameraID });
      for (const maskType in results) {
        if (results.hasOwnProperty(maskType)) {
          const MaskClass = getRegisteredMaskForType(maskType);

          if (MaskClass) {
            for (const mask of results[maskType].masks) {
              addedMasks.push(new MaskClass(mask.camera, mask));
            }
          }
        }
      }

      setAddedMasks(addedMasks);
      openModal();
    } catch (e) {
      message.error('Что-то пошло не так при загрузке масок');
    }
    globalDispatch(appActions.endAppFetching());
  }, [cameraID, globalDispatch, openModal, setAddedMasks]);

  const handleSaveSettings = useCallback(async () => {
    globalDispatch(appActions.startAppFetching());
    try {
      const saveMasks = [];

      for (const mask of addedMasks) {
        saveMasks.push(mask.maskObject);
      }

      await CamerasService.camerasMasksBatchCreateCreate({ body: { masks: saveMasks } });

      for (const id of deletedMasks) {
        CamerasService.camerasMasksDestroy({ id });
      }

      closeModal();
    } catch (e) {
      message.error('Что-то пошло не так при сохранении масок, попробуйте позже');
    }
    globalDispatch(appActions.endAppFetching());
  }, [addedMasks, closeModal, deletedMasks, globalDispatch]);

  const headerStyles = useMemo(() => ({ boxShadow: 'none', fontSize: '13px', color: '#6A7B9B', paddingBottom: 0 }), []);
  const wrapperStyles = useMemo(() => ({ borderRadius: 0 }), []);

  return (
    <>
      <Button onClick={handleShowModal} type={ButtonType.primaryLC}>
        Редактировать
      </Button>
      <SettingsModalWrap
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        width={'calc(100vw - 64px)'}
        zIndex={6000}
        title={`Настройка камеры (${title})`}
        centered={true}
      >
        <SettingsModalContent ref={contentRef}>
          <AvailableMasks
            headerStyles={headerStyles}
            wrapperStyles={wrapperStyles}
            onSaveSettings={handleSaveSettings}
            onCancelSettings={closeModal}
            currentMask={currentMask}
            onSetMask={setCurrentMask}
            onCancelMask={cancelCurrentMask}
            onSaveMask={saveCurrentMask}
            onChangeDrawType={changeDrawType}
            onChangeMaskName={changeMaskName}
            cameraID={cameraID}
          />
          {modalVisible && (
            <EditableContainer
              contentRef={contentRef}
              currentMask={currentMask}
              addedMasks={addedMasks}
              onChangePosition={changePosition}
            />
          )}
          <AddedMasks
            wrapperStyles={wrapperStyles}
            headerStyles={headerStyles}
            addedMasks={addedMasks}
            visibleAllMasks={visibleAllMasks}
            onChangeVisibleMask={changeVisibleMask}
            onChangeVisibleAllMasks={changeVisibleAllMasks}
            onEditMask={setCurrentMask}
            onDeleteMask={deleteMask}
          />
        </SettingsModalContent>
      </SettingsModalWrap>
    </>
  );
};

export default SettingsCameraUdc;
