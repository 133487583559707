import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { getFromURL } from 'api/getFromURL';
import { useTheme } from 'styled-components';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { CreatBlankStatementIcon } from 'UI/custom/icons/actionIcons/CreatBlankStatementIcon';

import { IButton } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { iconSize } from '../../interfacesCargoTransport/templates';
import { Styled } from './CreatBlankStatementButton.styled';
import { CreatBlankStatementModal } from './CreatBlankStatementModal';
import { ApplicationTheme } from '../../../../../../../styles/themes/model/ApplicationTheme.model';

const { LocalDiv, CreatBlankButton } = Styled;

export const CreatBlankStatementButton = (props: PropsWithChildren<IButton>) => {
  const newIconSize = useRef(iconSize);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [numberOpenApplicationForms, setNumberOpenApplicationForms] = useState<number>(0);
  const { isDisabled, refreshData = () => {}, tooltip } = props;
  const theme = useTheme() as ApplicationTheme;
  const openModal = useCallback(async () => {
    const response = await getFromURL.getWithParams(`/${IOPWDirectory.special_permits}/forms_account/?ordering=-id`, {
      page_size: 1,
    });
    setNumberOpenApplicationForms(response?.results?.[0]?.number ?? 0);
    setOpenModal(true);
  }, []);

  return (
    <LocalDiv>
      <CreatBlankButton
        onClick={openModal}
        icon={<CreatBlankStatementIcon />}
        rounded
        hasShadow
        disabled={isDisabled}
        type={'danger'}
        iconColor={theme?.colors.primary}
        iconSize={newIconSize.current}
        tooltip={tooltip}
      />
      <CreatBlankStatementModal
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        numberOpenApplicationForms={numberOpenApplicationForms}
        refreshData={refreshData}
      />
    </LocalDiv>
  );
};
