import { Action, ActionFunction1, createAction } from 'redux-actions';

class ReportsActions {
  /** action types */
  public readonly prefix: string = 'REPORTS';
  public readonly SET_CAR: string = `${this.prefix}.SET_CAR`;
  public readonly SET_EMAIL: string = `${this.prefix}.SET_EMAIL`;
  public readonly SET_EXTENSION: string = `${this.prefix}.SET_EXTENSION`;
  public readonly MORE_CARS: string = `${this.prefix}.MORE_CARS`;
  public readonly FILTERED_DATA: string = `${this.prefix}.FILTERED_DATA`;
  public readonly FILTERED_BELONG_GRZ_TO_CAR = `${this.prefix}.FILTERED_BELONG_GRZ_TO_CAR`;
  public readonly SET_CHECKED_ITEM = `${this.prefix}.SET_CHECKED_ITEM`;
  public readonly SET_CHECKED_ITEM_ANOTHER = `${this.prefix}.SET_CHECKED_ITEM_ANOTHER`;
  public readonly HEADERS = `${this.prefix}.HEADERS`;
  public readonly OWNER_TS = `${this.prefix}.OWNER_TS`;
  public readonly SET_SELECT = `${this.prefix}.SET_SELECT`;
  public readonly SET_TIME_START = `${this.prefix}.TIME_START`;
  public readonly SET_TIME_END = `${this.prefix}.TIME_END`;

  /** actions */
  public setCar: ActionFunction1<number | null, Action<{ carId: number | null }>> = createAction(
    this.SET_CAR,
    (carId: number | null) => ({ carId })
  );
  public setEmail: ActionFunction1<string | null, Action<{ email: string | null }>> = createAction(
    this.SET_EMAIL,
    (email: string | null) => ({ email })
  );
  public setExtension: ActionFunction1<string | null, Action<{ extension: string | null }>> = createAction(
    this.SET_EXTENSION,
    (extension: string | null) => ({ extension })
  );
  public setCheckedItem: ActionFunction1<Array<any> | null, Action<{ checkedItems: Array<any> | null }>> = createAction(
    this.SET_CHECKED_ITEM,
    (checkedItems: Array<any> | null) => ({ checkedItems })
  );
  public setCheckedItemAnother: ActionFunction1<Array<any> | null, Action<{ checkedItemsAnother: Array<any> | null }>> =
    createAction(this.SET_CHECKED_ITEM_ANOTHER, (checkedItemsAnother: Array<any> | null) => ({ checkedItemsAnother }));
  public setTimeStart: ActionFunction1<string | null, Action<{ timeStart: string | null }>> = createAction(
    this.SET_TIME_START,
    (timeStart: string | null) => ({ timeStart })
  );
  public setTimeEnd: ActionFunction1<string | null, Action<{ timeEnd: string | null }>> = createAction(
    this.SET_TIME_END,
    (timeEnd: string | null) => ({ timeEnd })
  );
}

export const reportsActions = new ReportsActions();
