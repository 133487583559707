import { Button, ConfigProvider } from 'antd';
import { Form } from 'antd';
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Styled } from '../styled/BaseInput.styled';
import { useBaseInputPlaceholderControl } from '../hooks/useBaseInputPlaceholderControl';
import { checkConditionAndCallFn } from '../../../../services/common/checkConditionAndCallFn';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';
import { makeRepresentationDataRequest } from '../../../../services/net/makeRepresentationDataRequest';
import styled from 'styled-components/macro';
import _ from 'underscore';

const { BaseErrorMessage, BaseSelectInput, BaseSelectPlaceholder, BaseSelectInputWrap } = Styled;

const StyledAntdButton = styled(Button)`
  width: 100%;
`;
const CreatableRelatedFieldInput = (props) => {
  const { description, object, field, mode } = props;
  const { required, message } = useBaseInputSettings(description);

  const inputWrapRef = useRef(null);
  const { control, errors, setValue } = useFormContext();
  const [optionsToChoose, setOptionsToChoose] = useState([]);
  // firstOptionsToChoose состояние для хранения первых пунктов меню отображаемых в селекте
  const [firstOptionsToChoose, setFirstOptionsToChoose] = useState([]);
  // filteredOptionsToChoose состояние для хранения отфильтрованных пунктов меню по ключевому слову введенному пользователем в селект
  const [filteredOptionsToChoose, setFilteredOptionsToChoose] = useState([]);
  // countOptionsList коэффициент для умножения количества пунктов меню отображаемых в селекте
  // первое значение равно двум так как по умолчанию отображается 20 пунктов и при первой прокрутке количество будет умножено на 2.
  const [countOptionsList, setCountOptionsList] = useState(2);

  const { placeholderState, setPlaceholderState } = useBaseInputPlaceholderControl(field, object, setValue);

  const selectOption = useMemo(() => optionsToChoose?.filter((el) => el?.props?.value === object?.[field]) ?? [], [
    field,
    object,
    optionsToChoose,
  ]);

  useEffect(() => {
    makeRepresentationDataRequest({
      // запрос на дороги? ошибка
      inputDescription: description,
    }).then((options) => {
      setOptionsToChoose(options);
      setFirstOptionsToChoose(_.first(options, 20));
    });
  }, [description]);

  let templateString = useRef('');

  const handleSetTemplates = (value) => {
    templateString.current = value;
    // @ts-ignore
    const filterOption = optionsToChoose?.filter((el) => el.props.children.toLowerCase().includes(value.toLowerCase()));
    setFilteredOptionsToChoose(filterOption);
    // @ts-ignore
    setFirstOptionsToChoose(_.first(filterOption, 20));
  };

  const onScroll = useCallback(
    (event) => {
      const { target } = event;
      const { scrollTop, offsetHeight, scrollHeight } = target;
      if (scrollTop + offsetHeight === scrollHeight) {
        let newOption = optionsToChoose;
        if (filteredOptionsToChoose.length) {
          newOption = filteredOptionsToChoose;
        }
        setFirstOptionsToChoose(_.first(newOption, countOptionsList * 20));
        setCountOptionsList(countOptionsList + 1);
      }
    },
    [countOptionsList, filteredOptionsToChoose, optionsToChoose]
  );

  if (errors[field]?.message?.includes(description.label) && errors[field]) {
    errors[field].message = null;
  }

  const finalOptions = useMemo(() => [...selectOption, ..._.without(firstOptionsToChoose, selectOption[0])], [
    firstOptionsToChoose,
    selectOption,
  ]);

  return (
    <Form.Item>
      <BaseSelectInputWrap ref={inputWrapRef}>
        <ConfigProvider
          renderEmpty={() => (
            <a href={'/app/directory/roads'} rel="noopener noreferrer" target="_blank">
              <StyledAntdButton type="primary" ghost onClick={() => {}}>
                Создать новый объект
              </StyledAntdButton>
            </a>
          )}
        >
          <Controller
            dropdownClassName={'custom-dropdown'}
            allowClear={true}
            name={field}
            as={
              <BaseSelectInput
                onSearch={(value) => handleSetTemplates(value)}
                renderEmpty={() => <Button>Добавить</Button>}
                onPopupScroll={onScroll}
              >
                {finalOptions}
              </BaseSelectInput>
            }
            control={control}
            disabled={!!description.read_only}
            mode={mode || null}
            showSearch
            optionFilterProp="children"
            rules={{ required: { value: required, message: message } }}
            getPopupContainer={() => inputWrapRef.current}
            onFocus={() => {
              setPlaceholderState(true);
            }}
            onBlur={(e) => {
              checkConditionAndCallFn(!e[0], () => setPlaceholderState(false));
            }}
          />
        </ConfigProvider>
        <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
        <BaseSelectPlaceholder className={`${placeholderState ? 'opened' : ''}`}>
          {description.label}
        </BaseSelectPlaceholder>
      </BaseSelectInputWrap>
    </Form.Item>
  );
};

export default CreatableRelatedFieldInput;
