import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { layerDataAccessors } from '../description/dataAccessors';
import { layerSettings } from '../description/settings';
import TrafficLightsObjectsSelection from '../../../../layers/SelectedObjectsRepresentations/TrafficLightsObjectsSelection/TrafficLightsObjectsSelection';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class TrafficLightObjectLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.trafficLightObject,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.TrafficLightObjectsDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.TrafficLightsObjects(),
        new relatedLayerData.ekbRelatedDataForLayers.TrafficLightsObjectsSchemaWork(),
      ],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.TrafficLightsObjectsCard(),
      customSettings: [new layerSettings.OpacitySetting(), new layerSettings.IconSizeScale()],
      layerSchema: new layerSchemas.ekbLayersSchema.TrafficLightsObjectsSchema(),
      selectedObjectPrototype: TrafficLightsObjectsSelection,
    });
  }
}
