import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';
import { Layers } from 'types/enums/map/layers/Layers';
import { layerSchemas } from 'registrators/map/layers/description/layerSchemaPresets';
import { ekbLayerCards } from 'registrators/map/layers/description/cards/ekb';
import { relatedLayerData } from '../description/relatedData';
import CamerasUdcSelection from 'layers/SelectedObjectsRepresentations/CamerasUdcSelection/CamerasUdcSelection';
import { projectLayerGroups } from '../description/layerGroups';
import { layerSettings } from 'registrators/map/layers/description/settings';
import { layerDataAccessors } from '../description/dataAccessors';

export class CamerasUdcLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.camerasUdc,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.CamerasUdcDataAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.CamerasUds()],
      layerGroup: new projectLayerGroups.MsdidiLayerGroup(),
      card: new ekbLayerCards.CamerasUdc(),
      customSettings: [
        new layerSettings.IconSizeScale(),
        new layerSettings.OpacitySetting(),
        new layerSettings.SwitchCluster(),
      ],
      layerSchema: new layerSchemas.ekbLayersSchema.CamerasUdcLayerSchema(),
      selectedObjectPrototype: CamerasUdcSelection,
    });
  }
}
