import React, { useCallback, useState } from 'react';
import { Button } from 'UI/common/components/Button/Button';
import { SettingsIcon } from 'UI/custom/icons/actionIcons/SettingsIcon';
import { VisibilityOffIcon } from 'UI/custom/icons/actionIcons/VisibilityOffIcon';
import { VisibilityIcon } from 'UI/custom/icons/actionIcons/VisibilityIcon';
import { withTheme } from 'styled-components';
import { OptionIcon } from 'UI/custom/icons/actionIcons/OptionIcon';
import PanelSlimCardTitle from 'UI/common/components/PanelSlimCard/components/Title/PanelSlimCardTitle';
import { Styled } from './DisplayedLayerItem.styled';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { ArrowIcon } from 'UI/custom/icons/actionIcons/ArrowIcon';
import { getRegisteredLayer } from 'registrators/map/layers/layersRegistrator';
import { iconSizesKit } from 'styles/utils/iconSizesKit';
import { DocIcon } from 'UI/custom/icons/objectIcons/DocIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { LayerPrototype } from 'registrators/map/layers/description/prototype/LayerPrototype';
import { ApplicationTheme } from 'styles/themes/model/ApplicationTheme.model';
import { AnyObject } from 'types/enums/general/general.model';

interface DisplayedLayerItemProps {
  item: LayerPrototype;
  theme: ApplicationTheme;
  toggleLayerVisible: () => void;
  selectDataForLayer: () => void;
  handleRemoveLayer: (layer: LayerPrototype) => void;
  selectMapboxLayerWithSideEffect: (layer: LayerPrototype) => void;
  isSettingsPanelVisible?: boolean;
  isLayerDataAccessorVisible?: boolean;
  onItemWrapperClick: () => void;
  isCurrentDataAccessorForThisItem: boolean;
  isSelected: boolean;
  handlerClearMapSelectedObject: () => void;
  selectedObject: AnyObject;
}

const DisplayedLayerItem = ({
  item,
  theme,
  toggleLayerVisible,
  selectDataForLayer,
  handleRemoveLayer,
  selectMapboxLayerWithSideEffect,
  isSettingsPanelVisible,
  isLayerDataAccessorVisible,
  onItemWrapperClick,
  isCurrentDataAccessorForThisItem,
  isSelected,
  handlerClearMapSelectedObject,
  selectedObject,
}: DisplayedLayerItemProps) => {
  const [isCardActive, setIsCardActive] = useState(false);
  const LayerResolved = getRegisteredLayer(item.name);
  const Card = LayerResolved?.getCard();

  const clickHandleRemoveLayer = useCallback(() => {
    handleRemoveLayer(item);
    const relatedDataNames = item.getRelatedData().map((el) => el.getName());
    if (relatedDataNames.includes(selectedObject.type)) {
      handlerClearMapSelectedObject();
    }
  }, [handleRemoveLayer, item, selectedObject, handlerClearMapSelectedObject]);
  const onCardActive = useCallback(() => setIsCardActive(true), []);
  const offCardActive = useCallback(() => setIsCardActive(false), []);

  return (
    <>
      <Styled.ExtendedPanelCard
        // @ts-ignore
        active={isCardActive}
        mt={'0.5rem'}
        onDoubleClick={onItemWrapperClick}
        isSelected={isSelected}
        p={'0.7rem'}
      >
        <Styled.CustomisedPanelCardContent>
          <PanelSlimCardTitle m={0} isSelected={isSelected}>
            {Card?.getLabel()}
          </PanelSlimCardTitle>
          <Button p={0} type={ButtonType.unbounded} iconSize={iconSizesKit.big} icon={<OptionIcon />} />
        </Styled.CustomisedPanelCardContent>
        <Styled.CustomisedPanelCardActionGroup
          // @ts-ignore
          active={isCardActive}
          onMouseEnter={onCardActive}
          onMouseLeave={offCardActive}
        >
          <Button
            type={ButtonType.unbounded}
            className={'moved-action-item'}
            p={0}
            iconSize={iconSizesKit.big}
            iconColor={theme.colors.danger}
            icon={<DeleteIcon />}
            onClick={clickHandleRemoveLayer}
          />
          <Button
            type={ButtonType.unbounded}
            className={`moved-action-item ${
              isCurrentDataAccessorForThisItem && isLayerDataAccessorVisible ? 'active' : ''
            }`}
            p={0}
            iconSize={iconSizesKit.big}
            onClick={selectDataForLayer}
            icon={<DocIcon />}
          />
          <Button
            type={ButtonType.unbounded}
            className={`moved-action-item ${!item.hidden ? 'active' : ''}`}
            p={0}
            iconSize={iconSizesKit.big}
            onClick={toggleLayerVisible}
            icon={!item.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
          />
          <Styled.LocalIconWrap>
            <ArrowIcon />
          </Styled.LocalIconWrap>
          <Button
            type={ButtonType.unbounded}
            className={`${item.isSelected && isSettingsPanelVisible ? 'active' : ''}`}
            p={0}
            iconSize={iconSizesKit.big}
            onClick={() => selectMapboxLayerWithSideEffect(item)}
            icon={<SettingsIcon />}
          />
        </Styled.CustomisedPanelCardActionGroup>
      </Styled.ExtendedPanelCard>
    </>
  );
};

export default withTheme(DisplayedLayerItem);
