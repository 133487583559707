import React from 'react';
import Icon from '@ant-design/icons';

const DocIconSVG = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 2.25H3.75C3.35218 2.25 2.97064 2.40804 2.68934 2.68934C2.40804 2.97064 2.25 3.35218 2.25 3.75V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25ZM14.25 14.25H3.75V5.25H14.25V14.25ZM12.75 9H5.25V7.5H12.75V9ZM9.75 12H5.25V10.5H9.75V12Z"
        fill="#6B8AE4"
      />
    </svg>
  );
};

export const DocIcon = (props) => <Icon component={DocIconSVG} {...props} />;
