import turfCenterOfMass from '@turf/center-of-mass';
import { Feature, polygon } from '@turf/helpers';
import { Polygon, Position } from '@turf/helpers/dist/js/lib/geojson';
import { WebMercatorViewport } from 'deck.gl';

interface CalculatingScreenOrSelectedAreaCoordsInterface {
  currentViewports: WebMercatorViewport;
  selectedArea?: Position[][] | Feature<Polygon> | Polygon;
  shouldReturnPolygon?: boolean;
  axisCoordsFormat?: boolean;
}

export type ScreenOrSelectedAreaCoordsType = {
  topRightCoords: [number, number];
  topLeftCoords: [number, number];
  bottomRightCoords: [number, number];
  bottomLeftCoords: [number, number];
  center: Position;
  ScreenAreaPolygon?: Feature<Polygon>;
};

export type ScreenOrSelectedAreaCoordsInAxisType = {
  AxisX_Max: number;
  AxisX_Min: number;
  AxisY_Max: number;
  AxisY_Min: number;
  ScreenAreaPolygon?: Feature<Polygon>;
  center: Position;
};

export const CalculatingScreenOrSelectedAreaCoords = ({
  currentViewports,
  selectedArea,
  shouldReturnPolygon = false,
  axisCoordsFormat = false,
}: CalculatingScreenOrSelectedAreaCoordsInterface):
  | ScreenOrSelectedAreaCoordsType
  | ScreenOrSelectedAreaCoordsInAxisType => {
  const topBottomCoords: number[] = [];
  const rightLeftCoords: number[] = [];

  if (selectedArea) {
    let selectedAreaCoords: Position[][] | undefined;

    if ('type' in selectedArea) {
      if (selectedArea.type === 'Polygon') {
        selectedAreaCoords = selectedArea.coordinates;
      }
      if (selectedArea.type === 'Feature') {
        selectedAreaCoords = selectedArea.geometry.coordinates;
      }
    } else {
      selectedAreaCoords = selectedArea;
    }

    if (!selectedAreaCoords) throw new Error('Не удалось прочитать геометрию');

    selectedAreaCoords[0].forEach((el) => {
      const screenCoords: number[] = currentViewports.project(el);
      if (!topBottomCoords.includes(screenCoords[1])) {
        topBottomCoords.push(screenCoords[1]);
      }

      if (!rightLeftCoords.includes(screenCoords[0])) {
        rightLeftCoords.push(screenCoords[0]);
      }
    });
  }

  const bottomRightCoords = currentViewports.unproject(
    selectedArea
      ? [Math.max(...rightLeftCoords), Math.max(...topBottomCoords)]
      : [currentViewports.width, currentViewports.height]
  ) as [number, number];

  const topRightCoords = currentViewports.unproject(
    selectedArea ? [Math.max(...rightLeftCoords), Math.min(...topBottomCoords)] : [currentViewports.width, 0]
  ) as [number, number];

  const bottomLeftCoords = currentViewports.unproject(
    selectedArea ? [Math.min(...rightLeftCoords), Math.max(...topBottomCoords)] : [0, currentViewports.height]
  ) as [number, number];

  const topLeftCoords = currentViewports.unproject(
    selectedArea ? [Math.min(...rightLeftCoords), Math.min(...topBottomCoords)] : [0, 0]
  ) as [number, number];

  const ScreenAreaPolygon = polygon([
    [topLeftCoords, topRightCoords, bottomRightCoords, bottomLeftCoords, topLeftCoords],
  ]);

  const center = turfCenterOfMass(ScreenAreaPolygon).geometry.coordinates;

  if (axisCoordsFormat) {
    return {
      AxisX_Max: bottomRightCoords[0],
      AxisX_Min: topLeftCoords[0],
      AxisY_Max: topLeftCoords[1],
      AxisY_Min: bottomRightCoords[1],
      center,
      ...(shouldReturnPolygon && { ScreenAreaPolygon: ScreenAreaPolygon }),
    };
  }

  return {
    bottomRightCoords,
    topRightCoords,
    bottomLeftCoords,
    topLeftCoords,
    center,
    ...(shouldReturnPolygon && { ScreenAreaPolygon: ScreenAreaPolygon }),
  };
};
