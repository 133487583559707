import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, PathLayer, TextLayer } from '@deck.gl/layers';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { calculateLinePathCenterCoords } from '../../../services/map/calculateLinePathCenterCoords';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { objectAllIconMapper } from '../../iconMappers/objectAllIconMapper/objectAllIconMapper';
import { DSelector } from '../../../services/map/Dselector/DSelector';

class SpeedBumpsCompositeLayers extends CompositeLayer {
  updateState({ changeFlags, props }) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;

    if (selectedLayer instanceof SpeedBumpsCompositeLayers) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }
    this.setState({
      data: props,
    });
  }

  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.roadSpeedBumps;
        if (event.sourceLayer.id.includes('speed-bumps')) selectedObjectType = MapObjects.speedBumps;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    return [
      new PathLayer(this.props, this.getSubLayerProps({ id: 'speed-bumps-path' }), {
        data: this.props.relatedData.speed_bumps,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        pickable: true,
        autoHighlight: false,
        widthScale: 2,
        widthMaxPixels: 5,
        rounded: true,
        billboard: false,
        getPath: (d) => {
          if (d.line_path) {
            return getCoordsFromParsedGeometry(d.line_path);
          }
          return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          return this.props?.selectedObject?.type === 'speed_bumps' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [222, 139, 71, 250]
            : [242, 153, 74, 250];
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'speed_bumps' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 3
            : 2;
        },
        opacity: 1,
        onClick: () => {
          this.props.onClick();
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'speed-bumps-icon' }), {
        data: this.props.relatedData.speed_bumps,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0,
        pickable: true,
        autoHighlight: true,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (this.state.data?.instance?.id === d.id) {
            return null;
          }
          return DSelector.getSelectableIcon({
            id: d.id,
            layerState: this.props,
            typeCheck: MapObjects.speedBumps,
            selectedMapper: 'speed_bump_active',
            deselectedMapper: 'speed_bump',
          });
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: 'accessor', value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: 'accessor', value: 8 },
  // Label color for each feature
  getLabelColor: { type: 'accessor', value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: 'accessor', value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: 'pixels',
  // Label background color
  labelBackground: { type: 'color', value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: 'Monaco, monospace',
};

SpeedBumpsCompositeLayers.layerName = 'TrolleybusSchema';
SpeedBumpsCompositeLayers.defaultProps = defaultProps;

export default SpeedBumpsCompositeLayers;
