import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import TrafficLightObjectsCompositeLayers from '../../../../../../layers/CompositeLayers/ekb/TrafficLightObjectsCompositeLayers';

export class TrafficLightsObjectsSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: TrafficLightObjectsCompositeLayers,
      id: 'TrafficLightsObjectsSchema',
    });
  }
}
