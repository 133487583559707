import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';
import { useTheme } from 'styled-components';
import { ApplicationTheme } from 'styles/themes/model/ApplicationTheme.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { DownloadIcon } from 'UI/custom/icons/actionIcons/DownloadIcon';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { IData } from '../../ApplicationsForm/interfacesApplications/interfacesApplications';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { iconSize } from '../../ApplicationsForm/interfacesApplications/templates';
import CreateButton from 'UI/custom/actionsButtons/common/CreateButton';
import { RefreshButton } from 'UI/custom/actionsButtons/common/RefreshButton';
import { downloadFile } from '../../ApplicationsForm/methods/downloadFile';
import { UploadTMCFileButton } from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileButton';
import { AnyObject } from 'types/enums/general/general.model';

const pagesDontNeedCreateButton: string[] = [ApplicationsDirectory.photos, ApplicationsDirectory.semifinishedObjects];

const { confirm } = Modal;

const DeleteStatementObject = (
  directory: ApplicationsDirectory,
  pageMenuKey: ApplicationsDirectory,
  idSignDocument: number,
  refreshData: () => void,
  dispatch: (param: any) => void
) => {
  const performDeletion = () => {
    const url = `${pageMenuKey.replace('api/', '')}`;
    dispatch(deleteObject({ url, id: idSignDocument }));
    refreshData();
  };
  confirm({
    title: 'Вы действительно желаете удалить объект?',
    icon: <QuestionCircleOutlined />,
    onOk() {
      performDeletion();
    },
  });
};

interface IButtonsProps {
  pageMenuKey: ApplicationsDirectory;
  moveToPageCreatingNewAccount: () => void;
  refreshData: () => void;
  idSignDocument: number;
  id?: string;
  data: IData[] | AnyObject;
  model?: ModelResponse;
  directory: ApplicationsDirectory;
}

export const ObjectPanelButtons = ({
  pageMenuKey,
  moveToPageCreatingNewAccount,
  refreshData,
  idSignDocument,
  id,
  data,
  directory,
}: PropsWithChildren<IButtonsProps>) => {
  const dispatch = useDispatch();

  const theme = useTheme() as ApplicationTheme;

  const clickDownloadFile = useCallback(() => {
    const url = data?.find((file: AnyObject) => file.id === idSignDocument)?.photo;
    if (url) downloadFile(url);
  }, [data, idSignDocument]);

  // место установки конопок завист от выбраной вкладки меню
  const addAndDownloadFilesPage = useMemo(() => pageMenuKey.includes(ApplicationsDirectory.photos), [pageMenuKey]);
  const isVisibleCreateButton = useMemo(
    () => !pagesDontNeedCreateButton.some((page) => pageMenuKey.includes(page)),
    [pageMenuKey]
  );

  return (
    <>
      {isVisibleCreateButton && <CreateButton moveToPageCreating={moveToPageCreatingNewAccount} tooltip={'Добавить'} />}
      {addAndDownloadFilesPage && (
        <UploadTMCFileButton
          isDisabled={!id}
          tooltip={'Добавить файл'}
          pageMenuKey={pageMenuKey}
          id={id}
          refreshData={refreshData}
        />
      )}
      {addAndDownloadFilesPage && (
        <Button
          rounded
          hasShadow={true}
          type={ButtonType.danger}
          icon={<DownloadIcon />}
          iconColor={theme.colors.primary}
          iconSize={iconSize}
          onClick={clickDownloadFile}
          tooltip={'Скачать файл'}
          disabled={!idSignDocument}
        />
      )}
      <RefreshButton refreshData={refreshData} tooltip={'Обновить'} />
      <Button
        rounded
        hasShadow={true}
        type={ButtonType.dangerDelete}
        p={'0.7rem'}
        icon={<DeleteIcon />}
        iconSize={iconSize}
        tooltip={'Удалить объект'}
        disabled={!idSignDocument}
        onClick={() => DeleteStatementObject(directory, pageMenuKey, idSignDocument, refreshData, dispatch)}
      />
    </>
  );
};
