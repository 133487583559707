import React from 'react';

import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { AnyObject } from 'types/enums/general/general.model';
import { PatchedRoadMarkingLine } from 'generated/api/api';

interface RoadMarkingLinesItemSelectionProps {
  handleCloseMapSelectedObjectInfo: () => void;
  selectedMapItem: AnyObject;
  data: PatchedRoadMarkingLine;
}

const RoadMarkingLinesItemSelection = (
  {
    handleCloseMapSelectedObjectInfo,
    selectedMapItem,
    data,
  }: RoadMarkingLinesItemSelectionProps) => {

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord: any) => dataRecord.name === MapObjects.roadMarkingLines);
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadMarkingLines}/${id}/`, '_blank');
    win?.focus();
  };

  const Descriptions: IDescriptions[] = [];

  Descriptions.push({
    key: 'Тип дорожной разметки по ГОСТ Р 52289-2019',
    value: data?.road_marking_type_name ?? 'Не указан',
  });
  Descriptions.push({
    key: 'Примечание',
    value: data.comment ?? '-',
  });
  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer?.toString() ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о дорожной разметке'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default RoadMarkingLinesItemSelection;
