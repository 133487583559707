import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getFromURL } from '../../../api/getFromURL';
import {
  getMainObject,
  PUSH_INLINE_HISTORY,
  RELOAD_INLINE_HISTORY,
  REVERT_HISTORY_TO_RECORD,
  selectDirectoryHistory,
} from '../../reducers/directory/directoryHistory';
import _ from 'underscore';
import { cropPath } from '../../../services/net/cropPath';

export const PUSH_TO_HISTORY_SAGA = 'PUSH_TO_HISTORY_SAGA';
export const REVERT_HISTORY_SAGA = 'REVERT_HISTORY_SAGA';
export const RELOAD_HISTORY_INLINE_SAGA = 'RELOAD_HISTORY_INLINE_SAGA';

export const initReloadLastInlineHistoryDataSaga = (lastInlineHistory) => ({
  type: RELOAD_HISTORY_INLINE_SAGA,
  payload: { lastInlineHistory },
});

const modifyModel = (model, parentKey) => {
  if (parentKey) {
    const copy = { ...model };
    copy.scheme[parentKey] = {
      ...copy.scheme[parentKey],
      read_only: true,
    };
    return { ...copy };
  } else {
    return model;
  }
};

const modifyData = (initialData, parentKey, parentId) => {
  if (parentKey) {
    const copy = { ...initialData };
    copy[parentKey] = parentId;
    return { ...copy };
  } else {
    return initialData;
  }
};

function* inlineHistorySaga(action) {
  yield put({ type: 'RECEIVED_PUSH_TO_INLINE_HISTORY_ATTEMPT' });
  if (action.payload.urlData) {
    yield put({ type: 'APPLICATIONS.START_FETCHING' });
    let parentId = null;
    let parentKey = null;
    let error = false;
    let preparedUrl = cropPath(action.payload.urlData);
    let model = yield call(() => getFromURL.getModelFromURL(preparedUrl));
    let objectData = [];
    const directoryHistoryAll = yield select(selectDirectoryHistory);
    const directoryHistoryMainObject = yield select(getMainObject);
    const directoryHistoryLastItem = directoryHistoryAll[directoryHistoryAll.length - 1];
    if (action.payload.type === 'inline') {
      parentId = directoryHistoryMainObject.data.id;
      const parentUrl = cropPath(directoryHistoryMainObject.urlData).replace(parentId + '/', '');
      const relatedItems = [];
      for (let key in model?.scheme) {
        if (cropPath(model.scheme[key]?.url) === parentUrl) {
          relatedItems.push(key);
          // parentKey = key;
          // break;
        }
      }
      if (relatedItems.length === 1) {
        parentKey = relatedItems[0];
      } else {
        parentKey = relatedItems.find((el) => model.scheme[el].required) || 'recursive';
      }
    } else if (action.payload.type === 'inlineObject') {
      parentKey = directoryHistoryLastItem.parentKey;
      parentId = directoryHistoryLastItem.parentId;
      preparedUrl = directoryHistoryLastItem.urlData;
    } else if (!action.payload.newObject) {
      objectData = yield call(() => getFromURL.getAll(preparedUrl));
      if (!objectData) {
        window.location.replace('/404');
        error = true;
      }
    }
    if (!error) {
      let writableData = objectData?.results ? objectData.results : objectData;

      if (action.payload.type === 'inlineObject') {
        writableData = writableData[0];
      }
      if (action.payload.isNewObject) {
        writableData = null;
      }
      if (parentKey && parentId) {
        model = modifyModel(model, parentKey);
        if (action.payload.type === 'inlineObject' || action.payload.type === 'mainObject') {
          writableData = modifyData(writableData, parentKey, parentId);
        }
      }
      yield put({
        type: PUSH_INLINE_HISTORY,
        payload: {
          parentId,
          parentKey,
          type: action.payload.type,
          ids: action.payload.ids,
          urlData: preparedUrl,
          url: action.payload.url,
          field: action.payload.field,
          data: writableData,
          model: model,
        },
      });
      yield put({ type: 'RECEIVED_PUSH_TO_INLINE_HISTORY_SUCCESS' });
    }
  }
  // type: action.payload.type,
  yield put({ type: 'APPLICATIONS.END_FETCHING' });
}

function* reloadLastInlineHistoryDataSaga(action) {
  yield put({ type: 'REFRESH_DATA_INLINE_HISTORY_ATTEMPT' });
  const lastHistoryData = action.payload.lastInlineHistory;
  if (lastHistoryData?.urlData) {
    yield put({ type: 'APPLICATIONS.START_FETCHING' });
    let objectData;
    if (!_.isArray(lastHistoryData.ids)) {
      const urlDetails = lastHistoryData.urlData?.split('/');
      if (!isNaN(urlDetails[urlDetails.length - 2])) {
        urlDetails.splice(urlDetails.length - 2, 1);
      }
      objectData = yield call(() => getFromURL.getDataFromURL(urlDetails.join('/')));
    } else if (lastHistoryData.ids.length) {
      objectData = yield call(() =>
        getFromURL.getDataFromURLWithFilters(lastHistoryData.urlData, [
          {
            field: `${lastHistoryData.field}`,
            operator: 'all',
            data: lastHistoryData.ids,
          },
        ])
      );
    } else objectData = null;

    yield put({
      type: RELOAD_INLINE_HISTORY,
      payload: {
        id: lastHistoryData.id,
        data: objectData?.results ? objectData?.results : objectData,
      },
    });
    yield put({
      type: REVERT_HISTORY_TO_RECORD,
      payload: {
        index: 0,
        newData: objectData?.results,
      },
    });
    yield put({ type: 'REFRESH_DATA_INLINE_HISTORY_SUCCESS' });
  }
  yield put({ type: 'APPLICATIONS.END_FETCHING' });
}

export const getInlineHistory = (state) => state.directoryHistory;

function* revertInlineHistorySaga(action) {
  yield put({ type: 'REVERT_INLINE_HISTORY_ATTEMPT' });
  const { typeOfRevert } = action.payload;

  switch (typeOfRevert) {
    case 'lastOfType': {
      const { typeName } = action.payload;
      const currentHistory = yield select(getInlineHistory);
      //TODO proceed save of closing data Тех-долг-FRONTEND #5636
      const foundedHistoryRecordIndex = currentHistory.findIndex((el) => {
        return el.type === typeName;
      });
      const newDataResponse = yield call(() =>
        getFromURL.getDataFromURL(currentHistory[foundedHistoryRecordIndex].urlData)
      );
      yield put({
        type: REVERT_HISTORY_TO_RECORD,
        payload: {
          index: foundedHistoryRecordIndex,
          newData: newDataResponse?.results,
        },
      });
      break;
    }
    case 'includes': {
      const { typeName } = action.payload;
      const currentHistory = yield select(getInlineHistory);
      //TODO proceed save of closing data Тех-долг-FRONTEND #5636
      let foundedHistoryRecordIndex = null;
      for (let it = currentHistory.length - 1; it >= 0; it--) {
        if (currentHistory[it].type.includes(typeName)) {
          foundedHistoryRecordIndex = it;
          break;
        }
      }
      const newDataResponse = yield call(() =>
        getFromURL.getDataFromURL(currentHistory[foundedHistoryRecordIndex].urlData)
      );
      yield put({
        type: REVERT_HISTORY_TO_RECORD,
        payload: {
          index: foundedHistoryRecordIndex,
          newData: newDataResponse?.results,
        },
      });
      break;
    }
    default:
      break;
  }
}

export function* watchInlineHistorySaga() {
  yield takeEvery(PUSH_TO_HISTORY_SAGA, inlineHistorySaga);
}

export function* watchReloadLastInlineHistoryDataSaga() {
  yield takeEvery(RELOAD_HISTORY_INLINE_SAGA, reloadLastInlineHistoryDataSaga);
}

export function* watchRevertHistoryByType() {
  yield takeLatest(REVERT_HISTORY_SAGA, revertInlineHistorySaga);
}
