export default class MapEventObserver {
  private static isEventBlocked: boolean = false;

  public static lockMapBubbles(): void {
    MapEventObserver.isEventBlocked = true;
  }

  public static unlockMapBubbles(): void {
    MapEventObserver.isEventBlocked = false;
  }

  public static checkEventLock(): boolean {
    return MapEventObserver.isEventBlocked;
  }
}
