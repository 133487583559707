import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import _ from 'underscore';
import { withTheme } from 'styled-components/macro';

import { getFromURL } from 'api/getFromURL';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';
import { FormInputProps } from './model/FormInputProps.model';
import { ThemeProps } from './model/ThemeProps.model';
import Select from '../../components/Select/Select';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { OptionProps } from 'UI/common/components/Select/model/SelectProps.model';
import { RequestPath } from '../../../../types/enums/routes/request/RequestPath';

interface RelatedFieldInputProps extends FormInputProps, ThemeProps {}

const RelatedFieldInput = (props: RelatedFieldInputProps) => {
  const dispatch = useDispatch();
  const { control, errors } = useFormContext();
  const { description, object, field, inputProps } = props;
  const [options, setOptions] = useState<Array<OptionProps>>([]);
  const { required, message } = useBaseInputSettings(description);
  const error = _.get(errors, field);

  const objectValue = useMemo(() => (object ? _.get(object, field) : null), [field, object]);

  useEffect(() => {
    (async () => {
      if (description.url) {
        try {
          const model = await getFromURL.getModelFromURL(RequestPath.roadway);
          const choices = model.scheme.roadway_type.choices;

          const { results } = await getFromURL.getRepresentationData(description.url!.replace('/api', ''));

          const options = results.map((el: { key: number; value: string }) => {
            const currentEl = choices.find((els: any) => els.value === el.value);
            return {
              value: el.key,
              label: currentEl.display_name,
            };
          });

          setOptions(options);
        } catch (e) {
          if (_.isObject(e) && e?.response && _.isObject(e.response)) {
            const message = createAlertMessage(e);
            dispatch(
              notificationActions.setGlobalAlertData({
                status: ActiveStatus.active,
                type: PromptType.error,
                title: 'Ошибка',
                message: message,
              })
            );
            setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 5000);
          }
        }
      }
    })();
  }, [description, dispatch, field]);

  if (error && error.message?.includes(description.label)) {
    error.message = null;
  }

  const onChange = useCallback(([value]) => value ?? null, []);

  return (
    <Form.Item>
      <Controller
        name={field}
        control={control}
        defaultValue={objectValue}
        required={required}
        error={!!error}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        onChange={onChange}
        as={
          <Select
            {...inputProps}
            options={options}
            showSearch
            allowClear
            placeholder={description.label}
            mode={description?.type.includes('multi') ? 'multiple' : 'default'}
          />
        }
      />
    </Form.Item>
  );
};

export default withTheme(RelatedFieldInput);
