import { RoadSchemaLayer } from './RoadSchemaLayer';
import { TrolleybusLayer } from './TrolleybusLayer';
import { TramsSchemaLayer } from './TramsSchemaLayer';
import { ElectricalSubstationsLayer } from './ElectricalSubstationsLayer';
import { PublicTransportLayer } from './PublicTransportLayer';
import { TrafficLightObjectLayer } from './TrafficLightObjectLayer';
import { CamerasAndDetectorsLayer } from './CamerasAndDetectorsLayer';
import { RoadSpeedBumpsLayer } from './RoadSpeedBumpsLayer';
import { IlluminatedPedestrianCrossingsLayer } from './IlluminatedPedestrianCrossingsLayer';
import { CityDistrictsSchemaLayer } from './CityDistrictsSchemaLayer';
import { TrafficManagementProjectsLayer } from './TrafficManagementProjectsLayer';
import { ProjectLayer } from './ProjectLayer';
import { BrigadeApplicationsLayer } from './BrigadeApplicationsLayer';
import { MonitoringUDSLayer } from './MonitoringUDSLayer';
import { CamerasUdcLayer } from './CamerasUdcLayer';
import { TransportOrdersLayer } from './TransportOrdersLayer';
import { RoadAccidentsLayer } from './RoadAccidentsLayer';

/**
 * Используется в: создание слоёв, создание объектов
 * Индексация слоёв
 * В них подключаются данные для таблицы, данные объектов, карточка вызова, настройки, схема слоя, шаблоны и шаблон
 * для карточки объекта
 */

export const ekbLayers = {
  PublicTransportLayer,
  RoadSchemaLayer,
  TrolleybusLayer,
  TramsSchemaLayer,
  ElectricalSubstationsLayer,
  TrafficLightObjectLayer,
  CamerasAndDetectorsLayer,
  RoadSpeedBumpsLayer,
  IlluminatedPedestrianCrossingsLayer,
  CityDistrictsSchemaLayer,
  TrafficManagementProjectsLayer,
  ProjectLayer,
  BrigadeApplicationsLayer,
  MonitoringUDSLayer,
  CamerasUdcLayer,
  TransportOrdersLayer,
  RoadAccidentsLayer,
};
