import { Form } from 'antd';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form';

const StyledCheckbox = styled(Checkbox)``;

const StyledCheckboxWrapper = styled.div`
  height: auto;
  border: none;
  box-sizing: border-box;
  margin-bottom: 1.85rem;
  display: flex;
  align-items: center;
  & .ant-form-item {
    margin-bottom: 0;
  }
`;

const BooleanInput = (props) => {
  const { control, errors, setValue } = useFormContext();
  const { description, object, field, theme } = props;
  const [initialValue, setInitialValue] = useState(object?.[field] || false);

  if (object && object[field] && initialValue !== object[field]) {
    setInitialValue(object[field]);
  }

  useEffect(() => {
    setValue(field, initialValue);
  }, [field, initialValue, setValue]);

  const handleChangeCheckbox = useCallback(
    (e) => {
      setValue(field, e.target.checked);
    },
    [field, setValue]
  );

  return (
    <StyledCheckboxWrapper>
      <Form.Item key={'form_' + field} name={field}>
        <Controller
          value={initialValue}
          key={'input_' + field}
          name={field}
          disabled={description.read_only}
          as={(props) => (
            <StyledCheckbox
              checkboxColor={theme.colors?.primary}
              onChange={handleChangeCheckbox}
              checked={props?.checked || props?.value}
            >
              {description.label}
            </StyledCheckbox>
          )}
          control={control}
        />
      </Form.Item>
      <ErrorMessage errors={errors} name={field} />
    </StyledCheckboxWrapper>
  );
};

export default withTheme(BooleanInput);
