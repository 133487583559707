import { handleActions, Reducer } from 'redux-actions';

import _ from 'underscore';

import { MapReducer } from '../reducers.model';
import { rolesDirectoryActions } from './rolesDirectory.actions';
import {
  ChangeArrayDataTypes,
  DirectoryDataFilteredModel,
  ReplaceDataTypes,
  RoleElement,
  RolesDirectoryArrayDataFields,
  RolesDirectoryFields,
  RoleState,
} from './rolesDirectory.model';

const {
  DIRECTORY_DATA_FILTERED_ACTION_RETURN_ACTION,
  CLICKED_RIGHT_ACTION,
  EMPTY_STORE_WHEN_EXIT_ACTION,
  REPLACE_FIELD_BY_KEY,
  CHANGE_PART_ARRAY_DATA_BY_KEY,
} = rolesDirectoryActions;

const initialState: RoleState = {
  checkingDirectory: [],
  checkedDirectory: [],
  directoryData: {},
  directoryDataFiltered: {},
  clickedRight: {},
  checkingReports: [],
  checkedReports: [],
  nameRoles: '',
  rolesList: [],
  checkedRoles: [],
  checkingMap: [],
  checkedMap: [],
  organizationsList: [],
  checkedOrganizationsList: [],
  districtsList: [],
  checkedDistrictsList: [],
  permissionsMap: [],
  idForRightDirec: [],
  idForRightMap: [],
  idForRightReports: [],
};

const replaceFieldByKeyReducer: Reducer<RoleState, { data: ReplaceDataTypes; field: RolesDirectoryFields }> = (
  state,
  { payload }
) => {
  const { data, field } = payload;
  return {
    ...state,
    [field]: data,
  };
};

const changePartArrayDataByKeyReducer: Reducer<
  RoleState,
  { data: ChangeArrayDataTypes; field: RolesDirectoryArrayDataFields }
> = (state, { payload }) => {
  const { data, field } = payload;
  const currentState = state[field];
  const newState = [...currentState, ...data];
  const actualState = _.uniq(newState, 'value');
  return {
    ...state,
    [field]: actualState,
  };
};

const directoryDataFilteredActionReturnReducer: Reducer<RoleState, { data: RoleElement[]; pageMenuKey: string }> = (
  state,
  { payload }
) => {
  const { data, pageMenuKey } = payload;
  const currentState = state.directoryDataFiltered;
  const newState = [...data, ...currentState[pageMenuKey]];
  const checkRepeatingData = _.uniq(newState, 'value');
  const copyDirectoryDataFiltered = { ...state.directoryDataFiltered };
  return {
    ...state,
    directoryDataFiltered: { ...copyDirectoryDataFiltered, [pageMenuKey]: checkRepeatingData },
  };
};

const clickedRightReducer: Reducer<RoleState, { data: RoleElement[]; pageMenuKey: string }> = (state, { payload }) => {
  const { data, pageMenuKey } = payload;
  const currentState = state.clickedRight;
  const newState = [...currentState[pageMenuKey], ...data];
  const checkRepeatingData = _.uniq(newState, 'id');
  const copyClickedRight = { ...state.clickedRight };
  return {
    ...state,
    clickedRight: { ...copyClickedRight, [pageMenuKey]: checkRepeatingData },
  };
};

const emptyStoreWhenExitReducer: Reducer<RoleState, { data: DirectoryDataFilteredModel }> = () => {
  return {
    ...initialState,
  };
};

const mapReducer: MapReducer<RoleState, any> = {
  [REPLACE_FIELD_BY_KEY]: replaceFieldByKeyReducer,
  [CHANGE_PART_ARRAY_DATA_BY_KEY]: changePartArrayDataByKeyReducer,
  [DIRECTORY_DATA_FILTERED_ACTION_RETURN_ACTION]: directoryDataFilteredActionReturnReducer,
  [CLICKED_RIGHT_ACTION]: clickedRightReducer,
  [EMPTY_STORE_WHEN_EXIT_ACTION]: emptyStoreWhenExitReducer,
};

export const rolesDirectoryReducer: Reducer<RoleState, any> = handleActions(mapReducer, initialState);
