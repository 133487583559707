import { put, takeEvery } from 'redux-saga/effects';
import { mapboxDataActions } from '../../reducers/map/mapboxData/mapboxData.actions';

const INIT_MAP_TABLE_APPLY_FILTER_SAGA = 'INIT_MAP_TABLE_APPLY_FILTER_SAGA';
export const initMapTableApplyFilterSaga = (filteredTableNodes) => ({
  type: INIT_MAP_TABLE_APPLY_FILTER_SAGA,
  payload: {
    filteredTableNodes,
  },
});

function* tempNameSaga() {
  yield put({
    type: 'SAGA_STARTED',
  });
  //saga content

  yield put(mapboxDataActions.setDisableMapboxDataReplacingForcedStatus());

  //
  yield put({
    type: 'SAGA_ENDED',
  });
}

export function* watchMapTableApplyFilterSaga() {
  yield takeEvery(INIT_MAP_TABLE_APPLY_FILTER_SAGA, tempNameSaga);
}
