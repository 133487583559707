import styled from 'styled-components/macro';

const LocalMainWrapper = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const LocalSendArrowWrapper = styled.div`
  margin: 0 auto 20px;
  transform: rotate(270deg);
`;

const LocalBackArrowWrapper = styled.div`
  margin: 0 auto;
  transform: rotate(90deg);
`;

export const Styled = {
  LocalMainWrapper,
  LocalSendArrowWrapper,
  LocalBackArrowWrapper,
};
