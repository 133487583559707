import React from 'react';
import Icon from '@ant-design/icons';

const EarthOnIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9167 14.4917C14.7001 13.8251 14.0751 13.3334 13.3334 13.3334H12.5001V10.8334C12.5001 10.6124 12.4123 10.4004 12.256 10.2442C12.0997 10.0879 11.8878 10.0001 11.6667 10.0001H6.66675V8.33342H8.33342C8.55443 8.33342 8.76639 8.24562 8.92267 8.08934C9.07895 7.93306 9.16675 7.7211 9.16675 7.50008V5.83341H10.8334C11.2754 5.83341 11.6994 5.65782 12.0119 5.34526C12.3245 5.0327 12.5001 4.60878 12.5001 4.16675V3.82508C13.4971 4.22705 14.382 4.86464 15.0788 5.68328C15.7756 6.50191 16.2637 7.4772 16.5012 8.52567C16.7387 9.57414 16.7187 10.6646 16.4427 11.7036C16.1668 12.7426 15.6432 13.6993 14.9167 14.4917V14.4917ZM9.16675 16.6084C5.87508 16.2001 3.33341 13.4001 3.33341 10.0001C3.33341 9.48342 3.40008 8.98342 3.50841 8.50842L7.50008 12.5001V13.3334C7.50008 13.7754 7.67568 14.1994 7.98824 14.5119C8.3008 14.8245 8.72472 15.0001 9.16675 15.0001V16.6084ZM10.0001 1.66675C8.90573 1.66675 7.8221 1.8823 6.81105 2.30109C5.80001 2.71987 4.88135 3.3337 4.10752 4.10752C2.54472 5.67033 1.66675 7.78994 1.66675 10.0001C1.66675 12.2102 2.54472 14.3298 4.10752 15.8926C4.88135 16.6665 5.80001 17.2803 6.81105 17.6991C7.8221 18.1179 8.90573 18.3334 10.0001 18.3334C12.2102 18.3334 14.3298 17.4554 15.8926 15.8926C17.4554 14.3298 18.3334 12.2102 18.3334 10.0001C18.3334 8.90573 18.1179 7.8221 17.6991 6.81105C17.2803 5.80001 16.6665 4.88135 15.8926 4.10752C15.1188 3.3337 14.2002 2.71987 13.1891 2.30109C12.1781 1.8823 11.0944 1.66675 10.0001 1.66675V1.66675Z"
      fill="currentColor"
    />
  </svg>
);

export const EarthOnIcon = (props) => <Icon component={EarthOnIconSVG} {...props} />;
