import React from 'react';
import Icon from '@ant-design/icons';

const TextIconSVG = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66667 2.5C3.74167 2.5 3 3.24167 3 4.16667V15.8333C3 16.7583 3.74167 17.5 4.66667 17.5H16.3333C17.2583 17.5 18 16.7583 18 15.8333V4.16667C18 3.24167 17.2583 2.5 16.3333 2.5H4.66667ZM4.66667 4.16667H16.3333V15.8333H4.66667V4.16667ZM6.33333 5.83333V7.5H14.6667V5.83333H6.33333ZM6.33333 9.16667V10.8333H14.6667V9.16667H6.33333ZM6.33333 12.5V14.1667H12.1667V12.5H6.33333Z"
      fill="currentColor"
    />
  </svg>
);

export const TextIcon = (props) => <Icon component={TextIconSVG} {...props} />;
