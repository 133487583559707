import PermissionsPanel from 'components/Application/ExtraModules/CustomRolesDirectory/parts/PermissionsPanel';
import { AdministrationRouteModel } from 'types/enums/routes/administration/AdministrationRoute.model';
import { RequestPath } from 'types/enums/routes/request/RequestPath';
import CreateButton from 'UI/custom/actionsButtons/common/CreateButton';
import DeleteButton from 'UI/custom/actionsButtons/common/DeleteButton';
import { RefreshButton } from 'UI/custom/actionsButtons/common/RefreshButton';
import CompendiumContainer from 'UI/custom/Aggrid/Compendium/CompendiumContainer';

import { AdministrationRegistratorObjectProps } from './AdministrationRegistratorObjectProps';
import { AdministrationFolders } from './folders/AdministrationFolders';
import { resolver } from './resolver';
import { generateRightsByKey } from '../../services/application/permissions/generateRightsByKey';

export const resolveUrl = resolver;

const defaultFields = {
  generalRenderer: CompendiumContainer,
  objectPanel: PermissionsPanel,
  gridActionButtons: [CreateButton, RefreshButton, DeleteButton],
  folderKey: AdministrationFolders.administration,
};

/***
 *register urls for application and custom renderers
 * register null if render is unnecessary
 */
export const registeredAdministrationUrls: AdministrationRegistratorObjectProps[] = [
  {
    url: AdministrationRouteModel.users,
    urlData: RequestPath.users,
    label: 'Пользователи',
    description: 'Список активных пользователей',
    permissionType: generateRightsByKey('user', 'users'),
    ...defaultFields,
  },
  {
    url: AdministrationRouteModel.permissionsGroups,
    urlData: RequestPath.permissionsGroups,
    label: 'Роли',
    description: 'Создание ролей для ограничения доступа в системе',
    permissionType: generateRightsByKey('group', 'auth'),
    ...defaultFields,
  },
];
