import React from 'react';
import Icon from '@ant-design/icons';

const BridgeIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83398 11.6668V9.09183C5.23398 8.81683 4.67565 8.4835 4.16732 8.09183V11.6668H5.83398ZM4.16732 15.0002H2.50065V13.3335H0.833984V11.6668H2.50065V5.8335H4.16732V7.02516C5.66732 8.3335 7.72565 9.16683 10.0006 9.16683C12.2756 9.16683 14.334 8.3335 15.834 7.02516V5.8335H17.5006V11.6668H19.1673V13.3335H17.5006V15.0002H15.834V13.3335H4.16732V15.0002ZM14.1673 9.09183V11.6668H15.834V8.09183C15.3256 8.4835 14.7673 8.81683 14.1673 9.09183ZM13.334 11.6668V9.4335C12.8006 9.62516 12.2423 9.76683 11.6673 9.86683V11.6668H13.334ZM10.834 11.6668V9.96683L10.0006 10.0002L9.16732 9.96683V11.6668H10.834ZM8.33398 11.6668V9.86683C7.75898 9.76683 7.20065 9.62516 6.66732 9.4335V11.6668H8.33398Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const BridgeIcon = (props) => <Icon component={BridgeIconSVG} {...props} />;
