import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from 'styled-components';

import { forageStore } from 'services/axiosInstances/axiosSecured';
import { getLastHistoryRecord } from 'store/reducers/directory/directoryHistory';
import { RELOAD_HISTORY_INLINE_SAGA } from 'store/sagas/directory/inlineHistorySaga';
import { ApplicationTheme } from 'styles/themes/model/ApplicationTheme.model';
import { Button } from '../../../common/components/Button/Button';
import { ButtonType } from '../../../common/components/Button/model/Button.model';
import { RefreshIcon } from '../../icons/actionIcons/RefreshIcon';

interface IRefreshButtonProps {
  loadingFromMaps?: boolean;
  refreshData?: (() => void) | (() => Promise<void>);
  tooltip?: string;
  disabled?: boolean;
  CRUDHandlers?: {
    refresh?: () => void;
  };
}

export const RefreshButton = ({
  loadingFromMaps,
  refreshData,
  tooltip,
  disabled,
  CRUDHandlers,
}: IRefreshButtonProps) => {
  const theme = useTheme() as ApplicationTheme;
  const lastInlineHistory = useSelector((state) => getLastHistoryRecord(state));
  const dispatch = useDispatch();

  const refreshHandler = useCallback(() => {
    dispatch({
      type: RELOAD_HISTORY_INLINE_SAGA,
      payload: {
        lastInlineHistory,
      },
    });
  }, [dispatch, lastInlineHistory]);
  const iconSize = useRef({ width: '20px', height: '20px' });

  const handleRefreshButtonClick = useCallback(async () => {
    await forageStore.clear();
    if (CRUDHandlers?.refresh) {
      CRUDHandlers.refresh();
    } else {
      refreshData ? refreshData() : refreshHandler();
    }
  }, [CRUDHandlers, refreshData, refreshHandler]);

  return (
    <Button
      rounded
      hasShadow={!loadingFromMaps}
      type={loadingFromMaps ? ButtonType.unboundedPrimary : ButtonType.danger}
      p={loadingFromMaps ? 'none' : '0.7rem'}
      icon={<RefreshIcon />}
      iconColor={theme.colors.primary}
      iconSize={iconSize.current}
      onClick={handleRefreshButtonClick}
      tooltip={tooltip}
      disabled={disabled}
    />
  );
};
