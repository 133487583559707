import { useEffect, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Styled } from './PrepareScrollableInfoCard.styled';
import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { CloseIcon } from 'UI/custom/icons/actionIcons/CloseIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';
import { createDescriptionItem } from './PrepareScrollableInfoCard';
import TextIconButton from 'UI/custom/actionsButtons/common/TextIconButton';
import UploadTMCFileTextButton from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileTextButton';
import { RoadWorksService, TrafficManagementProjectFile } from 'generated/api/api';
import { AnyObject } from 'types/enums/general/general.model';
import { FormGeneratedField } from 'types/interfaces/forms/FormGeneratedField.model';
import { RequestPath } from '../../types/enums/routes/request/RequestPath';

const { confirm } = Modal;

const {
  LocalHeaderWrapper,
  ExtendedBtnClose,
  LocalHeaderTitle,
  LocalItem,
  ButtonContainer,
  LocalWrapperBtn,
  FilesContainer,
  LocalWrapperBtnPrimary,
} = Styled;

interface IData extends TrafficManagementProjectFile {
  [k: string]: any;
}

interface IModel {
  scheme: {
    [key: string]: FormGeneratedField;
  };
  [key: string]: any;
}

interface ITrafficManagementProjectsCard {
  handleCloseMapSelectedObjectInfo: () => void;
  selectedMapItem: AnyObject;
  mode: AnyObject;
  data: IData;
  model: IModel;
}

const fields: string[] = ['created_at', 'created_by_full_name', 'updated_at'];

export const TrafficManagementProjectsCard = ({
  handleCloseMapSelectedObjectInfo,
  data,
  mode,
  model,
}: PropsWithChildren<ITrafficManagementProjectsCard>) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<AnyObject | undefined>();
  const [files, setFiles] = useState<AnyObject>({});
  const { scheme } = model;
  const { id, name } = data;

  useEffect(() => {
    (async () => {
      const response = await RoadWorksService.roadWorksTrafficManagementProjectsFilesList({
        trafficManagementProject: `${id}`,
      });
      setFiles(response);
    })();
  }, [id]);

  const downloadFile = useCallback(() => {
    const url = selectedFile?.file_url;
    const win = window.open(url, '_blank');
    win?.focus();
  }, [selectedFile]);

  const filesContainer = useMemo(
    () => (
      <FilesContainer>
        {files.results?.map((file: AnyObject, index: number) => (
          <p
            key={`file_name-${index}`}
            className={selectedFile?.file_url && selectedFile.file_url === file.file_url ? 'selected_file' : ''}
            onClick={() => setSelectedFile(file)}
          >
            {file.file_name}
          </p>
        ))}
      </FilesContainer>
    ),
    [files, selectedFile]
  );

  const clickDeleteMainObject = useCallback(() => {
    const title = 'Вы действительно желаете удалить объект';
    confirm({
      title: title,
      icon: <QuestionCircleOutlined />,
      onOk() {
        dispatch(deleteObject({ url: mode.urlData, id }));
      },
    });
  }, [dispatch, id, mode.urlData]);

  const clickDeleteFile = useCallback(() => {
    if (selectedFile) {
      const title = 'Вы действительно желаете удалить файл';
      const url = RequestPath.trafficManagmentFiles;
      confirm({
        title: title,
        icon: <QuestionCircleOutlined />,
        onOk() {
          dispatch(deleteObject({ url, id: selectedFile?.id }));
        },
      });
    }
  }, [dispatch, selectedFile]);

  const addAndDownloadFilesButton = useMemo(
    () => [
      <UploadTMCFileTextButton tooltip={'Добавить файл'} id={`${id}`} pageMenuKey={mode.urlData} />,
      <LocalWrapperBtnPrimary>
        <TextIconButton
          clickButton={downloadFile}
          tooltip={'Скачать файл'}
          isDisabled={!selectedFile}
          labelText={'Скачать файл'}
          type={ButtonType.primary}
        />
      </LocalWrapperBtnPrimary>,
      <LocalWrapperBtn>
        <TextIconButton
          clickButton={clickDeleteFile}
          tooltip={'Удалить файл'}
          isDisabled={!selectedFile}
          labelText={'Удалить файл'}
          type={ButtonType.dangerDelete}
        />
      </LocalWrapperBtn>,
    ],
    [clickDeleteFile, downloadFile, id, mode.urlData, selectedFile]
  );

  const Descriptions = useMemo(
    () => fields.map((field: string) => ({ key: scheme?.[field]?.label, value: data[field] ?? 'Не указан' })),
    [scheme, data]
  );

  const handleOpenObjectInNewTab = useCallback(() => {
    const win = window.open(
      `/app/directory/${DirectoryRouteModel.roadWorksTrafficManagementProjects}/${id}/`,
      '_blank'
    );
    win?.focus();
  }, [id]);

  const preparedDescItems = useMemo(
    () =>
      Descriptions.map((descItem) => {
        const { key, value } = descItem;
        return createDescriptionItem(key, value);
      }),
    [Descriptions]
  );

  return (
    <ScrollableCard
      headerContent={
        <LocalHeaderWrapper>
          <LocalHeaderTitle>{name}</LocalHeaderTitle>
          <ExtendedBtnClose
            onClick={handleCloseMapSelectedObjectInfo}
            icon={<CloseIcon />}
            type={ButtonType.unboundedPrimary}
            p={0}
          />
        </LocalHeaderWrapper>
      }
      children={
        <LocalItem>
          {filesContainer}
          {addAndDownloadFilesButton}
          {preparedDescItems}
        </LocalItem>
      }
      footerContent={
        <div>
          <ButtonContainer onClick={handleOpenObjectInNewTab} type={ButtonType.primary}>
            Открыть в справочнике
          </ButtonContainer>
          <ButtonContainer onClick={clickDeleteMainObject} type={ButtonType.dangerDelete}>
            <span>Удалить ПОДД</span>
          </ButtonContainer>
        </div>
      }
    />
  );
};
