import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Styled } from './style/Input.styled';
import { Input as InputAntd } from 'antd';
import { InputProps } from './model/Input.model';
import { EyeIcon, EyeTransparentIcon } from '../../../custom/icons/actionIcons/EyeIcon';

const {
  StyledInputContainer,
  StyledInputWrap,
  StyledInputEditablePlaceholder,
  StyledInputClearButton,
  StyledInputLabel,
} = Styled;

const Input: FC<InputProps> = ({
  value = '',
  disabled,
  small,
  placeholder,
  editableHelp = 'Введите данные',
  label,
  icon,
  error,
  allowClear,
  autoWidth,
  onChange,
  onBlur,
  onFocus,
  name,
  type,
}) => {
  const [visiblePlaceholder, setVisiblePlaceholder] = useState(false);
  const [isError, changeError] = useState(error);
  const [inputValue, setInputValue] = useState(value);
  const placeholderLength = placeholder && placeholder.length > 25;

  useEffect(() => changeError(error), [error]);
  useEffect(() => setInputValue(value ?? undefined), [value]);

  const showClearButton = useMemo(
    () => !!(allowClear && !!inputValue && !disabled),
    [allowClear, disabled, inputValue]
  );

  const placeholderText = useMemo(
    () => (visiblePlaceholder ? editableHelp : placeholder),
    [editableHelp, placeholder, visiblePlaceholder]
  );

  const classNameWrap = useMemo(() => {
    const className: Array<string> = [];

    if (small) className.push('small');

    if (autoWidth) className.push('auto');

    if (icon) className.push('icon');

    if (visiblePlaceholder) className.push('focus');

    if (visiblePlaceholder || !!inputValue) className.push('placeholder_top');

    if (isError) className.push('error');

    if (showClearButton) className.push('clear');

    if (disabled) className.push('disabled');

    return className.join(' ');
  }, [autoWidth, disabled, isError, icon, inputValue, showClearButton, small, visiblePlaceholder]);

  const onFocusInput = useCallback(() => {
    setVisiblePlaceholder(true);
    if (onFocus) onFocus();
  }, [onFocus]);

  const onBlurInput = useCallback(() => {
    setVisiblePlaceholder(false);
    if (onBlur) onBlur();
  }, [onBlur]);

  const onChangeInput = useCallback(
    (e) => {
      setInputValue(e.currentTarget.value);
      if (onChange) onChange(e.currentTarget.value);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    setInputValue('');
  }, []);

  return (
    <StyledInputContainer>
      <StyledInputWrap className={classNameWrap} placeholderLength={placeholderLength}>
        {!small && <StyledInputEditablePlaceholder>{placeholder}</StyledInputEditablePlaceholder>}
        {type === 'password' ? (
          <InputAntd.Password
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            disabled={disabled}
            prefix={icon}
            value={inputValue}
            placeholder={placeholderText}
            onChange={onChangeInput}
            name={name}
            type={type}
            // @ts-ignore TODO когда обновится антд ошибка должна уйти и иконки встать Тех-долг-FRONTEND #5553
            iconRender={(visible) => (visible ? <EyeTransparentIcon /> : <EyeIcon />)}
          />
        ) : (
          <InputAntd
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            disabled={disabled}
            prefix={icon}
            value={inputValue}
            placeholder={placeholderText}
            onChange={onChangeInput}
            name={name}
            type={type}
          />
        )}

        {showClearButton && <StyledInputClearButton onClick={onClear} />}
      </StyledInputWrap>
      {label && <StyledInputLabel>{label}</StyledInputLabel>}
    </StyledInputContainer>
  );
};

export default Input;
