import { LayerCardPrototype } from '../prototype/LayerCardPrototype';

export class RoadSchemaCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'Цифровой двойник',
      linkToPreview: 'SchemaRoad.svg',
      permissionToShowCard: 'layer_permissions.can_view_digital_twin_layer',
    });
  }
}
