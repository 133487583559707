import RepresentationCheckedItem from 'components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/parts/checkingWidget/RepresentationCheckedItem';
import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction, useMemo } from 'react';

import { DirectoryElement, RoleElement } from 'store/reducers/rolesDirectory/rolesDirectory.model';

import { Styled } from './RenderCollectionContainer.styled';

const { LocalMainWrapper, LocalText } = Styled;

interface RenderCollectionContainerProps {
  title: string;
  renderList: (values?: RoleElement[]) => ReactNode[] | undefined;
  items: RoleElement[] | DirectoryElement[];
  width: number;
  height: number;
  updateInput: (currentInput: ChangeEvent<HTMLInputElement>) => void;
  input: string;
  handleAllChecked: (currentInput: ChangeEvent<HTMLInputElement>) => void;
  nameAllCheckbox: string;
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
}

export const RenderCollectionContainer = ({
  title,
  renderList,
  items,
  width,
  height,
  updateInput,
  input,
  handleAllChecked,
  nameAllCheckbox,
  isChecked,
  setIsChecked,
}: RenderCollectionContainerProps) => {
  const memoizationItems = useMemo(() => renderList(items) ?? [], [renderList, items]);

  return (
    <>
      <LocalMainWrapper>
        <LocalText>{title}</LocalText>
        <RepresentationCheckedItem
          input={input}
          updateInput={updateInput}
          width={width}
          height={height}
          items={memoizationItems}
          handleAllChecked={handleAllChecked}
          nameAllCheckbox={nameAllCheckbox}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        />
      </LocalMainWrapper>
    </>
  );
};
