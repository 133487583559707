import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';
import { Modal, Input } from 'antd';

const LocalDescItemName = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  word-break: break-word;
  color: ${(props) => props.theme.colors.neutralDarker};
  margin: 0;
`;

const LocalItem = styled.div``;

const LocalItemWrap = styled.article`
  text-align: left;
  width: 100%;
  min-height: 60px;
  height: fit-content;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  background-color: ${(props) => props.theme.colors.defaultLight};
  padding: 12px 16px;

  :not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const LocalDescItemContent = styled.p`
  color: ${(props) => (props.color ? props.color : props.theme.colors.defaultDark)};
  font-weight: 500;
  word-break: break-word;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  margin: 0;
`;

const ExtendedBtnClose = styled(Button)`
  position: absolute;
  top: 9px;
  right: 12px;
`;

const LocalHeaderWrapper = styled.div`
  width: 100%;
`;

const LocalWrapperBtn = styled.div`
  button {
    width: 100%;
    margin-bottom: 5px;
  }

  button:hover {
    p {
      color: ${(props) => props.theme.colors.defaultLighter};
    }
  }
`;

const LocalWrapperBtnPrimary = styled(LocalWrapperBtn)`
  p {
    color: ${(props) => props.theme.colors.defaultLighter};
  }
`;

const LocalHeaderTitle = styled.div`
  width: 170px;
  color: ${(props) => props.theme.colors.defaultDark};
  word-break: break-word;
`;

const ButtonContainer = styled(Button)`
  margin: 5px 0;
  width: 100%;

  span:hover {
    color: ${(props) => props.theme.colors.defaultLighter};
  }
`;

const FilesContainer = styled.div`
  margin-top: 10px;

  p {
    padding: 0 0 1px 5px;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .selected_file {
    padding: 0 0 0 7px;
    font-weight: 700;
    font-size: 15px;
    text-decoration: underline;
  }
`;

const LocalItemButtonWrap = styled.div`
  background: ${(props) => props.theme.colors.transparent};

  button {
    ${(props) => (props?.style?.backgroundColor ? `background:${props.style.backgroundColor};` : '')}
    ${(props) => (props?.style?.color ? `color:${props.style.color};` : '')}
    width: 100%;
    margin-top: 16px;
  }
`;

const HeaderTop = styled.div`
  margin-bottom: 14px;
`;

const HeaderButtonWrap = styled.div`
  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  button.inactive {
    width: auto;
  }
`;

const FooterButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  width: 100%;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: ${(props) => props.theme.decoration.largeBorderRadius};
    overflow: hidden;
  }

  & .ant-modal-body {
    padding: 0;
  }

  & .ant-modal-close-x {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
`;

const StyledModalHeader = styled.div`
  background: ${(props) => props.theme.colors.defaultLighter};
  box-shadow: ${(props) => props.theme.decoration.panelBoxShadow};
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSettings.large};
  line-height: 120%;
  color: ${(props) => props.theme.colors.dark};
`;

const StyledModalBody = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const StyledModalFooter = styled.div`
  padding: 16px;
  box-shadow: ${(props) => props.theme.decoration.panelBoxShadow};
`;

const StyledModalTextArea = styled(Input.TextArea)`
  resize: none;
  height: 150px !important;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.default};
  line-height: 140%;
  color: ${(props) => props.theme.colors.dark};
`;

const StyledModalConfirmedTitle = styled.span`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSettings.large};
  line-height: 120%;
  color: ${(props) => props.theme.colors.danger};
  margin-bottom: 9px;
`;

const StyledModalConfirmedText = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.dark};
  margin-bottom: 16px;
`;

const StyledModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 112px);
  grid-gap: 16px;
`;

export const Styled = {
  LocalDescItemName,
  LocalDescItemContent,
  LocalHeaderTitle,
  LocalWrapperBtn,
  LocalItem,
  LocalHeaderWrapper,
  LocalItemButtonWrap,
  ExtendedBtnClose,
  ButtonContainer,
  FilesContainer,
  LocalWrapperBtnPrimary,
  HeaderTop,
  HeaderButtonWrap,
  FooterButtonWrap,
  LocalItemWrap,

  StyledModal,
  StyledModalHeader,
  StyledModalBody,
  StyledModalFooter,
  StyledModalTextArea,
  StyledModalConfirmedTitle,
  StyledModalConfirmedText,
  StyledModalButtons,
};
