import React from 'react';
import Icon from '@ant-design/icons';

const MapPlusIconSVG = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 2.5L2.8 4.08333C2.63333 4.14167 2.5 4.29167 2.5 4.48333V17.0833C2.5 17.1938 2.5439 17.2998 2.62204 17.378C2.70018 17.4561 2.80616 17.5 2.91667 17.5C2.95833 17.5 3 17.5 3.05 17.475L7.5 15.75L10.9667 16.9667C10.8833 16.6 10.8333 16.2167 10.8333 15.8333C10.8333 15.6417 10.8333 15.45 10.8667 15.25L7.5 14.0833V4.16667L12.5 5.91667V12.1333C13.3917 11.3333 14.5583 10.8333 15.8333 10.8333C16.4167 10.8333 16.975 10.9417 17.5 11.1333V2.91667C17.5 2.80616 17.4561 2.70018 17.378 2.62204C17.2998 2.5439 17.1938 2.5 17.0833 2.5H16.95L12.5 4.25L7.5 2.5ZM15 12.5V15H12.5V16.6667H15V19.1667H16.6667V16.6667H19.1667V15H16.6667V12.5H15Z'
      fill='currentColor' />
  </svg>
);

export const MapPlusIcon = (props) => <Icon component={MapPlusIconSVG} {...props} />;
