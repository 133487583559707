import React from 'react';
import Icon from '@ant-design/icons';

const UploadFileIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6668 1.66675L16.6668 6.66675V16.6667C16.6668 17.1088 16.4912 17.5327 16.1787 17.8453C15.8661 18.1578 15.4422 18.3334 15.0002 18.3334H5.00016C4.55814 18.3334 4.13421 18.1578 3.82165 17.8453C3.50909 17.5327 3.3335 17.1088 3.3335 16.6667V3.33341C3.3335 2.89139 3.50909 2.46746 3.82165 2.1549C4.13421 1.84234 4.55814 1.66675 5.00016 1.66675H11.6668ZM15.0002 16.6667V7.50008H10.8335V3.33341H5.00016V16.6667H15.0002ZM10.0002 10.0001L13.3335 13.3334H11.2502V15.8334H8.75016V13.3334H6.66683L10.0002 10.0001Z"
      fill="currentColor"
    />
  </svg>
);
export const UploadFileIcon = (props) => <Icon component={UploadFileIconSVG} {...props} />;
