import { Action, ActionFunction0, ActionFunction2, createAction } from 'redux-actions';

import {
  ChangeArrayDataTypes,
  ChangeDirectoryDataFilteredsAction,
  ChangePartArrayDataByKeyAction,
  ReplaceDataTypes,
  ReplaceFieldByKeyAction,
  RoleElement,
  RolesDirectoryArrayDataFields,
  RolesDirectoryFields,
} from './rolesDirectory.model';

class RolesDirectoryActions {
  /** action types */
  public readonly prefix: string = 'ROLES_DIRECTORY';
  public readonly DIRECTORY_DATA_FILTERED_ACTION_RETURN_ACTION: string = `${this.prefix}.DIRECTORY_DATA_FILTERED_ACTION_RETURN_ACTION`;
  public readonly CLICKED_RIGHT_ACTION: string = `${this.prefix}.CLICKED_RIGHT_ACTION`;
  public readonly EMPTY_STORE_WHEN_EXIT_ACTION: string = `${this.prefix}.EMPTY_STORE_WHEN_EXIT_ACTION`;
  public readonly REPLACE_FIELD_BY_KEY: string = `${this.prefix}.REPLACE_FIELD_BY_KEY`;
  public readonly CHANGE_PART_ARRAY_DATA_BY_KEY: string = `${this.prefix}.CHANGE_PART_ARRAY_DATA_BY_KEY`;
  public readonly DIRECTORY_PART_IN_DIRECTORY_BY_KEY_REDUCER: string = `${this.prefix}.DIRECTORY_PART_IN_DIRECTORY_BY_KEY_REDUCER`;

  /** actions */
  public replaceFieldByKeyActions: ActionFunction2<
    ReplaceDataTypes,
    RolesDirectoryFields,
    Action<ReplaceFieldByKeyAction>
  > = createAction(this.REPLACE_FIELD_BY_KEY, (data: ReplaceDataTypes, field: RolesDirectoryFields) => ({
    data,
    field,
  }));

  public changePartArrayDataByKeyActions: ActionFunction2<
    ChangeArrayDataTypes,
    RolesDirectoryArrayDataFields,
    Action<ChangePartArrayDataByKeyAction>
  > = createAction(
    this.CHANGE_PART_ARRAY_DATA_BY_KEY,
    (data: ChangeArrayDataTypes, field: RolesDirectoryArrayDataFields) => ({ data, field })
  );

  public directoryDataFilteredActionReturn: ActionFunction2<
    RoleElement[],
    string,
    Action<ChangeDirectoryDataFilteredsAction>
  > = createAction(this.DIRECTORY_DATA_FILTERED_ACTION_RETURN_ACTION, (data: RoleElement[], pageMenuKey: string) => ({
    data,
    pageMenuKey,
  }));

  public clickedRightAction: ActionFunction2<RoleElement[], string, Action<ChangeDirectoryDataFilteredsAction>> =
    createAction(this.CLICKED_RIGHT_ACTION, (data: RoleElement[], pageMenuKey: string) => ({ data, pageMenuKey }));

  public emptyStoreWhenExitAction: ActionFunction0<Action<null>> = createAction(this.EMPTY_STORE_WHEN_EXIT_ACTION);
}

export const rolesDirectoryActions = new RolesDirectoryActions();
