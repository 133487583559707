import React from 'react';
import Icon from '@ant-design/icons';

const CreatBlankStatementIconSVG = () => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10H8V8H0V10ZM16 8V4H14V8H10V10H14V14H16V10H20V8H16ZM12 0H0V2H12V0ZM12 4H0V6H12V4Z" fill="#6B8AE4" />
  </svg>
);

export const CreatBlankStatementIcon = (props) => <Icon component={CreatBlankStatementIconSVG} {...props} />;
