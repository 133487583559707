import React from 'react';
import Icon from '@ant-design/icons';
const FerryCrossingsIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99935 5.00016H14.9993V8.30016L9.99935 6.66683L4.99935 8.30016V5.00016ZM3.28268 15.8335H3.33268C4.66602 15.8335 5.83268 15.1002 6.66602 14.1668C7.49935 15.1002 8.66602 15.8335 9.99935 15.8335C11.3327 15.8335 12.4993 15.1002 13.3327 14.1668C14.166 15.1002 15.3327 15.8335 16.666 15.8335H16.7077L18.291 10.2585C18.3577 10.0502 18.3327 9.81683 18.241 9.61683C18.1327 9.41683 17.9577 9.26683 17.741 9.20016L16.666 8.85016V5.00016C16.666 4.55813 16.4904 4.13421 16.1779 3.82165C15.8653 3.50909 15.4414 3.3335 14.9993 3.3335H12.4993V0.833496H7.49935V3.3335H4.99935C4.55732 3.3335 4.1334 3.50909 3.82084 3.82165C3.50828 4.13421 3.33268 4.55813 3.33268 5.00016V8.85016L2.25768 9.20016C2.04102 9.26683 1.86602 9.41683 1.75768 9.61683C1.66602 9.81683 1.64102 10.0502 1.70768 10.2585L3.28268 15.8335ZM16.666 17.5002C15.5077 17.5002 14.3493 17.1085 13.3327 16.3918C11.2993 17.8168 8.69935 17.8168 6.66602 16.3918C5.64935 17.1085 4.49102 17.5002 3.33268 17.5002H1.66602V19.1668H3.33268C4.47435 19.1668 5.61602 18.8752 6.66602 18.3335C8.74935 19.4168 11.2493 19.4168 13.3327 18.3335C14.3827 18.8752 15.516 19.1668 16.666 19.1668H18.3327V17.5002H16.666Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const FerryCrossingsIcon = (props) => <Icon component={FerryCrossingsIconSVG} {...props} />;
