import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class RoadsDividingLines extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'dividing_lines',
      filterLabel: 'Разделительная линия',
      selectedObjectName: MapObjects.dividingLines,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsDividingLinesList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsDividingLinesDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsDividingLinesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadsDividingLines);
  }
}
