import { LayerCardPrototype } from '../prototype/LayerCardPrototype';

export class PublicTransportCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'Общественный транспорт',
      linkToPreview: 'Passajirskiy.svg',
      permissionToShowCard: 'layer_permissions.can_view_public_transport_layer',
    });
  }
}
