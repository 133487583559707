import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { TransportDetectorsService } from '../../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class CamerasAndDetectors extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'cameras_and_detectors',
      filterLabel: 'Камеры и детекторы',
      selectedObjectName: MapObjects.camerasAndDetectors,
    });
  }

  async uploadData() {
    return await TransportDetectorsService.transportDetectorsList();
  }

  async readRecord(id) {
    return await TransportDetectorsService.transportDetectorsRetrieve({ id });
  }

  async deleteRecord(id) {
    return await TransportDetectorsService.transportDetectorsDestroy({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.transportDetectors);
  }
}
