import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { Detection } from '../../../generated/api/api';
import { isEmpty, nth } from 'lodash';
import { useModeratorRequests } from './hooks/useModeratorRequests';

export interface ModeratorContextValue {
  detectionList: Detection[];
  activeModeration?: Detection;
}

const initialModeratorContextValues: ModeratorContextValue = {
  detectionList: [],
  activeModeration: undefined,
};

// noinspection JSUnusedLocalSymbols
const initialContextValue = {
  isFetching: false,
  moderation: initialModeratorContextValues,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  activateDetection: (detection: Detection) => {
    console.warn('MapDataContext не инициализирован');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDetectionList: (detectionList: Detection[]) => {
    console.warn('ModeratorContext не инициализирован');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  approveDetection: (detection: Detection) => {
    console.warn('ModeratorContext не инициализирован');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  declineDetection: (detection: Detection) => {
    console.warn('ModeratorContext не инициализирован');
  },
  uploadDetectionList: () => {
    console.warn('ModeratorContext не инициализирован');
  },
  handleSelectDeltaItem: (deltaArrayIndex: number) => {
    console.warn('ModeratorContext не инициализирован');
  },
};

const ModeratorContext = createContext(initialContextValue);

export const useModeratorContext = () => useContext(ModeratorContext);

interface ModeratorContextProviderProps {
  children: ReactNode;
  onNetworkError?: (error: any) => void;
}

export const ModeratorContextProvider = ({ children, onNetworkError }: ModeratorContextProviderProps) => {
  const [moderatorContextValue, setModeratorContextValue] = useState(initialModeratorContextValues);
  const { approveDetectionRequest, declineDetectionRequest, uploadDetectionListRequest, isFetching } =
    useModeratorRequests({ onNetworkError });

  const activateDetection = useCallback((detection: Detection) => {
    setModeratorContextValue((prevState) => ({
      ...prevState,
      activeModeration: detection,
    }));
  }, []);

  const setDetectionList = useCallback((detectionList: Detection[], newDetectionIndex?: number) => {
    if (!isEmpty(detectionList)) {
      const userDetection = detectionList[newDetectionIndex || 0];
      setModeratorContextValue({
        detectionList,
        activeModeration: userDetection,
      });
    } else {
      setModeratorContextValue({
        detectionList: [],
        activeModeration: undefined,
      });
    }
  }, []);

  const handleAfterSuccessAction = useCallback(
    (updatedDetection: Detection | null) => {
      if (updatedDetection) {
        const deletionIndex =
          moderatorContextValue.detectionList.findIndex(
            (storedDetection) => storedDetection.external_id === updatedDetection.external_id
          ) ?? 0;
        const newSelectedDetectionIndex =
          deletionIndex !== moderatorContextValue.detectionList.length ? deletionIndex : deletionIndex - 1;
        const filteredDetections = moderatorContextValue.detectionList.filter(
          (storedDetection) => storedDetection.external_id !== updatedDetection.external_id
        );
        setDetectionList(filteredDetections, newSelectedDetectionIndex);
      }
    },
    [moderatorContextValue.detectionList, setDetectionList]
  );

  const approveDetection = useCallback(
    (detection: Detection) => {
      approveDetectionRequest(detection)
        .then(handleAfterSuccessAction)
        .catch((e) => {
          onNetworkError && onNetworkError(e);
        });
    },
    [handleAfterSuccessAction, approveDetectionRequest, onNetworkError]
  );

  const declineDetection = useCallback(
    (detection: Detection) => {
      declineDetectionRequest(detection)
        .then(handleAfterSuccessAction)
        .catch((e) => {
          onNetworkError && onNetworkError(e);
        });
    },
    [handleAfterSuccessAction, declineDetectionRequest, onNetworkError]
  );

  const uploadDetectionList = useCallback(() => {
    uploadDetectionListRequest().then((detections) => {
      if (!isEmpty(detections)) {
        setDetectionList(detections);
      }
    });
  }, [setDetectionList, uploadDetectionListRequest]);

  const handleSelectDeltaItem = useCallback(
    (deltaArrayIndex: number) => {
      const currentActiveIndex =
        moderatorContextValue.detectionList.findIndex(
          (detections) => detections.external_id === moderatorContextValue.activeModeration?.external_id
        ) ?? 0;
      const currentListLength = moderatorContextValue.detectionList.length;

      const getNewIndex = () => {
        if (currentListLength === 0) return 0;
        const rawNewIndex = currentActiveIndex + deltaArrayIndex;
        if (rawNewIndex > currentActiveIndex) {
          return rawNewIndex - currentListLength;
        }
        if (rawNewIndex < 0) {
          return currentListLength + rawNewIndex;
        }
        return rawNewIndex;
      };

      const newIndex = getNewIndex();
      const newActiveItem = nth(moderatorContextValue.detectionList, newIndex) ?? undefined;
      setModeratorContextValue((prevState) => ({
        ...prevState,
        activeModeration: newActiveItem,
      }));
    },
    [moderatorContextValue.activeModeration?.external_id, moderatorContextValue.detectionList]
  );

  const contextValue = useMemo(
    () => ({
      isFetching,
      moderation: moderatorContextValue,
      activateDetection,
      setDetectionList,
      approveDetection,
      declineDetection,
      uploadDetectionList,
      handleSelectDeltaItem,
    }),
    [
      activateDetection,
      approveDetection,
      declineDetection,
      isFetching,
      moderatorContextValue,
      setDetectionList,
      uploadDetectionList,
      handleSelectDeltaItem,
    ]
  );

  return <ModeratorContext.Provider value={contextValue}>{children}</ModeratorContext.Provider>;
};
