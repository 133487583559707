import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import TransportOrdersCompositeLayer from '../../../../../../layers/CompositeLayers/ekb/TransportOrders/TransportOrdersCompositeLayer';

export class TransportOrdersLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: TransportOrdersCompositeLayer,
      id: 'TransportOrders',
    });
  }
}
