// @ts-ignore
import { combineReducers } from 'redux';
import { appReducer } from './global/app';
import directoryHistory from './directory/directoryHistory';
import directoryV2History from './directoryV2/reducer';
import { DirectoryState } from './directoryV2/types';
import selectedObject from './directory/selectedObject';
import mapboxView from './map/mapboxView';
import mapboxLayers from './map/mapboxLayers';
import mapPanels from './map/mapPanels';
import mapSelectedObject from './map/mapSelectedObject';
import mapboxTime from './map/mapboxTime/mapboxTime';
import socketsStatus from './map/socketsStatus';
import mapboxEditableLayersHistory from './map/mapboxEditableLayersHistory';
import mapboxEditableStandaloneObject from './map/mapboxEditableStandaloneObject';
import mapMode from './map/mapMode';
import mapDataAccessorSelectedObjects from './map/mapDataAccessorSelectedObjects';
import { AppState } from './global/app/app.model';
import { AuthState } from './global/auth/auth.model';
import { authReducer } from './global/auth';
import { notificationsReducer } from './global/notifications';
import { agGridFiltersReducer } from './global/aggrid';
import { NotificationsState } from './global/notifications/notifications.model';
import { MapboxDataState } from './map/mapboxData/mapboxData.model';
import { mapboxDataReducer } from './map/mapboxData/mapboxData.reducer';
import { roadColorPickerReducer } from '../../components/Application/BaseModules/Map/MapDisplayMode/LayersBar/LayersMenu/LayersPanels/LayersAdditionalPanels/LayerSettings/LayerSettingsContent/LayerChangeControlsTab/RoadColorPicker/roadColorPickerReducer';
import { mapMarkersReducer } from './map/mapMarkers';
import { MapMarkersState } from './map/mapMarkers/mapMarkers.model';
import { AnyObject } from 'types/enums/general/general.model';
import { mapMarkersRoadSingGroupReducer } from './map/mapMarkersRoadSingGroup/mapMarkers.reducer';
import { MapMarkersRoadSingGroupState } from './map/mapMarkersRoadSingGroup/mapMarkers.model';
import { RoleState } from './rolesDirectory/rolesDirectory.model';
import { rolesDirectoryReducer } from './rolesDirectory';
import { widgetsReducer } from 'store/reducers/widgets/widgets.reducer';
import { WidgetsStateI } from 'store/reducers/widgets/widgets.model';
import { reportsReducer } from './reports/reports.reducer';
import { ReportsStateI } from './reports/reports.model';

export interface StateRouterModel {
  action: string;
  location: {
    hash: string;
    key: string;
    pathname: string;
    search: string;
    state?: any;
  };
}

export interface StateModel {
  app: AppState;
  auth: AuthState;
  notifications: NotificationsState;
  directoryHistory: any;
  selectedObject: any;
  mapMode: any;
  mapboxView: any;
  mapboxLayers: any;
  mapboxData: MapboxDataState;
  mapPanels: any;
  mapSelectedObject: any;
  mapboxTime: any;
  socketsStatus: any;
  mapboxEditableLayersHistory: any;
  mapboxEditableStandaloneObject: any;
  sidebarPosition: any;
  mapDataAccessorSelectedObjects: any;
  directoryV2History: DirectoryState;
  mapMarkers: MapMarkersState;
  filtersAgGridState: AnyObject;
  router: StateRouterModel;
  mapMarkersRoadSingGroup: MapMarkersRoadSingGroupState;
  rolesDirectory: RoleState;
  roadColorPickerReducer: any;
  widgets: WidgetsStateI;
  reports: ReportsStateI;
}

export const reducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  notifications: notificationsReducer,
  directoryV2History,
  directoryHistory,
  selectedObject,
  mapMode,
  mapboxView,
  mapboxLayers,
  mapboxData: mapboxDataReducer,
  mapPanels,
  mapSelectedObject,
  mapboxTime,
  mapMarkers: mapMarkersReducer,
  mapMarkersRoadSingGroup: mapMarkersRoadSingGroupReducer,
  socketsStatus,
  mapboxEditableLayersHistory,
  mapboxEditableStandaloneObject,
  mapDataAccessorSelectedObjects,
  roadColorPickerReducer,
  filtersAgGridState: agGridFiltersReducer,
  rolesDirectory: rolesDirectoryReducer,
  widgets: widgetsReducer,
  reports: reportsReducer,
});
