export const signsRoadMapperType3 = {
  '2.3.7': { x: 0, y: 0, width: 128, height: 180 },
  '2.3.7-G': { x: 129, y: 0, width: 128, height: 180 },
  '2.3.7-R': { x: 0, y: 181, width: 128, height: 180 },
  3.1: { x: 129, y: 181, width: 128, height: 180 },
  '3.1-G': { x: 258, y: 0, width: 128, height: 180 },
  '3.1-R': { x: 258, y: 181, width: 128, height: 180 },
  3.2: { x: 387, y: 0, width: 128, height: 180 },
  '3.2-G': { x: 387, y: 181, width: 128, height: 180 },
  '3.2-R': { x: 0, y: 362, width: 128, height: 180 },
  3.3: { x: 129, y: 362, width: 128, height: 180 },
  '3.3-G': { x: 258, y: 362, width: 128, height: 180 },
  '3.3-R': { x: 387, y: 362, width: 128, height: 180 },
  3.4: { x: 516, y: 0, width: 128, height: 180 },
  '3.4-G': { x: 516, y: 181, width: 128, height: 180 },
  '3.4-R': { x: 516, y: 362, width: 128, height: 180 },
  3.5: { x: 0, y: 543, width: 128, height: 180 },
  '3.5-G': { x: 129, y: 543, width: 128, height: 180 },
  '3.5-R': { x: 258, y: 543, width: 128, height: 180 },
  3.6: { x: 387, y: 543, width: 128, height: 180 },
  '3.6-G': { x: 516, y: 543, width: 128, height: 180 },
  '3.6-R': { x: 645, y: 0, width: 128, height: 180 },
  3.7: { x: 645, y: 181, width: 128, height: 180 },
  '3.7-G': { x: 645, y: 362, width: 128, height: 180 },
  '3.7-R': { x: 645, y: 543, width: 128, height: 180 },
  3.8: { x: 774, y: 0, width: 128, height: 180 },
  '3.8-G': { x: 774, y: 181, width: 128, height: 180 },
  '3.8-R': { x: 774, y: 362, width: 128, height: 180 },
  3.9: { x: 774, y: 543, width: 128, height: 180 },
  '3.9-G': { x: 0, y: 724, width: 128, height: 180 },
  '3.9-R': { x: 129, y: 724, width: 128, height: 180 },
  '3.10': { x: 258, y: 724, width: 128, height: 180 },
  '3.10-G': { x: 387, y: 724, width: 128, height: 180 },
  '3.10-R': { x: 516, y: 724, width: 128, height: 180 },
  3.11: { x: 645, y: 724, width: 128, height: 180 },
  '3.11-G': { x: 774, y: 724, width: 128, height: 180 },
  '3.11-R': { x: 903, y: 0, width: 128, height: 180 },
  3.12: { x: 903, y: 181, width: 128, height: 180 },
  '3.12-G': { x: 903, y: 362, width: 128, height: 180 },
  '3.12-R': { x: 903, y: 543, width: 128, height: 180 },
  3.13: { x: 903, y: 724, width: 128, height: 180 },
  '3.13-G': { x: 0, y: 905, width: 128, height: 180 },
  '3.13-R': { x: 129, y: 905, width: 128, height: 180 },
  3.14: { x: 258, y: 905, width: 128, height: 180 },
  '3.14-G': { x: 387, y: 905, width: 128, height: 180 },
  '3.14-R': { x: 516, y: 905, width: 128, height: 180 },
  3.15: { x: 645, y: 905, width: 128, height: 180 },
  '3.15-G': { x: 774, y: 905, width: 128, height: 180 },
  '3.15-R': { x: 903, y: 905, width: 128, height: 180 },
  3.16: { x: 1032, y: 0, width: 128, height: 180 },
  '3.16-G': { x: 1032, y: 181, width: 128, height: 180 },
  '3.16-R': { x: 1032, y: 362, width: 128, height: 180 },
  '3.17.1': { x: 1032, y: 543, width: 128, height: 180 },
  '3.17.1-G': { x: 1032, y: 724, width: 128, height: 180 },
  '3.17.1-R': { x: 1032, y: 905, width: 128, height: 180 },
  '3.17.2': { x: 0, y: 1086, width: 128, height: 180 },
  '3.17.2-G': { x: 129, y: 1086, width: 128, height: 180 },
  '3.17.2-R': { x: 258, y: 1086, width: 128, height: 180 },
  '3.17.3': { x: 387, y: 1086, width: 128, height: 180 },
  '3.17.3-G': { x: 516, y: 1086, width: 128, height: 180 },
  '3.17.3-R': { x: 645, y: 1086, width: 128, height: 180 },
  '3.18.1': { x: 774, y: 1086, width: 128, height: 180 },
  '3.18.1-G': { x: 903, y: 1086, width: 128, height: 180 },
  '3.18.1-R': { x: 1032, y: 1086, width: 128, height: 180 },
  '3.18.2': { x: 1161, y: 0, width: 128, height: 180 },
  '3.18.2-G': { x: 1161, y: 181, width: 128, height: 180 },
  '3.18.2-R': { x: 1161, y: 362, width: 128, height: 180 },
  3.19: { x: 1161, y: 543, width: 128, height: 180 },
  '3.19-G': { x: 1161, y: 724, width: 128, height: 180 },
  '3.19-R': { x: 1161, y: 905, width: 128, height: 180 },
  '3.20': { x: 1161, y: 1086, width: 128, height: 180 },
  '3.20-G': { x: 1290, y: 0, width: 128, height: 180 },
  '3.20-R': { x: 1290, y: 181, width: 128, height: 180 },
  3.21: { x: 1290, y: 362, width: 128, height: 180 },
  '3.21-G': { x: 1290, y: 543, width: 128, height: 180 },
  '3.21-R': { x: 1290, y: 724, width: 128, height: 180 },
  3.22: { x: 1290, y: 905, width: 128, height: 180 },
  '3.22-G': { x: 1290, y: 1086, width: 128, height: 180 },
  '3.22-R': { x: 0, y: 1267, width: 128, height: 180 },
  3.23: { x: 129, y: 1267, width: 128, height: 180 },
  '3.23-G': { x: 258, y: 1267, width: 128, height: 180 },
  '3.23-R': { x: 387, y: 1267, width: 128, height: 180 },
  3.24: { x: 516, y: 1267, width: 128, height: 180 },
  '3.24-G': { x: 645, y: 1267, width: 128, height: 180 },
  '3.24-R': { x: 774, y: 1267, width: 128, height: 180 },
  3.25: { x: 903, y: 1267, width: 128, height: 180 },
  '3.25-G': { x: 1032, y: 1267, width: 128, height: 180 },
  '3.25-R': { x: 1161, y: 1267, width: 128, height: 180 },
  3.26: { x: 1290, y: 1267, width: 128, height: 180 },
  '3.26-G': { x: 1419, y: 0, width: 128, height: 180 },
  '3.26-R': { x: 1419, y: 181, width: 128, height: 180 },
  3.27: { x: 1419, y: 362, width: 128, height: 180 },
  '3.27-G': { x: 1419, y: 543, width: 128, height: 180 },
  '3.27-R': { x: 1419, y: 724, width: 128, height: 180 },
  3.28: { x: 1419, y: 905, width: 128, height: 180 },
  '3.28-G': { x: 1419, y: 1086, width: 128, height: 180 },
  '3.28-R': { x: 1419, y: 1267, width: 128, height: 180 },
  3.29: { x: 0, y: 1448, width: 128, height: 180 },
  '3.29-G': { x: 129, y: 1448, width: 128, height: 180 },
  '3.29-R': { x: 258, y: 1448, width: 128, height: 180 },
  '3.30': { x: 387, y: 1448, width: 128, height: 180 },
  '3.30-G': { x: 516, y: 1448, width: 128, height: 180 },
  '3.30-R': { x: 645, y: 1448, width: 128, height: 180 },
  3.31: { x: 774, y: 1448, width: 128, height: 180 },
  '3.31-G': { x: 903, y: 1448, width: 128, height: 180 },
  '3.31-R': { x: 1032, y: 1448, width: 128, height: 180 },
  3.32: { x: 1161, y: 1448, width: 128, height: 180 },
  '3.32-G': { x: 1290, y: 1448, width: 128, height: 180 },
  '3.32-R': { x: 1419, y: 1448, width: 128, height: 180 },
  3.33: { x: 1548, y: 0, width: 128, height: 180 },
  '3.33-G': { x: 1548, y: 181, width: 128, height: 180 },
  '3.33-R': { x: 1548, y: 362, width: 128, height: 180 },
};
