import { RoadSign } from 'generated/api/api';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { initSetMapSelectedObjectSaga } from 'store/sagas/map/mapSelectedObjectSaga';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { RoadSignContainerProps } from './model/RoadSign.model';
import RoadSignWrapper from './RoadSignWrapper';

const RoadSignContainer = ({ sign, initMapSelectObject, azimuthGroupRotate }: RoadSignContainerProps) => {
  const handleOpenSelectedSign = useCallback(() => {
    initMapSelectObject(sign, MapObjects.roadconSign, 'display');
  }, [initMapSelectObject, sign]);

  return <RoadSignWrapper sign={sign} onClick={handleOpenSelectedSign} azimuthGroupRotate={azimuthGroupRotate} />;
};

const mapDispatchToProps = (dispatch: any) => ({
  initMapSelectObject: (data: RoadSign, type: MapObjects, mapMode: string) =>
    dispatch(initSetMapSelectedObjectSaga(data, type, mapMode)),
});

export default connect(null, mapDispatchToProps)(RoadSignContainer);
