import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, ScatterplotLayer, TextLayer } from '@deck.gl/layers';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import { camerasAndDetectorsMapping } from '../../iconMappers/CamerasAndDetectorsMapping';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { DSelector } from 'services/map/Dselector/DSelector';
import { convertColorHexStringToArray } from '../../../services/map/convertColorHexStringToArray';
import { Settings } from '../../../types/enums/map/layers/Settings';

class CamerasAndDetectorsCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof CamerasAndDetectorsCompositeLayer) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }
  }

  getPickingInfo(event) {
    let selectedObjectType = null;
    let selectedObjectLayerName = Layers.camerasAndDetectors;
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (event.sourceLayer.id.includes('cameras-and-detectors')) {
        selectedObjectType = MapObjects.camerasAndDetectors;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    const sizeScaleBackgroundScatterplot = 20 * this?.props?.[Settings.iconSizeScale] + 3.2;
    const sizeScaleColorScatterplot = 20 * this?.props?.[Settings.iconSizeScale] + 1;

    return [
      //Иконка кластеров
      new ScatterplotLayer(this.props, this.getSubLayerProps({ id: 'cameras-and-detectors-white-scatterplot' }), {
        pickable: true,
        data: this.props.relatedData.cameras_and_detectors,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        getFillColor: () => [255, 255, 255],
        getRadius: () => sizeScaleBackgroundScatterplot,
        updateTriggers: {
          getRadius: this?.props?.sizeScale,
        },
      }),
      new ScatterplotLayer(this.props, this.getSubLayerProps({ id: 'cameras-and-detectors-color-scatterplot' }), {
        pickable: true,
        data: this.props.relatedData.cameras_and_detectors,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        getFillColor: (d) => {
          if (d.installation_plan_color) return convertColorHexStringToArray(d.installation_plan_color);
          return [0, 0, 0];
        },
        getRadius: () => sizeScaleColorScatterplot,
        updateTriggers: {
          getFillColor: [this.state.selectedObject],
          getRadius: this?.props?.sizeScale,
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'cameras-and-detectors-icon' }), {
        pickable: true,
        data: this.props.relatedData.cameras_and_detectors,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/camera_detector.png`,
        iconMapping: camerasAndDetectorsMapping,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        getIcon: (d) => {
          return d.detector_type === 'CAMERA' ? 'camera' : d.detector_type === 'DETECTOR' ? 'car_detector' : null;
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
};

CamerasAndDetectorsCompositeLayer.defaultProps = defaultProps;
CamerasAndDetectorsCompositeLayer.layerName = 'RoadAccidents';

export default CamerasAndDetectorsCompositeLayer;
