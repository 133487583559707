import { LayerGroupsPrototype } from './prototype/LayerGroupsPrototype';
import { LayerGroupLabel, LayerGroupName, LayerGroupLabelDecryption } from 'types/enums/map/layers/LayerGroupsEnums';

export class DutssdLayerGroup extends LayerGroupsPrototype {
  constructor() {
    super({
      label: LayerGroupLabel.dutssd,
      name: LayerGroupName.dutssd,
      decryption: LayerGroupLabelDecryption.dutssd,
    });
  }
}
