import { Action, ActionFunction0, createAction } from 'redux-actions';

class AppActions {
  /** action types */
  public readonly prefix: string = 'APPLICATIONS';
  public readonly START_FETCHING: string = `${this.prefix}.START_FETCHING`;
  public readonly END_FETCHING: string = `${this.prefix}.END_FETCHING`;
  public readonly SET_SIDEBAR_COLLAPSE: string = `${this.prefix}.SET_SIDEBAR_COLLAPSE`;
  public readonly RECALCULATE: string = `${this.prefix}.RECALCULATE`;

  /** actions */
  public startAppFetching: ActionFunction0<Action<null>> = createAction(this.START_FETCHING);
  public endAppFetching: ActionFunction0<Action<null>> = createAction(this.END_FETCHING);
  public setSidebarCollapse: ActionFunction0<Action<any>> = createAction(this.SET_SIDEBAR_COLLAPSE);
  public recalculate: ActionFunction0<Action<null>> = createAction(this.RECALCULATE);
}

export const appActions = new AppActions();
