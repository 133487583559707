import { handleActions, Reducer } from 'redux-actions';

import { mapMarkersActions } from 'store/reducers/map/mapMarkers/mapMarkers.actions';
import {
  MapChangeMarkerSizeModeAction,
  MapClearMarkersForLayer,
  MapMarker,
  MapMarkerAction,
  MapMarkersState,
  MapMoveMarkerAction,
} from 'store/reducers/map/mapMarkers/mapMarkers.model';

import { MapReducer } from '../../reducers.model';

const {
  ADD_CARD_MARKER,
  REMOVE_CARD_MARKER,
  MOVE_MARKER,
  CHANGE_MARKER_SIZE_MODE,
  CLEAR_MARKERS_FOR_LAYER,
  CLEAR_ALL_MARKERS,
} = mapMarkersActions;

const initialState = {};

/** Проверка на Equals для двух маркеров */
const areSameMarkers = (markerA: Partial<MapMarker>, markerB: Partial<MapMarker>) => {
  return markerA.type === markerB.type && markerA.clickInfo.object.id === markerB.clickInfo.object.id;
};

/** Добавление маркера на карту */
const addMarker: Reducer<MapMarkersState, MapMarkerAction> = (state, { payload }) => {
  const { layer, marker } = payload;
  const markerIndex = state?.[layer]?.findIndex((element) => areSameMarkers(element, marker));
  if (markerIndex === -1 || !markerIndex) {
    const prevState = state?.[layer] ?? [];
    const newArray = [...prevState, marker];
    return {
      ...state,
      [layer]: newArray,
    };
  }
  return state;
};

/** Удаление маркера с карты */
const removeMarker: Reducer<MapMarkersState, MapMarkerAction> = (state, { payload }) => {
  const { layer, marker } = payload;
  const source = state?.[layer] ?? [];
  const newArray = source.filter((el) => !areSameMarkers(marker, el));
  return {
    ...state,
    [layer]: newArray,
  };
};

/** DragMoveEnd для маркера на карте */
const moveMarker: Reducer<MapMarkersState, MapMoveMarkerAction> = (state, { payload }) => {
  const { layer, marker, markerPosition } = payload;
  const source = state?.[layer] ?? [];
  const markerIndex = source.findIndex((element) => areSameMarkers(element, marker));
  if (markerIndex !== undefined && markerIndex !== -1) {
    const layerMarkersCopy = [...source];
    let newMarker = layerMarkersCopy[markerIndex];
    newMarker = {
      ...newMarker,
      markerPosition,
    };
    layerMarkersCopy[markerIndex] = newMarker;

    return {
      ...state,
      [layer]: layerMarkersCopy,
    };
  }
  return state;
};

/** Изменеия манипуляций с маркером */
const changeMarkerSizeMode: Reducer<MapMarkersState, MapChangeMarkerSizeModeAction> = (state, { payload }) => {
  const { layer, marker, markerSizeMode } = payload;
  const source = state?.[layer] ?? [];
  const markerIndex = source.findIndex((element) => areSameMarkers(element, marker));
  if (markerIndex !== undefined && markerIndex !== -1) {
    const layerMarkersCopy = [...source];
    let newMarker = layerMarkersCopy[markerIndex];
    newMarker = {
      ...newMarker,
      markerSizeMode,
    };
    layerMarkersCopy[markerIndex] = newMarker;

    return {
      ...state,
      [layer]: layerMarkersCopy,
    };
  }
  return state;
};

/** Удаление всех маркеров для слоя*/
const clearMarkersForLayer: Reducer<MapMarkersState, MapClearMarkersForLayer> = (state, { payload }) => {
  const { layer } = payload;

  if (state[layer]) {
    return {
      ...state,
      [layer]: [],
    };
  }

  return state;
};

/** Удаление всех маркеров*/
const clearAllMarkers: Reducer<MapMarkersState, null> = () => {
  return initialState;
};

const mapMarkersMapReducer: MapReducer<MapMarkersState, any> = {
  [ADD_CARD_MARKER]: addMarker,
  [REMOVE_CARD_MARKER]: removeMarker,
  [MOVE_MARKER]: moveMarker,
  [CHANGE_MARKER_SIZE_MODE]: changeMarkerSizeMode,
  [CLEAR_MARKERS_FOR_LAYER]: clearMarkersForLayer,
  [CLEAR_ALL_MARKERS]: clearAllMarkers,
};

export const mapMarkersReducer: Reducer<MapMarkersState, any> = handleActions(mapMarkersMapReducer, initialState);
