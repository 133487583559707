import { LayerGroupsPrototype } from './prototype/LayerGroupsPrototype';
import { LayerGroupLabel, LayerGroupName, LayerGroupLabelDecryption } from 'types/enums/map/layers/LayerGroupsEnums';

export class MsdidiLayerGroup extends LayerGroupsPrototype {
  constructor() {
    super({
      label: LayerGroupLabel.msdidi,
      name: LayerGroupName.msdidi,
      decryption: LayerGroupLabelDecryption.msdidi,
    });
  }
}
