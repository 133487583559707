import React from 'react';
import PrimaryGroup from './PrimaryGroup';
import { toggleRasterTilesEnabled } from '../../../../../../store/reducers/map/actions/mapPanelsActions';
import { connect } from 'react-redux';

const PrimaryGroupContainer = (props) => {
  return <PrimaryGroup {...props} />;
};

PrimaryGroupContainer.propTypes = {};

const mapStateToProps = (state) => {
  return {
    rasterTileEnabled: state.mapPanels.rasterTileEnabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRasterTileEnable: () => dispatch(toggleRasterTilesEnabled()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryGroupContainer);
