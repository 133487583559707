import { PathLayer, TextLayer } from '@deck.gl/layers';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { getCoordsFromParsedGeometry } from '../../../../services/map/getCoordsFromParsedGeometry';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../../types/enums/map/MapObjects.model';
import MapEventObserver from '../../../../store/rakes/MapEventObserver';
import ITSLayer from '../../../../types/classes/ITSLayer';
import { PatchedRoadAccidentSection, RoadAccident } from '../../../../generated/api/api';

class RoadAccidentsHeatmapCompositeLayer extends ITSLayer<any, any> {
  updateState({ props }: any) {
    this.setState({
      data: props,
    });
  }

  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        const selectedObjectLayerName = Layers.roadAccidents;
        if (event.sourceLayer.id.includes('road-accidents-sections-path'))
          selectedObjectType = MapObjects.roadAccidentsSections;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props?.relatedData) return [];
    return [
      new HeatmapLayer({
        data: this.props.relatedData?.road_accidents,
        id: 'road-accidents-heatmap',
        pickable: false,
        // @ts-ignore
        getPosition: (d: RoadAccident) => (d.point ? getCoordsFromParsedGeometry(d.point) : [0, 0]),
        // @ts-ignore

        getWeight: 50,
        radiusPixels: 30,
        intensity: 1,
        threshold: 0.05,
      }),
      // Паслэйер аварийноопасные участки
      new PathLayer({
        data: this.props.relatedData?.road_accidents_sections,
        widthScale: 3,
        getWidth: (d) => (this.state?.selectedObject?.id === d.id ? 20 : 5),
        pickable: true,
        rounded: true,
        updateTriggers: {
          getColor: this.state.selectedObject,
          getWidth: this.state.selectedObject,
        },
        billboard: false,
        autoHighlight: true,
        widthMinPixels: 3,
        opacity: 1,
        // @ts-ignore
        getPath: (d: PatchedRoadAccidentSection) => (d.line_path ? getCoordsFromParsedGeometry(d.line_path) : [0, 0]),
        getColor: [235, 87, 87],
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
};

RoadAccidentsHeatmapCompositeLayer.defaultProps = defaultProps;
RoadAccidentsHeatmapCompositeLayer.layerName = 'RoadAccidents';

export default RoadAccidentsHeatmapCompositeLayer;
