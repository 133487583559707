import React from 'react';
import { Application } from './Application';
import { useProtect } from '../../hooks/useProtect/useProtect';
/**
 * Контейнерная компонента корневого приложения
 * @see Application
 */
export const ApplicationContainer = () => {
  useProtect();
  return <Application />;
};
