import React, { useCallback, useMemo } from 'react';

import { InputNumber } from 'antd';

import { axelsFieldEnum } from 'types/enums/directories/axelsField.model';
import { FormInputProps } from 'UI/common/form/inputs/model/FormInputProps.model';

import { IAxes, IVehicleParameters } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { Styled } from './VehicleParametersField.styled';

const { AxesParameterCointainer, LabelContainer, AxelContainer, AxelsContainer, PictureAxel, NumberInputVPContainer } =
  Styled;

interface IMainAxelsContainerProps extends FormInputProps {
  changeFields: (key: string, value: string | number, obj: IVehicleParameters | IAxes) => void;
}
const axelsField = [axelsFieldEnum.distance, axelsFieldEnum.load, axelsFieldEnum.slope];
const mainStep = 1;
const decimalStep = 0.1;

export const MainAxelsContainer = ({ description, object, changeFields }: IMainAxelsContainerProps) => {
  const axelLabels = useMemo(
    () =>
      axelsField.map((el: string, labelIndex: number) => (
        <p key={`label-${labelIndex}`}>{description?.[el]?.label ?? 'Неизвестная характеристика оси ТС'}</p>
      )),
    [description]
  );

  const axelInputs = useCallback(
    (axel) =>
      axelsField.map((el: string, axelIndex: number) => (
        <NumberInputVPContainer key={`axel-${axelIndex}`}>
          <InputNumber
            value={axel[el] ?? 0}
            onChange={(e) => e && changeFields(el, e, axel)}
            step={el === axelsFieldEnum.slope ? mainStep : decimalStep}
            max={description?.[el]?.max_value ?? Number.MAX_SAFE_INTEGER}
            min={0}
          />
        </NumberInputVPContainer>
      )),
    [changeFields, description]
  );

  const axelsVehicalParam = useMemo(
    () =>
      object?.axes?.map((axel: IAxes, index: number) => (
        <AxelContainer key={`axel-${index}`}>
          <p>{axel.number}</p>
          <PictureAxel />
          {axelInputs(axel)}
        </AxelContainer>
      )),
    [axelInputs, object]
  );

  return (
    <AxesParameterCointainer>
      <LabelContainer>{axelLabels}</LabelContainer>
      <AxelsContainer>{axelsVehicalParam}</AxelsContainer>
    </AxesParameterCointainer>
  );
};
