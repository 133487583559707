import React from 'react';
import Icon from '@ant-design/icons';

const To3DIconSVG = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.152344 0.68042H5.2079C5.87831 0.68042 6.52126 0.946738 6.99531 1.42079C7.46936 1.89484 7.73568 2.53779 7.73568 3.2082V10.7915C7.73568 11.4619 7.46936 12.1049 6.99531 12.5789C6.52126 13.053 5.87831 13.3193 5.2079 13.3193H0.152344V10.7915H5.2079V8.26375H1.41623V5.73598H5.2079V3.2082H0.152344V0.68042ZM10.2635 0.68042H14.0551C15.0607 0.68042 16.0252 1.0799 16.7362 1.79097C17.4473 2.50205 17.8468 3.46647 17.8468 4.47209V9.52764C17.8468 10.5333 17.4473 11.4977 16.7362 12.2088C16.0252 12.9198 15.0607 13.3193 14.0551 13.3193H10.2635V0.68042ZM14.0551 10.7915C14.3903 10.7915 14.7118 10.6584 14.9488 10.4213C15.1859 10.1843 15.319 9.86285 15.319 9.52764V4.47209C15.319 4.13688 15.1859 3.81541 14.9488 3.57838C14.7118 3.34136 14.3903 3.2082 14.0551 3.2082H12.7912V10.7915H14.0551Z"
      fill="currentColor"
    />
  </svg>
);

export const To3DIcon = (props) => <Icon component={To3DIconSVG} {...props} />;
