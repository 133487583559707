export const roadMarkingPolygonMapping = {
  G_1_14_1: {
    x: 0,
    y: 272,
    width: 200,
    height: 64,
    mask: true,
  },
  G_1_15: {
    x: 161,
    y: 578,
    width: 144,
    height: 64,
  },
  G_1_16_1: {
    x: 0,
    y: 0,
    width: 117,
    height: 271,
  },
  G_1_16_2: {
    x: 161,
    y: 337,
    width: 142,
    height: 240,
  },
  G_1_16_3: {
    x: 118,
    y: 0,
    width: 117,
    height: 229,
  },
  G_1_13: {
    x: 236,
    y: 0,
    width: 95,
    height: 320,
  },
  G_1_25: {
    x: 95,
    y: 337,
    width: 65,
    height: 320,
  },
  G_1_17: {
    x: 0,
    y: 337,
    width: 94,
    height: 360,
  },
};
