import React, { PropsWithChildren } from 'react';
import { Styled } from './style/panelSlimCard.styled';

const { MainWrapper } = Styled;

const PanelSlimCard = ({ children, ...other }: PropsWithChildren<{}>) => {
  return <MainWrapper {...other}>{children}</MainWrapper>;
};

export default PanelSlimCard;
