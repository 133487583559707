import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import RoadSchemaCompositeLayer from '../../../../../../layers/CompositeLayers/ekb/RoadSchemaCompositeLayer';
import { Layers } from 'types/enums/map/layers/Layers';

export class RoadSchemaLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: RoadSchemaCompositeLayer,
      id: Layers.roadsSchema,
    });
  }
}
