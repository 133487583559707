import React, { useCallback, useState } from 'react';
import { MapToolBar } from './MapToolBar';
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas';

interface IMapToolBarContainer {
  showTimePlayer: boolean;
  isDeckViewLoading?: boolean;
}

export const MapToolBarContainer = ({ showTimePlayer }: IMapToolBarContainer) => {
  const history = useHistory();
  const [showPrintPage, setShowPrintPage] = useState(false);

  const handlePrint = useCallback(() => {
    localStorage.removeItem('print');
    let div = document.getElementById('deckgl-wrapper');
    if (div) {
      html2canvas(div, { allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
        let a = document.createElement('a');
        a.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        localStorage.setItem('print', a.href);
        setShowPrintPage(true);
      });
    }
  }, []);

  if (showPrintPage) {
    history.push('/print');
    setShowPrintPage(false);
  }

  return <MapToolBar showTimePlayer={showTimePlayer} handlePrint={handlePrint} />;
};
