import { MainDataSocketPrototype } from '../prototype/MainDataSocketPrototype';
import { openSocket } from 'store/sagas/map/socketDataGetterSaga';
import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';

export class BrigadeWorkShiftsMainData extends MainDataSocketPrototype {
  _returnAC = true;

  constructor() {
    super({
      urlData: '/application-marks/',
      dataKeyForIds: 'work_shift_ids',
      relatedDataKey: MapObjects.brigadeWorkShiftsTrack,
    });
  }

  getIsReturnsAC() {
    return this._returnAC;
  }

  getCustomMainDataDownload(dispatch: any) {
    return openSocket({
      urlData: this.getUrlData(),
      layerName: Layers.brigadeApplications,
      subscribeKey: this.getDataKeyForIds(),
      subscribeIds: [],
      relatedDataKey: this.getRelatedDataKey(),
      objectKeyUpdate: 'work_shift_id',
    });
  }
}
