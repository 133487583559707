import { PathLayer, TextLayer } from '@deck.gl/layers';
import { HexagonLayer } from '@deck.gl/aggregation-layers';
import { getCoordsFromParsedGeometry } from '../../../../services/map/getCoordsFromParsedGeometry';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../../types/enums/map/MapObjects.model';
import MapEventObserver from '../../../../store/rakes/MapEventObserver';
import ITSLayer from '../../../../types/classes/ITSLayer';
import { PatchedRoadAccident, PatchedRoadAccidentSection } from '../../../../generated/api/api';

class RoadAccidentsHexagonCompositeLayer extends ITSLayer<any, any> {
  updateState({ props }: any) {
    this.setState({
      data: props,
    });
  }

  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        const selectedObjectLayerName = Layers.roadAccidents;
        if (event.sourceLayer.id.includes('road-accidents-sections-path'))
          selectedObjectType = MapObjects.roadAccidentsSections;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    return [
      // Паслэйер аварийноопасные участки
      new PathLayer({
        data: this.props.relatedData?.road_accidents_sections,
        widthScale: 3,
        getWidth: (d) => (this.props?.selectedObject?.id === d.id ? 20 : 5),
        pickable: true,
        rounded: true,
        updateTriggers: {
          getColor: this.props.selectedObject,
          getWidth: this.props.selectedObject,
        },
        billboard: false,
        autoHighlight: true,
        widthMinPixels: 3,
        opacity: 1,
        // @ts-ignore
        getPath: (d: PatchedRoadAccidentSection) => (d.line_path ? getCoordsFromParsedGeometry(d.line_path) : [0, 0]),
        getColor: [235, 87, 87],
      }),
      new HexagonLayer({
        id: 'road-accidents-hexagon',
        colorRange: [
          [111, 207, 151],
          [39, 174, 96],
          [33, 150, 83],
          [242, 201, 76],
          [242, 153, 74],
          [235, 87, 87],
        ],
        colorDomain: [1, 20],
        data: this.props.relatedData?.road_accidents,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: false,
        extruded: true,
        radius: this.props.radius ?? 300,
        elevationScale: this.props.elevationScale ?? 30,
        elevationRange: [0, 1000],
        coverage: 1,
        // @ts-ignore
        getPosition: (d: PatchedRoadAccident) => (d.point ? getCoordsFromParsedGeometry(d.point) : [0, 0]),
        transitions: {
          // @ts-ignore
          elevationScale: 100,
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
};

RoadAccidentsHexagonCompositeLayer.defaultProps = defaultProps;
RoadAccidentsHexagonCompositeLayer.layerName = 'RoadAccidents';

export default RoadAccidentsHexagonCompositeLayer;
