import React from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const TunnelsItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'tunnels');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.artificial_structures_tunnels}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = data;

  Descriptions.push({
    key: 'Наименование дороги',
    value: properties.segment_name,
  });

  Descriptions.push({
    key: 'Длина тунеля',
    value: properties.length ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об объекте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

TunnelsItemSelection.propTypes = {};

export default TunnelsItemSelection;
