import React from 'react';
import Icon from '@ant-design/icons';

const DeleteIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99935 15.8333C4.99935 16.2754 5.17494 16.6993 5.4875 17.0118C5.80007 17.3244 6.22399 17.5 6.66602 17.5H13.3327C13.7747 17.5 14.1986 17.3244 14.5112 17.0118C14.8238 16.6993 14.9994 16.2754 14.9994 15.8333V5.83333H4.99935V15.8333ZM6.66602 7.5H13.3327V15.8333H6.66602V7.5ZM12.916 3.33333L12.0827 2.5H7.91602L7.08268 3.33333H4.16602V5H15.8327V3.33333H12.916Z"
      fill="#EB5757"
    />
  </svg>
);

export const DeleteIcon = (props) => <Icon component={DeleteIconSVG} {...props} />;
