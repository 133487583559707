import { RegisteredReportsUrl } from '../../types/enums/routes/reports/RegisteredReportsUrl.model';
import { resolver } from './resolver';
import RoadAccidentContainer from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/Templates/RoadAccident/RoadAccidentContainer';
import RoadAccidentSectionsContainer from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/Templates/RoadAccidentSections/RoadAccidentSectionsContainer';
import StatisticOfShipmentsContainer from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/Templates/StatisticsOfShiment/StatisticOfShipmentsContainer';
import RoadSignsByRoadContainer from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/Templates/RoadSignsByRoad/RoadSignsByRoadContainer';
import RoadMarkingLineAreaAndVolumeReportContainer from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/Templates/RoadMarkingLineAreaAndVolumeReport/RoadMarkingLineAreaAndVolumeReportContainer';
import CrossRoadPerDistrictContainer from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/Templates/CrossRoadPerDistrict/CrossRoadPerDistrictContainer';
import TrafficIntensityContainer from '../../components/Application/BaseModules/Reports/ReportsRouter/ReportsBuilder/Templates/TrafficIntensity/TrafficIntensityContainer';

const basePath = `${
  process.env.REACT_APP_TYPE === 'PROD' || process.env.REACT_APP_TYPE === 'TEST'
    ? process.env.REACT_APP_URL
    : 'http://127.0.0.1:8000/api'
}`;
export const resolveUrl = resolver;

const REGISTERED_APP_URLS = RegisteredReportsUrl;

const applyReportByKey = (reportKey: string) => {
  return `report_permissions.can_view_${reportKey}`;
};

/***
 *register urls for application and custom renderers
 * register null if render is unnecessary
 */
export const registeredReportsUrls = [
  {
    url: REGISTERED_APP_URLS.roadAccident,
    urlData: basePath + `reports/${REGISTERED_APP_URLS.roadAccident}`,
    folderKey: 'Отчеты',
    i18LabelPath: 'Анализ ДТП',
    permissionToShow: applyReportByKey('road_accidents'),
    component: RoadAccidentContainer,
    description: 'Сведения о ДТП',
  },
  {
    url: REGISTERED_APP_URLS.roadAccidentSections,
    urlData: basePath + `reports/${REGISTERED_APP_URLS.roadAccidentSections}`,
    folderKey: 'Отчеты',
    i18LabelPath: 'Анализ ДТП по аварийно-опасным участкам дорог',
    permissionToShow: applyReportByKey('accidents_sections'),
    component: RoadAccidentSectionsContainer,
    description: 'Сведения о ДТП по аварийно-опасным участкам дорог',
  },
  {
    url: REGISTERED_APP_URLS.statisticsCargoTransportation,
    urlData: basePath + `reports/${REGISTERED_APP_URLS.statisticsCargoTransportation}`,
    folderKey: 'Отчеты',
    i18LabelPath: 'Статистика заявлений по перевозке груза',
    permissionToShow: applyReportByKey('statement_report'),
    component: StatisticOfShipmentsContainer,
    description: 'Статистика заявлений по перевозке груза',
  },
  {
    url: REGISTERED_APP_URLS.roadSignsByRoad,
    urlData: basePath + `reports/${REGISTERED_APP_URLS.roadSignsByRoad}`,
    folderKey: 'Отчеты',
    i18LabelPath: 'Отчет по дорожным знакам (ГОСТ)',
    permissionToShow: applyReportByKey('road_signs_by_road'),
    component: RoadSignsByRoadContainer,
    description: 'Сведения о дорожных знаках (ГОСТ)',
  },
  {
    url: REGISTERED_APP_URLS.roadMarkingLineAreaAndVolumeReport,
    urlData: basePath + `reports/${REGISTERED_APP_URLS.roadMarkingLineAreaAndVolumeReport}`,
    folderKey: 'Отчеты',
    i18LabelPath: 'Отчет расчета площади и объема разметки',
    permissionToShow: applyReportByKey('road_marking_line_area_and_volume'),
    component: RoadMarkingLineAreaAndVolumeReportContainer,
    description: 'Сведения о площади и объемам разметки',
  },
  {
    url: REGISTERED_APP_URLS.crossRoadPerDistrict,
    urlData: basePath + `reports/${REGISTERED_APP_URLS.crossRoadPerDistrict}`,
    folderKey: 'Отчеты',
    i18LabelPath: 'Отчет о пересечениях дорог',
    component: CrossRoadPerDistrictContainer,
    permissionToShow: applyReportByKey('cross_road_per_district'),
    description: 'Сведения о пересечениях дорог',
  },
  {
    url: REGISTERED_APP_URLS.trafficIntensity,
    urlData: basePath + `reports/${REGISTERED_APP_URLS.trafficIntensity}`,
    folderKey: 'Отчеты',
    i18LabelPath: 'Интенсивность движения',
    component: TrafficIntensityContainer,
    permissionToShow: applyReportByKey('traffic_intensity_report'),
    description: 'Сведения об интенсивности движения',
  },
];
