import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';

import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class ContoursLawn extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'contoursLawn',
      filterLabel: 'Контуры газонов',
      selectedObjectName: MapObjects.contoursLawn,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsContoursLawnList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsContoursLawnDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsContoursLawnRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.contoursLawn);
  }
}
