import { useEffect, useMemo, useState } from 'react';
import * as AllModules from 'ag-grid-enterprise';
import ru from 'i18n/ru';
import { defineColumnsDefinitions } from 'services/application/schemaManipulation/defineColumnsDefinitions';
import BusScheduleArrayRenderer from 'UI/custom/Aggrid/CellRenderers/BusScheduleArrayRenderer';
import BooleanCellRenderer from 'UI/custom/Aggrid/CellRenderers/BooleanCellRenderer/BooleanCellRenderer';
import RgbaCellRenderer from 'UI/custom/Aggrid/CellRenderers/RgbaCellRenderer/RgbaCellRenderer';
import ObjectCellRenderer from 'UI/custom/Aggrid/CellRenderers/ObjectCellRenderer/ObjectCellRenderer';
import LinkCellRenderer from 'UI/custom/Aggrid/CellRenderers/LinkCellRenderer/LinkCellRenderer';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { ContainerCallbacks } from 'UI/custom/Aggrid/Compendium/model/ContainerCallbacks.model';
import { IDefinedColumns, ISettings } from './model/useAgGridSettings.model';
import { AnyObject } from 'types/enums/general/general.model';
import { GridPointIcon } from 'UI/custom/icons/actionIcons/GridPointIcon';
import { GridLineIcon } from 'UI/custom/icons/actionIcons/GridLineIcon';
import { GridPolygonIcon } from 'UI/custom/icons/actionIcons/GridPolygonIcon';
import { GridGeometryIcon } from 'UI/custom/icons/actionIcons/GridGeometryIcon';

export function useAgGridSettings(
  data: AnyObject[],
  model: ModelResponse,
  containerCallbacks: ContainerCallbacks,
  cellSizes?: {
    minWidth: number;
    width: number;
    maxWidth: number;
  }
) {
  // Не передавать в defaultColDef обработчики событий, сразу в пропсы компоненты AgGridReact в файле Compendium
  const settings: ISettings = useMemo(
    () => ({
      modules: AllModules,
      defaultColDef: {
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        filter: true,
        autoHeight: true,
        minWidth: cellSizes?.minWidth ?? 70,
        maxWidth: cellSizes?.maxWidth ?? 400,
        width: cellSizes?.width ?? 200,
        cellStyle: containerCallbacks?.functionCellStyle,
        cellClass: containerCallbacks?.functionCellClass,
      },
      columnDefs: null,
      rowData: [],
      pivotRowTotals: 'before',
      rowGroupPanelShow: 'always',
      localeText: ru,
      singleClickEdit: true,
      onFilterChanged: containerCallbacks?.onFilterChanged,
      frameworkComponents: {
        objectCellRenderer: ObjectCellRenderer,
        booleanCellRenderer: BooleanCellRenderer,
        busScheduleArrayRenderer: BusScheduleArrayRenderer,
        linkCellRenderer: LinkCellRenderer,
        rgbaCellRenderer: RgbaCellRenderer,
        pointIconCellRenderer: GridPointIcon,
        lineIconCellRenderer: GridLineIcon,
        polygonIconCellRenderer: GridPolygonIcon,
        geometryIconCellRenderer: GridGeometryIcon,
      },
      sideBar: {},
      detailCellRendererParams: {},
      rowMultiSelectWithClick: true,
      ...containerCallbacks.additionalCallbacks,
    }),
    [cellSizes, containerCallbacks]
  );
  const [columnsData, setColumnsData] = useState<IDefinedColumns>();
  const [agSettings, setAgSettings] = useState<ISettings>(settings);

  useEffect(() => {
    const definedColumns = defineColumnsDefinitions(model);
    setAgSettings((prevState: ISettings) => ({
      ...prevState,
      columnDefs: definedColumns.result,
      rowData: data,
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: definedColumns.nested,
          defaultColDef: {
            resizable: true,
          },
        },
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        defaultToolPanel: 'filters',
        hiddenByDefault: true,
      },
    }));
    setColumnsData(definedColumns);
  }, [data, containerCallbacks, model]);

  return useMemo(
    () => ({
      agSettings,
      setAgSettings,
      columnsData,
    }),
    [agSettings, columnsData]
  );
}
