import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

// TODO duplicate fragment Тех-долг-FRONTEND #5576

const AxleLoadOverloadsSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'axle_load_overloads');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/api/${selectedMapItem.data[0].urlData.replace('_repr', '')}/${id}/`,
      '_blank'
    );
    win.focus();
  };
  let Descriptions = [];
  const properties = data;

  properties.statement_name &&
    Descriptions.push({
      key: 'Наименование заявления',
      value: properties.statement_name ? properties?.statement_name : 'Отсутствует',
    });

  Number.isFinite(properties.overload) &&
    Descriptions.push({
      key: 'Превышение нагрузки на ось',
      value: properties?.overload ? properties?.overload : 'Отсутствует',
    });

  properties.statement_route_name &&
    Descriptions.push({
      key: 'Маршрут в заявлении',
      value: properties.statement_route_name,
    });

  return (
    <PrepareScrollableInfoCard
      title={'Карточка нарушения допустимой нагрузки'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default AxleLoadOverloadsSelection;
