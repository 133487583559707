export enum ModelNames {
  billingList = 'Billing List',
  roadAccidentList = 'Road Accident List',
  segmentList = 'Segment List',
  statementRepresentationList = 'Statement Representation List',
  fileIndividualStatementList = 'File Individual Statement List',
  applicationList = 'Application List',
  individualStatementList = 'Individual Statement List',
  entityStatementList = 'Entity Statement List',
  formAccountStatementList = 'Form Account Statement List',
  permissionList = 'Permission List',
  teamOfWorkersList = 'Team Of Workers List',
  cameraWidgetList = 'Camera Widget List',
  busStopFileList = 'Bus Stop File List',
  DetectionList = 'Detection List',
  roadSignTypeList = 'Road Sign Type List',
  segmentSurfaceTypeList = 'Segment Surface Type List',
}
