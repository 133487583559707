import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RoadsService } from '../../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class SidewalkProject extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'sidewalk_project',
      filterLabel: 'Проектируемые тротуары',
      selectedObjectName: MapObjects.projectSidewalk,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsSidewalkProjectList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsSidewalkProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsSidewalkProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadSidewalkProjection);
  }
}
