export enum RequestPath {
  axleLoadOverloads = 'axle_load_overloads',
  brigadeApplications = '/applications/',
  brigadePhotos = '/applications/photos/',
  brigadeTasks = '/applications/tasks/',
  brigadeTeamsOfWorkers = '/applications/teams_of_workers/',
  brigadeWorkShifts = '/applications/work_shifts/',
  cabinets = '/roads/cabinets/',
  camerasUdc = '/cameras/',
  camerasUdcWidgets = '/cameras/widget/',
  citizenAppears = '/citizen_appears/',
  cityDistricts = '/states/city_districts/',
  contoursLawn = '/roads/contours_lawn/',
  criticalSegmentsAnalyst = '/roads_quality/analysis_critical_segments/',
  detections = '/detections/',
  engineeringConstructions = '/engineering_constructions/',
  entityStatementRoutes = '/special_permits/entity_statements/routes/',
  filtersUserOrganizations = '/filters/user_organizations/',
  filterUserDistricts = '/filters/user_districts/',
  groups = '/groups/',
  illuminatedPedestrianCrossings = '/road_constructions/pedestrian_crossings/',
  improvementElements = '/roads/improvement_elements/',
  knsCablesTram = '/kns/cables_tram/',
  knsCablesTroll = '/kns/cables_troll/',
  knsCableTroll = '/kns/supply_cables_troll/',
  knsLinesTram = '/kns/lines_tram/',
  knsLinesTroll = '/kns/lines_troll/',
  knsSubstations = '/kns/substations/',
  orderRoute = 'order_route',
  organizations = '/organizations/',
  organizationsForms = '/organizations/forms/',
  pedBorderProjection = '/road_constructions/ped_border_project/',
  pedBorders = '/road_constructions/pedBorders/',
  permissions = '/permissions/',
  permissionsGroups = '/permissions/groups/',
  permitsIndividualStatementAxleOverloads = '/special_permits/individual_statements/axle_load_overloads/',
  permitsIndividualStatementDimensionsOversizes = '/special_permits/individual_statements/general_dimensions_oversizes/',
  permitsIndividualStatementFiles = '/special_permits/individual_statements/files/',
  permitsIndividualStatementHeightOversizes = '/special_permits/individual_statements/dimensions_height_oversizes/',
  permitsIndividualStatementRoutes = '/special_permits/individual_statements/routes/',
  permitsIndividualStatementWidthOversizes = '/special_permits/individual_statements/dimensions_width_oversizes/',
  person = '/users/persons/',
  personTypes = '/users/person_types/',
  publicTransportBusStops = '/public_transport/bus_stops/',
  publicTransportTypesOfInformationBoards = '/public_transport/types_of_information_boards/',
  roadAccidents = '/road_accidents/',
  roadAccidentsParticipant = '/road_accidents/participant/',
  roadAccidentsSections = '/road_accidents/sections/',
  roadAccidentsVehicle = '/road_accidents/vehicle/',
  roadAxelLoad = '/roads/segment_axle_loads/',
  roadComplexTypeQuality = '/roads_quality/road_complex_type_quality/',
  roadConstructionsBusShelters = '/road_constructions/bus_shelters/',
  roadConstructionsRoadSignGroups = '/road_constructions/road_sign_groups/',
  roadConstructionsRoadSigns = '/road_constructions/road_signs/',
  roadFences = '/roads/road_fences/',
  roadFlatnessQuality = '/roads_quality/road_flatness_quality/',
  roadMarkingLines = '/roads/road_marking_lines/',
  roadMarkingPoints = '/roads/road_marking_points/',
  roadMarkingPolygonProjection = '/roads/road_marking_polygon_project/',
  roadMarkingPolygons = '/roads/road_marking_polygons/',
  roadMarkingSymbolProjection = '/roads/road_marking_symbol_project/',
  roadRutQuality = '/roads_quality/road_rut_quality/',
  roads = '/roads/',
  roads_construction_rest_areas = '/roads_construction/rest_areas/',
  roadsClutchQuality = '/roads_quality/road_clutch_quality/',
  roadsConstructionLighting = '/road_constructions/lighting/',
  roadsConstructionPedBorders = '/road_constructions/ped_borders/',
  roadsConstructionRoadSignGroups = '/road_constructions/road_sign_types/',
  roadsConstructionTrafficLights = '/road_constructions/traffic_lights/',
  roadsDividingLines = '/roads/dividing_lines/',
  roadSidewalkProjection = '/roads/sidewalk_project/',
  roadSignalBarProjection = '/roads/signal_bars_project/',
  roadSignProjection = '/road_constructions/road_signs_project/',
  roadsMarkingParameters = '/roads/road_marking_line_types/',
  roadsSegments = '/roads/segments/',
  roadsSegmentsCategories = '/roads/categories/',
  roadsSegmentsSupports = '/kns/support_pillars/',
  roadsSegmentSurfaceTypes = '/roads/segment_surface_types/',
  roadStrengthQuality = '/roads_quality/road_strength_quality/',
  roadStrengthQualityShort = '/roads_quality/road_strength_quality_repr/',
  roadTrafficLightsProjection = '/road_constructions/traffic_lights_project/',
  roadWarrantyViolations = '/roads_quality/violations_warranty_period/',
  roadway = '/roads/roadways/',
  roadwayProjection = '/roads/roadway_project/',
  roadWorksTrafficManagementProjects = '/road_works/traffic_management_projects/',
  roadWorksTrafficOrganizationSchema = '/road_works/traffic_organization_schema/',
  segmentsAnalystValues = '/roads_quality/segment_analysis_values/',
  sidewalks = '/roads/sidewalks/',
  signalBars = '/roads/signal_bars/',
  socialFacilities = '/social_facilities/',
  speedBumpProjection = '/road_constructions/speed_bump_project/',
  speedBumps = '/road_constructions/speed_bumps/',
  states = '/states/',
  statesDistricts = '/states/districts/',
  statesLocalities = '/states/localities/',
  statesLocalityTypes = '/states/locality_types/',
  statesMunicipalities = '/states/municipalities/',
  statesPopulation = '/states/population/',
  streetLamps = '/road_constructions/street_lamp_project/',
  systemConstants = '/system/constants/',
  supplyCablesTram = '/kns/supply_cables_tram/',
  supportWalls = '/road_constructions/support_walls/',
  terminals = '/terminals/',
  townSquares = '/roads/town_squares/',
  trafficLightObject = '/tl_controllers/',
  trafficManagmentFiles = '/road_works/traffic_management_projects_files/',
  trafficOrganizationSchemaFiles = '/road_works/traffic_organization_schema_files/',
  tramway = '/public_transport/tramways/',
  transportDetectors = '/transport_detectors/',
  traySystems = '/roads/tray_systems/',
  tubes = '/engineering_constructions/tubes/',
  uploadFilesHistory = '/roads_quality/upload_files_history/',
  userPositions = '/users/positions/',
  users = '/users/',
  road_accidents = '/road_accidents/',
  road_accidents_sections = '/road_accidents/sections/',
}

/** Будьте внимательнее, при написании урлов! Недописанные или лишние слэши приводят к ошибкам */
