import { Layout } from 'antd';

import styled from 'styled-components/macro';

const LocalMainWrapper = styled.div`
  margin: 40px;
  height: 700px !important;
`;

const LocalText = styled.div`
  text-align: center;
  padding-bottom: 30px;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.sectionTitle};
`;

const LocalSelectWrapper = styled.div`
  & :focus {
    background: ${(props) => props.theme.colors.defaultLighter};
    border-radius: 8px;
  }
  & button svg {
    width: 13px;
    height: 18px;
  }
`;

const PanelLayout = styled(Layout)`
  height: calc(100vh - 115px);
  padding: 0 20px 20px 20px;
`;

const LocalStyleValue = styled.div`
  & > article {
    line-height: 2.5;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const LocalInnerWrapper = styled.div`
  display: flex;
  & input:not(.ant-input) {
    width: 18px;
    height: 18px;
  }
  & label {
    font-weight: bold;
    text-align: left;
    line-height: 1.3rem;
  }
`;

const LocalTextDirectories = styled.div`
  display: flex;
  padding-bottom: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.neutralDarker};
`;

const RenderListContainer = styled.div`
  display: flex;
`;

const RenderListCheckboxWrapper = styled.div`
  padding-top: 5px;
`;

const RenderListItemWrapper = styled.div`
  padding-left: 20px;
`;

const LocalSelectWord = styled.p`
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  padding: 0 3px;
  margin: 0;
`;

export const Styled = {
  PanelLayout,
  LocalText,
  LocalMainWrapper,
  LocalSelectWrapper,
  LocalStyleValue,
  LocalInnerWrapper,
  LocalTextDirectories,
  LocalSelectWord,
  RenderListContainer,
  RenderListCheckboxWrapper,
  RenderListItemWrapper,
};
