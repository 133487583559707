import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react';

import { Menu } from 'antd';

import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Styled } from './SiderApplications.styled';
import {
  IApplicationsContext,
  CargoTransportParams,
  ISiderApplicationsProps,
} from 'components/Application/ExtraModules/Applications/ApplicationsForm/interfacesApplications/interfacesApplications';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { useRouteMatch } from 'react-router-dom';
import { AnyObject } from 'types/enums/general/general.model';
import { ApplicationsLabels } from 'types/enums/applications/ApplicationsLabels';
import { ApplicationsContext } from 'components/Application/ExtraModules/Applications/ApplicationsRouter';
import { getFromURL } from 'api/getFromURL';

const { SiderWrapper, SiderInner, SiderContent, SiderFooter, SiderTitle } = Styled;

/**
 * Компонент создания меню в сайтбаре
 * Поскольку пунктов меню нет в scheme меню создается вручную через переменные menuTab...
 */

interface ICreateMenuItemsProps {
  disabledMenu: boolean;
  model: ModelResponse;
  scoreMode?: string;
  directory: ApplicationsDirectory;
}

const createMenuItems = ({ model, disabledMenu, scoreMode, directory }: PropsWithChildren<ICreateMenuItemsProps>) => {
  const mainItem = [<Menu.Item key={ApplicationsDirectory.main}>Основное</Menu.Item>];
  const inlines = scoreMode
    ? []
    : Object.values(model.scheme).filter(
        (value) => value?.type === SchemaFieldType.inline && value?.label !== 'Тип мастера'
      );
  if (directory === ApplicationsDirectory.tasks && !scoreMode) {
    mainItem.push(
      <Menu.Item key={ApplicationsDirectory.childrenTasks} disabled={disabledMenu}>
        {ApplicationsLabels.childrenTasks}
      </Menu.Item>
    );
  }
  return [
    ...mainItem,
    ...inlines.map((el) => (
      <Menu.Item key={el.url} disabled={disabledMenu}>
        {el.label}
      </Menu.Item>
    )),
  ];
};

export const SiderApplications = React.forwardRef((props: PropsWithChildren<ISiderApplicationsProps>, ref: any) => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { scoreMode, directory } = params;
  const { mainModel: model, setPageMenuKey, setMainModel } = useContext<IApplicationsContext>(ApplicationsContext);
  const { initClose, pageMenuKey, disabledMenu, initSubmitForm } = props;

  const MenuItems = useMemo(
    () => createMenuItems({ model, disabledMenu, scoreMode, directory }),
    [directory, disabledMenu, model, scoreMode]
  );

  useEffect(() => {
    if (!model.name) {
      const newDirectory = directory === ApplicationsDirectory.main ? '' : `${directory}/`;
      const mainUrlModel = `/${ApplicationsDirectory.applications}/${newDirectory}`;
      getFromURL.getModelFromURL(mainUrlModel).then((res) => {
        setMainModel(res);
      });
    }
  }, [directory, model.name, setMainModel]);

  const onSelectMenuItem = useCallback(
    (event) => {
      setPageMenuKey(event.key);
    },
    [setPageMenuKey]
  );

  let isEditableSaveChange = ApplicationsDirectory.main === pageMenuKey || scoreMode; // Прячем кнопку в зависимости от возможности редактирования

  const clickSaveChangeButton = useCallback(() => {
    ref.current.estimation = false;
    initSubmitForm(ref);
  }, [initSubmitForm, ref]);

  const saveChangeButton = useMemo(() => {
    return (
      <Button onClick={clickSaveChangeButton} type={ButtonType.primary} width={'100%'} tooltip={'Сохранить изменения'}>
        {'Сохранить изменения'}
      </Button>
    );
  }, [clickSaveChangeButton]);

  const defaultSelectedKeys: string[] = useMemo(() => {
    const selectedTab = MenuItems.find((item: AnyObject) => item.key === pageMenuKey)?.key;
    const result = selectedTab ?? ApplicationsDirectory.main;
    return [`${result}`];
  }, [MenuItems, pageMenuKey]);

  return (
    <SiderWrapper width={'16%'}>
      <SiderInner>
        <SiderContent>
          <SiderTitle>Данные</SiderTitle>
          <Menu onClick={onSelectMenuItem} defaultSelectedKeys={defaultSelectedKeys}>
            {MenuItems}
          </Menu>
        </SiderContent>
        <SiderFooter>
          {isEditableSaveChange && saveChangeButton}
          <Button onClick={initClose} type={ButtonType.danger} width={'100%'} tooltip={'Выйти'}>
            Выйти
          </Button>
        </SiderFooter>
      </SiderInner>
    </SiderWrapper>
  );
});
