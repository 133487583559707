import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RoadsService as RoadConstructionsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class RoadMarkingLine extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_marking_line',
      filterLabel: 'Разметка (линия)',
      selectedObjectName: MapObjects.roadMarkingLine,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadsRoadMarkingLinesList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadsRoadMarkingLinesDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadsRoadMarkingLinesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadMarkingLines);
  }
}
