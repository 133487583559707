import React from 'react';
import styled from 'styled-components/macro';

const StyledLocalEmptyBlock = styled.div`
  width: 100%;
  height: 100px !important;
  box-sizing: border-box;
`;
/**
 * Игнорировние пространства под хэдером во флоу документа
 */
export const HeaderScipBlock = () => {
  return <StyledLocalEmptyBlock />;
};
