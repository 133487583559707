export const signsRoadMapperType2 = {
  2.1: { x: 0, y: 0, width: 128, height: 180 },
  '2.1-G': { x: 129, y: 0, width: 128, height: 180 },
  '2.1-R': { x: 0, y: 181, width: 128, height: 180 },
  2.2: { x: 129, y: 181, width: 128, height: 180 },
  '2.2-G': { x: 258, y: 0, width: 128, height: 180 },
  '2.2-R': { x: 258, y: 181, width: 128, height: 180 },
  '2.3.1': { x: 387, y: 0, width: 128, height: 180 },
  '2.3.1-G': { x: 387, y: 181, width: 128, height: 180 },
  '2.3.1-R': { x: 0, y: 362, width: 128, height: 180 },
  '2.3.2': { x: 129, y: 362, width: 128, height: 180 },
  '2.3.2-G': { x: 258, y: 362, width: 128, height: 180 },
  '2.3.2-R': { x: 387, y: 362, width: 128, height: 180 },
  '2.3.3': { x: 516, y: 0, width: 128, height: 180 },
  '2.3.3-G': { x: 516, y: 181, width: 128, height: 180 },
  '2.3.3-R': { x: 516, y: 362, width: 128, height: 180 },
  '2.3.4': { x: 0, y: 543, width: 128, height: 180 },
  '2.3.4-G': { x: 129, y: 543, width: 128, height: 180 },
  '2.3.4-R': { x: 258, y: 543, width: 128, height: 180 },
  '2.3.5': { x: 387, y: 543, width: 128, height: 180 },
  '2.3.5-G': { x: 516, y: 543, width: 128, height: 180 },
  '2.3.5-R': { x: 645, y: 0, width: 128, height: 180 },
  '2.3.6': { x: 645, y: 181, width: 128, height: 180 },
  '2.3.6-G': { x: 645, y: 362, width: 128, height: 180 },
  '2.3.6-R': { x: 645, y: 543, width: 128, height: 180 },
  2.4: { x: 774, y: 0, width: 128, height: 180 },
  '2.4-G': { x: 774, y: 181, width: 128, height: 180 },
  '2.4-R': { x: 774, y: 362, width: 128, height: 180 },
  2.5: { x: 774, y: 543, width: 128, height: 180 },
  '2.5-G': { x: 0, y: 724, width: 128, height: 180 },
  '2.5-R': { x: 129, y: 724, width: 128, height: 180 },
  2.6: { x: 258, y: 724, width: 128, height: 180 },
  '2.6-G': { x: 387, y: 724, width: 128, height: 180 },
  '2.6-R': { x: 516, y: 724, width: 128, height: 180 },
  2.7: { x: 645, y: 724, width: 128, height: 180 },
  '2.7-G': { x: 774, y: 724, width: 128, height: 180 },
  '2.7-R': { x: 903, y: 0, width: 128, height: 180 },
};
