import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { layerDataAccessors } from '../description/dataAccessors';
import { ekbLayerCards } from '../description/cards/ekb';
import { layerSettings } from '../description/settings';
import CamerasAndDetectorsItemSelection from '../../../../layers/SelectedObjectsRepresentations/CamerasAndDetectorsItemSelection/CamerasAndDetectorsItemSelection';
import { projectLayerGroups } from '../description/layerGroups';

export class CamerasAndDetectorsLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.camerasAndDetectors,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.CamerasAndDetectorsDataAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.CamerasAndDetectors()],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.CamerasAndDetectorsCard(),
      customSettings: [new layerSettings.OpacitySetting(), new layerSettings.IconSizeScale()],
      layerSchema: new layerSchemas.ekbLayersSchema.CamerasAndDetectorsSchema(),
      selectedObjectPrototype: CamerasAndDetectorsItemSelection,
    });
  }
}
