import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';

class NotificationsActions {
  /** action types */
  public readonly prefix: string = 'NOTIFICATIONS';
  public readonly SET_ALERT_DATA: string = `${this.prefix}.SET_ALERT_DATA`;
  public readonly CLEAR_ALERT_DATA: string = `${this.prefix}.CLEAR_ALERT_DATA`;
  public readonly SET_NOTIFICATION_DATA: string = `${this.prefix}.SET_NOTIFICATION_DATA`;
  public readonly CLEAR_NOTIFICATION_DATA: string = `${this.prefix}.CLEAR_NOTIFICATION_DATA`;

  /** actions */
  public setGlobalAlertData: ActionFunction1<any, Action<any>> = createAction(this.SET_ALERT_DATA);
  public clearGlobalAlertData: ActionFunction0<any> = createAction(this.CLEAR_ALERT_DATA);
  public clearPromptData: ActionFunction0<any> = createAction(this.CLEAR_NOTIFICATION_DATA);
  public setPromptData: ActionFunction1<any, Action<any>> = createAction(this.SET_NOTIFICATION_DATA);

  /** effects */
}

export const notificationActions = new NotificationsActions();
