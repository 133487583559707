export const rasterTileStylesEnum = {
  collection: [
    { label: 'Кадастровая карта', value: 'https://gext.ru/rosreestr_xyz/{z}/{x}/{y}.png' },
    {
      label: 'OCM Sputnik',
      value: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    },
    {
      label: 'Спутник',
      value: 'http://tiles.maps.sputnik.ru/{z}/{x}/{y}.png',
    },
    {
      label: 'TF cycle 25',
      value: 'https://a.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=13efc496ac0b486ea05691c820824f5f',
    },
    {
      label: 'TF transport dark 25',
      value: 'https://b.tile.thunderforest.com/transport-dark/{z}/{x}/{y}.png?apikey=13efc496ac0b486ea05691c820824f5f',
    },
    {
      label: 'TF outdoors 25',
      value: 'https://b.tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=13efc496ac0b486ea05691c820824f5f',
    },
    {
      label: 'dark theme 25',
      value: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
    },
    {
      label: 'hykebike 20',
      value: 'https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png',
    },
    {
      label: 'Стандарт ОСМ 20',
      value: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    {
      label: 'Wikipedia map 20',
      value: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
    },
    {
      label: 'ОСМ hot 20',
      value: 'http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
    },
    {
      label: 'ОСМ hydra 18',
      value: 'https://b.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png',
    },
    {
      label: 'OTM 17',
      value: 'https://b.tile.opentopomap.org/{z}/{x}/{y}.png',
    },
  ],
  selectItem(enumerate, value) {
    const suitable = enumerate.find((el) => el.value === value);
    if (suitable) return suitable.label;
    return 'none';
  },
};
