import React from 'react';
import Icon from '@ant-design/icons';

const RulerIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.1582 15.3001L2.6332 13.8334L3.81654 15.0001L4.69987 14.1251L3.51654 12.9501L4.69987 11.7668L6.7582 13.8334L7.64154 12.9501L5.5832 10.8834L6.7582 9.70845L7.94154 10.8834L8.82487 10.0001L7.64154 8.82511L8.82487 7.64178L10.8832 9.70845L11.7665 8.82511L9.7082 6.75845L10.8832 5.58345L12.0582 6.75845L12.9499 5.87511L11.7665 4.70011L12.9499 3.51678L14.9999 5.58345L15.8915 4.70011L13.8332 2.63345L15.2999 1.15845L18.8415 4.70011L4.69987 18.8418L1.1582 15.3001V15.3001Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const RulerIcon = (props) => <Icon component={RulerIconSVG} {...props} />;
