import { Shape } from 'konva/lib/Shape';
import { Stage } from 'konva/lib/Stage';
import { Line } from 'konva/lib/shapes/Line';
import _ from 'underscore';

import CameraMaskPrototype, { MaskObjectList, PolygonConfig, TypePoints } from './prototype/CameraMaskPrototype';
import { ObjectType } from './prototype/ObjectType';
import { DetectionMask, MaskTypeEnum } from 'generated/api/api';

class ObjectCounterPedestriansMask extends CameraMaskPrototype {
  protected static maskTypeName = 'Счетчик пешеходов';
  protected static maskType = MaskTypeEnum.ObjectCounterPedestrians;
  protected static maskObjectList: Array<MaskObjectList> = [
    {
      type: ObjectType.roiPoints,
      name: 'Область разметки',
      help: 'Выделите границы разметки пешеходного перехода',
    },
    {
      type: ObjectType.controlLinePoints,
      name: 'Средняя линия',
      help: 'Выберите две перпендикулярных полоске зебры линии',
    },
  ];
  protected static polygonConfig: PolygonConfig = {
    ...CameraMaskPrototype.polygonConfig,
    fill: 'rgba(242, 153, 74, 0.08)',
    stroke: '#F2994A',
  };

  constructor(cameraID: number, mask: DetectionMask | null = null) {
    super(cameraID, mask);
  }

  static getMaskTypeName(): string {
    return ObjectCounterPedestriansMask.maskTypeName;
  }

  static getMaskType(): string {
    return ObjectCounterPedestriansMask.maskType;
  }

  static getMaskObjectList(): Array<MaskObjectList> {
    return ObjectCounterPedestriansMask.maskObjectList;
  }

  get maskTypeName(): string {
    return ObjectCounterPedestriansMask.maskTypeName;
  }

  get maskType(): MaskTypeEnum {
    return ObjectCounterPedestriansMask.maskType;
  }

  get maskObjectList(): Array<MaskObjectList> {
    return ObjectCounterPedestriansMask.maskObjectList;
  }

  get polygonConfig(): PolygonConfig {
    return ObjectCounterPedestriansMask.polygonConfig;
  }

  get drawPoints(): TypePoints {
    if (this.currentDrawType && this.currentDrawType !== ObjectType.controlLinePoints) {
      return this._drawMaskPoints[this.currentDrawType] ?? [];
    }

    return [];
  }

  checkRules(): boolean {
    const superRules = super.checkRules();
    const roiPoints = this._drawMaskPoints[ObjectType.roiPoints];
    const controlLinePoints = this._drawMaskPoints[ObjectType.controlLinePoints];
    let available = true;

    // Проверка нахождения точек контрольной линии на средних точках двух линий полигона
    if (controlLinePoints?.length === 2 && !!roiPoints?.length && roiPoints?.length >= 4) {
      available = false;
      const availablePoints = roiPoints.filter((el, key) => {
        const nextElement = roiPoints[key + 1];
        let x, y;

        if (!nextElement) {
          const firstElement = roiPoints[0];

          x = Math.trunc((el[0] + firstElement[0]) / 2);
          y = Math.trunc((el[1] + firstElement[1]) / 2);
        } else {
          x = Math.trunc((el[0] + nextElement[0]) / 2);
          y = Math.trunc((el[1] + nextElement[1]) / 2);
        }

        return _.isEqual([x, y], controlLinePoints[0]) || _.isEqual([x, y], controlLinePoints[1]);
      });

      if (availablePoints.length === 2) {
        available = true;
      }
    }

    return superRules && available;
  }

  drawHandler(target: Shape | Stage): TypePoints {
    const stage = target.getStage();

    switch (this.currentDrawType) {
      case ObjectType.controlLinePoints: {
        return this.drawControlLine(target);
      }
      default: {
        return this.defaultDrawHandler(stage);
      }
    }
  }

  drawControlLine(target: Shape | Stage): TypePoints {
    const id = target.id();
    const stage = target.getStage();
    let newPoints: TypePoints = [];

    if (this.currentDrawType) {
      const drawPoints = this._drawMaskPoints?.[this.currentDrawType] ?? [];
      newPoints = [...drawPoints];
    }

    if (id.includes(ObjectType.roiPoints)) {
      const line: Line | undefined = stage?.findOne(`#${id}`);
      let points = line?.points();

      if (points) {
        // Вычисление середины отрезка
        const x = Math.trunc((points[0] + points[2]) / 2);
        const y = Math.trunc((points[1] + points[3]) / 2);
        newPoints.push([x, y]);
      }
    }

    if (newPoints.length > 2) newPoints.shift();

    return newPoints;
  }
}

export default ObjectCounterPedestriansMask;
