export enum Layers {
  roadsSchema = 'roadsSchema',
  transportOrders = 'transportOrders',
  publicTransport = 'publicTransport',
  trolleybusTransport = 'trolleybusTransport',
  tramsSchema = 'tramsSchema',
  substationsLayer = 'substationsLayer',
  trafficLightObject = 'trafficLightObject',
  camerasAndDetectors = 'camerasAndDetectors',
  roadSpeedBumps = 'roadSpeedBumps',
  illuminatedPedestrianCrossings = 'illuminatedPedestrianCrossings',
  citydistricts = 'citydistricts',
  trafficManagementProjects = 'trafficManagementProjects',
  projectLayer = 'projectLayer',
  brigadeApplications = 'brigadeApplications',
  monitoringUds = 'monitoringUds',
  camerasUdc = 'camerasUdc',
  roadAccidents = 'road_accidents',
}
