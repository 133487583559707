import SidebarItem from '../SidebarItem/SidebarItem';
import { HomeIcon } from '../../../../custom/icons/objectIcons/HomeIcon';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationIcon } from '../../../../custom/icons/objectIcons/LocationIcon';
import { Styled } from './SidebarCategory.styled';
import { PersonIcon, BrigadeIcon, FolderIcon, DocumentIcon, ClipboardIcon } from '@frontend-packages/its-ui-icons';
import { VehicleIcon } from 'UI/custom/icons/objectIcons/VehicleIcons';
import { useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import { isEmpty } from 'lodash';

interface SidebarCategoryProps {
  sidebarIsOpened: boolean;
}

const SidebarCategory = ({ sidebarIsOpened }: SidebarCategoryProps) => {
  const location = useLocation();
  const history = useHistory();
  const userRights = useSelector((state: StateModel) => state.auth.permissions);
  const isUserHasRightForMap = !isEmpty(userRights.layers);
  const isUserHasRightForDirectories = !isEmpty(userRights.directory);
  const isUserHasRightForReports = !isEmpty(userRights.reports);
  const isUserHaveRightForSpecialPermits =
    isUserHasRightForDirectories && userRights.directory.some((permission) => permission.startsWith('special_permits'));
  const isUserHaveRightForRoadApplications =
    isUserHasRightForDirectories && userRights.directory.some((permission) => permission.startsWith('applications'));
  const isUserHaveRightForAdministration =
    isUserHasRightForDirectories &&
    userRights.directory.some((permission) => permission.startsWith('auth') || permission.startsWith('system'));
  const isUserHaveRightForModeration =
    isUserHasRightForDirectories &&
    userRights.directory.some((permission) => {
      return permission.includes('view_awaiting_validation_detections');
    });
  return (
    <Styled.CategoryWrapper>
      <SidebarItem
        key={`home`}
        icon={<HomeIcon />}
        onClick={() => history.replace('/app/')}
        isSidebarOpened={sidebarIsOpened}
        active={location.pathname === `/app` || location.pathname === `/app/`}
      >
        Главная
      </SidebarItem>
      {isUserHasRightForMap && (
        <SidebarItem
          key={`map-main`}
          icon={<LocationIcon />}
          onClick={() => history.replace('/app/map/')}
          isSidebarOpened={sidebarIsOpened}
          active={location.pathname === `/app/map/`}
        >
          Карта
        </SidebarItem>
      )}
      {isUserHasRightForDirectories && (
        <SidebarItem
          key={`directory`}
          icon={<FolderIcon />}
          onClick={() => history.replace('/app/directory/')}
          isSidebarOpened={sidebarIsOpened}
          active={location.pathname === `/app/directory/`}
        >
          Справочники
        </SidebarItem>
      )}
      {isUserHasRightForReports && (
        <SidebarItem
          key={`reports`}
          icon={<DocumentIcon />}
          onClick={() => history.replace('/app/reports/')}
          isSidebarOpened={sidebarIsOpened}
          active={location.pathname === `/app/reports/`}
        >
          Отчеты
        </SidebarItem>
      )}
      {isUserHaveRightForSpecialPermits && (
        <SidebarItem
          key={`cargoTracking`}
          icon={<VehicleIcon />}
          onClick={() => history.replace('/app/special_permits/')}
          isSidebarOpened={sidebarIsOpened}
          active={location.pathname === `/app/special_permits/`}
        >
          Перевозка грузов
        </SidebarItem>
      )}
      {isUserHaveRightForRoadApplications && (
        <SidebarItem
          key={`applications`}
          icon={<BrigadeIcon />}
          onClick={() => history.replace('/app/applications/')}
          isSidebarOpened={sidebarIsOpened}
          active={location.pathname === `/app/applications/`}
        >
          ДУТССД
        </SidebarItem>
      )}
      {isUserHaveRightForAdministration && (
        <SidebarItem
          key={`administration`}
          icon={<PersonIcon />}
          onClick={() => history.replace('/app/administration/')}
          isSidebarOpened={sidebarIsOpened}
          active={location.pathname === `/app/administration/`}
        >
          Администрирование
        </SidebarItem>
      )}
      {isUserHaveRightForModeration && (
        <SidebarItem
          key={`moderation`}
          icon={<ClipboardIcon />}
          onClick={() => history.replace('/app/moderation/')}
          isSidebarOpened={sidebarIsOpened}
          active={location.pathname === `/app/moderation/`}
        >
          Модерация
        </SidebarItem>
      )}
    </Styled.CategoryWrapper>
  );
};

export default SidebarCategory;
