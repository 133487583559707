import { baseStyles } from '../../../../../../stylesRegistrator';
import styled from 'styled-components/macro';

const { BaseMapLayerPanelWrapperPrimary, BaseMapPanelHeader, BaseMapPanelItem } = baseStyles.modules.map;

const ExtendedPanelWrapper = styled(BaseMapLayerPanelWrapperPrimary)`
  width: 350px;
  height: 95vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & ${BaseMapPanelItem}:nth-of-type(2) {
    //this is should be form area
    flex: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
    //in this place we rewriting styles from autocomplete form
    & > form {
      padding: 0;
      flex: 1;
      width: auto;
      & > div {
        margin: 0;
        width: 100%;
        & > div {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
  & ${BaseMapPanelItem}:nth-of-type(3) {
    //this is should be controls area
    margin: -${(props) => props.theme.margins.defaultMargin} 0;
    & > * {
      margin: ${(props) => props.theme.margins.defaultMargin} 0;
    }
  }
`;

const ExtendedPanelHeader = styled(BaseMapPanelHeader)`
  padding: 0.8rem;
  height: fit-content;
`;

export const Styled = { ExtendedPanelWrapper, ExtendedPanelHeader };
