import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonType } from '../../../../../../../UI/common/components/Button/model/Button.model';
import { setGlobalPrintMode } from '../../../../../../../store/reducers/map/actions/mapPanelsActions';
import { Button } from 'UI/common/components/Button/Button';
import _ from 'underscore';

interface PrintWithAllSettingButtonProps {
  type: ButtonType | undefined;
  icon: React.FC;
  iconSize: string;
  key: number;
}

const PrintWithAllSettingButton = ({ type, icon, iconSize }: PrintWithAllSettingButtonProps) => {
  // @ts-ignore TODO поправить редьюсер Тех-долг-FRONTEND #5585
  const isPrintMode = useSelector((state) => state.mapPanels.GlobalPrintMode);
  const dispatch = useDispatch();

  const fullSizePrinting = useCallback(() => {
    dispatch(setGlobalPrintMode({ mode: true }));
  }, [dispatch]);

  return (
    <Button
      rounded
      type={isPrintMode ? ButtonType.primary : type}
      onClick={fullSizePrinting}
      icon={icon}
      hasShadow
      iconSize={iconSize}
      key={_.uniqueId()}
      tooltip={'Печать'}
    />
  );
};

export default PrintWithAllSettingButton;
