import styled from 'styled-components/macro';

const FieldChildInputWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;

const FieldChildInputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`;

const FieldChildInputGroupHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-template-rows: 20px;
`;

const FieldChildInputLabel = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.gray};
`;

export const Styled = {
  FieldChildInputWrap,
  FieldChildInputGroup,
  FieldChildInputGroupHeader,
  FieldChildInputLabel,
};
