import { Layout } from '@frontend-packages/its-ui-kit';
import React from 'react';
import { ModerateFileList } from './ModerateFileList/ModerateFileList';
import { ModeratorContextProvider } from './context/ModeratorContext';
import { ModeratedImageContainer } from './ModeratedImage/ModeratedImageContainer';
import { ModerateToolsContainer } from './ModerateTools/ModerateToolsContainer';
import { SLayoutContentWrapper, SLayoutSiderWrapper, SLayoutWrapper } from './styles/ModeratorRoom.styles';

export const ModeratorRoom = () => {
  return (
    <ModeratorContextProvider>
      <SLayoutWrapper>
        <SLayoutSiderWrapper>
          <ModerateFileList />
        </SLayoutSiderWrapper>
        <SLayoutContentWrapper className="imageContentContainer">
          <ModeratedImageContainer />
        </SLayoutContentWrapper>
        <Layout.Sider>
          <ModerateToolsContainer />
        </Layout.Sider>
      </SLayoutWrapper>
    </ModeratorContextProvider>
  );
};
