import { CompositeLayer } from '@deck.gl/core';
import { PathLayer, TextLayer } from '@deck.gl/layers';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { DSelector } from 'services/map/Dselector/DSelector';

class TrolleybusCompositeLayers extends CompositeLayer {
  updateState({ changeFlags, props }) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;

    if (selectedLayer instanceof TrolleybusCompositeLayers) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }
    this.setState({
      data: props,
    });
  }

  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.trolleybusTransport;
        if (event.sourceLayer.id.includes('cables_troll')) selectedObjectType = MapObjects.cablesTroll;
        if (event.sourceLayer.id.includes('supply_cables_troll')) selectedObjectType = MapObjects.supply_cables_troll;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    let trollCableColor = this.props.roadColorFromPicker.trollCableColor.slice(0, -1);
    let supplyCableColor = this.props.roadColorFromPicker.supplyCable.slice(0, -1);
    return [
      new PathLayer(this.props, this.getSubLayerProps({ id: 'cables_troll' }), {
        data: this.props.relatedData.Cables_troll,
        pickable: true,
        autoHighlight: true,
        widthScale: 3,
        widthMinPixels: 2,
        widthMaxPixels: 12,
        rounded: true,
        billboard: false,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPath: (d) => {
          if (d.line_path) {
            return getCoordsFromParsedGeometry(d.line_path);
          }
          return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          return this.props?.selectedObject?.type === 'Cables_troll' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [118, 118, 118, 250]
            : trollCableColor;
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'Cables_troll' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 20.5
            : 5;
        },
        opacity: this?.props?.opacity ?? 0.1,
        onClick: () => {
          this.props.onClick();
        },
      }),

      new PathLayer(this.props, this.getSubLayerProps({ id: 'supply_cables_troll' }), {
        data: this.props.relatedData.Supply_Cables_Troll,
        pickable: true,
        autoHighlight: true,
        widthScale: 3,
        widthMinPixels: 2,
        widthMaxPixels: 12,
        rounded: true,
        billboard: false,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPath: (d) => {
          if (d.line_path) {
            return getCoordsFromParsedGeometry(d.line_path);
          }
          return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          return this.props?.selectedObject?.type === 'Supply_Cables_Troll' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [118, 118, 118, 250]
            : supplyCableColor;
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'Supply_Cables_Troll' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 7.5
            : 5;
        },
        opacity: this?.props?.opacity ?? 0.1,
        onClick: () => {
          this.props.onClick();
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: 'accessor', value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: 'accessor', value: 8 },
  // Label color for each feature
  getLabelColor: { type: 'accessor', value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: 'accessor', value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: 'pixels',
  // Label background color
  labelBackground: { type: 'color', value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: 'Monaco, monospace',
};

TrolleybusCompositeLayers.layerName = 'TrolleybusSchema';
TrolleybusCompositeLayers.defaultProps = defaultProps;

export default TrolleybusCompositeLayers;
