import styled from 'styled-components/macro';
import { Button } from '../../../../../common/components/Button/Button';
import { Input } from 'antd';

// Стили иконок группы фильтрации. бефор и афтер создают вертикальные полосы
const ActionPanelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px 6px 0;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 20px;
    background-color: ${(props) => props.theme.colors.neutralDark};
  }
`;

// это кнопки фильтров (поиск, бочка бензтна и фильтр)
const ActionPanelButton = styled(Button)`
  padding: 0;
  width: 28px;
  height: 28px;

  &:not(:last-of-type) {
    margin-right: 6px;
  }
`;

// Инпут поиска по гриду.
const SearchInput = styled(Input)`
  border-radius: 5px;
  margin-right: 6px;
  height: 28px;
  width: 15em;

  &:focus,
  &:hover {
    border-color: ${(props) => props.theme.colors.lightBlue} !important;
    box-shadow: none !important;
  }
`;
export const Styled = { ActionPanelWrapper, ActionPanelButton, SearchInput };
