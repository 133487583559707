import React from 'react';
import Icon from '@ant-design/icons';

const RetainingWallsSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.50008 13.3334H10.0001V17.5H2.50008V13.3334ZM1.66675 8.33337H6.66675V12.5H1.66675V8.33337ZM7.50008 8.33337H12.5001V12.5H7.50008V8.33337ZM13.3334 8.33337H18.3334V12.5H13.3334V8.33337ZM10.8334 13.3334H17.5001V17.5H10.8334V13.3334ZM2.50008 3.33337H9.16675V7.50004H2.50008V3.33337ZM10.0001 3.33337H17.5001V7.50004H10.0001V3.33337Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const RetainingWalls = (props) => <Icon component={RetainingWallsSVG} {...props} />;
