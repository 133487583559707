import { useCallback, useContext } from 'react';
import { PortalContext } from '../../UI/common/components/ScreenPortal/ScreenPortal';

export function usePortalContext(onClose) {
  const { closePortal, isOpen } = useContext(PortalContext);
  const enhancedCloseCallback = useCallback(() => {
    onClose();
    closePortal();
  }, [onClose, closePortal]);
  return { closePortal, isOpen, enhancedCloseCallback };
}
