import { BooleanLayerSetting } from './prototype/types/BooleanLayerSetting';

export class ProjectionOnMapSetting extends BooleanLayerSetting {
  constructor() {
    super({
      label: 'Проекция на карту',
      keyForDeck: 'billboard',
    });
  }
}
