import { Position2D } from '@deck.gl/core/utils/positions';
import Konva from 'konva';
import lodash from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

export type DefectBbobx = [[number, number], [number, number]];

export interface IDefectText {
  time: string;
  violationType: string;
  point: Position2D;
}

export interface IUseMonitoringDefectImageProps {
  image?: string;
  bbox?: DefectBbobx;
  text?: IDefectText;
  onReady?: (dataUrl: string) => void;
}

export const useMonitoringDefectImage = (props: IUseMonitoringDefectImageProps) => {
  const { image, bbox, text, onReady } = props;

  const [windowImage, setWindowImage] = useState<HTMLImageElement>();
  const [sizes, setSizes] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const container = document.createElement('div');
    container.setAttribute('id', 'monitoringDefectConva');
    container.style.display = 'none';
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, []);

  useEffect(() => {
    const loadImage = new window.Image();

    const handleLoad = () => {
      setWindowImage(loadImage);
      setSizes({
        width: loadImage.width,
        height: loadImage.height,
      });
    };

    if (image) {
      loadImage.crossOrigin = 'Anonymous';
      loadImage.src = image;
      loadImage.addEventListener('load', handleLoad);
    }

    return () => {
      loadImage.removeEventListener('load', handleLoad);
    };
  }, [image]);

  const squareCoords = useMemo(() => {
    if (bbox) {
      const points = lodash.flatten(bbox);
      return [
        [points[0], points[1]],
        [points[2], points[1]],
        [points[2], points[3]],
        [points[0], points[3]],
        [points[0], points[1]],
      ];
    }

    return null;
  }, [bbox]);

  const square = useMemo(() => {
    if (squareCoords) {
      return new Konva.Line({
        points: lodash.flatten(squareCoords),
        strokeWidth: 4,
        stroke: '#CA3F4B',
      });
    }

    return null;
  }, [squareCoords]);

  const textContent = useMemo(() => {
    if (text && squareCoords) {
      const { width, height } = sizes;
      const [topLeft, topRight, bottomLeft] = squareCoords;
      const { point, time, violationType } = text;
      const formatTime = moment(time).format('DD.MM.YYYY HH:mm:ss');
      const formatText = `${formatTime}\nКласс: ${violationType}\nШирота: ${point[1]}\nДолгота: ${point[0]}`;

      let x = 0;
      let y = 0;

      const konvaText = new Konva.Text({
        fontFamily: 'Gilroy',
        fontSize: 16,
        text: formatText,
        fill: '#FFF',
      });

      const textWidth = konvaText.width();
      const textHeight = konvaText.height();

      // Расположение текста
      // Право-Верх
      if (width - topRight[0] - 5 > textWidth && height - topRight[1] > textHeight) {
        x = topRight[0] + 5;
        y = topRight[1];
        // Низ-Лево
      } else if (width - bottomLeft[0] > textWidth && height - bottomLeft[1] + 5 > textHeight) {
        x = bottomLeft[0];
        y = bottomLeft[1] + 5;
        // Лево-Верх
      } else if (topLeft[0] - textWidth - 15 > textWidth && topLeft[1] - textHeight > textHeight) {
        x = topLeft[0] - textWidth - 15;
        y = topLeft[1];
        // Верх-Лево
      } else if (width - topLeft[0] > textWidth && topLeft[1] - textHeight - 5 > textHeight) {
        x = topLeft[0];
        y = topLeft[1] - textHeight - 5;
      }
      if (x > 0 && y > 0) {
        konvaText.x(x);
        konvaText.y(y);

        return konvaText;
      }
    }

    return null;
  }, [sizes, squareCoords, text]);

  const stage = useMemo(() => {
    if (square && windowImage) {
      const konvaLayer = new Konva.Layer();
      const konvaImage = new Konva.Image({
        image: windowImage,
      });

      konvaLayer.add(...[square, konvaImage]);

      if (textContent) {
        konvaLayer.add(textContent);
      }

      const konvaStage = new Konva.Stage({
        ...sizes,
        container: '#monitoringDefectConva',
      });

      konvaStage.add(konvaLayer);

      return konvaStage;
    }

    return null;
  }, [sizes, square, textContent, windowImage]);

  useEffect(() => {
    if (square && windowImage) {
      const dataUrl = stage?.toDataURL();

      onReady && dataUrl && onReady(dataUrl);
    }
  }, [onReady, square, stage, windowImage]);
};
