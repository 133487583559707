import { ReactComponent as LogoFull } from './imgs/LogoFull.svg';
import { ReactComponent as LogoMini } from './imgs/LogoMini.svg';
import { ReactComponent as LogoAuth } from './imgs/LogoAuth.svg';
import { ReactComponent as ItsPrimary } from './imgs/ItsPrimary.svg';
import { DefaultItsTheme } from '@frontend-packages/its-ui-kit';

export const ProjectTheme = {
  ...DefaultItsTheme,
  logo: {
    full: LogoFull,
    mini: LogoMini,
    auth: LogoAuth,
    itsPrimary: ItsPrimary,
  },
  auth: {
    bgImgSrcLink: `${process.env.PUBLIC_URL + '/img/imgAuth/bg-auth-bkd.png'}`,
    shapeLeftSrcLink: `${process.env.PUBLIC_URL + '/img/imgAuth/shape-left-auth.svg'}`,
  },
  decoration: {
    ...DefaultItsTheme.decoration,
    largeBorderRadiusOnlyTop: '16px 16px 0 0',
    defaultBorderRadiusOnlyTop: '8px 8px 0 0',
    largeBorderRadiusOnlyBottom: '0 0 16px 16px',
    defaultBorderRadiusOnlyBottom: '0 0 8px 8px',
    largeBorderRadius: '16px',
    defaultBorderRadius: '8px',
    defaultBoxShadow: '0px 2px 8px rgba(0, 130, 145, 0.08)',
    dangerBoxShadow: '0px 18px 40px rgba(235, 87, 87, 0.08)',
    warningBoxShadow: 'box-shadow: 0px 18px 40px rgba(245, 191, 79, 0.08)',
    successBoxShadow: 'box-shadow: 0px 18px 40px rgba(100, 197, 84, 0.08)',
    darkBoxShadow: '0px 18px 40px rgba(0, 130, 145, 0.08)',
    darkenBoxShadow: '0px 2px 10px rgba(0, 130, 145, 0.09)',
    panelBoxShadow: '0px 2px 8px rgba(107, 138, 228, 0.12)',
    defaultBackgroundFilter: 'blur(18px)',
    smallBackgroundFilter: 'blur(16px)',
  },
  fontSettings: {
    fontFamilyGilroy: "Gilroy, 'sans-serif'",
    sectionTitle: '1.285rem',
    large: '1.15rem',
    defaultLarge: '1.05rem',
    default: '1rem',
    defaultSmall: '0.95rem',
    small: '0.7rem',
    size: {
      ...DefaultItsTheme.fontSettings.size,
      sectionTitleLarge: '1.55rem',
      sectionTitle: '1.285rem',
      large: '1.15rem',
      defaultLarger: '1.10rem',
      defaultLarge: '1.05rem',
      default: '1rem',
      defaultSmall: '0.95rem',
    },
    weight: {
      ...DefaultItsTheme.fontSettings.weight,
      default: 600,
      title: 700,
    },
  },
  margins: {
    defaultMargin: '1.1rem',
  },
  paddings: {
    ...DefaultItsTheme.padding,
    defaultLarge: '1.2rem',
    defaultPadding: '1.1rem',
    defaultSmall: '1rem',
    smallPadding: '0.6rem',
    sidebarElemPaddingLeft: '15%',
  },
  colors: {
    ...DefaultItsTheme.colors,
    primary: '#2F80ED',
    disable: '#8ABCFF',
    primaryLight: '#29A3B0',
    primaryLighter: '#c4d0f4',
    primaryDark: '#2263CB',
    primaryDarker: '#1B3548',
    paragraph: '#56697A',
    backgroundNeutral: '#f2f3f6',
    dangerDark: '#aa0202',
    danger: '#EB5757',
    warning: '#F2C94C',
    success: '#27AE60',
    default: '',
    dark: '#1A2138',
    darkBlue: '#20252a',
    defaultDark: '#495A82',
    defaultDarker: '#000',
    defaultLight: '#f1f3f6',
    defaultLighter: '#ffffff',
    neutralDark: '#c1cbd8',
    neutralDarker: '#929fb5',
    neutralLight: '#c2ccd9',
    blue: '#81BFF9',
    lightblue: '#2D9CDB',
    darkblue: '#2F80ED',
    darkBlueText: '#35446F',
    orange: '#F2994A',
    purple: '#9B51E0',
    green: '#27AE60',
    gray: '#6A7B9B',
    pink: '#BB6BD9',
    lightBlue: '#E6F7FF',
    transparent: 'transparent',
    primaryTranslucent: 'rgba(107, 138, 228, 0.4)',
    defaultLightTranslucent: 'rgba(107, 138, 228, 0.08)',
    dangerOpacity: 'rgba(235, 87, 87, 0.08)',
    lightBlueOpacity: 'rgba(47, 128, 237, 0.08)',
  },
  gradient: {
    defaultLighter: '90deg, rgba(2,0,36,1) 0%, rgba(11,127,215,1) 0%, rgba(224,233,240,1) 48%',
  },
  status: {
    NEW: '#2D9CDB',
    CONFIRMED: '#2F80ED',
    IN_WORK: '#F2994A',
    DONE: '#27AE60',
    APPOINTED: '#BB6BD9',
    CLOSED: '#929FB5',
    CANCELED: '#929FB5',
  },
};
