import React from 'react';
import { ModeratedImage } from './ModeratedImage';
import { useModeratorContext } from '../context/ModeratorContext';

export const ModeratedImageContainer = () => {
  const { moderation, approveDetection, declineDetection, handleSelectDeltaItem } = useModeratorContext();
  const { activeModeration } = moderation;
  if (activeModeration) {
    return (
      <ModeratedImage
        detectionItem={moderation.activeModeration}
        onApproveDetection={() => approveDetection(activeModeration)}
        onDeclineDetection={() => declineDetection(activeModeration)}
        onGoPreviousImage={() => handleSelectDeltaItem(-1)}
        onGoNextImage={() => handleSelectDeltaItem(1)}
      />
    );
  }
  return null;
};
