import React, { useRef } from 'react';
import { Button } from '../../../common/components/Button/Button';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { ButtonType } from '../../../common/components/Button/model/Button.model';
import { DownloadIcon } from 'UI/custom/icons/actionIcons/DownloadIcon';

const DownloadButton = ({ theme, tooltip, isDisabled, downloadData, CRUDHandlers }) => {
  const iconSize = useRef({ width: '20px', height: '20px' });
  const { downloadFile } = CRUDHandlers ?? {};

  return (
    <Button
      rounded
      hasShadow={true}
      type={ButtonType.danger}
      p={'0.7rem'}
      icon={<DownloadIcon />}
      iconColor={theme.colors.primary}
      iconSize={iconSize.current}
      onClick={downloadData ?? downloadFile}
      tooltip={tooltip}
      disabled={isDisabled}
    />
  );
};

export default compose(withTheme)(DownloadButton);
