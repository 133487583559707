import { LayerCardPrototype } from '../prototype/LayerCardPrototype';

export class TrafficManagementProjectsCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'ПОДД',
      linkToPreview: 'SchemaRoad.svg',
      permissionToShowCard: 'layer_permissions.can_view_podd_layer',
    });
  }
}
