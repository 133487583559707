import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'UI/common/components/Button/Button';
import { Styled } from './style/PrepareModal.styled';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import Select from 'UI/common/components/Select/Select';
import { PrepareModalI } from './model/PrepareModal.model';

const { StyledModal } = Styled;

const PrepareModal = ({ buttonText, title, handleSuccess, selectorProps, buttonType }: PrepareModalI) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [value, setValue] = useState(selectorProps?.value);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(selectorProps?.value);
  }, [selectorProps?.value]);

  const showModal = useCallback(() => setOpenModal(true), []);

  const handleOk = useCallback(() => {
    if (selectorProps?.required && !value) {
      setError(true);
    } else {
      setOpenModal(false);
      handleSuccess?.call(null, value);
    }
  }, [handleSuccess, selectorProps?.required, value]);

  const handleCancel = useCallback(() => {
    const value = selectorProps?.value ?? '';
    setValue(value);
    setError(false);
    setOpenModal(false);
  }, [selectorProps?.value]);

  const footer = (
    <Button onClick={handleOk} type={ButtonType.primaryLC}>
      Изменить
    </Button>
  );

  const onChange = useCallback((value) => {
    setValue(value);
  }, []);

  return (
    <>
      <Button type={buttonType} onClick={showModal}>
        {buttonText}
      </Button>
      <StyledModal footer={footer} title={title} visible={isOpenModal} width={340} onCancel={handleCancel}>
        <Select showSearch allowClear error={error} onChange={onChange} {...selectorProps} value={value} />
      </StyledModal>
    </>
  );
};

export default PrepareModal;
