import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedMapboxLayer } from '../../../../../../store/reducers/map/mapboxLayers';
import GlobalLoader from '../../../../../../UI/common/components/GlobalLoader/GlobalLoader';

const LayerDataAccessorBuilder = () => {
  const SelectedMapLayer = useSelector(getSelectedMapboxLayer);

  let DataAccessor = null;
  if (SelectedMapLayer) {
    const resolvedDataAccessor = SelectedMapLayer.getDataAccessor();
    if (resolvedDataAccessor?.getDataAccessorRepresentationComponent()) {
      DataAccessor = resolvedDataAccessor.getDataAccessorRepresentationComponent();
    }
  }
  return (
    <>
      {DataAccessor && (
        <Suspense fallback={<GlobalLoader show={true} />}>
          <DataAccessor selectedLayerName={SelectedMapLayer?.getName()} />
        </Suspense>
      )}
    </>
  );
};

export default LayerDataAccessorBuilder;
