import React from 'react';
import Icon from '@ant-design/icons';

const GridGeometryIconSVG = () => (
  <svg width="88" height="24" viewBox="0 0 88 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20L7 22L12 11L17 22L12 20ZM8 2H16V5H22V7H16V10H8V7H2V5H8V2ZM10 4V8H14V4H10Z" fill="#35446F" />
    <path d="M47 3V7.59L39.59 15H35V21H41V16.42L48.42 9H53V3H47ZM49 5H51V7H49V5ZM37 17H39V19H37" fill="#35446F" />
    <path
      d="M66 2V8H68.28L69.57 16H68V22H74V20.06L79 20.05V22H85V16H83.17L84 9H86V3H80V6.53L78.8 8H73.59L72 5.82V2H66ZM68 4H70V6H68V4ZM82 5H84V7H82V5ZM70.31 8H71.11L73 10.59V14H79V10.91L80.57 9H82L81.16 16H79V18.06H74V16H71.6L70.31 8ZM75 10H77V12H75V10ZM70 18H72V20H70V18ZM81 18H83V20H81"
      fill="#35446F"
    />
  </svg>
);

export const GridGeometryIcon = (props) => <Icon component={GridGeometryIconSVG} {...props} />;
