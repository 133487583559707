export enum ModelSchemeFields {
  name = 'name',
  segment = 'segment',
  point = 'point',
  roadAccidentType = 'road_accident_type',
  file = 'file',
  closedStatusTime = 'closed_status_time',
  createdAt = 'created_at',
  uuid = 'uuid',
  roadImportance = 'road_importance',
  routeId = 'route_id',
  uuidStatement = 'uuid_statement',
  description = 'description',
  amount = 'amount',
  status = 'status',
  paymentStatus = 'payment_status',
  paidAmount = 'paid_amount',
  number = 'number',
  color = 'color',
  linePath = 'line_path',
  teamOfMasters = 'team_of_masters',
  teamOfMastersNames = 'team_of_masters_names',
  appointedTeam = 'appointed_team',
  violationType = 'violation_type',
  image = 'image',
  imageBorderGreen = 'image_border_green',
  imageBorderRed = 'image_border_red',
  segmentName = 'segment_name',
}
