import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const DimensionsWidthOversizesSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'dimensions_width_oversizes');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/api/${selectedMapItem.data[0].urlData.replace('_repr', '')}/${id}/`,
      '_blank'
    );
    win.focus();
  };
  let Descriptions = [];

  data.statement_name &&
    Descriptions.push({
      key: 'Наименование заявления',
      value: data.statement_name ? data.statement_name : 'Отсутствует',
    });

  Number.isFinite(data.width_oversize) &&
    Descriptions.push({
      key: 'Превышение ширины',
      value: data?.width_oversize ? data.width_oversize : 'Отсутствует',
    });

  return (
    <PrepareScrollableInfoCard
      title={'Карточка нарушения допустимой нагрузки'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default DimensionsWidthOversizesSelection;
