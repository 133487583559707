import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import React, { useState, useEffect } from 'react';
import { Styled } from './SelectTime.styled';
import moment from 'moment';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

const { LocalMainWrapper, LocalInputWrapper, LocalIcon } = Styled;

const localeMap = {
  en: enLocale,
  ru: ruLocale,
};

class RuLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'LLLL', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'dd MMMM', { locale: this.locale });
  }
}

const localeUtilsMap = {
  en: DateFnsUtils,
  ru: RuLocalizedUtils,
};

const localeFormatMap = {
  en: 'dd.MM.yyyy HH:mm',
  ru: 'dd.MM.yyyy HH:mm',
};

const localeCancelLabelMap = {
  en: 'cancel',
  ru: 'отмена',
};

const SelectTime = (props) => {
  const [locale] = useState('ru');
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());

  let dateStart = moment(selectedDateStart).format('YYYY-MM-DD HH:mm:ss');
  let dateEnd = moment(selectedDateEnd).format('YYYY-MM-DD HH:mm:ss');

  useEffect(() => {
    props.updateDataStart(dateStart);
    props.updateDataEnd(dateEnd);
    /* TODO проверить зависимости - точно ли они не нужны  Тех-долг-FRONTEND #5551 */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <LocalMainWrapper>
      <MuiPickersUtilsProvider utils={localeUtilsMap[locale]} locale={localeMap[locale]}>
        <LocalInputWrapper>
          <DateTimePicker
            value={selectedDateStart}
            onChange={handleDateChangeStart}
            format={localeFormatMap[locale]}
            cancelLabel={localeCancelLabelMap[locale]}
            shouldDisableDate={false}
            label="Начало"
            variant="inline"
            ampm={false}
            onClose={() => props.updateDataStart(dateStart)}
          />
        </LocalInputWrapper>
        <LocalIcon />
        <LocalInputWrapper>
          <DateTimePicker
            value={selectedDateEnd}
            onChange={handleDateChangeEnd}
            format={localeFormatMap[locale]}
            cancelLabel={localeCancelLabelMap[locale]}
            label="Конец"
            variant="inline"
            ampm={false}
            onClose={() => props.updateDataEnd(dateEnd)}
          />
        </LocalInputWrapper>
      </MuiPickersUtilsProvider>
    </LocalMainWrapper>
  );
};

export default SelectTime;
