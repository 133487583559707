import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { EngineeringConstructionsTypeEnum } from '../../../types/enums/map/SelectedItemTypeEnum';

const EngineeringConstructionsItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'engineering_constructions');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.engineeringConstructions}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Тип',
    value: EngineeringConstructionsTypeEnum[data?.construction_type] ?? 'Не указан',
  });

  Descriptions.push({
    key: 'Эксплуатационный километр',
    value: data?.exploitative_kilometer ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об инженерное дорожных сооружениях'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

EngineeringConstructionsItemSelection.propTypes = {};

export default EngineeringConstructionsItemSelection;
