import { groupTypes } from '../../contentGroups';
import { LayersPrototype } from '../prototypes/LayersPrototype';
import React from 'react';
import { MapContextMenuItem } from '../../../../../types/enums/map/contextMenu/MapContextMenuItem.model';
import { initializeSingleObjectEditMode } from '../../../../../store/sagas/map/standaloneObjectEditableSaga';
import { MeasureDistanceMode } from '@nebula.gl/edit-modes';
import { setRulerMode } from '../../../../../store/reducers/map/actions/mapPanelsActions';
import { Layers } from '../../../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../../../types/enums/map/MapObjects.model';
import { RulerIcon } from '../../../../../UI/custom/icons/objectIcons/RulerIcon';
import { PlusSegmentIcon } from '../../../../../UI/custom/icons/actionIcons/PlusSegmentIcon';

const { ACTION_GROUP, ADD_GROUP } = groupTypes;

export class TramsSchema extends LayersPrototype {
  constructor() {
    super(Layers.tramsSchema);
    // super(Layers.roadsSchema);
  }

  actionItems = [
    {
      name: MapContextMenuItem.tramCables,
      path: 'Создать участок линии',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.tramCables, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
    {
      name: MapContextMenuItem.tramSupplyCables,
      path: 'Создать участок питающей линии',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.supplyTramCables, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
    {
      name: MapContextMenuItem.rulerLength,
      path: 'Линейка',
      group: ACTION_GROUP,
      dispatchedAction: () => setRulerMode(MeasureDistanceMode),
      icon: <RulerIcon />,
    },
  ];
}
