import { LayerCardPrototype } from 'registrators/map/layers/description/cards/prototype/LayerCardPrototype';

export class CamerasUdc extends LayerCardPrototype {
  constructor() {
    super({
      label: 'Камеры УДС',
      linkToPreview: 'CamerasUdc.svg',
      permissionToShowCard: 'layer_permissions.can_view_cameras_uds_layer',
    });
  }
}
