import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button as ButtonUI } from 'UI/common/components/Button/Button';
import { UserOutlineIcon } from 'UI/custom/icons/actionIcons/UserOutlinedIcon';
import { iconSizesKit } from 'styles/utils/iconSizesKit';
import { StateModel } from 'store/reducers';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Styled } from './style/UserSettings.styled';
import { authenticationActions } from 'store/reducers/global/auth/auth.actions';

const { HeaderItem, UserInfo, OptionMenu, HeaderTitle, MainWrap, LocalHeaderNotification } = Styled;

const UserSettings = () => {
  const user = useSelector((state: StateModel) => state.auth.user);
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(authenticationActions.userLogout());
    window.location.reload();
  }, [dispatch]);

  const username = user ?? localStorage.getItem('user');

  if (username) {
    return (
      <HeaderItem>
        <UserInfo>
          <MainWrap>
            <HeaderTitle>{localStorage.getItem('user') ?? 'Пользователь'}</HeaderTitle>
            <LocalHeaderNotification>
              <ButtonUI icon={<UserOutlineIcon />} rounded type={ButtonType.unbounded} iconSize={iconSizesKit.huge} />
            </LocalHeaderNotification>
          </MainWrap>
          <OptionMenu onClick={handleLogout}>Выйти</OptionMenu>
        </UserInfo>
      </HeaderItem>
    );
  } else {
    return <Redirect to={'/guest/login'} />;
  }
};

export default UserSettings;
