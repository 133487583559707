import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import _ from 'underscore';
import { Moment } from 'moment';
// @ts-ignore
import { saveAs } from 'file-saver';
import { postToURLSingle } from 'api/postToURLSingle';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { useDispatch } from 'react-redux';
import { CamerasList, TypesMasks } from './TrafficIntenisty.model';
import { AnyObject } from 'types/enums/general/general.model';
import { TrafficIntensity } from './TrafficIntensity';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { CamerasService, ReportsService } from 'generated/api/api';

const TrafficIntensityContainer = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [date, setDate] = useState<string>('');
  const [formatReport, setFormatReport] = useState<string>('');
  const [selectedCamera, setSelectedCamera] = useState<Array<number>>([]);
  const [selectedTypeMask, setSelectedTypeMask] = useState<Array<string>>([]);
  const [selectedMask, setSelectedMask] = useState<Array<string>>([]);
  const [camerasData, setCamerasData] = useState([]);
  const [typeMasksData, setTypeMasksData] = useState([]);
  const [masksData, setMasksData] = useState<Array<JSX.Element>>([]);
  const [data, setData] = useState<AnyObject>({});

  let extension =
    formatReport === 'xls' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/html';

  const selectDate = (date: Moment | null, dateString: string) => {
    setDate(dateString);
  };

  useEffect(() => {
    try {
      ReportsService.reportsTrafficIntensityRetrieve().then((res) => {
        // @ts-ignore TODO несоответствие интерфейса DynamicFilter из api.ts Тех-долг-FRONTEND #5590
        setTypeMasksData(res.filters.detection_mask_types.widget_data);
        setData(res);
      });
    } catch (e) {
      if (_.isObject(e) && e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: message,
          })
        );
      }
    }
  }, [dispatch]);

  const handleSelectCamera = useCallback(() => {
    if (!camerasData.length) {
      CamerasService.camerasList().then((response: AnyObject) => {
        const options = response.results
          ? response?.results.map((el: CamerasList) => (
              <Option key={el.id} value={el.id}>
                {el.address}
              </Option>
            ))
          : null;
        setCamerasData(options);
      });
    }
  }, [Option, camerasData]);

  const handleSelectMask = useCallback(() => {
    if (selectedTypeMask.length && selectedCamera.length) {
      CamerasService.camerasMasksList().then((response: AnyObject) => {
        const res = response.results;
        let cameras: any[] = [];
        let options: any[] = [];
        for (const prop of res) {
          selectedCamera.forEach((el) => {
            if (prop.camera === el) cameras.push(prop);
          });
        }
        for (const prop of cameras) {
          selectedTypeMask.forEach((el) => {
            if (prop.mask_type === el) options.push(prop);
          });
        }
        const masksOptions = options.map((el: any) => (
          <Option key={el.id} value={el.id}>
            {el.name}
          </Option>
        ));
        setMasksData(masksOptions);
      });
    }
  }, [Option, selectedCamera, selectedTypeMask]);

  const typeMasks = useMemo(
    () =>
      typeMasksData.map((el) => (
        <Option key={el} value={el}>
          {TypesMasks[el]}
        </Option>
      )),
    [Option, typeMasksData]
  );

  const prepareDataForReport = useCallback(() => {
    return {
      ...data,
      filters: {
        camera_ids: {
          ...data.filters.camera_ids,
          value: selectedCamera,
        },
        detection_mask_types: {
          ...data.filters.detection_mask_types,
          value: selectedTypeMask,
        },
        detection_mask_ids: {
          ...data.filters.detection_mask_ids,
          value: selectedMask,
        },
        date: {
          ...data.filters.date,
          value: [date],
        },
      },
    };
  }, [data, date, selectedCamera, selectedMask, selectedTypeMask]);

  const uploadFrom = useCallback(async (): Promise<any> => {
    try {
      const changedData = prepareDataForReport();
      const res = await postToURLSingle.postDataReports('/reports/traffic_intensity', changedData, extension, true);
      const blob = new Blob([res], {
        type: extension,
      });
      saveAs(blob, `report.${formatReport}`);
    } catch (e) {
      if (_.isObject(e) && e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: message,
          })
        );
      }
    }
  }, [dispatch, extension, formatReport, prepareDataForReport]);

  return (
    <TrafficIntensity
      handleSelectCamera={handleSelectCamera}
      handleSelectMask={handleSelectMask}
      setCamera={setSelectedCamera}
      setTypeMask={setSelectedTypeMask}
      setMask={setSelectedMask}
      camerasData={camerasData}
      typeMasks={typeMasks}
      masksData={masksData}
      setFormatReport={setFormatReport}
      sendData={uploadFrom}
      selectDate={selectDate}
      formatReport={formatReport}
    />
  );
};

export default TrafficIntensityContainer;
