import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { message } from 'antd';

import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import PrepareModal from 'UI/common/components/PrepareModal/PrepareModal';
import { Application, ApplicationsService, StatusF30Enum, TeamOfWorkers } from 'generated/api/api';
import { submitObjectSagaErrorResponse } from 'store/sagas/app/postObjectSagaErrorResponse';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { Layers } from 'types/enums/map/layers/Layers';
import { initUpdateMapSelectedObjectSaga } from 'store/sagas/map/mapSelectedObjectSaga';
import { MapObjects } from 'types/enums/map/MapObjects.model';

const BrigadeButton = ({ selectedMapItem, data }: { selectedMapItem: any; data: Application }) => {
  const dispatch = useDispatch();

  const selectedObjectData = selectedMapItem?.data[0];
  const currentBrigade = data?.appointed_team;
  const urlData = selectedObjectData?.urlData;

  const [brigades, setBrigades] = useState<TeamOfWorkers[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { results } = await ApplicationsService.applicationsTeamsOfWorkersList();
        setBrigades(results);
      } catch (e) {
        message.error('Что-то пошло не так при загрузке бригад');
      }
    })();
  }, []);

  const buttonText = useMemo(
    () => (currentBrigade ? 'Назначить другую бригаду' : 'Назначить бригаду'),
    [currentBrigade]
  );
  const selectedBrigade = useMemo(
    (): TeamOfWorkers | undefined => brigades.find((el: TeamOfWorkers) => el.id === currentBrigade),
    [currentBrigade, brigades]
  );
  const options = useMemo(
    () => brigades.map((el: TeamOfWorkers) => ({ value: el.id.toString(), label: el.name })),
    [brigades]
  );
  const selectorProps = useMemo(
    () => ({
      options: options,
      placeholder: 'Бригада',
      selected: selectedBrigade?.id?.toString() ?? '',
    }),
    [options, selectedBrigade?.id]
  );

  const handleInitClose = useCallback(
    (res) => {
      dispatch(
        mapboxDataActions.reloadMapboxLayerDataByRelatedName(MapObjects.brigadeApplications, Layers.brigadeApplications)
      );
      dispatch(initUpdateMapSelectedObjectSaga(res));
    },
    [dispatch]
  );

  const handleSuccess = useCallback(
    (appointed_team_id) => {
      const appointed_team = appointed_team_id ?? null;
      const id = data.id;
      const currentStatus = data?.status;
      if (currentStatus) {
        const notAvailableStatus = [StatusF30Enum.DONE, StatusF30Enum.CLOSED, StatusF30Enum.CANCELED];
        const availableCurrentStatus = notAvailableStatus.includes(currentStatus);

        if (!availableCurrentStatus) {
          dispatch(
            submitObjectSagaErrorResponse({
              url: urlData,
              data: { id, appointed_team, status: currentStatus },
              initClose: handleInitClose,
            })
          );
        }
      }
    },
    [data.id, data.status, dispatch, handleInitClose, urlData]
  );

  return (
    <PrepareModal
      handleSuccess={handleSuccess}
      selectorProps={selectorProps}
      buttonText={buttonText}
      buttonType={ButtonType.primaryReverse}
      title={'Изменение бригады'}
    />
  );
};

export default BrigadeButton;
