import React, { ExoticComponent, ReactNode } from 'react';
import { MapObjects } from 'types/enums/map/MapObjects.model';

const LayersDataAccessorContainer = React.lazy(
  () => import('components/Application/BaseModules/Map/MapDisplayMode/LayersDataAccessors/LayersDataAccessorContainer')
);

interface ILayerDataAccessorPrototypeProps {
  urlData?: string;
  dataAccessComponent?: ExoticComponent;
  selectedChild?: any;
  dataKeyForIds?: string;
  label: string;
  selectedType: string;
  isSelectedEditable?: boolean;
  actionButtons?: React.FC[];
  hideRefreshButton?: boolean;

  [K: string]: any;
}

export interface IFilterData {
  field: string;
  value: string;
}

export class LayerDataAccessorPrototype {
  static selectedType: string | null = null;
  static filterData: IFilterData | null = null;
  dataAccessComponent: ReactNode;
  label: string;
  selectedType: MapObjects | string | undefined;
  actionButtons: React.FC[];
  selectedChild: any;
  dataKeyForIds: any;
  isSelectedEditable: boolean;
  hideRefreshButton: boolean;
  additionalProps: any;

  constructor({
    urlData,
    dataAccessComponent = LayersDataAccessorContainer,
    selectedChild = null,
    dataKeyForIds = 'id',
    label,
    selectedType,
    filterData,
    isSelectedEditable = false,
    actionButtons = [],
    hideRefreshButton = false,
    ...other
  }: ILayerDataAccessorPrototypeProps) {
    LayerDataAccessorPrototype.selectedType = selectedType;
    LayerDataAccessorPrototype.filterData = filterData;
    this.dataAccessComponent = dataAccessComponent;
    this.selectedChild = selectedChild;
    this.dataKeyForIds = dataKeyForIds;
    this.additionalProps = other;
    this.label = label;
    this.actionButtons = actionButtons;
    this.isSelectedEditable = isSelectedEditable;
    this.hideRefreshButton = hideRefreshButton;
    this.additionalProps = other;
  }

  getIfSelectedEditable() {
    return this.isSelectedEditable;
  }

  getDataKeyForIds() {
    return this.dataKeyForIds;
  }

  getSelectedChildType() {
    return this.selectedChild;
  }

  getSelectedObjectType() {
    return LayerDataAccessorPrototype.selectedType;
  }

  getDataAccessorLabel(): string | undefined {
    return this.label;
  }

  getDataAccessor(): LayerDataAccessorPrototype {
    return this;
  }

  getDataAccessorRepresentationComponent(): ReactNode {
    return this.dataAccessComponent;
  }

  getActionButtons(): React.FC[] | [] {
    return this.actionButtons;
  }

  async getData(): Promise<any[] | { results: any[]; count: number } | null> {
    return null;
  }

  async getModel(): Promise<any[] | null> {
    return null;
  }

  getAccessorCallbacks() {
    return {};
  }

  getCertainFilterNamesCollectionForTable(): Array<string> {
    return [];
  }

  get filterData(): IFilterData | null {
    return LayerDataAccessorPrototype.filterData;
  }
}
