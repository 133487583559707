import React from 'react';
import { useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import SiderbarContainer from 'UI/common/components/Sidebar/SiderbarContainer';
import { Styled } from './SaiderContainerOnMap.styled';

const { SiderContainerOnMap } = Styled;

export const SaiderContainerInMap = () => {
  const isSidebarOpened = useSelector((state: StateModel) => state.app.sidebarIsOpened);
  return (
    <SiderContainerOnMap collapsed={isSidebarOpened} collapsible trigger={null} width={'270px'} collapsedWidth={60}>
      <SiderbarContainer />
    </SiderContainerOnMap>
  );
};
