export enum ApplicationsLabels {
  create = 'Создание новой заявки',
  main = 'Заявки',
  applications = 'ДУТССД',
  teams_of_masters = 'Мастера',
  expendable_materials = 'Расходные материалы',
  materials = 'Материалы',
  types = 'Нормативные сроки на устранение и тип заявки',
  teams_of_workers = 'Рабочие бригады',
  photos = 'Фото',
  tasks = 'Задачи',
  task_objects = 'Объекты задач',
  responsible_persons = 'Ответственные лица',
  sources = 'Источник заявки',
  childrenTasks = 'Подзадачи',
  semifinished_objects = 'Объекты для заявок',
}
