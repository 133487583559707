import React, { FC, useEffect } from 'react';
import { Styled } from './style/HomePage.styled';
import WidgetsGrid from './components/WidgetsGrid/WidgetsGrid';
import { useDispatch } from 'react-redux';
import { widgetsActions } from 'store/reducers/widgets/widgets.actions';

const { HomePageStyled } = Styled;

const HomePage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(widgetsActions.changePanelVisible(false));
      dispatch(widgetsActions.changeEditWidget(null));
    };
  }, [dispatch]);

  return (
    <HomePageStyled>
      <WidgetsGrid />
    </HomePageStyled>
  );
};

export default HomePage;
