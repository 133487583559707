import React, { useCallback, useMemo, useState } from 'react';

import { Spin, Popover } from 'antd';

import { RoadSignProps } from './model/RoadSign.model';
import { RoadSignImage, RoadSignImageContainer } from './style/RoadSign.styled';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { mapMarkersRoadSingGroupActions } from 'store/reducers/map/mapMarkersRoadSingGroup/mapMarkers.actions';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import _ from 'underscore';

const RoadSignWrapper = ({ sign, onClick, azimuthGroupRotate }: RoadSignProps) => {
  const dispatch = useDispatch();
  const { selectingSing } = useSelector((state: StateModel) => state.mapMarkersRoadSingGroup.selectingSing);
  const [showPopover, setShowPoppover] = useState<boolean>(false);
  const { id, road_sign_group, road_sign_image, road_sign_group_name, road_sign_type_name, azimuth } = sign ?? {};
  const titlePopover = `Желаете перенести дорожный знак '${road_sign_type_name}' из группы '${road_sign_group_name}' в другую группу?`;
  const differenceAzimuth = (azimuth ?? 0) - azimuthGroupRotate;
  const styled = useMemo(() => ({ transform: `rotate(${differenceAzimuth}deg)`, height: '100%' }), [differenceAzimuth]);

  const cancelSelectingSing = useCallback(() => {
    dispatch(mapMarkersRoadSingGroupActions.removeMapMarkerRoadSingGroup());
    setShowPoppover(false);
  }, [dispatch]);
  const moveRoadSing = useCallback(() => {
    if (id && road_sign_group) {
      dispatch(mapMarkersRoadSingGroupActions.addMapMarkerRoadSingGroup({ selectingSing: sign }));
      setShowPoppover(false);
    }
  }, [dispatch, id, road_sign_group, sign]);

  const contentPopover = useMemo(
    () => (
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button type={ButtonType.danger} width="45%" onClick={moveRoadSing}>
          Перенести занак
        </Button>
        <Button type={ButtonType.primary} width="45%" onClick={cancelSelectingSing}>
          Отмена
        </Button>
      </div>
    ),
    [cancelSelectingSing, moveRoadSing]
  );

  const isSelectedSing = !_.isEmpty(selectingSing);

  if (!road_sign_image) return null;

  return (
    <RoadSignImageContainer onDoubleClick={onClick} style={styled}>
      <Popover
        content={contentPopover}
        title={titlePopover}
        trigger="contextMenu"
        visible={showPopover}
        onVisibleChange={setShowPoppover}
      >
        <RoadSignImage
          src={road_sign_image}
          loader={<Spin />}
          draggable={false}
          contextMenu={'Открыть в справочнике'}
          className={isSelectedSing ? '' : 'need_warning_border'}
        />
      </Popover>
    </RoadSignImageContainer>
  );
};

export default RoadSignWrapper;
