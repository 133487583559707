import React, { PropsWithChildren } from 'react';
import { Styled } from './styles/Panel.styled';
import { PanelProps } from './model/Panel.model';

const { LocalWrapperPrimary, LocalWrapperSecondary } = Styled;

const Panel = ({ children, wrapperType = 'primary', ...other }: PropsWithChildren<PanelProps>) => {
  const ResultTag = wrapperType === 'primary' ? LocalWrapperPrimary : LocalWrapperSecondary;
  return <ResultTag {...other}>{children}</ResultTag>;
};

export default Panel;
