import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RoadsQualityService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class QualitySegments extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'segment_analysis_values',
      filterLabel: 'Анализ качества дорог',
      selectedObjectName: MapObjects.qualitySegmentComplex,
    });
  }

  async uploadData() {
    return await RoadsQualityService.roadsQualitySegmentAnalysisValuesList();
  }

  async readRecord(id) {
    return await RoadsQualityService.roadsQualitySegmentAnalysisValuesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.segmentsAnalystValues);
  }
}
