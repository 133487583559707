import React, { Dispatch, MutableRefObject, PropsWithChildren, useRef } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { compose } from 'redux';

import withContentPadding from 'HOC/withContentPadding';
import { StateModel } from 'store/reducers';
import { directoryHistoryActions } from 'store/reducers/directory/actions/directoryHistoryActions';
import { getLastHistoryRecord } from 'store/reducers/directory/directoryHistory';
import { TypeDisplayedDirectoryEnum, TypeOfRevertEnum } from 'types/enums/roles/inlineHistory.model';
import _ from 'underscore';

import { AdministrationBuilderProps } from './AdministrationBuilder.model';
import { DirectoriesEnum } from './enums/DirectoriesEnum';
import PermissionsPanel from './parts/PermissionsPanel';
import DataWithCompendium from './Templates/DataWithCompendium';
import PathWatcherContainer from './utils/PathWatcherContainer';
import { useHistory, useLocation } from 'react-router-dom';
import securedAxiosInstance from 'services/axiosInstances/axiosSecured';
import { theme } from 'styles/themes/rootTheme';
import { registeredAdministrationUrls } from 'registrators/administrationPage/administratonPageRegistrator';

const { revertToInlineHistoryRecordByType } = directoryHistoryActions;

interface IRoureRef {
  current: number;
}
const AdministrationBuilder = React.memo((props: PropsWithChildren<AdministrationBuilderProps>) => {
  const {
    params: { folder },
  } = useRouteMatch<{ folder: DirectoriesEnum }>();
  const { allInlineHistory, lastInHistory, revertToInlineHistoryRecordByTypeHandler } = props;
  const history = useHistory();
  const routeRef: MutableRefObject<IRoureRef | undefined> = useRef();
  const { pathname } = useLocation();

  const { data, model, urlData } = lastInHistory ?? {};

  let ResolvedDirectory = null;

  if (!_.isEmpty(allInlineHistory)) {
    const displayedDirectory = _.last(allInlineHistory) || null;
    if (displayedDirectory?.type && model) {
      switch (displayedDirectory.type) {
        case TypeDisplayedDirectoryEnum.main:
          ResolvedDirectory = (
            <DataWithCompendium
              lastInHistory={lastInHistory}
              folder={folder}
              data={data}
              model={model}
              urlData={urlData}
              history={history}
              axiosInstance={securedAxiosInstance}
              registeredAdministrationUrls={registeredAdministrationUrls}
              theme={theme}
              ref={routeRef}
              pathname={pathname}
            />
          );
          break;
        case TypeDisplayedDirectoryEnum.mainObject:
          ResolvedDirectory = (
            <PermissionsPanel
              revertToInlineHistoryRecordByType={revertToInlineHistoryRecordByTypeHandler}
              data={data}
              model={model}
              urlData={urlData}
            />
          );
          break;
        default:
          break;
      }
    }
  }

  return (
    <>
      {ResolvedDirectory}
      <PathWatcherContainer />
    </>
  );
});

const mapStateToProps = (state: StateModel) => ({
  allInlineHistory: state.directoryHistory,
  lastInHistory: getLastHistoryRecord(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  revertToInlineHistoryRecordByTypeHandler: (type: TypeOfRevertEnum, typeName: TypeDisplayedDirectoryEnum) =>
    dispatch(revertToInlineHistoryRecordByType(type, typeName)),
});

const enhance = compose(withContentPadding, connect(mapStateToProps, mapDispatchToProps));

export default enhance(AdministrationBuilder);
