import { MainStyled } from './MainStyles.styled';
import { Styled as BaseMapStyled } from './Application/BaseModules/Map/mapModuleBaseStyles.styled';
import { Styled as BaseDirectoryStyled } from './Application/BaseModules/Directory/directoryModuleBaseStyles.styled';
import { Styled as BaseHomePageStyled } from './Application/BaseModules/HomePage/homePageModuleBaseStyles';
import { Styled as ApplicationMixinsStyled } from '../styles/styleMixins/styleMixins.styled';

export const baseStyles = {
  main: MainStyled,
  mixins: ApplicationMixinsStyled,
  modules: {
    map: BaseMapStyled,
    directory: BaseDirectoryStyled,
    homePage: BaseHomePageStyled,
  },
};
