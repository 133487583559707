import {
  CHANGE_RASTER_STYLE,
  CLOSE_ALL_ADDITIONAL_PANELS,
  SET_LAYER_DATA_ACCESSOR,
  SET_LAYER_SETTINGS_TO_EDIT,
  SET_LAYER_SETTINGS_TYPE,
  SET_MAIN_MAP_PANEL_CONTEXT,
  SET_MAP_CONTEXT_MENU,
  SET_RULER_MODE,
  SET_SHOW_CREATE_MODAL,
  TOGGLE_LAYER_DATA_ACCESSOR_VISIBLE,
  TOGGLE_LAYER_SETTINGS_VISIBILITY,
  TOGGLE_LAYERS_GALLERY_VISIBILITY,
  TOGGLE_MAIN_PANEL_COLLAPSE,
  TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE,
  TOGGLE_RASTER_ENABLED,
  TOGGLE_TIME_PLAYER_VISIBLE,
  CLEAR_MAP_CONTEXT_MENU,
  TOGGLE_MAP_LEGEND_INFO_VISIBLE,
  TOGGLE_VISIBLE_UNVISIBLE_FILTER,
  TOGGLE_TOOLTIP,
  SET_COORD_FOR_PATH,
  DELETE_PATH,
  SET_SELECTED_AREA_MODE,
  SET_SELECTED_AREA,
  TOGGLE_WIDGETS_EDIT_PANEL,
} from '../mapPanels';

export const closeAllAdditionalPanels = () => ({
  type: CLOSE_ALL_ADDITIONAL_PANELS,
});

export const clearMapContextMenu = () => {
  return {
    type: CLEAR_MAP_CONTEXT_MENU,
  };
};

export const setMainMapPanelContent = (type) => ({
  type: SET_MAIN_MAP_PANEL_CONTEXT,
  payload: type,
});

export const selectEditorForLayer = (layer) => ({
  type: SET_LAYER_SETTINGS_TO_EDIT,
  payload: layer,
});

export const setSelectedLayerSettingsType = (type) => ({
  type: SET_LAYER_SETTINGS_TYPE,
  payload: type,
});

export const setLayerDataAccessor = (type) => ({
  type: SET_LAYER_DATA_ACCESSOR,
  payload: type,
});

export const setShowCreateModal = (value) => ({
  type: SET_SHOW_CREATE_MODAL,
  payload: {
    value,
  },
});

export const toggleLayersGalleryVisibility = (newStatus) => ({
  type: TOGGLE_LAYERS_GALLERY_VISIBILITY,
  payload: {
    newStatus,
  },
});

export const toggleVisibleUnvisibleFilter = (newStatus) => ({
  type: TOGGLE_VISIBLE_UNVISIBLE_FILTER,
  payload: {
    newStatus,
  },
});

export const toggleRasterTilesEnabled = () => ({
  type: TOGGLE_RASTER_ENABLED,
});

export const changeRasterTileStyle = (tileUrl) => ({
  type: CHANGE_RASTER_STYLE,
  payload: {
    tileUrl,
  },
});

export const toggleMainPanelCollapse = (newStatus) => ({
  type: TOGGLE_MAIN_PANEL_COLLAPSE,
  payload: {
    newStatus,
  },
});

export const toggleLayerSettingsVisibility = (newStatus) => ({
  type: TOGGLE_LAYER_SETTINGS_VISIBILITY,
  payload: {
    newStatus,
  },
});

export const toggleTimePlayerVisibility = (newStatus) => ({
  type: TOGGLE_TIME_PLAYER_VISIBLE,
  payload: {
    newStatus,
  },
});

export const toggleLayerDataAccessorVisibility = (newStatus) => ({
  type: TOGGLE_LAYER_DATA_ACCESSOR_VISIBLE,
  payload: { newStatus },
});

export const toggleMapSelectedObjectInfoVisibility = (newStatus) => ({
  type: TOGGLE_MAP_SELECTED_OBJECT_INFO_VISIBLE,
  payload: { newStatus },
});

export const toggleLegend = (newStatus) => ({
  type: TOGGLE_MAP_LEGEND_INFO_VISIBLE,
  payload: {
    newStatus,
  },
});

export const setRulerMode = (mode) => ({
  type: SET_RULER_MODE,
  payload: {
    mode,
  },
});

export const setMapContextMenu = (args) => {
  if (args?.x && args?.y && args?.visible) {
    const { x, y, visible, clickedObjectName, selected, coordinate, objectLayerName } = args;
    return {
      type: SET_MAP_CONTEXT_MENU,
      payload: {
        x,
        y,
        visible,
        clickedObjectName,
        coordinate,
        selected,
        objectLayerName,
      },
    };
  } else {
    return {
      type: SET_MAP_CONTEXT_MENU,
      payload: null,
    };
  }
};

export const toggleTooltip = (id, coordinate) => ({
  type: TOGGLE_TOOLTIP,
  payload: { id, coordinate },
});

export const setCoordForPath = (dataCollection, id, coordEndTooltip, coordStartTooltip) => ({
  type: SET_COORD_FOR_PATH,
  payload: { dataCollection, id, coordEndTooltip, coordStartTooltip },
});

export const deletePath = (id) => ({
  type: DELETE_PATH,
  payload: id,
});

export const setGlobalPrintMode = (mode) => ({
  type: SET_SELECTED_AREA_MODE,
  payload: mode,
});

export const setSelectArea = (data) => ({
  type: SET_SELECTED_AREA,
  payload: {
    data,
  },
});

export const toggleWidgetsEditPanel = (newStatus) => ({
  type: TOGGLE_WIDGETS_EDIT_PANEL,
  payload: {
    newStatus,
  },
});
