import { MapMode } from '../../../../../../types/enums/map/MapMode.model';

interface LayerSchemaConstructorArgs {
  id: string;
  mapMode?: MapMode;
  layerToRender: any;
  additionalParameters?: any;
  possibleLayersToRender?: any[];
}

export class LayerSchemaPrototype {
  id: string;
  mapMode: MapMode;
  layerToRender: any;
  additionalParameters: any = {};
  possibleLayersToRender?: any[];

  constructor({
    layerToRender,
    id,
    mapMode = MapMode.display,
    possibleLayersToRender,
    ...other
  }: LayerSchemaConstructorArgs) {
    this.layerToRender = layerToRender;
    this.id = id;
    this.mapMode = mapMode;
    // На данный момент возможные слои для выбора дожны быть массивом с объектами, у которых ключ layer - это композит слой,
    // label - его название, которое будет отображаться у чекбоксов. Чекбоксы появятся только при выполнении условий,
    // указанных в LayerChangeControlsTabContainer.js
    this.possibleLayersToRender = possibleLayersToRender;
    this.additionalParameters = other;
  }

  getAdditionalParameters() {
    return this.additionalParameters;
  }

  getLayerToRender() {
    return this.layerToRender;
  }

  getId() {
    return this.id;
  }

  getPossibleLayersToRender() {
    return this.possibleLayersToRender;
  }

  getMapMode() {
    return this.mapMode;
  }
}
