import securedAxiosInstance from 'services/axiosInstances/axiosSecured';

class DirectoryService {
  static getDirectoryData = async (url: string, params: any) => {
    const { data: modelResponse } = await securedAxiosInstance.options(url, params);
    const { data: dataResponse } = await securedAxiosInstance.get(url, params);

    return {
      model: modelResponse,
      data: dataResponse,
    };
  };

  static getRenderType = (data: any) => (data.hasOwnProperty('results') ? 'ag_grid' : 'form');

  static getRootBreadcrumbIfExist = (url: string) => {
    switch (url) {
      case 'roads':
        // TODO temp solution Тех-долг-FRONTEND #5804
        return {
          label: 'Дороги',
          key: 'Road List',
          url,
          params: null,
          sider: undefined,
        };
      case 'users':
        return {
          label: 'Пользователи',
          key: 'User List',
          url,
          params: null,
          sider: undefined,
        };
      default:
        return undefined;
    }
  };
}

export default DirectoryService;
