import styled from 'styled-components/macro';
import { Modal } from 'antd';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { OpacityColor } = styleMixins.color;

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    border-radius: ${(props) => props.theme.decoration.largeBorderRadius};
  }

  & .ant-modal-title {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSettings.default};
    line-height: 120%;
    color: ${(props) => props.theme.colors.defaultDark};
  }

  & .ant-modal-header {
    padding: 15px 16px;
    border-radius: ${(props) => props.theme.decoration.largeBorderRadiusOnlyTop};
    border-bottom: none;
    box-shadow: 0px 2px 8px ${(props) => OpacityColor(props.theme.colors.primary, 0.12)};
  }

  & .ant-modal-body {
    padding: 16px;
  }

  & .ant-modal-footer {
    border-top: none;
    padding: 16px;
    box-shadow: 0px 2px 8px ${(props) => OpacityColor(props.theme.colors.primary, 0.12)};

    & button {
      width: 100%;
    }
  }
`;

export const Styled = {
  StyledModal,
};
