import styled from 'styled-components/macro';

const LocalMainWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${(props) => props.theme.decoration.largeBorderRadius};
  justify-content: space-between;
  height: 48px;
  background-color: ${(props) => props.theme.colors.defaultLighter};
`;

const LocalHeaderButton = styled.div`
  display: grid;
  grid-template-rows: 40px;
`;

const LocalMainInner = styled.section`
  display: flex;

  & ${LocalHeaderButton}:not(:last-child) {
    margin-right: 8px;
  }
`;

export const Styled = { LocalMainWrapper, LocalMainInner, LocalHeaderButton };
