import { useLocation } from 'react-router';

/**
 * Хук для получения квери параметров из урла
 * использование:
 * query = useQuery()
 * query.get('<params_name>')
 */

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
