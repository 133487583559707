import { CompositeLayer } from '@deck.gl/core';
import { PolygonLayer, TextLayer } from '@deck.gl/layers';
import wkt from 'wkt';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { calculatePolygonPathCenterCoords } from 'services/map/calculatePolygonPathCenterCoords';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { DSelector } from 'services/map/Dselector/DSelector';

class CityDistrictsCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    if (this.props.selectedObject?.selectedObject) {
      this.setState({
        data: props,
        selectedObject: this.props.selectedObject?.selectedObject,
      });
    } else {
      this.setState({
        data: props,
      });
    }
  }
  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.citydistricts;
        if (event.sourceLayer.id.includes('CityDistricts')) selectedObjectType = MapObjects.citydistricts;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }
  renderLayers() {
    if (!this.props.relatedData) return [];
    return [
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'city_districts-polygon' }), {
        data: this.props.relatedData.city_districts,
        stroked: true,
        filled: true,
        pickable: true,
        wireframe: true,
        getLineColor: [47, 128, 237],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getFillColor: (d) => {
          const opacity =
            d?.id === this.state?.selectedObject?.id || d?.id === this.state?.selectedObject?.object?.id ? 200 : 100;
          return [47, 128, 237, opacity];
        },
        getPolygon: (d) => {
          if (d.polygon) {
            const parsed = wkt.parse(d.polygon);
            return parsed
              ? parsed.coordinates[0]
              : [
                  [0, 0],
                  [0.1, 0.1],
                  [0.11, 0.11],
                ];
          } else {
            return [
              [0, 0],
              [0.1, 0.1],
              [0.11, 0.11],
            ];
          }
        },
        updateTriggers: {
          getFillColor: this.state.selectedObject,
        },
        getLineWidth: 50,
        lineJointRounded: true,
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'city_districts-text' }), {
        data: this.props.relatedData.city_districts,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d) => {
          return d?.name;
        },
        getPosition: (d) => {
          if (d.polygon) {
            const cordinate = calculatePolygonPathCenterCoords(d.polygon);
            return [cordinate[0], cordinate[1] + 0.00002];
          } else return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getSize: () => {
          return 20;
        },
        updateTriggers: {},
      }),
    ];
  }
}

CityDistrictsCompositeLayer.layerName = Layers.citydistricts;

export default CityDistrictsCompositeLayer;
