import React from 'react';
import Icon from '@ant-design/icons';

const FenceSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66675 17.5H3.33341V11.6667H6.66675H16.6667V17.5H18.3334V5.83333V4.16667V2.5H16.6667V3.33325V4.16667H3.33341V3.33333V2.5H1.66675V4.16667V5.83333V17.5ZM16.6667 5.83333H3.33341V10H16.6667V5.83333Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const Fence = (props) => <Icon component={FenceSVG} {...props} />;
