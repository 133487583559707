import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DSelector } from '../../../services/map/Dselector/DSelector';
import MonitoringUDSInfoCard from '../MonitoringUDSInfoCard';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { groupTypes } from 'registrators/map/itemContextMenuRegistrator/contentGroups';
import { initializeSingleObjectEditMode } from 'store/sagas/map/standaloneObjectEditableSaga';
import { Layers } from 'types/enums/map/layers/Layers';
import { AnyObject } from 'types/enums/general/general.model';
import _ from 'underscore';
import { DetectionsService, DetectionStatusEnum } from 'generated/api/api';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { StateModel } from 'store/reducers';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { mapboxDataActions } from '../../../store/reducers/map/mapboxData/mapboxData.actions';
import { IDefectText } from '../../../hooks/custom/useMonitoringDefectImage';

const MonitoringUDSItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }: AnyObject) => {
  const dispatch = useDispatch();

  const handleEditObject = useCallback(() => {
    dispatch(
      initializeSingleObjectEditMode(data, MapObjects.monitoringUds, Layers.monitoringUds, null, groupTypes.ADD_GROUP)
    );
  }, [data, dispatch]);

  const idItem = data.id;

  const handleRejectDetection = useCallback(
    async (comment?: string) => {
      try {
        await DetectionsService.detectionsPartialUpdate({
          id: idItem,
          body: {
            // @ts-ignore
            status: DetectionStatusEnum.NOT_RELEVANT,
            comment,
          },
        });
        dispatch(
          mapboxDataActions.refreshDataAfterDeleteObject({ name: MapObjects.monitoringUds }, Layers.monitoringUds, data)
        );
      } catch (e) {
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: createAlertMessage(e as AnyObject),
          })
        );
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
      }
    },
    [data, dispatch, idItem]
  );

  let currentObjectModel;
  // присвоит в currentObjectModel модель объекта, которую присылает бэк
  _.mapObject(
    useSelector((state: StateModel) => state?.mapboxData?.[Layers.monitoringUds]?.relatedData),
    (val, key) => {
      if (key?.includes('model') && key?.includes(selectedMapItem.type)) currentObjectModel = val;
    }
  );

  const defectPoint = useMemo(() => DSelector.getPositionFromPoint(data), [data]);

  const Descriptions: { key: string; value: string }[] = [];

  const availableFields = ['detection_type', 'violation_type_display_name', 'camera', 'road_kilometer'];

  /** добавит в Descriptions все переданные бэком поля перечисленные выше в availableFields */
  if (currentObjectModel) {
    _.mapObject(currentObjectModel, (val: any, key) => {
      if (availableFields.some((el) => el === key)) {
        let label;
        let description;
        if (val.choices) {
          description = val?.choices.find((el: any) => el.value === data[key])?.display_name ?? 'Н/Д';
        } else {
          description = data[key] ?? 'Н/Д';
        }
        label = val?.label;
        Descriptions.push({
          key: label,
          value: description,
        });
      }
    });
  }

  Descriptions.push({
    key: 'Координаты',
    value: `${defectPoint[1]} - ${defectPoint[0]}`,
  });

  const imageDefect = data.image_src;
  const defectName = `Дефект №${data.id} ${data.nearest_address ? `(${data.nearest_address})` : ''}`;

  const defectText: IDefectText = useMemo(
    () => ({
      time: data.created_at,
      violationType: data.violation_type_display_name,
      point: defectPoint,
    }),
    [data.created_at, data.violation_type_display_name, defectPoint]
  );

  return (
    <MonitoringUDSInfoCard
      defectName={defectName}
      bbox={data.bbox}
      defectText={defectText}
      title={`Дефект №${data.id}`}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      listOfDescriptions={Descriptions}
      handleEditObject={handleEditObject}
      handleRejectDetection={handleRejectDetection}
      image={imageDefect}
    />
  );
};

export default MonitoringUDSItemSelection;
