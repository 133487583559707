import { handleActions, Reducer } from 'redux-actions';
import { authenticationActions } from './auth.actions';
import { AuthState } from './auth.model';
import { MapReducer } from '../../reducers.model';

const { LOGOUT_USER, APPLY_PERMISSIONS, LOGIN_USER } = authenticationActions;

const initialState = {
  user: null,
  permissions: {
    directory: [],
    layers: [],
    reports: [],
    widgets: [],
  },
  initialized: false,
};

export const loginUser: Reducer<AuthState, any> = (state, action) => {
  return {
    ...state,
    user: action.payload,
    initialized: true,
  };
};

export const logoutUser: Reducer<AuthState, any> = (state) => {
  return {
    ...state,
    user: null,
    initialized: false,
  };
};

export const applyPermissions: Reducer<AuthState, any> = (state, action) => {
  return {
    ...state,
    permissions: {
      directory: action.payload?.directory ?? [],
      layers: action.payload?.layers ?? [],
      widgets: action.payload?.widgets ?? [],
      reports: action.payload?.reports ?? [],
    },
  };
};

const mapReducer: MapReducer<AuthState, any> = {
  [LOGIN_USER]: loginUser,
  [LOGOUT_USER]: logoutUser,
  [APPLY_PERMISSIONS]: applyPermissions,
};

export const authReducer: Reducer<AuthState, any> = handleActions(mapReducer, initialState);
