import { Action, ActionFunction0, ActionFunction1, ActionFunction2, ActionFunction3 } from 'redux-actions';
import { createAction } from 'redux-actions';
import { getRegisteredLayer } from 'registrators/map/layers/layersRegistrator';
import { DataAccessMethod } from 'types/enums/routes/request/DataAccessMethod.model';
import moment from 'moment';
import _ from 'underscore';
import { cropPath } from 'services/net/cropPath';
import { getFromURL } from 'api/getFromURL';
import { Layers } from 'types/enums/map/layers/Layers';
import { RelatedDataPrototype } from 'registrators/map/layers/description/relatedData/prototype/RelatedDataPrototype';
import { CommonDataResponse } from 'types/enums/routes/request/Response.model';
import { SET_MAP_CONTEXT_MENU } from '../mapPanels';
import securedAxiosInstance from 'services/axiosInstances/axiosSecured';
import { AnyObject } from 'types/enums/general/general.model';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { MainDataHttpPrototype } from 'registrators/map/layers/description/mainData/prototype/MainDataHttpPrototype';
import { MainDataSocketPrototype } from 'registrators/map/layers/description/mainData/prototype/MainDataSocketPrototype';
import { Dispatch } from 'react';

class MapboxDataActions {
  // TODO add additional type check Тех-долг-FRONTEND #5648
  /** action types */
  public readonly prefix: string = 'APPLICATIONS';
  public readonly ADD_MAPBOX_DATA: string = `${this.prefix}.ADD_MAPBOX_DATA`;
  public readonly PUSH_TO_MAPBOX_DATA: string = `${this.prefix}.PUSH_TO_MAPBOX_DATA`;
  public readonly PUSH_ARRAY_TO_MAPBOX_DATA: string = `${this.prefix}.PUSH_ARRAY_TO_MAPBOX_DATA`;
  public readonly PUSH_TO_MAPBOX_RELATED_DATA: string = `${this.prefix}.PUSH_TO_MAPBOX_RELATED_DATA`;
  public readonly PUSH_ARRAY_TO_MAPBOX_RELATED_DATA: string = `${this.prefix}.PUSH_ARRAY_TO_MAPBOX_RELATED_DATA`;
  public readonly ADD_MAPBOX_RELATED_DATA: string = `${this.prefix}.ADD_MAPBOX_RELATED_DATA`;
  public readonly CLONE_MAPBOX_RELATED_DATA: string = `${this.prefix}.CLONE_MAPBOX_RELATED_DATA`;
  public readonly RESET_RELATED_DATA_FROM_CLONE_RELATED_DATA: string = `${this.prefix}.RESET_RELATED_DATA_FROM_CLONE_RELATED_DATA`;
  public readonly ADD_MAPBOX_MULTIPLE_RELATED_DATA: string = `${this.prefix}.ADD_MAPBOX_MULTIPLE_RELATED_DATA`;
  public readonly REPLACE_CERTAIN_FIELD_IN_RELATED_DATA: string = `${this.prefix}.REPLACE_CERTAIN_FIELD_IN_RELATED_DATA`;
  public readonly REPLACE_ALL_MAPBOX_RELATED_DATA: string = `${this.prefix}.REPLACE_ALL_MAPBOX_RELATED_DATA`;
  public readonly REPLACE_ALL_MAPBOX_SIMPLY_DATA: string = `${this.prefix}.REPLACE_ALL_MAPBOX_SIMPLY_DATA`;
  public readonly RESET_MAPBOX_STORE: string = `${this.prefix}.RESET_MAPBOX_STORE`;
  public readonly SET_ACTIVE_DATA_REPLACING_FORCED_STATUS: string = `${this.prefix}.SET_ACTIVE_DATA_REPLACING_FORCED_STATUS`;
  public readonly SET_DISABLE_DATA_REPLACING_FORCED_STATUS: string = `${this.prefix}.SET_DISABLE_DATA_REPLACING_FORCED_STATUS`;
  public readonly UPDATE_RELATED_DATA_SOCKET: string = `${this.prefix}.UPDATE_RELATED_DATA_SOCKET`;
  public readonly UPDATE_OBJECT_AFTER_CHANGE: string = `${this.prefix}.UPDATE_OBJECT_AFTER_CHANGE`;
  public readonly REPLACE_RELATED_DATA_BY_KEY: string = `${this.prefix}.REPLACE_RELATED_DATA_BY_KEY`;
  public readonly DELETE_RELATED_DATA_OBJECT_BY_KEY: string = `${this.prefix}.DELETE_RELATED_DATA_OBJECT_BY_KEY`;
  public readonly REFRESH_DATA_AFTER_DELETE_OBJECT: string = `${this.prefix}.REFRESH_DATA_AFTER_DELETE_OBJECT`;
  /** actions */
  // 0 attributes
  public resetRelatedDataFromCloneRelatedData: ActionFunction0<Action<any>> = createAction(
    this.RESET_RELATED_DATA_FROM_CLONE_RELATED_DATA
  );
  public resetMapboxStore: ActionFunction0<Action<null>> = createAction(this.RESET_MAPBOX_STORE);
  public setActiveMapboxDataReplacingForcedStatus: ActionFunction0<Action<null>> = createAction(
    this.SET_ACTIVE_DATA_REPLACING_FORCED_STATUS
  );
  public setDisableMapboxDataReplacingForcedStatus: ActionFunction0<Action<null>> = createAction(
    this.SET_DISABLE_DATA_REPLACING_FORCED_STATUS
  );
  // 1 attributes
  public addMapboxMultipleRelatedData: ActionFunction1<any, Action<any>> = createAction(
    this.ADD_MAPBOX_MULTIPLE_RELATED_DATA
  );
  // 2 attributes
  public addMapboxData: ActionFunction2<any, any, Action<any>> = createAction(this.ADD_MAPBOX_DATA);
  public cloneRelatedData: ActionFunction2<any, any, Action<any>> = createAction(
    this.CLONE_MAPBOX_RELATED_DATA,
    (selectedLayerName: any, selectedGroupOfObjectsType: any) => ({ selectedLayerName, selectedGroupOfObjectsType })
  );
  public pushToMapboxData: ActionFunction2<any, any, Action<any>> = createAction(this.PUSH_TO_MAPBOX_DATA);
  public replaceAllRelatedMapboxData: ActionFunction2<any, any, Action<any>> = createAction(
    this.REPLACE_ALL_MAPBOX_RELATED_DATA,
    (layerName: string, newDataArray: any[]) => ({ layerName, newDataArray })
  );
  public replaceAllSimplyMapboxData: ActionFunction2<any, any, Action<any>> = createAction(
    this.REPLACE_ALL_MAPBOX_SIMPLY_DATA
  );
  // 3 attributes
  public addMapboxRelatedData: ActionFunction3<any, any, any, Action<any>> = createAction(
    this.ADD_MAPBOX_RELATED_DATA,
    (parentKey: any, childKey: any, data: any) => ({ parentKey, childKey, data })
  );
  public updateObjectAfterChangeMethod: ActionFunction3<any, any, any, Action<any>> = createAction(
    this.UPDATE_OBJECT_AFTER_CHANGE,
    (RelatedDataObject: any, layerName: any, dataToPost: any) => ({ RelatedDataObject, layerName, dataToPost })
  );
  public refreshDataAfterDeleteObjectMethod: ActionFunction3<{ name: string }, Layers, AnyObject, Action<any>> =
    createAction(
      this.REFRESH_DATA_AFTER_DELETE_OBJECT,
      (RelatedDataObject: { name: string }, layerName: Layers, dataToPost: AnyObject) => ({
        RelatedDataObject,
        layerName,
        dataToPost,
      })
    );
  // public addMapboxRelatedData: ActionFunction1<any, Action<any>> = createAction(this.ADD_MAPBOX_RELATED_DATA);

  // noinspection JSUnusedLocalSymbols
  /** effects */
  public updateObjectAfterChange =
    (RelatedDataObject: RelatedDataPrototype, layerName: Layers, dataToPost: AnyObject) =>
    (dispatch: Dispatch<any>) => {
      dispatch(this.updateObjectAfterChangeMethod(RelatedDataObject, layerName, dataToPost));
    };
  public refreshDataAfterDeleteObject =
    (RelatedDataObject: { name: string }, layerName: Layers, dataToPost: AnyObject) => (dispatch: Dispatch<any>) => {
      dispatch(this.refreshDataAfterDeleteObjectMethod(RelatedDataObject, layerName, dataToPost));
    };
  public getDataForLayer = (layerName: Layers /*filteredIds: number[]*/) => (dispatch: any) => {
    const Layer = getRegisteredLayer(layerName);
    const mainData = Layer.getMainData();
    if (mainData) {
      mainData.forEach((mainDataElement: MainDataHttpPrototype | MainDataSocketPrototype) => {
        if (mainDataElement.dataAccessMethod === DataAccessMethod.httpGet && mainDataElement.urlData) {
          securedAxiosInstance.get(mainDataElement.urlData).then((response: any) => {
            return dispatch(this.addMapboxData(layerName, response?.data));
          });
        } else if (mainDataElement.dataAccessMethod === DataAccessMethod.httpPost && mainDataElement.urlData) {
          securedAxiosInstance
            .post(mainDataElement.urlData, {
              vehicle_id: [22],
              datetime_from: moment('10.05.2020 10:00Z', 'DD.MM.YYYY hh:mm').format(),
              datetime_to: moment('10.05.2020 11:00Z', 'DD.MM.YYYY hh:mm').format(),
            })
            .then((response: any) => {
              if (response) {
                const indexed = _.groupBy(response?.data?.results, 'vehicle_id');
                const result = [];
                for (let keys in indexed) {
                  if (indexed.hasOwnProperty(keys)) {
                    result.push(indexed[keys]);
                  }
                }
                return dispatch(this.addMapboxData(layerName, result));
              }
            });
        } else if (mainDataElement.dataAccessMethod === DataAccessMethod.httpGetAll && mainDataElement.urlData) {
          securedAxiosInstance.get(mainDataElement.urlData).then((response: any) => {
            if (response) {
              const indexed = _.groupBy(response?.data?.results, 'vehicle_id');
              const result = [];
              for (let keys in indexed) {
                if (indexed.hasOwnProperty(keys)) {
                  result.push(indexed[keys]);
                }
              }
              return dispatch(this.addMapboxData(layerName, result));
            }
          });
        } else if (mainDataElement.dataAccessMethod === DataAccessMethod.websocket) {
          // TODO refactor sockets Тех-долг-FRONTEND #5648
          // return dispatch(openSocket(mainData.urlData, layerName, keyForSubscribe, filteredIds, dispatch(addMapboxData)));
        }
      });
    }
    return { type: 'EMPTY' };
  };
  public reloadMapboxLayerData = (urlData: string, layerName: Layers) => (dispatch: any) => {
    setTimeout(async () => {
      if (layerName) {
        const relatedDescriptions = getRegisteredLayer(layerName).getRelatedData();
        const relatedRecord = _.isArray(relatedDescriptions)
          ? relatedDescriptions.find((el) => cropPath(el.urlData) === cropPath(urlData))
          : null;
        const { results } = await getFromURL.getDataFromURL(cropPath(urlData));
        dispatch({
          type: this.REPLACE_CERTAIN_FIELD_IN_RELATED_DATA,
          payload: {
            layerName,
            relatedKey: relatedRecord,
            data: results,
          },
        });
      }
    }, 1000);
  };
  //TODO убрать верхний метод после рефакторинга если он когда то будет к 2025 году Тех-долг-FRONTEND #5648
  public reloadMapboxLayerDataByClass =
    (RelatedDataObject: RelatedDataPrototype, layerName: Layers) => (dispatch: any) => {
      setTimeout(async () => {
        if (layerName) {
          try {
            const response: CommonDataResponse = await RelatedDataObject.uploadData();
            dispatch({
              type: this.REPLACE_CERTAIN_FIELD_IN_RELATED_DATA,
              payload: {
                layerName,
                relatedKey: RelatedDataObject,
                data: response?.results,
              },
            });
            dispatch({
              type: SET_MAP_CONTEXT_MENU,
              payload: null,
            });
          } catch (e) {}
        }
      }, 1000);
    };

  public reloadMapboxLayerDataByRelatedName = (relatedDataName: MapObjects, layerName: Layers) => (dispatch: any) => {
    setTimeout(async () => {
      try {
        const relatedDescriptions = getRegisteredLayer(layerName).getRelatedData();
        const currentRelatedData = relatedDescriptions.find((el: RelatedDataPrototype) => el.name === relatedDataName);
        const { results } = await currentRelatedData?.uploadData();

        dispatch({
          type: this.REPLACE_CERTAIN_FIELD_IN_RELATED_DATA,
          payload: {
            layerName,
            relatedKey: currentRelatedData,
            data: results,
          },
        });
      } catch (e) {}
    }, 1000);
  };

  public replaceRelatedDataByKey =
    (relatedDataKey: string, layerName: Layers, newRelatedDataObject: AnyObject) => (dispatch: any) => {
      dispatch({
        type: this.REPLACE_RELATED_DATA_BY_KEY,
        payload: {
          layerName,
          relatedDataKey,
          newRelatedDataObject,
        },
      });
    };

  public deleteRelatedDataObjectByKey =
    (relatedDataKey: string, layerName: Layers, deletingObjectId: number) => (dispatch: any) => {
      dispatch({
        type: this.DELETE_RELATED_DATA_OBJECT_BY_KEY,
        payload: {
          layerName,
          relatedDataKey,
          deletingObjectId,
        },
      });
    };
}

export const mapboxDataActions = new MapboxDataActions();
