import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FieldChildInputProps } from './model/FieldChildInput.model';
import { Styled } from './style/FieldChildInput.styled';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Button } from 'UI/common/components/Button/Button';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { useFormContext } from 'react-hook-form';

const { FieldChildInputWrap, FieldChildInputGroup, FieldChildInputGroupHeader, FieldChildInputLabel } = Styled;

const FieldChildInput: FC<FieldChildInputProps> = ({ field, description, object, provideInput }) => {
  const { setValue, getValues } = useFormContext();
  const [countGroup, setCountGroup] = useState(1);

  useEffect(() => {
    if (object && Array.isArray(object[field])) {
      const count = object[field].length;
      setCountGroup(count);
    }
  }, [field, object]);

  const onAddCount = useCallback(
    (event) => {
      event.preventDefault();
      setCountGroup(countGroup + 1);
    },
    [countGroup]
  );

  const onDecCount = useCallback(
    (key, event) => {
      event.preventDefault();

      if (description.child) {
        const children = description.child.children;
        const values = getValues();

        if (children) {
          for (const child in children) {
            if (children.hasOwnProperty(child)) {
              for (let i = key; i < countGroup - 1; i++) {
                const nextKey = i + 1;
                const nextValue = values[`${field}[${nextKey}].${child}`];

                setValue(`${field}[${nextKey}].${child}`, nextValue);
              }
            }
          }
        }
      }
      setCountGroup(countGroup - 1);
    },
    [countGroup, description.child, field, getValues, setValue]
  );

  const content = useMemo(() => {
    if (description.child) {
      const children = description.child.children;

      if (children) {
        const content: Array<JSX.Element> = [];

        for (let i = 0; i < countGroup; i++) {
          const inputs: Array<JSX.Element | null> = [];

          for (const child in children) {
            if (children.hasOwnProperty(child)) {
              const childDescription = children[child];
              const childField = `${field}[${i}].${child}`;
              inputs.push(provideInput(childField, childDescription, object));
            }
          }

          content.push(
            <FieldChildInputGroup>
              <FieldChildInputGroupHeader>
                <FieldChildInputLabel>{description.label}</FieldChildInputLabel>
                {countGroup > 1 && (
                  <Button
                    type={ButtonType.unbounded}
                    icon={<DeleteIcon />}
                    iconSize={20}
                    onClick={(e) => onDecCount(i, e)}
                  />
                )}
              </FieldChildInputGroupHeader>
              {inputs}
            </FieldChildInputGroup>
          );
        }

        return content;
      }
    }

    return null;
  }, [description.child, description.label, countGroup, onDecCount, field, provideInput, object]);

  return (
    <FieldChildInputWrap>
      {content}
      <Button type={ButtonType.primaryTranslucent} onClick={onAddCount}>
        Добавить значение
      </Button>
    </FieldChildInputWrap>
  );
};

export default FieldChildInput;
