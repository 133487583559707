import React from 'react';
import { resolveUrl } from '../../registrators/directory/directoryRegistrator/directoryRegistrator';
import PropsPasser from '../../HOC/PropsPasser';
import DownloadButton from 'UI/custom/actionsButtons/common/DownloadButton';
import { UploadTMCFileButton } from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileButton';
import _ from 'underscore';

export const useActionBarRenderer = (lastInDirectoryHistory, CRUDHandlers, customDirectory) => {
  let ActionBarRenderer = null;
  const addAndDownloadFilesButton = customDirectory
    ? []
    : [
        <UploadTMCFileButton key={_.uniqueId()} tooltip={'Добавить файл'} CRUDHandlers={CRUDHandlers} />,
        <div key={_.uniqueId()} style={{ margin: '0 15px' }}>
          <DownloadButton tooltip={'Скачать файл'} CRUDHandlers={CRUDHandlers} />
        </div>,
      ];
  if (lastInDirectoryHistory) {
    const ActionBarPrototype = resolveUrl(lastInDirectoryHistory.urlData, 'gridActionButtons', 'urlData');
    ActionBarRenderer = ActionBarPrototype?.map((PrototypeButton) => (
      <PropsPasser key={_.uniqueId()} component={<PrototypeButton />} CRUDHandlers={CRUDHandlers} />
    ));
  }
  return { ActionBarRenderer: [...addAndDownloadFilesButton, ...(ActionBarRenderer ?? [])] };
};
