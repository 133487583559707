import { theme } from '../themes/rootTheme';
import * as d3 from 'd3';
import { ThemedStyledProps, ThemeProps } from 'styled-components';
import { ThemeColors } from './model/ThemeColors.model';
import { Property } from 'csstype';
import { MarginMixinProps } from './model/MarginMixin.model';
import { PaddingMixinProps } from './model/PaddingMixin.model';
import { SizeMixinProps } from './model/SizeMixin.model';

const CutStringWithEllipsisOnTheEnd = () => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HideScrollbar = () => `
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollbarThin = (props: ThemeProps<typeof theme>) => `
  * {
      ::-webkit-scrollbar {
      width: 9px;
      height: 9px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
      background: ${props.theme.colors.defaultLighter};
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
      background: ${props.theme.colors.neutralDark};
      border-radius: 5px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
      background: ${props.theme.colors.neutralDarker};
      }
    }
`;
const GetDefaultBeatifiedScrollbar = (props: ThemeProps<typeof theme>) => `
    ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    border: none;
    width: 10px;
    border-radius: ${props.theme.decoration.defaultBorderRadius};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props.theme.colors.neutralDark};
    border-radius: ${props.theme.decoration.defaultBorderRadius};
  }
`;

const OpacityColorInCertainProperty = (colorName: keyof ThemeColors, property: string, val: number) => `
    ${property}: ${d3.rgb(theme.colors[colorName]).copy({ opacity: val })}
`;

const OpacityColor = (color: Property.Color, val: number) => `
    ${d3.rgb(color).copy({ opacity: val })}
`;

const asRGBA = (hexColor: string) => `${d3.rgb(hexColor).formatRgb()}`;

const firstLetterUppercase = () => `
  &:first-letter {
    text-transform: uppercase;
  }
`;

const AddMarginsProps = (props: ThemedStyledProps<MarginMixinProps, typeof theme>) =>
  `margin: ${props.m ?? ''};
    margin-top: ${props.mt ?? ''};
    margin-bottom: ${props.mb ?? ''};
    margin-left: ${props.ml ?? ''};
    margin-right: ${props.mr ?? ''};`;

const AddPaddingsProps = (props: ThemedStyledProps<PaddingMixinProps, typeof theme>) => `
    padding: ${props.p ?? ''};
    padding-top: ${props.pt ?? ''};
    padding-bottom: ${props.pb ?? ''};
    padding-left: ${props.pl ?? ''};
    padding-right: ${props.pr ?? ''};
`;

const AddSizeProps = (props: ThemedStyledProps<SizeMixinProps, typeof theme>) => `
  width: ${props?.width ?? 'auto'};
  max-width: ${props?.maxWidth ?? '100%'};
  min-width: ${props?.minWidth ?? 'auto'};
  height: ${props?.height ?? 'auto'};
  min-height: ${props?.minHeight ?? 'auto'};
  max-height: ${props?.maxHeight ?? 'auto'};
`;

export const Styled = {
  offset: {
    AddMarginsProps,
    AddPaddingsProps,
  },
  size: {
    AddSizeProps,
  },
  text: {
    CutStringWithEllipsisOnTheEnd,
    firstLetterUppercase,
  },
  color: {
    OpacityColorInCertainProperty,
    OpacityColor,
    asRGBA,
  },
  other: {
    HideScrollbar,
    GetDefaultBeatifiedScrollbar,
    ScrollbarThin,
  },
};
