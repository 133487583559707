import React, { FC } from 'react';
import { Styled } from './style/Button.styled';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { ButtonType } from './model/Button.model';
import { ButtonPropsModel } from './model/ButtonProps.model';
import { Tooltip } from 'antd';

const {
  Item,
  PrimaryItem,
  PrimaryTranslucentItem,
  PrimaryReverseItem,
  DangerItem,
  DangerItemDelete,
  DangerReverseItem,
  UnboundedItem,
  UnboundedPrimaryItem,
  PrimaryLCItem,
  PrimaryReverseLBItem,
  UnboundedDangerItem,
  TooltipContainer,
  DangerReverseTranslucent,
  PrimaryItemDisable,
} = Styled;

const CleanButton: FC<ButtonPropsModel> = ({
  children,
  type,
  disabled,
  onClick,
  className,
  icon,
  loading,
  width,
  iconColor,
  iconMargin,
  isBold,
  iconSize = { width: '24px', height: '24px' },
  rounded,
  hasShadow,
  fontSize,
  ...otherAttrs
}) => {
  const onClickAction = (e: any) => {
    if (type === ButtonType.submit) return;
    if (disabled || !onClick || loading) {
      e.preventDefault();
    } else {
      return onClick(e);
    }
  };

  const itemProps = {
    as: otherAttrs.href ? 'a' : 'button',
    className: `${className}`,
    onClick: onClickAction,
    disabled,
    iconColor,
    iconMargin,
    isBold,
    type,
    width,
    iconSize,
    rounded,
    hasShadow,
    fontSize,
    ...otherAttrs,
  };
  const itemChildren = loading ? <LoadingOutlined /> : [icon ? { ...icon, key: Math.random() } : null, children];

  const resolveTypeOfBtn = (type: ButtonType | undefined, children: any, props: any) => {
    switch (type) {
      case ButtonType.primary:
        return <PrimaryItem {...props}>{children}</PrimaryItem>;
      case ButtonType.disable:
        return <PrimaryItemDisable {...props}>{children}</PrimaryItemDisable>;
      case ButtonType.primaryTranslucent:
        return <PrimaryTranslucentItem {...props}>{children}</PrimaryTranslucentItem>;
      case ButtonType.primaryLC:
        return <PrimaryLCItem {...props}>{children}</PrimaryLCItem>;
      case ButtonType.primaryReverse:
        return <PrimaryReverseItem {...props}>{children}</PrimaryReverseItem>;
      case ButtonType.primaryReverseLB:
        return <PrimaryReverseLBItem {...props}>{children}</PrimaryReverseLBItem>;
      case ButtonType.danger:
        return <DangerItem {...props}>{children}</DangerItem>;
      case ButtonType.dangerDelete:
        return <DangerItemDelete {...props}>{children}</DangerItemDelete>;
      case ButtonType.dangerReverse:
        return <DangerReverseItem {...props}>{children}</DangerReverseItem>;
      case ButtonType.dangerReverseTranslucent:
        return <DangerReverseTranslucent {...props}>{children}</DangerReverseTranslucent>;
      case ButtonType.unbounded:
        return <UnboundedItem {...props}>{children}</UnboundedItem>;
      case ButtonType.unboundedPrimary:
        return <UnboundedPrimaryItem {...props}>{children}</UnboundedPrimaryItem>;
      case ButtonType.unboundedDanger:
        return <UnboundedDangerItem {...props}>{children}</UnboundedDangerItem>;
      default:
        return <Item {...props}>{children}</Item>;
    }
  };

  return resolveTypeOfBtn(type, itemChildren, itemProps);
};

export const Button: FC<ButtonPropsModel> = (props) => {
  const { tooltip, placement } = props;
  const titleTooltip = () => {
    return <TooltipContainer>{tooltip}</TooltipContainer>;
  };

  return (
    <Tooltip mouseEnterDelay={0.1} mouseLeaveDelay={0.2} title={tooltip ? titleTooltip : null} placement={placement}>
      <CleanButton {...props} />
    </Tooltip>
  );
};

Button.defaultProps = {
  iconSize: { width: '24px', height: '24px' },
  placement: 'topLeft',
};
