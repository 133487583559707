import React from 'react';
import { withTheme } from 'styled-components';
import PropsPasser from '../../../../../../HOC/PropsPasser';
import styled from 'styled-components/macro';

const StyledSelectedInfoContainer = styled.div`
  height: 100%;
  margin-left: 16px;
  grid-area: 1/6/2/7;
  padding-top: 65px;
`;

const StyledSelectedInfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LocalStyled = {
  StyledSelectedInfoContainer,
  StyledSelectedInfoContent,
};

const MapSelectedObjectInfo = React.memo((props) => {
  const { handleCloseMapSelectedObjectInfo, handleOpenObjectInNewTab, selectedMapItem, reloadMapboxLayerData } = props;
  let renderResult = null;
  // TODO надо переписать эту логику, сейчас она привязана на data , по хорошему надо дать возможность выбора в самом слое,
  //  где и идет обработка события пока добавлен блок if. Тех-долг-FRONTEND #5578
  if (selectedMapItem.componentToRender) {
    const SelectedObjectPrototype = selectedMapItem.componentToRender;
    renderResult = (
      <PropsPasser
        component={
          <SelectedObjectPrototype
            selectedMapItem={selectedMapItem}
            data={selectedMapItem.selectedObject}
            model={null}
            mode={null}
            handleCloseMapSelectedObjectInfo={handleCloseMapSelectedObjectInfo}
            handleOpenObjectInNewTab={handleOpenObjectInNewTab}
            reloadMapboxLayerData={reloadMapboxLayerData}
          />
        }
      />
    );
  } else if (selectedMapItem?.data) {
    // Это логика переключения данных в карточке - если в mapSelectObjectRegistrator.js подключен не один объект, а больше.
    // По нынешнему дизайну, пока, не используется
    // const maxIndex = selectedMapItem.data.length - 1;
    // const handleChangeToPreviousIndex = () => {
    //   if (selectedModeIndex > 0) {
    //     setSelectedModeIndex(selectedModeIndex - 1);
    //   } else {
    //     setSelectedModeIndex(maxIndex);
    //   }
    // };
    //
    // const handleChangeToNextIndex = () => {
    //   if (selectedModeIndex < maxIndex) {
    //     setSelectedModeIndex(selectedModeIndex + 1);
    //   } else {
    //     setSelectedModeIndex(0);
    //   }
    // };
    const modeToDisplay = selectedMapItem.data[0];
    const SelectedObjectPrototype = modeToDisplay.representationPrototype;
    renderResult = (
      <PropsPasser
        component={
          <SelectedObjectPrototype
            selectedMapItem={selectedMapItem}
            data={modeToDisplay.data}
            model={modeToDisplay.model}
            mode={modeToDisplay}
            handleCloseMapSelectedObjectInfo={handleCloseMapSelectedObjectInfo}
            handleOpenObjectInNewTab={handleOpenObjectInNewTab}
            reloadMapboxLayerData={reloadMapboxLayerData}
          />
        }
      />
    );
  } else if (selectedMapItem?.selectedObject) {
    const { layer } = selectedMapItem?.selectedObject;
    const masterLayer = layer?.props?.masterLayer;
    if (masterLayer) {
      const SelectedObjectPrototype = masterLayer?.getSelectedObjectPrototype();
      renderResult = (
        <PropsPasser
          component={
            <SelectedObjectPrototype
              selectedMapItem={selectedMapItem}
              data={selectedMapItem.selectedObject}
              model={null}
              mode={null}
              handleCloseMapSelectedObjectInfo={handleCloseMapSelectedObjectInfo}
              handleOpenObjectInNewTab={handleOpenObjectInNewTab}
              reloadMapboxLayerData={reloadMapboxLayerData}
            />
          }
        />
      );
    }
  }
  let widthStyledSelectedInfoContainer = '';
  if (renderResult?.props?.component?.type?.displayName === 'PublicTransportBusStopSelection')
    widthStyledSelectedInfoContainer = '100%';
  if (renderResult) {
    return (
      <LocalStyled.StyledSelectedInfoContainer style={{ width: widthStyledSelectedInfoContainer }}>
        {renderResult}
      </LocalStyled.StyledSelectedInfoContainer>
    );
  }
  return null;
});

export default withTheme(MapSelectedObjectInfo);
