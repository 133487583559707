import styled from 'styled-components/macro';
import { Layout } from 'antd';

const { Sider } = Layout;

const SiderWrapper = styled(Sider)`
  background: transparent;
  height: 100%;
  margin-right: 1.15rem;
  & .ant-menu-item {
    line-height: 2rem;
    font-size: 1rem;
    padding: 0;
    height: auto;
    margin: 0 !important;
    color: ${(props) => props.theme.colors.neutralDarker};
    &:hover {
      color: ${(props) => props.theme.colors.neutralLight};
    }
    &.active {
      background-color: transparent;
      font-weight: bold;
      color: ${(props) => props.theme.colors.primary};
    }
    & + .ant-menu-item {
      margin-top: 1.15rem !important;
    }
  }
  & ul {
    border: none;
  }
  & button:nth-child(2) {
    background: ${(props) => props.theme.colors.dangerOpacity};
  }
`;

const SiderInner = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const SiderContent = styled('div')`
  width: 100%;
  //padding: 1.15rem;
  flex: 1 1 auto;
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: 6px;
  text-align: left;
  & div {
    color: ${(props) => props.theme.colors.primary};
    height: 36px;
    width: 184px;
    background: rgba(47, 128, 237, 0.08);
    display: flex;
    align-items: center;
    padding-left: 12px;
    border-radius: 8px;
    font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
  }
`;

const SiderTitle = styled('h3')`
  margin-top: 9px;
  font-family: Gilroy, 'sans-serif';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  & + * {
    margin-top: 1.15rem;
  }
`;

const SiderFooter = styled('footer')`
  width: 100%;
  & button {
    margin-top: 1.15rem;
  }
`;

export const Styled = {
  SiderWrapper,
  SiderInner,
  SiderContent,
  SiderFooter,
  SiderTitle,
};
