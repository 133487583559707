import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const LineTrollItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'cablesTroll');
    const { id } = roadData.data;

    const win = window.open(`/app/directory/${DirectoryRouteModel.knsLinesTroll}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Наименование линии',
    value: data?.line_name,
  });

  Descriptions.push({
    key: 'Протяженность Линии',
    value: data?.line_length ? data?.line_length : '-',
  });

  Descriptions.push({
    key: 'Пропускная способность',
    value: data?.throughput ? data?.throughput : '-',
  });

  Descriptions.push({
    key: 'Питающая подстанция(основная)',
    value: data?.substation_name ? data?.substation_name : '-',
  });

  Descriptions.push({
    key: 'Питающая подстанция(резервная)',
    value: data?.reserve_substation_name ? data?.reserve_substation_name : '-',
  });

  Descriptions.push({
    key: 'Линия, принимающая нагрузку (при выходе из строя)',
    value: !data?.line_of_failure_name ? data?.line_of_failure_name : '-',
  });

  Descriptions.push({
    key: 'Линия, принимающая нагрузку (при отключении)',
    value: data?.line_of_disable_name ? data?.line_of_disable_name : '-',
  });

  Descriptions.push({
    key: 'Марка кабеля',
    value: data?.model ? data?.model : '-',
  });

  Descriptions.push({
    key: 'Протяженность Кабеля',
    value: data?.length ? data?.length : '-',
  });

  Descriptions.push({
    key: 'Начало кабеля',
    value: data?.start,
  });

  Descriptions.push({
    key: 'Конец кабеля',
    value: data?.end,
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об участке линии'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

LineTrollItemSelection.propTypes = {};

export default LineTrollItemSelection;
