import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import CityDistrictsCompositeLayer from '../../../../../../layers/CompositeLayers/ekb/CityDistrictsCompositeLayer';

export class CityDistrictsLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: CityDistrictsCompositeLayer,
      id: 'CityDistricts',
    });
  }
}
