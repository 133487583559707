import styled from 'styled-components';
import { OptionIcon } from 'UI/custom/icons/actionIcons/OptionIcon';
import { WidgetWrapBodyProps } from '../model/WidgetWrap.model';
//Стили нарочно не из темы, что бы легче переносить в отдельную библиотеку
const WidgetWrapStyled = styled.div`
  background: #fff;
  box-shadow: 0px 2px 8px rgba(107, 138, 228, 0.12);
  border-radius: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const WidgetWrapHeader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`;

const WidgetWrapButtonWrap = styled.div`
  display: flex;
  margin-left: 16px;

  & button:not(:last-of-type) {
    margin-right: 16px;
  }
`;

const WidgetWrapHeaderName = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: #1a2138;
`;

const WidgetWrapBody = styled.div<WidgetWrapBodyProps>`
  padding: ${(props) => (props.padding ? `${props.padding}px` : '16px')};
  height: 100%;
  overflow: hidden;
`;

const DragIcon = styled(OptionIcon)`
  margin-left: auto;
`;

export const Styled = {
  WidgetWrapStyled,
  WidgetWrapHeader,
  WidgetWrapHeaderName,
  WidgetWrapBody,
  WidgetWrapButtonWrap,
  DragIcon,
};
