import styled from 'styled-components';
import { DefaultStyleMixins } from '@frontend-packages/its-ui-kit';
const { getSpacing } = DefaultStyleMixins.offset;

export const SFileListContainer = styled.div`
  width: 390px;
  height: 100%;

  aside {
    height: 100%;
  }

  .panel__content {
    height: 100%;
    padding: ${getSpacing([0, 2, 1, 2])};
  }
`;

export const SColumnTitleContainer = styled.div`
  margin: ${getSpacing([2, 0, 2, 0])};
`;

export const ColWrapper = styled.div`
  cursor: pointer;
  & span {
    transition: 0.3s;
  }
  &:hover span {
    color: ${(props) => props.theme.colors.primary};
  }
`;
