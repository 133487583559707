import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

// TODO duplicate fragment Тех-долг-FRONTEND #5583

const OrderRoutePathSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'order_route');
    const { id } = roadData.data;
    const win = window.open(`/app/cargo_transport/individual_statements/${id}/`, '_blank');
    win.focus();
  };
  let Descriptions = [];

  const properties = data?.[0];

  properties?.name_route &&
    Descriptions.push({
      key: 'Название маршрута',
      value: properties.name_route,
    });

  properties?.statement_name &&
    Descriptions.push({
      key: 'Заявление',
      value: properties.statement_name,
    });

  properties?.start_route &&
    Descriptions.push({
      key: 'Начало маршрута',
      value: `${Number(properties.start_route)?.toFixed(2) ?? 0} км`,
    });

  properties?.end_route &&
    Descriptions.push({
      key: 'Конец маршрута',
      value: `${Number(properties.end_route)?.toFixed(2) ?? 0} км`,
    });

  properties?.road_length &&
    Descriptions.push({
      key: 'Протяженность дороги',
      value: `${Number(properties.road_length)?.toFixed(2) ?? 0} км`,
    });

  return (
    <>
      {Descriptions.length && (
        <PrepareScrollableInfoCard
          title={'Маршрут заявки'}
          handleCloseCard={handleCloseMapSelectedObjectInfo}
          handleOpenItemInDirectory={handleOpenObjectInNewTab}
          listOfDescriptions={Descriptions}
          openInNewTabBtnDesc={'Открыть в справочнике'}
        />
      )}
    </>
  );
};

export default OrderRoutePathSelection;
