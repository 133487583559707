import { useDispatch } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { selectObject } from 'store/reducers/directory/actions/selectedObjectActions';

interface CargoTransportParams {
  folder: string;
  id?: string;
}

export const ChangePathNameComponent = () => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch<CargoTransportParams>();
  const { folder, id } = params;
  const history = useHistory();
  dispatch(selectObject({ id, folder }));
  history.push(`/app/directory/${folder}/`);
  return <></>;
};
