import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CompassButton from './CompassButton';
import { setMapBearing, setMapToNorth, toggle3D } from '../../../../../../../store/reducers/map/actions/mapboxActions';

const CompassButtonContainer = ({ viewState, handlerToggle3D, handlerSetMapToNorth, handlerSetMapBearing }) => {
  const toggle3DHandler = (event) => {
    event.stopPropagation();
    handlerToggle3D();
  };
  const toggleBearingHandler = (event) => {
    event.stopPropagation();
    handlerSetMapToNorth();
  };

  const setMapBearingHandler = (event) => {
    event.preventDefault();
    const initialY = 675;
    const deltaBearing = event.clientY - initialY;
    handlerSetMapBearing(deltaBearing);
  };

  return (
    <>
      <CompassButton
        viewState={viewState}
        toggle3DHandler={toggle3DHandler}
        toggleBearingHandler={toggleBearingHandler}
        setMapBearingHandler={setMapBearingHandler}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    viewState: state.mapboxView.viewState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlerToggle3D: () => dispatch(toggle3D()),
    handlerSetMapToNorth: () => dispatch(setMapToNorth()),
    handlerSetMapBearing: (value) => dispatch(setMapBearing(value)),
  };
};

CompassButtonContainer.propTypes = {
  viewState: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompassButtonContainer);
