import React, { FC } from 'react';
import { Styled } from './style/PrepareInput.styled';
import { PrepareInputProps } from './model/PrepareInput.model';

const { PrepareInputStyled, PrepareInputName } = Styled;

const PrepareInput: FC<PrepareInputProps> = ({ label, children }) => {
  return (
    <PrepareInputStyled>
      <PrepareInputName>{label}</PrepareInputName>
      {children}
    </PrepareInputStyled>
  );
};

export default PrepareInput;
