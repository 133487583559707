import turfCenterOfMass from '@turf/center-of-mass';
// @ts-ignore
import turf from 'turf';
import { notificationActions } from '../../../store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { setSelectArea } from '../../../store/reducers/map/actions/mapPanelsActions';
import { WebMercatorViewport } from 'deck.gl';
import { postToURLSingle } from '../../../api/postToURLSingle';
import {
  CalculatingScreenOrSelectedAreaCoords,
  ScreenOrSelectedAreaCoordsInAxisType,
  ScreenOrSelectedAreaCoordsType,
} from '../../../services/map/screenOrSelectedAreaCoordsGetter/CalculatingScreenOrSelectedAreaCoordsFunction';
import { bbox } from '@turf/turf';

// interface objectPointsCoordsWithPointsScreenCoordsInterface {
//   coords: [number, number];
//   screenCoords: [number, number];
// }

type returnObject = {
  center: number[] | undefined;
  topLeftCoords: [number, number] | undefined;
  bottomRightCoords: any;
  answer: any;
  answer2: any;
};

type typeOfCalculateCanvasParams = {
  height: number;
  tiles_count: number;
  tiles_count_horizontal: number;
  tiles_count_vertical: number;
  width: number;
};

interface IRaschet {
  totalNumberOfVerticalScreen: number;
  totalNumberOfHorizontalScreen: number;
  totalHeight: number;
  totalWidth: number;
  totalTilesCount: number;
}

export const calculatingDiagonalPoints = async ({
  editCoords,
  currentViewports,
  dispatch,
  cartographicScale = '1000',
}: {
  editCoords: any;
  currentViewports: WebMercatorViewport;
  dispatch: any;
  cartographicScale: string;
}): Promise<returnObject | void> => {
  const asas = CalculatingScreenOrSelectedAreaCoords({
    currentViewports: currentViewports,
    selectedArea: editCoords,
    shouldReturnPolygon: true,
  }) as ScreenOrSelectedAreaCoordsType;

  const asasd = CalculatingScreenOrSelectedAreaCoords({
    currentViewports: currentViewports,
    selectedArea: editCoords,
    shouldReturnPolygon: true,
    axisCoordsFormat: true,
  }) as ScreenOrSelectedAreaCoordsInAxisType;

  const data: any = {
    type: 'FeatureCollection',
    features: [asasd.ScreenAreaPolygon],
  };

  const dispatchError = (message: string) => {
    dispatch(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message: message,
      })
    );
  };

  if (editCoords?.coordinates?.[0]) {
    // const features = turf.featureCollection(editCoords.coordinates[0].map((el: any) => turf.point(el)));
    // const enveloped = turf.envelope(features);
    // let center = turfCenterOfMass(enveloped);
    // let bottomTopScreenPixelsCoords: number[] | [] = [],
    //   leftRightScreenPixelsCoords: number[] | [] = [];
    //
    // enveloped?.geometry?.coordinates?.[0]?.forEach((el: [number, number]) => {
    //
    //   const screenCoords = currentViewports.project(el);
    //
    //   // @ts-ignore
    //   if (!bottomTopScreenPixelsCoords.includes(screenCoords[1])) {
    //     // @ts-ignore
    //     bottomTopScreenPixelsCoords.push(screenCoords[1]);
    //   }
    //   // @ts-ignore
    //   if (!leftRightScreenPixelsCoords.includes(screenCoords[0])) {
    //     // @ts-ignore
    //     leftRightScreenPixelsCoords.push(screenCoords[0]);
    //   }
    // });
    //
    // const bottomRightCoords = currentViewports.unproject([Math.max(...leftRightScreenPixelsCoords), Math.max(...bottomTopScreenPixelsCoords)]);
    // const DELETE_topRightCoords = currentViewports.unproject([
    //   Math.max(...leftRightScreenPixelsCoords),
    //   Math.min(...bottomTopScreenPixelsCoords)
    // ]);
    // const DELETE_bottomLeftCoords = currentViewports.unproject([
    //   Math.min(...leftRightScreenPixelsCoords),
    //   Math.max(...bottomTopScreenPixelsCoords)
    // ]);
    // const topLeftCoords = currentViewports.unproject([Math.min(...leftRightScreenPixelsCoords), Math.min(...bottomTopScreenPixelsCoords)]);

    const calculateCanvasParams: typeOfCalculateCanvasParams = await postToURLSingle.postOrPutDataErrorResponse(
      'http://its-api.gor.ekb.lan/print/calculate_canvas_params',
      {
        upper_left: {
          latitude: asas.topLeftCoords[1],
          longitude: asas.topLeftCoords[0],
        },
        upper_right: {
          latitude: asas.topRightCoords[1],
          longitude: asas.topRightCoords[0],
        },
        lower_left: {
          latitude: asas.bottomLeftCoords[1],
          longitude: asas.bottomLeftCoords[0],
        },
        lower_right: {
          latitude: asas.bottomRightCoords[1],
          longitude: asas.bottomRightCoords[0],
        },
        scale: cartographicScale,
      }
    );

    /** ВОТ ОН */
    const raschet = ({
      totalWidth,
      //totalTilesCount,
      totalNumberOfVerticalScreen,
      totalNumberOfHorizontalScreen,
      totalHeight,
    }: IRaschet) => {
      const leftTopScreenCoords = currentViewports.project(asas.topLeftCoords);
      const rightBottomScreenCoords = currentViewports.project(asas.bottomRightCoords);

      const areaWidth = rightBottomScreenCoords[0] - leftTopScreenCoords[0];
      const areaHeight = rightBottomScreenCoords[1] - leftTopScreenCoords[1];
      const remainderOfTheDivisionHeight = areaHeight % totalNumberOfVerticalScreen;
      const remainderOfTheDivisionWidth = areaWidth % totalNumberOfHorizontalScreen;

      const oneSideHeight = (areaHeight - remainderOfTheDivisionHeight) / totalNumberOfVerticalScreen;
      // const lastSideHeight = oneSideHeight + remainderOfTheDivisionHeight;
      const oneSideWidth = (areaWidth - remainderOfTheDivisionWidth) / totalNumberOfHorizontalScreen;
      // const lastSideWidth = oneSideWidth + remainderOfTheDivisionWidth;

      const hashOfScreenCoords: { [index: string]: [number, number] } = {};

      for (let verticalScreenNumber = 0; verticalScreenNumber <= totalNumberOfVerticalScreen; verticalScreenNumber++) {
        for (
          let horizontalScreenNumber = 0;
          horizontalScreenNumber <= totalNumberOfHorizontalScreen;
          horizontalScreenNumber++
        ) {
          let verticalCoords = leftTopScreenCoords[1];
          let horizontalCoords = leftTopScreenCoords[0];

          if (verticalScreenNumber > 0 && verticalScreenNumber < totalNumberOfVerticalScreen) {
            verticalCoords = oneSideHeight * verticalScreenNumber + leftTopScreenCoords[1]; //AxisY_Min
          }
          if (verticalScreenNumber === totalNumberOfVerticalScreen) {
            verticalCoords = areaHeight + leftTopScreenCoords[1]; //AxisY_Min
          }

          if (horizontalScreenNumber > 0 && horizontalScreenNumber < totalNumberOfHorizontalScreen) {
            horizontalCoords = oneSideWidth * horizontalScreenNumber + leftTopScreenCoords[0]; //AxisX_Min
          }
          if (horizontalScreenNumber === totalNumberOfHorizontalScreen) {
            horizontalCoords = areaWidth + leftTopScreenCoords[0]; //AxisX_Min
          }

          hashOfScreenCoords[`vertical${verticalScreenNumber}horizontal${horizontalScreenNumber}`] =
            currentViewports.unproject([horizontalCoords, verticalCoords]) as [number, number];
        }
      }

      const oneObj = (arrayOfCoords: number[][]) => {
        return {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [arrayOfCoords],
          },
        };
      };

      const coordsObject: any = {
        YakovTheBest: {},
      };

      const coordsObjectWithBorder: any = {
        YakovTheBest2: {},
      };

      if (hashOfScreenCoords) {
        const remainderOfTheDivisionBackHeight = totalHeight % totalNumberOfVerticalScreen;
        const remainderOfTheDivisionBackWidth = totalWidth % totalNumberOfHorizontalScreen;

        const oneBackSideHeight = (totalHeight - remainderOfTheDivisionBackHeight) / totalNumberOfVerticalScreen;
        const lastBackSideHeight = oneBackSideHeight + remainderOfTheDivisionBackHeight;
        const oneBackSideWidth = (totalWidth - remainderOfTheDivisionBackWidth) / totalNumberOfHorizontalScreen;
        const lastBackSideWidth = oneBackSideWidth + remainderOfTheDivisionBackWidth;

        for (
          let firstVerticalCoordsNumber = 0;
          firstVerticalCoordsNumber < totalNumberOfVerticalScreen;
          firstVerticalCoordsNumber++
        ) {
          for (
            let firstHorizontalCoordsNumber = 0;
            firstHorizontalCoordsNumber < totalNumberOfHorizontalScreen;
            firstHorizontalCoordsNumber++
          ) {
            const secondVerticalCoordsNumber = firstVerticalCoordsNumber + 1;
            const secondHorizontalCoordsNumber = firstHorizontalCoordsNumber + 1;
            const arrayOfCoords = [
              hashOfScreenCoords[`vertical${firstVerticalCoordsNumber}horizontal${firstHorizontalCoordsNumber}`],
              hashOfScreenCoords[`vertical${firstVerticalCoordsNumber}horizontal${secondHorizontalCoordsNumber}`],
              hashOfScreenCoords[`vertical${secondVerticalCoordsNumber}horizontal${secondHorizontalCoordsNumber}`],
              hashOfScreenCoords[`vertical${secondVerticalCoordsNumber}horizontal${firstHorizontalCoordsNumber}`],
              hashOfScreenCoords[`vertical${firstVerticalCoordsNumber}horizontal${firstHorizontalCoordsNumber}`],
            ];
            coordsObject.YakovTheBest[
              `vertical${firstVerticalCoordsNumber + 1}horizontal${firstHorizontalCoordsNumber + 1}`
            ] = {
              center: turfCenterOfMass(turf.polygon([arrayOfCoords])).geometry.coordinates,
              pixelWidth:
                firstHorizontalCoordsNumber === totalNumberOfHorizontalScreen - 1
                  ? lastBackSideWidth
                  : oneBackSideWidth,
              pixelHeight:
                firstVerticalCoordsNumber === totalNumberOfVerticalScreen - 1 ? lastBackSideHeight : oneBackSideHeight,
            };

            const objj = oneObj(arrayOfCoords);

            data.features.push(objj);

            const thatCenter: any = turfCenterOfMass(objj).geometry.coordinates;
            const [minLng, minLat, maxLng, maxLat] = bbox(objj);

            coordsObjectWithBorder.YakovTheBest2[thatCenter] = { minLng, minLat, maxLng, maxLat };
          }
        }
      }

      // const aaa = data;
      // const bbb = coordsObject;
      // const ddd = hashOfScreenCoords;
      // const eee = calculateCanvasParams;
      // const fff = currentViewports;
      // const ggg = coordsObjectWithBorder;

      return { coordsObject, coordsObjectWithBorder };
    };

    const answer = calculateCanvasParams
      ? raschet({
          totalNumberOfVerticalScreen: calculateCanvasParams.tiles_count_vertical,
          totalNumberOfHorizontalScreen: calculateCanvasParams.tiles_count_horizontal,
          totalHeight: calculateCanvasParams.height,
          totalWidth: calculateCanvasParams.width,
          totalTilesCount: calculateCanvasParams.tiles_count,
        })
      : null;

    let center;

    if (asas.bottomRightCoords && asas.topRightCoords && asas.bottomLeftCoords && asas.topLeftCoords) {
      // data.features.push(turf.polygon([enveloped.geometry.coordinates[0]]));
      data.features.push(turf.polygon([editCoords.coordinates[0]]));
      const ScreenAreaPolygon = turf.polygon([
        [asas.topLeftCoords, asas.topRightCoords, asas.bottomRightCoords, asas.bottomLeftCoords, asas.topLeftCoords],
      ]);
      data.features.push(ScreenAreaPolygon);
      center = turfCenterOfMass(ScreenAreaPolygon);
      dispatch(setSelectArea(data));
    }

    if (asas.bottomRightCoords && asas.topLeftCoords) {
      return {
        center: center?.geometry.coordinates,
        answer: answer?.coordsObject,
        answer2: answer?.coordsObjectWithBorder,
        // @ts-ignore
        topLeftCoords: asas.topLeftCoords,
        bottomRightCoords: asas.bottomRightCoords,
      };
    } else {
      dispatchError('Не удалось расчитать геометрию, повторите попытку');
    }
  } else dispatchError('Не нарисована область для печати');
};
