import React from 'react';
import { BreadcrumbType } from './types';
import { useBreadcrumbContext } from './context';
import { Wrapper } from './styled-elements';
import BreadcrumbItem from './BreadcrumbItem';

export interface BreadcrumbProps {}
export interface BreadcrumbsProps {
  routes: Array<BreadcrumbType>;
  onClick?: (item: BreadcrumbType, index: number) => void;
  theme?: any;
}
export interface BreadcrumbWithContextProps {}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export const BreadcrumbWithContext: React.FC<BreadcrumbWithContextProps> = () => {
  const { routes } = useBreadcrumbContext();

  return (
    <Breadcrumb>
      {routes.map((route: BreadcrumbType) => (
        <BreadcrumbItem>{route.label}</BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ routes, onClick = () => {}, theme }) => {
  return (
    <Wrapper>
      {routes?.map((route: BreadcrumbType, index: number) => (
        <BreadcrumbItem key={route.key} onClick={() => onClick(route, index)} theme={theme}>
          {route.label}
        </BreadcrumbItem>
      ))}
    </Wrapper>
  );
};
