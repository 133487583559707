import styled from 'styled-components/macro';
import Panel from '../Panel/Panel';
import PanelHeader from '../Panel/components/Header/PanelHeader';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { CutStringWithEllipsisOnTheEnd } = styleMixins.text;

const LocalMainWrapper = styled.section`
  height: fit-content;
  position: relative;
  width: 100%;
  margin-top: auto;
`;

const ExtendedMainInner = styled(Panel)`
  height: fit-content;
  margin-top: auto;
  background: ${(props) => props.theme.colors.defaultLighter};
  backdrop-filter: blur(18px);
  width: 100%;
`;

const ExtendedHeaderWrap = styled(PanelHeader)`
  padding: ${(props) => props.theme.paddings.smallPadding};
  box-shadow: none;
`;

const LocalHeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LocalHeaderTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSettings.default};
  color: ${(props) => props.theme.colors.defaultDark};
  margin: 0;
  ${CutStringWithEllipsisOnTheEnd}
`;

export const Styled = {
  main: { LocalMainWrapper, ExtendedMainInner },
  header: { ExtendedHeaderWrap, LocalHeaderGroup, LocalHeaderTitle },
};
