import { BreadcrumbType } from 'UI/common/components/Breadcrumb/types';

export const GET_DIRECTORY_REQUEST = 'GET_DIRECTORY_REQUEST';
export const GET_DIRECTORY_SUCCESS = 'GET_DIRECTORY_SUCCESS';
export const GET_DIRECTORY_FAILURE = 'GET_DIRECTORY_FAILURE';

export const UPDATE_DIRECTORY_REQUEST = 'UPDATE_DIRECTORY_REQUEST';
export const UPDATE_DIRECTORY_SUCCESS = 'UPDATE_DIRECTORY_SUCCESS';
export const UPDATE_DIRECTORY_FAILURE = 'UPDATE_DIRECTORY_FAILURE';

export const UPDATE_BREADCRUMBS_REQUEST = 'UPDATE_BREADCRUMBS_REQUEST';
export const UPDATE_BREADCRUMBS_SUCCESS = 'UPDATE_BREADCRUMBS_SUCCESS';
export const UPDATE_BREADCRUMBS_FAILURE = 'UPDATE_BREADCRUMBS_FAILURE';

export const RESET_DIRECTORY_HISTORY = 'RESET_DIRECTORY_HISTORY';

export interface DirectoryPayloadInterface {
  url: string;
  params?: { params: any; [key: string]: any };
}

export type getDirectoryPayload = DirectoryPayloadInterface & {
  contentRenderType?: 'ag_grid' | 'form';
};

export type UpdateDirectoryPayload = DirectoryPayloadInterface & {
  activeTab: string;
  label: string;
};

export type UpdateBreadcrumbsPayload = DirectoryPayloadInterface & {
  breadcrumbIndex: number;
  sider?: SiderInterface;
};

export type SiderElementType = {
  label: string;
  key: string | number;
  url: string;
  [key: string]: any;
};

export interface SiderInterface {
  activeTab?: string;
  elements: Array<SiderElementType>;
}

export interface DirectoryState {
  changeHistoryInProcess: boolean;
  breadcrumbs: Array<BreadcrumbType>;
  sider?: SiderInterface;
  data: any;
  model: any;
  contentRenderType: 'form' | 'ag_grid';
  requestUrl: string;
}
