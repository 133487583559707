import React, { useEffect, useMemo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useAgGridSidebarWithoutAdditionalFunctional } from 'hooks/ag-grid/useAgGridSidebarWithoutAdditionalFunctional';
import { useRenderCompendiumWithCertainColumns } from 'hooks/ag-grid/useRenderCompendiumWithCertainColumns';
import { useAgGridSettings } from 'hooks/ag-grid/useAgGridSettings';
import { CompendiumProps } from './model/Compendium.model';
import ModalSearchAdress from './model/ModalSearchAddress';
import { SpinContainer } from './CompendiumContainer';

const loadingOutlined = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const Compendium = (props: CompendiumProps) => {
  const {
    aggApi,
    data,
    model,
    containerCallbacks,
    showQuickFilter,
    namesCollectionForFiltering,
    agGridThemeName,
    gridCellSizes,
    withoutGridSidebarAdditionalFunctional,
  } = props;
  const toggleQuickFilter = containerCallbacks.toggleShowQuickFilter;
  const { agSettings, columnsData } = useAgGridSettings(data, model, containerCallbacks, gridCellSizes);
  useRenderCompendiumWithCertainColumns(aggApi, columnsData, namesCollectionForFiltering);

  useAgGridSidebarWithoutAdditionalFunctional(aggApi, withoutGridSidebarAdditionalFunctional);
  const heightStyle = useMemo(
    () => ({
      height: '100%',
    }),
    []
  );

  /**
   * Исправление бага определения слоя при переходе с одного слоя на другой с открытым гридом. (f: handleSelectRow in LayersDataAccessorContainer Line 61:53)
   * agGridReact - ссылка на компонент грида, и два эффекта для обновления методов.
   * Грид по какой-то причине запоминает методы, такими, какие они определены при инициализауии и хоть убей не хочет их
   * переопределять. Чтобы обойти это поведение и используется ссылка.
   */

  const agGridRef = React.useRef<any>(null);

  useEffect(() => {
    if (agGridRef?.current?.gridOptions?.onCellClicked) {
      agGridRef.current.gridOptions.onCellClicked = containerCallbacks.handleSelectRow;
    }
  }, [containerCallbacks.handleSelectRow]);

  useEffect(() => {
    if (agGridRef?.current?.gridOptions?.onCellDoubleClicked) {
      agGridRef.current.gridOptions.onCellDoubleClicked = containerCallbacks.handleProvideData;
    }
  }, [containerCallbacks.handleProvideData]);

  return (
    <>
      <div className={agGridThemeName} style={heightStyle}>
        <ModalSearchAdress showQuickFilter={showQuickFilter} toggleShowQuickFilter={toggleQuickFilter} />
        {agSettings?.columnDefs?.length ? (
          <AgGridReact
            ref={agGridRef}
            columnDefs={agSettings.columnDefs}
            defaultColDef={agSettings.defaultColDef}
            rowData={agSettings.rowData}
            rowSelection="multiple"
            onGridReady={containerCallbacks.handleAGGInit}
            gridOptions={{
              onCellClicked: containerCallbacks.handleSelectRow,
              onCellDoubleClicked: containerCallbacks.handleProvideData,
            }}
            animateRows={true}
            sideBar={agSettings.sideBar}
            floatingFilter={showQuickFilter}
            onRowSelected={containerCallbacks?.handleSetSelectedRows}
            onCellEditingStopped={containerCallbacks?.cellEditingStopped}
            enableRangeSelection={true}
            enableCharts={true}
            groupSelectsChildren={true}
            groupMultiAutoColumn={true}
            paginationAutoPageSize={true}
            localeText={agSettings.localeText}
            rowGroupPanelShow={agSettings.rowGroupPanelShow}
            detailCellRendererParams={agSettings.detailCellRendererParams}
            frameworkComponents={agSettings.frameworkComponents}
            suppressDragLeaveHidesColumns={true}
            onFilterChanged={agSettings.onFilterChanged}
            enableCellChangeFlash={true}
            rowMultiSelectWithClick={agSettings.rowMultiSelectWithClick}
          />
        ) : (
          <SpinContainer indicator={loadingOutlined} />
        )}
        <div id="myChart" className={`${agGridThemeName} my-chart`} />
      </div>
    </>
  );
};

export default Compendium;
