import { all } from 'redux-saga/effects';
import { watchPostObjectSaga } from './app/postObjectSaga';
import { watchDeleteObjectSaga } from './directory/deleteObjectSaga';
import {
  watchInlineHistorySaga,
  watchReloadLastInlineHistoryDataSaga,
  watchRevertHistoryByType,
} from './directory/inlineHistorySaga';
import { watchOpenSocketSaga } from './map/socketDataGetterSaga';
import { watchDirectoryGetMainDataRequest } from '../reducers/directoryV2/sagas';
import { watchTimePlayerSaga } from './map/timePlayerSaga';
import {
  watchEditableLayersHistorySaga,
  watchInitializeMapEditModeSaga,
  watchReloadLastEditableLayersHistoryDataSaga,
  watchRevertEditableLayersHistoryByType,
} from './map/editableLayersHistorySaga';
import { watchMapTableApplyFilterSaga } from './map/mapTableApplyFilterSaga';
import { watchMapSelectedObjectSaga } from './map/mapSelectedObjectSaga';
import { watchSingleObjectEditableSaga } from './map/standaloneObjectEditableSaga';
import { watchGetRelatedDataForLayerSaga } from './map/getRelatedDataForLayerSaga';
import { watchInitializeAddLayerToMapSaga } from './map/addLayerToMapSaga';
import { watchSpecializingObjectEditableSaga } from './map/overpassesAddingSegmentEditableSaga';
import { watchStartCalculationSaga } from './directory/startCalculationSaga';
import { watchPostObjectSagaErrorResponse } from './app/postObjectSagaErrorResponse';
import { watchWidgetsSage } from './widgets/WidgetsSaga';

/**
 * Используется в: создание саги
 * Обычная функция-генератор, в которую нужно заносить любой вотчер для саги
 */

export default function* rootSaga() {
  yield all([
    watchPostObjectSaga(),
    watchPostObjectSagaErrorResponse(),
    watchDeleteObjectSaga(),
    watchStartCalculationSaga(),

    watchInlineHistorySaga(),
    watchReloadLastInlineHistoryDataSaga(),
    watchRevertHistoryByType(),

    watchEditableLayersHistorySaga(),
    watchReloadLastEditableLayersHistoryDataSaga(),
    watchRevertEditableLayersHistoryByType(),
    watchInitializeMapEditModeSaga(),

    watchOpenSocketSaga(),
    watchTimePlayerSaga(),
    watchMapTableApplyFilterSaga(),
    watchMapSelectedObjectSaga(),

    watchSpecializingObjectEditableSaga(),
    watchSingleObjectEditableSaga(),
    watchGetRelatedDataForLayerSaga(),
    watchInitializeAddLayerToMapSaga(),

    // Directory new version
    watchDirectoryGetMainDataRequest(),

    // Widgets
    watchWidgetsSage(),
  ]);
}
