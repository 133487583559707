import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Button } from '../../../components/Button/Button';
import styled from 'styled-components/macro';
import { Modal } from 'antd';
import MapContainer from './MapContainer';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { PenIcon } from 'UI/custom/icons/objectIcons/PenIcon';
import { iconSizesKit } from 'styles/utils/iconSizesKit';
import { AnyObject } from 'types/enums/general/general.model';

const LocalModal = styled(Modal)``;

const MapWrap = styled.div`
  height: calc(100vh - 200px);
  position: relative;
  & > button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 20px;
    left: 20px;
  }
`;

const StartUploadButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  & .shitTrader {
    width: 300px;
    & p {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
    height: 40px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.defaultLighter};
  }
`;

interface IUploadModalProps {
  isOpenModal: boolean;
  setOpenModal: (status: boolean) => void;
  field: string;
  object?: { [k: string]: any };
  setCustomFieldsData?: (prop: AnyObject) => void;
  fieldType?: string;
}

export const ModalMapContainer = (props: PropsWithChildren<IUploadModalProps>) => {
  const [changePosition, setChangePosition] = useState<boolean>(false);
  const [newPosition, setNewPosition] = useState<string | undefined>();

  const { isOpenModal, setOpenModal, field, object, setCustomFieldsData, fieldType } = props;

  const hideModal = useCallback(() => setOpenModal(false), [setOpenModal]);
  const clickEditButton = useCallback(() => setChangePosition((prevValue) => !prevValue), []);

  const savePositionsObject = useCallback(() => {
    if (newPosition && setCustomFieldsData) {
      setCustomFieldsData((prevValue: AnyObject) => ({ ...prevValue, [field]: newPosition }));
    }
    hideModal();
  }, [newPosition, field, hideModal, setCustomFieldsData]);

  const viewChangeGeometry = !!setCustomFieldsData;

  const FooterContant = useMemo(
    () =>
      viewChangeGeometry ? (
        <StartUploadButton>
          <Button className={'shitTrader'} onClick={savePositionsObject}>
            Запомнить расположение объекта
          </Button>
        </StartUploadButton>
      ) : null,
    [savePositionsObject, viewChangeGeometry]
  );

  return (
    <LocalModal
      title={viewChangeGeometry ? 'Выберите местоположение объекта' : 'Маршрут'}
      visible={isOpenModal}
      onOk={hideModal}
      onCancel={hideModal}
      centered={true}
      width={1600}
      footer={FooterContant}
    >
      <MapWrap>
        <MapContainer
          field={fieldType}
          object={object}
          newPosition={newPosition}
          setNewPosition={setNewPosition}
          changePosition={changePosition}
        />
        {viewChangeGeometry && (
          <Button
            type={ButtonType.primary}
            rounded
            icon={<PenIcon />}
            p={'none'}
            iconSize={iconSizesKit.largest}
            onClick={clickEditButton}
          />
        )}
      </MapWrap>
    </LocalModal>
  );
};
