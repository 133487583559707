import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const PublicTransportItemCard = ({ handleCloseMapSelectedObjectInfo, selectedMapItem }) => {
  let Descriptions = [];

  Descriptions.push({
    key: 'Наименование маршрута',
    value: selectedMapItem?.selectedObject?.route_name,
  });

  Descriptions.push({
    key: 'Длина маршрута',
    value: selectedMapItem?.selectedObject?.length + ' м',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={''}
    />
  );
};

PublicTransportItemCard.propTypes = {};

export default PublicTransportItemCard;
