import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';

import {
  MapMarkerRoadSingGroup,
  MapMarkerRoadSingGroupAction,
} from 'store/reducers/map/mapMarkersRoadSingGroup/mapMarkers.model';

class MapMarkersRoadSingGroupActions {
  /** action types */
  public readonly prefix: string = 'MAP_MARKERS_ROAD_SING_GROUP';
  public readonly ADD_SING_GROUP: string = `${this.prefix}.ADD_SING_GROUP`;
  public readonly REMOVE_SING_GROUP: string = `${this.prefix}.REMOVE_SING_GROUP`;

  /** actions */
  public addMapMarkerRoadSingGroup: ActionFunction1<MapMarkerRoadSingGroup, Action<MapMarkerRoadSingGroupAction>> =
    createAction(this.ADD_SING_GROUP, (selectingSing: MapMarkerRoadSingGroup) => ({ selectingSing }));

  public removeMapMarkerRoadSingGroup: ActionFunction0<Action<null>> = createAction(this.REMOVE_SING_GROUP);
}

export const mapMarkersRoadSingGroupActions = new MapMarkersRoadSingGroupActions();
