import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { basePath } from 'registrators/map/layers/layersRegistrator';

import { Form, InputNumber } from 'antd';

import { useSavePdf } from 'hooks/reports/useSavePdf';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Checkbox } from 'UI/common/components/Checkbox/Checkbox';
import _ from 'underscore';

import { Styled } from './PrintButtonModal.styled';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { useDispatch } from 'react-redux';

const { StartUploadButton, LocalModal, FormContainer, ContainerLable, CheckboxWrapper } = Styled;

interface IPrintButtonModal {
  isOpenModal: boolean;
  setOpenModal: (status: boolean) => void;
  directory?: string;
  idStatement?: number | null;
  numberFreeBlank: number | null;
}

export const PrintButtonModal = (props: PropsWithChildren<IPrintButtonModal>) => {
  const dispatch = useDispatch();
  const { isOpenModal, setOpenModal, numberFreeBlank, directory, idStatement } = props;
  const [startCountApplicationForms, setStartCountApplicationForms] = useState<number | null>(null);
  const [flag, setFlag] = useState(false);
  const { savePdf } = useSavePdf();
  useEffect(() => {
    setStartCountApplicationForms(numberFreeBlank);
  }, [numberFreeBlank]);

  const postNewStatus = useCallback(async () => {
    try {
      const url = !flag
        ? `${basePath}/${IOPWDirectory.special_permits}/${directory}/${idStatement}/print/?form_account_number=${startCountApplicationForms}`
        : `${basePath}/${IOPWDirectory.special_permits}/${directory}/${idStatement}/print/?test=true`;
      await savePdf(url);
      setOpenModal(false);
    } catch (e) {
      if (_.isObject(e) && e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message,
          })
        );
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
      }
    }
  }, [flag, directory, idStatement, startCountApplicationForms, savePdf, setOpenModal, dispatch]);

  const startChangeStatusButton = useMemo(
    () => (
      <StartUploadButton>
        <Button className={'shitTrader'} width={'100%'} type={ButtonType.primary} onClick={postNewStatus}>
          <p>Печать</p>
        </Button>
      </StartUploadButton>
    ),
    [postNewStatus]
  );

  const onChangeCheckboxHandler = () => {
    setFlag((prevState) => !prevState);
  };
  return (
    <LocalModal
      title={`Распечатать бланк строгой отчетности`}
      visible={isOpenModal}
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      centered={true}
      footer={startChangeStatusButton}
    >
      <FormContainer>
        <Form.Item>
          <ContainerLable>Номер бланка:</ContainerLable>
          <InputNumber
            disabled={flag}
            value={startCountApplicationForms ?? 0}
            onChange={(value) => setStartCountApplicationForms(value ?? null)}
          />
          <CheckboxWrapper>
            <Checkbox value={flag} onChange={onChangeCheckboxHandler}>
              Тестовая печать
            </Checkbox>
          </CheckboxWrapper>
        </Form.Item>
      </FormContainer>
    </LocalModal>
  );
};
