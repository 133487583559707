import React, { SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authApi } from 'api/authApi';
import { Styled } from './PasswordRemember.style';
import Input from 'UI/common/components/Input/Input';
import { StateModel } from 'store/reducers';

const {
  LocalMainWrapper,
  LocalLoginForm,
  LocalLoginFormTitle,
  LocalFormContentWrapper,
  LocalInputWrapper,
  LocalPrimaryDescription,
  LocalMainWraper,
  ExtendedButton,
  ExtendedNavLink,
  LocalLoginFormTitleDescription,
} = Styled;

/**
 * Component provides Login user form and starts login process
 * @returns Login form
 * @constructor none
 */
const PasswordRemember = () => {
  const [userData, setUserData] = useState({
    email: '',
  });
  const [completed, setCompleted] = useState(false);

  const user = useSelector((state: StateModel) => state.auth.user);
  const dispatch = useDispatch();
  const sessionUser = localStorage.user;

  const handleChange = (email: string) => {
    setUserData({ email: email });
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    authApi.passwordReset(userData.email).then(() => {
      setCompleted(true);
    });
  };

  const checkUser = () => {
    if (sessionUser && !user) {
      dispatch({
        type: 'LOGIN_USER',
        payload: sessionUser,
      });
    }
  };

  checkUser();
  return (
    <LocalMainWrapper>
      {!completed ? (
        <LocalLoginForm>
          <LocalLoginFormTitle>Восстановление</LocalLoginFormTitle>
          <LocalFormContentWrapper onSubmit={handleSubmit}>
            <LocalInputWrapper>
              <Input onChange={handleChange} placeholder={'Электронная почта'} />
            </LocalInputWrapper>
            <ExtendedButton
              onClick={(e: SyntheticEvent) => handleSubmit(e)}
              type={'primary'}
              hasShadow
              p={'1.3rem'}
              fontSize={'1.15rem'}
              width={'100%'}
            >
              Восстановить пароль
            </ExtendedButton>
            <ExtendedNavLink to={'/guest/login'}>
              <LocalPrimaryDescription>Вернуться ко входу</LocalPrimaryDescription>
            </ExtendedNavLink>
          </LocalFormContentWrapper>
        </LocalLoginForm>
      ) : (
        <LocalLoginForm className={'login-form'}>
          <LocalMainWraper>
            <LocalLoginFormTitle className={'login-form__title'}>Успешно!</LocalLoginFormTitle>
            <LocalLoginFormTitleDescription>
              Письмо с дальнейшими инструкциями по восстановлению пароля отправлено на вашу электронную почту
            </LocalLoginFormTitleDescription>
            <ExtendedNavLink to={'/guest/login'}>
              <LocalPrimaryDescription>Вернуться ко входу</LocalPrimaryDescription>
            </ExtendedNavLink>
          </LocalMainWraper>
        </LocalLoginForm>
      )}
    </LocalMainWrapper>
  );
};

export default PasswordRemember;
