import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import { logger } from 'redux-logger';
import { CURRENT_APP_MODE } from '../types/constants/constants';
import { createBrowserHistory } from 'history';
import { reducers } from './reducers';

const combineMiddleware = (baseMiddleware: Middleware[], conditionalMiddleware: Middleware[]) => {
  if (CURRENT_APP_MODE === 'development') {
    return [...baseMiddleware, ...conditionalMiddleware];
  } else {
    return [...baseMiddleware];
  }
};

export const history = createBrowserHistory();

function configureStore(initialState?: any) {
  const sageMiddleware = createSagaMiddleware();

  // @ts-ignore
  const composeEnhancers =
    // @ts-ignore
    typeof window === 'object' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // @ts-ignore
        window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const baseMiddleware = [thunk, sageMiddleware];
  const conditionalMiddleware = [logger];
  const combinedMiddleware = combineMiddleware(baseMiddleware, conditionalMiddleware);
  const enhancer = composeEnhancers(applyMiddleware(...combinedMiddleware));
  const store = createStore(reducers, initialState, enhancer);
  sageMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();
