import React from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import useGlobal from '../../store';
import { getFromURL } from '../../../../../../../../api/getFromURL';
import { postToURLSingle } from '../../../../../../../../api/postToURLSingle';
import withContentPadding from '../../../../../../../../HOC/withContentPadding';
import _ from 'underscore';
import { notificationActions } from '../../../../../../../../store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../../../../../../types/enums/UI/ActiveStatus.model';
import { PromptType } from '../../../../../../../../types/enums/UI/PromptType.model';
import { createAlertMessage } from '../../../../../../../../store/sagas/app/createAlertMessage';
import StatisticOfShipments from './StatisticOfShipments';
import { saveAs } from 'file-saver';

const StatisticOfShipmentsContainer = () => {
  const [globalState] = useGlobal();
  const dispatch = useDispatch();
  const sendData = () => {
    async function postData() {
      const response = await getFromURL.getDataFromURL('/reports/statement');
      const json = await response;
      const rewriteJson = { ...json };
      const { email, checkedItems, extensions, timeStart, timeEnd } = globalState;
      const valuesArray = _.values(checkedItems);
      rewriteJson.email = email;
      rewriteJson.mime_type = extensions;
      rewriteJson.periodicity = 'bla-bla-bla'; //это поле требуется бэку, на результат не влияет
      rewriteJson.filters[1].value = valuesArray.includes('individual') ? 1 : 0;
      rewriteJson.filters[2].value = valuesArray.includes('entity') ? 1 : 0;
      rewriteJson.filters[0].value = [timeStart, timeEnd];
      try {
        const postResult = await postToURLSingle.postDataReports('/reports/statement', rewriteJson, extensions);

        if (extensions === 'text/html') {
          const win = window.open(`about:blank`, '_blank');
          win.document.write(postResult);
          win.focus();
        }
        if (extensions === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          const result = await postToURLSingle.postDataReports('/reports/statement', rewriteJson, extensions, true);
          const blob = new Blob([result], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, 'report.xls');
        }
      } catch (e) {
        if (e?.response && _.isObject(e.response)) {
          const message = createAlertMessage(e);
          dispatch(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: 'Ошибка',
              message: message,
            })
          );
        }
      } finally {
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 9000);
      }
    }
    postData();
  };

  return (
    <div>
      <StatisticOfShipments sendData={sendData} />
    </div>
  );
};

const enhance = compose(withContentPadding, connect(null, null));
export default enhance(StatisticOfShipmentsContainer);
