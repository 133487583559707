import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import ITSLayer from 'types/classes/ITSLayer';
import BrigadeApplicationsLayer from '../../InstanceLayers/BrigadeApplications/BrigadeApplicationsLayer';
import BrigadeWorkShiftsLayer from '../../InstanceLayers/BrigadeApplications/BrigadeWorkShiftsLayer';
import BrigadeMonitoringLayer from '../../InstanceLayers/BrigadeApplications/BrigadeMonitoringLayer';
import { Settings } from 'types/enums/map/layers/Settings';

class BrigadeApplicationsCompositeLayer extends ITSLayer<any, any> {
  renderLayers() {
    if (!this.props.relatedData) return [];

    const sizeScale = this?.props?.sizeScale ?? 1;
    const opacity = this.props?.opacity ?? 0;
    const layerInstanceCommonProps = {
      onClickInstance: this.props.onClickHandler,
      sizeScale: sizeScale,
      opacity: opacity,
      selectedObject: this.props.selectedObject,
    };

    const workShiftsData = {
      [MapObjects.brigadeWorkShiftsApplications]: this.props.relatedData?.[MapObjects.brigadeWorkShiftsApplications],
      [MapObjects.brigadeWorkShiftsTrack]: this.props.relatedData?.[MapObjects.brigadeWorkShiftsTrack],
      [MapObjects.brigadeWorkShifts]: this.props.relatedData?.[MapObjects.brigadeWorkShifts],
    };

    return [
      new BrigadeApplicationsLayer({
        ...layerInstanceCommonProps,
        data: this.props.relatedData?.[MapObjects.brigadeApplications],
      }),
      new BrigadeWorkShiftsLayer({
        ...layerInstanceCommonProps,
        data: workShiftsData,
      }),
      new BrigadeMonitoringLayer({
        ...layerInstanceCommonProps,
        data: this.props.relatedData?.[MapObjects.brigadeMonitoring],
        showVehiclesSetting: this.props[Settings.showVehicles],
      }),
    ];
  }
}

BrigadeApplicationsCompositeLayer.layerName = Layers.brigadeApplications;

export default BrigadeApplicationsCompositeLayer;
