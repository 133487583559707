import { AnyObject } from 'types/enums/general/general.model';
import { PropsWithChildren } from 'react';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { IApplicationFormProps } from '../../interfacesApplications/interfacesApplications';
import { createInputs } from './createInputs';

interface IprepareSingleGroupsProps {
  model: ModelResponse;
  data?: AnyObject[] | AnyObject;
  formProps: IApplicationFormProps;
}

export function prepareSinglePanels({ formProps, model, data }: PropsWithChildren<IprepareSingleGroupsProps>) {
  const missingGroups = Object.keys(model.scheme);
  return createInputs(model, missingGroups, formProps.form, data);
}
