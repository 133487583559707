import React, { useMemo, useState } from 'react';
import { DeckContainer } from './Deck/DeckContainer';
import { MapDisplayModeContainer } from './MapDisplayMode/MapDisplayModeContainer';
import withMapModuleContext from './moduleContext/withMapModuleContext';
import { MapStandaloneEditModeContainer } from './MapStandaloneEditMode/MapStandaloneEditModeContainer';
import { useParams } from 'react-router-dom';
import { WebMercatorViewport } from 'deck.gl';

export interface IMapbox {
  standaloneEditModeEnabled?: boolean;
}

/**
 * Компонент подготовки карты и режимов карты
 * @param {boolean} standaloneEditModeEnabled Включен ли режим редактирования
 * @returns {(MapStandaloneEditModeContainer | MapDisplayModeContainer) & DeckContainer}
 * @see MapStandaloneEditModeContainer
 * @see MapDisplayModeContainer
 * @see DeckContainer
 */
const Mapbox = ({ standaloneEditModeEnabled }: IMapbox) => {
  const [isPrintMode, setIsPrintMode] = useState(false);
  const [deckViewRef, setDeckViewRef] = useState<WebMercatorViewport | null>(null);

  let { params } = useParams<{ params: string }>();
  if (params === 'print_mode' && !isPrintMode) setIsPrintMode(true);

  const currentMapModuleToRender = useMemo(() => {
    if (standaloneEditModeEnabled && !isPrintMode) {
      return <MapStandaloneEditModeContainer />;
    }
    if (!standaloneEditModeEnabled && !isPrintMode) {
      return <MapDisplayModeContainer deckViewRef={deckViewRef} />;
    }
    return null;
  }, [deckViewRef, isPrintMode, standaloneEditModeEnabled]);

  return (
    <>
      {currentMapModuleToRender}
      <DeckContainer setDeckViewRef={setDeckViewRef} deckViewRef={deckViewRef} />
    </>
  );
};

export default withMapModuleContext(Mapbox);
