import * as axios from 'axios';

class CancelToken {
  constructor(initialValue) {
    this.source = initialValue;
    this.isCanceled = false;
    this.tokenAwaitCanceled = this.source?.token?.promise?.then((response) => {
      this.isCanceled = response.__CANCEL__;
    });
  }

  getSource() {
    return this.source;
  }

  setSource(value) {
    this.source = value ? value : axios.CancelToken.source();
    this.isCanceled = false;
    this.tokenAwaitCanceled = this.source?.token?.promise?.then((response) => {
      this.isCanceled = response.__CANCEL__;
    });
  }

  cancel(message) {
    this.source.cancel(message ? message : null);
  }
}

export const cancelToken = new CancelToken(axios.CancelToken.source());
