import { handleActions, Reducer } from 'redux-actions';
import { mapboxDataActions } from './mapboxData.actions';
import { MapboxDataState } from './mapboxData.model';
import { MapReducer } from '../../reducers.model';
import _ from 'underscore';
import { Layers } from 'types/enums/map/layers/Layers';
import { IRelatedDataObject } from '../../../../types/interfaces/map/mapboxData/mapboxData';
import { AnyObject } from 'types/enums/general/general.model';
import lodash from 'lodash';

const {
  ADD_MAPBOX_DATA,
  PUSH_TO_MAPBOX_DATA,
  PUSH_ARRAY_TO_MAPBOX_DATA,
  PUSH_TO_MAPBOX_RELATED_DATA,
  PUSH_ARRAY_TO_MAPBOX_RELATED_DATA,
  ADD_MAPBOX_RELATED_DATA,
  CLONE_MAPBOX_RELATED_DATA,
  RESET_RELATED_DATA_FROM_CLONE_RELATED_DATA,
  ADD_MAPBOX_MULTIPLE_RELATED_DATA,
  REPLACE_CERTAIN_FIELD_IN_RELATED_DATA,
  REPLACE_ALL_MAPBOX_RELATED_DATA,
  REPLACE_ALL_MAPBOX_SIMPLY_DATA,
  RESET_MAPBOX_STORE,
  SET_ACTIVE_DATA_REPLACING_FORCED_STATUS,
  SET_DISABLE_DATA_REPLACING_FORCED_STATUS,
  UPDATE_RELATED_DATA_SOCKET,
  UPDATE_OBJECT_AFTER_CHANGE,
  REPLACE_RELATED_DATA_BY_KEY,
  DELETE_RELATED_DATA_OBJECT_BY_KEY,
  REFRESH_DATA_AFTER_DELETE_OBJECT,
} = mapboxDataActions;

const initialState = {};

const resetMapboxDataState: Reducer<MapboxDataState, null> = () => {
  return initialState;
};
const updateObjectAfterChange: Reducer<
  MapboxDataState,
  { RelatedDataObject: IRelatedDataObject; layerName: Layers; dataToPost: any }
> = (state, { payload }) => {
  const { RelatedDataObject, layerName, dataToPost } = payload;
  return {
    ...state,
    [layerName]: {
      ...state[layerName],
      relatedData: {
        ...state[layerName].relatedData,
        [RelatedDataObject.name]: state[layerName].relatedData[RelatedDataObject.name].map((el: any) => {
          if (el.id === dataToPost.id) {
            return dataToPost;
          }
          return el;
        }),
      },
    },
  };
};
const refreshDataAfterDeleteObject: Reducer<
  MapboxDataState,
  { RelatedDataObject: { name: string }; layerName: Layers; dataToPost: any }
> = (state, { payload }) => {
  const { RelatedDataObject, layerName, dataToPost } = payload;
  return {
    ...state,
    [layerName]: {
      ...state[layerName],
      relatedData: {
        ...state[layerName].relatedData,
        [RelatedDataObject.name]: state[layerName].relatedData[RelatedDataObject.name].filter(
          (el: any) => el.id !== dataToPost.id
        ),
      },
    },
  };
};
const addMapboxData: Reducer<MapboxDataState, { key: Layers; value: any }> = (state, { payload }) => {
  const { key, value } = payload;
  return {
    ...state,
    [key]: {
      ...state[key],
      data: value,
    },
  };
};
const replaceFieldInRelatedData: Reducer<MapboxDataState, { layerName: Layers; relatedKey: any; data: any[] }> = (
  state,
  { payload }
) => {
  const { layerName, relatedKey, data } = payload;
  return {
    ...state,
    [layerName]: {
      ...state[layerName],
      relatedData: {
        ...state[layerName]?.relatedData,
        [relatedKey?.name]: data,
      },
    },
  };
};
const replaceAllRelatedData: Reducer<MapboxDataState, { layerName: Layers; newDataArray: any[] }> = (
  state,
  { payload }
) => {
  const { layerName, newDataArray } = payload;
  if (layerName && newDataArray) {
    return {
      ...state,
      [layerName]: {
        ...state[layerName],
        relatedData: {
          ...newDataArray,
        },
      },
    };
  }
  return state;
};
const replaceAllSimpleData: Reducer<MapboxDataState, { layerName: Layers; newDataArray: any[] }> = (
  state,
  { payload }
) => {
  const { layerName, newDataArray } = payload;
  if (layerName && newDataArray) {
    return {
      ...state,
      [layerName]: {
        ...state[layerName],
        data: {
          ...newDataArray,
        },
      },
    };
  }
  return state;
};
const pushToRelatedData: Reducer<MapboxDataState, { initiatorName: Layers; relatedDataKey: string; value: any[] }> = (
  state,
  { payload }
) => {
  const { initiatorName, relatedDataKey, value } = payload;
  if (state?.[initiatorName]?.relatedData?.[relatedDataKey]) {
    const relatedData = [...state?.[initiatorName]?.relatedData?.[relatedDataKey]];

    // TODO кто сюда просунул это логику? нужно особое поведение? значит заведите свой редьюсер и экшн Тех-долг-FRONTEND #5637
    // @ts-ignore
    const incomeRegistrationNumber = value?.registration_number;
    const updatedRelateDataIndex = relatedData?.findIndex((el) => el.registration_number === incomeRegistrationNumber);
    for (let key in value) {
      if (value.hasOwnProperty(key)) {
        relatedData[updatedRelateDataIndex][key] = value[key];
      }
    }

    return {
      ...state,
      [initiatorName]: {
        ...state[initiatorName],
        relatedData: {
          ...state[initiatorName].relatedData,
          [relatedDataKey]: [...relatedData],
        },
      },
    };
  } else {
    return state;
  }
};
// TODO в этом редьюсере дыра в типизации Тех-долг-FRONTEND #5637
const pushToMainData: Reducer<MapboxDataState, { key: Layers; value: any[] }> = (state, { payload }) => {
  const { key, value } = payload;
  const valueCopy = { ...value };
  // TODO дыра в типизации, нужно переработать Тех-долг-FRONTEND #5637
  // @ts-ignore
  const coordinates = [valueCopy?.point.longitude, valueCopy?.point.latitude];
  // @ts-ignore
  delete valueCopy?.point;
  const properties: any = { ...valueCopy };
  const dataIndex = state?.[key]?.data?.findIndex((el: any) => el?.device_id === properties?.device_id);
  if (dataIndex >= 0) {
    const dataCopy = state?.[key].data;
    const objOld = _.clone(dataCopy[dataIndex]);
    delete objOld.timeReceived;
    const objNew = {
      coordinates: coordinates,
      ...properties,
    };
    delete objNew.timeReceived;
    if (_.isEqual(objOld, objNew)) {
      return state;
    }
    dataCopy[dataIndex] = {
      coordinates: coordinates,
      ...properties,
    };
    return {
      ...state,
      [key]: {
        ...state[key],
        data: [...dataCopy],
      },
    };
  } else if (state?.[key]?.data) {
    return {
      ...state,
      [key]: {
        ...state[key],
        data: [
          ...state[key].data,
          {
            coordinates: coordinates,
            ...properties,
          },
        ],
      },
    };
  } else {
    return {
      ...state,
      [key]: {
        ...state[key],
        data: [
          {
            coordinates: coordinates,
            ...properties,
          },
        ],
      },
    };
  }
};

const pushArrayToMainData: Reducer<MapboxDataState, { key: Layers; value: any[] }> = (state, { payload }) => {
  const { key, value } = payload;
  const valueCopy = [...value];
  let newStateBranch = [...state[key].data];
  for (let item of valueCopy) {
    const coordinates = [item?.point.longitude, item?.point.latitude];
    delete item.point;
    const properties = { ...item };
    const dataIndex = newStateBranch?.findIndex((el) => el?.device_id === properties?.device_id);

    if (dataIndex >= 0) {
      newStateBranch[dataIndex] = {
        coordinates: coordinates,
        ...properties,
      };
    } else if (state?.[key]?.data) {
      newStateBranch = [
        ...newStateBranch,
        {
          coordinates: coordinates,
          ...properties,
        },
      ];
    } else {
      newStateBranch = [
        {
          coordinates: coordinates,
          ...properties,
        },
      ];
    }
  }
  return {
    ...state,
    [key]: {
      ...state[key],
      data: [...newStateBranch],
    },
  };
};

const pushArrayToRelatedData: Reducer<
  MapboxDataState,
  { initiatorName: Layers; relatedDataKey: string; value: any[] }
> = (state, { payload }) => {
  const { initiatorName, relatedDataKey, value } = payload;
  return {
    ...state,
    [initiatorName]: {
      ...state[initiatorName],
      relatedData: {
        ...state[initiatorName]?.relatedData,
        [relatedDataKey]: [...value],
      },
    },
  };
};

const addRelatedData: Reducer<MapboxDataState, { parentKey: Layers; childKey: string; data: any[] }> = (
  state,
  { payload }
) => {
  const { parentKey, childKey, data } = payload;
  if (state?.[parentKey]?.relatedData) {
    return {
      ...state,
      [parentKey]: {
        ...state[parentKey],
        relatedData: {
          ...state[parentKey]['relatedData'],
          [childKey]: data,
        },
      },
    };
  } else {
    return {
      ...state,
      [parentKey]: {
        ...state[parentKey],
        relatedData: {
          [childKey]: data,
        },
      },
    };
  }
};

const cloneRelatedData: Reducer<MapboxDataState, { selectedLayerName: Layers; selectedGroupOfObjectsType: string }> = (
  state: any,
  { payload }
) => {
  const { selectedLayerName } = payload;

  if (state?.[selectedLayerName]?.relatedData) {
    return {
      ...state,
      fullRelatedData: {
        ...state.fullRelatedData,
        [selectedLayerName]: {
          ...state[selectedLayerName],
        },
      },
    };
  }
};

const resetRelatedDataFromCloneRelatedData: Reducer<MapboxDataState, null> = (state: any) => {
  if (state?.fullRelatedData) {
    return {
      ...state.fullRelatedData,
      fullRelatedData: null,
    };
  } else {
    return state;
  }
};
/**
 * Используется при вызове слоя с несколькими типами объектов, формирует стэйт
 * @param state
 * @param payload
 */
const addMultipleRelatedData: Reducer<MapboxDataState, { parentKey: Layers; childKey: string; data: any[] }[]> = (
  state,
  { payload }
) => {
  const arrayOfRelatedData = payload;
  let stateCopy = { ...state };
  for (let value of arrayOfRelatedData) {
    const { parentKey, childKey, data } = value;
    if (stateCopy?.[parentKey]?.relatedData) {
      stateCopy = {
        ...stateCopy,
        [parentKey]: {
          ...stateCopy[parentKey],
          relatedData: {
            ...stateCopy[parentKey]['relatedData'],
            [childKey]: data,
          },
        },
      };
    } else {
      stateCopy = {
        ...stateCopy,
        [parentKey]: {
          ...stateCopy[parentKey],
          relatedData: {
            [childKey]: data,
          },
        },
      };
    }
  }
  return { ...stateCopy };
};
const activateForceStatus: Reducer<MapboxDataState, null> = (state) => {
  return { ...state, isDataReplacingForced: true };
};
const disableForceStatus: Reducer<MapboxDataState, null> = (state) => {
  return { ...state, isDataReplacingForced: false };
};

const updateRelatedDataSocket: Reducer<
  MapboxDataState,
  { layerName: Layers; relatedDataKey: string; objectKeyUpdate: string; value: any[] }
> = (state, { payload }) => {
  const { layerName, relatedDataKey, value, objectKeyUpdate } = payload;

  if (state?.[layerName]?.relatedData?.[relatedDataKey]) {
    const newRelatedData = [...state[layerName].relatedData[relatedDataKey]];

    for (const item of value) {
      const index = newRelatedData.findIndex((el) => el?.[objectKeyUpdate] === item?.[objectKeyUpdate]);

      newRelatedData[index] = { ...item };
    }

    return {
      ...state,
      [layerName]: {
        ...state?.[layerName],
        relatedData: {
          ...state?.[layerName]?.relatedData,
          [relatedDataKey]: newRelatedData,
        },
      },
    };
  } else {
    return {
      ...state,
      [layerName]: {
        ...state?.[layerName],
        relatedData: {
          ...state?.[layerName]?.relatedData,
          [relatedDataKey]: [...value],
        },
      },
    };
  }
};

const replaceRelatedDataByKey: Reducer<
  MapboxDataState,
  { relatedDataKey: string; layerName: Layers; newRelatedDataObject: AnyObject }
> = (state, { payload }) => {
  const { layerName, relatedDataKey, newRelatedDataObject } = payload;
  const cloneRelatedData: AnyObject[] = lodash.cloneDeep(state[layerName]?.relatedData?.[relatedDataKey]);
  const indexOldObject = cloneRelatedData?.findIndex((el) => el.id === newRelatedDataObject.id);
  if (indexOldObject !== -1) {
    cloneRelatedData[indexOldObject] = newRelatedDataObject;
  } else {
    cloneRelatedData.push(newRelatedDataObject);
  }
  return {
    ...state,
    [layerName]: {
      ...state?.[layerName],
      relatedData: {
        ...state?.[layerName]?.relatedData,
        [relatedDataKey]: cloneRelatedData,
      },
    },
  };
};

const replaceRelatedDataDeleteObject: Reducer<
  MapboxDataState,
  { relatedDataKey: string; layerName: Layers; deletingObjectId: AnyObject }
> = (state, { payload }) => {
  const { layerName, relatedDataKey, deletingObjectId } = payload;
  const cloneRelatedData: AnyObject[] = state[layerName]?.relatedData?.[relatedDataKey].filter(
    (data: AnyObject) => data.id !== deletingObjectId
  );

  return {
    ...state,
    [layerName]: {
      ...state?.[layerName],
      relatedData: {
        ...state?.[layerName]?.relatedData,
        [relatedDataKey]: cloneRelatedData,
      },
    },
  };
};

const mapReducer: MapReducer<MapboxDataState, any> = {
  [UPDATE_OBJECT_AFTER_CHANGE]: updateObjectAfterChange,
  [REFRESH_DATA_AFTER_DELETE_OBJECT]: refreshDataAfterDeleteObject,
  [RESET_MAPBOX_STORE]: resetMapboxDataState,
  [ADD_MAPBOX_DATA]: addMapboxData,
  [REPLACE_CERTAIN_FIELD_IN_RELATED_DATA]: replaceFieldInRelatedData,
  [REPLACE_ALL_MAPBOX_RELATED_DATA]: replaceAllRelatedData,
  [REPLACE_ALL_MAPBOX_SIMPLY_DATA]: replaceAllSimpleData,
  [PUSH_TO_MAPBOX_RELATED_DATA]: pushToRelatedData,
  [PUSH_TO_MAPBOX_DATA]: pushToMainData,
  [PUSH_ARRAY_TO_MAPBOX_DATA]: pushArrayToMainData,
  [PUSH_ARRAY_TO_MAPBOX_RELATED_DATA]: pushArrayToRelatedData,
  [ADD_MAPBOX_RELATED_DATA]: addRelatedData,
  [CLONE_MAPBOX_RELATED_DATA]: cloneRelatedData,
  [RESET_RELATED_DATA_FROM_CLONE_RELATED_DATA]: resetRelatedDataFromCloneRelatedData,
  [ADD_MAPBOX_MULTIPLE_RELATED_DATA]: addMultipleRelatedData,
  // TODO чекнуть зачем это нужно Тех-долг-FRONTEND #5800
  [SET_ACTIVE_DATA_REPLACING_FORCED_STATUS]: activateForceStatus,
  [SET_DISABLE_DATA_REPLACING_FORCED_STATUS]: disableForceStatus,
  [UPDATE_RELATED_DATA_SOCKET]: updateRelatedDataSocket,
  [REPLACE_RELATED_DATA_BY_KEY]: replaceRelatedDataByKey,
  [DELETE_RELATED_DATA_OBJECT_BY_KEY]: replaceRelatedDataDeleteObject,
};

export const mapboxDataReducer: Reducer<MapboxDataState, any> = handleActions(mapReducer, initialState);
