import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { getFromURL } from 'api/getFromURL';
import { useTheme } from 'styled-components';
import { PrintIcon } from 'UI/custom/icons/actionIcons/PrintIcon';

import { IButton } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { iconSize } from '../../interfacesCargoTransport/templates';
import { PrintButtonModal } from './PrintButtonModal';
import { Styled } from './PrintButtonModal.styled';
import { ApplicationTheme } from '../../../../../../../styles/themes/model/ApplicationTheme.model';

const { LocalDiv, CreatBlankButton } = Styled;

export const PrintButtonStatementModal = (props: PropsWithChildren<IButton>) => {
  const newIconSize = useRef(iconSize);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [numberFreeBlank, setNumberFreeBlank] = useState<number | null>(null);
  const { isDisabled, directory, idStatement, tooltip } = props;
  const theme = useTheme() as ApplicationTheme;
  const openModal = useCallback(async () => {
    const response = await getFromURL.getAll(`special_permits/forms_account/get_free_number/`);
    setNumberFreeBlank(response ?? null);
    setOpenModal(true);
  }, []);

  return (
    <LocalDiv>
      <CreatBlankButton
        onClick={openModal}
        icon={<PrintIcon />}
        rounded
        hasShadow
        disabled={isDisabled}
        type={'danger'}
        iconColor={theme.colors.primary}
        iconSize={newIconSize.current}
        tooltip={tooltip}
      />
      <PrintButtonModal
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        numberFreeBlank={numberFreeBlank}
        directory={directory}
        idStatement={idStatement}
      />
    </LocalDiv>
  );
};
