import styled from 'styled-components/macro';

const LocalMainWrapper = styled.aside`
  height: calc(100% - 40px);
  padding-top: 100px;
  width: 52px;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const LocalItemsGroup = styled.section`
  & + & {
    margin-top: ${(props) => props.theme.margins.defaultMargin};
  }
  & > * {
    & + * {
      margin-top: ${(props) => props.theme.margins.defaultMargin};
    }
  }
`;

export const Styled = { LocalMainWrapper, LocalItemsGroup };
