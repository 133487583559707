import styled from 'styled-components';

export const CompendiumContainer = styled.div`
  height: 75%;
  margin: 0 16px 16px 40px;
  border-radius: 16px;
  .ag-cell:first-child {
    display: inline;
  }
  .STATUS_CHOICE {
    font-weight: 500;
    font-size: 14px;
  }
  .NEW {
    color: ${(props) => props.theme.colors.lightblue};
  }
  .REGISTERED {
    color: ${(props) => props.theme.colors.darkblue};
  }
  .REGISTRATION_DENIED {
    color: ${(props) => props.theme.colors.gray};
  }
  .IN_WORK {
    color: ${(props) => props.theme.colors.orange};
  }
  .AWAITING_AGREED {
    color: ${(props) => props.theme.colors.purple_600};
  }
  .AWAITING_AGREED_GBDD {
    color: ${(props) => props.theme.colors.purple_300};
  }
  .REFUSED_TO_ISSUE {
    color: ${(props) => props.theme.colors.gray};
  }
  .AWAITING_PAYMENT {
    color: ${(props) => props.theme.colors.purple};
  }
  .READY_TO_ISSUE {
    color: ${(props) => props.theme.colors.success};
  }
  .ISSUED_BY {
    color: ${(props) => props.theme.colors.darkgreen};
  }
  .CANCELED {
    color: ${(props) => props.theme.colors.grey};
  }
  .empty_cell {
    color: ${(props) => props.theme.colors.danger};
  }
  .ag-checked::after {
    color: ${(props) => props.theme.colors.primary} !important;
  }
  * {
    ::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.defaultLighter};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutralDark};
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.neutralDarker};
    }
  }
`;
