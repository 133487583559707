import { RelatedDataPrototype } from 'registrators/map/layers/description/relatedData/prototype/RelatedDataPrototype';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { CamerasService } from 'generated/api/api';
import { getFromURL } from 'api/getFromURL';
import { RequestPath } from 'types/enums/routes/request/RequestPath';

export class CamerasUds extends RelatedDataPrototype {
  constructor() {
    super({
      name: MapObjects.camerasUdc,
      filterLabel: 'Камеры',
      selectedObjectName: MapObjects.camerasUdc,
      urlData: RequestPath.camerasUdc,
    });
  }

  async uploadData() {
    return await CamerasService.camerasList();
  }

  async deleteRecord(id: number) {
    return await CamerasService.camerasDestroy({ id });
  }

  async readRecord(id: number) {
    return await CamerasService.camerasRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.camerasUdc);
  }
}
