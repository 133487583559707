import { axiosInstance } from 'services/axiosInstances/axiosGuest';

/**
 * Методы обновления и сброса пароля
 * @type {{passwordReset(*): Promise<boolean>, refreshToken(): Promise<boolean>}}
 */
export const authApi = {
  async refreshToken() {
    const response = await axiosInstance.post(
      '/users/token/refresh/',
      {
        refresh: localStorage.getItem('token_refresh'),
        username: localStorage.getItem('user'),
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );
    if (response.data.message) {
      return false;
    } else {
      localStorage.setItem('token_access', response.data.access);
      localStorage.setItem('token_refresh', response.data.refresh);
      return true;
    }
  },
  /**
   *
   * @param email валидный почтовый ящик на который будет отправлен новый пароль
   * @returns {Promise<boolean>}
   */
  async passwordReset(email: string) {
    const response = await axiosInstance.post(
      '/users/password/reset/',
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );
    return response.status === 200;
  },
  async passwordConfirm(new_password1: string, new_password2: string, uid?: string, token?: string) {
    const response = await axiosInstance.post(
      '/users/password/reset/confirm/',
      {
        new_password1,
        new_password2,
        uid,
        token,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    );
    // return response.status === 200;
    return response?.data ?? response;
  },
};
