import { SiderInterface, DirectoryPayloadInterface } from '../interfaces';

export type SiderElementType = {
  label: string;
  key: string | number;
  url: string;
  [key: string]: any;
};

export enum ContentRenderType {
  AgGrid = 'ag_grid',
  Form = 'form',
}

export type BreadcrumbType = {
  label: string;
  path?: string;
  key: string;
  url: string;
  sider?: SiderInterface;
  params: any;
  isInline?: boolean;
  parentData?: any;
};

export type getDirectoryPayload = DirectoryPayloadInterface & {
  contentRenderType?: ContentRenderType.AgGrid | ContentRenderType.Form;
};

export type UpdateDirectoryPayload = DirectoryPayloadInterface & {
  activeTab: string;
  label: string;
  key?: string;
};

export type UpdateBreadcrumbsPayload = DirectoryPayloadInterface & {
  breadcrumbIndex: number;
  sider?: SiderInterface;
};
