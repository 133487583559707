import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { useTheme } from 'styled-components';
import { DownloadIcon } from 'UI/custom/icons/actionIcons/DownloadIcon';

import { IButton } from '../../interfacesCargoTransport/interfacesCargoTransport';
import { iconSize } from '../../interfacesCargoTransport/templates';
import { PrintButtonFederalStatement } from './PrintButtonFederalStatement';
import { Styled } from './PrintFederalStatement.styled';
import { ApplicationTheme } from '../../../../../../../styles/themes/model/ApplicationTheme.model';

const { LocalDiv, CreatBlankButton } = Styled;

export const PrintFederalStatementModal = (props: PropsWithChildren<IButton>) => {
  const newIconSize = useRef(iconSize);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const { isDisabled, idStatement, tooltip } = props;

  const theme = useTheme() as ApplicationTheme;
  const openModal = useCallback(async () => setOpenModal(true), []);

  return (
    <LocalDiv>
      <CreatBlankButton
        onClick={openModal}
        icon={<DownloadIcon />}
        rounded
        hasShadow
        disabled={isDisabled}
        type={'danger'}
        iconColor={theme.colors.primary}
        iconSize={newIconSize.current}
        tooltip={tooltip}
      />
      <PrintButtonFederalStatement isOpenModal={isOpenModal} setOpenModal={setOpenModal} idStatement={idStatement} />
    </LocalDiv>
  );
};
