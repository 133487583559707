export enum RegisteredReportsUrl {
  roadAccident = 'road_accident',
  roadStats = 'road_stats',
  roadAccidentSections = 'road_accident_sections',
  statisticsCargoTransportation = 'statistics_cargo_transportation',
  roadSignsByRoad = 'road_signs_by_road',
  roadMarkingLineAreaAndVolumeReport = 'road_marking_line_area_and_volume_report',
  crossRoadPerDistrict = 'cross_road_per_district',
  trafficIntensity = 'traffic_intensity',
}
