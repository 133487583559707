import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class RoadFences extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_fences', // АКА border/ped_border
      filterLabel: 'Дорожные ограждения',
      selectedObjectName: MapObjects.roadFences,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsRoadFencesList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsRoadFencesDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsRoadFencesRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadFences);
  }
}
