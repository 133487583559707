import styled from 'styled-components/macro';

const ButtonGroupWrapper = styled.div<{ vertical?: boolean }>`
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  display: flex;
  & *:first-child {
    border-radius: 8px 8px 0 0;
  }
  & *:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  & *:last-child {
    border-radius: 0 0 8px 8px;
  }
  & *:not(:first-child) {
    margin: 0 !important;
  }
  & * + * {
    border-top: ${(props) => `1px solid ${props.theme.colors.neutralLight}`};
  }
`;

export const Styled = { ButtonGroupWrapper };
