import React, { useEffect, useMemo } from 'react';

import { BrigadeWorkShiftsSelectionI } from './BrigadeWorkShiftsSelection.model';
import { setStateApplicationsForWorkShift } from './utils/setStateApplicationsForWorkShift';
import { useDispatch } from 'react-redux';

const BrigadeWorkShiftsSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: BrigadeWorkShiftsSelectionI) => {
  const dispatch = useDispatch();
  const workShiftsId = useMemo(() => data.id, [data.id]);

  useEffect(() => {
    setStateApplicationsForWorkShift(workShiftsId, dispatch);
  }, [dispatch, workShiftsId]);

  return <></>;
};

export default BrigadeWorkShiftsSelection;
