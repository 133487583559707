import { Layout } from 'antd';
import styled from 'styled-components/macro';

const { Sider } = Layout;

const SiderContainerOnMap = styled(Sider)`
  height: 97vh;
  margin: 15px 15px 0 0;
  z-index: 1000;
`;

export const Styled = { SiderContainerOnMap };
