import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';
import { CameraWidget } from 'generated/api/api';

class WidgetsActions {
  /** action types */
  public readonly prefix: string = 'WIDGETS';
  public readonly TOGGLE_PANEL_VISIBLE: string = `${this.prefix}.TOGGLE_PANEL_VISIBLE`;
  public readonly CHANGE_PANEL_VISIBLE: string = `${this.prefix}.CHANGE_PANEL_VISIBLE`;
  public readonly CHANGE_EDIT_WIDGET: string = `${this.prefix}.CHANGE_EDIT_WIDGET`;
  public readonly SET_WIDGETS: string = `${this.prefix}.SET_WIDGETS`;
  public readonly SET_CURRENT_WIDGET: string = `${this.prefix}.SET_CURRENT_WIDGET`;

  /** actions */
  public togglePanelVisible: ActionFunction0<Action<any>> = createAction(this.TOGGLE_PANEL_VISIBLE);

  public changePanelVisible: ActionFunction1<boolean, Action<{ newStatus: boolean }>> = createAction(
    this.CHANGE_PANEL_VISIBLE,
    (newStatus: boolean) => ({ newStatus })
  );

  public changeEditWidget: ActionFunction1<number | null, Action<{ id: number | null }>> = createAction(
    this.CHANGE_EDIT_WIDGET,
    (id: number | null) => ({ id })
  );

  public setWidgets: ActionFunction1<Array<CameraWidget>, Action<{ widgets: Array<CameraWidget> }>> = createAction(
    this.SET_WIDGETS,
    (widgets: Array<CameraWidget>) => ({ widgets })
  );

  public setCurrentWidget: ActionFunction1<CameraWidget, Action<{ widget: CameraWidget }>> = createAction(
    this.SET_CURRENT_WIDGET,
    (widget: CameraWidget) => ({ widget })
  );
}

export const widgetsActions = new WidgetsActions();
