import React from 'react';
import Icon from '@ant-design/icons';

const CrossIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8333 5.34199L14.6583 4.16699L9.99996 8.82533L5.34163 4.16699L4.16663 5.34199L8.82496 10.0003L4.16663 14.6587L5.34163 15.8337L9.99996 11.1753L14.6583 15.8337L15.8333 14.6587L11.175 10.0003L15.8333 5.34199Z"
      fill="#EB5757"
    />
  </svg>
);

export const CrossIcon = (props) => <Icon component={CrossIconSVG} {...props} />;
