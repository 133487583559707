import React from 'react';
import { commonSelectedObjectProtoUtils } from '../commonUtils/commonSelectedObjectProtoUtils';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const { parseCoordinatesToDesc } = commonSelectedObjectProtoUtils;

const FerryCrossingsItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'ferry_crossings');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/${DirectoryRouteModel.artificial_structures_ferry_crossings}/${id}/`,
      '_blank'
    );
    win.focus();
  };

  //Словарь для способа передвижения парома
  const moveMethodMap = new Map([
    ['SELF_PROPELLED', 'Самоходный'],
    ['TOWING', 'Буксирный'],
    ['CABLE', 'Канатный'],
    ['HINGED', 'Навесной'],
  ]);

  //Словарь для типа пирса
  const pierMap = new Map([
    ['SINGLE', 'Одиночный'],
    ['DOUBLE', 'Двойной'],
    ['DOUBLE_SOCKET', 'Двойной раструбный'],
  ]);

  let Descriptions = [];

  Descriptions.push({
    key: 'Координаты',
    value: parseCoordinatesToDesc(selectedMapItem),
  });

  const properties = data;

  Descriptions.push({
    key: 'Наименование дороги',
    value: properties.segment_name,
  });

  Descriptions.push({
    key: 'Способ передвижения парома',
    value: moveMethodMap.get(properties.move_method) ?? 'Не указан',
  });

  Descriptions.push({
    key: 'Пирс',
    value: pierMap.get(properties.pier) ?? 'Не указан',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные об объекте'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

FerryCrossingsItemSelection.propTypes = {};

export default FerryCrossingsItemSelection;
