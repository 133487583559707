import styled from 'styled-components/macro';
import { Button } from '../../../../../../../../../../UI/common/components/Button/Button';

const LocalMainWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExtendedButton = styled(Button)`
  margin-right: 16px;
  width: 84px;
  height: 84px;
  font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.lightBlueOpacity};
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: white;
  }
  &:focus {
    background: ${(props) => props.theme.colors.primary};
    color: white;
  }
`;

export const Styled = { LocalMainWrapper, ExtendedButton };
