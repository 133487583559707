export const objectAllIconMapperProject = {
  pedestrianFences: { x: 0, y: 0, width: 128, height: 128 },
  pedestrianFences_danger: { x: 0, y: 129, width: 128, height: 128 },
  pedestrianFences_active: { x: 129, y: 0, width: 128, height: 128 },
  streetLamp: { x: 129, y: 129, width: 128, height: 128 },
  streetLamp_danger: { x: 0, y: 258, width: 128, height: 128 },
  streetLamp_active: { x: 129, y: 258, width: 128, height: 128 },
  speedBump: { x: 258, y: 0, width: 128, height: 128 },
  speedBump_danger: { x: 258, y: 129, width: 128, height: 128 },
  speedBump_active: { x: 258, y: 258, width: 128, height: 128 },
};
