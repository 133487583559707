const EDIT_GROUP = 'edit';
const ACTION_GROUP = 'action';
const ADD_GROUP = 'add';

export const groupsList = [
  { type: EDIT_GROUP, title: 'Редактировать' },
  { type: ADD_GROUP, title: 'Добавить' },
  { type: ACTION_GROUP, title: 'Действия' },
];

export const groupTypes = { EDIT_GROUP, ACTION_GROUP, ADD_GROUP };
