export enum MapContextMenuItem {
  dataConcerning = 'Data concerning',
  roadSection = 'Road section',
  checkpoint = 'Checkpoint',
  createRoadSign = 'create road signs',
  roadSigns = 'road_signs',
  bridges = 'bridges',
  trafficLight = 'traffic_light',
  overpasses = 'overpasses',
  railCrossings = 'rail_crossings',
  pipes = 'pipes',
  improvementElements = 'improvement_elements',
  lighting = 'lighting',
  restAreas = 'rest_areas',
  weatherStations = 'weather_stations',
  pipelines = 'pipelines',
  tunnels = 'tunnels',
  ferryCrossings = 'ferry_crossings',
  roadWays = 'road_ways',
  supportWall = 'support_wall',
  roadFences = 'road_fences',
  pedestrianFences = 'pedestrian_fences',
  speedBumps = 'speed_bumps',
  supportPillars = 'support_pillars',
  roadAccident = 'roadAccident',
  roadWorks = 'roadWorks',
  sidewalk = 'sidewalk',
  dividingLines = 'dividing_lines',
  tramRails = 'tram_rails',
  blockades = 'Blockades',
  openInNewDirectory = 'Open in directory',
  deleteLand = 'Delete land',
  dataAndLocation = 'Data and location',
  deleteItem = 'Delete',
  rulerLength = 'rulerLength',
  rulerArea = 'rulerArea',
  createSegment = 'create segment',
  createCargoTrackingPoint = 'createCargoTrackingPoint',
  createSecurityCheckpoint = 'create security checkpoint',
  createRoadBlock = 'create road block',
  createRoadWorks = 'create road work',
  createDischargeZone = 'create discharge zone',
  createReceptionArea = 'create reception area',
  createRoadOverpass = 'create road overpass',
  createDockSegments = 'create dock segment',
  controlRoomsSegment = 'create control rooms segment',
  CreateRoadAccident = 'Create Road Accident',
  tramCables = 'create tram cable',
  tramSupplyCables = 'create tram supply cable',
  createCablesTroll = 'create cables troll',
  substations = 'substations',
  createSubstations = 'Create substations',
  supplyCablesTroll = 'create cables troll',
  roadMarkings = 'road markings',
  roadMarkingsLines = 'road markings lines',
  roadMarkingsPolygons = 'road markings polygons',
  createRoadMarkings = 'Create road markings',
  busStops = 'bus_stops',
  createBusStops = 'Create bus_stops',
  trafficLightObject = 'traffic light object',
  signalPoles = 'signal poles',
  contoursLawn = 'contours lawn',
  townSquares = 'town squares',
  busShelters = 'bus shelters',
  engineeringConstructions = 'engineeringConstructions',
  /** Камеры/детекторы */
  camerasAndDetectors = 'cameras_and_detectors',

  /** Пешеходные переходы */
  illuminatedPedestrianCrossings = 'illuminated_pedestrian_crossings',

  /** Заявки*/
  brigadeApplications = 'brigade_applications',

  /** Камеры УДС*/
  camerasUdc = 'Камеры УДС',
}
