import React from 'react';
import Icon from '@ant-design/icons';

const FilterRemoveIconSVG = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.2748 17.3583L14.6498 15L12.2748 12.6417L13.4581 11.4667L15.8331 13.8083L18.1664 11.4667L19.3498 12.6417L17.0081 15L19.3498 17.3583L18.1664 18.5333L15.8331 16.1667L13.4581 18.5333L12.2748 17.3583V17.3583ZM10.8331 16.5667C10.8665 16.8167 10.7831 17.0833 10.5915 17.2583C10.5144 17.3356 10.4228 17.3969 10.322 17.4387C10.2212 17.4805 10.1131 17.502 10.004 17.502C9.89481 17.502 9.78674 17.4805 9.68593 17.4387C9.58512 17.3969 9.49355 17.3356 9.41645 17.2583L6.07478 13.9167C5.9839 13.8278 5.91479 13.7192 5.87286 13.5992C5.83094 13.4792 5.81734 13.3511 5.83312 13.225V8.95833L1.84145 3.85C1.70612 3.67628 1.64506 3.45605 1.67161 3.23744C1.69815 3.01883 1.81015 2.81962 1.98312 2.68333C2.14145 2.56667 2.31645 2.5 2.49978 2.5H14.1665C14.3498 2.5 14.5248 2.56667 14.6831 2.68333C14.8561 2.81962 14.9681 3.01883 14.9946 3.23744C15.0212 3.45605 14.9601 3.67628 14.8248 3.85L10.8331 8.95833V16.5667V16.5667ZM4.19978 4.16667L7.49978 8.38333V12.9833L9.16645 14.65V8.375L12.4665 4.16667H4.19978Z'
      fill='currentColor' />
  </svg>
);

export const FilterRemoveIcon = (props) => <Icon component={FilterRemoveIconSVG} {...props} />;
