import { useMemo } from 'react';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { DirectoryRouteModel } from 'types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { BusSheltersSelectionSelectionProps } from './model/BusSheltersSelectionSelection.model';

const BusSheltersSelectionSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: BusSheltersSelectionSelectionProps) => {
  const { data: selectedObjectData } = selectedMapItem;
  const name = selectedObjectData?.[0]?.model?.scheme.name;
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedObjectData.find(
      (dataRecord: { name: string }) => dataRecord.name === MapObjects.busShelters
    );
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roadConstructionsBusShelters}/${id}/`, '_blank');
    win?.focus();
  };

  let Descriptions = useMemo(
    () => [
      {
        key: name.label,
        value: data.name ?? 'Не указан',
      },
    ],
    [data.name, name.label]
  );

  return (
    <PrepareScrollableInfoCard
      title={'Данные об автобусной остановке'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default BusSheltersSelectionSelection;
