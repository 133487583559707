import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFromURL } from '../../../../../../../../api/getFromURL';
import { postToURLSingle } from '../../../../../../../../api/postToURLSingle';
import withContentPadding from '../../../../../../../../HOC/withContentPadding';
import RoadSignsByRoad from './RoadSignsByRoad';
import { notificationActions } from '../../../../../../../../store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../../../../../../types/enums/UI/ActiveStatus.model';
import { PromptType } from '../../../../../../../../types/enums/UI/PromptType.model';
import { createAlertMessage } from '../../../../../../../../store/sagas/app/createAlertMessage';
import { saveAs } from 'file-saver';
import _ from 'underscore';

const RoadSignsByRoadContainer = () => {
  const reports = useSelector((state) => state.reports);
  const dispatch = useDispatch();

  const sendData = () => {
    async function postData() {
      const response = await getFromURL.getDataFromURL('/reports/road_signs_by_road');
      const json = await response;
      const rewriteJson = { ...json };
      const { checkedItems, checkedItemsAnother, extension } = reports;

      rewriteJson.periodicity = 'bla-bla-bla'; //это поле требуется бэку, на результат не влияет
      rewriteJson.mime_type = extension;
      rewriteJson.filters.road.value = checkedItems;
      rewriteJson.filters.road_sign_number.value = checkedItemsAnother;

      try {
        const postResult = await postToURLSingle.postDataReports('/reports/road_signs_by_road', rewriteJson, extension);

        if (extension === 'text/html') {
          const win = window.open(`about:blank`, '_blank');
          win.document.write(postResult);
          win.focus();
        }
        if (extension === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          const result = await postToURLSingle.postDataReports(
            '/reports/road_signs_by_road',
            rewriteJson,
            extension,
            true
          );
          const blob = new Blob([result], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          saveAs(blob, 'report.xls');
        }
      } catch (e) {
        if (e?.response && _.isObject(e.response)) {
          const message = createAlertMessage(e);
          dispatch(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: 'Ошибка',
              message: message,
            })
          );
        }
      } finally {
        setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 9000);
      }
    }
    postData();
  };

  return (
    <div>
      <RoadSignsByRoad sendData={sendData} />
    </div>
  );
};

export default withContentPadding(RoadSignsByRoadContainer);
