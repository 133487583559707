export const carActions = (store, carId) => {
  store.setState({ carId });
};
export const emailActions = (store, email) => {
  store.setState({ email });
};

export const extensionActions = (store, extensions) => {
  store.setState({ extensions });
};

export const moreCars = (store, moreCarsElement) => {
  store.setState({ moreCars: moreCarsElement });
};

export const filteredDataActions = (store, filteredDataActionsData) => {
  store.setState({ filteredDataActions: filteredDataActionsData });
};

export const filteredBelongGRZToCar = (store, sortedCarWithGRZ) => {
  store.setState({ filteredBelongGRZToCar: sortedCarWithGRZ });
};
export const grzActions = (store, checkedItems) => {
  store.setState({ checkedItems: checkedItems });
};
export const grzSecondActions = (store, checkedItemsAnother) => {
  store.setState({ checkedItemsAnother: checkedItemsAnother });
};
export const headersActions = (store, headers) => {
  store.setState({ headers });
};
export const ownerActions = (store, owner) => {
  store.setState({ ownerTS: owner });
};

export const selectActions = (store, select) => {
  store.setState({ select });
};
export const timeActionsStart = (store, time) => {
  store.setState({ timeStart: time });
};

export const timeActionsEnd = (store, time) => {
  store.setState({ timeEnd: time });
};

export const emptyStoreAction = (store) => {
  store.setState({
    checkedItems: [],
    checkedItemsAnother: [],
  });
};
