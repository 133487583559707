import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateModel } from 'store/reducers';
import { rolesDirectoryActions } from 'store/reducers/rolesDirectory/rolesDirectory.actions';
import { RoleElement, RolesDirectoryFields, RoleState } from 'store/reducers/rolesDirectory/rolesDirectory.model';
import _ from 'underscore';

import { DirectoriesEnum } from '../enums/DirectoriesEnum';
import { TabMenuEnum } from '../enums/TabMenuEnum';
import { ArrowHandler } from '../parts/ArrowHandler';
import { Styled } from '../parts/PermissionsPanel.styled';
import { RenderCollectionContainer } from '../parts/RenderCollectionContainer';
import { handleCheckedItem } from '../utils/functions';

const {
  LocalText,
  LocalSelectWrapper,
  LocalInnerWrapper,
  LocalMainWrapper,
  RenderListContainer,
  RenderListCheckboxWrapper,
  RenderListItemWrapper,
} = Styled;

export const RightsToSelectedDirectories = ({ pageMenuKey }: { pageMenuKey: DirectoriesEnum }) => {
  const rolesDirectory: RoleState = useSelector((state: StateModel) => state.rolesDirectory);
  const dispatch = useDispatch();
  const [allRight, setInAllRights] = useState<RoleElement[]>([]);
  const [checkedRight, setInCheckedRight] = useState<RoleElement[]>([]);
  const [filterDirectoriesRight, setInFilterDirectoriesRight] = useState<RoleElement[]>([]);
  const [input, setInput] = useState<string>('');
  const [inputRight, setInputRight] = useState<string>('');
  const [isCheckedLeftAllCheckbox, setIsCheckedLeftAllCheckbox] = useState<boolean>(false);
  const [isCheckedRightAllCheckbox, setIsCheckedRightAllCheckbox] = useState<boolean>(false);

  const { directoryDataFiltered, clickedRight } = rolesDirectory;

  useEffect(() => {
    setInCheckedRight(clickedRight?.[pageMenuKey]);
    setInFilterDirectoriesRight(clickedRight?.[pageMenuKey]);
  }, [clickedRight, pageMenuKey]);
  useEffect(() => {
    if (pageMenuKey === DirectoriesEnum.get_directory) {
      const getGeneralStructure = _.flatten(directoryDataFiltered?.[pageMenuKey]);
      const allValue = getGeneralStructure?.map((el: RoleElement) => {
        return {
          value: el.value,
          isChecked: false,
          id: el.id,
        };
      });
      setInAllRights(allValue);
    } else if (pageMenuKey === DirectoriesEnum.get_layers) {
      const setPermission = directoryDataFiltered?.[pageMenuKey]?.map((el) => {
        return {
          value: el.value,
          isChecked: false,
          id: el.id,
        };
      });
      setInAllRights(setPermission);
    }
    setIsCheckedLeftAllCheckbox(false);
    setIsCheckedRightAllCheckbox(false);
  }, [directoryDataFiltered, pageMenuKey]);

  const handleCheckedToSelected = useCallback(() => {
    const getClickedRight = allRight.filter((el) => el.isChecked);
    const getClickedRightFalse = allRight.filter((el) => !el.isChecked);

    const convertClickedRightNotTrue = getClickedRight.map((el) => {
      return {
        value: el.value,
        isChecked: false,
        id: el.id,
      };
    });
    dispatch(rolesDirectoryActions.clickedRightAction(convertClickedRightNotTrue, pageMenuKey));
    dispatch(
      rolesDirectoryActions.replaceFieldByKeyActions(
        {
          ...directoryDataFiltered,
          [pageMenuKey]: getClickedRightFalse,
        },
        RolesDirectoryFields.directoryDataFiltered
      )
    );
  }, [allRight, directoryDataFiltered, dispatch, pageMenuKey]);

  const handleCheckedToSelecting = useCallback(() => {
    const getCheckedDirectory = checkedRight.filter((el) => el.isChecked);
    const itemsNotTrue = getCheckedDirectory.map((el) => {
      return {
        value: el.value,
        isChecked: false,
        id: el.id,
      };
    });

    dispatch(rolesDirectoryActions.directoryDataFilteredActionReturn(itemsNotTrue, pageMenuKey));
    const getCheckedDirectoryFalse = checkedRight.filter((el) => !el.isChecked);
    const getID = getCheckedDirectoryFalse
      .map((el) => el.id)
      .flat()
      .filter((el) => el);

    if (pageMenuKey === DirectoriesEnum.get_directory) {
      // @ts-ignore TS не прав. все возможные undefined отмел фильтрацией массива.
      dispatch(rolesDirectoryActions.replaceFieldByKeyActions(getID, RolesDirectoryFields.idForRightDirec));
    }
    if (pageMenuKey === DirectoriesEnum.get_layers) {
      // @ts-ignore TS не прав. все возможные undefined отмел фильтрацией массива.
      dispatch(rolesDirectoryActions.replaceFieldByKeyActions(getID, RolesDirectoryFields.idForRightMap));
    }
    dispatch(
      rolesDirectoryActions.replaceFieldByKeyActions(
        { ...clickedRight, [pageMenuKey]: getCheckedDirectoryFalse },
        RolesDirectoryFields.clickedRight
      )
    );
  }, [checkedRight, clickedRight, dispatch, pageMenuKey]);

  const handleCheckElement = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === 'allRight') {
        handleCheckedItem(allRight, event, setInAllRights);
      } else if (event.target.value === 'allSelectedRight') {
        handleCheckedItem(checkedRight, event, setInCheckedRight);
      } else {
        handleCheckedItem(allRight, event, setInAllRights);
        handleCheckedItem(checkedRight, event, setInCheckedRight);
      }
      const filterElement = checkedRight.filter((el) => el.isChecked);
      const getID = filterElement
        .map((el) => el.id)
        .flat()
        .filter((el) => el);

      if (pageMenuKey === DirectoriesEnum.get_directory) {
        // @ts-ignore TS не прав. все возможные undefined отмел фильтрацией массива.
        dispatch(rolesDirectoryActions.replaceFieldByKeyActions(getID, RolesDirectoryFields.idForRightDirec));
      }
      if (pageMenuKey === DirectoriesEnum.get_layers) {
        // @ts-ignore TS не прав. все возможные undefined отмел фильтрацией массива.
        dispatch(rolesDirectoryActions.replaceFieldByKeyActions(getID, RolesDirectoryFields.idForRightMap));
      }
    },
    [allRight, checkedRight, dispatch, pageMenuKey]
  );

  const renderList = useCallback(
    (value) =>
      value?.map((item: RoleElement) => {
        return (
          <LocalSelectWrapper key={_.uniqueId()}>
            <RenderListContainer style={{ display: 'flex' }}>
              <RenderListCheckboxWrapper style={{ paddingTop: '5px' }}>
                <input
                  type="checkbox"
                  value={item.value}
                  checked={item.isChecked}
                  id={item.id?.toString()}
                  onChange={handleCheckElement}
                />
              </RenderListCheckboxWrapper>
              <RenderListItemWrapper style={{ paddingLeft: '20px' }}>
                <label htmlFor={item.value}>{item.value}</label>
              </RenderListItemWrapper>
            </RenderListContainer>
          </LocalSelectWrapper>
        );
      }),
    [handleCheckElement]
  );

  const title = TabMenuEnum[pageMenuKey];

  let customWords;
  switch (title) {
    case TabMenuEnum.get_directory:
      customWords = 'cправочникам';
      break;
    case TabMenuEnum.get_layers:
      customWords = 'картам';
      break;
    case TabMenuEnum.get_reports:
      customWords = 'отчётам';
      break;
    default:
      break;
  }

  const updateInputSelected = useCallback(
    (currentInput) => {
      const value = currentInput.target.value;
      const filtered = allRight?.filter((data) => {
        return data?.value?.toLowerCase().includes(value.toLowerCase());
      });
      setInput(value);
      setInAllRights(filtered);
    },
    [allRight]
  );

  const updateInputSelecting = useCallback(
    (currentInput) => {
      const value = currentInput.target.value;
      const filtered = checkedRight?.filter((data) => {
        return data?.value?.toLowerCase().includes(value.toLowerCase());
      });
      setInputRight(value);
      setInFilterDirectoriesRight(filtered);
    },
    [checkedRight]
  );

  return (
    <>
      <LocalMainWrapper>
        <LocalText>Права к выбранным {customWords}</LocalText>
        <LocalInnerWrapper>
          <RenderCollectionContainer
            updateInput={updateInputSelected}
            title="Выберите права"
            width={315}
            height={650}
            items={allRight}
            input={input}
            renderList={renderList}
            handleAllChecked={handleCheckElement}
            nameAllCheckbox={'allRight'}
            isChecked={isCheckedLeftAllCheckbox}
            setIsChecked={setIsCheckedLeftAllCheckbox}
          />
          <ArrowHandler handleCheckedRight={handleCheckedToSelected} handleCheckedLeft={handleCheckedToSelecting} />
          <RenderCollectionContainer
            title="Выбранные права"
            width={315}
            height={650}
            renderList={renderList}
            items={filterDirectoriesRight}
            input={inputRight}
            updateInput={updateInputSelecting}
            handleAllChecked={handleCheckElement}
            nameAllCheckbox={'allSelectedRight'}
            isChecked={isCheckedRightAllCheckbox}
            setIsChecked={setIsCheckedRightAllCheckbox}
          />
        </LocalInnerWrapper>
      </LocalMainWrapper>
    </>
  );
};
