import React, { PropsWithChildren, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Styled } from './style/CollapsableItem.styled';
import { CollapsableItemProps } from './model/CollapsableItem.model';
import { ArrowToUp } from '../../../custom/icons/actionIcons/ArrowToUp';

const { LocalMainWrapper, LocalTitleGroup, LocalTitleItem } = Styled;
/** Компонент скрывающегося списка*/
export const CollapsableItem = (props: PropsWithChildren<CollapsableItemProps>) => {
  const {
    titleContent,
    onClick,
    children,
    arrowIcon,
    transitionDurationInMs = 300,
    arrowIconOffset = '0.5rem',
    defaultOpened = true,
    rotateDegree = 180,
  } = props;
  const icon = arrowIcon || <ArrowToUp />;
  const [isOpened, setIsOpened] = useState(defaultOpened);

  return (
    <LocalMainWrapper transitionDurationInMs={transitionDurationInMs}>
      <LocalTitleGroup
        onClick={() => {
          onClick && onClick();
          setIsOpened((prev) => !prev);
        }}
      >
        <LocalTitleItem>{titleContent}</LocalTitleItem>
        <LocalTitleItem rotateDegree={rotateDegree} arrowIconOffset={arrowIconOffset} isOpened={isOpened}>
          {icon}
        </LocalTitleItem>
      </LocalTitleGroup>
      <Collapse onRest={console.log} onWork={console.log} isOpened={isOpened}>
        {children}
      </Collapse>
    </LocalMainWrapper>
  );
};
