import _ from 'lodash';
import { message } from 'antd';

import { Model, ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { Application, StatusF30Enum, UsersService } from 'generated/api/api';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';

export const prepareApplicationsList = async (model: ModelResponse, data: Application) => {
  const prepareModel: ModelResponse = _.cloneDeep(model);
  const prepareData: Application = _.cloneDeep(data);
  const prepareSchema: Model = {};

  const availableFields = ['status', 'object_characteristic'];

  // Подготовка схемы объекта для формы
  for (const field in prepareModel.scheme) {
    if (prepareModel.scheme.hasOwnProperty(field)) {
      const fieldObject = prepareModel.scheme[field];

      if (field === 'district' && !prepareData.id) {
        fieldObject.read_only = true;
      }

      if (field === 'appointed_team' && prepareData.id) {
        fieldObject.read_only = true;
      }

      if (field === 'status') {
        fieldObject.read_only = true;
      }

      if (field === 'object_characteristic') {
        fieldObject.type = SchemaFieldType.relatedReferenceField;
        fieldObject.reference = 'object_type';
        fieldObject.url = '/api/applications/get_object_characteristic_data/?object_type={object_type}';
      }

      if (!fieldObject.read_only || availableFields.includes(field)) {
        prepareSchema[field] = fieldObject;
      }
    }
  }

  prepareModel.scheme = prepareSchema;

  // Загрузка текущего пользователя, если он не задан
  if (!prepareData.author) {
    try {
      const userName = localStorage.getItem('user') ?? '';
      //@ts-ignore
      const { results } = await UsersService.usersList({ username: userName });
      const user = results[0];
      if (user) {
        prepareData.author = user.id;
      }
    } catch (e) {
      message.error('Ошибка при загрузке пользователя');
    }
  }

  // Подготовка данных объекта
  prepareData.status = prepareData.status ?? StatusF30Enum.CONFIRMED;
  prepareData.created_at = prepareData.created_at ?? new Date();

  return { prepareModel, prepareData };
};
