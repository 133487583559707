import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RoadsService } from '../../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class RoadMarkingPolygonProject extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_marking_polygon_project',
      filterLabel: 'Проектирумая дорожная разметка (Площадная)',
      selectedObjectName: MapObjects.projectLayerRoadMarkingPolygonProject,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsRoadMarkingPolygonProjectList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsRoadMarkingPolygonProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsRoadMarkingPolygonProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadMarkingPolygonProjection);
  }
}
