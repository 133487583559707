export const signsRoadMapperType5 = {
  5.1: { x: 1677, y: 1629, width: 88, height: 180 },
  '5.1-G': { x: 1419, y: 1448, width: 88, height: 180 },
  '5.1-R': { x: 1290, y: 1267, width: 88, height: 180 },
  5.2: { x: 1161, y: 1086, width: 88, height: 180 },
  '5.2-G': { x: 903, y: 905, width: 88, height: 180 },
  '5.2-R': { x: 774, y: 724, width: 88, height: 180 },
  5.3: { x: 627, y: 543, width: 88, height: 180 },
  '5.3-G': { x: 627, y: 362, width: 88, height: 180 },
  '5.3-R': { x: 627, y: 181, width: 88, height: 180 },
  5.4: { x: 1908, y: 1553, width: 88, height: 180 },
  '5.4-G': { x: 1908, y: 1734, width: 88, height: 180 },
  '5.4-R': { x: 0, y: 1945, width: 88, height: 180 },
  5.5: { x: 498, y: 181, width: 128, height: 180 },
  '5.5-G': { x: 498, y: 362, width: 128, height: 180 },
  '5.5-R': { x: 498, y: 543, width: 128, height: 180 },
  5.6: { x: 747, y: 0, width: 128, height: 180 },
  '5.6-G': { x: 747, y: 181, width: 128, height: 180 },
  '5.6-R': { x: 747, y: 362, width: 128, height: 180 },
  '5.7.1': { x: 258, y: 1810, width: 128, height: 114 },
  '5.7.1-G': { x: 387, y: 1810, width: 128, height: 114 },
  '5.7.1-R': { x: 516, y: 1810, width: 128, height: 114 },
  '5.7.2': { x: 645, y: 1810, width: 128, height: 114 },
  '5.7.2-G': { x: 774, y: 1810, width: 128, height: 114 },
  '5.7.2-R': { x: 903, y: 1810, width: 128, height: 114 },
  5.8: { x: 747, y: 543, width: 128, height: 180 },
  '5.8-G': { x: 0, y: 724, width: 128, height: 180 },
  '5.8-R': { x: 129, y: 724, width: 128, height: 180 },
  5.9: { x: 258, y: 724, width: 128, height: 180 },
  '5.9-G': { x: 387, y: 724, width: 128, height: 180 },
  '5.9-R': { x: 516, y: 724, width: 128, height: 180 },
  '5.10': { x: 645, y: 724, width: 128, height: 180 },
  '5.10-G': { x: 876, y: 0, width: 128, height: 180 },
  '5.10-R': { x: 876, y: 181, width: 128, height: 180 },
  '5.11.1': { x: 876, y: 362, width: 128, height: 180 },
  '5.11.1-G': { x: 876, y: 543, width: 128, height: 180 },
  '5.11.1-R': { x: 876, y: 724, width: 128, height: 180 },
  '5.11.2': { x: 0, y: 905, width: 128, height: 180 },
  '5.11.2-G': { x: 129, y: 905, width: 128, height: 180 },
  '5.11.2-R': { x: 258, y: 905, width: 128, height: 180 },
  '5.12.1': { x: 387, y: 905, width: 128, height: 180 },
  '5.12.1-G': { x: 516, y: 905, width: 128, height: 180 },
  '5.12.1-R': { x: 645, y: 905, width: 128, height: 180 },
  '5.12.2': { x: 774, y: 905, width: 128, height: 180 },
  '5.12.2-G': { x: 1005, y: 0, width: 128, height: 180 },
  '5.12.2-R': { x: 1005, y: 181, width: 128, height: 180 },
  '5.13.1': { x: 1005, y: 362, width: 128, height: 180 },
  '5.13.1-G': { x: 1005, y: 543, width: 128, height: 180 },
  '5.13.1-R': { x: 1005, y: 724, width: 128, height: 180 },
  '5.13.2': { x: 1005, y: 905, width: 128, height: 180 },
  '5.13.2-G': { x: 1134, y: 0, width: 128, height: 180 },
  '5.13.2-R': { x: 1134, y: 181, width: 128, height: 180 },
  '5.13.3': { x: 1134, y: 362, width: 128, height: 180 },
  '5.13.3-G': { x: 1134, y: 543, width: 128, height: 180 },
  '5.13.3-R': { x: 1134, y: 724, width: 128, height: 180 },
  '5.13.4': { x: 1134, y: 905, width: 128, height: 180 },
  '5.13.4-G': { x: 0, y: 1086, width: 128, height: 180 },
  '5.13.4-R': { x: 129, y: 1086, width: 128, height: 180 },
  '5.14.1': { x: 258, y: 1086, width: 128, height: 180 },
  '5.14.1-G': { x: 387, y: 1086, width: 128, height: 180 },
  '5.14.1-R': { x: 516, y: 1086, width: 128, height: 180 },
  '5.14.2': { x: 1908, y: 1380, width: 120, height: 172 },
  '5.14.2-G': { x: 645, y: 1086, width: 128, height: 180 },
  '5.14.2-R': { x: 774, y: 1086, width: 128, height: 180 },
  '5.14.3': { x: 903, y: 1086, width: 128, height: 180 },
  '5.14.3-G': { x: 1032, y: 1086, width: 128, height: 180 },
  '5.14.3-R': { x: 1263, y: 0, width: 128, height: 180 },
  5.14: { x: 1263, y: 181, width: 128, height: 180 },
  '5.14-G': { x: 1263, y: 362, width: 128, height: 180 },
  '5.14-R': { x: 1263, y: 543, width: 128, height: 180 },
  '5.15.1': { x: 1779, y: 0, width: 128, height: 134 },
  '5.15.1-G': { x: 1779, y: 135, width: 128, height: 134 },
  '5.15.1-R': { x: 1779, y: 270, width: 128, height: 134 },
  '5.15.2.1': { x: 1263, y: 724, width: 128, height: 180 },
  '5.15.2.1-G': { x: 1263, y: 905, width: 128, height: 180 },
  '5.15.2.1-R': { x: 1263, y: 1086, width: 128, height: 180 },
  '5.15.2.2': { x: 0, y: 1267, width: 128, height: 180 },
  '5.15.2.2-G': { x: 129, y: 1267, width: 128, height: 180 },
  '5.15.2.2-R': { x: 258, y: 1267, width: 128, height: 180 },
  '5.15.2.3': { x: 387, y: 1267, width: 128, height: 180 },
  '5.15.2.3-G': { x: 516, y: 1267, width: 128, height: 180 },
  '5.15.2.3-R': { x: 645, y: 1267, width: 128, height: 180 },
  '5.15.2.4': { x: 774, y: 1267, width: 128, height: 180 },
  '5.15.2.4-1': { x: 903, y: 1267, width: 128, height: 180 },
  '5.15.2.4-G': { x: 1032, y: 1267, width: 128, height: 180 },
  '5.15.2.4-G-1': { x: 1161, y: 1267, width: 128, height: 180 },
  '5.15.2.4-R': { x: 1392, y: 0, width: 128, height: 180 },
  '5.15.2.4-R-1': { x: 1392, y: 181, width: 128, height: 180 },
  '5.15.2.5': { x: 1392, y: 362, width: 128, height: 180 },
  '5.15.2.5-G': { x: 1392, y: 543, width: 128, height: 180 },
  '5.15.2.5-R': { x: 1392, y: 724, width: 128, height: 180 },
  '5.15.2': { x: 1392, y: 905, width: 128, height: 180 },
  '5.15.2d': { x: 1392, y: 1086, width: 128, height: 180 },
  '5.15.2d-G': { x: 1392, y: 1267, width: 128, height: 180 },
  '5.15.2d-R': { x: 0, y: 1448, width: 128, height: 180 },
  '5.15.2-G': { x: 129, y: 1448, width: 128, height: 180 },
  '5.15.2-R': { x: 258, y: 1448, width: 128, height: 180 },
  '5.15.3.1': { x: 387, y: 1448, width: 128, height: 180 },
  '5.15.3.1-G': { x: 516, y: 1448, width: 128, height: 180 },
  '5.15.3.1-R': { x: 645, y: 1448, width: 128, height: 180 },
  '5.15.3.2': { x: 774, y: 1448, width: 128, height: 180 },
  '5.15.3.2-G': { x: 903, y: 1448, width: 128, height: 180 },
  '5.15.3.2-R': { x: 1032, y: 1448, width: 128, height: 180 },
  '5.15.3.3': { x: 1161, y: 1448, width: 128, height: 180 },
  '5.15.3.3-G': { x: 1290, y: 1448, width: 128, height: 180 },
  '5.15.3.3-R': { x: 1521, y: 0, width: 128, height: 180 },
  '5.15.3': { x: 1521, y: 181, width: 128, height: 180 },
  '5.15.3-G': { x: 1521, y: 362, width: 128, height: 180 },
  '5.15.3-R': { x: 1521, y: 543, width: 128, height: 180 },
  '5.15.4.1': { x: 1521, y: 724, width: 128, height: 180 },
  '5.15.4.1-G': { x: 1521, y: 905, width: 128, height: 180 },
  '5.15.4.1-R': { x: 1521, y: 1086, width: 128, height: 180 },
  '5.15.4.2': { x: 1521, y: 1267, width: 128, height: 180 },
  '5.15.4.2-G': { x: 1521, y: 1448, width: 128, height: 180 },
  '5.15.4.2-R': { x: 1650, y: 0, width: 128, height: 180 },
  '5.15.4': { x: 1650, y: 181, width: 128, height: 180 },
  '5.15.4-G': { x: 1650, y: 362, width: 128, height: 180 },
  '5.15.4-R': { x: 1650, y: 543, width: 128, height: 180 },
  '5.15.5': { x: 1650, y: 724, width: 128, height: 180 },
  '5.15.5-G': { x: 1650, y: 905, width: 128, height: 180 },
  '5.15.5-R': { x: 1650, y: 1086, width: 128, height: 180 },
  '5.15.6': { x: 1650, y: 1267, width: 128, height: 180 },
  '5.15.6-G': { x: 1650, y: 1448, width: 128, height: 180 },
  '5.15.6-R': { x: 0, y: 1629, width: 128, height: 180 },
  '5.15.7.1': { x: 1779, y: 405, width: 128, height: 134 },
  '5.15.7.1-G': { x: 1779, y: 540, width: 128, height: 134 },
  '5.15.7.1-R': { x: 1779, y: 675, width: 128, height: 134 },
  '5.15.7.2': { x: 1779, y: 810, width: 128, height: 134 },
  '5.15.7.2-G': { x: 1779, y: 945, width: 128, height: 134 },
  '5.15.7.2-R': { x: 1779, y: 1080, width: 128, height: 134 },
  '5.15.7.3': { x: 129, y: 1629, width: 128, height: 180 },
  '5.15.7.3-G': { x: 258, y: 1629, width: 128, height: 180 },
  '5.15.7.3-R': { x: 387, y: 1629, width: 128, height: 180 },
  '5.15.7': { x: 1779, y: 1215, width: 128, height: 134 },
  '5.15.7-G': { x: 1779, y: 1350, width: 128, height: 134 },
  '5.15.7-R': { x: 1779, y: 1485, width: 128, height: 134 },
  '5.15.8': { x: 1779, y: 1620, width: 128, height: 134 },
  '5.15.8-G': { x: 0, y: 1810, width: 128, height: 134 },
  '5.15.8-R': { x: 129, y: 1810, width: 128, height: 134 },
  '5.16.2': { x: 0, y: 0, width: 248, height: 180 },
  '5.16.2-G': { x: 0, y: 181, width: 248, height: 180 },
  '5.16.2-R': { x: 249, y: 0, width: 248, height: 180 },
  5.16: { x: 89, y: 1945, width: 88, height: 180 },
  '5.16-G': { x: 178, y: 1945, width: 88, height: 180 },
  '5.16-R': { x: 267, y: 1945, width: 88, height: 180 },
  '5.17.2': { x: 249, y: 181, width: 248, height: 180 },
  '5.17.2-G': { x: 0, y: 362, width: 248, height: 180 },
  '5.17.2-R': { x: 249, y: 362, width: 248, height: 180 },
  5.17: { x: 356, y: 1945, width: 88, height: 180 },
  '5.17-G': { x: 445, y: 1945, width: 88, height: 180 },
  '5.17-R': { x: 534, y: 1945, width: 88, height: 180 },
  5.18: { x: 623, y: 1945, width: 88, height: 180 },
  '5.18-G': { x: 712, y: 1945, width: 88, height: 180 },
  '5.18-R': { x: 801, y: 1945, width: 88, height: 180 },
  '5.19.1-2': { x: 0, y: 543, width: 248, height: 180 },
  '5.19.1-2-G': { x: 249, y: 543, width: 248, height: 180 },
  '5.19.1-2-R': { x: 498, y: 0, width: 248, height: 180 },
  '5.19.1': { x: 516, y: 1629, width: 128, height: 180 },
  '5.19.1-G': { x: 645, y: 1629, width: 128, height: 180 },
  '5.19.1-R': { x: 774, y: 1629, width: 128, height: 180 },
  '5.19.2': { x: 903, y: 1629, width: 128, height: 180 },
  '5.19.2-G': { x: 1032, y: 1629, width: 128, height: 180 },
  '5.19.2-R': { x: 1161, y: 1629, width: 128, height: 180 },
  '5.20': { x: 1290, y: 1629, width: 128, height: 180 },
  '5.20-G': { x: 1419, y: 1629, width: 128, height: 180 },
  '5.20-R': { x: 1548, y: 1629, width: 128, height: 180 },
  5.21: { x: 890, y: 1945, width: 88, height: 180 },
  '5.21-G': { x: 979, y: 1945, width: 88, height: 180 },
  '5.21-R': { x: 1068, y: 1945, width: 88, height: 180 },
  5.22: { x: 1157, y: 1945, width: 88, height: 180 },
  '5.22-G': { x: 1246, y: 1945, width: 88, height: 180 },
  '5.22-R': { x: 1335, y: 1945, width: 88, height: 180 },
  5.23: { x: 1032, y: 1810, width: 128, height: 114 },
  '5.23-1': { x: 1161, y: 1810, width: 128, height: 114 },
  '5.23-G': { x: 1290, y: 1810, width: 128, height: 114 },
  '5.23-G-1': { x: 1419, y: 1810, width: 128, height: 114 },
  '5.23-R': { x: 1548, y: 1810, width: 128, height: 114 },
  '5.23-R-1': { x: 1677, y: 1810, width: 128, height: 114 },
  5.24: { x: 1908, y: 0, width: 128, height: 114 },
  '5.24-1': { x: 1908, y: 115, width: 128, height: 114 },
  '5.24-G': { x: 1908, y: 230, width: 128, height: 114 },
  '5.24-G-1': { x: 1908, y: 345, width: 128, height: 114 },
  '5.24-R': { x: 1908, y: 460, width: 128, height: 114 },
  '5.24-R-1': { x: 1908, y: 575, width: 128, height: 114 },
  5.25: { x: 1908, y: 690, width: 128, height: 114 },
  '5.25-G': { x: 1908, y: 805, width: 128, height: 114 },
  '5.25-R': { x: 1908, y: 920, width: 128, height: 114 },
  5.26: { x: 1908, y: 1035, width: 128, height: 114 },
  '5.26-G': { x: 1908, y: 1150, width: 128, height: 114 },
  '5.26-R': { x: 1908, y: 1265, width: 128, height: 114 },
  5.27: { x: 1424, y: 1945, width: 88, height: 180 },
  '5.27-G': { x: 1513, y: 1945, width: 88, height: 180 },
  '5.27-R': { x: 1602, y: 1945, width: 88, height: 180 },
  5.28: { x: 1691, y: 1945, width: 88, height: 180 },
  '5.28-G': { x: 1780, y: 1945, width: 88, height: 180 },
  '5.28-R': { x: 1869, y: 1945, width: 88, height: 180 },
  5.29: { x: 2037, y: 0, width: 88, height: 180 },
  '5.29-G': { x: 2037, y: 181, width: 88, height: 180 },
  '5.29-R': { x: 2037, y: 362, width: 88, height: 180 },
  '5.30': { x: 2037, y: 543, width: 88, height: 180 },
  '5.30-G': { x: 2037, y: 724, width: 88, height: 180 },
  '5.30-R': { x: 2037, y: 905, width: 88, height: 180 },
  5.31: { x: 2037, y: 1086, width: 88, height: 180 },
  '5.31-G': { x: 2037, y: 1267, width: 88, height: 180 },
  '5.31-R': { x: 2037, y: 1448, width: 88, height: 180 },
  5.32: { x: 2037, y: 1629, width: 88, height: 180 },
  '5.32-G': { x: 2037, y: 1810, width: 88, height: 180 },
  '5.32-R': { x: 2126, y: 0, width: 88, height: 180 },
  5.33: { x: 2126, y: 181, width: 88, height: 180 },
  '5.33-G': { x: 2126, y: 362, width: 88, height: 180 },
  '5.33-R': { x: 2126, y: 543, width: 88, height: 180 },
  5.34: { x: 2126, y: 724, width: 88, height: 180 },
  '5.34-G': { x: 2126, y: 905, width: 88, height: 180 },
  '5.34-R': { x: 2126, y: 1086, width: 88, height: 180 },
};
