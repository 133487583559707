import { useEffect, useState } from 'react';
import _ from 'lodash';

//setInputValue should be fn setValue from useContext/react-form-hook
export function useBaseInputPlaceholderControl(fieldVal, object, setInputValue) {
  const [placeholderState, setPlaceholderState] = useState(false);
  const [initialValue, setInitialValue] = useState(undefined);
  const value = _.get(object, fieldVal);

  if (object && value && initialValue !== value) {
    setInitialValue(value);
  }

  useEffect(() => {
    if (initialValue) {
      setInputValue(fieldVal, initialValue);
      setPlaceholderState(true);
    }
  }, [fieldVal, initialValue, setInputValue]);

  return {
    placeholderState,
    setInitialValue,
    initialValue,
    setPlaceholderState,
  };
}
