import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import CityDistrictsSelection from '../../../../layers/SelectedObjectsRepresentations/RoadSchemeItemSelection/CityDistrictsSelection';
import { layerDataAccessors } from '../description/dataAccessors';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class CityDistrictsSchemaLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.citydistricts,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.CityDistrictsAccessor(),
      relatedData: [new relatedLayerData.ekbRelatedDataForLayers.CityDistricts()],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.CityDistrictsCard(),
      customSettings: [],
      layerSchema: new layerSchemas.ekbLayersSchema.CityDistrictsLayerSchema(),
      selectedObjectPrototype: CityDistrictsSelection,
    });
  }
}
