import styled from 'styled-components';

export const SFileItemContainer = styled.div<{ isActive: boolean }>`
  background-color: ${(props) => (props.isActive ? 'rgba(47, 128, 237, 0.08)' : 'transparent')};
  height: 45px;
  user-select: none;
  cursor: pointer;

  .text {
    color: #6a7b9b;
  }

  &:hover {
    background-color: #6b8ae4;

    .text {
      color: #f5f5f5;
    }
  }
`;
