import styled from 'styled-components/macro';

const BaseHomePageWidgetCard = styled.section`
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Styled = { BaseHomePageWidgetCard };
