import { IconLayer, TextLayer } from '@deck.gl/layers';

import InstanceLayer from 'types/classes/InstanceLayer';
import { SocketVehicleI } from './BrigadeApplications.model';
import { vehiclesMonitoring } from '../../iconMappers/vehiclesMonitoring';
import { DSelector } from 'services/map/Dselector/DSelector';

class BrigadeMonitoringLayer extends InstanceLayer<SocketVehicleI, any> {
  renderLayers() {
    if (!this.props.data) return [];

    return [
      new IconLayer<SocketVehicleI>({
        id: 'brigade-monitoring-layer',
        data: this.props.data,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/vehiclesMonitoring.png`,
        iconMapping: vehiclesMonitoring,
        opacity: this?.props?.opacity ?? 0,
        autoHighlight: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => [d.point.longitude, d.point.latitude],
        getAngle: (d) => (!!d.azimuth ? parseInt(d.azimuth) : 0),
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        getIcon: (d) => d.transport_type,
        visible: this.props.showVehiclesSetting,
      }),
      new TextLayer<SocketVehicleI>({
        id: 'brigade-monitoring-speed-layer',
        data: this.props.data,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => [d.point.longitude, d.point.latitude],
        getText: (d) => `${d.speed} км/ч`,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        sizeMinPixels: 13,
        sizeMaxPixels: 13,
        getPixelOffset: () => [0, -20],
        getSize: 15,
        getColor: [0, 0, 0],
        visible: this.props.showVehiclesSetting,
      }),
    ];
  }
}

export default BrigadeMonitoringLayer;
