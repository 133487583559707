import React, { Dispatch, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { Controller, ErrorMessage, FieldValues, useForm } from 'react-hook-form';
import { Styled } from './Login.styled';
import { authenticationActions } from 'store/reducers/global/auth/auth.actions';
import { TokenObtainPair } from 'generated/api/api';
import { StateModel } from 'store/reducers';
import Input from 'UI/common/components/Input/Input';
import { LoginIcon } from 'UI/custom/icons/actionIcons/LoginIcon';
import { PasswordIcon } from 'UI/custom/icons/actionIcons/PasswordIcon';

const {
  LocalMainWrapper,
  LocalMainInner,
  LocalForm,
  LocalInputLogin,
  LocalPasswordInput,
  ExtendedButton,
  ExtendedNavLink,
  LocalMainTitle,
  LocalPrimaryDescription,
  ErrorMessageItem,
  ErrorMessageDanger,
} = Styled;

const Login = ({ userLoginFetch, alertMsg }: FieldValues) => {
  const formRef = useRef(null);

  const user = useSelector((state: StateModel) => state.auth.user);
  const dispatch = useDispatch();
  const sessionUser = localStorage.user;

  const handleSubmitWithFetch = (userData: FieldValues) => {
    userLoginFetch(userData);
  };

  const checkUser = () => {
    if (sessionUser && !user) {
      dispatch({
        type: 'LOGIN_USER',
        payload: sessionUser,
      });
    }
  };

  const onSubmit = (data: FieldValues) => {
    handleSubmitWithFetch(data);
  };

  const { errors, control, handleSubmit } = useForm({
    validateCriteriaMode: 'all',
    mode: 'onChange',
  });

  const errorProp = errors?.username?.type === 'pattern';
  const errorAlert = alertMsg.status === 'active';

  checkUser();
  return (
    <LocalMainWrapper>
      <LocalMainInner>
        <LocalMainTitle>Вход</LocalMainTitle>
        <LocalForm ref={formRef}>
          <LocalInputLogin>
            <Controller
              as={
                <Input
                  icon={<LoginIcon />}
                  placeholder={'Логин'}
                  editableHelp={'Введите логин'}
                  error={errorProp || errorAlert}
                />
              }
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Введите логин пользователя',
                },
                pattern: {
                  value: /^[a-zA-Z0-9_-]{3,16}$/,
                  message: 'Недопустимые символы',
                },
              }}
              name="username"
            />
          </LocalInputLogin>
          <ErrorMessage
            name="username"
            errors={errors}
            as={errorProp ? <ErrorMessageDanger /> : <ErrorMessageItem />}
          />
          <LocalPasswordInput>
            <Controller
              as={
                <Input
                  type={'password'}
                  icon={<PasswordIcon />}
                  error={errorAlert}
                  label={alertMsg.message}
                  placeholder={'Пароль'}
                  editableHelp={'Введите пароль'}
                />
              }
              name="password"
              prefix={<LockOutlined />}
              control={control}
              style={{ margin: '0 auto' }}
            />
          </LocalPasswordInput>
          <ExtendedButton
            onClick={handleSubmit((data: FieldValues) => {
              onSubmit(data);
            })}
            type={'primary'}
            hasShadow
            p={'1.3rem'}
            width={'100%'}
          >
            Войти
          </ExtendedButton>
        </LocalForm>
        {
          <ExtendedNavLink to={'/guest/login/remember'}>
            <LocalPrimaryDescription>Забыли пароль?</LocalPrimaryDescription>
          </ExtendedNavLink>
        }
      </LocalMainInner>

      {sessionUser && (
        <Redirect
          to={{
            pathname: '/app',
          }}
        />
      )}
    </LocalMainWrapper>
  );
};
//Todo realization state for input Тех-долг-FRONTEND #5611
//TODO style for ...PlaceHolderLogin Тех-долг-FRONTEND #5611

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  userLoginFetch: (userInfo: TokenObtainPair) => dispatch(authenticationActions.userLogin(userInfo)),
});

export default connect(null, mapDispatchToProps)(Login);
