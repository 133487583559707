import { IFormProps } from 'components/Application/ExtraModules/Applications/ApplicationsForm/interfacesApplications/interfacesApplications';
import { AnyObject } from 'types/enums/general/general.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model.js';
import { provideInput } from './provideInput';

/**
 *
 * @param groupFromModel model object recieved from options response
 * @param fields list of fields to drawS
 * @param instance single instance of object with values or empty for creating instance
 * @param referenceToForm link to Antd.Form
 * @returns {[]} containing input fields in group or single input field with wrapper
 */
export const createInputs = (
  groupFromModel: ModelResponse,
  fields: string[],
  referenceToForm: IFormProps,
  instance?: AnyObject
) => {
  const group = groupFromModel?.scheme;
  let result = [];
  if (group) {
    for (let field of fields) {
      const description = group[field];
      const element = provideInput(field, referenceToForm, instance, description);
      result.push(element);
    }
  }

  return result;
};
