import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
/*import { ArtificialStructuresService } from '../../../../../../generated/api/api';*/
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';

export class Overpasses extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'overpasses',
      filterLabel: 'Искусственные сооружения',
      selectedObjectName: MapObjects.overpasses,
    });
  }

  /*  async uploadData() {
    return await ArtificialStructuresService.artificialStructuresOverpassesList();
  }

  async readRecord(id) {
    return await ArtificialStructuresService.artificialStructuresOverpassesRead({ id });
  }

  async deleteRecord(id) {
    return await ArtificialStructuresService.artificialStructuresOverpassesDelete({ id });
  }*/

  async uploadModel() {
    return await getFromURL.getModelFromURL('/artificial_structures/overpasses/');
  }
}
