import React, { Dispatch, RefObject, SetStateAction } from 'react';
import { baseStyles } from '../../../../../../stylesRegistrator';
import { Button } from 'UI/common/components/Button/Button';
import { Styled } from './StandaloneEditModeFormPanel.styled';
import AutocompleteForm from 'UI/custom/objectPanels/Common/AutocompleteForm/AutocompleteForm';
import { AnyObject } from 'types/enums/general/general.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

const { BaseMapPanelTopPart, BaseMapPanelPrimaryTitle, BaseMapPanelItem } = baseStyles.modules.map;
const { ExtendedPanelWrapper, ExtendedPanelHeader } = Styled;

interface StandaloneEditModeFormPanelProps {
  data: AnyObject;
  model: ModelResponse;
  formTitle: string;
  handleSubmitForm: (currentData: AnyObject) => void;
  initSubmitForm: () => void;
  handleRejectObjectEditing: () => void;
  form: {
    showTime: boolean;
    dateTime: {};
    setDateTime: Dispatch<SetStateAction<{}>>;
    formRef: RefObject<HTMLFormElement>;
    setCustomFieldsData: Dispatch<SetStateAction<{}>>;
  };
}

const StandaloneEditModeFormPanel = React.forwardRef(
  (
    {
      handleSubmitForm,
      initSubmitForm,
      handleRejectObjectEditing,
      form,
      data,
      model,
      formTitle,
    }: StandaloneEditModeFormPanelProps,
    ref
  ) => {
    return (
      <ExtendedPanelWrapper>
        <ExtendedPanelHeader>
          <BaseMapPanelTopPart>
            <BaseMapPanelPrimaryTitle>{formTitle}</BaseMapPanelPrimaryTitle>
          </BaseMapPanelTopPart>
        </ExtendedPanelHeader>
        <BaseMapPanelItem>
          <AutocompleteForm
            model={model}
            data={data}
            handleSubmitForm={handleSubmitForm}
            ref={ref}
            hideTitle
            form={form}
          />
        </BaseMapPanelItem>
        <BaseMapPanelItem>
          <Button type={ButtonType.primary} onClick={initSubmitForm} width={'100%'}>
            Сохранить изменения
          </Button>
          <Button onClick={handleRejectObjectEditing} type={ButtonType.dangerReverseTranslucent} width={'100%'}>
            Отменить и выйти
          </Button>
        </BaseMapPanelItem>
      </ExtendedPanelWrapper>
    );
  }
);

export default StandaloneEditModeFormPanel;
