import styled from 'styled-components/macro';
import { LabelStyleProps } from './model/LabelStyleProps.model';
import { CheckboxWrapperStyleProps } from './model/CheckboxWrapperStyle.model';
import { TextWrapperStyleProps } from './model/TextWrapperStyle.model';

const CheckboxPart = styled.div`
  transition: all 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  &:last-child {
    opacity: 0;
  }
`;

const TextWrapper = styled.span<TextWrapperStyleProps>`
  color: ${(props) => props.fontColor ?? 'black'};
  font-weight: ${(props) => (props.boldText ? 'bold' : 'normal')};
  text-align: left;
  line-height: 1.3rem;
`;

const CheckboxWrapper = styled.span<CheckboxWrapperStyleProps>`
  color: ${(props) => props.checkboxColor ?? 'black'};
  position: relative;
  transition: all 0.2s;
  display: block;
  width: ${(props) => `${props.checkboxSizeInPx ?? 24}px`};
  height: ${(props) => `${props.checkboxSizeInPx ?? 24}px`};
  & input[type='checkbox'] {
    display: none;
    &:checked + ${CheckboxPart} {
      opacity: 1;
    }
  }
  & + ${TextWrapper} {
    margin-left: 0.8rem;
  }
`;

const Label = styled.label<LabelStyleProps>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: fit-content;
  display: flex;
  align-items: center;
`;

export const Styled = { Label, CheckboxWrapper, CheckboxPart, TextWrapper };
