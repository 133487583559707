import React from 'react';
import Icon from '@ant-design/icons';

const CopyIconSVG = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8332 17.5002H5.6665V5.8335H14.8332V17.5002ZM14.8332 4.16683H5.6665C5.22448 4.16683 4.80055 4.34242 4.48799 4.65498C4.17543 4.96755 3.99984 5.39147 3.99984 5.8335V17.5002C3.99984 17.9422 4.17543 18.3661 4.48799 18.6787C4.80055 18.9912 5.22448 19.1668 5.6665 19.1668H14.8332C15.2752 19.1668 15.6991 18.9912 16.0117 18.6787C16.3242 18.3661 16.4998 17.9422 16.4998 17.5002V5.8335C16.4998 5.39147 16.3242 4.96755 16.0117 4.65498C15.6991 4.34242 15.2752 4.16683 14.8332 4.16683ZM12.3332 0.833496H2.33317C1.89114 0.833496 1.46722 1.00909 1.15466 1.32165C0.842099 1.63421 0.666504 2.05814 0.666504 2.50016V14.1668H2.33317V2.50016H12.3332V0.833496Z"
      fill="currentColor"
    />
  </svg>
);

export const CopyIcon = (props) => <Icon component={CopyIconSVG} {...props} />;
