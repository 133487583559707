import React, { useMemo } from 'react';

import { Styled } from './PrepareScrollableInfoCard.styled';
import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { CloseIcon } from 'UI/custom/icons/actionIcons/CloseIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { Button } from 'UI/common/components/Button/Button';
import PreparedDescItems from 'UI/custom/components/PreparedDescItems/PreparedDescItems';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';

const { LocalHeaderWrapper, ExtendedBtnClose, LocalHeaderTitle, FooterButtonWrap } = Styled;

interface BrigadeTeamsOfWorkersCardI {
  title: string;
  handleCloseCard: () => void;
  listOfDescriptions?: Array<IDescriptions>;
  handleEditObject: () => void;
  orderApplicationsComponent: JSX.Element;
}

const BrigadeTeamsOfWorkersCard = ({
  title,
  handleCloseCard,
  listOfDescriptions,
  handleEditObject,
  orderApplicationsComponent,
}: BrigadeTeamsOfWorkersCardI) => {
  const headerStyles = useMemo(() => ({ padding: '14px', position: 'relative' }), []);
  const contentStyles = useMemo(() => ({ padding: '16px 10px 16px 16px' }), []);
  const footerStyles = useMemo(() => ({ padding: '16px' }), []);

  const headerContent = useMemo(
    () => (
      <LocalHeaderWrapper>
        <LocalHeaderTitle>{title}</LocalHeaderTitle>
        <ExtendedBtnClose onClick={handleCloseCard} icon={<CloseIcon />} type={ButtonType.unboundedPrimary} p={0} />
      </LocalHeaderWrapper>
    ),
    [handleCloseCard, title]
  );

  const footerContent = useMemo(
    () => (
      <FooterButtonWrap>
        <Button onClick={handleEditObject} type={ButtonType.primaryLC}>
          Редактировать
        </Button>
      </FooterButtonWrap>
    ),
    [handleEditObject]
  );

  return (
    <ScrollableCard
      headerStyles={headerStyles}
      headerContent={headerContent}
      contentStyles={contentStyles}
      children={
        <>
          {orderApplicationsComponent}
          {listOfDescriptions && <PreparedDescItems listOfDescriptions={listOfDescriptions} />}
        </>
      }
      footerStyles={footerStyles}
      footerContent={footerContent}
    />
  );
};

export default BrigadeTeamsOfWorkersCard;
