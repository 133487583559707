import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { CommonSelectedItemContextMenuPrototype } from '../../prototypes/CommonSelectedItemContextMenuPrototype';
import { groupTypes } from '../../../contentGroups';
import { MapContextMenuItem } from '../../../../../../types/enums/map/contextMenu/MapContextMenuItem.model';
import { initializeSingleObjectEditMode } from '../../../../../../store/sagas/map/standaloneObjectEditableSaga';
import { BridgeIcon } from '../../../../../../UI/custom/icons/contextMenuIcons/BridgeIcon';
import React from 'react';

const { ADD_GROUP } = groupTypes;
// noinspection ES6ClassMemberInitializationOrder
export class OverpassesDescription extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, MapObjects.overpasses, layerName);
    this.initialCoordinate = initialCoordinate;
  }

  actionItems = [
    ...this.actionItems,
    {
      name: MapContextMenuItem.bridges,
      path: 'Мост',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(
          this.selectedObject,
          MapObjects.bridges,
          this.layerName,
          this.initialCoordinate,
          ADD_GROUP
        ),
      icon: <BridgeIcon />,
    },
  ];
}
