import React, { useCallback, useState } from 'react';
import { withTheme } from 'styled-components/macro';
import { useRouteMatch } from 'react-router-dom';

import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import TextIconButton from 'UI/custom/actionsButtons/common/TextIconButton';
import { Styled } from '../../styled/BaseInput.styled';
import { ModalMapInputProps } from './model/ModalMapInput.model';
import { ModalMapContainer } from './ModalMapContainer';
import { AnyObject } from 'types/enums/general/general.model';
import { mainPages } from 'types/enums/app/mainPages/mainPages';

const { BaseSelectInputWrap, TextIconButtonContainer } = Styled;

const ModalMapInput = (props: ModalMapInputProps) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const {
    object,
    field,
    formProps: { setCustomFieldsData },
    description: { type: fieldType },
  } = props;

  const value: string = object?.[field];

  const onOpenModal = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (e?.preventDefault) e.preventDefault();

      if (setCustomFieldsData) {
        setCustomFieldsData((prevValue: AnyObject) => ({ ...prevValue, [field]: value }));
      }

      setOpenModal(true);
    },
    [field, setCustomFieldsData, value]
  );

  const labelText = value ? 'Показать на карте' : 'Указать местоположение';

  //Конструкция ниже нужна для того что бы кнопка не отображалась в форме редактирования на карте
  const { params } = useRouteMatch<{ mainPage?: mainPages }>();
  const { mainPage } = params;

  const match = mainPage === mainPages.map;

  if (match) {
    return null;
  }

  return (
    <BaseSelectInputWrap>
      <TextIconButtonContainer>
        <TextIconButton
          clickButton={onOpenModal}
          isDisabled={false}
          labelText={labelText}
          type={ButtonType.primary}
          tooltip={labelText}
        />
      </TextIconButtonContainer>
      <ModalMapContainer
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        field={field}
        object={object}
        setCustomFieldsData={setCustomFieldsData}
        fieldType={fieldType}
      />
    </BaseSelectInputWrap>
  );
};

export default withTheme(ModalMapInput);
