export enum MapObjects {
  segment = 'segment',
  segments = 'segments',
  digitalTwinRoadSignGroup = 'digital_twin_road_sign_group',
  roadSegment = 'segment',
  bridges = 'bridges',
  overpasses = 'overpasses',
  road = 'road',
  roadSigns = 'road_signs',
  trafficLight = 'traffic_light',
  speedBumps = 'speed_bumps',
  cabinets = 'cabinets',
  railCrossings = 'rail_crossings',
  pipes = 'pipes',
  lighting = 'lighting',
  restAreas = 'rest_areas',
  weatherStations = 'weather_stations',
  pipelines = 'pipelines',
  tunnels = 'tunnels',
  ferryCrossings = 'ferry_crossings',
  roadWays = 'road_ways',
  supportWall = 'support_wall',
  roadFences = 'road_fences',
  pedestrianFences = 'pedestrian_fences',
  engineeringConstructions = 'engineering_constructions',
  supportPillars = 'support_pillars',
  sidewalk = 'sidewalk',
  dividingLines = 'dividing_lines',
  tramRails = 'tram_rails',
  roadMarkingLine = 'road_marking_line',
  roadMarkingPolygon = 'road_marking_polygon',
  roadMarkingLines = 'road_marking_lines',
  signalPoles = 'signal_poles',
  improvementElements = 'improvement_elements',
  traySystem = 'tray_systems',
  citydistricts = 'city_districts',
  roadSignGroup = 'road_sign_group',
  roadconSign = 'roadcon_sign',
  securityCheckpoint = 'security_checkpoint',
  roadBlock = 'road_block',
  roadWorks = 'road_works',
  deckGLMap = 'deckGLMap',
  geozone = 'geozone',
  monitoringObject = 'monitoringObject',
  monitoringCluster = 'monitoringCluster',
  railway = 'railway',
  dischargeZone = 'discharge_zone',
  receptionArea = 'reception_area',
  cargoTrackingPoint = 'cargoTrackingPoint',
  clusterCargos = 'clusterCargos',
  trackingVehicle = 'trackingVehicle',
  roadOverpass = 'road_overpass',
  dockSegment = 'dock_segment',
  controlRoomsSegment = 'control_room',
  trafficBackgroundRoadSegment = 'trafficBackgroundRoadSegment',
  contoursLawn = 'contoursLawn',
  townSquares = 'townSquares',
  trafficManagementProjects = 'traffic_management_projects',
  busShelters = 'bus_shelters',

  /** Дорожные тех. характеристики*/
  roadTechnicalCharacteristicsAxeLoads = 'axle_loads',
  roadTechnicalCharacteristicsCoatingTypes = 'coating_types',
  roadTechnicalCharacteristicsLanesAmounts = 'lanes_amounts',
  technicalSegments = 'technicalSegments',

  /**Качество УДС */
  qualitySegmentClutch = 'road_clutch_quality',
  qualitySegmentComplex = 'road_complex_type_quality',
  qualitySegmentFlatness = 'road_flatness_quality',
  qualitySegmentRut = 'road_rut_quality',
  qualitySegmentStrength = 'road_strength_quality',
  SegmentAnalysisValues = 'segment_analysis_values',
  ViolationsWarrantyPeriod = 'violations_warranty_period',

  /** ДТП */
  roadAccidents = 'road_accidents',
  roadAccidentsSections = 'road_accidents_sections',
  roadAccidentsCluster = 'road_accidents_cluster',

  /** Заявки на перевозку */
  individualStatements = 'individual_statements',
  entityStatements = 'entity_statements',
  axleLoadOverloads = 'axle_load_overloads',
  dimensionsHeightOversizes = 'dimensions_height_oversizes',
  dimensionsWidthOversizes = 'dimensions_width_oversizes',
  generalDimensionsHeightWidthOversizes = 'general_dimensions_height_width_oversizes',
  entityStatementsAxleLoadOverloads = 'entity_statements_axle_load_overloads',
  entityStatementsDimensionsHeightOversizes = 'entity_statements_dimensions_height_oversizes',
  entityStatementsDimensionsWidthOversizes = 'entity_statements_dimensions_width_oversizes',
  entityStatementsGeneralDimensionsHeightWidthOversizes = 'entity_statements_general_dimensions_height_width_oversizes',

  /** Маршрутная сеть */
  publicTransportRoute = 'transportRoutes',
  publicTransportBusStop = 'publicTransportBusStop',

  /** Анализ маршрутов */
  transportAnalysis = 'transportAnalysis',

  /** Подстанции */
  substations = 'substations',

  /** Троллейбус */
  linesTroll = 'Lines_troll',
  cablesTroll = 'Cables_troll',
  supply_cables_troll = 'Supply_Cables_Troll',

  /** Трамваи */
  tramLines = 'Lines_tram',
  tramCables = 'Cables_tram',
  supply_cables_tram = 'supply_cables_tram',
  supplyTramCables = 'supply_cables_tram',

  /** Схема дорог */
  roadMarkings = 'road_markings',
  busStops = 'bus_stops',

  /** Сфетофорные объекты*/
  trafficLightObject = 'traffic_light_object',

  /** Камеры и детекторы */
  camerasAndDetectors = 'cameras_and_detectors',

  /** Пешеходные переходы */
  illuminatedPedestrianCrossings = 'illuminated_pedestrian_crossings',

  /** Проектный слой*/
  projectLayerRoadMarkingLine = 'road_marking_line_project',
  projectLayerProjectRoadSigns = 'road_signs_project',
  projectLayerPedBorder = 'ped_border_project',
  projectLayerSpeedBump = 'speed_bump_project',
  projectLayerStreetLamp = 'street_lamp_project',
  projectLayerTrafficLights = 'traffic_lights_project',
  projectLayerSignalPoles = 'signal_bars_project',
  projectLayerRoadMarkingPolygonProject = 'road_marking_polygon_project',
  projectLayerRoadMarkingSymbolProject = 'road_marking_symbol_project',
  projectRoadway = 'roadway_project',
  projectSidewalk = 'sidewalk_project',

  /** Заявки */
  brigadeApplications = 'brigade_applications',
  brigadeWorkShifts = 'brigade_work_shifts',
  brigadeWorkShiftsApplications = 'brigade_work_shifts_applications',
  brigadeWorkShiftsTrack = 'brigade_work_shifts_track',
  brigadeMonitoring = 'brigade_monitoring',
  brigadeTeamsOfWorkers = 'brigade_teams_of_workers',

  /** Мониторинг УДС **/
  monitoringUds = 'monitoring_uds',

  /** Камеры УДС */
  camerasUdc = 'cameras_udc',
}
