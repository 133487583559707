import React from 'react';
import Icon from '@ant-design/icons';

const PlusSegmentIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6673 15.0002V12.5002H15.0007V15.0002H12.5007V16.6668H15.0007V19.1668H16.6673V16.6668H19.1673V15.0002H16.6673ZM15.0007 3.3335H16.6673V10.8335H15.0007V3.3335ZM3.33398 3.3335H5.00065V16.6668H3.33398V3.3335ZM9.16732 3.3335H10.834V6.66683H9.16732V3.3335ZM9.16732 8.3335H10.834V11.6668H9.16732V8.3335ZM9.16732 13.3335H10.834V16.6668H9.16732V13.3335Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const PlusSegmentIcon = (props) => <Icon component={PlusSegmentIconSVG} {...props} />;
