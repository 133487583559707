import React, { PropsWithChildren } from 'react';
import { Styled } from './style/Checkbox.styled';
import { CheckboxEmptySVG } from './components/Parts/CheckboxEmptySVG';
import { CheckboxFillSVG } from './components/Parts/CheckboxFillSVG';
import { CheckboxOwnProps } from './model/Checkbox.model';

const { Label, CheckboxWrapper, CheckboxPart, TextWrapper } = Styled;
/** Стилизованный чек-бокс*/
export const Checkbox = (props: PropsWithChildren<CheckboxOwnProps>) => {
  const {
    children,
    disabled,
    onChange,
    className,
    checked,
    checkboxColor,
    fontColor,
    value,
    boldText = true,
    onClick,
    checkboxSizeInPx = 24,
    ...otherAttrs
  } = props;
  const onChangeAction = (e: any) => {
    if (disabled) {
      e.preventDefault();
    } else {
      return onChange && onChange(e);
    }
  };

  return (
    <Label {...otherAttrs} disabled={disabled} onChange={onChangeAction}>
      <CheckboxWrapper checkboxSizeInPx={checkboxSizeInPx} checkboxColor={checkboxColor}>
        <CheckboxPart>
          <CheckboxEmptySVG width={checkboxSizeInPx} height={checkboxSizeInPx} />
        </CheckboxPart>
        <input type={'checkbox'} defaultChecked={checked} value={value} />
        <CheckboxPart>
          <CheckboxFillSVG width={checkboxSizeInPx} height={checkboxSizeInPx} />
        </CheckboxPart>
      </CheckboxWrapper>
      {children && (
        <TextWrapper boldText={boldText} fontColor={fontColor}>
          {children}
        </TextWrapper>
      )}
    </Label>
  );
};
