import React from 'react';

import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';

export type MapMarkersState = {
  [key in Layers]?: MapMarker[];
};

export interface MapMarker {
  id?: number;
  type: MapObjects;
  component: React.ReactNode;
  clickInfo?: any;
  markerPosition: [number, number];
  markerSizeMode: MarkerSizeMode;
}

export interface MapMarkerAction {
  layer: Layers;
  marker: Partial<MapMarker>;
}

export interface MapMoveMarkerAction extends MapMarkerAction {
  markerPosition: [number, number];
}

export interface MapChangeMarkerSizeModeAction extends MapMarkerAction {
  markerSizeMode: MarkerSizeMode;
}

export enum MarkerSizeMode {
  move = 'move',
  drag = 'drag',
  static = 'static',
}
export interface MapClearMarkersForLayer {
  layer: Layers;
}
