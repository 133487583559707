export const signsRoadMapperType4 = {
  '4.1.1': { x: 525, y: 0, width: 128, height: 180 },
  '4.1.1-G': { x: 1170, y: 1086, width: 128, height: 180 },
  '4.1.1-R': { x: 0, y: 0, width: 128, height: 180 },
  '4.1.2': { x: 1041, y: 362, width: 128, height: 180 },
  '4.1.2-G': { x: 912, y: 181, width: 128, height: 180 },
  '4.1.2-R': { x: 912, y: 362, width: 128, height: 180 },
  '4.1.3': { x: 258, y: 905, width: 128, height: 180 },
  '4.1.3-G': { x: 654, y: 543, width: 128, height: 180 },
  '4.1.3-R': { x: 1170, y: 362, width: 128, height: 180 },
  '4.1.4': { x: 654, y: 181, width: 128, height: 180 },
  '4.1.4-G': { x: 387, y: 543, width: 128, height: 180 },
  '4.1.4-R': { x: 258, y: 1086, width: 128, height: 180 },
  '4.1.5': { x: 654, y: 362, width: 128, height: 180 },
  '4.1.5-G': { x: 1041, y: 181, width: 128, height: 180 },
  '4.1.5-R': { x: 129, y: 543, width: 128, height: 180 },
  '4.1.6': { x: 258, y: 543, width: 128, height: 180 },
  '4.1.6-G': { x: 129, y: 362, width: 128, height: 180 },
  '4.1.6-R': { x: 1041, y: 905, width: 128, height: 180 },
  '4.2.1': { x: 1170, y: 181, width: 128, height: 180 },
  '4.2.1-G': { x: 516, y: 543, width: 128, height: 180 },
  '4.2.1-R': { x: 1170, y: 905, width: 128, height: 180 },
  '4.2.2': { x: 912, y: 0, width: 128, height: 180 },
  '4.2.2-G': { x: 0, y: 181, width: 128, height: 180 },
  '4.2.2-R': { x: 129, y: 181, width: 128, height: 180 },
  '4.2.3': { x: 1170, y: 0, width: 128, height: 180 },
  '4.2.3-G': { x: 258, y: 724, width: 128, height: 180 },
  '4.2.3-R': { x: 654, y: 0, width: 128, height: 180 },
  4.3: { x: 1041, y: 543, width: 128, height: 180 },
  '4.3-G': { x: 307, y: 181, width: 128, height: 180 },
  '4.3-R': { x: 0, y: 362, width: 128, height: 180 },
  '4.4.1': { x: 129, y: 724, width: 128, height: 180 },
  '4.4.1-G': { x: 645, y: 724, width: 128, height: 180 },
  '4.4.1-R': { x: 525, y: 362, width: 128, height: 180 },
  '4.4.2': { x: 783, y: 181, width: 128, height: 180 },
  '4.4.2-G': { x: 0, y: 1086, width: 128, height: 180 },
  '4.4.2-R': { x: 1170, y: 543, width: 128, height: 180 },
  '4.5.1': { x: 863, y: 905, width: 128, height: 180 },
  '4.5.1-G': { x: 0, y: 905, width: 128, height: 180 },
  '4.5.1-R': { x: 1041, y: 724, width: 128, height: 180 },
  '4.5.2': { x: 0, y: 724, width: 128, height: 180 },
  '4.5.2-G': { x: 307, y: 0, width: 128, height: 180 },
  '4.5.2-R': { x: 605, y: 905, width: 128, height: 180 },
  '4.5.3': { x: 1170, y: 724, width: 128, height: 180 },
  '4.5.3-G': { x: 912, y: 724, width: 128, height: 180 },
  '4.5.3-R': { x: 1041, y: 0, width: 128, height: 180 },
  '4.5.4': { x: 129, y: 905, width: 128, height: 180 },
  '4.5.4-G': { x: 0, y: 543, width: 128, height: 180 },
  '4.5.4-R': { x: 516, y: 1086, width: 128, height: 180 },
  '4.5.5': { x: 783, y: 0, width: 128, height: 180 },
  '4.5.5-G': { x: 387, y: 1086, width: 128, height: 180 },
  '4.5.5-R': { x: 783, y: 362, width: 128, height: 180 },
  '4.5.6': { x: 783, y: 724, width: 128, height: 180 },
  '4.5.6-G': { x: 783, y: 543, width: 128, height: 180 },
  '4.5.6-R': { x: 476, y: 905, width: 128, height: 180 },
  '4.5.7': { x: 258, y: 362, width: 128, height: 180 },
  '4.5.7-G': { x: 645, y: 1086, width: 128, height: 180 },
  '4.5.7-R': { x: 525, y: 181, width: 128, height: 180 },
  4.6: { x: 387, y: 724, width: 128, height: 180 },
  '4.6-G': { x: 912, y: 543, width: 128, height: 180 },
  '4.6-R': { x: 129, y: 1086, width: 128, height: 180 },
  4.7: { x: 774, y: 1086, width: 128, height: 180 },
  '4.7-G': { x: 734, y: 905, width: 128, height: 180 },
  '4.7-R': { x: 516, y: 724, width: 128, height: 180 },
  '4.8.1': { x: 992, y: 1086, width: 88, height: 180 },
  '4.8.1-G': { x: 903, y: 1086, width: 88, height: 180 },
  '4.8.1-R': { x: 218, y: 0, width: 88, height: 180 },
  '4.8.2': { x: 436, y: 181, width: 88, height: 180 },
  '4.8.2-G': { x: 436, y: 362, width: 88, height: 180 },
  '4.8.2-R': { x: 1081, y: 1086, width: 88, height: 180 },
  '4.8.3': { x: 387, y: 905, width: 88, height: 180 },
  '4.8.3-G': { x: 436, y: 0, width: 88, height: 180 },
  '4.8.3-R': { x: 129, y: 0, width: 88, height: 180 },
};
