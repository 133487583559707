import React from 'react';
import { setMainMapPanelContent } from '../../../../../../../../../../store/reducers/map/actions/mapPanelsActions';
import { connect } from 'react-redux';
import { StackIcon } from '../../../../../../../../../../UI/custom/icons/objectIcons/StackIcon';
import { SettingsIcon } from '../../../../../../../../../../UI/custom/icons/actionIcons/SettingsIcon';
import ToggleButtonGroup from '../../../../../../../../../../UI/common/components/ToggleButtonGroup/ToggleButtonGroup';

const SWITCHER_BUTTONS_TEMPLATE = [
  {
    id: 1,
    icon: <StackIcon key={1} />,
    name: 'Слои',
    switcher: 'layers',
  },
  /*  {
    id: 2,
    icon: <DownloadIcon key={2} />,
    name: 'Шаблоны',
    switcher: 'templates',
  },*/
  {
    id: 3,
    icon: <SettingsIcon key={3} />,
    name: 'Настройки',
    switcher: 'settings',
  },
];

const LayersContentSwitcherContainer = ({ setPanelContext, activeMapPanelContent }) => {
  const handleChangePanelContext = (value) => {
    setPanelContext(value);
  };

  const buttonClickHandler = (element) => {
    handleChangePanelContext(element.switcher);
  };

  return (
    <ToggleButtonGroup
      handleChangePanelContext={handleChangePanelContext}
      buttonClickHandler={buttonClickHandler}
      activePanel={activeMapPanelContent}
      displayedButtons={SWITCHER_BUTTONS_TEMPLATE}
    />
  );
};

LayersContentSwitcherContainer.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return {
    setPanelContext: (value) => dispatch(setMainMapPanelContent(value)),
  };
};

const mapStateToProps = (state) => {
  return {
    activeMapPanelContent: state.mapPanels.selectedMainContext,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayersContentSwitcherContainer);
