import React from 'react';
import { Button } from '../../../../../../UI/common/components/Button/Button';
import ButtonGroup from '../../../../../../UI/common/components/ButtonGroup/ButtonGroup';
import { PlusIcon } from '../../../../../../UI/custom/icons/actionIcons/PlusIcon';
import { MinusIcon } from '../../../../../../UI/custom/icons/actionIcons/MinusIcon';
import CompassButtonContainer from './CompassButton/CompassButtonContainer';
import { RulerIcon } from '../../../../../../UI/custom/icons/objectIcons/RulerIcon';
import { MeasureDistanceMode } from '@nebula.gl/edit-modes';
/*import { MapIcon } from '../../../../../../UI/custom/icons/objectIcons/MapIcon';*/
import _ from 'underscore';
/*import LegendPanel from './LegendPanel/LegendPanel';*/

const SecondaryGroup = ({
  handleZoomIn,
  toggleRuler,
  handleZoomOut,
  iconSize,
  /*  handleToggleLegend,
  iconSizeLarge,
  mapLegendVisible,
  selectedLayer,*/
}) => {
  return (
    <>
      <ButtonGroup vertical>
        <Button
          hasShadow
          type={'primaryReverse'}
          onClick={handleZoomIn}
          icon={<PlusIcon />}
          iconSize={iconSize}
          key={_.uniqueId()}
          tooltip={'Увеличить масштаб'}
        />
        <Button
          hasShadow
          type={'primaryReverse'}
          onClick={handleZoomOut}
          icon={<MinusIcon />}
          iconSize={iconSize}
          key={_.uniqueId()}
          tooltip={'Уменьшить масштаб'}
        />
      </ButtonGroup>
      <CompassButtonContainer />
      <Button
        hasShadow
        rounded
        type={'primaryReverse'}
        onClick={() => toggleRuler(MeasureDistanceMode)}
        icon={<RulerIcon />}
        iconSize={iconSize}
        key={_.uniqueId()}
        tooltip={'Линейка'}
      />
      {/* <Button
        style={{ position: 'relative' }}
        hasShadow
        rounded
        type={'primaryReverse'}
        onClick={selectedLayer?.name === 'road_accidents' ? handleToggleLegend : null}
        icon={<MapIcon />}
        iconSize={iconSizeLarge}
        p={0.5}
        key={_.uniqueId()}
      >
        {selectedLayer && mapLegendVisible && <LegendPanel />}
      </Button>*/}
    </>
  );
};

export default SecondaryGroup;
