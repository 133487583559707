import styled from 'styled-components/macro';

const LayersGalleryScrollbar = styled.div`
  max-height: calc(100vh - 360px);
  border-radius: 0 0 16px 16px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Styled = {
  LayersGalleryScrollbar,
};
