import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import { agGridFiltersActions } from 'store/reducers/global/aggrid/agGridFilters.actions';
import { AgGridApi, IquickMenu } from './model/useAgGridSettings.model';
import { useLocation } from 'react-router';

const quickMenuInitialState: IquickMenu = {
  search: false,
  group: false,
  filters: false,
};

export function useAgGridControl() {
  const filtersAgGridState = useSelector((state: StateModel) => state.filtersAgGridState);
  const location = useLocation();
  const urlData = location.pathname;

  const dispatch = useDispatch();
  const [aggApi, setAggApi] = useState<AgGridApi>();
  const [quickMenu, setQuickMenu] = useState<IquickMenu>(quickMenuInitialState);

  // Функция поисковой "сквозной" фильтрации по данным.
  const onExternalFilterChanged = useCallback(
    (newValue: ChangeEvent<HTMLInputElement>) => {
      aggApi?.api.setQuickFilter(newValue.target.value);
    },
    [aggApi]
  );

  // Функция записи состояния фильтра в гриде
  const onFilterChanged = useCallback(
    ({ api }: AgGridApi) => {
      const filterModel = api.getFilterModel();
      dispatch(agGridFiltersActions.addFilter({ newFilter: { [urlData]: filterModel } }));
    },
    [dispatch, urlData]
  );

  // Функция очистки фильтра в текущем гриде
  const clearAgGridFilters = useCallback(() => {
    dispatch(agGridFiltersActions.clearFilter(urlData));
    aggApi?.api.setFilterModel(null);
  }, [aggApi?.api, dispatch, urlData]);

  // Функция срабатывает при готовности грида
  const handleAGGInit = useCallback(
    (aggApi: AgGridApi) => {
      // Если в редаксе есть фильтр текущей таблицы то применяем его
      if (filtersAgGridState[urlData]) {
        aggApi.api.setFilterModel(filtersAgGridState[urlData]);
      }
      setAggApi(aggApi);
    },
    [filtersAgGridState, urlData]
  );

  const toggleShowQuickFilter = useCallback(() => {
    setQuickMenu({
      ...quickMenu,
      search: !quickMenu.search,
    });
  }, [quickMenu, setQuickMenu]);

  // Функция изменения колонок в гриде
  const toggleShowRowsMenu = useCallback(() => {
    if (aggApi?.api) {
      if (!quickMenu.group) {
        aggApi.api.openToolPanel('columns');
        aggApi.api.setSideBarVisible(true);
      } else {
        aggApi.api.setSideBarVisible(false);
        aggApi.api.closeToolPanel();
      }
      setQuickMenu((prevState) => ({
        ...quickMenuInitialState,
        group: !prevState.group,
      }));
    }
  }, [aggApi, quickMenu.group]);

  // Функция фильтрации грида по полям
  const toggleShowFilterMenu = useCallback(() => {
    if (aggApi?.api) {
      if (!quickMenu.filters) {
        aggApi.api.openToolPanel('filters');
        aggApi.api.setSideBarVisible(true);
      } else {
        aggApi.api.setSideBarVisible(false);
        aggApi.api.closeToolPanel();
      }
      setQuickMenu((prevState) => ({
        ...quickMenuInitialState,
        filters: !prevState.filters,
      }));
    }
  }, [quickMenu.filters, aggApi]);

  return useMemo(
    () => ({
      aggApi,
      handleAGGInit,
      quickMenu,
      toggleShowQuickFilter,
      toggleShowRowsMenu,
      toggleShowFilterMenu,
      onExternalFilterChanged,
      onFilterChanged,
      clearAgGridFilters,
    }),
    [
      aggApi,
      clearAgGridFilters,
      handleAGGInit,
      onFilterChanged,
      quickMenu,
      toggleShowFilterMenu,
      onExternalFilterChanged,
      toggleShowQuickFilter,
      toggleShowRowsMenu,
    ]
  );
}
