export enum IOPWTabMenuModel {
  statement_billings = 'Формирование счетов',
  approvals = 'Заявления на согласования',
  statement_harm_calculations = 'Расчет вреда',
  approval_files = 'Файлы',
  approval_federal_statement_resolutions = 'Ответ на заявление',
  approval_federal_statement_vehicles = 'ТС в составе автопоезда',
  approval_federal_statement_vehicle_axis = 'Информация об осях ТС',
  approval_federal_statement_control_stations = 'Пункты весогабаритного контроля',
  approval_federal_statement_additional_inspection = 'Запросы',
  write_off_acts = 'Акты о списании',
  destruction_acts = 'Акты о уничтожении',
  forms_account = 'Бланки строгой отчетности',
  new_act = 'Новый акт',
  vehicle_axes = 'Информация об осях ТС',
  vehicle_parameters = 'Параметры транспорта в заявке для физ.лиц',
  files = 'Файлы для заявлений',
  road_route = 'road_route',
  calc_of_harm = 'Расчет вреда',
  roads = 'Дороги в заявлении',
  create = 'create',
  billings = 'Формирование счетов',
  approval_additional_inspection = 'Одобрение дополнительной проверки',
  approval_vehicles = 'Транспортные средства',
  approval_resolutions = 'Решения об утверждении',
  cancels = 'Запросы на отмену согласований заявлений от ФКУ "Росдормониторинг"',
  adapter_messages = 'Информация для запросов сервиса СМЭВ3',
  federal_files = 'Файлы для согласования заявления от ФКУ "Росдормониторинг"',
  additional_inspections = 'Запросы дополнительных материалов',
  vehicles = 'ТС в составе автопоезда',
  routes = 'Маршруты',
  vehicle_axis = 'Информация об осях ТС',
  control_stations = 'Пункты весогабаритного контроля по маршруту',
  axle_load_overloads = 'axle_load_overloads',
  dimensions_height_oversizes = 'dimensions_height_oversizes',
  dimensions_width_oversizes = 'dimensions_width_oversizes',
  dimensions_segment_width_oversizes = 'dimensions_segment_width_oversizes',
  general_dimensions_oversizes = 'general_dimensions_oversizes',
  statement_approvals = 'statement_approvals',
  send_billing_to_unp = 'send_billing_to_unp_to_unp',
  check_billing_status_from_unp = 'check_billing_status_from_unp_from_unp',
  check_billing_payment_from_unp = 'check_billing_payment_from_unp_from_unp',
  main = 'Основное',
  excess_percents = 'Рассчитанный размер вреда под определенный процент превышения нагрузки',
  history_change_statement = 'История изменения заявок',
  individual_statements = 'Заявления от физических лиц или ИП',
  entity_statements = 'Заявления от юридических лиц',
  federal_statements = 'Согласование маршрута Росавтодор',
  coef_for_harm = 'Коэффициент вреда',
  permissible_axis_loads = 'Допустимые осевые нагрузки',
  vehicle_axle_loads = 'Нагрузки на ось',
  location_axis = 'Автомобильная ось',
  payment_types = 'Предоставляемая услуга',
  ip_billings = 'Счета на оплату физических лиц и ИП',
  org_billings = 'Счета на оплату юридичеких лиц',
  permissible_weight = 'Допустимый вес',
  non_working_days = 'Нерабочие дни',
  global_organizations = 'Организации региональных и федеральных дорог',
  signers = 'Подписанты',
  responsible_persons = 'Ответственные лица',
  special_permits = 'Перевозка грузов',
  additional_inspection_comment_templates = 'Шаблоны ответов для запроса дополнительных материалов',
  approval_comment_templates = 'Шаблоны ответов для согласования заявления',
  decline_comment_templates = 'Шаблоны ответов для отказа в согласовании заявления',
  special_condition_comment_templates = 'Шаблоны ответов для особых условий движения',
}
