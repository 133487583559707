import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { postToURLSingle } from 'api/postToURLSingle';
import { useAgGridControl } from 'hooks/ag-grid/useAgGridControl';
import moment from 'moment-timezone';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import _ from 'underscore';

import { getNewModel } from '../../../../BaseModules/Map/MapDisplayMode/getNewModel';
import { ActionButtons } from '../../CargoTransportForm/ActionButtons/ActionButtons';
import {
  IBreadcrumbsLinks,
  CargoTransportContextModel,
  IData,
  IIdBlanks,
  CargoTransportParams,
} from '../../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import { emptyBlank, statusChoice } from '../../CargoTransportForm/interfacesCargoTransport/templates';
import { CargoTransportContext } from '../../CargoTransportRouter';
import { CompendiumContainer } from './CompendiumWithButton.status';
import { AnyObject } from 'types/enums/general/general.model';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { useDispatch } from 'react-redux';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { ContainerCallbacks } from 'UI/custom/Aggrid/Compendium/model/ContainerCallbacks.model';
import { CellClassParams } from 'ag-grid-community';
import Compendium from 'UI/custom/Aggrid/Compendium/Compendium';
import { HeaderScipBlock } from 'components/Application/Common/HeaderScipBlock/HeaderScipBlock';
import { BreadcrumbsControlled } from 'components/Application/Common/BreadcrumbsControlled/BreadcrumbsControlled';
/**
 * Компонент просмотра списка
 * @returns {JSX.Element}
 * @constructor
 */

interface ICompendiumWithButtonProps {
  data: AnyObject[];
  model?: ModelResponse;
  showQuickFilter: boolean;
  gridCellSizes?: {
    minWidth: number;
    width: number;
    maxWidth: number;
  };
  agGridThemeName: string;
  namesCollectionForFiltering: any[];
  buttons: any;
  pageMenuKey?: null | IOPWDirectory;
  selectIdAGGrid: (id: number) => void;
  setEventData?: (data: IData) => void;
  refreshData: () => void;
  isHeader: boolean;
  breadcrumbsLinks: IBreadcrumbsLinks[] | null;
  idBlanksData?: { idBlanks: IIdBlanks[]; setIdBlanks: (data: IIdBlanks[]) => void };
}

export const CompendiumWithButton = React.memo((props: PropsWithChildren<ICompendiumWithButtonProps>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { params } = useRouteMatch<CargoTransportParams>();
  const { directory, scoreMode, scoreId } = params;
  const { setStatement, setPageMenuKey } = useContext<CargoTransportContextModel>(CargoTransportContext);

  const {
    data,
    model,
    showQuickFilter,
    gridCellSizes,
    agGridThemeName,
    namesCollectionForFiltering,
    buttons,
    pageMenuKey,
    selectIdAGGrid,
    setEventData,
    refreshData,
    isHeader,
    breadcrumbsLinks,
    idBlanksData,
  } = props;

  const { idBlanks, setIdBlanks } = idBlanksData ?? {};

  const tableRenderingModel = useMemo(() => model && getNewModel(model, true), [model]);

  const {
    quickMenu,
    aggApi,
    handleAGGInit,
    toggleShowFilterMenu,
    toggleShowQuickFilter,
    toggleShowRowsMenu,
    onFilterChanged,
    clearAgGridFilters,
  } = useAgGridControl();

  const handleProvideData = useCallback(
    (event) => {
      if (
        !event.data ||
        directory === IOPWDirectory.forms_account ||
        (scoreMode === IOPWDirectory.vehicle_parameters && scoreId) ||
        pageMenuKey === IOPWDirectory.history_change_statement
      ) {
        return;
      } else if (pageMenuKey && setEventData) {
        setEventData(event.data);
      } else {
        setPageMenuKey(IOPWDirectory.main);
        setStatement(event.data);
        history.push(`${location.pathname}${event.data.id}/`);
      }
    },
    [directory, history, location.pathname, pageMenuKey, scoreId, scoreMode, setEventData, setPageMenuKey, setStatement]
  );

  const handleSelectRow = useCallback(
    (event) => {
      if (!selectIdAGGrid || !event.data) return;
      if (directory === IOPWDirectory.forms_account) return;
      selectIdAGGrid(event.data.id);
    },
    [directory, selectIdAGGrid]
  );

  const DIFFERENCE_PERCENTAGE_MINUTES = 100;
  const DIFFERENCE_PERCENTAGE_EMPTY_CELL = 70;
  const DIFFERENCE_PERCENTAGE_IN_WORK = 50;

  const functionCellClass = useCallback(
    (event: CellClassParams) => {
      let result = '';
      if (
        statusChoice.some((choice) => choice.value === event.data?.status) &&
        statusChoice.some((choice) => choice.display_name === event.value) &&
        event?.colDef?.field === 'status'
      ) {
        result = `STATUS_CHOICE ${event.data?.status}`;
      } else if (
        event?.colDef?.field === 'planned_date_of_the_end' ||
        event?.colDef?.field === 'planned_date_of_the_reg'
      ) {
        if (event.value === 'Отсутствует') return;

        const timeValue = moment(event.value);
        const startStatmentTime = moment(event.data?.created_at);
        const momentNow = moment();
        const differencePercentage =
          (timeValue.diff(momentNow, 'minutes') * DIFFERENCE_PERCENTAGE_MINUTES) /
          timeValue.diff(startStatmentTime, 'minutes');
        if (moment(momentNow).isAfter(startStatmentTime)) result = 'empty_cell';
        else if (differencePercentage > DIFFERENCE_PERCENTAGE_EMPTY_CELL) result = 'AWAITING_AGREED';
        else if (differencePercentage > DIFFERENCE_PERCENTAGE_IN_WORK) result = 'IN_WORK';
      }
      if (!event.value && scoreMode === IOPWDirectory.vehicle_parameters && scoreId) result = 'empty_cell';
      return result;
    },
    [scoreId, scoreMode]
  );

  const cellEditingStopped = useCallback(
    async (event) => {
      if (scoreMode === IOPWDirectory.vehicle_parameters && scoreId) {
        try {
          const { data } = event;
          delete data.undefined;
          await postToURLSingle.postOrPutDataErrorResponse(
            `/${IOPWDirectory.special_permits}/${directory}/${pageMenuKey}/`,
            data
          );
          refreshData();
        } catch (e) {
          if (_.isObject(e) && e?.response && _.isObject(e.response)) {
            const message = createAlertMessage(e);
            dispatch(
              notificationActions.setGlobalAlertData({
                status: ActiveStatus.active,
                type: PromptType.error,
                title: 'Ошибка',
                message,
              })
            );
            setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
          }
        }
      }
    },
    [directory, dispatch, pageMenuKey, refreshData, scoreId, scoreMode]
  );

  const handleSetSelectedRows = useCallback(
    (event) => {
      if (!event.data?.id || directory !== IOPWDirectory.forms_account) return;
      if (idBlanks?.some((blank: AnyObject) => blank?.id === event.data.id) && setIdBlanks) {
        const deleteIdBlank = idBlanks.find((blank: AnyObject) => blank?.id === event.data.id) ?? emptyBlank;
        setIdBlanks(_.without(idBlanks, deleteIdBlank));
      } else if (setIdBlanks) {
        setIdBlanks([...(idBlanks ?? []), { id: event.data.id, number: event.data.number }]);
      }
    },
    [directory, idBlanks, setIdBlanks]
  );

  const containerCallbacks: ContainerCallbacks = useMemo(
    () => ({
      handleAGGInit,
      toggleShowFilterMenu,
      toggleShowRowsMenu,
      toggleShowQuickFilter,
      handleProvideData,
      handleSelectRow,
      handleSetSelectedRows,
      cellEditingStopped,
      functionCellClass,
      onFilterChanged,
      clearAgGridFilters,
    }),
    [
      handleAGGInit,
      toggleShowFilterMenu,
      toggleShowRowsMenu,
      toggleShowQuickFilter,
      handleProvideData,
      handleSelectRow,
      handleSetSelectedRows,
      cellEditingStopped,
      functionCellClass,
      onFilterChanged,
      clearAgGridFilters,
    ]
  );

  const clickBreadcrumbsControlled = useCallback(() => setPageMenuKey(IOPWDirectory.main), [setPageMenuKey]);

  return (
    <>
      {isHeader && <HeaderScipBlock />}
      {!!breadcrumbsLinks && (
        <BreadcrumbsControlled links={breadcrumbsLinks} clickBreadcrumbsControlled={clickBreadcrumbsControlled} />
      )}
      <ActionButtons containerCallbacks={containerCallbacks} quickMenu={quickMenu} buttons={buttons} />
      <CompendiumContainer>
        {tableRenderingModel && (
          <Compendium
            aggApi={aggApi}
            data={data}
            model={tableRenderingModel}
            showQuickFilter={showQuickFilter}
            containerCallbacks={containerCallbacks}
            gridCellSizes={gridCellSizes}
            agGridThemeName={agGridThemeName}
            namesCollectionForFiltering={namesCollectionForFiltering}
          />
        )}
      </CompendiumContainer>
    </>
  );
});
