import { Application } from 'generated/api/api';

export enum modeEnum {
  view = 'view',
  edit = 'edit',
}

export interface OrderApplicationsI {
  appointedTeamId: number;
  teamMastersId: Array<number>;
}

export interface OrderApplicationsButtonI {
  teamMastersId: Array<number>;
  appointedTeamId: number;
  applications: Array<Application>;
  onUploadApplications: () => void;
}
