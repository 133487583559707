import { useReducer } from 'react';
import _ from 'lodash';

import { MaskAction, SettingsCameraUdcState } from './useCamerasState.model';
import { ObjectType } from '../utils/masks/prototype/ObjectType';
import { MaskType } from '../utils/masks/maskRegistrator';
import { TypePoints } from '../utils/masks/prototype/CameraMaskPrototype';

const initialState: SettingsCameraUdcState = {
  modalVisible: false,
  currentMask: null,
  visibleAllMasks: true,
  addedMasks: [],
  deletedMasks: [],
};

function reducer(state: SettingsCameraUdcState, action: any) {
  switch (action.type) {
    case MaskAction.closeModal: {
      return {
        ...initialState,
      };
    }
    case MaskAction.openModal: {
      return {
        ...state,
        modalVisible: true,
      };
    }
    case MaskAction.setCurrentMask: {
      const currentMask = action.payload.mask;
      currentMask.initialDraw();

      const addedMasks = state.addedMasks.map((el) => {
        if (el.maskType !== currentMask.maskType) el.visible = false;
        return el;
      });

      return {
        ...state,
        currentMask,
        addedMasks,
      };
    }
    case MaskAction.cancelCurrentMask: {
      state.currentMask?.finishDraw();
      const addedMasks = state.addedMasks.map((el) => {
        el.visible = true;
        return el;
      });

      return {
        ...state,
        currentMask: null,
        addedMasks,
      };
    }
    case MaskAction.saveCurrentMask: {
      state?.currentMask?.saveDraw();
      const addedMasks = state.addedMasks.map((el) => {
        el.visible = true;

        if (el.id === state?.currentMask?.id) {
          return state?.currentMask;
        }

        return el;
      });
      const mask = state.addedMasks.find((el: any) => el.id === state?.currentMask?.id);
      if (!mask && state.currentMask) addedMasks.push(state.currentMask);

      return {
        ...state,
        addedMasks,
        currentMask: null,
      };
    }
    case MaskAction.setAddedMasks: {
      return {
        ...state,
        addedMasks: action.payload.addedMasks,
      };
    }
    case MaskAction.changeVisibleMask: {
      let visibleAllMasks = false;
      const addedMasks = state.addedMasks.map((el) => {
        if (el.id === action.payload.id) el.visible = !el.visible;
        if (el.visible) visibleAllMasks = true;
        return el;
      });

      return {
        ...state,
        addedMasks,
        visibleAllMasks,
      };
    }
    case MaskAction.changeVisibleAllMasks: {
      const addedMasks = state.addedMasks.map((el) => {
        el.visible = !state.visibleAllMasks;
        return el;
      });

      return {
        ...state,
        addedMasks,
        visibleAllMasks: !state.visibleAllMasks,
      };
    }
    case MaskAction.deleteMask: {
      const deletedMasks = [...state.deletedMasks];
      const addedMasks = state.addedMasks.filter((el) => {
        if (el.id === action.payload.id && el.maskID) deletedMasks.push(el.maskID);

        return el.id !== action.payload.id;
      });

      return {
        ...state,
        addedMasks,
        deletedMasks,
      };
    }
    case MaskAction.changeDrawType: {
      if (state.currentMask) {
        const currentMask = _.cloneDeep(state.currentMask);
        currentMask.currentDrawType = action.payload.type;

        return {
          ...state,
          currentMask,
        };
      }

      return state;
    }
    case MaskAction.changePositionForDrawType: {
      if (state.currentMask) {
        const currentMask = _.cloneDeep(state.currentMask);
        const currentDrawType = currentMask.currentDrawType as ObjectType;

        currentMask.drawMaskPoints = {
          ...currentMask.drawMaskPoints,
          [currentDrawType]: action.payload.points,
        };

        return {
          ...state,
          currentMask,
        };
      }

      return state;
    }
    case MaskAction.changeMaskName: {
      if (state.currentMask) {
        const currentMask = _.cloneDeep(state.currentMask);
        currentMask.tmpName = action.payload.name;

        return {
          ...state,
          currentMask,
        };
      }

      return state;
    }
    default:
      return state;
  }
}

export default function useCamerasState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = () => dispatch({ type: MaskAction.openModal });
  const closeModal = () => dispatch({ type: MaskAction.closeModal });
  const setCurrentMask = (mask: MaskType) => dispatch({ type: MaskAction.setCurrentMask, payload: { mask } });
  const cancelCurrentMask = () => dispatch({ type: MaskAction.cancelCurrentMask });
  const saveCurrentMask = () => dispatch({ type: MaskAction.saveCurrentMask });
  const changeVisibleMask = (id: number) => dispatch({ type: MaskAction.changeVisibleMask, payload: { id } });
  const changeVisibleAllMasks = () => dispatch({ type: MaskAction.changeVisibleAllMasks });
  const deleteMask = (id: number) => dispatch({ type: MaskAction.deleteMask, payload: { id } });
  const changeDrawType = (type: ObjectType) => dispatch({ type: MaskAction.changeDrawType, payload: { type } });
  const changePosition = (points: TypePoints) =>
    dispatch({ type: MaskAction.changePositionForDrawType, payload: { points } });
  const setAddedMasks = (addedMasks: MaskType[]) =>
    dispatch({ type: MaskAction.setAddedMasks, payload: { addedMasks } });
  const changeMaskName = (name: string) => dispatch({ type: MaskAction.changeMaskName, payload: { name } });

  const actions = {
    openModal,
    closeModal,
    setCurrentMask,
    cancelCurrentMask,
    saveCurrentMask,
    changeVisibleMask,
    changeVisibleAllMasks,
    deleteMask,
    changeDrawType,
    changePosition,
    setAddedMasks,
    changeMaskName,
  };

  return {
    state,
    actions,
  };
}
