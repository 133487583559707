import { getFromURL } from 'api/getFromURL';
import { RoadConstructionsService } from 'generated/api/api';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { RequestPath } from 'types/enums/routes/request/RequestPath';
import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';

export class DigitalTwinRoadSignsGroup extends RelatedDataPrototype {
  constructor() {
    super({
      name: MapObjects.roadSignGroup,
      filterLabel: 'Группа дорожных знаков',
      selectedObjectName: MapObjects.roadSignGroup,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsRoadSignGroupsList();
  }

  async deleteRecord(id: number) {
    return await RoadConstructionsService.roadConstructionsRoadSignGroupsDestroy({ id });
  }

  async readRecord(id: number) {
    return await RoadConstructionsService.roadConstructionsRoadSignGroupsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadConstructionsRoadSignGroups);
  }
}
