import { DrawLineStringMode, DrawPointMode, DrawPolygonMode } from '@nebula.gl/edit-modes';

/**
 * Возвращает тип метода редактирования геометрии из схемы
 * @param {Object} model
 * @returns {null|DrawLineStringMode || DrawPointMode || DrawPolygonMode}
 * @see DrawLineStringMode
 * @see DrawPointMode
 * @see DrawPolygonMode
 */
export const defineDrawModeOnModel = (model) => {
  const { scheme } = model;
  for (let it in scheme) {
    if (scheme[it].type === 'line string') return DrawLineStringMode;
    if (scheme[it].type === 'polygon') return DrawPolygonMode;
    if (scheme[it].type === 'point') return DrawPointMode;
    // TODO убрать когда бэк пофиксит баг по урлу https://bkd-api.admtyumen.ru/api/layer_iopw/statement_routes/ Тех-долг-FRONTEND #5802
    if (it === 'path') return DrawLineStringMode;
  }
  return null;
};
/**
 * Возвращает ключ в схеме, содержащий геометрию
 * @param {Object} model
 * @returns {string|null}
 */
export const defineDrawKeyOnModel = (model) => {
  const { scheme } = model;
  for (let it in scheme) {
    if (scheme[it].type === 'line string') return it;
    if (scheme[it].type === 'polygon') return it;
    if (scheme[it].type === 'point') return it;
    // TODO убрать когда бэк пофиксит баг по урлу https://bkd-api.admtyumen.ru/api/layer_iopw/statement_routes/ Тех-долг-FRONTEND #5802
    if (it === 'path') return it;
  }
  return null;
};
/**
 * Возвращает типа геометрии по схеме модели данных
 * @param {Object} model
 * @returns {string|null}
 */
export const defineDrawGeometryTypeOnModel = (model) => {
  const { scheme } = model;
  for (let it in scheme) {
    if (scheme[it].type === 'line string') return 'linestring';
    if (scheme[it].type === 'polygon') return 'polygon';
    if (scheme[it].type === 'point') return 'point';
    // TODO убрать когда бэк пофиксит баг по урлу https://bkd-api.admtyumen.ru/api/layer_iopw/statement_routes/ Тех-долг-FRONTEND #5802
    if (it === 'path') return 'linestring';
  }
  return null;
};

/**
 *
 * @param model
 * @returns {[]}
 */
export const defineNestedDescriptionOnModel = (model) => {
  const { scheme } = model;
  const results = [];
  for (let key in scheme) {
    if (scheme[key].type === 'inline') {
      const newObj = { ...scheme[key] };
      newObj.key = key;
      results.push(newObj);
    }
  }
  return results;
};
