import React, { useCallback, useEffect, useState } from 'react';
import PrintModal from './PrintModal/PrintModal';
import { Select } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import './PrintMenu.scss';
import {
  PrintMenuWrapper,
  PrintMenuHeader,
  PrintMenuBody,
  PrintMenuInput,
  PrintMenuInputBlock,
  PrintMenuFooter,
  PrintMenuFooterButton,
  RedoOutlinedIco,
  PrintMenuAnotherToggle,
} from './PrintMenu.styled';
import { useDispatch, useSelector } from 'react-redux';
import { calculatingDiagonalPoints } from '../PrintUtils/CalculatingDiagonalPointsFunction';
import { getLayersWithSettings } from '../PrintUtils/GetLayersWithSettingsUtils';

const PrintMenu = ({ deckViewRef }) => {
  const [modalActive, setModalActive] = useState(false);
  const [isPaperSize, setIsPaperSize] = useState(false);
  const [paper_format, set_paper_format] = useState('A4');
  const [paper_width, set_paper_width] = useState(null);
  const [paper_height, set_paper_height] = useState(null);
  const [orientation, set_orientation] = useState('album');
  const [cartographic_scale, set_cartographic_scale] = useState('1:200');
  const [paper_count, set_paper_count] = useState(null);
  const [email, set_email] = useState(null);
  const [errorsArray, setErrorsArray] = useState([]);
  const [request_object, set_request_object] = useState(null);
  const dispatch = useDispatch();
  const { Option } = Select;
  const openSizePaper = (value) => {
    value === 'Another' ? setIsPaperSize(true) : setIsPaperSize(false);
    set_paper_format(value);
  };
  // @ts-ignore
  const editObject = useSelector((state) => state?.mapPanels?.selectAreaData?.features?.[0]?.geometry);
  // @ts-ignore
  const mapboxLayers = useSelector((state) => state?.mapboxLayers);

  useEffect(() => {
    const selectedLayerMessage = 'В приложении не выбран ни один слой';
    if (!mapboxLayers.length && !errorsArray.includes(selectedLayerMessage)) {
      setErrorsArray([selectedLayerMessage]);
    }
  }, [errorsArray, mapboxLayers]);

  const calculateAngSetReqObject = useCallback(async () => {
    let isReqObjectCalculatedAngSet = false;
    if (deckViewRef && editObject && typeof deckViewRef.project === 'function') {
      const {
        topLeftCoords = undefined,
        bottomRightCoords = undefined,
        center = undefined,
        answer = undefined,
        answer2 = undefined,
      } = await calculatingDiagonalPoints({
        editCoords: editObject,
        currentViewports: deckViewRef,
        dispatch,
        cartographicScale: '1000',
      });
      const layersWithAllSettings = getLayersWithSettings(mapboxLayers);
      set_request_object({
        layersWithAllSettings,
        center: center, //[lon, lan]
        zoom: deckViewRef.zoom,
        bearing: deckViewRef.bearing,
        pitch: deckViewRef.pitch,
        paper_format: paper_format !== 'Another' ? paper_format : { paper_width, paper_height },
        orientation,
        cartographic_scale,
        paper_count,
        user: localStorage.getItem('user'),
        email,
        answer,
        answer2,
      });

      if (topLeftCoords && bottomRightCoords && center) isReqObjectCalculatedAngSet = true;
    }
    return isReqObjectCalculatedAngSet;
  }, [
    cartographic_scale,
    deckViewRef,
    dispatch,
    editObject,
    email,
    mapboxLayers,
    orientation,
    paper_count,
    paper_format,
    paper_height,
    paper_width,
  ]);

  const checkForm = useCallback(async () => {
    let errors = [];
    const areaMessage = 'Нарисуйте область печати';
    const pageCountMessage = 'Укажите кол-во страниц';
    const isReqObjectCalculatedAngSet = await calculateAngSetReqObject();
    if (!isReqObjectCalculatedAngSet) {
      errors.push(areaMessage);
    }
    if (!paper_count) {
      errors.push(pageCountMessage);
    }
    if (!errors.length) {
      setErrorsArray(errors);
      setModalActive(true);
    } else {
      const commonError = errorsArray.filter(
        (el) => !errors.includes(el) && el !== areaMessage && el !== pageCountMessage
      );
      setErrorsArray([...commonError, ...errors]);
    }
  }, [calculateAngSetReqObject, errorsArray, paper_count]);

  return (
    <div className="PrintMenu">
      <PrintMenuWrapper>
        <PrintMenuHeader>
          <p>Настройка печати</p>
          <RedoOutlinedIco>
            <RedoOutlined />
          </RedoOutlinedIco>
        </PrintMenuHeader>
        <PrintMenuBody>
          <PrintMenuInputBlock>
            <p>Формат бумаги</p>
            <Select defaultValue={paper_format} className="PrintMenuSelect" onChange={openSizePaper}>
              <Option value="A3">A3</Option>
              <Option value="A4">A4</Option>
              <Option value="B4">B4</Option>
              <Option value="B5">B5</Option>
              <Option value="Another">Указать вручную</Option>
            </Select>
          </PrintMenuInputBlock>
          {isPaperSize && (
            <PrintMenuAnotherToggle>
              <PrintMenuInputBlock>
                <p>Ширина</p>
                <PrintMenuInput
                  placeholder="Введите ширину (см)"
                  onChange={(event) => set_paper_width(event.target.value)}
                />
              </PrintMenuInputBlock>
              <PrintMenuInputBlock>
                <p>Высота</p>
                <PrintMenuInput
                  placeholder="Введите высоту (см)"
                  onChange={(event) => set_paper_height(event.target.value)}
                />
              </PrintMenuInputBlock>
            </PrintMenuAnotherToggle>
          )}
          <PrintMenuInputBlock>
            <p>Ориентация</p>
            <Select defaultValue={orientation} className="PrintMenuSelect" onChange={(event) => set_orientation(event)}>
              <Option value="album">Альбомная</Option>
              <Option value="portrait">Портретная</Option>
            </Select>
          </PrintMenuInputBlock>
          <PrintMenuInputBlock>
            <p>Масштаб изображения</p>
            <Select
              defaultValue={cartographic_scale}
              className="PrintMenuSelect"
              onChange={(event) => set_cartographic_scale(event)}
            >
              <Option value="100">1:100</Option>
              <Option value="200">1:200</Option>
              <Option value="400">1:400</Option>
              <Option value="500">1:500</Option>
              <Option value="800">1:800</Option>
            </Select>
          </PrintMenuInputBlock>
          <PrintMenuInputBlock>
            <p>Количество страниц</p>
            <PrintMenuInput
              type={'number'}
              placeholder="Введите количество"
              onChange={(event) => set_paper_count(event.target.value)}
            />
          </PrintMenuInputBlock>
          <PrintMenuInputBlock>
            {/*<p>Нарисовать геометрию</p>*/}
            {/*<PrintMenuButton>Нарисовать автоматически</PrintMenuButton>*/}
          </PrintMenuInputBlock>
          {errorsArray.length
            ? errorsArray.map((el, index) => {
                return (
                  <p
                    style={{
                      textAlign: 'center',
                      color: 'red',
                    }}
                    key={index}
                  >
                    {el}
                  </p>
                );
              })
            : null}
        </PrintMenuBody>
        <PrintMenuFooter>
          <PrintMenuFooterButton onClick={checkForm}>Отправить на почту</PrintMenuFooterButton>
        </PrintMenuFooter>
      </PrintMenuWrapper>
      <div className={modalActive ? 'setBackground_active' : ''} />
      <PrintModal
        active={modalActive}
        setActive={setModalActive}
        set_email={set_email}
        request_object={request_object}
      />
    </div>
  );
};

export default PrintMenu;
