export enum IOPWDirectory {
  individual_statements = 'individual_statements',
  entity_statements = 'entity_statements',
  federal_statements = 'federal_statements',
  coef_for_harm = 'coef_for_harm',
  permissible_axis_loads = 'permissible_axis_loads',
  vehicle_axle_loads = 'vehicle_axle_loads',
  location_axis = 'location_axis',
  vehicle_axes = 'vehicle_axes',
  vehicle_parameters = 'vehicle_parameters',
  write_off_acts = 'write_off_acts',
  destruction_acts = 'destruction_acts',
  forms_account = 'forms_account',
  road_route = 'road_route',
  calc_of_harm = 'calc_of_harm',
  create = 'create',
  payment_types = 'payment_types',
  billings = 'billings',
  ip_billings = 'ip_billings',
  org_billings = 'org_billings',
  permissible_weight = 'permissible_weight',
  approvals = 'approvals',
  approval_files = 'approval_files',
  approval_additional_inspection = 'approval_additional_inspection',
  approval_vehicles = 'approval_vehicles',
  approval_resolutions = 'approval_resolutions',
  statement_billings = 'statement_billings',
  statement_approvals = 'statement_approvals',
  statement_harm_calculations = 'statement_harm_calculations',
  approval_federal_statement_resolutions = 'approval_federal_statement_resolutions',
  approval_federal_statement_vehicles = 'approval_federal_statement_vehicles',
  approval_federal_statement_vehicle_axis = 'approval_federal_statement_vehicle_axis',
  approval_federal_statement_control_stations = 'approval_federal_statement_control_stations',
  approval_federal_statement_additional_inspection = 'approval_federal_statement_additional_inspection',
  send_billing_to_unp = 'send_billing_to_unp',
  check_billing_status_from_unp = 'check_billing_status_from_unp',
  check_billing_payment_from_unp = 'check_billing_payment_from_unp',
  new_act = 'new_act',
  cancels = 'cancels',
  adapter_messages = 'adapter_messages',
  files = 'files',
  additional_inspections = 'additional_inspections',
  vehicles = 'vehicles',
  roads = 'roads',
  routes = 'routes',
  vehicle_axis = 'vehicle_axis',
  control_stations = 'control_stations',
  axle_load_overloads = 'axle_load_overloads',
  dimensions_height_oversizes = 'dimensions_height_oversizes',
  dimensions_width_oversizes = 'dimensions_width_oversizes',
  dimensions_segment_width_oversizes = 'dimensions_segment_width_oversizes',
  general_dimensions_oversizes = 'general_dimensions_oversizes',
  federal_files = 'federal_files',
  main = 'main',
  excess_percents = 'excess_percents',
  history_change_statement = 'history_change_statement',
  non_working_days = 'non_working_days',
  global_organizations = 'global_organizations',
  signers = 'signers',
  responsible_persons = 'responsible_persons',
  special_permits = 'special_permits',
  additional_inspection_comment_templates = 'additional_inspection_comment_templates',
  approval_comment_templates = 'approval_comment_templates',
  decline_comment_templates = 'decline_comment_templates',
  special_condition_comment_templates = 'special_condition_comment_templates',
}

export enum RequiredSmevStatement {
  REGISTRATION_DENIED = 'REGISTRATION_DENIED',
  REFUSED_TO_ISSUE = 'REFUSED_TO_ISSUE',
  ISSUED_BY = 'ISSUED_BY',
  AGREED = 'AGREED',
  DENIED = 'DENIED',
  SEND_FOR_APPROVAL = 'SEND_FOR_APPROVAL',
}
