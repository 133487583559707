import styled from 'styled-components/macro';
import { LocalMainWrapperPropsModel } from './model/LocalMainWrapperProps.model';
import { LocalTitleItemPropsModel } from './model/LocalTitleItemProps.model';

const LocalMainWrapper = styled.section<LocalMainWrapperPropsModel>`
  .ReactCollapse--collapse {
    transition: height ${(props) => (props.transitionDurationInMs ? `${props.transitionDurationInMs}ms` : '300ms')};
  }
`;

const LocalTitleItem = styled.div<LocalTitleItemPropsModel>`
  transition: all 0.3s;
  display: flex;
  font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.primary};
  &:nth-of-type(2) {
    margin-left: ${(props) => props.arrowIconOffset ?? '0'};
    transform: ${(props) => (props.isOpened ? 'rotate(0)' : `rotate(-${props.rotateDegree ?? 90}deg)`)};
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const LocalTitleGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Styled = { LocalMainWrapper, LocalTitleGroup, LocalTitleItem };
