import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Styled } from './UploadTMCFileButton.styled';
import { TMCUploadModal } from './UploadTMCFileModal';
import TextIconButton from '../../common/TextIconButton';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

const { LocalDivMap } = Styled;

interface IUploadTMCFileTextButtonProps {
  tooltip: string;
  pageMenuKey: string;
  id: string;
  refreshData?: () => void;
  updateAsyncData?: () => void;
}

const UploadTMCFileTextButton = ({
  tooltip,
  pageMenuKey,
  id,
  refreshData,
  updateAsyncData,
}: PropsWithChildren<IUploadTMCFileTextButtonProps>) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const onOpenModal = useCallback(() => setOpenModal(true), []);

  return (
    <LocalDivMap>
      <TextIconButton
        clickButton={onOpenModal}
        tooltip={tooltip}
        isDisabled={false}
        labelText={'Загрузить новый файл'}
        type={ButtonType.primary}
      />
      <TMCUploadModal
        isOpenModal={isOpenModal}
        setOpenModal={setOpenModal}
        pageMenuKey={pageMenuKey}
        id={id}
        refreshData={refreshData}
        updateAsyncData={updateAsyncData}
      />
    </LocalDivMap>
  );
};

export default UploadTMCFileTextButton;
