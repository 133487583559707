import styled from 'styled-components';

const StyledWrapper = styled.div`
  margin: 5px 5px 20px;
  width: calc(100% / 3 - 40px);
  min-width: 314px;
  justify-content: flex-start;
  position: relative;
  display: ${(props) => (props.hidden ? 'hidden' : 'flex')};
  .ant-input,
  .ant-input-number {
    border-radius: 8px;
    color: ${(props) => props.theme.colors.paragraph};
    background-color: ${(props) => props.theme.colors.backgroundNeutral};
    width: 100%;
    height: 58px;
    border-color: ${(props) => props.theme.colors.transparent};
    input {
      margin-top: 22px;
    }
    &:focus,
    &:hover {
      border: 2px solid ${(props) => props.theme.colors.primaryTranslucent};
    }
  }
  .ant-input {
    padding-top: 24px;
  }
  span.base_placeholder {
    top: 15px;
  }
  span.class_errors {
    color: ${(props) => props.theme.colors.danger};
    font-weight: 500;
    font-size: 15px;
  }

  div.class_errors {
    .ant-select-selection,
    .ant-input,
    .ant-input-number {
      border-radius: 8px;
      border: 2px solid ${(props) => props.theme.colors.danger};
    }
  }
`;

const VehicleButtonContainer = styled.div`
  display: flex;
  button {
    background-color: ${(props) => props.theme.colors.transparent};
    width: auto;
    box-shadow: none;
    color: ${(props) => props.theme.colors.primary};
    white-space: nowrap;
    :hover {
      color: ${(props) => props.theme.colors.defaultLighter};
    }
  }
  border-right: 1px solid ${(props) => props.theme.colors.neutralDark};
  padding-right: 10px;
`;

const TabContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  div:first-child {
    border-radius: 16px 0 0 0;
  }
  .ant-dropdown-open {
    background-color: ${(props) => props.theme.colors.transparent};
  }
  .is_activ_button_container {
    background-color: ${(props) => props.theme.colors.primary};
    p,
    path {
      color: ${(props) => props.theme.colors.defaultLighter};
      fill: ${(props) => props.theme.colors.defaultLighter};
    }
    .ant-btn-default > i {
      ::before,
      ::after {
        background-color: ${(props) => props.theme.colors.defaultLighter};
      }
    }
  }
  overflow-y: hidden;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.defaultLighter};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutralDark};
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.neutralDarker};
  }
`;

const MainVPContainer = styled.div`
  width: 100%;
  height: calc(40% - 40px);
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
`;

const AxesParameterCointainer = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
`;

const CreatePVButtonContainer = styled.div`
  position: relative;
  p {
    color: ${(props) => props.theme.colors.defaultLighter} !important;
  }
  span {
    position: absolute;
    top: 9px;
    left: 25px;
    color: ${(props) => props.theme.colors.defaultLighter};
  }
  button {
    width: 150px;
  }
`;

const LabelContainer = styled.div`
  width: 250px;
  height: 100%;
  p {
    color: ${(props) => props.theme.colors.neutralDarker};
    font-weight: bold;
    font-size: 14px;
    padding-top: 40px;
    &:last-child {
      padding-top: 60px;
    }
  }
`;

const DropdownStyledContainer = styled.div`
  .ant-btn-default {
    display: none;
  }
  .ant-dropdown-trigger {
    display: inline;
    border: none;
    width: 20px;
    :active,
    :hover,
    :checked {
      background-color: ${(props) => props.theme.colors.transparent};
    }
    i {
      svg {
        display: none;
      }
      ::before,
      ::after {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 1px;
        background-color: ${(props) => props.theme.colors.primary};
      }
      ::before {
        top: 18px;
        left: 7px;
        transform: rotate(45deg);
      }
      ::after {
        top: 18px;
        left: 12px;
        transform: rotate(-45deg);
      }
    }
  }
`;

const HeaderDropdownMenu = styled.p`
  margin: 0;
  padding-left: 10px;
`;

const AxelContainer = styled.div`
  position: relative;
  width: 200px;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  p {
    color: ${(props) => props.theme.colors.neutralDarker};
    font-weight: bold;
    font-size: 14px;
    padding-left: 50px;
  }
`;

const AxelsContainer = styled.div`
  width: calc(100% - 200px);
  height: 100%;
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 1100px;
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.defaultLighter};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutralDark};
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.neutralDarker};
  }
`;

const PictureAxel = styled.div`
  position: absolute;
  width: 16px;
  height: 170px;
  background-color: ${(props) => props.theme.colors.defaultLight};
  top: 35px;
  left: 50px;
  ::before,
  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 72px;
    height: 16px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.defaultLight};
  }
  ::before {
    top: 0;
    left: -28px;
  }
  ::after {
    top: 155px;
    left: -28px;
  }
`;

const NumberInputVPContainer = styled(StyledWrapper)`
  margin: 0 0 0 110px;
  .ant-input-number {
    font-weight: 500;
    font-size: 18px;
    width: 88px;
    height: 56px;
    input {
      margin-top: 4px;
      padding-top: 17px;
    }
  }
  &:nth-child(4) {
    margin: 30px 0 40px 14px;
  }
  &:last-child {
    margin: 14px;
  }
`;

export const Styled = {
  VehicleButtonContainer,
  TabContainer,
  CreatePVButtonContainer,
  DropdownStyledContainer,
  HeaderDropdownMenu,
  MainVPContainer,
  AxesParameterCointainer,
  LabelContainer,
  AxelContainer,
  AxelsContainer,
  PictureAxel,
  NumberInputVPContainer,
  StyledWrapper,
};
