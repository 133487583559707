export enum SelectedItemTypeEnum {
  /** Словарь для ДТП */
  TYPE_01 = 'Иной вид ДТП',
  TYPE_02 = 'Наезд на велосипедиста',
  TYPE_03 = 'Наезд на внезапно возникшее препятствие',
  TYPE_04 = 'Наезд на гужевой транспорт',
  TYPE_05 = 'Наезд на животное',
  TYPE_06 = 'Наезд на лицо, не являющееся участником дорожного движения (иного участника ДТП), осуществляющее какую-либо другую деятельность',
  TYPE_07 = 'Наезд на лицо, не являющееся участником дорожного движения (иного участника ДТП), осуществляющее несение службы',
  TYPE_08 = 'Наезд на лицо, не являющееся участником дорожного движения (иного участника ДТП), осуществляющее производство работ',
  TYPE_09 = 'Наезд на лицо, не являющееся участником дорожного движения, осуществляющее какую-либо другую деятельность',
  TYPE_10 = 'Наезд на лицо, не являющееся участником дорожного движения, осуществляющее несение службы',
  TYPE_11 = 'Наезд на лицо, не являющееся участником дорожного движения, осуществляющее производство работ',
  TYPE_12 = 'Наезд на пешехода',
  TYPE_13 = 'Наезд на препятствие',
  TYPE_14 = 'Наезд на стоящее ТС',
  TYPE_15 = 'Опрокидывание',
  TYPE_16 = 'Отбрасывание предмета',
  TYPE_17 = 'Отбрасывание предмета(отсоединение колеса)',
  TYPE_18 = 'Падение груза',
  TYPE_19 = 'Падение пассажира',
  TYPE_20 = 'Столкновение',
  TYPE_21 = 'Съезд с дороги',
  /** Словарь для Overpasses */
  BRIDGE = 'Мост',
  OVERPASS = 'Путепроводод',
  VIADUCT = 'Виадук',
  CATTLE_DRIVE = 'Скотопрогон',
  TRESTLE = 'Эстакада',
  /** Словарь для barrier type */
  RIVER = 'Река',
  CREEK = 'Ручей',
  CHANNEL = 'Канал',
  RAILWAY = 'Железная дорога',
  HIGHWAY = 'Автомобильная дорога',
  LAKE = 'Озеро',
  UNSPECIFIED_BARRIER = 'Неуказанное препятствие',
}

export enum RoadAccidentsTypeIconEnum {
  /** Словарь для иконок ДТП */
  TYPE_01 = '/img/dtp_icon/1.png',
  TYPE_02 = '/img/dtp_icon/2.png',
  TYPE_03 = '/img/dtp_icon/3.png',
  TYPE_04 = '/img/dtp_icon/4.png',
  TYPE_05 = '/img/dtp_icon/5.png',
  TYPE_06 = '/img/dtp_icon/6.png',
  TYPE_07 = '/img/dtp_icon/7.png',
  TYPE_08 = '/img/dtp_icon/8.png',
  TYPE_09 = '/img/dtp_icon/9.png',
  TYPE_10 = '/img/dtp_icon/10.png',
  TYPE_11 = '/img/dtp_icon/11.png',
  TYPE_12 = '/img/dtp_icon/12.png',
  TYPE_13 = '/img/dtp_icon/13.png',
  TYPE_14 = '/img/dtp_icon/14.png',
  TYPE_15 = '/img/dtp_icon/15.png',
  TYPE_16 = '/img/dtp_icon/16.png',
  TYPE_17 = '/img/dtp_icon/17.png',
  TYPE_18 = '/img/dtp_icon/18.png',
  TYPE_19 = '/img/dtp_icon/19.png',
  TYPE_20 = '/img/dtp_icon/20.png',
  TYPE_21 = '/img/dtp_icon/21.png',
}

export enum SupportWallTypeEnum {
  /** Словарь для типов подпорных стен */
  CONCRETE_BLOCKS = 'Бетонные блоки',
  WOOD = 'Дерево',
  GRAVEL = 'Каменная кладка',
  BRICKWORK = 'Кирпичная кладка',
  METAL = 'Металл',
  MONOLITHIC_REINFORCED_CONCRETE = 'Монолитный железобетон',
}

export enum RoadFencesTypeEnum {
  /** Словарь для типов подпорных стен */
  BARRIER = 'Барьерное',
  PARAPET = 'Парапетное',
  CABLE = 'Тросовое',
}

export enum EngineeringConstructionsTypeEnum {
  /** Словарь для типов инженерных дорожных конструкций */
  BRIDGE = 'Мост',
  SMALL_BRIDGE = 'Малый мост',
  OVERPASS = 'Путепровод',
  VIADUCT = 'Виадук',
  FLYOVER = 'Эстакада',
  TUNNEL = 'Тоннель',
  CITY_TUNNEL = 'Городской тоннель',
  PEDESTRIAN_TUNNEL = 'Пешеходный тоннель',
  OVERHEAD_PEDESTRIAN_CROSSING = 'Надземный пешеходный переход',
  OVERHEAD_COMMUNICATIONS = 'Надземные коммуникации',
  UNDERGROUND_PEDESTRIAN_CROSSING = 'Подземный пешеходный переход',
  U_SHAPED_SUPPORT_FOR_TSODD = 'П-образная опора для ТСОДД',
}

export enum MaterialTypeEnum {
  ASPHALT_CONCRETE = 'Асфальтобетон',
  CEMENT_CONCRETE = 'Цементобетон',
  NO = 'Нет',
  GRAVEL = 'Гравий',
  CRUSHED_STONE = 'Щебень',
  PRIMING = 'Грунт',
  METAL = 'Металл',
  PAVING_STONE = 'Брусчатка',
  RUBBER_PLASTIC = 'Резинопластик',
  LAWN = 'Газон',
  PAVING_WITH_TILES = 'Мощение плиткой',
  WOOD = 'Дерево',
  REINFORCED_CONCRETE_SLABS = 'Железобетонные плиты',
  PAVING_SLABS = 'Тротуарная плитка',
}

export enum RoadWaysTypeEnum {
  MAIN_ROADWAY = 'Основная проезжая часть',
  CROSSROADS = 'Перекресток',
  EXIT_TO_THE_ADJACENT_TERRITORY = 'Съезд на прилегающую территорию',
  STOPPING_PLATFORM = 'Остановочная площадка (карман)',
  AREA_ADJACENT_TO_THE_ROADWAY = 'Площадка, прилегающая к проезжей части',
  TRANSITION_LANE = 'Переходно-скоростная полоса',
  SLOP_AND_TURNAROUND_PLATFORM_FOR_PUBLIC_TRANSPORT = 'Отстойно-разворотная площадка общественного транспорта плиты',
  LOCAL_TRAVEL = 'Местный проезд',
}

export enum CamerasAndDetectorsTypeEnum {
  DETECTOR = 'Детектор',
  CAMERA = 'Камера',
}

export enum SpeedBumpsMaterialTypeEnum {
  ASPHALT = 'Асфальт',
  RUBBER = 'Резина',
  COMPOSITE = 'Композит',
  POLYMER_SAND = 'Полимер',
}

export enum SpeedBumpsTypeEnum {
  PREFABRICATED = 'Сборный',
  FABRICATED = 'Цельный',
}
