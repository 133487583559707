import { Form } from 'antd';
import React, { useMemo } from 'react';
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form';
import _ from 'lodash';

import { Styled } from '../styled/BaseInput.styled';
import Input from '../../components/Input/Input';

const { BaseErrorMessage } = Styled;

const StringInput = (props) => {
  const { control, errors } = useFormContext();
  const { description, object, field, inputProps } = props;
  const required = description.required;
  const message = `${description.label} неверно заполнено`;
  const error = _.get(errors, field);

  const objectValue = useMemo(() => _.get(object, field), [field, object]);

  if (error && error.message?.includes(description.label)) {
    error.message = null;
  }

  return (
    <Form.Item key={'form_' + field}>
      <Controller
        defaultValue={objectValue}
        as={<Input {...inputProps} />}
        key={'input_' + field}
        name={field}
        error={!!error}
        control={control}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        placeholder={description.label}
      />
      <ErrorMessage as={BaseErrorMessage} errors={errors} name={field} />
    </Form.Item>
  );
};

export default StringInput;
