import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { CargoTransportParams } from '../interfacesApplications/interfacesApplications';
import { TaskObjectFormsContainerProps } from './model/TaskObjectFormsContainer.model';
import { SemifinishedObjectForm } from './SemifinishedObjectForm/SemifinishedObjectForm';
import { TaskObject } from './TaskObject/TaskObject';

export const TaskObjectFormsContainer = ({
  parentModel,
  setCustomFieldsData,
  data,
  setData,
  extraData,
}: TaskObjectFormsContainerProps) => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { directory } = params;
  const taskObject = parentModel.scheme.task_objects?.child?.children;

  const result = useMemo(() => {
    if (directory === ApplicationsDirectory.main) {
      return <SemifinishedObjectForm extraData={extraData} />;
    } else if (!!taskObject && directory === ApplicationsDirectory.tasks) {
      return (
        <TaskObject
          field={'task_objects'}
          descriptions={taskObject}
          setCustomFieldsData={setCustomFieldsData}
          data={data}
          setData={setData}
          extraData={extraData}
        />
      );
    }
    return null;
  }, [data, directory, extraData, setCustomFieldsData, setData, taskObject]);

  return result;
};
