import { ApplicationsDescription } from 'types/enums/applications/ApplicationsDescription';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { ApplicationsFolders } from 'types/enums/applications/ApplicationsFolders';
import { ApplicationsLabels } from 'types/enums/applications/ApplicationsLabels';
import { ILinksCT } from '../ApplicationsForm/interfacesApplications/interfacesApplications';
import IndividualOrdersContainer from '../Pages/IndividualOrdersContainer';
import { generateRightsByKey } from '../../../../../services/application/permissions/generateRightsByKey';

export const links: ILinksCT[] = [
  // Справочники заявок
  {
    label: ApplicationsLabels.main,
    description: ApplicationsDescription.main,
    url: ApplicationsDirectory.main,
    component: IndividualOrdersContainer,
    folder: ApplicationsFolders.applications,
    permissions: generateRightsByKey('application', 'applications'),
  },
  {
    label: ApplicationsLabels.teams_of_masters,
    description: ApplicationsDescription.teams_of_masters,
    url: ApplicationsDirectory.teams_of_masters,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('teamofmasters', 'applications'),
    folder: ApplicationsFolders.applications,
  },
  {
    label: ApplicationsLabels.expendable_materials,
    description: ApplicationsDescription.expendable_materials,
    url: ApplicationsDirectory.expendable_materials,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('expendablematerial', 'applications'),
    folder: ApplicationsFolders.applications,
  },
  {
    label: ApplicationsLabels.materials,
    description: ApplicationsDescription.materials,
    url: ApplicationsDirectory.materials,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('material', 'applications'),
    folder: ApplicationsFolders.applications,
  },
  {
    label: ApplicationsLabels.types,
    description: ApplicationsDescription.types,
    url: ApplicationsDirectory.types,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('applicationtype', 'applications'),
    folder: ApplicationsFolders.applications,
  },
  {
    label: ApplicationsLabels.teams_of_workers,
    description: ApplicationsDescription.teams_of_workers,
    url: ApplicationsDirectory.teams_of_workers,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('teamofworkers', 'applications'),
    folder: ApplicationsFolders.applications,
  },
  {
    label: ApplicationsLabels.photos,
    description: ApplicationsDescription.photos,
    url: ApplicationsDirectory.photos,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('applicationphoto', 'applications'),
    folder: ApplicationsFolders.applications,
  },
  // Справочники задач
  {
    label: ApplicationsLabels.tasks,
    description: ApplicationsDescription.tasks,
    url: ApplicationsDirectory.tasks,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('task', 'applications'),
    folder: ApplicationsFolders.tasks,
  },
  {
    label: ApplicationsLabels.task_objects,
    description: ApplicationsDescription.task_objects,
    url: ApplicationsDirectory.task_objects,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('taskobject', 'applications'),
    folder: ApplicationsFolders.tasks,
  },
  {
    label: ApplicationsLabels.sources,
    description: ApplicationsDescription.source,
    url: ApplicationsDirectory.sources,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('applicationsource', 'applications'),
    folder: ApplicationsFolders.applications,
  },
  {
    label: ApplicationsLabels.responsible_persons,
    description: ApplicationsDescription.responsible_persons,
    url: ApplicationsDirectory.responsible_persons,
    component: IndividualOrdersContainer,
    permissions: generateRightsByKey('responsibleperson', 'users'),
    folder: ApplicationsFolders.tasks,
  },
];
