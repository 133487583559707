import { Styled } from './TooltipGroup.styled';

const { LocalWrapper, LocalTitle, LocalItem } = Styled;

interface TooltipGroupProps {
  title: string;
  children: JSX.Element[];
}

const TooltipGroup = ({ title, children }: TooltipGroupProps) => {
  return (
    <LocalWrapper>
      {title && <LocalTitle>{title}</LocalTitle>}
      <LocalItem>{children}</LocalItem>
    </LocalWrapper>
  );
};

export default TooltipGroup;
