import { put, call, takeEvery, delay } from 'redux-saga/effects';
import { postToURLSingle } from '../../../api/postToURLSingle';
import { makeUrlWithoutIdAtTheEnd } from '../../../services/net/makeUrlWithoutIdAtTheEnd';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import { createAlertMessage } from '../app/createAlertMessage';
import _ from 'underscore';

export const clickOnStartCalculationSaga = (url, data) => ({
  type: START_PROCESS_CALCULATION,
  payload: {
    url,
    data,
  },
});
export const START_PROCESS_CALCULATION = 'START_PROCESS_CALCULATION';

function* startCalculationSaga(action) {
  try {
    yield put(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.success,
        title: 'СТАРТ',
        message: 'Отправленка запроса на начало процесса расчета',
      })
    );
    const { url, data } = action.payload;
    const preparedUrl = makeUrlWithoutIdAtTheEnd(url);
    const response = yield call(() => postToURLSingle.postOrPutDataErrorResponse(preparedUrl, data));
    if (response) {
      yield put(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: 'Запущен процесс расчета показателей',
        })
      );
    }
  } catch (e) {
    if (e?.response && _.isObject(e.response)) {
      const message = createAlertMessage(e);
      yield put(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message: message,
        })
      );
    }
  } finally {
    yield delay(9000);
    yield put(notificationActions.clearGlobalAlertData());
  }
}

export function* watchStartCalculationSaga() {
  yield takeEvery(START_PROCESS_CALCULATION, startCalculationSaga);
}
