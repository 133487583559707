export interface ILayerGroupsPrototypeConstructor {
  label: string;
  name: string;
  decryption: string;
}

export class LayerGroupsPrototype {
  protected label: string;
  protected name: string;
  protected decryption: string;

  constructor({ label, name, decryption }: ILayerGroupsPrototypeConstructor) {
    this.label = label;
    this.name = name;
    this.decryption = decryption;
  }

  public get getLabel(): string {
    return this.label;
  }

  get getName(): string {
    return this.name;
  }

  get getDecryption(): string {
    return this.decryption;
  }
}
