import React, { createContext, PropsWithChildren } from 'react';
import { Portal } from 'react-portal';
import { Styled } from './style/ScreenPortal.styled';
import { ScreenPortalProps } from './model/ScreenPortal.model';

export const PortalContext = createContext(null);

const ScreenPortal = (props: PropsWithChildren<ScreenPortalProps>) => {
  const { portalSide, closePortal = () => {}, isOpen, children } = props;

  return (
    // @ts-ignore
    <PortalContext.Provider value={{ closePortal, isOpen }}>
      {isOpen && (
        <Portal>
          <Styled.LocalContentWrap portalSide={portalSide}>{children}</Styled.LocalContentWrap>
        </Portal>
      )}
    </PortalContext.Provider>
  );
};

export default ScreenPortal;
