import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

import { getNewData } from '../../../BaseModules/Map/MapDisplayMode/getNewData';
import { CompendiumWithButton } from '../Pages/Pages/CompendiumWithButton';
import { IData, CargoTransportParams } from './interfacesCargoTransport/interfacesCargoTransport';
import { Styled } from './ObjectPanel.styled';

const { LocalTitle } = Styled;

interface TabPageProps {
  pageMenuKey: IOPWDirectory;
  data: any[] | null;
  model: ModelResponse;
  buttons: ReactNode;
  selectIdAGGrid: (id: number) => void;
  setEventData: (data: IData) => void;
  refreshData: () => void;
}

export const TabPageContainer = ({
  pageMenuKey,
  data,
  model,
  buttons,
  selectIdAGGrid,
  setEventData,
  refreshData,
}: PropsWithChildren<TabPageProps>) => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { directory } = params;

  const gridCellSizes = useMemo(() => ({ minWidth: 70, width: 220, maxWidth: 350 }), []);

  const newData = useMemo(() => {
    if (Array.isArray(data) && data.length) {
      return data.map((dataElement) => getNewData(dataElement));
    } else if (pageMenuKey === IOPWDirectory.history_change_statement) {
      return [data];
    } else {
      return data ?? [];
    }
  }, [data, pageMenuKey]);

  const lableFederalStatmentFiles =
    pageMenuKey === IOPWDirectory.files && directory === IOPWDirectory.federal_statements;

  const title = lableFederalStatmentFiles ? IOPWTabMenuModel.federal_files : IOPWTabMenuModel[pageMenuKey];

  let totalAmount = null;
  if (pageMenuKey === IOPWDirectory.calc_of_harm && Array.isArray(newData) && newData?.length) {
    const sum = newData
      .reduce((summa, current) => summa + current?.fact_harm_calc, 0)
      .toFixed(2)
      .replace('.', ',');
    totalAmount = <p>(Всего {sum} руб)</p>;
  }

  return (
    <>
      <LocalTitle>
        {title}
        {totalAmount}
      </LocalTitle>
      <CompendiumWithButton
        data={newData}
        model={model}
        showQuickFilter={false}
        gridCellSizes={gridCellSizes}
        agGridThemeName={'ag-theme-custom-base'}
        namesCollectionForFiltering={[]}
        buttons={buttons}
        selectIdAGGrid={selectIdAGGrid}
        pageMenuKey={pageMenuKey}
        setEventData={setEventData}
        refreshData={refreshData}
        isHeader={false}
        breadcrumbsLinks={null}
      />
    </>
  );
};
