import React, { useMemo } from 'react';
import ScrollableBoxArea from 'UI/common/layouts/scrollableBoxArea/ScrollableBoxArea';
import { CollapsableItem } from 'UI/common/components/CollapsableItem/CollapsableItem';
import { Styled } from '../../../BaseModules/Directory/DirectoryFolder/DirectoryCardsFolder/DirectoryCards.styled';
import { arrayGroupBy } from 'services/data/arrayGroupBy';
import { Link } from 'react-router-dom';
import { StarIcon } from 'UI/custom/icons/actionIcons/StarIcon';
import { OptionIcon } from 'UI/custom/icons/actionIcons/OptionIcon';
import { HeaderScipBlock } from '../../../Common/HeaderScipBlock/HeaderScipBlock';
import { links } from './LinksCargoTransport';
import _ from 'underscore';
import { ILinksCT } from '../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

const {
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardWrapper,
  LocalCardStar,
  LocalCardPoint,
  LocalMainWrapper,
  LocalCardDescr,
  LocalCardsWrapper,
  ExtendedCollapseItem,
} = Styled;

const CargoTransportFolder = () => {
  const uniqueFolders = useMemo(() => _.uniq(links.map((item) => item.folder)), []);
  const resultList = arrayGroupBy(links, (rule: ILinksCT) => rule.folder);
  const cardsGroupsForRender: {
    name: string;
    cardsGroup: any[];
  }[] = useMemo(() => {
    const result = [];
    for (let folder of uniqueFolders) {
      const oneCardsGroup = [];
      const itemsCollection = resultList.get(folder);
      oneCardsGroup.push(
        itemsCollection.map((el: ILinksCT, index: number) => (
          <Link to={`/app/${IOPWDirectory.special_permits}/${el.url}/`} key={index}>
            <LocalCardMainWrapper>
              <LocalCardWrapper>
                <LocalCardHeader>
                  {el.label}
                  <LocalCardOption>
                    <LocalCardStar>
                      <StarIcon />
                    </LocalCardStar>
                    <LocalCardPoint>
                      <OptionIcon />
                    </LocalCardPoint>
                  </LocalCardOption>
                </LocalCardHeader>
                <LocalCardDescr>{el.description}</LocalCardDescr>
              </LocalCardWrapper>
            </LocalCardMainWrapper>
          </Link>
        ))
      );
      result.push({
        name: folder,
        cardsGroup: oneCardsGroup,
      });
    }
    return result;
  }, [resultList, uniqueFolders]);

  const CardsGroupsForRenderWrapper = useMemo(
    () =>
      cardsGroupsForRender.map((el) => {
        return (
          <ExtendedCollapseItem>
            <CollapsableItem rotateDegree={90} titleContent={el.name}>
              <div style={{ display: 'flex', backgroundColor: '#f1f3f6', flexWrap: 'wrap' }}>{el.cardsGroup}</div>
            </CollapsableItem>
          </ExtendedCollapseItem>
        );
      }),
    [cardsGroupsForRender]
  );

  return (
    <>
      <HeaderScipBlock />
      <LocalMainWrapper>
        <ScrollableBoxArea>
          <LocalCardsWrapper>{CardsGroupsForRenderWrapper}</LocalCardsWrapper>
        </ScrollableBoxArea>
      </LocalMainWrapper>
    </>
  );
};
export default CargoTransportFolder;
