import React, { useCallback, useEffect, useState } from 'react';
import { InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import { InputNumberProps } from 'antd/es/input-number';
import { point, length, bearing, Feature, Point, rhumbDestination, getCoord, lineString } from '@turf/turf';
import { replaceDrawDataCoordinatesForStandaloneObject } from 'store/reducers/map/actions/mapboxEditableStandaloneObjectActions';

const KM_TO_METERS = 1000;

interface LineDataI {
  firstPoint: Feature<Point>;
  secondPoint: Feature<Point>;
  lineLength: number;
}

const LinePathLengthMarker = () => {
  const dispatch = useDispatch();

  const drawDataLine = useSelector((state: StateModel) => state.mapboxEditableStandaloneObject?.drawData?.features[0]);
  const [lineData, setLineData] = useState<LineDataI | null>(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const coordinates: Array<Array<number>> = drawDataLine?.geometry?.coordinates;
    if (coordinates) {
      const firstPoint: Feature<Point> = point(coordinates[0]);
      const secondPoint: Feature<Point> = point(coordinates[1]);
      const lineLength: number = length(drawDataLine) * KM_TO_METERS;
      const formatLineLength: number = parseFloat(lineLength.toFixed(2));

      setLineData({
        firstPoint,
        secondPoint,
        lineLength: formatLineLength,
      });
      setValue(formatLineLength);
    }
  }, [drawDataLine]);

  const handleChangeInput = useCallback(
    (value) => {
      const length: number = lineData?.lineLength ?? 0;
      value ? setValue(value) : setValue(length);
    },
    [lineData]
  );

  const handlePressEnter = useCallback(
    (e: InputNumberProps) => {
      if (value && lineData) {
        const lineBearing: number = bearing(lineData.firstPoint, lineData.secondPoint);
        const lineLength = value / KM_TO_METERS;
        const newPoint: Feature<Point> = rhumbDestination(lineData.firstPoint, lineLength, lineBearing);
        const firstPointCoords: Array<number> = getCoord(lineData.firstPoint);
        const newPointCoords: Array<number> = getCoord(newPoint);
        const newCoordsArray: Array<Array<number>> = [firstPointCoords, newPointCoords];
        const newLineLength = length(lineString(newCoordsArray));
        dispatch(replaceDrawDataCoordinatesForStandaloneObject(newCoordsArray, newLineLength));
      }
    },
    [dispatch, lineData, value]
  );

  return <InputNumber value={value} onChange={handleChangeInput} onPressEnter={handlePressEnter} />;
};

export default LinePathLengthMarker;
