import styled from 'styled-components/macro';
import { Layout } from 'antd';
import ScrollableBoxArea from '../../../common/layouts/scrollableBoxArea/ScrollableBoxArea';

const PanelLayout = styled(Layout)`
  height: calc(100vh - 240px);
  padding: 0 20px 20px 20px;
  & ${ScrollableBoxArea} {
    margin-left: 20px !important;
  }
`;

export const Styled = {
  PanelLayout,
};
