import { LayerSettingsPrototype } from '../LayerSettingsPrototype';

export class BooleanLayerSetting extends LayerSettingsPrototype {
  type = Boolean;
  currentValue;

  constructor({ currentValue = false, label, keyForDeck }) {
    super({ label, keyForDeck });
    this.currentValue = currentValue;
  }

  getSettingType() {
    return this.type;
  }

  getInitialValue() {
    return this.currentValue;
  }
}
