import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Sidewalk extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'sidewalk',
      filterLabel: 'Тротуары',
      selectedObjectName: MapObjects.sidewalk,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsSidewalksList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsSidewalksDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsSidewalksRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.sidewalks);
  }
}
