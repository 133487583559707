import React, { Suspense } from 'react';

import { editableObjectDescription } from '../../../../../../../registrators/map/editableObjectsRegistrator/editableObjectsRegistrator';
import styled from 'styled-components';

const StyledButtonWrapper = styled.div`
  position: absolute;
  top: 83px;
  left: 74%;
  width: fit-content;
  height: fit-content;
  z-index: 9999;
`;

export const TopActionBar = ({ editableObject }) => {
  const objectType = editableObject?.selectedInstance;
  if (objectType) {
    const registeredType = editableObjectDescription.find((item) => item.name === objectType);
    const customButtons = registeredType?.topPanelButtons?.map((Button) => (
      <Suspense fallback={<p>loading</p>}>
        <Button />
      </Suspense>
    ));
    return <StyledButtonWrapper>{customButtons}</StyledButtonWrapper>;
  } else {
    return null;
  }
};
