import { RoadSchemaLayerSchema } from './RoadSchemaLayerSchema';
import { TrolleybusLayerSchema } from './TrolleybusLayerSchema';
import { TramsSchemaLayerSchema } from './TramsSchemaLayerSchema';
import { ElectricalSubstationSchema } from './ElectricalSubstationSchema';
import { TrafficLightsObjectsSchema } from './TrafficLightsObjectsSchema';
import { CamerasAndDetectorsSchema } from './CamerasAndDetectorsSchema';
import { SpeedBumpsLayerSchema } from './SpeedBumpsLayerSchema';
import { IlluminatedPedestrianCrossingsSchema } from './IlluminatedPedestrianCrossingsSchema';
import { CityDistrictsLayerSchema } from './CityDistrictsLayerSchema';
import { TrafficManagementProjectsLayerSchema } from './TrafficManagementProjectsLayerSchema';
import { ProjectLayerSchema } from './ProjectLayerSchema';
import { BrigadeApplicationsLayerSchema } from './BrigadeApplicationsLayerSchema';
import { MonitoringUDSLayerSchema } from './MonitoringUDSLayerSchema';
import { CamerasUdcLayerSchema } from './CamerasUdcLayerSchema';
import { PublicTransportLayerSchema } from './PublicTransportLayerSchema';
import { TransportOrdersLayerSchema } from './TransportOrdersLayerSchema';
import { RoadAccidentsLayerSchema } from './RoadAccidentsLayerSchema';
/**
 * Используется в: создание слоёв
 * Схема слоя
 * Может содержать:
 * type - тип слоя
 * id - айди слоя
 * mapMode = режим слоя по умолчанию
 * additionalParameters - дополнительные параметры
 * layerToRender - композитный слой для отображения объектов по умолчанию
 * possibleLayersToRender - массив с дополнительными композитными слоями для отображения
 */

export const ekbLayersSchema = {
  RoadSchemaLayerSchema,
  TrolleybusLayerSchema,
  TramsSchemaLayerSchema,
  ElectricalSubstationSchema,
  TrafficLightsObjectsSchema,
  CamerasAndDetectorsSchema,
  SpeedBumpsLayerSchema,
  IlluminatedPedestrianCrossingsSchema,
  CityDistrictsLayerSchema,
  TrafficManagementProjectsLayerSchema,
  ProjectLayerSchema,
  BrigadeApplicationsLayerSchema,
  MonitoringUDSLayerSchema,
  CamerasUdcLayerSchema,
  PublicTransportLayerSchema,
  TransportOrdersLayerSchema,
  RoadAccidentsLayerSchema,
};
