import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import CamerasAndDetectorsCompositeLayer from '../../../../../../layers/CompositeLayers/ekb/CamerasAndDetectorsCompositeLayer';

export class CamerasAndDetectorsSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: CamerasAndDetectorsCompositeLayer,
      id: 'CamerasAndDetectorsSchema',
    });
  }
}
