import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { OpacityColor } = styleMixins.color;

const OrderApplicationsForm = styled.div`
  padding: 12px 16px 16px;
  background: ${(props) => props.theme.colors.defaultLight};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  margin-bottom: 8px;

  & .react-grid-layout::before {
    content: 'Приоритет';
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.danger};
    right: 0;
    top: 9px;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    backdrop-filter: ${(props) => props.theme.decoration.smallBackgroundFilter};
    background-color: ${(props) => OpacityColor(props.theme.colors.danger, 0.08)};
    pointer-events: none;
  }
`;

const OrderApplicationsTitle = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  word-break: break-word;
  color: ${(props) => props.theme.colors.neutralDarker};
  margin-bottom: 8px;
`;

const OrderApplicationsWrap = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  grid-gap: 8px;
  align-items: center;
  position: relative;

  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: ${(props) => props.theme.colors.neutralDark};
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
    top: 0;
    left: 80px;
  }
`;

const OrderApplicationsItemDate = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.gray};
  text-align: right;
`;

const OrderApplicationsItemCircle = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.color};
  background-color: ${(props) => props.theme.colors.defaultLighter};
  position: relative;
  z-index: 2;
`;

const OrderApplicationsItemName = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
`;

const OrderApplicationsButtonWrap = styled.div`
  background: transparent !important;

  button {
    width: 100%;
    margin-top: 16px;
  }

  & div {
    padding: 0;
  }
`;

const OrderItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: ${(props) => props.theme.colors.pink};
`;

const OrderItemIcon = styled(Button)`
  width: 20px;
  height: 20px;
`;

export const Styled = {
  OrderApplicationsForm,
  OrderApplicationsTitle,
  OrderApplicationsWrap,
  OrderApplicationsItemDate,
  OrderApplicationsItemCircle,
  OrderApplicationsItemName,
  OrderApplicationsButtonWrap,
  OrderItem,
  OrderItemIcon,
};
