import React, { Dispatch, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import PropsPasser from 'HOC/PropsPasser';
import { useActionBarRenderer } from 'hooks/directoryTemplates/useActionBarRenderer';
import { resolveUrl } from 'registrators/administrationPage/administratonPageRegistrator';
import { directoryHistoryActions } from 'store/reducers/directory/actions/directoryHistoryActions';
import { selectObject } from 'store/reducers/directory/actions/selectedObjectActions';
import { AnyObject } from 'types/enums/general/general.model';
import { TypeDisplayedDirectoryEnum } from 'types/enums/roles/inlineHistory.model';
import { DirectoryHistory } from 'types/interfaces/roles/directoryHistory.model';
import { Styled } from './TemplateBaseStyle.styled';
import { Breadcrumbs } from 'packages/directory/src/libs/UI/components/Breadcrumb';
import { ContextState } from 'packages/directory/src/interfaces';
import {
  BreadcrumbType,
  ContentRenderType,
  getDirectoryPayload,
  UpdateBreadcrumbsPayload,
} from 'packages/directory/src/types/AppState';
import DirectoryService from 'packages/directory/src/services/directory';
import _ from 'underscore';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import AdministrationHelper from 'packages/directory/src/utils/administration-helper';
import { DataWithCompendiumProps, ISelectedItem, IChangeStateSuccess } from './model/DataWithCompendiumModel';

const { postToInlineHistory } = directoryHistoryActions;

const DataWithCompendium = (props: DataWithCompendiumProps) => {
  const dispatch = useDispatch();
  const {
    lastInHistory,
    folder,
    pushInlineHistory,
    data,
    model,
    urlData,
    selectingObject,
    beforeFetching,
    afterFetching,
    history,
    axiosInstance,
    registeredAdministrationUrls,
    theme,
    pathname,
    ref,
  } = props;

  const [lastAction, setLastAction] = useState<{ key: string; args?: any }>({ key: '', args: {} });
  const [, setChangeHistoryInProcess] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({ id: null });
  const [directoryState, setDirectoryState] = useState<ContextState>({
    changeHistoryInProcess: false,
    breadcrumbs: [
      {
        label: 'Администрирование',
        key: 'administration',
        url: '',
        params: null,
        sider: undefined,
      },
    ],
    sider: undefined,
    model: {},
    data: {},
    contentRenderType: ContentRenderType.AgGrid,
    requestUrl: '',
    parentData: undefined,
  });

  const { breadcrumbs, requestUrl } = directoryState;

  const notificationAlert = useCallback(
    (e, message) => {
      let messages = ['Неизвестная ошибка обратитесь к администратору'];
      if (e?.response && _.isObject(e.response)) {
        messages = createAlertMessage(e);
      }
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: message ? PromptType.success : PromptType.error,
          title: message ? 'Успешно' : 'Ошибка',
          message: message ?? messages,
        })
      );
    },
    [dispatch]
  );

  const beforeFetch = useCallback(() => {
    setChangeHistoryInProcess((prev) => !prev);
    beforeFetching && beforeFetching();
  }, [beforeFetching]);

  const changeStateSuccess = useCallback((payload: IChangeStateSuccess) => {
    setDirectoryState((state: ContextState) => ({
      ...state,
      ...payload,
      changeHistoryInProcess: false,
    }));
  }, []);

  const service = useMemo(() => {
    return new DirectoryService(axiosInstance, directoryState, registeredAdministrationUrls);
  }, [axiosInstance, directoryState, registeredAdministrationUrls]);

  const afterFetch = useCallback(() => {
    setChangeHistoryInProcess((prev) => !prev);
    setSelectedItem({ id: null });
    afterFetching && afterFetching();
  }, [afterFetching]);

  const handleClickBreadcrumbItem = useCallback(
    async (item: BreadcrumbType, index: number) => {
      const { key, url, sider, params } = item;
      if (key === 'administration') {
        history.push('/app/administration/');
      } else {
        beforeFetch();
        const payload: UpdateBreadcrumbsPayload = {
          url,
          sider,
          params,
          breadcrumbIndex: index,
        };

        try {
          const data = await service.updateBreadcrumb(payload);
          changeStateSuccess({ ...data, disableSider: false });
          setLastAction({ key: 'getBreadcrumbData', args: { item, index } });
        } catch (e) {
          notificationAlert(e, false);
        } finally {
          afterFetch();
        }
      }
    },
    [history, beforeFetch, service, changeStateSuccess, notificationAlert, afterFetch]
  );

  const proceedData = useCallback(
    (selectedRow) => {
      const resolvedDataUrl = resolveUrl(folder, 'urlData');
      pushInlineHistory({
        type: TypeDisplayedDirectoryEnum.mainObject,
        ids: selectedRow.data.id,
        urlData: resolvedDataUrl + `${selectedRow.data.id}/`,
        url: `/app/application/${folder}/${selectedRow.data.id}/`,
        field: null,
      });
    },
    [folder, pushInlineHistory]
  );

  const createObject = () => {
    const resolvedDataUrl = resolveUrl(folder, 'urlData');
    selectingObject(null);
    pushInlineHistory({
      type: TypeDisplayedDirectoryEnum.mainObject,
      ids: null,
      urlData: resolvedDataUrl?.toString() ?? '',
      url: `/app/application/${folder}/new/`,
      field: null,
      isNewObject: true,
    });
  };

  const fetchInitialData = useCallback(
    async (pathname: string) => {
      beforeFetch();
      const url = AdministrationHelper.resolveUrl(pathname, registeredAdministrationUrls);
      const payload: getDirectoryPayload = {
        url,
      };
      try {
        const data = await service.getDirectoryRequest({ payload });
        changeStateSuccess({ ...data, disableSider: false });
      } catch (e) {
        notificationAlert(e, false);
      } finally {
        afterFetch();
      }
    },
    [afterFetch, beforeFetch, changeStateSuccess, notificationAlert, registeredAdministrationUrls, service]
  );

  useEffect(() => {
    fetchInitialData(pathname);
    setLastAction({ key: 'initial' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const refresh = useCallback(async () => {
    if (!lastAction) return;
    switch (lastAction.key) {
      case 'initial':
        return fetchInitialData(pathname);
      default:
        return null;
    }
  }, [fetchInitialData, lastAction, pathname]);

  const deleteSelected = useCallback(async () => {
    if (selectedItem.id) {
      const preparedUrl = `${requestUrl}${selectedItem.id}`;
      try {
        await axiosInstance.delete(preparedUrl);
        refresh();
        notificationAlert(null, 'Объект удален');
      } catch (e) {
        notificationAlert(e, false);
      }
    }
  }, [selectedItem, requestUrl, axiosInstance, refresh, notificationAlert]);

  useImperativeHandle(ref, () => ({
    refresh,
    deleteSelected,
  }));

  let CompendiumRenderer = null;
  const CompendiumPrototype: any = resolveUrl(urlData, 'generalRenderer', 'urlData');

  if (urlData && data?.length) {
    CompendiumRenderer = (
      <PropsPasser
        component={
          <CompendiumPrototype
            data={data}
            model={model}
            proceedData={proceedData}
            createObject={createObject}
            withPagination={true}
            agGridThemeName={'ag-theme-custom-base'}
          />
        }
      />
    );
  }
  const CRUDHandlers = {
    createObject,
  };
  const { ActionBarRenderer } = useActionBarRenderer(lastInHistory, CRUDHandlers, true);

  return (
    <Styled.BaseTemplateMainWrapper>
      <div>{breadcrumbs && <Breadcrumbs theme={theme} routes={breadcrumbs} onClick={handleClickBreadcrumbItem} />}</div>
      <Styled.BaseTemplateActionBarWrapper>{ActionBarRenderer}</Styled.BaseTemplateActionBarWrapper>
      {CompendiumRenderer}
    </Styled.BaseTemplateMainWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  selectingObject: (data: AnyObject | null) => dispatch(selectObject(data)),
  pushInlineHistory: (newHistoryItem: DirectoryHistory) => dispatch(postToInlineHistory(newHistoryItem)),
});

export default connect(null, mapDispatchToProps)(DataWithCompendium);
