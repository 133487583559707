import React from 'react';
import { Item, Separator } from './styled-elements';

export interface BreadcrumbItemProps {
  separator?: React.ReactNode;
  onClick?: () => void;
  theme?: () => void;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ separator = '/', children, theme, onClick, ...restProps }) => {
  let link;

  if ('href' in restProps) {
    link = <a {...restProps}>{children}</a>;
  } else {
    link = <span {...restProps}>{children}</span>;
  }

  return (
    <Item onClick={onClick} theme={theme}>
      {link}
      {separator && separator !== '' && (
        <Separator theme={theme} className="breadcrumb-separator">
          {separator}
        </Separator>
      )}
    </Item>
  );
};

export default BreadcrumbItem;
