export const roadMarkingIconMapping = {
  '1.18 | прямо': { x: 958, y: 818, width: 92, height: 404 },
  '1.18 | прямо-active': { x: 1051, y: 818, width: 92, height: 404 },
  '1.18 | направо и налево': { x: 1466, y: 397, width: 200, height: 396 },
  '1.18 | направо и налево-active': { x: 1466, y: 794, width: 200, height: 396 },
  '1.18 | прямо, направо и налево': { x: 1210, y: 802, width: 195, height: 400 },
  '1.18 | прямо, направо и налево-active': { x: 0, y: 1223, width: 195, height: 400 },
  '1.18 | прямо и налево': { x: 470, y: 413, width: 147, height: 404 },
  '1.18 | прямо и налево-active': { x: 784, y: 0, width: 147, height: 404 },
  '1.18 | прямо и направо': { x: 618, y: 413, width: 147, height: 404 },
  '1.18 | прямо и направо-active': { x: 784, y: 405, width: 147, height: 404 },
  '1.18 | направо': { x: 486, y: 818, width: 117, height: 404 },
  '1.18 | направо-active': { x: 722, y: 818, width: 117, height: 404 },
  '1.18 | правее': { x: 640, y: 1223, width: 143, height: 398 },
  '1.18 | правее-active': { x: 928, y: 1223, width: 143, height: 398 },
  '1.23.1': { x: 1216, y: 1223, width: 224, height: 396 },
  '1.23.1-active': { x: 1466, y: 0, width: 224, height: 396 },
  '1.23.2': { x: 0, y: 413, width: 234, height: 404 },
  '1.23.2-active': { x: 235, y: 413, width: 234, height: 404 },
  '1.23.3': { x: 1210, y: 0, width: 255, height: 400 },
  '1.23.3-active': { x: 1210, y: 401, width: 255, height: 400 },
  '1.18 | налево': { x: 604, y: 818, width: 117, height: 404 },
  '1.18 | налево-active': { x: 840, y: 818, width: 117, height: 404 },
  '1.18 | левее': { x: 784, y: 1223, width: 143, height: 398 },
  '1.18 | левее-active': { x: 1072, y: 1223, width: 143, height: 398 },
  '1.19 | левее': { x: 784, y: 1223, width: 143, height: 398 } /*дубликат верхнего*/,
  '1.19 | левее-active': { x: 1072, y: 1223, width: 143, height: 398 } /* дубликат верхнего*/,
  '1.24.1 | 1.23': { x: 1244, y: 1624, width: 244, height: 214 },
  '1.24.1 | 1.23-active': { x: 1691, y: 0, width: 244, height: 214 },
  '1.24.2 | 60': { x: 1466, y: 1191, width: 214, height: 214 },
  '1.24.2 | 60-active': { x: 1466, y: 1406, width: 214, height: 214 },
  '1.24.3': { x: 0, y: 1624, width: 310, height: 214 },
  '1.24.3-active': { x: 622, y: 1624, width: 310, height: 214 },
  '1.24.4': { x: 311, y: 1624, width: 310, height: 214 },
  '1.24.4-active': { x: 933, y: 1624, width: 310, height: 214 },
  '2.1.1': { x: 0, y: 0, width: 127, height: 412 },
  '2.1.1-active': { x: 128, y: 0, width: 127, height: 412 },
  'Frame 900': { x: 0, y: 818, width: 121, height: 404 } /*налево левый карман*/,
  'Frame 901': { x: 122, y: 818, width: 121, height: 404 } /*налево левый карман актив*/,
  'Frame 946': { x: 256, y: 0, width: 131, height: 406 } /*прямо направа карман*/,
  'Frame 980': { x: 520, y: 0, width: 131, height: 406 } /*прямо направа карман актив*/,
  'Frame 949': { x: 244, y: 818, width: 120, height: 404 } /*направа карман*/,
  'Frame 974': { x: 365, y: 818, width: 120, height: 404 } /*направа карман актив*/,
  'Frame 950': { x: 388, y: 0, width: 131, height: 406 } /*прямо налева карман*/,
  'Frame 981': { x: 652, y: 0, width: 131, height: 406 } /*прямо налева карман актив*/,
  '1.18_Russian_road_marking 10': { x: 196, y: 1223, width: 110, height: 400 } /*прямо левый разворот*/,
  '1.18_Russian_road_marking 11': { x: 932, y: 0, width: 138, height: 404 } /*левый разворот*/,
  '1.18_Russian_road_marking 13': { x: 932, y: 405, width: 138, height: 404 } /*правыцй разворот*/,
  '1.18_Russian_road_marking 15': { x: 307, y: 1223, width: 110, height: 400 } /*прямо правый разворот*/,
  '1.18_Russian_road_marking 17': { x: 418, y: 1223, width: 110, height: 400 } /*прямо левый разворот актив*/,
  '1.18_Russian_road_marking 18': { x: 529, y: 1223, width: 110, height: 400 } /*прямо правый разворот актив*/,
  '1.18_Russian_road_marking 19': { x: 1071, y: 0, width: 138, height: 404 } /*левый разворот актив*/,
  '1.18_Russian_road_marking 20': { x: 1071, y: 405, width: 138, height: 404 } /*правый разворот актив*/,
};
