import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

import { CargoTransportParams } from '../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import { IndividualOrderList } from './IndividualOrdersList';
import { AccountFormation } from './Pages/Account/AccountFormation';
import { FormsAccount } from './Pages/FormsAccount/FormsAccount';
import { IndividualOrder } from './Pages/Order/IndividualOrder';

const IndividualOrdersContainer = React.memo(() => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { id, mode, scoreMode, scoreId } = params;

  if (id || mode) {
    return <IndividualOrder />;
  } else if (scoreMode || scoreId) {
    if (scoreMode === IOPWDirectory.write_off_acts || scoreMode === IOPWDirectory.destruction_acts) {
      return <FormsAccount />;
    } else {
      return <AccountFormation />;
    }
  }
  return <IndividualOrderList />;
});

export default IndividualOrdersContainer;
