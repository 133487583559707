import { useState, useMemo, useCallback } from 'react';
import _ from 'underscore';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { DeleteIcon } from 'UI/custom/icons/actionIcons/DeleteIcon';
import { iconSize } from '../../../interfacesApplications/templates';
import { Styled } from '../../SemifinishedObjectForm/SemifinishedObjectForm.styled';
import { ModalMapContainer } from 'UI/common/form/inputs/ModalMapInput/ModalMapContainer';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { TaskDataObjectsProps } from './model/TaskDataObjects.model';
import { AnyObject } from 'types/enums/general/general.model';

const {
  SemifinishedObjectsContainer,
  SemifObjectContainer,
  SemifObjectContainerWithMapButton,
  ObjectTitle,
  DescriptionContainer,
} = Styled;

export const TaskDataObjects = ({
  setCustomFieldsData,
  taskObjectList,
  setData,
  model,
  extraData,
}: TaskDataObjectsProps) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [activeObject, setActiveObject] = useState<AnyObject>({});
  const [activeObjectIndex, setActiveObjectIndex] = useState<number>(0);
  const [activeField, setActiveField] = useState<SchemaFieldType>(SchemaFieldType.point);

  const clickOpenModalButton = useCallback(
    (object: AnyObject, index: number) => {
      const { geometry, ...allField } = object;

      const fieldType =
        extraData?.find((data) => data.value === object.object_type)?.geometry_type ?? SchemaFieldType.point;
      const field = fieldType === SchemaFieldType.lineString ? SchemaFieldType.linePath : fieldType;

      setActiveObject({ ...allField, [field]: geometry });
      setActiveField(field);
      setActiveObjectIndex(index);
      setOpenModal(true);
    },
    [extraData]
  );

  const savePositionActiveObject = useCallback(
    (newPosition) => {
      const position: AnyObject = newPosition();
      setData((prevData) => {
        if (!Array.isArray(prevData)) {
          const newTaskObjects = [...prevData.task_objects];
          newTaskObjects[activeObjectIndex] = {
            ...newTaskObjects[activeObjectIndex],
            geometry: position[activeField],
          };
          return { ...prevData, task_objects: newTaskObjects };
        } else return prevData;
      });
      if (setCustomFieldsData)
        setCustomFieldsData((prevValues: AnyObject) => {
          const newTaskObjects = [...prevValues.task_objects];
          newTaskObjects[activeObjectIndex] = {
            ...newTaskObjects[activeObjectIndex],
            geometry: position[activeField],
          };
          return { ...prevValues, task_objects: newTaskObjects };
        });
    },
    [activeField, activeObjectIndex, setCustomFieldsData, setData]
  );

  const DeleteTaskObject = useCallback(
    (object: AnyObject) => {
      setData((prevData) => {
        if (!Array.isArray(prevData)) {
          const task_objects = _.without(prevData.task_objects, object);
          return { ...prevData, task_objects };
        } else return prevData;
      });
      if (setCustomFieldsData)
        setCustomFieldsData((prevValues: AnyObject) => {
          const task_objects = _.without(prevValues.task_objects, object);
          return { ...prevValues, task_objects };
        });
    },
    [setCustomFieldsData, setData]
  );

  const TaskDataObjectsList = useMemo(() => {
    return taskObjectList.map((object: AnyObject, index: number) => {
      const { geometry, object_type, address } = object;
      const typeObject = model.scheme.object_type?.choices?.find(
        (choice) => choice.value === object_type
      )?.display_name;
      return (
        <SemifObjectContainerWithMapButton key={`SemifObjectContainerWithMapButton_${index}`}>
          <SemifObjectContainer>
            <ObjectTitle>Описание объекта: {typeObject}</ObjectTitle>
            <DescriptionContainer>{address}</DescriptionContainer>
            <Button
              p={'none'}
              type={ButtonType.unbounded}
              icon={<DeleteIcon />}
              iconSize={iconSize}
              onClick={() => DeleteTaskObject(object)}
            />
          </SemifObjectContainer>
          <Button
            className={!geometry ? 'emptyGeometry' : ''}
            onClick={() => clickOpenModalButton(object, index)}
            type={ButtonType.primaryReverse}
            width={'100%'}
          >
            {!geometry ? 'Указать на карте' : 'Посмотреть на карте'}
          </Button>
        </SemifObjectContainerWithMapButton>
      );
    });
  }, [DeleteTaskObject, clickOpenModalButton, model.scheme.object_type?.choices, taskObjectList]);

  return (
    <SemifinishedObjectsContainer>
      {TaskDataObjectsList}
      {isOpenModal && (
        <ModalMapContainer
          isOpenModal={isOpenModal}
          setOpenModal={setOpenModal}
          field={activeField}
          object={activeObject}
          setCustomFieldsData={savePositionActiveObject}
        />
      )}
    </SemifinishedObjectsContainer>
  );
};
