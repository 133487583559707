import styled from 'styled-components';
import { DefaultStyleMixins, Layout } from '@frontend-packages/its-ui-kit';
const { getSpacing } = DefaultStyleMixins.offset;

export const SLayoutWrapper = styled(Layout)`
  margin: 70px 0 8px 0;
  height: calc(100vh - 70px);
`;

export const SLayoutContentWrapper = styled(Layout.Content)`
  // поднятие специфичности (перебитие дефолтных маржинов)
  &.imageContentContainer {
    height: 100%;
    margin: 0;
    padding: ${getSpacing(1)};
  }
`;

export const SLayoutSiderWrapper = styled(Layout.Sider)`
  .ant-layout-sider-children {
    padding: 0;
  }
`;
