import { AnyObject } from 'types/enums/general/general.model';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { ElectricalSubstationsSelectionProps } from '../RoadSchemeItemSelection/RoadSchemeItemSelection.model';

const ElectricalSubstationsSelection = ({
  handleCloseMapSelectedObjectInfo,
  selectedMapItem,
  data,
}: ElectricalSubstationsSelectionProps) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord: AnyObject) => dataRecord.name === 'substations');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.knsSubstations}/${id}/`, '_blank');
    win?.focus();
  };

  let Descriptions = [];

  const properties = data;

  Descriptions.push({
    key: 'Наименование',
    value: properties.name ? properties.name : '-',
  });

  Descriptions.push({
    key: 'Ток К.З',
    value: properties.current_sc ? properties.current_sc + ' А' : '- А',
  });

  Descriptions.push({
    key: 'Расчетная нагрузка',
    value: properties.rated_load ? properties.rated_load + ' А' : '- А',
  });

  Descriptions.push({
    key: 'Расчетная мощность',
    value: properties.rated_power ? properties.rated_power + ' Вт' : '- Вт',
  });

  Descriptions.push({
    key: 'Расчетное напряжение',
    value: properties.rated_voltage ? properties.rated_voltage + ' В' : '- В',
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о подстанции'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default ElectricalSubstationsSelection;
