import styled from 'styled-components/macro';
import { PortalSidePositionModel } from './model/PortalSidePosition.model';
import { LocalContentWrapPropsModel } from './model/LocalContentWrapProps.model';

const handleResolvePortalRenderSide = (portalSide: PortalSidePositionModel): string => {
  switch (portalSide) {
    case 'top-right':
      return `top: 0;
                right: 0;
                `;
    case 'top-center':
      return `top: 80px;
                right: 50%;
                transform:translateX(50%)`;
    case 'top-center-with-padding-top':
      return `top: 100px;
                right: 50%;
                transform:translateX(50%)`;
    case 'top-left': {
      return `bottom: 0;
                right: 0;
                `;
    }
    case 'bottom-right': {
      return `bottom: 0;
                right: 0;
                `;
    }
    case 'bottom-center': {
      return `bottom: 0;
                left: 50%;
                transform: translateX(-50%)
                `;
    }
    case 'bottom-left': {
      return `bottom: 0;
                left: 0;
                `;
    }
    case 'middle-left': {
      return `bottom: 50%;
                left: 0;
                transform: translateY(-25%)`;
    }
    case 'middle-center': {
      return `bottom: 50%;
                left: 50%;
                transform: translate(-50%, 25%)`;
    }
    case 'middle-right': {
      return `bottom: 50%;
                right: 0;
                transform: translateY(25%)`;
    }
    default: {
      return '';
    }
  }
};

const LocalContentWrap = styled.section<LocalContentWrapPropsModel>`
  position: fixed;
  z-index: 9999;
  ${(props) => handleResolvePortalRenderSide(props.portalSide)}
`;

export const Styled = { LocalContentWrap };
