import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

// TODO duplicate fragment Тех-долг-FRONTEND #5793

const RoadQualitySegmentSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'road');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/api/${selectedMapItem.data[0].urlData.replace('_repr', '')}/${id}/`,
      '_blank'
    );
    win.focus();
  };
  let Descriptions = [];

  const properties = data;
  properties.road_name &&
    Descriptions.push({
      key: 'Дорога',
      value: properties.road_name,
    });

  properties.segment_length &&
    Descriptions.push({
      key: 'Протяженность участка, км',
      value: `${properties.segment_length?.toFixed(2)} км`,
    });

  properties.average_value &&
    Descriptions.push({
      key: 'Среднее значение',
      value: properties.average_value,
    });

  properties.less_allowable_value &&
    Descriptions.push({
      key: 'Предельно допустимое значение',
      value: properties.less_allowable_value,
    });

  properties.value &&
    Descriptions.push({
      key: 'Значение',
      value: properties.value,
    });

  properties.year_of_certification &&
    Descriptions.push({
      key: 'Год паспортизации',
      value: properties.year_of_certification,
    });

  properties.year &&
    Descriptions.push({
      key: 'Год диагностики',
      value: properties.year,
    });

  return (
    <PrepareScrollableInfoCard
      title={'Диагностика УДС'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default RoadQualitySegmentSelection;
