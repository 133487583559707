export class LayerSettingsPrototype {
  label;
  keyForDeck;
  currentValue;

  constructor({ label, keyForDeck, currentValue }) {
    this.label = label;
    this.keyForDeck = keyForDeck;
    this.currentValue = currentValue;
  }

  getLabel() {
    return this.label;
  }

  getDeckKey() {
    return this.keyForDeck;
  }

  getSettingValues() {
    return this;
  }

  getCurrentValue() {
    return this.currentValue;
  }
}
