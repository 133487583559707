import styled from 'styled-components/macro';
import { Styled as styleMixins } from '../../../../../styles/styleMixins/styleMixins.styled';
const { CutStringWithEllipsisOnTheEnd } = styleMixins.text;

const LocalMainWrapper = styled.figure`
  background: ${(props) => props.theme.colors.defaultLight};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  display: flex;
  align-items: center;
  min-height: 80px;
  overflow: hidden;
  position: relative;
  & + & {
    margin-top: 0.6rem;
  }
`;

const LocalDescriptionAreaWrap = styled.div`
  width: 70%;
  word-break: break-word;
  ${CutStringWithEllipsisOnTheEnd}
  & > * {
    word-break: break-word;
    ${CutStringWithEllipsisOnTheEnd}
  }
`;

const LocalContentWrap = styled.main`
  padding: ${(props) => props.theme.paddings.smallPadding};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin: 0 -6px;
  & > * {
    margin: 0 6px;
  }
`;

const LocalPresentationAreaWrap = styled.section`
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  position: relative;
  object-fit: contain;
  overflow: hidden;
  width: 40%;
  height: 100%;
  & > * {
    height: 100%;
    width: 100%;
    max-height: 65px;
    max-width: 65px;
  }
`;

const LocalActionAreaWrap = styled.section`
  width: 10%;
`;

export const Styled = {
  main: { LocalMainWrapper, LocalContentWrap },
  presentationArea: { LocalPresentationAreaWrap },
  descriptionArea: { LocalDescriptionAreaWrap },
  actionArea: { LocalActionAreaWrap },
};
