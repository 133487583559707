import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { StateModel } from 'store/reducers';

import { useTimeoutFuncCall } from 'hooks/common/useTimeoutFuncCall';
import { dispatchNativeResizeEvent } from 'services/window/dispatchNativeResizeEvent';
import { Styled } from './style/WidgetsGrid.styled';
import ConnectWidget from './components/CameraWidget/CameraWidget';
import { loadAllWidgetsSaga } from 'store/sagas/widgets/WidgetsSaga';
import WidgetEditPanelContainer from '../WidgetsEditPanel/WidgetEditPanelContainer';
import { isEmpty } from 'lodash';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const { WidgetsGridWrap, WidgetEditPanelWrap } = Styled;

const WidgetsGrid: FC<any> = () => {
  const dispatch = useDispatch();
  const widgetsEditPanel = useSelector((state: StateModel) => state.widgets.widgetsEditPanelVisible);
  const sidebarIsOpened = useSelector((state: StateModel) => state.app.sidebarIsOpened);
  const useWidgetsRights = useSelector(() => (state: StateModel) => state.auth.permissions.widgets);

  const widgets = useSelector((state: StateModel) => state.widgets.widgets);

  useTimeoutFuncCall(dispatchNativeResizeEvent, [sidebarIsOpened], 200);

  useEffect(() => {
    if (!isEmpty(widgets) && !isEmpty(useWidgetsRights)) {
      dispatch(loadAllWidgetsSaga());
    }
  }, [dispatch, useWidgetsRights, widgets]);

  const widgetsContent = useMemo(
    () =>
      widgets.map((el, key) => {
        const x = !(key % 2) ? 0 : 6;
        return (
          // Необходимо оборачивать виджеты в div, или перестает работать grid
          <div key={el.id} data-grid={{ x, y: 0, w: 6, h: 7, minW: 6, maxW: 6, minH: 7, maxH: 7 }}>
            <ConnectWidget widgetDescription={el} />
          </div>
        );
      }),
    [widgets]
  );

  return (
    <WidgetsGridWrap>
      <ResponsiveReactGridLayout
        rowHeight={50}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        margin={[16, 16]}
      >
        {widgetsContent}
      </ResponsiveReactGridLayout>
      {widgetsEditPanel && (
        <WidgetEditPanelWrap>
          <WidgetEditPanelContainer />
        </WidgetEditPanelWrap>
      )}
    </WidgetsGridWrap>
  );
};

export default WidgetsGrid;
