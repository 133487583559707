import styled from 'styled-components/macro';

const ExtendedStyledInput = styled.div`
  width: 100%;
  & .ant-input-suffix {
    right: 90%;
  }

  & .ant-input-suffix i {
    color: ${(props) => props.theme.colors.darkBlue};
  }

  & .ant-input {
    font-family: ${(props) => props.theme.fontSettings.fontFamilyGilroy};
    font-weight: 600;
    font-size: 13px;
    padding-top: 8px !important;
    background: ${(props) => props.theme.colors.defaultLight};
    padding-left: 35px;
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
    color: ${(props) => props.theme.colors.gray};
    height: 40px;
    border: none;
    ::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.gray};
    }
  }
`;

export const Styled = { ExtendedStyledInput };
