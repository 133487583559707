import React, { Dispatch, useCallback, useMemo, useRef, useState } from 'react';
import StandaloneEditModeFormPanel from './StandaloneEditModeFormPanel';
import { connect } from 'react-redux';
import {
  clearNotificationData,
  clearStandaloneEditModeObject,
  clearMapContextMenu,
} from 'store/reducers/map/actions/mapboxEditableStandaloneObjectActions';
import { cropPath } from 'services/net/cropPath';
import {
  submitObjectSagaErrorResponse,
  SubmitObjectSagaErrorResponseProps,
} from 'store/sagas/app/postObjectSagaErrorResponse';
import turfLength from '@turf/length';
import turfArea from '@turf/area';
// @ts-ignore
import wkt from 'wkt';
import { resolveEditableObjectDescription } from 'registrators/map/editableObjectsRegistrator/editableObjectsRegistrator';
import { DrawLineStringMode } from '@nebula.gl/edit-modes';
import { defineDrawKeyOnModel, defineDrawModeOnModel } from 'services/map/defineDrawMapOnModel';
import turfLineSlice from '@turf/line-slice';
import turfNearestPoint from '@turf/nearest-point-on-line';
import _ from 'underscore';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { mapboxDataActions } from 'store/reducers/map/mapboxData/mapboxData.actions';
import { mapMarkersActions } from 'store/reducers/map/mapMarkers/mapMarkers.actions';
import usePrepareFieldsHook from 'hooks/form/usePrepareFieldsHook/usePrepareFieldsHook';
import { getPreparedData } from 'services/net/getPreparedData';
import { StateModel } from 'store/reducers';
import { AnyObject } from 'types/enums/general/general.model';
import { Feature, Properties } from '@turf/helpers';
import { Layers } from 'types/enums/map/layers/Layers';
import { PromptState } from 'store/reducers/global/notifications/notifications.model';

interface StandaloneEditModeFormPanelContainerProps {
  handlerClearMapContextMenu: () => void;
  editableStandaloneObject: AnyObject;
  handlerClearStandaloneEditModeObject: () => void;
  startPostWithSaga: (props: SubmitObjectSagaErrorResponseProps) => void;
  clearPromptData: () => void;
  updateObject: (relatedDataKey: string, layerName: Layers, dataToPost: AnyObject) => void;
}

function localResolveAdditionalGeometryData(
  geometryKey: string,
  geometryValue: Feature<any, Properties>,
  editableStandaloneObject: AnyObject
) {
  const result = [];
  const { model } = editableStandaloneObject;
  const { scheme } = model;
  for (let key in scheme) {
    if (scheme.hasOwnProperty(key)) {
      const { type } = scheme[key];
      // eslint-disable-next-line default-case
      switch (type) {
        case 'start value for objects with geometry': {
          const { coordinates } = geometryValue.geometry;
          const firstPoint = _.first(coordinates);
          if (editableStandaloneObject?.parentData) {
            const { parentData } = editableStandaloneObject;
            const { line_path } = parentData;
            const line = wkt.parse(line_path);
            const nearest = turfNearestPoint(line, firstPoint);
            const { location } = nearest.properties;
            result.push({
              key,
              value: location,
            });
          }
          break;
        }
        case 'end value for objects with geometry': {
          const { coordinates } = geometryValue.geometry;
          const lastPoint = _.last(coordinates);
          if (editableStandaloneObject?.parentData) {
            const { parentData } = editableStandaloneObject;
            const { line_path } = parentData;
            const line = wkt.parse(line_path);
            const nearest = turfNearestPoint(line, lastPoint);
            const { location } = nearest.properties;
            result.push({
              key,
              value: location,
            });
          }
          break;
        }
      }
    }
  }

  if (geometryKey === 'line_path' && geometryValue) {
    result.push({
      key: 'total_length',
      value: turfLength(geometryValue),
    });
  } else if (geometryKey === 'polygon' && geometryValue) {
    //area in square meters
    result.push({ key: 'area_size', value: turfArea(geometryValue) });
  }
  return result;
}

const StandaloneEditModeFormPanelContainer = ({
  handlerClearMapContextMenu,
  editableStandaloneObject,
  handlerClearStandaloneEditModeObject,
  startPostWithSaga,
  clearPromptData,
  updateObject,
}: StandaloneEditModeFormPanelContainerProps) => {
  const [dateTime, setDateTime] = useState({});
  const [customFieldsData, setCustomFieldsData] = useState({});
  const formRef = useRef<HTMLFormElement>(null);

  const handleRejectObjectEditing = useCallback(() => {
    handlerClearMapContextMenu();
    clearPromptData();
    handlerClearStandaloneEditModeObject();
  }, [clearPromptData, handlerClearMapContextMenu, handlerClearStandaloneEditModeObject]);

  const initSubmitForm = useCallback(() => {
    formRef.current?.submit();
    handlerClearMapContextMenu();
  }, [handlerClearMapContextMenu]);

  const handleSubmitForm = useCallback(
    (currentData) => {
      try {
        let writableGeometry = editableStandaloneObject.drawData.features[0];
        const EditableObject = resolveEditableObjectDescription(editableStandaloneObject.selectedInstance);
        //@ts-ignore ТС не прав на то и проверяем вопросами
        const isSnap = EditableObject?.parent?.snapChildGeometry;
        if (isSnap) {
          const mode = editableStandaloneObject.editMode;
          const parentGeometryKey = defineDrawKeyOnModel(editableStandaloneObject.parentModel);
          if (mode === DrawLineStringMode) {
            const parentMode = defineDrawModeOnModel(editableStandaloneObject.parentModel);
            // if parent mode is linestring
            if (typeof parentMode === typeof DrawLineStringMode) {
              const coords = writableGeometry.geometry.coordinates;
              const snapGeometry = wkt.parse(editableStandaloneObject.parentData[parentGeometryKey ?? '']);
              const firstPoint = _.first(coords);
              const lastPoint = _.last(coords);
              writableGeometry = turfLineSlice(firstPoint, lastPoint, snapGeometry);
            }
          }
        }
        const { geometryKey } = editableStandaloneObject;
        let geometryValue = writableGeometry;
        //@ts-ignore ТС не прав на то и проверяем вопросами
        if (EditableObject?.withoutGeometry && EditableObject?.hardcodeInstance?.['geometry']) {
          geometryValue = editableStandaloneObject.instance['geometry'];
        }
        const additionalGeometryData = localResolveAdditionalGeometryData(
          geometryKey,
          editableStandaloneObject.drawData.features[0],
          editableStandaloneObject
        );
        // Todo временное решение(надо было прикрутить слайдер, он не работает с react-hook-form v5,
        //  по этому такой костыль, убрать когда обновиться до v7) Тех-долг-FRONTEND #5579
        let dataToPost;
        if (editableStandaloneObject.name === 'Дорожный знак') {
          dataToPost = geometryValue
            ? {
                ...currentData,
                ...customFieldsData,
                [geometryKey]: wkt.stringify(geometryValue),
                id: editableStandaloneObject?.instance?.id,
              }
            : {
                ...currentData,
                ...customFieldsData,
                id: editableStandaloneObject?.instance?.id,
              };
        } else {
          dataToPost = geometryValue
            ? {
                ...currentData,
                ...customFieldsData,
                [geometryKey]:
                  typeof geometryValue === 'string'
                    ? geometryValue.split(';').find((el) => !el.includes('SRID'))
                    : wkt.stringify(geometryValue),
                id: editableStandaloneObject?.instance?.id,
              }
            : {
                ...currentData,
                ...customFieldsData,
                id: editableStandaloneObject?.instance?.id,
              };
        }

        if (additionalGeometryData && additionalGeometryData.length) {
          for (let record of additionalGeometryData) {
            const { key, value } = record;
            dataToPost[key] = value;
          }
        }

        const { model } = editableStandaloneObject;
        const newDataToPost = getPreparedData(dataToPost, model);
        startPostWithSaga({
          url: cropPath(editableStandaloneObject.urlData),
          data: newDataToPost,
          setReturnResponse: (response) => {
            updateObject(editableStandaloneObject.selectedInstance, editableStandaloneObject.layerName, response);
          },
        });
      } catch (e) {
        console.error('form submit error: ', e);
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Произошла ошибка',
          message: 'Произошла ошибка при отправке формы, проверьте правильность заполненных данных',
        });
      } finally {
        const writableGeometryData = editableStandaloneObject.drawData.features.length;
        if (writableGeometryData !== 0) {
          handleRejectObjectEditing();
        }
      }
    },
    [customFieldsData, editableStandaloneObject, handleRejectObjectEditing, startPostWithSaga, updateObject]
  );

  const form = useMemo(
    () => ({
      showTime: true,
      dateTime,
      setDateTime,
      formRef,
      setCustomFieldsData,
    }),
    [dateTime, formRef]
  );

  const { prepareData, prepareModel } = usePrepareFieldsHook(
    editableStandaloneObject.model,
    editableStandaloneObject.instance
  );

  const data = useMemo(() => prepareData ?? {}, [prepareData]);

  return (
    <>
      {prepareModel && (
        <StandaloneEditModeFormPanel
          ref={formRef}
          initSubmitForm={initSubmitForm}
          handleSubmitForm={handleSubmitForm}
          handleRejectObjectEditing={handleRejectObjectEditing}
          form={form}
          model={prepareModel}
          data={data}
          formTitle={editableStandaloneObject.name}
        />
      )}
    </>
  );
};

function mapStateToProps(state: StateModel) {
  return {
    editableStandaloneObject: state.mapboxEditableStandaloneObject,
  };
}

function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    handlerClearStandaloneEditModeObject: () => {
      dispatch(clearStandaloneEditModeObject());
      dispatch(mapMarkersActions.clearAllMarkers());
    },
    startPostWithSaga: ({ url, data, setReturnResponse }: SubmitObjectSagaErrorResponseProps) =>
      dispatch(submitObjectSagaErrorResponse({ url, data, setReturnResponse })),
    updateObject: (relatedDataKey: string, layerName: Layers, dataToPost: AnyObject) =>
      dispatch(mapboxDataActions.replaceRelatedDataByKey(relatedDataKey, layerName, dataToPost)),
    setGlobalAlertData: ({ status, message, title, type }: PromptState) =>
      dispatch(notificationActions.setGlobalAlertData({ status, message, title, type })),
    handlerClearMapContextMenu: () => {
      dispatch(clearMapContextMenu());
    },
    handlerClearNotificationData: () => {
      dispatch(clearNotificationData());
    },
    clearPromptData: () => dispatch(notificationActions.clearPromptData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneEditModeFormPanelContainer);
