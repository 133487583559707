import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateModel } from 'store/reducers';
import { rolesDirectoryActions } from 'store/reducers/rolesDirectory/rolesDirectory.actions';
import {
  DirectoryElement,
  RoleElement,
  RolesDirectoryArrayDataFields,
  RolesDirectoryFields,
  RoleState,
} from 'store/reducers/rolesDirectory/rolesDirectory.model';
import _ from 'underscore';

import { DirectoriesEnum } from '../enums/DirectoriesEnum';
import { TabMenuEnum } from '../enums/TabMenuEnum';
import { ArrowHandler } from '../parts/ArrowHandler';
import { Styled } from '../parts/PermissionsPanel.styled';
import { RenderCollectionContainer } from '../parts/RenderCollectionContainer';
import {
  dataForCheckedItemDirectory,
  dataForCheckedItemMap,
  getIDForReports,
  getRightForSelectedItem,
  handleCheckedItem,
} from '../utils/functions';

const {
  LocalText,
  LocalSelectWrapper,
  LocalInnerWrapper,
  LocalMainWrapper,
  RenderListContainer,
  RenderListCheckboxWrapper,
  RenderListItemWrapper,
} = Styled;

interface AccessToDirectoriesProps {
  keysDirec: DirectoryElement[];
  pageMenuKey: DirectoriesEnum;
  selectDirec: RoleElement[] | DirectoryElement[];
}

export const AccessToDirectories = ({ keysDirec, pageMenuKey, selectDirec }: AccessToDirectoriesProps) => {
  const rolesDirectory: RoleState = useSelector((state: StateModel) => state.rolesDirectory);
  const dispatch = useDispatch();
  const [input, setInput] = useState<string>('');
  const [inputRight, setInputRight] = useState<string>('');
  const [allDirectories, setInAllDirectories] = useState<DirectoryElement[] | RoleElement[]>([]);
  const [filterDirectories, setInFilterDirectories] = useState<DirectoryElement[] | RoleElement[]>([]);
  const [filterDirectoriesRight, setInFilterDirectoriesRight] = useState<DirectoryElement[] | RoleElement[]>([]);
  const [checkedDirectories, setInCheckedDirectories] = useState<DirectoryElement[] | RoleElement[]>([]);
  const [isCheckedLeftAllCheckbox, setIsCheckedLeftAllCheckbox] = useState<boolean>(false);
  const [isCheckedRightAllCheckbox, setIsCheckedRightAllCheckbox] = useState<boolean>(false);

  const {
    checkedDirectory,
    checkingReports,
    permissionsMap,
    checkedMap,
    directoryData,
    checkingDirectory,
    checkingMap,
    checkedReports,
    rolesList,
    checkedRoles,
    organizationsList,
    checkedOrganizationsList,
    districtsList,
    checkedDistrictsList,
    directoryDataFiltered,
    clickedRight,
  } = rolesDirectory;

  useEffect(() => {
    setInAllDirectories(keysDirec);
    setInFilterDirectories(keysDirec);
    setInCheckedDirectories(selectDirec);
    setInFilterDirectoriesRight(selectDirec);
    setIsCheckedLeftAllCheckbox(false);
    setIsCheckedRightAllCheckbox(false);
  }, [keysDirec, selectDirec, pageMenuKey]);

  const getClickingElements = useCallback(
    (elements) =>
      elements?.filter((el: RoleElement) => clickedRight[pageMenuKey].every((clickedEl) => el.id !== clickedEl.id)),
    [clickedRight, pageMenuKey]
  );

  const getDataForCheckedItems = useCallback(() => {
    let result;
    if (
      pageMenuKey === DirectoriesEnum.get_directory &&
      checkedDirectory?.length &&
      !_.isArray(directoryData) &&
      directoryData.get_directory
    ) {
      result = dataForCheckedItemDirectory(checkedDirectories, directoryData.get_directory);
    } else if (pageMenuKey === DirectoriesEnum.get_layers && !_.isArray(directoryData) && directoryData.get_layers) {
      result = dataForCheckedItemMap(directoryData.get_layers);
    }

    const flatten = _.flatten(result ?? []);
    if (pageMenuKey === DirectoriesEnum.get_directory && checkedDirectory?.length) {
      dispatch(
        rolesDirectoryActions.replaceFieldByKeyActions(
          {
            ...directoryDataFiltered,
            get_directory: getClickingElements(flatten),
          },
          RolesDirectoryFields.directoryDataFiltered
        )
      );
    }
    dispatch(rolesDirectoryActions.replaceFieldByKeyActions(flatten, RolesDirectoryFields.permissionsMap));
  }, [
    checkedDirectories,
    checkedDirectory,
    directoryData,
    directoryDataFiltered,
    dispatch,
    getClickingElements,
    pageMenuKey,
  ]);

  const createNewList = useCallback(
    (list) =>
      list
        .filter((el: RoleElement) => el.isChecked)
        .map((el: RoleElement) => ({
          value: el.value,
          isChecked: false,
          id: el?.id,
        })),
    []
  );

  const handleCheckedToSelected = useCallback(() => {
    let changePartData;
    let changePartDataField;
    let replaceData;
    let replaceFieldData;
    switch (pageMenuKey) {
      case DirectoriesEnum.get_directory:
        changePartData = createNewList(checkingDirectory);
        changePartDataField = RolesDirectoryArrayDataFields.checkedDirectory;
        replaceData = checkingDirectory.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkingDirectory;
        break;
      case DirectoriesEnum.get_layers:
        changePartData = createNewList(checkingMap);
        changePartDataField = RolesDirectoryArrayDataFields.checkedMap;
        replaceData = checkingMap.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkingMap;
        break;
      case DirectoriesEnum.get_reports:
        changePartData = createNewList(checkingReports);
        changePartDataField = RolesDirectoryArrayDataFields.checkedReports;
        replaceData = checkingReports.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkingReports;
        break;
      case DirectoriesEnum.permissions_groups:
        changePartData = createNewList(rolesList);
        changePartDataField = RolesDirectoryArrayDataFields.checkedRoles;
        replaceData = rolesList.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.rolesList;
        break;
      case DirectoriesEnum.filters_user:
        changePartData = createNewList(organizationsList);
        changePartDataField = RolesDirectoryArrayDataFields.checkedOrganizationsList;
        replaceData = organizationsList.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.organizationsList;
        break;
      case DirectoriesEnum.filters_user_districts:
        changePartData = createNewList(districtsList);
        changePartDataField = RolesDirectoryArrayDataFields.checkedDistrictsList;
        replaceData = districtsList.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.districtsList;
        break;
      default:
        break;
    }
    if (changePartData && changePartDataField && replaceData && replaceFieldData) {
      dispatch(rolesDirectoryActions.changePartArrayDataByKeyActions(changePartData, changePartDataField));
      dispatch(rolesDirectoryActions.replaceFieldByKeyActions(replaceData, replaceFieldData));
    }
    getDataForCheckedItems();
  }, [
    checkingDirectory,
    checkingMap,
    checkingReports,
    createNewList,
    dispatch,
    districtsList,
    getDataForCheckedItems,
    organizationsList,
    pageMenuKey,
    rolesList,
  ]);

  const handleCheckedToSelecting = useCallback(() => {
    let changePartData;
    let changePartDataField;
    let replaceData;
    let replaceFieldData;
    switch (pageMenuKey) {
      case DirectoriesEnum.get_directory:
        changePartData = createNewList(checkedDirectories);
        changePartDataField = RolesDirectoryArrayDataFields.checkingDirectory;
        replaceData = checkedDirectories.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkedDirectory;
        break;
      case DirectoriesEnum.get_layers:
        changePartData = createNewList(checkedMap);
        changePartDataField = RolesDirectoryArrayDataFields.checkingMap;
        replaceData = checkedMap.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkedMap;
        break;
      case DirectoriesEnum.get_reports:
        changePartData = createNewList(checkedReports);
        changePartDataField = RolesDirectoryArrayDataFields.checkingReports;
        replaceData = checkedReports.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkedReports;
        break;
      case DirectoriesEnum.permissions_groups:
        changePartData = createNewList(checkedRoles);
        changePartDataField = RolesDirectoryArrayDataFields.rolesList;
        replaceData = checkedRoles.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkedRoles;
        break;
      case DirectoriesEnum.filters_user:
        changePartData = createNewList(checkedOrganizationsList);
        changePartDataField = RolesDirectoryArrayDataFields.organizationsList;
        replaceData = checkedOrganizationsList.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkedOrganizationsList;
        break;
      case DirectoriesEnum.filters_user_districts:
        changePartData = createNewList(checkedDistrictsList);
        changePartDataField = RolesDirectoryArrayDataFields.districtsList;
        replaceData = checkedDistrictsList.filter((el) => !el.isChecked);
        replaceFieldData = RolesDirectoryFields.checkedDistrictsList;
        break;
      default:
        break;
    }
    if (changePartData && changePartDataField && replaceData && replaceFieldData) {
      dispatch(rolesDirectoryActions.changePartArrayDataByKeyActions(changePartData, changePartDataField));
      dispatch(rolesDirectoryActions.replaceFieldByKeyActions(replaceData, replaceFieldData));
    }
  }, [
    checkedDirectories,
    checkedDistrictsList,
    checkedMap,
    checkedOrganizationsList,
    checkedReports,
    checkedRoles,
    createNewList,
    dispatch,
    pageMenuKey,
  ]);

  const handleCheckElement = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === 'allDirectories') {
        handleCheckedItem(allDirectories, event, setInAllDirectories);
      } else if (event.target.value === 'allDirectoriesRight') {
        handleCheckedItem(checkedDirectories, event, setInCheckedDirectories);
        getDataForCheckedItems();
      } else {
        handleCheckedItem(allDirectories, event, setInAllDirectories);
        if (checkedDirectories?.length) {
          handleCheckedItem(checkedDirectories, event, setInCheckedDirectories);
          getDataForCheckedItems();
        }
      }

      if (pageMenuKey === DirectoriesEnum.get_layers) {
        const data = getRightForSelectedItem(permissionsMap, checkedMap, checkedDirectories);
        dispatch(
          rolesDirectoryActions.replaceFieldByKeyActions(
            {
              ...directoryDataFiltered,
              get_layers: getClickingElements(data),
            },
            RolesDirectoryFields.directoryDataFiltered
          )
        );
      }

      if (pageMenuKey === DirectoriesEnum.get_reports) {
        const getID = getIDForReports(checkedReports)
          .flat()
          .filter((el) => el);
        // @ts-ignore TS не прав. все возможные undefined отмел фильтрацией массива.
        dispatch(rolesDirectoryActions.replaceFieldByKeyActions(getID, RolesDirectoryFields.idForRightReports));
      }
    },
    [
      allDirectories,
      checkedDirectories,
      checkedMap,
      checkedReports,
      directoryDataFiltered,
      dispatch,
      getClickingElements,
      getDataForCheckedItems,
      pageMenuKey,
      permissionsMap,
    ]
  );

  const renderList = useCallback(
    (value?: RoleElement[]) =>
      value?.map((item: RoleElement) => {
        return (
          <LocalSelectWrapper key={_.uniqueId()}>
            <RenderListContainer>
              <RenderListCheckboxWrapper>
                <input
                  type="checkbox"
                  value={item.value}
                  checked={item.isChecked}
                  id={item.id?.toString()}
                  onChange={handleCheckElement}
                />
              </RenderListCheckboxWrapper>
              <RenderListItemWrapper>
                <label htmlFor={item.value}>{item.value}</label>
              </RenderListItemWrapper>
            </RenderListContainer>
          </LocalSelectWrapper>
        );
      }),
    [handleCheckElement]
  );

  const title =
    TabMenuEnum[pageMenuKey] === TabMenuEnum.filters_user
      ? TabMenuEnum.filters_user_organizations
      : TabMenuEnum[pageMenuKey];

  let subtitle;
  switch (title) {
    case TabMenuEnum.get_directory:
      subtitle = 'cправочники';
      break;
    case TabMenuEnum.get_layers:
      subtitle = 'карты';
      break;
    case TabMenuEnum.get_reports:
      subtitle = 'отчёты';
      break;
    case TabMenuEnum.permissions_groups:
      subtitle = 'роли';
      break;
    case TabMenuEnum.filters_user_organizations:
      subtitle = 'организации';
      break;
    case TabMenuEnum.filters_user_districts:
      subtitle = 'районы';
      break;
    default:
      subtitle = 'элементы';
      break;
  }

  const updateInputSelected = (currentInput: ChangeEvent<HTMLInputElement>) => {
    const value = currentInput.target.value;
    const filtered = allDirectories?.filter((data) => {
      return data?.value?.toLowerCase().includes(value.toLowerCase());
    });
    setInput(value);
    setInFilterDirectories(filtered);
  };

  const updateInputSelecting = (currentInput: ChangeEvent<HTMLInputElement>) => {
    const value = currentInput.target.value;
    const filtered = checkedDirectories?.filter((data) => {
      return data?.value?.toLowerCase().includes(value.toLowerCase());
    });
    setInputRight(value);
    setInFilterDirectoriesRight(filtered);
  };

  return (
    <>
      <LocalMainWrapper>
        <LocalText>{title}</LocalText>
        <LocalInnerWrapper>
          <RenderCollectionContainer
            updateInput={updateInputSelected}
            title={`Выберите ${subtitle}`}
            width={315}
            height={650}
            renderList={renderList}
            items={filterDirectories}
            input={input}
            handleAllChecked={handleCheckElement}
            nameAllCheckbox={'allDirectories'}
            isChecked={isCheckedLeftAllCheckbox}
            setIsChecked={setIsCheckedLeftAllCheckbox}
          />
          <ArrowHandler handleCheckedRight={handleCheckedToSelected} handleCheckedLeft={handleCheckedToSelecting} />
          <RenderCollectionContainer
            title={`Выбранные ${subtitle}`}
            width={315}
            height={650}
            renderList={renderList}
            items={filterDirectoriesRight}
            input={inputRight}
            updateInput={updateInputSelecting}
            handleAllChecked={handleCheckElement}
            nameAllCheckbox={'allDirectoriesRight'}
            isChecked={isCheckedRightAllCheckbox}
            setIsChecked={setIsCheckedRightAllCheckbox}
          />
        </LocalInnerWrapper>
      </LocalMainWrapper>
    </>
  );
};
