import { getFromURL } from 'api/getFromURL';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import _ from 'underscore';

export const showSignature = async (idSignDocument: number, dispatch: (param: any) => void) => {
  try {
    const response = await getFromURL.getAll(
      `https://bkd-api.admtyumen.ru/api/${IOPWDirectory.special_permits}/statement_billings/${idSignDocument}/show_xml/`
    );
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sign_xml.xml'); // or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error();
    }
  } catch (e) {
    if (_.isObject(e) && e?.response && _.isObject(e.response)) {
      const message = createAlertMessage(e);
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message,
        })
      );
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
    }
  }
};
