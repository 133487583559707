import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { CargoTransportParams } from '../ApplicationsForm/interfacesApplications/interfacesApplications';

import { IndividualOrderList } from './IndividualOrdersList';
import { IndividualOrder } from './Pages/IndividualOrder';

const IndividualOrdersContainer = React.memo(() => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { application } = params;

  if (application) {
    return <IndividualOrder />;
  }
  return <IndividualOrderList />;
});

export default IndividualOrdersContainer;
