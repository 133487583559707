import styled from 'styled-components/macro';
import { Button } from 'UI/common/components/Button/Button';
import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { OpacityColor } = styleMixins.color;

const AvailableMasksFooter = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;
`;

const AvailableMasksContentWrap = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const AvailableMaskItemWrap = styled.div``;

const AvailableMaskItemButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 16px;
`;

const AvailableMaskItemName = styled.span`
  cursor: default;
  color: ${(props) => props.theme.colors.darkBlueText};
  max-height: 40px;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
`;

const AvailableMaskItemButton = styled(Button)`
  height: 40px;
`;

const CurrentAvailableMaskItem = styled.div`
  padding: 16px;
  margin: 0 -16px;
  display: grid;
  grid-gap: 16px;
  box-shadow: 0px 2px 8px ${(props) => OpacityColor(props.theme.colors.primary, 0.12)};
  backdrop-filter: blur(18px);
`;

const CurrentAvailableMaskItemTitle = styled.span`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.darkBlueText};
`;

const CurrentAvailableMaskItemButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

const CurrentAvailableMaskItemStepTitle = styled.span`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.gray};
`;

const CurrentAvailableMaskItemStepsWrap = styled.div`
  position: relative;
  display: grid;
  grid-gap: 8px;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    top: 0;
    left: 4px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.neutralDark};
    border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  }
`;

const CurrentAvailableMaskItemStep = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 6px;
  align-items: center;
`;

const CurrentAvailableMaskItemCircle = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border: 2px solid ${(props) => props.theme.colors.neutralDark};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.defaultLighter};

  &.select {
    border: 2px solid ${(props) => props.theme.colors.darkblue};
  }
`;

const CurrentAvailableMaskItemButton = styled(Button)`
  height: 36px;
  justify-content: flex-start;
  font-weight: 500;
`;

export const Styled = {
  AvailableMasksFooter,
  AvailableMasksContentWrap,
  AvailableMaskItemWrap,
  AvailableMaskItemName,
  AvailableMaskItemButton,
  AvailableMaskItemButtonWrap,
  CurrentAvailableMaskItem,
  CurrentAvailableMaskItemTitle,
  CurrentAvailableMaskItemButtons,
  CurrentAvailableMaskItemStepTitle,
  CurrentAvailableMaskItemStepsWrap,
  CurrentAvailableMaskItemStep,
  CurrentAvailableMaskItemCircle,
  CurrentAvailableMaskItemButton,
};
