import React, { PropsWithChildren } from 'react';
import { Styled } from '../../styles/Panel.styled';

const { LocalSectionWrap } = Styled;

const PanelSection = ({ children, ...other }: PropsWithChildren<{}>) => {
  return <LocalSectionWrap {...other}>{children}</LocalSectionWrap>;
};

export default PanelSection;
