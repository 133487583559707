import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledBreadcrumbsControlled = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  padding-left: 16px;
  padding-bottom: 20px;
  a {
    padding-right: 3px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const BreadcrumbsControlled = ({ links, clickBreadcrumbsControlled = () => {} }) => {
  const Links = links.map((link, index) => {
    return (
      <>
        {links?.length - 1 !== index && (
          <Link to={link.url} onClick={clickBreadcrumbsControlled}>
            <span>{link.label}/</span>
          </Link>
        )}
        {links?.length - 1 === index && <span>{link.label}/</span>}
      </>
    );
  });
  return <StyledBreadcrumbsControlled>{Links}</StyledBreadcrumbsControlled>;
};
