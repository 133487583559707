import React, { useCallback, useMemo, useState } from 'react';
import { GlobalLoader, Panel, Typography } from '@frontend-packages/its-ui-kit';
import { ChevronRightIcon, ChevronLeftIcon } from '@frontend-packages/its-ui-icons';
import { Detection } from '../../../generated/api/api';
import { useMonitoringDefectImage } from '../../../hooks/custom/useMonitoringDefectImage';
import { ModeratedImagePanelFooter } from './ModeratedImagePanelFooter/ModeratedImagePanelFooter';
import {
  SPreviousImageButton,
  SNextImageButton,
  SImageContainer,
  SImageWrapper,
  SModeratedImageContainer,
} from './styles/ModeratedImage.styles';
import { DSelector } from '../../../services/map/Dselector/DSelector';
import { Position2D } from '@deck.gl/core/utils/positions';

interface ModeratedImageProps {
  detectionItem?: Detection;
  onApproveDetection: () => void;
  onDeclineDetection: () => void;
  onGoNextImage: () => void;
  onGoPreviousImage: () => void;
}

export const ModeratedImage = (props: ModeratedImageProps) => {
  const { detectionItem, onDeclineDetection, onApproveDetection, onGoNextImage, onGoPreviousImage } = props;
  const [preparedImage, setPreparedImage] = useState<string>();
  const hasDetection = !!detectionItem;
  const defectPoint = useMemo(
    () => (hasDetection ? DSelector.getPositionFromPoint(detectionItem) : [0, 0]),
    [detectionItem, hasDetection]
  );
  const detectionTextConfig = useMemo(
    () =>
      hasDetection
        ? {
            violationType: detectionItem?.violation_type_display_name,
            time: detectionItem.created_at as unknown as string,
            point: defectPoint as Position2D,
          }
        : {
            violationType: 'неизвестно',
            time: 'неизвестно',
            point: defectPoint as Position2D,
          },
    [defectPoint, detectionItem, hasDetection]
  );

  useMonitoringDefectImage({
    image: detectionItem?.image_src,
    bbox: detectionItem?.bbox as any,
    text: detectionTextConfig,
    onReady: setPreparedImage,
  });

  const handleGoNext = useCallback(() => {
    setPreparedImage(undefined);
    onGoNextImage();
  }, [onGoNextImage]);

  const handleGoPrevious = useCallback(() => {
    setPreparedImage(undefined);
    onGoPreviousImage();
  }, [onGoPreviousImage]);

  return (
    <SModeratedImageContainer>
      <Panel headerContent={<Typography type="type1">Кадр</Typography>} className="imagePanel">
        <SImageContainer>
          <SImageWrapper
            src={preparedImage}
            placeholder={!preparedImage ? <GlobalLoader isShow={true} /> : null}
            alt="Изображение дефекта"
            key={detectionItem?.external_id}
          />
          <SNextImageButton
            className="next"
            icon={<ChevronRightIcon />}
            type="white"
            onClick={handleGoNext}
            iconColor="#20252A"
            bordered
          />
          <SPreviousImageButton
            className="prev"
            icon={<ChevronLeftIcon />}
            type="white"
            onClick={handleGoPrevious}
            iconColor="#20252A"
            bordered
          />
        </SImageContainer>
        <ModeratedImagePanelFooter onApproveDetection={onApproveDetection} onDeclineDetection={onDeclineDetection} />
      </Panel>
    </SModeratedImageContainer>
  );
};
