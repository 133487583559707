import React, { PropsWithChildren } from 'react';

import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

import { Styled } from '../ApplicationForm/ApplicationForm.styled';
import { IApplicationFormProps, ModelGroup } from '../interfacesCargoTransport/interfacesCargoTransport';
import { createInputs } from './createInputs';

const { LocalPanelColumn, ExtendedAntdCollapseItem, ExtendedAntdCollapse } = Styled;

interface IpreparePanelGroupsProps {
  model: ModelResponse;
  data: any[];
  groups: ModelGroup[];
  formProps: IApplicationFormProps;
}

export function preparePanelGroups({ formProps, groups, model, data }: PropsWithChildren<IpreparePanelGroupsProps>) {
  const panelsResult = [];
  let missingGroups = Object.keys(model.scheme);
  for (const group of groups) {
    const currentInputGroups = createInputs(model, group.fields, data, formProps.form);
    panelsResult.push(
      <LocalPanelColumn key={`uniq_${group.label}`}>
        <ExtendedAntdCollapse bordered={false} expandIconPosition={'right'} defaultActiveKey={['']}>
          <ExtendedAntdCollapseItem header={<span>{group.label}</span>} key={'1'} forceRender={true}>
            {currentInputGroups}
          </ExtendedAntdCollapseItem>
        </ExtendedAntdCollapse>
      </LocalPanelColumn>
    );
    for (const field of group.fields) {
      missingGroups = missingGroups.filter((el) => el !== field);
    }
  }

  const inputGroups = createInputs(model, missingGroups, data, formProps.form);

  panelsResult.unshift(inputGroups);

  return panelsResult;
}
