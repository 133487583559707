/** Используется в компоненте Compendium для настройки модели таблиц AGGrid перед отрисовкой
 */

import { RoadImportanceEnum } from 'generated/api/api';
import { ModelNames } from 'types/enums/general/ModelNames.model';
import { ModelSchemeFields } from 'types/enums/general/ModelSchemeFields.model';
import { TypeRoadDisplayNames } from 'types/enums/map/TypeRoadDisplayNames.model';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import _ from 'lodash';
import { AnyObject } from 'types/enums/general/general.model';

export const getNewModel = (model: ModelResponse, modelForCompendium?: boolean, data?: AnyObject) => {
  let newModel = _.cloneDeep(model);

  if (newModel?.name) {
    for (let field in newModel?.scheme) {
      const propertyField = newModel.scheme[field];

      //Блок скрытия колонок во всех таблицах
      const fieldColor = field === ModelSchemeFields.color;
      const fieldLine_path = field === ModelSchemeFields.linePath;
      if (fieldColor || fieldLine_path) {
        propertyField.hidden = true;
        propertyField.type = SchemaFieldType.field;
      }
      if (modelForCompendium) {
        propertyField.read_only = true;
      }

      // Блок индивидуальной настройки таблиц
      switch (newModel?.name) {
        case ModelNames.teamOfWorkersList:
          switch (field) {
            case ModelSchemeFields.teamOfMastersNames:
              if (modelForCompendium) {
                propertyField.type = SchemaFieldType.stringRelatedField;
              }
              break;
            default:
              break;
          }
          break;
        case ModelNames.roadAccidentList:
          switch (field) {
            case ModelSchemeFields.segment:
              propertyField.hidden = true;
              break;
            case ModelSchemeFields.point:
              propertyField.hidden = true;
              break;
            case ModelSchemeFields.roadAccidentType:
              break;
            default:
              break;
          }
          break;
        case ModelNames.fileIndividualStatementList:
          switch (field) {
            case ModelSchemeFields.file:
              propertyField.hidden = true;
              propertyField.type = SchemaFieldType.float;
              break;
            default:
              break;
          }
          break;
        case ModelNames.applicationList:
          switch (field) {
            case ModelSchemeFields.closedStatusTime:
              propertyField.hidden = true;
              propertyField.type = SchemaFieldType.fileUpload;
              break;
            case ModelSchemeFields.createdAt:
              propertyField.hidden = true;
              propertyField.type = SchemaFieldType.fileUpload;
              break;
            case ModelSchemeFields.status:
              if (!modelForCompendium) {
                propertyField.hidden = true;
                propertyField.type = SchemaFieldType.fileUpload;
              }
              break;
            case ModelSchemeFields.appointedTeam:
              if (data?.id) {
                propertyField.hidden = true;
                propertyField.type = SchemaFieldType.fileUpload;
              }
              break;
            default:
              break;
          }
          break;
        case ModelNames.segmentList:
          switch (field) {
            case ModelSchemeFields.uuid:
              propertyField.hidden = true;
              propertyField.type = SchemaFieldType.float;
              break;
            case ModelSchemeFields.roadImportance:
              propertyField.type = SchemaFieldType.choice;
              propertyField.choices = [
                { value: RoadImportanceEnum.MUNICIPAL, display_name: TypeRoadDisplayNames.MUNICIPAL },
                { value: RoadImportanceEnum.REGIONAL, display_name: TypeRoadDisplayNames.REGIONAL },
                { value: RoadImportanceEnum.FEDERAL, display_name: TypeRoadDisplayNames.REGIONAL },
              ];
              break;
            default:
              break;
          }
          break;
        case ModelNames.statementRepresentationList:
          switch (field) {
            case ModelSchemeFields.routeId:
              propertyField.type = SchemaFieldType.boolean;
              break;
            default:
              break;
          }
          break;
        case ModelNames.individualStatementList:
          switch (field) {
            case ModelSchemeFields.uuidStatement:
              propertyField.hidden = true;
              break;
            default:
              break;
          }
          break;
        case ModelNames.entityStatementList:
          switch (field) {
            case ModelSchemeFields.uuidStatement:
              propertyField.hidden = true;
              break;
            default:
              break;
          }
          break;
        case ModelNames.billingList:
          switch (field) {
            case ModelSchemeFields.description:
              propertyField.order = 1;
              break;
            case ModelSchemeFields.amount:
              propertyField.order = 2;
              break;
            case ModelSchemeFields.status:
              propertyField.order = 3;
              break;
            case ModelSchemeFields.paymentStatus:
              propertyField.order = 4;
              break;
            case ModelSchemeFields.paidAmount:
              propertyField.order = 5;
              break;
            case ModelSchemeFields.createdAt:
              propertyField.order = 6;
              break;
            default:
              propertyField.hidden = true;
              break;
          }
          break;
        case ModelNames.formAccountStatementList:
          switch (field) {
            case ModelSchemeFields.number:
              propertyField.checkboxSelection = true;
              break;
            default:
              break;
          }
          break;
        case ModelNames.permissionList:
          newModel.scheme.directories = {
            type: SchemaFieldType.float,
            read_only: false,
            required: false,
            label: 'Справочники',
          };
          newModel.scheme.access = {
            type: SchemaFieldType.float,
            read_only: false,
            required: false,
            label: 'Доступ',
          };
          newModel.scheme.reports = {
            type: SchemaFieldType.float,
            read_only: false,
            required: false,
            label: 'Отчёты',
          };
          newModel.scheme.widgets = {
            type: SchemaFieldType.float,
            read_only: false,
            required: false,
            label: 'Виджеты',
          };
          break;
        case ModelNames.busStopFileList:
          switch (field) {
            case ModelSchemeFields.file:
              propertyField.hidden = true;
              break;
            default:
              break;
          }
          break;
        case ModelNames.DetectionList:
          switch (field) {
            case ModelSchemeFields.violationType:
              propertyField.hidden = true;
              break;
            default:
              break;
          }
          break;
        case ModelNames.roadSignTypeList:
          switch (field) {
            case ModelSchemeFields.image:
            case ModelSchemeFields.imageBorderGreen:
            case ModelSchemeFields.imageBorderRed:
              propertyField.hidden = true;
              break;
            default:
              break;
          }
          break;
        case ModelNames.segmentSurfaceTypeList:
          switch (field) {
            case ModelSchemeFields.segmentName:
              propertyField.hidden = true;
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  }
  return newModel;
};
