import React from 'react';
import { connect } from 'react-redux';

import { StateModel } from 'store/reducers';
import { MarkerSizeMode } from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { MapObjects } from 'types/enums/map/MapObjects.model';

import { RoadSignGroupInfoContainerProps } from './model/RoadSignGroupInfo.model';
import { RoadSignGroupInfo } from './RoadSignGroupInfo';

const RoadSignGroupInfoContainer = (props: RoadSignGroupInfoContainerProps) => {
  const { roadGroup, changeMarkerSizeMode, layer, allMarkers, onClose } = props;

  const suitableMarker = allMarkers?.[layer]?.find((marker) => {
    return marker.type === MapObjects.roadSignGroup && marker.clickInfo.object.id === roadGroup.id;
  });
  return (
    <RoadSignGroupInfo
      roadGroup={roadGroup}
      markerSizeMode={suitableMarker?.markerSizeMode ?? MarkerSizeMode.static}
      changeMarkerSizeMode={changeMarkerSizeMode}
      onClose={onClose}
    />
  );
};

const mapStateToProps = (state: StateModel) => ({
  allMarkers: state.mapMarkers,
});

export default connect(mapStateToProps)(RoadSignGroupInfoContainer);
