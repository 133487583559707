import { connect } from 'react-redux';
import {
  supplyCableColorFromPickerAC,
  roadSectionColorFromPickerAC,
  tramCableColorFromPickerAC,
  trollCableColorFromPickerAC,
} from './roadColorPickerReducer';
import ColorPicker from './ColorPicker';

let mapStateToProps = (state) => {
  return {};
};

let mapDispatchToProps = (dispatch) => {
  return {
    onRoadSectionColorChange: (color) => {
      let action = roadSectionColorFromPickerAC(color);
      dispatch(action);
    },
    onTramCableColorChange: (color) => {
      let action = tramCableColorFromPickerAC(color);
      dispatch(action);
    },
    onSupplyCableColorChange: (color) => {
      let action = supplyCableColorFromPickerAC(color);
      dispatch(action);
    },
    onTrollCableColorChange: (color) => {
      let action = trollCableColorFromPickerAC(color);
      dispatch(action);
    },
  };
};

const ColorPickerContainer = connect(mapStateToProps, mapDispatchToProps)(ColorPicker);

export default ColorPickerContainer;
