import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteObject } from 'store/sagas/directory/deleteObjectSaga';

import { Styled } from './style/FileUploader.styled';
import UploadTMCFileTextButton from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileTextButton';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { FileUploaderI } from './model/FileUploader.model';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

const {
  FileUploaderForm,
  FileUploaderTitle,
  FileUploaderItemWrap,
  FileUploaderButtonWrap,
  FileUploaderItem,
  StyledCheckboxGroup,
} = Styled;

const FileUploader = ({ id, pageMenuKey, deletePath, files, updateAsyncData }: FileUploaderI) => {
  enum modeEnum {
    upload = 'upload',
    delete = 'delete',
  }

  const dispatch = useDispatch();

  const [mode, setMode] = useState<modeEnum>(modeEnum.upload);
  const [data, setData] = useState<CheckboxValueType[]>([]);

  const onDeleteHandle = useCallback(() => {
    for (const id of data) {
      dispatch(deleteObject({ url: deletePath, id }));
    }
  }, [data, deletePath, dispatch]);

  const options = useMemo(() => files.map((el) => ({ label: el.name, value: el.id })), [files]);

  const title = useMemo(() => {
    if (mode === modeEnum.delete) {
      return 'Удаление документов';
    }

    return 'Документы';
  }, [mode, modeEnum.delete]);

  const content = useMemo(() => {
    if (mode === modeEnum.delete) {
      return <StyledCheckboxGroup options={options} onChange={(e) => setData(e)} />;
    }

    return files.map((el) => <FileUploaderItem key={el.id}>{el.name}</FileUploaderItem>);
  }, [mode, modeEnum.delete, options, files]);

  const disabledBtnDelete = useMemo(() => data.length === 0, [data]);

  const deleteButton = useMemo(() => {
    return (
      <>
        <Button type={ButtonType.dangerReverse} onClick={onDeleteHandle} disabled={disabledBtnDelete}>
          Удалить файлы
        </Button>
        <Button type={ButtonType.dangerReverseTranslucent} onClick={() => setMode(modeEnum.upload)}>
          Отмена
        </Button>
      </>
    );
  }, [disabledBtnDelete, modeEnum.upload, onDeleteHandle]);

  return (
    <FileUploaderForm>
      <FileUploaderTitle>{title}</FileUploaderTitle>
      <FileUploaderItemWrap>{content}</FileUploaderItemWrap>
      <FileUploaderButtonWrap>
        {mode === modeEnum.upload && (
          <>
            <UploadTMCFileTextButton
              tooltip={'Добавить файл'}
              id={id}
              pageMenuKey={pageMenuKey}
              updateAsyncData={updateAsyncData}
            />
            <Button type={ButtonType.dangerReverseTranslucent} onClick={() => setMode(modeEnum.delete)}>
              Удалить файлы
            </Button>
          </>
        )}
        {mode === modeEnum.delete && <>{deleteButton}</>}
      </FileUploaderButtonWrap>
    </FileUploaderForm>
  );
};

export default FileUploader;
