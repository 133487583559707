import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { StateModel } from 'store/reducers';
import { mapMarkersActions } from 'store/reducers/map/mapMarkers/mapMarkers.actions';
import { MapMarker as MapMarkerModel } from 'store/reducers/map/mapMarkers/mapMarkers.model';
import { Layers } from 'types/enums/map/layers/Layers';

import MapMarker from './components/MapMarker/MapMarker';
import { MapMarkerContainerProps } from './model/MapMarker.model';

const MapMarkerListContainer = ({ mapMarkers, onMarkerMove }: MapMarkerContainerProps) => {
  const markersOnMap = useMemo(
    () =>
      Object.entries(mapMarkers).flatMap(([layer, markers]) => {
        return markers?.map((marker, index) => {
          return (
            <MapMarker
              key={`${marker?.id}_${index}`}
              marker={marker}
              onMarkerMove={(newPosition: [number, number]) => onMarkerMove(layer as Layers, marker, newPosition)}
            />
          );
        });
      }),
    [mapMarkers, onMarkerMove]
  );

  return <>{markersOnMap}</>;
};

const mapStateToProps = (state: StateModel) => ({
  mapMarkers: state.mapMarkers,
});

const mapDispatchToProps = (dispatch: any) => ({
  onMarkerMove: (layer: Layers, marker: MapMarkerModel, newPosition: [number, number]) =>
    dispatch(mapMarkersActions.moveMarker(layer, marker, newPosition)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapMarkerListContainer);
