import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
/*import { ArtificialStructuresService } from '../../../../../../generated/api/api';*/
import { getFromURL } from '../../../../../../api/getFromURL';

export class Bridges extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'bridges',
      filterLabel: 'Мосты',
      selectedObjectName: MapObjects.bridges,
    });
  }
  /*

  async uploadData() {
    return await ArtificialStructuresService.artificialStructuresBridgesList();
  }

  async deleteRecord(id) {
    return await ArtificialStructuresService.artificialStructuresBridgesDelete({ id });
  }

  async readRecord(id) {
    return await ArtificialStructuresService.artificialStructuresBridgesRead({ id });
  }
*/

  async uploadModel() {
    return await getFromURL.getModelFromURL('/artificial_structures/bridges/');
  }
}
