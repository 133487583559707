import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
/*import { ArtificialStructuresService, LayerRoadsService } from '../../../../../../generated/api/api';*/
import { getFromURL } from '../../../../../../api/getFromURL';

export class RailCrossings extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'rail_crossings',
      filterLabel: 'ЖД переезды',
      selectedObjectName: MapObjects.railCrossings,
    });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL('/artificial_structures/rail_crossings/');
  }
}
