import styled from 'styled-components';
import { Button, DefaultStyleMixins } from '@frontend-packages/its-ui-kit';
import { Image } from 'antd';
const { getSpacing } = DefaultStyleMixins.offset;

export const SModeratedImageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(1)};
  justify-content: space-between;

  .imagePanel {
    height: 100%;
  }
`;

export const SImageWrapper = styled(Image)`
  max-width: 1150px;
  width: 100%;
`;

export const SImageContainer = styled.div`
  position: relative;
`;

export const SNextImageButton = styled(Button)`
  /* Повышение специфичности https://styled-components.com/docs/advanced#issues-with-specificity */
  &.next {
    position: absolute;
    right: ${getSpacing(2)};
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border-radius: 16px;
    width: 40px;
    height: 40px;
    right: ${getSpacing(2)};
  }
`;

export const SPreviousImageButton = styled(Button)`
  /* Повышение специфичности https://styled-components.com/docs/advanced#issues-with-specificity */
  &.prev {
    position: absolute;
    right: ${getSpacing(2)};
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border-radius: 16px;
    width: 40px;
    height: 40px;
    left: ${getSpacing(2)};
  }
`;
