import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RoadAccidentsService } from '../../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class RoadAccidentsSections extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_accidents_sections',
      filterLabel: 'Аварийно-опасные участки',
      selectedObjectName: MapObjects.roadAccidentsSections,
    });
  }

  async uploadData() {
    return await RoadAccidentsService.roadAccidentsSectionsList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async uploadFullData() {
    return await RoadAccidentsService.roadAccidentsSectionsList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async readRecord(id) {
    return await RoadAccidentsService.roadAccidentsSectionsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadAccidents);
  }
}
