import { useMemo } from 'react';
import { DeckContainer } from 'components/Application/BaseModules/Map/Deck/DeckContainer';
import withMapModuleContext from 'components/Application/BaseModules/Map/moduleContext/withMapModuleContext';
import { getEditLayer } from './getEditLayer';
import { getMainLayer } from './getMainLayer';

export interface IMapContainerProps {
  changePosition: boolean;
  newPosition: string;
  setNewPosition: (status: string) => void;
  field: string;
  object: { [k: string]: any };
}

const MapContainer = (props: IMapContainerProps) => {
  const { field, changePosition, object, newPosition, setNewPosition } = props;

  const mainLayer = useMemo(() => getMainLayer(field, object, newPosition), [field, newPosition, object]);
  const editLayer = useMemo(
    () => getEditLayer(field, setNewPosition, changePosition),
    [changePosition, field, setNewPosition]
  );

  const directoryLayers = useMemo(() => [mainLayer, editLayer], [editLayer, mainLayer]);

  // @ts-ignore
  return <DeckContainer directoryLayers={directoryLayers} />;
};
export default withMapModuleContext(MapContainer);
