import wkt from 'wkt';
import turfCenterOfMass from '@turf/center-of-mass';

export function calculatePolygonPathCenterCoords(polygonCenter) {
  try {
    const parsed = wkt.parse(polygonCenter);
    const center = turfCenterOfMass(parsed);
    return center.geometry.coordinates;
  } catch (e) {
    console.log(e, ' error in calculateLinePathCenterCoords(), maybe input data is not valid');
  }
}
