import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, PathLayer, TextLayer, PolygonLayer } from '@deck.gl/layers';
import wkt from 'wkt';
import { FillStyleExtension, PathStyleExtension } from '@deck.gl/extensions';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { MapSignsSubtype } from '../../../types/enums/map/MapSignsSubtype.model';
import { calculateLinePathCenterCoords } from '../../../services/map/calculateLinePathCenterCoords';
import { calculatePolygonPathCenterCoords } from '../../../services/map/calculatePolygonPathCenterCoords';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import { roadObjectsIconMapping } from '../../iconMappers/roadObjectsIconMapping';
import { roadMarkingIconMapping } from '../../iconMappers/roadMarkingIconMapping/roadMarkingIconMapping';
import RoadSignGroupLayer from 'layers/RoadSignGroupLayer/RoadSignGroupLayer';
import { trafficLightType1 } from '../../iconMappers/trafficLight/trafficLightType1';
import { signsRoadMapperType1 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType1';
import { signsRoadMapperType2 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType2';
import { signsRoadMapperType3 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType3';
import { signsRoadMapperType4 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType4';
import { signsRoadMapperType5 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType5';
import { signsRoadMapperType6 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType6';
import { signsRoadMapperType7 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType7';
import { signsRoadMapperType8 } from '../../iconMappers/signsRoadAllTypesMapper/signsRoadMapperType8';
import { setIconsAdditionalParams } from '../../../types/utils/setIconsAdditionalParams';
import { objectAllIconMapper } from '../../iconMappers/objectAllIconMapper/objectAllIconMapper';
import { layerColorsMapper } from '../../colorMappers/layerColorsMapper';
import { DSelector } from '../../../services/map/Dselector/DSelector';
import { publicTransportIconMapper } from 'layers/iconMappers/publicTransportIconMapper';
import { trafficLightType2 } from '../../iconMappers/trafficLight/trafficLightType2';
import MapEventObserver from 'store/rakes/MapEventObserver';
import { RoadMarkingType419Enum, Status981Enum } from '../../../generated/api/api';
import { roadMarkingPolygonMapping } from '../../iconMappers/roadMarkingPolygonMapping';

const SIGNS_MAPPER_SET = [
  signsRoadMapperType1,
  signsRoadMapperType2,
  signsRoadMapperType3,
  signsRoadMapperType4,
  signsRoadMapperType5,
  signsRoadMapperType6,
  signsRoadMapperType7,
  signsRoadMapperType8,
];

const typesOfRoadMarkingPolygon = {
  G_1_14_1: [250, 250, 250, 255],
  G_1_14_1y: [252, 250, 252, 100],
  G_1_16_1: [204, 204, 204, 100],
  G_1_16_2: [158, 158, 158, 100],
  G_1_16_3: [130, 130, 130, 100],
  G_1_17_1: [92, 92, 92, 100],
  G_1_25: [71, 71, 71, 100],
};

class RoadSchemaCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof RoadSchemaCompositeLayer) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }
    this.setState({
      data: props,
    });
  }

  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.roadsSchema;
        if (event.sourceLayer.id.includes('segments-path'))
          selectedObjectType = {
            mapObjects: MapObjects.roadSegment,
            settingsEntity: this.props.objectSettings.road_segment,
          };
        if (event.sourceLayer.id.includes('signs'))
          selectedObjectType = {
            mapObjects: MapObjects.roadSigns,
            settingsEntity: this.props.objectSettings.road_signs,
          };
        if (event.sourceLayer.id.includes('contours-lawn'))
          selectedObjectType = {
            mapObjects: MapObjects.contoursLawn,
            settingsEntity: this.props.objectSettings.contours_lawn,
          };
        if (event.sourceLayer.id.includes('town-squares'))
          selectedObjectType = {
            mapObjects: MapObjects.townSquares,
            settingsEntity: this.props.objectSettings.town_squares,
          };
        if (event.sourceLayer.id.includes('traffic-light'))
          selectedObjectType = {
            mapObjects: MapObjects.trafficLight,
            settingsEntity: this.props.objectSettings.traffic_light,
          };
        if (event.sourceLayer.id.includes('speed-bumps'))
          selectedObjectType = {
            mapObjects: MapObjects.speedBumps,
            settingsEntity: this.props.objectSettings.speed_bumps,
          };
        if (event.sourceLayer.id.includes('rail-crossings'))
          selectedObjectType = {
            mapObjects: MapObjects.railCrossings,
            settingsEntity: this.props.objectSettings.rail_crossings,
          };
        if (event.sourceLayer.id.includes('overpasses'))
          selectedObjectType = {
            mapObjects: MapObjects.overpasses,
            settingsEntity: this.props.objectSettings.overpasses,
          };
        if (event.sourceLayer.id.includes('pipes'))
          selectedObjectType = {
            mapObjects: MapObjects.pipes,
            settingsEntity: this.props.objectSettings.pipes,
          };
        if (event.sourceLayer.id.includes('tray_systems'))
          selectedObjectType = {
            mapObjects: MapObjects.traySystem,
            settingsEntity: this.props.objectSettings.tray_system,
          };
        if (event.sourceLayer.id.includes('lighting'))
          selectedObjectType = {
            mapObjects: MapObjects.lighting,
            settingsEntity: this.props.objectSettings.lighting,
          };
        if (event.sourceLayer.id.includes('rest-areas'))
          selectedObjectType = {
            mapObjects: MapObjects.restAreas,
            settingsEntity: this.props.objectSettings.rest_areas,
          };
        if (event.sourceLayer.id.includes('weather-stations'))
          selectedObjectType = {
            mapObjects: MapObjects.weatherStations,
            settingsEntity: this.props.objectSettings.weather_stations,
          };
        if (event.sourceLayer.id.includes('pipelines'))
          selectedObjectType = {
            mapObjects: MapObjects.pipelines,
            settingsEntity: this.props.objectSettings.pipelines,
          };
        if (event.sourceLayer.id.includes('tunnels'))
          selectedObjectType = {
            mapObjects: MapObjects.tunnels,
            settingsEntity: this.props.objectSettings.tunnels,
          };
        if (event.sourceLayer.id.includes('ferry-crossings'))
          selectedObjectType = {
            mapObjects: MapObjects.ferryCrossings,
            settingsEntity: this.props.objectSettings.ferry_crossings,
          };
        if (event.sourceLayer.id.includes('road-ways'))
          selectedObjectType = {
            mapObjects: MapObjects.roadWays,
            settingsEntity: this.props.objectSettings.road_ways,
          };
        if (event.sourceLayer.id.includes('sidewalk'))
          selectedObjectType = {
            mapObjects: MapObjects.sidewalk,
            settingsEntity: this.props.objectSettings.sidewalk,
          };
        if (event.sourceLayer.id.includes('support-wall'))
          selectedObjectType = {
            mapObjects: MapObjects.supportWall,
            settingsEntity: this.props.objectSettings.support_wall,
          };
        if (event.sourceLayer.id.includes('road-fences'))
          selectedObjectType = {
            mapObjects: MapObjects.roadFences,
            settingsEntity: this.props.objectSettings.road_fences,
          };
        if (event.sourceLayer.id.includes('pedestrian_fences'))
          selectedObjectType = {
            mapObjects: MapObjects.pedestrianFences,
            settingsEntity: this.props.objectSettings.pedestrian_fences,
          };
        if (event.sourceLayer.id.includes('improvement_elements'))
          selectedObjectType = {
            mapObjects: MapObjects.improvementElements,
            settingsEntity: this.props.objectSettings.improvement_elements,
          };
        if (event.sourceLayer.id.includes('engineering-constructions'))
          selectedObjectType = {
            mapObjects: MapObjects.engineeringConstructions,
            settingsEntity: this.props.objectSettings.engineering_constructions,
          };
        if (event.sourceLayer.id.includes('support-pillars'))
          selectedObjectType = {
            mapObjects: MapObjects.supportPillars,
            settingsEntity: this.props.objectSettings.support_pillars,
          };
        if (event.sourceLayer.id.includes('road-marking-icon'))
          selectedObjectType = {
            mapObjects: MapObjects.roadMarkings,
            settingsEntity: this.props.objectSettings.road_markings,
          };
        if (event.sourceLayer.id.includes('dividing-lines'))
          selectedObjectType = {
            mapObjects: MapObjects.dividingLines,
            settingsEntity: this.props.objectSettings.dividing_lines,
          };
        if (event.sourceLayer.id.includes('tram-rails'))
          selectedObjectType = {
            mapObjects: MapObjects.tramRails,
            settingsEntity: this.props.objectSettings.tram_rails,
          };
        if (event.sourceLayer.id.includes('road-marking-polygon'))
          selectedObjectType = {
            mapObjects: MapObjects.roadMarkingPolygon,
            settingsEntity: this.props.objectSettings.road_marking_polygon,
          };
        if (event.sourceLayer.id.includes('road-marking-lines'))
          selectedObjectType = {
            mapObjects: MapObjects.roadMarkingLine,
            settingsEntity: this.props.objectSettings.road_marking_line,
          };
        if (event.sourceLayer.id.includes('cabinets'))
          selectedObjectType = {
            mapObjects: MapObjects.cabinets,
            settingsEntity: this.props.objectSettings.cabinets,
          };
        if (event.sourceLayer.id.includes('pipes'))
          selectedObjectType = {
            mapObjects: MapObjects.pipes,
            settingsEntity: this.props.objectSettings.pipes,
          };
        if (event.sourceLayer.id.includes('signal-poles'))
          selectedObjectType = {
            mapObjects: MapObjects.signalPoles,
            settingsEntity: this.props.objectSettings.signal_poles,
          };
        if (event.sourceLayer.id.includes('bus-shelters-icon'))
          selectedObjectType = {
            mapObjects: MapObjects.busShelters,
            settingsEntity: this.props.objectSettings.bus_shelters,
          };
        if (selectedObjectType) {
          this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
        }
      }
    }
    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];

    const { isSmallObjectIconVisible, isBigObjectIconVisible } = this.props;
    const isTextVisible = isSmallObjectIconVisible && this.props.billboards;
    const roadMarkingPolygonLayer = new PolygonLayer({
      id: 'road-marking-polygon',
      data: this.props.relatedData.road_marking_polygon,
      stroked: true,
      filled: true,
      pickable: true,
      autoHighlight: true,
      highlightColor: [0, 0, 0, 30],
      wireframe: true,
      lineWidthMinPixels: 3,
      visible: isBigObjectIconVisible,
      getPolygonOffset: DSelector.getNewPolygonOffset,
      getLineColor: (d) => {
        if (d?.road_marking_type && typesOfRoadMarkingPolygon[d.road_marking_type])
          return typesOfRoadMarkingPolygon[d.road_marking_type];
      },
      getFillColor: (d) => {
        if (d?.road_marking_type && typesOfRoadMarkingPolygon[d.road_marking_type])
          return typesOfRoadMarkingPolygon[d.road_marking_type];
      },

      getPolygon: (d) => {
        if (d.status === Status981Enum.EXISTS) {
          if (d.polygon) {
            const parsed = wkt.parse(d.polygon);
            return parsed
              ? parsed.coordinates[0]
              : [
                  [0, 0],
                  [0.1, 0.1],
                  [0.11, 0.11],
                ];
          } else {
            return [
              [0, 0],
              [0.1, 0.1],
              [0.11, 0.11],
            ];
          }
        } else {
          return [
            [0, 0],
            [0.1, 0.1],
            [0.11, 0.11],
          ];
        }
      },
      updateTriggers: {
        getFillColor: this.state.selectedLayer,
        getPolygon: this.state.geozonesTypes,
      },
      getLineWidth: 1,
      lineJointRounded: true,
      fillPatternAtlas: `${process.env.PUBLIC_URL}/img/textures/roadMarkingPolygon.png`,
      fillPatternMapping: roadMarkingPolygonMapping,
      getFillPattern: (d) => {
        if (d?.road_marking_type === RoadMarkingType419Enum.G_1_14_1) return 'G_1_14_1';
      },
      getFillPatternScale: 0.05,
      getFillPatternOffset: [0, 0],
      extensions: [new FillStyleExtension({ pattern: true })],
    });

    const roadMarkingLineLayer = isBigObjectIconVisible
      ? [
          new PathLayer(this.props, this.getSubLayerProps({ id: 'road-marking-lines' }), {
            data: this.props.relatedData.road_marking_line,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            widthScale: 3,
            widthMaxPixels: 3,
            billboard: false,
            extensions: [new PathStyleExtension({ dash: true })],
            getDashArray: (d) => {
              switch (d.road_marking_type_name) {
                case '1.5': {
                  return [20, 50];
                }
                case '1.6': {
                  return [30, 10];
                }
                case '1.7': {
                  return [10, 10];
                }
                case '1.8': {
                  return [20, 50];
                }
                case '1.9': {
                  return [30, 10];
                }
                case '1.10': {
                  return [10, 10];
                }
                default:
                  return [0, 0];
              }
            },
            getPolygonOffset: DSelector.getNewPolygonOffset,
            dashJustified: true,
            getPath: (d) => {
              if (d.status === Status981Enum.EXISTS) {
                if (d?.line_path) {
                  const geometry = wkt.parse(d.line_path);
                  return geometry.coordinates;
                }
                return [0, 0];
              }
            },
            getColor: (d) => {
              switch (d.road_marking_type_name) {
                case '1.3': {
                  return [255, 0, 0];
                }
                case '1.9': {
                  return [0, 0, 255];
                }
                case '1.11': {
                  return [255, 0, 255];
                }
                default:
                  return [255, 255, 255];
              }
            },
            getWidth: (d) => {
              if (d.road_marking_type_name === '1.12') {
                return [10];
              } else {
                return [0.1];
              }
            },
          }),
        ]
      : null;
    const roadMarkingPointLayer = isBigObjectIconVisible
      ? [
          new IconLayer(this.props, this.getSubLayerProps({ id: 'road-marking-icon' }), {
            data: this.props.relatedData.road_markings,
            iconAtlas: `${process.env.PUBLIC_URL}/img/textures/razmetka.png`,
            iconMapping: roadMarkingIconMapping,
            sizeUnits: 'meters',
            opacity: this?.props?.opacity ?? 0.1,
            pickable: true,
            autoHighlight: true,
            highlightColor: [0, 0, 0, 30],
            fp64: true,
            billboard: false,
            getPolygonOffset: DSelector.getNewPolygonOffset,
            getPosition: (d) => {
              if (d.point) {
                return getCoordsFromParsedGeometry(d.point);
              } else return [0, 0];
            },
            sizeScale: this?.props?.sizeScale ?? 1,
            getSize: () => 25,
            updateTriggers: {
              getIcon: [this.state.selectedObject, this.state.data?.instance],
            },
            getAngle: (d) => {
              return d.azimuth ? 180 - d.azimuth : 0;
            },
            getIcon: (d) => {
              if (d.status === Status981Enum.EXISTS) {
                if (
                  this.props?.selectedObject?.type === 'road_markings' &&
                  d.id === this.props?.selectedObject?.selectedObject?.object?.id
                ) {
                  if (this.state.data?.instance?.id === d.id) {
                    return null;
                  } else if (d?.road_marking_type) {
                    return d.road_marking_type_name + '-active';
                  }
                } else {
                  if (this.state.data?.instance?.id === d.id) {
                    return null;
                  } else if (d?.road_marking_type_name) {
                    return d.road_marking_type_name;
                  }
                }
              }
            },
          }),
        ]
      : null;

    const trafficLightData = this.props.relatedData?.traffic_light || [];
    const trafficLightNoType = [];
    const trafficLightWithType = [];

    for (const item of trafficLightData) {
      if (!item.shape) {
        trafficLightNoType.push(item);
      } else {
        trafficLightWithType.push(item);
      }
    }

    const signsLayers = [];

    for (let it = 1; it < 9; it++) {
      const icon = new IconLayer(this.props, this.getSubLayerProps({ id: `signs-icon-type-${it}` }), {
        data: this.props.relatedData.road_signs,
        iconAtlas: `${process.env.PUBLIC_URL}/img/signsRoadAllTypes/signsRoadType${it}.png`,
        iconMapping: SIGNS_MAPPER_SET[it - 1],
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        billboard: false,
        updateTriggers: {
          getIcon: this.state.selectedObject,
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        getAngle: (d) => {
          return d.azimuth ? 180 - d.azimuth : 0;
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            const { road_sign_type_name, to_remove } = d;
            const borderType = to_remove ? '-R' : '';
            let roadSignName;
            if (road_sign_type_name?.includes('6.9.1')) {
              if (d.subtype) roadSignName = road_sign_type_name + d.subtype;
            }
            if (road_sign_type_name?.includes('5.15')) {
              if (d.subtype === MapSignsSubtype.right) roadSignName = road_sign_type_name + '.3';
              if (d.subtype === MapSignsSubtype.left) roadSignName = road_sign_type_name + '.4';
              if (d.subtype === MapSignsSubtype.rightAndStraight) roadSignName = road_sign_type_name + '.2';
              if (d.subtype === MapSignsSubtype.leftAndStraight) roadSignName = road_sign_type_name + '.1';
            }
            roadSignName = `${roadSignName ?? road_sign_type_name}${borderType}`;
            return [roadSignName];
          }
        },
      });
      signsLayers.push(icon);
    }

    return [
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'road-ways-polygon' }), {
        data: this.props.relatedData.road_ways,
        stroked: true,
        filled: true,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        wireframe: true,
        getLineColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return layerColorsMapper.grayAsphalt;
        },
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'road_ways' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [133, 143, 163, 255]
            : [146, 159, 181, 255];
        },
        getPolygon: (d) => {
          if (d.status === Status981Enum.EXISTS) return DSelector.getDPolygon(d);
          return [];
        },
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        lineJointRounded: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
      }),
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'contours-lawn-polygon' }), {
        data: this.props.relatedData.contoursLawn,
        stroked: true,
        filled: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        pickable: true,
        wireframe: true,
        getLineColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return layerColorsMapper.blue;
        },
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'road_ways' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [47, 128, 237, 200]
            : [47, 128, 237, 100];
        },
        getPolygon: DSelector.getDPolygon,
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        lineJointRounded: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'contours-lawn-icon' }), {
        data: this.props.relatedData.contoursLawn,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.polygon) {
            return calculatePolygonPathCenterCoords(d.polygon);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'contoursLawn' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `lawn${additionalParams}`
              : 'lawn';
          }
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'contours-lawn-text' }), {
        data: this.props.relatedData.contoursLawn,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            return d?.name;
          }
        },
        getPosition: (d) => {
          if (d.polygon) {
            const cordinate = calculatePolygonPathCenterCoords(d.polygon);
            return [cordinate[0], cordinate[1] + 0.00002];
          } else return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getSize: (d) => {
          if (isTextVisible) {
            if (!this.state?.selectedObject) {
              return 20;
            }
            if (this.state?.routePathes?.includes(d?.route_path_id)) {
              return 20;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
        updateTriggers: {
          getSize: [isTextVisible, this.state?.selectedObject],
        },
      }),
      roadMarkingPolygonLayer,
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'town-squares-polygon' }), {
        data: this.props.relatedData.townSquares,
        stroked: true,
        filled: true,
        pickable: true,
        wireframe: true,
        getLineColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return layerColorsMapper.blue;
        },
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'road_ways' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [1, 152, 66, 255]
            : [0, 173, 75, 255];
        },
        getPolygon: (d) => {
          if (d.status === Status981Enum.EXISTS) return DSelector.getDPolygon(d);
        },
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        lineJointRounded: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'town-squares-icon' }), {
        data: this.props.relatedData.townSquares,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.polygon) {
            return calculatePolygonPathCenterCoords(d.polygon);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );

            return this.props?.selectedObject?.type === 'townSquares' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `monument${additionalParams}`
              : 'monument';
          }
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'town-squares-text' }), {
        data: this.props.relatedData.townSquares,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getText: (d) => {
          return d?.name;
        },
        getPosition: (d) => {
          if (d.polygon) {
            const cordinate = calculatePolygonPathCenterCoords(d.polygon);
            return [cordinate[0], cordinate[1] + 0.00002];
          } else return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getSize: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (isTextVisible) {
              if (!this.state?.selectedObject) {
                return 20;
              }
              if (this.state?.routePathes?.includes(d?.route_path_id)) {
                return 20;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        },
        updateTriggers: {
          getSize: [isTextVisible, this.state?.selectedObject],
        },
      }),
      [],
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'tray_systems-polygon' }), {
        data: this.props.relatedData.tray_systems,
        stroked: true,
        filled: true,
        pickable: true,
        wireframe: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        lineWidthMinPixels: 3,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getLineColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return layerColorsMapper.yellowTranslucent;
        },
        getFillColor: () => [204, 204, 33, 100],
        getPolygon: (d) => {
          if (d.status === Status981Enum.EXISTS) return DSelector.getDPolygon(d);
        },
        updateTriggers: {
          getFillColor: this.state.selectedLayer,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1,
        lineJointRounded: true,
      }),
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'sidewalk-polygon' }), {
        data: this.props.relatedData.sidewalk,
        stroked: true,
        filled: true,
        pickable: true,
        wireframe: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getLineColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return layerColorsMapper.graySidewalk;
        },
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'sidewalk' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [175, 185, 197, 255]
            : [193, 203, 216, 255];
        },
        getPolygon: (d) => {
          if (d.status === Status981Enum.EXISTS) return DSelector.getDPolygon(d);
          return [];
        },
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        lineJointRounded: true,
      }),
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'dividing-lines-polygon' }), {
        data: this.props.relatedData.dividing_lines,
        stroked: true,
        filled: true,
        pickable: true,
        wireframe: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getLineColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return layerColorsMapper.gray;
        },
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'dividing_lines' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [140, 140, 140, 255]
            : [150, 150, 150, 255];
        },
        getPolygon: (d) => {
          if (d.status === Status981Enum.EXISTS) return DSelector.getDPolygon(d);
        },
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        lineJointRounded: true,
      }),
      new PolygonLayer(this.props, this.getSubLayerProps({ id: 'tram-rails-polygon' }), {
        data: this.props.relatedData.tram_rails,
        stroked: true,
        filled: true,
        pickable: true,
        wireframe: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getLineColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return layerColorsMapper.pinkTram;
        },
        getLineWidth: 1 * this?.props?.getLineWidth ?? 0.5,
        getFillColor: (d) => {
          return this.props?.selectedObject?.type === 'tram_rails' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [152, 82, 100, 255]
            : [175, 95, 117, 255];
        },
        getPolygon: (d) => {
          if (d.status === Status981Enum.EXISTS) return DSelector.getDPolygon(d);
          return [];
        },
        updateTriggers: {
          getFillColor: this.props.selectedObject,
          getPolygon: this.state.geozonesTypes,
        },
      }),
      new PathLayer(this.props, this.getSubLayerProps({ id: 'engineering-constructions-path' }), {
        data: this.props.relatedData.engineering_constructions,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        stroked: true,
        widthScale: 2,
        widthMinPixels: 2,
        widthMaxPixels: 12,
        rounded: true,
        billboard: false,
        getPath: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (d.line_path) {
              return getCoordsFromParsedGeometry(d.line_path);
            }
            return [0, 0];
          }
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          switch (d.construction_type) {
            case 'BRIDGE':
            case 'SMALL_BRIDGE':
            case 'OVERPASS':
            case 'VIADUCT':
            case 'FLYOVER':
            case 'U_SHAPED_SUPPORT_FOR_TSODD':
            case 'TUNNEL':
            case 'CITY_TUNNEL':
              return this.props?.selectedObject?.type === 'engineering_constructions' &&
                d.id === this.props?.selectedObject?.selectedObject?.object?.id
                ? [1, 152, 66, 255]
                : [0, 173, 75, 255];
            case 'PEDESTRIAN_TUNNEL':
            case 'OVERHEAD_COMMUNICATIONS':
            case 'OVERHEAD_PEDESTRIAN_CROSSING':
            case 'UNDERGROUND_PEDESTRIAN_CROSSING':
              return this.props?.selectedObject?.type === 'engineering_constructions' &&
                d.id === this.props?.selectedObject?.selectedObject?.object?.id
                ? [171, 99, 197, 255]
                : [187, 107, 217, 255];
            default:
              break;
          }
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'engineering_constructions' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 7.5
            : 5;
        },
        opacity: 1,
        onClick: () => {
          this.props.onClick();
        },
      }),
      new PathLayer(this.props, this.getSubLayerProps({ id: 'pipes-path' }), {
        data: this.props.relatedData.pipes,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        pickable: true,
        autoHighlight: false,
        widthScale: 2,
        widthMinPixels: 2,
        widthMaxPixels: 12,
        rounded: true,
        billboard: false,
        getPath: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (d.line_path) {
              return getCoordsFromParsedGeometry(d.line_path);
            }
            return [0, 0];
          }
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return this.props?.selectedObject?.type === 'pipes' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [0, 112, 50, 250]
            : [0, 128, 57, 250];
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'pipes' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 7.5
            : 5;
        },
        opacity: 1,
        onClick: () => {
          this.props.onClick();
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'engineering-constructions-icon' }), {
        data: this.props.relatedData.engineering_constructions,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }

            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            switch (d.construction_type) {
              case 'BRIDGE':
              case 'SMALL_BRIDGE':
                return this.props?.selectedObject?.type === 'engineering_constructions' &&
                  d.id === this.props?.selectedObject?.selectedObject?.object?.id
                  ? `bridge${additionalParams}`
                  : `bridge`;
              case 'OVERPASS':
              case 'VIADUCT':
              case 'FLYOVER':
              case 'U_SHAPED_SUPPORT_FOR_TSODD':
                return this.props?.selectedObject?.type === 'engineering_constructions' &&
                  d.id === this.props?.selectedObject?.selectedObject?.object?.id
                  ? `overpass${additionalParams}`
                  : 'overpass';
              case 'TUNNEL':
              case 'CITY_TUNNEL':
                return this.props?.selectedObject?.type === 'engineering_constructions' &&
                  d.id === this.props?.selectedObject?.selectedObject?.object?.id
                  ? `tunnel${additionalParams}`
                  : 'tunnel';
              case 'PEDESTRIAN_TUNNEL':
                return this.props?.selectedObject?.type === 'engineering_constructions' &&
                  d.id === this.props?.selectedObject?.selectedObject?.object?.id
                  ? `pedestrian${additionalParams}`
                  : 'pedestrian';
              case 'OVERHEAD_COMMUNICATIONS':
              case 'OVERHEAD_PEDESTRIAN_CROSSING':
                return this.props?.selectedObject?.type === 'engineering_constructions' &&
                  d.id === this.props?.selectedObject?.selectedObject?.object?.id
                  ? `overhead_passage${additionalParams}`
                  : 'overhead_passage_white';
              case 'UNDERGROUND_PEDESTRIAN_CROSSING':
                return this.props?.selectedObject?.type === 'engineering_constructions' &&
                  d.id === this.props?.selectedObject?.selectedObject?.object?.id
                  ? `underground_crossing${additionalParams}`
                  : 'underground_crossing';
              default:
                break;
            }
          }
        },
        pickable: true,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
      }),
      new PathLayer(this.props, this.getSubLayerProps({ id: 'support-wall-path' }), {
        data: this.props.relatedData.support_wall,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        widthScale: 2,
        widthMaxPixels: 5,
        rounded: true,
        billboard: false,
        getPath: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (d.line_path) {
              return getCoordsFromParsedGeometry(d.line_path);
            }
          } else return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return this.props?.selectedObject?.type === 'support_wall' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [171, 99, 197, 255]
            : [187, 107, 217, 255];
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'support_wall' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 2
            : 1;
        },
        opacity: 1,
        onClick: () => {
          this.props.onClick();
        },
      }),
      new PathLayer(this.props, this.getSubLayerProps({ id: 'road-fences-path' }), {
        data: this.props.relatedData.road_fences,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        widthScale: 2,
        widthMaxPixels: 5,
        rounded: true,
        billboard: false,
        getPath: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (d.line_path) {
              return getCoordsFromParsedGeometry(d.line_path);
            }
          } else return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return this.props?.selectedObject?.type === 'road_fences' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [217, 138, 63, 255]
            : [242, 153, 74, 255];
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'road_fences' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 2
            : 1;
        },
        opacity: 1,
        onClick: () => {
          this.props.onClick();
        },
      }),
      new PathLayer(this.props, this.getSubLayerProps({ id: 'segments-path' }), {
        data: this.props.relatedData.segments,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        widthScale: 3,
        widthMinPixels: 2,
        widthMaxPixels: 8,
        rounded: true,
        billboard: false,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPath: (d) => {
          if (d.line_path) {
            return getCoordsFromParsedGeometry(d.line_path);
          }
          return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return [133, 143, 163, 255];
        },
        getWidth: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            return this.props?.selectedObject?.type === 'segment' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? 5
              : 3.5;
          }
        },
        opacity: 1,
        onClick: () => {
          this.props.onClick();
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'segments-text' }), {
        data: this.props.relatedData.segments,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d) => {
          return d?.road_name;
        },
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          }
          return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getSize: (d) => {
          if (isTextVisible) {
            if (!this.state?.selectedObject) {
              return 20;
            }
            if (this.state?.routePathes?.includes(d?.route_path_id)) {
              return 20;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
        updateTriggers: {
          getSize: [isTextVisible, this.state?.selectedObject],
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'traffic-light-no-type-icon' }), {
        data: trafficLightNoType,
        iconAtlas: `${process.env.PUBLIC_URL}/img/trafficLight/trafficLightType2.png`,
        iconMapping: trafficLightType2,
        opacity: this?.props?.opacity ?? 0.5,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        billboard: false,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        getAngle: (d) => {
          return d.azimuth ? 90 - d.azimuth : 0;
        },
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
          getSize: this.props?.selectedObject,
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const name = d.to_remove ? 'remove' : 'not_type';

            return DSelector.getSelectableIcon({
              typeCheck: MapObjects.trafficLight,
              id: d.id,
              layerState: this.props,
              selectedMapper: name,
              deselectedMapper: `${name}_select`,
            });
          }
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'traffic-light-with-type-icon' }), {
        data: trafficLightWithType,
        iconAtlas: `${process.env.PUBLIC_URL}/img/trafficLight/trafficLightType1.png`,
        iconMapping: trafficLightType1,
        opacity: this?.props?.opacity ?? 0.5,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        billboard: false,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        getAngle: (d) => {
          return d.azimuth ? 90 - d.azimuth : 0;
        },
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
          getSize: this.props?.selectedObject,
        },
        getIcon: (d) => {
          const name = d.shape;
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            if (d.to_remove) {
              return DSelector.getSelectableIcon({
                id: d.id,
                layerState: this.props,
                typeCheck: MapObjects.trafficLight,
                selectedMapper: `${name}-toremove-active`,
                deselectedMapper: `${name}-toremove`,
              });
            } else {
              return DSelector.getSelectableIcon({
                id: d.id,
                layerState: this.props,
                typeCheck: MapObjects.trafficLight,
                selectedMapper: `${name}-active`,
                deselectedMapper: name,
              });
            }
          }
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'support-pillars-icon' }), {
        data: this.props.relatedData.support_pillars,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'support_pillars' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `support_pillars${additionalParams}`
              : 'support_pillars';
          }
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'support-pillars-text' }), {
        data: this.props.relatedData.support_pillars,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d) => {
          return d?.name;
        },
        getPosition: (d) => {
          if (d.point) {
            let cordinate = getCoordsFromParsedGeometry(d.point);
            return [cordinate[0], cordinate[1] + 0.00002];
          } else return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getSize: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (isTextVisible) {
              if (!this.state?.selectedObject) {
                return 20;
              }
              if (this.state?.routePathes?.includes(d?.route_path_id)) {
                return 20;
              } else {
                return 0;
              }
            } else {
              return 0;
            }
          }
        },
        updateTriggers: {
          getSize: [isTextVisible, this.state?.selectedObject],
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'support-wall-icon' }), {
        data: this.props.relatedData.support_wall,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }

            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'support_wall' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `support_wall${additionalParams}`
              : 'support_wall';
          }
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'road-fences-icon' }), {
        data: this.props.relatedData.road_fences,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
          getSize: this.context?.viewport?.zoom,
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'road_fences' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `road_fences${additionalParams}`
              : 'road_fences';
          }
        },
      }),
      new PathLayer(this.props, this.getSubLayerProps({ id: 'pedestrian_fences-path' }), {
        data: this.props.relatedData.pedestrian_fences,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        widthScale: 2,
        widthMaxPixels: 5,
        rounded: true,
        billboard: false,
        getPath: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (d.line_path) {
              return getCoordsFromParsedGeometry(d.line_path);
            }
          } else return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: (d) => {
          if (d.to_remove) return layerColorsMapper.red;
          return this.props?.selectedObject?.type === 'road_fences' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? [217, 138, 63, 255]
            : [242, 153, 74, 255];
        },
        getWidth: (d) => {
          return this.props?.selectedObject?.type === 'road_fences' &&
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? 2
            : 1;
        },
        opacity: 1,
        onClick: () => {
          this.props.onClick();
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'pedestrian_fences-icon' }), {
        data: this.props.relatedData.pedestrian_fences,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: this.state.selectedObject,
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'road_fences' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `road_fences${additionalParams}`
              : 'road_fences';
          }
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'improvement_elements-icon' }), {
        data: this.props.relatedData.improvement_elements,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects_icon.png`,
        iconMapping: roadObjectsIconMapping,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        updateTriggers: {
          getIcon: this.state.selectedObject,
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            return this.props?.selectedObject?.type === 'improvement_elements' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? 'rest_area_color'
              : 'rest_area_white';
          }
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'improvement_elements-text' }), {
        data: this.props.relatedData.improvement_elements,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d) => {
          return d?.name;
        },
        getPosition: (d) => {
          if (d.point) {
            let cordinate = getCoordsFromParsedGeometry(d.point);
            return [cordinate[0], cordinate[1] + 0.00002];
          } else return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getSize: (d) => {
          if (isTextVisible) {
            if (!this.state?.selectedObject) {
              return 20;
            }
            if (this.state?.routePathes?.includes(d?.route_path_id)) {
              return 20;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
        updateTriggers: {
          getSize: [isTextVisible, this.state?.selectedObject],
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'cabinets-icon' }), {
        data: this.props.relatedData.cabinets,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'cabinets' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `overhead_communications${additionalParams}`
              : 'overhead_communications';
          }
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'cabinets-icon-text' }), {
        data: this.props.relatedData.cabinets,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            return d?.name;
          }
        },
        getPosition: (d) => {
          if (d.point) {
            let cordinate = getCoordsFromParsedGeometry(d.point);
            return [cordinate[0], cordinate[1] + 0.00002];
          } else return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getSize: (d) => {
          if (isTextVisible) {
            if (!this.state?.selectedObject) {
              return 20;
            }
            if (this.state?.routePathes?.includes(d?.route_path_id)) {
              return 20;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
        updateTriggers: {
          getSize: [isTextVisible, this.state?.selectedObject],
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'pipes-point' }), {
        data: this.props.relatedData.pipes,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'pipes' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `pipe${additionalParams}`
              : 'pipe';
          }
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'lighting-point' }), {
        data: this.props.relatedData.lighting,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            if (this.state.data?.instance?.id === d.id) {
              return null;
            }
            const additionalParams = setIconsAdditionalParams(
              this.props?.selectedObject?.active,
              this.props?.selectedObject?.disable,
              this.props?.selectedObject?.danger
            );
            return this.props?.selectedObject?.type === 'lighting' &&
              d.id === this.props?.selectedObject?.selectedObject?.object?.id
              ? `lighting${additionalParams}`
              : 'lighting';
          }
        },
      }),
      roadMarkingPointLayer,
      new PathLayer(this.props, this.getSubLayerProps({ id: 'signal-poles-path' }), {
        data: this.props.relatedData.signal_poles,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        pickable: false,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        widthScale: 2,
        widthMaxPixels: 5,
        rounded: true,
        billboard: false,
        getPath: (d) => {
          if (d.line_path) {
            return getCoordsFromParsedGeometry(d.line_path);
          }
          return [0, 0];
        },
        updateTriggers: {
          getColor: [this.state.selectedObject, this.props.roadColorFromPicker],
          getWidth: this.state.selectedObject,
        },
        getColor: [187, 107, 217, 255],
        getWidth: () => 2,
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'signal-poles' }), {
        pickable: true,
        autoHighlight: true,
        highlightColor: [0, 0, 0, 30],
        data: this.props.relatedData.signal_poles,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        getIcon: (d) =>
          DSelector.getSelectableIcon({
            id: d.id,
            layerState: this.props,
            typeCheck: MapObjects.signalPoles,
            selectedMapper: 'signal_poles_active',
            deselectedMapper: 'signal_poles',
          }),
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
      }),
      new TextLayer(this.props, this.getSubLayerProps({ id: 'signal-poles-text' }), {
        data: this.props.relatedData.signal_poles,
        backgroundColor: [255, 255, 255, 255],
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'bottom',
        pickable: false,
        sdf: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getText: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            return d?.name;
          }
        },
        getPosition: (d) => {
          if (d.point) {
            let cordinate = getCoordsFromParsedGeometry(d.point);
            return [cordinate[0], cordinate[1] + 0.00002];
          } else return [0, 0];
        },
        getColor: () => [47, 128, 237],
        getSize: (d) => {
          if (isTextVisible) {
            if (!this.state?.selectedObject) {
              return 20;
            }
            if (this.state?.routePathes?.includes(d?.route_path_id)) {
              return 20;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        },
        updateTriggers: {
          getSize: [isTextVisible, this.state?.selectedObject],
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'bus-shelters-icon' }), {
        data: this.props.relatedData.bus_shelters,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/busStops.png`,
        iconMapping: publicTransportIconMapper,
        autoHighlight: true,
        pickable: true,
        getPosition: (d) => (d.point ? getCoordsFromParsedGeometry(d.point) : [0, 0]),
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: [this.state.selectedObject, this.state.data?.instance],
        },
        getIcon: (d) => {
          if (d.status === Status981Enum.EXISTS) {
            const selectedObjectId =
              this.props?.selectedObject?.selectedObject?.object?.id || this.props?.selectedObject?.selectedObject?.id;
            const isSelectedObject = d.id === selectedObjectId;
            return isSelectedObject ? 'isSelectedBlue' : 'nonSelectedBlue';
          }
        },
        getPolygonOffset: DSelector.getNewPolygonOffset,
      }),
      new RoadSignGroupLayer({
        roadSignGroups: this.props.relatedData?.road_sign_group,
        sizeScale: this?.props?.sizeScale ?? 1,
        onMarkerAdd: this.props.onMarkerAdd,
        onMarkerClose: this.props.onRemoveMarker,
        activeMarkers: this.props.activeMarkers,
        visible: true,
        handleChangeMarkerSizeMode: this.props.handleChangeMarkerSizeMode,
      }),
      roadMarkingLineLayer,
      ...signsLayers,
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
  // Label for each feature
  getLabel: { type: 'accessor', value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: 'accessor', value: 8 },
  // Label color for each feature
  getLabelColor: { type: 'accessor', value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: 'accessor', value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: 'meters',
  // Label background color
  labelBackground: { type: 'color', value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: 'Monaco, monospace',
};

RoadSchemaCompositeLayer.layerName = Layers.roadsSchema;
RoadSchemaCompositeLayer.defaultProps = defaultProps;

export default RoadSchemaCompositeLayer;
