import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { PublicTransportService } from '../../../../../../../generated/api/api';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class TransportStops extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'bus_stops',
      filterLabel: 'Остановки общественного транспорта',
    });
  }

  async uploadData() {
    return await PublicTransportService.publicTransportBusStopsList();
  }

  async deleteRecord(id) {
    return await PublicTransportService.publicTransportBusStopsDestroy({ id });
  }

  async readRecord(id) {
    return await PublicTransportService.publicTransportBusStopsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.publicTransportBusStops);
  }
}
