import React from 'react';
import Icon from '@ant-design/icons';

const GridPointIconSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20L7 22L12 11L17 22L12 20ZM8 2H16V5H22V7H16V10H8V7H2V5H8V2ZM10 4V8H14V4H10Z" fill="#2F80ED" />
  </svg>
);

export const GridPointIcon = (props) => <Icon component={GridPointIconSVG} {...props} />;
