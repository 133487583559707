import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { Styled } from './style/SelectChartType.styled';
import ChartArcIcon from 'UI/custom/icons/actionIcons/ChartArcIcon';
import ChartLineIcon from 'UI/custom/icons/actionIcons/ChartLineIcon';
import { ChartTypeEnum } from 'generated/api/api';
import { SelectChartProps } from './model/SelectChartType.model';

const { SelectChartTypeStyled, SelectChartTypeWrap, SelectChartTypeButton, SelectedChartName } = Styled;

const SelectChartType: FC<SelectChartProps> = ({ field, description, object }) => {
  const { control } = useFormContext();
  const [value, setValue] = useState();

  useEffect(() => {
    if (!value) {
      const value = object?.[field] ?? description.choices?.[0]?.value;
      setValue(value);
    }
  }, [description.choices, field, object, value]);

  const defaultValue = useMemo(
    () => object?.[field] ?? description.choices?.[0]?.value,
    [description.choices, field, object]
  );

  const content = useMemo(() => {
    return description.choices?.map((el) => {
      switch (el.value) {
        case ChartTypeEnum.PIE: {
          return (
            <SelectChartTypeButton value={ChartTypeEnum.PIE}>
              <ChartArcIcon />
            </SelectChartTypeButton>
          );
        }
        // case ChartTypeEnum.BAR: {
        //   return (
        //     <SelectChartTypeButton value={ChartTypeEnum.BAR}>
        //       <ChartBarIcon />
        //     </SelectChartTypeButton>
        //   );
        // }
        case ChartTypeEnum.LINE_GRAPH: {
          return (
            <SelectChartTypeButton value={ChartTypeEnum.LINE_GRAPH}>
              <ChartLineIcon />
            </SelectChartTypeButton>
          );
        }
        // case ChartTypeEnum.FILLED_LINE_GRAPH: {
        //   return (
        //     <SelectChartTypeButton value={ChartTypeEnum.FILLED_LINE_GRAPH}>
        //       <ChartAreaSplineIcon />
        //     </SelectChartTypeButton>
        //   );
        // }
        default: {
          return null;
        }
      }
    });
  }, [description.choices]);

  const required = useMemo(() => description.required, [description.required]);

  const message = useMemo(() => `${description.label} неверно заполнено`, [description.label]);

  const currentName = (() => {
    const currentChoice = description.choices?.find((el) => el.value === value);
    return currentChoice?.display_name ?? '';
  })();

  const onChange = useCallback((value) => {
    const currentValue = value[0].target.value;

    setValue(currentValue);
    return currentValue;
  }, []);

  return (
    <SelectChartTypeStyled>
      <Controller
        name={field}
        defaultValue={defaultValue}
        control={control}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        onChange={onChange}
        as={<SelectChartTypeWrap>{content}</SelectChartTypeWrap>}
      />
      <SelectedChartName>{currentName}</SelectedChartName>
    </SelectChartTypeStyled>
  );
};

export default SelectChartType;
