import React, { useCallback, useMemo } from 'react';
import LayerCard from './LayerCard';
import { useDispatch } from 'react-redux';
import { getRegisteredLayer } from 'registrators/map/layers/layersRegistrator';
import { initAddLayerToMapSaga } from 'store/sagas/map/addLayerToMapSaga';
import { LayerCardPrototype } from 'registrators/map/layers/description/cards/prototype/LayerCardPrototype';
import { Layers } from 'types/enums/map/layers/Layers';

interface ILayerCardContainer {
  card: LayerCardPrototype;
  name: Layers;
}

export const LayerCardContainer = ({ card, name }: ILayerCardContainer) => {
  const dispatch = useDispatch();

  const layerInstance = useMemo(() => getRegisteredLayer(name), [name]);

  const addNewLayer = useCallback((Layer) => dispatch(initAddLayerToMapSaga(Layer)), [dispatch]);

  const addLayerWithSideEffects = useCallback(() => addNewLayer(layerInstance), [addNewLayer, layerInstance]);

  return <LayerCard card={card} addLayerWithSideEffects={addLayerWithSideEffects} />;
};
