import React, { useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { deleteObject } from '../../../../store/sagas/directory/deleteObjectSaga';
import { Button } from '../../../common/components/Button/Button';
import { DeleteIcon } from '../../icons/actionIcons/DeleteIcon';
import { getLastHistoryRecord } from '../../../../store/reducers/directory/directoryHistory';
import { getLastSelectedObject } from '../../../../store/reducers/directory/selectedObject';
import { RELOAD_HISTORY_INLINE_SAGA } from '../../../../store/sagas/directory/inlineHistorySaga';
import { ButtonType } from '../../../common/components/Button/model/Button.model';

const { confirm } = Modal;

const DeleteButton = ({ selectedObject, hendleDeleteObject, lastInlineHistory, CRUDHandlers }) => {
  const redrawDispatch = useMemo(
    () => ({
      type: RELOAD_HISTORY_INLINE_SAGA,
      payload: {
        lastInlineHistory,
      },
    }),
    [lastInlineHistory]
  );
  const iconSize = useRef({ width: '20px', height: '20px' });
  const urlData = lastInlineHistory?.urlData;
  const id = selectedObject?.id;

  const clickButton = useCallback(() => {
    const performDeletion = () => {
      CRUDHandlers?.deleteSelected ? CRUDHandlers.deleteSelected() : hendleDeleteObject(urlData, id, redrawDispatch);
    };
    confirm({
      title: 'Вы действительно желаете удалить объект',
      icon: <QuestionCircleOutlined />,
      onOk() {
        performDeletion();
      },
    });
  }, [CRUDHandlers, hendleDeleteObject, id, redrawDispatch, urlData]);

  return (
    <Button
      type={ButtonType.dangerDelete}
      hasShadow
      rounded
      icon={<DeleteIcon />}
      onClick={clickButton}
      iconSize={iconSize.current}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    selectedObject: getLastSelectedObject(state),
    lastInlineHistory: getLastHistoryRecord(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hendleDeleteObject: (url, id, redrawDispatch) => dispatch(deleteObject({ url: url, id, redrawDispatch })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteButton);
