import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import PublicTransportCompositeLayer from '../../../../../../layers/CompositeLayers/ekb/PublicTransportCompositeLayer';

export class PublicTransportLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: PublicTransportCompositeLayer,
      id: 'PublicTransport',
    });
  }
}
