import styled from 'styled-components/macro';

const MainWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderTitle = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSettings.defaultLarge};
  padding: 0;
  margin: 0;
`;
const OptionMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.colors.primary};
  width: 100%;
  height: 30px;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 600;

  :hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.defaultLighter};
    cursor: pointer;
  }
`;
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  padding: 0 16px 8px 16px;
  overflow: hidden;
  height: 44px;
  align-items: flex-start;
  transition: all ease 0.3s;
  background-color: ${(props) => props.theme.colors.defaultLighter};
  z-index: 9990;

  :hover {
    height: 90px;
  }
`;
const HeaderItem = styled.div`
  display: flex;
`;

const LocalHeaderNotification = styled.div`
  width: 50px;
  height: 50px;
  padding-left: 15px;
  background: ${(props) => props.theme.colors.defaultLighter};
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  align-items: center;
  & > button:hover {
    & i {
      color: #008291;
    }
  }
`;

export const Styled = {
  HeaderItem,
  UserInfo,
  OptionMenu,
  HeaderTitle,
  MainWrap,
  LocalHeaderNotification,
};
