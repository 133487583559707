import { groupTypes } from '../../contentGroups';
import { LayersPrototype } from '../prototypes/LayersPrototype';
import React from 'react';
import { MapContextMenuItem } from '../../../../../types/enums/map/contextMenu/MapContextMenuItem.model';
import { initializeSingleObjectEditMode } from '../../../../../store/sagas/map/standaloneObjectEditableSaga';
import { MeasureDistanceMode } from '@nebula.gl/edit-modes';
import { setRulerMode } from '../../../../../store/reducers/map/actions/mapPanelsActions';
import { Layers } from '../../../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../../../types/enums/map/MapObjects.model';
import { RulerIcon } from '../../../../../UI/custom/icons/objectIcons/RulerIcon';
import { PlusSegmentIcon } from '../../../../../UI/custom/icons/actionIcons/PlusSegmentIcon';
import { RestAreasIcon } from '../../../../../UI/custom/icons/contextMenuIcons/RestAreasIcon';

const { ACTION_GROUP, ADD_GROUP } = groupTypes;

export class RoadSchema extends LayersPrototype {
  constructor() {
    super(Layers.roadsSchema);
  }

  actionItems = [
    {
      name: MapContextMenuItem.createSegment,
      path: 'Создать участок дороги',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.roadSegment, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
    {
      name: MapContextMenuItem.supportPillars,
      path: 'Создать опорный столб',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.supportPillars, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
    {
      name: MapContextMenuItem.contoursLawn,
      path: 'Контуры газонов',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.contoursLawn, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
    {
      name: MapContextMenuItem.townSquares,
      path: 'Площади',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.townSquares, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
    {
      name: MapContextMenuItem.cabinets,
      path: 'Создать шкафы кабельных коммуникаций',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.cabinets, this.layerName, null, ADD_GROUP),
      icon: <PlusSegmentIcon />,
    },
    {
      name: MapContextMenuItem.improvementElements,
      path: 'Создать элементы благоустройства',
      group: ADD_GROUP,
      dispatchedAction: () =>
        initializeSingleObjectEditMode(null, MapObjects.improvementElements, this.layerName, null, ADD_GROUP),
      icon: <RestAreasIcon />,
    },
    {
      name: MapContextMenuItem.rulerLength,
      path: 'Линейка',
      group: ACTION_GROUP,
      dispatchedAction: () => setRulerMode(MeasureDistanceMode),
      icon: <RulerIcon />,
    },
  ];
}
