import styled from 'styled-components';
import { Table } from 'antd';

const StyledTable = styled(Table)`
  height: 100%;

  & .ant-spin-nested-loading {
    height: 100%;
  }

  & .ant-spin-container {
    height: 100%;
  }

  & .ant-table {
    height: 100%;
  }

  & .ant-table-content {
    height: 100%;
  }

  & .ant-table-scroll {
    height: 100%;
    overflow: hidden;
  }

  & .ant-table-header {
    background: transparent;

    & th {
      background: transparent;
      border: none;
      padding: 12px 8px 10px;
      font-weight: 600;
      font-size: 13px;
      line-height: 140%;
      color: #1a2138;
    }
  }

  & .ant-table-tbody {
    & td {
      border: none;
      padding: 12px 8px 10px;
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
      color: #6a7b9b;
    }

    & .ant-table-row {
      &:hover td {
        background: rgba(47, 128, 237, 0.08);
        color: #2f80ed;

        &:first-of-type {
          border-radius: 8px 0 0 8px;
        }

        &:last-of-type {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
`;

export const Styled = {
  StyledTable,
};
