import { LayerDataAccessorPrototype } from '../prototype/LayerDataAccessorPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { TransportDetectorsService } from '../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class CamerasAndDetectorsDataAccessor extends LayerDataAccessorPrototype {
  // отвечает за объект который выбирается в дата ацессоре, так как он общий для экземпляров класса то сделаем его статичным, чтобы не создавать лишние экземпляры
  static selectedType = LayerDataAccessorPrototype.selectedType;
  actionButtons = [];

  constructor() {
    super({
      label: 'Камеры и детекторы',
      selectedType: MapObjects.camerasAndDetectors,
    });
  }
  //получение данных для грид таблицы
  async getData() {
    return await TransportDetectorsService.transportDetectorsList();
  }

  // по запросу по модели вернем нашу схему. Await нужен для согласованности then, можно и в промис обернуть, но лень)
  async getModel() {
    return await getFromURL.getModelFromURL(RequestPath.transportDetectors);
  }

  getSelectedObjectType() {
    return MapObjects.camerasAndDetectors;
  }
}
