import React from 'react';
import ObjectPanel from './ObjectPanel';

const ObjectPanelContainer = (props) => {
  const {
    model,
    data,
    goToInline,
    initClose,
    initSubmitForm,
    handleSubmitForm,
    referenceToForm,
    lastInlineFieldName,
    className,
    ...otherProps
  } = props;
  return (
    <>
      {model && (
        <ObjectPanel
          className={`${className}`}
          {...otherProps}
          handleSubmitForm={handleSubmitForm}
          initSubmitForm={initSubmitForm}
          initClose={initClose}
          goToInline={goToInline}
          visiblePanel={true}
          model={model}
          data={data}
          ref={referenceToForm}
          lastInlineFieldName={lastInlineFieldName}
        />
      )}
      {/*{modified && <Modal*/}
      {/*    centered*/}
      {/*    title={t('app.detailedObject.attentionTitle')}*/}
      {/*    visible={modified}*/}
      {/*    onCancel={() => setModified(false)}*/}
      {/*    onOk={closeCurrent}*/}
      {/*    cancelText={t('app.detailedObject.cancel')}*/}
      {/*    okText={t('app.detailedObject.quit')}*/}
      {/*>*/}
      {/*    <p>{t('app.detailedObject.attentionQuitDisclaimer')}</p>*/}
      {/*</Modal>}*/}
    </>
  );
};

export default ObjectPanelContainer;
