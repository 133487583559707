import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';
import { RoadConstructionsService } from '../../../../../../../generated/api/api';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RequestPath } from '../../../../../../../types/enums/routes/request/RequestPath';

export class PedBorderProject extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'ped_border_project',
      filterLabel: 'Пешеходные ограждения',
      selectedObjectName: MapObjects.projectLayerPedBorder,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsPedBorderProjectList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsPedBorderProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsPedBorderProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.pedBorderProjection);
  }
}
