import { getFromURL } from 'api/getFromURL';
import { RoadConstructionsService } from 'generated/api/api';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { RequestPath } from 'types/enums/routes/request/RequestPath';
import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';

export class BusShelters extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'bus_shelters',
      filterLabel: 'Автобусные остановки',
      selectedObjectName: MapObjects.busShelters,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsBusSheltersList();
  }

  async deleteRecord(id: number) {
    return await RoadConstructionsService.roadConstructionsBusSheltersDestroy({ id });
  }

  async readRecord(id: number) {
    return await RoadConstructionsService.roadConstructionsBusSheltersRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadConstructionsBusShelters);
  }
}
