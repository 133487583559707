import styled from 'styled-components/macro';

const MSFullHeightWrap = styled.section`
  height: 100%;
`;

export const Styled = {
  MSFullHeightWrap,
};

export { Styled as MainStyled };
