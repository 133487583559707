import React, { FC, useMemo } from 'react';

import { SearchIcon } from 'UI/custom/icons/actionIcons/SearchIcon';
import { ColumnIcon } from 'UI/custom/icons/objectIcons/ColumnIcon';
import { FilterIcon } from 'UI/custom/icons/actionIcons/FilterIcon';
import { FilterRemoveIcon } from 'UI/custom/icons/actionIcons/FilterRemoveIcon';
import { MapMinusIcon } from 'UI/custom/icons/actionIcons/MapMinusIcon';
import { MapPlusIcon } from 'UI/custom/icons/actionIcons/MapPlusIcon';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { CompendiumColumnActionPanelProps } from 'UI/custom/Aggrid/Compendium/model/CompendiumColumnActionPanel.model';
import { Styled } from './CompendiumActionPanel.styled';

const { ActionPanelWrapper, ActionPanelButton, SearchInput } = Styled;

const CompendiumColumnActionPanel: FC<CompendiumColumnActionPanelProps> = ({
  quickMenuState,
  toggleShowQuickFilter,
  toggleShowRowsMenu,
  toggleShowFilterMenu,
  iconSizeType = 'default',
  clearAgGridFilters,
  toggleFilterData,
  toggleClearFilterData,
  onExternalFilterChanged,
}) => {
  const localIconSizedKit = useMemo(
    () => ({
      large: { width: '24px', height: '24px' },
      default: { width: '20px', height: '20px' },
      small: { width: '16px', height: '16px' },
    }),
    []
  );

  const rowsMenuIcon = useMemo(
    () => (quickMenuState.group ? ButtonType.primary : ButtonType.primaryTranslucent),
    [quickMenuState.group]
  );
  const filterIcon = useMemo(
    () => (quickMenuState.filters ? ButtonType.primary : ButtonType.primaryTranslucent),
    [quickMenuState.filters]
  );

  return (
    <ActionPanelWrapper>
      {onExternalFilterChanged && (
        <SearchInput
          onChange={(event: any) => onExternalFilterChanged(event)}
          placeholder="Сквозной поиск"
          size="small"
        />
      )}
      {toggleShowQuickFilter && (
        <ActionPanelButton
          tooltip="Поиск по адресу"
          className={`${quickMenuState.search ? 'active' : ''}`}
          active={quickMenuState.search}
          iconSize={localIconSizedKit[iconSizeType]}
          rounded
          icon={<SearchIcon />}
          type={'unbounded'}
          onClick={toggleShowQuickFilter}
        />
      )}
      {toggleShowRowsMenu && (
        <ActionPanelButton
          iconSize={localIconSizedKit[iconSizeType]}
          icon={<ColumnIcon />}
          type={rowsMenuIcon}
          onClick={toggleShowRowsMenu}
        />
      )}
      <>
        {toggleShowFilterMenu && (
          <>
            {toggleFilterData && (
              <ActionPanelButton
                iconSize={localIconSizedKit[iconSizeType]}
                icon={<MapPlusIcon />}
                type={ButtonType.primaryTranslucent}
                onClick={toggleFilterData}
                tooltip={'Применение фильтрации на карте'}
              />
            )}
            {toggleClearFilterData && (
              <ActionPanelButton
                iconSize={localIconSizedKit[iconSizeType]}
                icon={<MapMinusIcon />}
                type={ButtonType.dangerReverseTranslucent}
                onClick={toggleClearFilterData}
                tooltip={'Отмена фильтрация на карте'}
              />
            )}
            <ActionPanelButton
              iconSize={localIconSizedKit[iconSizeType]}
              icon={<FilterIcon />}
              type={filterIcon}
              onClick={toggleShowFilterMenu}
            />
            <ActionPanelButton
              active={quickMenuState.filters}
              iconSize={localIconSizedKit[iconSizeType]}
              icon={<FilterRemoveIcon />}
              type={ButtonType.dangerReverseTranslucent}
              onClick={clearAgGridFilters}
            />
          </>
        )}
      </>
    </ActionPanelWrapper>
  );
};

export default CompendiumColumnActionPanel;
