import React, { Dispatch, PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { StateModel } from 'store/reducers';
import { directoryHistoryActions } from 'store/reducers/directory/actions/directoryHistoryActions';
import { DirectoryHistory } from 'types/interfaces/roles/directoryHistory.model';

import PathWatcher from './PathWatcher';
import { PathWatcherContainerProps } from './PathWatcher.model';

const { postToInlineHistory } = directoryHistoryActions;

const PathWatcherContainer = React.memo((props: PropsWithChildren<PathWatcherContainerProps>) => {
  const { inlineHistory, pushInlineHistory } = props;
  const location = useLocation();
  return <PathWatcher location={location} inlineHistory={inlineHistory} pushInlineHistory={pushInlineHistory} />;
});

const mapStateToProps = (state: StateModel) => {
  return {
    inlineHistory: state.directoryHistory,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    pushInlineHistory: (historyRecord: DirectoryHistory) => dispatch(postToInlineHistory(historyRecord)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PathWatcherContainer);
