import { LayerGroupsPrototype } from './prototype/LayerGroupsPrototype';
import { LayerGroupLabel, LayerGroupName, LayerGroupLabelDecryption } from 'types/enums/map/layers/LayerGroupsEnums';

export class DefaultLayerGroup extends LayerGroupsPrototype {
  constructor() {
    super({
      label: LayerGroupLabel.others,
      name: LayerGroupName.others,
      decryption: LayerGroupLabelDecryption.others,
    });
  }
}
