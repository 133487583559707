import React from 'react';
import BooleanInput from 'UI/common/form/inputs/BooleanInput.jsx';
import StringInput from 'UI/common/form/inputs/StringInput.jsx';
import IntegerInput from 'UI/common/form/inputs/IntegerInput.jsx';
import DateTimeInput from 'UI/common/form/inputs/DateTimeInput.jsx';
import CustomMaskedInput from 'UI/common/form/inputs/CustomMaskedInput.jsx';
import TimeInput from 'UI/common/form/inputs/TimeInput.jsx';
import ChoiceInput from 'UI/common/form/inputs/ChoiceInput.jsx';
import CreatableRelatedFieldInput from 'UI/common/form/inputs/CreatableRelatedFieldInput.jsx';
import INNValidator from 'UI/common/form/validators/INNValidator';
import KPPValidator from 'UI/common/form/validators/KPPValidator';
import OGRNValidator from 'UI/common/form/validators/OGRNValidator';
import SNILSValidator from 'UI/common/form/validators/SNILSValidator';
import PhoneValidator from 'UI/common/form/validators/PhoneValidator';
import ColorPickerInput from 'UI/common/form/inputs/ColorPickerInput/ColorPickerInput';
import SliderInput from 'UI/common/form/inputs/SliderInput/SliderInput';
import ModalMapInput from 'UI/common/form/inputs/ModalMapInput/ModalMapInput';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { AnyObject } from 'types/enums/general/general.model.js';
import { IFormProps } from 'components/Application/ExtraModules/Applications/ApplicationsForm/interfacesApplications/interfacesApplications.js';
import { FormGeneratedField } from 'types/interfaces/forms/FormGeneratedField.model';
import { Styled } from './styled/provideInput.styled';
import RelatedFieldInput from 'UI/common/form/inputs/RelatedFieldInput';
import ReferenceFieldInput from 'UI/common/form/inputs/ReferenceFieldInput';
import ObjectCharacteristicInput from 'UI/common/form/inputs/fields/ObjectCharacteristicInput/ObjectCharacteristicInput';
import RoadwayFieldInput from '../../../UI/common/form/inputs/RoadwayFieldInput';

const { StyledWrapper } = Styled;

/**
 *
 * @param object single instance of object. For initial values
 * @param description description of field. Main ones are .type and .help_text on which fields are created
 * @param field key of field
 * @param formProps link to Antd.Form
 * @returns {React.Component} must provide props description={description} object={object} field={field} formProps={formProps}
 */

export const provideInput = (
  field: string,
  formProps: IFormProps,
  object?: AnyObject,
  description?: FormGeneratedField
) => {
  switch (description?.type) {
    case SchemaFieldType.datetime: {
      return (
        <StyledWrapper>
          <DateTimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.date: {
      return (
        <StyledWrapper>
          <DateTimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.time: {
      return (
        <StyledWrapper>
          <TimeInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.integer: {
      if (field === 'id') return null;
      switch (description.help_text) {
        case SchemaFieldType.hidden:
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={1}
                object={object}
                field={field}
                formProps={formProps}
                className={'hidden'}
              />
            </StyledWrapper>
          );
        default:
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={1}
                object={object}
                field={field}
                formProps={formProps}
              />
            </StyledWrapper>
          );
      }
    }
    case SchemaFieldType.float: {
      if (field === 'azimuth') {
        return (
          <StyledWrapper>
            <SliderInput
              key={`input-${field}`}
              description={description}
              min={0}
              max={360}
              step={1}
              field={field}
              object={object}
              formProps={formProps}
            />
          </StyledWrapper>
        );
      }

      switch (description.help_text) {
        case SchemaFieldType.hidden:
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={0.1}
                object={object}
                field={field}
                formProps={formProps}
                className={'hidden'}
              />
            </StyledWrapper>
          );
        default:
          return (
            <StyledWrapper>
              <IntegerInput
                key={`input-${field}`}
                description={description}
                step={1}
                object={object}
                field={field}
                formProps={formProps}
              />
            </StyledWrapper>
          );
      }
    }
    case SchemaFieldType.snils: {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="000 000 000 00"
            validator={SNILSValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.phone: {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="+0(000)000-00-00"
            validator={PhoneValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.rgbaColor: {
      return (
        <StyledWrapper>
          <ColorPickerInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.string: {
      if (field === 'username') {
        // return <UsernameInput key={`input-${field}`} description={description} object={object} field={field} formProps={formProps}/>
        return (
          <StyledWrapper>
            <StringInput
              key={`input-${field}`}
              description={description}
              object={object}
              field={field}
              formProps={formProps}
            />
          </StyledWrapper>
        );
      } else {
        return (
          <StyledWrapper>
            <StringInput
              key={`input-${field}`}
              description={description}
              object={object}
              field={field}
              formProps={formProps}
            />
          </StyledWrapper>
        );
      }
    }
    case SchemaFieldType.url: {
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.email: {
      // return <EmailInput key={`input-${field}`} description={description} object={object} field={field} formProps={formProps}/>
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.transportRegistrationNumber: {
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.year: {
      return (
        <StyledWrapper>
          <StringInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.boolean: {
      return (
        <StyledWrapper>
          <BooleanInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.inn: {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="000000000000"
            validator={INNValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.kpp: {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="0000-00-000"
            validator={KPPValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.ogrn: {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="0-00-00-0000000-0"
            validator={OGRNValidator}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.okpo: {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="00000000"
            validator={() => true}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.okved: {
      return (
        <StyledWrapper>
          <CustomMaskedInput
            mask="000000"
            validator={() => true}
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.field: {
      if (field.includes('_detail')) return null;
      if (field.includes('_name')) return null;
      return null;
    }
    case SchemaFieldType.choice: {
      return (
        <StyledWrapper>
          <ChoiceInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.relatedField:
    case SchemaFieldType.multiRelatedField: {
      if (field === 'roadway') {
        return (
          <StyledWrapper>
            <RoadwayFieldInput key={`input-${field}`} description={description} object={object} field={field} />
          </StyledWrapper>
        );
      } else {
        return (
          <StyledWrapper>
            <RelatedFieldInput key={`input-${field}`} description={description} object={object} field={field} />
          </StyledWrapper>
        );
      }
    }
    case SchemaFieldType.relatedReferenceField:
    case SchemaFieldType.multiRelatedReferenceField: {
      if (field === 'object_characteristic') {
        return (
          <StyledWrapper>
            <ObjectCharacteristicInput field={field} description={description} object={object} />
          </StyledWrapper>
        );
      } else {
        return (
          <StyledWrapper>
            <ReferenceFieldInput field={field} description={description} object={object} />
          </StyledWrapper>
        );
      }
    }
    case SchemaFieldType.creatableRelatedField: {
      return (
        <StyledWrapper>
          <CreatableRelatedFieldInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    // TODO названия полей скорректировать после выхода схемы бека Тех-долг-FRONTEND #5806
    case SchemaFieldType.polygon:
    case SchemaFieldType.lineString:
    case SchemaFieldType.point: {
      return (
        <StyledWrapper>
          <ModalMapInput
            key={`input-${field}`}
            description={description}
            object={object}
            field={field}
            formProps={formProps}
          />
        </StyledWrapper>
      );
    }
    case SchemaFieldType.inline: {
      return null;
    }
    default: {
      console.warn(`${field} AS TYPE ${description?.type} >>> doesnt have renderer and is ignored`);
      return null;
    }
  }
};
