import React from 'react';
import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../store';
import storeProvider from '../store/storeProvider';
import { ApplicationContainer } from './Application/Application.container';
import GuestPage from './GuestPage/GuestPage';
import Page404 from './Page404/Page404';

import { initAxios } from '../services/axiosInstances/axiosSecured';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Print from './Print/Print';
// @ts-ignore
import { ThroughProvider } from 'react-through';
import { ITSThemeProvider } from '@frontend-packages/its-ui-kit';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles/themes/rootTheme';

/**
 * Базовый роутинг приложения
 * Подключает один из модулей приложения, а также сожержит редиректы на main и guest
 * @return ApplicationContainer || GuestPage || Page404
 * @see ApplicationContainer
 * @see GuestPage
 * @see Page404
 */
const App = () => {
  return (
    <div className="App" onContextMenu={(event) => event.preventDefault()}>
      <Switch>
        <Route path={`/app/:mainPage?`} component={ApplicationContainer} />
        <Route path="/404" component={Page404} />
        <Route path="/print" component={Print} />
        <Route path="/guest" component={GuestPage} />
        <Redirect exact from="/password_recovery/:uid/:token" to={'/guest/password_recovery/:uid/:token'} />
        <Redirect exact from={'/'} to={'/guest'} />
        <Redirect exact from={''} to={'/guest'} />
      </Switch>
    </div>
  );
};

/**
 * Подключение глобальных провайдеров к роутеру
 * @see App
 */
const AppEntryPoint = () => {
  storeProvider.init(store);
  initAxios();
  return (
    // Redux
    <Provider store={store}>
      {/*React-Router*/}
      <BrowserRouter>
        {/*React through for breadcrumbs*/}
        <ThroughProvider>
          {/*Styled components styles and themes */}
          <ITSThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              {/*Drag and drop provider*/}
              {
                // @ts-ignore
                <DndProvider backend={HTML5Backend}>
                  <App />
                </DndProvider>
              }
            </ThemeProvider>
          </ITSThemeProvider>
        </ThroughProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default AppEntryPoint;
