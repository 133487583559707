import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import { Styled } from './style/BrigadeApplicationsChat.styled';
import ScrollableCard from 'UI/common/components/ScrollableCard/ScrollableCard';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { UploadFileIcon } from 'UI/custom/icons/actionIcons/UploadFileIcon';
import { iconSizesKit } from 'styles/utils/iconSizesKit';
import { BrigadeApplicationsChatProps } from './model/BrigadeApplicationsChat.model';
import { IWsMessage } from 'layers/SelectedObjectsRepresentations/BrigadeApplicationsCard';
import { useCallback } from 'react';
import { ChatMessageStatusEnum } from 'types/enums/map/DUTCCD/ChatMessageStatusEnum';
import { TMCUploadModal } from 'UI/custom/actionsButtons/bkd/directory/UploadTMCFileModal';
import { AnyObject } from 'types/enums/general/general.model';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const {
  ChatWrap,
  ChatMessage,
  ChatMessageTitle,
  ChatMessageText,
  ChatMessageStatus,
  ChatMessagePhoto,
  TextAreaContainer,
  FooterChatButtonWrap,
  FooterButtonWrap,
  ChatPhotoModal,
} = Styled;

const BrigadeApplicationsChat = ({
  headerStyles,
  headerContent,
  contentStyles,
  footerStyles,
  wsChannel,
  messages,
  disabledButtons,
  wsChannelUrl,
  application_id,
}: BrigadeApplicationsChatProps) => {
  const [value, setValue] = useState<string>('');
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [modalPhoto, setModalPhoto] = useState<string>('');

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
    localStorage.setItem(wsChannelUrl, `${messages.length}`);
  }, [messages, wsChannelUrl]);

  const handleCancel = useCallback(() => {
    setModalPhoto('')
  }, []);

  const userName: string = localStorage.getItem('user')!;

  const content = useMemo(
    () => (
      <ChatWrap>
        {messages.map((message: IWsMessage) => {
          const { message_type, user_data, message: messageText, datetime, media } = message;
          const { first_name, is_dispatcher, last_name, username: dataServerUserName } = user_data ?? {};
          const { image } = media ?? {};
          let className;
          let title;
          let photo;
          if (message_type === ChatMessageStatusEnum.ERROR_MESSAGE) {
            className = 'error_message';
            title = 'Ошибка';
          } else if (message_type === ChatMessageStatusEnum.SYSTEM_MESSAGE) {
            className = 'system_message';
            title = 'Системное сообщение';
          } else {
            if (image) {
              photo = <ChatMessagePhoto src={image} alt={messageText} onClick={() => setModalPhoto(image)}/>;
            }
            const userMessage = dataServerUserName === userName;
            className = userMessage ? 'yours' : 'strange';
            title = userMessage ? 'Вы' : `${is_dispatcher ? 'Диспетчер' : ''} ${first_name}  ${last_name}`;
          }
          return (
            <ChatMessage key={`${first_name}_${datetime}`} className={className}>
              <ChatMessageTitle>{title}</ChatMessageTitle>
              <ChatMessageText>{messageText}</ChatMessageText>
              {photo}
              {datetime && <ChatMessageStatus>{datetime}</ChatMessageStatus>}
            </ChatMessage>
          );
        })}
        <div ref={messagesEndRef} />
        <ChatPhotoModal visible={!!modalPhoto} width={'max-content'} footer={null} onCancel={handleCancel}>
          <img src={modalPhoto} alt='' />
        </ChatPhotoModal>
      </ChatWrap>
    ),
    [handleCancel, messages, modalPhoto, userName]
  );

  const changeTextValue = useCallback(
    (e) => {
      if (!value && e.target.value === '\n') return;
      setValue(e.target.value);
    },
    [value]
  );

  const sendMessage = useCallback(() => {
    if (!value) return;
    const sentMessage = JSON.stringify({ message: value });
    wsChannel?.send(sentMessage);
    setValue('');
  }, [value, wsChannel]);

  const onPressEnter = useCallback(
    (e) => {
      if (!e.shiftKey && value.replace(/\n/g, '')) {
        sendMessage();
      }
      e.stopPropagation();
    },
    [sendMessage, value]
  );

  const onOpenModal = useCallback(() => {
    if (!value) {
      confirm({
        title: 'Вы не написали сообщение к изображению, для продолжения отправки нажмите OK',
        icon: <QuestionCircleOutlined />,
        onOk() {
          setOpenModal(true);
        },
      });
    } else setOpenModal(true);
  }, [value]);

  const sentPhoto = useCallback(
    (data: AnyObject) => {
      const { photo } = data;
      if (!photo) return;
      const sentMessage = JSON.stringify({
        message: value,
        media: { image: photo },
      });
      wsChannel?.send(sentMessage);
      setValue('');
    },
    [value, wsChannel]
  );

  const footerContent = useMemo(
    () => (
      <FooterButtonWrap>
        <TextAreaContainer
          placeholder={'Введите сообщение...'}
          onChange={changeTextValue}
          value={value}
          onPressEnter={onPressEnter}
        />
        <FooterChatButtonWrap>
          <Button type={ButtonType.primaryLC} onClick={sendMessage} disabled={disabledButtons}>
            Отправить
          </Button>
          <Button
            type={ButtonType.primaryReverse}
            icon={<UploadFileIcon />}
            iconSize={iconSizesKit.huge}
            disabled={disabledButtons}
            onClick={onOpenModal}
          />
          <TMCUploadModal
            isOpenModal={isOpenModal}
            setOpenModal={setOpenModal}
            pageMenuKey={'/applications/'}
            id={`${application_id}`}
            refreshData={sentPhoto}
          />
        </FooterChatButtonWrap>
      </FooterButtonWrap>
    ),
    [
      application_id,
      changeTextValue,
      disabledButtons,
      isOpenModal,
      onOpenModal,
      onPressEnter,
      sendMessage,
      sentPhoto,
      value,
    ]
  );

  return (
    <ScrollableCard
      headerStyles={headerStyles}
      headerContent={headerContent}
      contentStyles={contentStyles}
      children={content}
      footerStyles={footerStyles}
      footerContent={footerContent}
    />
  );
};

export default BrigadeApplicationsChat;
