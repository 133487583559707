import React, { useMemo } from 'react';

import { SizeMixinProps } from 'styles/styleMixins/model/SizeMixin.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import CustomAlert from 'UI/common/components/Alert/Alert';
import GlobalLoader from 'UI/common/components/GlobalLoader/GlobalLoader';
import PromptNotifications from 'UI/common/components/PromptNotifications/PromptNotification';

import { ApplicationUtilsProps } from './model/ApplicationUtils.model';

/**
 * Служебная компонента (уведомления и неактивность пользователя)
 */
export const ApplicationUtils = (props: ApplicationUtilsProps) => {
  const { isFetching, alertData, clearGlobalAlertData, promptData } = props;

  const customAlertSize = useMemo(() => ({ width: '400px' } as SizeMixinProps), []);

  return (
    <>
      <GlobalLoader show={isFetching} />
      <CustomAlert
        portalSide="bottom-right"
        isOpen={alertData.status === ActiveStatus.active}
        type={alertData.type}
        title={alertData.title}
        message={alertData.message}
        size={customAlertSize}
        onClose={clearGlobalAlertData}
      />
      <PromptNotifications
        portalSide="top-center"
        promptData={promptData}
        message={promptData.message}
        isOpen={promptData.status === ActiveStatus.active}
      />
    </>
  );
};
