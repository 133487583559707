import React from 'react';

const PropsPasser = ({ component, ...other }) => {
  if (component) {
    return <>{React.cloneElement(component, other)}</>;
  } else {
    return null;
  }
};

export default PropsPasser;
