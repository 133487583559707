import React from 'react';
import Icon from '@ant-design/icons';

const FilterIconSVG = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9999 19.88C15.0399 20.18 14.9399 20.5 14.7099 20.71C14.6174 20.8027 14.5075 20.8762 14.3865 20.9264C14.2655 20.9766 14.1359 21.0024 14.0049 21.0024C13.8739 21.0024 13.7442 20.9766 13.6233 20.9264C13.5023 20.8762 13.3924 20.8027 13.2999 20.71L9.28989 16.7C9.18082 16.5934 9.09789 16.463 9.04759 16.319C8.99728 16.175 8.98095 16.0213 8.99989 15.87V10.75L4.20989 4.62C4.0475 4.41153 3.97422 4.14726 4.00607 3.88493C4.03793 3.6226 4.17232 3.38355 4.37989 3.22C4.56989 3.08 4.77989 3 4.99989 3H18.9999C19.2199 3 19.4299 3.08 19.6199 3.22C19.8275 3.38355 19.9618 3.6226 19.9937 3.88493C20.0256 4.14726 19.9523 4.41153 19.7899 4.62L14.9999 10.75V19.88ZM7.03989 5L10.9999 10.06V15.58L12.9999 17.58V10.05L16.9599 5H7.03989Z'
        fill='currentColor' />
    </svg>
  )
;

export const FilterIcon = (props) => <Icon component={FilterIconSVG} {...props} />;
