import React, { FC, useState } from 'react';
import {
  DefectBbobx,
  IDefectText,
  useMonitoringDefectImage,
} from '../../../../../../hooks/custom/useMonitoringDefectImage';
import { SWrap, SInnerWrap, SModal, SModalContentWrap, SImage } from './style/DefectModal.styled';

export interface IDefectModalProps {
  image: string;
  defectName: string;
  bbox?: DefectBbobx;
  defectText: IDefectText;
}

export const DefectModal: FC<IDefectModalProps> = ({ image, defectName, bbox, defectText }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [canvasImage, setCanvasImage] = useState<string>();

  useMonitoringDefectImage({
    image,
    bbox,
    text: defectText,
    onReady: setCanvasImage,
  });

  return (
    <>
      <SWrap>
        <SInnerWrap image={image} onClick={() => setIsShowModal(true)} />
      </SWrap>
      <SModal visible={isShowModal} width={'82%'} header={defectName} onCancel={() => setIsShowModal(false)}>
        <SModalContentWrap>
          <SImage src={canvasImage} alt="Изображение дефекта" />
        </SModalContentWrap>
      </SModal>
    </>
  );
};
