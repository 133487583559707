export const sortOneArrayByAnother = <T extends { [key: string]: string | any }[]>(
  array: T,
  orderArray: string[],
  sortingKey: string
): T => {
  array.sort((a, b) => {
    const A = a[sortingKey];
    const B = b[sortingKey];
    return orderArray.indexOf(A) > orderArray.indexOf(B) ? 1 : -1;
  });
  return array;
};
