import React, { PropsWithChildren } from 'react';
import { Styled } from './style/ScrollableCard.styled';
import { ScrollableCardProps } from './model/ScrollableCard.model';

const { ExtendedPanelFooter, ExtendedPanelWrapper, ExtendedPanelHeader, ExtendedPanelContent } = Styled;

const ScrollableCard = (props: PropsWithChildren<ScrollableCardProps>) => {
  const {
    headerContent,
    footerContent,
    headerStyles = {},
    footerStyles = {},
    contentStyles = {},
    wrapperStyles = {},
    children,
  } = props;

  return (
    <ExtendedPanelWrapper style={wrapperStyles}>
      {headerContent && <ExtendedPanelHeader style={headerStyles}>{headerContent}</ExtendedPanelHeader>}
      <ExtendedPanelContent style={contentStyles}>{children}</ExtendedPanelContent>
      {footerContent && <ExtendedPanelFooter style={footerStyles}>{footerContent}</ExtendedPanelFooter>}
    </ExtendedPanelWrapper>
  );
};

export default ScrollableCard;
