import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, PathLayer, TextLayer } from '@deck.gl/layers';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../../types/enums/map/MapObjects.model';
import { getCoordsFromParsedGeometry } from '../../../../services/map/getCoordsFromParsedGeometry';
import { roadObjectsIconMapping } from '../../../iconMappers/roadObjectsIconMapping';
import { calculateLinePathCenterCoords } from '../../../../services/map/calculateLinePathCenterCoords';
import { CTG_IconMapping } from '../../../iconMappers/CTG_hw_IconMapping';
import { TransportOrdersDataAccessor } from '../../../../registrators/map/layers/description/dataAccessors/ekb/TransportOrdersDataAccessor';
import MapEventObserver from '../../../../store/rakes/MapEventObserver';
import { DSelector } from 'services/map/Dselector/DSelector';
import { Settings } from '../../../../types/enums/map/layers/Settings';

class TransportOrdersCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    if (
      props?.selectedObject?.type === 'axle_load_overloads' ||
      props?.selectedObject?.type === 'entity_statements_axle_load_overloads'
    ) {
      this.setState({
        data: props.relatedData ?? [],
        selectedID: props.selectedObject.data?.[0]?.data?.statement ?? null,
        selectedObject: props?.selectedObject?.selectedObject,
        selectedObjectType: props?.selectedObject?.type,
      });
    } else if (
      props?.selectedObject?.type === TransportOrdersDataAccessor.selectedType ||
      props?.selectedObject?.type === 'dimensions_height_oversizes' ||
      props?.selectedObject?.type === 'dimensions_width_oversizes' ||
      props?.selectedObject?.type === 'general_dimensions_height_width_oversizes' ||
      props?.selectedObject?.type === 'entity_statements_dimensions_height_oversizes' ||
      props?.selectedObject?.type === 'entity_statements_dimensions_width_oversizes' ||
      props?.selectedObject?.type === 'entity_statements_general_dimensions_height_width_oversizes'
    ) {
      if (props.selectedObject.selectedObject?.object?.statement) {
        this.setState({
          data: props.relatedData ?? [],
          selectedID: props.selectedObject.selectedObject.object.statement ?? null,
          selectedObject: props?.selectedObject?.selectedObject,
          selectedObjectType: props?.selectedObject?.type,
        });
      } else if (props.selectedObject.data?.[0]?.data?.[0]?.statement) {
        this.setState({
          data: props.relatedData ?? [],
          selectedID: props.selectedObject.data[0].data[0].statement ?? null,
          selectedObject: props?.selectedObject?.selectedObject,
          selectedObjectType: props?.selectedObject?.type,
        });
      } else if (props.selectedObject.selectedObject?.id) {
        this.setState({
          data: props.relatedData ?? [],
          selectedID: props.selectedObject.selectedObject?.id ?? null,
          selectedObject: props?.selectedObject?.selectedObject,
          selectedObjectType: props?.selectedObject?.type,
        });
      }
    } else {
      this.setState({
        selectedID: null,
        selectedObject: props?.selectedObject?.selectedObject,
        selectedObjectType: props?.selectedObject?.type,
      });
    }
  }

  getPickingInfo(event) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickHandler) {
        let selectedObjectType = null;
        let selectedObjectLayerName = Layers.transportOrders;
        if (event.sourceLayer.id.includes('order-route-path'))
          selectedObjectType = TransportOrdersDataAccessor.selectedType;
        if (TransportOrdersDataAccessor.selectedType === MapObjects.individualStatements) {
          if (event.sourceLayer.id.includes('axle-load-overloads')) selectedObjectType = MapObjects.axleLoadOverloads;
          if (event.sourceLayer.id.includes('dimensions-height-oversize'))
            selectedObjectType = MapObjects.dimensionsHeightOversizes;
          if (event.sourceLayer.id.includes('dimensions-width-oversizes'))
            selectedObjectType = MapObjects.dimensionsWidthOversizes;
          if (event.sourceLayer.id.includes('dimensions-general-height-width-oversizes-icon'))
            selectedObjectType = MapObjects.generalDimensionsHeightWidthOversizes;
        } else if (TransportOrdersDataAccessor.selectedType === MapObjects.entityStatements) {
          if (event.sourceLayer.id.includes('axle-load-overloads'))
            selectedObjectType = MapObjects.entityStatementsAxleLoadOverloads;
          if (event.sourceLayer.id.includes('dimensions-height-oversize'))
            selectedObjectType = MapObjects.entityStatementsDimensionsHeightOversizes;
          if (event.sourceLayer.id.includes('dimensions-width-oversizes'))
            selectedObjectType = MapObjects.entityStatementsDimensionsWidthOversizes;
          if (event.sourceLayer.id.includes('dimensions-general-height-width-oversizes-icon'))
            selectedObjectType = MapObjects.entityStatementsGeneralDimensionsHeightWidthOversizes;
        }
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.state.selectedID) return [];

    const lineWidth = this?.props?.[Settings.lineWidthScale] ?? 8;

    return [
      // Паслэйер заявок
      new PathLayer(this.state, this.getSubLayerProps({ id: 'order-route-path' }), {
        data: this.state.data.order_route,
        widthScale: 3,
        pickable: true,
        rounded: true,
        billboard: false,
        autoHighlight: true,
        opacity: 1,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPath: (d) => {
          return d.statement === this.state.selectedID ? getCoordsFromParsedGeometry(d.path) : [0, 0];
        },
        getColor: [47, 128, 237],
        getWidth: lineWidth,
      }),
      // Паслэйер участков с перенагрузкой на ось
      new PathLayer(this.props, this.getSubLayerProps({ id: 'axle-load-overloads-path' }), {
        data: this.state.data.axle_load_overloads,
        widthScale: 3,
        pickable: true,
        rounded: true,
        billboard: false,
        autoHighlight: true,
        opacity: 1,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPath: (d) => {
          return d.statement === this.state.selectedID ? getCoordsFromParsedGeometry(d.line_path) : [0, 0];
        },
        getColor: [235, 87, 87],
        getWidth: lineWidth,
      }),
      // Паслэйер участков с превышением по ширине
      new PathLayer(this.props, this.getSubLayerProps({ id: 'dimensions-width-oversizes-path' }), {
        data: this.state.data.dimensions_width_oversizes,
        widthScale: 3,
        pickable: true,
        rounded: true,
        billboard: false,
        autoHighlight: true,
        opacity: 1,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPath: (d) => {
          return d.statement === this.state.selectedID ? getCoordsFromParsedGeometry(d.line_path) : [0, 0];
        },
        getColor: [235, 87, 87],
        getWidth: lineWidth,
      }),
      // Иконки
      new IconLayer(this.props, this.getSubLayerProps({ id: 'axle-load-overloads-icon' }), {
        data: this.state.data.axle_load_overloads,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/road_objects_icon.png`,
        iconMapping: roadObjectsIconMapping,
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 30,
        opacity: 1,
        pickable: true,
        autoHighlight: true,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.statement === this.state.selectedID && d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        updateTriggers: {
          getIcon: this.state.selectedObject,
          getSize: this.context?.viewport?.zoom,
        },
        getIcon: (d) => {
          if (!this.state.selectedObject?.object?.id && this.state.selectedObject?.object?.id !== 0)
            return 'alarm_white';
          const select = d.id === this.state.selectedObject.object.id;
          return select && this.state.selectedObjectType.includes('axle_load_overloads')
            ? 'alarm_color'
            : 'alarm_white';
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'dimensions-height-oversize-icon' }), {
        data: this.state.data.dimensions_height_oversizes,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/CTG_all_icon.png`,
        iconMapping: CTG_IconMapping,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.statement === this.state.selectedID && d.point) {
            return getCoordsFromParsedGeometry(d.point);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: this.state.selectedObject,
          getSize: this.context?.viewport?.zoom,
        },
        getIcon: (d) => {
          if (!this.state.selectedObject?.object?.id) return 'height_white';
          const select = d.id === this.state.selectedObject.object.id;
          return select && this.state.selectedObjectType.includes('dimensions_height_oversizes')
            ? 'height_color'
            : 'height_white';
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'dimensions-width-oversizes-icon' }), {
        data: this.state.data.dimensions_width_oversizes,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/CTG_all_icon.png`,
        iconMapping: CTG_IconMapping,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.statement === this.state.selectedID && d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: this.state.selectedObject,
          getSize: this.context?.viewport?.zoom,
        },
        getIcon: (d) => {
          if (!this.state.selectedObject?.object?.id) return 'width_white';
          const select = d.id === this.state.selectedObject.object.id;
          return select && this.state.selectedObjectType.includes('dimensions_width_oversizes')
            ? 'width_color'
            : 'width_white';
        },
      }),
      new IconLayer(this.props, this.getSubLayerProps({ id: 'dimensions-general-height-width-oversizes-icon' }), {
        data: this.state.data.general_dimensions_height_width_oversizes,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/CTG_all_icon.png`,
        iconMapping: CTG_IconMapping,
        opacity: this?.props?.opacity ?? 0.1,
        pickable: true,
        autoHighlight: true,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.statement === this.state.selectedID && d.line_path) {
            return calculateLinePathCenterCoords(d.line_path);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: this.state.selectedObject,
          getSize: this.context?.viewport?.zoom,
        },
        getIcon: (d) => {
          if (d.height_oversize && d.width_oversize) {
            if (!this.state.selectedObject?.object?.id) return 'width_height_oversize_white';
            const select = d.id === this.state.selectedObject.object.id;
            return select && this.state.selectedObjectType.includes('general_dimensions_height_width_oversizes')
              ? 'width_height_oversize_color'
              : 'width_height_oversize_white';
          } else if (d.width_oversize) {
            if (!this.state.selectedObject?.object?.id) return 'width_white';
            const select = d.id === this.state.selectedObject.object.id;
            return select && this.state.selectedObjectType.includes('general_dimensions_height_width_oversizes')
              ? 'width_color'
              : 'width_white';
          } else if (d.height_oversize) {
            if (!this.state.selectedObject?.object?.id) return 'height_white';
            const select = d.id === this.state.selectedObject.object.id;
            return select && this.state.selectedObjectType.includes('general_dimensions_height_width_oversizes')
              ? 'height_color'
              : 'height_white';
          }
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...TextLayer.defaultProps,
};

TransportOrdersCompositeLayer.defaultProps = defaultProps;
TransportOrdersCompositeLayer.layerName = 'TransportOrder';

export default TransportOrdersCompositeLayer;
