import React, { useCallback } from 'react';

import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';

import { LinkCellRendererProps } from './model/LinkCellRenderer.model';

const LinkCellRenderer = (props: LinkCellRendererProps) => {
  const field = props.colDef.field;
  const data = props.data[field];

  const onClickHandler = useCallback(() => {
    window.location.replace(data);
  }, [data]);

  return (
    <Button type={ButtonType.unbounded} href={`${data}`} onClick={onClickHandler}>
      Открыть
    </Button>
  );
};

export default LinkCellRenderer;
