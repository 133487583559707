import styled from 'styled-components/macro';
import { Radio } from 'antd';

import { Styled as styleMixins } from 'styles/styleMixins/styleMixins.styled';

const { OpacityColor } = styleMixins.color;

const SelectChartTypeStyled = styled.div``;

const SelectChartTypeWrap = styled(Radio.Group)`
  display: grid;
  grid-template-columns: repeat(4, 40px);
  grid-template-rows: 40px;
  grid-gap: 8px;
  margin-bottom: 8px;
`;

const SelectChartTypeButton = styled(Radio.Button)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius}!important;
  backdrop-filter: ${(props) => props.theme.decoration.smallBackgroundFilter};
  background: ${(props) => OpacityColor(props.theme.colors.darkblue, 0.08)};
  color: ${(props) => props.theme.colors.darkblue};

  &:hover {
    background: ${(props) => OpacityColor(props.theme.colors.darkblue, 0.08)};
    color: ${(props) => props.theme.colors.darkblue};
  }

  &::before {
    content: unset !important;
  }

  &.ant-radio-button-wrapper-checked {
    background: ${(props) => props.theme.colors.darkblue};
    color: ${(props) => props.theme.colors.defaultLighter};

    &:hover {
      background: ${(props) => props.theme.colors.darkblue};
      color: ${(props) => props.theme.colors.defaultLighter};
    }
  }

  & span:not([class]) {
    line-height: normal;
    height: 20px;
  }
`;

const SelectedChartName = styled.span`
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: 140%;
  color: ${(props) => props.theme.colors.darkblue};
`;

export const Styled = {
  SelectChartTypeStyled,
  SelectChartTypeWrap,
  SelectChartTypeButton,
  SelectedChartName,
};
