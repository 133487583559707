export enum RoadTechnicalCharacteristics {
  /** Словарь для слоя дорожных технических характеристик */
  SIX = '6',
  TEN = '10',
  ELEVEN_AND_HALF = '11,5',

  CRUSHED_STONE = 'Щебень',
  GRAVEL = 'Гравий',
  PRIMING = 'Грунт',
  ASPHALT = 'Асфальт',
  PAVED_COATING = 'Мощеное покрытие',
  PRECAST_CEMENT_COATING = 'Сборное цементное покрытие',
}
