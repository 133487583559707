import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadConstructionsService } from '../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class TrafficLight extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'traffic_light',
      filterLabel: 'Светофорные знаки',
      selectedObjectName: MapObjects.trafficLight,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadConstructionsService.roadConstructionsTrafficLightsList();
  }

  async deleteRecord(id) {
    return await RoadConstructionsService.roadConstructionsTrafficLightsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadConstructionsService.roadConstructionsTrafficLightsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.roadsConstructionTrafficLights);
  }
}
