import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { StateModel } from 'store/reducers';
import { RoleState } from 'store/reducers/rolesDirectory/rolesDirectory.model';
import styled from 'styled-components';

import { DirectoriesEnum } from '../enums/DirectoriesEnum';
import { AccessToDirectories } from './AccessToDirectories';
import { RightsToSelectedDirectories } from './RightsToSelectedDirectories';

const CardContainer = styled.div`
  display: flex;
`;

interface TabPageContainerProps {
  pageMenuKey: DirectoriesEnum;
  InlineDrawer: JSX.Element;
  folder: DirectoriesEnum;
}

export const TabPageContainer = ({ pageMenuKey, InlineDrawer, folder }: TabPageContainerProps) => {
  const rolesDirectory: RoleState = useSelector((state: StateModel) => state.rolesDirectory);

  const {
    checkingDirectory,
    checkingMap,
    checkedReports,
    checkingReports,
    checkedDirectory,
    checkedMap,
    rolesList,
    checkedRoles,
    organizationsList,
    checkedOrganizationsList,
    districtsList,
    checkedDistrictsList,
  } = rolesDirectory;

  const derictoryData = useMemo(() => {
    switch (pageMenuKey) {
      case DirectoriesEnum.get_directory:
        return {
          keysDirec: checkingDirectory,
          clickedDirec: checkedDirectory,
          isNeedOpenCheckedPermission: true,
        };
      case DirectoriesEnum.get_layers:
        return {
          keysDirec: checkingMap,
          clickedDirec: checkedMap,
          isNeedOpenCheckedPermission: true,
        };
      case DirectoriesEnum.get_reports:
        return {
          keysDirec: checkingReports,
          clickedDirec: checkedReports,
          isNeedOpenCheckedPermission: false,
        };
      case DirectoriesEnum.permissions_groups:
        return {
          keysDirec: rolesList,
          clickedDirec: checkedRoles,
          isNeedOpenCheckedPermission: false,
        };
      case DirectoriesEnum.filters_user:
        return {
          keysDirec: organizationsList,
          clickedDirec: checkedOrganizationsList,
          keysDistricts: districtsList,
          checkedDistrictsList,
          isNeedOpenCheckedPermission: false,
          filtersUser: true,
        };
      default:
        return {};
    }
  }, [
    pageMenuKey,
    checkingDirectory,
    checkedDirectory,
    checkingMap,
    checkedMap,
    checkingReports,
    checkedReports,
    rolesList,
    checkedRoles,
    organizationsList,
    checkedOrganizationsList,
    districtsList,
    checkedDistrictsList,
  ]);

  if (
    (folder === 'permissions_groups' && pageMenuKey === DirectoriesEnum.permissions_groups) ||
    pageMenuKey === DirectoriesEnum.main
  )
    return <>{InlineDrawer}</>;

  return (
    <CardContainer>
      <AccessToDirectories
        pageMenuKey={pageMenuKey}
        keysDirec={derictoryData.keysDirec ?? []}
        selectDirec={derictoryData.clickedDirec ?? []}
      />
      {derictoryData.filtersUser && (
        <AccessToDirectories
          pageMenuKey={DirectoriesEnum.filters_user_districts}
          keysDirec={derictoryData.keysDistricts}
          selectDirec={derictoryData.checkedDistrictsList}
        />
      )}
      {derictoryData.isNeedOpenCheckedPermission && <RightsToSelectedDirectories pageMenuKey={pageMenuKey} />}
    </CardContainer>
  );
};
