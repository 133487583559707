import React, { useMemo } from 'react';
import { useModeratorContext } from '../../context/ModeratorContext';
import { Button, Flex, Typography } from '@frontend-packages/its-ui-kit';
import { RefreshIcon } from '@frontend-packages/its-ui-icons';

export const ModeratedFileListHeader = () => {
  const { uploadDetectionList } = useModeratorContext();

  const iconSize = useMemo(() => ({ width: '20px', height: '20px' }), []);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography type="type1">Объекты</Typography>
        <Button
          onClick={uploadDetectionList}
          type="primary"
          buttonStyle="transparent"
          icon={<RefreshIcon />}
          iconSize={iconSize}
          p={0}
        />
      </Flex>
    </>
  );
};
