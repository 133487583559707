import moment from 'moment';
import _ from 'underscore';

export const applyParamsFilter = ({ filters }) => {
  let result = '?';
  let counter = 0;
  for (let filter of filters) {
    let queryParam = '';
    let queryData = '';
    let { field, operator, data, subfield = null, isDate = false } = filter;
    if (isDate) {
      data = data.map((el) => moment(el).format('YYYY-mm-DD'));
    }
    if (_.isEmpty(data)) return null;
    switch (operator) {
      case '==': {
        if (Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__in') : (queryParam += field + '__in');
          queryParam += field + '__in';
          queryData += data.toString().replace('[', '').replace(']', '');
        } else {
          subfield ? (queryParam += field + '__' + subfield) : (queryParam += field);
          queryData += data.toString();
        }
        break;
      }
      case 'all': {
        if (Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__in') : (queryParam += field + '__in');
          queryData += data.toString().replace('[', '').replace(']', '');
        } else {
          subfield ? (queryParam += field + '__' + subfield) : (queryParam += field);
          queryData += data.toString();
        }
        break;
      }
      case 'between': {
        if (Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__range') : (queryParam += field + '__range');
          queryData += data.toString().replace('[', '').replace(']', '');
        }
        break;
      }
      case 'includes': {
        if (!Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__icontains') : (queryParam += field + '__icontains');
          queryData += data.toString();
        }
        break;
      }
      case 'after': {
        if (!Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__after') : (queryParam += field + '__after');
          queryData += data.toString();
        }
        break;
      }
      case '<': {
        if (!Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__lt') : (queryParam += field + '__lt');
          queryData += data.toString();
        }
        break;
      }
      case '>': {
        if (!Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__gt') : (queryParam += field + '__gt');
          queryData += data.toString();
        }
        break;
      }
      case '>=': {
        if (!Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__gte') : (queryParam += field + '__gte');
          queryData += data.toString();
        }
        break;
      }
      case '<=': {
        if (!Array.isArray(data)) {
          subfield ? (queryParam += field + '__' + subfield + '__lte') : (queryParam += field + '__lte');
          queryData += data.toString();
        }
        break;
      }
      default:
    }
    if (operator.includes('!')) queryParam += '!';
    result += queryParam + '=' + queryData;
    if (counter + 1 < filters.length) result += '+';
    counter++;
  }
  return result;
};
