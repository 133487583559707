import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Cabinets extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'cabinets',
      filterLabel: 'Шкафы кабельных коммуникаций ',
      selectedObjectName: MapObjects.cabinets,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsCabinetsList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsCabinetsDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsCabinetsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.cabinets);
  }
}
