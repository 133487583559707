import React from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

const SupplyLineTramItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'Supply_cables_tram');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.supply_cables_tram}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];

  Descriptions.push({
    key: 'Наименование линии',
    value: data?.line_name,
  });

  Descriptions.push({
    key: 'Наименование питающей линии',
    value: data?.name,
  });

  Descriptions.push({
    key: 'Питающая станция',
    value: data?.substation_name,
  });

  Descriptions.push({
    key: 'Тип кабеля',
    value: data?.type_name,
  });

  return (
    <PrepareScrollableInfoCard
      title={'Данные о питающем кабеле'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

SupplyLineTramItemSelection.propTypes = {};

export default SupplyLineTramItemSelection;
