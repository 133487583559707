import React from 'react';
import Icon from '@ant-design/icons';

const RoadMarkupSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.70837 1.95833L7.38337 4.28333C7.32383 4.34129 7.28293 4.4157 7.26592 4.49703C7.24891 4.57836 7.25656 4.66292 7.28788 4.73988C7.3192 4.81684 7.37277 4.88271 7.44174 4.92905C7.51071 4.97539 7.59194 5.00009 7.67503 5L9.1667 5L9.1667 16.6667C9.1667 17.125 9.5417 17.5 10 17.5C10.4584 17.5 10.8334 17.125 10.8334 16.6667L10.8334 5L12.325 5C12.7 5 12.8834 4.55 12.6167 4.29167L10.2917 1.96667C10.1334 1.8 9.8667 1.8 9.70837 1.95833Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const RoadMarkup = (props) => <Icon component={RoadMarkupSVG} {...props} />;
