import React from 'react';
import LayerChangeControlsField from './LayerChangeControlsField';
import { connect } from 'react-redux';
import {
  changeLayerSetting,
  onChangeDisplayOfMapItem,
} from '../../../../../../../../../../../../store/reducers/map/actions/mapboxLayersActions';
import LayerChangeDisplayField from './LayerChangeDisplayField';

/**
 * Контейнер с настройками - Дизайн
 * @param enabledLayers - доступные слои
 * @param changeLayerSetting - функция изменения настроек слоя
 * @returns {JSX.Element}
 * @constructor
 */

const LayerChangeControlsTabContainer = ({ enabledLayers, handlerChangeLayerSetting, changeDisplayOfMapItem }) => {
  const filteredLayer = enabledLayers.filter((item) => item.isSelected === true);
  // выводятся вначале настройки из customSettings слоя, потом, если выполнится условие, настройки вида отображения объектов,
  // потом настройки стиля карты

  return (
    <div>
      <LayerChangeControlsField selectedLayer={filteredLayer[0]} changeLayerSetting={handlerChangeLayerSetting} />
      {filteredLayer[0].layerSchema.possibleLayersToRender?.length ? (
        <LayerChangeDisplayField selectedLayer={filteredLayer[0]} changeDisplayOfMapItem={changeDisplayOfMapItem} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    enabledLayers: state.mapboxLayers,
    mapStyle: state.mapboxView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlerChangeLayerSetting: (layerKey, settingKey, val) =>
      dispatch(changeLayerSetting({ keyOfLayer: layerKey, keyOfSetting: settingKey, value: val })),
    changeDisplayOfMapItem: (nextCompositeLayer, selectedLayerName) =>
      dispatch(onChangeDisplayOfMapItem(nextCompositeLayer, selectedLayerName)),
  };
};

LayerChangeControlsTabContainer.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(LayerChangeControlsTabContainer);
