import styled from 'styled-components/macro';

const BaseTemplateMainWrapper = styled.div`
  padding: ${(props) => props.theme.paddings.defaultPadding};
  padding-top: 0;
  height: calc(100vh - 150px);
  * {
    ::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #c1cbd8;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #929fb5;
    }
  }
`;

const BaseTemplateActionBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  & button + button {
    margin-left: 1rem;
  }
  & + div {
    margin-top: 1.5rem;
  }
`;

export const Styled = { BaseTemplateMainWrapper, BaseTemplateActionBarWrapper };
