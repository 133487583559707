import {
  defaultObjectDescription,
  selectionMapObjectRegistrator,
  SelectionMapObjectRegistratorModel,
} from './mapSelectObjectRegistrator';

export const resolver = (
  searchValue: string,
  resolvedField: keyof SelectionMapObjectRegistratorModel,
  searchField: keyof SelectionMapObjectRegistratorModel = 'name',
  useDefaultIfMissing: boolean = false
) => {
  if (!searchValue) throw new Error(`${searchValue} is empty for field ${resolvedField}`);
  let result = selectionMapObjectRegistrator.filter((el) => searchValue === el[searchField]);
  const exact = result.filter((el) => el[searchField].toLowerCase() === searchValue);
  if (exact && exact.length) result = exact;
  if (resolvedField && result && result[0] && result[0][resolvedField]) {
    return result[0][resolvedField];
  } else if (resolvedField) {
    if (useDefaultIfMissing && defaultObjectDescription[resolvedField]) {
      return defaultObjectDescription[resolvedField];
    } else return null;
  } else if (!resolvedField) return result[0];
};
