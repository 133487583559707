import { NumericLayerSetting } from './prototype/types/NumericLayerSetting';
import { Settings } from 'types/enums/map/layers/Settings';

export class LineWidthSetting extends NumericLayerSetting {
  constructor() {
    super({
      label: 'Ширина линий',
      keyForDeck: Settings.lineWidthScale,
      minimum: 0.1,
      currentValue: 10,
      maximum: 50,
      step: 0.1,
      isRanged: false,
      isPercent: true,
    });
  }
}
