import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';

export class transportAnalysisDistrictYurginskiiy extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'transportAnalysisDistrictYurginskiiy',
      filterLabel: 'Юргинский район',
    });
  }

  async uploadData() {
    return await getFromURL.getWithParams('/public_transport/checkpoints_you_can_reach/', { district_id: '30' });
  }
}
