import { MainDataSocketPrototype } from '../prototype/MainDataSocketPrototype';
import { openSocket } from 'store/sagas/map/socketDataGetterSaga';
import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { Vehicle, VehiclesService } from 'generated/api/api';

export class BrigadeMonitoringMainData extends MainDataSocketPrototype {
  _returnAC = true;

  constructor() {
    super({
      urlData: '/monitoring/',
      dataKeyForIds: 'vehicle_ids',
      relatedDataKey: MapObjects.brigadeMonitoring,
    });
  }

  getIsReturnsAC() {
    return this._returnAC;
  }

  async getAvailableVehicles(): Promise<any> {
    return await VehiclesService.vehiclesList();
  }

  async getSubscribeIds() {
    const { results } = await this.getAvailableVehicles();
    return results?.map((el: Vehicle) => el.id);
  }

  getCustomMainDataDownload(subscribeIds: Array<number>) {
    return openSocket({
      urlData: this.getUrlData(),
      layerName: Layers.brigadeApplications,
      subscribeKey: this.getDataKeyForIds(),
      subscribeIds: subscribeIds,
      relatedDataKey: this.getRelatedDataKey(),
      objectKeyUpdate: 'device_id',
    });
  }
}
