export default function OGRNValidator(ogrn) {
  let result;
  let localOgrn = ogrn.toString().replace(/[^0-9.]/g, '');
  if (/[^0-9]/.test(localOgrn)) {
    result = 'ОГРН должен состоять только из цифр';
  } else if (localOgrn.length !== 13 && localOgrn.length !== 15) {
    result = 'ОГРН должен содержать 13 или 15 символов';
  } else {
    let n13 = parseInt((parseInt(localOgrn.slice(0, -1)) % 11).toString().slice(-1));
    if (n13 === parseInt(localOgrn[12])) {
      result = true;
    } else {
      result = 'Ошибка контрольной суммы';
    }
  }
  return result;
}
