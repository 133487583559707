import { Form } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useBaseInputSettings } from '../hooks/useBaseInputSettings';
import _ from 'lodash';
import Select from '../../components/Select/Select';

const ChoiceInput = (props) => {
  const { control, errors, watch, setValue } = useFormContext();
  const { description, object, field, inputProps, watcher } = props;
  const { required, message } = useBaseInputSettings(description);
  const error = _.get(errors, field);
  let watchField;

  if (watcher) {
    watchField = watch(watcher.reference);
  }

  useEffect(() => {
    if (watcher) {
      watcher.watcher(setValue, watchField);
    }
  }, [setValue, watchField, watcher]);

  if (error && error.message?.includes(description.label)) {
    error.message = null;
  }

  const objectValue = useMemo(() => _.get(object, field), [field, object]);

  const options = useMemo(() => {
    return description.choices.map((el) => ({
      value: el.value,
      label: el.display_name,
    }));
  }, [description.choices]);

  const onChange = useCallback(([value, el]) => value ?? null, []);

  return (
    <Form.Item>
      <Controller
        name={field}
        control={control}
        defaultValue={objectValue}
        required={required}
        error={!!error}
        rules={{ required: { value: required, message: message } }}
        disabled={description.read_only}
        onChange={onChange}
        as={
          <Select
            {...inputProps}
            options={options}
            showSearch
            allowClear
            placeholder={description.label}
            mode={description?.type.includes('multi') ? 'multiple' : 'default'}
          />
        }
      />
    </Form.Item>
  );
};

export default ChoiceInput;
