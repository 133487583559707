import { MaskType } from '../utils/masks/maskRegistrator';

export interface SettingsCameraUdcState {
  modalVisible: boolean;
  currentMask: null | MaskType;
  visibleAllMasks: boolean;
  addedMasks: Array<MaskType>;
  deletedMasks: Array<number>;
}

export enum MaskAction {
  closeModal = 'CLOSE_MODAL',
  openModal = 'OPEN_MODAL',
  setCurrentMask = 'SET_CURRENT_MASK',
  cancelCurrentMask = 'CANCEL_CURRENT_MASK',
  saveCurrentMask = 'SAVE_CURRENT_MASK',
  setAddedMasks = 'SET_ADDED_MASKS',
  changeVisibleMask = 'CHANGE_VISIBLE_MASK',
  changeVisibleAllMasks = 'CHANGE_VISIBLE_ALL_MASKS',
  deleteMask = 'DELETE_MASK',
  changeDrawType = 'CHANGE_DRAW_TYPE',
  changePositionForDrawType = 'CHANGE_POSITION_FOR_DRAW_TYPE',
  changeMaskName = 'CHANGE_MASK_NAME',
}
