export enum mainPages {
  map = 'map',
  directory = 'directory',
  reports = 'reports',
  special_permits = 'special_permits',
  applications = 'applications',
  administration = 'administration',
}

export enum mainPagesLabel {
  map = 'Карта',
  directory = 'Справочники',
  reports = 'Отчеты',
  special_permits = 'Перевозка грузов',
  applications = 'ДУТССД',
  administration = 'Администрирование',
  default = 'Главная',
}
