export const SET_INITIAL_DATETIME = 'SET_INITIAL_DATETIME';
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME';
export const RESET_TIMER = 'RESET_TIMER';
export const TOGGLE_PLAYER_RUNNING_STATUS = 'TOGGLE_PLAYER_RUNNING_STATUS';
export const TOGGLE_PLAYER_ENDED_STATUS = 'TOGGLE_PLAYER_ENDED_STATUS';
export const START_TIME_PLAYER_SAGA = 'START_TIME_PLAYER_SAGA';
export const STOP_TIME_PLAYER_SAGA = 'STOP_TIME_PLAYER_SAGA';

export const setPlayerInitialDateTime = (startDateTime, endDateTime) => ({
  type: SET_INITIAL_DATETIME,
  payload: {
    startDateTime,
    endDateTime,
  },
});

export const resetInitialTime = () => ({
  type: RESET_TIMER,
});

const setCurrentTimeOnTimePlayer = (currentTimeStamp) => ({
  type: SET_CURRENT_TIME,
  payload: {
    currentTimeStamp,
  },
});

export const togglePlayerRunningStatus = (bool) => ({
  type: TOGGLE_PLAYER_RUNNING_STATUS,
  payload: {
    newStatus: bool,
  },
});

const togglePlayerEndedStatus = (bool) => ({
  type: TOGGLE_PLAYER_ENDED_STATUS,
  payload: {
    newStatus: bool,
  },
});

const startPLayerSaga = () => ({
  type: START_TIME_PLAYER_SAGA,
});

const stopPlayerSaga = () => ({
  type: STOP_TIME_PLAYER_SAGA,
});

export const mapboxTimeActions = {
  setPlayerInitialDateTime,
  setCurrentTimeOnTimePlayer,
  togglePlayerRunningStatus,
  togglePlayerEndedStatus,
  startPLayerSaga,
  stopPlayerSaga,
};
