import { SchemesType } from '../model';

class DataHelper {
  static schemesInlineKeys = ['inline'];

  static getObjectForRenderSide = (schemes: SchemesType, parentData?: any) => {
    const result: Array<any> = [];
    if (!schemes) {
      return result;
    }
    Object.keys(schemes).forEach((key) => {
      const item = schemes[key];
      if (DataHelper.schemesInlineKeys.includes(item.type)) {
        const url =
          item.url &&
          item.url
            .split('/')
            .filter(Boolean)
            .filter((i: string) => i !== 'api')
            .join('/');

        result.push({ ...item, url, key, parentData });
      }
    });
    return result;
  };

  static normalizeUrl = (url: string) => url.replace(/([^:]\/)\/+/g, '$1');
}

export default DataHelper;
