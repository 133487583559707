import React from 'react';

import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import { ArrowIcon } from 'UI/custom/icons/actionIcons/ArrowIcon';

import { Styled } from './ArrowHandler.styled';

const { LocalMainWrapper, LocalBackArrowWrapper, LocalSendArrowWrapper } = Styled;

interface ArrowHandlerProps {
  handleCheckedRight: () => void;
  handleCheckedLeft: () => void;
}

export const ArrowHandler = ({ handleCheckedRight, handleCheckedLeft }: ArrowHandlerProps) => {
  return (
    <LocalMainWrapper>
      <LocalSendArrowWrapper onClick={handleCheckedRight}>
        <Button icon={<ArrowIcon />} onClick={handleCheckedRight} iconSize={10} type={ButtonType.unboundedPrimary} />
      </LocalSendArrowWrapper>
      <LocalBackArrowWrapper onClick={handleCheckedLeft}>
        <Button icon={<ArrowIcon />} onClick={handleCheckedLeft} iconSize={10} type={ButtonType.unboundedPrimary} />
      </LocalBackArrowWrapper>
    </LocalMainWrapper>
  );
};
