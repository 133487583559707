import { LayerSchemaPrototype } from 'registrators/map/layers/description/layerSchemaPresets/prototype/LayerSchemaPrototype';
import CamerasUdcCompositeLayer from 'layers/CompositeLayers/ekb/CamerasUdcCompositeLayer';

export class CamerasUdcLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: CamerasUdcCompositeLayer,
      id: 'CamerasUdcSchema',
    });
  }
}
