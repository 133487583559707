import React, { PropsWithChildren, useRef } from 'react';
import { Styled } from './style/Tooltip.styled';
import { useDispatch } from 'react-redux';
import { useOutsideAlert } from './hooks/UseOutsideAlert';
import { CustomTooltipProps } from './model/Tooltip.model';

const Tooltip = ({
  positionStyleOfTooltip = { x: 0, y: 0, visible: true },
  children,
  onCloseCallback,
  tooltipWidth,
  tailSide,
  tooltipHeight,
  tooltipOpacity,
}: PropsWithChildren<CustomTooltipProps>) => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  useOutsideAlert(wrapperRef, dispatch, onCloseCallback);

  // быстрое решение проблемы отклонения координат при клике на карту в отличие клика на обьект
  const { clickedObjectName } = positionStyleOfTooltip;
  if (clickedObjectName === 'map') {
    positionStyleOfTooltip.x += 180;
  }
  return (
    <Styled.LocalMainWrapper
      ref={wrapperRef}
      tooltipWidth={tooltipWidth}
      tooltipOpacity={tooltipOpacity}
      tooltipHeight={tooltipHeight}
      positionStyleOfTooltip={positionStyleOfTooltip}
      tailSide={tailSide}
    >
      {children}
    </Styled.LocalMainWrapper>
  );
};

export default Tooltip;
