import { Dispatch } from 'react';
import _ from 'lodash';

import { CameraWidget } from 'generated/api/api';
import { ModelNames } from 'types/enums/general/ModelNames.model';
import { WatcherI } from 'hooks/form/usePrepareFieldsHook/model/watchFieldsModel.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { AnyObject } from 'types/enums/general/general.model';
import { widgetTypeWatcher } from './watchFieldsFunctions/cameraWidgetList/widgetTypeWatcher';
import { countingIntervalWatcher } from './watchFieldsFunctions/cameraWidgetList/countingIntervalWatcher';

// Работает через замыкание и изменение переменной prepareModel в вотчерах
export const watchFieldsModel = (
  model: ModelResponse,
  data: AnyObject,
  setModel: Dispatch<ModelResponse>
): WatcherI => {
  const prepareModel = _.cloneDeep(model);

  switch (model.name) {
    case ModelNames.cameraWidgetList: {
      return {
        counting_interval: {
          reference: 'counting_period',
          watcher: countingIntervalWatcher(prepareModel, data as CameraWidget, setModel),
        },
        widget_type: {
          reference: 'widget_type',
          watcher: widgetTypeWatcher(prepareModel, data as CameraWidget, setModel),
        },
      };
    }
    default: {
      return {};
    }
  }
};
