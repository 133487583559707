import React from 'react';
import Icon from '@ant-design/icons';

const StackIconSVG = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.9925 12.905L1.465 8.6075L0.25 9.5525L7 14.8025L13.75 9.5525L12.5275 8.6L6.9925 12.905ZM7 11L12.52 6.7025L13.75 5.75L7 0.5L0.25 5.75L1.4725 6.7025L7 11ZM7 2.3975L11.305 5.75L7 9.1025L2.695 5.75L7 2.3975Z"
      fill="currentColor"
    />
  </svg>
);

export const StackIcon = (props) => <Icon component={StackIconSVG} {...props} />;
