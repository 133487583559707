import { Image } from 'antd';
import styled from 'styled-components';
import { DefaultStyleMixins, Modal } from '@frontend-packages/its-ui-kit';

const { getSpacing } = DefaultStyleMixins.offset;

export const SWrap = styled.div`
  height: 92px;
  background-color: ${(p) => p.theme.colors.gray_200};
  border-radius: ${(p) => p.theme.decoration.borderRadius.default};
  margin-bottom: ${getSpacing(1)};
  padding: ${getSpacing(1)};
  cursor: pointer;
`;

export const SInnerWrap = styled.div<{ image: string }>`
  height: 100%;
  background-size: cover;
  background-image: url('${(p) => p.image}');
  border-radius: ${(p) => p.theme.decoration.borderRadius.small};
`;

export const SModal = styled(Modal)`
  height: 95%;

  & .ant-modal-content {
    height: 100%;
  }

  & .ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  & .modal__body {
    overflow: hidden;
  }
`;

export const SModalContentWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
