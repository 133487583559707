import { Action, ActionFunction1, createAction } from 'redux-actions';

class AgGridActions {
  /** action types */
  public readonly prefix: string = 'AGGRIG';
  public readonly ADD_FILTER: string = `${this.prefix}.ADD_FILTER`;
  public readonly CLEAR_FILTER: string = `${this.prefix}.CLEAR_FILTER`;

  /** actions */
  public addFilter: ActionFunction1<any, Action<any>> = createAction(this.ADD_FILTER);
  public clearFilter: ActionFunction1<any, Action<any>> = createAction(this.CLEAR_FILTER);

  /** effects */
}

export const agGridFiltersActions = new AgGridActions();
