import React, { useMemo } from 'react';
import { baseStyles } from 'components/stylesRegistrator';
import { LayerCardContainer } from '../Card/LayerCardContainer';
import { groupOfLayersItem, layersItem } from '../Types/LayersGalleryTypes';
import { Tooltip } from 'antd';

const { BaseMapPanelSection, TitleGroupLayer, TooltipContainer } = baseStyles.modules.map;

interface ILayersGalleryContent {
  sortedGroupOfLayersGalleryItemsWithoutSelected: groupOfLayersItem[];
}

export const LayersGalleryContent = ({ sortedGroupOfLayersGalleryItemsWithoutSelected }: ILayersGalleryContent) => {
  const layersGalleryContent = useMemo(
    () =>
      sortedGroupOfLayersGalleryItemsWithoutSelected.map((el: groupOfLayersItem, index) => {
        const groupCards = el.layersGalleryItems.map(
          (item: layersItem) => item && <LayerCardContainer key={item.name} name={item.name} card={item.card} />
        );
        return (
          <div key={`sorted_group_of_layers_gallery_items_without_selected__${index}`}>
            <TitleGroupLayer>
              <TooltipContainer>
                <Tooltip title={el.decryption} placement="topLeft" getPopupContainer={(triggerNode) => triggerNode}>
                  {el.label}
                </Tooltip>
              </TooltipContainer>
            </TitleGroupLayer>
            <BaseMapPanelSection>{groupCards}</BaseMapPanelSection>
          </div>
        );
      }),
    [sortedGroupOfLayersGalleryItemsWithoutSelected]
  );

  return <>{layersGalleryContent}</>;
};
