import Img from 'react-image';

import styled from 'styled-components/macro';

export const RoadSignImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: default;
  border: 1px solid ${(props) => props.theme.colors.transparent};
  border-radius: 10px;
`;

export const RoadSignImageContainer = styled.div`
  .need_warning_border:hover {
    border-color: ${(props) => props.theme.colors.warning};
  }
`;
