import styled from 'styled-components/macro';
import { Checkbox } from 'antd';

const FileUploaderForm = styled.div`
  background: ${(props) => props.theme.colors.defaultLight};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  margin-bottom: 8px;
  padding: 12px 16px 16px;
`;

const FileUploaderTitle = styled.p`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  word-break: break-word;
  color: ${(props) => props.theme.colors.neutralDarker};
  margin-bottom: 8px;
`;

const FileUploaderItemWrap = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  word-break: break-word;
  font-size: ${(props) => props.theme.fontSettings.defaultSmall};
  line-height: ${(props) => props.theme.fontSettings.large};
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const FileUploaderItem = styled.span`
  :not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const FileUploaderButtonWrap = styled.div`
  background: transparent !important;

  button {
    width: 100%;
    margin-top: 16px;
  }

  & div {
    padding: 0;
  }
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  & .ant-checkbox-group-item {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSettings.defaultSmall};
    line-height: 140%;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  & .ant-checkbox + span {
    padding-left: 18px;
    padding-right: 0;
  }

  & .ant-checkbox-inner {
    width: 15px;
    height: 15px;
    background: transparent;
    border: 2px solid ${(props) => props.theme.colors.primary}!important;

    &::after {
      left: 2px;
    }
  }

  & .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const Styled = {
  FileUploaderForm,
  FileUploaderTitle,
  FileUploaderItemWrap,
  FileUploaderButtonWrap,
  FileUploaderItem,
  StyledCheckboxGroup,
};
