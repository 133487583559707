import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { layerSettings } from '../description/settings';
import { layerDataAccessors } from '../description/dataAccessors';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import LineTrollItemSelection from '../../../../layers/SelectedObjectsRepresentations/TrolleybusItemSelection/LineTrollItemSelection';
import { ekbLayerCards } from '../description/cards/ekb';
import { projectLayerGroups } from '../description/layerGroups';

export class TrolleybusLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.trolleybusTransport,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.TrolleybusTransportDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.Lines_troll(),
        new relatedLayerData.ekbRelatedDataForLayers.Cables_troll(),
        new relatedLayerData.ekbRelatedDataForLayers.Supply_Cables_Troll(),
      ],
      layerGroup: new projectLayerGroups.DefaultLayerGroup(),
      card: new ekbLayerCards.TrolleybusCard(),
      customSettings: [
        new layerSettings.OpacitySetting(),
        new layerSettings.IconSizeScale(),
        new layerSettings.ColorSetting(),
      ],
      layerSchema: new layerSchemas.ekbLayersSchema.TrolleybusLayerSchema(),
      selectedObjectPrototype: LineTrollItemSelection,
    });
  }
}
