import styled from 'styled-components/macro';
import { Modal } from 'antd';

const SettingsModalWrap = styled(Modal)`
  padding-bottom: 0;

  & .ant-modal-content {
    border-radius: ${(props) => props.theme.decoration.largeBorderRadius};
    height: calc(100vh - 110px);
    overflow: hidden;
  }

  & .ant-modal-header {
    padding: 16px;
    border-radius: ${(props) => props.theme.decoration.largeBorderRadiusOnlyTop};
    border: none;
    box-shadow: 0px 2px 8px rgba(107, 138, 228, 0.12);

    & .ant-modal-title {
      font-size: ${(props) => props.theme.fontSettings.default};
    }
  }

  & .ant-modal-body {
    padding: 0;
    height: calc(100% - 54px);
  }
`;

const SettingsModalContent = styled.div`
  display: grid;
  grid-template-columns: 272px 1fr 272px;
  grid-gap: 16px;
  height: 100%;
`;

export const Styled = {
  SettingsModalWrap,
  SettingsModalContent,
};
