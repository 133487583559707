import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select } from 'antd';
// @ts-ignore
import { SpecialPermitsService } from 'generated/api/api';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { Button } from 'UI/common/components/Button/Button';
import { ButtonType } from 'UI/common/components/Button/model/Button.model';
import _ from 'underscore';

import { Styled } from './PrintEntityOrIndividualStatementLetter.styled';
import { useSavePdf } from 'hooks/reports/useSavePdf';

const { Option } = Select;
const { StartUploadButton, LocalModal, FormContainer, BaseSelectInput } = Styled;

interface IPrintEntityOrIndividualStatementLetterModal {
  isOpenModal: boolean;
  setOpenModal: (status: boolean) => void;
  idStatement?: number | null;
  directory?: string;
}

export const PrintEntityOrIndividualStatementLetterButton = (
  props: PropsWithChildren<IPrintEntityOrIndividualStatementLetterModal>
) => {
  const dispatch = useDispatch();
  const { isOpenModal, setOpenModal, idStatement, directory } = props;
  const [exportOption, setExportOption] = useState<number | unknown>();
  const { savePdf } = useSavePdf();

  const exportStatement = useCallback(async () => {
    try {
      if (idStatement) {
        if (directory === 'entity_statements') {
          // TODO исправить после бека Тех-долг-FRONTEND #5599
          // @ts-ignore
          const response = await SpecialPermitsService.specialPermitsEntityStatementsPrintCancelLetterRetrieve(
            { id: idStatement },
            {
              responseType: 'blob',
            }
          );
          savePdf(response);
        } else if (directory === 'individual_statements') {
          // TODO исправить после бека Тех-долг-FRONTEND #5599
          // @ts-ignore
          const response = await SpecialPermitsService.specialPermitsIndividualStatementsPrintCancelLetterRetrieve(
            { id: idStatement },
            {
              responseType: 'blob',
            }
          );
          savePdf(response);
        } else
          dispatch(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: 'Ошибка',
              message: 'Невозможно получить тип справочника',
            })
          );
      }
      setOpenModal(false);
    } catch (e) {
      if (_.isObject(e) && e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message,
          })
        );
      }
    } finally {
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
    }
  }, [idStatement, setOpenModal, directory, dispatch, savePdf]);

  const exportStatementButton = useMemo(
    () => (
      <StartUploadButton>
        <Button className={'shitTrader'} width={'100%'} type={ButtonType.primary} onClick={exportStatement}>
          <p>Скачать файл</p>
        </Button>
      </StartUploadButton>
    ),
    [exportStatement]
  );

  const options = useMemo(() => ['Отказ в выдаче'], []);

  const optionMenu = useMemo(
    () =>
      options.map((el: string, index: number) => (
        <Option value={el} key={`base_select_option_${el}_${index}`}>
          {`${el}`}
        </Option>
      )),
    [options]
  );

  const collapseInput = useMemo(
    () => (
      <FormContainer>
        <BaseSelectInput onChange={(value) => setExportOption(value)}>{optionMenu}</BaseSelectInput>
        <p className={exportOption ? 'fullField' : 'emptyField'}>Выгрузить...</p>
      </FormContainer>
    ),
    [exportOption, optionMenu]
  );

  return (
    <LocalModal
      title={`Выберите что выгрузить`}
      visible={isOpenModal}
      onOk={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      centered={true}
      footer={exportOption ? exportStatementButton : null}
    >
      {collapseInput}
    </LocalModal>
  );
};
