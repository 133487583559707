import styled from 'styled-components/macro';
import { Button } from '../../../UI/common/components/Button/Button';

const LocalMainWrapper = styled.div`
  width: 306px;
`;

const LocalMainTitle = styled.h3`
  margin: 0;
  font-weight: 700;
  font-family: 'Gilroy', sans-serif;
  font-size: 18px;
  color: ${(props) => props.theme.colors.dark};
`;

const LocalMainDescription = styled.div`
  width: 170px;
  font-size: 14px;
  margin-top: 8px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.gray};
`;

const LocalButtonHeadIcon = styled(Button)`
  margin-left: -5px;
  & svg {
    transform-origin: center;
  }
`;

export const Styled = {
  LocalMainWrapper,
  LocalMainTitle,
  LocalMainDescription,
  LocalButtonHeadIcon,
};
