import React from 'react';
import Icon from '@ant-design/icons';

const LightingIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 18.3332H10.8333C9.91667 18.3332 9.16667 17.5832 9.16667 16.6665V12.4998H14.1667V16.6665C14.1667 17.5832 13.4167 18.3332 12.5 18.3332ZM5.83333 11.6665H17.5L12.5 8.0915V4.99984C12.5 3.65817 11.6167 1.6665 9.16667 1.6665C6.71667 1.6665 5.83333 3.65817 5.83333 4.99984C5.83333 5.37484 5.675 5.83317 5 5.83317H4.16667V2.49984H2.5V9.99984H4.16667V7.49984H5C6.83333 7.49984 7.5 6.00817 7.5 4.99984C7.5 4.72484 7.58333 3.33317 9.16667 3.33317C10.6917 3.33317 10.8333 4.6165 10.8333 4.99984V8.0915L5.83333 11.6665Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const LightingIcon = (props) => <Icon component={LightingIconSVG} {...props} />;
