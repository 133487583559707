import { handleActions, Reducer } from 'redux-actions';
import { notificationActions } from './notifications.actions';
import { NotificationsState, PromptState } from './notifications.model';
import { MapReducer } from '../../reducers.model';
import { ActiveStatus } from '../../../../types/enums/UI/ActiveStatus.model';
import { PromptType } from '../../../../types/enums/UI/PromptType.model';

const { CLEAR_ALERT_DATA, CLEAR_NOTIFICATION_DATA, SET_ALERT_DATA, SET_NOTIFICATION_DATA } = notificationActions;

const initialAlertState: PromptState = {
  status: ActiveStatus.disabled,
  type: PromptType.info,
  title: '',
  message: '',
};

const initialNotificationState: PromptState = {
  status: ActiveStatus.disabled,
  type: PromptType.success,
  title: '',
  message: '',
};

const initialState: NotificationsState = {
  alert: initialAlertState,
  notification: initialNotificationState,
};

export const setAlert: Reducer<NotificationsState, PromptState> = (state, action) => {
  const { status, type, title, message } = action.payload;
  return { ...state, alert: { status, type, title, message } };
};

export const clearAlert: Reducer<NotificationsState, null> = (state) => {
  return { ...state, alert: initialAlertState };
};

export const setNotification: Reducer<NotificationsState, PromptState> = (state, action) => {
  const { status, type, title, message } = action.payload;
  return { ...state, notification: { status, type, title, message } };
};

export const clearNotification: Reducer<NotificationsState, null> = (state) => {
  return { ...state, notification: initialNotificationState };
};

const mapReducer: MapReducer<NotificationsState> = {
  [SET_ALERT_DATA]: setAlert,
  [SET_NOTIFICATION_DATA]: setNotification,
  [CLEAR_ALERT_DATA]: clearAlert,
  [CLEAR_NOTIFICATION_DATA]: clearNotification,
};
// @ts-ignore
export const notificationsReducer: Reducer<NotificationsState, any> = handleActions(mapReducer, initialState);
