import React from 'react';
import { To3DIcon } from '../../../../../../../UI/custom/icons/actionIcons/To3DIcon';
import { CompassIcon } from '../../../../../../../UI/custom/icons/actionIcons/CompassIcon';
import styled from 'styled-components/macro';

const StyledCompassWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  height: 50px;
  right: ${(props) => (props.rotate ? '20px' : '7px')};
`;

const Styled3DButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  left: 10px;
  top: 8px;
  z-index: 1;
  border: ${(props) => (props.rotate ? '2px' : '0px')} solid ${(props) => props.theme.colors.neutralDark};
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: 16px;
  transform: rotateX(${(props) => props.rotate || 0}deg);
`;

const StyledCompassButton = styled.div`
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${(props) => props.rotate || 0}deg);
`;

export const CompassButton = ({ viewState, toggle3DHandler, toggleBearingHandler }) => {
  return (
    <StyledCompassWrapper rotate={viewState.bearing}>
      <Styled3DButton rotate={viewState.pitch} onClick={toggle3DHandler}>
        <To3DIcon />
      </Styled3DButton>
      <StyledCompassButton rotate={viewState.bearing} onClick={toggleBearingHandler}>
        <CompassIcon />
      </StyledCompassButton>
    </StyledCompassWrapper>
  );
};

export default CompassButton;
