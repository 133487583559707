import { LayerSettingsPrototype } from '../LayerSettingsPrototype';

export class NumericLayerSetting extends LayerSettingsPrototype {
  type = Number;
  currentValue;
  minimum;
  maximum;
  step;
  isRanged;
  isPercent;

  constructor({ currentValue, label, keyForDeck, minimum, maximum, step, isRanged = false, isPercent = false }) {
    super({ label, keyForDeck, currentValue });

    this.currentValue = currentValue;
    this.minimum = minimum;
    this.maximum = maximum;
    this.step = step;
    this.isRanged = isRanged;
    this.isPercent = isPercent;
  }

  getSettingType() {
    return this.type;
  }

  getInitialValue() {
    return this.currentValue;
  }

  getCurrentValue() {
    return this.currentValue;
  }

  getMinimum() {
    return this.minimum;
  }

  getMaximum() {
    return this.maximum;
  }

  getStep() {
    return this.step;
  }

  getIsRanged() {
    return this.isRanged;
  }

  getIsPercent() {
    return this.isPercent;
  }
}
