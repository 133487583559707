import React, { useEffect, useState } from 'react';
import { Styled } from './PreparingDataContainer.styled';
import RepresentationCheckedItem from '../../checkingWidget/RepresentationCheckedItem';
import { useHistory } from 'react-router-dom';
import { useReportsRegistersUrl } from '../../../../../../../../../hooks/reports/useReportsRegisterUrl';
import { reportsActions } from '../../../../../../../../../store/reducers/reports/reports.actions';
import { useDispatch } from 'react-redux';
const { LocalSelectWrapper, LocalSelectWrapperInner } = Styled;

const PreparingDataContainer = ({ otherData }) => {
  const dispatch = useDispatch();

  let {
    model,
    itemRoads,
    setItemRoads,
    itemAnother,
    setItemAnother,
    filterItems,
    setFilterItems,
    filterItemsAnother,
    setFilterItemsAnother,
    isLoading,
  } = useReportsRegistersUrl();
  let history = useHistory();
  let urlLocation = history.location.pathname;

  if (otherData) {
    itemRoads = itemAnother;
    setItemRoads = setItemAnother;
    filterItems = filterItemsAnother;
  }
  useEffect(() => {
    model(urlLocation);
  }, [model, urlLocation]);

  const [input, setInput] = useState('');
  const [inputAnother, setInputAnother] = useState('');
  const searchCheckedItem = () => {
    const isCheckedAllItem = itemRoads.filter((elem) => elem.isChecked === true);
    const selectValues = isCheckedAllItem.map((el) => el.id);
    otherData
      ? dispatch(reportsActions.setCheckedItemAnother(selectValues))
      : dispatch(reportsActions.setCheckedItem(selectValues));
  };

  const handleAllChecked = (event) => {
    const selects = itemRoads;
    selects.forEach((currentSelect) => (currentSelect.isChecked = event.target.checked));
    setItemRoads([...selects]);
    searchCheckedItem();
  };

  const handleCheckElement = (event) => {
    const selects = itemRoads;
    selects.forEach((currentSelect) => {
      if (currentSelect.value === event.target.value) currentSelect.isChecked = event.target.checked;
    });
    setItemRoads([...selects]);
    searchCheckedItem();
  };

  const items = filterItems?.map((item, index) => {
    return (
      <LocalSelectWrapper key={index}>
        <LocalSelectWrapperInner>
          <div>
            <input
              type="checkbox"
              value={item.value}
              checked={item.isChecked}
              id={item.id}
              onClick={handleCheckElement}
            />
          </div>
          <div>
            <label htmlFor={item.value}>{item.value}</label>
          </div>
        </LocalSelectWrapperInner>
      </LocalSelectWrapper>
    );
  });

  const updateInput = (inputValue) => {
    const value = inputValue.target.value;
    const filtered = itemRoads?.filter((data) => {
      return data?.value?.toLowerCase().includes(value.toLowerCase());
    });
    setInput(value);
    setFilterItems(filtered);
  };

  const updateInputAnother = (currentInput) => {
    const value = currentInput.target.value;
    const filtered = itemRoads?.filter((data) => {
      return data?.value?.toLowerCase().includes(value.toLowerCase());
    });
    setInputAnother(value);
    setFilterItemsAnother(filtered);
  };

  return (
    <RepresentationCheckedItem
      handleAllChecked={handleAllChecked}
      updateInput={updateInput}
      updateInputAnother={updateInputAnother}
      inputAnother={inputAnother}
      otherData={otherData}
      isLoading={isLoading}
      input={input}
      items={items}
    />
  );
};

export default PreparingDataContainer;
