export const objectAllIconMapper = {
  busstation_active_disabled_danger: { x: 258, y: 0, width: 128, height: 128 },
  overhead_communications: { x: 258, y: 129, width: 128, height: 128 },
  overhead_communications_active: { x: 0, y: 266, width: 128, height: 128 },
  overhead_communications_disabled: { x: 129, y: 266, width: 128, height: 128 },
  overhead_communications_disabled_danger: { x: 258, y: 266, width: 128, height: 128 },
  overhead_communications_active_disabled: { x: 387, y: 0, width: 128, height: 128 },
  overhead_communications_active_disabled_danger: { x: 387, y: 129, width: 128, height: 128 },
  intersection: { x: 387, y: 258, width: 128, height: 128 },
  intersection_disabled: { x: 0, y: 395, width: 128, height: 128 },
  intersection_disabled_danger: { x: 129, y: 395, width: 128, height: 128 },
  intersection_active: { x: 258, y: 395, width: 128, height: 128 },
  intersection_active_disabled: { x: 387, y: 395, width: 128, height: 128 },
  intersection_active_disabled_danger: { x: 516, y: 0, width: 128, height: 128 },
  curb: { x: 516, y: 129, width: 128, height: 128 },
  curb_disabled: { x: 516, y: 258, width: 128, height: 128 },
  curb_disabled_danger: { x: 516, y: 387, width: 128, height: 128 },
  curb_active: { x: 0, y: 524, width: 128, height: 128 },
  curb_active_disabled: { x: 129, y: 524, width: 128, height: 128 },
  curb_active_disabled_danger: { x: 258, y: 524, width: 128, height: 128 },
  district: { x: 387, y: 524, width: 128, height: 128 },
  district_disabled: { x: 516, y: 524, width: 128, height: 128 },
  district_disabled_danger: { x: 645, y: 0, width: 128, height: 128 },
  district_active: { x: 645, y: 129, width: 128, height: 128 },
  district_active_disabled: { x: 645, y: 258, width: 128, height: 128 },
  district_active_disabled_danger: { x: 645, y: 387, width: 128, height: 128 },
  electricity: { x: 645, y: 516, width: 128, height: 128 },
  electricity_disabled: { x: 0, y: 653, width: 128, height: 128 },
  electricity_disabled_danger: { x: 129, y: 653, width: 128, height: 128 },
  electricity_active: { x: 258, y: 653, width: 128, height: 128 },
  electricity_active_disabled: { x: 387, y: 653, width: 128, height: 128 },
  electricity_active_disabled_danger: { x: 516, y: 653, width: 128, height: 128 },
  road_fences: { x: 645, y: 653, width: 128, height: 128 },
  road_fences_disabled: { x: 774, y: 0, width: 128, height: 128 },
  road_fences_disabled_danger: { x: 774, y: 129, width: 128, height: 128 },
  road_fences_active: { x: 774, y: 258, width: 128, height: 128 },
  road_fences_active_disabled: { x: 774, y: 387, width: 128, height: 128 },
  road_fences_active_disabled_danger: { x: 774, y: 516, width: 128, height: 128 },
  filepodd: { x: 774, y: 645, width: 128, height: 128 },
  filepodd_disabled: { x: 0, y: 782, width: 128, height: 128 },
  filepodd_disabled_danger: { x: 129, y: 782, width: 128, height: 128 },
  filepodd_active: { x: 258, y: 782, width: 128, height: 128 },
  filepodd_active_disabled: { x: 387, y: 782, width: 128, height: 128 },
  filepodd_active_disabled_danger: { x: 516, y: 782, width: 128, height: 128 },
  flowers: { x: 645, y: 782, width: 128, height: 128 },
  flowers_disabled: { x: 774, y: 782, width: 128, height: 128 },
  flowers_disabled_danger: { x: 903, y: 0, width: 128, height: 128 },
  flowers_active: { x: 903, y: 129, width: 128, height: 128 },
  flowers_active_disabled: { x: 903, y: 258, width: 128, height: 128 },
  flowers_active_disabled_danger: { x: 903, y: 387, width: 128, height: 128 },
  overpass: { x: 903, y: 516, width: 128, height: 128 },
  overpass_disabled: { x: 903, y: 645, width: 128, height: 128 },
  overpass_disabled_danger: { x: 903, y: 774, width: 128, height: 128 },
  overpass_active: { x: 0, y: 911, width: 128, height: 128 },
  overpass_active_disabled: { x: 129, y: 911, width: 128, height: 128 },
  overpass_active_disabled_danger: { x: 258, y: 911, width: 128, height: 128 },
  fountain: { x: 387, y: 911, width: 128, height: 128 },
  fountain_disabled: { x: 516, y: 911, width: 128, height: 128 },
  fountain_disabled_danger: { x: 645, y: 911, width: 128, height: 128 },
  fountain_active: { x: 774, y: 911, width: 128, height: 128 },
  fountain_active_disabled: { x: 903, y: 911, width: 128, height: 128 },
  fountain_active_disabled_danger: { x: 1032, y: 0, width: 128, height: 128 },
  graveStone: { x: 1032, y: 129, width: 128, height: 128 },
  graveStone_disabled: { x: 1032, y: 258, width: 128, height: 128 },
  graveStone_disabled_danger: { x: 1032, y: 387, width: 128, height: 128 },
  graveStone_active: { x: 1032, y: 516, width: 128, height: 128 },
  graveStone_active_disabled: { x: 1032, y: 645, width: 128, height: 128 },
  graveStone_active_disabled_danger: { x: 1032, y: 774, width: 128, height: 128 },
  hatch: { x: 1032, y: 903, width: 128, height: 128 },
  hatch_disabled: { x: 0, y: 1040, width: 128, height: 128 },
  hatch_disabled_danger: { x: 129, y: 1040, width: 128, height: 128 },
  hatch_active: { x: 258, y: 1040, width: 128, height: 128 },
  hatch_active_disabled: { x: 387, y: 1040, width: 128, height: 128 },
  hatch_active_disabled_danger: { x: 516, y: 1040, width: 128, height: 128 },
  heightOfDimension: { x: 645, y: 1040, width: 128, height: 128 },
  heightOfDimension_disabled: { x: 774, y: 1040, width: 128, height: 128 },
  heightOfDimension_disabled_danger: { x: 903, y: 1040, width: 128, height: 128 },
  heightOfDimension_active: { x: 1032, y: 1040, width: 128, height: 128 },
  heightOfDimension_active_disabled: { x: 1161, y: 0, width: 128, height: 128 },
  heightOfDimension_active_disabled_danger: { x: 1161, y: 129, width: 128, height: 128 },
  hospital: { x: 1161, y: 258, width: 128, height: 128 },
  hospital_disabled: { x: 1161, y: 387, width: 128, height: 128 },
  hospital_disabled_danger: { x: 1161, y: 516, width: 128, height: 128 },
  hospital_active: { x: 1161, y: 645, width: 128, height: 128 },
  hospital_active_disabled: { x: 1161, y: 774, width: 128, height: 128 },
  hospital_active_disabled_danger: { x: 1161, y: 903, width: 128, height: 128 },
  lighting: { x: 1161, y: 1032, width: 128, height: 128 },
  lighting_disabled: { x: 0, y: 1169, width: 128, height: 128 },
  lighting_disabled_danger: { x: 129, y: 1169, width: 128, height: 128 },
  lighting_active: { x: 258, y: 1169, width: 128, height: 128 },
  lighting_active_disabled: { x: 387, y: 1169, width: 128, height: 128 },
  lighting_active_disabled_danger: { x: 516, y: 1169, width: 128, height: 128 },
  landPlot: { x: 645, y: 1169, width: 128, height: 128 },
  landPlot_disabled: { x: 774, y: 1169, width: 128, height: 128 },
  landPlot_disabled_danger: { x: 903, y: 1169, width: 128, height: 128 },
  landPlot_active: { x: 1032, y: 1169, width: 128, height: 128 },
  landPlot_active_disabled: { x: 1161, y: 1169, width: 128, height: 128 },
  landPlot_active_disabled_danger: { x: 1290, y: 0, width: 128, height: 128 },
  lawn: { x: 1290, y: 129, width: 128, height: 128 },
  lawn_disabled: { x: 1290, y: 258, width: 128, height: 128 },
  lawn_disabled_danger: { x: 1290, y: 387, width: 128, height: 128 },
  lawn_active: { x: 1290, y: 516, width: 128, height: 128 },
  lawn_active_disabled: { x: 1290, y: 645, width: 128, height: 128 },
  lawn_active_disabled_danger: { x: 1290, y: 774, width: 128, height: 128 },
  monument: { x: 1290, y: 903, width: 128, height: 128 },
  monument_disabled: { x: 1290, y: 1032, width: 128, height: 128 },
  monument_disabled_danger: { x: 1290, y: 1161, width: 128, height: 128 },
  monument_active: { x: 0, y: 1298, width: 128, height: 128 },
  monument_active_disabled: { x: 129, y: 1298, width: 128, height: 128 },
  monument_active_disabled_danger: { x: 258, y: 1298, width: 128, height: 128 },
  overallDimensions: { x: 387, y: 1298, width: 128, height: 128 },
  overallDimensions_disabled: { x: 516, y: 1298, width: 128, height: 128 },
  overallDimensions_disabled_danger: { x: 645, y: 1298, width: 128, height: 128 },
  overallDimensions_active: { x: 774, y: 1298, width: 128, height: 128 },
  overallDimensions_active_disabled: { x: 903, y: 1298, width: 128, height: 128 },
  overallDimensions_active_disabled_danger: { x: 1032, y: 1298, width: 128, height: 128 },
  crosswalk: { x: 1161, y: 1298, width: 128, height: 128 },
  crosswalk_disabled: { x: 1290, y: 1298, width: 128, height: 128 },
  crosswalk_disabled_danger: { x: 1419, y: 0, width: 128, height: 128 },
  crosswalk_active: { x: 1419, y: 129, width: 128, height: 128 },
  crosswalk_active_disabled: { x: 1419, y: 258, width: 128, height: 128 },
  crosswalk_active_disabled_danger: { x: 1419, y: 387, width: 128, height: 128 },
  phone: { x: 1419, y: 516, width: 128, height: 128 },
  phone_disabled: { x: 1419, y: 645, width: 128, height: 128 },
  phone_disabled_danger: { x: 1419, y: 774, width: 128, height: 128 },
  phone_active: { x: 1419, y: 903, width: 128, height: 128 },
  phone_active_disabled: { x: 1419, y: 1032, width: 128, height: 128 },
  phone_active_disabled_danger: { x: 1419, y: 1161, width: 128, height: 128 },
  pipe: { x: 1419, y: 1290, width: 128, height: 128 },
  pipe_disabled: { x: 0, y: 1427, width: 128, height: 128 },
  pipe_disabled_danger: { x: 129, y: 1427, width: 128, height: 128 },
  pipe_active: { x: 258, y: 1427, width: 128, height: 128 },
  pipe_active_disabled: { x: 387, y: 1427, width: 128, height: 128 },
  pipe_active_disabled_danger: { x: 516, y: 1427, width: 128, height: 128 },
  pipes: { x: 645, y: 1427, width: 128, height: 128 },
  pipes_disabled: { x: 774, y: 1427, width: 128, height: 128 },
  pipes_disabled_danger: { x: 903, y: 1427, width: 128, height: 128 },
  pipes_active: { x: 1032, y: 1427, width: 128, height: 128 },
  pipes_active_disabled: { x: 1161, y: 1427, width: 128, height: 128 },
  pipes_active_disabled_danger: { x: 1290, y: 1427, width: 128, height: 128 },
  railwayCrossing: { x: 1419, y: 1427, width: 128, height: 128 },
  railwayCrossing_disabled: { x: 1548, y: 0, width: 128, height: 128 },
  railwayCrossing_disabled_danger: { x: 1548, y: 129, width: 128, height: 128 },
  railwayCrossing_active: { x: 1548, y: 258, width: 128, height: 128 },
  railwayCrossing_active_disabled: { x: 1548, y: 387, width: 128, height: 128 },
  railwayCrossing_active_disabled_danger: { x: 1548, y: 516, width: 128, height: 128 },
  recreationArea: { x: 1548, y: 645, width: 128, height: 128 },
  recreationArea_disabled: { x: 1548, y: 774, width: 128, height: 128 },
  recreationArea_disabled_danger: { x: 1548, y: 903, width: 128, height: 128 },
  recreationArea_active: { x: 1548, y: 1032, width: 128, height: 128 },
  recreationArea_active_disabled: { x: 1548, y: 1161, width: 128, height: 128 },
  recreationArea_active_disabled_danger: { x: 1548, y: 1290, width: 128, height: 128 },
  support_wall: { x: 1548, y: 1419, width: 128, height: 128 },
  support_wall_disabled: { x: 0, y: 1556, width: 128, height: 128 },
  support_wall_disabled_danger: { x: 129, y: 1556, width: 128, height: 128 },
  support_wall_active: { x: 258, y: 1556, width: 128, height: 128 },
  support_wall_active_disabled: { x: 387, y: 1556, width: 128, height: 128 },
  support_wall_active_disabled_danger: { x: 516, y: 1556, width: 128, height: 128 },
  roadClosure: { x: 645, y: 1556, width: 128, height: 128 },
  roadClosure_disabled: { x: 774, y: 1556, width: 128, height: 128 },
  roadClosure_disabled_danger: { x: 903, y: 1556, width: 128, height: 128 },
  roadClosure_active: { x: 1032, y: 1556, width: 128, height: 128 },
  roadClosure_active_disabled: { x: 1161, y: 1556, width: 128, height: 128 },
  roadClosure_active_disabled_danger: { x: 1290, y: 1556, width: 128, height: 128 },
  roadRepairs: { x: 1419, y: 1556, width: 128, height: 128 },
  roadRepairs_disabled: { x: 1548, y: 1556, width: 128, height: 128 },
  roadRepairs_disabled_danger: { x: 1677, y: 0, width: 128, height: 128 },
  roadRepairs_active: { x: 1677, y: 129, width: 128, height: 128 },
  roadRepairs_active_disabled: { x: 1677, y: 258, width: 128, height: 128 },
  roadRepairs_active_disabled_danger: { x: 1677, y: 387, width: 128, height: 128 },
  ship: { x: 1677, y: 516, width: 128, height: 128 },
  ship_disabled: { x: 1677, y: 645, width: 128, height: 128 },
  ship_disabled_danger: { x: 1677, y: 774, width: 128, height: 128 },
  ship_active: { x: 1677, y: 903, width: 128, height: 128 },
  ship_active_disabled: { x: 1677, y: 1032, width: 128, height: 128 },
  ship_active_disabled_danger: { x: 1677, y: 1161, width: 128, height: 128 },
  signal_poles: { x: 1677, y: 1290, width: 128, height: 128 },
  signal_poles_disabled: { x: 1677, y: 1419, width: 128, height: 128 },
  signal_poles_disabled_danger: { x: 1677, y: 1548, width: 128, height: 128 },
  signal_poles_active: { x: 0, y: 1685, width: 128, height: 128 },
  signal_poles_active_disabled: { x: 129, y: 1685, width: 128, height: 128 },
  signal_poles_active_disabled_danger: { x: 258, y: 1685, width: 128, height: 128 },
  soundShield: { x: 387, y: 1685, width: 128, height: 128 },
  soundShield_disabled: { x: 0, y: 0, width: 128, height: 132 },
  soundShield_disabled_danger: { x: 129, y: 0, width: 128, height: 132 },
  soundShield_active: { x: 516, y: 1685, width: 128, height: 128 },
  soundShield_active_disabled: { x: 0, y: 133, width: 128, height: 132 },
  soundShield_active_disabled_danger: { x: 129, y: 133, width: 128, height: 132 },
  speed_bump: { x: 645, y: 1685, width: 128, height: 128 },
  speed_bump_disabled: { x: 774, y: 1685, width: 128, height: 128 },
  speed_bump_disabled_danger: { x: 903, y: 1685, width: 128, height: 128 },
  speed_bump_active: { x: 1032, y: 1685, width: 128, height: 128 },
  speed_bump_active_disabled: { x: 1161, y: 1685, width: 128, height: 128 },
  speed_bump_active_disabled_danger: { x: 1290, y: 1685, width: 128, height: 128 },
  support_pillars: { x: 1419, y: 1685, width: 128, height: 128 },
  support_pillars_disabled: { x: 1548, y: 1685, width: 128, height: 128 },
  support_pillars_disabled_danger: { x: 1677, y: 1685, width: 128, height: 128 },
  support_pillars_active: { x: 1806, y: 0, width: 128, height: 128 },
  support_pillars_active_disabled: { x: 1806, y: 129, width: 128, height: 128 },
  support_pillars_active_disabled_danger: { x: 1806, y: 258, width: 128, height: 128 },
  trafficLights: { x: 1806, y: 387, width: 128, height: 128 },
  trafficLights_disabled: { x: 1806, y: 516, width: 128, height: 128 },
  trafficLights_disabled_danger: { x: 1806, y: 645, width: 128, height: 128 },
  trafficLights_active: { x: 1806, y: 774, width: 128, height: 128 },
  trafficLights_active_disabled: { x: 1806, y: 903, width: 128, height: 128 },
  trafficLights_active_disabled_danger: { x: 1806, y: 1032, width: 128, height: 128 },
  trashcan: { x: 1806, y: 1161, width: 128, height: 128 },
  trashcan_disabled: { x: 1806, y: 1290, width: 128, height: 128 },
  trashcan_disabled_danger: { x: 1806, y: 1419, width: 128, height: 128 },
  trashcan_active: { x: 1806, y: 1548, width: 128, height: 128 },
  trashcan_active_disabled: { x: 1806, y: 1677, width: 128, height: 128 },
  trashcan_active_disabled_danger: { x: 0, y: 1814, width: 128, height: 128 },
  traySystems: { x: 129, y: 1814, width: 128, height: 128 },
  traySystems_disabled: { x: 258, y: 1814, width: 128, height: 128 },
  traySystems_disabled_danger: { x: 387, y: 1814, width: 128, height: 128 },
  traySystems_active: { x: 516, y: 1814, width: 128, height: 128 },
  traySystems_active_disabled: { x: 645, y: 1814, width: 128, height: 128 },
  traySystems_active_disabled_danger: { x: 774, y: 1814, width: 128, height: 128 },
  tunnel: { x: 903, y: 1814, width: 128, height: 128 },
  tunnel_disabled: { x: 1032, y: 1814, width: 128, height: 128 },
  tunnel_disabled_danger: { x: 1161, y: 1814, width: 128, height: 128 },
  tunnel_active: { x: 1290, y: 1814, width: 128, height: 128 },
  tunnel_active_disabled: { x: 1419, y: 1814, width: 128, height: 128 },
  tunnel_active_disabled_danger: { x: 1548, y: 1814, width: 128, height: 128 },
  underground_crossing: { x: 1677, y: 1814, width: 128, height: 128 },
  underground_crossing_disabled: { x: 1806, y: 1814, width: 128, height: 128 },
  underground_crossing_disabled_danger: { x: 1935, y: 0, width: 128, height: 128 },
  underground_crossing_active: { x: 1935, y: 129, width: 128, height: 128 },
  underground_crossing_active_disabled: { x: 1935, y: 258, width: 128, height: 128 },
  underground_crossing_active_disabled_danger: { x: 1935, y: 387, width: 128, height: 128 },
  weatherStation: { x: 1935, y: 516, width: 128, height: 128 },
  weatherStation_disabled: { x: 1935, y: 645, width: 128, height: 128 },
  weatherStation_disabled_danger: { x: 1935, y: 774, width: 128, height: 128 },
  weatherStation_active: { x: 1935, y: 903, width: 128, height: 128 },
  weatherStation_active_disabled: { x: 1935, y: 1032, width: 128, height: 128 },
  weatherStation_active_disabled_danger: { x: 1935, y: 1161, width: 128, height: 128 },
  widthDimensionsDimensions: { x: 1935, y: 1290, width: 128, height: 128 },
  widthDimensionsDimensions_disabled: { x: 1935, y: 1419, width: 128, height: 128 },
  widthDimensionsDimensions_disabled_danger: { x: 1935, y: 1548, width: 128, height: 128 },
  widthDimensionsDimensions_active: { x: 1935, y: 1677, width: 128, height: 128 },
  widthDimensionsDimensions_active_disabled: { x: 1935, y: 1806, width: 128, height: 128 },
  widthDimensionsDimensions_active_disabled_danger: { x: 0, y: 1943, width: 128, height: 128 },
  overhead_passage: { x: 129, y: 1943, width: 128, height: 128 },
  overhead_passage_disabled: { x: 258, y: 1943, width: 128, height: 128 },
  overhead_passage_disabled_danger: { x: 387, y: 1943, width: 128, height: 128 },
  overhead_passage_active: { x: 516, y: 1943, width: 128, height: 128 },
  overhead_passage_active_disabled: { x: 645, y: 1943, width: 128, height: 128 },
  overhead_passage_active_disabled_danger: { x: 774, y: 1943, width: 128, height: 128 },
  advertisement: { x: 903, y: 1943, width: 128, height: 128 },
  advertisement_disabled: { x: 1032, y: 1943, width: 128, height: 128 },
  advertisement_disabled_danger: { x: 1161, y: 1943, width: 128, height: 128 },
  advertisement_active: { x: 1290, y: 1943, width: 128, height: 128 },
  advertisement_active_disabled: { x: 1419, y: 1943, width: 128, height: 128 },
  advertisement_active_disabled_danger: { x: 1548, y: 1943, width: 128, height: 128 },
  artificialLighting: { x: 1677, y: 1943, width: 128, height: 128 },
  artificialLighting_disabled: { x: 1806, y: 1943, width: 128, height: 128 },
  artificialLighting_disabled_danger: { x: 1935, y: 1943, width: 128, height: 128 },
  artificialLighting_active: { x: 2064, y: 0, width: 128, height: 128 },
  artificialLighting_active_disabled: { x: 2064, y: 129, width: 128, height: 128 },
  artificialLighting_active_disabled_danger: { x: 2064, y: 258, width: 128, height: 128 },
  attention: { x: 2064, y: 387, width: 128, height: 128 },
  attention_disabled: { x: 2064, y: 516, width: 128, height: 128 },
  attention_disabled_danger: { x: 2064, y: 645, width: 128, height: 128 },
  attention_active: { x: 2064, y: 774, width: 128, height: 128 },
  attention_active_disabled: { x: 2064, y: 903, width: 128, height: 128 },
  attention_active_disabled_danger: { x: 2064, y: 1032, width: 128, height: 128 },
  barrier: { x: 2064, y: 1161, width: 128, height: 128 },
  barrier_disabled: { x: 2064, y: 1290, width: 128, height: 128 },
  barrier_disabled_danger: { x: 2064, y: 1419, width: 128, height: 128 },
  barrier_active: { x: 2064, y: 1548, width: 128, height: 128 },
  barrier_active_disabled: { x: 2064, y: 1677, width: 128, height: 128 },
  barrier_active_disabled_danger: { x: 2064, y: 1806, width: 128, height: 128 },
  benches: { x: 2064, y: 1935, width: 128, height: 128 },
  benches_disabled: { x: 0, y: 2072, width: 128, height: 128 },
  benches_disabled_danger: { x: 129, y: 2072, width: 128, height: 128 },
  benches_active: { x: 258, y: 2072, width: 128, height: 128 },
  benches_active_disabled: { x: 387, y: 2072, width: 128, height: 128 },
  benches_active_disabled_danger: { x: 516, y: 2072, width: 128, height: 128 },
  berth: { x: 645, y: 2072, width: 128, height: 128 },
  berth_disabled: { x: 774, y: 2072, width: 128, height: 128 },
  berth_disabled_danger: { x: 903, y: 2072, width: 128, height: 128 },
  berth_active: { x: 1032, y: 2072, width: 128, height: 128 },
  berth_active_disabled: { x: 1161, y: 2072, width: 128, height: 128 },
  berth_active_disabled_danger: { x: 1290, y: 2072, width: 128, height: 128 },
  bicycle: { x: 1419, y: 2072, width: 128, height: 128 },
  bicycle_disabled: { x: 1548, y: 2072, width: 128, height: 128 },
  bicycle_disabled_danger: { x: 1677, y: 2072, width: 128, height: 128 },
  bicycle_active: { x: 1806, y: 2072, width: 128, height: 128 },
  bicycle_active_disabled: { x: 1935, y: 2072, width: 128, height: 128 },
  bicycle_active_disabled_danger: { x: 2064, y: 2072, width: 128, height: 128 },
  billboard: { x: 2193, y: 0, width: 128, height: 128 },
  billboard_disabled: { x: 2193, y: 129, width: 128, height: 128 },
  billboard_disabled_danger: { x: 2193, y: 258, width: 128, height: 128 },
  billboard_active: { x: 2193, y: 387, width: 128, height: 128 },
  billboard_active_disabled: { x: 2193, y: 516, width: 128, height: 128 },
  billboard_active_disabled_danger: { x: 2193, y: 645, width: 128, height: 128 },
  bridge: { x: 2193, y: 774, width: 128, height: 128 },
  bridge_disabled: { x: 2193, y: 903, width: 128, height: 128 },
  bridge_disabled_danger: { x: 2193, y: 1032, width: 128, height: 128 },
  bridge_active: { x: 2193, y: 1161, width: 128, height: 128 },
  bridge_active_disabled: { x: 2193, y: 1290, width: 128, height: 128 },
  bridge_active_disabled_danger: { x: 2193, y: 1419, width: 128, height: 128 },
  busstation: { x: 2193, y: 1548, width: 128, height: 128 },
  busstation_disabled: { x: 2193, y: 1677, width: 128, height: 128 },
  busstation_disabled_danger: { x: 2193, y: 1806, width: 128, height: 128 },
  busstation_active: { x: 2193, y: 1935, width: 128, height: 128 },
  busstation_active_disabled: { x: 2193, y: 2064, width: 128, height: 128 },
};
