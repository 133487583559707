import React from 'react';
import { Styled } from './PeriodicityData.styled';

import { CollapsableItem } from '../../../../../../../../UI/common/components/CollapsableItem/CollapsableItem';
import SelectTime from '../../../../../../../../UI/common/components/Calendar/SelectTime';
import { reportsActions } from '../../../../../../../../store/reducers/reports/reports.actions';
import { useDispatch } from 'react-redux';

const { LocalMainWrapper } = Styled;

const TimePeriodicityData = () => {
  const dispatch = useDispatch();

  const updateDataStart = (value) => {
    dispatch(reportsActions.setTimeStart(value));
  };

  const updateDataEnd = (value) => {
    dispatch(reportsActions.setTimeEnd(value));
  };

  return (
    <LocalMainWrapper>
      <CollapsableItem titleContent={'За какой период получить данные?'}>
        <SelectTime updateDataStart={updateDataStart} updateDataEnd={updateDataEnd} />
      </CollapsableItem>
    </LocalMainWrapper>
  );
};

export default TimePeriodicityData;
