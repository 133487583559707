import React, { PropsWithChildren, ReactNode } from 'react';

import { Styled } from './SidebarItem.styled';

interface SidebarItemProps {
  icon: ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean;
  active: boolean;
  isSidebarOpened: boolean;
}

const { Content, Icon, Item } = Styled;

const SidebarItem = ({
  icon,
  onClick,
  disabled,
  children,
  active,
  isSidebarOpened,
}: PropsWithChildren<SidebarItemProps>) => {
  const onClickAction = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (disabled || !onClick) {
      e.preventDefault();
    } else {
      return onClick(e);
    }
  };
  return (
    <Item
      className={`${active ? 'active' : ''}`}
      isSidebarOpened={isSidebarOpened}
      active={active}
      disabled={disabled}
      onClick={onClickAction}
    >
      {/*this is temporary solution*/}
      <Icon isSidebarOpened={isSidebarOpened}>{icon}</Icon>
      <Content isSidebarOpened={isSidebarOpened}>{children}</Content>
    </Item>
  );
};

export default SidebarItem;
