import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import widgetsProvideInput from './widtgetsProvideInput';
import { AnyObject } from 'types/enums/general/general.model';
import { WatcherI } from 'hooks/form/usePrepareFieldsHook/model/watchFieldsModel.model';

const widgetsPrepareInputs = (model: ModelResponse, object?: AnyObject, watchFields?: WatcherI) => {
  const scheme = model.scheme;

  if (scheme) {
    const schemeKeys = Object.keys(scheme);

    return schemeKeys.map((field) => {
      const description = scheme[field];
      const watcher = watchFields?.[field];

      return widgetsProvideInput(field, description, object, true, watcher);
    });
  }

  return [];
};

export default widgetsPrepareInputs;
