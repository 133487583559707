import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { BrigadeTeamsOfWorkersSelectionI } from './BrigadeTeamsOfWorkersSelection.model';
import BrigadeTeamsOfWorkersCard from '../BrigadeTeamsOfWorkersCard';
import { initializeSingleObjectEditMode } from 'store/sagas/map/standaloneObjectEditableSaga';
import { IDescriptions } from 'types/interfaces/map/selection/selection.model';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { Layers } from 'types/enums/map/layers/Layers';
import { groupTypes } from 'registrators/map/itemContextMenuRegistrator/contentGroups';
import OrderApplications from 'UI/custom/components/OrderApplications/OrderApplications';

const BrigadeTeamsOfWorkersSelection = ({
  handleCloseMapSelectedObjectInfo,
  data,
}: BrigadeTeamsOfWorkersSelectionI) => {
  const dispatch = useDispatch();

  const handleEditObject = useCallback(() => {
    dispatch(
      initializeSingleObjectEditMode(
        data,
        MapObjects.brigadeTeamsOfWorkers,
        Layers.brigadeApplications,
        null,
        groupTypes.EDIT_GROUP
      )
    );
  }, [data, dispatch]);

  const Descriptions: IDescriptions[] = [];

  Descriptions.push({
    key: 'Тип бригады',
    value: data.name,
  });

  Descriptions.push({
    key: 'Ответственный',
    value: data.teamlead_full_name,
  });

  const teamOfWorkersID = data.id;

  const teamOfMasters = useMemo(() => data.team_of_masters ?? [], [data.team_of_masters]);

  const orderApplications = useMemo(() => {
    return <OrderApplications appointedTeamId={teamOfWorkersID} teamMastersId={teamOfMasters} />;
  }, [teamOfMasters, teamOfWorkersID]);

  return (
    <BrigadeTeamsOfWorkersCard
      title={`Бригада №${teamOfWorkersID}`}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      listOfDescriptions={Descriptions}
      orderApplicationsComponent={orderApplications}
      handleEditObject={handleEditObject}
    />
  );
};

export default BrigadeTeamsOfWorkersSelection;
