import { call, put, takeEvery } from 'redux-saga/effects';
import { postToURLSingle } from '../../../api/postToURLSingle';
import { makeUrlWithoutIdAtTheEnd } from '../../../services/net/makeUrlWithoutIdAtTheEnd';
import { PromptType } from '../../../types/enums/UI/PromptType.model';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';

// после обработки и тестирования функции с обработкаой ошибок postObjectSagaErrorResponse. Данную функцию удалить!!!

// export const submitObjectSaga = (url, data, initial, redrawDispatch = null) => ({
//   type: SUBMIT_OBJECT_SAGA,
//   payload: {
//     url,
//     data,
//     initial,
//     redrawDispatch,
//   },
// });
export const SUBMIT_OBJECT_SAGA = 'SUBMIT_OBJECT_SAGA';

function* postObjectSaga(action) {
  try {
    yield put({ type: 'START_POSTING_DATA' });
    const { url, data, initial, redrawDispatch } = action.payload;
    //this fn call can break something in the project
    const preparedUrl = makeUrlWithoutIdAtTheEnd(url);
    const response = yield call(() => postToURLSingle.postOrPutDataErrorResponse(preparedUrl, data, initial));
    if (response) {
      yield put(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: 'Объект был успешно добавлен',
        })
      );
      if (redrawDispatch) {
        yield put(redrawDispatch);
        yield put({
          type: 'REFRESH_SUCCESSFUL',
        });
      }
      yield put({
        type: 'POST_SUCCESSFUL',
      });
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error();
    }
  } catch (e) {
    yield put(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message: 'Что-то пошло не так',
      })
    );
  }
}

export function* watchPostObjectSaga() {
  yield takeEvery(SUBMIT_OBJECT_SAGA, postObjectSaga);
}
