import React from 'react';
import Icon from '@ant-design/icons';

const ChangeStatusIconSVG = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7498 6.16683L9.83317 3.25016L10.9998 2.0835L12.7498 3.8335L16.2498 0.333496L17.4165 1.50016L12.7498 6.16683ZM8.1665 2.8335H0.666504V4.50016H8.1665V2.8335ZM16.4998 8.16683L15.3332 7.00016L13.1665 9.16683L10.9998 7.00016L9.83317 8.16683L11.9998 10.3335L9.83317 12.5002L10.9998 13.6668L13.1665 11.5002L15.3332 13.6668L16.4998 12.5002L14.3332 10.3335L16.4998 8.16683ZM8.1665 9.50016H0.666504V11.1668H8.1665V9.50016Z"
      fill="currentColor"
    />
  </svg>
);

export const ChangeStatusIcon = (props) => <Icon component={ChangeStatusIconSVG} {...props} />;
