import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { CommonSelectedItemContextMenuPrototype } from '../../prototypes/CommonSelectedItemContextMenuPrototype';

export class SupplyCablesShemaTram extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, MapObjects.supply_cables_tram, layerName);
    this.initialCoordinate = initialCoordinate;
  }

  /**
   * Используется в: создание объектов
   * Дополнительные действия контекстного меню - создание объектов НА СЕГМЕНТАХ НА СЛОЕ "СХЕМА ДОРОГ"
   */
  // actionItems = [
  //   ...this.actionItems,
  //   {
  //     name: MapContextMenuItem.tramSupplyCables,
  //     path: 'Добавить в ИС',
  //     group: EDIT_GROUP,
  //     dispatchedAction: () =>
  //       initializeSpecializeObjectEditMode(
  //         this.selectedObject,
  //         MapObjects.supplyTramCables,
  //         this.layerName,
  //         this.initialCoordinate,
  //         EDIT_GROUP,
  //         '/artificial_structures/segments_overpasses/' //?????
  //       ),
  //     icon: <PlusRoadIcon />,
  //   },
  // ];
}
