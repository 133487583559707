import styled from 'styled-components/macro';
import { Slider } from 'antd';
import { Styled as styledMixins } from '../../../../../styles/styleMixins/styleMixins.styled';
import { SliderWrapperProps } from './model/SliderWrapperProps';

const { CutStringWithEllipsisOnTheEnd } = styledMixins.text;

const SliderWrapper = styled.div<SliderWrapperProps>`
  padding: ${(props) => (props.p ? props.p : props.theme.paddings.smallPadding)};
  background: ${(props) => props.theme.colors.defaultLight};
  font-size: ${(props) => props.theme.fontSettings.default};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
`;

const SliderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SliderLabel = styled.span`
  width: 75%;
  text-align: left;
  &:first-letter {
    text-transform: uppercase;
  }
  ${CutStringWithEllipsisOnTheEnd}
`;

const SliderInfoWindow = styled.div`
  background: ${(props) => props.theme.colors.defaultLighter};
  border-radius: ${(props) => props.theme.decoration.defaultBorderRadius};
  color: ${(props) => props.theme.colors.primary};
  width: 20%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExtendedAntdSlider = styled(Slider)`
  margin: 3px;

  & .ant-slider-rail {
    background: ${(props) => props.theme.colors.neutralDark};
  }
  & .ant-slider-track {
    background: ${(props) => props.theme.colors.primary};
  }
  & .ant-slider-handle {
    background: ${(props) => props.theme.colors.primary};
    width: 6px;
    height: 16px;
    border: none;
    box-shadow: none;
    border-radius: 4px;
  }
  &:hover .ant-slider-track,
  .ant-slider-handle {
    background: ${(props) => props.theme.colors.primary};
  }
`;

export const Styled = {
  ExtendedAntdSlider,
  SliderWrapper,
  SliderLabel,
  SliderInner,
  SliderInfoWindow,
};
