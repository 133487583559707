import { LayerSchemaPrototype } from 'registrators/map/layers/description/layerSchemaPresets/prototype/LayerSchemaPrototype';
import BrigadeApplicationsCompositeLayer from 'layers/CompositeLayers/ekb/BrigadeApplicationsCompositeLayer';

export class BrigadeApplicationsLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: BrigadeApplicationsCompositeLayer,
      id: 'BrigadeApplicationsSchema',
    });
  }
}
