import { BreadcrumbsControlled } from 'components/Application/Common/BreadcrumbsControlled/BreadcrumbsControlled';
import { HeaderScipBlock } from 'components/Application/Common/HeaderScipBlock/HeaderScipBlock';
import React, { useCallback, useContext, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ApplicationsDirectory } from 'types/enums/applications/ApplicationsDirectory';
import { ApplicationsLabels } from 'types/enums/applications/ApplicationsLabels';
import {
  IApplicationsContext,
  CargoTransportParams,
} from '../../ApplicationsForm/interfacesApplications/interfacesApplications';
import { emptyArr } from '../../ApplicationsForm/interfacesApplications/templates';
import { ApplicationsContext } from '../../ApplicationsRouter';
import ObjectPanelApplications from './ObjectPanelApplications/ObjectPanelApplications';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const IndividualOrder = React.memo(() => {
  const { params } = useRouteMatch<CargoTransportParams>();
  const { setPageMenuKey, setApplicationTasks } = useContext<IApplicationsContext>(ApplicationsContext);

  const { directory, application } = params;

  const labelDirectory = useMemo(() => {
    let result;
    const isNewDocument = application === ApplicationsDirectory.create;
    switch (directory) {
      case ApplicationsDirectory.teams_of_masters:
        result = isNewDocument ? 'Создание нового мастера' : 'Редактирование мастера';
        break;
      case ApplicationsDirectory.expendable_materials:
        result = isNewDocument ? 'Создание расходного материала' : `Редактирование расходного материала`;
        break;
      case ApplicationsDirectory.materials:
        result = isNewDocument ? 'Создание нового материала' : `Редактирование материала`;
        break;
      case ApplicationsDirectory.types:
        result = isNewDocument ? 'Создание нового типа работ' : `Редактирование типа работ`;
        break;
      case ApplicationsDirectory.teams_of_workers:
        result = isNewDocument ? 'Создание новой бригады' : `Редактирование рабочей бригады`;
        break;
      case ApplicationsDirectory.tasks:
        result = isNewDocument ? 'Создание новой задачи' : `Редактирование задачи`;
        break;
      case ApplicationsDirectory.task_objects:
        result = isNewDocument ? 'Создание нового объекта задач' : `Редактирование объекта задач`;
        break;
      case ApplicationsDirectory.responsible_persons:
        result = isNewDocument ? 'Создание ответственного лица' : `Редактирование ответственного лица`;
        break;
      default:
        result = isNewDocument ? ApplicationsLabels.create : `Заявка № ${application}`;
    }
    return result;
  }, [application, directory]);

  const clickBreadcrumbsControlled = useCallback(() => {
    setApplicationTasks(emptyArr);
    setPageMenuKey(ApplicationsDirectory.main);
  }, [setApplicationTasks, setPageMenuKey]);

  const breadcrumbsLinks = useMemo(
    () => [
      {
        url: `/app/${ApplicationsDirectory.applications}/`,
        label: ApplicationsLabels.applications,
      },
      {
        url: `/app/${ApplicationsDirectory.applications}/${directory}/`,
        label: ApplicationsLabels[directory],
      },
      {
        url: '',
        label: labelDirectory,
      },
    ],
    [directory, labelDirectory]
  );

  return (
    <>
      <HeaderScipBlock />
      {breadcrumbsLinks && (
        <BreadcrumbsControlled links={breadcrumbsLinks} clickBreadcrumbsControlled={clickBreadcrumbsControlled} />
      )}
      <ObjectPanelApplications />
    </>
  );
});
