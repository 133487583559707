import React, { useEffect, useState } from 'react';
import { DirectoryRouteModel } from '../../../types/enums/routes/directory/DirectoryRoute.model';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';
import { getFromURL } from '../../../api/getFromURL';
import { TypeRoadDisplayNames } from 'types/enums/map/TypeRoadDisplayNames.model';
import { RoadImportanceEnum } from 'generated/api/api';

const TramsSchemeItemSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const [currentProperties, setCurrentProperties] = useState(null);

  useEffect(() => {
    if (data.road) {
      getFromURL.getById('roads/', data.road).then((res) => {
        setCurrentProperties(res);
      });
    }
  }, [data?.road]);

  const typeMap = new Map([
    [RoadImportanceEnum.MUNICIPAL, TypeRoadDisplayNames.MUNICIPAL],
    [RoadImportanceEnum.REGIONAL, TypeRoadDisplayNames.REGIONAL],
    [RoadImportanceEnum.FEDERAL, TypeRoadDisplayNames.FEDERAL],
  ]);

  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'segment');
    const { id } = roadData.data;
    const win = window.open(`/app/directory/${DirectoryRouteModel.roads_segments}/${id}/`, '_blank');
    win.focus();
  };

  let Descriptions = [];
  const properties = selectedMapItem.selectedObject.object;

  currentProperties?.name &&
    Descriptions.push({
      key: 'Наименование дороги',
      value: currentProperties.name,
    });

  currentProperties?.importance &&
    Descriptions.push({
      key: 'Тип',
      value: typeMap.get(currentProperties.importance),
    });

  currentProperties?.total_road_length &&
    Descriptions.push({
      key: 'Протяжённость',
      value: currentProperties.total_road_length,
    });

  currentProperties?.owner_organization_name &&
    Descriptions.push({
      key: 'Собственник',
      value: currentProperties.owner_organization_name,
    });

  currentProperties?.road_number &&
    Descriptions.push({
      key: 'Номер дороги',
      value: currentProperties.road_number,
    });

  // if (currentProperties?.road_code) {
  //   Descriptions.push({
  //     key: 'Код дороги',
  //     value: currentProperties?.road_code,
  //   });
  // } else {
  //   Descriptions.push({
  //     key: 'Код дороги',
  //     value: 'Н/Д',
  //   });
  // }

  properties?.line_name &&
    Descriptions.push({
      key: 'Наименование линии',
      value: properties?.line_name ?? 'Н/Д',
    });

  properties?.model &&
    Descriptions.push({
      key: 'Марка кабеля',
      value: properties?.model ?? 'Н/Д',
    });

  if (properties?.start) {
    Descriptions.push({
      key: 'Начало',
      value: properties?.start,
    });
  } else {
    Descriptions.push({
      key: 'Начало участка',
      value: properties.start_segment,
    });
  }

  properties?.end &&
    Descriptions.push({
      key: 'Конец',
      value: properties?.end ?? 'Н/Д',
    });

  properties?.length &&
    Descriptions.push({
      key: 'Протяжённость',
      value: properties?.length ?? 'Н/Д',
    });

  properties?.section_cable &&
    Descriptions.push({
      key: 'Сечение кабеля',
      value: properties?.section_cable ?? 'Н/Д',
    });
  // properties.total_length &&
  //   Descriptions.push({
  //     key: "Протяженность",
  //     value: `${properties.total_length?.toFixed(2)} км`,
  //   });

  return (
    <PrepareScrollableInfoCard
      title={'Данные линий трамваев'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

TramsSchemeItemSelection.propTypes = {};

export default TramsSchemeItemSelection;
