import React, { SyntheticEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './PasswordReset.style';
import { Styled } from './PasswordReset.style';
import { authApi } from 'api/authApi';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import _ from 'underscore';
import CustomAlert from 'UI/common/components/Alert/Alert';
import Input from 'UI/common/components/Input/Input';
import { StateModel } from 'store/reducers';

const {
  LocalMainWrapper,
  LocalFormWrapper,
  LocalFormTitle,
  LocalFormSubmit,
  LocalInputWrapperPassword,
  LocalInputWrapperPasswordRepeat,
  ExtendedButton,
  LocalSetDataError,
  LocalPrimaryDescription,
  ExtendedNavLink,
} = Styled;

const PasswordReset = (props: { uid?: string; token?: string }) => {
  const alertStatus = useSelector((state: StateModel) => {
    return {
      alertData: state?.notifications?.alert,
      promptData: state?.notifications?.notification,
    };
  });

  const dispatch = useDispatch();
  const { uid, token } = props;
  const [userData, setUserData] = useState({
    uid,
    token,
    new_password1: '',
    new_password2: '',
  });
  const history = useHistory();

  const buttonBackgroundColor = () => {
    return userData.new_password1 !== '' && userData.new_password2 !== '' ? 'primary' : 'disable';
  };

  const handleChangePassword1 = (password: string) => {
    setUserData({
      ...userData,
      new_password1: password,
    });
  };

  const handleChangePassword2 = (password: string) => {
    setUserData({
      ...userData,
      new_password2: password,
    });
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    try {
      await authApi
        .passwordConfirm(userData.new_password1, userData.new_password2, userData.uid, userData.token)
        .then(() => {
          history.push('/login');
          dispatch(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.success,
              title: 'Успешно',
            })
          );
        });
    } catch (e: any) {
      if (e?.response && _.isObject(e.response)) {
        const message = createAlertMessage(e);
        dispatch(
          notificationActions.setGlobalAlertData({
            status: ActiveStatus.active,
            type: PromptType.error,
            title: 'Ошибка',
            message: message,
          })
        );
      }
    } finally {
      setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), 9000);
    }
  };

  const handleBackToLogin = () => {
    history.push('/login');
  };

  return (
    <LocalMainWrapper>
      {uid && token && (
        <LocalFormWrapper>
          <LocalFormTitle className={'login-form__title'}>Придумайте пароль</LocalFormTitle>
          <LocalFormSubmit onSubmit={handleSubmit}>
            <LocalInputWrapperPassword>
              <Input onChange={handleChangePassword1} placeholder={'Новый пароль'} type={'password'} />
            </LocalInputWrapperPassword>
            <LocalInputWrapperPasswordRepeat>
              <Input onChange={handleChangePassword2} placeholder={'Повторите пароль'} type={'password'} />
            </LocalInputWrapperPasswordRepeat>
            <ExtendedButton
              onClick={handleSubmit}
              type={buttonBackgroundColor()}
              hasShadow
              p={'1.3rem'}
              fontSize={'1.15rem'}
              width={'100%'}
            >
              Сохранить и войти
            </ExtendedButton>
            <ExtendedNavLink to={'/guest/login'}>
              <LocalPrimaryDescription>Отменить сброс пароля</LocalPrimaryDescription>
            </ExtendedNavLink>
          </LocalFormSubmit>
          <CustomAlert
            portalSide={'bottom-right'}
            mb={'2rem'}
            mr={'2rem'}
            isOpen={alertStatus.alertData.status === ActiveStatus.active}
            type={alertStatus.alertData.type}
            title={alertStatus.alertData.title}
            message={alertStatus.alertData.message}
            size={{ width: '400px' }}
            onClose={() => dispatch(notificationActions.clearGlobalAlertData())}
          />
        </LocalFormWrapper>
      )}
      {(!uid || !token) && (
        <LocalFormWrapper>
          <LocalFormTitle className={'login-form__title'}>Ошибка в процессе авторизации</LocalFormTitle>
          <LocalSetDataError> Пожалуйста обратитесь тех поддержку или повторите попытку</LocalSetDataError>
          <ExtendedButton
            type="primary"
            hasShadow
            onClick={handleBackToLogin}
            p={'1.3rem'}
            fontSize={'1.15rem'}
            width={'100%'}
          >
            Назад
          </ExtendedButton>
          <CustomAlert
            portalSide={'bottom-right'}
            mb={'2rem'}
            mr={'2rem'}
            isOpen={alertStatus.alertData.status === ActiveStatus.active}
            type={alertStatus.alertData.type}
            title={alertStatus.alertData.title}
            message={alertStatus.alertData.message}
            size={{ width: '400px' }}
            onClose={() => dispatch(notificationActions.clearGlobalAlertData())}
          />
        </LocalFormWrapper>
      )}
    </LocalMainWrapper>
  );
};

export default PasswordReset;
