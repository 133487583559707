import { END, eventChannel } from 'redux-saga';
import { call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects';
import { mapboxDataActions } from '../../reducers/map/mapboxData/mapboxData.actions';
import _ from 'underscore';
import { CHECK_SOCKET_STATUS, REGISTER_SOCKET_STATUS } from '../../reducers/map/socketsStatus';
import { initUpdateMapSelectedObjectSaga } from './mapSelectedObjectSaga';
import { notificationActions } from '../../reducers/global/notifications/notifications.actions';
import { ActiveStatus } from '../../../types/enums/UI/ActiveStatus.model';
import { PromptType } from '../../../types/enums/UI/PromptType.model';

export const SAGA_GET_DATA_BY_SOCKET = 'SAGA_GET_DATA_BY_SOCKET';
export const CLOSE_SOCKET_CONNECTION = 'CLOSE_SOCKET_CONNECTION';

const dispatch = put.resolve;

export const openSocket = ({ urlData, layerName, subscribeKey, subscribeIds, relatedDataKey, objectKeyUpdate }) => {
  return {
    type: SAGA_GET_DATA_BY_SOCKET,
    payload: { urlData, layerName, subscribeKey, subscribeIds, relatedDataKey, objectKeyUpdate },
  };
};

export const closeSocket = () => ({ type: CLOSE_SOCKET_CONNECTION });

function createWebSocketConnection(action) {
  return new Promise((resolve, reject) => {
    const { urlData, subscribeKey, subscribeIds } = action.payload;

    const socket = new WebSocket(`${urlData}?token=${localStorage.getItem('token_access')}`);

    socket.onopen = function () {
      if (subscribeIds) {
        socket.send(JSON.stringify({ [subscribeKey]: subscribeIds }));
      } else {
        const range = _.range(29, 125, 1);
        socket.send(JSON.stringify({ [subscribeKey]: range }));
      }
      resolve(socket);
    };

    socket.onerror = function (evt) {
      reject(evt);
    };
  });
}

function createSocketChannel(socket) {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      emit(event.data);
    };

    socket.onclose = () => {
      emit(END);
    };

    return () => {
      socket.onmessage = null;
    };
  });
}

function* listenForSocketMessages(action) {
  let socket;
  let socketChannel;

  try {
    yield put({
      type: CHECK_SOCKET_STATUS,
      payload: {
        initiatorType: 'mapLayer',
        initiatorLayerName: action.payload.layerName,
        initiatorName: action.payload.relatedDataKey,
      },
    });
    socket = yield call(createWebSocketConnection, action);
    socketChannel = yield call(createSocketChannel, socket);
    yield put({ type: 'CONNECTIONS_SUCCESS' });
    yield put({
      type: REGISTER_SOCKET_STATUS,
      payload: {
        initiatorType: 'mapLayer',
        initiatorLayerName: action.payload.layerName,
        initiatorName: action.payload.relatedDataKey,
        socketInstance: socket,
      },
    });

    while (true) {
      const payload = yield take(socketChannel);
      const message = JSON.parse(payload);
      if (message.event === 'error') {
        try {
          yield put(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: 'Ошибка',
              message: message.data.description.connect[0],
            })
          );
        } catch (e) {
          yield put({ type: 'SOCKET_ERROR' });
        }
      } else if (Array.isArray(message) && message?.length) {
        yield put({
          type: mapboxDataActions.UPDATE_RELATED_DATA_SOCKET,
          payload: {
            layerName: action.payload.layerName,
            relatedDataKey: action.payload.relatedDataKey,
            value: message,
            objectKeyUpdate: action.payload.objectKeyUpdate,
          },
        });
      } else if (!Array.isArray(message)) {
        yield put({
          type: mapboxDataActions.PUSH_TO_MAPBOX_DATA,
          payload: {
            key: 'monitoring',
            value: message,
          },
        });
        yield put({
          type: mapboxDataActions.PUSH_TO_MAPBOX_RELATED_DATA,
          payload: {
            initiatorName: action.payload.layerName,
            relatedDataKey: 'vehicles',
            value: message,
          },
        });
        yield put(initUpdateMapSelectedObjectSaga(message));
      }
    }
  } catch (error) {
    yield put({ type: 'SOCKET_ERROR' });
  }
}

function* startSocketSaga(action) {
  const socketTask = yield fork(() => listenForSocketMessages(action));
  yield take(CLOSE_SOCKET_CONNECTION);
  yield cancel(socketTask);
  yield dispatch({ type: 'SOCKET_DISCONNECTED_SUCCESSFUL' });
}

export function* watchOpenSocketSaga() {
  yield takeEvery(SAGA_GET_DATA_BY_SOCKET, startSocketSaga);
}
