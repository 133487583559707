import React from 'react';
import { Styled } from './MapToolBar.styled';
import PrimaryGroupContainer from './Groups/PrimaryGroupContainer';
import SecondaryGroupContainer from './Groups/SecondaryGroupContainer';
import { iconSizesKit } from 'styles/utils/iconSizesKit';

const { LocalMainWrapper, LocalItemsGroup } = Styled;

interface IMapToolBar {
  showTimePlayer: boolean;
  isDeckViewLoading?: boolean;
  handlePrint: () => void;
}

export const MapToolBar = ({ /*showTimePlayer*/ handlePrint }: IMapToolBar) => {
  return (
    <LocalMainWrapper>
      {/*  print size other*/}
      <LocalItemsGroup>
        <PrimaryGroupContainer
          // @ts-ignore не понимаю суть ошибки, исправить при переводе PrimaryGroupContainer на TS
          iconSize={iconSizesKit.default}
          handlePrint={handlePrint}
        />
      </LocalItemsGroup>
      {/*  zoom 3d*/}
      <LocalItemsGroup>
        <SecondaryGroupContainer iconSize={iconSizesKit.default} iconSizeLarge={iconSizesKit.largest} />
      </LocalItemsGroup>
      {/*  legend timeplayer*/}
      <LocalItemsGroup>
        {' '}
        {/*<ConditionalItemsGroupContainer
          showTimePlayer={showTimePlayer}
          iconSize={iconSizesKit.default}
          iconSizeLarge={iconSizesKit.largest}
        />*/}
      </LocalItemsGroup>
    </LocalMainWrapper>
  );
};
