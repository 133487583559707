import React from 'react';
import Icon from '@ant-design/icons';

const OptionIconSVG = () => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.16683 12C4.16683 12.9167 3.41683 13.6667 2.50016 13.6667C1.5835 13.6667 0.833496 12.9167 0.833496 12C0.833496 11.0833 1.5835 10.3333 2.50016 10.3333C3.41683 10.3333 4.16683 11.0833 4.16683 12ZM2.50016 5.33334C1.5835 5.33334 0.833496 6.08334 0.833496 7.00001C0.833496 7.91668 1.5835 8.66668 2.50016 8.66668C3.41683 8.66668 4.16683 7.91668 4.16683 7.00001C4.16683 6.08334 3.41683 5.33334 2.50016 5.33334ZM2.50016 0.333344C1.5835 0.333344 0.833496 1.08334 0.833496 2.00001C0.833496 2.91668 1.5835 3.66668 2.50016 3.66668C3.41683 3.66668 4.16683 2.91668 4.16683 2.00001C4.16683 1.08334 3.41683 0.333344 2.50016 0.333344ZM7.50016 3.66668C8.41683 3.66668 9.16683 2.91668 9.16683 2.00001C9.16683 1.08334 8.41683 0.333344 7.50016 0.333344C6.5835 0.333344 5.8335 1.08334 5.8335 2.00001C5.8335 2.91668 6.5835 3.66668 7.50016 3.66668ZM7.50016 5.33334C6.5835 5.33334 5.8335 6.08334 5.8335 7.00001C5.8335 7.91668 6.5835 8.66668 7.50016 8.66668C8.41683 8.66668 9.16683 7.91668 9.16683 7.00001C9.16683 6.08334 8.41683 5.33334 7.50016 5.33334ZM7.50016 10.3333C6.5835 10.3333 5.8335 11.0833 5.8335 12C5.8335 12.9167 6.5835 13.6667 7.50016 13.6667C8.41683 13.6667 9.16683 12.9167 9.16683 12C9.16683 11.0833 8.41683 10.3333 7.50016 10.3333Z"
      fill="#929FB5"
    />
  </svg>
);

export const OptionIcon = (props) => <Icon component={OptionIconSVG} {...props} />;
