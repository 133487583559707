import { LayerCardPrototype } from '../prototype/LayerCardPrototype';

export class TramsSchemaCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'СКС трамваев',
      linkToPreview: 'SchemaRoad.svg',
      permissionToShowCard: 'layer_permissions.can_view_scs_trams_layer',
    });
  }
}
