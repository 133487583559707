import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { CommonSelectedItemContextMenuPrototype } from '../../prototypes/CommonSelectedItemContextMenuPrototype';
import { MapContextMenuItem } from '../../../../../../types/enums/map/contextMenu/MapContextMenuItem.model';
import React from 'react';
import { groupTypes } from '../../../contentGroups';
import { initializeSpecializeObjectEditMode } from '../../../../../../store/sagas/map/overpassesAddingSegmentEditableSaga';
import { PlusRoadIcon } from '../../../../../../UI/custom/icons/actionIcons/PlusRoadIcon';

const { EDIT_GROUP } = groupTypes;

export class Cables extends CommonSelectedItemContextMenuPrototype {
  initialCoordinate = null;
  constructor(selectedObject, layerName, initialCoordinate) {
    super(selectedObject, MapObjects.cablesTroll, layerName);
    this.initialCoordinate = initialCoordinate;
  }

  /**
   * Используется в: создание объектов
   * Дополнительные действия контекстного меню - создание объектов НА СЕГМЕНТАХ НА СЛОЕ "СХЕМА ДОРОГ"
   */
  actionItems = [
    ...this.actionItems,
    {
      name: MapContextMenuItem.createCablesTroll,
      path: 'Добавить в ИС',
      group: EDIT_GROUP,
      dispatchedAction: () =>
        initializeSpecializeObjectEditMode(
          this.selectedObject,
          MapObjects.cablesTroll,
          this.layerName,
          this.initialCoordinate,
          EDIT_GROUP,
          '/artificial_structures/segments_overpasses/' //?????
        ),
      icon: <PlusRoadIcon />,
    },
  ];
}
