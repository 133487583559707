import React from 'react';
import Icon from '@ant-design/icons';

const DividingLineSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 3.33337L5 3.33337L5 17.5L15 17.5L15 3.33337ZM6.66667 15.8334L6.66667 5.00004L9.16667 5.00004L9.16667 15.8334L6.66667 15.8334ZM10.8333 5.00004L13.3333 5.00004L13.3333 15.8334L10.8333 15.8334L10.8333 5.00004Z"
      fill="#6B8AE4"
    />
  </svg>
);

export const DividingLine = (props) => <Icon component={DividingLineSVG} {...props} />;
