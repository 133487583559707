import React from 'react';
import { StarIcon } from '../../../custom/icons/actionIcons/StarIcon';
import { OptionIcon } from '../../../custom/icons/actionIcons/OptionIcon';
import { Styled } from './SingleSubjectCard.style';

const {
  LocalCardMainWrapper,
  LocalCardHeader,
  LocalCardOption,
  LocalCardWrapper,
  LocalCardStar,
  LocalCardPoint,
  LocalCardDescription,
} = Styled;

const SingleSubjectCard = ({ url, to, titleContent, titleDescr }) => {
  return (
    <LocalCardMainWrapper url={url} to={to}>
      <LocalCardWrapper>
        <LocalCardHeader>
          {titleContent}
          <LocalCardOption>
            <LocalCardStar>
              <StarIcon />
            </LocalCardStar>
            <LocalCardPoint>
              <OptionIcon />
            </LocalCardPoint>
          </LocalCardOption>
        </LocalCardHeader>
        <LocalCardDescription>{titleDescr}</LocalCardDescription>
      </LocalCardWrapper>
    </LocalCardMainWrapper>
  );
};

export default SingleSubjectCard;
