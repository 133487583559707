import React from 'react';
import LayersSearchBar from './LayersSearchBar';

const LayersSearchBarContainer = () => {
  return (
    <div>
      <LayersSearchBar />
    </div>
  );
};

LayersSearchBarContainer.propTypes = {};

export default LayersSearchBarContainer;
