export enum DirectoriesEnum {
  role_name = 'role_name',
  get_layers = 'get_layers',
  get_directory = 'get_directory',
  get_reports = 'get_reports',
  permissions_groups = 'permissions_groups',
  main = 'main',
  filters_user = 'filters_user',
  filters_user_districts = 'filters_user_districts',
  users = 'users',
}
