import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import TrolleybusCompositeLayers from '../../../../../../layers/CompositeLayers/ekb/TrolleybusCompositeLayers';

export class TrolleybusLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: TrolleybusCompositeLayers,
      id: 'TrolleybusSchema',
    });
  }
}
