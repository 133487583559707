import {
  BreadcrumbType,
  getDirectoryPayload,
  UpdateBreadcrumbsPayload,
  UpdateDirectoryPayload,
  ContentRenderType,
} from '../types/AppState';
import { ContextState, SiderInterface } from '../interfaces';
import DataHelper from '../utils/data-helper';

class DirectoryService {
  axiosInstance: any;
  state: ContextState;
  registeredUrls: Array<any>;

  constructor(axios: any, directoryState: ContextState, registeredUrls: any) {
    this.axiosInstance = axios;
    this.state = directoryState;
    this.registeredUrls = registeredUrls;
  }

  getDirectoryData = async (url: string, params: any) => {
    const { data: modelResponse } = await this.axiosInstance.options(url, params);
    const { data: dataResponse } = await this.axiosInstance.get(url, params);

    return {
      model: modelResponse,
      data: dataResponse,
    };
  };

  getDirectoryRequest = async ({ payload }: { payload: getDirectoryPayload }) => {
    const { url, params = {}, contentRenderType = ContentRenderType.AgGrid } = payload;

    const normalizedUrl = DataHelper.normalizeUrl(url);
    const { model, data } = await this.getDirectoryData(normalizedUrl, params);

    const breadcrumbs: Array<BreadcrumbType> = this.state.breadcrumbs;
    const rootBreadcrumb = this.getRootBreadcrumbIfExist(url);

    let sider;
    if (rootBreadcrumb === undefined) {
      const siderData = DataHelper.getObjectForRenderSide(model.scheme, data);
      sider = {
        activeTab: 'main',
        elements: [
          {
            key: 'main',
            label: 'Основное',
            url,
          },
          ...siderData,
        ],
      };
    }

    if (rootBreadcrumb === undefined) {
      breadcrumbs.push({
        label: data?.name ?? data?.username,
        key: model.name,
        url,
        sider,
        params,
        parentData: data,
      });
    } else {
      breadcrumbs.push(rootBreadcrumb);
    }

    const uniqueBreadcrumbs = this.getUniqueBreadcrumbs(breadcrumbs);

    return {
      breadcrumbs: uniqueBreadcrumbs,
      sider,
      model,
      data,
      contentRenderType,
      requestUrl: url,
      lastAction: { cb: this.getDirectoryRequest, args: { payload } },
    };
  };

  getUniqueBreadcrumbs = (breadcrumbs: Array<any>) => {
    const uniqueBreadcrumbs: any[] = [];
    breadcrumbs.forEach((breadcrumb: any) => {
      const brWithoutCurrentElement = breadcrumbs.filter(
        (breadcrumbFilletItem: any) => breadcrumbFilletItem.key === breadcrumb.key
      );
      if (brWithoutCurrentElement.length <= 1) {
        uniqueBreadcrumbs.push(breadcrumb);
      } else if (!uniqueBreadcrumbs.find((uniqueBreadcrumb: any) => breadcrumb.key === uniqueBreadcrumb.key)) {
        uniqueBreadcrumbs.push(breadcrumb);
      }
    });
    return uniqueBreadcrumbs;
  };

  updateDirectoryRequest = async (
    { payload }: { payload: UpdateDirectoryPayload },
    isInline = false,
    parentData?: string
  ): Promise<any> => {
    const { url = '', params = {}, activeTab = 'main', label = '' } = payload;

    const normalizedUrl = DataHelper.normalizeUrl(url);
    const { model, data } = await this.getDirectoryData(normalizedUrl, params);
    const contentRenderType = DirectoryService.getRenderType(data);

    const currentSider: SiderInterface = this.state?.sider || { activeTab: '', elements: [] };
    let breadcrumbs: Array<BreadcrumbType> = this.state?.breadcrumbs;

    const sider: any = {
      ...currentSider,
      activeTab,
    };
    const lastItem = breadcrumbs[breadcrumbs.length - 1];
    const shouldPop = currentSider.elements.find((el) => el.key === lastItem?.key);
    if (activeTab === 'main') {
      breadcrumbs.pop();
    } else if (!!shouldPop) {
      breadcrumbs.splice(breadcrumbs.length - 1, 1, {
        label: label || data?.name || data?.username || data.name,
        key: activeTab,
        url,
        params,
        sider,
        isInline,
        parentData,
      });
    } else {
      breadcrumbs.push({
        label: label || data?.name || data?.username || data.name,
        key: activeTab,
        url,
        params,
        sider,
        isInline,
        parentData,
      });
    }
    const uniqueBreadcrumbs = this.getUniqueBreadcrumbs(breadcrumbs);

    return {
      breadcrumbs: uniqueBreadcrumbs,
      sider,
      model,
      data,
      contentRenderType,
      requestUrl: url,
      lastAction: { cb: this.updateDirectoryRequest, args: { payload } },
    };
  };

  updateBreadcrumb = async (payload: UpdateBreadcrumbsPayload): Promise<any> => {
    const { url, params = {}, sider, breadcrumbIndex = 0 } = payload;

    const normalizedUrl = DataHelper.normalizeUrl(url);
    const { model, data } = await this.getDirectoryData(normalizedUrl, params);

    const contentRenderType = DirectoryService.getRenderType(data);

    let breadcrumbs: Array<BreadcrumbType> = this.state.breadcrumbs;
    breadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);
    return {
      sider,
      breadcrumbs,
      model,
      data,
      contentRenderType,
      requestUrl: url,
      lastAction: { cb: this.updateBreadcrumb, args: payload },
    };
  };

  getCreateFormData = (model: any, data: any, url: string, parentData?: any) => {
    const result: any = {};
    Object.keys(model.scheme).forEach((key: string) => {
      const currentScheme = model.scheme[key];
      if (currentScheme.type === 'related field') {
        result[key] = parentData?.id;
      } else if (currentScheme.type === 'string related field') {
        // TODO something wrong with representation call Тех-долг-FRONTEND #5808
        result[key] = parentData?.name;
      } else {
        result[key] = null;
      }
    });

    const breadcrumbs: Array<BreadcrumbType> = this.state.breadcrumbs;

    let sider;
    const siderData = DataHelper.getObjectForRenderSide(model.scheme);
    sider = {
      activeTab: 'main',
      elements: [
        {
          key: 'main',
          label: 'Основное',
          url,
        },
        ...siderData,
      ],
    };

    breadcrumbs.push({
      label: 'Создание',
      key: model.name,
      url,
      sider,
      params: {},
    });
    const uniqueBreadcrumbs = this.getUniqueBreadcrumbs(breadcrumbs);

    return {
      contentRenderType: ContentRenderType.Form,
      data: result,
      breadcrumbs: uniqueBreadcrumbs,
      sider,
      model,
      disableSider: true,
      requestUrl: url,
    };
  };

  static getRenderType = (data: any) => (data.hasOwnProperty('results') ? 'ag_grid' : 'form');

  getRootBreadcrumbIfExist = (url: string) => {
    const currentRegisteredUrl = this.registeredUrls.find((i) => url === i.urlData);
    if (currentRegisteredUrl) {
      return {
        label: currentRegisteredUrl.label,
        key: currentRegisteredUrl.folderKey,
        url,
        params: null,
        sider: undefined,
      };
    }
    return undefined;
  };
}

export default DirectoryService;
