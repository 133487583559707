import { saveAs } from 'file-saver';

export const useSaveExel = () => {
  // Хук для того сохранение Exel файлов
  const saveExel = async (url) => {
    const response = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + localStorage.token_access,
      },
    });
    const blob = await response.blob();
    await saveAs(blob, 'act.xls');
  };

  return { saveExel };
};
