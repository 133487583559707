import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { setMapContextMenu } from '../../../../../store/reducers/map/actions/mapPanelsActions';
import { initializeSingleObjectEditMode } from '../../../../../store/sagas/map/standaloneObjectEditableSaga';
import MapContextMenu from './MapContextMenu';
import { contextMenuGroupsFactory } from '../../../../../services/map/contextMenuGroupsFactory';
import {
  contextMenuContentGroupList,
  resolveContextMenuContent,
} from '../../../../../registrators/map/itemContextMenuRegistrator/contextMenuContentRegistrator';

const MapContextMenuContainer = ({ mode, mapContextMenuProperties, closeMapContext }) => {
  const dispatch = useDispatch();
  let contentItemsCollection;
  let ContextDescriptionInstance = null;

  const ContentDescriptionClass = resolveContextMenuContent(
    mode,
    mapContextMenuProperties?.clickedObjectName.mapObjects || mapContextMenuProperties?.clickedObjectName,
    mapContextMenuProperties?.objectLayerName
  );
  if (ContentDescriptionClass) {
    ContextDescriptionInstance = new ContentDescriptionClass(
      mapContextMenuProperties?.selected,
      mapContextMenuProperties?.objectLayerName,
      mapContextMenuProperties?.coordinate
    );
  }

  contentItemsCollection = ContextDescriptionInstance?.getActionItemsList();

  let preparedTooltipGroups = null;
  if (contentItemsCollection) {
    preparedTooltipGroups = contextMenuGroupsFactory({
      groupsList: contextMenuContentGroupList,
      certainCollection: contentItemsCollection,
      dispatchFn: dispatch,
    });
  }
  const title = ContextDescriptionInstance?.getTitle();
  return (
    <>
      {mapContextMenuProperties ? (
        <MapContextMenu
          mapContextMenuProperties={mapContextMenuProperties}
          groupsRenderProp={preparedTooltipGroups}
          closeMapContext={closeMapContext}
          title={title}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    mapContextMenuProperties: state.mapPanels.mapContextMenu,
    mapLayers: state.mapboxLayers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMapContext: () => dispatch(setMapContextMenu()),
    initializeSingleObjectEditMode: (selectedObject, typeOfObject) =>
      dispatch(initializeSingleObjectEditMode(selectedObject, typeOfObject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContextMenuContainer);
