import styled from 'styled-components';

export const CompendiumContainer = styled.div`
  height: 75%;
  margin: 0 16px 16px 16px;
  border-radius: 16px;
  .ag-cell:first-child {
    display: inline;
  }
  * {
    ::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.defaultLighter};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutralDark};
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.neutralDarker};
    }
  }
`;
