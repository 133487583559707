import React, { PropsWithChildren, useCallback } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { IApplicationFormProps } from '../interfacesApplications/interfacesApplications';

import { Styled } from './ApplicationForm.styled';
import { prepareSinglePanels } from './InputsComponents/prepareSinglePanels';

const { ExtendedApplicationteForm, LocalTitle, LocalPanelsWrapper } = Styled;

const ApplicationForm = React.forwardRef((props: PropsWithChildren<IApplicationFormProps>, ref: any) => {
  const { model, data, handleSubmitForm, additionalRenders, nonTitle } = props;
  const methods = useForm();

  const mainPanelsRender = prepareSinglePanels({ formProps: props, data, model });

  const onFinish = useCallback(() => {
    methods.triggerValidation().then((isDataValidation) => isDataValidation && handleSubmitForm(methods.getValues()));
  }, [handleSubmitForm, methods]);

  return (
    <FormContext {...methods}>
      <ExtendedApplicationteForm ref={ref} onFinish={onFinish}>
        {!nonTitle && <LocalTitle>Основные данные</LocalTitle>}
        <LocalPanelsWrapper>{mainPanelsRender}</LocalPanelsWrapper>
      </ExtendedApplicationteForm>
      {additionalRenders}
    </FormContext>
  );
});

export default ApplicationForm;
