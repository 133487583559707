import React from 'react';
import Icon from '@ant-design/icons';

const UserStartedPageIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99998 3.33337C10.884 3.33337 11.7319 3.68456 12.357 4.30968C12.9821 4.93481 13.3333 5.78265 13.3333 6.66671C13.3333 7.55076 12.9821 8.39861 12.357 9.02373C11.7319 9.64885 10.884 10 9.99998 10C9.11592 10 8.26808 9.64885 7.64296 9.02373C7.01784 8.39861 6.66665 7.55076 6.66665 6.66671C6.66665 5.78265 7.01784 4.93481 7.64296 4.30968C8.26808 3.68456 9.11592 3.33337 9.99998 3.33337ZM9.99998 5.00004C9.55795 5.00004 9.13403 5.17564 8.82147 5.4882C8.50891 5.80076 8.33331 6.22468 8.33331 6.66671C8.33331 7.10873 8.50891 7.53266 8.82147 7.84522C9.13403 8.15778 9.55795 8.33337 9.99998 8.33337C10.442 8.33337 10.8659 8.15778 11.1785 7.84522C11.4911 7.53266 11.6666 7.10873 11.6666 6.66671C11.6666 6.22468 11.4911 5.80076 11.1785 5.4882C10.8659 5.17564 10.442 5.00004 9.99998 5.00004ZM9.99998 10.8334C12.225 10.8334 16.6666 11.9417 16.6666 14.1667V16.6667H3.33331V14.1667C3.33331 11.9417 7.77498 10.8334 9.99998 10.8334ZM9.99998 12.4167C7.52498 12.4167 4.91665 13.6334 4.91665 14.1667V15.0834H15.0833V14.1667C15.0833 13.6334 12.475 12.4167 9.99998 12.4167Z"
      fill="currentColor"
    />
  </svg>
);

export const LoginIcon = (props) => <Icon component={() => UserStartedPageIconSVG(props.error)} {...props} />;
