import wkt from 'wkt';
import { getCoords } from '@turf/turf';

export function getCoordsFromParsedGeometry(linePath) {
  //linePath should be geojson object
  try {
    const geometry = wkt.parse(linePath);
    return getCoords(geometry);
  } catch (e) {
    console.log(e, ' error in getCoordsFromParsedGeometry(), maybe input data is not valid');
  }
}
