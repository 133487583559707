import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

export const changeMenuTab = (
  key: string,
  pageMenuKey: IOPWDirectory | null,
  setData: (data: any[]) => void,
  setIdSignDocument: Function,
  directory: string,
  setUrl: Function,
  setPageMenuKey: Function,
  scoreMode: string | undefined,
  urlModel: string
): void => {
  if (key === pageMenuKey) return;

  setData([]);
  setIdSignDocument(null);
  if (key !== 'main') {
    if (directory === IOPWDirectory.write_off_acts || directory === IOPWDirectory.destruction_acts) {
      setUrl(`/${IOPWDirectory.special_permits}/${key}/`);
    } else {
      setUrl(`/${IOPWDirectory.special_permits}/${directory}/${key}/`);
    }
    setPageMenuKey(key);
  } else if (scoreMode === IOPWDirectory.vehicle_parameters) {
    setUrl(urlModel);
    setPageMenuKey(scoreMode);
  } else {
    setUrl(urlModel);
    setPageMenuKey(IOPWDirectory.main);
  }
};
