import React from 'react';
import Icon from '@ant-design/icons';

const HomeIconSVG = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 4.2675L12.75 7.6425V13.5H11.25V9H6.75V13.5H5.25V7.6425L9 4.2675ZM9 2.25L1.5 9H3.75V15H8.25V10.5H9.75V15H14.25V9H16.5L9 2.25Z"
      fill="currentColor"
    />
  </svg>
);

export const HomeIcon = (props) => <Icon component={HomeIconSVG} {...props} />;
