import { LayerCardPrototype } from '../prototype/LayerCardPrototype';

export class ElectricalSubstationCard extends LayerCardPrototype {
  constructor() {
    super({
      label: 'Подстанции',
      linkToPreview: 'Road_charactristics.svg',
      permissionToShowCard: 'layer_permissions.can_view_substations_layer',
    });
  }
}
