import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useCallback, useMemo, useState } from 'react';
import { DatePicker } from 'antd';
import { AnyObject } from 'types/enums/general/general.model';
import { Button } from 'UI/common/components/Button/Button';
import { Model } from 'types/interfaces/net/ModelResponse.model';
import { Styled } from './MainDataFederalStatementCustomView.styled';
import { StatusesApprovalModal, statusApprovalNames } from './ApprovalStatmentContainer';
import moment from 'moment';
import { CheckIcon } from 'UI/custom/icons/actionIcons/CheckIcon';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { TemplateSelectInput } from './TemplateSelectInput';
import {
  FederalStatementStatusEnum,
  FederalStatement,
  SpecialPermitsService,
  FederalStatementApprovedCommentTemplate,
  FederalStatementDeclinedCommentTemplate,
  FederalStatementAdditionalInspectionCommentTemplate,
} from 'generated/api/api';

const {
  SaveChangeButton,
  TextAreaContainer,
  ModalContainer,
  BaseInput,
  BaseInputContainer,
  ApprovalNotificationContainer,
} = Styled;

interface ModalApprovalContainerProps {
  isOpenApprovalModal: boolean | StatusesApprovalModal;
  setOpenApprovalModal: Dispatch<SetStateAction<boolean | StatusesApprovalModal>>;
  object: AnyObject;
  scheme: Model;
  resolutionRouteDescription?: string;
  resolutionSpecialCondition?: string;
  notificationAlert: (e: any) => void;
}

const mainModalFields = ['created_by_name', 'approval_id', 'transporter_author_job_title'];

type SelectedTemplateTypes =
  | FederalStatementApprovedCommentTemplate[]
  | FederalStatementDeclinedCommentTemplate[]
  | FederalStatementAdditionalInspectionCommentTemplate[];

export const ModalApprovalContainer = (props: PropsWithChildren<ModalApprovalContainerProps>) => {
  const [isOpenDoneModal, setIsOpenDoneModal] = useState(false);
  const [responseTemplates, setResponseTemplates] = useState<SelectedTemplateTypes>();
  const [comment, setComment] = useState<string>();

  const {
    isOpenApprovalModal,
    setOpenApprovalModal,
    object,
    scheme,
    resolutionRouteDescription = object.resolution_route_description,
    resolutionSpecialCondition = object.resolution_special_condition,
    notificationAlert,
  } = props;

  const hideModal = useCallback(() => setOpenApprovalModal(false), [setOpenApprovalModal]);

  useEffect(() => {
    (async () => {
      const responseTemplates =
        isOpenApprovalModal === StatusesApprovalModal.approval
          ? SpecialPermitsService.specialPermitsFederalStatementsApprovalCommentTemplatesList()
          : isOpenApprovalModal === StatusesApprovalModal.cancel
          ? SpecialPermitsService.specialPermitsFederalStatementsDeclineCommentTemplatesList()
          : SpecialPermitsService.specialPermitsFederalStatementsAdditionalInspectionCommentTemplatesList();
      const responseTemplatesDone = await responseTemplates;
      // @ts-ignore Ошибка в апи у БЕКа
      setResponseTemplates(responseTemplatesDone?.results ?? responseTemplatesDone);
    })();
  }, [isOpenApprovalModal]);

  const sendResolution = useCallback(async () => {
    try {
      if (isOpenApprovalModal === StatusesApprovalModal.requestMaterials) {
        await SpecialPermitsService.specialPermitsFederalStatementsAdditionalInspectionsCreate({
          // @ts-ignore Ошибка в апи у БЕКа
          body: {
            statement: object.id,
            comment: comment ?? '',
          },
        });
      } else {
        const status =
          isOpenApprovalModal === StatusesApprovalModal.approval
            ? FederalStatementStatusEnum.Approved
            : FederalStatementStatusEnum.Declined;

        const doneFederalStatement: FederalStatement = {
          ...object,
          resolution_route_description: resolutionRouteDescription,
          resolution_special_condition: resolutionSpecialCondition,
          resolution_comment: comment ?? '',
          // @ts-ignore Ошибка в апи у БЕКа
          status,
        };
        await SpecialPermitsService.specialPermitsFederalStatementsUpdate({
          id: object.id,
          body: doneFederalStatement,
        });
      }
    } catch (e) {
      notificationAlert(e);
    } finally {
      setIsOpenDoneModal(true);
      setTimeout(() => {
        setIsOpenDoneModal(false);
        hideModal();
      }, AlertDelay.approvalNotification);
    }
  }, [
    comment,
    hideModal,
    isOpenApprovalModal,
    notificationAlert,
    object,
    resolutionRouteDescription,
    resolutionSpecialCondition,
  ]);

  const changeComment = useCallback((e) => setComment(e.target.value), []);

  const InlineDrawer = useMemo(() => {
    const placeholder =
      isOpenApprovalModal === StatusesApprovalModal.approval
        ? 'Шаблон согласований'
        : isOpenApprovalModal === StatusesApprovalModal.cancel
        ? 'Причина отказ'
        : 'Причины запроса доп. информации';

    return (
      <>
        <BaseInputContainer>
          <DatePicker value={moment(object.request_date)} disabled />
          <p>{scheme.request_date?.label}</p>
        </BaseInputContainer>
        {mainModalFields.map((el) => (
          <BaseInputContainer key={el}>
            <BaseInput value={object[el]} disabled />
            <p>{scheme[el]?.label}</p>
          </BaseInputContainer>
        ))}
        <TemplateSelectInput responseTemplates={responseTemplates} placeholder={placeholder} setComment={setComment} />
        <TextAreaContainer value={comment} onChange={changeComment} rows={6} placeholder={'Комментарий'} />
      </>
    );
  }, [changeComment, comment, isOpenApprovalModal, object, responseTemplates, scheme]);

  const title =
    statusApprovalNames[typeof isOpenApprovalModal === 'string' ? isOpenApprovalModal : StatusesApprovalModal.approval];

  const footerButtonName =
    isOpenApprovalModal === StatusesApprovalModal.approval
      ? 'Согласовать'
      : isOpenApprovalModal === StatusesApprovalModal.cancel
      ? 'Отправить отказ'
      : 'Отправить запрос';

  const infarmationDoneModal =
    isOpenApprovalModal === StatusesApprovalModal.approval
      ? 'Согласование отправлено'
      : isOpenApprovalModal === StatusesApprovalModal.cancel
      ? 'Отказ отправлен'
      : 'Запрос отправлен';

  return isOpenDoneModal ? (
    <ModalContainer
      visible={!!isOpenApprovalModal && isOpenDoneModal}
      onCancel={hideModal}
      centered={true}
      width={338}
      footer={null}
    >
      <ApprovalNotificationContainer>
        <CheckIcon />
        <p>{infarmationDoneModal}</p>
      </ApprovalNotificationContainer>
    </ModalContainer>
  ) : (
    <ModalContainer
      title={title}
      visible={!!isOpenApprovalModal}
      onOk={hideModal}
      onCancel={hideModal}
      centered={true}
      width={338}
      footer={
        <SaveChangeButton>
          <Button className={'shitTrader'} onClick={sendResolution}>
            {footerButtonName}
          </Button>
        </SaveChangeButton>
      }
    >
      {InlineDrawer}
    </ModalContainer>
  );
};
