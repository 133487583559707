import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Modal } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { ApplicationTheme } from 'styles/themes/model/ApplicationTheme.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';

import { PrintEntityOrIndividualStatementLetterModal } from '../CargoTransportForm/ActionButtons/PrintEntityOrIndividualStatementSomeLetter/PrintEntityOrIndividualStatementLetterModal';
import {
  IBreadcrumbsLinks,
  CargoTransportContextModel,
  IIdBlanks,
  CargoTransportParams,
} from '../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import { CargoTransportContext } from '../CargoTransportRouter';
import { IndividualOrdersListDirectory } from './index';
import _ from 'underscore';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';

const { confirm } = Modal;

const DeleteStatementObject = (
  directory: string,
  refreshData: () => void,
  dispatch: (param: any) => void,
  idStatement: number
) => {
  confirm({
    title: 'Вы действительно желаете удалить объект?',
    icon: <QuestionCircleOutlined />,
    onOk() {
      const preDir = directory === IOPWDirectory.responsible_persons ? 'users' : 'special_permits';
      const url = `/${preDir}/${directory}/`;
      dispatch(deleteObject({ url, id: idStatement }));
      refreshData();
    },
  });
};

const {
  IOPWDirectory,
  getFromURL,
  basePath,
  CreateButton,
  RefreshButton,
  CreatBlankStatementButton,
  PrintButtonStatementModal,
  Button,
  ButtonType,
  CopyIcon,
  CheckINNIcon,
  PrintIcon,
  useSaveExel,
  printDocument,
  GetRequest,
  getINNVolition,
  clickSignDocument,
  SignatureIcon,
  UploadTMCFileButton,
  iconSize,
  DeleteIcon,
  useDispatch,
  deleteObject,
  IOPWTabMenuModel,
  pagesNeedsDeleteButton,
  TextIconButton,
  ChangeStatusButton,
  CompendiumWithButton,
  PrintFederalStatementModal,
} = IndividualOrdersListDirectory;

/**
 * Компонент просмотра списка заявлений
 * @returns {JSX.Element}
 * @constructor
 */

const statementsBooks = [IOPWDirectory.individual_statements, IOPWDirectory.entity_statements];
const actsBooks = [IOPWDirectory.write_off_acts, IOPWDirectory.destruction_acts];
const statementBillingsBooks = [IOPWDirectory.ip_billings, IOPWDirectory.org_billings];

export const IndividualOrderList = React.memo(() => {
  const { saveExel } = useSaveExel();
  const history = useHistory<any>();
  const location = useLocation();
  const dispatch = useDispatch();
  const { params } = useRouteMatch<CargoTransportParams>();
  const [data, setData] = useState<any[]>([]);
  const [model, setModel] = useState<ModelResponse>();
  const [idStatement, setIdStatement] = useState<number | undefined>();
  const [idBlanks, setIdBlanks] = useState<IIdBlanks[]>([]);
  const { directory } = params;
  const theme = useTheme() as ApplicationTheme;
  const {
    setIdBlanks: generalSetIdBlanks,
    setMainData,
    setPageMenuKey,
  } = useContext<CargoTransportContextModel>(CargoTransportContext);

  const refreshData = useCallback(async () => {
    await (async () => {
      dispatch({ type: 'APPLICATIONS.START_FETCHING' });
      let urlData = `/${IOPWDirectory.special_permits}/${directory}/`;
      let urlModel = urlData;

      switch (directory) {
        case IOPWDirectory.forms_account:
          urlData += `?write_off_act__isnull=True&destruction_act__isnull=True`;
          break;
        case IOPWDirectory.ip_billings:
          urlData =
            urlModel = `/${IOPWDirectory.special_permits}/${IOPWDirectory.individual_statements}/${IOPWDirectory.billings}`;
          break;
        case IOPWDirectory.org_billings:
          urlData =
            urlModel = `/${IOPWDirectory.special_permits}/${IOPWDirectory.entity_statements}/${IOPWDirectory.billings}`;
          break;
        case IOPWDirectory.responsible_persons:
          urlData = urlModel = `/users/${directory}/`;
          break;
        case IOPWDirectory.additional_inspection_comment_templates:
        case IOPWDirectory.approval_comment_templates:
        case IOPWDirectory.decline_comment_templates:
        case IOPWDirectory.special_condition_comment_templates:
          urlData = urlModel = `/${IOPWDirectory.special_permits}/${IOPWDirectory.federal_statements}/${directory}`;
          break;
        default:
          break;
      }
      try {
        const responseData = getFromURL.getAll(urlData);
        const responseModel = getFromURL.getModelFromURL(urlModel);
        const doneResponseData = await responseData;
        setData(doneResponseData?.results);
        setModel(await responseModel);
      } catch (e) {
        if (_.isObject(e) && e?.response && _.isObject(e.response)) {
          const message = createAlertMessage(e);
          dispatch(
            notificationActions.setGlobalAlertData({
              status: ActiveStatus.active,
              type: PromptType.error,
              title: 'Ошибка',
              message,
            })
          );
          setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
        }
      }
      dispatch({ type: 'APPLICATIONS.END_FETCHING' });
    })();
  }, [directory, dispatch]);

  useEffect(() => {
    refreshData();
  }, [directory, refreshData]);

  const label = useMemo(() => IOPWTabMenuModel[directory], [directory]);

  const moveToPageCreatingNewRequest = useCallback(() => {
    setPageMenuKey(IOPWDirectory.main);
    history.push(`${location.pathname}create/`);
  }, [history, location.pathname, setPageMenuKey]);

  const moveToWriteActsPage = useCallback(() => {
    setMainData(null);
    history.push(`${location.pathname}new_act/${IOPWDirectory.write_off_acts}/`);
    generalSetIdBlanks(idBlanks);
  }, [generalSetIdBlanks, history, idBlanks, location.pathname, setMainData]);

  const moveToDestructionActsPage = useCallback(() => {
    setMainData(null);
    history.push(`${location.pathname}new_act/${IOPWDirectory.destruction_acts}/`);
    generalSetIdBlanks(idBlanks);
  }, [generalSetIdBlanks, history, idBlanks, location.pathname, setMainData]);

  const federalStatementsPage = useMemo(() => directory === IOPWDirectory.federal_statements, [directory]);
  const blankPage = useMemo(() => directory === IOPWDirectory.forms_account, [directory]);
  const isOpenActsBooks = useMemo(() => actsBooks.some((s) => s === directory), [directory]);
  const statementBillingsPage = useMemo(() => statementBillingsBooks.some((s) => s === directory), [directory]);
  const formsAccountPage = useMemo(() => directory === IOPWDirectory.forms_account, [directory]);
  const isOpenStatementsBooks = useMemo(() => statementsBooks.some((s) => s === directory), [directory]);
  const gridCellSizes = useMemo(() => ({ minWidth: 70, width: 340, maxWidth: 700 }), []);
  const isVisibleDirectoryDeleteButtton = useMemo(
    () => pagesNeedsDeleteButton.some((s) => s === directory),
    [directory]
  );

  const buttons = useMemo(
    () => (
      <>
        {!statementBillingsPage && !formsAccountPage && !federalStatementsPage && (
          <CreateButton moveToPageCreating={moveToPageCreatingNewRequest} tooltip={'Добавление нового заявления'} />
        )}
        <RefreshButton refreshData={refreshData} tooltip={'Обновить страницу'} />
        {isOpenStatementsBooks && (
          <>
            <Button
              rounded
              hasShadow={true}
              type={ButtonType.danger}
              p={'0.7rem'}
              icon={<CheckINNIcon />}
              iconColor={theme.colors.primary}
              iconSize={iconSize}
              onClick={() => getINNVolition(data, directory, dispatch, idStatement)}
              tooltip="Проверить действительность ИНН"
              disabled={!idStatement}
            />
            <PrintButtonStatementModal
              isDisabled={!idStatement}
              tooltip="Распечатать заявление"
              directory={directory}
              idStatement={idStatement}
            />
            <Button
              rounded
              hasShadow={true}
              type={ButtonType.danger}
              p={'0.5rem'}
              icon={<CopyIcon />}
              iconColor={theme.colors.primary}
              onClick={() =>
                GetRequest(
                  `${basePath}/${IOPWDirectory.special_permits}/${directory}/${idStatement}/copy/`,
                  dispatch,
                  refreshData,
                  'Заявление скопированно!'
                )
              }
              tooltip="Скопировать заявление"
              disabled={!idStatement}
            />
            <PrintEntityOrIndividualStatementLetterModal
              isDisabled={!idStatement}
              tooltip="Выгрузка файлов"
              idStatement={idStatement}
              directory={directory}
            />
            <ChangeStatusButton
              data={data}
              model={model}
              directory={directory}
              idStatement={idStatement}
              isDisabled={!idStatement}
              tooltip={'Изменить статус заявления'}
              refreshData={refreshData}
            />
          </>
        )}
        {isOpenActsBooks && (
          <Button
            rounded
            hasShadow={true}
            type={ButtonType.danger}
            p={'0.7rem'}
            icon={<PrintIcon />}
            iconColor={theme.colors.primary}
            iconSize={iconSize}
            onClick={() =>
              printDocument(
                `${basePath}/${IOPWDirectory.special_permits}/${directory}/${idStatement}/print/`,
                saveExel,
                dispatch
              )
            }
            tooltip="Распечатать акт"
            disabled={!idStatement}
          />
        )}
        {blankPage && !isOpenActsBooks && (
          <>
            <CreatBlankStatementButton
              tooltip={'Сгенерировать новые бланки заявлений для перевозки грузов'}
              refreshData={refreshData}
            />
            <TextIconButton
              clickButton={moveToWriteActsPage}
              tooltip={'Сформировать акт списания'}
              isDisabled={!idBlanks.length}
              labelText={'Акт списания'}
            />
            <TextIconButton
              clickButton={moveToDestructionActsPage}
              tooltip={'Отправка счета на проверку'}
              isDisabled={!idBlanks.length}
              labelText={'Акт уничтожения'}
            />
          </>
        )}
        {federalStatementsPage && (
          <>
            <Button
              rounded
              hasShadow={true}
              type={ButtonType.danger}
              p={'0.7rem'}
              icon={<CheckINNIcon />}
              iconColor={theme.colors.primary}
              iconSize={iconSize}
              onClick={() =>
                GetRequest(
                  `${basePath}/${IOPWDirectory.special_permits}/${directory}/${idStatement}/send_resolution/`,
                  dispatch,
                  refreshData,
                  'Разрешение отправлено!'
                )
              }
              tooltip="Отправить разрешение"
              disabled={!idStatement}
            />
            <Button
              rounded
              hasShadow={true}
              type={ButtonType.dangerDelete}
              icon={<SignatureIcon />}
              iconSize={iconSize}
              iconColor={theme.colors.dangerDelete}
              onClick={() =>
                clickSignDocument(`/${IOPWDirectory.special_permits}/${directory}/${idStatement}/`, dispatch)
              }
              tooltip={'Осуществить электронную подпись'}
              disabled={!idStatement}
            />
            <UploadTMCFileButton
              isDisabled={!idStatement}
              tooltip={'Добавить файл'}
              pageMenuKey={'upload_federal_statement_request'}
              id={`${idStatement}`}
              refreshData={refreshData}
            />
            <PrintFederalStatementModal
              isDisabled={!idStatement}
              tooltip="Распечатать заявление"
              idStatement={idStatement}
            />
          </>
        )}
        {isVisibleDirectoryDeleteButtton && (
          <Button
            rounded
            hasShadow={true}
            type={ButtonType.dangerDelete}
            p={'0.7rem'}
            icon={<DeleteIcon />}
            iconSize={iconSize}
            tooltip={'Удалить объект'}
            disabled={!idStatement}
            onClick={() => (idStatement ? DeleteStatementObject(directory, refreshData, dispatch, idStatement) : null)}
          />
        )}
      </>
    ),
    [
      statementBillingsPage,
      formsAccountPage,
      federalStatementsPage,
      moveToPageCreatingNewRequest,
      refreshData,
      isOpenStatementsBooks,
      theme.colors.primary,
      theme.colors.dangerDelete,
      idStatement,
      directory,
      data,
      model,
      isOpenActsBooks,
      blankPage,
      moveToWriteActsPage,
      idBlanks.length,
      moveToDestructionActsPage,
      isVisibleDirectoryDeleteButtton,
      saveExel,
      dispatch,
    ]
  );

  const breadcrumbsLinks: IBreadcrumbsLinks[] = useMemo(
    () => [
      {
        url: `/app/${IOPWDirectory.special_permits}/`,
        label: 'Перевозка грузов',
      },
      {
        url: '',
        label,
      },
    ],
    [label]
  );

  return (
    <>
      <CompendiumWithButton
        data={data}
        model={model}
        breadcrumbsLinks={breadcrumbsLinks}
        showQuickFilter={false}
        gridCellSizes={gridCellSizes}
        agGridThemeName={'ag-theme-custom-base'}
        namesCollectionForFiltering={[]}
        buttons={buttons}
        isHeader={true}
        selectIdAGGrid={setIdStatement}
        refreshData={refreshData}
        idBlanksData={{ idBlanks, setIdBlanks }}
      />
    </>
  );
});
