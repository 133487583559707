import React from 'react';
import { Styled } from './ButtonExtension.styled';

const { LocalMainWrapper, ExtendedButton } = Styled;

const ButtonExtensions = ({ handleSetExtension }) => {
  const handleSetExt = (e) => {
    handleSetExtension(e.target.value);
  };

  return (
    <LocalMainWrapper>
      <ExtendedButton value={'text/html'} onClick={handleSetExt} type={'primaryReverse'}>
        {'HTML'}
      </ExtendedButton>
      <ExtendedButton
        value={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
        onClick={handleSetExt}
        type={'primaryReverse'}
      >
        {'Excel'}
      </ExtendedButton>
    </LocalMainWrapper>
  );
};

export default ButtonExtensions;
