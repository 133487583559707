import { useSelector } from 'react-redux';
import { StateModel } from 'store/reducers';
import SiderbarContainer from 'UI/common/components/Sidebar/SiderbarContainer';
import { Styled } from './SaiderContainer.styled';

const { SiderContainer } = Styled;

export const SaiderContainer = () => {
  const isSidebarOpened = useSelector((state: StateModel) => state.app.sidebarIsOpened);
  return (
    <SiderContainer collapsed={isSidebarOpened} collapsible trigger={null} width={'270px'} collapsedWidth={60}>
      <SiderbarContainer />
    </SiderContainer>
  );
};
