import CameraMaskPrototype, { MaskObjectList, PolygonConfig } from './prototype/CameraMaskPrototype';
import { ObjectType } from './prototype/ObjectType';
import { DetectionMask, MaskTypeEnum } from 'generated/api/api';

class WhiteTrackMask extends CameraMaskPrototype {
  protected static maskTypeName = 'Заснеженность и колея';
  protected static maskType = MaskTypeEnum.WhiteTrack_detector;
  protected static maskObjectList: Array<MaskObjectList> = [
    {
      type: ObjectType.roiPoints,
      name: 'Область детекции ТС',
      help: 'Выделите на полосе движения область детекции',
    },
    {
      type: ObjectType.controlLinePoints,
      name: 'Линия направления движения',
      help: 'Укажите линией направление движения транспортного средства',
    },
  ];
  protected static polygonConfig: PolygonConfig = {
    ...CameraMaskPrototype.polygonConfig,
    fill: 'rgba(242, 201, 76, 0.08)',
    stroke: '#F2C94C',
  };

  constructor(cameraID: number, mask: DetectionMask | null = null) {
    super(cameraID, mask);
  }

  static getMaskTypeName(): string {
    return WhiteTrackMask.maskTypeName;
  }

  static getMaskType(): string {
    return WhiteTrackMask.maskType;
  }

  static getMaskObjectList(): Array<MaskObjectList> {
    return WhiteTrackMask.maskObjectList;
  }

  get maskTypeName(): string {
    return WhiteTrackMask.maskTypeName;
  }

  get maskType(): MaskTypeEnum {
    return WhiteTrackMask.maskType;
  }

  get maskObjectList(): Array<MaskObjectList> {
    return WhiteTrackMask.maskObjectList;
  }

  get polygonConfig(): PolygonConfig {
    return WhiteTrackMask.polygonConfig;
  }
}

export default WhiteTrackMask;
