import React from 'react';
import { PanelDefaultCard } from '../../../../../../../../../../../../../../UI/common/components/PanelDefaultCard/PanelDefaultCard';
import { DotsIcon } from '../../../../../../../../../../../../../../UI/custom/icons/actionIcons/DotsIcon';
import { Styled } from '../../LayerTemplatesTab.styled';
import { iconSizesKit } from '../../../../../../../../../../../../../../styles/utils/iconSizesKit';

const { CardButton, CardText, CardTextData } = Styled;

const TemplateItemCard = () => {
  return (
    <PanelDefaultCard.Main>
      <PanelDefaultCard.Content>
        <PanelDefaultCard.PresentationArea />
        <PanelDefaultCard.DescriptionArea style={{ margin: `0 4px` }}>
          <CardText>{'Перекрытие для поставок'}</CardText>
          <CardTextData>{'12.05.20 - 19.05.20'}</CardTextData>
        </PanelDefaultCard.DescriptionArea>
        <PanelDefaultCard.ActionArea>
          <CardButton padding={'none'} iconSize={iconSizesKit.small} icon={<DotsIcon />} />
        </PanelDefaultCard.ActionArea>
      </PanelDefaultCard.Content>
    </PanelDefaultCard.Main>
  );
};

export default TemplateItemCard;
