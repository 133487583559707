import { IconLayer } from '@deck.gl/layers';

import MapEventObserver from 'store/rakes/MapEventObserver';
import InstanceLayer from 'types/classes/InstanceLayer';
import { Layers } from 'types/enums/map/layers/Layers';
import { MapObjects } from 'types/enums/map/MapObjects.model';
import { Application, StatusF30Enum } from 'generated/api/api';
import { brigadeApplicationsMapping } from '../../iconMappers/brigadeApplicationsMapping';
import { DSelector } from 'services/map/Dselector/DSelector';

const defaultTypeObj = 'ROAD_SIGN';

class BrigadeApplicationsLayer extends InstanceLayer<Application, any> {
  getPickingInfo(event: any) {
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (this.props.onClickInstance) {
        this.props.onClickInstance(event.info, MapObjects.brigadeApplications, Layers.brigadeApplications);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.data) return [];

    return [
      new IconLayer<Application>({
        id: 'brigade-applications-layer',
        data: this.props.data,
        iconAtlas: `${process.env.PUBLIC_URL}/img/textures/applications.png`,
        iconMapping: brigadeApplicationsMapping,
        opacity: this?.props?.opacity ?? 0,
        pickable: true,
        autoHighlight: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: DSelector.getPositionFromPoint,
        updateTriggers: {
          getIcon: this.props.selectedObject,
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        getIcon: (d) => {
          const status = d?.status ?? StatusF30Enum.NEW;
          const typeObj = d?.object_type ?? defaultTypeObj;
          const name = typeObj + '_' + status;
          return DSelector.getSelectableIcon({
            id: d.id,
            layerState: this.props,
            typeCheck: MapObjects.brigadeApplications,
            selectedMapper: `${name}_ACTIVE`,
            deselectedMapper: name,
          });
        },
      }),
    ];
  }
}

export default BrigadeApplicationsLayer;
