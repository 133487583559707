import React, { useRef } from 'react';
import { Styled } from './style/ToggleButtonGroup.styled';
import _ from 'underscore';
import { ToggleButtonI, ToggleButtonGroupProps } from './model/ToggleButtonGroup.model';

const { StyledButton, StyledWrapper, CountNewMessage } = Styled;

const ToggleButtonGroup = ({
  buttonClickHandler,
  displayedButtons,
  activePanel,
  countNewMessage,
}: ToggleButtonGroupProps) => {
  const iconSize = useRef({ width: '20px', height: '20px' });
  const Buttons = displayedButtons.map((element: ToggleButtonI) => {
    const className = element.switcher === activePanel ? 'active' : 'inactive';
    const iconMargin = className === 'active' ? 0.6 : 0;
    if (element.ignore) return null;
    const showCountNewMessage = !!countNewMessage && element.id === 2 && className !== 'active';
    const count = countNewMessage < 100 ? countNewMessage : '+99';
    return (
      <StyledButton
        iconSize={iconSize.current}
        icon={element.icon}
        iconMargin={iconMargin}
        className={className}
        key={_.uniqueId()}
        onClick={() => buttonClickHandler(element)}
      >
        {showCountNewMessage && <CountNewMessage>{count}</CountNewMessage>}
        {element?.name}
      </StyledButton>
    );
  });

  return <StyledWrapper>{Buttons}</StyledWrapper>;
};

export default ToggleButtonGroup;
