export enum ApplicationsDescription {
  main = 'Сведения о ДУТССД',
  applications = 'Сведения о приложениях',
  teams_of_masters = 'Сведения о мастерах',
  expendable_materials = 'Сведения о расходных материалах',
  materials = 'Сведения о материалах',
  types = 'Сведения о типах',
  teams_of_workers = 'Сведения о рабочих бригадах',
  photos = 'Сведения о фото',
  tasks = 'Сведения о задачах',
  task_objects = 'Сведения об объектах задач',
  responsible_persons = 'Сведения об ответственных лицах',
  source = 'Сведения о источниках заявок',
}
