import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
/*import { ArtificialStructuresService } from '../../../../../../generated/api/api';*/
import { getFromURL } from '../../../../../../api/getFromURL';

export class FerryCrossings extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'ferry_crossings',
      filterLabel: 'Паромные переправы',
      selectedObjectName: MapObjects.ferryCrossings,
    });
  }

  /*
  async uploadData() {
    return await ArtificialStructuresService.artificialStructuresFerryCrossingsList({
      pageSize: Number.MAX_SAFE_INTEGER,
    });
  }

  async deleteRecord(id) {
    return await ArtificialStructuresService.artificialStructuresFerryCrossingsDelete({ id });
  }

  async readRecord(id) {
    return await ArtificialStructuresService.artificialStructuresFerryCrossingsRead({ id });
  }
*/

  async uploadModel() {
    return await getFromURL.getModelFromURL('/artificial_structures/ferry_crossings/');
  }
}
