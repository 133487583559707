import { CompositeLayer } from '@deck.gl/core';
import { IconLayer, ScatterplotLayer } from '@deck.gl/layers';
import { Layers } from '../../../types/enums/map/layers/Layers';
import { MapObjects } from '../../../types/enums/map/MapObjects.model';
import { getCoordsFromParsedGeometry } from '../../../services/map/getCoordsFromParsedGeometry';
import MapEventObserver from '../../../store/rakes/MapEventObserver';
import { objectAllIconMapper } from '../../iconMappers/objectAllIconMapper/objectAllIconMapper';
import { DSelector } from 'services/map/Dselector/DSelector';

class IlluminatedPedestrianCrossingsCompositeLayer extends CompositeLayer {
  updateState({ changeFlags, props }) {
    const selectedLayer = this.props.selectedObject?.selectedObject?.layer;
    if (selectedLayer instanceof IlluminatedPedestrianCrossingsCompositeLayer) {
      this.setState({
        selectedObject: this.props.selectedObject?.selectedObject?.object,
      });
    } else {
      this.setState({ selectedObject: this.props.selectedObject?.selectedObject ?? null });
    }
    this.setState({
      data: props,
    });
  }
  getPickingInfo(event) {
    let selectedObjectType = null;
    let selectedObjectLayerName = Layers.illuminatedPedestrianCrossings;
    if (event.mode === 'query' && !MapEventObserver.checkEventLock()) {
      if (event.sourceLayer.id.includes('illuminated-pedestrian-crossing-point')) {
        selectedObjectType = MapObjects.illuminatedPedestrianCrossings;
        this.props.onClickHandler(event.info, selectedObjectType, selectedObjectLayerName);
      }
    }

    return event.info;
  }

  renderLayers() {
    if (!this.props.relatedData) return [];
    return [
      new IconLayer(this.props, this.getSubLayerProps({ id: 'illuminated-pedestrian-crossing-point' }), {
        data: this.props.relatedData.illuminated_pedestrian_crossings,
        iconAtlas: `${process.env.PUBLIC_URL}/img/allObjects.png`,
        iconMapping: objectAllIconMapper,
        opacity: this?.props?.opacity ?? 0,
        pickable: true,
        autoHighlight: true,
        fp64: true,
        getPolygonOffset: DSelector.getNewPolygonOffset,
        getPosition: (d) => {
          if (d.coordinates) {
            return getCoordsFromParsedGeometry(d.coordinates);
          } else return [0, 0];
        },
        sizeScale: this?.props?.sizeScale ?? 1,
        getSize: () => 25,
        updateTriggers: {
          getIcon: this.state.selectedObject,
          getSize: this.context?.viewport?.zoom,
        },
        getIcon: (d) => {
          if (this.state.data?.instance?.id === d.id) {
            return null;
          }
          return this.props?.selectedObject?.selectedObject?.id === d?.id ||
            d.id === this.props?.selectedObject?.selectedObject?.object?.id
            ? `crosswalk_active`
            : 'crosswalk';
        },
      }),
    ];
  }
}

const defaultProps = {
  // Inherit all of GeoJsonLayer's props
  ...ScatterplotLayer.defaultProps,
  // Label for each feature
  getLabel: { type: 'accessor', value: (x) => x },
  // Label size for each feature
  getLabelSize: { type: 'accessor', value: 8 },
  // Label color for each feature
  getLabelColor: { type: 'accessor', value: [0, 0, 0, 255] },
  getCurrentTimeStamp: { type: 'accessor', value: 0 },
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: 'pixels',
  // Label background color
  labelBackground: { type: 'color', value: [255, 255, 255], optional: true },
  // Label font
  fontFamily: 'Monaco, monospace',
};

IlluminatedPedestrianCrossingsCompositeLayer.layerName = 'IlluminatedPedestrianCrossingPoint';
IlluminatedPedestrianCrossingsCompositeLayer.defaultProps = defaultProps;

export default IlluminatedPedestrianCrossingsCompositeLayer;
