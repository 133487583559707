import { LayerPrototype } from '../description/prototype/LayerPrototype';
import { Layers } from '../../../../types/enums/map/layers/Layers';
import { relatedLayerData } from '../description/relatedData';
import { layerSchemas } from '../description/layerSchemaPresets';
import { layerDataAccessors } from '../description/dataAccessors';
import { layerSettings } from '../description/settings';
import { ekbLayerCards } from '../description/cards/ekb';
import ProjectLayerItemSelection from 'layers/SelectedObjectsRepresentations/ProjectLayerItemSelection/ProjectLayerItemSelection';
import { projectLayerGroups } from '../description/layerGroups';

const defaultIconSizeScale = 0.3;

export class ProjectLayer extends LayerPrototype {
  constructor() {
    super({
      name: Layers.projectLayer,
      editable: true,
      mainData: null,
      dataAccessor: new layerDataAccessors.ekbLayerDataAccessors.ProjectLayerDataAccessor(),
      relatedData: [
        new relatedLayerData.ekbRelatedDataForLayers.ProjectMarkingLine(),
        new relatedLayerData.ekbRelatedDataForLayers.ProjectRoadSigns(),
        new relatedLayerData.ekbRelatedDataForLayers.PedBorderProject(),
        new relatedLayerData.ekbRelatedDataForLayers.SpeedBumpProject(),
        new relatedLayerData.ekbRelatedDataForLayers.StreetLampProject(),
        new relatedLayerData.ekbRelatedDataForLayers.TrafficLightsProject(),
        new relatedLayerData.ekbRelatedDataForLayers.SignalPolesProject(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadMarkingPolygonProject(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadMarkingSymbolProject(),
        new relatedLayerData.ekbRelatedDataForLayers.RoadwayProject(),
        new relatedLayerData.ekbRelatedDataForLayers.SidewalkProject(),
      ],
      layerGroup: new projectLayerGroups.UdsLayerGroup(),
      card: new ekbLayerCards.ProjectCard(),
      customSettings: [new layerSettings.OpacitySetting(), new layerSettings.IconSizeScale(defaultIconSizeScale)],
      layerSchema: new layerSchemas.ekbLayersSchema.ProjectLayerSchema(),
      selectedObjectPrototype: ProjectLayerItemSelection,
    });
  }
}
