import React from 'react';
import Icon from '@ant-design/icons';

const QuestionIconSVG = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.1665 14H9.83317V12.3333H8.1665V14ZM8.99984 0.666668C7.90549 0.666668 6.82186 0.882216 5.81081 1.30101C4.79976 1.71979 3.8811 2.33362 3.10728 3.10744C1.54448 4.67025 0.666504 6.78986 0.666504 9C0.666504 11.2101 1.54448 13.3298 3.10728 14.8926C3.8811 15.6664 4.79976 16.2802 5.81081 16.699C6.82186 17.1178 7.90549 17.3333 8.99984 17.3333C11.21 17.3333 13.3296 16.4554 14.8924 14.8926C16.4552 13.3298 17.3332 11.2101 17.3332 9C17.3332 7.90565 17.1176 6.82202 16.6988 5.81097C16.28 4.79993 15.6662 3.88127 14.8924 3.10744C14.1186 2.33362 13.1999 1.71979 12.1889 1.30101C11.1778 0.882216 10.0942 0.666668 8.99984 0.666668V0.666668ZM8.99984 15.6667C5.32484 15.6667 2.33317 12.675 2.33317 9C2.33317 5.325 5.32484 2.33333 8.99984 2.33333C12.6748 2.33333 15.6665 5.325 15.6665 9C15.6665 12.675 12.6748 15.6667 8.99984 15.6667ZM8.99984 4C8.11578 4 7.26794 4.35119 6.64281 4.97631C6.01769 5.60143 5.6665 6.44928 5.6665 7.33333H7.33317C7.33317 6.89131 7.50877 6.46738 7.82133 6.15482C8.13389 5.84226 8.55781 5.66667 8.99984 5.66667C9.44187 5.66667 9.86579 5.84226 10.1783 6.15482C10.4909 6.46738 10.6665 6.89131 10.6665 7.33333C10.6665 9 8.1665 8.79167 8.1665 11.5H9.83317C9.83317 9.625 12.3332 9.41667 12.3332 7.33333C12.3332 6.44928 11.982 5.60143 11.3569 4.97631C10.7317 4.35119 9.88389 4 8.99984 4Z"
      fill="#929FB5"
    />
  </svg>
);

export const QuestionIcon = (props) => <Icon component={QuestionIconSVG} {...props} />;
