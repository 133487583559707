import { LayerSchemaPrototype } from '../prototype/LayerSchemaPrototype';
import RoadAccidentsHeatmapCompositeLayer from 'layers/CompositeLayers/ekb/RoadAccidents/RoadAccidentsHeatmapCompositeLayer';
import RoadAccidentsPointsCompositeLayer from 'layers/CompositeLayers/ekb/RoadAccidents/RoadAccidentsPointsCompositeLayer';
import RoadAccidentsHexagonCompositeLayer from 'layers/CompositeLayers/ekb/RoadAccidents/RoadAccidentsHexagonCompositeLayer';
import RoadAccidentsClustersPointsCompositeLayer from 'layers/CompositeLayers/ekb/RoadAccidents/RoadAccidentsClustersPointsCompositeLayer';

export class RoadAccidentsLayerSchema extends LayerSchemaPrototype {
  constructor() {
    super({
      layerToRender: RoadAccidentsPointsCompositeLayer,
      possibleLayersToRender: [
        { compositeLayer: RoadAccidentsPointsCompositeLayer, label: 'Точки' },
        { compositeLayer: RoadAccidentsClustersPointsCompositeLayer, label: 'Точки и кластеры' },
        { compositeLayer: RoadAccidentsHexagonCompositeLayer, label: 'Гексагон диаграмма' },
        { compositeLayer: RoadAccidentsHeatmapCompositeLayer, label: 'Тепловая карта' },
      ],
      id: 'RoadAccidents',
    });
  }
}
