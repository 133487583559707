import { call, put, takeEvery } from 'redux-saga/effects';
import { CamerasService } from 'generated/api/api';
import { widgetsActions } from 'store/reducers/widgets/widgets.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AnyObject } from 'types/enums/general/general.model';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';

const LOAD_ALL_WIDGETS_SAGA = 'LOAD_ALL_WIDGETS_SAGA';

export const loadAllWidgetsSaga = () => ({
  type: LOAD_ALL_WIDGETS_SAGA,
});

function* setWidgets() {
  try {
    //@ts-ignore
    const { results } = yield call(() => CamerasService.camerasWidgetList());
    yield put(widgetsActions.setWidgets(results));
  } catch (e) {
    yield put(
      notificationActions.setGlobalAlertData({
        status: ActiveStatus.active,
        type: PromptType.error,
        title: 'Ошибка',
        message: createAlertMessage(e as AnyObject),
      })
    );
  }
}

export function* watchWidgetsSage() {
  yield takeEvery(LOAD_ALL_WIDGETS_SAGA, setWidgets);
}
