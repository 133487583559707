import RoadMarksMask from './RoadMarksMask';
import SpeedCounterCarMask from './SpeedCounterCarMask';
import ObjectCounterCarMask from './ObjectCounterCarMask';
import ObjectCounterPedestriansMask from './ObjectCounterPedestriansMask';
import RoadSignsMask from './RoadSignsMask';
import WhiteTrackMask from './WhiteTrackMask';

export type MaskType =
  | SpeedCounterCarMask
  | RoadMarksMask
  | ObjectCounterCarMask
  | ObjectCounterPedestriansMask
  | RoadSignsMask
  | WhiteTrackMask;

const maskRegistrator = [
  RoadSignsMask,
  RoadMarksMask,
  ObjectCounterPedestriansMask,
  ObjectCounterCarMask,
  SpeedCounterCarMask,
  WhiteTrackMask,
];

export const getAllRegisteredMask = () => maskRegistrator;

export const getRegisteredMaskForType = (type: string) => {
  return maskRegistrator.find((el) => el.getMaskType() === type);
};
