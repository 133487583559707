import React, { useRef } from 'react';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import printJS from 'print-js';
import { Button } from '../../UI/common/components/Button/Button';
import { iconSizesKit } from '../../styles/utils/iconSizesKit';
import { useHistory } from 'react-router-dom';
import { Styled } from './Print.styled';

const { ButtonWrapper } = Styled;

const Print = () => {
  const history = useHistory();
  const cropperRef = useRef(null);
  const stopRef = useRef();
  const dataPrint = localStorage.getItem('print');

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (stopRef.current) {
      printJS({
        printable: cropper.getCroppedCanvas().toDataURL(),
        type: 'image',
        showModal: true,
      });
      stopRef.current = false;
    }
  };
  const handlerClick = () => {
    stopRef.current = true;
    onCrop();
  };

  const handlerBack = () => {
    history.goBack();
  };
  return (
    <>
      <ButtonWrapper>
        <Button onClick={handlerClick} type={'primary'} p={0.5} iconSize={iconSizesKit.huge}>
          Отправить на печать
        </Button>
        <Button onClick={handlerBack} type={'primary'} p={0.5} iconSize={iconSizesKit.huge}>
          Назад
        </Button>
      </ButtonWrapper>
      <Cropper src={dataPrint} style={{ height: '100%', width: '100%' }} guides={true} crop={onCrop} ref={cropperRef} />
    </>
  );
};

export default Print;
