import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { AnyObject } from 'types/enums/general/general.model';
import { ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import { CargoTransportContext } from '../../../CargoTransportRouter';
import { Styled as ApplicationFormStyled } from '../../ApplicationForm/ApplicationForm.styled';
import {
  CargoTransportContextModel,
  CargoTransportParams,
} from '../../interfacesCargoTransport/interfacesCargoTransport';
import { ApprovalStatmentContainer } from './ApprovalStatmentContainer';
import { Styled } from './MainDataFederalStatementCustomView.styled';
import { MainStatmentDataContainer } from './MainStatmentDataContainer';
import {
  CalculationOfHarmFederalStatement,
  FederalStatementVehicle,
  FederalStatementVehicleAxis,
  SpecialPermitsService,
} from 'generated/api/api';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';
import { getFromURL } from 'api/getFromURL';

const { LocalTitle } = ApplicationFormStyled;
const { MainContainer, MainDataContainer } = Styled;

interface MainDataFederalStatementCustomViewProps {
  model: ModelResponse;
  data: AnyObject;
  notificationAlert: (e: any) => void;
  urlModel: string;
}

export const MainDataFederalStatementCustomView = ({
  urlModel,
  model,
  data,
  notificationAlert,
}: MainDataFederalStatementCustomViewProps) => {
  const dispatch = useDispatch();
  const { params } = useRouteMatch<CargoTransportParams>();
  const { id } = params;
  const { pageMenuKey } = useContext<CargoTransportContextModel>(CargoTransportContext);
  const [mainData, setMainData] = useState<AnyObject>();
  const [mainModel, setMainModel] = useState<ModelResponse>();
  const [vehiclesData, setVehiclesData] = useState<FederalStatementVehicle[]>();
  const [vehiclesModel, setVehiclesModel] = useState<ModelResponse>();
  const [vehiclesAxisData, setVehiclesAxisData] = useState<FederalStatementVehicleAxis[]>();
  const [vehiclesAxisModel, setVehiclesAxisModel] = useState<ModelResponse>();
  const [calcOfHarmData, setCalcOfHarmData] = useState<CalculationOfHarmFederalStatement[]>();
  const [calcOfHarmModel, setCalcOfHarmModel] = useState<ModelResponse>();

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: 'APPLICATIONS.START_FETCHING' });
        if (id) {
          if (pageMenuKey === IOPWDirectory.main) {
            setMainModel(model);
            setMainData(data);
          } else {
            const responseModel = getFromURL.getModelFromURL(urlModel);
            const responseData = SpecialPermitsService.specialPermitsFederalStatementsRetrieve({ id: +id });
            const doneResponseData = await responseData;
            setMainModel(await responseModel);
            setMainData(doneResponseData);
          }
          // Дергаем все ТС по заявлению
          const vehiclesResponse = SpecialPermitsService.specialPermitsFederalStatementsVehiclesList({ statement: id });
          const responseVehiclesModel = getFromURL.getModelFromURL(
            `/${IOPWDirectory.special_permits}/${IOPWDirectory.federal_statements}/${IOPWDirectory.vehicles}/`
          );

          // Дергаем все оси ТС по id ТС
          const responseVehiclesAxisModel = getFromURL.getModelFromURL(
            `/${IOPWDirectory.special_permits}/${IOPWDirectory.federal_statements}/${IOPWDirectory.vehicle_axis}/`
          );

          const doneResponseData = await vehiclesResponse;
          // @ts-ignore Ошибка в апи у БЕКа
          const vehiclesData: FederalStatementVehicle[] = doneResponseData?.results.reverse() ?? doneResponseData;

          const vehiclesAxisResponse = Promise.all(
            vehiclesData.map((el) =>
              SpecialPermitsService.specialPermitsFederalStatementsVehicleAxisList({ vehicleParameters: `${el.id}` })
            )
          );

          // Дергаем расчет вреда по по заявлению
          const responseСalcOfHarmModel = getFromURL.getModelFromURL(
            `/${IOPWDirectory.special_permits}/${IOPWDirectory.federal_statements}/${IOPWDirectory.calc_of_harm}/`
          );
          const calcOfHarmResponse = SpecialPermitsService.specialPermitsFederalStatementsCalcOfHarmList({
            statement: id,
          });
          const calcOfHarmResponseData = await calcOfHarmResponse;
          const calcOfHarmData: CalculationOfHarmFederalStatement[] =
            // @ts-ignore Ошибка в апи у БЕКа
            calcOfHarmResponseData?.results.reverse() ?? calcOfHarmResponse;

          const vehiclesAxisResponseData = await vehiclesAxisResponse;

          setVehiclesModel(await responseVehiclesModel);
          setVehiclesData(vehiclesData);
          // @ts-ignore Ошибка в апи у БЕКа
          setVehiclesAxisData(vehiclesAxisResponseData.flatMap((axis) => axis.results.reverse()));
          setVehiclesAxisModel(await responseVehiclesAxisModel);
          setCalcOfHarmData(calcOfHarmData);
          setCalcOfHarmModel(await responseСalcOfHarmModel);
        }
      } catch (e) {
        notificationAlert(e);
      } finally {
        dispatch({ type: 'APPLICATIONS.END_FETCHING' });
      }
    })();
  }, [data, dispatch, id, model, notificationAlert, pageMenuKey, urlModel]);

  const { scheme } = mainModel ?? {};
  const vehicleScheme = vehiclesModel?.scheme;
  const vehicleAxisScheme = vehiclesAxisModel?.scheme;
  const calcOfHarmScheme = calcOfHarmModel?.scheme;

  if (!mainData || !scheme || !vehicleScheme || !vehicleAxisScheme || !calcOfHarmScheme) return null;

  return (
    <MainContainer>
      <LocalTitle>Основные данные</LocalTitle>
      <MainDataContainer>
        <MainStatmentDataContainer
          mainData={mainData}
          scheme={scheme}
          vehiclesData={vehiclesData}
          vehicleScheme={vehicleScheme}
          vehiclesAxisData={vehiclesAxisData}
          vehicleAxisScheme={vehicleAxisScheme}
          calcOfHarmData={calcOfHarmData}
          calcOfHarmScheme={calcOfHarmScheme}
        />
        <ApprovalStatmentContainer notificationAlert={notificationAlert} mainData={mainData} scheme={scheme} />
      </MainDataContainer>
    </MainContainer>
  );
};
