import React from 'react';
import Icon from '@ant-design/icons';

const PipelinesIconSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3327 11.6665H16.666V13.3332H11.666V10.8332H13.3327V9.1665H11.666V4.99984C11.666 4.55781 11.4904 4.13389 11.1779 3.82133C10.8653 3.50877 10.4414 3.33317 9.99935 3.33317H3.33268V1.6665H1.66602V8.33317H3.33268V6.6665H8.33268V9.1665H6.66602V10.8332H8.33268V14.9998C8.33268 15.4419 8.50828 15.8658 8.82084 16.1783C9.1334 16.4909 9.55732 16.6665 9.99935 16.6665H16.666V18.3332H18.3327"
      fill="#6B8AE4"
    />
  </svg>
);

export const PipelinesIcon = (props) => <Icon component={PipelinesIconSVG} {...props} />;
