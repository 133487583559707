import styled from 'styled-components';

const PrintMenuWrapper = styled.div`
  position: fixed;
  top: 16px;
  left: 16px;
  bottom: 33px;
  width: 272px;
  background-color: #ffffff;
  border-radius: 8px 8px;
  z-index: 5;
`;
const PrintMenuHeader = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  box-shadow: 0px 2px 8px rgba(107, 138, 228, 0.12);
  padding: 16px;
  p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #495a82;
  }
`;
const PrintMenuBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 130px);
  padding: 16px;
`;
const PrintMenuInput = styled.input`
  width: 100%;
  height: 40px;
  background: #f1f3f6;
  border-radius: 8px;
  padding: 10px 10px 10px 14px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  position: relative;
  border: none;
  color: #929fb5;
  outline: none;
  ::placeholder {
    color: #929fb5;
  }
`;
const PrintMenuInputBlock = styled.div`
  margin-bottom: 32px;
  p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 140%;
    color: #929fb5;
    margin-bottom: 16px;
  }
`;
const PrintMenuButton = styled.div`
  width: 100%;
  height: 40px;
  padding: 11px 34px;
  background: #6b8ae4;
  border-radius: 8px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;
const PrintMenuFooter = styled.div`
  padding: 16px;
`;
const PrintMenuFooterButton = styled.div`
  width: 100%;
  height: 40px;
  padding: 11px 34px;
  background: #6b8ae4;
  border-radius: 8px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;
const RedoOutlinedIco = styled.div`
  cursor: pointer;
  transform: rotate(180deg);
`;

const PrintMenuAnotherToggle = styled.div``;

export {
  PrintMenuWrapper,
  PrintMenuHeader,
  PrintMenuBody,
  PrintMenuInput,
  PrintMenuButton,
  PrintMenuInputBlock,
  PrintMenuFooter,
  PrintMenuFooterButton,
  RedoOutlinedIco,
  PrintMenuAnotherToggle,
};
