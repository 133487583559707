/* tslint:disable:no-commented-out-code */
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import moment from 'moment';
import { SchemaFieldType } from 'types/enums/routes/request/model/SchemaFieldType.model';
import { ExtendedColDef } from 'types/interfaces/agGrid/ModifiedColDef.model';
import { Model, ModelResponse } from 'types/interfaces/net/ModelResponse.model';
import _ from 'underscore';

import { DefaultGridColumn } from './model/DefaultGridColumn.model';

/**
 *
 * @param object
 * @param field
 * @param sort
 * @returns {{headerName: *, field: *, editable: boolean}}
 */
function getBaseColumnTemplateFields(object: Model, field: string, sort?: 'asc' | 'desc') {
  const template: DefaultGridColumn = {
    headerName: object[field].label,
    field,
    editable: object[field].editable,
  };
  if (sort) {
    template.sort = sort;
  }
  // блок скрытия из грида
  if (object[field]?.hidden) {
    template.hide = true;
    template.enableValue = false;
    template.enableRowGroup = false;
  }
  if (object[field]?.order) {
    template.order = object[field]?.order;
  }
  if (object[field].checkboxSelection) {
    template.checkboxSelection = object[field].checkboxSelection;
  }
  return template as DefaultGridColumn;
}

// пока оставить, шаблон ссылки на файл * без проблем, но неиспользуемые функции вызываюь ворнинги *
// noinspection JSUnusedLocalSymbols
// function getHrefBaseColumnTemplateFields(object: Model, field: string, sort?: 'asc' | 'desc') {
// tslint:disable-next-line:no-commented-out-code
//   const template: DefaultGridColumn = {
//     headerName: 'Ссылка на файл',
//     field: field,
//     editable: !object[field].read_only,
//   };
//   if (sort) {
//     template.sort = sort;
//   }
//   return template;
// }

export const defineColumnsDefinitions = (
  data: ModelResponse
): { result: ExtendedColDef[]; nested: ExtendedColDef[] } => {
  let result: ExtendedColDef[] = [];
  let nested: ExtendedColDef[] = [];
  const scheme = data?.scheme;
  try {
    for (const field in scheme) {
      switch (scheme[field].type) {
        case SchemaFieldType.object: {
          result.push({
            headerName: scheme[field].label,
            field,
            cellRenderer: 'objectCellRenderer',
            valueGetter(params: ValueGetterParams) {
              if (params.data && params.data[field]) {
                return 'Объект=>';
              } else return ' ';
            },
            additionalData: {
              field,
              filtered: false,
              group: false,
              order: scheme[field]?.order,
            },
            editable: false,
            enableValue: false,
            enableRowGroup: false,
            sortable: false,
          });
          break;
        }
        case SchemaFieldType.field: {
          if (field.includes('_detail')) continue;
          if (field.includes('_name')) continue;
          if (field.includes('line')) {
            result.push({
              ...getBaseColumnTemplateFields(scheme, field),
              cellRenderer: 'lineIconCellRenderer',
            });
            continue;
          }
          result.push({
            headerName: scheme[field].label,
            field,
            cellRenderer: 'objectCellRenderer',
            valueGetter(params: ValueGetterParams) {
              if (params.data && params.data[field]) {
                return 'Объект=>';
              } else return ' ';
            },
            additionalData: {
              url: scheme[field + '_detail']?.url,
              id: scheme[field]?.id,
              label: scheme[field + '_name']?.label,
              order: scheme[field]?.order,
            },
            editable: false,
            enableValue: false,
            enableRowGroup: true,
            sortable: false,
          });
          break;
        }
        case SchemaFieldType.datetime:
        case SchemaFieldType.date: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            valueGetter(params: ValueGetterParams) {
              if (params.data) {
                const date = moment(params.data[field]);
                return date.isValid() ? date.format('DD.MM.YY, hh:mm') : 'Отсутствует';
              } else return ' ';
            },
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator(filterLocalDateAtMidnight: Date, cellValue: string) {
                const dateAsString = cellValue;
                if (dateAsString === 'Отсутствует') return -1;
                const dateParts = dateAsString.split(' ');
                const day = dateParts[0].slice(0, 2);
                // tslint:disable-next-line:no-magic-numbers
                const moths = dateParts[0].slice(3, 5);
                // tslint:disable-next-line:no-magic-numbers
                const year = '20' + dateParts[0].slice(6, 8);
                const cellDate = new Date(+year, +moths - 1, +day);
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                } else if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
                return 0;
              },
              browserDatePicker: true,
              inRangeInclusive: true,
            },
            comparator(valueA: string, valueB: string) {
              const valueAParsed = moment(valueA, 'DD.MM.YY, hh:mm');
              const valueBParsed = moment(valueB, 'DD.MM.YY, hh:mm');
              const isAfter = valueAParsed.isAfter(valueBParsed);
              const isBefore = valueAParsed.isBefore(valueBParsed);
              // if not date comparators
              if (!valueAParsed.isValid() && !valueBParsed.isValid()) {
                return -1;
              } else if (!valueAParsed.isValid() && valueBParsed.isValid()) {
                return -1;
              } else if (valueAParsed.isValid() && !valueBParsed.isValid()) {
                return 1;
              }
              // if both date comparators
              if (isBefore) {
                return -1;
              } else if (isAfter) {
                return 1;
              } else {
                return 0;
              }
            },
          });
          break;
        }
        case SchemaFieldType.boolean: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            chartDataType: 'series',
            valueGetter(params: ValueGetterParams) {
              if (params.data) {
                return params.data[field];
              } else return ' ';
            },
            cellRenderer: 'booleanCellRenderer',
          });
          break;
        }
        case SchemaFieldType.rgbaColor: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            chartDataType: 'series',
            valueGetter(params: ValueGetterParams) {
              return params.data ? params.data[field] : '';
            },
            cellRenderer: 'rgbaCellRenderer',
          });
          break;
        }
        case SchemaFieldType.fileUpload: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field, 'asc'),
            valueGetter(params: ValueGetterParams) {
              const time = moment(params.data[field]);
              return time.isValid() ? time.format('DD.MM.YYYY, HH:mm') : 'Отсутствует';
            },
          });
          break;
        }
        case SchemaFieldType.choice:
        case SchemaFieldType.multichoice: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field, 'asc'),
            valueGetter(params: ValueGetterParams) {
              let newValue = '';
              const valueField = params?.data?.[field];
              if (scheme[field].choices?.length) {
                scheme[field].choices?.forEach((choice) => {
                  if (_.isArray(valueField) && valueField.length) {
                    valueField.forEach((value) => {
                      if (choice?.value === value) {
                        newValue += `${choice.display_name}, `;
                      }
                    });
                  } else if (choice?.value === valueField) {
                    newValue = choice.display_name;
                  }
                });
              }
              return newValue || valueField;
            },
          });
          break;
        }
        case SchemaFieldType.integer: {
          if (field.toLowerCase() === 'id') {
            continue;
          } else
            result.push({
              ...getBaseColumnTemplateFields(scheme, field),
              filter: 'agNumberColumnFilter',
              chartDataType: 'series',
            });
          break;
        }
        case SchemaFieldType.float: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            filter: 'agNumberColumnFilter',
            chartDataType: 'series',
            enableValue: true,
          });
          break;
        }
        case SchemaFieldType.string: {
          if (field.includes('name')) {
            result.push({
              ...getBaseColumnTemplateFields(scheme, field, 'asc'),
              chartDataType: 'category',
            });
          } else {
            result.push(getBaseColumnTemplateFields(scheme, field, 'asc'));
          }
          break;
        }
        case SchemaFieldType.href: {
          if (field.toLowerCase() === 'errors_href') {
            result.push({
              ...getBaseColumnTemplateFields(scheme, field, 'asc'),
              chartDataType: 'series',
              valueGetter(params: ValueGetterParams) {
                if (params.data) {
                  return scheme[field];
                } else return ' ';
              },
              cellRenderer: 'linkCellRenderer',
              additionalData: { field },
            });
          }
          break;
        }
        case SchemaFieldType.busScheduleArray: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field, 'asc'),
            cellRenderer: 'busScheduleArrayRenderer',
            additionalData: { field },
          });
          break;
        }
        case SchemaFieldType.relatedField: {
          if (field === 'application') {
            result.push({
              ...getBaseColumnTemplateFields(scheme, field),
              filter: 'agNumberColumnFilter',
              chartDataType: 'series',
              enableValue: true,
            });
          }
          break;
        }
        case SchemaFieldType.point:
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            cellRenderer: 'pointIconCellRenderer',
          });
          break;
        case SchemaFieldType.lineString:
        case SchemaFieldType.linePath:
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            cellRenderer: 'lineIconCellRenderer',
          });
          break;
        case SchemaFieldType.polygon:
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            cellRenderer: 'polygonIconCellRenderer',
          });
          break;
        case SchemaFieldType.geometry:
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
            cellRenderer: 'geometryIconCellRenderer',
          });
          break;
        case SchemaFieldType.stringRelatedField:
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
          });
          break;
        case SchemaFieldType.email:
        case SchemaFieldType.length:
        case SchemaFieldType.json:
        case SchemaFieldType.snils:
        case SchemaFieldType.inn:
        case SchemaFieldType.kpp:
        case SchemaFieldType.okved:
        case SchemaFieldType.okpo:
        case SchemaFieldType.ogrn:
        case SchemaFieldType.phone:
        case SchemaFieldType.url:
        case SchemaFieldType.transportRegistrationNumber:
        case SchemaFieldType.year:
        case SchemaFieldType.list:
        case SchemaFieldType.nestedObject: {
          result.push(getBaseColumnTemplateFields(scheme, field, 'asc'));
          break;
        }
        // скрываем колонку inline во всех гридах
        case SchemaFieldType.inline: {
          break;
        }
        case SchemaFieldType.imageUpload: {
          result.push({
            ...getBaseColumnTemplateFields(scheme, field),
          });
          break;
        }
        default: {
          result.push({
            headerName: scheme[field].label ?? field,
            field,
            editable: false,
            additionalData: {
              order: scheme[field]?.order,
            },
            sort: 'asc',
          });
          break;
        }
      }
    }
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.log('Define column definition', e);
  }
  // в конце фильтруем по порядку, чтобы колонки тоже были по порядку
  result = _.sortBy(result, 'additionalData.order' && 'order');
  nested = _.sortBy(nested, 'additionalData.order' && 'order');
  // удаляем настроичные ключи для того чтоб не ругался AGGrid
  result.forEach((el) => {
    delete el.order;
    delete el.additionalData;
  });

  return { result, nested };
};
