import { RelatedDataPrototype } from '../../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../../api/getFromURL';
import { RoadsService } from '../../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../../types/enums/map/MapObjects.model';

export class ProjectMarkingLine extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'road_marking_line_project',
      filterLabel: 'Дорожная разметка',
      selectedObjectName: MapObjects.projectLayerRoadMarkingLine,
      isHidden: true,
    });
  }

  async uploadData() {
    return await RoadsService.roadsRoadMarkingLineProjectList();
  }

  async deleteRecord(id) {
    return await RoadsService.roadsRoadMarkingLineProjectDestroy({ id });
  }

  async readRecord(id) {
    return await RoadsService.roadsRoadMarkingLineProjectRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL('/roads/road_marking_line_project');
  }
}
