import React from 'react';
import PrepareScrollableInfoCard from '../PrepareScrollableInfoCard';

// TODO duplicate code Тех-долг-FRONTEND #5796
const ViolationsWarrantyPeriodSelection = ({ handleCloseMapSelectedObjectInfo, selectedMapItem, data }) => {
  const handleOpenObjectInNewTab = () => {
    const roadData = selectedMapItem.data.find((dataRecord) => dataRecord.name === 'road');
    const { id } = roadData.data;
    const win = window.open(
      `/app/directory/api/${selectedMapItem.data[0].urlData.replace('_repr', '')}/${id}/`,
      '_blank'
    );
    win.focus();
  };
  let Descriptions = [];

  const properties = data;
  properties.road_complex_type_quality_representation_name &&
    Descriptions.push({
      key: 'Комплексный показатель КПД дороги',
      value: properties.road_name,
    });

  properties.start_segment &&
    Descriptions.push({
      key: 'Начало участка, км',
      value: `${properties.start_segment} км`,
    });

  properties.end_segment &&
    Descriptions.push({
      key: 'Конец участка, км',
      value: `${properties.end_segment} км`,
    });

  properties.less_allowable_value &&
    Descriptions.push({
      key: 'Предельно допустимое значение',
      value: properties.less_allowable_value,
    });

  properties.road_work_name &&
    Descriptions.push({
      key: 'Дорожные работы',
      value: properties.road_work_name,
    });

  properties.road_work_gen_contractor &&
    Descriptions.push({
      key: 'Ген. подрядчик',
      value: properties.road_work_gen_contractor,
    });

  properties.road_work_curator &&
    Descriptions.push({
      key: 'Куратор',
      value: properties.road_work_curator,
    });

  properties.year &&
    Descriptions.push({
      key: 'Год диагностики',
      value: properties.year,
    });

  properties.object_end_warranty_date &&
    Descriptions.push({
      key: 'Дата окончания гарантийного срока',
      value: properties.object_end_warranty_date,
    });

  properties.date_time_detection &&
    Descriptions.push({
      key: 'Дата обнаружения',
      value: properties.date_time_detection,
    });

  return (
    <PrepareScrollableInfoCard
      title={'Диагностика УДС'}
      handleCloseCard={handleCloseMapSelectedObjectInfo}
      handleOpenItemInDirectory={handleOpenObjectInNewTab}
      listOfDescriptions={Descriptions}
      openInNewTabBtnDesc={'Открыть в справочнике'}
    />
  );
};

export default ViolationsWarrantyPeriodSelection;
