import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { IOPWTabMenuModel } from 'types/enums/directories/IOPWTabMenu.model';
import { IOPWDirectory } from 'types/enums/routes/directory/IOPWDirectory.model';

import { BreadcrumbsControlled } from '../../../../../Common/BreadcrumbsControlled/BreadcrumbsControlled';
import { HeaderScipBlock } from '../../../../../Common/HeaderScipBlock/HeaderScipBlock';
import { CargoTransportParams } from '../../../CargoTransportForm/interfacesCargoTransport/interfacesCargoTransport';
import { CargoTransportContext } from '../../../CargoTransportRouter';
import ObjectPanel from '../../../CargoTransportForm/ObjectPanel';

/**
 * @returns {JSX.Element}
 * @constructor
 */

export const AccountFormation = React.memo(() => {
  const history = useHistory();
  const { params } = useRouteMatch<CargoTransportParams>();
  const { directory, id, statement: statementDir, scoreMode, scoreId } = params;

  const { statement, count, setPageMenuKey } = useContext(CargoTransportContext);

  const labelDirectory = useMemo(() => {
    return IOPWTabMenuModel[directory];
  }, [directory]);

  const labelStatement = useMemo(() => {
    const crumbsSelector = (statementDir || id) && statementDir !== 'create';
    let nameCrumbs = `Заявление без имени`;
    if (statement?.number_of_statement) {
      nameCrumbs = `Заявление ${statement.number_of_statement}`;
    } else if (statement?.approval_id) {
      nameCrumbs = `Идентификатор заявки ${statement.approval_id}`;
    }
    return crumbsSelector ? nameCrumbs : 'Создание заявления';
  }, [id, statement, statementDir]);

  const labelCount = useMemo(() => {
    if (count) return count;
    if (scoreMode) return IOPWTabMenuModel[scoreMode];
    return 'Empty';
  }, [count, scoreMode]);

  let newDirectory = `${directory}/`;
  if (directory === IOPWDirectory.destruction_acts || directory === IOPWDirectory.write_off_acts) {
    newDirectory = '';
  }

  const urlModel = scoreId
    ? `/${IOPWDirectory.special_permits}/${newDirectory}${scoreMode}/${scoreId}/`
    : `/${IOPWDirectory.special_permits}/${newDirectory}${scoreMode}/`;

  const breadcrumbsLinks = [
    {
      url: `/app/${IOPWDirectory.special_permits}/`,
      label: 'Перевозка грузов',
    },
    {
      url: `/app/${IOPWDirectory.special_permits}/${directory}/`,
      label: labelDirectory,
    },
    {
      url: `/app/${IOPWDirectory.special_permits}/${directory}/${statementDir}/`,
      label: labelStatement,
    },
    {
      url: '',
      label: labelCount,
    },
  ];

  let newStatementDir = `${statementDir}/`;
  if (statementDir === IOPWDirectory.new_act) {
    newStatementDir = '';
  }

  const initClose = useCallback(() => {
    history.push(`/app/${IOPWDirectory.special_permits}/${directory}/${newStatementDir}`);
  }, [history, directory, newStatementDir]);

  const clickBreadcrumbsControlled = useCallback(() => setPageMenuKey(IOPWDirectory.main), [setPageMenuKey]);

  return (
    <>
      <HeaderScipBlock />
      {breadcrumbsLinks && (
        <BreadcrumbsControlled links={breadcrumbsLinks} clickBreadcrumbsControlled={clickBreadcrumbsControlled} />
      )}
      <ObjectPanel urlModel={urlModel} initClose={initClose} />
    </>
  );
});
