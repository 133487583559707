import { RelatedDataPrototype } from '../prototype/RelatedDataPrototype';
import { getFromURL } from '../../../../../../api/getFromURL';
import { KnsService } from '../../../../../../generated/api/api';
import { MapObjects } from '../../../../../../types/enums/map/MapObjects.model';
import { RequestPath } from '../../../../../../types/enums/routes/request/RequestPath';

export class Substations extends RelatedDataPrototype {
  constructor() {
    super({
      name: 'substations',
      filterLabel: 'Подстанции',
      selectedObjectName: MapObjects.substations,
    });
  }

  async uploadData() {
    return await KnsService.knsSubstationsList();
  }

  async deleteRecord(id) {
    return await KnsService.knsSubstationsDestroy({ id });
  }

  async readRecord(id) {
    return await KnsService.knsSubstationsRetrieve({ id });
  }

  async uploadModel() {
    return await getFromURL.getModelFromURL(RequestPath.knsSubstations);
  }
}
