import React from 'react';
import { SketchPicker } from 'react-color';
import { Radio } from 'antd';
import { PRESET_COLORS_FOR_ROAD_PICKER } from './PresetColorsForRoadPicker';

class ColorPicker extends React.Component {
  state = {
    background: { r: 47, g: 128, b: 237, a: 1 },
    value: null,
    textBtnRoadsSchema: ['Участки'],
    defaultTextBtn: ['Кабеля', 'Питающие кабеля'],
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleChangeComplete = (color) => {
    this.setState({ background: color.rgb });
  };

  roadsSchemaRadioButton = (arrayText) => {
    return (
      <Radio.Group onChange={this.onChange} value={this.state.value}>
        {arrayText.map((m, index) => (
          <div>
            <Radio value={index}>{m}</Radio>
          </div>
        ))}
      </Radio.Group>
    );
  };

  onChangeColorPicker = (value) => {
    if (this.props.layerName === 'trolleybusTransport') {
      switch (this.state.value) {
        case 0:
          return this.props.onTrollCableColorChange(Object.values(value.rgb));
        case 1:
          return this.props.onSupplyCableColorChange(Object.values(value.rgb));
        default:
          return value;
      }
    } else if (this.props.layerName === 'tramsSchema') {
      switch (this.state.value) {
        case 0:
          return this.props.onTramCableColorChange(Object.values(value.rgb));
        case 1:
          return this.props.onSupplyCableColorChange(Object.values(value.rgb));
        default:
          return value;
      }
    } else {
      return this.props.onRoadSectionColorChange(Object.values(value.rgb));
    }
  };
  render() {
    return (
      <>
        {this.props.layerName !== 'roadsSchema' && this.roadsSchemaRadioButton(this.state.defaultTextBtn)}
        <SketchPicker
          presetColors={PRESET_COLORS_FOR_ROAD_PICKER}
          color={this.state.background}
          onChangeComplete={this.handleChangeComplete}
          onChange={(value) => {
            this.onChangeColorPicker(value);
          }}
        />
      </>
    );
  }
}

export default ColorPicker;
