import { HTTPStatusCode } from 'types/enums/routes/request/HTTPStatusCode.model';
import { AnyObject } from 'types/enums/general/general.model';

// Рекурсионная функция обработки ошибок
const createMessage = (detail: AnyObject, alertMessages: string[] = [], name = ''): string[] => {
  let newMessages = [...alertMessages];

  for (const key in detail) {
    if (detail.hasOwnProperty(key)) {
      const item = detail[key];
      if (typeof item === 'string') {
        const keyName = name || key;
        const errorSting = `${keyName}: ${item}`;
        if (!newMessages.includes(errorSting)) {
          newMessages.push(errorSting);
        }
      } else {
        const keyName = Array.isArray(item) ? key : '';
        newMessages = createMessage(item, newMessages, keyName);
      }
    }
  }

  return newMessages;
};

export const createAlertMessage = (error: AnyObject) => {
  let alertMessage: string[] = [];

  if (error?.response) {
    const { status, data } = error.response;

    switch (status) {
      case HTTPStatusCode.BAD_REQUEST:
      case HTTPStatusCode.UNAUTHORIZED:
        if (data?.detail) {
          if (typeof data.detail === 'object') {
            alertMessage = createMessage(data.detail);
          } else {
            alertMessage.push(data.detail);
          }
        } else {
          alertMessage.push('Неизвестная ошибка обратитесь к администратору');
        }
        break;
      case HTTPStatusCode.FORBIDDEN:
        alertMessage.push('Недостаточно прав для этой операции');
        break;
      case HTTPStatusCode.METHOD_NOT_ALLOWED:
        alertMessage.push('Данная операция недоступна');
        break;
      case HTTPStatusCode.INTERNAL_SERVER_ERROR:
        alertMessage.push('Ошибка при обработке запроса, обратитесь к администратору');
        break;
      case HTTPStatusCode.BAD_GATEWAY:
      case HTTPStatusCode.GATEWAY_TIMEOUT:
        alertMessage.push('Сервер не отвечает');
        break;
      default:
        alertMessage.push('Неизвестная ошибка обратитесь к администратору');
        break;
    }
  } else {
    alertMessage.push('Неизвестная ошибка обратитесь к администратору');
  }

  return alertMessage;
};
