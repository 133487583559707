import React, { useState } from 'react';
import './PrintModal.scss';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
  PrintModalWrapper,
  PrintModalHeader,
  PrintModalIcon,
  PrintModalBody,
  PrintModalButton,
  PrintModalFooter,
} from './PrintModal.styled';
import { postToURLSingle } from '../../../../api/postToURLSingle';

const PrintModal = ({ active, setActive, set_email, request_object }) => {
  const [isSend, setIsSend] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const submitForm = async () => {
    await postToURLSingle.postOrPutDataErrorResponse('http://its-api.gor.ekb.lan/print/print_params/', {
      printRequestParams: {
        printUrl: 'https://its-ekb.oits.su/app/map/print_mode/',
        username: localStorage.getItem('user'),
        answer2: request_object.answer2.YakovTheBest2,
        bearing: request_object.bearing,
        pitch: request_object.pitch,
        layersWithAllSettings: request_object.layersWithAllSettings,
      },
      printParams: request_object.answer.YakovTheBest,
      username: localStorage.getItem('user'),
    });
    if (request_object.email) {
      setIsSend(true);
      setTimeout(() => {
        setActive(false);
        setIsSend(false);
      }, 1200);
    } else {
      setEmailError(true);
    }
  };
  return (
    <PrintModalWrapper className={active ? 'active' : 'modal'} onClick={() => setActive(false)}>
      <div onClick={(e) => e.stopPropagation()}>
        <PrintModalHeader>
          <h1>Отправить на почту</h1>
          <PrintModalIcon>
            <CloseOutlined onClick={() => setActive(false)} />
          </PrintModalIcon>
        </PrintModalHeader>
        <PrintModalBody>
          <form>
            <div>
              <p>Электронная почта</p>
              <input
                placeholder="example@gmal.com"
                onChange={(event) => {
                  if (event.target.value && emailError) setEmailError(false);
                  set_email(event.target.value);
                }}
              />
            </div>
          </form>
          {emailError && (
            <p
              style={{
                textAlign: 'center',
                color: 'red',
              }}
            >
              Укажите электронную почту
            </p>
          )}
        </PrintModalBody>
        <PrintModalFooter>
          <PrintModalButton className={isSend ? 'PrintModalButton' : ''} onClick={submitForm}>
            Отправить
          </PrintModalButton>
          {isSend && (
            <PrintModalButton>
              <CheckOutlined className="PrintModalButtonSend" />
              Отправлено
            </PrintModalButton>
          )}
        </PrintModalFooter>
      </div>
    </PrintModalWrapper>
  );
};

export default PrintModal;
