import { getFromURL } from 'api/getFromURL';
import { postToURLSingle } from 'api/postToURLSingle';
import { smevApi } from 'api/smev';
import { notificationActions } from 'store/reducers/global/notifications/notifications.actions';
import { createAlertMessage } from 'store/sagas/app/createAlertMessage';
import { AlertDelay } from 'types/enums/delay/AlertDelay.model';
import { ActiveStatus } from 'types/enums/UI/ActiveStatus.model';
import { PromptType } from 'types/enums/UI/PromptType.model';
import _ from 'underscore';

export const clickSignDocument = async (url: string, dispatch: (param: any) => void) => {
  try {
    const xmlData = await getFromURL.getAll(url + 'get_xml/');
    const xmlResponse = await smevApi.getSignature(xmlData);
    const response = await postToURLSingle.postOrPutDataErrorResponse(url + 'sign_xml/', {
      resolution_xml_data: xmlResponse,
    });
    if (response) {
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.success,
          title: 'Успешно',
          message: 'Успешно подписано!',
        })
      );
    }
  } catch (e) {
    if (_.isObject(e) && e?.response && _.isObject(e.response)) {
      const message = createAlertMessage(e);
      dispatch(
        notificationActions.setGlobalAlertData({
          status: ActiveStatus.active,
          type: PromptType.error,
          title: 'Ошибка',
          message,
        })
      );
    }
  } finally {
    setTimeout(() => dispatch(notificationActions.clearGlobalAlertData()), AlertDelay.main);
  }
};
