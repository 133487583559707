import React from 'react';
import { ResizableBox } from 'react-resizable';
import { Styled } from './WindowedDataTable.styled';
import PropTypes from 'prop-types';

const { LocalMainWrapper, ExtendedMainInner } = Styled.main;

const WindowedDataTable = ({ headerContent, children, resizableBoxSettings }) => {
  return (
    <LocalMainWrapper>
      <ExtendedMainInner>
        {headerContent}
        <ResizableBox
          width={Infinity}
          height={resizableBoxSettings.height}
          minConstraints={resizableBoxSettings.minConstraints}
          maxConstraints={resizableBoxSettings.maxConstraints}
          resizeHandles={resizableBoxSettings.resizeHandlers}
        >
          {children}
        </ResizableBox>
      </ExtendedMainInner>
    </LocalMainWrapper>
  );
};

export default WindowedDataTable;

WindowedDataTable.propTypes = {
  children: PropTypes.node,
  headerContent: PropTypes.node,
  resizableBoxSettings: PropTypes.shape({
    height: PropTypes.number,
    maxConstraints: PropTypes.arrayOf(PropTypes.number),
    minConstraints: PropTypes.arrayOf(PropTypes.number),
    resizeHandlers: PropTypes.arrayOf(PropTypes.string),
    width: PropTypes.number,
  }),
};

WindowedDataTable.defaultProps = {
  resizableBoxSettings: {
    height: 320,
    minConstraints: [100, 100],
    maxConstraints: [1000, 1000],
    // закомментированно - включение динамического изменения размера грида, раскомметированно - выключение
    // resizeHandlers: ["se", "sw"],
    resizeHandlers: [],
    axis: 'none',
  },
};
